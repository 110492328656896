import {memo, useState} from 'react';
import { Form } from 'react-bootstrap';
import { TQuizGame } from './types';
import Check from 'src/assets/images/gamification/input-checked.svg'

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The single choice component's input properties' interface
 * @param { TQuizGame } gameData
 *    The quiz game's data
 */
interface SingleChoiceProps {
  gameData: TQuizGame,
  onAnswersSelect: (answersIndex: number[]) => void
}

/**
 * @description
 *    The Single Choice game's body component
 */
function SingleChoice({ gameData, onAnswersSelect }: SingleChoiceProps) {
  const { options, question } = gameData;

  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);

  /**
   * @description
   *    The method is handling the on answer click event
   * @param {number} selectedOptionIndex
   *    The selected answer's index variable
   */
  const handleAnswerSelect = (selectedOptionIndex: number) => {
    onAnswersSelect([selectedOptionIndex]);
    setSelectedAnswer(selectedOptionIndex);
  }

  return (
    <Form>
      {options.map((option, index) => {
        return (
          <p key={`${option.title}_${index}`} className="custom-radio">
            <input
              type="radio"
              id={index.toString()}
              checked={selectedAnswer === index}
              name={question}
              onChange={()=> handleAnswerSelect(index)}
            />
            <label htmlFor={index.toString()}>{option.title} <span className="check-wrapper"><img  src={Check} alt="check"/></span></label>
          </p> )
      })}
      <p key={'final.option'} ><label htmlFor={'final.option'}/></p>
    </Form>
  )
}

export default memo(SingleChoice);
