import BarMenu from '../bar-menu';
import Shop from '../shop';
import { Container } from 'react-bootstrap';

export default function Profile() {
  return (
    <Container className='islands animate d-flex align-items-center'>
      <Shop />
      <BarMenu />
    </Container>
  );
}