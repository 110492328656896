// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local @types
import { TGame, TGameLevel, TGameMechanic, TGameStatus, TGameTooltip } from 'src/@types';
import { EnumGameLevel, EnumGameStatus } from 'src/@constants';
// API services
import {
  fetchGameById,
  fetchGameMechanic,
  createGame,
  createGameMechanic,
  saveGame,
  submitPlayedGame
} from 'src/services';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The game base state variables.
 */
type GameState = {
  error: Error | string | null;
  isLoading: boolean;
  isEditMode: boolean;
  status: TGameStatus;
  game: TGame | null;
  gameMechanic: TGameMechanic | null;
  page: number;
  points: number;
  isShowHints: boolean
  isShowInstructions: boolean
  gameTooltip: TGameTooltip | null,
  isTimeUp: boolean
};

/**
 * @description - The initialization of the game state
 */
const gameInitialState: GameState = {
  error: null,
  isLoading: false,
  isEditMode: false,
  status: EnumGameStatus.new,
  game: null,
  gameMechanic: null,
  page: 0,
  points: 0,
  isShowHints: false,
  isShowInstructions: false,
  gameTooltip: null,
  isTimeUp: false
};

/**
 * @description - The slices for the game.
 */
const gameSlice = createSlice({
  name: 'game',
  initialState: gameInitialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GAME STATUS
    changeGameStatusSuccess(state, action) {
      state.status = action.payload;
    },
    changeGameEditModeSuccess(state, action) {
      state.isEditMode = action.payload;
    },
    // GET GAME
    getGameSuccess(state, action) {
      state.isLoading = false;
      state.game = action.payload;
    },
    getGameMechanicSuccess(state, action) {
      state.isLoading = false;
      state.gameMechanic = action.payload;
    },
    changeGameContentPageSuccess(state, action) {
      state.page = action.payload;
      state.isLoading = false;
    },
    randomizeContent(state, action) {
      if (state.game) {
        state.game = {
          ...state.game,
          content: action.payload
        };
        state.gameMechanic = action.payload[0];
      }
    },
    changeGamePlayedPoints(state, action) {
      state.points = action.payload;
    },
    // RESET GAME MECHANIC PLAY/EDIT
    // CLOSE GAME
    closeGameSuccess(state) {
      state.isLoading = false;
      state.isEditMode = false;
      state.gameMechanic = null;
      state.page = 0;
      state.points = 0;
    },
    showHintsPopup(state, action) {
      state.isShowHints = action.payload;
    },
    showInstructionsPopup(state, action) {
      state.isShowInstructions = action.payload;
    },
    showGameTooltip(state, action) {
      state.gameTooltip = action.payload;
    },
    showTimeUp(state, action) {
      state.isTimeUp = action.payload;
    }
  }
});
// The reducers
export default gameSlice.reducer;

// State base actions
export const {} = gameSlice.actions;

// ========================================================================== //
// ================================ METHODS ================================= //
// ========================================================================== //

/**
 * @description
 *    The method is fetching game data from the server and storing it into the
 *    internal storage/redux
 */
export function getGameById(gameId: string) {
  return () => {
    dispatch(gameSlice.actions.startLoading());
    try {
      fetchGameById({
        gameId,
        onSuccess: (gameData: TGame) =>
          dispatch(gameSlice.actions.getGameSuccess(gameData)),
        onFail: (error) =>
          dispatch(gameSlice.actions.hasError(error))
      });
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description
 *    The method is fetching the game mechanic by the pages
 * @param game
 *    The current game data
 * @param page
 *    The content page which needs to be parsed
 */
export function getGameMechanic(game: TGame, page: number) {
  return () => {
    dispatch(gameSlice.actions.startLoading());
    try {
      fetchGameMechanic({
        content: game.content,
        page,
        onSuccess: (gameMechanic: TGameMechanic | null) =>
          dispatch(gameSlice.actions.getGameMechanicSuccess(gameMechanic)),
        onFail: (error: Error | string) =>
          dispatch(gameSlice.actions.hasError(error))
      });
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description
 *    The method is triggering game slice action for the randomizing game content
 * @param content
 */

export function randomizeContentData(content: TGameMechanic[]) {
  return () => {
    dispatch(gameSlice.actions.startLoading());
    try {
      let finalContent = [...content];
      //shuffling the content
      for (let i = finalContent.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [finalContent[i], finalContent[j]] = [finalContent[j], finalContent[i]];
      }
      dispatch(gameSlice.actions.randomizeContent(finalContent));
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description
 *    The method is triggering game slice action for the game creation process
 * @param gameData
 * @param onSuccess
 */
export function createNewGame(
  gameData: TGame,
  onSuccess: (id: string) => void
) {
  return () => {
    dispatch(gameSlice.actions.startLoading());
    try {
      createGame({
        game: gameData,
        onSuccess,
        onFail: error => {
        }
      });
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description
 *    The method is creating game mechanic data from the provided object
 * @param data
 */
export function createGameNewMechanic(data: Record<string, any>) {
  return () => {
    dispatch(gameSlice.actions.startLoading());
    try {
      createGameMechanic({
        data,
        onSuccess: (gameMechanic: TGameMechanic) =>
          dispatch(gameSlice.actions.getGameMechanicSuccess(gameMechanic)),
        onFail: (error: Error | string) =>
          dispatch(gameSlice.actions.hasError(error))
      });
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description - The method triggers closing action for the game state
 */
export function closeGame() {
  return () => {
    dispatch(gameSlice.actions.closeGameSuccess());
  };
}

/**
 * @description
 *    The method is changing the game-playable status
 * @param { TGameStatus } newStatus
 *    The new status of the game default is "new"
 */
export function changeGameStatus(newStatus: TGameStatus) {
  return () => {
    dispatch(gameSlice.actions.changeGameStatusSuccess(newStatus));
  };
}

/**
 * @description
 *    The method is changing the internal mode of the game edit and play
 * @param { boolean } newEditMode
 *    The new editable mode of the game, if its true then editable if false then
 *    only playable or preview.
 */
export function changeGameEditMode(newEditMode: boolean) {
  return () => {
    dispatch(gameSlice.actions.changeGameEditModeSuccess(newEditMode));
  };
}

/**
 * @description
 *    The method is changing the page global state for the game content
 * @param {number} newPage
 *    The page number that needs to be changed.
 *    If the page number does not provide or less than 0, then the default page
 *    number will be defined as 0.
 */
export function changePage(newPage?: number) {
  return () => {
    const finalPage = newPage && newPage >= 0 ? newPage : 0;
    dispatch(gameSlice.actions.changeGameContentPageSuccess(finalPage));
  };
}

/**
 * @description
 *    The method is updating the internal state of the game's content
 * @param { TGame } game
 *    The modified and final game
 */
export function updateGame(game: TGame | null) {
  return () => {
    try {
      dispatch(gameSlice.actions.getGameSuccess(game));
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description
 *    The method is storing the final game data into the DB
 * @param {TGame} game
 *    The final game object that needs to be saved
 * @param onSuccess
 */
export function submitGame(game: TGame, onSuccess: VoidFunction) {
  return () => {
    const finalGame = { ...game };
    if (typeof game?.level === 'number') {
      finalGame.level = Object.keys(EnumGameLevel)[game.level - 1] as unknown as TGameLevel;
    }
    try {
      saveGame({
        game: finalGame,
        onSuccess: onSuccess,
        onFail: (error) => dispatch(gameSlice.actions.hasError(error))
      });
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description
 *    The method is updating the internal state of the game's content
 * @param { TGameMechanic | null } gameMechanic
 *    The modified and final game
 */
export function updateGameMechanic(gameMechanic: TGameMechanic | null) {
  return () => {
    try {
      dispatch(gameSlice.actions.getGameMechanicSuccess(gameMechanic));
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description
 *    The method is changing the internal state of the played points.
 * @param finalPoints
 */
export function updateGameCurrentPoints(finalPoints: number) {
  return () => {
    try {
      dispatch(gameSlice.actions.changeGamePlayedPoints(finalPoints));
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description
 *    The method is submitting the final points into the server side.
 * @param finalPoints
 * @param userId
 * @param gameId
 */
export function submitGameCurrentPoints(finalPoints: number, userId: string, gameId: string, gameName: string, userEmail: string) {
  return () => {
    try {
      submitPlayedGame({
        data: {
          gameId,
          userId,
          finalPoints,
          gameName,
          userEmail
        },
        // onSuccess: () => window.location.reload(),
        onSuccess: () => {},
        onFail: (error: Error | string) => dispatch(gameSlice.actions.hasError(error))
      });
    } catch (error) {
      dispatch(gameSlice.actions.hasError(error));
    }
  };
}

/**
 * @description
 *    The method is either closing or opening the hints popup in the editor.
 */
export const showTheHintsPopup = (payload: boolean) => {
  dispatch(gameSlice.actions.showHintsPopup(payload));
};


/**
 * @description
 *    The method is either closing or opening the hints popup in the editor.
 */
export const showTheInstructionsPopup = (payload: boolean) => {
  dispatch(gameSlice.actions.showInstructionsPopup(payload));
};
/**
 * @description
 *    The method is either closing or opening the game info tooltip in the selected skill island view
 */
export const showTheGameInfoTooltip = (payload: TGameTooltip | null) => {
  dispatch(gameSlice.actions.showGameTooltip(payload));
};

export const showTheTimeUp  = (payload: boolean) => {
  dispatch(gameSlice.actions.showTimeUp(payload))
}
