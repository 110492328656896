import React, { memo, useMemo, useState } from 'react';
import { Col, Row, Container, Modal, Form } from 'react-bootstrap';
import SelectedIsland from 'src/assets/svg/games/selected-island';
import CloseBtn from 'src/assets/svg/buttons/close-btn';
import { ButtonMotion, Loading } from 'src/components';
import { EnumGameStatus, EditUsers, Institutes } from '../../../@constants';
import { getDiamondColor } from '../../../helpers';
import LevelInfo from 'src/components/level-info';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth, useGame } from '../../../hooks';
import { PATH_GLMS_PLAYER } from '../../../routes/paths';
import AzureAuth from '../../../azure-component/AzureAuth';
import Button from 'react-bootstrap/Button';
import { updateGameService } from '../../../api/v0';
import SelectedGameConfigs from '../../../components/selected-game-configs';
import { changeGameStatus } from '../../../redux/slices';
import { dispatch } from '../../../redux/store';
import { AnimatePresence, motion } from 'framer-motion';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //


function SelectedGameView() {

  const [show, setShow] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // State to hold the text
  const [errorMessage, setErrorMessage] = useState(''); // State to hold the text
  const [viewResults, setViewResults] = useState(false);


  const { fileId } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (newText: string, type: string) => {
    switch (type) {
      case 'success':
        setSuccessMessage(newText);
        break;
      default:
        setErrorMessage(newText);
        break;
    }
  };

  const account = AzureAuth.getAccount();

  const { localAccountId: accountId, username, tenantId } = account;
  const { user } = useAuth();
  const userEmail = username.toLowerCase();

  const {
    error,
    isLoading,
    isEditMode,
    status,
    game,
    playGame,
    closeGame,
    switchToEditMode,
    updateGame
  } = useGame();

  if (isLoading) {
    return <Loading />;
  }

  const handleSave = () => {
    // Here you can handle the save action
    setShow(false);
    if (game) {
      updateGameService(
        game.id,
        { message: { success: successMessage, error: errorMessage } },
        () => window.location.reload()
      );
    }
  };

  const checkIfAllowToEdit = () => {
    return user?.role?.toLowerCase() !== 'student';
  };

  if (game) {
    const { title, description, level, type, mini_world_id, progress } = game;

    const navigate = useNavigate();

    const closeSelectedGame = () => {
      closeGame();
      dispatch(changeGameStatus(EnumGameStatus.new));
      navigate(`/${PATH_GLMS_PLAYER.classes.islands.get('demoClassId', 'demoCourseId', mini_world_id)}`);
    };

    const gameLevelNumber = level as unknown as number;

    const gameInfoText = useMemo(() => {
        switch (status) {
          case EnumGameStatus.succeeded:
            return <div className='warning-wrapper success'><h2 className='warning success'>Great!</h2>
              <h3>You won!</h3></div>;
          case EnumGameStatus.failed:
            return <div className='warning-wrapper fail'><h2 className='warning fail'>Oops!</h2>  <h3>You lost...</h3></div>;
          default:
            return null;
        }
      },
      [status]
    );

    const goToEditor = () => {
      // ToDo
      // navigate(PATH_GLMS_PLAYER.games.game.edit.getFullPath(game?.id));
      window.location.href = PATH_GLMS_PLAYER.games.game.edit.getFullPath(game?.id);
    };

    const getActionButtonsSections = useMemo(() => {
      switch (status) {
        case 'new':
          return (
            <ButtonMotion
              className='left-btn'
              variant={'custom'}
              onClick={playGame}
            >
              Let's play!
            </ButtonMotion>
          );
        default:
          return (
            <ButtonMotion
              className='left-btn'
              variant={'custom'}
              onClick={playGame}
            >
              Play again
            </ButtonMotion>
          );
      }
    }, [status]);

    return (
      <Container className='islands animate gamified'>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit End Screen Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Success Completeness Message</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter text'
                  name={'success'}
                  value={successMessage}
                  onChange={(e) => handleChange(e.target.value, 'success')}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Failed Completeness Message</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter text'
                  name={'error'}
                  value={errorMessage}
                  onChange={(e) => handleChange(e.target.value, 'error')}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <Button variant='primary' onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <div className='selected-island'>
          <AnimatePresence>
            {viewResults && <motion.div className='results-popup'
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}>
              <CloseBtn close={() => setViewResults(false)} />
              {game.file?.type === 'pdf' ?
                <iframe src={game.file?.url} width='100%' height='auto' />
                : <img className='img-fluid' src={game.file?.url} alt='answers' />}
            </motion.div>}
          </AnimatePresence>
          <Row>
            <Col md={8} className='d-flex justify-content-between flex-column'>
              <div>
                <CloseBtn close={closeSelectedGame} />
                <h2>{title}</h2>
                <p dangerouslySetInnerHTML={{__html: description}}/>
                {gameInfoText}
                <LevelInfo level={gameLevelNumber} />
                {game?.program_name && (<h3>Program: {game.program_name}</h3>)}
                {status !== 'new' && <div className='score-info'>
                  <h3>Scores</h3>
                  <h3>Time</h3>
                  <h3>Badges</h3>
                </div>}
                {status === EnumGameStatus.failed && game?.file?.show && (
                  <ButtonMotion
                    variant={'results'}
                    className='right-btn mt-3'
                    onClick={() => setViewResults(true)}
                  >
                    View results
                  </ButtonMotion>
                )}
              </div>

              {/*{fileId && game?.file?.show && game?.file?.url && (*/}
              {/*  <div>*/}
              {/*    <img src={game.file.url} alt='feedback' style={{ width: 400 }} />*/}
              {/*  </div>*/}
              {/*)}*/}
            </Col>
            <Col md={4} className="text-center"><SelectedIsland colorClass={getDiamondColor(type, progress)} gameType={type}/></Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className='btn-wrapper'>
                {getActionButtonsSections}
                {/*{checkIfAllowToEdit() ? <ButtonMotion variant={'light'} onClick={handleShow}>Change Message</ButtonMotion> : null}*/}
                {checkIfAllowToEdit() ? <ButtonMotion className='right-btn' variant={'custom'} onClick={goToEditor}>Edit
                  Game</ButtonMotion> : null}
              </div>
            </Col>
          </Row>
        </div>
        <SelectedGameConfigs />
      </Container>
    );
  }
return null;
}

export default memo(SelectedGameView);
