import { memo, useCallback, useEffect, useState } from 'react';
import { useGameMechanic } from 'src/hooks';
import { TScrambleSentenceGame, word } from './types';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { checkIsMobile } from 'src/helpers';
import { DragItem, DropItem } from 'src/components';
import GameNotFound from '../../../not-found';
import 'src/assets/styles/games/scramble-game.scss';

function ScrambleSentenceGame() {
  const {
    gameMechanic,
    points,
    changeCorrectAnswersPoints
  } = useGameMechanic();
  if (gameMechanic) {
    const {
      sentenceArr,
      highlights
    } = gameMechanic.content as TScrambleSentenceGame;

    const isMobile = checkIsMobile();

    const shuffle = (array: string[]) => {
      const shuffledArray = [...array];

      for (let i = highlights.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));

        [shuffledArray[highlights[i]], shuffledArray[highlights[j]]] = [shuffledArray[highlights[j]], shuffledArray[highlights[i]]];
      }

      return shuffledArray;
    };

    const [shuffledSentenceArray, setShuffle] = useState(shuffle(sentenceArr));

    const swapElements = useCallback((index1: number, index2: number, arr: string[]) => {
      if (index1 < 0 || index1 >= arr.length || index2 < 0 || index2 >= arr.length) {
        console.error('Invalid indices');
        return arr;
      }
      let newArr = [...arr];

      const temp = newArr[index1];
      newArr[index1] = newArr[index2];
      newArr[index2] = temp;

      return newArr;
    }, []);

    const handleDrop = (item: { index: number }, index: number) => {
      const newContent = swapElements(item.index, index, shuffledSentenceArray);
      setShuffle(newContent);
      if (areArraysIdentical(newContent, sentenceArr)) {
        changeCorrectAnswersPoints(100);
      }
    };

    const areArraysIdentical = (arr1: string[], arr2: string[]) => {
      return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
    }

    return (
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <div className='scramble-sentence'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='scramble-description d-flex flex-wrap'>
                {shuffledSentenceArray.map((word, index) => {
                  if (highlights.includes(index)) {
                    return (
                      <DropItem onDrop={(item) => handleDrop(item, index)} key={`${word}-${index}`}>
                        <DragItem
                          item={{ index, element:<div className='word'>
                              <p> {word}</p>
                            </div> }}>
                          <div className='word'>
                            <p> {word}</p>
                          </div>
                        </DragItem>
                      </DropItem>
                    );
                  } else {
                    return (
                      <div key={`${word}-${index}`} className='word'>{word}</div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </DndProvider>
    );
  }
  return <GameNotFound />;
}

export default memo(ScrambleSentenceGame);