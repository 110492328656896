import { memo } from 'react';

function ImageHolderLandscape() {
  return (
    <svg viewBox="0 0 529 321" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.602707" width="528.284" height="319.631" rx="33" fill="white"/>
      <path d="M28.6089 24.319L497.816 301.908" stroke="#B3B3B3" strokeMiterlimit="10"/>
      <path d="M496.68 23.78L29.745 302.447" stroke="#B3B3B3" strokeMiterlimit="10"/>
    </svg>
  );
}

export default memo(ImageHolderLandscape);
