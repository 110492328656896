import React, { memo, useState, useMemo, useEffect } from 'react';
import { useGameEditMechanic } from 'src/hooks';
import { TDragDropTextGame } from "./types";
import GameNotFound from '../../../not-found';
import { handleChange } from 'src/helpers';
import { ButtonMotion } from 'src/components';
import { TGameMechanicContent } from "src/@types";
import classnames from "classnames";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The calculation game component
 * @constructor
 */
function DragDropTextGame() {
  const {
    gameMechanic,
    updateGameMechanic,
  } = useGameEditMechanic();
  const [description, setDescription] = useState<string>('');
  const [descriptionSaved, setDescriptionSaved] = useState<Boolean>(false);
  const [descriptionArr, setDescriptionArr] = useState<string[]>([]);
  const [currentSelection, setCurrentSelection] = useState<number[]>([]);
  const [missingWords, setMissingWords] = useState<number[]>([]);

  useEffect(() => {
    if (gameMechanic?.content) {
      setDescription((gameMechanic.content as TDragDropTextGame).sentence ?? '');
    }
  }, [gameMechanic]);

  if (gameMechanic) {
    const onGameMechanicContentChange = (finalContent: TDragDropTextGame) => {
      updateGameMechanic({
        ...gameMechanic,
        content: finalContent as unknown as TGameMechanicContent
      });
    };

    const selectWords = ()=> {
      const sentenceList = (gameMechanic.content as TDragDropTextGame).sentence?.split(' ') ?? [];
      let finalDescription = description;
      currentSelection.map((currentSelectedWord) => {
        finalDescription = finalDescription.replace(sentenceList[currentSelectedWord], '{{_}}');
      })
      onGameMechanicContentChange({
        ...gameMechanic.content as TDragDropTextGame,
        missing_words: [...missingWords, ...currentSelection].map((indexNumber) => ({
          word: sentenceList?.[indexNumber] ?? '',
          index: indexNumber
        })),
        sentence: finalDescription,
      });
      setMissingWords(prev => [...prev, ...currentSelection]);
      setCurrentSelection([]);
    }

    const toggleSelection = (index: number) => {
      setCurrentSelection(prev => {
        if (prev.includes(index)) {
          return prev.filter(n => n !== index);
        } else {
          return [...prev, index];
        }
      });
    }

    const saveDescription = () => {
      onGameMechanicContentChange({
        ...gameMechanic.content as TDragDropTextGame,
        sentence: description,
        missing_words: []
      });
      setDescriptionSaved(true);
      setDescriptionArr(description.split(' '));
    };

    const actionButtons = useMemo(() => {
      if (!descriptionSaved) return <ButtonMotion variant={'scramble'} disabled={description.length === 0}
                                                  onClick={saveDescription}>Save</ButtonMotion>;
      return (
        <ButtonMotion
          variant={'scramble'}
          onClick={selectWords}
        >Select Word
        </ButtonMotion>
      );
    }, [descriptionSaved, currentSelection, description]);


    return (
      <div className='highlight-word-editor'>
        {descriptionSaved ? <div className='sentence-to-scramble text-area-style'><p className="d-flex flex-wrap">{descriptionArr.map((word, i) =>
            <span key={`word-${i}`} onClick={() => toggleSelection(i)}
                  className={classnames({
                    selected: currentSelection.includes(i),
                    highlighted: missingWords.includes(i)
                  })}>{word}</span>)}</p></div> :
          <textarea value={description} onChange={handleChange(setDescription)}
                    placeholder='Enter here the correct sentence, then select the words desired and click on “select word” button to create a new selectable word.' />}

        {actionButtons}
      </div>
    );
  }
  return <GameNotFound />;
}

export default memo(DragDropTextGame);
