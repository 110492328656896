import { EnumGameMechanicCategory } from "src/@constants";
export const MarkSpotGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.mark_spot,
  points: 100,
  content: {
    description: '',
    annotations: [],
    filePath: null,
  }
}
