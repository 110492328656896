import { Col, Row } from 'react-bootstrap';
import { ImageAnnotation, Uploader } from 'src/components';
import { DndProvider } from 'react-dnd';
import { useGameEditMechanic } from 'src/hooks';
import { TGameMechanicContent } from "../../../../@types";
import GameNotFound from "../../../not-found";
import { TDragDropTextToImageGame } from "./types";
import React from "react";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { checkIsMobile } from "src/helpers";

export default function DragDropTextToImageEditor(){

  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();

  const validateCurrentContent = () => {
    return true;
  }

  if (gameMechanic) {
    const onGameMechanicContentChange = (finalContent: TGameMechanicContent) => {
      if (validateCurrentContent()) {
        updateGameMechanic({
          ...gameMechanic,
          content: finalContent as unknown as TGameMechanicContent,
        });
      }
    }

    const { annotations, filePath } = gameMechanic.content as TDragDropTextToImageGame;

    const isMobile = checkIsMobile();

    return (
      <div className='drop-text-to-image'>
        <Row>
          <Col md={6}>
            {
              filePath
                ? (<ImageAnnotation
                    annotations={annotations ?? []}
                    onSubmit={(annotations: any[]) => onGameMechanicContentChange({ ...gameMechanic.content, annotations })}
                    image={filePath}
                    viewMode={false}
                />)
                : (<DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                  <Uploader  gameId={gameId} currentUrl={filePath} onGameMechanicContentChange={(filePath) => onGameMechanicContentChange({
                    ...gameMechanic.content,
                    filePath
                  } as TGameMechanicContent)} key={Math.random()} />
                </DndProvider>)
            }
          </Col>
          <Col md={6}>
            <div className='d-flex flex-wrap'>

            </div>
          </Col>
        </Row>
      </div>
    )
  }

  return <GameNotFound />;
}