import { TQuizGame } from './types';
import React, { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ButtonMotion, Uploader } from '../../../../components';
import { checkIsMobile } from '../../../../helpers';
import { TGameMechanicContent } from '../../../types';
import DeleteBtn from '../../../../assets/svg/buttons/delete-btn';
import TinyMceEditor from '../../../../components/tiny-mce-editor';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The single-choice component's input properties' interface
 * @param { TQuizGame } gameData
 *    The quiz game's data
 */
interface SingleChoiceEditor {
  onContentChange: (currentData: TGameMechanicContent) => void;
  data: TQuizGame;
  gameId:string | null
}

function SingleChoiceEditor(
  {
    data = {
      question: '',
      options: [],
      filePath: null,
    },
    onContentChange,
    gameId
  }: SingleChoiceEditor
) {

  const isMobile = checkIsMobile();

  const { question, options, filePath } = data;



  const handleQuestionChange = (value: string) => {
    if (data) {
      const finalData = {
        ...data,
        question: value
      };

      onContentChange(finalData);
    }
  };
  const handleOptionCorrectnessChange = (selectedIndex: number) => {
    if (data) {
      const finalData = {
        ...data,
        options: options.map((option, index) => ({
          ...option,
          is_correct: index === selectedIndex
        }))
      };

      onContentChange(finalData);
    }
  };

  const handleOptionChange = (value: string, selectedIndex: number) => {
    if (data) {
      const finalData = {
        ...data,
        options: options.map((option, index) => ({
          ...option,
          title: index === selectedIndex ? value : option.title
        }))
      };

      onContentChange(finalData);
    }
  };

  /**
   * @description
   *    The method is handling the option of the single choice to delete
   * @param {number} indexToRemove
   *    The selected index that need to be deleted
   */
  const handleOptionDelete = (indexToRemove: number) => {
    if (data?.options?.length > 1) {
      const finalData = {
        ...data,
        options: options.filter(
          (option, index) => index !== indexToRemove
        )
      };

      onContentChange(finalData);
    }
  }

  /**
   * @description
   *    The method is handling the option to be added
   *    The selected index that need to be deleted
   */
  const handleOptionAdd = () => {
    if (data?.options) {
      const finalData = {
        ...data,
        options: [
          ...options,
          {
            title: '',
            is_correct: false,
          }
        ]
      };

      onContentChange(finalData);
    }
  }
  return (
    <div className='single-quiz-editor'>
      <Row>
        <Col md={4} className='mb-3 mb-md-0'>
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <Uploader gameId={gameId} currentUrl={filePath} onGameMechanicContentChange={(filePath) => onContentChange({...data, filePath})} key={Math.random()} />
          </
            DndProvider>
        </Col>
        <Col md={8}>
          <TinyMceEditor handleChange={handleQuestionChange} value={question} />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <div className='options'>
            {options.map((opt, i) => {
              return (
                <div
                  key={`opt-container-${i}`}
                  className='quiz-option-container'
                >
                  <p key={`opt-${i}`} className='custom-radio'>
                    <input
                      type='radio'
                      checked={opt.is_correct}
                      id={`opt-${i}`}
                      name='question'
                      onChange={() => handleOptionCorrectnessChange(i)}
                    />
                    <label htmlFor={`opt-${i}`}>
                      <input
                        value={opt.title}
                        placeholder={`Option ${i + 1}`}
                        onChange={(e) => handleOptionChange(e.target.value, i)}
                      />
                    </label>
                 <DeleteBtn  onClick={() => handleOptionDelete(i)}/>
                  </p>

                </div>
              );
            })}
            <button
              onClick={handleOptionAdd}
              className={'quiz-add-option-btn'}
            >
              Add option
            </button>

            <p className='instruction'>Click on the radio button to select to correct answer.</p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default memo(SingleChoiceEditor);
