import { TWorld, TMiniWorld, TGame } from 'src/@types';
import { IGameView } from 'src/@interfaces';
import Maths from "../../assets/svg/games/Maths/Maths";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The method is converting the database content into the world
 * object in order to view them in the component
 * @param { Record<string, any> } data - The server data
 * ToDO - add validation
 */
export function convertDbDataToWorld(
  data: Record<string, any>,
): TWorld {
  return {
    id: data.id,
    created_by: data.created_by,
    updated_by: data.updated_by ?? null,
    created_at: data.created_at,
    updated_at: data.updated_at,
    category: data.category,
    course_id: data.course_id,
    title: data.title,
    description: data.description,
    worlds: data.worlds.map((miniWorld: Record<string, any>) => ({
      id: miniWorld.id,
      title: miniWorld.title,
      description: miniWorld.description,
      category: miniWorld.category,
      icon: miniWorld.icon,
    })),
  }
}

/**
 * @description - The method is converting the database content into the mini
 * worlds' games list object in order to view them in the component
 * @param { Record<string, any>[] } data - The server data
 * ToDO - add validation
 */
export function convertDbDataToMiniWorldGamesList(
  data: Record<string, any>[],
): IGameView[] {
  return data.map((gameInfo: Record<string, any>) => ({
    id: gameInfo.id,
    title: gameInfo.title,
    description: gameInfo.description,
    level: gameInfo.level,
    mini_world_id: gameInfo.mini_world_id,
    status: gameInfo.status,
    pages: gameInfo.content?.length || 0,
    type: gameInfo.type || 'mini',
    progress: Math.floor(Math.random() * 101),
  }));
}

/**
 * @description - The method is converting the database content into the mini
 * world's object in order to view them in the component
 * @param { Record<string, any>[] } data - The server data
 * ToDO - add validation
 */
export function convertDbDataToMiniWorld(
  data: Record<string, any>,
): TMiniWorld {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    icon: data.icon,
    category: data.category,
  };
}
