import { ButtonMotion } from '../../../components';

export default function FilterBtn({ onClick }: { onClick: () => void }) {
  return (
    <ButtonMotion variant="filter" onClick={onClick}>
      <svg viewBox='0 0 32 33' fill='none' xmlns='http://www.w3.org/2000/svg' className='filter-icon'>
        <path
          d='M30.6213 1.8205e-06H1.37808C0.862285 -0.000837874 0.389893 0.288857 0.153696 0.750689C-0.0850038 1.2184 -0.043273 1.78183 0.262196 2.20924L10.9753 17.3934C10.9787 17.3985 10.9828 17.4035 10.9862 17.4086C11.3751 17.9376 11.5863 18.5774 11.5871 19.2357V31.6153C11.5846 31.9823 11.7281 32.3341 11.9852 32.5944C12.2423 32.8547 12.5911 33.0008 12.9559 33.0008C13.1412 33.0008 13.3239 32.9639 13.495 32.8925L19.5151 30.5834C20.0543 30.4179 20.4123 29.9057 20.4123 29.2894V19.2374C20.4132 18.5791 20.6243 17.9392 21.0132 17.4102C21.0166 17.4052 21.0208 17.4002 21.0241 17.3951L31.7381 2.2084C32.0435 1.78183 32.0853 1.2184 31.8466 0.750689C31.6104 0.288857 31.138 -0.000837874 30.6213 1.8205e-06Z'
          fill='#006837' />
      </svg>
    </ButtonMotion>
  );
}