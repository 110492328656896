import { useState } from 'react';
// @ts-ignore
import Annotation from 'react-image-annotation';
import './styles.css';

interface ImageAnnotationProps {
  onSubmit: (annotations: any[]) => void;
  annotations: any[],
  image: string,
  viewMode?: boolean
}

export function ImageAnnotation({ onSubmit, annotations: annotationsInput, image, viewMode=false }: ImageAnnotationProps) {
  const [annotations, setAnnotations] = useState<any[]>(annotationsInput ?? []);
  const [annotation, setAnnotation] = useState({});
  const [srcImage, setSrcImage] = useState(image);

  const onChange = (annotation: any) => {
    setAnnotation(annotation);
  }

  const handleAnnotationSubmit = (annotation: any) => {
    const { geometry, data } = annotation

    // @ts-ignore
    const finalAnnotations = annotations.concat({
      // @ts-ignore
      geometry,
      data: {
        ...data,
        id: Math.random()
      }
    });

    onSubmit(finalAnnotations);
    setAnnotation({});
    setAnnotations(finalAnnotations);
  }

  if (srcImage) {
    return (
      <Annotation
        src={srcImage}
        alt="Highlight the spots"
        annotations={annotations}
        value={annotation}
        onChange={setAnnotation}
        onSubmit={handleAnnotationSubmit}
        renderEditor={renderEditor}
        renderContent={viewMode ? () => {} : renderContent}
        {...(viewMode ? { renderOverlay: () => {}, renderHighlight: () => {} } : {})}
      />
    );
  }

  return null;
}

function renderEditor (props: any) {
  const { geometry } = props.annotation
  if (!geometry) return null

  return (
    <div
      style={{
        background: 'white',
        borderRadius: 3,
        position: 'absolute',
        left: `${geometry.x}%`,
        top: `${geometry.y + geometry.height}%`,
        color: 'black'
      }}
    >
      <input
        onChange={e => props.onChange({
          ...props.annotation,
          data: {
            ...props.annotation.data,
            text: e.target.value
          }
        })}
        style={{ color: 'black' }}
      />
      <button onClick={props.onSubmit}>Submit</button>
    </div>
  )
}

function renderHighlight ({ annotation, active }: { annotation: any, active: boolean }) {
  const { geometry } = annotation
  if (!geometry) return null

  return (
    <div
      key={annotation.data.id}
      className={`p-2 ${active ? 'shadow-lg' : ''}`}
      style={{
        border: '1px solid black',
        boxShadow: active ? '0 0 20px 20px rgba(255, 255, 255, 0.3) inset' : ''
      }}
    >
      Custom Highlight
    </div>
  )
}

function renderOverlay () {
  return (
    <div
      style={{
        background: 'rgba(0, 0, 0, 0.3)',
        color: 'white',
        padding: 5,
        pointerEvents: 'none',
        position: 'absolute',
        top: 5,
        left: 5
      }}
    >
      Custom Overlay
    </div>
  )
}

function renderContent ({ annotation }: { annotation: any }) {
  const { geometry } = annotation
  return (
    <div
      key={annotation.data.id}
      style={{
        background: 'black',
        color: 'white',
        padding: 10,
        position: 'absolute',
        fontSize: 12,
        left: `${geometry.x}%`,
        top: `${geometry.y + geometry.height}%`
      }}
    >
      {annotation.data && annotation.data.text}
    </div>
  )
}
