import { Col, Row } from 'react-bootstrap';
import { ButtonMotion } from '../buttons';
import { memo, useState } from 'react';
import 'src/assets/styles/hint-popup.scss';

interface IHint {
  hint: string | undefined,
  score: number
}

function InstructionsPopup({ description, close }: { description: string | undefined, close: () => void }) {
  const [hints, setHints] = useState<IHint[]>([]);
  const [selectedScore, setSelectedScore] = useState(25);
  const [currentHint, setCurrentHint] = useState('');

  const addHint = () => {
    setHints([...hints, { hint: currentHint, score: selectedScore }]);
    setCurrentHint('');
    setSelectedScore(25);
  };

  return (
    <>
      <div className='popup-header'>
        <h2>Game instructions</h2>
      </div>
      <div className='instruction-body'>
        <Row>
          <Col md={12}>
            <div className="description">
              {description}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonMotion variant='success'
                          onClick={close}>Ok</ButtonMotion>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default memo(InstructionsPopup);