import { MouseEvent, useEffect, useState } from 'react';
import { ICellProps } from './types';





export default function WordSearchCell({ hasSelectionStarted, cell, selectionStart, selectionEnd, endBox }:ICellProps) {

  const [isHighlighted, setIsHighlighted] = useState(false);
  const hasStarted = hasSelectionStarted();

  function mouseOver() {
    setIsHighlighted(true);
  }

  useEffect(() => {
    if (isHighlighted && !hasStarted) {
      setIsHighlighted(false);
    }

  }, [hasStarted, isHighlighted]);


  function mouseOut(evt: MouseEvent<HTMLDivElement>) {
    let id = (evt.target as HTMLDivElement).id;
    let startBox = hasSelectionStarted();
    let highlighted = false;
    if (startBox && startBox.id === id) {
      highlighted = true;
    }
    setIsHighlighted(highlighted);
  }

  function mouseUp(evt: MouseEvent<HTMLDivElement>) {
    setIsHighlighted(false);
    selectionEnd(evt);
  }

  let currClass = 'cell' + ((cell.used || isHighlighted) ? ' highlighted' : '');

  return (
    <div className={currClass} id={cell.id}
         onMouseDown={selectionStart}
         onMouseUp={mouseUp}
         onMouseOver={mouseOver}
         onMouseOut={mouseOut}
    >
      {cell.letter}
    </div>
  );
}