// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The loader component to show the spinner
 * ToDo add timme out here and design
 * @constructor
 */
export function NoEditorPermission(){
  return (
    <div className="loader">No Permission to edit</div>
  )
}
