import { AvatarProfileType } from './index';
import { useEffect, useState } from 'react';
import { getCssProps } from '../../../helpers';
import { MyCustomCSS } from '../../avatar/person-styles';

interface AvatarStylePickerProps {
  currentStyle: AvatarProfileType,
  updateCurrentStyle: (currentStyle: AvatarProfileType) => void,
  submitCurrentProfile: (currentStyle: AvatarProfileType) => void,
}

const Genders = ['male', 'female'];
const gendersDegree = 360 / Genders.length;
const Colors = ['FACEC3', 'F8BA96', '9A5B4E'];
const colorsDegree = 360 / Colors.length;
const Heads = [1, 2, 3, 4, 5];
const headsDegree = 360 / Heads.length;

export default function AvatarStylePicker({ currentStyle, updateCurrentStyle, submitCurrentProfile }: AvatarStylePickerProps) {
  const [profile, setProfile] = useState<AvatarProfileType>(currentStyle ?? null);

  useEffect(() => {
    if (currentStyle && currentStyle !== profile) {
      setProfile(currentStyle);
    }
  }, [currentStyle]);

  const updateProfile = (selectedProfile: AvatarProfileType) => {
    setProfile({ ...selectedProfile });
    updateCurrentStyle({ ...selectedProfile });
  }

  const submitProfile = () => {
    submitCurrentProfile({ ...profile });
  }

  return (
    <div>
      {/* Gender */}
      <div className="list-item ps-4 position-relative my-2" key={'gender'}>
        <h4>Gender</h4>
        <div className='palette rounded-circle overflow-hidden position-relative'>
          {Genders.map((currentGender, index) => {
            const cssProps = getCssProps(gendersDegree, false, index);
            return (
              <div
                key={`gender-${currentGender}`}
                className={`position-absolute item ${
                  profile?.gender === currentGender ? 'active' : 'disabled'
                }`}
                style={
                  {
                    '--color': `#${ currentGender === 'male' ? '1D1A55' : 'F0BFDA' }`,
                    '--rotate': `${cssProps.degree}deg`,
                    '--clip-path-degree': `${cssProps.clipPathDeg}%`
                  } as MyCustomCSS
                }
                onClick={(event) => {
                  updateProfile({
                    ...profile,
                    gender: currentGender,
                    wardrobe: {
                      top: null,
                      bottom: null,
                      shoes: null,
                      accessories: null,
                    }
                  })
                }}
              />
            )
          })}
          <button
            type='button'
            className='palette-btn rounded-circle position-absolute d-flex align-items-center justify-content-center border-0'
            onClick={submitProfile}
          >
            <strong>ok</strong>
          </button>
        </div>
      </div>
      {/* Skin */}
      <div className="list-item ps-4 position-relative my-2" key={'gender'}>
        <h4>Skin</h4>
        <div className='palette rounded-circle overflow-hidden position-relative'>
          {Colors.map((currentColor, index) => {
            const cssProps = getCssProps(colorsDegree, false, index);
            return (
              <div
                key={`color-${currentColor}`}
                className={`position-absolute item ${
                  profile?.style?.color === currentColor ? 'active' : 'disabled'
                }`}
                style={
                  {
                    '--color': `#${currentColor}`,
                    '--rotate': `${cssProps.degree}deg`,
                    '--clip-path-degree': `${cssProps.clipPathDeg}%`
                  } as MyCustomCSS
                }
                onClick={(event) => {
                  updateProfile({
                    ...profile,
                    style: {
                      ...profile.style,
                      color: currentColor
                    },
                  })
                }}
              />
            )
          })}
          <button
            type='button'
            className='palette-btn rounded-circle position-absolute d-flex align-items-center justify-content-center border-0'
            onClick={submitProfile}
          >
            <strong>ok</strong>
          </button>
        </div>
      </div>
      {/* Skin */}
      <div className="list-item ps-4 position-relative my-2" key={'gender'}>
        <h4>Heads</h4>
        <div className='palette rounded-circle overflow-hidden position-relative'>
          {Heads.map((currentHead, index) => {
            const cssProps = getCssProps(headsDegree, false, index);
            return (
              <div
                key={`head-${currentHead}`}
                className={`position-absolute item ${
                  profile?.style?.head === currentHead ? 'active' : 'disabled'
                }`}
                style={
                  {
                    '--color': `#${['E77D2E', 'FFF8C8', 'ac7f69', '514463', '4b2922'][currentHead - 1 ?? 0]}`,
                    '--rotate': `${cssProps.degree}deg`,
                    '--clip-path-degree': `${cssProps.clipPathDeg}%`
                  } as MyCustomCSS
                }
                onClick={(event) => {
                  updateProfile({
                    ...profile,
                    style: {
                      ...profile.style,
                      head: currentHead
                    },
                  })
                }}
              />
            )
          })}
          <button
            type='button'
            className='palette-btn rounded-circle position-absolute d-flex align-items-center justify-content-center border-0'
            onClick={submitProfile}
          >
            <strong>ok</strong>
          </button>
        </div>
      </div>
    </div>
  )
}
