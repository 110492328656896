import * as msal from '@azure/msal-browser';

class AzureAuth {
  msalConfig: msal.Configuration;
  app: msal.PublicClientApplication;
  scope: string;
  shouldLogin = false;
  loginCallbacks: Array<() => void> = [];
  onLogInCalled = false;

  constructor() {
    this.msalConfig = {
      auth: {
        clientId: process.env.REACT_APP_AZURE_AUTH_CLIENT_ID || '',
        authority: process.env.REACT_APP_AZURE_AUTH_AUTHORITY || '',
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };
    this.scope = process.env.REACT_APP_AZURE_AUTH_SCOPES || '';
    this.app = new msal.PublicClientApplication(this.msalConfig);
    localStorage.setItem('redirectHandled', 'false');
    this.setupRedirect();
  }

  log(...msgs: unknown[]) {
    if (!process.env.REACT_APP_DEBUG) return;
    console.log(msgs);
  }
  warn(...msgs: unknown[]) {
    if (!process.env.REACT_APP_DEBUG) return;
    console.warn(msgs);
  }

  async setupRedirect() {
    try {
      const tokenResponse = await this.app.handleRedirectPromise();
      this.log('tokenResponse', tokenResponse);
      localStorage.setItem('redirectHandled', `true`);
      if (this.shouldLogin && tokenResponse) {
        try {
          this.loginPopup();
        } catch {
          this.log('failed');
        }
      }
    } catch (e) {
      this.warn('tokenError: ', e);
      localStorage.setItem('redirectHandled', `false`);
      if (this.shouldLogin) {
        try {
          this.loginPopup();
        } catch {
          this.log('failed');
        }
      }
    }
  }

  onLogin() {
    this.onLogInCalled = true;
    this.loginCallbacks.forEach(callback => {
      callback();
    });
    window.location.reload();
  }

  redirectHandled() {
    return localStorage.getItem('redirectHandled') === 'true';
  }

  isLoggedIn() {
    return !!this.getAccount();
  }

  async loginPopup(callback?: () => void) {
    if (!this.redirectHandled()) {
      this.shouldLogin = true;
      if (callback) this.loginCallbacks.push(callback);
      return Promise.reject();
    }
    if (this.isLoggedIn()) {
      this.log('already logged in');
      if (!this.onLogInCalled) this.onLogin();
      return Promise.reject();
    }
    try {
      const loginSuccess = await this.app.loginPopup({
        scopes: [this.scope],
      });
      this.onLogin();
      return loginSuccess;
    } catch (err) {
      console.log(err, 'error ---');
      if (err.errorCode === 'popup_window_error') {
        return this.loginRedirect();
      } else {
        this.warn('loginPopup: ', err);
        return Promise.reject(err);
      }
    }
  }

  async loginRedirect() {
    try {
      return await this.app.loginRedirect({
        scopes: [this.scope],
      });
    } catch (err) {
      this.warn('loginRedirect: ', err);
      return Promise.reject(err);
    }
  }

  getAccount() {
    return this.app.getAllAccounts()[0];
    // return {
    //   environment: "login.windows.net",
    //   homeAccountId: "88dcd3d5-4ed6-48d0-b871-409645b3ee24.255bebb6-afe8-4d45-acc9-dc7ddc9479fe",
    //   idTokenClaims: {
    //     aud: '6947267b-3ab5-4036-aabd-5b7659a54776',
    //     iss: 'https://login.microsoftonline.com/255bebb6-afe8-4d45-acc9-dc7ddc9479fe/v2.0',
    //     iat: 1707212054,
    //     nbf: 1707212054,
    //     exp: 1707215954,
    //     // Add other claims here as needed
    //   },
    //   localAccountId: "88dcd3d5-4ed6-48d0-b871-409645b3ee24",
    //   name: "Test Mentor02",
    //   nativeAccountId: undefined,
    //   tenantId: "255bebb6-afe8-4d45-acc9-dc7ddc9479fe",
    //   username: "test.mentor02@mcast.edu.mt"
    // }
  }

  async logout() {
    localStorage.setItem('logout', 'true');
    this.app.logout();
  }

  async getToken(scope?: string) {
    try {
      this.log('try silent');
      const loginToken = await this.app.acquireTokenSilent({
        account: this.getAccount(),
        scopes: scope ? [scope] : [this.scope],
      });
      // console.log(loginToken.accessToken);
      return loginToken.accessToken;
    } catch (e) {
      this.log('failed silent: ', e);
      if (e instanceof msal.InteractionRequiredAuthError) {
        return await this.getTokenPopup(scope);
      }
    }
    // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImtXYmthYTZxczh3c1RuQndpaU5ZT2hIYm5BdyIsImtpZCI6ImtXYmthYTZxczh3c1RuQndpaU5ZT2hIYm5BdyJ9.eyJhdWQiOiJhcGk6Ly82OTQ3MjY3Yi0zYWI1LTQwMzYtYWFiZC01Yjc2NTlhNTQ3NzYiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8yNTViZWJiNi1hZmU4LTRkNDUtYWNjOS1kYzdkZGM5NDc5ZmUvIiwiaWF0IjoxNzA3MjEyMDU0LCJuYmYiOjE3MDcyMTIwNTQsImV4cCI6MTcwNzIxNjUzNywiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhWQUFBQTNhUEgvSDBoOWM0YVNaTmoxekJpK0U5ejY0REQvUHFySXZ2UTF2V1NCZk9uQ1NWZGpwYlVWT3J3ZkxDcWpQSTQiLCJhbXIiOlsicHdkIl0sImFwcGlkIjoiNjk0NzI2N2ItM2FiNS00MDM2LWFhYmQtNWI3NjU5YTU0Nzc2IiwiYXBwaWRhY3IiOiIwIiwiZW1haWwiOiJUZXN0Lk1lbnRvcjAyQG1jYXN0LmVkdS5tdCIsImZhbWlseV9uYW1lIjoiTWVudG9yMDIiLCJnaXZlbl9uYW1lIjoiVGVzdCIsImlwYWRkciI6IjgxLjE2LjkuMTY0IiwibmFtZSI6IlRlc3QgTWVudG9yMDIiLCJvaWQiOiI4OGRjZDNkNS00ZWQ2LTQ4ZDAtYjg3MS00MDk2NDViM2VlMjQiLCJyaCI6IjAuQVR3QXR1dGJKZWl2UlUyc3lkeDkzSlI1X25zbVIybTFPalpBcXIxYmRsbWxSM1lhQVdrLiIsInNjcCI6ImhlbGxvLnJlYWQiLCJzaWQiOiI1MDIxNGEyZi1jNGZkLTRjNDEtODNlMC1iYTA1NTJmZDI0MmEiLCJzdWIiOiJ6UzFkbWFNSG1zU0pqOGl5aUxfSmlmNEVub3BXSktKRE5UNXpPWGwtb19BIiwidGlkIjoiMjU1YmViYjYtYWZlOC00ZDQ1LWFjYzktZGM3ZGRjOTQ3OWZlIiwidW5pcXVlX25hbWUiOiJUZXN0Lk1lbnRvcjAyQG1jYXN0LmVkdS5tdCIsInVwbiI6IlRlc3QuTWVudG9yMDJAbWNhc3QuZWR1Lm10IiwidXRpIjoiRm84RG1iMGZha0tNZVF0blhRWVdBQSIsInZlciI6IjEuMCJ9.Mc-6pBPRr7CFxlUecSOIE1sZWxL85RAzG37EQu4HGBbKWiPiQCS7qiwbYOCkykvcc-13I83GRu0lhszoI9CUU2hcLw7L1CQsxcKs8xQeMF59TKo901f2S1IX6DzLMvdYYvd3se77r7U1oMG9c5w_ufCs9oZijYtVqoRlVfei4gy9FbZw-wLV4PiOkPyBys3o35qf11sShtOEVCfT66O2mrnjyEFnnPh6kCqDcA9wrd4alp7WcGl-XB9HBgm2cWI7FwxVPn7DgZfaGII1xSK6AfN8o8euQNWA7tp8TYuIi_f-2n349W6lF3JkOhtXsk2K4BKEl-IzZzmDEQH-LKvTWg';
  }

  async getTokenPopup(scope?: string) {
    try {
      this.log('try popup');
      return (
        await this.app.acquireTokenPopup({
          scopes: scope ? [scope] : [this.scope],
        })
      ).accessToken;
    } catch (e) {
      return await this.getTokenRedirect(scope);
    }
  }

  async getTokenRedirect(scope?: string) {
    try {
      this.log('try redirect');
      return await this.app.acquireTokenRedirect({
        scopes: scope ? [scope] : [this.scope],
      });
    } catch (e) {
      return await Promise.reject(new Error(e));
    }
  }
}

export default new AzureAuth();
