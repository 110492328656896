import axios from 'axios';
import { TGame, TGameMechanic } from 'src/@types';
import {
  convertDbDataToGame,
  convertDbDataToGameMechanic,
  createDummyGameMechanic,
} from './utils';
import {
  createGame as createGameV0,
  fetchGameDataById,
  saveGame as storeGameV0,
  storePlayedGamePointsV0,
  getProfile as getProfileData,
} from "../../api/v0";
// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The properties that need to pass to the fetcher of the game
 * data.
 * @param { string } gameId
 *    The game ID variable which data need to fetch from the server side.
 * @param { function } onSuccess
 *    The callback method for successful execution that will be triggered
 *    whenever the data receives successfully.
 * @param { function } onFail
 *    The callback method that will be triggered whenever the server side throws
 *    an error.
 */
interface FetchGameProps {
  gameId: string;
  onSuccess: (data: TGame) => void;
  onFail: (error: Error | string) => void;
}

/**
 * @description
 *    The service method that fetching the game data by the provided game ID
 *    from the server side.
 */
export function fetchGameById({ gameId, onSuccess, onFail }: FetchGameProps) {
  // axios.get(`${PATH_GET_GAME}/${gameId}`)
  //   .then((res:any) => {
  //     onSuccess(convertDbDataToGame({ data: res.data }));
  //   }).catch((err:any) => {
  //   onFail(err.message);
  // })
  // return onSuccess(convertDbDataToGame(GameDataDemo));
  fetchGameDataById(gameId, (gameData) => onSuccess(convertDbDataToGame(gameData)));
}

/**
 * @description
 *    The properties' interface that required to pass to the fetcher of the
 *    game's mechanics
 * @param { TGameMechanic[] } content
 *    The game contents' lists that represent paginated game mechanics.
 * @param { number } page
 *    The page number which data need to be fetched.
 * @param { function } onSuccess
 *    The callback method for successful execution that will be triggered
 *    whenever the data receives successfully.
 * @param { function } onFail
 *    The callback method that will be triggered whenever the server side throws
 *    an error.
 */
interface FetchGameMechanicProps {
  content: TGameMechanic[];
  page: number;
  onSuccess: (data: TGameMechanic | null) => void;
  onFail: (error: Error | string) => void;
}

/**
 * @description
 *    The method is trying to fetch selected game mechanic data from the
 *    provided game contents
 */
export function fetchGameMechanic(
  { content, page, onSuccess, onFail }: FetchGameMechanicProps
) {
  try {
    const selectedContent = content?.[page];
    if (selectedContent) {
      onSuccess(convertDbDataToGameMechanic(
        selectedContent as unknown as Record<string, any>
      ))
    } else {
      onSuccess(null);
    }
  } catch (error) {
    onFail(new Error(error.message));
  }
}

/**
 * @description
 *    The interface for the game creation method
 * @param { TGame } game
 *    The initial data of the game
 * @param { function } onSuccess
 *    The callback method for successful execution that will be triggered
 *    whenever the data receives successfully.
 * @param { function } onFail
 *    The callback method that will be triggered whenever the server side throws
 *    an error.
 */
interface CreateGameProps {
  game: TGame;
  onSuccess: (gameId: string) => void;
  onFail: (error: Error | string) => void;
}

/**
 * @description
 *   The interface for the save creation method
 * @param { TGame } game
 *    The partial updated data of the game
 * @param { function } onSuccess
 *    The callback method for successful execution that will be triggered
 *    whenever the data receives successfully.
 * @param { function } onFail
 *    The callback method that will be triggered whenever the server side throws
 *    an error.
 */
export function createGame(
  { game, onSuccess, onFail }: CreateGameProps
) {
  createGameV0(game, onSuccess);
}

/**
 * @description
 *    The game data save methods properties.
 * @param
 *    Th
 */
interface SaveGameProps {
  game: Partial<TGame>;
  onSuccess: (gameId: string) => void;
  onFail: (error: Error | string) => void;
}

export function saveGame({ game, onSuccess, onFail }: SaveGameProps) {
  storeGameV0(game.id as unknown as string, game, onSuccess);
}

/**
 * @description
 *    The interface for the game mechanic empty/dummy data creation process
 * @param { Record<string, any> } data
 *    The empty data that should be provided by the UI
 * @param { function } onSuccess
 *    The callback method for successful execution that will be triggered
 *    whenever the data receives successfully.
 * @param { function } onFail
 *    The callback method that will be triggered whenever the server side throws
 *    an error.
 */
interface CreateInternalGameMechanicDataProps {
  data: Record<string, any>;
  onSuccess: (gameMechanic: TGameMechanic) => void;
  onFail: (error: Error | string) => void;
}

/**
 * @description
 *    The method is executing empty/dummy game mechanic object creation process
 */
export function createGameMechanic(
  { data, onSuccess, onFail }: CreateInternalGameMechanicDataProps
) {
  try {
    onSuccess(createDummyGameMechanic(data));
  } catch (e) {
    onFail(e)
  }
}

interface SubmitPlayedGameProps {
  data: {
    gameId: string,
    gameName: string,
    userId: string,
    userEmail: string,
    finalPoints: number,
  };
  onSuccess: () => void;
  onFail: (error: Error | string) => void;
}

export function submitPlayedGame({ data, onSuccess, onFail }: SubmitPlayedGameProps) {
  try {
    storePlayedGamePointsV0(data, onSuccess);
  } catch (e) {
    onFail(e)
  }
}

export function getProfile(
  userId: string,
  onSuccess: (profiles: Record<string, any>[]) => void,
  onFail: (error: Error | string) => void,
) {
  try {
    getProfileData(userId, onSuccess, onFail);
  } catch (e) {
    onFail(e);
  }
}
