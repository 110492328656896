import { TInformativeGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the "informative game"
 *    game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function informativeDbToViewConverter(
  dbData: Record<string, any>
): TInformativeGame {
  return {
    audio: dbData.audio ?? null,
    video: dbData.video ?? null,
    image: dbData.image ?? null,
    infoDescription: dbData.infoDescription ?? null
  }
}
