import React, { ReactNode, useEffect, useState } from 'react';
import MsButton from "../assets/MsSymbollockupSigninLight";
import Communicator from "../assets/Communicator";
import Learing from "../assets/Learning";
import VrApp from "../assets/VrApp";
import ArApp from "../assets/ArApp";
import AzureAuth from "../AzureAuth";
import Logo from "../assets/MCASTLogo";
import "./AzureComponent.scss";

const Azure = (
  {
    forceLogin,
    children
  }: { forceLogin:boolean, children:ReactNode }
) => {
  const [loggedIn, setLoggedIn] = useState(AzureAuth.isLoggedIn());
  AzureAuth.app.handleRedirectPromise().catch((err)=> console.log(err));
  useEffect(() => {
    (async () => {
      if (forceLogin) {
        if (!loggedIn) {
          if (!localStorage.getItem("logout")) {
            AzureAuth.log("try login on load");
            loginHandler();
          }
          localStorage.removeItem("logout");
        } else {
          AzureAuth.log("ALREADY LOGGED IN");
        }
      }
    })();
    }, []);

    const setLogin = () => {
      AzureAuth.log("set login to true");
      setLoggedIn(true);
    };

    const loginHandler = async () => {
      try {
        const resp = await AzureAuth.loginPopup(setLogin);
        AzureAuth.log("login handler", resp);
        console.log(resp);
        if (resp) setLogin();
      } catch (e) {
        setLoggedIn(false);
      }
    };


    return (
      <>
        {!loggedIn ? (
          <div className="AppAzureComponent">
            <div className="holderLogos">
              <img
                src={'https://firebasestorage.googleapis.com/v0/b/glms-mcast.appspot.com/o/logos%2FFondi-eu-logo-1.png?alt=media&token=eda96185-d687-4160-a8bf-f8718a3b64da'}
                alt={'fondi-logos'}
                className={"FondiLogo"}
              />
              <img
                src={'https://firebasestorage.googleapis.com/v0/b/glms-mcast.appspot.com/o/logos%2FGovernment%20of%20Malta%20logo%20-%20EN.png?alt=media&token=13ecc4b2-37a1-452b-8c05-5b011553ba38'}
                alt={'maltese-gov-logos'}
                className={"MalteseGovLogo"}
              />
              <div className="holderAzureComponent">
                <Logo className="McastLogoAzureComponent" />

                <h2>
                  Welcome to the <strong>OTIS</strong> platform
                </h2>
                <div className="appIconsAzureComponent">
                  <Communicator />
                  <Learing />
                  <VrApp />
                  <ArApp />
                </div>
                <h3>
                  Sign in with your MCAST credentials and gain access to all the apps
                </h3>
                <button onClick={loginHandler} data-testid="login_button">
                  <MsButton />
                </button>
              </div>
              <img
                src={"/assets/images/login_brand.png"}
                alt={'EU programme'}
                className={"EUProgramme"}
              />
            </div>
          </div>
        ) : children}
        {/*{children}*/}
      </>
    );
};

export default Azure;
