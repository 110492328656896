import { TCalculationGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the calculation game
 *    type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function calculationDbToViewConverter(
  dbData: Record<string, any>
): TCalculationGame {
  return {
    question: dbData.question,
    answer: dbData.answer,
    filePath: dbData.filePath || null
  }
}
