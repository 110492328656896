import { memo } from 'react';

function AvatarHe(){
    return (
        <svg  viewBox="0 0 264 393" fill="none" preserveAspectRatio="xMinYMin meet">
            <path d="M222.584 217.62L164.362 183.795C165.107 184.216 165.399 185.35 165.01 186.711C158.303 210.622 132.514 239.457 102.285 256.92C78.8279 270.463 57.9628 274.06 46.623 267.483L104.845 301.307C116.184 307.917 137.049 304.288 160.507 290.745C190.735 273.282 216.525 244.447 223.232 220.536C223.62 219.175 223.329 218.041 222.584 217.62Z" fill="#FCBF29"/>
            <path d="M161.317 182.953C180.724 194.228 200.131 205.503 219.538 216.778C223.815 217.814 231.429 220.115 239.107 224.586L180.886 190.761C173.207 186.29 165.593 183.989 161.317 182.953Z" fill="#FCBF29"/>
            <path d="M222.584 217.62L164.362 183.795C164.362 183.795 164.297 183.795 164.297 183.763L222.519 217.588C222.519 217.588 222.551 217.588 222.584 217.62Z" fill="#014961"/>
            <path d="M222.519 217.588L164.297 183.763C163.487 183.536 162.742 183.342 162.062 183.147L220.283 216.972C220.964 217.134 221.709 217.361 222.519 217.588Z" fill="#014961"/>
            <path d="M102.285 253.648C79.7027 266.673 59.6151 270.172 48.6965 263.821L106.918 297.646C117.837 303.997 137.924 300.53 160.507 287.473C181.08 275.582 199.515 258.216 210.855 240.915C215.553 233.755 218.987 226.627 220.899 219.888C221.255 218.592 221.028 217.458 220.316 217.037L162.094 183.212C162.807 183.633 163.066 184.735 162.677 186.063C160.701 193.126 156.975 200.675 151.921 208.192C152.99 208.645 154.091 209.131 155.161 209.585C155.355 209.682 155.647 209.779 155.841 209.877C156.262 210.071 156.813 210.298 157.234 210.492C159.016 211.27 160.766 212.047 162.548 212.793C160.798 212.015 159.016 211.237 157.234 210.492C156.327 210.103 155.387 209.682 154.48 209.293C153.638 208.937 152.763 208.548 151.921 208.192C140.549 225.137 122.437 242.017 102.285 253.648ZM207.842 210.719V210.784V210.849V210.816V210.751V210.719Z" fill="#014961"/>
            <path d="M216.428 216.13L158.206 182.272L157.882 182.24L216.104 216.065L216.428 216.13Z" fill="#014961"/>
            <path d="M159.664 182.564C158.725 182.37 158.109 182.24 157.915 182.208C177.322 193.483 196.729 204.757 216.136 216.065C216.331 216.065 216.946 216.194 217.886 216.389C212.41 213.214 206.935 210.039 201.459 206.863C188.597 199.379 175.767 191.927 162.904 184.443C162.612 184.281 162.353 184.119 162.062 183.957C161.997 183.925 161.867 183.86 161.803 183.795C161.641 183.698 161.414 183.568 161.252 183.471C161.09 183.374 160.863 183.244 160.701 183.147C160.345 182.953 160.021 182.694 159.632 182.532L159.664 182.564Z" fill="#FCBF29"/>
            <path d="M219.927 216.842C219.214 216.518 215.78 215.838 215.747 215.838C213.577 215.546 211.633 214.542 210.337 213.862L210.758 214.089C212.443 215.06 214.257 215.773 216.136 216.065C216.136 216.065 216.266 216.065 216.525 216.13C217.011 216.227 217.983 216.421 219.247 216.745C219.473 216.81 219.7 216.842 219.959 216.907C220.089 216.907 220.186 216.972 220.283 217.037C220.186 216.972 220.024 216.875 219.927 216.842Z" fill="#F2BC81"/>
            <path d="M157.882 182.24C156.003 181.948 154.221 181.236 152.504 180.264L210.726 214.088C212.41 215.06 214.225 215.741 216.104 216.065C198.641 205.924 176.901 189.044 157.882 182.24Z" fill="#014961"/>
            <path d="M157.915 182.24C177.192 193.45 196.437 204.628 215.715 215.838C214.16 215.644 212.346 214.963 210.337 213.862C192.776 204.174 169.87 190.34 152.504 180.264C154.189 181.236 156.003 181.948 157.882 182.24H157.915ZM201.459 206.863C188.597 199.379 175.767 191.927 162.904 184.443C162.613 184.281 162.353 184.119 162.062 183.957C161.997 183.925 161.867 183.86 161.803 183.795C161.641 183.698 161.414 183.568 161.252 183.471C161.09 183.374 160.863 183.244 160.701 183.147C160.377 182.953 160.085 182.726 159.761 182.596C160.118 182.694 160.539 182.758 161.025 182.888C161.252 182.953 161.479 182.985 161.738 183.05C161.835 183.05 161.965 183.115 162.062 183.179L219.927 216.81C219.311 216.583 218.339 216.324 217.497 216.162C212.151 213.052 206.805 209.941 201.459 206.831V206.863Z" fill="#F2BC81"/>
            <path d="M161.317 182.953C160.701 182.791 160.118 182.661 159.664 182.564C160.669 183.147 161.997 183.925 163.001 184.508C181.307 195.135 199.58 205.762 217.886 216.389C218.372 216.486 218.923 216.616 219.571 216.778C218.015 215.87 216.493 214.996 214.937 214.089C213.415 213.181 211.859 212.307 210.304 211.399C202.14 206.669 193.975 201.906 185.81 197.176C184.838 196.625 183.575 195.88 182.603 195.297C180.691 194.195 178.132 192.705 176.253 191.603C175.313 191.053 174.049 190.34 173.142 189.789C172.235 189.271 171.004 188.558 170.064 188.007C169.157 187.489 167.958 186.776 167.051 186.258C165.334 185.253 163.163 183.666 161.317 182.92V182.953Z" fill="#FCBF29"/>
            <path d="M220.283 216.972L162.062 183.147C161.673 183.05 161.317 182.985 161.057 182.92L219.279 216.745C219.571 216.81 219.895 216.875 220.283 216.972Z" fill="#014961"/>
            <path d="M164.006 185.091C181.955 195.524 199.904 205.956 217.886 216.389C216.881 215.806 215.553 215.028 214.549 214.445C204.699 208.71 194.817 202.976 184.968 197.241C176.544 192.349 168.088 187.424 159.664 182.532C161.122 183.374 162.58 184.216 164.038 185.059L164.006 185.091Z" fill="#FCBF29"/>
            <path d="M216.557 216.162L158.336 182.337C158.336 182.337 158.271 182.337 158.239 182.305L216.46 216.13C216.46 216.13 216.525 216.13 216.557 216.162Z" fill="#014961"/>
            <path d="M157.915 182.24C177.322 193.515 196.729 204.79 216.136 216.097C196.729 204.822 177.322 193.547 157.915 182.24Z" fill="#FCBF29"/>
            <path d="M157.915 182.24L216.136 216.065C206.416 210.427 196.729 204.79 187.042 199.152C185.908 198.472 184.352 197.597 183.219 196.917C182.085 196.269 180.562 195.362 179.428 194.714C178.294 194.066 176.803 193.191 175.669 192.511C173.466 191.215 170.518 189.53 168.315 188.234C167.246 187.618 165.82 186.776 164.751 186.16C162.71 184.961 160.15 183.018 157.947 182.208L157.915 182.24Z" fill="#FCBF29"/>
            <path d="M210.628 214.024C212.345 215.06 214.225 215.773 216.136 216.097C216.136 216.097 216.266 216.097 216.525 216.162C216.493 216.162 216.46 216.162 216.428 216.13L216.104 216.065C214.192 215.741 212.345 215.028 210.628 214.024Z" fill="#014961"/>
            <path d="M152.407 180.199L210.628 214.024C210.628 214.024 210.693 214.056 210.758 214.088L152.536 180.264C152.536 180.264 152.439 180.231 152.407 180.199Z" fill="#014961"/>
            <path d="M46.2989 246.941C46.5257 246.617 46.6553 246.455 46.6553 246.455L46.4286 246.779C46.4286 246.779 46.3637 246.909 46.2989 246.974V246.941Z" fill="#014961"/>
            <path d="M141.65 152.141C130.634 171.969 116.865 187.586 102.22 196.042C121.627 207.317 141.035 218.592 160.442 229.867C175.086 221.411 188.856 205.827 199.872 185.998C182.408 175.857 159.113 162.314 141.65 152.141Z" fill="#F2BC81"/>
            <path d="M219.117 124.861L160.895 91.0359C160.474 92.3642 159.535 93.6278 158.498 94.2434L216.719 128.068C217.756 127.485 218.696 126.189 219.117 124.861Z" fill="#FFD098"/>
            <path d="M158.239 94.3082C177.646 105.583 197.053 116.858 216.46 128.133C216.557 128.133 216.622 128.068 216.719 128.036L158.498 94.211C158.498 94.211 158.336 94.2758 158.271 94.3082H158.239Z" fill="#FFD098"/>
            <path d="M158.239 94.3082C157.558 94.5674 157.007 94.535 156.586 94.3082L214.808 128.133C215.197 128.36 215.747 128.392 216.428 128.133C197.021 116.858 177.613 105.583 158.206 94.3082H158.239Z" fill="#FFD098"/>
            <path d="M231.882 97.8073L173.661 63.9824C172.106 63.0752 170.194 62.9132 167.991 63.626C167.602 63.7556 167.181 63.9176 166.76 64.112L224.981 97.9369C225.402 97.7425 225.824 97.5805 226.212 97.4509C228.416 96.7381 230.327 96.9001 231.882 97.7749V97.8073Z" fill="#FFD098"/>
            <path d="M166.76 64.112C164.524 78.5297 161.219 89.9342 160.895 91.0034L219.117 124.828C219.441 123.759 222.746 112.355 224.981 97.9369C205.574 86.6619 186.167 75.3869 166.76 64.112Z" fill="#FFD098"/>
            <path d="M58.2218 382.856L0.000267029 348.999L0.0326996 358.265L58.2543 392.123L58.2218 382.856Z" fill="#FCBF29"/>
            <path d="M116.994 163.124C113.528 163.643 109.64 164.226 105.525 166.591L163.746 200.448C167.894 198.051 171.782 197.5 175.216 196.982C177.808 196.625 180.043 196.334 181.987 195.2C162.58 183.925 143.173 172.65 123.766 161.375C121.822 162.509 119.586 162.8 116.994 163.157V163.124ZM170.129 196.463H170L169.87 196.496H169.74L169.611 196.56H169.481L169.352 196.625H169.222L169.092 196.69H168.963L168.833 196.755H168.704L168.574 196.82H168.444L168.315 196.884H168.185L168.056 196.949H168.185L168.315 196.884H168.444L168.574 196.82H168.704L168.833 196.755H168.963L169.092 196.69H169.222L169.352 196.625H169.481L169.611 196.56H169.74L169.87 196.496H170L170.129 196.463ZM114.694 164.226H114.564L114.435 164.258H114.305L114.176 164.323H114.046L113.916 164.388H113.787L113.657 164.453H113.495L113.366 164.517H113.236L113.106 164.582H112.977L112.847 164.647H112.718L112.588 164.712H112.718L112.847 164.647H112.977L113.106 164.582H113.236L113.366 164.517H113.495L113.657 164.453H113.787L113.916 164.388H114.046L114.176 164.323H114.305L114.435 164.258H114.564L114.694 164.226Z" fill="#FFD098"/>
            <path d="M126.261 162.703C126.261 162.703 126.228 162.703 126.196 162.736C144.793 173.557 163.39 184.346 181.987 195.167C184.482 193.742 187.398 190.599 187.366 183.698C187.366 177.088 184.968 172.423 181.048 170.155L122.826 136.33C126.714 138.598 129.112 143.231 129.144 149.841C129.144 156.742 126.228 159.884 123.766 161.342C124.576 161.796 125.386 162.282 126.196 162.736C126.196 162.736 126.228 162.736 126.261 162.703C126.293 162.703 126.196 162.671 126.163 162.638C126.034 162.574 125.872 162.476 125.742 162.412C125.904 162.509 126.099 162.606 126.261 162.703C126.261 162.703 126.585 162.509 127.071 162.185C126.811 162.379 126.552 162.541 126.261 162.703C102.415 179.972 162.256 183.633 180.27 194.098C162.256 183.633 144.275 173.168 126.261 162.703ZM181.534 186.452V186.581L181.437 186.711V186.841L181.339 186.97V186.841L181.437 186.711V186.581L181.534 186.452ZM181.404 193.677H181.339L181.275 193.742H181.21L181.145 193.806H181.08L181.015 193.871H180.951L180.886 193.936H180.821L180.756 194.001H180.691L180.627 194.066H180.562L180.529 194.13H180.465L180.4 194.195H180.465L180.529 194.13H180.562L180.627 194.066H180.691L180.756 194.001H180.821L180.886 193.936H180.951L181.015 193.871H181.08L181.145 193.806H181.21L181.275 193.742H181.339L181.404 193.677Z" fill="#FFD098"/>
            <path d="M160.28 202.425L102.058 168.6L98.7859 170.479L157.007 204.336L160.28 202.425Z" fill="#CC7938"/>
            <path d="M160.215 174.043L101.993 140.186L102.058 168.6L160.28 202.425L160.215 174.043Z" fill="#CC7938"/>
            <path d="M102.22 196.042C88.0942 204.207 74.7456 204.79 63.8918 198.472L122.113 232.297C132.967 238.615 146.316 238.032 160.442 229.867C141.035 218.592 121.627 207.317 102.22 196.042Z" fill="#F2BC81"/>
            <path d="M87.3165 180.264C106.724 191.539 126.131 202.813 145.538 214.088C148.94 210.622 152.828 206.734 157.007 204.304L98.7859 170.479C94.6064 172.877 90.7508 176.797 87.3165 180.264Z" fill="#FFD098"/>
            <path d="M87.3165 180.264C84.6922 182.888 82.4566 185.188 80.5126 186.322C97.6519 196.301 114.791 206.248 131.93 216.194L138.734 220.147C140.678 219.013 142.914 216.713 145.538 214.088C145.408 214.024 145.246 213.926 145.117 213.862C141.942 212.015 138.767 210.168 135.624 208.354C119.521 198.99 103.419 189.627 87.3165 180.264Z" fill="#FFD098"/>
            <path d="M80.5125 186.322C79.2814 187.035 77.953 187.456 76.8838 186.841L135.105 220.698C136.142 221.314 137.503 220.892 138.702 220.18L80.4801 186.355L80.5125 186.322Z" fill="#FFD098"/>
            <path d="M76.9164 186.841L135.138 220.698C136.175 221.313 137.535 220.892 138.767 220.179C140.711 219.045 142.946 216.745 145.571 214.121C148.972 210.654 152.86 206.766 157.04 204.336L98.8182 170.511C94.6387 172.909 90.7833 176.829 87.3489 180.296C84.7246 182.92 82.489 185.221 80.5451 186.355C79.3139 187.067 77.9855 187.489 76.9164 186.873V186.841Z" fill="#CC7938"/>
            <path d="M156.943 175.922C143.886 183.471 133.291 200.934 133.323 214.898C133.323 221.8 136.272 221.573 138.767 220.147C140.711 219.013 142.946 216.713 145.571 214.088C148.972 210.622 152.86 206.734 157.04 204.304L160.312 202.425L160.215 174.043L156.943 175.922Z" fill="#BC6931"/>
            <path d="M181.08 170.155L122.859 136.33C118.517 133.803 112.329 134.257 105.428 138.209L163.649 172.034C170.518 168.081 176.739 167.628 181.08 170.155Z" fill="#CC7938"/>
            <path d="M163.649 172.034L105.428 138.209L101.993 140.186L160.215 174.043L163.649 172.034Z" fill="#CC7938"/>
            <path d="M156.651 94.373L214.873 128.198C214.484 127.971 214.225 127.55 214.16 126.934C211.924 109.665 205.12 101.922 202.95 99.8161L144.728 65.9912C146.931 68.0971 153.703 75.8406 155.938 93.1094C156.035 93.725 156.295 94.1462 156.651 94.373Z" fill="#CC7938"/>
            <path d="M160.215 174.043L101.993 140.186L98.7211 142.097L156.943 175.922L160.215 174.043Z" fill="#BC6931"/>
            <path d="M76.9165 186.841L135.138 220.698C134.069 220.082 133.324 218.43 133.324 214.931L75.1021 181.074C75.1021 184.54 75.8473 186.225 76.9165 186.841Z" fill="#BC6931"/>
            <path d="M76.9165 186.841L135.138 220.698C134.069 220.082 133.324 218.43 133.324 214.931L75.1021 181.074C75.1021 184.54 75.8473 186.225 76.9165 186.841Z" fill="#CC7938"/>
            <path d="M124.446 100.14C123.409 100.756 122.47 100.788 121.789 100.399L180.011 134.224C180.691 134.613 181.631 134.548 182.668 133.965C184.709 132.766 186.394 129.85 186.394 127.485C166.986 116.21 147.579 104.935 128.172 93.6278C128.172 95.9929 126.487 98.9089 124.446 100.108V100.14Z" fill="#FFD098"/>
            <path d="M127.103 91.295C127.783 91.6838 128.204 92.5262 128.204 93.6926C147.612 104.968 167.019 116.243 186.426 127.55C186.426 126.383 186.005 125.541 185.324 125.152C167.861 115.011 144.566 101.468 127.103 91.3275V91.295Z" fill="#FFD098"/>
            <path d="M185.324 125.12L127.103 91.295C126.422 90.9062 125.483 90.9386 124.446 91.5542L182.668 125.379C183.704 124.796 184.644 124.731 185.324 125.12Z" fill="#494949"/>
            <path d="M121.789 100.399L180.011 134.224C179.331 133.835 178.909 132.993 178.909 131.827C178.909 129.461 180.562 126.578 182.635 125.347L124.414 91.5219C122.34 92.7206 120.688 95.6366 120.688 97.9693C120.688 99.1357 121.109 99.9781 121.789 100.367V100.399Z" fill="#494949"/>
            <path d="M156.943 175.922L98.721 142.097C85.6641 149.646 75.0696 167.109 75.102 181.074L133.323 214.931C133.291 200.967 143.886 183.503 156.943 175.954V175.922Z" fill="#BC6931"/>
            <path d="M79.5406 126.092C78.5039 126.707 77.5643 126.74 76.8839 126.351L135.105 160.176C135.786 160.565 136.725 160.5 137.762 159.917C139.836 158.718 141.521 155.802 141.488 153.437C122.081 142.162 102.674 130.887 83.2666 119.612C98.0082 128.165 112.75 136.751 127.492 145.305C132.157 148.026 136.823 150.748 141.488 153.437C141.488 152.271 141.067 151.428 140.387 151.039L82.165 117.214C82.8454 117.603 83.2666 118.446 83.2666 119.612C83.2666 121.977 81.5818 124.893 79.5406 126.092Z" fill="#FFD098"/>
            <path d="M63.8918 198.472L122.113 232.297C121.692 232.07 121.303 231.811 120.915 231.552C114.078 227.178 108.279 219.985 103.97 210.103L45.7481 176.278C50.0572 186.16 55.8568 193.353 62.693 197.727C63.0818 197.986 63.503 198.213 63.9242 198.472H63.8918Z" fill="#FFD098"/>
            <path d="M140.387 151.072L82.1651 117.247C81.4847 116.858 80.5451 116.891 79.5083 117.506L137.73 151.331C138.767 150.748 139.706 150.683 140.387 151.072Z" fill="#494949"/>
            <path d="M76.8839 126.351L135.106 160.176C134.425 159.787 134.004 158.945 134.004 157.778C134.004 155.413 135.656 152.53 137.73 151.299L79.5083 117.474C77.4347 118.672 75.7824 121.556 75.7824 123.921C75.7824 125.088 76.2036 125.93 76.8839 126.319V126.351Z" fill="#494949"/>
            <path d="M52.2604 152.659C52.2604 154.053 51.3856 155.705 50.2192 156.677C67.3585 166.623 84.4977 176.602 101.637 186.581L108.441 190.534C109.64 189.562 110.482 187.91 110.482 186.517C91.0748 175.242 71.6676 163.967 52.2604 152.692V152.659Z" fill="#FFD098"/>
            <path d="M49.6685 157.066C69.0757 168.341 88.4829 179.616 107.89 190.891C108.052 190.793 108.247 190.664 108.409 190.502C89.0013 179.227 69.5941 167.952 50.1869 156.677C50.0249 156.839 49.8305 156.968 49.6685 157.066Z" fill="#FFD098"/>
            <path d="M49.6685 157.066C48.9881 157.454 48.3401 157.487 47.8541 157.228L106.076 191.053C106.562 191.344 107.21 191.279 107.89 190.891C88.4829 179.616 69.0757 168.341 49.6685 157.066Z" fill="#FFD098"/>
            <path d="M65.285 91.2303C55.306 105.972 52.4873 140.25 52.2605 152.659C71.6677 163.934 91.0748 175.209 110.482 186.484C110.709 174.075 113.56 139.797 123.507 125.055C106.043 114.914 82.7482 101.371 65.285 91.2303Z" fill="#FFD098"/>
            <path d="M202.917 99.8484L144.696 66.0236C104.391 88.9623 72.3156 91.1006 65.2849 91.2626L123.506 125.088C130.537 124.926 162.612 122.787 202.917 99.8484Z" fill="#FFD098"/>
            <path d="M47.8542 157.228L106.076 191.053C105.881 190.923 105.687 190.729 105.557 190.502C101.41 182.694 98.2999 174.95 96.2588 167.207C91.1721 148.156 92.4032 129.397 100.049 111.188C114.726 76.0998 148.13 54.7486 157.979 49.0464L99.7579 15.2215C89.9085 20.9237 56.5048 42.2425 41.8279 77.3634C34.214 95.5718 32.9505 114.331 38.0372 133.382C40.1108 141.093 43.1887 148.869 47.3358 156.677C47.4654 156.936 47.6598 157.098 47.8542 157.228Z" fill="#CC7938"/>
            <path d="M85.9881 186.16C84.3358 190.988 83.9146 195.912 84.7894 200.027C86.3121 207.187 96.7771 211.659 103.97 210.103C108.279 219.953 114.078 227.178 120.915 231.552C131.995 238.647 145.797 238.323 160.442 229.867C175.086 221.411 188.856 205.827 199.872 185.998C206.676 173.751 212.41 159.884 216.622 145.078C223.782 138.339 234.247 121.783 235.673 112.905C237.487 102.052 233.243 95.1181 226.212 97.4509C225.824 97.5805 225.402 97.7425 224.981 97.9369C222.746 112.355 219.441 123.759 219.117 124.828C218.696 126.157 217.756 127.453 216.719 128.036C215.229 128.684 214.322 128.165 214.16 126.934C211.924 109.665 205.12 101.922 202.95 99.816C162.645 122.755 130.57 124.893 123.539 125.055C113.56 139.797 110.741 174.075 110.514 186.484C110.514 187.877 109.64 189.53 108.473 190.502C108.311 190.664 108.117 190.793 107.955 190.891C106.983 191.441 106.043 191.312 105.622 190.502C101.475 182.693 98.3647 174.95 96.3235 167.207V167.693C96.3235 169.215 96.3235 170.673 96.4207 172.164C95.6755 172.585 94.9304 173.136 94.2176 173.719C90.7184 176.602 87.8024 181.041 86.0529 186.16H85.9881ZM138.767 220.147C136.272 221.573 133.356 221.799 133.323 214.898C133.291 200.934 143.886 183.471 156.943 175.922L160.215 174.043L163.682 172.067C176.739 164.517 187.366 169.734 187.43 183.698C187.43 190.599 184.514 193.742 182.052 195.167C180.108 196.301 177.873 196.593 175.281 196.949C171.814 197.468 167.926 198.018 163.811 200.416L157.072 204.304C152.893 206.701 149.037 210.622 145.603 214.088C142.979 216.713 140.743 219.013 138.799 220.147H138.767ZM182.668 125.379C184.709 124.18 186.426 125.152 186.426 127.517C186.426 129.883 184.741 132.799 182.7 133.997C180.627 135.196 178.942 134.224 178.942 131.859C178.942 129.494 180.594 126.61 182.668 125.379ZM137.762 151.299C139.803 150.1 141.521 151.072 141.521 153.437C141.521 155.802 139.836 158.718 137.795 159.917C135.721 161.116 134.036 160.144 134.036 157.778C134.036 155.413 135.689 152.53 137.762 151.299Z" fill="#FFD098"/>
            <path d="M163.649 172.034C176.706 164.485 187.333 169.701 187.398 183.665C187.398 190.567 184.482 193.709 182.02 195.135C180.076 196.269 177.84 196.56 175.248 196.917C171.781 197.435 167.894 197.986 163.779 200.384L160.312 202.392L160.215 174.01L163.682 172.034H163.649Z" fill="#CC7938"/>
            <path d="M160.28 198.407L163.747 200.416L160.28 202.425V198.407Z" fill="#FFD098"/>
            <path d="M48.6965 263.821C66.1597 273.962 89.4549 287.505 106.886 297.646C104.812 296.415 103.387 295.152 101.993 293.337C101.216 292.3 100.535 291.134 99.9847 289.87C99.5311 288.898 99.8874 287.214 100.859 285.885C101.054 285.626 101.216 285.367 101.41 285.108C102.901 283.002 104.002 281.544 104.521 280.799C104.747 280.475 104.877 280.313 104.877 280.313L46.6553 246.488C46.6553 246.488 46.5258 246.65 46.299 246.974C45.7806 247.687 42.2815 252.708 42.0871 252.968C41.1475 254.296 41.3095 255.041 41.7631 256.013C43.2211 259.415 45.5862 262.007 48.6965 263.821Z" fill="#F2BC81"/>
            <path d="M46.6553 246.455C64.1186 256.596 87.4137 270.139 104.877 280.28L46.6553 246.455Z" fill="#014961"/>
            <path d="M46.6553 246.455C46.6553 246.455 46.5905 246.52 46.5582 246.585C65.9653 257.86 85.3725 269.135 104.78 280.41C104.812 280.345 104.845 280.313 104.877 280.28C86.3121 269.491 67.7797 258.735 49.2149 247.946C48.3725 247.46 47.4977 246.941 46.6553 246.455Z" fill="#FCBF29"/>
            <path d="M98.3322 289.449C97.5546 290.778 97.2307 292.268 97.6519 293.208C104.553 309.148 130.408 308.144 160.507 290.745C190.735 273.282 216.525 244.447 223.232 220.536C223.62 219.143 223.296 217.976 222.519 217.588C221.709 217.361 220.964 217.166 220.283 216.972C219.894 216.875 219.571 216.81 219.279 216.745C219.506 216.81 219.732 216.842 219.992 216.907C220.931 217.134 221.32 218.398 220.899 219.888C214.451 242.891 189.601 270.69 160.507 287.473C131.509 304.223 106.626 305.195 99.9846 289.838C99.531 288.866 99.8874 287.181 100.859 285.853C101.054 285.594 101.216 285.335 101.41 285.075C101.216 285.367 100.989 285.659 100.73 286.015C100.276 286.631 99.8226 287.279 99.3366 287.959C99.0126 288.445 98.6562 288.931 98.3322 289.449Z" fill="#014961"/>
            <path d="M120.915 231.552V240.462C120.979 250.505 113.625 268.714 104.877 280.28C104.877 280.28 104.747 280.475 104.521 280.766C103.97 281.479 102.901 282.969 101.41 285.075C101.248 285.335 101.054 285.594 100.859 285.853C99.9198 287.181 99.5311 288.866 99.9847 289.838C106.627 305.195 131.509 304.191 160.507 287.473C189.601 270.69 214.419 242.891 220.899 219.888C221.32 218.398 220.931 217.166 219.992 216.907C219.732 216.842 219.506 216.778 219.279 216.745C218.015 216.454 217.076 216.227 216.557 216.13C216.298 216.065 216.169 216.065 216.169 216.065C214.257 215.741 212.378 215.028 210.661 213.991C205.509 210.946 201.459 205.114 200.26 198.699C200.163 198.213 200.099 197.727 200.034 197.208C199.937 196.431 199.904 195.653 199.904 194.876V185.966C188.888 205.794 175.086 221.411 160.442 229.835C145.797 238.291 131.995 238.615 120.915 231.519V231.552Z" fill="#F2BC81"/>
            <path d="M100.73 286.047C100.989 285.691 101.216 285.399 101.41 285.108C102.901 283.002 103.97 281.511 104.521 280.799C104.553 280.734 104.585 280.669 104.65 280.604L104.877 280.28C104.618 280.637 103.484 282.127 101.799 284.492L100.147 286.825C100.341 286.533 100.535 286.274 100.73 285.983V286.047Z" fill="#FCBF29"/>
            <path d="M100.147 286.857C100.147 286.857 42.5731 252.255 42.0872 252.968C41.7632 253.454 40.4347 255.106 40.1107 255.624L98.3323 289.449C98.6887 288.931 99.0127 288.445 99.3367 287.959C99.8227 287.279 99.9199 287.116 100.147 286.857Z" fill="#014961"/>
            <path d="M99.9523 287.149L99.5635 287.732C100.406 286.533 101.151 285.464 101.799 284.525L100.147 286.857C100.147 286.857 100.017 287.052 99.9523 287.149Z" fill="#FCBF29"/>
            <path d="M99.369 287.991C99.5634 287.7 99.7578 287.408 99.9521 287.149L99.5634 287.732C99.5634 287.732 99.4338 287.927 99.369 287.991Z" fill="#FCBF29"/>
            <path d="M46.623 267.483L104.845 301.307C101.605 299.428 99.1747 296.739 97.6195 293.208C97.1983 292.268 97.5223 290.778 98.2998 289.449L40.0783 255.624C39.3007 256.953 38.9767 258.443 39.3979 259.383C40.9207 262.914 43.3831 265.603 46.623 267.483Z" fill="#014961"/>
            <path d="M98.2998 289.482L40.0782 255.657C27.1509 274.384 -0.0969086 317.701 0.000289917 349.032L58.2219 382.857C58.1247 351.526 85.4049 308.209 98.2998 289.482Z" fill="#FCBF29"/>
            <path d="M98.2998 289.482C85.4049 308.208 58.1247 351.526 58.2219 382.857V392.123L263.245 273.768V264.502C263.115 224.359 218.048 216.389 216.104 216.097C217.011 216.292 217.983 216.486 219.247 216.778C219.538 216.842 219.862 216.907 220.251 217.004C220.931 217.166 221.676 217.393 222.486 217.62C223.264 218.009 223.588 219.175 223.199 220.568C216.493 244.479 190.703 273.314 160.474 290.778C130.343 308.176 104.521 309.18 97.6195 293.24C97.1983 292.3 97.5222 290.81 98.2998 289.482Z" fill="#FCBF29"/>
            <path d="M120.915 240.494L62.6931 206.669C62.6931 216.713 55.3708 234.921 46.6553 246.488L104.877 280.313C113.625 268.779 120.947 250.538 120.915 240.494Z" fill="#F2BC81"/>
            <path d="M120.915 231.552L62.6605 197.727L62.693 206.637L120.915 240.494V231.552Z" fill="#F2BC81"/>
            <path d="M182.668 125.379C180.594 126.578 178.942 129.494 178.942 131.859C178.942 134.224 180.627 135.196 182.7 133.997C184.741 132.799 186.426 129.883 186.426 127.517C186.426 125.152 184.741 124.18 182.668 125.379Z" fill="#494949"/>
            <path d="M137.73 151.299C135.656 152.497 133.971 155.413 134.004 157.778C134.004 160.144 135.689 161.116 137.762 159.917C139.836 158.718 141.521 155.802 141.488 153.437C141.488 151.072 139.803 150.1 137.73 151.299Z" fill="#494949"/>
            <path d="M32.8208 173.881L91.0424 207.706C87.8997 205.891 85.4698 203.235 84.7894 200.06C83.9146 195.945 84.3358 191.02 85.9881 186.193L27.7666 152.368C26.1142 157.195 25.693 162.12 26.5678 166.235C27.2482 169.41 29.7105 172.067 32.8533 173.881H32.8208Z" fill="#FFD098"/>
            <path d="M38.0371 133.868C38.0371 135.391 38.0695 136.849 38.1343 138.339L96.3559 172.164C96.2911 170.673 96.2587 169.215 96.2587 167.693V167.207C76.8515 155.932 57.4443 144.657 38.0371 133.349V133.835V133.868Z" fill="#FFD098"/>
            <path d="M96.3559 172.164L38.1343 138.339C37.3891 138.76 36.6438 139.311 35.9311 139.894C32.4319 142.778 29.516 147.184 27.7665 152.335L85.988 186.16C87.7376 181.009 90.6535 176.57 94.1526 173.719C94.8654 173.136 95.6431 172.585 96.3559 172.164Z" fill="#FFD098"/>
            <path d="M212.67 36.9614L154.448 3.13651C143.27 -3.34335 125.742 0.220551 102.22 13.7959L160.442 47.6208C183.964 34.0454 201.492 30.4491 212.67 36.9614Z" fill="#CC7938"/>
            <path d="M160.442 47.6531L102.22 13.7959L101.702 14.1199L159.923 47.9448L160.442 47.6531Z" fill="#CC7938"/>
            <path d="M159.923 47.9448L101.702 14.1199C101.702 14.1199 100.989 14.5087 99.7579 15.2215L157.979 49.0463C159.211 48.3336 159.891 47.9772 159.923 47.9448Z" fill="#CC7938"/>
            <path d="M157.979 49.0463C148.098 54.7486 114.726 76.0673 100.049 111.188C92.4356 129.397 91.1721 148.156 96.2588 167.207C98.3323 174.918 101.41 182.694 105.557 190.502C105.979 191.312 106.918 191.474 107.89 190.891C108.052 190.793 108.247 190.664 108.409 190.502C109.607 189.53 110.45 187.877 110.45 186.484C110.676 174.075 113.528 139.797 123.474 125.055C130.505 124.893 162.58 122.755 202.885 99.816C205.088 101.922 211.86 109.665 214.095 126.934C214.257 128.165 215.197 128.684 216.331 128.198C217.691 127.453 218.631 126.189 219.052 124.828C219.376 123.759 222.681 112.355 224.916 97.9369C227.93 78.5945 228.934 53.8414 218.048 41.4325C207.583 29.4771 188.176 31.5831 160.409 47.6208L159.891 47.9123C159.891 47.9123 159.178 48.3012 157.947 49.0139L157.979 49.0463Z" fill="#CC7938"/>
        </svg>
    )
}

export default memo(AvatarHe)
