import { createContext, ReactNode, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { dispatch, useDispatch, useSelector } from 'src/redux/store';
import { TGame, TGameMechanic, TGameStatus, TGameTooltip } from 'src/@types';
import { IGameView } from 'src/@interfaces';
import { EnumGameStatus } from 'src/@constants';
import {
  PATH_GAME_ID, PATH_CREATE, PATH_GAMES, PATH_PLAY, PATH_EDIT, PATH_MECHANICS
} from 'src/routes/paths';
import {
  getGameById,
  getGameMechanic,
  changeGameEditMode,
  changeGameStatus,
  showTheGameInfoTooltip,
  showTheTimeUp,
  changePage as changeGamePage,
  closeGame as resetGameData,
  createNewGame,
  updateGame as updateGameData,
} from 'src/redux/slices';
import { convertDBDataToGameView } from 'src/services/game/utils';
import { Loading } from 'src/components';
import AzureAuth from "../azure-component/AzureAuth";
import {useAuth} from "../hooks";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    Global type/context for all functional games.
 *    The interface contains all necessary methods/variables/lifecycles for the
 *    games that are recognized by the main controller of the game.
 * @param { Error | string | NaN } error
 *    The error variable that may contain an error object if there is any error
 *    during the fetching process of the game data and UI
 * @param { boolean } isEditMode
 *    The boolean variable that shows if the game is in edit mode or not
 * @param { boolean } isLoading
 *    The boolean variable that represents the game fetching process
 */
export interface GamesBaseInterface {
  // Data
  error: string | Error | null;
  isLoading: boolean;
  isEditMode: boolean;
  gameId: string | undefined;
  game: IGameView | null;
  status: TGameStatus;
  gameTooltip: TGameTooltip | null,

  // Methods
  loadGame: VoidFunction;
  loadGameMechanic: VoidFunction;
  updateGame: (finalGameData: TGame) => void;
  playGame: VoidFunction;
  closeGame: VoidFunction;
  switchToEditMode: VoidFunction;
  createGame: (gameData: TGame) => void;
  changePage: (pageNumber: number) => void;
  showTheGameInfoTooltip: (payload: TGameTooltip | null) => void
  showTheTimeUp:(payload:boolean) => void
}

/**
 * @description - Games base context to create context of games' lifecycle
 */
export const ContextGamesBase = createContext<GamesBaseInterface | null>(null);

/**
 * @description - Games' base lifecycle Provider hook. Contains lifecycle logic
 * @param children - Children components
 * @constructor - Adding listener for game lifecycle
 */
export function GamesBaseProvider({ children }: { children: ReactNode }) {

  // ========================== INTERNAL VARIABLES ========================== //
  /**
   * @description - The global state-based variables and methods
   */
  const dispatch = useDispatch();

  /**
   * @description
   *  Account info
   */
  const { user } = useAuth();

  const navigate = useNavigate();
  // Getting the internal game related state
  const {
    error,
    isLoading,
    isEditMode,
    status,
    game,
    page,
    gameTooltip,
  } = useSelector((state) => state.game);

  /**
   * @description - The web url based variables and methods in order to get and
   * manage the url, and it's params.
   */
  const { [PATH_GAME_ID.replace(':', '')]: gameId } = useParams();

  // ======================================================================== //
  // ========================== LIFECYCLE METHODS =========================== //
  // ======================================================================== //

  /**
   * @description
   *    The method is saving the game mechanic into the game object for the
   *    internal state
   */
  const updateGame = (finalGame: TGame) => {
    if (game) {
      dispatch(updateGameData({ ...finalGame }));
    }
  }

  /**
   * @description
   *    The method is triggering logic to fetch game data and show
   *    that change status of loading game
   */
  const loadGame = () => {
    if (gameId && user) {
      dispatch(getGameById(gameId));
    }
  };

  /**
   * @description
   *    The method is fetching the game mechanic data, based on the provided
   *    page and game data. If there is no such game mechanic its returning null
   */
  const loadGameMechanic = () => {
    if (game && user) {
      dispatch(getGameMechanic(game, page ?? 0));
    }
  };

  /**
   * @description
   *    The method is closing the game and resetting the whole data from the
   *    redux store.
   */
  const closeGame = () => {
    dispatch(resetGameData());
    // navigate(-1);
  };

  /**
   * @description
   *    The method is changing the game editing mode to be editable in order to
   *    load the editing view
   */
  const switchToEditMode = () => {
    if (game) {
      // ToDo add authentication hook and check role based permission for the
      //   edit mode
      dispatch(changeGameEditMode(true));
      dispatch(getGameMechanic(game, 0));
      dispatch(changeGameStatus(EnumGameStatus.new))
      navigate(`/${PATH_GAMES}/${PATH_EDIT}/${game.id}`);
    }
  };

  /**
   * @description
   *    The method that executes logic for a game starting process in this
   *    stage the game is simply will trigger the timer.
   */
  const playGame = () => {
    if (game) {
      dispatch(changeGameStatus(EnumGameStatus.new))
      dispatch(changeGameStatus(EnumGameStatus.playing));
      dispatch(getGameMechanic(game, 0));
      navigate(`/${PATH_GAMES}/${PATH_PLAY}/${game.id}`);
    }
  };

  /**
   * @description
   *    The method is creating the initial game's data in the server.
   * @param { TGame } gameData
   *    The initial game data
   */
  const createGame = (gameData: TGame) => {
    dispatch(
      createNewGame(
        {
          ...gameData,
          created_by: user?.account.username ?? user?.account.localAccountId,
          updated_by: user?.account.username ?? user?.account.localAccountId,
        },
        (gameId: string) => navigate(`/${PATH_GAMES}/${PATH_CREATE}/${PATH_MECHANICS}/${gameId}`)
      )
    );
  };

  /**
   * @description
   *    The method is changing the page number of the game
   * @param {number} pageNumber
   *    The selected page
   */
  const changePage = (pageNumber: number) => {
    dispatch(changeGamePage(pageNumber));
  };

  // ========================= VIEW METHODS/ACTIONS ========================= //

  /**
   * @description
   *    The hook listener for the game selection.
   *    If the selected game ID exists, then trigger the game's data fetching
   *    process through API and store the data into the global state/redux
   */
  useEffect(() => {
    if (user) {
      loadGame();
    }
  }, [gameId, user]);

  /**
   * @description
   *    The hook listener for the game data and page info.
   *    If page or game data changed load game mechanic.
   */
  useEffect(() => {
    loadGameMechanic();
  }, [game?.content, page]);

  /**
   * @description
   *    If any fetching action is still in process, then show the loader
   *    components otherwise show the actual page's UI
   */
  if (isLoading && gameId && gameId !== PATH_CREATE) {
    return <Loading />;
  }

  /**
   * @description
   *    The provider view component
   */
  return (
    <ContextGamesBase.Provider
      value={{
        // Data
        error,
        isLoading,
        status,
        isEditMode,
        gameId,
        gameTooltip,
        game: game
          ? convertDBDataToGameView(game as unknown as Record<string, any>)
          : null,
        // Methods
        updateGame,
        loadGame,
        loadGameMechanic,
        playGame,
        closeGame,
        switchToEditMode,
        createGame,
        changePage,
        showTheGameInfoTooltip,
        showTheTimeUp
      }}
    >
      {children}
    </ContextGamesBase.Provider>
  );
}
