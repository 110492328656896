import { createContext, ReactNode, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  getWorldByCourseId,
  getMiniWorldById,
  getAllGameByMiniWorldId,
  closeMiniWorldGames as onCloseMiniWorldGames,
  closeMiniWorld as onCloseMiniWorld,
  closeWorld as onCloseWorld
} from 'src/redux/slices';
import {
  APP_ROOT_FULL_PATH,
  PATH_CLASS_ID,
  PATH_MINI_WORLD,
  PATH_COURSE_ID,
  PATH_GLMS_PLAYER, PATH_ISLAND
} from 'src/routes/paths';
import { TWorld, TMiniWorld } from 'src/@types';
import { IGameView } from 'src/@interfaces';
import { Loading } from 'src/components';
import {useAuth} from "../hooks";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - Global interface that contains all necessary methods and
 *  variables for the world picker
 * @param { boolean } isLoading
 *  The boolean variable that shows if there is any
 *  fetching action is in process
 * @param { TWorld | NaN } world - The variable contains the corresponded world
 *  object data
 * @param {function} selectWorld -
 * The method variable that is starting to fetch
 *  the world data.
 * @param { function } closeWorld - The method variable that is closing and
 *  resetting the world global state data.
 * @param { TMiniWorld | NaN } miniWorld - The variable shows if there is a
 *  selected mini world or not,
 *  if the variable exists then world is selected if
 *  not then it is null.
 * @param { function } selectMiniWorld - The method variable that is starting to
 *  fetch the world data.
 * @param { function } closeWorld - The method variable that is closing and
 *  resetting the world global state data.
 * @param { IGameView[] | NaN } miniWorldGames - The variable shows the games'
 *  list that is available and can be played.
 */
export interface WorldsBaseInterface {
  isLoading: boolean;
  error: string | Error | null;
  world: TWorld | null;
  selectWorld: () => void;
  closeWorld: () => void;
  miniWorld: TMiniWorld | null;
  selectMiniWorld: (miniWorldId: string) => void;
  closeMiniWorld: VoidFunction;
  miniWorldGames: IGameView[] | null;
  getMiniWorldGames: (miniWorldId: string) => void;
  closeMiniWorldGames: VoidFunction;
  selectGame: (id: string) => void;
  selectIsland: (classId: string, courseId: string, skillId: string) => void;
  addGame: (miniWorldId: string) => void;
  courseId?: string;
  classId?: string;
}

/**
 * @description - Worlds' base context to create context of world lifecycle
 */
export const ContextWorldsBase = createContext<WorldsBaseInterface | null>(null);

/**
 * @description - Worlds' base lifecycle Provider hook. Contains lifecycle logic
 * @param { ReactNode } children - Children components that need to parse
 * @constructor - Adding listener for world lifecycle
 */
export function WorldsBaseProvider({ children }: { children: ReactNode }) {

  // ========================== INTERNAL VARIABLES ========================== //
  /**
   * @description - The global state based variables and methods
   */
  const dispatch = useDispatch();
  const {
    world,
    miniWorld,
    miniWorldGames,
    isLoading,
    error
  } = useSelector((state) => state.world);

  const navigate = useNavigate();
  const { user } = useAuth();

  /**
   * @description - The web url based variables and methods in order to get and
   * manage the url, and it's params.
   */
  const {
    [PATH_CLASS_ID.replace(':', '')]: classId,
    [PATH_COURSE_ID.replace(':', '')]: courseId,
    [PATH_ISLAND.replace(':', '')]: miniWorldId
  } = useParams();

  // ========================== LIFECYCLE METHODS =========================== //

  /**
   * @description - The method is triggering the API and getting the world data
   *  as an object and storing it in the internal state/redux in the "world"
   *  variable
   */
  const selectWorld = () => {
    if (classId && courseId) {
      dispatch(getWorldByCourseId(courseId));
    }
  };

  /**
   * @description - The method is resetting the global world data, in order to
   *  execute action of closing the world
   */
  const closeWorld = () => {
    dispatch(onCloseWorld());
  };

  /**
   * @description - The method is triggering fetching the mini world data from
   *  the server and storing it into the global state/redux variable
   * @param {string} miniWorldId - The selected mini world id
   */
  const selectMiniWorld = (miniWorldId: string) => {
    dispatch(getMiniWorldById(miniWorldId));
  };

  /**
   * @description - The mini world closing action method that triggers resetting
   *  the global state/redux mini world data and games data
   */
  const closeMiniWorld = () => {
    dispatch(onCloseMiniWorld());
  };

  /**
   * @description - The method is triggering fetching process of the mini world
   *  games list from the server and storing them into the global state/redux
   * @param {string} miniWorldId - The mini world's ID
   */
  const getMiniWorldGames = (miniWorldId: string) => {
    if (user) {
      dispatch(getAllGameByMiniWorldId(miniWorldId, user));
    }
  };

  /**
   * @description - The method is triggering action to reset the global/redux
   *  state for the games' list
   */
  const closeMiniWorldGames = () => {
    dispatch(onCloseMiniWorldGames());
  };

  /**
   * @description
   *    The method is redirecting the user into the proper game
   * @param { string } id
   *    The game's ID where need to redirect the user
   */
  const selectGame = (id: string) => {
    navigate(
      `/${PATH_GLMS_PLAYER.games.game.get(id)}`
    );
  };

  /**
   * @description
   *    The method is redirecting the user into the skill island
   * @param { string } id
   *    The game's ID where need to redirect the user
   */
  const selectIsland = (classId: string, courseId: string, skillId: string) => {
    if (user) {
      dispatch(getAllGameByMiniWorldId(skillId, user));
      selectMiniWorld(skillId);
      setTimeout(() => navigate(
        `/${PATH_GLMS_PLAYER.classes.islands.get(classId, courseId, skillId)}`
      ), 700);
    }
  };

  /**
   * @description
   *    The method is redirecting the page to the create page URL, where user
   *    can create the game.
   * @param { string } miniWorldId
   *    The mini world id where the component needs to be changed
   */
  const addGame = (miniWorldId: string) => {
    navigate(
      `/${PATH_GLMS_PLAYER.games.root}/${PATH_GLMS_PLAYER.games.game.create.root}/${miniWorldId}`
    );
  };


  // ========================= VIEW METHODS/ACTIONS ========================= //

  /**
   * @description - The hook listener for the url path changes.
   * If the url changed, then need to check if proper course ID and class ID are exists so the
   * component can fetch the world and games data.
   */
  useEffect(() => selectWorld(), [classId, courseId]);

  useEffect(() => {
    if (miniWorldId && user) {
      dispatch(getAllGameByMiniWorldId(miniWorldId, user));
    }
  }, [miniWorldId, user]);

  /**
   * @description - If any fetching action is still in process then show the
   * loader component
   */
  //if (isLoading) return <Loading />;

  /**
   * @description - The provider view
   */
  return (
    <ContextWorldsBase.Provider
      value={{
        isLoading,
        error,
        classId,
        courseId,
        world,
        selectWorld,
        closeWorld,
        miniWorld,
        selectMiniWorld,
        closeMiniWorld,
        miniWorldGames,
        getMiniWorldGames,
        closeMiniWorldGames,
        selectGame,
        addGame,
        selectIsland
      }}
    >
      {children}
    </ContextWorldsBase.Provider>
  );
}
