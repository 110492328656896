// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';

import { item } from '../../components/avatar';


// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The game base state variables.
 */
export type AvatarType = { head:string, tops: string, accessories: string[], bottoms: string, shoes: string, gender: string,  skin: string }
export type WardrobeType = {
  tops: item[], accessories: item[],
  bottoms: item[], shoes: item[]
}
export type PersonStylesType = { gender: string[], skin: string[], head: string[]  }

export type UserState = {
  avatar: AvatarType,
  avatarItems: { wardrobe: WardrobeType, personStyles: PersonStylesType },
};

/**
 * @description - The initialization of the game state
 */
const userInitialState: UserState = {
  avatar: { tops: '', accessories: [], bottoms: '', shoes: '', gender: '1D1A55',  skin:'FACEC3',  head: 'E77D2E' },
  avatarItems: {
    wardrobe: {
      tops: [],
      accessories: [],
      bottoms: [],
      shoes: []
      // tops: [{ url: 'shirt', price: 50 }, { url: 'shirt-dark', price: 50 }],
      // accessories: [{ url: 'glasses', price: 50 }],
      // bottoms: [{ url: 'trousers', price: 50 }],
      // shoes: [{ url: 'shoes-black', price: 50 }]
    },
    personStyles: {
      // gender: ['1D1A55', 'F0BFDA', 'F9AE00'],
      gender: ['1D1A55', 'F0BFDA'],
      skin: ['F8BA96', '9A5B4E', 'FACEC3'],
      head: ['E77D2E', 'FFF8C8', 'ac7f69', '514463', '4b2922'],
    }
  }
};

/**
 * @description - The slices for the game.
 */
const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    selectAvatarItem(state, action) {
      state.avatar = { ...state.avatar, ...action.payload };
    }
  }
});
// The reducers
export default userSlice.reducer;

// State base actions
export const {} = userSlice.actions;

// ========================================================================== //
// ================================ METHODS ================================= //
// ========================================================================== //


export const selectAvatarItem = (payload: Partial<AvatarType>) => {
  dispatch(userSlice.actions.selectAvatarItem(payload));
};