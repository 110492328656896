import { motion } from 'framer-motion';
import type { FC } from 'react';
import { memo } from 'react';
import sandClock from 'src/assets/images/gamification/sand-clock.svg';
import CloseBtn from '../../assets/svg/buttons/close-btn';
import { showTheTimeUp } from '../../redux/slices';

const TimeUpPopup: FC = () => {
  const handleClose = () => {
    showTheTimeUp(false);
    window.location.reload();
  };
  return (
    <motion.div
      className='popup-wrapper time-up'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='time-up-popup'>
        <CloseBtn close={handleClose} />
        <img src={sandClock} alt='sand clock' />
        <h3>Time's up!</h3>
      </div>
    </motion.div>
  );
};

export default memo(TimeUpPopup);