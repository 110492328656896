import { memo } from 'react';
import { ButtonMotion } from '../../../components';

function DeleteBtn({ onClick }: { onClick: () => void }) {
  return (
    <ButtonMotion variant='delete' onClick={onClick} className='quiz-delete-option-btn'>
      <svg viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46 92C71.4051 92 92 71.4051 92 46C92 20.5949 71.4051 0 46 0C20.5949 0 0 20.5949 0 46C0 71.4051 20.5949 92 46 92Z" fill="#FF0000"/>
        <path d="M62.3995 32.8792H30.9095C30.5298 32.8792 30.1655 33.0428 29.9185 33.333C29.6685 33.6202 29.5573 33.9999 29.6129 34.3734L34.8612 71.1117C34.9538 71.76 35.5064 72.2416 36.1609 72.2416H57.1542C57.8087 72.2416 58.3613 71.76 58.454 71.1117L63.7023 34.3734C63.7579 33.9968 63.6436 33.6171 63.3966 33.333C63.1466 33.0428 62.7854 32.8792 62.4056 32.8792H62.3995ZM56.0119 69.6175H37.297L32.4254 35.5034H60.8898L56.015 69.6175H56.0119Z" fill="white"/>
        <path d="M67.6478 27.6309H54.527V21.0705C54.527 20.345 53.9404 19.7584 53.2149 19.7584H40.0941C39.3686 19.7584 38.782 20.345 38.782 21.0705V27.6309H24.3491C23.6236 27.6309 23.037 28.2175 23.037 28.943C23.037 29.6685 23.6236 30.255 24.3491 30.255H67.6478C68.3733 30.255 68.9599 29.6685 68.9599 28.943C68.9599 28.2175 68.3733 27.6309 67.6478 27.6309ZM41.4062 22.3826H51.9028V27.6309H41.4062V22.3826Z" fill="white"/>
      </svg>
    </ButtonMotion>
  );
}

export default memo(DeleteBtn);