//row.js


import WordSearchCell from './word-search-cell';
import { ICell, ICellProps } from './types';

interface IRowProps extends Omit<ICellProps, 'cell'> {
  row: ICell[] | string[],
  endBox: ICell | null
}

export default function WordSearchRow({ row, selectionStart, selectionEnd, hasSelectionStarted, endBox }: IRowProps) {
  return (
    <div className='row-el'>
      {row.map((item: ICell | string, i: number) => {
        return (<WordSearchCell endBox={endBox} cell={item as ICell} key={i} selectionStart={selectionStart}
                                selectionEnd={selectionEnd}
                                hasSelectionStarted={hasSelectionStarted}
        />);
      })}
    </div>
  );
}


