import {barListItems} from './constants';
import 'src/assets/styles/bar-menu.scss'
import ToggleButton from '../toggle-button';
import { useEffect, useState } from 'react';
import { getProfile } from "../../services/profile";
import AzureAuth from 'src/azure-component/AzureAuth';
import {getExpenses, getScores} from "../../api/v0";
import BarMenuContent from './bar-menu-content';

export default function BarMenu() {
  const [openMenu, setOpenMenu] = useState(false)
  const [profile, setProfile] = useState<any | null>(null);
  const account = AzureAuth.getAccount();
  const [coins, setCoins] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const parseCurrentPoints = (finalData: Record<string, any>[]) => {
    const finalPoints = finalData.reduce((acc, curr) => +curr.finalPoints + acc, 0);
    setCoins(finalPoints);
  };
  const parseExpenses = (finalData: Record<string, any>[]) => {
    let totalExpenses = 0;
    finalData.map((data) => {
      totalExpenses += data.cost;
    })

    setExpenses(totalExpenses);
  }
  useEffect(() => {
    if (!profile) {
      getProfile(account.localAccountId, (data) => setProfile(data?.[0] ?? null), console.log);
      getScores(account.localAccountId, parseCurrentPoints);
      getExpenses(account.localAccountId, parseExpenses);
    }
  }, []);
  const getValue = (index: number) => {
    switch (index) {
      case 0:
        return coins - expenses;
      case 1:
        return profile?.badges;
      case 2:
        return profile?.progress;
      case 3:
        return profile?.notifications;
      default:
        return ''
    }
  }
  return (
    <div className='bar-menu d-flex justify-content-between flex-wrap position-relative'>
      {barListItems.map((item, index ) => <div className='d-flex list-item col-auto' key={item.title}>
        <div>
          <img src={`/assets/images/bar-menu-icons/${item.svg}`} className="pe-3 list-lg-icon" alt='icon'/>
        </div>
        <div><h4 className="text-nowrap fs-5 list-title"><strong>{item.title}</strong></h4>
          <div>#
            <img src={`/assets/images/bar-menu-icons/${item.svg}`} className="ps-1 list-sm-icon" alt='icon'/>
            {getValue(index)}
          </div>
        </div>
      </div>)}
      <BarMenuContent isOpen={openMenu} badges={profile?.badges} notifications={profile?.notifications}/>
      <ToggleButton onToggle={() => setOpenMenu(prev => !prev)}/>
    </div>
  );
}