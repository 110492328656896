import { TGameLevel, TGameSkillsCategory, TGameTypeCategory } from '../@types';

/**
 * @description - The game category mechanic types that are existing in the GLMS
 *  platform.
 */
export enum EnumGameMechanicCategory {
  quiz_single_choice = 'quiz_single_choice',
  quiz_multi_choice = 'quiz_multi_choice',
  quiz_true_false = 'quiz_true_false',
  crossword = 'crossword',
  drag_drop_text = 'drag_drop_text',
  drag_drop_image = 'drag_drop_image',
  drag_drop_text_to_image = 'drag_drop_text_to_image',
  mark_spot = 'mark_spot',
  jumble_words = 'jumble_words',
  memory = 'memory',
  essay = 'essay',
  hotspot = 'hotspot',
  calculation = 'calculation',
  fill_in_game = 'fill_in_game',
  scramble_sentence = 'scramble_sentence',
  simulation_dialogue = 'simulation_dialogue',
  simulation_game = 'simulation_game',
  highlight_word_game='highlight_word_game',
  word_search='word_search',
  informative_game='informative_game'
}

/**
 * @description - The game skills category types that are existing in the GLMS
 *  platform.
 */
export enum EnumGameSkillsCategory {
  math = 'math',
  science = 'science',
  maltese = 'maltese',
  english = 'english',
}

/**
 * @description - The variable that represents the game status of the playable
 *  data.
 *
 *  new // if the game is new for the user, and he does not even open it.
 *  opened // if the user just opened the game info without starting it.
 *  started // if the user started to play
 *  aborted // if for any reason the game was aborted on the user side
 *  paused // if the user paused the game
 *  succeeded // if the user successfully completed the game
 *  failed // if the user played but failed the game
 */
export enum EnumGameStatus {
  new = 'new',
  playing = 'playing',
  aborted = 'aborted',
  paused = 'paused',
  succeeded = 'succeeded',
  failed = 'failed',
}

/**
 * @description - The game level number represented as enum. The object that
 *  contains the difficulty of the game.
 *
 *  entry = 0 // Entry level difficulty
 *  intermediate = 1 // Intermediate level difficulty
 *  professional = 2 // Professional level difficulty
 */
export enum EnumGameLevel {
  first = 'first',
  second = 'second',
  third = 'third'
}

export enum EnumGameLevelNumbers {
  entry = 0,
  first = 1,
  second = 2,
  third = 3
}

/**
 * @description - The game type represented as enum.
 *
 *  Core
 *  Mini game
 */
export enum EnumGameType {
  core = 'core',
  mini = 'mini'
}

/**
 * @description
 *    The empty/dummy data for the game object
 */
export const NewGameEmptyData = {
  id: '',
  mini_world_id: '',
  course_id: null,
  created_by: 'admin',
  updated_by: null,
  created_at: new Date(),
  updated_at: new Date(),
  title: '',
  level: EnumGameLevel[EnumGameLevel.first] + 1 as unknown as TGameLevel,
  skill: EnumGameSkillsCategory as unknown as TGameSkillsCategory,
  type: EnumGameType as unknown as TGameTypeCategory,
  description: '',
  points: 100,
  content: [],
  progress: 0,
};

export enum EnumGamePublicationStatus {
  created = 'new',
  confirmed = 'confirmed',
  reviewed = 'reviewed',
  published = 'published',
  edit = 'edit',
}
