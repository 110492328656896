import { TWordSearchGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the quiz game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function wordSearchDbToViewConverter(dbData: Record<string, any>): TWordSearchGame {
  return {
    wordList: dbData.wordList,
  }
}
