// Redux slices
import { createSlice } from '@reduxjs/toolkit';
// Local dispatch
import { dispatch } from 'src/redux/store';
// Local @types
import {TWorld, TMiniWorld, TypeUserInfo} from 'src/@types';
import { IGameView } from 'src/@interfaces';
// API services
import {
  fetchWorldByCourseId,
  fetchAllGamesByMiniWorldId,
  fetchMiniWorldById
} from 'src/services';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The form state type of the slice
 */
type WorldState = {
  isLoading: boolean;
  error: Error | string | null;
  world: TWorld | null;
  miniWorld: TMiniWorld | null;
  miniWorldGames: IGameView[] | null;
};

/**
 * @description - The initialization of the game state
 */
const worldInitialState: WorldState = {
  isLoading: false,
  error: null,
  world: null,
  miniWorld: null,
  miniWorldGames: null,
}

/**
 * @description - The slices for the game.
 */
const worldSlice = createSlice({
  name: 'world',
  initialState: worldInitialState,
  reducers: {
    // START LOADING
    startLoading(state) { state.isLoading = true },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET & CLOSE WORLD
    getWorldSuccess(state, action) {
      state.isLoading = false;
      state.world = action.payload;
    },
    closeWorldSuccess(state) {
      state.isLoading = false;
      state.world = null;
      state.miniWorld = null;
      state.miniWorldGames = null;
    },
    getMiniWorldSuccess(state, action) {
      state.isLoading = false;
      state.miniWorld = action.payload;
    },
    closeMiniWorldSuccess(state) {
      state.isLoading = false;
      state.miniWorld = null;
      state.miniWorldGames = null;
    },
    getAllGamesSuccess(state, action) {
      state.isLoading = false;
      state.miniWorldGames = action.payload;
    },
    closeMiniWorldGamesSuccess(state) {
      state.isLoading = false;
      state.miniWorldGames = null;
    },
  }
});
// The reducers
export default worldSlice.reducer;

// State base actions
export const {} = worldSlice.actions;

/**
 * @description - The method is fetching world data from the server and storing
 * it in the internal state
 * @param {string} courseId - The parameter is a course ID which is the selected
 *  course and the world
 */
export function getWorldByCourseId(courseId: string) {
  return () => {
    dispatch(worldSlice.actions.startLoading());
    try {
      fetchWorldByCourseId({
        courseId,
        onSuccess: (worldData: TWorld) =>
          dispatch(worldSlice.actions.getWorldSuccess(worldData)),
        onFail: (error) =>
          dispatch(worldSlice.actions.hasError(error)),
      });
    } catch (error) {
      dispatch(worldSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is getting the mini world data by mini world id
 * @param {string} miniWorldId - The mini world's ID parameter
 */
export function getMiniWorldById(miniWorldId: string) {
  return () => {
    dispatch(worldSlice.actions.startLoading());
    try {
      fetchMiniWorldById({
        id: miniWorldId,
        onSuccess: (miniWorldData: TMiniWorld) =>
          dispatch(worldSlice.actions.getMiniWorldSuccess(miniWorldData)),
        onFail: (error) =>
          dispatch(worldSlice.actions.hasError(error)),
      });
    } catch (error) {
      dispatch(worldSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method is getting the mini world's games by the ID
 * @param {string} miniWorldId - The mini world ID parameter
 */
export function getAllGameByMiniWorldId(miniWorldId: string, user: TypeUserInfo) {
  return () => {
    dispatch(worldSlice.actions.startLoading());
    try {
      fetchAllGamesByMiniWorldId({
        miniWorldId,
        user,
        onSuccess: (gamesListData: IGameView[]) => {
          dispatch(worldSlice.actions.getAllGamesSuccess(gamesListData))
        },
        onFail: (error) =>
          dispatch(worldSlice.actions.hasError(error)),
      });
    } catch (error) {
      dispatch(worldSlice.actions.hasError(error));
    }
  }
}

/**
 * @description - The method triggers closing action for the world state
 */
export function closeMiniWorld() {
  return () => {
    dispatch(worldSlice.actions.closeMiniWorldSuccess());
  }
}

/**
 * @description - The method trigger closing action for the world state
 */
export function closeWorld() {
  return () => {
    dispatch(worldSlice.actions.closeWorldSuccess());
  }
}

/**
 * @description - The method trigger closing action for the mini world games
 * state.
 */
export function closeMiniWorldGames() {
  return () => {
    dispatch(worldSlice.actions.closeMiniWorldGamesSuccess());
  }
}
