import { usePreview } from 'react-dnd-preview'
import { FC, memo, ReactNode } from 'react';

interface IItem{
  index:number,
  element:ReactNode
}

const DndPreview:FC<{children:ReactNode}> = ({children}) => {
  const preview = usePreview()
  if (!preview.display) {
    return null
  }
  const { item, style} = preview;

  return <div style={style}>{(item as IItem).element}</div>
}

export default memo(DndPreview)