import { Col, Row } from 'react-bootstrap';
import 'src/assets/styles/drop-text-to-image-game.scss'
import { useGameMechanic } from 'src/hooks';
import GameNotFound from "../../../not-found";
import React from "react";
import { TMarkSpotGame } from "./types";
import {ImageAnnotation} from "../../../../components";

export default function DragDropTextToImage(){
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
    points
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const { annotations, description, filePath } = gameMechanic.content as TMarkSpotGame;

  return (
      <div className='drop-text-to-image'>
        <Row>
          <Col md={6}>
            {filePath && (<ImageAnnotation
              annotations={annotations ?? []}
              onSubmit={(annotations: any[]) => {}}
              image={filePath}
              viewMode={true}
            />)}
          </Col>
          <Col md={6}>
            <p>{description}</p>
          </Col>
        </Row>
      </div>
  )
}