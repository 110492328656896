import { TGameMechanic } from "src/@types";
import { EnumGameMechanicCategory } from "src/@constants";

export const QuizGameData = (
  category: EnumGameMechanicCategory.quiz_true_false | EnumGameMechanicCategory.quiz_single_choice | EnumGameMechanicCategory.quiz_multi_choice,
): TGameMechanic => ({
  title: '',
  description: '',
  category: category,
  points: 100,
  content: {
    filePath: null,
    question: '',
    options: [
      {
        title: '',
        is_correct: false,
      },
      {
        title: '',
        is_correct: false,
      },
    ],
  }
});
