import { EnumGameMechanicCategory } from "src/@constants";
export const MemoryGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.memory,
  points: 100,
  content: {
    cards: []
  }
}
