import { memo, ReactNode } from 'react';
import { useDrop } from 'react-dnd';
import classnames from 'classnames';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

interface DropItemProps {
  onDrop: (item: any) => void;
  children: ReactNode;
}

/**
 * @description
 *    The drop component for the drag and drop action
 * @constructor
 */
function DropItemComponent({ onDrop, children }: DropItemProps) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'div',
    drop(_item, monitor) { onDrop(_item) },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return (
    <span
      ref={drop}
      className={classnames(`drop-area`, { 'is-over': isOver })}
    >
      {children}
    </span>
  );
}

export const DropItem = memo(DropItemComponent);