import { TQuizGame } from './types';
import React, {memo, useEffect, useRef, useState} from 'react';
import { ButtonMotion, Uploader } from 'src/components';
import { Col, Row } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { checkIsMobile } from '../../../../helpers';
import { TGameMechanicContent } from '../../../types';
import TinyMceEditor from '../../../../components/tiny-mce-editor';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The true-false component's input properties' interface
 * @param { TQuizGame } gameData
 *    The quiz game's data
 */
interface TrueFalseProps {
  onContentChange: (currentData: TGameMechanicContent) => void;
  data: TQuizGame;
  gameId:string | null
}

function TrueFalseEditor(
  {
    data = {
      question: '',
      options: [],
      filePath: null
    },
    onContentChange,
    gameId
  }: TrueFalseProps
) {



  const isMobile = checkIsMobile();

  const { question, options, filePath } = data;

  /**
   * @description
   *    The method is building the final TQuizGame data and executing parent
   *    method for the final data update.
   *    Logic is simple: If the input value is string then it's a question
   *    value, and we need to update the question content only, but if the
   *    number which represents the index of the true false buttons then update
   *    only the options' part.
   * @param value
   */
  const handleDataChange = (value: string | number) => {
    if (data) {
      const finalData = {
        ...(typeof value === "string"
          ? {...data, question: value}
          : {
            ...data,
            options: [
              {is_correct: value === 0, title: 'True'},
              {is_correct: value === 1, title: 'False'},
            ],
          }),
      };

      onContentChange(finalData);
    }
  }

  return (
    <div className='quiz-editor'>
      <Row>
        <Col md={4} className='mb-3 mb-md-0'>
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <Uploader gameId={gameId} currentUrl={filePath} onGameMechanicContentChange={filePath => onContentChange({...data, filePath})} key={Math.random()} />
          </
            DndProvider>
        </Col>
        <Col md={8}>
          <TinyMceEditor handleChange={handleDataChange} value={question} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className='btn-wrapper with-count'>
            <div>
              <ButtonMotion
                className={options?.[0]?.is_correct
                  ? 'active'
                  : ''
                }
                variant='outline-success'
                onClick={() => handleDataChange(0)}
              >
                Click for True
              </ButtonMotion>
              <ButtonMotion
                className={options?.[1]?.is_correct
                  ? 'active'
                  : ''
                }
                variant='outline-danger'
                onClick={() => handleDataChange(1)}
              >
                Click for False
              </ButtonMotion>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default memo(TrueFalseEditor)
