import {ChangeEvent, memo, useEffect, useState} from 'react';
import CloseBtn from '../../assets/svg/buttons/close-btn';
import { useEditGame } from '../../hooks';
import { ButtonMotion } from '../buttons';
import 'src/assets/styles/buy-hints-popup.scss';
import { IHint } from './hint-interface';
import Coin from '../../assets/svg/coin';
import { getScores, createExpense, getExpenses } from "../../api/v0";
import AzureAuth from "../../azure-component/AzureAuth";

function BuyHintPopup({ currentHints = [] }: { currentHints?: IHint[] }) {
  const [hints, setHints] = useState<IHint[]>(currentHints.map((hintData, index) => ({ ...hintData, id: index })));
  const [toOpen, setToOpen] = useState<number[]>([]);
  const [openHint, setOpenHint] = useState<IHint | null | undefined>(null);
  const [hintCost, setHintCost] = useState(0);
  const [coins, setCoins] = useState(0);
  const [expenses, setExpenses] = useState(0);
  const { showTheHintsPopup, isShowHints, game } = useEditGame();

  const account = AzureAuth.getAccount();
  const { localAccountId: accountId, name: userName } = account;

  const parseCurrentPoints = (finalData: Record<string, any>[]) => {
    const finalPoints = finalData.reduce((acc, curr) => +curr.finalPoints + acc, 0);
    setCoins(finalPoints);
  };

  const parseExpenses = (finalData: Record<string, any>[]) => {
    let gameHintExpenses = null;
    let totalExpenses = 0;
    finalData.map((data) => {
      if (data.game_id === game?.id) {
        gameHintExpenses = data;
      }
      totalExpenses += data.cost;
    })

    setExpenses(totalExpenses);
  }

  useEffect(() => {
    getScores(accountId, parseCurrentPoints);
    getExpenses(accountId, parseExpenses);
  }, []);

  function updateToOpen(e: ChangeEvent<HTMLInputElement>, id: number) {
    let currentHint = hints.find(h=> h.id === id)
    let cost = currentHint?.score || 0;
    if (e.target.checked) {
      setToOpen(prev => ([...prev, id]));
      setHintCost(prev => prev + cost);
    } else {
      setToOpen(prev => prev.filter(i => i !== id));
      setHintCost(prev => prev - cost);
    }
  }

  function buyHints() {
    let id = toOpen[0];
    let currentHint = hints.find(h=> h.id === id)
    setOpenHint(currentHint);
    setHintCost(prev=> prev - (currentHint?.score || 0))
    setHints(prev=> prev.filter(h=> h.id !== id))
    setToOpen(prev=> prev.filter(i => i!==id))

    if (game?.id) {
      createExpense(
        accountId,
        {expense_type: 'hints', game_id: game.id, hints: hints ?? null, items: toOpen ?? null, cost: hintCost ?? 0, active: true},
        () => {}
      );
    }
  }

  function showNextHints() {
    if(toOpen.length > 0){
      buyHints()
    } else {
      setOpenHint(null)
    }
  }

  return (
    <div className='buy-hints-popup'>
      <div className='popup-header'>
        <CloseBtn close={() => showTheHintsPopup(false)} />
      </div>
      {!openHint ?
        <>
          <ul>
            {hints.map((hint, i) => {
              return (
                <li key={`hint-${i}`}><p className='custom-checkbox'>
                  <input
                    type='checkbox'
                    checked={toOpen.includes(hint.id || i)}
                    id={`type-${i}`}
                    name='type'
                    onChange={(e) => updateToOpen(e, hint.id || i)}
                  />
                  <label htmlFor={`type-${i}`}>Hint  : <span>{hint.score === 0 ? 'free' : hint.score}</span> <Coin />
                    <span className='check-wrapper'>&#10003;</span></label>
                </p></li>
              );
            })}
          </ul>
          <div className='costs-info d-flex justify-content-between'>
            <p><span>Coins </span>: {coins - expenses} <Coin /></p> <p><span>Hint cost</span>: {hintCost} <Coin /></p>
          </div>
          <ButtonMotion variant='buy-hint' disabled={toOpen.length === 0} onClick={buyHints}>Buy the
            hints</ButtonMotion>  </> :
        <>
          <div className='costs-info'>
            <p><span>Coins :</span> {coins - expenses} <Coin /></p> <p><span>Hint cost :</span> {openHint.score} <Coin /></p>
          </div>
          <div className='opened-hint'><p> {openHint.hint} </p></div>
          <ButtonMotion variant='buy-hint' onClick={showNextHints}>Next hint</ButtonMotion>
        </>}
    </div>
  );
}

export default memo(BuyHintPopup);