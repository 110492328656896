/**
 * @description - The mini world skills category types that are existing in the
 *  GLMS platform.
 */
export enum EnumMiniWorldKeySkillsCategory {
  math = 'math',
  science = 'science',
  maltese = 'maltese',
  english = 'english',
}

/**
 * @description - The world category types
 */
export enum EnumWorldCategory {
  islands = 'islands',
}
