export const GamesListDemo = [
  // { id: '1', title: 'crosswords', level: 2, status: 'new', mini_world_id: '1', content: [] },
  { id: '2', title: 'true or false', level: 2, status: 'new', mini_world_id: '1', content: [] },
  { id: '3', title: 'Quiz', level: 2, status: 'new', mini_world_id: '1', content: [] },
  { id: '4', title: 'Multiple choice', level: 2, status: 'new', mini_world_id: '1', content: [] },
]

export const GameDataDemo = {
  id: '1',
  created_by: '',
  updated_by: null,
  created_at: new Date(),
  updated_at: new Date(),
  title: 'test title',
  description: 'test description',
  skills: ['math, science'],
  level: 1,
  course_id: '123',
  content: [
    // {
    //   title: 'States of text',
    //   description: 'Drag and drop each item you see in the right container: solid, liquid or gas. Be fast!',
    //   points: 100,
    //   category: 'drag_drop_text',
    //   content: {
    //     answers: ['solid', 'liquid', 'gas'],
    //     options: [
    //       { description: 'petroleum', option: 'liquid' },
    //       { description: 'cables', option: 'solid' },
    //       { description: 'vase', option: 'solid' }
    //     ]
    //   },
    // },
    // {
    //   title: 'States of matter',
    //   description: 'Drag and drop each item you see in the right container: solid, liquid or gas. Be fast!',
    //   points: 100,
    //   category: 'drag_drop_image',
    //   content: {
    //     answers: ['solid', 'liquid', 'gas'],
    //     options: [
    //       { description: 'petroleum', option: 'liquid' },
    //       { description: 'cables', option: 'solid' },
    //       { description: 'vase', option: 'solid' }
    //     ]
    //   },
    // },
    // {
    //   title: 'Fresher’s Week',
    //   description: 'Help these new students to get to know each other. Choose the most appropriate response and let the conversation flow.',
    //   points: 100,
    //   category: 'simulation_dialogue',
    //   content: {
    //     dialogues: [
    //       {
    //         options: ['Goodbye!', 'Hi!'],
    //         endAnswer: 'What?',
    //         rightAnswer: 1
    //       },
    //       {
    //         options: ['My name is Miguel', 'I don\'t know'],
    //         endAnswer: 'What?',
    //         rightAnswer: 0
    //       }
    //     ]
    //   },
    // },
    // {
    //   title: 'Scramble Cover Letter',
    //   description: 'In the following cover letter something went wrong. Drag and drop the pieces of the letter that are misplaced and put them in the right places.',
    //   points: 100,
    //   category: 'simulation_game',
    //   content: {
    //     initial_items: [
    //       {
    //         description: 'Two assure edward whence the was.',
    //         id: 'a_1'
    //       },
    //       {
    //         description: 'Who worthy yet ten boy denote wonder.',
    //         id: 'p_1'
    //       },
    //       { description: 'Weeks views her sight old tears sorry.', id: 'l_1' },
    //       {
    //         description: 'Additions can suspected its concealed put furnished.',
    //         id: 'e_1'
    //       },
    //       {
    //         description: 'Met the why particular devonshire decisively considered partiality.',
    //         id: 'p_2'
    //       },
    //     ],
    //     answer: 'Two assure edward whence the was. Who worthy yet ten boy denote wonder. Weeks views her sight old tears sorry. Additions can suspected its concealed put furnished. Met the why particular devonshire decisively considered partiality.',
    //   },
    // },
    // {
    //   title: 'Hotspot',
    //   description: 'FIll in missing words',
    //   points: 100,
    //   category: 'hotspot',
    //   content: {
    //     questions: [
    //       '3+5×2-1',
    //       '32+3×2-2',
    //       '5×6-22÷2',
    //     ],
    //     answers: [12, 13, 14],
    //     img: 'workplace.jpg',
    //     map_id: 'usemap',
    //     details: [
    //       { shape: 'rect', coords: '34,44,270,350', title: 'Computer' },
    //       { shape: 'rect', coords: '290,172,333,250', title: 'Phone' },
    //       { shape: 'circle', coords: '337,300,44', title: 'Coffee' }
    //     ],
    //   },
    // },
    // {
    //   title: 'Essay',
    //   description: 'FIll in missing words',
    //   points: 100,
    //   category: 'essay',
    //   content: {
    //     text: '',
    //     keyWords: ['win', 'make', 'play'],
    //     maxLength: 200,
    //   },
    // },
    // {
    //   title: 'Fill In Game',
    //   description: 'Add missing words',
    //   points: 100,
    //   category: 'fill_in_game',
    //   content: {
    //     text: 'The word is a {{_}} version of the original and ' +
    //       'therefore does not mean {{_}} in particular. The book however where it {{_}} ' +
    //       'discusses the philosophical {{_}} of Epicureanism, Stoicism, and the Platonism of Antiochus of Ascalon.',
    //     answers: ['someword', 'anotherWord', 'anotherWord2', 'anotherWord3']
    //   },
    // },
    {
      title: 'True or false',
      description: 'choose the right one',
      points: 15,
      category: 'quiz_single_choice',
      content: {
        'question': 'Is the capital of France Tokyo?',
        'options': [
          {
            'id': 1,
            'title': 'True',
            'is_correct': false
          },
          {
            'id': 3,
            'title': 'False',
            'is_correct': true
          }
        ],
      }
    },
    {
      title: 'Multiple Choice',
      description: 'choose the right answers',
      category: 'quiz_multi_choice',
      points: 25,
      content: {
        question: 'What is the capital of France?',
        options: [
          {
            'title': 'New York',
            'is_correct': false
          },
          {
            'title': 'London',
            'is_correct': true
          },
          {
            'title': 'Paris',
            'is_correct': true
          },
          {
            'title': 'Dublin',
            'is_correct': false
          }
        ]
      }
    },
    {
      title: 'True or false',
      description: 'choose the right one',
      points: 15,
      category: 'quiz_true_false',
      content: {
        'question': 'Is the capital of France Tokyo?',
        'options': [
          {
            'id': 1,
            'title': 'True',
            'is_correct': false
          },
          {
            'id': 3,
            'title': 'False',
            'is_correct': true
          }
        ],
      }
    },
    // {
    //   title: 'crosswords',
    //   description: 'You need to fill in the squares...',
    //   points: 30,
    //   category: 'crossword',
    //   content: {
    //     across: {
    //       '1': {
    //         'clue': 'img_4',
    //         'answer': 'SCAT',
    //         'col': 0,
    //         'row': 0,
    //         'classname':"across"
    //       },
    //       '2': {
    //         'clue': 'img_1',
    //         'answer': 'SHARK',
    //         'row': 3,
    //         'col': 1
    //       },
    //       '3': {
    //         'clue': 'img_2',
    //         'answer': 'LXXXXX',
    //         'row': 5,
    //         'col': 1
    //       }
    //     },
    //     down: {
    //       '1': {
    //         'clue': 'img_3',
    //         'answer': 'TUNA',
    //         'row': 0,
    //         'col': 3
    //       },
    //       '2': {
    //         'clue': 'All',
    //         'answer': 'SALMON',
    //         'row': 3,
    //         'col': 1
    //       }
    //     }
    //   }
    // },
    // {
    //   title: 'Who is an Algebra master?',
    //   description: 'You need to work out all algebraic expressions/equations which you will be given in this game in order to win.',
    //   points: 15,
    //   category: 'calculation',
    //   content: {
    //     question: 'What is the value of the expression:\n 3x if x=3',
    //     answer: 9
    //   },
    // },
  ]
}

export const WorldDemo = {
  id: 'demoId',
  title: 'Islands',
  description: 'The Islands world',
  course_id: 'demoCourseId',
  created_by: 'test',
  updated_by: 'test',
  created_at: new Date(),
  updated_at: new Date(),
  category: 'islands',
  worlds: [
    { icon: 'Maths', description: 'Maths', title: 'Maths', category: 'math', id: '1' },
    { icon: 'Maltese', description: 'Maltese',title: 'Maltese', category: 'maltese', id: '2' },
    { icon: 'English', description: 'English',title: 'English', category: 'english', id: '3' },
    { icon: 'Science', description: 'Science',title: 'Science and Technology', category: 'science', id: '4' }
  ]
};
