import { EnumGameMechanicCategory } from "src/@constants";
export const CalculationGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.calculation,
  points: 100,
  content: {
    question: '',
    answer: '',
    filePath: null
  }
}
