import { createContext, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  TGame,
  TGameMechanic,
  TGameMechanicContent,
  TGameStatus
} from 'src/@types';
import {
  getGameMechanic,
  updateGameMechanic,
  updateGameCurrentPoints,
} from 'src/redux/slices';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The game mechanic base interface that contains all necessary
 *  variables and methods for the game mechanic playable and editable components
 * @param { boolean } isLoading - The boolean variable that shows if the game is
 *  in loading process or not
 * @param { boolean} isPaused - The boolean variable that shows if the game is
 *  paused or not
 * @param { TGameMechanic } gameMechanicData
 *    the mechanic object of the game data that is necessary in order to parse
 *    the component view.
 * @param { function } changeCorrectAnswersPoints
 *    The method is changing the total points for the current page, based on the
 *    provided answers
 * @param { function } saveGameMechanicContent
 *    The method is changing the content data for the current page, based on the
 *    provided input
 * @param { function } addNewPage
 *    The method is adding a new page to the games data
 * @param { number } page
 *    The current page of the game
 * @param { VoidFunction } saveGameMechanic
 *    The method is saving the internal state game object with the modified
 *    game mechanic of the proper page
 */
export interface GameMechanicBaseInterface {
  isLoading: boolean;
  isEditMode: boolean;
  status: TGameStatus;
  points: number;
  gameMechanic: TGameMechanic | null;
  changeCorrectAnswersPoints: (points: number) => void;
  changeGameMechanicContent: ( finalGameMechanic: TGameMechanicContent ) => void;
  saveGameMechanic: VoidFunction;
  changePage: (pageNumber: number) => void;
  page: number;
}

/**
 * @description - Game mechanic base context to create context of world
 *  lifecycle
 */
export const ContextGameMechanicBase = createContext<GameMechanicBaseInterface | null>(null);

/**
 * @description - Games' base lifecycle Provider hook. Contains lifecycle logic
 * @param children - Children components
 * @constructor - Adding listener for game lifecycle
 */
export function GamesMechanicBaseProvider(
  { children }: { children: ReactNode }
) {

  // ========================== INTERNAL VARIABLES ========================== //
  /**
   * @description - The global state-based variables and methods
   */
  const dispatch = useDispatch();
  const {
    gameMechanic,
    game,
    points,
    status,
    isEditMode,
    isLoading,
    error,
    page,
  } = useSelector((state) => state.game);

  // ========================== LIFECYCLE METHODS =========================== //

  /**
   * @description
   *    The method is changing the game's current content mechanic points based
   *    on the provided answers
   * @param { number } newPoints
   * ToDo need to move the points calculation to the server side.
   */
  const changeCorrectAnswersPoints = (newPoints: number) => {
    dispatch(updateGameCurrentPoints(newPoints));
  }

  /**
   * @description
   *    The method is saving the modified game mechanic content by triggering
   *    the API to store the data into the server
   * @param { TGameMechanicContent } finalGameMechanicContent
   *    The final game mechanic content data that need to be modified or added
   *    into the server's DB
   */
  const changeGameMechanicContent = (
    finalGameMechanicContent: TGameMechanicContent
  ) => {
    if (gameMechanic) {
      dispatch(updateGameMechanic({
        ...gameMechanic,
        content: finalGameMechanicContent,
      }));
    }
  }

  /**
   * @description
   *    The method is saving the game mechanic into the game object for the
   *    internal state
   */
  const saveGameMechanic = () => {
    if (game && gameMechanic) {
      const modifiedContent = [...game?.content];
      modifiedContent[page] = gameMechanic;
    }
  }

  /**
   * @description
   *    The method is changing the page number of the game
   * @param { number } pageNumber
   *    The selected page
   */
  const changePage = (pageNumber: number) => {
    // dispatch(changeGameContentPage(pageNumber));
  }

  // ========================= VIEW METHODS/ACTIONS ========================= //

  /**
   * @description - The provider view
   */
  return (
    <ContextGameMechanicBase.Provider
      value={{
        isLoading,
        status,
        isEditMode,
        points,
        gameMechanic,
        changeCorrectAnswersPoints,
        changePage,
        changeGameMechanicContent,
        saveGameMechanic,
        page,
      }}
    >
      {children}
    </ContextGameMechanicBase.Provider>
  );
}
