import { EnumGameType } from '../@constants';
import { ChangeEvent } from 'react';

export const checkIsMobile = () => {
  return /Android|webOS|iPhone|iPad|Mobile|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const true_false_btns = ['outline-success', 'outline-danger'];
export const true_false_btns_selected = ['success', 'danger'];

export const calculationRegex = /^(?:[-+]?(?!1[xyabcd])(?:\d+(?:\.\d+)?|\.\d+)(?:\/\d+)?[xyabcd]?)?$/;

export const diamondColors: { [key: string]: string[] } = {
  'fail-core': ['370435', '58074D', 'A54998', '4E0648', '410B34'],
  'fail-mini': ['7A0269', '390036', 'A54998', '68297C'],
  'win-core': ['FF80F9', 'CE5BBE', 'A54998', 'E065D5', 'FFA4EA'],
  'win-mini': ['CE5BBE', 'FF80F9', 'A54998', '68297C'],
  'notPlayed-core': ['FFD2FD', 'FFB1F4', 'FFCDF8', 'FFD0FB', 'EAD0E4'],
  'notPlayed-mini': ['FFB4F5', 'FF80F9', 'FFDAFA', 'F3CFFF']
};

const gameStateByProgress = (progress:number) => {
  if(progress === 0) return 'notPlayed'
  if(progress > 70) return 'win'


  return  'fail';
}
export const getDiamondColor = (type: (string | undefined) = EnumGameType.mini ? "mini" : "core", progress: (number | undefined) = 120) => `${gameStateByProgress(progress)}-${type}`;

export const handleChange = ((setState: (value: string) => void) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  setState(e.target.value);
});


export const getCssProps = (degree2: number, moreThan8: boolean, i: number) => {

  const percentage = 50; // 50%

  const degree1 = 90; // First angle in degrees

  // Convert degrees to radians for the trigonometric function
  const radian = moreThan8
    ? degree2 * (Math.PI / 180)
    : (degree1 - degree2) * (Math.PI / 180);

  // Calculate the result using the Math.tan() function
  let result = { clipPathDeg: 0, degree: 0 };
  result.clipPathDeg = moreThan8
    ? percentage * (1 + Math.tan(radian))
    : percentage - Math.tan(radian) * percentage;
  result.degree = moreThan8 && i * degree2 > 180 ? i * degree2 - 360 : i * degree2;
  return result;
};


