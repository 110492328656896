import { useContext } from 'react';
import { ContextGameMechanicBase } from 'src/contexts';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The hook for the game component. This is containing all
 * necessary lifecycle methods and variables.
 */
const useGameMechanic = () => {
  const context = useContext(ContextGameMechanicBase);

  if (!context) throw new Error(
    'Game context must be use inside GameBaseProvider'
  );

  return context;
};

export default useGameMechanic;
