import { Col, Row } from 'react-bootstrap';
import { ButtonMotion, Uploader } from 'src/components';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/pagination';
import 'src/assets/styles/editors/simulation-game.scss';

import 'swiper/scss/navigation';
import { Pagination } from 'swiper/modules';
import { useGameEditMechanic } from '../../../../hooks';
import GameNotFound from '../../../not-found';
import { TPlayers, TSimulationDialogueGame, TSimulationOption } from './types';
import { useEffect, useState } from 'react';
import { TGameMechanicContent } from '../../../types';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { checkIsMobile } from '../../../../helpers';

export const emptyPlayersContent: TPlayers = {
  player1: {
    options: [{ correct: '', incorrect: '' }],
    filePath: ''
  },
  player2: {
    options: [{ correct: '', incorrect: '' }],
    filePath: ''
  }

};

interface IAnswer {
  [key: string]: string
}

export default function SimulationDialogueEditor() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();

  if (gameMechanic) {
    const isMobile = checkIsMobile();
    const {
      players = emptyPlayersContent
    } = gameMechanic.content as TSimulationDialogueGame;
    const [answer, setAnswer] = useState<IAnswer>({ player1: '', player2: '' });
    const [dialogIndex, setDialogIndex] = useState(0);
    const onGameMechanicContentChange = (finalContent: TGameMechanicContent, player: string) => {
      // @ts-ignore
      const players = gameMechanic.content.players ? { ...gameMechanic.content.players as TSimulationDialogueGame } : emptyPlayersContent;
      updateGameMechanic({
        ...gameMechanic,
        content: {
          ...gameMechanic.content,
          players: { ...players, [player]: finalContent }
        } as unknown as TGameMechanicContent
      });
    };
    const updateAnswer = (player: string, value: string) => {
      setAnswer(prev => ({ ...prev, [player]: value }));
    };

    const updateUploadedFile = (filePath: string, player: string) => {
     const updatedContent =  {...players[player as keyof TPlayers] as unknown as TGameMechanicContent, filePath}
      onGameMechanicContentChange(updatedContent, player);
    };


    const currentDialog = (player: string, index: number) => players[player as keyof TPlayers]?.options[index];


    const isCorrectSelected = (player: string, index: number) => currentDialog(player, index)?.correct;
    const isInCorrectSelected = (player: string, index: number) => currentDialog(player, index)?.incorrect;

    const checkDialogReady = () => Boolean(isCorrectSelected('player1', dialogIndex) && isCorrectSelected('player2', dialogIndex)
      && isInCorrectSelected('player1', dialogIndex) && isInCorrectSelected('player2', dialogIndex));


    const handleAnswerSelectClick = (player: string, option: string) => {
      let updatedOptions = JSON.parse(JSON.stringify(players[player as keyof TPlayers]?.options));
      if (!updatedOptions[dialogIndex]) {
        updatedOptions[dialogIndex] = {};
      }
      updatedOptions[dialogIndex][option as keyof TSimulationOption] = answer[player];

      onGameMechanicContentChange({
        ...players[player as keyof TPlayers],
        options: updatedOptions
      } as unknown as TGameMechanicContent, player);
      setAnswer(prev => ({ ...prev, [player]: '' }));
    };

    useEffect(() => {
      if (checkDialogReady()) {
        setDialogIndex(prev => prev + 1);
      }
    }, [checkDialogReady()]);

    return (
      <div className='simulation-editor'>
        <div className='dialogue-content'>
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <Row>
              {Object.values(players).map((player, i) => {
                let currentPlayer = `player${i + 1}`;
                return (<Col lg='6' md={12} key={`player-${i}`}>
                    <Uploader
                      onGameMechanicContentChange={(filePath) => updateUploadedFile(filePath, currentPlayer)}
                      gameId={gameId}
                      position='landscape' currentUrl={players[currentPlayer as keyof TPlayers].filePath} />
                    <input className='form-control content-input' placeholder={`Avatar content (user ${i+1})`}
                           onChange={(e) => updateAnswer(currentPlayer, e.target.value)}
                           value={answer[currentPlayer]} />
                    <div className='answer-btns'>
                      <ButtonMotion variant='correct' onClick={() => handleAnswerSelectClick(currentPlayer, 'correct')}
                                    disabled={Boolean(isCorrectSelected(currentPlayer, dialogIndex))}>Correct</ButtonMotion>
                      <ButtonMotion variant='danger'
                                    onClick={() => handleAnswerSelectClick(currentPlayer, 'incorrect')}
                                    disabled={Boolean(isInCorrectSelected(currentPlayer, dialogIndex))}>Incorrect</ButtonMotion>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </DndProvider>
        </div>
        <Row>
          <Col md='12'>
            <Swiper direction={'vertical'}
                    slidesPerView={2}
                    pagination={{
                      clickable: true
                    }}
                    modules={[Pagination]}
                    className='mySwiper'>


              {new Array(dialogIndex + 1).fill('').map((dialog, i) => {
                const firstDialogOptions = currentDialog('player1', i);
                const secondDialogOptions = currentDialog('player2', i);
                return (
                  <SwiperSlide key={`dialog-${i}`}>
                    <div className='dialogues'>
                      {(firstDialogOptions?.correct || firstDialogOptions?.incorrect) &&
                      <div className='first-player dialogue'>
                        {firstDialogOptions?.correct &&
                        <p className='correct'>{firstDialogOptions?.correct}</p>
                        }
                        {firstDialogOptions?.incorrect &&
                        <p className='incorrect'>{firstDialogOptions?.incorrect}</p>
                        }
                      </div>
                      }
                      {(secondDialogOptions?.correct || secondDialogOptions?.incorrect) &&
                      <div className='second-player dialogue'>  {secondDialogOptions?.correct &&
                      <p className='correct'>{secondDialogOptions?.correct}</p>}
                        {secondDialogOptions?.incorrect &&
                        <p className='incorrect'>{secondDialogOptions?.incorrect}</p>}</div>
                      }
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

          </Col>
        </Row>
      </div>
    );
  }
  return <GameNotFound />;
}