import { Col, Row } from 'react-bootstrap';
import 'src/assets/styles/drop-text-to-image-game.scss'
import { useGameMechanic } from "src/hooks";
import GameNotFound from "../../../not-found";
import React, {useState} from "react";
import {TDragDropTextToImageGame} from "./types";
import { ImageAnnotation, DropItem, DragItem } from "../../../../components";
import {TouchBackend} from "react-dnd-touch-backend";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {checkIsMobile} from "../../../../helpers";

export default function DragDropTextToImage(){
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
    isLoading,
    points,
    status
  } = useGameMechanic();

  const isMobile = checkIsMobile();

  if (!gameMechanic || isLoading) {
    return <GameNotFound />;
  }

  const { annotations, filePath } = gameMechanic.content as TDragDropTextToImageGame;
  const [stateAnnotations, setStateAnnotations] = useState(annotations);

  const handleDrop = (item: any) => {
    const finalAnnotations = stateAnnotations.filter(stateAnnotation => stateAnnotation?.data?.text !== item?.data?.text);

    if (finalAnnotations.length) {
      changeCorrectAnswersPoints(points + 100);
    }

    setStateAnnotations(finalAnnotations);
  }

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <div className='drop-text-to-image'>
        <Row>
          {filePath &&
          <Col md={6}>
            <DropItem onDrop={handleDrop} key={'text_to_image_annotation'}>
              <ImageAnnotation
                annotations={annotations ?? []}
                onSubmit={(annotations: any[]) => {
                }}
                image={filePath}
                viewMode={true}
              />
            </DropItem>
          </Col>
          }
          <Col md={6}>
            <div className='d-flex flex-wrap'>
              {stateAnnotations?.map((item, index) => (
                <DragItem item={{ ...item, element:<div className="droppable-item">{item?.data?.text}</div> }}>
                  <div className="droppable-item">{item?.data?.text}</div>
                </DragItem>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </DndProvider>
  )
}