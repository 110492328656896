import { EnumGameMechanicCategory } from "src/@constants";
import { TCrosswordGame } from "./types";

export const CrosswordGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.crossword,
  points: 100,
  content: {
    across: {},
    down: {},
    grid:{row:8, column:8}
  }
}
