import { THighlightGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the "highlight word game"
 *    game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function highlightWordDbToViewConverter(
  dbData: Record<string, any>
): THighlightGame {
  return {
    sentence: dbData.sentence,
    highlight: dbData.highlight,
  }
}
