import { TDragDropTextToImageGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the drag and drop
 *    image game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function dragDropTextToImageDbToViewConverter(
  dbData: Record<string, any>
): TDragDropTextToImageGame {
  return {
    annotations: dbData.annotations,
    filePath: dbData.filePath ?? null,
  }
}
