import { Col, Row } from 'react-bootstrap';
import {ImageAnnotation, Uploader} from 'src/components';
import { useGameEditMechanic } from 'src/hooks';
import {TGameMechanicContent} from "../../../../@types";
import GameNotFound from "../../../not-found";
import {TMarkSpotGame} from "./types";
import React, {useState} from "react";
import {TEssayGame} from "../essay/types";
import {DndProvider} from "react-dnd";
import {TouchBackend} from "react-dnd-touch-backend";
import {HTML5Backend} from "react-dnd-html5-backend";
import {checkIsMobile} from "../../../../helpers";

export default function DragDropTextToImageEditor(){

  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();

  const validateCurrentContent = () => {
    return true;
  }

  if (gameMechanic) {
    const onGameMechanicContentChange = (finalContent: TGameMechanicContent) => {
      if (validateCurrentContent()) {
        updateGameMechanic({
          ...gameMechanic,
          content: finalContent,
        });
      }
    }

    const isMobile = checkIsMobile();

    const { annotations, description, filePath } = gameMechanic.content as TMarkSpotGame;

    return (
      <div className='drop-text-to-image'>
        <Row>
          <Col md={6}>
            {
              filePath
                ? (<ImageAnnotation
                    annotations={annotations ?? []}
                    onSubmit={(annotations: any[]) => onGameMechanicContentChange({ ...gameMechanic?.content, annotations })}
                    image={filePath}
                    viewMode={false}
                />)
                : (<DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                  <Uploader gameId={gameId} currentUrl={filePath} onGameMechanicContentChange={(filePath)=> onGameMechanicContentChange({...gameMechanic.content, filePath})} key={Math.random()} />
                </DndProvider>)
            }
          </Col>
          <Col md={6}>
            <MarkSpotDescription description={description} onParentChange={(newDescription: string) => onGameMechanicContentChange({ ...gameMechanic?.content, description: newDescription } as TMarkSpotGame)} />
          </Col>
        </Row>
      </div>
    )
  }

  return <GameNotFound />;
}

interface MarkSpotDescriptionProps {
  onParentChange: (description: string) => void;
  description: string;
}

function MarkSpotDescription(
  { onParentChange, description }: MarkSpotDescriptionProps
)  {
  const [currentDescription, setCurrentDescription] = useState<string>(description ?? '');

  return (
    <textarea
      placeholder='add some information text here'
      value={currentDescription}
      onChange={(e) => {
        setCurrentDescription(e.target.value);
        onParentChange(e.target.value)
      }}
    />
  )
}