import React, { memo, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import EditableCard from './editable-card';
import 'src/assets/styles/editors/memory-game.scss';
import { ButtonMotion } from 'src/components';
import { useGameEditMechanic } from '../../../../../hooks';
import { TGameMechanicContent } from '../../../../../@types';
import {CardData, TMemoryGame, TMemoryGameCard} from '../types';
import GameNotFound from '../../../../not-found';

function MemoryGameEditor() {
  const {
    gameMechanic,
    updateGameMechanic,
    gameId,
  } = useGameEditMechanic();


  if(gameMechanic && gameId) {
    const { cards } = gameMechanic.content as TMemoryGame;
    const currentHighlights = cards?.map((card) => {return card.numbers})?.flat();
    const [highlighted, setHighlighted] = useState<number[]>([]);
    const [cardData, setCardData] = useState<TMemoryGameCard>({ first: {}, second: {} });

    const [gameData, setGameData] = useState<TMemoryGameCard[]>(cards ?? []);
    const onGameMechanicContentChange = (finalContent: TMemoryGame) => {
      updateGameMechanic({
        ...gameMechanic,
        content: finalContent as unknown as TGameMechanicContent
      });
    };

    const saveFilledCards = () =>{
      let data = { ...cardData, numbers: highlighted }
      onGameMechanicContentChange({
        ...gameMechanic.content as TMemoryGame,
        cards: [...gameData, data]
      });
      setGameData(prev=> ([...prev, data]))
      setHighlighted([]);
      setCardData({ first: {}, second: {} });
    }

    const saveCardData = (data: CardData, key: string) => {
      setCardData(prev => ({ ...prev, [key]: data }));
    }

    const  toggleHighlight = (index: number) => {
      setHighlighted(prev => {
        if (prev.includes(index)) {
          return prev.filter(n => n !== index);
        } else if (prev.length < 2) {
          return [...prev, index];
        } else {
          return [prev[0], index];
        }
      });
    }

    const isSelected = useCallback((num: number) => gameData.find(data => data.numbers?.includes(num)), [gameData]);

    return (
      <div className='memory-film-game editor'>
        <Row>
          <Col md={7} sm={12}>
            <div className='cards pb-3 pb-md-0'>
              <Row>
                <EditableCard saveData={saveCardData} index={'first'} data={cardData.first} gameId={gameId} />
                <EditableCard saveData={saveCardData} index={'second'} data={cardData.second} gameId={gameId} />
              </Row>
            </div>
          </Col>
          <Col md={5} sm={12} className="mb-3 mb-md-0">
            <div className='numbers-container'>
              {new Array(48).fill('')
                .map((el, i) => <span className={classnames({
                  selected: isSelected(i),
                  matched: highlighted.includes(i)
                })}
                  key={i}
                  onClick={() => toggleHighlight(i)}> {i + 1}</span>)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='btn-wrapper with-count'>
              <ButtonMotion
                variant={'save'}
                onClick={saveFilledCards}
                disabled={cardData.first && cardData.second && highlighted.length !== 2}
              >
                Save & Next Couple
              </ButtonMotion>
            </div>
            <br />
            <div><p>#{cards.length} Cards</p></div>
          </Col>
        </Row>
      </div>
    );
  }
  return  <GameNotFound />
}

export default memo(MemoryGameEditor);
