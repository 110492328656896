import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { store, persistor } from 'src/redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'src/App';
import reportWebVitals from 'src/reportWebVitals';
import { initializeApp } from "firebase/app";
import 'src/index.css';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

const firebaseConfig = {
  apiKey: "AIzaSyCdOSOrsvrcwqN9846J0raF31UXWOGgB3M",
  authDomain: "glms-platform.firebaseapp.com",
  projectId: "glms-platform",
  storageBucket: "glms-platform.appspot.com",
  messagingSenderId: "746432210358",
  appId: "1:746432210358:web:8267316883fef7b2f78d17"
};

const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            {/* ToDo add LocalizationProvider */}
            {/* ToDo add theme provider */}
            <App />
          </BrowserRouter>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
