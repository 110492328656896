import React, { memo, useEffect, useState } from 'react';
import LetterItem from './Letter-item';
import { Col, Container, Row } from 'react-bootstrap';
import DropTarget from './drop-target';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DragItem from './drag-item';
import { useGameMechanic } from '../../../../hooks';
import GameNotFound from '../../../not-found';
import { Letter, TJumbleWordsGame } from './types';
import ImgWithZoom from '../../../../components/img-with-zoom';

interface ItemObject {
  letter: string,
  id: string,
  type: string,
  curIndex: number
}

function JumbleWordsGame() {

  const { gameMechanic, changeGameMechanicContent, changeCorrectAnswersPoints } = useGameMechanic();
  const [items, setItems] = useState<Letter[]>([]);
  const [droppedItemsList, setDroppedItems] = useState<string[]>(['', '', '', '', '', '', '', '', '', '', '', '', '', '']);

  function shuffleArray(array: any[]) {
    let shuffledArray = [...array];

    for (let i = shuffledArray.length - 1; i > 0; i--) {
      // Generate a random index lower than the current index
      const j = Math.floor(Math.random() * (i + 1));

      // Swap elements at indices i and j
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  }

  useEffect(() => {
    if (gameMechanic?.content) {
      setItems(shuffleArray((gameMechanic?.content as TJumbleWordsGame)?.letters ?? []));
    }
  }, [gameMechanic?.content]);

  if (gameMechanic) {
    const gameData = gameMechanic?.content as TJumbleWordsGame;
    const { filePath, letters, text } = gameData;
    const handleDrop = (letter: string, index: number, type: string, curIndex: number) => {
      if (type === 'fromTop') {
        let newDroppedItemsList = [...droppedItemsList];
        newDroppedItemsList[index] = letter;

        setDroppedItems(newDroppedItemsList);
        setItems(prev => prev.filter((el, i) => el.letter !== letter || i !== curIndex));
      } else {
        const newContent = swapElements(curIndex, index, droppedItemsList);
        setDroppedItems(newContent);
      }
    };

    let points = 0;
    Object.values((gameMechanic.content as TJumbleWordsGame).draggedItems).map((item, index) => {
      if (droppedItemsList[index] === item.letter) {
        points = 100;
      } else {
        points = 0;
      }
    });

    if (points > 0) {
      changeCorrectAnswersPoints(points);
    }

    const swapElements = (first: number, second: number, arr: string[]) => {
      let cloned = [...arr];
      [cloned[first], cloned[second]] = [cloned[second], cloned[first]];
      return cloned;
    };
    // @ts-ignore
    const draggedItems = gameMechanic.content.draggedItems || {};

    return (
      <DndProvider backend={HTML5Backend}>
        <div className='jumble-word'>
          <Container>
            <Row>
              <Col md={6}>
                {filePath ?
                  <ImgWithZoom filePath={filePath}  />
                  : <p className='jumble-description'>{text} </p>
                }
              </Col>

              <Col md={6} className='game-area'>
                <div className='jumbled-letters'>
                  {items.map((item: Letter, i) => (
                    <DragItem obj={{ letter: item.letter, type: 'fromTop', curIndex: i }} key={item.id}>
                      <LetterItem {...item} />
                    </DragItem>
                  ))}
                </div>
                <div className='drop-area'>
                  {new Array(letters.length).fill('').map((item, index) =>
                    <DropTarget
                      key={`drop-${index}`}
                      onDrop={(item: ItemObject) => handleDrop(item.letter, index, item.type, item.curIndex)}>
                      <DragItem obj={{ letter: draggedItems[index]?.letter, type: 'fromBottom', curIndex: index }}
                                key={index}><span>{droppedItemsList[index]}</span>
                      </DragItem>
                    </DropTarget>)}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </DndProvider>
    );
  }
  return <GameNotFound />;

}

export default memo(JumbleWordsGame);