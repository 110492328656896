import { useParams } from 'react-router-dom';
import { PATH_GAME_ID } from 'src/routes/paths';
import { Loading } from '../components';
import { SelectedGameView } from './island-games/sections';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The game factory component
 * @constructor
 */
export function GameFactory(): JSX.Element {
  /**
   * @description - The web url based variables and methods in order to get and
   * manage the url, and it's params.
   */
  const {[PATH_GAME_ID.replace(':', '')]: gameId} = useParams();
  if (gameId) {
    return  <SelectedGameView />;
  }

  return <Loading />;
}
