export const APP_ROOT_FULL_PATH = process.env.ENV === 'PROD'
  ? process.env.REACT_APP_PROD_APP_ROOT_PATH
  : process.env.ENV === 'DEV'
    ? process.env.REACT_APP_DEV_APP_ROOT_PATH
    : process.env.REACT_APP_STAGE_APP_ROOT_PATH;

const PATH_HOME = '/';
const PATH_404 = '404';
export const PATH_LOG_OUT = 'logout';
const PATH_ISLANDS = 'islands';
export const PATH_ISLAND = ':island';
const PATH_ITEM_ID = ':id';
export const PATH_ANALYTICS = 'analytics';
export const PATH_GAMES_OVERVIEW = 'games-overview';
export const PATH_GAMES = 'games';
export const PATH_MINI_WORLD = 'miniWorld';
export const PATH_CREATE = 'create';
export const PATH_PLAY = 'play';
export const PATH_EDIT = 'edit';
export const PATH_MECHANICS = 'mechanics';
export const PATH_GAME_ID = ':gameId';
export const PATH_GAME_TYPE_ID = ':gameTypeId';
const PATH_CLASSES = 'classes';
export const PATH_CLASS_ID = ':classId';
const PATH_COURSES = 'courses';
const PATH_PROFILE = 'profile';
export const PATH_COURSE_ID = ':courseId';

export const PATH_GLMS_PLAYER = {
  classes: {
    root: PATH_CLASSES,
    class: {
      root: PATH_CLASS_ID,
      full: PATH_CLASS_ID,
      redirect: (classId: string) => classId,
      get: (classId: string) => `${PATH_CLASSES}/${classId}`
    },
    course: {
      root: PATH_COURSE_ID,
      full: `${PATH_CLASS_ID}/${PATH_COURSE_ID}`,
      redirect:
        (classId: string, courseId: string) => `${classId}/${courseId}`,
      get:
        (classId: string, courseId: string) => `${PATH_CLASSES}/${classId}/${courseId}`
    },
    analytics: {
      root: PATH_ANALYTICS,
      full: `${PATH_CLASS_ID}/${PATH_ANALYTICS}`
    },
    gamesOverview: {
      root: PATH_GAMES_OVERVIEW,
      full: `${PATH_CLASS_ID}/${PATH_GAMES_OVERVIEW}`
    },
    islands: {
      root: PATH_ISLAND,
      full: `${PATH_CLASS_ID}/${PATH_COURSE_ID}/${PATH_MINI_WORLD}/${PATH_ISLAND}`,
      redirect:
        (classId: string, courseId: string) => `${classId}/${courseId}`,
      get:
        (classId: string, courseId: string, skillId: string) => `${PATH_CLASSES}/${classId}/${courseId}/${PATH_MINI_WORLD}/${skillId}`
    }
  },
  games: {
    root: PATH_GAMES,
    game: {
      root: PATH_GAME_ID,
      full: `/${PATH_GAMES}/${PATH_GAME_ID}`,
      create: {
        root: PATH_CREATE,
        full: `/${PATH_GAMES}/${PATH_CREATE}/${PATH_ISLAND}`,
        mechanics: {
          root: PATH_EDIT,
          full: `/${PATH_GAMES}/${PATH_CREATE}/${PATH_MECHANICS}/${PATH_GAME_ID}`,
          getFullPath: (id: string) => `/${PATH_GAMES}/${PATH_CREATE}/${PATH_MECHANICS}/${id}`
        }
      },
      play: {
        root: PATH_PLAY,
        full: `/${PATH_GAMES}/${PATH_PLAY}/${PATH_GAME_ID}`
      },
      edit: {
        root: PATH_EDIT,
        full: `/${PATH_GAMES}/${PATH_EDIT}/${PATH_GAME_ID}`,
        getFullPath: (id: string) => `/${PATH_GAMES}/${PATH_EDIT}/${id}`
      },
      redirect: (gameId: string) => gameId,
      get: (gameId: string) => `${PATH_GAMES}/${gameId}`
    },
    not_found: {
      root: PATH_404,
      full: PATH_404
    }
  },
  profile: {
    root: PATH_PROFILE,
    full: `/${PATH_PROFILE}`
  }
};

export const PATH_MAIN = {
  root: PATH_HOME,
  logout: PATH_LOG_OUT
};
