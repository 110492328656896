import { ButtonMotion } from '../../components';
import { ChangeEvent, useMemo, useState } from 'react';
import Form from 'react-bootstrap/esm/Form';
import { FilterData } from './index';
import CloseBtn from '../../assets/svg/buttons/close-btn';


export default function Filter({
                                 filter,
                                 save,
                                 fData,
                                 close
                               }: {
  filter: string,
  save: (data: FilterData | {}) => void,
  fData: FilterData | {},
  close: () => void
}) {
  const [filterData, setFilterData] = useState<FilterData | {}>(fData);
  const updateFilterData = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>, key: keyof FilterData | {}) => {
    // @ts-ignore
    setFilterData(prev => ({ ...prev, [key]: e.target.value }));
  };

  const filterContent = useMemo(() => {
    switch (filter) {
      case 'title':
        return (<input className='form-control' value={'title' in filterData && filterData?.title || ''}
                       onChange={(e) => updateFilterData(e, 'title')} />);
      case 'skill':
        return (<input className='form-control' value={'skill' in filterData && filterData?.skill || ''}
                       onChange={(e) => updateFilterData(e, 'skill')} />);
      case 'created_by':
        return (<input className='form-control' value={'created_by' in filterData && filterData?.created_by || ''}
                       onChange={(e) => updateFilterData(e, 'created_by')} />);
      case 'created':
      case   'updated':
        // @ts-ignore
        return <input type='date' className='form-control' value={filterData?.[filter] || ''}
                      onChange={(e) => updateFilterData(e, filter)} />;
      case 'status' :
        return <Form.Select aria-label='Default select example'
                            value={'status' in filterData && filterData?.status || 0}
                            onChange={(e) => updateFilterData(e, 'status')}>
          <option value=''>Open this select menu</option>
          <option value='new'>New</option>
          <option value='published'>Published</option>
          <option value='reviewed'>Reviewed</option>
          <option value='edit'>Need Edit</option>
        </Form.Select>;
    }
  }, [filter, filterData]);

  function saveData() {
    save(filterData);
    close();
  }

  function resetData() {
    setFilterData(prev => {
      let cloned = { ...prev };
      // @ts-ignore
      if (cloned[filter]) { // @ts-ignore
        delete cloned[filter];
      }
      return cloned;
    });
  }

  return (
    <div className='filter'>
      <CloseBtn close={close} />
      <h3>Filter by {filter}</h3>
      <div className='filter-content'>
        {filterContent}
      </div>
      <div className='d-flex justify-content-around'>

        <ButtonMotion variant='success' onClick={saveData}>
          Ok
        </ButtonMotion>
        <ButtonMotion variant='danger' onClick={resetData}>
          Reset
        </ButtonMotion>

      </div>
    </div>
  );
}