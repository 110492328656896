import { memo } from 'react';

function ScrambleIcon() {
    return (
        <svg  viewBox="0 0 70 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_106_243)">
                <path d="M66.9935 68H66.9918C63.3061 67.9965 44.6337 67.9842 38.9437 67.9755C37.2939 67.972 35.9513 66.6309 35.9513 64.9852C35.9635 46.445 35.9723 39.5262 35.9758 36.9859C35.9776 35.3402 37.322 34.0026 38.9735 34.0026H66.9988C68.6486 34.0026 69.993 35.3402 69.9965 36.9859C70.0053 41.9352 69.9965 56.671 69.9895 65.015C69.9895 66.6624 68.6451 68.0018 66.9935 68.0018V68ZM38.5839 64.9852C38.5839 65.1848 38.7471 65.3476 38.9472 65.3476C44.6354 65.3564 63.3078 65.3704 66.9918 65.3722C67.1919 65.3722 67.3551 65.2093 67.3551 65.0098C67.3603 56.6675 67.3691 41.9317 67.3621 36.9859C67.3621 36.7863 67.1989 36.6235 66.997 36.6235H38.9735C38.7734 36.6235 38.6085 36.7863 38.6085 36.9859C38.605 39.5262 38.5962 46.4468 38.5839 64.9817V64.9852Z" fill="black"/>
                <path d="M62.5953 36.6357C62.5953 36.6357 62.5953 36.6357 62.5935 36.6357C61.7054 36.6357 40.7549 36.62 34.5454 36.6112C32.8939 36.6077 31.553 35.2667 31.5548 33.621C31.5565 29.9427 31.5705 11.3062 31.5793 5.62159C31.5828 3.9759 32.9272 2.6366 34.577 2.6366H62.6023C64.2521 2.6366 65.5965 3.97415 65.6 5.61984C65.6035 7.9413 65.6052 12.7173 65.6 19.8147C65.6 20.5395 65.0103 21.1278 64.2837 21.1278C63.5571 21.1278 62.9673 20.5395 62.9673 19.8147C62.9709 12.719 62.9691 7.9448 62.9673 5.62509C62.9673 5.42551 62.8041 5.26269 62.6023 5.26269H34.5753C34.3752 5.26269 34.2102 5.42551 34.2102 5.62509C34.2014 11.3062 34.1891 29.9427 34.1856 33.621C34.1856 33.8206 34.3489 33.9834 34.5489 33.9834C40.7567 33.9939 61.7054 34.0079 62.5935 34.0079C62.7181 34.0079 62.8024 33.9501 62.8498 33.9028C62.8989 33.8538 62.9568 33.7715 62.9568 33.6472C62.9568 31.7845 62.9603 28.5544 62.9621 24.8901C62.9621 24.1653 63.5518 23.577 64.2784 23.577C65.005 23.577 65.5947 24.1653 65.5947 24.8901C65.593 28.5544 65.5912 31.7845 65.5895 33.6472C65.5895 34.4456 65.2771 35.1966 64.7102 35.7604C64.145 36.3241 63.3921 36.634 62.5935 36.634L62.5953 36.6357Z" fill="black"/>
                <path d="M35.5774 65.3739H35.5757C29.6013 65.3704 12.9175 65.3564 7.52758 65.3494C5.87779 65.3476 4.53515 64.0048 4.53691 62.3591C4.53866 58.6808 4.5527 40.0444 4.56148 34.3597C4.56323 32.7141 5.90939 31.3748 7.55917 31.3748H35.5844C37.2342 31.3748 38.5786 32.7123 38.5821 34.358C38.5909 40.0479 38.5786 58.7054 38.5751 62.3871C38.5751 64.0346 37.229 65.3739 35.5792 65.3739H35.5774ZM35.5827 34.0008H7.55917C7.35909 34.0008 7.19411 34.1637 7.19411 34.3632C7.18534 40.0461 7.17305 58.6808 7.16954 62.3591C7.16954 62.5587 7.33276 62.7215 7.53284 62.7215C12.9227 62.7303 29.6049 62.7425 35.5792 62.746C35.7793 62.746 35.9425 62.5832 35.9425 62.3836C35.946 58.7019 35.9583 40.0461 35.9495 34.3597C35.9495 34.1602 35.7863 33.9973 35.5844 33.9973L35.5827 34.0008Z" fill="black"/>
                <path d="M31.0423 34.0009H31.0405C28.801 34.0009 8.98606 33.9851 2.99243 33.9764C1.34089 33.9729 0 32.6301 0 30.9844C0.00175509 27.3078 0.0157958 8.67836 0.0245713 2.98499C0.0263263 1.33931 1.37073 0 3.02226 0H31.0475C32.6973 0 34.0417 1.33756 34.0452 2.98324C34.054 8.24593 34.0452 24.3386 34.0382 31.0124C34.0382 32.6598 32.6921 33.9991 31.0423 33.9991V34.0009ZM31.0475 2.62609H3.02226C2.82043 2.62609 2.65721 2.78891 2.65721 2.98849C2.64843 8.68011 2.63615 27.3078 2.63263 30.9844C2.63263 31.184 2.79586 31.3468 2.99594 31.3468C8.98957 31.3555 28.8028 31.3695 31.0423 31.3713C31.2424 31.3713 31.4056 31.2085 31.4056 31.0089C31.4108 24.3351 31.4214 8.24593 31.4126 2.98674C31.4126 2.78716 31.2494 2.62434 31.0475 2.62434V2.62609Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_106_243">
                    <rect width="70" height="68" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
}

export default memo(ScrambleIcon);
