import Modal from 'react-bootstrap/Modal';
import { ButtonMotion } from '../buttons';
import { EnumMiniWorldKeySkillsCategory } from 'src/@constants';
import MalteseGames from '../../assets/svg/games/Maltese/Maltese';
import Science from '../../assets/svg/games/Science/Science';
import English from '../../assets/svg/games/English/English';
import React, { useEffect, useMemo } from 'react';
import Maths from '../../assets/svg/games/Maths/Maths';
import { motion } from 'framer-motion';
import CloseBtn from '../../assets/svg/buttons/close-btn';
import { useWorld, useAuth } from 'src/hooks';
import { useParams } from 'react-router';
import InfoTooltip from '../info-tooltip';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PATH_GLMS_PLAYER } from '../../routes/paths';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The prop for the games list modal
 * ToDo - remove?
 */


const transition = {
  type: 'spring',
  damping: 25,
  stiffness: 120,
  duration: 0.6,
  scale: { duration: 0.6 }
};


export default function GamesIsland() {
  const {
    miniWorld,
    miniWorldGames: games,
    selectGame,
    selectMiniWorld,
    addGame,
    getMiniWorldGames
  } = useWorld();

  const { user } = useAuth();

  const { island = '1' } = useParams();
  const navigate = useNavigate();

  const gameId = miniWorld?.category;

  const coreGames = games?.filter(game=> game.type === "core")
  const miniGames = games?.filter(game=> game.type !== "core")

  const skillIsland = useMemo(() => {
    switch (gameId) {
      case EnumMiniWorldKeySkillsCategory.maltese:
        return <MalteseGames onGameSelect={selectGame} miniGames={miniGames || []} coreGames={coreGames || []} />;
      case EnumMiniWorldKeySkillsCategory.english:
        return <English onGameSelect={selectGame} miniGames={miniGames || []} coreGames={coreGames || []} />;
      case EnumMiniWorldKeySkillsCategory.math:
        return <Maths onGameSelect={selectGame} miniGames={miniGames || []} coreGames={coreGames || []} />;
      case EnumMiniWorldKeySkillsCategory.science:
        return <Science onGameSelect={selectGame} miniGames={miniGames || []} coreGames={coreGames || []} />;
    }
  }, [gameId, games]);

  const onAddGame = () => {
    addGame(miniWorld?.id || 'na');
  };

  const onCloseGameList = () => {
    navigate(`/${PATH_GLMS_PLAYER.classes.root}`);
  };

  useEffect(() => {
    selectMiniWorld(island);
    getMiniWorldGames(island);
  }, [island]);

  return (
    <Container className='islands gamified'>
      <InfoTooltip />
      <div className='animate'>
        <CloseBtn close={onCloseGameList} />
        <motion.div
          className='games-list'
          initial={{ scale: 0.75, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ ...transition }}>
          {skillIsland}
        </motion.div>
        <Modal.Footer className="mt-5">
          {user && user?.role?.toLowerCase() !== 'student' && (<ButtonMotion
            variant='outline-secondary'
            onClick={onAddGame}
          >
            Add New Game
          </ButtonMotion>)}
        </Modal.Footer>
      </div>
    </Container>
  );
};
