import React, { MouseEvent, useRef, useEffect, useState, useMemo } from 'react';
import WSGenerator from './ws-generator';
import WordSearchRow from './word-search-row';
import { ICell, Iword } from './types';
import { Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { ButtonMotion } from '../../../../components';
import CloseBtn from '../../../../assets/svg/buttons/close-btn';


interface IGridProps {
  wordList: Iword[];
  changeContent: (wordList: Iword[]) => void;
}

export default function Grid({ wordList, changeContent }: IGridProps) {
  const [showClues, setShowClues] = useState(false);
  const wsGeneratorRef = useRef(new WSGenerator(wordList || []));
  const ws = wsGeneratorRef.current;
  const [isPopulated, setIsPopulated] = useState(false);

  useEffect(() => {
    ws.setGridSize();
    ws.initGrid();
    setIsPopulated(true);
    ws.startBox = null;
    ws.endBox = null;
  }, [wsGeneratorRef]);


  function selectionStart(evt: MouseEvent<HTMLDivElement>) {
    let id = (evt.target as HTMLDivElement).id;
    ws.startBox = ws.getBoxById(id) as ICell;
    return false;
  }

  function selectionEnd(evt: MouseEvent<HTMLDivElement>) {
    //let dir;
    if (ws.startBox != null) {
      let id = (evt.target as HTMLDivElement).id;
      ws.endBox = ws.getBoxById(id) as ICell;
    }
    if (ws.startBox && ws.endBox) {

      let strObj = ws.getStringBetweenPoints(ws.startBox as ICell, ws.endBox as ICell);

      let str = strObj.str;
      let obj = ws.TestString(str);
      if (obj.match && !obj.found) {
        ws.alreadyFound.push(str);
        ws.wordList.forEach((item: Iword) => {
          if ((item.text.toLowerCase() === str.toLowerCase()) || (str.split('').reverse().join('').toLowerCase() === item.text.toLowerCase())) {
            item.found = true;
          }
        });
        strObj.ids.forEach((item: number[]) => {
          let [i, j] = item;
          (ws.gridArr[i][j] as ICell).used = true;
        });
      }

      ws.startBox = null;
      ws.endBox = null;

      changeContent(ws.wordList as Iword[]);
    }
  }


  function hasSelectionStarted() {
    return ws.startBox;
  }


  let gridArr = ws.gridArr.slice();
  let wordListArr = ws.wordList.slice();

  return (
    <div className='row'>
      <Col md={10}>
        <div className='grid'>
          {gridArr.map((row: ICell[] | string[], i: number) => {
            return (<WordSearchRow row={row} key={`row-${i}`}
                                   endBox={ws.endBox}
                                   selectionStart={selectionStart}
                                   selectionEnd={selectionEnd} hasSelectionStarted={hasSelectionStarted} />);
          })}
        </div>
      </Col>
      <Col md={2} className='d-flex justify-content-end'>
        <ButtonMotion variant={'show-clues'} onClick={() => setShowClues(true)}>? </ButtonMotion>
        {showClues &&
        <motion.div className='clues-info'>
          <CloseBtn close={() => setShowClues(false)} />
          <div id='word-list'>
            <ul>
              {wordListArr.map((item: Iword, i: number) => {
                let styleObj = {
                  textDecoration: item.found ? 'line-through' : 'none'
                };
                return (<li key={i} style={styleObj}>{item.text}</li>);
              })}
            </ul>
          </div>
        </motion.div>
        }
      </Col>
    </div>
  );
}
