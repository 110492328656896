import React, {ChangeEvent, Suspense, useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';
import {
  GamesBaseProvider,
  WorldsBaseProvider,
  GamesMechanicBaseProvider,
  GameMechanicEditProvider,
  GamePlayProvider,
  GameEditProvider,
} from 'src/contexts';
import { ButtonMotion, MainSidebar } from 'src/components';
// ToDo remove this scss and move into the individual wrappers,
//  like island games or island world etc.
import 'bootstrap/dist/css/bootstrap.min.css';
import 'src/assets/styles/main.scss';
import { getNotifications, markAsDoneNotification } from "../api/v0";
import AzureAuth from "../azure-component/AzureAuth";
// @ts-ignore
import { AlertList, Alert, AlertContainer } from "react-bs-notifier";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

export default function PlayerMainContainer() {

  const account = AzureAuth.getAccount();

  const [notifications, setNotifications] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    if (account?.localAccountId) {
      getNotifications(account.localAccountId, setNotifications, (errorMessage: string) => console.log(errorMessage));
    }
  }, [account?.localAccountId]);
  /**
   * @description - The sidebar visibility variable and toggle method to switch
   * the sidebar state from hidden to visible
   */
  const [sideBarVisibility, setSideBarVisibility] = useState<boolean>(false);
  const onSideBarToggle = () => {
    setSideBarVisibility(!sideBarVisibility);
  };

  // ToDo add auth state listener for the user profile
  // return <Loading />;

  const onDismissNotification = (notificationId: string) => {
    const finalNotifications = notifications?.filter((notification) => notification.id !== notificationId) ?? [];
    markAsDoneNotification(notificationId, () => setNotifications(finalNotifications));
  }

  return (
    <WorldsBaseProvider>
      <GamesBaseProvider>
        <Suspense fallback={<div>Loading .... </div>}>
          <MainSidebar isMenuOpen={sideBarVisibility} onMenuToggle={onSideBarToggle} />
          <GameEditProvider>
            <GamePlayProvider>
              <GamesMechanicBaseProvider>
                <GameMechanicEditProvider>
                  <AlertList alerts={notifications} onDismiss={(e: any) => onDismissNotification(e.id)} showIcon={true} />
                  <Outlet />
                </GameMechanicEditProvider>
              </GamesMechanicBaseProvider>
            </GamePlayProvider>
          </GameEditProvider>
        </Suspense>
      </GamesBaseProvider>
    </WorldsBaseProvider>
  );
}
