import { THotspotGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the hotspot game
 *    type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function hotspotDbToViewConverter(
  dbData: Record<string, any>
): THotspotGame {
  return {
    questions: dbData.questions,
    answers: dbData.answers,
    boats: dbData.boats
  };
}
