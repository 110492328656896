import { useGameMechanic } from 'src/hooks';
import { TQuizGame } from './types';
import GameNotFound from '../../../not-found';
import React, { memo, useMemo, useState } from 'react';
import SingleChoice from './single-choice';
import MultiChoice from './multi-choice';
import TrueFalse from './true-false';
import { Col, Row } from 'react-bootstrap';
import ImgWithZoom from '../../../../components/img-with-zoom';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The quiz game component
 * @constructor
 */
function QuizGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
    points
  } = useGameMechanic();

  if (gameMechanic) {
    const { content, category } = gameMechanic;
    const { question, options, filePath } = content as unknown as TQuizGame;
    /**
     * @description
     *    The method is calculating the final points based on the selected
     *    correct answers.
     * @param { number[] } selectedOptionIndex
     *    The indexes of the user selected answers from the options.
     */
    const calculateCorrectAnswers = (selectedOptionIndex: number[]) => {
      const correctAnswers = options.filter((option, index) =>
        selectedOptionIndex.includes(index) && option.is_correct
      );
      if (selectedOptionIndex.length === correctAnswers.length) {
        return gameMechanic.points || 0;
      }
      return 0;
    };

    /**
     * @description
     *    The method is handling the logic for
     * @param { number[] } selectedOptionIndex
     *    The indexes of the user selected answers from the options.
     */
    const handleAnswersClick = (selectedOptionIndex: number[]) => {
      changeCorrectAnswersPoints(
        calculateCorrectAnswers(selectedOptionIndex) + points
      );
    };

    const QuizGameBody = useMemo(() => {
      switch (category) {
        case 'quiz_true_false':
          return <TrueFalse
            gameData={content as unknown as TQuizGame}
            onAnswersSelect={handleAnswersClick}
            key={Math.random()}
          />;
        case 'quiz_multi_choice':
          return <MultiChoice
            gameData={content as unknown as TQuizGame}
            onAnswersSelect={handleAnswersClick}
            key={Math.random()}
          />;
        case 'quiz_single_choice':
          return (
            <SingleChoice
              gameData={content as unknown as TQuizGame}
              onAnswersSelect={handleAnswersClick}
              key={Math.random()}
            />
          );
        default:
          return <GameNotFound />;
      }
    }, [category, content]);

    return (
      <div className='question-container'>
        <Row>

          {filePath ? (<Col lg={6} md={12}>{filePath.includes('video') ?
              <ImgWithZoom filePath={filePath} type='video' />
            :
              <ImgWithZoom filePath={filePath} />
            }
          </Col>) : null}

          <Col lg={6} md={12}
               className={category === 'quiz_true_false' ? 'd-flex flex-column justify-content-between' : ''}>
            <div dangerouslySetInnerHTML={{ __html: question }} />
            <div className='answers mt-3'>
              {QuizGameBody}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return <GameNotFound />;
}

export default memo(QuizGame);
