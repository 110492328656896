import React, { useEffect } from 'react';
import LevelInfo from '../level-info';
import { useGame } from '../../hooks';


export default function InfoTooltip() {
  const { gameTooltip, showTheGameInfoTooltip } = useGame();

  useEffect(()=>{
    return ()=> showTheGameInfoTooltip(null)
  },[])

  if (gameTooltip) {
    const { x, y, level, title } = gameTooltip;
    const directionClass = x - 180 < 0 || y - 220 < 0 ? ' right' : '';

    return (
      <div className={`info-tooltip${directionClass}`} style={{
        left: `${directionClass === ' right' ? x + 20 : x - 180}px`, top: `${directionClass === ' right' ?
          y-40 : y - 220}px`
      }}>
        <h2>{title}</h2>
        <LevelInfo level={level} />
        <div className='triangle'></div>
      </div>
    );
  }
  return null;
}