import { memo, useCallback, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';
import { ButtonMotion } from 'src/components';
import { Container } from 'react-bootstrap';
import { EnumGameMechanicCategory } from 'src/@constants';
import { TGameMechanic, TGameMechanicCategory } from 'src/@types';
// dummy data
import { QuizGameData } from '../game-mechanics/quiz/constants';
import { CalculationGameData } from '../game-mechanics/calculation/constants';
import { CrosswordGameData } from '../game-mechanics/crossword/constants';
import { EssayGameData } from '../game-mechanics/essay/constants';
import { JumbleWordsGameData } from '../game-mechanics/jamble-words/constants';
import {
  HighlightWordGameData
} from '../game-mechanics/highlight-word/constants';
import {
  InformativeGameData
} from '../game-mechanics/informative-game/constants';
import { MemoryGameData } from "../game-mechanics/memory/constants";
import { ScrambleGameData } from "../game-mechanics/scramble-game/constants";
import { DragDropTextToImageGameData } from "../game-mechanics/drag-drop-text-to-image/constants";
import { MarkSpotGameData } from "../game-mechanics/mark-spot/constants";
import { HotspotGameData } from "../game-mechanics/hotspot/constants";
import {
  DragDropImageGameData
} from "../game-mechanics/drag-drop-image/constants";
import {
  SimulationDialogGameData
} from "../game-mechanics/simulation-dialogue/constants";
import {
  DragDropTextGameData
} from "../game-mechanics/drag-drop-text/constants";
import { FillInGameData } from "../game-mechanics/fill-in-game/constants";
// ToDo move all icons into the icon picker component
import FillInBlanksIcon from 'src/assets/svg/game-types/fill-in-blanks-icon';
import DragDropIcon from 'src/assets/svg/game-types/drag-drop-icon';
import TrueOrFalseIcon from 'src/assets/svg/game-types/true-false-icon';
import JumbleWordsIcon from 'src/assets/svg/game-types/jumble-icon';
import CrosswordsIcon from 'src/assets/svg/game-types/crossword-icon';
import MemoryIcon from 'src/assets/svg/game-types/memory-icon';
import HotspotIcon from 'src/assets/svg/game-types/hotspot-icon';
import CalculationIcon from 'src/assets/svg/game-types/calculation-icon';
import ScrambleIcon from 'src/assets/svg/game-types/scrumble-icon';
import SimulationIcon from 'src/assets/svg/game-types/simulation-icon';
import { useEditGame } from '../../../hooks';
import {WordSearchGameData} from "../game-mechanics/word-search/constants";
import EditorCloseBtn from '../../../assets/svg/buttons/editor-close-btn';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

type GameType = {
  title: string,
  icon: string,
  category: TGameMechanicCategory,
  disabled?: boolean,
}

/**
 * @description
 *    The game mechanic picker component that allows to select the game mechanic
 *    in order to edit or add it's content
 * @constructor
 */
function GameMechanicPicker() {
  /**
   * @description
   *    The editing game hook methods and variables in order to parse and
   *    process the game play mode.
   */
  const {
    game,
    page,
    updateGame,
    createGameMechanic,
  } = useEditGame();
  // Navigation
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  /**
   * @description
   *    The method is handling the game mechanic type button click event.
   *    The logic of the method is to generate dummy content of the game
   *    mechanic object
   * @param { TGameMechanic } gameMechanic
   *    Selected game mechanic dummy data passed from the picker view.
   */
  const handleGameMechanicTypePicker = (gameMechanic: TGameMechanic) => {
    if (game) {
      let finalContent = game.content ? [...game.content] : [];
      finalContent[page] = gameMechanic;

      updateGame({
        ...game,
        content: finalContent
      });
      createGameMechanic(gameMechanic, game.id || "0");
    }
  };
  function selectGame(index:number){
    setActiveIndex(prev => prev === index ? -1 : index)
  }

  const gameTypes: GameType[] = [
    { title: 'Fill In Blanks', icon: 'FillInBlanksIcon', category: EnumGameMechanicCategory.fill_in_game },
    { title: 'Essay', icon: 'FillInBlanksIcon', category: EnumGameMechanicCategory.essay },
    { title: 'Mark Spot', icon: 'DragDropIcon', category: EnumGameMechanicCategory.mark_spot },
    { title: 'Drag & Drop Text To Image', icon: 'DragDropIcon', category: EnumGameMechanicCategory.drag_drop_text_to_image },
    { title: 'Drag & Drop Text', icon: 'DragDropIcon', category: EnumGameMechanicCategory.drag_drop_text },
    { title: 'True or False', icon: 'TrueOrFalseIcon', category: EnumGameMechanicCategory.quiz_true_false },
    { title: 'Single Choice', icon: 'TrueOrFalseIcon', category: EnumGameMechanicCategory.quiz_single_choice },
    { title: 'Multi Choice', icon: 'TrueOrFalseIcon', category: EnumGameMechanicCategory.quiz_multi_choice },
    { title: 'Jumble Words', icon: 'JumbleWordsIcon', category: EnumGameMechanicCategory.jumble_words },
    { title: 'Crosswords', icon: 'CrosswordsIcon', category: EnumGameMechanicCategory.crossword },
    { title: 'Memory Game', icon: 'MemoryIcon', category: EnumGameMechanicCategory.memory },
    { title: 'Battleship', icon: 'HotspotIcon', category: EnumGameMechanicCategory.hotspot },
    { title: 'Calculation', icon: 'CalculationIcon', category: EnumGameMechanicCategory.calculation },
    { title: 'Drag & Drop Image', icon: 'DragDropIcon', category: EnumGameMechanicCategory.drag_drop_image },
    { title: 'Scramble Sentence', icon: 'ScrambleIcon', category: EnumGameMechanicCategory.scramble_sentence },
    { title: 'Simulation', icon: 'SimulationIcon', category: EnumGameMechanicCategory.simulation_dialogue },
    // { title: 'Simulation', icon: 'SimulationIcon', category: EnumGameMechanicCategory.simulation_game, disabled: true },
    { title: 'Highlight Text', icon: 'FillInBlanksIcon', category: EnumGameMechanicCategory.highlight_word_game },
    { title: 'Informative', icon: 'FillInBlanksIcon', category: EnumGameMechanicCategory.informative_game },
    { title: 'Word Search', icon: 'CrosswordsIcon', category: EnumGameMechanicCategory.word_search },
  ];

  const Icon = useCallback((icon: string) => {
    switch (icon) {
      case 'FillInBlanksIcon' :
        return <FillInBlanksIcon />;
      case 'DragDropIcon' :
        return <DragDropIcon />;
      case 'TrueOrFalseIcon' :
        return <TrueOrFalseIcon />;
      case 'JumbleWordsIcon' :
        return <JumbleWordsIcon />;
      case 'CrosswordsIcon' :
        return <CrosswordsIcon />;
      case 'CalculationIcon' :
        return <CalculationIcon />;
      case 'MemoryIcon' :
        return <MemoryIcon />;
      case 'HotspotIcon' :
        return <HotspotIcon />;
      case 'ScrambleIcon' :
        return <ScrambleIcon />;
      case 'SimulationIcon' :
        return <SimulationIcon />;
    }
  }, []);

  /**
   * @description
   *    The method is handling the game mechanic category selection event.
   *    Whenever the game mechanic is selected and confirmed, it will trigger
   *    the handleGameMechanicTypePickerCategory callback method that provided by parent
   *    component.
   */
  const handleOnGameMechanicSelectionConfirm = () => {
    if (activeIndex >= 0) {
      const category =
        gameTypes[activeIndex].category as unknown as TGameMechanicCategory;

      switch (category) {
        case "quiz_multi_choice":
          handleGameMechanicTypePicker(QuizGameData(EnumGameMechanicCategory.quiz_multi_choice));
          break;
        case "quiz_single_choice":
          handleGameMechanicTypePicker(QuizGameData(EnumGameMechanicCategory.quiz_single_choice));
          break;
        case "quiz_true_false":
          handleGameMechanicTypePicker(QuizGameData(EnumGameMechanicCategory.quiz_true_false));
          break;
        case "crossword":
          handleGameMechanicTypePicker(CrosswordGameData);
          break;
        case "drag_drop_text":
          handleGameMechanicTypePicker(DragDropTextGameData);
          break;
        case "drag_drop_image":
          handleGameMechanicTypePicker(DragDropImageGameData);
          break;
        case "drag_drop_text_to_image":
          handleGameMechanicTypePicker(DragDropTextToImageGameData);
          break;
        case "mark_spot":
          handleGameMechanicTypePicker(MarkSpotGameData);
          break;
        case "jumble_words":
          handleGameMechanicTypePicker(JumbleWordsGameData);
          break;
        case "memory":
          handleGameMechanicTypePicker(MemoryGameData);
          break;
        case "essay":
          handleGameMechanicTypePicker(EssayGameData);
          break;
        case "hotspot":
          handleGameMechanicTypePicker(HotspotGameData);
          break;
        case "calculation":
          handleGameMechanicTypePicker(CalculationGameData);
          break;
        case "fill_in_game":
          handleGameMechanicTypePicker(FillInGameData);
          break;
        case "scramble_sentence":
          handleGameMechanicTypePicker(ScrambleGameData);
          break;
        case "simulation_dialogue":
          handleGameMechanicTypePicker(SimulationDialogGameData);
          break;
        case "word_search":
          handleGameMechanicTypePicker(WordSearchGameData);
          break;
        case "highlight_word_game":
          handleGameMechanicTypePicker(HighlightWordGameData);
          break;
        case "informative_game":
          handleGameMechanicTypePicker(InformativeGameData);
          break;
        default:
          break;
      }
    }
  }

  return (
    <Container className="islands">
      <div className='game-wrapper animate'>
        <EditorCloseBtn close={()=> navigate(-1)} />
        {/* HEADER SECTION OF THE GAME PLAYABLE VIEW */}
        <div className='game-info'>
          <h2 className='title'>{game?.title ?? ''}</h2>
          <p>{game?.description ?? ''}</p>
        </div>
        <div className='game-container editor game-picker'>
          {gameTypes.map((game, i) => (
            <motion.div
              key={game.category}
              whileTap={{scale: 0.97}}
              className={`game-type ${game.disabled ? " disabled" : ""}`}>
              <button
                className={
                  `custom-picker btn ${activeIndex ===  i ? "active" : ""}`
                }
                onClick={()=> selectGame(i)}
              >
                {game.title} {Icon(game.icon)}
              </button>
            </motion.div>
          ))}
        </div>
        <div className='submit-btn'>
          <ButtonMotion
            className='btn-block'
            variant={'success'}
            disabled={activeIndex < 0}
            onClick={handleOnGameMechanicSelectionConfirm}
          >
            Next
          </ButtonMotion>
        </div>
      </div>
    </Container>
  );
}

export default memo(GameMechanicPicker);
