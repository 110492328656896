import { TGameMechanic } from "src/@types";
import { EnumGameMechanicCategory } from "src/@constants";

export const WordSearchGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.word_search,
  points: 100,
  content: {
    wordList: [],
  }
}
