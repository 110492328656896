import { RouteObject, Navigate } from 'react-router-dom';
import { PATH_GLMS_PLAYER, PATH_MAIN } from 'src/routes/paths';
import Analytics from "../games/analytics";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The route of the main functionality
 */
export const analyticsRoutes: RouteObject = {
  path: 'analytics',
  element: <Analytics />
};
