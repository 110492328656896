import { TSimulationGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the simulation game
 *    type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function simulationDbToViewConverter(
  dbData: Record<string, any>
): TSimulationGame {
  return {
    initial_items: dbData.initial_items?.map((item: Record<string, any>) => ({
      description: item.description,
      id: item.id,
    })),
    answer: dbData.answer,
  }
}
