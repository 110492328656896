import { memo } from 'react';

function ImageBtn() {
  return (
    <svg  viewBox='0 0 60 52' fill='none' xmlns='http://www.w3.org/2000/svg' className="image-btn">
      <g clipPath='url(#clip0_201_223)'>
        <path
          d='M6.96429 52H53.0357C56.8754 52 60 48.9052 60 45.102V6.89796C60 3.0948 56.8754 0 53.0357 0H6.96429C3.12455 0 0 3.0948 0 6.89796V45.102C0 48.9052 3.12455 52 6.96429 52ZM53.0357 3.18367C55.1036 3.18367 56.7857 4.8498 56.7857 6.89796V45.102C56.7857 47.1502 55.1036 48.8163 53.0357 48.8163H6.96429C4.89643 48.8163 3.21429 47.1502 3.21429 45.102V6.89796C3.21429 4.8498 4.89643 3.18367 6.96429 3.18367H53.0357Z'
          fill='white' />
        <path
          d='M13.2724 44.5714H48.8706C50.2875 44.5714 51.5679 43.7954 52.2107 42.5458C52.8549 41.2949 52.7384 39.8145 51.9067 38.679L37.7786 19.3554C37.2724 18.6683 36.4621 18.2597 35.609 18.2597C34.7558 18.2597 33.9415 18.6696 33.4407 19.3554L25.9353 29.6188L23.5661 26.3794C23.0639 25.6922 22.2523 25.2823 21.3965 25.2823C20.5407 25.2823 19.7264 25.6936 19.2255 26.3834L10.2335 38.6803C9.40315 39.8171 9.28663 41.2989 9.93083 42.5485C10.5737 43.7981 11.8527 44.5728 13.271 44.5728L13.2724 44.5714ZM35.6116 21.8161L49.3058 40.5467C49.425 40.7086 49.4411 40.9208 49.35 41.0999C49.259 41.2776 49.0755 41.3878 48.8719 41.3878H13.2724C13.0715 41.3878 12.888 41.2777 12.7956 41.0986C12.7032 40.9195 12.7206 40.7086 12.8384 40.5454L21.3991 28.8374L23.7697 32.0795C24.2665 32.7653 25.0782 33.1752 25.9366 33.1752C26.7951 33.1752 27.6081 32.764 28.109 32.0742L35.6116 21.8148V21.8161Z'
          fill='white' />
        <path
          d='M14.4643 22.2857C18.896 22.2857 22.5 18.716 22.5 14.3265C22.5 9.93704 18.896 6.36735 14.4643 6.36735C10.0326 6.36735 6.42857 9.93704 6.42857 14.3265C6.42857 18.716 10.0326 22.2857 14.4643 22.2857ZM14.4643 9.55102C17.1228 9.55102 19.2857 11.6934 19.2857 14.3265C19.2857 16.9597 17.1228 19.102 14.4643 19.102C11.8058 19.102 9.64286 16.9597 9.64286 14.3265C9.64286 11.6934 11.8058 9.55102 14.4643 9.55102Z'
          fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_201_223'>
          <rect width='60' height='52' fill='white' transform='matrix(-1 0 0 1 60 0)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default memo(ImageBtn);
