import { EnumGameMechanicCategory } from "src/@constants";
export const DragDropTextGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.drag_drop_text,
  points: 100,
  content: {
    sentence: '',
    sentences: null,
    missing_words: [{ word: '', index: 0 }, ],
    filePath: null,
  }
}
