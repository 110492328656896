import React, { useEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Col, Container, Row } from 'react-bootstrap';
// ToDo fork this library
import Crossword, {
  ThemeProvider,
  CrosswordProviderImperative
} from '@jaredreisinger/react-crossword';
import { useGameMechanic } from 'src/hooks';
import GameNotFound from '../../../not-found';
import { TCrosswordGame } from './types';
import purpleCell from 'src/assets/images/purple-cell.svg';
import CloseBtn from '../../../../assets/svg/buttons/close-btn';
import { ButtonMotion } from '../../../../components';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The crossword game component
 * @constructor
 */
export default function CrosswordGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
    isLoading,
    status
  } = useGameMechanic();

  if (!gameMechanic || isLoading) {
    return <GameNotFound />;
  }
  const [content, setContent] = useState(gameMechanic.content as unknown as TCrosswordGame)
  const { across, down } = content
  const [showClues, setShowClues] = useState(false);

  const crosswordRef = useRef<CrosswordProviderImperative>(null);

  const cluesObj = useMemo(() => {
    if (across && down) {
      let obj: { across: string[], down: string[] } = { across: [], down: [] };
      let acrossValues = Object.values(across);
      let downValues = Object.values(down);
      acrossValues.map((el: any) => obj.across.push(el.clue));
      downValues.map((el: any) => obj.down.push(el.clue));
      return obj;
    }

  }, [across, down]);

  const handleAnswersSubmit = (isCorrect: boolean) => {
    if (gameMechanic) {
      if (isCorrect) {
        // ToDo check correctness
        changeCorrectAnswersPoints(100);
      } else {
        changeCorrectAnswersPoints(0);
      }
    }
  };

  return (
    <ThemeProvider
      theme={{
        allowNonSquare: true,
        columnBreakpoint: '9999px',
        gridBackground: 'transparent',
        cellBackground: 'transparent',
        cellBorder: '#ffff',
        textColor: 'white',
        numberColor: 'white',
        focusBackground: '#9db168',
        highlightBackground: '#C3CE39'
      }}
    >
      <Row>
        <Col md={8}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' height='0'>
            <defs>
              <clipPath id='cover-clip'>
                <rect width='100%' height='100%' />
              </clipPath>
            </defs>
            <defs>
              <pattern id='background-image' width='100%' height='100%' patternUnits='userSpaceOnUse'>
                <image href={purpleCell} width='100%' height='100%' clipPath='url(#cover-clip)' />
              </pattern>
            </defs>
          </svg>
          <Crossword
            ref={crosswordRef}
            data={content as unknown as TCrosswordGame}
            useStorage={false}
            onCrosswordComplete={handleAnswersSubmit}
          />
        </Col>
        <Col md={3} className='d-flex justify-content-end'><ButtonMotion variant={'show-clues'}
                                                                         onClick={() => setShowClues(true)}>? </ButtonMotion></Col>
      </Row>
      {showClues &&
      <motion.div className='clues-info'
                  initial={{ opacity: 0, display: 'none' }}
                  animate={{ opacity: 1, display: 'block' }}
                  exit={{ opacity: 0, display: 'none' }}
      >
        <CloseBtn close={() => setShowClues(false)} />
        <Container>
          <Row>
            <Col md={12}><h3>Across:</h3>
              <ul>
                {Object.entries(across).map(([key, acrossValue]) => <li
                  key={`across_${key}`}>{key}: {acrossValue.clue}</li>)}
              </ul>
            </Col>
            <Col md={12}><h3>Down:</h3>
              <ul>
                {Object.entries(down).map(([key, downValue]) => <li key={`down_${key}`}>{key}: {downValue.clue}</li>)}
              </ul>
            </Col>
          </Row>
        </Container>
      </motion.div>
      }
    </ThemeProvider>
  );
}

