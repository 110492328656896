import { useState, useEffect } from 'react';
import { TGameMechanicCategory } from 'src/@types';
import GameNotFound from '../../not-found';
import { GameQuiz, GameEditorQuiz } from './quiz';
import { GameCrossword, GameEditorCrossword } from './crossword';
import { GameEditorFillIn, GameFillIn } from './fill-in-game';
import { GameCalculation, GameEditorCalculation } from './calculation';
import { GameEssay, GameEditorEssay } from './essay';
import { GameHotspot, BattleshipsEditor } from './hotspot';
import { GameSimulation } from './simulation-game';
import { GameSimulationDialogue, SimulationDialogueEditor } from './simulation-dialogue';
import { GameDragDropImage, GameDragDropImageEditor } from './drag-drop-image';
import { GameDragDropText, GameEditorDragDropText } from './drag-drop-text';
import { DropTextToImageGameEditor, DropTextToImageGame } from './drag-drop-text-to-image';
import { JambleWordsGame, JambleWordsGameEditor } from './jamble-words';
import { HighlightWordsGame, HighlightWordsEditor } from './highlight-word';
import { MemoryGame, MemoryGameEditor } from './memory';
import { InformativeEditor, InformativeGame } from './informative-game';
import { ScrambleSentenceGame, ScrumbleSentenceEditor } from './scramble-game';
import { MarkSpotGameEditor, MarkSpotGame } from "./mark-spot";
import { WordSearchEditor, WordSearchGame } from './word-search';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The Game mechanic factory props that required parent component to pass the
 *    category of the game mechanic, so it can load the proper game;
 * @param { TGameMechanicCategory } category
 *    The game mechanic category variable
 */
interface IslandGameMechanicFactoryProps {
  category: TGameMechanicCategory;
  isEditView?: boolean;
  page?: number;
  gameId?: string;
}

export function IslandGameMechanicFactory(
  { category, isEditView = false, page = 0, gameId = 'none' }: IslandGameMechanicFactoryProps
) {
  /**
   * @description
   *    The listener method and action that listen to the game mechanic category
   *    change and return
   */
  const [
    currentPage,
    setCurrentPage
  ] = useState<number | null>(null);
  useEffect(() => {
    if (currentPage !== page) {
      setCurrentPage(page);
    }
  }, [category, page]);

  /**
   * @description
   *    The logic is getting the game mechanic view based on the provided game
   *    mechanic's category.
   */
  switch (category) {
    case 'quiz_true_false':
      return isEditView ? <GameEditorQuiz key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameQuiz key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'quiz_single_choice':
      return isEditView ? <GameEditorQuiz key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameQuiz key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'quiz_multi_choice':
      return isEditView ? <GameEditorQuiz key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameQuiz key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'crossword':
      return isEditView ? <GameEditorCrossword key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameCrossword key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'calculation':
      return isEditView ? <GameEditorCalculation key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameCalculation key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'fill_in_game':
      return isEditView ? <GameEditorFillIn key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameFillIn key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'essay':
      return isEditView ? <GameEditorEssay key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameEssay key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'hotspot':
      return isEditView ? <BattleshipsEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameHotspot key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'simulation_game':
      return <GameSimulation key={`${gameId}_${currentPage}_${category}_editor`} />;
    case 'simulation_dialogue':
      return isEditView ? <SimulationDialogueEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameSimulationDialogue key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'drag_drop_image':
      return isEditView ? <GameDragDropImageEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameDragDropImage key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'drag_drop_text':
      return isEditView ? <GameEditorDragDropText key={`${gameId}_${currentPage}_${category}_editor`} /> : <GameDragDropText key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'drag_drop_text_to_image':
      return isEditView ? <DropTextToImageGameEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <DropTextToImageGame key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'mark_spot':
      return isEditView ? <MarkSpotGameEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <MarkSpotGame key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'memory':
      return isEditView ? <MemoryGameEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <MemoryGame key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'jumble_words':
      return isEditView ? <JambleWordsGameEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <JambleWordsGame key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'highlight_word_game':
      return isEditView ? <HighlightWordsEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <HighlightWordsGame key={`${gameId}_${currentPage}_${category}_view`} />;
    case 'informative_game':
      return isEditView ? <InformativeEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <InformativeGame key={`${gameId}_${currentPage}_${category}_view`} />;
    case "scramble_sentence":
      return isEditView ? <ScrumbleSentenceEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <ScrambleSentenceGame key={`${gameId}_${currentPage}_${category}_view`} />;
    case "word_search":
      return isEditView ? <WordSearchEditor key={`${gameId}_${currentPage}_${category}_editor`} /> : <WordSearchGame key={`${gameId}_${currentPage}_${category}_view`} />;
    default:
      return <GameNotFound />;
  }
}