import { ButtonMotion } from 'src/components';
import { useGameEditMechanic } from 'src/hooks';
import GameNotFound from '../../../not-found';
import { TGameMechanicContent } from 'src/@types';
import { TWordSearchGame } from './types';
import { useState } from 'react';
import 'src/assets/styles/editors/word-search.scss'

export default function WordSearchEditor() {
  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();

  const [currentWord, setCurrentWord] = useState('');

  if (gameMechanic) {
    const { content } = gameMechanic;
    const { wordList =[]} = content as TWordSearchGame;
    const onGameMechanicContentChange = (finalContent: TWordSearchGame) => {
      updateGameMechanic({
        ...gameMechanic,
        content: finalContent as TGameMechanicContent
      });
    };

    const generateWordObj = () => ({
      text: currentWord,
      index: (content as TWordSearchGame).wordList?.length || 0,
      found: false
    });

    const saveWord = () => {
      const newWordItem = generateWordObj();
      onGameMechanicContentChange({ ...content, wordList: [newWordItem, ...wordList] });
      setCurrentWord('');
    };


    return (
      <div className='word-search-editor'>
        <div>
          <input type='text' className='form-control' placeholder='type a word' value={currentWord}
                 onChange={(e) => setCurrentWord(e.target.value)} />
          <ButtonMotion className="mt-2" variant={'success'} disabled={currentWord.length === 0} onClick={saveWord}>Save</ButtonMotion>
        </div>
        <div className='word-list'>
          <ul>{wordList.map((word) => {
            return (<li key={word.index}>{word.text}</li>);
          })}</ul>
        </div>
      </div>
    );
  }
  return <GameNotFound />;
}