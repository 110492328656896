import { TQuizGame } from './types';
import { memo, useMemo } from 'react';
import { useGameEditMechanic } from 'src/hooks';
import TrueFalseGameEditor from './true-false-editor';
import SingleChoiceEditor from './single-choice-editor';
import MultiChoiceEditor from "./multi-choice-editor";
import GameNotFound from 'src/games/not-found';
import { TGameMechanicContent } from 'src/@types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The quiz game editor component
 * @constructor
 */
function QuizGameEditor() {

  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();

  if (gameMechanic) {
    const { content, category } = gameMechanic;

    const validateCurrentContent = () => {
      return true;
    }

    const onGameMechanicContentChange = (finalContent: TGameMechanicContent) => {
      if (validateCurrentContent()) {
        updateGameMechanic({
          ...gameMechanic,
          content: finalContent as unknown as TGameMechanicContent,
        });
      }
    }

    return useMemo(() => {
      switch (category) {
        case "quiz_true_false":
          return (
            <TrueFalseGameEditor
              gameId={gameId}
              data={content as unknown as TQuizGame}
              onContentChange={onGameMechanicContentChange}
            />
          );
        case "quiz_single_choice":
          return (
            <SingleChoiceEditor
              gameId={gameId}
              data={content as unknown as TQuizGame}
              onContentChange={onGameMechanicContentChange}
            />
          )
        case "quiz_multi_choice":
          return (
            <MultiChoiceEditor
              gameId={gameId}
              data={content as unknown as TQuizGame}
              onContentChange={onGameMechanicContentChange}
            />
          );
        default:
          return <GameNotFound />;
      }
    }, [category, content]);
  }

  return <GameNotFound />;
}

export default memo(QuizGameEditor);
