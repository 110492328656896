import { TQuizGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the quiz game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function quizDbToViewConverter(dbData: Record<string, any>): TQuizGame {
  return {
    question: dbData.question,
    options: dbData.options.map((option: Record<string, any>) => ({
      title: option.title,
      is_correct: option.is_correct,
    })),
    filePath: dbData.filePath || null,
  }
}
