import { createContext, ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { TGame, TGameMechanic, TGameStatus } from 'src/@types';
import { EnumGameStatus } from 'src/@constants';
import {
  changeGameStatus,
  randomizeContentData,
  submitGameCurrentPoints,
  updateGameCurrentPoints
} from 'src/redux/slices';
import { useGame } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { PATH_GLMS_PLAYER } from '../routes/paths';
import AzureAuth from 'src/azure-component/AzureAuth';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    Global type/context for all functional games.
 *    The interface contains all necessary methods/variables/lifecycles for the
 *    games that are recognized by the main controller of the game.
 * @param { Error | string | NaN } error
 *    The error variable that may contain an error object if there is any error
 *    during the fetching process of the game data and UI
 * @param { boolean } isEditMode
 *    The boolean variable that shows if the game is in edit mode or not
 * @param { function } changeEditMode
 *    The method is changing the edit mode based on the provided input mode
 * @param { boolean } isPaused
 *    The variable contains the boolean value that shows if the game is in pause
 *    mode or not
 * @param { function } pauseGame
 *    The method is switching the game status from pause to playing
 * @param { boolean } isLoading
 *    The boolean variable that represents the game fetching process
 */
export interface GamesPlayInterface {
  // Data
  error: string | Error | null;
  isLoading: boolean;
  gameMechanic: TGameMechanic | null;
  userId: string;
  game: TGame | null;
  page: number;
  totalPages: number;
  points: number;
  isShowHints:boolean,
  isTimeUp:boolean,
  // Methods
  updatePoints: (finalPoints: number) => void;
  pauseGame: VoidFunction;
  resumeGame: VoidFunction;
  closeGame: VoidFunction;
  abortGame: VoidFunction;
  completeGame: (status:TGameStatus) => void;
  changePage: (pageNumber: number) => void;
}

/**
 * @description - Games base context to create context of games' lifecycle
 */
export const ContextGamePlay = createContext<GamesPlayInterface | null>(null);

/**
 * @description - Games' base lifecycle Provider hook. Contains lifecycle logic
 * @param children - Children components
 * @constructor - Adding listener for game lifecycle
 */
export function GamePlayProvider({ children }: { children: ReactNode }) {

  // ========================== INTERNAL VARIABLES ========================== //
  /**
   * @description - The global state-based variables and methods
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Getting the internal game related state
  const {
    error,
    isLoading,
    status,
    game,
    gameMechanic,
    points,
    page,
    isShowHints,
    isTimeUp
  } = useSelector((state) => state.game);

  /**
   * @description
   *    The variables and common methods from game's based hook
   */
  const { changePage, gameId, closeGame, loadGameMechanic } = useGame();
  const [ isRandomized, setIsRandomized ] = useState(false);

  const account = AzureAuth.getAccount();
  const accountId: string = account.localAccountId ?? 'None';
  const accountUserName: string = account.username ?? 'None';
  const accountName: string = account.name ?? 'None';

  // ======================================================================== //
  // ========================== LIFECYCLE METHODS =========================== //
  // ======================================================================== //

  /**
   * @description - The method is executing logic if the player paused the game
   */
  const pauseGame = () => {
    if (game) {
      dispatch(changeGameStatus(EnumGameStatus.paused));
    }
  }

  /**
   * @description - The method is executing logic if the player paused the game
   */
  const resumeGame = () => {
    if (game) {
      dispatch(changeGameStatus(EnumGameStatus.playing));
    }
  }

  /**
   * @description
   *    The method is executing logic if the player completed the game either
   *    with success status or failed.
   */
  const completeGame = (status: TGameStatus) => {
    if (game && game.id && game.title) {
      dispatch(changeGameStatus(status));
      const totalPoints = 100 * game.content.length;
      let finalPoints = 0;

      if (points >= totalPoints * 60 / 100) {
        finalPoints = 1;
      }
      if (points >= totalPoints * 75 / 100) {
        finalPoints = 2
      }
      if (points >= totalPoints * 90 / 100) {
        finalPoints = 3;
      }

      dispatch(submitGameCurrentPoints(finalPoints, accountId, game.id, game.title, accountUserName));
      closeGame()
      if (game.file?.show) {
        navigate(`/${PATH_GLMS_PLAYER.games.game.get(game.id || "0")}/feedback`);
      } else {
        navigate(`/${PATH_GLMS_PLAYER.games.game.get(game.id || "0")}`);
      }
    }
  }

  /**
   * @description - The method is executing logic if the player somehow aborted
   * the game
   */
  const abortGame = () => {
    if (game) {
      dispatch(changeGameStatus(EnumGameStatus.aborted));
    }
  }

  const updatePoints = (finalPoints: number) => {
    dispatch(updateGameCurrentPoints(finalPoints));
  }

  // ========================= VIEW METHODS/ACTIONS ========================= //
  /**
   * @description - The hook listener for the game selection. If the selected
   *  game ID exists, then trigger the game's data fetching process through
   *  API and store the data into the global state/redux
   */
  useEffect(() => {
    if (game?.randomize && game?.content && !isRandomized) {
      setIsRandomized(true)
      // dispatch(randomizeContentData(game.content));
    }
  }, [game]);

  /**
   * @description
   *    The provider view for the game play context
   */
  return (
    <ContextGamePlay.Provider
      value={{
        // Data
        error,
        isLoading,
        gameMechanic,
        userId: accountId,
        game,
        points: points,
        page,
        isShowHints,
        isTimeUp,
        totalPages: game?.content?.length ?? 0,
        // Methods
        pauseGame,
        resumeGame,
        abortGame,
        closeGame,
        completeGame,
        changePage,
        updatePoints,
      }}
    >
      {children}
    </ContextGamePlay.Provider>
  );
}
