import React, { ChangeEvent, memo } from 'react';
import { useGameMechanic } from 'src/hooks';
import { TCalculationGame } from './types';
import GameNotFound from '../../../not-found';
import TinyMceEditor from '../../../../components/tiny-mce-editor';
import ImgWithZoom from '../../../../components/img-with-zoom';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The calculation game component
 * @constructor
 */
function CalculationGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
    points
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const {
    question,
    answer,
    filePath
  } = gameMechanic.content as unknown as TCalculationGame;

  function fillAnswer(value: string) {
    if (value === String(answer)) {
      changeCorrectAnswersPoints((gameMechanic?.points || 0) + points);
    } else {
      changeCorrectAnswersPoints(points);
    }
  }

  return (
    <div className='calculation-game'>
      <div className='row'>
        {filePath && <div className='col-lg-6'>
          {filePath.includes('video') ?
            <ImgWithZoom filePath={filePath} type='video' />
            :
            <ImgWithZoom filePath={filePath} />}</div>}
        <div className={`col-lg-${filePath ? 6 : 12}`}>
          <div className='mb-2' dangerouslySetInnerHTML={{ __html: question }} />
          <TinyMceEditor handleChange={fillAnswer} maxHeight={130} />
        </div>
      </div>
    </div>);
};

export default memo(CalculationGame);
