import { memo, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import classnames from 'classnames';
import { IslandGameMechanicFactory } from '../game-mechanics';
import { ButtonMotion, Loading } from 'src/components';
import { useEditGame, usePlayGame } from 'src/hooks';
import CloseBtn from '../../../assets/svg/buttons/close-btn';
import BuyHintPopup from '../../../components/popups/buy-hint-popup';
import { useNavigate } from 'react-router-dom';
import { PATH_GLMS_PLAYER } from '../../../routes/paths';
import { EnumGameStatus } from '../../../@constants';
import { motion } from 'framer-motion';
import Timer from 'src/components/timer';
import 'src/assets/styles/gamification.scss'
import 'src/assets/styles/media.scss'
import TimeUpPopup from '../../../components/popups/time-up-popup';


 const transition = {
  type: 'spring',
  damping: 0,
  stiffness: 0,
   //bounce:0.25,
  duration: 0.3,
  scale: { duration: 0.1 },
  opacity: { duration: 0.3 }
};
// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The playable view component of the game that renders the game mechanic and
 *    allow to play the game.
 * @constructor
 */
function IslandGamePlayView() {
  /**
   * @description
   *    The playing game hook methods and variables in order to parse and
   *    process the game play mode.
   */
  const {
    gameMechanic,
    game,
    page,
    totalPages,
    isShowHints,
    changePage,
    completeGame,
    points,
    isTimeUp,
    closeGame
  } = usePlayGame();
  const { showTheHintsPopup } = useEditGame();
  const navigate = useNavigate();

  /**
   * @description
   *    The method is handling the next page button click event.
   *    The logic is following: when there is still content available for the
   *    game (means has next page) then change page number in the store.
   *    That will trigger automatically fetch the gameMechanic object of the
   *    specific page from a game play provider component.
   */
  const handleOnNextPageClick = () => {
    if (page < totalPages - 1) {
      changePage(page + 1);
    } else {
      completeGame(points > 50 ? EnumGameStatus.succeeded : EnumGameStatus.failed);
    }
  };

  const handleCloseEditView = () => {
    closeGame();
    navigate(`/${PATH_GLMS_PLAYER.games.game.get(game?.id || '0')}`);
  };

  /**
   * @description
   *    The view of the Island game play component
   */
  if (gameMechanic && game) {
    return (
      <Container className='islands '>
        <motion.div
          className={`game-wrapper gamified${isTimeUp ? ' pe-none' : ''}`}
          key={page}
          layout
          animate='opening'
          initial='closing'
          variants={{
            opening: { opacity: 1 },
            closing: {
              opacity: 0
            }
          }}
          transition={{
            ...transition
          }}
        >
          <CloseBtn close={handleCloseEditView} />
          {isShowHints && (
            <BuyHintPopup
              key={`hints_${game.id}`}
              currentHints={game.hints ?? []}
            />
          )}
          { isTimeUp &&  <TimeUpPopup /> }
          {/* HEADER SECTION OF THE GAME PLAYABLE VIEW */}
          <div className='game-info'>
            <h2 className='title'>{game.title}</h2>
            <p dangerouslySetInnerHTML={{ __html: game.description}} />
          </div>
          {/* BODY/PLAYABLE SECTION OF THE GAME VIEW */}
          <div className='game-container'>
            <div className='game-content'>
              <IslandGameMechanicFactory
                category={gameMechanic?.category}
                key={page}
              />
            </div>
          </div>
          {/* FOOTER SECTION OF THE GAME PLAYABLE VIEW */}
          <div className='btn-wrapper with-count'>
            <h4 className={'count'}>{page + 1}/{totalPages} </h4>
            <div>
              <ButtonMotion className="left-btn" variant='hint' onClick={() => showTheHintsPopup(true)}> Hint </ButtonMotion>
              <ButtonMotion
                variant='success'
                className={classnames('right-btn',{ disabled: false })}
                onClick={handleOnNextPageClick}
              >
                {page < totalPages - 1 ? 'Next' : 'Submit'}
              </ButtonMotion>
            </div>
          </div>
          <Timer timeInMinute={game.timer ?? 0} />
        </motion.div>
      </Container>
    );
  }

  return <Loading />;
}

export default memo(IslandGamePlayView);
