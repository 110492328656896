import { useState, memo } from 'react';
import { useGameMechanic } from 'src/hooks';
import { Reorder } from 'framer-motion';
import { TSimulationGame, TSimulationGameItem } from './types';
import GameNotFound from '../../../not-found';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The calculation game component
 * @constructor
 */
function SimulationGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const {
    initial_items,
    answer,
  } = gameMechanic.content as unknown as TSimulationGame;

  const [items, setItems] = useState<TSimulationGameItem[]>(initial_items);

  return (
    <div className='simulation-game'>
      <Reorder.Group axis='y' onReorder={setItems} values={items}>
        {items.map((item) => (
          <SimulationItem key={item.id} item={item} />
        ))}
      </Reorder.Group>
    </div>
  );
}

export default SimulationGame;

/**
 * @description
 *    The simulation item component
 * @param item
 * @constructor
 */
function SimulationItemComponent({ item }: { item: TSimulationGameItem }) {
  const { id, description } = item;
  return (
    <Reorder.Item value={item} id={id}> {/*add drag attribute for all axis directions*/}
      <span>{description}</span>
    </Reorder.Item>
  );
}

const SimulationItem = memo(SimulationItemComponent);
