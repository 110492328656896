import { Col, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

interface IBarMenuProps {
  isOpen: boolean,
  badges: number,
  notifications: number
}

const transition = {
  type: 'spring',
  damping: 0,
  stiffness: 0,
  duration: 0.6
};

const badgesArr = [{ badge: 'badge 1', svg: 'badges' }, {
  badge: 'badge 2',
  svg: 'badges'
}, { badge: 'badge 3', svg: 'badges' }];
const notificationsArr = ['some text', 'notification', 'another notification'];

export default function BarMenuContent({ isOpen, badges, notifications }: IBarMenuProps) { //TODO update props to pass notifications and badges full data
  return (
    <motion.div className='container bar-menu-content' animate={isOpen ? 'opening' : 'closing'}
                custom='sync'
                variants={{
                  opening: { height: 'auto', opacity: 1 },
                  closing: {
                    height: 0,
                    opacity: 0,
                    margin: 0
                  }
                }}
                transition={{
                  ...transition,
                  height: { delay: isOpen ? 0 : 0.2, duration: 0.2 },
                  opacity: { delay: 0.2, duration: isOpen ? 0.2 : 0 }
                }}>
      <ul className="p-5">
        <li className="mb-5">
          <Row>
            <Col md={5}>
              <div className='d-flex'>
                <div>
                  <img src={`/assets/images/bar-menu-icons/badges.svg`} className='pe-3 list-lg-icon' alt='icon' />
                </div>
                <div><h4 className='text-nowrap fs-5 list-title'><strong>My Badges</strong></h4>
                  <div className='text-center'>
                    {badges}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={5}>
              <Swiper navigation={true} modules={[Navigation]} className='mySwiper'>
                {badgesArr.map(item => {
                  return (
                    <SwiperSlide key={item.badge}>
                      <div className='d-flex justify-content-center flex-column'>
                        <img src={`/assets/images/bar-menu-icons/${item.svg}.svg `} className='img-fluid list-lg-icon'
                             alt='item' />
                        <p className="mt-2">{item.badge}</p>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Col>
          </Row></li>
        <li>
          <Row>
            <Col md={5}>
              <div className='d-flex'>
                <div>
                  <img src={`/assets/images/bar-menu-icons/bell.svg`} className='pe-3 list-lg-icon' alt='icon' />
                </div>
                <div><h4 className='text-nowrap fs-5 list-title'><strong>My Notifications</strong></h4>
                  <div className='text-center'>
                    {notifications}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <ul>
                {notificationsArr.map((notification, i) => {
                  return (
                    <li key={`ntf-${i}`}>
                      <div className='d-flex'>
                        <img src={`/assets/images/bar-menu-icons/bell.svg`} className='pe-3 list-sm-icon' alt='icon' />
                        <h4 className='text-nowrap fs-5 list-title'><strong>{notification}</strong></h4>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </li>
      </ul>
    </motion.div>
  );
}