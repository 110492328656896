import {
  TGame,
  TGameMechanic,
  TGameLevel,
  TGameMechanicCategory,
} from 'src/@types';
import { IGameView } from 'src/@interfaces';
import {
  convertDbDataToGameMechanicContent,
  createGameMechanicDummyContent,
} from 'src/games/utils';
import { EnumGameLevel, EnumGameType } from "../../@constants";
import { Timestamp } from "firebase/firestore";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the database content into the game
 *    mechanic object in order to view it and parse in the component.
 * @param data
 *    The data that comes from the server side
 */
export function convertDbDataToGameMechanic(
  data: Record<string, any>
): TGameMechanic {
  const category = data.category as unknown as TGameMechanicCategory;
  return {
    title: data.title,
    description: data.description,
    points: data.points,
    category,
    content: convertDbDataToGameMechanicContent({ category, data: data.content }),
  };
}

/**
 * @description
 *    The method is creating the empty/dummy game mechanic object in order to
 *    view it and parse in the component.
 * @param data
 *    The data that is provided from the UI.
 */
export function createDummyGameMechanic(data: Record<string, any>): TGameMechanic {
  const category = data.category as unknown as TGameMechanicCategory;
  return {
    title: data.title,
    description: data.description,
    points: data.points,
    category,
    content: convertDbDataToGameMechanicContent({ category, data: data.content }),
  };
}

/**
 * @description - The method is converting the database content into the game
 * object in order to view them in the component
 */
export function convertDbDataToGame(data: Record<string, any>): TGame {
  const level = Object.values(EnumGameLevel).indexOf(data.level) ?? 0;
  return {
    id: data.id,
    mini_world_id: data.mini_world_id,
    points: 120,
    created_by: data.created_by,
    updated_by: data.updated_by ?? null,
    created_at: data.created_at,
    updated_at: data.updated_at,
    title: data.title,
    description: data.description,
    course_id: data.course_id ?? null,
    content: data.content,
    level: level + 1 as unknown as TGameLevel,
    type: data.type ?? EnumGameType.core,
    message: data?.message ?? null,
    hints: data?.hints ?? null,
    file: data?.file ?? null,
    timer: data.timer ?? null,
    points_by_answers: data.points_by_answers ?? 0,
    points_by_time: data.points_by_time ?? 0,
    hints_timer: data.hints_timer ?? 0,
    program_name: data.program_name ?? null,
    randomize: data.randomize ?? null,
    progress: Math.floor(Math.random() * 101),
    status: data.status ?? null,
    tenant_id: data?.tenant_id ?? null,
  }
}

/**
 * @description
 *    The method is converting db data into the game view object in order to
 *    parse it in the view component
 * @param { Record<string, any> } data
 *    The db data object that is fetched through server
 */
export function convertDBDataToGameView(data: Record<string, any>): IGameView {
  return {
    id: data.id,
    mini_world_id: data.mini_world_id,
    title: data.title,
    description: data.description,
    level: data.level,
    status: data.status,
    pages: data.content?.length || 0,
    content: data.content ?? null,
    type: data.type ?? EnumGameType.core,
    message: data.message ?? null,
    hints: data.hints ?? null,
    file: data.file ?? null,
    timer: data.timer ?? null,
    program_name: data.program_name ?? null,
    created_at: data.created_at ? data.created_at?.toDate().toDateString() : null,
    updated_at: data.updated_at ? data.updated_at?.toDate().toDateString() : null,
    created_by: data.created_by ?? null,
    updated_by: data.updated_by ?? null,
    progress: Math.floor(Math.random() * 101),
    tenant_id: data?.tenant_id ?? null,
  }
}
