import { memo } from 'react';

function DragDropIcon() {
    return (
        <svg  viewBox='0 0 66 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M44.0762 34.7208L66.0015 47.5427L56.6423 50.2251L63.3318 61.8441L57.8789 65L51.1894 53.384L44.1914 60.1707L44.0777 34.7208H44.0762ZM37.7792 12.6266H44.0762V18.9398H59.8181C60.6534 18.9398 61.4532 19.2727 62.0435 19.8646C62.6338 20.4564 62.9659 21.2598 62.9659 22.0972V34.7223H56.6688V25.2531H25.1866V56.8166H37.7792V63.1298H22.0374C21.2021 63.1298 20.4023 62.7969 19.8105 62.2051C19.2202 61.6133 18.8881 60.8099 18.8881 59.9739V44.1915H12.5911V37.8782H18.8881V22.0957C18.8881 21.2583 19.2202 20.4564 19.8105 19.8631C20.4008 19.2713 21.2021 18.9384 22.0374 18.9384H37.7792V12.6251V12.6266ZM6.29703 37.8767V44.19H0V37.8767H6.29703ZM6.29703 25.2516V31.5649H0V25.2516H6.29703ZM6.29703 12.6266V18.9398H0V12.6266H6.29703ZM6.29703 0V6.31328H0V0H6.29703ZM18.8896 0V6.31328H12.5926V0H18.8896ZM31.4822 0V6.31328H25.1852V0H31.4822ZM44.0748 0V6.31328H37.7777V0H44.0748Z'
                fill='black' />
        </svg>

    );
}

export default memo(DragDropIcon);
