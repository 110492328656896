import { RouteObject } from 'react-router-dom';
import GamesPublicationsWorkflow from 'src/games/publications';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The route of the main functionality
 */
export const gamesWorkflowRoutes: RouteObject = {
  path: 'workflow',
  element: <GamesPublicationsWorkflow />
};
