import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Azure } from 'src/azure-component';
import {
  mainRoutes,
  glmsPlayerCoursesRoutes,
  glmsPlayerGamesRoutes,
  analyticsRoutes,
  gamesOverviewRoutes,
  glmsProfileRoutes,
  gamesWorkflowRoutes
} from 'src/routes';
import 'src/App.css';
import { AuthProvider } from "./contexts";
// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

function App() {
  return (
      <Azure forceLogin={true}>
        <AuthProvider>
          {useRoutes([
            mainRoutes,
            glmsPlayerCoursesRoutes,
            glmsPlayerGamesRoutes,
            analyticsRoutes,
            gamesOverviewRoutes,
            glmsProfileRoutes,
            gamesWorkflowRoutes
          ])}
        </AuthProvider>
      </Azure>
  );
}

export default App;
