import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import MovieCard from './movie-card';
import { useGameMechanic } from 'src/hooks';
import GameNotFound from '../../../../not-found';
import { TMemoryGame } from '../types';

function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index
    const j = Math.floor(Math.random() * (i + 1));

    // Swap elements at indices i and j
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function MemoryGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
    isLoading,
    points,
    status
  } = useGameMechanic();

  if (!gameMechanic || isLoading) {
    return <GameNotFound />;
  }

  const { cards } = gameMechanic.content as TMemoryGame;
  const cardsOriginalArr: any[] = [];
  cards?.map((card, index) => {
    cardsOriginalArr.push({
      ...card.first,
      id: `${index}`
    });
    cardsOriginalArr.push({
      ...card.second,
      id: `${index}`
    });
  });
  const [cardsArr, setCardsArr] = useState(shuffleArray(cardsOriginalArr));
  const [isMatch, setIsMatch] = useState<boolean>(false);
  const [flipped, setFlipped] = useState<number[]>([]);
  const [opened, setOpened] = useState<(string | undefined)[]>([]);
  const timeout = useRef<number>();
  const firstCard = useMemo(() => (cardsArr[flipped[0]] || {
    'id': '',
    'type': '',
    'word': ''
  }), [flipped]);
  const secondCard = useMemo(() => cardsArr[flipped[1]] || {
    'id': '',
    'type': '',
    'word': ''
  }, [flipped]);

  const evaluate = () => {
    //enable();
    if (firstCard?.id === secondCard?.id) {
      setOpened((prev) => ([...prev, firstCard?.id]));
      setIsMatch(true);
      //setFlipped([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = window.setTimeout(() => {
      setFlipped([]);
    }, 2000);
  };

  function resetCards() {
    setIsMatch(false);
    setFlipped([]);
  }

  function handleCardClick(index: number) {
    if (flipped?.length === 1) {
      setFlipped((prev) => [...prev, index]);
    } else {
      clearTimeout(timeout.current);
      setFlipped([index]);
    }
  }

  const checkCompletion = () => {
    if (opened?.length === cardsArr?.length && cards?.length) {
      changeCorrectAnswersPoints(points + 100);
    }
  };
  useEffect(() => {
    let timeout: number = 0;
    if (flipped?.length === 2) {
      evaluate();
      timeout = window.setTimeout(evaluate, 300);
    }
    return () => {
      window.clearTimeout(timeout);
    };
  }, [flipped]);

  useEffect(() => {
    checkCompletion();
  }, [opened]);

  useEffect(() => {
    let timeout = setTimeout(() => resetCards(), 800);

    return () => clearTimeout(timeout);
  }, [isMatch]);
  return (
    <div className='memory-film-game'>
      <Row>
        <Col md={6}>
          <div className='cards pb-3 pb-md-0'>
            <Row>
              <MovieCard
                card={firstCard}
                index={1}
                isFlipped={Boolean(firstCard.id)}
                isOpened={opened?.includes(firstCard.id)}
              />
              <MovieCard
                card={secondCard}
                index={2}
                isFlipped={Boolean(secondCard.id)}
                isOpened={opened?.includes(secondCard.id)}
              />
            </Row>
          </div>
        </Col>
        <Col md={6}>
          <div className='numbers-container'>
            {cardsArr
              .map((el, i) => (
                <span
                  className={classnames({
                    selected: opened.includes(el.id) && el.id !== firstCard?.id,
                    matched: flipped.includes(i)
                  })}
                  key={i}
                  onClick={() => flipped.length < 2 ? handleCardClick(i) : null}> {i + 1}
                </span>
              ))
            }
          </div>
        </Col>
      </Row>
      <h4 className={'count'}>{isMatch && 'It\'s a match'}</h4>
    </div>
  );
}

export default memo(MemoryGame);