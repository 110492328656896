import { EnumGameMechanicCategory } from "src/@constants";
export const DragDropImageGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.drag_drop_image,
  points: 100,
  content: {
    options: [

    ],
    answers: []
  }
}
