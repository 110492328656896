export default function PublishedIcon() {
  return (
    <svg viewBox='0 0 26 41' fill='none' xmlns='http://www.w3.org/2000/svg' className='published-icon'>
      <path
        d='M25.995 15.4901C25.9908 15.4604 25.9865 15.4314 25.9774 15.4017C25.9675 15.3692 25.9547 15.3403 25.9399 15.3099C25.9321 15.2936 25.9293 15.276 25.9194 15.2604C25.913 15.2505 25.9032 15.2434 25.8954 15.2336C25.877 15.2074 25.8544 15.1848 25.8318 15.1615C25.8092 15.1382 25.7851 15.117 25.759 15.0979C25.7491 15.0908 25.7434 15.0816 25.7335 15.076C25.718 15.0661 25.7003 15.064 25.6841 15.0562C25.653 15.0406 25.6226 15.0279 25.5894 15.018C25.5618 15.011 25.5343 15.0067 25.506 15.0032C25.4728 14.9989 25.4403 14.9968 25.4071 14.9989C25.376 15.0004 25.3463 15.0067 25.3152 15.0145C25.2961 15.0187 25.2763 15.0173 25.2572 15.0244L0.382987 23.5052C0.157558 23.5808 0.00491572 23.7907 -3.10091e-05 24.0288C-0.00427106 24.267 0.139891 24.4825 0.3632 24.5673L11.9852 29.0328L17.0068 40.6583C17.0966 40.8661 17.3015 40.9989 17.5255 40.9989C17.5354 40.9989 17.5439 40.9989 17.5538 40.9989C17.7898 40.9869 17.9926 40.8307 18.064 40.606L25.9795 15.7311C25.9851 15.7134 25.9837 15.6957 25.9865 15.6773C25.9936 15.6455 25.9986 15.6152 25.9993 15.5827C26.0007 15.5509 25.9993 15.5212 25.995 15.4901ZM2.22388 24.0712L23.321 16.8787L12.2693 27.9304L2.22388 24.0712ZM17.4442 38.8224L13.0798 28.7183L24.1965 17.6016L17.445 38.8224H17.4442Z'
        fill='#006837' />
      <path
        d='M8.88009 32.1199C8.6589 31.8987 8.30203 31.8987 8.08084 32.1199L0.166075 40.0347C-0.0551142 40.2559 -0.0551142 40.6128 0.166075 40.8339C0.276316 40.9442 0.421185 40.9993 0.566053 40.9993C0.710922 40.9993 0.85579 40.9442 0.966032 40.8339L8.8808 32.9192C9.10198 32.698 9.10198 32.3411 8.8808 32.1199H8.88009Z'
        fill='#006837' />
      <path
        d='M10.3422 36.0773L6.38483 40.0347C6.16364 40.2558 6.16364 40.6127 6.38483 40.8339C6.49507 40.9441 6.63994 40.9993 6.7848 40.9993C6.92967 40.9993 7.07454 40.9441 7.18478 40.8339L11.1422 36.8765C11.3634 36.6553 11.3634 36.2985 11.1422 36.0773C10.921 35.8561 10.5641 35.8561 10.3429 36.0773H10.3422Z'
        fill='#006837' />
      <path
        d='M0.565347 34.7812C0.710215 34.7812 0.855084 34.7261 0.965325 34.6159L4.92271 30.6585C5.1439 30.4373 5.1439 30.0804 4.92271 29.8593C4.70152 29.6381 4.34465 29.6381 4.12346 29.8593L0.166075 33.8159C-0.0551142 34.0371 -0.0551142 34.394 0.166075 34.6152C0.276316 34.7254 0.421185 34.7805 0.566053 34.7805L0.565347 34.7812Z'
        fill='#006837' />
    </svg>

  );
}