import Gems from './Gems';
import { useMemo } from 'react';
import { getDiamondColor } from 'src/helpers';
import { ISkillIsland } from 'src/@interfaces/skill-island-interfaces';
import { EnumGameLevelNumbers } from 'src/@constants';

export default function Science({ coreGames, miniGames, onGameSelect }: ISkillIsland) {

  const currentSea = useMemo(() => {
    const totalGamesNumber = (miniGames?.length ?? 0) + (coreGames?.length ?? 0);
    if (totalGamesNumber > 9 && totalGamesNumber <= 18) return <g id='SAND AND SEA 2'>
      <g id='Group_2' opacity='.29'>
        <path id='Vector_4' fill='#DEF6F9'
              d='M155.405 399.644C146.731 403.598 140.346 408.162 136.697 413.335C119.051 438.435 112.206 485.284 38.1312 519.356C-35.9506 553.427 9.68617 600.276 75.0942 612.295C140.651 624.462 182.482 665.075 276.187 646.217C370.04 627.509 388.748 630.549 470.582 628.877C680.193 624.618 686.733 579.442 712.138 538.369C717.765 529.241 732.519 526.655 734.652 520.114C746.366 482.995 797.555 493.422 826 463C944 425 956.964 386.894 974 344C988.3 307.795 738.153 269.735 610.83 298.946C559.113 310.808 520.783 279.933 452.179 310.504C450.202 311.418 448.224 312.332 446.247 313.239C427.844 321.757 415.372 328.907 404.87 335.143C394.374 341.379 386.005 346.552 375.971 351.265C361.975 357.657 344.791 362.823 314.212 367.691C253.212 376.819 188.414 384.423 155.405 399.637V399.644Z' />
      </g>
      <path id='Vector_5' fill='#F5D7AF'
            d='M176.097 406.646C168.189 410.295 162.102 414.405 158.757 419.274C142.479 442.397 136.094 485.745 67.7952 517.081C-0.50412 548.417 41.4831 591.616 101.867 602.87C162.258 613.974 200.893 651.546 287.44 634.206C373.994 616.865 394.679 632.682 466.777 618.233C526.254 606.214 642.315 579.598 689.618 534.726C697.526 527.271 708.482 523.926 710.459 517.839C721.259 483.612 683.382 488.941 709.545 460.949C760.348 406.646 717.304 420.79 732.973 381.397C746.203 347.934 713.499 287.09 596.225 314.011C548.462 324.96 513.023 296.522 449.897 324.662C448.069 325.421 446.247 326.335 444.419 327.093C427.383 334.853 415.825 341.542 406.237 347.325C396.656 353.107 388.897 357.976 379.62 362.235C366.688 368.166 350.871 372.886 322.582 377.293C266.45 385.663 206.676 392.508 176.097 406.653V406.646Z' />
      <path id='Vector_6' fill='#E2C49B'
            d='M215.491 413.491C208.497 416.687 203.324 420.181 200.276 424.291C185.976 444.218 180.505 481.486 120.568 508.407C60.7866 535.484 97.4449 572.596 150.537 582.184C203.472 591.765 237.395 624.016 313.298 609.106C389.201 594.196 394.984 606.824 460.846 602.565C517.126 598.915 624.67 561.796 665.892 523.466C672.886 517.074 682.474 514.19 684.147 509.017C693.579 479.657 626.952 502.625 683.537 461.403C740.123 420.181 687.492 428.245 701.332 394.166C712.89 365.417 687.031 310.199 584.051 333.322C542.219 342.754 511.188 318.263 455.665 342.449C453.993 343.208 452.469 343.817 450.953 344.582C436.043 351.272 426.008 357.055 417.491 361.923C408.973 366.791 402.276 371.05 394.063 374.7C382.654 379.873 368.814 383.976 344.018 387.781C294.732 395.385 242.257 401.317 215.484 413.491H215.491Z' />
    </g>;


    if (totalGamesNumber > 18) return <g id='SAND AND SEA 3'>
      <g id='Group_3' opacity='.29'>
        <path id='Vector_7' fill='#DEF6F9'
              d='M155.405 399.644C146.731 403.598 140.346 408.162 136.697 413.335C119.051 438.435 112.206 485.284 38.1312 519.355C-35.9506 553.427 9.68617 600.276 75.0942 612.294C140.651 624.462 182.482 665.074 276.187 646.217C370.04 627.509 388.748 630.549 470.582 628.877C680.193 624.618 688.595 625.073 714 584C797.862 674.508 879.867 599.541 882 593C893.714 555.881 945.555 589.422 974 559C1092 521 1199.96 458.894 1217 416C1231.3 379.795 1194.32 253.79 1067 283C1015.28 294.863 520.783 279.933 452.179 310.504C450.202 311.418 448.224 312.332 446.247 313.239C427.844 321.757 415.372 328.907 404.87 335.143C394.374 341.379 386.005 346.552 375.971 351.265C361.975 357.657 344.791 362.823 314.212 367.691C253.212 376.819 188.414 384.422 155.405 399.637V399.644Z' />
      </g>
      <path id='Vector_8' fill='#F5D7AF'
            d='M176.097 406.646C168.189 410.295 162.102 414.405 158.757 419.274C142.479 442.397 136.094 485.745 67.7952 517.081C-0.50412 548.417 41.4831 591.616 101.867 602.869C162.258 613.974 200.893 651.546 287.44 634.206C373.994 616.865 394.679 632.682 466.777 618.233C526.254 606.214 642.315 579.598 689.618 534.726C697.526 527.271 708.482 523.926 710.459 517.839C721.259 483.612 683.382 488.941 709.545 460.949C760.348 406.646 717.304 420.79 732.973 381.396C746.203 347.934 713.499 287.09 596.225 314.011C548.462 324.96 513.023 296.522 449.897 324.662C448.069 325.421 446.247 326.335 444.419 327.093C427.383 334.853 415.825 341.542 406.237 347.325C396.656 353.107 388.897 357.976 379.62 362.235C366.688 368.166 350.871 372.886 322.582 377.293C266.45 385.663 206.676 392.508 176.097 406.653V406.646Z' />
      <path id='Vector_9' fill='#E2C49B'
            d='M215.491 413.491C208.497 416.687 203.324 420.181 200.276 424.291C185.976 444.218 180.505 481.486 120.568 508.407C60.7866 535.484 97.4449 572.596 150.537 582.184C203.472 591.765 237.395 624.015 313.298 609.106C389.201 594.196 394.984 606.824 460.846 602.565C517.126 598.915 624.67 561.796 665.892 523.466C672.886 517.074 682.474 514.19 684.147 509.016C693.579 479.657 626.952 502.624 683.537 461.403C740.123 420.181 687.492 428.245 701.332 394.166C712.89 365.417 687.031 310.199 584.051 333.322C542.219 342.754 511.188 318.263 455.665 342.449C453.993 343.208 452.469 343.817 450.953 344.582C436.043 351.272 426.008 357.054 417.491 361.923C408.973 366.791 402.276 371.05 394.063 374.7C382.654 379.873 368.814 383.976 344.018 387.781C294.732 395.385 242.257 401.317 215.484 413.491H215.491Z' />
    </g>;


    return <g id='SAND AND SEA 1'>
      <g id='Group' opacity='.29'>
        <path id='Vector' fill='#DEF6F9'
              d='M155.405 399.644C146.731 403.598 140.346 408.162 136.697 413.335C119.051 438.435 112.206 485.284 38.1312 519.356C-35.9506 553.427 9.68617 600.276 75.0942 612.295C140.651 624.462 182.482 665.075 276.187 646.217C370.04 627.509 388.748 630.549 470.582 628.877C680.193 624.618 686.733 579.442 712.138 538.369C717.765 529.241 732.519 526.655 734.652 520.114C746.366 482.995 705.293 488.778 733.738 458.355C788.8 399.488 742.107 414.852 759.143 371.957C773.443 335.753 738.153 269.735 610.83 298.946C559.113 310.808 520.783 279.933 452.179 310.504C450.202 311.418 448.224 312.332 446.247 313.239C427.844 321.757 415.372 328.907 404.87 335.143C394.374 341.379 386.005 346.552 375.971 351.265C361.975 357.657 344.791 362.823 314.212 367.691C253.212 376.819 188.414 384.422 155.405 399.637V399.644Z' />
      </g>
      <path id='Vector_2' fill='#F5D7AF'
            d='M176.097 406.646C168.189 410.295 162.102 414.405 158.757 419.274C142.479 442.397 136.094 485.745 67.7952 517.081C-0.50412 548.417 41.4831 591.616 101.867 602.87C162.258 613.974 200.893 651.546 287.44 634.206C373.994 616.865 394.679 632.682 466.777 618.233C526.254 606.214 642.315 579.598 689.618 534.726C697.526 527.271 708.482 523.926 710.459 517.839C721.259 483.612 683.382 488.941 709.545 460.949C760.348 406.646 717.304 420.79 732.973 381.397C746.203 347.934 713.499 287.09 596.225 314.011C548.462 324.96 513.023 296.522 449.897 324.662C448.069 325.421 446.247 326.335 444.419 327.093C427.383 334.853 415.825 341.542 406.237 347.325C396.656 353.107 388.897 357.976 379.62 362.235C366.688 368.166 350.871 372.886 322.582 377.293C266.45 385.663 206.676 392.508 176.097 406.653V406.646Z' />
      <path id='Vector_3' fill='#E2C49B'
            d='M215.491 413.491C208.497 416.687 203.324 420.181 200.276 424.291C185.976 444.218 180.505 481.486 120.568 508.407C60.7866 535.484 97.4449 572.596 150.537 582.184C203.472 591.765 237.395 624.016 313.298 609.106C389.201 594.196 394.984 606.824 460.846 602.565C517.126 598.915 624.67 561.796 665.892 523.466C672.886 517.074 682.474 514.19 684.147 509.017C693.579 479.657 626.952 502.625 683.537 461.403C740.123 420.181 687.492 428.245 701.332 394.166C712.89 365.417 687.031 310.199 584.051 333.322C542.219 342.754 511.188 318.263 455.665 342.449C453.993 343.208 452.469 343.817 450.953 344.582C436.043 351.272 426.008 357.055 417.491 361.923C408.973 366.791 402.276 371.05 394.063 374.7C382.654 379.873 368.814 383.976 344.018 387.781C294.732 395.385 242.257 401.317 215.484 413.491H215.491Z' />
    </g>;
  }, [miniGames, coreGames]);

  const totalGamesNumber = (miniGames?.length ?? 0) + (coreGames?.length ?? 0);

  return (
    <>
      <svg viewBox='0 0 98 30' fill='none' xmlns='http://www.w3.org/2000/svg' className='skill-title'>
        <g style={{ mixBlendMode: 'multiply' }} clipPath='url(#clip0_511_4426)'>
          <path
            d='M3.20124 13.2703L1.95614 12.547L1.76873 12.2258L1.04603 11.4345L0.577505 10.4556L0.40298 10.2411L0.390096 10.1602L0.0012207 8.86013L0.18863 8.71242L1.34003 8.09579L2.49143 7.39828C2.5008 7.45221 2.66127 7.99263 2.97401 9.02073L4.04575 9.61039L3.92511 8.9668L3.77752 8.43107L3.62994 8.16261L2.7866 7.25058L1.94325 5.92355L1.26038 4.66334L0.764915 3.26949L1.07297 1.78186L2.21148 0.817064L3.54912 0.174651L4.90198 0.187546L5.03551 0.160583C5.51809 0.259056 5.9995 0.348149 6.48208 0.429037L7.00448 0.724454L7.79512 1.12655L8.67946 2.31994L9.36233 3.65986L9.57668 5.14749L8.82704 5.32216L7.96964 5.65744C6.97872 5.94348 6.43406 6.10408 6.33567 6.14042V6.12753H6.30873L6.09438 4.98806L5.29086 4.29055L4.91604 5.43001L5.27797 5.77818L6.21502 6.4077C6.87564 6.94344 7.23758 7.22948 7.29965 7.26582L8.26364 8.27164L8.85281 9.50488L8.97346 10.8718L8.47799 12.1859C8.0938 12.4719 7.7143 12.7662 7.33948 13.071L5.98662 13.4602L5.2768 13.4731L4.59393 13.5938L3.20124 13.2726V13.2703Z'
            fill='#A97C50' />
          <path
            d='M15.2692 13.9139L14.0909 13.6056L13.8098 13.5786L12.471 12.9491L11.5597 11.7967L11.3325 11.3548L10.7831 10.5365L10.5289 9.08873L10.2338 7.65385L10.3005 5.97866L10.4879 5.46871L10.8089 4.41014L11.6265 2.98932L12.5635 1.72912L14.3849 0.441944C14.8312 0.397397 15.3044 0.338782 15.8045 0.267273V0.481802L15.9521 0.373951L17.4924 0.682263L18.7785 1.62009L19.0057 2.37036L19.4344 3.06787L19.5281 4.0057L19.7284 4.60942L19.6745 5.76178L19.7015 6.17677L18.1343 6.44523L16.5811 6.53901L15.6839 4.84974L15.336 5.35969L14.8804 6.07009L14.7738 6.68672L14.6672 7.66558L14.6402 8.6714L14.6672 9.27513C14.953 10.0793 15.131 10.567 15.2025 10.7358L16.1137 10.0652L16.5026 8.87186L18.3509 8.91172L18.8066 8.96564L20.2391 9.04653L19.7296 10.5213L19.0069 11.7944L18.0827 12.9608L16.7029 13.5106L15.2704 13.9127L15.2692 13.9139Z'
            fill='#A97C50' />
          <path
            d='M20.0903 13.5927L20.3984 10.5096L20.4382 9.00785L20.7064 7.09116L20.6936 6.04548L20.1442 0.268454H20.3714L21.5908 0.120746L22.3814 0.160603L23.0104 0.0668204L24.4699 0V0.0128952H24.4968L23.8268 6.3538L23.76 7.4663L23.787 7.77461C23.8046 8.24821 23.8362 8.72182 23.8807 9.19542L23.8538 9.61041C23.8807 10.424 23.9158 11.2364 23.9603 12.0499L24.0142 13.4708L22.7551 13.5106L21.6974 13.4977C21.5545 13.5153 21.278 13.5294 20.8669 13.5376L20.0903 13.5915V13.5927Z'
            fill='#A97C50' />
          <path
            d='M31.595 13.5927L30.3499 13.4848L30.1484 13.4977L29.5054 13.4848L28.6878 13.5118C27.7238 13.4497 27.1112 13.418 26.8523 13.418C25.9774 13.4802 25.0977 13.5388 24.2134 13.5927L24.3879 10.5905L24.6421 9.15676L24.7229 8.4065L24.7358 7.72306L24.8564 6.9048L24.8963 6.28818L24.9091 4.8404L25.1504 3.40552L25.0696 1.99761L25.0567 0.0668502L27.1323 0.160633C28.0963 0.133671 29.0567 0.098502 30.0114 0.0539551L31.5645 0.241521L33.1856 0.0668502C33.1235 0.513492 33.0697 0.960133 33.0251 1.40677L32.9584 2.65292L32.8108 3.97994L31.4579 3.96705C30.7528 3.85099 30.3335 3.78886 30.1988 3.77948L28.9666 3.73963C28.9759 4.65987 28.9935 5.58481 29.0204 6.51443L30.6673 6.58125L31.1358 6.55429L31.2693 8.79336L30.05 8.73943H29.0193L29.0321 10.4686L30.385 10.3748L31.7648 10.1333L33.1446 10.2001L33.1317 11.1919L33.1587 11.8354L33.1048 12.2106L33.0509 13.4977L32.4617 13.4579L31.5915 13.5915L31.595 13.5927Z'
            fill='#A97C50' />
          <path
            d='M36.4968 13.3781L35.3314 13.1765C34.2245 12.8904 33.626 12.7427 33.5369 12.7345L33.6576 11.1121L33.9527 9.9187C33.9621 9.70417 33.9844 9.47206 34.0195 9.22119L33.8719 5.01268L33.9387 3.4172L34.488 4.47577L33.9656 3.32341L33.9785 2.89436L33.9656 2.93421L33.9926 2.47819L33.524 0.239126L33.6447 0.0245972L34.6497 0.105485L35.3466 0.0515598L37.1282 0.0386646L37.3964 0.936637L37.6506 1.5263L38.1191 2.94711L38.7223 4.32806L39.0433 5.30692C39.1686 5.59296 39.5434 6.57651 40.1677 8.2564L40.1138 7.15679C39.9709 5.62813 39.8995 4.69499 39.8995 4.35503L39.8327 2.96118V1.64704L39.7121 0.0925898C40.8108 0.101968 41.5475 0.114863 41.9223 0.132448L42.9941 0.105485L43.048 1.74083L43.3291 3.30935L43.2623 4.07368L43.383 4.89193L43.4907 6.47335L43.6653 8.04187L43.7191 9.63735L43.9874 11.1918L44.0682 12.8541L42.9695 12.9877C41.7642 13.2292 40.9513 13.4027 40.532 13.5106L39.568 12.1965L39.0327 11.2047L38.7247 10.8026C38.2608 10.0336 37.9785 9.57405 37.8813 9.42165C37.667 9.01018 37.4433 8.60339 37.2113 8.2013L37.1305 9.36773C37.1305 10.1719 37.1352 10.6279 37.1434 10.7346V10.962L37.2102 12.1015L37.1703 13.5891L36.5003 13.3746L36.4968 13.3781Z'
            fill='#A97C50' />
          <path
            d='M48.6317 13.9139L47.4533 13.6056L47.1722 13.5786L45.8334 12.9491L44.9221 11.7967L44.6949 11.3548L44.1455 10.5365L43.8914 9.08873L43.5962 7.65385L43.663 5.97866L43.8504 5.46871L44.1713 4.41014L44.9889 2.98932L45.9259 1.72912L47.7473 0.441944C48.1936 0.397397 48.6668 0.338782 49.1669 0.267273V0.481802L49.3145 0.373951L50.8548 0.682263L52.1409 1.62009L52.3681 2.37036L52.7968 3.06787L52.8905 4.0057L53.0908 4.60942L53.0369 5.76178L53.0639 6.17677L51.4967 6.44523L49.9435 6.53901L49.0463 4.84974L48.6984 5.35969L48.2428 6.07009L48.1362 6.68672L48.0296 7.66558L48.0027 8.6714L48.0296 9.27513C48.3154 10.0793 48.4934 10.567 48.5649 10.7358L49.4762 10.0652L49.865 8.87186L51.7134 8.91172L52.169 8.96564L53.6015 9.04653L53.0932 10.5213L52.3705 11.7944L51.4463 12.9608L50.0665 13.5106L48.634 13.9127L48.6317 13.9139Z'
            fill='#A97C50' />
          <path
            d='M60.7793 13.5927L59.5342 13.4848L59.3339 13.4977L58.6908 13.4848L57.8744 13.5118C56.9104 13.4497 56.2978 13.418 56.039 13.418C55.164 13.4802 54.2844 13.5388 53.4 13.5927L53.5746 10.5905L53.8287 9.15676L53.9095 8.4065L53.9224 7.72306L54.0431 6.9048L54.0829 6.28818L54.0958 4.8404L54.3371 3.40552L54.2563 1.99761L54.2434 0.0668502L56.3189 0.160633C57.2829 0.133671 58.2434 0.098502 59.198 0.0539551L60.7512 0.241521L62.3723 0.0668502C62.3102 0.513492 62.2563 0.960133 62.2118 1.40677L62.145 2.65292L61.9974 3.97994L60.6446 3.96705C59.9394 3.85099 59.5189 3.78886 59.3854 3.77948L58.1532 3.73963C58.1626 4.65987 58.1801 5.58481 58.2071 6.51443L59.8539 6.58125L60.3225 6.55429L60.456 8.79336L59.2367 8.73943H58.2059L58.2188 10.4686L59.5717 10.3748L60.9515 10.1333L62.3313 10.2001L62.3184 11.1919L62.3453 11.8354L62.2914 12.2106L62.2376 13.4977L61.6484 13.4579L60.7781 13.5915L60.7793 13.5927Z'
            fill='#A97C50' />
          <path
            d='M74.7624 10.5635L74.575 9.77216L72.6869 9.75927C72.5264 10.465 72.4327 10.8987 72.4058 11.0593C72.3788 11.2199 72.3202 11.6889 72.2312 12.4673L71.9103 13.2855L71.7896 13.7685L71.5085 13.7146L70.5445 13.7544L69.9683 13.5399L69.2854 13.5528L68.2546 13.2574L68.0403 13.2445L68.2406 12.7885L68.5357 11.8096L69.0582 10.3747L69.4997 8.92695L70.0081 7.50613L70.6113 6.09822L70.7589 5.69612L70.9861 4.61058L71.4546 3.75247C71.5882 3.37734 71.7264 3.0069 71.8693 2.63997L72.0836 1.80882L72.2043 0.441931L72.5932 0.454826L72.674 0.441931H72.2055V0.429036L72.5943 0.389178L73.5583 0.34932L74.0409 0.295395C74.5493 0.295395 75.0588 0.286017 75.5683 0.268433L75.8892 1.71621L76.599 4.45115C76.6974 4.91538 76.804 5.37609 76.92 5.83211L76.9598 6.03374L77.3745 7.15914C77.6427 8.0618 77.9191 8.96446 78.2049 9.86712L78.5797 11.2211L78.6606 12.0792L78.9018 12.683L78.1112 12.9244L77.6029 13.2058L76.9868 13.3265C76.5674 13.4871 76.1387 13.6395 75.7006 13.7826L75.0178 14.1577C74.9557 12.9596 74.8702 11.7627 74.7636 10.5646L74.7624 10.5635ZM74.1861 7.85548L74.1463 7.26581L73.905 5.71136L73.6637 5.73832L73.4225 6.81097L73.1273 7.86954L74.185 7.85665L74.1861 7.85548Z'
            fill='#A97C50' />
          <path
            d='M81.6731 13.3781L80.5076 13.1765C79.4008 12.8904 78.8022 12.7427 78.7132 12.7345L78.8338 11.1121L79.129 9.9187C79.1384 9.70417 79.1606 9.47206 79.1958 9.22119L79.0482 5.01268L79.115 3.4172L79.6643 4.47577L79.1419 3.32341L79.1548 2.89436L79.1419 2.93421L79.1688 2.47819L78.7003 0.239126L78.821 0.0245972L79.8259 0.105485L80.5229 0.0515598L82.3044 0.0386646L82.5727 0.936637L82.8268 1.5263L83.2954 2.94711L83.8986 4.32806L84.2207 5.30692C84.346 5.59296 84.7208 6.57651 85.3452 8.2564L85.2913 7.15679C85.1484 5.62813 85.0769 4.69499 85.0769 4.35503L85.0102 2.96118V1.64704L84.8895 0.0925898C85.9882 0.101968 86.725 0.114863 87.0998 0.132448L88.1715 0.105485L88.2254 1.74083L88.5065 3.30935L88.4398 4.07368L88.5604 4.89193L88.6682 6.47335L88.8427 8.04187L88.8966 9.63735L89.1648 11.1918L89.2456 12.8541L88.1469 12.9877C86.9417 13.2292 86.1288 13.4027 85.7094 13.5106L84.7454 12.1965L84.2102 11.2047L83.9021 10.8026C83.4383 10.0336 83.156 9.57405 83.0588 9.42165C82.8444 9.01018 82.6207 8.60339 82.3888 8.2013L82.308 9.36773C82.308 10.1719 82.3126 10.6279 82.3208 10.7346V10.962L82.3876 12.1015L82.3478 13.5891L81.6778 13.3746L81.6731 13.3781Z'
            fill='#A97C50' />
          <path
            d='M88.7712 13.6196L88.8111 13.03L88.7982 12.1719L89.0125 10.7639L89.0524 9.23529L88.9188 7.92115L89.0664 7.17089V5.07953L89.1472 4.20852L89.0934 3.65872C89.1027 3.65872 89.1519 3.36799 89.2409 2.78771L89.4553 0.254395L90.6207 0.361073C90.8257 0.361073 91.4196 0.397414 92.4023 0.467751L93.8758 0.722137L95.2146 1.37862L96.1517 1.96828L96.4995 2.14295L97.2902 3.43012L97.4108 3.77829L97.9063 4.77004L98 7.75938L97.7048 9.24701L96.9411 10.5611L95.9631 11.7276L95.4407 11.9691L94.6243 12.438L93.2855 13.1085L91.7721 13.4837L90.3256 13.6712L88.7724 13.6173L88.7712 13.6196ZM94.0749 8.19078L94.3959 7.11814L94.007 6.09942C93.8992 6.03729 93.6099 5.90247 93.1367 5.69732L93.0828 7.05132L93.1637 8.68666L94.0749 8.19078Z'
            fill='#A97C50' />
          <path
            d='M3.04072 29.544L3.28201 28.1232L3.21525 26.7153L3.41671 25.3343L3.5233 23.9405L3.61701 23.4844L3.67089 22.5595L3.84541 21.7413L3.92623 19.8785C3.06883 19.9148 2.18449 19.9641 1.27439 20.0262L1.07292 19.9992H0L0.0937048 19.3556L0.0269401 18.5784L0.201465 17.2514V15.8974L0.669989 16.085L3.30895 16.5808L5.15728 16.4871L6.51014 16.2456H7.52801L8.11718 16.1647L9.79098 16.1248L9.76404 16.9958L9.81792 17.5316L9.79098 18.3498L9.8308 18.8856L10.0721 20.3064L8.65247 20.2935L7.64748 20.2126H7.32654L6.96461 20.1587L6.01351 20.1188L6.05333 21.2184L6.10721 21.6744L6.46915 23.216L6.50897 23.9803L6.66944 24.7845L6.65656 25.8032C6.79945 27.0459 6.9283 28.2873 7.04543 29.5299L6.52303 29.4901C5.8179 29.517 5.10808 29.5346 4.39358 29.544H3.04072Z'
            fill='#A97C50' />
          <path
            d='M17.1035 29.6776L15.8584 29.571L15.657 29.5839L15.0139 29.571L14.1963 29.5979C13.2323 29.5346 12.6197 29.5041 12.3609 29.5041C11.4859 29.5663 10.6063 29.6249 9.72192 29.6788L9.89645 26.6766L10.1506 25.2429L10.2314 24.4926L10.2443 23.8092L10.365 22.9909L10.4048 22.3743L10.4177 20.9265L10.659 19.4916L10.5782 18.0837L10.5653 16.153L12.6408 16.2468C13.6048 16.2198 14.5653 16.1846 15.5199 16.1401L17.0731 16.3276L18.6942 16.153C18.6321 16.5996 18.5782 17.0463 18.5337 17.4929L18.4669 18.739L18.3193 20.0661L16.9665 20.0532C16.2613 19.9371 15.842 19.875 15.7073 19.8656L14.4751 19.8257C14.4845 20.746 14.502 21.6709 14.529 22.6006L16.1758 22.6674L16.6444 22.6404L16.7779 24.8795L15.5586 24.8256H14.5278L14.5407 26.5547L15.8936 26.4609L17.2734 26.2194L18.6532 26.2862L18.6403 27.278L18.6672 27.9216L18.6133 28.2967L18.5595 29.5839L17.9703 29.544L17.1 29.6776H17.1035Z'
            fill='#A97C50' />
          <path
            d='M23.9345 30L22.7562 29.6917L22.4751 29.6647L21.1362 29.034L20.225 27.8817L19.9977 27.4397L19.4484 26.6215L19.1942 25.1737L18.899 23.7388L18.9658 22.0636L19.1532 21.5537L19.4742 20.4951L20.2917 19.0743L21.2288 17.8141L23.0502 16.5269C23.4964 16.4824 23.9696 16.4237 24.4698 16.3522V16.5668L24.6174 16.4589L26.1577 16.7672L27.4438 17.7051L27.671 18.4553L28.0997 19.1528L28.1934 20.0907L28.3937 20.6944L28.3398 21.8467L28.3667 22.2617L26.7995 22.5302L25.2464 22.624L24.3492 20.9347L24.0013 21.4446L23.5456 22.1551L23.439 22.7717L23.3325 23.7505L23.3055 24.7564L23.3325 25.3601C23.6183 26.1643 23.7963 26.6519 23.8677 26.8208L24.779 26.1502L25.1679 24.9568L27.0162 24.9967L27.4719 25.0506L28.9044 25.1315L28.3949 26.6062L27.6722 27.8793L26.748 29.0458L25.3682 29.5956L23.9357 29.9977L23.9345 30Z'
            fill='#A97C50' />
          <path
            d='M32.5332 29.6647L31.3139 29.4103L30.631 29.3705L29.5733 29.1829L28.7698 29.129L28.8635 28.4315L28.9572 26.1666V25.4164L28.9033 24.7188C29.0368 23.1198 29.1048 22.1586 29.1048 21.8374L29.1715 19.7061L29.1587 18.9418L29.2793 17.4811V16.2479L31.1405 16.1143L31.944 16.2221L32.614 16.262L32.5871 16.6512L32.6269 17.7097L32.5332 18.2724L32.573 19.1845L32.4793 20.6451C32.3458 21.5126 32.2743 21.9991 32.265 22.1058L34.3546 22.066L34.3815 21.6908V20.6323L34.4483 19.1716L34.6088 17.7109C34.6263 17.4343 34.6404 16.9337 34.6486 16.2092L35.1581 16.3429L36.0951 16.4765L37.4878 16.7977C37.4972 17.0216 37.5417 17.521 37.6214 18.2994C37.6565 19.113 37.6881 19.6088 37.7151 19.787L37.9962 22.7764L37.9154 24.0225C37.9423 24.2464 37.9552 24.4738 37.9552 24.7059L38.2902 27.2533L38.3569 28.8617C37.5265 28.9508 37.0357 29.0094 36.8834 29.0364C36.812 29.0446 36.3739 29.1841 35.5704 29.4514L34.1109 29.6389V27.843L34.4459 24.3707L32.3563 24.5184L32.3294 26.1268L32.5039 27.843L32.5309 29.6659L32.5332 29.6647Z'
            fill='#A97C50' />
          <path
            d='M41.7208 29.4631L40.5554 29.2626C39.4485 28.9766 38.85 28.8289 38.7609 28.8207L38.8816 27.1982L39.1767 26.0048C39.1861 25.7903 39.2084 25.5582 39.2435 25.3073L39.0959 21.0988L39.1627 19.5033L39.712 20.5619L39.1896 19.4095L39.2025 18.9805L39.1896 19.0203L39.2166 18.5643L38.748 16.3252L38.8687 16.1107L39.8737 16.1916L40.5706 16.1377L42.3522 16.1248L42.6204 17.0228L42.8746 17.6124L43.3431 19.0332L43.9463 20.4142L44.2673 21.393C44.3926 21.6791 44.7674 22.6626 45.3917 24.3425L45.3378 23.2429C45.1949 21.7143 45.1235 20.7811 45.1235 20.4411L45.0567 19.0473V17.7332L44.9361 16.1787C46.0348 16.1881 46.7715 16.201 47.1463 16.2186L48.2181 16.1916L48.272 17.8269L48.5531 19.3955L48.4863 20.1598L48.607 20.9781L48.7147 22.5595L48.8893 24.128L48.9431 25.7235L49.2114 27.2779L49.2922 28.9402L48.1935 29.0739C46.9882 29.3154 46.1753 29.4889 45.756 29.5967L44.792 28.2826L44.2567 27.2908L43.9487 26.8887C43.4848 26.1197 43.2025 25.6602 43.1053 25.5078C42.891 25.0963 42.6673 24.6895 42.4353 24.2874L42.3545 25.4538C42.3545 26.258 42.3592 26.7141 42.3674 26.8207V27.0482L42.4342 28.1876L42.3943 29.6753L41.7243 29.4607L41.7208 29.4631Z'
            fill='#A97C50' />
          <path
            d='M52.5157 29.2896L51.1359 28.5522L50.7072 27.9227L50.2118 27.2932L49.9166 26.7035L49.4481 25.9533L48.9655 24.4656L48.9116 22.9112L49.0194 22.0929L48.9924 21.4095L49.2197 20.78L49.3801 19.9617L49.7948 19.2244L49.9693 18.7554L50.7459 17.3346L52.1925 16.1823L52.5275 16.1283L53.8264 16.1014L53.8136 16.1553L53.8803 16.1823L54.0279 16.1694L54.3629 16.2503L55.5951 16.371L56.7875 17.3768C56.8683 17.4659 57.2513 17.8188 57.9389 18.4354L58.1533 19.0649L58.6616 19.8421L59.0903 21.3438C59.1172 21.8714 59.1618 22.3942 59.2238 22.9124L59.17 23.1539L59.0622 24.468L58.6874 25.9967L57.844 27.3108L56.8531 28.5042L55.5541 29.3482C54.6967 29.4819 54.1884 29.571 54.0267 29.6167L52.5134 29.2955L52.5157 29.2896ZM54.2704 25.4832L54.6721 24.5852L54.8057 22.7752L54.6452 20.9793L54.056 19.5983L53.8686 19.9734L53.4797 20.9922L53.3591 21.7694L53.3052 22.7881C53.3673 23.7529 53.4165 24.3519 53.4528 24.584L54.056 25.8572L54.2704 25.482V25.4832Z'
            fill='#A97C50' />
          <path
            d='M60.5919 29.6917L59.6139 29.6249L59.0376 29.6647L59.1442 28.0833L59.3047 27.0646L59.3714 26.0998L59.3585 25.135C59.403 24.4469 59.4394 23.7634 59.4663 23.0835L59.4265 22.1715L59.56 21.515L59.6268 20.6967L59.6139 19.2091L59.4265 17.7742L59.3058 16.2057L59.2789 16.1928L59.9489 16.126L60.23 16.1799L61.2607 16.126L62.5328 16.2737L62.5867 17.7344L62.7202 19.222C62.6839 20.2231 62.6581 20.7952 62.6394 20.9382L62.7061 22.2113V23.2969L62.7729 23.7118L62.76 25.1995L62.6792 26.7141C62.8221 26.7141 63.3937 26.6824 64.394 26.6203L66.0819 26.3389L67.8634 26.2452L67.8505 27.9473L67.7428 29.5287L66.3501 29.5827L64.9035 29.5698L64.1668 29.5967L63.4698 29.6776L62.8936 29.6237L62.0233 29.6378L61.4341 29.5979L60.5908 29.6917H60.5919Z'
            fill='#A97C50' />
          <path
            d='M71.6678 29.2896L70.288 28.5522L69.8593 27.9227L69.3639 27.2932L69.0687 26.7035L68.6002 25.9533L68.1176 24.4656L68.0637 22.9112L68.1703 22.0929L68.1434 21.4095L68.3706 20.78L68.5311 19.9617L68.9457 19.2244L69.1202 18.7554L69.8968 17.3346L71.3434 16.1823L71.6784 16.1283L72.9774 16.1014L72.9645 16.1553L73.0312 16.1823L73.1788 16.1694L73.5138 16.2503L74.746 16.371L75.9384 17.3768C76.0193 17.4659 76.4023 17.8188 77.0898 18.4354L77.3042 19.0649L77.8125 19.8421L78.2412 21.3438C78.2682 21.8714 78.3127 22.3942 78.3748 22.9124L78.3209 23.1539L78.2131 24.468L77.8383 25.9967L76.995 27.3108L76.004 28.5042L74.705 29.3482C73.8476 29.4819 73.3393 29.571 73.1777 29.6167L71.6643 29.2955L71.6678 29.2896ZM73.4225 25.4832L73.8242 24.5852L73.9577 22.7752L73.7973 20.9793L73.2081 19.5983L73.0207 19.9734L72.6318 20.9922L72.5112 21.7694L72.4573 22.7881C72.5194 23.7529 72.5686 24.3519 72.6049 24.584L73.2081 25.8572L73.4225 25.482V25.4832Z'
            fill='#A97C50' />
          <path
            d='M83.2006 29.6776L81.7411 29.4092L80.9915 28.9801L80.4421 28.7257L79.3844 27.6801C79.0272 26.8583 78.8222 26.407 78.7683 26.3261C78.5633 25.3789 78.4427 24.7892 78.4064 24.5571L78.1112 23.4716L78.2049 23.3379L78.192 23.2981L78.2857 22.7623L78.3794 21.7037L78.554 21.2348L78.7554 20.1352L79.3985 19.1294L79.5589 18.7543L79.8939 18.3651L80.4562 17.5468L82.5727 16.1119L83.8858 16.2327L84.4211 16.3804L85.3991 16.5551L86.6442 17.48L87.2334 18.9278L87.3669 19.3838L87.7558 20.4154C87.7113 20.9429 87.6703 21.474 87.6351 22.0109L86.082 22.3063L84.4351 22.4669L83.6445 20.5631C83.6 20.644 83.3809 21.0988 82.9885 21.93L82.6536 23.4845C82.6629 24.3156 82.6758 24.8478 82.6934 25.0799L83.1221 26.5547L84.2606 25.4961L83.3762 25.5629V25.55L83.3493 25.5629L83.4161 23.4985L84.8896 23.3508L85.3722 23.3379L86.3502 23.1503L86.3631 23.2711L87.0858 23.2312L87.6082 23.1503L87.7956 24.652L88.1435 26.1127C88.2149 26.9263 88.2641 27.4269 88.2911 27.6144V29.0892L87.046 29.4784C86.5458 29.5229 86.0773 29.5815 85.6392 29.653L85.7599 28.8359L85.2375 29.1044L84.6342 29.4666L84.031 29.5335L83.2006 29.6812V29.6776Z'
            fill='#A97C50' />
          <path
            d='M90.1382 29.6518C90.3256 28.687 90.5083 27.0024 90.6875 24.5981L91.0764 22.9627L90.8749 22.6673L90.1921 21.8362L89.884 21.2195L89.4823 20.6158L89.255 20.1868L88.6389 19.5033L87.5273 17.7074L88.3039 17.3182C88.9915 17.14 89.6697 16.9559 90.3397 16.7684L90.9687 16.4601L91.5449 17.9746C91.6258 18.1446 91.9643 18.712 92.5628 19.6768L93.2457 18.5245L93.9016 17.1705L94.745 15.7766L96.0709 16.6347L97.8524 17.6405L96.9681 18.7132L96.1505 19.8257C95.1678 21.0414 94.4627 21.9264 94.034 22.4797L93.7529 23.0026L93.7927 23.6731C93.945 24.4867 94.0878 25.3038 94.2214 26.1267L94.2483 26.6625L94.7578 29.3974L94.0351 29.3576L93.1918 29.5053L92.2676 29.4783L91.7183 29.585C90.7625 29.6119 90.2354 29.6342 90.1382 29.6518Z'
            fill='#A97C50' />
        </g>
        <defs>
          <clipPath id='clip0_511_4426'>
            <rect width='98' height='30' fill='white' />
          </clipPath>
        </defs>
      </svg>
      <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 1220 582' className='skill-island-svg'>
        <g id='SCIENCE'>

          {currentSea}

          {totalGamesNumber > 18 && <g id='ISLAND 3'>
            <g id='ISLAND'>
              <g id='ICELAND-2'>
                <g id='Group_4'>
                  <path id='Vector_10' fill='#877C73'
                        d='M975.467 203.759V231.193L965.294 223.982V196.548L975.467 203.759Z' />
                  <path id='Vector_11' fill='#363333'
                        d='M1088.87 192.581V220.015L1068.02 244.17V216.736L1088.87 192.581Z' />
                  <path id='Vector_12' fill='#76521F'
                        d='M560.511 229.171V256.605L515.756 224.699V197.265L560.511 229.171Z' />
                  <path id='Vector_13' fill='#73501E'
                        d='M863.59 262.88V290.309L841.213 257.148V229.714L863.59 262.88Z' />
                  <path id='Vector_14' fill='#798889'
                        d='M1102.6 240.887V268.321L1082.51 295.716V268.287L1102.6 240.887Z' />
                  <path id='Vector_15' fill='#A3722B'
                        d='M637.801 255.669V283.102L612.124 303.286V275.857L637.801 255.669Z' />
                  <path id='Vector_16' fill='#73501E'
                        d='M707.98 280.542V307.976L695.268 252.458V225.024L707.98 280.542Z' />
                  <path id='Vector_17' fill='#926626' d='M569.15 299.29H433.208V326.724H569.15V299.29Z' />
                  <path id='Vector_18' fill='#7B5620' d='M433.208 299.29V326.724L411 316.264V288.835L433.208 299.29Z' />
                  <path id='Vector_19' fill='#76521F'
                        d='M584.325 310.047V337.476L569.15 326.724V299.29L584.325 310.047Z' />
                  <path id='Vector_20' fill='#373538'
                        d='M1184.98 320.556V347.99L1165.15 362.048V334.619L1184.98 320.556Z' />
                  <path id='Vector_21' fill='#76521F'
                        d='M550.846 344.711V372.145L534.912 360.909V333.475L550.846 344.711Z' />
                  <path id='Vector_22' fill='#926626' d='M592.543 344.711H550.846V372.145H592.543V344.711Z' />
                  <path id='Vector_23' fill='#8F6425'
                        d='M528.976 382.562V409.996L463.886 406.393V378.959L528.976 382.562Z' />
                  <path id='Vector_24' fill='#A3722B'
                        d='M463.886 378.959V406.393L455.242 412.518V385.089L463.886 378.959Z' />
                  <path id='Vector_25' fill='#73501E'
                        d='M436.425 385.089V412.518L425.241 394.313V366.879L436.425 385.089Z' />
                  <path id='Vector_26' fill='#926626' d='M455.242 385.089H436.425V412.523H455.242V385.089Z' />
                  <path id='Vector_27' fill='#373538' d='M1158.03 390.855H1133.11V418.289H1158.03V390.855Z' />
                  <path id='Vector_28' fill='#C3C8CA' d='M1186 348.319V375.748L1158.03 418.289V390.855L1186 348.319Z' />
                  <path id='Vector_29' fill='#74511E'
                        d='M551.354 401.669V429.103L528.976 409.996V382.562L551.354 401.669Z' />
                  <path id='Vector_30' fill='#A3722B'
                        d='M589.999 402.392V429.826L565.087 446.406V418.973L589.999 402.392Z' />
                  <path id='Vector_31' fill='#926626' d='M565.087 418.973H551.354V446.406H565.087V418.973Z' />
                  <path id='Vector_32' fill='#7A7976'
                        d='M1133.11 390.855V418.289L1119.64 456.498V429.064L1133.11 390.855Z' />
                  <path id='Vector_33' fill='#9F6F2A'
                        d='M598.135 431.591V459.025L562.542 470.557V443.128L598.135 431.591Z' />
                  <path id='Vector_34' fill='#373538' d='M1089.88 449.976H1042.34V477.41H1089.88V449.976Z' />
                  <path id='Vector_35' fill='#8F8D88'
                        d='M1119.64 429.064V456.498L1089.88 477.41V449.976L1119.64 429.064Z' />
                  <path id='Vector_36' fill='#B0BCBF' d='M609.836 500.445H502.536V527.879H609.836V500.445Z' />
                  <path id='Vector_37' fill='#373538'
                        d='M620.512 492.875V520.309L609.836 527.879V500.445L620.512 492.875Z' />
                  <path id='Vector_38' fill='#8F8D88'
                        d='M1042.34 449.976V477.41L951.566 541.579V514.145L1042.34 449.976Z' />
                  <path id='Vector_39' fill='#919190' d='M951.571 514.145H890.036V541.579H951.571V514.145Z' />
                  <path id='Vector_40' fill='#798889'
                        d='M705.44 542.263V569.697L620.512 520.309V492.875L705.44 542.263Z' />
                  <path id='Vector_41' fill='#373538' d='M732.897 542.263H705.44V569.697H732.897V542.263Z' />
                  <path id='Vector_42' fill='#C3C8CA'
                        d='M890.036 514.145V541.579L864.098 575.827V548.393L890.036 514.145Z' />
                  <path id='Vector_43' fill='#919190'
                        d='M864.098 548.393V575.827L807.144 587V559.566L864.098 548.393Z' />
                  <path id='Vector_44' fill='#798889'
                        d='M807.144 559.566V587L732.897 569.697V542.263L807.144 559.566Z' />
                  <path id='Vector_45' fill='#90C10A'
                        d='M1004.96 176L1023.27 188.619V200.694L1041.2 213.492L1070.56 192.581H1088.87L1068.02 216.736V227.187H1102.6V240.887L1082.51 268.287L1111.24 263.239L1173.8 287.389V304.334L1184.98 320.556L1165.15 334.619H1186V348.319L1158.03 390.855H1133.11L1119.64 429.064L1089.88 449.976H1042.34L951.566 514.145H890.036L864.098 548.393L807.144 559.566L732.897 542.263H705.44L620.512 492.875L609.836 500.445H502.536V481.697L515.505 472.686L525.421 479.539H564.574V462.594L598.135 438.798V431.591L562.542 443.128V431.412L589.999 412.125V402.392L565.087 418.973H551.354V401.669L528.976 382.562L463.886 378.959L455.242 385.089H436.425L425.241 366.879L510.164 356.966H590.511L592.543 344.711H550.846L534.912 333.475L584.325 310.047L569.15 299.29H433.208L411 288.835V275.857L449.645 263.598V248.457L494.312 207.605L560.511 254.946V229.171L515.756 197.265V179.603H553.898L637.801 237.643V255.669L612.124 275.857L630.434 312.806L677.979 279.102L688.147 302.894L707.98 280.542L695.268 225.024H717.132L751.206 274.053H764.934V232.235H777.647L789.856 223.584H826.977V237.643L863.59 275.857V262.88L841.213 229.714L850.878 222.866H876.303L890.544 243.773H904.78L923.853 216.014L939.362 226.828L975.467 213.128V203.759L965.294 196.548V176H1004.96Z' />
                </g>
              </g>
            </g>
            <g id='Group 3' fillRule='evenodd' clipRule='evenodd'>
              <path id='Vector_46' fill='#7BA302'
                    d='M1088.59 301.004C1076.81 301.775 1068.51 307.738 1070.06 314.32C1071.6 320.901 1082.41 325.615 1094.18 324.844C1105.95 324.073 1114.25 318.11 1112.71 311.528C1111.17 304.946 1100.36 300.233 1088.59 301.004Z' />
              <path id='Vector_47' fill='#56321A'
                    d='M1088.46 312.625V312.517C1089.46 301.699 1089.63 280.074 1091.48 280.053C1093.37 280.031 1093.37 301.71 1094.31 312.538V312.658V312.734C1094.31 312.766 1094.3 312.799 1094.29 312.832V312.897C1094.29 312.897 1094.26 312.962 1094.25 312.984L1094.21 313.06C1094.21 313.06 1094.18 313.125 1094.17 313.157L1094.14 313.223L1094.07 313.31L1094.03 313.375C1094.03 313.375 1093.96 313.44 1093.94 313.472L1093.89 313.527C1093.89 313.527 1093.81 313.592 1093.77 313.625L1093.72 313.657C1093.66 313.701 1093.59 313.744 1093.53 313.798C1093.45 313.842 1093.36 313.885 1093.27 313.929H1093.25L1093.21 313.961C1093.13 313.994 1093.06 314.026 1092.98 314.048L1092.92 314.07H1092.88C1092.83 314.102 1092.77 314.124 1092.72 314.135L1092.65 314.157H1092.61C1092.61 314.157 1092.52 314.189 1092.47 314.2L1092.38 314.222H1092.34C1092.34 314.222 1092.26 314.244 1092.21 314.254H1092.12L1092.06 314.276C1092.03 314.276 1091.99 314.276 1091.94 314.276H1091.87L1091.79 314.287C1091.76 314.287 1091.71 314.287 1091.67 314.287H1091.6H1091.52H1091.25C1091.25 314.287 1091.16 314.287 1091.11 314.287H1091.06H1090.98C1090.93 314.287 1090.87 314.287 1090.82 314.265H1090.75L1090.7 314.254C1090.61 314.244 1090.53 314.222 1090.45 314.211H1090.39L1090.37 314.2C1090.28 314.178 1090.22 314.168 1090.14 314.146H1090.09C1089.99 314.102 1089.9 314.07 1089.81 314.048C1089.75 314.016 1089.69 313.994 1089.63 313.972L1089.58 313.95C1089.51 313.918 1089.44 313.885 1089.37 313.842C1089.29 313.798 1089.21 313.744 1089.15 313.701L1089.09 313.657C1089.05 313.625 1089 313.592 1088.97 313.57V313.559L1088.92 313.505C1088.92 313.505 1088.85 313.451 1088.82 313.418L1088.79 313.386L1088.75 313.342L1088.69 313.266L1088.67 313.234H1088.66L1088.63 313.19L1088.59 313.114V313.092L1088.57 313.071L1088.56 313.016L1088.54 312.951L1088.51 312.919V312.864V312.799L1088.49 312.766V312.69V312.647L1088.46 312.625Z' />
              <path id='Vector_48' fill='#325C0F'
                    d='M1095.62 305.576C1097.38 306.673 1100.46 306.402 1100.98 304.892C1102.13 301.634 1102.93 301.156 1106.41 302.155C1108.1 302.644 1110.68 301.829 1110.73 299.679C1110.82 296.464 1112.54 294.932 1115.27 296.149C1117.92 297.333 1116.26 294.313 1116.06 293.966L1095.7 258.472C1093.68 254.953 1089.15 254.855 1087.07 258.472L1066.7 293.966C1066.5 294.313 1064.83 297.333 1067.48 296.149C1070.21 294.922 1071.94 296.464 1072.03 299.679C1072.08 301.84 1074.66 302.644 1076.35 302.155C1079.83 301.156 1080.62 301.634 1081.77 304.892C1082.3 306.402 1085.47 306.804 1087.13 305.576C1090.28 303.252 1091.79 303.198 1095.62 305.576Z' />
              <path id='Vector_49' fill='#44731D'
                    d='M1095.33 301.427C1096.94 302.448 1099.78 302.199 1100.26 300.787C1101.32 297.735 1102.05 297.279 1105.25 298.223C1106.81 298.68 1109.18 297.919 1109.24 295.899C1109.31 292.88 1110.91 291.446 1113.42 292.586C1115.85 293.694 1114.32 290.87 1114.14 290.545L1095.39 257.255C1093.54 253.953 1089.37 253.867 1087.45 257.255L1068.7 290.534C1068.51 290.86 1066.98 293.694 1069.42 292.576C1071.93 291.424 1073.52 292.869 1073.6 295.888C1073.65 297.919 1076.02 298.669 1077.58 298.213C1080.78 297.279 1081.51 297.724 1082.57 300.776C1083.07 302.188 1085.98 302.568 1087.51 301.417C1090.41 299.234 1091.8 299.179 1095.33 301.417V301.427Z' />
              <path id='Vector_50' fill='#54872A'
                    d='M1094.73 297.061C1096.09 297.93 1098.49 297.713 1098.91 296.518C1099.8 293.933 1100.43 293.553 1103.13 294.346C1104.45 294.737 1106.46 294.096 1106.51 292.38C1106.57 289.828 1107.92 288.611 1110.04 289.578C1112.11 290.523 1110.81 288.123 1110.66 287.851L1094.78 259.677C1093.21 256.886 1089.68 256.81 1088.07 259.677L1072.19 287.851C1072.04 288.123 1070.74 290.523 1072.8 289.578C1074.93 288.611 1076.27 289.828 1076.34 292.38C1076.38 294.096 1078.39 294.737 1079.71 294.346C1082.42 293.553 1083.04 293.933 1083.94 296.518C1084.35 297.713 1086.82 298.039 1088.12 297.061C1090.59 295.215 1091.75 295.171 1094.74 297.061H1094.73Z' />
              <path id='Vector_51' fill='#659E36'
                    d='M1094.18 292.999C1095.33 293.727 1097.32 293.542 1097.66 292.543C1098.41 290.382 1098.93 290.067 1101.19 290.729C1102.29 291.055 1103.96 290.512 1104.01 289.089C1104.06 286.96 1105.19 285.939 1106.95 286.754C1108.67 287.536 1107.59 285.538 1107.47 285.31L1094.23 261.806C1092.92 259.471 1089.98 259.406 1088.63 261.806L1075.4 285.31C1075.27 285.538 1074.19 287.536 1075.91 286.754C1077.68 285.939 1078.81 286.96 1078.86 289.089C1078.89 290.523 1080.58 291.055 1081.68 290.729C1083.94 290.067 1084.45 290.382 1085.21 292.543C1085.55 293.542 1087.61 293.803 1088.69 292.999C1090.74 291.457 1091.71 291.424 1094.2 292.999H1094.18Z' />
              <path id='Vector_52' fill='#325C0F'
                    d='M1094.87 287.243C1096.31 288.144 1098.84 287.916 1099.27 286.678C1100.21 284.006 1100.86 283.604 1103.72 284.43C1105.11 284.832 1107.23 284.169 1107.27 282.388C1107.34 279.749 1108.76 278.489 1110.99 279.488C1113.17 280.465 1111.8 277.978 1111.64 277.696L1094.91 248.566C1093.25 245.677 1089.54 245.601 1087.84 248.566L1071.11 277.696C1070.95 277.978 1069.58 280.455 1071.75 279.488C1073.99 278.489 1075.41 279.749 1075.48 282.388C1075.52 284.158 1077.64 284.821 1079.03 284.43C1081.89 283.604 1082.53 284.006 1083.48 286.678C1083.92 287.916 1086.52 288.242 1087.88 287.243C1090.48 285.331 1091.7 285.288 1094.85 287.243H1094.87Z' />
              <path id='Vector_53' fill='#44731D'
                    d='M1094.62 283.843C1095.95 284.69 1098.27 284.473 1098.67 283.322C1099.53 280.813 1100.13 280.444 1102.76 281.215C1104.04 281.595 1105.99 280.965 1106.04 279.303C1106.1 276.827 1107.4 275.643 1109.47 276.588C1111.46 277.5 1110.21 275.176 1110.06 274.905L1094.66 247.578C1093.13 244.863 1089.71 244.798 1088.14 247.578L1072.75 274.905C1072.59 275.176 1071.34 277.5 1073.34 276.588C1075.4 275.643 1076.7 276.827 1076.77 279.303C1076.81 280.965 1078.75 281.584 1080.04 281.215C1082.67 280.444 1083.27 280.813 1084.14 283.322C1084.54 284.484 1086.93 284.788 1088.19 283.843C1090.58 282.051 1091.7 282.008 1094.61 283.843H1094.62Z' />
              <path id='Vector_54' fill='#54872A'
                    d='M1094.13 280.259C1095.25 280.976 1097.22 280.802 1097.55 279.814C1098.29 277.685 1098.8 277.381 1101.03 278.033C1102.11 278.348 1103.76 277.826 1103.8 276.414C1103.85 274.318 1104.96 273.319 1106.7 274.112C1108.4 274.883 1107.33 272.917 1107.21 272.689L1094.18 249.555C1092.89 247.263 1089.99 247.198 1088.67 249.555L1075.64 272.689C1075.51 272.917 1074.45 274.883 1076.14 274.112C1077.89 273.319 1078.99 274.318 1079.05 276.414C1079.08 277.826 1080.73 278.348 1081.82 278.033C1084.05 277.381 1084.55 277.696 1085.29 279.814C1085.63 280.791 1087.66 281.063 1088.72 280.259C1090.74 278.739 1091.7 278.706 1094.15 280.259H1094.13Z' />
              <path id='Vector_55' fill='#659E36'
                    d='M1093.68 276.914C1094.62 277.511 1096.25 277.359 1096.54 276.545C1097.15 274.774 1097.58 274.514 1099.43 275.057C1100.34 275.317 1101.71 274.883 1101.74 273.71C1101.78 271.961 1102.7 271.125 1104.16 271.787C1105.57 272.428 1104.68 270.788 1104.57 270.604L1093.71 251.314C1092.63 249.403 1090.22 249.348 1089.11 251.314L1078.25 270.604C1078.14 270.788 1077.26 272.428 1078.67 271.787C1080.12 271.125 1081.05 271.961 1081.09 273.71C1081.12 274.883 1082.5 275.328 1083.39 275.057C1085.25 274.514 1085.67 274.774 1086.29 276.545C1086.57 277.359 1088.26 277.587 1089.15 276.914C1090.83 275.654 1091.64 275.621 1093.68 276.914Z' />
              <path id='Vector_56' fill='#325C0F'
                    d='M1094.23 270.115C1095.41 270.853 1097.48 270.669 1097.84 269.659C1098.61 267.476 1099.14 267.15 1101.47 267.823C1102.61 268.149 1104.33 267.606 1104.38 266.161C1104.43 264 1105.59 262.968 1107.42 263.794C1109.2 264.586 1108.08 262.566 1107.95 262.327L1094.28 238.509C1092.92 236.152 1089.89 236.076 1088.49 238.509L1074.82 262.327C1074.69 262.555 1073.57 264.586 1075.35 263.794C1077.18 262.968 1078.34 264 1078.39 266.161C1078.44 267.606 1080.16 268.149 1081.3 267.823C1083.63 267.15 1084.17 267.476 1084.93 269.659C1085.29 270.669 1087.41 270.94 1088.54 270.115C1090.65 268.551 1091.66 268.518 1094.23 270.115Z' />
              <path id='Vector_57' fill='#44731D'
                    d='M1094.03 267.334C1095.11 268.019 1097.02 267.856 1097.35 266.9C1098.06 264.847 1098.55 264.543 1100.7 265.173C1101.75 265.477 1103.34 264.967 1103.37 263.609C1103.43 261.589 1104.49 260.622 1106.18 261.393C1107.82 262.143 1106.78 260.242 1106.66 260.025L1094.07 237.683C1092.83 235.468 1090.03 235.403 1088.74 237.683L1076.15 260.025C1076.03 260.242 1075.01 262.143 1076.63 261.393C1078.32 260.622 1079.39 261.589 1079.44 263.609C1079.47 264.967 1081.07 265.477 1082.12 265.173C1084.27 264.543 1084.76 264.847 1085.47 266.9C1085.79 267.845 1087.75 268.106 1088.79 267.334C1090.74 265.868 1091.67 265.836 1094.03 267.334Z' />
              <path id='Vector_58' fill='#54872A'
                    d='M1093.62 264.402C1094.54 264.988 1096.16 264.836 1096.43 264.043C1097.03 262.306 1097.44 262.056 1099.27 262.588C1100.15 262.849 1101.51 262.414 1101.53 261.263C1101.57 259.547 1102.48 258.732 1103.9 259.384C1105.28 260.014 1104.41 258.407 1104.3 258.222L1093.67 239.302C1092.61 237.423 1090.24 237.379 1089.16 239.302L1078.5 258.211C1078.4 258.396 1077.53 260.003 1078.91 259.373C1080.34 258.721 1081.23 259.536 1081.27 261.252C1081.31 262.403 1082.65 262.838 1083.53 262.577C1085.36 262.045 1085.77 262.306 1086.37 264.033C1086.65 264.836 1088.31 265.054 1089.18 264.391C1090.83 263.153 1091.61 263.12 1093.62 264.391V264.402Z' />
              <path id='Vector_59' fill='#659E36'
                    d='M1093.26 261.676C1094.03 262.164 1095.37 262.045 1095.6 261.372C1096.1 259.927 1096.45 259.71 1097.97 260.155C1098.71 260.372 1099.84 260.014 1099.86 259.058C1099.89 257.635 1100.64 256.951 1101.83 257.494C1102.99 258.026 1102.26 256.68 1102.17 256.528L1093.28 240.757C1092.4 239.193 1090.42 239.15 1089.53 240.757L1080.64 256.528C1080.55 256.68 1079.83 258.026 1080.98 257.494C1082.17 256.951 1082.92 257.635 1082.96 259.058C1082.98 260.014 1084.1 260.372 1084.85 260.155C1086.36 259.71 1086.71 259.927 1087.21 261.372C1087.44 262.045 1088.83 262.219 1089.55 261.676C1090.93 260.644 1091.58 260.611 1093.25 261.676H1093.26Z' />
              <path id='Vector_60' fill='#7BA302'
                    d='M1115.89 314.472C1106.27 315.102 1099.5 319.967 1100.75 325.333C1102.01 330.709 1110.83 334.554 1120.45 333.924C1130.06 333.294 1136.84 328.428 1135.57 323.052C1134.32 317.676 1125.49 313.831 1115.89 314.461V314.472Z' />
              <path id='Vector_61' fill='#56321A'
                    d='M1115.23 323.91V323.801C1116.24 312.984 1116.4 291.359 1118.26 291.337C1120.14 291.316 1120.14 312.995 1121.08 323.823V323.943V324.019C1121.08 324.051 1121.07 324.084 1121.06 324.116V324.182C1121.06 324.182 1121.03 324.247 1121.02 324.268L1120.98 324.344C1120.98 324.344 1120.95 324.41 1120.94 324.442L1120.91 324.507L1120.84 324.594L1120.8 324.659C1120.8 324.659 1120.73 324.725 1120.71 324.757L1120.66 324.811C1120.66 324.811 1120.58 324.877 1120.54 324.92L1120.49 324.953C1120.43 324.996 1120.36 325.04 1120.3 325.094C1120.22 325.137 1120.13 325.181 1120.05 325.224H1120.02L1119.98 325.257C1119.9 325.289 1119.83 325.322 1119.75 325.344L1119.7 325.365H1119.65C1119.6 325.398 1119.54 325.42 1119.49 325.431L1119.42 325.452H1119.38C1119.38 325.452 1119.28 325.485 1119.24 325.496L1119.15 325.517H1119.11C1119.11 325.517 1119.03 325.539 1118.99 325.55H1118.89L1118.83 325.572C1118.8 325.572 1118.76 325.572 1118.71 325.572H1118.64L1118.56 325.583C1118.53 325.583 1118.48 325.583 1118.45 325.583H1118.39H1118.3H1118.04C1118.04 325.583 1117.94 325.583 1117.89 325.583H1117.84H1117.76C1117.71 325.583 1117.65 325.583 1117.6 325.561H1117.53L1117.48 325.55C1117.39 325.539 1117.32 325.517 1117.23 325.507H1117.17L1117.15 325.496C1117.07 325.474 1117 325.463 1116.92 325.441H1116.87C1116.77 325.398 1116.68 325.365 1116.6 325.344C1116.53 325.311 1116.48 325.289 1116.41 325.268L1116.37 325.246C1116.29 325.213 1116.22 325.181 1116.15 325.137C1116.07 325.094 1116 325.04 1115.93 324.996L1115.87 324.953C1115.83 324.92 1115.79 324.888 1115.75 324.866V324.855L1115.7 324.801C1115.7 324.801 1115.63 324.746 1115.61 324.714L1115.58 324.681L1115.55 324.638L1115.48 324.562L1115.46 324.529H1115.45L1115.43 324.486L1115.38 324.41V324.388L1115.36 324.366L1115.35 324.312L1115.33 324.247L1115.31 324.214V324.16V324.095L1115.29 324.062V323.986V323.943L1115.23 323.91Z' />
              <path id='Vector_62' fill='#325C0F'
                    d='M1121.65 315.753C1123.09 316.655 1125.63 316.427 1126.05 315.189C1126.99 312.517 1127.64 312.115 1130.51 312.94C1131.89 313.342 1134.01 312.68 1134.05 310.909C1134.12 308.27 1135.54 307.01 1137.78 308.009C1139.95 308.987 1138.59 306.5 1138.42 306.217L1121.69 277.088C1120.03 274.199 1116.32 274.123 1114.62 277.088L1097.89 306.217C1097.73 306.5 1096.36 308.976 1098.54 308.009C1100.77 306.999 1102.19 308.27 1102.26 310.909C1102.3 312.68 1104.42 313.342 1105.81 312.94C1108.67 312.115 1109.31 312.517 1110.26 315.189C1110.7 316.427 1113.3 316.753 1114.66 315.753C1117.26 313.842 1118.48 313.798 1121.63 315.753H1121.65Z' />
              <path id='Vector_63' fill='#44731D'
                    d='M1121.4 312.343C1122.73 313.19 1125.06 312.973 1125.45 311.822C1126.31 309.313 1126.91 308.943 1129.54 309.715C1130.82 310.095 1132.78 309.465 1132.82 307.803C1132.89 305.327 1134.19 304.143 1136.25 305.088C1138.25 306 1136.99 303.676 1136.84 303.404L1121.44 276.078C1119.91 273.362 1116.5 273.297 1114.93 276.078L1099.53 303.404C1099.38 303.676 1098.12 306 1100.12 305.088C1102.18 304.143 1103.48 305.327 1103.55 307.803C1103.59 309.465 1105.54 310.084 1106.82 309.715C1109.45 308.943 1110.06 309.313 1110.92 311.822C1111.32 312.984 1113.71 313.288 1114.97 312.343C1117.36 310.551 1118.48 310.507 1121.39 312.343H1121.4Z' />
              <path id='Vector_64' fill='#54872A'
                    d='M1120.91 308.759C1122.03 309.476 1124 309.302 1124.34 308.313C1125.07 306.185 1125.58 305.881 1127.81 306.532C1128.89 306.847 1130.54 306.326 1130.58 304.914C1130.64 302.818 1131.74 301.818 1133.49 302.611C1135.18 303.382 1134.11 301.417 1133.99 301.189L1120.96 278.054C1119.67 275.763 1116.77 275.697 1115.45 278.054L1102.42 301.189C1102.29 301.417 1101.23 303.382 1102.93 302.611C1104.67 301.818 1105.78 302.818 1105.83 304.914C1105.86 306.326 1107.51 306.847 1108.6 306.532C1110.83 305.881 1111.33 306.195 1112.08 308.313C1112.41 309.291 1114.44 309.562 1115.5 308.759C1117.52 307.238 1118.48 307.206 1120.93 308.759H1120.91Z' />
              <path id='Vector_65' fill='#659E36'
                    d='M1120.46 305.424C1121.4 306.022 1123.04 305.87 1123.32 305.055C1123.93 303.285 1124.36 303.024 1126.21 303.567C1127.12 303.828 1128.5 303.393 1128.52 302.22C1128.56 300.472 1129.48 299.635 1130.94 300.298C1132.35 300.939 1131.47 299.299 1131.36 299.114L1120.49 279.825C1119.41 277.913 1117 277.859 1115.9 279.825L1105.03 299.114C1104.92 299.299 1104.04 300.95 1105.45 300.298C1106.9 299.635 1107.83 300.472 1107.87 302.22C1107.9 303.393 1109.28 303.839 1110.18 303.567C1112.03 303.024 1112.46 303.285 1113.07 305.055C1113.35 305.87 1115.05 306.098 1115.93 305.424C1117.61 304.164 1118.42 304.132 1120.46 305.424Z' />
              <path id='Vector_66' fill='#325C0F'
                    d='M1121.01 298.625C1122.19 299.364 1124.25 299.179 1124.61 298.169C1125.38 295.986 1125.91 295.66 1128.25 296.334C1129.38 296.659 1131.11 296.116 1131.15 294.672C1131.2 292.51 1132.36 291.479 1134.2 292.304C1135.98 293.097 1134.85 291.077 1134.72 290.838L1121.05 267.019C1119.7 264.663 1116.66 264.586 1115.26 267.019L1101.59 290.838C1101.46 291.066 1100.34 293.097 1102.12 292.304C1103.95 291.479 1105.11 292.51 1105.16 294.672C1105.21 296.116 1106.93 296.659 1108.07 296.334C1110.4 295.66 1110.94 295.986 1111.7 298.169C1112.06 299.179 1114.18 299.451 1115.31 298.625C1117.43 297.061 1118.43 297.029 1121.01 298.625Z' />
              <path id='Vector_67' fill='#44731D'
                    d='M1120.81 295.834C1121.89 296.518 1123.8 296.355 1124.13 295.4C1124.84 293.347 1125.33 293.043 1127.48 293.673C1128.53 293.977 1130.12 293.466 1130.16 292.109C1130.21 290.088 1131.27 289.122 1132.96 289.893C1134.6 290.642 1133.56 288.742 1133.44 288.524L1120.85 266.183C1119.61 263.967 1116.81 263.902 1115.53 266.183L1102.94 288.524C1102.82 288.742 1101.79 290.642 1103.42 289.893C1105.1 289.122 1106.17 290.088 1106.22 292.109C1106.26 293.466 1107.85 293.977 1108.9 293.673C1111.05 293.043 1111.54 293.347 1112.25 295.4C1112.58 296.344 1114.53 296.605 1115.57 295.834C1117.52 294.368 1118.45 294.335 1120.81 295.834Z' />
              <path id='Vector_68' fill='#54872A'
                    d='M1120.41 292.912C1121.32 293.499 1122.94 293.347 1123.21 292.554C1123.81 290.816 1124.23 290.566 1126.05 291.099C1126.94 291.359 1128.29 290.925 1128.31 289.773C1128.35 288.057 1129.26 287.243 1130.68 287.894C1132.07 288.524 1131.19 286.917 1131.08 286.732L1120.43 267.823C1119.37 265.944 1117 265.901 1115.92 267.823L1105.26 286.732C1105.15 286.917 1104.29 288.524 1105.67 287.894C1107.1 287.243 1107.99 288.057 1108.04 289.773C1108.07 290.925 1109.41 291.359 1110.3 291.099C1112.12 290.566 1112.53 290.827 1113.13 292.554C1113.41 293.358 1115.07 293.575 1115.94 292.912C1117.59 291.674 1118.38 291.642 1120.38 292.912H1120.41Z' />
              <path id='Vector_69' fill='#659E36'
                    d='M1120.03 290.175C1120.8 290.664 1122.14 290.545 1122.37 289.871C1122.87 288.427 1123.22 288.209 1124.74 288.655C1125.48 288.872 1126.61 288.514 1126.63 287.558C1126.66 286.135 1127.42 285.451 1128.61 285.994C1129.76 286.526 1129.03 285.179 1128.94 285.027L1120.06 269.257C1119.17 267.693 1117.2 267.649 1116.3 269.257L1107.41 285.027C1107.33 285.179 1106.61 286.526 1107.75 285.994C1108.94 285.451 1109.7 286.135 1109.73 287.558C1109.75 288.514 1110.87 288.872 1111.62 288.655C1113.13 288.209 1113.48 288.427 1113.99 289.871C1114.22 290.545 1115.6 290.718 1116.32 290.175C1117.7 289.144 1118.35 289.111 1120.02 290.175H1120.03Z' />
            </g>
            <g id='Group 2' fillRule='evenodd' clipRule='evenodd'>
              <path id='Vector_70' fill='#7BA302'
                    d='M811.504 513.381C821.672 514.05 828.833 519.222 827.495 524.93C826.166 530.639 816.838 534.727 806.68 534.059C796.522 533.39 789.361 528.218 790.69 522.509C792.018 516.8 801.347 512.712 811.504 513.381Z' />
              <path id='Vector_71' fill='#56321A'
                    d='M811.618 523.461V523.366C810.751 513.984 810.609 495.228 809.007 495.209C807.377 495.19 807.377 513.993 806.567 523.385V523.489V523.555C806.567 523.583 806.576 523.611 806.586 523.64V523.696C806.586 523.696 806.614 523.753 806.624 523.772L806.652 523.838C806.652 523.838 806.68 523.894 806.689 523.922L806.718 523.979L806.774 524.054L806.812 524.111C806.812 524.111 806.868 524.167 806.887 524.195L806.934 524.243C806.934 524.243 807 524.299 807.038 524.327L807.076 524.356C807.132 524.393 807.189 524.431 807.245 524.478C807.311 524.516 807.387 524.553 807.462 524.591H807.481L807.519 524.619C807.585 524.648 807.651 524.676 807.717 524.695L807.764 524.714H807.801C807.848 524.742 807.896 524.761 807.943 524.77L807.999 524.789H808.037C808.037 524.789 808.112 524.817 808.159 524.827L808.235 524.845H808.272C808.272 524.845 808.338 524.864 808.376 524.874H808.461L808.508 524.893C808.536 524.893 808.574 524.893 808.612 524.893H808.678L808.744 524.902C808.772 524.902 808.81 524.902 808.847 524.902H808.904H808.979H809.205C809.205 524.902 809.29 524.902 809.328 524.902H809.375H809.441C809.488 524.902 809.535 524.902 809.582 524.883H809.639L809.686 524.874C809.761 524.864 809.827 524.845 809.903 524.836H809.95L809.969 524.827C810.044 524.808 810.1 524.798 810.166 524.78H810.214C810.298 524.742 810.374 524.714 810.449 524.695C810.506 524.667 810.553 524.648 810.609 524.629L810.647 524.61C810.713 524.582 810.769 524.553 810.835 524.516C810.901 524.478 810.967 524.431 811.024 524.393L811.071 524.356C811.109 524.327 811.146 524.299 811.175 524.28V524.271L811.222 524.224C811.222 524.224 811.278 524.177 811.307 524.148L811.335 524.12L811.363 524.082L811.42 524.017L811.438 523.988H811.448L811.467 523.951L811.504 523.885V523.866L811.523 523.847L811.533 523.8L811.552 523.743L811.57 523.715V523.668V523.611L811.589 523.583V523.517V523.48L811.618 523.461Z' />
              <path id='Vector_72' fill='#325C0F'
                    d='M805.436 517.347C803.919 518.298 801.262 518.063 800.81 516.753C799.82 513.927 799.132 513.513 796.127 514.379C794.666 514.803 792.442 514.097 792.395 512.232C792.32 509.443 790.831 508.115 788.475 509.17C786.195 510.197 787.627 507.578 787.797 507.276L805.37 476.491C807.113 473.439 811.024 473.354 812.814 476.491L830.397 507.276C830.567 507.578 832.008 510.197 829.718 509.17C827.363 508.105 825.874 509.443 825.799 512.232C825.752 514.106 823.528 514.803 822.067 514.379C819.061 513.513 818.383 513.927 817.394 516.753C816.932 518.063 814.199 518.411 812.767 517.347C810.044 515.331 808.744 515.284 805.436 517.347Z' />
              <path id='Vector_73' fill='#44731D'
                    d='M805.691 513.748C804.296 514.634 801.846 514.417 801.432 513.192C800.518 510.545 799.886 510.15 797.125 510.969C795.778 511.365 793.733 510.706 793.686 508.953C793.62 506.334 792.245 505.091 790.077 506.08C787.976 507.041 789.295 504.592 789.455 504.309L805.634 475.436C807.236 472.572 810.835 472.496 812.484 475.436L828.673 504.3C828.833 504.582 830.152 507.041 828.051 506.071C825.883 505.072 824.508 506.325 824.442 508.944C824.395 510.706 822.35 511.356 821.003 510.96C818.242 510.15 817.61 510.536 816.696 513.183C816.272 514.408 813.756 514.737 812.437 513.739C809.931 511.845 808.734 511.798 805.691 513.739V513.748Z' />
              <path id='Vector_74' fill='#54872A'
                    d='M806.209 509.961C805.031 510.715 802.958 510.527 802.6 509.49C801.827 507.248 801.29 506.919 798.953 507.606C797.813 507.945 796.08 507.39 796.042 505.901C795.985 503.687 794.826 502.632 792.989 503.471C791.208 504.29 792.329 502.208 792.461 501.973L806.162 477.536C807.519 475.115 810.562 475.049 811.957 477.536L825.657 501.973C825.789 502.208 826.911 504.29 825.13 503.471C823.292 502.632 822.133 503.687 822.077 505.901C822.039 507.39 820.305 507.945 819.165 507.606C816.828 506.919 816.291 507.248 815.519 509.49C815.16 510.527 813.031 510.809 811.91 509.961C809.78 508.36 808.781 508.322 806.199 509.961H806.209Z' />
              <path id='Vector_75' fill='#659E36'
                    d='M806.68 506.438C805.691 507.069 803.966 506.909 803.674 506.042C803.033 504.168 802.581 503.895 800.631 504.469C799.679 504.752 798.237 504.281 798.2 503.047C798.153 501.2 797.182 500.315 795.656 501.021C794.176 501.7 795.109 499.966 795.213 499.768L806.633 479.383C807.764 477.357 810.308 477.301 811.467 479.383L822.887 499.768C823 499.966 823.933 501.7 822.444 501.021C820.918 500.315 819.947 501.2 819.9 503.047C819.872 504.29 818.421 504.752 817.469 504.469C815.519 503.895 815.076 504.168 814.425 506.042C814.124 506.909 812.353 507.135 811.42 506.438C809.648 505.1 808.81 505.072 806.661 506.438H806.68Z' />
              <path id='Vector_76' fill='#325C0F'
                    d='M806.086 501.445C804.843 502.227 802.657 502.029 802.289 500.955C801.479 498.638 800.913 498.289 798.445 499.005C797.248 499.354 795.42 498.779 795.382 497.234C795.326 494.945 794.101 493.852 792.169 494.719C790.294 495.567 791.472 493.41 791.613 493.165L806.049 467.899C807.481 465.394 810.685 465.328 812.155 467.899L826.59 493.165C826.732 493.41 827.909 495.558 826.034 494.719C824.103 493.852 822.878 494.945 822.821 497.234C822.783 498.77 820.955 499.345 819.759 499.005C817.29 498.289 816.734 498.638 815.914 500.955C815.537 502.029 813.295 502.312 812.117 501.445C809.874 499.787 808.819 499.75 806.105 501.445H806.086Z' />
              <path id='Vector_77' fill='#44731D'
                    d='M806.303 498.497C805.154 499.231 803.147 499.043 802.807 498.045C802.063 495.868 801.545 495.548 799.274 496.217C798.171 496.547 796.485 496 796.447 494.559C796.39 492.411 795.269 491.384 793.488 492.204C791.764 492.995 792.848 490.979 792.979 490.744L806.265 467.042C807.585 464.687 810.534 464.63 811.891 467.042L825.177 490.744C825.309 490.979 826.392 492.995 824.668 492.204C822.887 491.384 821.766 492.411 821.709 494.559C821.672 496 819.994 496.537 818.882 496.217C816.612 495.548 816.093 495.868 815.349 498.045C815 499.053 812.937 499.316 811.853 498.497C809.79 496.942 808.819 496.905 806.313 498.497H806.303Z' />
              <path id='Vector_78' fill='#54872A'
                    d='M806.727 495.388C805.757 496.01 804.061 495.859 803.768 495.002C803.137 493.155 802.694 492.892 800.772 493.457C799.839 493.73 798.416 493.278 798.379 492.053C798.332 490.235 797.38 489.368 795.872 490.056C794.412 490.725 795.335 489.02 795.439 488.822L806.68 468.757C807.792 466.769 810.298 466.712 811.438 468.757L822.68 488.822C822.793 489.02 823.707 490.725 822.246 490.056C820.739 489.368 819.787 490.235 819.74 492.053C819.712 493.278 818.289 493.73 817.347 493.457C815.424 492.892 814.991 493.165 814.35 495.002C814.058 495.85 812.305 496.085 811.391 495.388C809.648 494.069 808.819 494.041 806.708 495.388H806.727Z' />
              <path id='Vector_79' fill='#659E36'
                    d='M807.113 492.487C806.303 493.005 804.89 492.873 804.645 492.166C804.117 490.631 803.75 490.405 802.148 490.876C801.366 491.102 800.178 490.725 800.16 489.708C800.122 488.191 799.33 487.465 798.068 488.04C796.852 488.596 797.615 487.173 797.71 487.013L807.085 470.283C808.018 468.625 810.1 468.578 811.052 470.283L820.428 487.013C820.522 487.173 821.285 488.596 820.07 488.04C818.816 487.465 818.016 488.191 817.978 489.708C817.95 490.725 816.762 491.111 815.99 490.876C814.388 490.405 814.02 490.631 813.493 492.166C813.248 492.873 811.787 493.071 811.024 492.487C809.573 491.394 808.876 491.365 807.113 492.487Z' />
              <path id='Vector_80' fill='#325C0F'
                    d='M806.633 486.589C805.615 487.23 803.834 487.07 803.523 486.194C802.854 484.3 802.402 484.018 800.386 484.602C799.406 484.884 797.917 484.413 797.879 483.16C797.832 481.286 796.833 480.391 795.25 481.107C793.714 481.794 794.685 480.042 794.798 479.835L806.595 459.176C807.764 457.132 810.383 457.066 811.589 459.176L823.386 479.835C823.5 480.033 824.47 481.794 822.934 481.107C821.351 480.391 820.352 481.286 820.305 483.16C820.268 484.413 818.779 484.884 817.799 484.602C815.782 484.018 815.321 484.3 814.661 486.194C814.35 487.07 812.522 487.305 811.552 486.589C809.724 485.233 808.857 485.205 806.633 486.589Z' />
              <path id='Vector_81' fill='#44731D'
                    d='M806.812 484.178C805.879 484.771 804.23 484.63 803.947 483.801C803.335 482.02 802.911 481.757 801.055 482.303C800.15 482.567 798.774 482.124 798.746 480.947C798.699 479.194 797.785 478.356 796.325 479.025C794.911 479.675 795.806 478.026 795.91 477.838L806.774 458.46C807.848 456.538 810.261 456.482 811.373 458.46L822.237 477.838C822.341 478.026 823.226 479.675 821.822 479.025C820.371 478.356 819.448 479.194 819.401 480.947C819.372 482.124 817.997 482.567 817.092 482.303C815.236 481.757 814.812 482.02 814.199 483.801C813.917 484.621 812.23 484.847 811.335 484.178C809.648 482.906 808.847 482.878 806.812 484.178Z' />
              <path id='Vector_82' fill='#54872A'
                    d='M807.161 481.634C806.369 482.143 804.975 482.011 804.739 481.323C804.221 479.816 803.863 479.599 802.289 480.061C801.526 480.287 800.357 479.91 800.339 478.912C800.301 477.423 799.519 476.717 798.294 477.282C797.097 477.828 797.851 476.434 797.945 476.274L807.123 459.864C808.037 458.234 810.082 458.196 811.014 459.864L820.211 476.265C820.296 476.425 821.05 477.819 819.862 477.273C818.628 476.707 817.855 477.414 817.818 478.902C817.789 479.901 816.63 480.278 815.867 480.052C814.294 479.59 813.936 479.816 813.417 481.314C813.182 482.011 811.749 482.199 810.996 481.625C809.573 480.551 808.894 480.523 807.161 481.625V481.634Z' />
              <path id='Vector_83' fill='#659E36'
                    d='M807.472 479.27C806.812 479.694 805.653 479.59 805.455 479.006C805.022 477.753 804.72 477.565 803.41 477.951C802.77 478.139 801.799 477.828 801.78 476.999C801.752 475.765 801.102 475.172 800.075 475.643C799.076 476.104 799.707 474.936 799.783 474.804L807.453 461.126C808.216 459.77 809.921 459.732 810.694 461.126L818.364 474.804C818.44 474.936 819.061 476.104 818.072 475.643C817.045 475.172 816.395 475.765 816.367 476.999C816.348 477.828 815.377 478.139 814.736 477.951C813.427 477.565 813.125 477.753 812.692 479.006C812.494 479.59 811.297 479.741 810.675 479.27C809.488 478.375 808.923 478.347 807.481 479.27H807.472Z' />
              <path id='Vector_84' fill='#7BA302'
                    d='M787.948 525.062C796.249 525.609 802.091 529.829 801.008 534.483C799.924 539.146 792.31 542.48 784.009 541.934C775.717 541.388 769.866 537.167 770.959 532.504C772.042 527.841 779.656 524.506 787.948 525.053V525.062Z' />
              <path id='Vector_85' fill='#56321A'
                    d='M788.513 533.248V533.154C787.646 523.772 787.505 505.016 785.903 504.997C784.273 504.978 784.273 523.781 783.463 533.173V533.277V533.343C783.463 533.371 783.472 533.399 783.481 533.427V533.484C783.481 533.484 783.51 533.54 783.519 533.559L783.547 533.625C783.547 533.625 783.576 533.682 783.585 533.71L783.613 533.767L783.67 533.842L783.708 533.898C783.708 533.898 783.764 533.955 783.783 533.983L783.83 534.03C783.83 534.03 783.896 534.087 783.934 534.125L783.971 534.153C784.028 534.19 784.084 534.228 784.141 534.275C784.207 534.313 784.282 534.351 784.358 534.388H784.377L784.414 534.417C784.48 534.445 784.546 534.473 784.612 534.492L784.659 534.511H784.697C784.744 534.539 784.791 534.558 784.838 534.567L784.895 534.586H784.933C784.933 534.586 785.017 534.614 785.055 534.624L785.13 534.643H785.168C785.168 534.643 785.234 534.662 785.272 534.671H785.357L785.404 534.69C785.432 534.69 785.47 534.69 785.507 534.69H785.573L785.639 534.699C785.667 534.699 785.705 534.699 785.733 534.699H785.79H785.865H786.092C786.092 534.699 786.176 534.699 786.214 534.699H786.261H786.327C786.374 534.699 786.421 534.699 786.468 534.68H786.525L786.572 534.671C786.647 534.662 786.713 534.643 786.789 534.633H786.836L786.855 534.624C786.93 534.605 786.987 534.596 787.053 534.577H787.1C787.185 534.539 787.26 534.511 787.335 534.492C787.392 534.464 787.439 534.445 787.495 534.426L787.533 534.407C787.599 534.379 787.656 534.351 787.722 534.313C787.788 534.275 787.854 534.228 787.91 534.19L787.957 534.153C787.995 534.125 788.033 534.096 788.061 534.077V534.068L788.108 534.021C788.108 534.021 788.164 533.974 788.183 533.946L788.212 533.917L788.24 533.88L788.296 533.814L788.315 533.785H788.325L788.344 533.748L788.381 533.682V533.663L788.4 533.644L788.409 533.597L788.428 533.54L788.447 533.512V533.465V533.409L788.466 533.38V533.314V533.277L788.513 533.248Z' />
              <path id='Vector_86' fill='#325C0F'
                    d='M782.973 526.174C781.729 526.956 779.543 526.758 779.175 525.684C778.365 523.367 777.8 523.018 775.331 523.734C774.134 524.082 772.306 523.508 772.268 521.972C772.212 519.683 770.987 518.59 769.055 519.457C767.18 520.305 768.358 518.148 768.499 517.903L782.935 492.637C784.367 490.131 787.571 490.065 789.041 492.637L803.476 517.903C803.618 518.148 804.796 520.295 802.92 519.457C800.989 518.581 799.764 519.683 799.707 521.972C799.67 523.508 797.842 524.082 796.645 523.734C794.176 523.018 793.62 523.367 792.8 525.684C792.424 526.758 790.181 527.04 789.003 526.174C786.761 524.516 785.705 524.478 782.991 526.174H782.973Z' />
              <path id='Vector_87' fill='#44731D'
                    d='M783.189 523.216C782.04 523.951 780.033 523.762 779.694 522.764C778.949 520.587 778.431 520.267 776.16 520.936C775.058 521.266 773.371 520.719 773.333 519.278C773.277 517.13 772.155 516.103 770.374 516.923C768.65 517.714 769.734 515.698 769.866 515.463L783.152 491.761C784.471 489.406 787.42 489.35 788.777 491.761L802.063 515.463C802.195 515.698 803.278 517.714 801.554 516.923C799.773 516.103 798.652 517.13 798.595 519.278C798.558 520.719 796.88 521.256 795.769 520.936C793.498 520.267 792.979 520.587 792.235 522.764C791.886 523.772 789.823 524.035 788.739 523.216C786.676 521.661 785.705 521.624 783.199 523.216H783.189Z' />
              <path id='Vector_88' fill='#54872A'
                    d='M783.613 520.107C782.643 520.729 780.947 520.578 780.655 519.721C780.023 517.874 779.58 517.611 777.658 518.176C776.725 518.449 775.303 517.997 775.265 516.772C775.218 514.954 774.266 514.087 772.758 514.775C771.298 515.444 772.221 513.739 772.325 513.541L783.566 493.476C784.678 491.488 787.185 491.431 788.325 493.476L799.566 513.541C799.679 513.739 800.593 515.444 799.132 514.775C797.625 514.087 796.673 514.954 796.626 516.772C796.598 517.997 795.175 518.449 794.233 518.176C792.31 517.611 791.877 517.884 791.236 519.721C790.944 520.569 789.192 520.804 788.278 520.107C786.534 518.788 785.705 518.76 783.594 520.107H783.613Z' />
              <path id='Vector_89' fill='#659E36'
                    d='M784 517.215C783.189 517.733 781.776 517.601 781.531 516.895C781.003 515.359 780.636 515.133 779.034 515.604C778.252 515.83 777.065 515.453 777.046 514.436C777.008 512.919 776.217 512.194 774.954 512.769C773.738 513.324 774.502 511.902 774.596 511.742L783.971 495.011C784.904 493.353 786.987 493.306 787.938 495.011L797.314 511.742C797.408 511.902 798.171 513.334 796.956 512.769C795.703 512.194 794.902 512.919 794.864 514.436C794.836 515.453 793.648 515.84 792.876 515.604C791.274 515.133 790.906 515.359 790.379 516.895C790.134 517.601 788.673 517.799 787.91 517.215C786.459 516.122 785.762 516.094 784 517.215Z' />
              <path id='Vector_90' fill='#325C0F'
                    d='M783.529 511.318C782.511 511.958 780.73 511.798 780.419 510.922C779.75 509.029 779.298 508.746 777.281 509.33C776.301 509.613 774.813 509.142 774.775 507.889C774.728 506.014 773.729 505.119 772.146 505.835C770.61 506.523 771.581 504.771 771.694 504.563L783.491 483.905C784.659 481.86 787.279 481.794 788.485 483.905L800.282 504.563C800.395 504.761 801.366 506.523 799.83 505.835C798.247 505.119 797.248 506.014 797.201 507.889C797.163 509.142 795.674 509.613 794.694 509.33C792.678 508.746 792.216 509.029 791.557 510.922C791.246 511.798 789.418 512.034 788.447 511.318C786.619 509.961 785.752 509.933 783.529 511.318Z' />
              <path id='Vector_91' fill='#44731D'
                    d='M783.698 508.897C782.765 509.49 781.116 509.349 780.834 508.52C780.221 506.74 779.797 506.476 777.941 507.022C777.036 507.286 775.661 506.843 775.632 505.666C775.585 503.913 774.671 503.075 773.211 503.744C771.797 504.394 772.692 502.745 772.796 502.557L783.66 483.179C784.735 481.257 787.147 481.201 788.259 483.179L799.123 502.557C799.227 502.745 800.112 504.394 798.708 503.744C797.257 503.075 796.334 503.913 796.287 505.666C796.259 506.843 794.883 507.286 793.978 507.022C792.122 506.476 791.698 506.74 791.086 508.52C790.803 509.34 789.116 509.566 788.221 508.897C786.534 507.625 785.733 507.597 783.698 508.897Z' />
              <path id='Vector_92' fill='#54872A'
                    d='M784.047 506.363C783.255 506.871 781.861 506.74 781.625 506.052C781.107 504.545 780.749 504.328 779.175 504.79C778.412 505.016 777.244 504.639 777.225 503.64C777.187 502.152 776.405 501.445 775.18 502.011C773.983 502.557 774.737 501.163 774.831 501.003L784.028 484.602C784.942 482.972 786.987 482.934 787.92 484.602L797.116 501.003C797.21 501.163 797.955 502.557 796.767 502.011C795.533 501.445 794.76 502.152 794.723 503.64C794.694 504.639 793.535 505.016 792.772 504.79C791.199 504.328 790.841 504.554 790.322 506.052C790.087 506.749 788.654 506.937 787.901 506.363C786.478 505.289 785.799 505.261 784.066 506.363H784.047Z' />
              <path id='Vector_93' fill='#659E36'
                    d='M784.367 503.989C783.708 504.413 782.549 504.309 782.351 503.725C781.917 502.472 781.616 502.284 780.306 502.67C779.665 502.858 778.695 502.547 778.676 501.718C778.648 500.484 777.997 499.891 776.97 500.362C775.972 500.824 776.603 499.655 776.678 499.524L784.348 485.845C785.112 484.489 786.817 484.451 787.59 485.845L795.26 499.524C795.335 499.655 795.957 500.824 794.968 500.362C793.941 499.891 793.29 500.484 793.262 501.718C793.243 502.547 792.273 502.858 791.632 502.67C790.322 502.284 790.021 502.472 789.587 503.725C789.389 504.309 788.193 504.46 787.571 503.989C786.384 503.094 785.818 503.066 784.377 503.989H784.367Z' />
            </g>
            {miniGames.slice(16, 25).map((game, i) => <Gems key={16 + i + 2} color={getDiamondColor(game.type)}
                                                            index={16 + i + 2}
                                                            level={EnumGameLevelNumbers[game.level]}
                                                            title={game.title}
                                                            onGameSelect={() => onGameSelect(game.id)} />)}
          </g>}
          {totalGamesNumber > 9 && <g id='ISLAND 2'>
            <g id='ISLAND_2' fillRule='evenodd' clipRule='evenodd'>
              <path id='Vector_293' fill='#BAB2C4'
                    d='M718.229 422.19L679.702 446.327L682.758 374.596L672.851 334.061L669.879 279.116L724.402 245.107L719.333 315.115L718.436 344.94L723.298 374.183L720.303 380.406L718.229 422.19Z' />
              <path id='Vector_294' fill='#7C7187'
                    d='M770.8 428.655L731.581 416.865L732.26 314.084L745.842 248.685L771.551 252.287L773.601 275.757L788.226 350.411L785.328 390.339L776.099 382.722L770.8 428.655Z' />
              <path id='Vector_295' fill='#BAB2C4'
                    d='M952 353.892L903.274 407.926L865.911 328.785L857.64 216.797L882.355 244.367L891.632 267.715L941.959 310.967L952 353.892Z' />
              <path id='Vector_296' fill='#BAB2C4'
                    d='M854.487 201.369L801.699 248.769L793.671 295.333L822.436 356.111L825.019 435.677L843.064 405.779L870.131 338.803L854.487 201.369Z' />
              <path id='Vector_297' fill='#A096AB'
                    d='M903.274 407.926L843.064 405.779L851.359 323.242L835.545 286.103L854.487 201.369L872.253 288.419L892.081 310.87L903.274 407.926Z' />
              <path id='Vector_298' fill='#BAB2C4'
                    d='M623.712 279.444L636.142 416.829L582.578 429.904L598.124 379.181L593.65 331.332L605.922 318.463L605.983 279.589L623.712 279.444Z' />
              <path id='Vector_299' fill='#A096AB'
                    d='M679.702 446.327L655.412 435.556L657.207 380.854L649.785 351.842L672.851 334.061L682.758 374.596L679.702 446.327Z' />
              <path id='Vector_300' fill='#7C7187'
                    d='M538.339 418.842L501 388.12L506.312 316.668L520.512 304.187L523.738 270.759L520.379 236.398L569.578 243.603L549.035 305.351L561.489 302.222L540.716 365.038L538.339 418.842Z' />
              <path id='Vector_301' fill='#A096AB'
                    d='M582.578 429.904L538.339 418.842L540.716 365.038L561.489 302.222L549.035 305.351L569.578 243.603L605.995 274.386L605.922 318.463L593.65 331.332L598.124 379.181L582.578 429.904Z' />
              <path id='Vector_302' fill='#739900'
                    d='M775.614 157.438L839.62 165.395L837.619 171.714L824.825 186.972L857.568 194.929L854.487 201.369L801.699 248.769L770.933 258.776L745.842 248.685L724.402 245.106L663.901 282.852L638.81 249.752L622.184 280.62L605.983 279.589L569.578 243.603L520.379 236.398L515.674 226.416L546.076 190.914L584.785 179.925L686.53 162.362L767.246 167.772L775.614 157.438Z' />
              <path id='Vector_303' fill='#7C7187'
                    d='M672.851 334.061L649.785 351.842L657.207 380.854L655.412 435.556L636.142 416.829L624.209 338.803L623.846 280.523L672.851 334.061Z' />
              <path id='Vector_304' fill='#BAB2C4'
                    d='M837.619 171.714L846.096 197.294L824.825 186.972L837.619 171.714Z' />
              <path id='Vector_305' fill='#769D01'
                    d='M622.184 280.62L672.851 334.061L719.369 350.532L732.03 348.179L822.436 356.111L848.897 347.754L895.44 339.98L941.959 310.967L945.233 304.551L922.968 300.281L822.812 332.399L717.672 319.106L663.889 282.852L639.089 238.763L622.184 280.62Z' />
              <path id='Vector_306' fill='#A096AB'
                    d='M801.699 248.769L793.671 295.333L816.833 324.782L783.266 325.097L768.762 281.991L770.933 258.776L801.699 248.769Z' />
              <path id='Vector_307' fill='#A096AB'
                    d='M724.402 245.107L745.842 248.685L732.26 314.084L719.333 315.115L724.402 245.107Z' />
              <path id='Vector_308' fill='#96C40A'
                    d='M773.395 149.688L839.62 165.395L822.606 179.21L857.568 194.929L799.48 241.019L774.717 252.323L746.121 243.7L726.706 235.452L661.488 278.558L626.053 278.425L605.995 274.386L567.371 235.852L515.674 226.416L543.857 183.152L582.578 172.163L684.323 154.6L765.027 160.022L773.395 149.688Z' />
              <path id='Vector_309' fill='#96C40A'
                    d='M626.053 278.425L674.512 327.414L714.967 345.195L733.606 343.788L822.521 350.981L850.898 340.246L894.894 335.249L945.233 304.551L932.1 288.262L909.689 284.708L921.076 295.43L891.608 310.336L846.144 310.991L816.833 324.782L783.266 325.097L732.26 314.084L719.333 315.115L665.55 276.205L640.751 232.116L626.053 278.425Z' />
              <path id='Vector_310' fill='#A096AB'
                    d='M788.032 353.091L785.328 390.339L776.099 382.722L770.8 428.655L825.019 435.677L822.436 356.111L788.032 353.091Z' />
              <path id='Vector_311' fill='#A096AB'
                    d='M719.369 350.532L723.299 374.183L720.303 380.406L718.229 422.19L731.581 416.865L732.03 348.179L719.369 350.532Z' />
              <path id='Vector_312' fill='#7BA302'
                    d='M528.395 206.912L562.969 216.142L606.504 225.7L642.667 215.73L693.612 244.864L688.215 187.13L564.121 177.402L543.857 183.152L528.395 206.912Z' />
              <path id='Vector_313' fill='#A096AB'
                    d='M670.789 217.489L693.612 244.864L691.393 211.861L706.867 161.744L702.501 142.41L674.488 144.339L686.53 162.362L670.789 217.489Z' />
              <path id='Vector_314' fill='#7C7187'
                    d='M724.487 128.025L729.471 225.591L760.237 227.908L752.597 166.789L738.081 129.469L724.487 128.025Z' />
              <path id='Vector_315' fill='#BAB2C4'
                    d='M693.612 244.864L729.471 225.591L726.888 171.496L724.487 128.025L702.501 142.41L706.867 161.744L691.393 211.861L693.612 244.864Z' />
              <path id='Vector_316' fill='#BAB2C4'
                    d='M796.072 210.709L802.73 170.246L785.898 130.536L773.383 129.408L752.852 95.8954L740.579 102.069L749.274 121.039L761.826 136.613L796.072 210.709Z' />
              <path id='Vector_317' fill='#A096AB'
                    d='M760.237 227.908L796.072 210.709L761.826 136.613L749.274 121.039L738.081 129.469L752.306 173.691L760.237 227.908Z' />
              <path id='Vector_318' fill='#739900'
                    d='M769.223 122.628L773.383 129.408L769.175 136.94L770.557 138.456L785.898 130.536L789.22 126.036L769.223 122.628Z' />
              <path id='Vector_319' fill='#7C7187'
                    d='M674.488 144.339L686.53 162.362L670.789 217.489L642.667 215.73L655.776 188.003L653.132 156.553L674.488 144.339Z' />
              <path id='Vector_320' fill='#BAB2C4'
                    d='M606.504 225.7L642.667 215.73L655.776 188.003L653.132 156.553L629.169 147.614L622.912 173.339L622.851 206.318L606.504 225.7Z' />
              <path id='Vector_321' fill='#A096AB'
                    d='M602.684 152.841L629.169 147.614L622.912 173.339L622.851 206.318L606.504 225.7L584.47 210.272L586.386 194.662L602.684 152.841Z' />
              <path id='Vector_322' fill='#7C7187'
                    d='M584.47 210.272L586.386 194.662L602.684 152.841L565.455 111.724L564.084 179.003L557.596 199.756L584.47 210.272Z' />
              <path id='Vector_323' fill='#739900'
                    d='M620.656 75.7249C594.753 89.9401 572.476 96.7323 563.187 105.841L565.455 111.724L602.672 152.841L629.157 147.614L653.12 156.553L674.476 144.339L702.489 142.41L724.475 128.025L738.069 129.469L749.262 121.039L740.555 102.069L752.828 95.8954L755.229 93.2998L705.557 75.1063L673.893 81.098L638.446 70L620.656 75.7249Z' />
              <path id='Vector_324' fill='#96C40A'
                    d='M563.187 105.841L604.758 146.473L630.273 140.967L654.515 153.084L676.149 136.855L702.78 137.692L724.766 123.307L738.36 124.75L747.746 117.679L740.579 102.057L740.858 97.3509L755.265 93.2877L733.061 82.2746L705.593 75.0942L673.93 81.0859L638.446 70L620.656 75.7249C593.65 90.5223 571.821 96.3321 563.187 105.841Z' />
              <path id='Vector_325' fill='#96C40A'
                    d='M767.55 119.887L773.007 128.801L769.175 136.94L789.22 126.036L767.55 119.887Z' />
              <path id='Vector_326' fill='#739900'
                    d='M554.674 322.829L596.706 328.117L608.845 334.097L628.903 336.632L624.695 341.023L595.772 353.964L549.253 336.511L527.388 318.135L523.301 311.573L554.674 322.829Z' />
              <path id='Vector_327' fill='#96C40A'
                    d='M554.674 322.829L597.142 327.669L608.857 334.097L628.903 336.632L595.141 348.518L551.096 333.624L523.301 311.573L554.674 322.829Z' />
              <path id='Vector_328' fill='#A096AB'
                    d='M768.544 454.89L777.821 466L776.912 452.597L783.193 432.245L781.423 424.385L770.048 425.174L774.935 432.488L768.544 454.89Z' />
              <path id='Vector_329' fill='#7C7187'
                    d='M790.36 418.539L792.386 458.177L804.876 459.123L801.772 434.295L795.878 419.134L790.36 418.539Z' />
              <path id='Vector_330' fill='#BAB2C4'
                    d='M777.821 466L792.386 458.177L791.33 436.199L790.36 418.539L781.423 424.385L783.193 432.245L776.912 452.597L777.821 466Z' />
              <path id='Vector_331' fill='#BAB2C4'
                    d='M819.441 452.124L822.145 435.69L815.305 419.558L810.224 419.109L801.881 405.488L796.897 407.999L800.426 415.701L805.519 422.032L819.441 452.124Z' />
              <path id='Vector_332' fill='#A096AB'
                    d='M804.876 459.123L819.441 452.124L805.519 422.032L800.426 415.701L795.878 419.134L801.65 437.097L804.876 459.123Z' />
              <path id='Vector_333' fill='#7C7187'
                    d='M770.048 425.174L774.935 432.488L768.544 454.89L757.12 454.174L762.444 442.906L761.365 430.135L770.048 425.174Z' />
              <path id='Vector_334' fill='#BAB2C4'
                    d='M742.435 458.213L757.12 454.174L762.444 442.906L761.365 430.135L751.639 426.496L749.092 436.951L749.068 450.341L742.435 458.213Z' />
              <path id='Vector_335' fill='#A096AB'
                    d='M740.883 428.618L751.639 426.496L749.092 436.951L749.068 450.341L742.435 458.213L733.473 451.955L734.261 445.611L740.883 428.618Z' />
              <path id='Vector_336' fill='#7C7187'
                    d='M733.473 451.955L734.261 445.611L740.883 428.618L725.76 411.917L725.202 439.256L722.571 447.685L733.473 451.955Z' />
              <path id='Vector_337' fill='#769D01'
                    d='M748.183 397.301C737.657 403.075 728.61 405.84 724.839 409.527L725.76 411.917L740.883 428.618L751.639 426.496L761.377 430.122L770.048 425.162L781.423 424.373L790.36 418.527L795.878 419.109L800.426 415.689L796.885 407.987L801.869 405.476L802.839 404.421L782.66 397.034L769.805 399.46L755.386 394.96L748.159 397.289L748.183 397.301Z' />
              <path id='Vector_338' fill='#96C40A'
                    d='M724.839 409.527L741.719 426.035L752.088 423.803L761.935 428.728L770.727 422.141L781.544 422.481L790.469 416.635L795.987 417.217L799.795 414.343L796.885 407.999L796.994 406.083L802.839 404.433L793.817 399.958L782.66 397.034L769.805 399.46L755.386 394.96L748.159 397.289C737.196 403.305 728.319 405.658 724.814 409.515L724.839 409.527Z' />
              <path id='Vector_339' fill='#7BA302'
                    d='M612.092 118.431C603.7 118.982 597.789 123.241 598.893 127.943C599.99 132.644 607.69 136.011 616.074 135.46C624.458 134.909 630.369 130.65 629.272 125.949C628.175 121.247 620.476 117.881 612.092 118.431Z' />
              <path id='Vector_340' fill='#56321A'
                    d='M611.998 126.732V126.655C612.714 118.928 612.83 103.482 614.153 103.466C615.498 103.451 615.498 118.936 616.167 126.67V126.756V126.81C616.167 126.833 616.159 126.856 616.151 126.88V126.926C616.151 126.926 616.128 126.973 616.12 126.988L616.097 127.043C616.097 127.043 616.074 127.089 616.066 127.112L616.042 127.159L615.996 127.221L615.965 127.268C615.965 127.268 615.918 127.314 615.902 127.337L615.864 127.376C615.864 127.376 615.809 127.423 615.778 127.446L615.747 127.469C615.7 127.5 615.654 127.531 615.607 127.57C615.552 127.601 615.49 127.632 615.428 127.663H615.412L615.381 127.687C615.327 127.71 615.273 127.733 615.218 127.749L615.179 127.764H615.148C615.109 127.787 615.07 127.803 615.031 127.811L614.985 127.826H614.954C614.954 127.826 614.891 127.85 614.853 127.857L614.79 127.873H614.759C614.759 127.873 614.705 127.888 614.674 127.896H614.604L614.565 127.912C614.541 127.912 614.51 127.912 614.479 127.912H614.425L614.37 127.919C614.347 127.919 614.316 127.919 614.285 127.919H614.238H614.176H613.989C613.989 127.919 613.919 127.919 613.888 127.919H613.849H613.795C613.756 127.919 613.717 127.919 613.678 127.904H613.631L613.593 127.896C613.53 127.888 613.476 127.873 613.414 127.865H613.375L613.359 127.857C613.297 127.842 613.25 127.834 613.196 127.818H613.157C613.087 127.787 613.025 127.764 612.963 127.749C612.916 127.725 612.877 127.71 612.83 127.694L612.799 127.679C612.745 127.656 612.698 127.632 612.644 127.601C612.589 127.57 612.535 127.531 612.488 127.5L612.449 127.469C612.418 127.446 612.387 127.423 612.364 127.407V127.4L612.325 127.361C612.325 127.361 612.278 127.322 612.255 127.299L612.232 127.275L612.208 127.244L612.162 127.19L612.146 127.167H612.138L612.123 127.136L612.092 127.081V127.066L612.076 127.05L612.068 127.012L612.053 126.965L612.037 126.942V126.903V126.856L612.022 126.833V126.779V126.748L611.998 126.732Z' />
              <path id='Vector_341' fill='#325C0F'
                    d='M617.1 121.697C618.352 122.481 620.546 122.287 620.919 121.209C621.736 118.881 622.303 118.54 624.784 119.254C625.99 119.603 627.825 119.021 627.864 117.485C627.926 115.189 629.155 114.095 631.1 114.964C632.982 115.809 631.8 113.652 631.66 113.404L617.155 88.0512C615.716 85.5376 612.488 85.4678 611.01 88.0512L596.498 113.404C596.358 113.652 595.168 115.809 597.058 114.964C599.002 114.087 600.231 115.189 600.293 117.485C600.332 119.029 602.168 119.603 603.373 119.254C605.854 118.54 606.414 118.881 607.231 121.209C607.612 122.287 609.867 122.574 611.049 121.697C613.297 120.037 614.37 119.998 617.1 121.697Z' />
              <path id='Vector_342' fill='#44731D'
                    d='M616.89 118.734C618.041 119.463 620.063 119.285 620.406 118.276C621.16 116.096 621.681 115.77 623.96 116.445C625.072 116.771 626.76 116.228 626.799 114.785C626.853 112.628 627.989 111.604 629.777 112.419C631.512 113.21 630.423 111.193 630.291 110.96L616.937 87.1823C615.615 84.8239 612.644 84.7618 611.283 87.1823L597.921 110.953C597.789 111.185 596.7 113.21 598.434 112.411C600.223 111.589 601.359 112.621 601.413 114.777C601.452 116.228 603.14 116.763 604.252 116.438C606.531 115.77 607.052 116.088 607.806 118.268C608.156 119.277 610.233 119.548 611.322 118.726C613.39 117.167 614.378 117.128 616.89 118.726V118.734Z' />
              <path id='Vector_343' fill='#54872A'
                    d='M616.462 115.615C617.435 116.236 619.146 116.081 619.441 115.227C620.079 113.381 620.522 113.109 622.451 113.676C623.392 113.955 624.823 113.497 624.854 112.272C624.901 110.448 625.858 109.58 627.374 110.27C628.844 110.945 627.919 109.23 627.81 109.036L616.501 88.9123C615.381 86.9185 612.869 86.8642 611.718 88.9123L600.41 109.036C600.301 109.23 599.375 110.945 600.845 110.27C602.362 109.58 603.319 110.448 603.365 112.272C603.396 113.497 604.827 113.955 605.768 113.676C607.697 113.109 608.141 113.381 608.778 115.227C609.074 116.081 610.832 116.313 611.757 115.615C613.515 114.296 614.339 114.265 616.47 115.615H616.462Z' />
              <path id='Vector_344' fill='#659E36'
                    d='M616.074 112.714C616.89 113.234 618.313 113.102 618.555 112.388C619.083 110.844 619.457 110.619 621.067 111.092C621.852 111.325 623.042 110.937 623.073 109.921C623.112 108.4 623.913 107.671 625.173 108.253C626.394 108.811 625.624 107.384 625.539 107.221L616.112 90.4329C615.179 88.7649 613.079 88.7184 612.123 90.4329L602.696 107.221C602.603 107.384 601.833 108.811 603.062 108.253C604.322 107.671 605.123 108.4 605.162 109.921C605.185 110.945 606.383 111.325 607.168 111.092C608.778 110.619 609.144 110.844 609.681 112.388C609.929 113.102 611.392 113.288 612.162 112.714C613.624 111.612 614.316 111.589 616.089 112.714H616.074Z' />
              <path id='Vector_345' fill='#325C0F'
                    d='M616.564 108.602C617.59 109.246 619.395 109.083 619.698 108.199C620.367 106.29 620.833 106.003 622.871 106.593C623.859 106.88 625.368 106.407 625.399 105.134C625.445 103.249 626.456 102.349 628.051 103.063C629.599 103.761 628.626 101.984 628.51 101.783L616.595 80.9759C615.413 78.9123 612.768 78.858 611.555 80.9759L599.64 101.783C599.523 101.984 598.551 103.753 600.099 103.063C601.693 102.349 602.704 103.249 602.751 105.134C602.782 106.399 604.291 106.872 605.278 106.593C607.316 106.003 607.775 106.29 608.452 108.199C608.763 109.083 610.614 109.316 611.586 108.602C613.437 107.237 614.308 107.206 616.548 108.602H616.564Z' />
              <path id='Vector_346' fill='#44731D'
                    d='M616.385 106.174C617.334 106.779 618.99 106.624 619.27 105.801C619.885 104.009 620.312 103.746 622.187 104.296C623.097 104.568 624.489 104.118 624.52 102.931C624.567 101.162 625.492 100.317 626.962 100.991C628.385 101.643 627.491 99.9829 627.382 99.789L616.416 80.27C615.327 78.3305 612.893 78.2839 611.773 80.27L600.806 99.789C600.698 99.9829 599.803 101.643 601.226 100.991C602.696 100.317 603.622 101.162 603.669 102.931C603.7 104.118 605.084 104.56 606.002 104.296C607.876 103.746 608.304 104.009 608.918 105.801C609.206 106.631 610.909 106.849 611.804 106.174C613.507 104.894 614.308 104.863 616.377 106.174H616.385Z' />
              <path id='Vector_347' fill='#54872A'
                    d='M616.035 103.614C616.836 104.126 618.236 104.002 618.477 103.296C618.998 101.775 619.363 101.558 620.95 102.023C621.72 102.248 622.894 101.876 622.926 100.867C622.964 99.37 623.75 98.6563 624.994 99.2226C626.2 99.7735 625.438 98.3693 625.352 98.2064L616.074 81.6819C615.156 80.045 613.087 79.9984 612.146 81.6819L602.867 98.2064C602.774 98.3693 602.02 99.7735 603.225 99.2226C604.47 98.6563 605.255 99.37 605.294 100.867C605.317 101.876 606.492 102.248 607.27 102.023C608.856 101.558 609.214 101.783 609.743 103.296C609.984 103.994 611.43 104.188 612.185 103.614C613.624 102.528 614.308 102.504 616.05 103.614H616.035Z' />
              <path id='Vector_348' fill='#659E36'
                    d='M615.716 101.224C616.385 101.651 617.551 101.542 617.754 100.96C618.189 99.6959 618.492 99.5097 619.815 99.8976C620.46 100.084 621.44 99.7735 621.456 98.9356C621.487 97.6866 622.14 97.0892 623.182 97.5624C624.185 98.0202 623.555 96.8487 623.478 96.7168L615.739 82.9387C614.969 81.5733 613.25 81.5345 612.465 82.9387L604.726 96.7168C604.649 96.8487 604.019 98.0202 605.022 97.5624C606.056 97.0892 606.717 97.6866 606.748 98.9356C606.772 99.7735 607.752 100.092 608.389 99.8976C609.712 99.5097 610.015 99.6959 610.45 100.96C610.653 101.542 611.858 101.705 612.488 101.224C613.686 100.324 614.261 100.301 615.716 101.224Z' />
              <path id='Vector_349' fill='#325C0F'
                    d='M616.112 96.3677C616.952 96.8953 618.422 96.7634 618.679 96.0419C619.231 94.4825 619.605 94.2498 621.269 94.7308C622.078 94.9635 623.307 94.5756 623.338 93.5438C623.377 92 624.201 91.263 625.508 91.8526C626.775 92.4189 625.974 90.9759 625.881 90.8053L616.144 73.7921C615.179 72.1086 613.017 72.0543 612.022 73.7921L602.284 90.8053C602.191 90.9682 601.39 92.4189 602.657 91.8526C603.964 91.263 604.789 92 604.827 93.5438C604.859 94.5756 606.087 94.9635 606.896 94.7308C608.561 94.2498 608.942 94.4825 609.486 96.0419C609.743 96.7634 611.252 96.9573 612.053 96.3677C613.561 95.2506 614.277 95.2273 616.112 96.3677Z' />
              <path id='Vector_350' fill='#44731D'
                    d='M615.965 94.3817C616.735 94.8704 618.096 94.7541 618.329 94.0714C618.835 92.6051 619.185 92.3879 620.717 92.8378C621.463 93.0551 622.599 92.6904 622.622 91.7207C622.661 90.2777 623.415 89.5873 624.621 90.1381C625.788 90.6734 625.049 89.3157 624.963 89.1606L615.996 73.2025C615.109 71.6198 613.118 71.5733 612.2 73.2025L603.233 89.1606C603.147 89.3157 602.416 90.6734 603.575 90.1381C604.773 89.5873 605.535 90.2777 605.574 91.7207C605.597 92.6904 606.733 93.0551 607.48 92.8378C609.012 92.3879 609.362 92.6051 609.867 94.0714C610.101 94.7463 611.493 94.9325 612.232 94.3817C613.624 93.3344 614.285 93.3111 615.965 94.3817Z' />
              <path id='Vector_351' fill='#54872A'
                    d='M615.677 92.287C616.33 92.706 617.481 92.5974 617.676 92.031C618.104 90.7898 618.399 90.6113 619.698 90.9915C620.328 91.1777 621.292 90.8673 621.308 90.045C621.339 88.8192 621.984 88.2374 622.996 88.7029C623.983 89.1528 623.361 88.0046 623.283 87.8728L615.708 74.3584C614.954 73.0163 613.266 72.9853 612.496 74.3584L604.905 87.865C604.835 87.9969 604.213 89.1451 605.193 88.6951C606.212 88.2296 606.85 88.8115 606.881 90.0372C606.904 90.8596 607.861 91.1699 608.491 90.9837C609.789 90.6036 610.085 90.7898 610.513 92.0233C610.707 92.5974 611.889 92.7525 612.512 92.2793C613.686 91.3949 614.246 91.3716 615.677 92.2793V92.287Z' />
              <path id='Vector_352' fill='#659E36'
                    d='M615.42 90.3398C615.965 90.6889 616.921 90.6036 617.085 90.1226C617.442 89.0908 617.691 88.9356 618.772 89.2537C619.301 89.4089 620.102 89.1528 620.118 88.4701C620.141 87.4539 620.678 86.9651 621.526 87.353C622.35 87.7331 621.829 86.7712 621.767 86.6625L615.436 75.398C614.806 74.2809 613.398 74.2498 612.76 75.398L606.43 86.6625C606.367 86.7712 605.854 87.7331 606.671 87.353C607.518 86.9651 608.055 87.4539 608.078 88.4701C608.094 89.1528 608.895 89.4089 609.424 89.2537C610.505 88.9356 610.754 89.0908 611.112 90.1226C611.275 90.6036 612.263 90.7277 612.776 90.3398C613.756 89.6028 614.223 89.5795 615.412 90.3398H615.42Z' />
              <path id='Vector_353' fill='#7BA302'
                    d='M631.535 128.051C624.683 128.501 619.861 131.977 620.756 135.809C621.65 139.649 627.934 142.396 634.786 141.946C641.63 141.496 646.46 138.02 645.558 134.18C644.663 130.34 638.379 127.593 631.535 128.043V128.051Z' />
              <path id='Vector_354' fill='#56321A'
                    d='M631.068 134.793V134.715C631.784 126.988 631.901 111.542 633.223 111.527C634.568 111.511 634.568 126.996 635.237 134.731V134.816V134.87C635.237 134.894 635.229 134.917 635.222 134.94V134.987C635.222 134.987 635.198 135.033 635.191 135.049L635.167 135.103C635.167 135.103 635.144 135.15 635.136 135.173L635.113 135.22L635.066 135.282L635.035 135.328C635.035 135.328 634.988 135.375 634.973 135.398L634.934 135.437C634.934 135.437 634.879 135.483 634.848 135.514L634.817 135.538C634.771 135.569 634.724 135.6 634.677 135.638C634.623 135.67 634.561 135.701 634.498 135.732H634.483L634.452 135.755C634.397 135.778 634.343 135.801 634.288 135.817L634.249 135.832H634.218C634.179 135.856 634.141 135.871 634.102 135.879L634.055 135.895H634.024C634.024 135.895 633.954 135.918 633.923 135.926L633.861 135.941H633.829C633.829 135.941 633.775 135.957 633.744 135.964H633.674L633.635 135.98C633.612 135.98 633.581 135.98 633.549 135.98H633.495L633.441 135.988C633.417 135.988 633.386 135.988 633.363 135.988H633.316H633.254H633.067C633.067 135.988 632.997 135.988 632.966 135.988H632.927H632.873C632.834 135.988 632.795 135.988 632.756 135.972H632.71L632.671 135.964C632.608 135.957 632.554 135.941 632.492 135.933H632.453L632.437 135.926C632.375 135.91 632.328 135.902 632.274 135.887H632.235C632.165 135.856 632.103 135.832 632.041 135.817C631.994 135.794 631.955 135.778 631.908 135.763L631.877 135.747C631.823 135.724 631.776 135.701 631.722 135.67C631.667 135.638 631.613 135.6 631.566 135.569L631.527 135.538C631.496 135.514 631.465 135.491 631.442 135.476V135.468L631.403 135.429C631.403 135.429 631.356 135.39 631.341 135.367L631.317 135.344L631.294 135.313L631.247 135.258L631.232 135.235H631.224L631.208 135.204L631.177 135.15V135.134L631.162 135.119L631.154 135.08L631.138 135.033L631.123 135.01V134.971V134.925L631.107 134.901V134.847V134.816L631.068 134.793Z' />
              <path id='Vector_355' fill='#325C0F'
                    d='M635.642 128.967C636.668 129.611 638.473 129.448 638.776 128.563C639.445 126.655 639.911 126.368 641.949 126.957C642.937 127.244 644.446 126.771 644.477 125.507C644.523 123.621 645.535 122.721 647.129 123.435C648.677 124.133 647.704 122.357 647.588 122.155L635.673 101.348C634.491 99.2847 631.846 99.2304 630.633 101.348L618.718 122.155C618.601 122.357 617.629 124.126 619.177 123.435C620.771 122.714 621.782 123.621 621.829 125.507C621.86 126.771 623.369 127.244 624.357 126.957C626.394 126.368 626.853 126.655 627.53 128.563C627.841 129.448 629.692 129.68 630.664 128.967C632.515 127.601 633.386 127.57 635.626 128.967H635.642Z' />
              <path id='Vector_356' fill='#44731D'
                    d='M635.463 126.531C636.412 127.136 638.068 126.981 638.348 126.158C638.963 124.366 639.39 124.102 641.265 124.653C642.175 124.925 643.567 124.475 643.598 123.288C643.645 121.519 644.57 120.673 646.04 121.348C647.463 122 646.569 120.34 646.46 120.146L635.494 100.627C634.405 98.6874 631.971 98.6408 630.851 100.627L619.885 120.146C619.776 120.34 618.881 122 620.305 121.348C621.774 120.673 622.7 121.519 622.747 123.288C622.778 124.475 624.162 124.917 625.08 124.653C626.954 124.102 627.382 124.366 627.996 126.158C628.284 126.988 629.987 127.206 630.882 126.531C632.585 125.251 633.386 125.22 635.455 126.531H635.463Z' />
              <path id='Vector_357' fill='#54872A'
                    d='M635.113 123.971C635.914 124.483 637.314 124.358 637.555 123.652C638.076 122.132 638.442 121.915 640.028 122.38C640.798 122.605 641.972 122.233 642.004 121.224C642.042 119.727 642.828 119.013 644.072 119.58C645.278 120.13 644.516 118.726 644.43 118.563L635.152 102.039C634.234 100.402 632.165 100.355 631.224 102.039L621.946 118.563C621.852 118.726 621.098 120.13 622.303 119.58C623.548 119.013 624.333 119.727 624.372 121.224C624.395 122.233 625.57 122.605 626.348 122.38C627.934 121.915 628.292 122.14 628.821 123.652C629.062 124.351 630.509 124.545 631.263 123.971C632.702 122.884 633.386 122.861 635.128 123.971H635.113Z' />
              <path id='Vector_358' fill='#659E36'
                    d='M634.794 121.589C635.463 122.016 636.629 121.907 636.832 121.325C637.267 120.061 637.57 119.874 638.893 120.262C639.538 120.448 640.518 120.138 640.534 119.3C640.565 118.051 641.218 117.454 642.26 117.927C643.264 118.385 642.634 117.213 642.556 117.081L634.817 103.303C634.047 101.938 632.328 101.899 631.543 103.303L623.804 117.081C623.727 117.213 623.097 118.393 624.1 117.927C625.134 117.454 625.795 118.051 625.827 119.3C625.85 120.138 626.83 120.456 627.468 120.262C628.79 119.874 629.093 120.061 629.529 121.325C629.731 121.907 630.936 122.07 631.566 121.589C632.764 120.689 633.34 120.666 634.794 121.589Z' />
              <path id='Vector_359' fill='#325C0F'
                    d='M635.183 116.732C636.023 117.26 637.493 117.128 637.749 116.407C638.302 114.847 638.675 114.614 640.339 115.095C641.148 115.328 642.377 114.94 642.408 113.908C642.447 112.365 643.271 111.628 644.578 112.217C645.846 112.784 645.045 111.341 644.951 111.17L635.214 94.1567C634.249 92.4732 632.087 92.4189 631.092 94.1567L621.354 111.17C621.261 111.333 620.46 112.784 621.728 112.217C623.034 111.628 623.859 112.365 623.898 113.908C623.929 114.94 625.158 115.328 625.966 115.095C627.631 114.614 628.012 114.847 628.556 116.407C628.813 117.128 630.322 117.322 631.123 116.732C632.632 115.615 633.347 115.592 635.183 116.732Z' />
              <path id='Vector_360' fill='#44731D'
                    d='M635.043 114.739C635.813 115.227 637.174 115.111 637.407 114.428C637.913 112.962 638.263 112.745 639.795 113.195C640.541 113.412 641.677 113.047 641.7 112.078C641.739 110.635 642.494 109.944 643.699 110.495C644.866 111.03 644.127 109.673 644.041 109.517L635.074 93.5593C634.187 91.9767 632.196 91.9302 631.278 93.5593L622.311 109.517C622.226 109.673 621.494 111.03 622.653 110.495C623.851 109.944 624.613 110.635 624.652 112.078C624.675 113.047 625.811 113.412 626.558 113.195C628.09 112.745 628.44 112.962 628.945 114.428C629.179 115.103 630.571 115.289 631.31 114.739C632.702 113.691 633.363 113.668 635.043 114.739Z' />
              <path id='Vector_361' fill='#54872A'
                    d='M634.755 112.652C635.408 113.071 636.559 112.962 636.754 112.396C637.182 111.154 637.477 110.976 638.776 111.356C639.406 111.542 640.37 111.232 640.386 110.41C640.417 109.184 641.063 108.602 642.074 109.067C643.061 109.517 642.439 108.369 642.361 108.237L634.771 94.7308C634.016 93.3887 632.328 93.3576 631.558 94.7308L623.968 108.237C623.89 108.369 623.275 109.517 624.255 109.067C625.274 108.602 625.912 109.184 625.943 110.41C625.966 111.232 626.923 111.542 627.553 111.356C628.852 110.976 629.147 111.162 629.575 112.396C629.77 112.97 630.952 113.125 631.574 112.652C632.748 111.767 633.308 111.744 634.739 112.652H634.755Z' />
              <path id='Vector_362' fill='#659E36'
                    d='M634.491 110.697C635.035 111.046 635.992 110.96 636.155 110.479C636.513 109.448 636.762 109.292 637.843 109.611C638.372 109.766 639.173 109.51 639.188 108.827C639.211 107.811 639.748 107.322 640.596 107.71C641.42 108.09 640.899 107.128 640.837 107.019L634.506 95.7548C633.876 94.6377 632.468 94.6067 631.831 95.7548L625.5 107.019C625.438 107.128 624.924 108.09 625.741 107.71C626.589 107.322 627.125 107.811 627.149 108.827C627.164 109.51 627.965 109.766 628.494 109.611C629.575 109.292 629.824 109.448 630.182 110.479C630.345 110.96 631.333 111.085 631.846 110.697C632.826 109.96 633.293 109.936 634.483 110.697H634.491Z' />
            </g>
            <g id='GEMS 2_2'>
              {miniGames.slice(8, 16).map((game, i) => <Gems key={8 + i + 2} index={8 + i + 2}
                                                             level={EnumGameLevelNumbers[game.level]}
                                                             title={game.title}
                                                             onGameSelect={() => onGameSelect(game.id)}
                                                             color={getDiamondColor(game.type, game.progress)} />)}
            </g>
          </g>}
          <g id='ISLAND 1'>
            <g id='ISLAND'>
              <g id='Group_9'>
                <path id='Vector_562' fill='#5E6868'
                      d='M610.526 297.734L609.916 433.262L591.966 458.511L592.576 323.132L610.526 297.734Z' />
                <path id='Vector_563' fill='#6D7A7A'
                      d='M592.576 323.132L591.966 458.511L540.249 475.25L540.859 339.714L592.576 323.132Z' />
                <path id='Vector_564' fill='#6D7A7A'
                      d='M540.859 339.714L540.249 475.25L486.555 493.958L487.165 358.422L540.859 339.714Z' />
                <path id='Vector_565' fill='#8DA0A3'
                      d='M487.165 358.422L486.555 493.958L456.133 482.549L456.742 347.013L487.165 358.422Z' />
                <path id='Vector_566' fill='#8DA0A3'
                      d='M283.939 349.756L283.33 485.284L257.167 463.84L257.776 328.305L283.939 349.756Z' />
                <path id='Vector_567' fill='#849496'
                      d='M333.381 364.205L332.772 499.733L283.33 485.284L283.939 349.756L333.381 364.205Z' />
                <path id='Vector_568' fill='#798889'
                      d='M400.915 367.706L400.306 503.39L332.772 499.733L333.381 364.205L400.915 367.706Z' />
                <path id='Vector_569' fill='url(#paint0_linear_43_7)'
                      d='M610.526 297.734L592.576 323.132L540.859 339.714L487.165 358.422L456.742 347.013L400.915 367.706L333.381 364.205L283.939 349.756L257.776 328.305L299.154 301.383L332.772 287.544L374.299 284.496L419.014 278.416L418.561 276.134L458.564 257.724H524.128L589.84 271.415L610.526 297.734Z' />
                <path id='Vector_570' fill='#5E6868'
                      d='M456.742 347.013L456.133 482.549L400.306 503.39L400.915 367.706L456.742 347.013Z' />
              </g>
              <path id='Vector_571' fill='url(#paint1_linear_43_7)'
                    d='M257.627 374.395L283.337 397.667L333.381 414.398L270.56 422.002L227.666 394.62L234.355 373.325L257.627 367.698C257.627 367.847 257.627 374.388 257.627 374.388V374.395Z' />
              <path id='Vector_572' fill='#8DA0A3'
                    d='M227.659 411.358V394.627L270.553 422.009V440.264L227.659 411.358Z' />
              <path id='Vector_573' fill='#6C7C7C'
                    d='M333.381 414.405L333.077 433.262L270.553 440.264V422.009L333.381 414.405Z' />
              <path id='Vector_574' fill='url(#paint2_linear_43_7)'
                    d='M380.535 435.7L333.381 434.176C333.381 434.176 318.471 439.35 318.167 439.35C317.862 439.35 321.207 451.212 321.207 451.212L339.61 459.886L365.164 458.363L487.313 412.577L456.587 401.168L401.22 423.072L380.535 435.7Z' />
              <path id='Vector_575' fill='url(#paint3_linear_43_7)'
                    d='M333.381 434.176L318.167 439.498L294.434 450.908L269.795 453.948L245.155 446.344L223.095 435.7L208.036 420.485L216.405 401.472L227.659 397.667V411.358L270.553 440.264L333.077 433.262L333.381 434.176Z' />
              <path id='Vector_576' fill='#8DA0A3'
                    d='M206.52 438.131C206.371 438.131 208.043 420.485 208.043 420.485L223.102 435.7L220.515 451.822L206.52 438.131Z' />
              <path id='Vector_577' fill='#849496'
                    d='M245.155 446.344L241.045 458.511L220.508 451.822L223.095 435.7L245.155 446.344Z' />
              <path id='Vector_578' fill='#7C8B8C'
                    d='M269.795 453.948L268.122 466.115L241.045 458.511C241.045 458.511 244.39 445.883 245.155 446.344C245.913 446.953 269.795 453.948 269.795 453.948Z' />
              <path id='Vector_579' fill='#6F7F7F'
                    d='M294.434 450.908V463.536L268.122 466.122L269.795 453.948L294.434 450.908Z' />
              <path id='Vector_580' fill='#5E6D6D'
                    d='M318.167 439.498L319.081 456.23L294.434 463.536V450.908L318.167 439.498Z' />
              <path id='Vector_581' fill='#6D7A7A'
                    d='M487.165 412.882L486.555 493.958L368.211 540.806L365.015 458.511L487.165 412.882Z' />
              <path id='Vector_582' fill='#6F7F7F'
                    d='M339.462 460.035L343.572 537.766L368.211 540.806L365.015 458.511L339.462 460.035Z' />
              <path id='Vector_583' fill='#849496'
                    d='M321.058 451.368L325.161 528.639L343.572 537.766L339.462 460.035L321.058 451.368Z' />
              <path id='Vector_584' fill='#8DA0A3'
                    d='M321.058 517.23L318.167 439.498L321.058 451.368L325.161 528.639L321.058 517.23Z' />
              <path id='Vector_585' fill='url(#paint4_linear_43_7)'
                    d='M319.081 456.23L321.972 466.122L340.22 472.202L304.632 488.331L249.109 493.497L217.773 489.848L183.701 474.945L175.488 461.559L174.418 443.304L189.023 436.763L191.759 424.744L208.036 420.485L206.52 438.131L220.508 451.822L241.045 458.511L268.122 466.122L294.434 463.536L319.081 456.23Z' />
              <path id='Vector_586' fill='#5E6D6D'
                    d='M340.22 472.202L343.572 537.766L299.912 559.061L304.632 488.331L340.22 472.202Z' />
              <path id='Vector_587' fill='#6F7F7F'
                    d='M249.109 493.497L247.437 560.585L299.912 559.061L304.632 488.331L249.109 493.497Z' />
              <path id='Vector_588' fill='#7D8D8E'
                    d='M217.773 489.848L214.882 554.497L247.437 560.585L249.109 493.497L217.773 489.848Z' />
              <path id='Vector_589' fill='#849496'
                    d='M183.701 474.945L179.591 539.283L214.882 554.497L217.773 489.848L183.701 474.945Z' />
              <path id='Vector_590' fill='#8DA0A3'
                    d='M175.488 461.559L171.378 522.552L179.591 539.283L183.701 474.945L175.488 461.559Z' />
              <path id='Vector_591' fill='#819496'
                    d='M174.418 443.304L170.464 507.954L171.378 522.552L175.488 461.559L174.418 443.304Z' />
              <path id='Vector_592' fill='url(#paint5_linear_43_7)'
                    d='M183.701 474.945L171.839 514.948L190.391 527.576L217.773 489.848L183.701 474.945Z' />
              <path id='Vector_593' fill='url(#paint6_linear_43_7)'
                    d='M249.109 493.497L234.355 530.163L190.391 527.576L217.773 489.848L249.109 493.497Z' />
              <path id='Vector_594' fill='#556060'
                    d='M248.344 527.115L234.355 530.163L249.109 493.497L248.344 527.115Z' />
              <path id='Vector_595' fill='#5E6D6D'
                    d='M205.301 528.639L200.737 553.279L209.56 552.216L210.169 528.639H205.301Z' />
              <path id='Vector_596' fill='#9AADAF'
                    d='M175.488 537.306L200.737 553.279L205.301 528.639L190.391 527.576L177.465 518.753L175.488 537.306Z' />
              <path id='Vector_597' fill='url(#paint7_linear_43_7)'
                    d='M231.917 536.243L248.046 539.283L301.584 535.024L342.204 510.384L355.888 513.424L353.911 533.203L332.772 549.934L286.221 555.256L238.763 551.457L231.917 536.243Z' />
              <path id='Vector_598' fill='#556060'
                    d='M355.888 513.424L361.061 539.892L355.888 556.326L353.911 533.203L355.888 513.424Z' />
              <path id='Vector_599' fill='#5E6D6D'
                    d='M332.772 549.934L353.911 533.203L355.888 556.326L336.726 572.752L332.772 549.934Z' />
              <path id='Vector_600' fill='#687777'
                    d='M286.221 555.256L287.596 580.356L336.726 572.752L332.772 549.934L286.221 555.256Z' />
              <path id='Vector_601' fill='#6F7F7F'
                    d='M238.763 551.457L240.131 575.792L287.596 580.356L286.221 555.256L238.763 551.457Z' />
              <path id='Vector_602' fill='#8DA0A3'
                    d='M231.917 536.243V557.842L240.131 575.792L238.763 551.457L231.917 536.243Z' />
              <path id='Vector_603' fill='#849496'
                    d='M368.211 540.806L365.476 458.667L395.742 469.921L397.719 552.974L368.211 540.806Z' />
              <path id='Vector_604' fill='#677272'
                    d='M581.322 482.853V479.204L498.878 472.202L493.549 471.749L489.9 471.444L407.151 464.45L395.742 469.921L397.719 552.974L581.322 482.853Z' />
              <g id='Group_10'>
                <path id='Vector_605' fill='url(#paint8_linear_43_7)'
                      d='M373.533 455.627V459.277L403.807 470.53V466.88L373.533 455.627Z' />
                <path id='Vector_606' fill='url(#paint9_linear_43_7)'
                      d='M403.807 466.88L373.533 455.627L365.476 458.667L395.742 469.921L403.807 466.88Z' />
                <path id='Vector_607' fill='url(#paint10_linear_43_7)'
                      d='M381.598 456.23V459.886L411.871 471.139V467.49L381.598 456.23Z' />
                <path id='Vector_608' fill='url(#paint11_linear_43_7)'
                      d='M412.02 467.49L381.598 456.23L373.533 459.277L403.807 470.53L412.02 467.49Z' />
                <path id='Vector_609' fill='url(#paint12_linear_43_7)'
                      d='M389.662 456.69V460.489L419.928 471.749V468.099L389.662 456.69Z' />
                <path id='Vector_610' fill='url(#paint13_linear_43_7)'
                      d='M419.928 468.099L389.662 456.839L381.598 459.886L411.871 471.139L419.928 468.099Z' />
                <path id='Vector_611' fill='url(#paint14_linear_43_7)'
                      d='M397.719 457.3V460.949L428.141 472.202V468.553L397.719 457.3Z' />
                <path id='Vector_612' fill='url(#paint15_linear_43_7)'
                      d='M428.141 468.553L397.719 457.3L389.662 460.34L419.928 471.593L428.141 468.553Z' />
                <path id='Vector_613' fill='url(#paint16_linear_43_7)'
                      d='M405.784 457.909V461.559L436.057 472.812V469.162L405.784 457.909Z' />
                <path id='Vector_614' fill='url(#paint17_linear_43_7)'
                      d='M436.206 469.162L405.784 457.909L397.719 460.949L427.993 472.202L436.206 469.162Z' />
                <path id='Vector_615' fill='url(#paint18_linear_43_7)'
                      d='M413.848 458.363V462.168L444.27 473.421V469.616L413.848 458.363Z' />
                <path id='Vector_616' fill='url(#paint19_linear_43_7)'
                      d='M444.27 469.616L413.848 458.511L405.784 461.559L436.057 472.812L444.27 469.616Z' />
                <path id='Vector_617' fill='url(#paint20_linear_43_7)'
                      d='M421.905 458.972V462.622L452.328 473.875V470.225L421.905 458.972Z' />
                <path id='Vector_618' fill='url(#paint21_linear_43_7)'
                      d='M452.328 470.225L421.905 458.972L413.848 462.012L444.114 473.272L452.328 470.225Z' />
                <path id='Vector_619' fill='url(#paint22_linear_43_7)'
                      d='M429.97 459.581V463.231L460.236 474.484V470.835L429.97 459.581Z' />
                <path id='Vector_620' fill='url(#paint23_linear_43_7)'
                      d='M460.392 470.835L429.97 459.581L421.905 462.622L452.179 473.875L460.392 470.835Z' />
                <path id='Vector_621' fill='url(#paint24_linear_43_7)'
                      d='M438.034 460.035V463.84L468.456 474.945V471.295L438.034 460.035Z' />
                <path id='Vector_622' fill='url(#paint25_linear_43_7)'
                      d='M468.456 471.295L438.034 460.191L429.97 463.231L460.236 474.484L468.456 471.295Z' />
                <path id='Vector_623' fill='url(#paint26_linear_43_7)'
                      d='M446.091 460.644V464.294L476.514 475.554V471.898L446.091 460.644Z' />
                <path id='Vector_624' fill='url(#paint27_linear_43_7)'
                      d='M476.514 471.898L446.091 460.644L438.034 463.684L468.3 474.945L476.514 471.898Z' />
                <path id='Vector_625' fill='url(#paint28_linear_43_7)'
                      d='M454.156 461.254V464.903L484.578 476.157V472.507L454.156 461.254Z' />
                <path id='Vector_626' fill='url(#paint29_linear_43_7)'
                      d='M484.578 472.507L454.156 461.254L446.091 464.294L476.365 475.554L484.578 472.507Z' />
                <path id='Vector_627' fill='url(#paint30_linear_43_7)'
                      d='M462.22 461.707V465.513L492.642 476.766V472.968L462.22 461.707Z' />
                <path id='Vector_628' fill='url(#paint31_linear_43_7)'
                      d='M492.642 472.968L462.369 461.707L454.156 464.903L484.422 476.157L492.642 472.968Z' />
                <path id='Vector_629' fill='url(#paint32_linear_43_7)'
                      d='M470.278 462.317V465.966L500.7 477.227V473.57L470.278 462.317Z' />
                <path id='Vector_630' fill='url(#paint33_linear_43_7)'
                      d='M500.7 473.57L470.278 462.317L462.22 465.357L492.487 476.617L500.7 473.57Z' />
                <path id='Vector_631' fill='url(#paint34_linear_43_7)'
                      d='M478.342 462.926V466.576L508.764 477.836V474.18L478.342 462.926Z' />
                <path id='Vector_632' fill='url(#paint35_linear_43_7)'
                      d='M508.764 474.18L478.342 462.926L470.278 465.966L500.551 477.227L508.764 474.18Z' />
                <path id='Vector_633' fill='url(#paint36_linear_43_7)'
                      d='M486.399 463.38V467.185L516.829 478.438V474.64L486.399 463.38Z' />
                <path id='Vector_634' fill='url(#paint37_linear_43_7)'
                      d='M516.829 474.64L486.555 463.536L478.342 466.576L508.608 477.836L516.829 474.64Z' />
                <path id='Vector_635' fill='url(#paint38_linear_43_7)'
                      d='M494.464 463.989V467.639L524.886 478.899V475.25L494.464 463.989Z' />
                <path id='Vector_636' fill='url(#paint39_linear_43_7)'
                      d='M524.886 475.25L494.464 463.989L486.399 467.029L516.673 478.29L524.886 475.25Z' />
                <path id='Vector_637' fill='url(#paint40_linear_43_7)'
                      d='M502.677 464.599V468.248L532.95 479.509V475.852L502.677 464.599Z' />
                <path id='Vector_638' fill='url(#paint41_linear_43_7)'
                      d='M532.95 475.852L502.677 464.599L494.464 467.639L524.737 478.899L532.95 475.852Z' />
                <path id='Vector_639' fill='url(#paint42_linear_43_7)'
                      d='M510.585 465.052V468.858L541.007 479.962V476.313L510.585 465.052Z' />
                <path id='Vector_640' fill='url(#paint43_linear_43_7)'
                      d='M541.007 476.313L510.741 465.052L502.528 468.099L532.794 479.509L541.007 476.313Z' />
                <path id='Vector_641' fill='url(#paint44_linear_43_7)'
                      d='M518.806 465.662V469.311L549.072 480.572V476.922L518.806 465.662Z' />
                <path id='Vector_642' fill='url(#paint45_linear_43_7)'
                      d='M549.072 476.922L518.806 465.662L510.585 468.709L540.859 479.962L549.072 476.922Z' />
                <path id='Vector_643' fill='url(#paint46_linear_43_7)'
                      d='M526.863 466.271V469.921L557.136 481.181V477.531L526.863 466.271Z' />
                <path id='Vector_644' fill='url(#paint47_linear_43_7)'
                      d='M557.136 477.531L526.863 466.271L518.65 469.311L548.923 480.572L557.136 477.531Z' />
                <path id='Vector_645' fill='url(#paint48_linear_43_7)'
                      d='M534.771 466.732V470.381L565.194 481.635V477.985L534.771 466.732Z' />
                <path id='Vector_646' fill='url(#paint49_linear_43_7)'
                      d='M565.194 477.985L534.927 466.732L526.714 469.921L556.98 481.025L565.194 477.985Z' />
                <path id='Vector_647' fill='url(#paint50_linear_43_7)'
                      d='M542.985 467.334V470.991L573.258 482.244V478.594L542.985 467.334Z' />
                <path id='Vector_648' fill='url(#paint51_linear_43_7)'
                      d='M573.258 478.594L542.985 467.334L534.771 470.381L565.045 481.634L573.258 478.594Z' />
                <path id='Vector_649' fill='url(#paint52_linear_43_7)'
                      d='M551.049 467.943V471.593L581.322 482.853V479.204L551.049 467.943Z' />
                <path id='Vector_650' fill='url(#paint53_linear_43_7)'
                      d='M581.322 479.204L551.049 467.943L542.836 470.991L573.109 482.244L581.322 479.204Z' />
                <path id='Vector_651' fill='url(#paint54_linear_43_7)'
                      d='M542.985 467.334V470.991L573.258 482.244V478.594L542.985 467.334Z' />
                <path id='Vector_652' fill='url(#paint55_linear_43_7)'
                      d='M573.258 478.594L542.985 467.334L534.771 470.381L565.045 481.634L573.258 478.594Z' />
                <path id='Vector_653' fill='url(#paint56_linear_43_7)'
                      d='M534.771 466.732V470.381L565.194 481.635V477.985L534.771 466.732Z' />
                <path id='Vector_654' fill='url(#paint57_linear_43_7)'
                      d='M565.194 477.985L534.927 466.732L526.714 469.921L556.98 481.025L565.194 477.985Z' />
                <path id='Vector_655' fill='url(#paint58_linear_43_7)'
                      d='M526.863 466.271V469.921L557.136 481.181V477.531L526.863 466.271Z' />
                <path id='Vector_656' fill='url(#paint59_linear_43_7)'
                      d='M557.136 477.531L526.863 466.271L518.65 469.311L548.923 480.572L557.136 477.531Z' />
                <path id='Vector_657' fill='url(#paint60_linear_43_7)'
                      d='M518.806 465.662V469.311L549.072 480.572V476.922L518.806 465.662Z' />
                <path id='Vector_658' fill='url(#paint61_linear_43_7)'
                      d='M549.072 476.922L518.806 465.662L510.585 468.709L540.859 479.962L549.072 476.922Z' />
                <path id='Vector_659' fill='url(#paint62_linear_43_7)'
                      d='M510.585 465.052V468.858L541.007 479.962V476.313L510.585 465.052Z' />
                <path id='Vector_660' fill='url(#paint63_linear_43_7)'
                      d='M541.007 476.313L510.741 465.052L502.528 468.099L532.794 479.509L541.007 476.313Z' />
                <path id='Vector_661' fill='url(#paint64_linear_43_7)'
                      d='M502.677 464.599V468.248L532.95 479.509V475.852L502.677 464.599Z' />
                <path id='Vector_662' fill='url(#paint65_linear_43_7)'
                      d='M532.95 475.852L502.677 464.599L494.464 467.639L524.737 478.899L532.95 475.852Z' />
                <path id='Vector_663' fill='url(#paint66_linear_43_7)'
                      d='M494.464 463.989V467.639L524.886 478.899V475.25L494.464 463.989Z' />
                <path id='Vector_664' fill='url(#paint67_linear_43_7)'
                      d='M524.886 475.25L494.464 463.989L486.399 467.029L516.673 478.29L524.886 475.25Z' />
                <path id='Vector_665' fill='url(#paint68_linear_43_7)'
                      d='M486.399 463.38V467.185L516.829 478.438V474.64L486.399 463.38Z' />
                <path id='Vector_666' fill='url(#paint69_linear_43_7)'
                      d='M516.829 474.64L486.555 463.536L478.342 466.576L508.608 477.836L516.829 474.64Z' />
                <path id='Vector_667' fill='url(#paint70_linear_43_7)'
                      d='M478.342 462.926V466.576L508.764 477.836V474.18L478.342 462.926Z' />
                <path id='Vector_668' fill='url(#paint71_linear_43_7)'
                      d='M508.764 474.18L478.342 462.926L470.278 465.966L500.551 477.227L508.764 474.18Z' />
                <path id='Vector_669' fill='url(#paint72_linear_43_7)'
                      d='M470.278 462.317V465.966L500.7 477.227V473.57L470.278 462.317Z' />
                <path id='Vector_670' fill='url(#paint73_linear_43_7)'
                      d='M500.7 473.57L470.278 462.317L462.22 465.357L492.487 476.617L500.7 473.57Z' />
                <path id='Vector_671' fill='url(#paint74_linear_43_7)'
                      d='M462.22 461.707V465.513L492.642 476.766V472.968L462.22 461.707Z' />
                <path id='Vector_672' fill='url(#paint75_linear_43_7)'
                      d='M492.642 472.968L462.369 461.707L454.156 464.903L484.422 476.157L492.642 472.968Z' />
                <path id='Vector_673' fill='url(#paint76_linear_43_7)'
                      d='M454.156 461.254V464.903L484.578 476.157V472.507L454.156 461.254Z' />
                <path id='Vector_674' fill='url(#paint77_linear_43_7)'
                      d='M484.578 472.507L454.156 461.254L446.091 464.294L476.365 475.554L484.578 472.507Z' />
                <path id='Vector_675' fill='url(#paint78_linear_43_7)'
                      d='M446.091 460.644V464.294L476.514 475.554V471.898L446.091 460.644Z' />
                <path id='Vector_676' fill='url(#paint79_linear_43_7)'
                      d='M476.514 471.898L446.091 460.644L438.034 463.684L468.3 474.945L476.514 471.898Z' />
                <path id='Vector_677' fill='url(#paint80_linear_43_7)'
                      d='M438.034 460.035V463.84L468.456 474.945V471.295L438.034 460.035Z' />
                <path id='Vector_678' fill='url(#paint81_linear_43_7)'
                      d='M468.456 471.295L438.034 460.191L429.97 463.231L460.236 474.484L468.456 471.295Z' />
                <path id='Vector_679' fill='url(#paint82_linear_43_7)'
                      d='M429.97 459.581V463.231L460.236 474.484V470.835L429.97 459.581Z' />
                <path id='Vector_680' fill='url(#paint83_linear_43_7)'
                      d='M460.392 470.835L429.97 459.581L421.905 462.622L452.179 473.875L460.392 470.835Z' />
                <path id='Vector_681' fill='url(#paint84_linear_43_7)'
                      d='M421.905 458.972V462.622L452.328 473.875V470.225L421.905 458.972Z' />
                <path id='Vector_682' fill='url(#paint85_linear_43_7)'
                      d='M452.328 470.225L421.905 458.972L413.848 462.012L444.114 473.272L452.328 470.225Z' />
                <path id='Vector_683' fill='url(#paint86_linear_43_7)'
                      d='M413.848 458.363V462.168L444.27 473.421V469.616L413.848 458.363Z' />
                <path id='Vector_684' fill='url(#paint87_linear_43_7)'
                      d='M444.27 469.616L413.848 458.511L405.784 461.559L436.057 472.812L444.27 469.616Z' />
                <path id='Vector_685' fill='url(#paint88_linear_43_7)'
                      d='M405.784 457.909V461.559L436.057 472.812V469.162L405.784 457.909Z' />
                <path id='Vector_686' fill='url(#paint89_linear_43_7)'
                      d='M436.206 469.162L405.784 457.909L397.719 460.949L427.993 472.202L436.206 469.162Z' />
                <path id='Vector_687' fill='url(#paint90_linear_43_7)'
                      d='M397.719 457.3V460.949L428.141 472.202V468.553L397.719 457.3Z' />
                <path id='Vector_688' fill='url(#paint91_linear_43_7)'
                      d='M428.141 468.553L397.719 457.3L389.662 460.34L419.928 471.593L428.141 468.553Z' />
                <path id='Vector_689' fill='url(#paint92_linear_43_7)'
                      d='M389.662 456.69V460.489L419.928 471.749V468.099L389.662 456.69Z' />
                <path id='Vector_690' fill='url(#paint93_linear_43_7)'
                      d='M419.928 468.099L389.662 456.839L381.598 459.886L411.871 471.139L419.928 468.099Z' />
                <path id='Vector_691' fill='url(#paint94_linear_43_7)'
                      d='M381.598 456.23V459.886L411.871 471.139V467.49L381.598 456.23Z' />
                <path id='Vector_692' fill='url(#paint95_linear_43_7)'
                      d='M412.02 467.49L381.598 456.23L373.533 459.277L403.807 470.53L412.02 467.49Z' />
                <path id='Vector_693' fill='url(#paint96_linear_43_7)'
                      d='M373.533 455.627V459.277L403.807 470.53V466.88L373.533 455.627Z' />
                <path id='Vector_694' fill='url(#paint97_linear_43_7)'
                      d='M403.807 466.88L373.533 455.627L365.476 458.667L395.742 469.921L403.807 466.88Z' />
              </g>
              <path id='Vector_695' fill='url(#paint98_linear_43_7)'
                    d='M396.656 506.125L524.128 492.895L518.806 499.733L505.568 503.39L502.528 510.384L483.054 514.034L468.456 515.706L454.156 522.552L444.27 527.115L417.498 531.679L384.638 526.506L366.539 517.988L359.998 507.344L366.844 504.297L396.656 506.125Z' />
              <path id='Vector_696' fill='#465454'
                    d='M524.128 492.895L529.145 502.78L523.369 511.908L518.806 499.733L524.128 492.895Z' />
              <path id='Vector_697' fill='#6D7C7C'
                    d='M505.568 503.39L518.806 499.733L523.369 511.908L513.781 521.035L505.568 503.39Z' />
              <path id='Vector_698' fill='#465454'
                    d='M502.528 510.384L505.568 503.39L513.781 521.035V534.719L502.528 510.384Z' />
              <path id='Vector_699' fill='#6D7C7C'
                    d='M483.054 514.034L493.705 543.847L513.781 534.719L502.528 510.384L483.054 514.034Z' />
              <path id='Vector_700' fill='#778483'
                    d='M468.456 515.706L476.818 551.457L493.705 543.847L483.054 514.034L468.456 515.706Z' />
              <path id='Vector_701' fill='#5E6D6D'
                    d='M444.27 527.115L453.695 573.362L476.818 551.457L468.456 515.706L444.27 527.115Z' />
              <path id='Vector_702' fill='#6D7C7C'
                    d='M417.498 531.679L421.601 579.598L453.695 573.362L444.27 527.115L417.498 531.679Z' />
              <path id='Vector_703' fill='#7C8B8C'
                    d='M384.638 526.506L376.424 570.166L421.601 579.598L417.498 531.679L384.638 526.506Z' />
              <path id='Vector_704' fill='#849496'
                    d='M366.539 517.988L361.061 553.13L376.425 570.166L384.638 526.506L366.539 517.988Z' />
              <path id='Vector_705' fill='#8DA0A3'
                    d='M359.998 507.344L358.779 534.719L361.061 553.13L366.539 517.988L359.998 507.344Z' />
              <path id='Vector_706' fill='#6D7A7A'
                    d='M487.165 359.641V412.882L372.321 453.948L369.43 446.344L466.777 363.595L487.165 359.641Z' />
              <g id='Group_11'>
                <path id='Vector_707' fill='#B6C7C9'
                      d='M487.165 359.641L456.742 348.388L448.529 351.428L478.795 362.681L487.165 359.641Z' />
                <path id='Vector_708' fill='#8DA0A3'
                      d='M448.529 351.428V355.077L478.795 366.338V362.681L448.529 351.428Z' />
                <path id='Vector_709' fill='#8DA0A3'
                      d='M440.465 358.118V361.923L470.738 373.027V369.378L440.465 358.118Z' />
                <path id='Vector_710' fill='#B6C7C9'
                      d='M478.795 366.338L448.529 355.077L440.465 358.118L470.738 369.378L478.795 366.338Z' />
                <path id='Vector_711' fill='#8DA0A3'
                      d='M432.252 364.963V368.62L462.518 379.873V376.223L432.252 364.963Z' />
                <path id='Vector_712' fill='#B6C7C9'
                      d='M470.582 373.027L440.316 361.923L432.252 364.963L462.518 376.223L470.582 373.027Z' />
                <path id='Vector_713' fill='#8DA0A3'
                      d='M424.038 371.66V375.309L454.305 386.563V382.913L424.038 371.66Z' />
                <path id='Vector_714' fill='#B6C7C9'
                      d='M462.518 379.873L432.096 368.62L424.038 371.66L454.305 382.913L462.518 379.873Z' />
                <path id='Vector_715' fill='#8DA0A3'
                      d='M415.825 378.349V382.155L446.091 393.259V389.61L415.825 378.349Z' />
                <path id='Vector_716' fill='#B6C7C9'
                      d='M454.156 386.563L423.882 375.309L415.825 378.349L446.091 389.61L454.156 386.563Z' />
                <path id='Vector_717' fill='#8DA0A3'
                      d='M407.612 385.195V388.844L437.878 400.105V396.455L407.612 385.195Z' />
                <path id='Vector_718' fill='#B6C7C9'
                      d='M445.943 393.259L415.669 382.155L407.612 385.195L437.878 396.455L445.943 393.259Z' />
                <path id='Vector_719' fill='#8DA0A3'
                      d='M399.392 391.892V395.541L429.665 406.794V403.145L399.392 391.892Z' />
                <path id='Vector_720' fill='#B6C7C9'
                      d='M437.878 400.105L407.456 388.844L399.392 391.892L429.665 403.145L437.878 400.105Z' />
                <path id='Vector_721' fill='#8DA0A3'
                      d='M391.178 398.581V402.387L421.452 413.491V409.842L391.178 398.581Z' />
                <path id='Vector_722' fill='#B6C7C9'
                      d='M429.665 406.794L399.243 395.541L391.178 398.581L421.452 409.842L429.665 406.794Z' />
                <path id='Vector_723' fill='#8DA0A3'
                      d='M382.965 405.427V409.076L413.239 420.337V416.687L382.965 405.427Z' />
                <path id='Vector_724' fill='#B6C7C9'
                      d='M421.452 413.491L391.03 402.387L382.965 405.427L413.239 416.687L421.452 413.491Z' />
                <path id='Vector_725' fill='#8DA0A3'
                      d='M374.752 412.123V415.773L405.025 427.026V423.377L374.752 412.123Z' />
                <path id='Vector_726' fill='#B6C7C9'
                      d='M413.239 420.337L382.816 409.076L374.752 412.123L405.025 423.377L413.239 420.337Z' />
                <path id='Vector_727' fill='#8DA0A3'
                      d='M366.539 418.813V422.463L396.805 433.723V430.066L366.539 418.813Z' />
                <path id='Vector_728' fill='#B6C7C9'
                      d='M405.025 427.026L374.603 415.773L366.539 418.813L396.805 430.066L405.025 427.026Z' />
                <path id='Vector_729' fill='#8DA0A3'
                      d='M358.326 425.659V429.308L388.592 440.568V436.912L358.326 425.659Z' />
                <path id='Vector_730' fill='#B6C7C9'
                      d='M396.805 433.723L366.539 422.463L358.326 425.659L388.592 436.912L396.805 433.723Z' />
                <path id='Vector_731' fill='#8DA0A3'
                      d='M350.261 432.348V436.005L380.535 447.258V443.609L350.261 432.348Z' />
                <path id='Vector_732' fill='#B6C7C9'
                      d='M388.592 440.568L358.326 429.308L350.261 432.348L380.535 443.609L388.592 440.568Z' />
                <path id='Vector_733' fill='#8DA0A3'
                      d='M342.048 439.045V442.694L372.321 453.948V450.298L342.048 439.045Z' />
                <path id='Vector_734' fill='#B6C7C9'
                      d='M380.535 447.258L350.261 436.005L342.048 439.045L372.321 450.298L380.535 447.258Z' />
              </g>
              <path id='Vector_735' fill='url(#paint99_linear_43_7)'
                    d='M365.476 458.667L346.612 459.73L381.598 474.945L396.047 469.772L365.476 458.667Z' />
              <path id='Vector_736' fill='#5A6666'
                    d='M396.656 506.125L377.643 513.424L381.598 474.945L396.047 469.772L396.656 506.125Z' />
              <path id='Vector_737' fill='#849496'
                    d='M346.612 459.73L350.261 503.539L359.998 507.344L377.643 513.424L381.598 474.945L346.612 459.73Z' />
              <path id='Vector_738' fill='#849496'
                    d='M350.261 512.057V503.539L359.998 507.344L359.693 514.948L350.261 512.057Z' />
              <path id='Vector_739' fill='url(#paint100_linear_43_7)'
                    d='M570.671 414.405L591.966 405.122L610.072 385.351L610.221 373.027L629.843 375.919L638.971 394.932L628.476 409.537L601.249 414.101L570.671 414.405Z' />
              <path id='Vector_740' fill='url(#paint101_linear_43_7)'
                    d='M570.671 414.405L591.966 405.122L610.072 385.351L610.221 373.027L629.843 375.919L638.971 394.932L628.476 409.537L601.249 414.101L570.671 414.405Z' />
              <path id='Vector_741' fill='#849496'
                    d='M601.249 414.101L613.566 469.013L578.736 462.77L570.671 414.405L601.249 414.101Z' />
              <path id='Vector_742' fill='#798889'
                    d='M628.476 409.537L649.77 455.627C649.77 455.627 613.722 470.686 613.417 469.014C613.112 467.341 601.249 414.256 601.249 414.256L628.476 409.544V409.537Z' />
              <path id='Vector_743' fill='#606D6D'
                    d='M638.971 394.932L660.875 439.35L649.77 455.627L628.476 409.537L638.971 394.932Z' />
              <path id='Vector_744' fill='#556060'
                    d='M629.843 375.919L656.311 426.268L660.875 439.35L638.971 394.932L629.843 375.919Z' />
              <path id='Vector_745' fill='url(#paint102_linear_43_7)'
                    d='M610.221 364.963L621.177 374.7L610.221 373.027V364.963Z' />
              <path id='Vector_746' fill='url(#paint103_linear_43_7)'
                    d='M610.221 364.963L621.177 374.7L610.221 373.027V364.963Z' />
              <path id='Vector_747' fill='url(#paint104_linear_43_7)'
                    d='M592.576 352.952L566.412 373.027L557.136 371.66L592.576 352.952Z' />
              <path id='Vector_748' fill='url(#paint105_linear_43_7)'
                    d='M592.576 352.952L566.412 373.027L557.136 371.66L592.576 352.952Z' />
              <path id='Vector_749' fill='#626B6B'
                    d='M591.966 405.122L578.885 414.405L566.412 373.027L592.576 352.952L591.966 405.122Z' />
              <path id='Vector_750' fill='#7D8D8E'
                    d='M557.136 371.66L570.671 414.405H578.885L566.412 373.027L557.136 371.66Z' />
              <path id='Vector_751' fill='url(#paint106_linear_43_7)'
                    d='M171.839 514.948V499.733L175.488 461.559L183.701 474.945L171.839 514.948Z' />
              <path id='Vector_752' fill='#677272'
                    d='M581.322 482.853V479.204L573.258 482.244V478.594L565.194 481.634V477.985L557.129 481.025V477.376L549.065 480.416V476.766L541 479.806V476.157L532.936 479.197V475.547L524.872 478.587V474.938L516.807 477.978V474.328L508.743 477.368V474.172L500.678 477.213V473.563L492.614 476.603V472.954L484.55 475.994V472.344L476.485 475.384V471.735L468.421 474.775V471.125L460.357 474.165V470.516L452.292 473.556V469.906L444.228 472.946V469.297L436.163 472.337V468.688L428.099 471.728C428.099 471.728 428.552 468.227 428.099 468.078C427.645 467.929 420.035 471.118 420.035 471.118V467.469L411.97 470.509V466.859L403.906 469.899V466.25L395.693 469.29L426.115 479.941L554.039 488.154L568.949 486.935L581.273 482.223V482.832L581.322 482.853Z' />
              <g id='Group_12' fill='#798889'>
                <path id='Vector_753' d='M420.233 276.134L460.087 257.724H458.564L418.561 276.134H420.233Z' />
                <path id='Vector_754'
                      d='M337.031 363.291L287.596 348.841L261.581 327.391L302.803 300.469L334.444 287.388L332.772 287.544L299.154 301.383L257.776 328.305L283.939 349.756L333.381 364.205L400.915 367.706L403.502 366.791L337.031 363.291Z' />
                <path id='Vector_755'
                      d='M462.064 348.388L456.742 347.013L487.165 358.422L490.361 357.21L462.064 348.388Z' />
              </g>
              <path id='Vector_756' fill='#798889'
                    d='M234.355 373.332L227.659 394.627V406.794L230.096 398.432L259.3 414.859L231.308 393.564L234.355 373.332Z' />
              <path id='Vector_757' fill='#798889'
                    d='M216.405 401.472L208.036 420.485L207.278 429.613L209.404 423.986L221.422 435.7V444.827L224.165 437.521L244.241 445.89L224.165 434.176L210.169 420.485L216.405 401.472Z' />
              <path id='Vector_758' fill='#798889'
                    d='M174.418 443.304L175.481 461.559L181.108 474.335L178.521 492.285L185.211 477.68L213.656 489.848L204.982 507.337L218.978 492.122L248.946 493.646C248.946 493.646 218.219 488.324 218.375 487.864C218.524 487.559 189.016 474.931 189.016 474.931C189.016 474.931 178.216 460.326 178.216 460.021C178.216 459.716 174.411 443.29 174.411 443.29L174.418 443.304Z' />
              <path id='Vector_759' fill='#798889'
                    d='M231.917 536.243C231.917 536.243 240.889 547.957 241.654 548.105C242.42 548.254 283.33 552.974 283.33 552.974L332.765 549.934L288.652 557.842L286.823 567.728L282.26 556.475L241.647 554.646L239.365 563.774C239.365 563.774 238.302 553.123 237.083 550.997C235.865 548.871 231.91 536.087 231.91 536.243H231.917Z' />
              <path id='Vector_760' fill='#798889'
                    d='M359.998 507.344L368.211 517.995L384.638 524.082C384.638 524.082 413.387 529.56 417.491 529.56C421.445 529.709 444.263 526.974 444.263 526.974L418.858 533.819V547.51L415.513 533.061L387.068 529.107L380.528 548.424L380.981 527.888L367.595 521.198C367.595 521.198 363.485 537.476 363.641 537.171C363.641 536.866 364.703 519.221 364.25 518.002C363.796 516.783 359.991 507.351 359.991 507.351L359.998 507.344Z' />
              <path id='Vector_761' fill='#798889'
                    d='M318.167 439.498L323.645 450.908L339.462 460.035L321.816 453.948V460.949L320.753 451.971L318.167 439.498Z' />
              <path id='Vector_762' fill='#798889'
                    d='M346.612 459.73C346.612 459.73 368.665 467.795 369.274 468.099C369.884 468.404 381.598 474.945 381.598 474.945L366.844 470.537L346.612 459.737V459.73Z' />
              <path id='Vector_763' fill='#A7A9AC'
                    d='M359.998 507.344L377.487 514.948C377.487 514.948 423.124 515.706 423.883 515.862C424.641 516.018 459.478 508.868 461.455 508.868C463.432 508.719 518.65 499.896 518.65 499.896L524.128 493.051L396.656 506.281L377.643 513.431L359.998 507.344Z' />
              <path id='Vector_764' fill='#798889'
                    d='M257.776 328.305L280.438 350.358C280.438 350.358 283.939 377.889 283.939 377.435C283.939 376.982 286.83 353.405 286.83 353.405L330.788 365.119C330.788 365.119 332.46 395.236 333.374 390.673C334.288 386.109 337.484 366.94 337.484 366.94L401.064 367.698L333.523 364.049L284.088 349.6L257.776 328.305Z' />
              <path id='Vector_765' fill='#A7A9AC'
                    d='M231.917 536.243L248.046 539.283L301.584 535.024L342.204 510.384L350.261 512.057L355.888 513.729L321.058 540.806L268.122 544.612L239.372 541.111L231.917 536.243Z' />
              <path id='Vector_766' fill='#A7A9AC'
                    d='M206.52 438.131L220.515 451.822L241.052 458.511L268.129 466.115L294.292 463.38L318.932 456.23L320.3 460.793L300.529 467.185C300.529 467.185 274.061 470.686 273.607 470.381C273.154 470.076 239.231 463.989 239.231 463.989L217.78 454.104L206.527 438.131H206.52Z' />
              <path id='Vector_767' fill='#A7A9AC'
                    d='M227.659 411.358L270.553 440.257L333.07 433.262L333.374 434.176L343.565 434.481L318.925 442.694L318.167 439.35L312.079 442.39C312.079 442.39 267.208 445.125 266.903 444.672C266.45 444.218 227.659 411.358 227.659 411.358Z' />
              <path id='Vector_768' fill='#A7A9AC'
                    d='M257.627 367.706V374.395L283.33 397.667L333.381 414.405L327.443 415.164L278.773 399.339L253.673 374.856L257.627 367.706Z' />
              <path id='Vector_769' fill='#A7A9AC'
                    d='M578.885 414.405L591.966 405.122L610.072 385.351L610.221 373.027L625.584 375.309L630.757 386.563L620.716 407.56L596.076 414.101L578.885 414.405Z' />
              <path id='Vector_770' fill='url(#paint107_linear_43_7)'
                    d='M484.578 405.427L540.249 385.195L534.169 389.759L484.578 405.427Z' />
              <path id='Vector_771' fill='url(#paint108_linear_43_7)'
                    d='M484.578 405.427L540.249 385.195L534.169 389.759L484.578 405.427Z' />
              <path id='Vector_772' fill='#778483'
                    d='M484.578 405.427L542.836 448.782L534.169 389.759L484.578 405.427Z' />
              <path id='Vector_773' fill='#5E6868'
                    d='M540.249 385.195L542.836 448.782L534.169 389.759L540.249 385.195Z' />
              <path id='Vector_774' fill='#A7A9AC'
                    d='M540.249 385.195L544.055 388.844L542.836 448.782L540.249 385.195Z' />
              <path id='Vector_775' fill='#637D87'
                    d='M419.014 278.416L470.582 277.502L495.682 271.415L471.036 275.369L418.561 276.134L419.014 278.416Z' />
              <path id='Vector_776' fill='#394B51'
                    d='M471.036 275.369L471.496 277.346L495.682 271.415L471.036 275.369Z' />
              <path id='Vector_777' fill='#394B51'
                    d='M304.632 488.331L284.4 484.072L306.304 487.722C306.304 487.722 303.264 510.994 303.264 510.845C303.115 510.54 304.632 488.331 304.632 488.331Z' />
              <path id='Vector_778' fill='#394B51'
                    d='M468.456 515.706L456.133 515.401L466.933 516.472L473.474 537.462L468.456 515.706Z' />
              <path id='Vector_779' fill='#394B51'
                    d='M610.526 297.734L559.567 300.469L530.059 294.233L560.028 301.993L608.853 299.562C608.853 299.562 610.221 332.72 610.221 332.266C610.37 331.961 610.526 297.734 610.526 297.734Z' />
              <path id='Vector_780' fill='#4C6268' d='M530.059 294.233L559.567 300.469V301.837L530.059 294.233Z' />
              <path id='Vector_781' fill='#A7A9AC'
                    d='M171.839 514.948L190.398 527.576L234.206 530.163L248.202 527.122L234.206 531.381C234.206 531.381 189.64 530.318 189.64 529.248C189.64 528.178 171.846 514.948 171.846 514.948H171.839Z' />
              <path id='Vector_782' fill='#637D87'
                    d='M234.355 373.332H246.827L250.328 375.919L246.367 373.637H234.199L234.355 373.332Z' />
              <path id='Vector_783' fill='#456168'
                    d='M246.827 373.332V373.942L246.367 373.637H234.199L234.355 373.332H246.827Z' />
              <path id='Vector_784' fill='#3B545B'
                    d='M189.023 436.763L204.082 431.136L189.179 436.154L189.023 436.763Z' />
              <path id='Vector_785' fill='#A7A9AC'
                    d='M248.344 527.115L241.654 529.092L258.385 530.765L250.023 493.497L248.344 527.115Z' />
              <path id='Vector_786' fill='#798889'
                    d='M350.261 436.005L342.048 439.045V441.327L342.353 439.498L368.97 449.086L342.658 439.194L350.261 436.005Z' />
              <path id='Vector_787' fill='#798889'
                    d='M358.326 429.308L350.261 432.348V434.63L350.566 432.653L377.183 442.39L350.722 432.348L358.326 429.308Z' />
              <path id='Vector_788' fill='#798889'
                    d='M366.539 422.463L358.326 425.659V427.94L358.779 425.963L385.247 435.7L358.935 425.659L366.539 422.463Z' />
              <path id='Vector_789' fill='#798889'
                    d='M374.603 415.773L366.539 418.813V421.095L366.844 419.267L393.46 428.855L367.148 418.962L374.603 415.773Z' />
              <path id='Vector_790' fill='#798889'
                    d='M382.816 409.076L374.752 412.123V414.405L375.057 412.421L401.674 422.158L375.362 412.123L382.816 409.076Z' />
              <path id='Vector_791' fill='#798889'
                    d='M391.03 402.387L382.965 405.427V407.709L383.27 405.731L409.887 415.312L383.426 405.427L391.03 402.387Z' />
              <path id='Vector_792' fill='#798889'
                    d='M399.243 395.541L391.03 398.581V400.863L391.483 399.042L418.107 408.623L391.639 398.737L399.243 395.541Z' />
              <path id='Vector_793' fill='#798889'
                    d='M407.456 388.844L399.392 391.892V394.173L399.696 392.196L426.32 401.926L400.001 391.892L407.456 388.844Z' />
              <path id='Vector_794' fill='#798889'
                    d='M415.669 382.155L407.612 385.195V387.477L407.91 385.5L434.533 395.236L408.214 385.195L415.669 382.155Z' />
              <path id='Vector_795' fill='#798889'
                    d='M423.882 375.309L415.825 378.349V380.631L416.13 378.81L442.747 388.391L416.279 378.505L423.882 375.309Z' />
              <path id='Vector_796' fill='#798889'
                    d='M432.096 368.62L424.038 371.66V373.942L424.343 371.964L450.96 381.701L424.492 371.809L432.096 368.62Z' />
              <path id='Vector_797' fill='#798889'
                    d='M440.316 361.774L432.252 364.814V367.096L432.556 365.268L459.173 374.856L432.705 364.963L440.316 361.774Z' />
              <path id='Vector_798' fill='#A7A9AC'
                    d='M400.766 391.282L412.169 390.524V386.867L420.694 383.983V380.178L429.056 377.138V373.481L437.12 370.441V366.791L443.81 363.142L443.2 359.188L451.413 356.14L450.35 352.037L457.954 348.841L456.742 347.013V348.388L448.529 351.428V355.077L440.465 358.118L440.316 361.923L432.252 364.963L432.096 368.62L424.038 371.66L423.882 375.309L415.825 378.349L415.669 382.155L407.612 385.195L407.456 388.844L400.766 391.282Z' />
              <path id='Vector_799' fill='#798889'
                    d='M456.742 348.388L448.529 351.428V353.71L448.983 351.733L475.451 361.469L449.139 351.428L456.742 348.388Z' />
              <path id='Vector_800' fill='#798889'
                    d='M448.529 355.077L440.316 358.118V360.399L440.77 358.578L467.386 368.159L440.918 358.273L448.529 355.077Z' />
              <path id='Vector_801' fill='url(#paint109_linear_43_7)'
                    d='M573.258 482.244L582.534 485.745L630.453 467.943L578.126 459.121L551.049 467.943L581.322 479.204L573.258 482.244Z' />
              <path id='Vector_802' fill='#849496'
                    d='M573.258 485.893V482.244L582.534 485.745V489.394L573.258 485.893Z' />
              <path id='Vector_803' fill='#677272'
                    d='M630.453 467.943L582.534 485.745V489.394L630.453 470.835V467.943Z' />
              <path id='Vector_804' fill='#A7A9AC'
                    d='M384.333 459.73L387.678 458.511L392.241 460.184L395.742 458.965L400.306 460.637L403.651 459.418L408.824 461.247L411.864 460.028L416.576 461.856L419.772 460.488L424.485 462.317L427.681 461.098L432.549 462.926L435.894 461.707L441.067 463.684L444.412 462.466L449.124 464.294L452.165 462.926L457.182 464.755L460.527 463.536L464.481 465.059L467.677 463.84L472.999 465.817L476.344 464.599L481.056 466.271L484.252 465.052L488.511 466.576L491.856 465.357L497.029 467.334L500.374 466.115L505.086 467.943L508.126 466.576L513.299 468.553L516.495 467.334L521.208 469.006L524.404 467.788L529.421 469.616L532.461 468.397L537.634 470.374L540.674 469.155L545.387 470.828L548.427 469.609L553.905 471.586L557.101 470.367L585.999 460.63L577.935 459.263L547.513 469.304L542.644 467.476L539.3 468.695L534.587 466.866L531.242 468.085L526.374 466.257L523.029 467.476L518.161 465.647L514.965 466.866L510.096 465.038L506.9 466.257L502.032 464.429L498.836 465.647L493.968 463.819L490.623 465.038L485.754 463.21L482.41 464.429L477.541 462.6L474.196 463.819L469.328 461.991L466.132 463.21L461.264 461.381L457.919 462.6L453.05 460.772L449.706 461.991L444.837 460.162L441.492 461.381L436.624 459.553L433.279 460.772L428.567 458.944L425.222 460.162L420.353 458.334L417.157 459.553L412.289 457.725L409.093 458.944L404.225 457.271L401.029 458.49L396.16 456.662L392.815 457.881L387.947 456.052L384.751 457.271L379.883 455.443L376.687 456.662L384.29 459.702L384.333 459.73Z' />
            </g>
            <g id='TREES' fillRule='evenodd' clipPath='url(#clip0_43_7)' clipRule='evenodd'>
              <path id='Vector_805' fill='#7BA302'
                    d='M417.69 298.85C406.9 299.56 399.3 305.05 400.72 311.11C402.13 317.17 412.03 321.51 422.81 320.8C433.59 320.09 441.19 314.6 439.78 308.54C438.37 302.48 428.47 298.14 417.69 298.85Z' />
              <path id='Vector_806' fill='#56321A'
                    d='M417.57 309.55V309.45C418.49 299.49 418.64 279.58 420.34 279.56C422.07 279.54 422.07 299.5 422.93 309.47V309.58V309.65C422.93 309.68 422.92 309.71 422.91 309.74V309.8C422.91 309.8 422.88 309.86 422.87 309.88L422.84 309.95C422.84 309.95 422.81 310.01 422.8 310.04L422.77 310.1L422.71 310.18L422.67 310.24C422.67 310.24 422.61 310.3 422.59 310.33L422.54 310.38C422.54 310.38 422.47 310.44 422.43 310.47L422.39 310.5C422.33 310.54 422.27 310.58 422.21 310.63C422.14 310.67 422.06 310.71 421.98 310.75H421.96L421.92 310.78C421.85 310.81 421.78 310.84 421.71 310.86L421.66 310.88H421.62C421.57 310.91 421.52 310.93 421.47 310.94L421.41 310.96H421.37C421.37 310.96 421.29 310.99 421.24 311L421.16 311.02H421.12C421.12 311.02 421.05 311.04 421.01 311.05H420.92L420.87 311.07C420.84 311.07 420.8 311.07 420.76 311.07H420.69L420.62 311.08C420.59 311.08 420.55 311.08 420.51 311.08H420.45H420.37H420.13C420.13 311.08 420.04 311.08 420 311.08H419.95H419.88C419.83 311.08 419.78 311.08 419.73 311.06H419.67L419.62 311.05C419.54 311.04 419.47 311.02 419.39 311.01H419.34L419.32 311C419.24 310.98 419.18 310.97 419.11 310.95H419.06C418.97 310.91 418.89 310.88 418.81 310.86C418.75 310.83 418.7 310.81 418.64 310.79L418.6 310.77C418.53 310.74 418.47 310.71 418.4 310.67C418.33 310.63 418.26 310.58 418.2 310.54L418.15 310.5C418.11 310.47 418.07 310.44 418.04 310.42V310.41L417.99 310.36C417.99 310.36 417.93 310.31 417.9 310.28L417.87 310.25L417.84 310.21L417.78 310.14L417.76 310.11H417.75L417.73 310.07L417.69 310V309.98L417.67 309.96L417.66 309.91L417.64 309.85L417.62 309.82V309.77V309.71L417.6 309.68V309.61V309.57L417.57 309.55Z' />
              <path id='Vector_807' fill='#325C0F'
                    d='M424.13 303.06C425.74 304.07 428.56 303.82 429.04 302.43C430.09 299.43 430.82 298.99 434.01 299.91C435.56 300.36 437.92 299.61 437.97 297.63C438.05 294.67 439.63 293.26 442.13 294.38C444.55 295.47 443.03 292.69 442.85 292.37L424.2 259.69C422.35 256.45 418.2 256.36 416.3 259.69L397.64 292.37C397.46 292.69 395.93 295.47 398.36 294.38C400.86 293.25 402.44 294.67 402.52 297.63C402.57 299.62 404.93 300.36 406.48 299.91C409.67 298.99 410.39 299.43 411.44 302.43C411.93 303.82 414.83 304.19 416.35 303.06C419.24 300.92 420.62 300.87 424.13 303.06Z' />
              <path id='Vector_808' fill='#44731D'
                    d='M423.86 299.24C425.34 300.18 427.94 299.95 428.38 298.65C429.35 295.84 430.02 295.42 432.95 296.29C434.38 296.71 436.55 296.01 436.6 294.15C436.67 291.37 438.13 290.05 440.43 291.1C442.66 292.12 441.26 289.52 441.09 289.22L423.92 258.57C422.22 255.53 418.4 255.45 416.65 258.57L399.47 289.21C399.3 289.51 397.9 292.12 400.13 291.09C402.43 290.03 403.89 291.36 403.96 294.14C404.01 296.01 406.18 296.7 407.61 296.28C410.54 295.42 411.21 295.83 412.18 298.64C412.63 299.94 415.3 300.29 416.7 299.23C419.36 297.22 420.63 297.17 423.86 299.23V299.24Z' />
              <path id='Vector_809' fill='#54872A'
                    d='M423.31 295.22C424.56 296.02 426.76 295.82 427.14 294.72C427.96 292.34 428.53 291.99 431.01 292.72C432.22 293.08 434.06 292.49 434.1 290.91C434.16 288.56 435.39 287.44 437.34 288.33C439.23 289.2 438.04 286.99 437.9 286.74L423.36 260.8C421.92 258.23 418.69 258.16 417.21 260.8L402.67 286.74C402.53 286.99 401.34 289.2 403.23 288.33C405.18 287.44 406.41 288.56 406.47 290.91C406.51 292.49 408.35 293.08 409.56 292.72C412.04 291.99 412.61 292.34 413.43 294.72C413.81 295.82 416.07 296.12 417.26 295.22C419.52 293.52 420.58 293.48 423.32 295.22H423.31Z' />
              <path id='Vector_810' fill='#659E36'
                    d='M422.81 291.48C423.86 292.15 425.69 291.98 426 291.06C426.68 289.07 427.16 288.78 429.23 289.39C430.24 289.69 431.77 289.19 431.81 287.88C431.86 285.92 432.89 284.98 434.51 285.73C436.08 286.45 435.09 284.61 434.98 284.4L422.86 262.76C421.66 260.61 418.96 260.55 417.73 262.76L405.61 284.4C405.49 284.61 404.5 286.45 406.08 285.73C407.7 284.98 408.73 285.92 408.78 287.88C408.81 289.2 410.35 289.69 411.36 289.39C413.43 288.78 413.9 289.07 414.59 291.06C414.91 291.98 416.79 292.22 417.78 291.48C419.66 290.06 420.55 290.03 422.83 291.48H422.81Z' />
              <path id='Vector_811' fill='#325C0F'
                    d='M423.44 286.18C424.76 287.01 427.08 286.8 427.47 285.66C428.33 283.2 428.93 282.83 431.55 283.59C432.82 283.96 434.76 283.35 434.8 281.71C434.86 279.28 436.16 278.12 438.21 279.04C440.2 279.94 438.95 277.65 438.8 277.39L423.48 250.57C421.96 247.91 418.56 247.84 417 250.57L401.68 277.39C401.53 277.65 400.28 279.93 402.27 279.04C404.32 278.12 405.62 279.28 405.68 281.71C405.72 283.34 407.66 283.95 408.93 283.59C411.55 282.83 412.14 283.2 413.01 285.66C413.41 286.8 415.79 287.1 417.04 286.18C419.42 284.42 420.54 284.38 423.42 286.18H423.44Z' />
              <path id='Vector_812' fill='#44731D'
                    d='M423.21 283.05C424.43 283.83 426.56 283.63 426.92 282.57C427.71 280.26 428.26 279.92 430.67 280.63C431.84 280.98 433.63 280.4 433.67 278.87C433.73 276.59 434.92 275.5 436.81 276.37C438.64 277.21 437.49 275.07 437.35 274.82L423.25 249.66C421.85 247.16 418.72 247.1 417.28 249.66L403.18 274.82C403.04 275.07 401.89 277.21 403.72 276.37C405.61 275.5 406.8 276.59 406.86 278.87C406.9 280.4 408.68 280.97 409.86 280.63C412.27 279.92 412.82 280.26 413.61 282.57C413.98 283.64 416.17 283.92 417.32 283.05C419.51 281.4 420.54 281.36 423.2 283.05H423.21Z' />
              <path id='Vector_813' fill='#54872A'
                    d='M422.76 279.75C423.79 280.41 425.59 280.25 425.9 279.34C426.57 277.38 427.04 277.1 429.08 277.7C430.07 277.99 431.58 277.51 431.62 276.21C431.67 274.28 432.68 273.36 434.28 274.09C435.83 274.8 434.85 272.99 434.74 272.78L422.81 251.48C421.63 249.37 418.97 249.31 417.76 251.48L405.83 272.78C405.71 272.99 404.74 274.8 406.29 274.09C407.89 273.36 408.9 274.28 408.95 276.21C408.98 277.51 410.49 277.99 411.49 277.7C413.53 277.1 413.99 277.39 414.67 279.34C414.98 280.24 416.84 280.49 417.81 279.75C419.66 278.35 420.54 278.32 422.78 279.75H422.76Z' />
              <path id='Vector_814' fill='#659E36'
                    d='M422.35 276.67C423.21 277.22 424.71 277.08 424.97 276.33C425.53 274.7 425.92 274.46 427.62 274.96C428.45 275.2 429.71 274.8 429.73 273.72C429.77 272.11 430.61 271.34 431.95 271.95C433.24 272.54 432.43 271.03 432.33 270.86L422.38 253.1C421.39 251.34 419.18 251.29 418.17 253.1L408.22 270.86C408.12 271.03 407.31 272.54 408.6 271.95C409.93 271.34 410.78 272.11 410.82 273.72C410.85 274.8 412.11 275.21 412.93 274.96C414.63 274.46 415.02 274.7 415.58 276.33C415.84 277.08 417.39 277.29 418.2 276.67C419.74 275.51 420.48 275.48 422.35 276.67Z' />
              <path id='Vector_815' fill='#325C0F'
                    d='M422.86 270.41C423.94 271.09 425.83 270.92 426.16 269.99C426.87 267.98 427.35 267.68 429.49 268.3C430.53 268.6 432.11 268.1 432.15 266.77C432.2 264.78 433.26 263.83 434.94 264.59C436.57 265.32 435.54 263.46 435.42 263.24L422.9 241.31C421.66 239.14 418.88 239.07 417.6 241.31L405.08 263.24C404.96 263.45 403.93 265.32 405.56 264.59C407.24 263.83 408.3 264.78 408.35 266.77C408.39 268.1 409.97 268.6 411.01 268.3C413.15 267.68 413.64 267.98 414.34 269.99C414.67 270.92 416.61 271.17 417.64 270.41C419.58 268.97 420.5 268.94 422.86 270.41Z' />
              <path id='Vector_816' fill='#44731D'
                    d='M422.67 267.85C423.66 268.48 425.41 268.33 425.71 267.45C426.36 265.56 426.81 265.28 428.78 265.86C429.74 266.14 431.2 265.67 431.23 264.42C431.28 262.56 432.25 261.67 433.8 262.38C435.3 263.07 434.35 261.32 434.24 261.12L422.71 240.55C421.57 238.51 419.01 238.45 417.83 240.55L406.3 261.12C406.19 261.32 405.25 263.07 406.74 262.38C408.28 261.67 409.26 262.56 409.31 264.42C409.34 265.67 410.8 266.14 411.76 265.86C413.73 265.28 414.18 265.56 414.83 267.45C415.13 268.32 416.92 268.56 417.87 267.85C419.66 266.5 420.51 266.47 422.67 267.85Z' />
              <path id='Vector_817' fill='#54872A'
                    d='M422.3 265.15C423.14 265.69 424.62 265.55 424.87 264.82C425.42 263.22 425.8 262.99 427.47 263.48C428.28 263.72 429.52 263.32 429.54 262.26C429.58 260.68 430.41 259.93 431.71 260.53C432.98 261.11 432.18 259.63 432.08 259.46L422.34 242.04C421.37 240.31 419.2 240.27 418.21 242.04L408.45 259.45C408.36 259.62 407.56 261.1 408.82 260.52C410.13 259.92 410.95 260.67 410.99 262.25C411.02 263.31 412.25 263.71 413.06 263.47C414.73 262.98 415.11 263.22 415.66 264.81C415.91 265.55 417.43 265.75 418.23 265.14C419.74 264 420.46 263.97 422.3 265.14V265.15Z' />
              <path id='Vector_818' fill='#659E36'
                    d='M421.97 262.64C422.67 263.09 423.9 262.98 424.11 262.36C424.57 261.03 424.89 260.83 426.28 261.24C426.96 261.44 427.99 261.11 428.01 260.23C428.04 258.92 428.73 258.29 429.82 258.79C430.88 259.28 430.21 258.04 430.13 257.9L421.99 243.38C421.18 241.94 419.37 241.9 418.55 243.38L410.41 257.9C410.33 258.04 409.67 259.28 410.72 258.79C411.81 258.29 412.5 258.92 412.53 260.23C412.55 261.11 413.58 261.44 414.26 261.24C415.65 260.83 415.97 261.03 416.43 262.36C416.64 262.98 417.91 263.14 418.57 262.64C419.83 261.69 420.43 261.66 421.96 262.64H421.97Z' />
              <path id='Vector_819' fill='#7BA302'
                    d='M442.69 311.25C433.88 311.83 427.68 316.31 428.83 321.25C429.98 326.2 438.06 329.74 446.87 329.16C455.67 328.58 461.88 324.1 460.72 319.15C459.57 314.2 451.49 310.66 442.69 311.24V311.25Z' />
              <path id='Vector_820' fill='#56321A'
                    d='M442.09 319.94V319.84C443.01 309.88 443.16 289.97 444.86 289.95C446.59 289.93 446.59 309.89 447.45 319.86V319.97V320.04C447.45 320.07 447.44 320.1 447.43 320.13V320.19C447.43 320.19 447.4 320.25 447.39 320.27L447.36 320.34C447.36 320.34 447.33 320.4 447.32 320.43L447.29 320.49L447.23 320.57L447.19 320.63C447.19 320.63 447.13 320.69 447.11 320.72L447.06 320.77C447.06 320.77 446.99 320.83 446.95 320.87L446.91 320.9C446.85 320.94 446.79 320.98 446.73 321.03C446.66 321.07 446.58 321.11 446.5 321.15H446.48L446.44 321.18C446.37 321.21 446.3 321.24 446.23 321.26L446.18 321.28H446.14C446.09 321.31 446.04 321.33 445.99 321.34L445.93 321.36H445.89C445.89 321.36 445.8 321.39 445.76 321.4L445.68 321.42H445.64C445.64 321.42 445.57 321.44 445.53 321.45H445.44L445.39 321.47C445.36 321.47 445.32 321.47 445.28 321.47H445.21L445.14 321.48C445.11 321.48 445.07 321.48 445.04 321.48H444.98H444.9H444.66C444.66 321.48 444.57 321.48 444.53 321.48H444.48H444.41C444.36 321.48 444.31 321.48 444.26 321.46H444.2L444.15 321.45C444.07 321.44 444 321.42 443.92 321.41H443.87L443.85 321.4C443.77 321.38 443.71 321.37 443.64 321.35H443.59C443.5 321.31 443.42 321.28 443.34 321.26C443.28 321.23 443.23 321.21 443.17 321.19L443.13 321.17C443.06 321.14 443 321.11 442.93 321.07C442.86 321.03 442.79 320.98 442.73 320.94L442.68 320.9C442.64 320.87 442.6 320.84 442.57 320.82V320.81L442.52 320.76C442.52 320.76 442.46 320.71 442.44 320.68L442.41 320.65L442.38 320.61L442.32 320.54L442.3 320.51H442.29L442.27 320.47L442.23 320.4V320.38L442.21 320.36L442.2 320.31L442.18 320.25L442.16 320.22V320.17V320.11L442.14 320.08V320.01V319.97L442.09 319.94Z' />
              <path id='Vector_821' fill='#325C0F'
                    d='M447.97 312.43C449.29 313.26 451.61 313.05 452 311.91C452.86 309.45 453.46 309.08 456.08 309.84C457.35 310.21 459.29 309.6 459.33 307.97C459.39 305.54 460.69 304.38 462.74 305.3C464.73 306.2 463.48 303.91 463.33 303.65L448.01 276.83C446.49 274.17 443.09 274.1 441.53 276.83L426.21 303.65C426.06 303.91 424.81 306.19 426.8 305.3C428.85 304.37 430.15 305.54 430.21 307.97C430.25 309.6 432.19 310.21 433.46 309.84C436.08 309.08 436.67 309.45 437.54 311.91C437.94 313.05 440.32 313.35 441.57 312.43C443.95 310.67 445.07 310.63 447.95 312.43H447.97Z' />
              <path id='Vector_822' fill='#44731D'
                    d='M447.74 309.29C448.96 310.07 451.09 309.87 451.45 308.81C452.24 306.5 452.79 306.16 455.2 306.87C456.37 307.22 458.16 306.64 458.2 305.11C458.26 302.83 459.45 301.74 461.34 302.61C463.17 303.45 462.02 301.31 461.88 301.06L447.78 275.9C446.38 273.4 443.25 273.34 441.81 275.9L427.71 301.06C427.57 301.31 426.42 303.45 428.25 302.61C430.14 301.74 431.33 302.83 431.39 305.11C431.43 306.64 433.21 307.21 434.39 306.87C436.8 306.16 437.35 306.5 438.14 308.81C438.51 309.88 440.7 310.16 441.85 309.29C444.04 307.64 445.07 307.6 447.73 309.29H447.74Z' />
              <path id='Vector_823' fill='#54872A'
                    d='M447.29 305.99C448.32 306.65 450.12 306.49 450.43 305.58C451.1 303.62 451.57 303.34 453.61 303.94C454.6 304.23 456.11 303.75 456.15 302.45C456.2 300.52 457.21 299.6 458.81 300.33C460.36 301.04 459.38 299.23 459.27 299.02L447.34 277.72C446.16 275.61 443.5 275.55 442.29 277.72L430.36 299.02C430.24 299.23 429.27 301.04 430.82 300.33C432.42 299.6 433.43 300.52 433.48 302.45C433.51 303.75 435.02 304.23 436.02 303.94C438.06 303.34 438.52 303.63 439.2 305.58C439.51 306.48 441.37 306.73 442.34 305.99C444.19 304.59 445.07 304.56 447.31 305.99H447.29Z' />
              <path id='Vector_824' fill='#659E36'
                    d='M446.88 302.92C447.74 303.47 449.24 303.33 449.5 302.58C450.06 300.95 450.45 300.71 452.15 301.21C452.98 301.45 454.24 301.05 454.26 299.97C454.3 298.36 455.14 297.59 456.48 298.2C457.77 298.79 456.96 297.28 456.86 297.11L446.91 279.35C445.92 277.59 443.71 277.54 442.7 279.35L432.75 297.11C432.65 297.28 431.84 298.8 433.13 298.2C434.46 297.59 435.31 298.36 435.35 299.97C435.38 301.05 436.64 301.46 437.46 301.21C439.16 300.71 439.55 300.95 440.11 302.58C440.37 303.33 441.92 303.54 442.73 302.92C444.27 301.76 445.01 301.73 446.88 302.92Z' />
              <path id='Vector_825' fill='#325C0F'
                    d='M447.38 296.66C448.46 297.34 450.35 297.17 450.68 296.24C451.39 294.23 451.87 293.93 454.01 294.55C455.05 294.85 456.63 294.35 456.67 293.02C456.72 291.03 457.78 290.08 459.46 290.84C461.09 291.57 460.06 289.71 459.94 289.49L447.42 267.56C446.18 265.39 443.4 265.32 442.12 267.56L429.6 289.49C429.48 289.7 428.45 291.57 430.08 290.84C431.76 290.08 432.82 291.03 432.87 293.02C432.91 294.35 434.49 294.85 435.53 294.55C437.67 293.93 438.16 294.23 438.86 296.24C439.19 297.17 441.13 297.42 442.16 296.66C444.1 295.22 445.02 295.19 447.38 296.66Z' />
              <path id='Vector_826' fill='#44731D'
                    d='M447.2 294.09C448.19 294.72 449.94 294.57 450.24 293.69C450.89 291.8 451.34 291.52 453.31 292.1C454.27 292.38 455.73 291.91 455.76 290.66C455.81 288.8 456.78 287.91 458.33 288.62C459.83 289.31 458.88 287.56 458.77 287.36L447.24 266.79C446.1 264.75 443.54 264.69 442.36 266.79L430.83 287.36C430.72 287.56 429.78 289.31 431.27 288.62C432.81 287.91 433.79 288.8 433.84 290.66C433.87 291.91 435.33 292.38 436.29 292.1C438.26 291.52 438.71 291.8 439.36 293.69C439.66 294.56 441.45 294.8 442.4 294.09C444.19 292.74 445.04 292.71 447.2 294.09Z' />
              <path id='Vector_827' fill='#54872A'
                    d='M446.83 291.4C447.67 291.94 449.15 291.8 449.4 291.07C449.95 289.47 450.33 289.24 452 289.73C452.81 289.97 454.05 289.57 454.07 288.51C454.11 286.93 454.94 286.18 456.24 286.78C457.51 287.36 456.71 285.88 456.61 285.71L446.85 268.3C445.88 266.57 443.71 266.53 442.72 268.3L432.96 285.71C432.86 285.88 432.07 287.36 433.33 286.78C434.64 286.18 435.46 286.93 435.5 288.51C435.53 289.57 436.76 289.97 437.57 289.73C439.24 289.24 439.62 289.48 440.17 291.07C440.42 291.81 441.94 292.01 442.74 291.4C444.25 290.26 444.97 290.23 446.81 291.4H446.83Z' />
              <path id='Vector_828' fill='#659E36'
                    d='M446.49 288.88C447.19 289.33 448.42 289.22 448.63 288.6C449.09 287.27 449.41 287.07 450.8 287.48C451.48 287.68 452.51 287.35 452.53 286.47C452.56 285.16 453.25 284.53 454.34 285.03C455.4 285.52 454.73 284.28 454.65 284.14L446.51 269.62C445.7 268.18 443.89 268.14 443.07 269.62L434.93 284.14C434.85 284.28 434.19 285.52 435.24 285.03C436.33 284.53 437.02 285.16 437.05 286.47C437.07 287.35 438.1 287.68 438.78 287.48C440.17 287.07 440.49 287.27 440.95 288.6C441.16 289.22 442.43 289.38 443.09 288.88C444.35 287.93 444.95 287.9 446.48 288.88H446.49Z' />
            </g>
            <g id='GEMS 1'>
              {miniGames.slice(0, 8).map((game, i) => <Gems key={i + 2} index={i + 2}
                                                            onGameSelect={() => onGameSelect(game.id)}
                                                            level={EnumGameLevelNumbers[game.level]}
                                                            title={game.title}
                                                            color={getDiamondColor(game.type)} />)}
            </g>
          </g>
        </g>
        <defs>
          <linearGradient id='paint0_linear_43_7' x1='257.776' x2='610.526' y1='312.715' y2='312.715'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#96C40A' />
            <stop offset='1' stopColor='#96C40A' />
          </linearGradient>
          <linearGradient id='paint1_linear_43_7' x1='227.659' x2='333.381' y1='394.854' y2='394.854'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#66A609' />
            <stop offset='1' stopColor='#91CF0A' />
          </linearGradient>
          <linearGradient id='paint2_linear_43_7' x1='275.924' x2='403.459' y1='467.965' y2='430.236'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='1' stopColor='#ADBBC0' />
          </linearGradient>
          <linearGradient id='paint3_linear_43_7' x1='208.036' x2='333.381' y1='425.807' y2='425.807'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='1' stopColor='#ADBBC0' />
          </linearGradient>
          <linearGradient id='paint4_linear_43_7' x1='174.418' x2='340.22' y1='456.988' y2='456.988'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#86C50A' />
            <stop offset='1' stopColor='#66A609' />
          </linearGradient>
          <linearGradient id='paint5_linear_43_7' x1='189.951' x2='199.858' y1='522.46' y2='481.493'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.11' stopColor='#C3CDD1' />
            <stop offset='.29' stopColor='#B6C3C7' />
            <stop offset='.52' stopColor='#AFBCC1' />
            <stop offset='1' stopColor='#ADBBC0' />
          </linearGradient>
          <linearGradient id='paint6_linear_43_7' x1='214.981' x2='224.2' y1='532.267' y2='490.252'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#BDC4C7' />
            <stop offset='.11' stopColor='#B0B9BD' />
            <stop offset='.29' stopColor='#A3AEB2' />
            <stop offset='.52' stopColor='#9CA7AC' />
            <stop offset='1' stopColor='#9AA6AB' />
          </linearGradient>
          <linearGradient id='paint7_linear_43_7' x1='231.917' x2='355.888' y1='532.82' y2='532.82'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#66A609' />
            <stop offset='1' stopColor='#66A609' />
          </linearGradient>
          <linearGradient id='paint8_linear_43_7' x1='373.569' x2='403.863' y1='463.075' y2='463.075'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint9_linear_43_7' x1='365.426' x2='403.885' y1='462.77' y2='462.77'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint10_linear_43_7' x1='381.626' x2='411.928' y1='463.685' y2='463.685'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint11_linear_43_7' x1='373.491' x2='411.977' y1='463.38' y2='463.38'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint12_linear_43_7' x1='389.69' x2='419.992' y1='464.216' y2='464.216'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint13_linear_43_7' x1='381.555' x2='420.006' y1='463.989' y2='463.989'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint14_linear_43_7' x1='397.719' x2='428.035' y1='464.747' y2='464.747'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint15_linear_43_7' x1='389.612' x2='428.099' y1='464.443' y2='464.443'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint16_linear_43_7' x1='405.826' x2='436.1' y1='465.357' y2='465.357'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint17_linear_43_7' x1='397.677' x2='436.163' y1='465.052' y2='465.052'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint18_linear_43_7' x1='413.891' x2='444.192' y1='465.888' y2='465.888'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint19_linear_43_7' x1='405.734' x2='444.221' y1='465.662' y2='465.662'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint20_linear_43_7' x1='421.955' x2='452.257' y1='466.42' y2='466.42'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint21_linear_43_7' x1='413.848' x2='452.328' y1='466.115' y2='466.115'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint22_linear_43_7' x1='430.012' x2='460.286' y1='467.029' y2='467.029'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint23_linear_43_7' x1='421.905' x2='460.392' y1='466.725' y2='466.725'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint24_linear_43_7' x1='438.077' x2='468.378' y1='467.483' y2='467.483'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint25_linear_43_7' x1='429.97' x2='468.456' y1='467.334' y2='467.334'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint26_linear_43_7' x1='446.141' x2='476.457' y1='468.092' y2='468.092'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint27_linear_43_7' x1='438.034' x2='476.514' y1='467.788' y2='467.788'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint28_linear_43_7' x1='454.213' x2='484.514' y1='468.702' y2='468.702'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint29_linear_43_7' x1='446.091' x2='484.578' y1='468.397' y2='468.397'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint30_linear_43_7' x1='462.277' x2='492.579' y1='469.233' y2='469.233'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint31_linear_43_7' x1='454.156' x2='492.642' y1='468.928' y2='468.928'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint32_linear_43_7' x1='470.341' x2='500.643' y1='469.765' y2='469.765'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint33_linear_43_7' x1='462.22' x2='500.7' y1='469.467' y2='469.467'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint34_linear_43_7' x1='478.399' x2='508.7' y1='470.374' y2='470.374'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint35_linear_43_7' x1='470.278' x2='508.764' y1='470.069' y2='470.069'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint36_linear_43_7' x1='486.463' x2='516.765' y1='470.906' y2='470.906'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint37_linear_43_7' x1='478.342' x2='516.829' y1='470.679' y2='470.679'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint38_linear_43_7' x1='494.527' x2='524.843' y1='471.444' y2='471.444'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint39_linear_43_7' x1='486.399' x2='524.886' y1='471.139' y2='471.139'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint40_linear_43_7' x1='502.606' x2='532.872' y1='472.047' y2='472.047'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint41_linear_43_7' x1='494.464' x2='532.95' y1='471.742' y2='471.742'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint42_linear_43_7' x1='510.663' x2='540.965' y1='472.507' y2='472.507'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint43_linear_43_7' x1='502.528' x2='541.007' y1='472.28' y2='472.28'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint44_linear_43_7' x1='518.728' x2='549.029' y1='473.117' y2='473.117'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint45_linear_43_7' x1='510.585' x2='549.072' y1='472.812' y2='472.812'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint46_linear_43_7' x1='526.785' x2='557.058' y1='473.726' y2='473.726'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint47_linear_43_7' x1='518.65' x2='557.136' y1='473.421' y2='473.421'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint48_linear_43_7' x1='534.849' x2='565.151' y1='474.18' y2='474.18'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint49_linear_43_7' x1='526.714' x2='565.194' y1='473.875' y2='473.875'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint50_linear_43_7' x1='542.914' x2='573.23' y1='474.789' y2='474.789'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint51_linear_43_7' x1='534.771' x2='573.258' y1='474.484' y2='474.484'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint52_linear_43_7' x1='550.992' x2='581.287' y1='475.398' y2='475.398'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint53_linear_43_7' x1='544.374' x2='629.028' y1='476.745' y2='468.836'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint54_linear_43_7' x1='542.914' x2='573.23' y1='474.789' y2='474.789'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint55_linear_43_7' x1='534.771' x2='573.258' y1='474.484' y2='474.484'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint56_linear_43_7' x1='534.849' x2='565.151' y1='474.18' y2='474.18'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint57_linear_43_7' x1='526.714' x2='565.194' y1='473.875' y2='473.875'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint58_linear_43_7' x1='526.785' x2='557.058' y1='473.726' y2='473.726'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint59_linear_43_7' x1='518.65' x2='557.136' y1='473.421' y2='473.421'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint60_linear_43_7' x1='518.728' x2='549.029' y1='473.117' y2='473.117'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint61_linear_43_7' x1='510.585' x2='549.072' y1='472.812' y2='472.812'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint62_linear_43_7' x1='510.663' x2='540.965' y1='472.507' y2='472.507'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint63_linear_43_7' x1='502.528' x2='541.007' y1='472.28' y2='472.28'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint64_linear_43_7' x1='502.606' x2='532.872' y1='472.047' y2='472.047'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint65_linear_43_7' x1='494.464' x2='532.95' y1='471.742' y2='471.742'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint66_linear_43_7' x1='494.527' x2='524.843' y1='471.444' y2='471.444'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint67_linear_43_7' x1='486.399' x2='524.886' y1='471.139' y2='471.139'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint68_linear_43_7' x1='486.463' x2='516.765' y1='470.906' y2='470.906'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint69_linear_43_7' x1='478.342' x2='516.829' y1='470.679' y2='470.679'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint70_linear_43_7' x1='478.399' x2='508.7' y1='470.374' y2='470.374'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint71_linear_43_7' x1='470.278' x2='508.764' y1='470.069' y2='470.069'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint72_linear_43_7' x1='470.341' x2='500.643' y1='469.765' y2='469.765'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint73_linear_43_7' x1='462.22' x2='500.7' y1='469.467' y2='469.467'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint74_linear_43_7' x1='462.277' x2='492.579' y1='469.233' y2='469.233'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint75_linear_43_7' x1='454.156' x2='492.642' y1='468.928' y2='468.928'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint76_linear_43_7' x1='454.213' x2='484.514' y1='468.702' y2='468.702'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint77_linear_43_7' x1='446.091' x2='484.578' y1='468.397' y2='468.397'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint78_linear_43_7' x1='446.141' x2='476.457' y1='468.092' y2='468.092'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint79_linear_43_7' x1='438.034' x2='476.514' y1='467.788' y2='467.788'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint80_linear_43_7' x1='438.077' x2='468.378' y1='467.483' y2='467.483'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint81_linear_43_7' x1='429.97' x2='468.456' y1='467.334' y2='467.334'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint82_linear_43_7' x1='430.012' x2='460.286' y1='467.029' y2='467.029'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint83_linear_43_7' x1='421.905' x2='460.392' y1='466.725' y2='466.725'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint84_linear_43_7' x1='421.955' x2='452.257' y1='466.42' y2='466.42'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint85_linear_43_7' x1='413.848' x2='452.328' y1='466.115' y2='466.115'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint86_linear_43_7' x1='413.891' x2='444.192' y1='465.888' y2='465.888'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint87_linear_43_7' x1='405.734' x2='444.221' y1='465.662' y2='465.662'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint88_linear_43_7' x1='405.826' x2='436.1' y1='465.357' y2='465.357'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint89_linear_43_7' x1='397.677' x2='436.163' y1='465.052' y2='465.052'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint90_linear_43_7' x1='397.719' x2='428.035' y1='464.747' y2='464.747'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint91_linear_43_7' x1='389.612' x2='428.099' y1='464.443' y2='464.443'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint92_linear_43_7' x1='389.69' x2='419.992' y1='464.216' y2='464.216'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint93_linear_43_7' x1='381.555' x2='420.006' y1='463.989' y2='463.989'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint94_linear_43_7' x1='381.626' x2='411.928' y1='463.685' y2='463.685'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint95_linear_43_7' x1='373.491' x2='411.977' y1='463.38' y2='463.38'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint96_linear_43_7' x1='373.569' x2='403.863' y1='463.075' y2='463.075'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <linearGradient id='paint97_linear_43_7' x1='279.411' x2='416.626' y1='493.901' y2='453.303'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='1' stopColor='#ADBBC0' />
          </linearGradient>
          <linearGradient id='paint98_linear_43_7' x1='295.086' x2='502.358' y1='606.618' y2='458.568'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#91CF0A' />
            <stop offset='1' stopColor='#91CF0A' />
          </linearGradient>
          <linearGradient id='paint99_linear_43_7' x1='279.928' x2='416.945' y1='492.236' y2='451.701'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='1' stopColor='#ADBBC0' />
          </linearGradient>
          <linearGradient id='paint100_linear_43_7' x1='570.671' x2='638.971' y1='393.713' y2='393.713'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='1' stopColor='#ADBBC0' />
          </linearGradient>
          <linearGradient id='paint101_linear_43_7' x1='560.361' x2='642.79' y1='385.733' y2='405.2'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#ADBBC0' />
            <stop offset='1' stopColor='white' />
          </linearGradient>
          <linearGradient id='paint102_linear_43_7' x1='593.398' x2='619.256' y1='363.383' y2='372.007'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#C2C9CC' />
            <stop offset='1' stopColor='#9FABB0' />
          </linearGradient>
          <linearGradient id='paint103_linear_43_7' x1='602.327' x2='629.312' y1='374.133' y2='365.452'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#ADBBC0' />
            <stop offset='1' stopColor='white' />
          </linearGradient>
          <linearGradient id='paint104_linear_43_7' x1='557.087' x2='592.576' y1='362.986' y2='362.986'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='1' stopColor='#ADBBC0' />
          </linearGradient>
          <linearGradient id='paint105_linear_43_7' x1='557.087' x2='592.576' y1='362.986' y2='362.986'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#ADBBC0' />
            <stop offset='1' stopColor='white' />
          </linearGradient>
          <linearGradient id='paint106_linear_43_7' x1='171.839' x2='183.623' y1='488.246' y2='488.246'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#BDC4C7' />
            <stop offset='.11' stopColor='#B0B9BD' />
            <stop offset='.29' stopColor='#A3AEB2' />
            <stop offset='.52' stopColor='#9CA7AC' />
            <stop offset='1' stopColor='#9AA6AB' />
          </linearGradient>
          <linearGradient id='paint107_linear_43_7' x1='484.578' x2='540.249' y1='395.307' y2='395.307'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='1' stopColor='#ADBBC0' />
          </linearGradient>
          <linearGradient id='paint108_linear_43_7' x1='484.578' x2='540.249' y1='395.307' y2='395.307'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#ADBBC0' />
            <stop offset='1' stopColor='white' />
          </linearGradient>
          <linearGradient id='paint109_linear_43_7' x1='544.118' x2='627.809' y1='475.816' y2='467.993'
                          gradientUnits='userSpaceOnUse'>
            <stop stopColor='#D1D9DC' />
            <stop offset='.63' stopColor='#B0BCBF' />
            <stop offset='1' stopColor='#A3B1B3' />
          </linearGradient>
          <clipPath id='clip0_43_7'>
            <rect width='66.86' height='90.23' fill='white' transform='translate(397 239)' />
          </clipPath>
        </defs>
      </svg>

    </>


  );
}