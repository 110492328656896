import { memo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { TQuizGame } from './types';
import { ButtonMotion } from 'src/components';
import { true_false_btns, true_false_btns_selected } from 'src/helpers';
import classnames from 'classnames';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The true-false component's input properties' interface
 * @param { TQuizGame } gameData
 *    The quiz game's data
 */
interface TrueFalseProps {
  gameData: TQuizGame,
  onAnswersSelect: (answersIndex: number[]) => void
}

/**
 * @description
 *    The Single Choice game's body component
 */
function TrueFalse({ gameData, onAnswersSelect }: TrueFalseProps) {
  const { options, question } = gameData;

  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);

  /**
   * @description - The method is
   * @param {number} selectedOptionIndex
   *
   */
  const handleAnswerSelect = (selectedOptionIndex: number) => {
    setSelectedAnswer(selectedOptionIndex);
    onAnswersSelect([selectedOptionIndex]);
  }

  return (
    <Form>
      {options.map((opt, index) => {
        return (
          <ButtonMotion
            className={classnames('option ', {'left-btn':!index, 'right-btn':index, 'selected':selectedAnswer === index})}
            key={opt.title}
            variant={ true_false_btns_selected[index]}
            onClick={() => handleAnswerSelect(index)}
          >
            {opt.title}
          </ButtonMotion>
        )
      })}
    </Form>
  )
}

export default memo(TrueFalse);
