import { memo } from 'react';

function FilledStar(){
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 49 49">
      <path fill="url(#a)" d="M45.332 27.955c1.9-11.62-5.936-22.589-17.504-24.498C16.26 1.547 5.342 9.42 3.44 21.04c-1.9 11.62 5.937 22.589 17.504 24.498 11.568 1.91 22.486-5.964 24.387-17.584Z" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#b)" fillRule="evenodd" d="m24.384 35.632 12.487 7.061.66.02.185-.63-2.825-14.122 10.547-9.753.218-.617-.541-.371-14.243-1.665L24.899 2.46l-.515-.404-.522.404-5.973 13.095L3.646 17.22l-.541.37.224.617 10.547 9.754-2.831 14.122.185.63.66-.02 12.494-7.061Z" clipRule="evenodd"/>
      <path fill="#fff" fillRule="evenodd" d="M24.384 2.056v1.008l-.522-.604.522-.404Z" clipRule="evenodd"/>
      <path fill="url(#c)" fillRule="evenodd" d="M23.862 2.46 17.89 15.555l.482.67 6.013-13.161-.522-.604Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#fff" fillRule="evenodd" d="m4.055 17.896-.95-.305.541-.371.41.676Z" clipRule="evenodd"/>
      <path fill="url(#d)" fillRule="evenodd" d="m3.646 17.22.41.676 14.315-1.671-.482-.67L3.646 17.22Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFF873" fillRule="evenodd" d="m3.105 17.59.224.617.726-.311-.95-.305Z" clipRule="evenodd"/>
      <path fill="url(#e)" fillRule="evenodd" d="m13.876 27.96.786-.251-10.607-9.813-.726.311 10.547 9.754Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#f)" fillRule="evenodd" d="m14.662 27.709-2.845 14.195-.772.18 2.831-14.123.786-.252Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFECBD" fillRule="evenodd" d="m11.817 41.904-.587.81-.185-.63.772-.18Z" clipRule="evenodd"/>
      <path fill="url(#g)" fillRule="evenodd" d="M24.384 34.803v.829L11.89 42.693l-.073-.789 12.567-7.1Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="#FFB717" fillRule="evenodd" d="m11.89 42.693-.073-.789-.587.81.66-.02Z" clipRule="evenodd"/>
      <path fill="#FFFDA9" fillRule="evenodd" d="M24.384 2.056v1.008l.515-.604-.515-.404Z" clipRule="evenodd"/>
      <path fill="url(#h)" fillRule="evenodd" d="m24.899 2.46 5.973 13.095-.482.67-6.006-13.161.515-.604Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="#FFFAB8" fillRule="evenodd" d="m44.712 17.896.944-.305-.541-.371-.403.676Z" clipRule="evenodd"/>
      <path fill="url(#i)" fillRule="evenodd" d="m45.115 17.22-.403.676-14.322-1.671.482-.67 14.243 1.665Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#EDB400" fillRule="evenodd" d="m45.656 17.59-.218.617-.726-.311.944-.305Z" clipRule="evenodd"/>
      <path fill="url(#j)" fillRule="evenodd" d="m34.891 27.96-.792-.251 10.613-9.813.726.311-10.547 9.754Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#k)" fillRule="evenodd" d="m34.1 27.709 2.844 14.195.772.18-2.825-14.123-.792-.252Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFC552" fillRule="evenodd" d="m36.944 41.904.587.81.185-.63-.772-.18Z" clipRule="evenodd"/>
      <path fill="url(#l)" fillRule="evenodd" d="M24.384 34.803v.829l12.487 7.061.073-.789-12.56-7.1Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="#894E00" fillRule="evenodd" d="m36.871 42.693.073-.789.587.81-.66-.02Z" clipRule="evenodd"/>
      <path fill="url(#m)" fillRule="evenodd" d="m4.055 17.896 3.287 1.074 12.006-1.4-.977-1.345-14.316 1.67Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#n)" fillRule="evenodd" d="m18.371 16.225 6.013-13.161v3.468L19.348 17.57l-.977-1.346Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#o)" fillRule="evenodd" d="m7.342 18.97-3.287-1.074 10.607 9.813 1.57-.517-8.89-8.222Z" clipRule="evenodd" opacity=".5" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#p)" fillRule="evenodd" d="m14.662 27.709-2.845 14.195 2.033-2.811 2.383-11.902-1.571.518Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#q)" fillRule="evenodd" d="M24.384 33.139v1.664l-12.567 7.101 2.033-2.811 10.534-5.954Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#r)" fillRule="evenodd" d="M44.712 17.896 41.42 18.97l-12-1.4.971-1.345 14.322 1.67Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#s)" fillRule="evenodd" d="M30.39 16.225 24.384 3.064v3.468L29.42 17.57l.97-1.346Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#t)" fillRule="evenodd" d="m41.419 18.97 3.293-1.074L34.1 27.709l-1.57-.517 8.89-8.222Z" clipRule="evenodd" opacity=".5" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#u)" fillRule="evenodd" d="m34.1 27.709 2.844 14.195-2.033-2.811-2.383-11.902 1.571.518Z" clipRule="evenodd" opacity=".5" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#v)" fillRule="evenodd" d="M24.384 33.139v1.664l12.56 7.101-2.033-2.811-10.527-5.954Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#w)" fillRule="evenodd" d="m24.384 24.533-5.036-6.962L7.342 18.97l17.042 5.563Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#x)" fillRule="evenodd" d="M24.384 24.533 7.342 18.97l8.89 8.222 8.152-2.66Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#y)" fillRule="evenodd" d="m24.384 24.533-8.151 2.659-2.383 11.901 10.534-14.56Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#z)" fillRule="evenodd" d="M24.384 24.533 13.85 39.093l10.534-5.954v-8.606Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#A)" fillRule="evenodd" d="M24.384 24.533v8.606l10.527 5.954-10.527-14.56Z" clipRule="evenodd" opacity=".5" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#B)" fillRule="evenodd" d="m24.384 24.533 10.527 14.56-2.383-11.901-8.144-2.66Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#C)" fillRule="evenodd" d="m24.384 24.533 8.144 2.659 8.89-8.222-17.034 5.563Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#D)" fillRule="evenodd" d="m24.384 24.533 17.035-5.563-12-1.4-5.035 6.963Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#E)" fillRule="evenodd" d="m24.384 24.533 5.036-6.962-5.036-11.04v18.002Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#F)" fillRule="evenodd" d="M24.384 24.533V6.53l-5.036 11.04 5.036 6.962Z" clipRule="evenodd" style={{mixBlendMode:"multiply"}}/>
      <path fill="url(#G)" fillRule="evenodd" d="m29.255 26.124 3.273 1.068 8.89-8.222-12.163 7.154Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#H)" fillRule="evenodd" d="M24.384 27.98v5.159l10.527 5.954-10.527-11.112Z" clipRule="evenodd" opacity=".45" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#I)" fillRule="evenodd" d="m24.384 24.533-3.122 1.02-7.412 13.54 10.534-14.56Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#J)" fillRule="evenodd" d="m24.384 24.533 2.831-3.912-2.831-14.09v18.002Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#K)" fillRule="evenodd" d="m32.528 27.192 8.89-8.222-11.998-1.399-5.036-11.04-5.036 11.04L7.342 18.97l8.89 8.222-2.382 11.901 10.534-5.954 10.527 5.954-2.383-11.901Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#L)" fillRule="evenodd" d="M15.388 31.402 37.59 18.526l-8.171-.955-5.036-11.04-5.036 11.04L7.342 18.97l8.89 8.222-.844 4.21Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#M)" fillRule="evenodd" d="m4.458 17.85 13.807-1.77 5.927-12.605-5.755 12.843-13.98 1.531Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#N)" fillRule="evenodd" d="m12.53 23.77 3.518 3.481-.805 4.893 1.168-5.012-3.881-3.362Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#O)" fillRule="evenodd" d="m12.701 25.899 1.855 1.843L12.26 39.69l2.495-12.014-2.053-1.777Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#P)" fillRule="evenodd" d="m26.318 34.233 8.586 4.993 1.406 1.803-1.287-1.923-1.927-9.07 1.637 8.805-8.415-4.608Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#Q)" fillRule="evenodd" d="m15.183 32.443-1.439 6.643-1.505 2.228 1.703-2.128 5.795-3.422-5.729 3.11 1.175-6.431Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#R)" fillRule="evenodd" d="m10.649 22.033-3.432-3.03-2.865-1.008 2.937.882 4.62-.438-4.435.577 3.175 3.017Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#S)" fillRule="evenodd" d="m11.91 18.44 7.339-1.002L24.08 7.194l-4.66 10.483-7.51.762Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#T)" fillRule="evenodd" d="m26.945 8.673 3.36 7.671 13.384 1.432-13.22-1.657-3.524-7.446Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M18.556 35.473c.013-.126.112-.146.218-.126.125.02.191.146.178.265-.013.093-.145.14-.225.113-.118-.04-.178-.113-.171-.245v-.007Zm2-6.471c.013-.126.112-.146.217-.126.126.02.192.145.179.265-.014.093-.146.14-.225.113-.119-.04-.178-.113-.171-.246v-.006Zm9.59-10.397c.013-.126.112-.146.218-.126.125.02.191.146.178.265-.013.093-.145.14-.225.113-.118-.04-.178-.113-.171-.245v-.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M26 24.201c.113-.066.192.007.245.113.06.12-.007.252-.119.312-.085.046-.21-.047-.23-.126-.04-.126-.014-.226.105-.292V24.2Zm5.921 8.029c.112-.066.192.007.244.113.06.12-.006.252-.118.312-.086.046-.212-.047-.231-.126-.04-.126-.014-.226.105-.292v-.006Zm-2.653 4.92c.112-.066.191.007.244.113.06.12-.006.252-.119.312-.085.046-.21-.047-.23-.126-.04-.126-.014-.226.105-.292v-.007Zm-7.617-6.57c.014-.133.12-.153.231-.14.132.02.198.153.185.279-.013.1-.158.146-.238.12-.125-.04-.19-.12-.178-.26Z" clipRule="evenodd" opacity=".6" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M22.133 27.735c.013-.132.119-.152.231-.139.132.02.198.152.185.279-.013.099-.158.145-.238.119-.125-.04-.191-.12-.178-.259Zm6.35 2.341c.013-.133.118-.153.23-.14.133.02.198.153.185.279-.013.1-.158.146-.237.12-.126-.04-.192-.12-.178-.26ZM27.255 35.3c0-.06.053-.066.099-.06.06.007.092.067.086.127 0 .046-.066.066-.106.053-.053-.02-.086-.053-.08-.113V35.3Zm3.082-4.29c.013-.132.119-.152.231-.139.132.02.198.153.185.279-.013.1-.158.146-.238.12-.125-.04-.191-.12-.178-.26Z" clipRule="evenodd" opacity=".5" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M16.035 31.687c.013-.126.112-.146.217-.133.126.02.192.146.179.266-.014.092-.152.139-.231.112-.12-.04-.185-.12-.172-.245h.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M18.47 26.82c.013-.179.158-.212.317-.185.178.026.277.212.257.378-.013.132-.218.198-.33.159-.171-.06-.257-.166-.244-.358v.006Zm2.897 8.819c.014-.18.159-.213.317-.186.178.026.277.212.258.378-.014.132-.212.199-.33.159-.172-.06-.258-.166-.244-.358v.007Zm13.472-3.429c.006-.119.105-.139.21-.125.12.02.185.139.172.252-.006.086-.145.132-.217.106-.113-.04-.172-.113-.165-.232Z" clipRule="evenodd" opacity=".35" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M28.786 21.934c.013-.18.159-.213.317-.186.178.027.277.212.257.378-.013.133-.21.199-.33.16-.171-.06-.257-.167-.244-.359v.007Zm-2.759 6.643c.014-.179.159-.212.317-.185.178.026.277.212.258.378-.014.132-.212.198-.33.159-.172-.06-.258-.166-.245-.358v.006Zm-3.089-9.408c.014-.18.159-.212.317-.186.178.027.277.212.258.378-.014.133-.218.199-.33.16-.172-.06-.258-.166-.245-.359v.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFBC00" fillRule="evenodd" d="M10.9 23.445c.006-.092.085-.112.165-.099.092.013.145.113.132.199-.007.073-.113.106-.172.086-.092-.033-.139-.086-.125-.186Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M24.773 22.1c0-.074.066-.087.126-.074.072.014.112.087.105.153 0 .053-.086.08-.132.066-.072-.026-.105-.066-.099-.146Zm6.825 1.127c0-.073.066-.087.132-.073.072.013.112.086.105.152 0 .053-.085.08-.132.066-.072-.026-.105-.066-.099-.145h-.006Zm-4.977 9.116c0-.073.066-.086.132-.073.073.014.113.087.106.153 0 .053-.086.08-.132.066-.073-.026-.106-.066-.1-.146h-.006Zm5.564-3.003c0-.073.066-.086.132-.073.073.013.112.086.106.152 0 .053-.086.08-.132.067-.073-.027-.106-.067-.1-.146h-.006Zm2.614 6.067c0-.073.066-.087.132-.073.073.013.112.086.105.152 0 .053-.085.08-.132.066-.072-.026-.105-.066-.099-.145H34.8ZM16.721 38.37c0-.073.066-.086.125-.073.073.014.113.087.106.153 0 .053-.086.08-.132.066-.073-.026-.106-.066-.099-.146Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFC785" fillRule="evenodd" d="M17.216 29.983c-.046.02-.172-.007-.205-.02-.04-.007-.151-.046-.171-.093a.387.387 0 0 1 .119-.093c.033-.02.125-.06.158-.046.066.026.119.166.106.232v.02h-.007Zm8.415 4.687c-.046.02-.171-.006-.204-.02-.04-.006-.152-.046-.172-.092a.387.387 0 0 1 .119-.093c.033-.02.125-.06.158-.047.066.027.119.166.106.233v.02h-.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFC785" fillRule="evenodd" d="M16.84 28.683c.066-.033.277-.013.336 0 .06.007.251.053.297.126a.63.63 0 0 1-.178.173c-.053.033-.191.113-.25.1-.113-.034-.218-.253-.205-.359v-.04Zm12.5 5.47c.067-.033.278-.013.337 0 .06.007.251.053.297.126a.63.63 0 0 1-.178.173c-.053.033-.191.113-.25.1-.113-.034-.219-.253-.205-.359v-.04Zm-5.702-7.512c-.013.06-.132.18-.172.212-.04.034-.165.126-.224.12a.508.508 0 0 1-.013-.2c0-.046.033-.178.066-.205.079-.053.264 0 .323.06l.02.02v-.007ZM34.1 29.744c-.014.06-.133.18-.172.212-.04.034-.165.126-.225.12a.508.508 0 0 1-.013-.2c0-.046.033-.178.066-.205.08-.053.264 0 .324.06l.02.02v-.007Z" clipRule="evenodd" opacity=".4" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M25.176 27.324c.06-.08.31-.186.39-.219.078-.026.323-.093.415-.04a.823.823 0 0 1-.099.312c-.033.073-.152.252-.231.279-.145.033-.403-.153-.462-.285l-.013-.04v-.007Zm3.861 1.519c.06-.08.31-.186.39-.22.079-.026.323-.092.415-.039a.823.823 0 0 1-.099.312c-.033.072-.152.252-.23.278-.146.033-.404-.152-.463-.285l-.013-.04v-.006Zm-13.464-3.78c.033-.066.21-.172.264-.199.052-.026.23-.106.303-.073 0 .08-.02.173-.04.246-.02.06-.085.199-.145.225-.105.04-.316-.073-.37-.166l-.012-.033Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFC785" fillRule="evenodd" d="M21.196 22.385c-.093.046-.159.053-.205-.047-.052-.106-.033-.179.08-.239.098-.053.164-.046.204.067.04.113.033.159-.072.219h-.007Zm10.052 9.328c-.092.047-.158.053-.205-.046-.052-.106-.033-.179.08-.239.099-.053.165-.046.204.067.04.112.033.159-.072.218h-.007Zm-2.482-7.067c-.092.046-.158.053-.204-.047-.053-.106-.033-.179.079-.239.099-.053.165-.046.205.067.04.113.032.159-.073.219h-.007Zm7.115.596c-.092.047-.158.053-.204-.046-.053-.106-.033-.18.079-.239.099-.053.165-.046.205.066.04.113.032.16-.073.22h-.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M15.355 35.771c.046-.033.185-.02.237 0 .08.027.185.106.159.206-.027.093-.159.139-.244.112-.132-.04-.152-.198-.146-.318h-.006Zm11.642-12.81c.014.034-.02.113-.04.14-.026.046-.091.093-.144.06-.046-.034-.06-.12-.027-.166.046-.073.145-.06.211-.034Zm-.462 7.844c.034.086 0 .146-.079.186-.072.04-.158-.02-.184-.093-.027-.08.006-.172.085-.199.08-.027.146.04.172.106h.006Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#U)" fillRule="evenodd" d="M26.892 30.845a.488.488 0 1 1-.977 0 .488.488 0 1 1 .977 0Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M28.68 20.29c.034.092 0 .165-.085.205-.086.04-.172-.027-.205-.106a.18.18 0 0 1 .1-.226c.091-.033.164.04.19.12v.006Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#V)" fillRule="evenodd" d="M29.076 20.336a.545.545 0 0 1-.54.544.545.545 0 0 1 0-1.088c.296 0 .54.245.54.544Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M13.725 25.713c.026.073 0 .133-.073.166-.066.033-.139-.02-.165-.087a.137.137 0 0 1 .08-.179c.072-.026.131.034.158.1Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#W)" fillRule="evenodd" d="M14.048 25.746a.443.443 0 1 1-.887.002.443.443 0 0 1 .887-.002Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M31.816 25.594c.171-.08.297-.034.409.112.079.1.092.332-.027.392a.33.33 0 0 1-.442-.12c-.079-.14-.105-.305.06-.391v.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#X)" fillRule="evenodd" d="M32.819 25.852c0 .451-.37.822-.819.822a.824.824 0 0 1-.818-.822.82.82 0 0 1 .818-.822.82.82 0 0 1 .819.822Z" clipRule="evenodd" opacity=".5" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M18.576 31.017c.171-.086.303-.033.415.113.08.1.1.338-.032.404-.159.08-.357.04-.45-.119-.079-.14-.112-.312.06-.398h.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#Y)" d="M18.76 32.111c.46 0 .832-.374.832-.835a.834.834 0 0 0-.831-.836.834.834 0 0 0-.832.836c0 .461.372.835.832.835Z" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M16.226 34.425c.066.133.02.226-.086.318-.072.06-.25.073-.297-.02-.06-.119-.033-.271.093-.338.105-.06.237-.08.297.047l-.007-.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#Z)" fillRule="evenodd" d="M16.035 35.194a.628.628 0 0 1-.627-.63c0-.351.277-.63.627-.63s.627.279.627.63c0 .352-.278.63-.627.63Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M31.934 36.348c.047.093.014.16-.059.225-.053.04-.178.053-.211-.013a.174.174 0 0 1 .066-.239c.072-.04.165-.06.211.034l-.007-.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#aa)" fillRule="evenodd" d="M31.796 36.892a.443.443 0 0 1 0-.889.443.443 0 0 1 0 .889Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M22.747 32.661c.066.133.02.226-.086.319-.072.06-.25.073-.297-.02-.06-.12-.033-.272.093-.338.105-.06.237-.08.297.046l-.007-.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#ab)" fillRule="evenodd" d="M22.549 33.43a.628.628 0 0 1-.627-.63c0-.35.277-.63.627-.63s.627.28.627.63c0 .352-.277.63-.627.63Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#ac)" fillRule="evenodd" d="M22.787 17.571a3.444 3.444 0 0 1-3.44 3.454 3.444 3.444 0 0 1-3.438-3.454 3.444 3.444 0 0 1 3.439-3.454c1.9 0 3.439 1.544 3.439 3.454Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#ad)" fillRule="evenodd" d="M21.645 17.57a2.304 2.304 0 0 1-2.297 2.308 2.304 2.304 0 0 1-2.297-2.307 2.304 2.304 0 0 1 2.297-2.308 2.304 2.304 0 0 1 2.297 2.308Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#ae)" d="M19.09 13.752h.475v7.638h-.475z"/>
      <path fill="url(#af)" d="M17.665 15.821h3.326v3.501h-3.326z"/>
      <path fill="url(#ag)" d="M17.665 15.821h3.326v3.501h-3.326z"/>
      <path fill="url(#ah)" fillRule="evenodd" d="M27.024 6.578a2.69 2.69 0 0 1-2.686 2.698 2.69 2.69 0 0 1-2.687-2.698 2.69 2.69 0 0 1 2.687-2.699 2.69 2.69 0 0 1 2.686 2.699Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#ai)" d="M24.338 8.375c.988 0 1.788-.805 1.788-1.797 0-.993-.8-1.797-1.788-1.797s-1.789.804-1.789 1.797c0 .992.8 1.797 1.789 1.797Z" style={{mixBlendMode:"screen"}}/>
      <path fill="url(#aj)" d="M24.159 3.568h.317v6.047h-.317z"/>
      <path fill="url(#ak)" d="M21.308 6.432h6.019v.318h-6.019z"/>
      <path fill="url(#al)" d="M23.051 5.159h2.693v2.705h-2.693z"/>
      <path fill="url(#am)" d="M23.051 5.159h2.693v2.705h-2.693z"/>
      <path fill="url(#an)" fillRule="evenodd" d="m32.528 27.192 8.89-8.222-11.998-1.399-5.036-11.04-5.036 11.04L7.342 18.97l8.89 8.222-2.382 11.901 10.534-5.954 10.527 5.954-2.383-11.901Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <path fill="#FFE682" fillRule="evenodd" d="M39.122 21.629c.013.04-.02.126-.04.159-.033.046-.105.106-.165.066-.052-.033-.066-.133-.026-.185.053-.08.158-.067.238-.04h-.007Z" clipRule="evenodd" style={{mixBlendMode:"screen"}}/>
      <defs>
        <radialGradient id="a" cx="0" cy="0" r="1" gradientTransform="matrix(21.2262 0 0 21.3231 24.388 24.505)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7300"/>
          <stop offset=".11" stopColor="#CB5B00"/>
          <stop offset=".24" stopColor="#954300"/>
          <stop offset=".36" stopColor="#682E00"/>
          <stop offset=".49" stopColor="#421D00"/>
          <stop offset=".62" stopColor="#251000"/>
          <stop offset=".74" stopColor="#100700"/>
          <stop offset=".87" stopColor="#040100"/>
          <stop offset=".98"/>
        </radialGradient>
        <radialGradient id="b" cx="0" cy="0" r="1" gradientTransform="matrix(44.7295 0 0 44.9337 22.18 1.983)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD80F"/>
          <stop offset="1" stopColor="#FF8C00"/>
        </radialGradient>
        <radialGradient id="c" cx="0" cy="0" r="1" gradientTransform="matrix(24.0775 0 0 24.1874 21.44 3.322)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="d" cx="0" cy="0" r="1" gradientTransform="matrix(17.5169 0 0 17.5969 3.976 12.79)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="e" cx="0" cy="0" r="1" gradientTransform="matrix(21.6618 0 0 21.7607 13.216 28.213)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC830"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="f" cx="0" cy="0" r="1" gradientTransform="matrix(23.3317 0 0 23.4382 9.428 42.388)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="g" cx="0" cy="0" r="1" gradientTransform="matrix(31.2057 0 0 31.3482 24.219 36.414)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E8AA00"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="h" cx="0" cy="0" r="1" gradientTransform="matrix(17.3321 0 0 17.4112 25.242 2.527)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC830"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="i" cx="0" cy="0" r="1" gradientTransform="matrix(27.8264 0 0 27.9534 44.052 14.514)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="j" cx="0" cy="0" r="1" gradientTransform="matrix(17.9195 0 0 18.0013 46.006 18.506)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC830"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="k" cx="0" cy="0" r="1" gradientTransform="matrix(27.569 0 0 27.6949 41.657 47.918)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="l" cx="0" cy="0" r="1" gradientTransform="matrix(18.5135 0 0 18.5981 38.673 39.365)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7B00"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="m" cx="0" cy="0" r="1" gradientTransform="matrix(20.817 0 0 20.912 21.915 19.626)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="n" cx="0" cy="0" r="1" gradientTransform="matrix(23.9587 0 0 24.0681 24.496 7.917)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="o" cx="0" cy="0" r="1" gradientTransform="matrix(20.5398 0 0 20.6336 7.943 18.87)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7B00"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="p" cx="0" cy="0" r="1" gradientTransform="matrix(22.6782 0 0 22.7818 12.371 42.66)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="q" cx="0" cy="0" r="1" gradientTransform="matrix(21.8334 0 0 21.9331 12.84 41.884)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7B00"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="r" cx="0" cy="0" r="1" gradientTransform="matrix(27.107 0 0 27.2307 41.59 14.587)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="s" cx="0" cy="0" r="1" gradientTransform="matrix(17.6753 0 0 17.756 24.879 2.388)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EDA200"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="t" cx="0" cy="0" r="1" gradientTransform="matrix(24.6517 0 0 24.7643 34.093 27.782)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7B00"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="u" cx="0" cy="0" r="1" gradientTransform="matrix(25.0807 0 0 25.1952 40.792 39.279)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="v" cx="0" cy="0" r="1" gradientTransform="matrix(15.0286 0 0 15.0972 24.852 33.835)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7B00"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="w" cx="0" cy="0" r="1" gradientTransform="matrix(16.9031 0 0 16.9803 13.493 13.679)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="x" cx="0" cy="0" r="1" gradientTransform="matrix(22.9423 0 0 23.047 25.407 35.944)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC830"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="y" cx="0" cy="0" r="1" gradientTransform="matrix(21.5232 0 0 21.6215 15.691 24.93)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7B00"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="z" cx="0" cy="0" r="1" gradientTransform="matrix(18.6983 0 0 18.7837 25.314 24.606)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="A" cx="0" cy="0" r="1" gradientTransform="matrix(21.0018 0 0 21.0977 39.023 35.254)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="B" cx="0" cy="0" r="1" gradientTransform="matrix(16.8173 0 0 16.8941 20.43 29.685)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC830"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="C" cx="0" cy="0" r="1" gradientTransform="matrix(19.0019 0 0 19.0887 37.63 31.521)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="D" cx="0" cy="0" r="1" gradientTransform="matrix(18.8039 0 0 18.8898 24.826 24.493)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7B00"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="E" cx="0" cy="0" r="1" gradientTransform="matrix(30.3872 0 0 30.526 37.175 7.546)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="F" cx="0" cy="0" r="1" gradientTransform="matrix(19.0547 0 0 19.1417 24.879 24.626)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7B00"/>
          <stop offset="1" stopColor="#fff"/>
        </radialGradient>
        <radialGradient id="G" cx="0" cy="0" r="1" gradientTransform="matrix(18.1505 0 0 18.2334 45.53 23.247)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFF8F"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="H" cx="0" cy="0" r="1" gradientTransform="matrix(17.1209 0 0 17.1991 23.935 36.812)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFF8F"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="I" cx="0" cy="0" r="1" gradientTransform="matrix(18.0977 0 0 18.1803 15.203 38.861)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD000"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="J" cx="0" cy="0" r="1" gradientTransform="matrix(18.1637 0 0 18.2466 24.331 25.614)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFF8F"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="K" cx="0" cy="0" r="1" gradientTransform="matrix(58.4843 0 0 58.7513 14.84 8.806)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD200"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="L" cx="0" cy="0" r="1" gradientTransform="matrix(42.4722 0 0 42.6661 23.895 6.226)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFCF"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="M" cx="0" cy="0" r="1" gradientTransform="matrix(15.55 0 0 15.621 19.13 13.546)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFCF"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="N" cx="0" cy="0" r="1" gradientTransform="matrix(15.0484 0 0 15.1171 16.1 31.972)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFCF"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="O" cx="0" cy="0" r="1" gradientTransform="matrix(15.6886 0 0 15.7603 12.325 36.646)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFCF"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="P" cx="0" cy="0" r="1" gradientTransform="matrix(17.7479 0 0 17.8289 36.126 39.464)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFCF"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="Q" cx="0" cy="0" r="1" gradientTransform="matrix(15.484 0 0 15.5547 13.83 40.446)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFCF"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="R" cx="0" cy="0" r="1" gradientTransform="matrix(8.34924 0 0 8.38736 7.355 18.579)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFCF"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="S" cx="0" cy="0" r="1" gradientTransform="matrix(17.4641 0 0 17.5438 20.074 14.7)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFCF"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="T" cx="0" cy="0" r="1" gradientTransform="matrix(22.4208 0 0 22.5232 39.109 15.436)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFCF"/>
          <stop offset="1" stopColor="#000800"/>
        </radialGradient>
        <radialGradient id="U" cx="0" cy="0" r="1" gradientTransform="matrix(.48841 0 0 .49064 26.404 30.845)" gradientUnits="userSpaceOnUse">
          <stop offset=".29" stopColor="#FA0"/>
          <stop offset=".32" stopColor="#F5A100"/>
          <stop offset=".66" stopColor="#944B00"/>
          <stop offset=".89" stopColor="#571500"/>
          <stop offset="1" stopColor="#400000"/>
        </radialGradient>
        <radialGradient id="V" cx="0" cy="0" r="1" gradientTransform="matrix(.54122 0 0 .54369 28.535 20.336)" gradientUnits="userSpaceOnUse">
          <stop offset=".29" stopColor="#FA0"/>
          <stop offset=".32" stopColor="#F5A100"/>
          <stop offset=".66" stopColor="#944B00"/>
          <stop offset=".89" stopColor="#571500"/>
          <stop offset="1" stopColor="#400000"/>
        </radialGradient>
        <radialGradient id="W" cx="0" cy="0" r="1" gradientTransform="matrix(.44221 0 0 .44423 13.606 25.746)" gradientUnits="userSpaceOnUse">
          <stop offset=".29" stopColor="#FA0"/>
          <stop offset=".32" stopColor="#F5A100"/>
          <stop offset=".66" stopColor="#944B00"/>
          <stop offset=".89" stopColor="#571500"/>
          <stop offset="1" stopColor="#400000"/>
        </radialGradient>
        <radialGradient id="X" cx="0" cy="0" r="1" gradientTransform="matrix(.81842 0 0 .82216 32 25.852)" gradientUnits="userSpaceOnUse">
          <stop offset=".29" stopColor="#FA0"/>
          <stop offset=".32" stopColor="#F5A100"/>
          <stop offset=".66" stopColor="#944B00"/>
          <stop offset=".89" stopColor="#571500"/>
          <stop offset="1" stopColor="#400000"/>
        </radialGradient>
        <radialGradient id="Y" cx="0" cy="0" r="1" gradientTransform="matrix(.83162 0 0 .83542 18.76 31.276)" gradientUnits="userSpaceOnUse">
          <stop offset=".29" stopColor="#FA0"/>
          <stop offset=".32" stopColor="#F5A100"/>
          <stop offset=".66" stopColor="#944B00"/>
          <stop offset=".89" stopColor="#571500"/>
          <stop offset="1" stopColor="#400000"/>
        </radialGradient>
        <radialGradient id="Z" cx="0" cy="0" r="1" gradientTransform="matrix(0 .62988 -.62702 0 16.035 34.565)" gradientUnits="userSpaceOnUse">
          <stop offset=".29" stopColor="#FA0"/>
          <stop offset=".32" stopColor="#F5A100"/>
          <stop offset=".66" stopColor="#944B00"/>
          <stop offset=".89" stopColor="#571500"/>
          <stop offset="1" stopColor="#400000"/>
        </radialGradient>
        <radialGradient id="aa" cx="0" cy="0" r="1" gradientTransform="matrix(0 .44423 -.44221 0 31.796 36.447)" gradientUnits="userSpaceOnUse">
          <stop offset=".29" stopColor="#FA0"/>
          <stop offset=".32" stopColor="#F5A100"/>
          <stop offset=".66" stopColor="#944B00"/>
          <stop offset=".89" stopColor="#571500"/>
          <stop offset="1" stopColor="#400000"/>
        </radialGradient>
        <radialGradient id="ab" cx="0" cy="0" r="1" gradientTransform="matrix(0 .62988 -.62702 0 22.549 32.8)" gradientUnits="userSpaceOnUse">
          <stop offset=".29" stopColor="#FA0"/>
          <stop offset=".32" stopColor="#F5A100"/>
          <stop offset=".66" stopColor="#944B00"/>
          <stop offset=".89" stopColor="#571500"/>
          <stop offset="1" stopColor="#400000"/>
        </radialGradient>
        <radialGradient id="ac" cx="0" cy="0" r="1" gradientTransform="matrix(3.16809 0 0 3.18255 19.348 17.571)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9100"/>
          <stop offset=".09" stopColor="#D47800"/>
          <stop offset=".23" stopColor="#9C5900"/>
          <stop offset=".37" stopColor="#6C3D00"/>
          <stop offset=".5" stopColor="#452700"/>
          <stop offset=".63" stopColor="#271600"/>
          <stop offset=".76" stopColor="#110900"/>
          <stop offset=".89" stopColor="#040200"/>
          <stop offset="1"/>
        </radialGradient>
        <radialGradient id="ad" cx="0" cy="0" r="1" gradientTransform="matrix(2.11206 0 0 2.1217 19.348 17.57)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff"/>
          <stop offset=".09" stopColor="#D4D4D4"/>
          <stop offset=".23" stopColor="#9C9C9C"/>
          <stop offset=".37" stopColor="#6C6C6C"/>
          <stop offset=".5" stopColor="#454545"/>
          <stop offset=".63" stopColor="#272727"/>
          <stop offset=".76" stopColor="#111"/>
          <stop offset=".89" stopColor="#040404"/>
          <stop offset="1"/>
        </radialGradient>
        <radialGradient id="ah" cx="0" cy="0" r="1" gradientTransform="matrix(2.47507 0 0 2.48637 24.338 6.578)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF9100"/>
          <stop offset=".09" stopColor="#D47800"/>
          <stop offset=".23" stopColor="#9C5900"/>
          <stop offset=".37" stopColor="#6C3D00"/>
          <stop offset=".5" stopColor="#452700"/>
          <stop offset=".63" stopColor="#271600"/>
          <stop offset=".76" stopColor="#110900"/>
          <stop offset=".89" stopColor="#040200"/>
          <stop offset="1"/>
        </radialGradient>
        <radialGradient id="ai" cx="0" cy="0" r="1" gradientTransform="matrix(1.65005 0 0 1.65758 24.338 6.578)" gradientUnits="userSpaceOnUse">
          <stop stopColor="#fff"/>
          <stop offset=".09" stopColor="#D4D4D4"/>
          <stop offset=".23" stopColor="#9C9C9C"/>
          <stop offset=".37" stopColor="#6C6C6C"/>
          <stop offset=".5" stopColor="#454545"/>
          <stop offset=".63" stopColor="#272727"/>
          <stop offset=".76" stopColor="#111"/>
          <stop offset=".89" stopColor="#040404"/>
          <stop offset="1"/>
        </radialGradient>
        <radialGradient id="an" cx="0" cy="0" r="1" gradientTransform="matrix(26.3215 0 0 26.4417 20.872 16.994)" gradientUnits="userSpaceOnUse">
          <stop offset=".34" stopColor="#000800"/>
          <stop offset=".4" stopColor="#060D03"/>
          <stop offset=".48" stopColor="#171D0E"/>
          <stop offset=".58" stopColor="#33381F"/>
          <stop offset=".68" stopColor="#5B5D37"/>
          <stop offset=".79" stopColor="#8E8D56"/>
          <stop offset=".91" stopColor="#CBC77C"/>
          <stop offset="1" stopColor="#FFF89C"/>
        </radialGradient>
        <pattern id="ae" width="1" height="1" patternContentUnits="objectBoundingBox">
          <use href="#ao" transform="scale(.33333 .02083)"/>
        </pattern>
        <pattern id="af" width="1" height="1" patternContentUnits="objectBoundingBox">
          <use href="#ap" transform="scale(.05 .05263)"/>
        </pattern>
        <pattern id="ag" width="1" height="1" patternContentUnits="objectBoundingBox">
          <use href="#aq" transform="scale(.05 .05263)"/>
        </pattern>
        <pattern id="aj" width="1" height="1" patternContentUnits="objectBoundingBox">
          <use href="#ar" transform="scale(1 .02703)"/>
        </pattern>
        <pattern id="ak" width="1" height="1" patternContentUnits="objectBoundingBox">
          <use href="#as" transform="scale(.02857 .5)"/>
        </pattern>
        <pattern id="al" width="1" height="1" patternContentUnits="objectBoundingBox">
          <use href="#at" transform="scale(.05882 .07692)"/>
        </pattern>
        <pattern id="am" width="1" height="1" patternContentUnits="objectBoundingBox">
          <use href="#au" transform="scale(.05882 .07692)"/>
        </pattern>
        <image id="ao" width="3" height="48" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAwCAYAAADUxsC8AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAIklEQVQokWP4////fwYoYGJAAgPHgTlpkDhnaHMYBlcEAwCzrQxRNlVY8AAAAABJRU5ErkJggg=="/>
        <image id="ap" width="20" height="19" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAC4jAAAuIwF4pT92AAAASElEQVQ4jd3TKxYAIAhEUcb971mLxuFrgZfhHgoihfatYgzAVhSzZpDBANA9F+jFXGAEM8EopoIZjIJZjF7W8wOGYA/8hrXoAFbJZ7kxsW6SAAAAAElFTkSuQmCC"/>
        <image id="aq" width="20" height="19" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAC4jAAAuIwF4pT92AAAAXUlEQVQ4jd3TOw4AIAgDUOr976wLg4kfKOAim0l9GhQRra4lycK8mEEAWOMkWIFuN2TQYziKXoMR1AyxqOtUBnX3xotSL+hB6X9moY0FoxNkVtXsf4CWNbj8ls/QAbanV+LPpUJUAAAAAElFTkSuQmCC"/>
        <image id="ar" width="1" height="37" data-name="image.png" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAlCAYAAACDKIOpAAAACXBIWXMAAC4jAAAuIwF4pT92AAAADElEQVQImWNgGKIAAAC5AAFKu3wnAAAAAElFTkSuQmCC"/>
        <image id="as" width="35" height="2" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAACCAYAAADSKWXKAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAGklEQVQYlWP8////f4ZBApgG2gEwwMjIyAgAXTYEBIOU30oAAAAASUVORK5CYII="/>
        <image id="at" width="17" height="13" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAANCAYAAABPeYUaAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAT0lEQVQokaXPSwpAIQxD0Rv3v+c6Up741H4yKqQciHDGzGzckvTttL/HABfyAp6IB7giXuCIRIBfJApsSAZYkCwwkQoAoCoA0KoAsE7JpgMslTAOocRnpQAAAABJRU5ErkJggg=="/>
        <image id="au" width="17" height="13" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAANCAYAAABPeYUaAAAACXBIWXMAAC4jAAAuIwF4pT92AAAATklEQVQoka3SSQ4AIAgDwPb/j8aLMRqUVa7WCQEIACIimEWSSNb60IGOcBVSwQp0DWWhZyADmY9RyG01AoUG50HhNVpQ6qi6l62wHfwCDT3MO/wnyHEtAAAAAElFTkSuQmCC"/>
      </defs>
    </svg>

  )
}

export default memo(FilledStar)