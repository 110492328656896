import { wardrobeItemsCategoryList, WardrobeItemType } from './avatar/wardrobePicker';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';
import { SetStateAction, useEffect, useState } from 'react';
import Coin, { Coin50 } from '../../assets/svg/coin';
import { AvatarType } from '../../redux/slices/user';
import { ButtonMotion } from '../buttons';
import { createExpense, getUserProfile, updateProfile, updateUserProfile } from '../../api/v0';
import AzureAuth from '../../azure-component/AzureAuth';

interface ShopSectionProps {
  wardrobeItems: WardrobeItemType[],
  coins?: number,
  gender?: string,
  availableItems?: string[],
  currentProfile?: Record<string, any>;
}

const wardrobeItemsCategoriesList = ['top', 'accessory', 'bottom', 'shoe'];

export function ShopSection({ wardrobeItems, gender, availableItems, coins }: ShopSectionProps) {
  const [profile, setProfile] = useState<any | null>(null);
  const [shopItems, setShopItems] = useState<WardrobeItemType[]>([]);
  const [selectedGender, setSelectedGender] = useState<string>('');
  const [currentCoins, setCurrentCoins] = useState<number>(0);

  const account = AzureAuth.getAccount();

  useEffect(() => {
    setShopItems(wardrobeItems);
  }, [wardrobeItems]);

  useEffect(() => {
    if (gender && gender !== selectedGender) {
      setSelectedGender(gender);
    }
  }, [gender]);

  useEffect(() => {
    if (account.localAccountId) {
      getUserProfile(account.localAccountId, (data) => setProfile(data ?? null));
    }
  }, [account?.localAccountId]);

  const getCategoryItems = (category: string) => {
    return shopItems.filter((item) => item?.gender === selectedGender && item.category === category);
  }

  return (
    <div className="shop-block flex-grow-1 overflow-hidden">
      <h3>Shop</h3>
      <div className='shop-items d-grid w-100'>
        {wardrobeItemsCategoryList.map((category, index) => {
          return (
            <div className="shop-item-container" key={category}>
              <h4 className="text-center">{category}</h4>
              <ShopItem wardrobeItems={getCategoryItems(wardrobeItemsCategoriesList[index])} availableItems={availableItems ?? []} currentProfile={profile} />
            </div>
          );
        })}
      </div>
    </div>
  )
}

export function ShopItem({ wardrobeItems, gender, availableItems, currentProfile }: ShopSectionProps) {
  const [shopItems, setShopItems] = useState<WardrobeItemType[]>([]);
  const [profile , setProfile] = useState<any | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [canShopItems, setCanShopItems] = useState<string[]>([]);
  const account = AzureAuth.getAccount();

  useEffect(() => {
    setShopItems(wardrobeItems);
  }, [wardrobeItems]);

  useEffect(() => {
    setProfile(currentProfile);
    setCanShopItems(currentProfile?.items ?? []);
  }, [currentProfile]);

  // useEffect(() => {
  //   if (availableItems && availableItems !== canShopItems) {
  //     setCanShopItems(availableItems);
  //   }
  // }, [availableItems]);

  // useEffect(() => {
  //   if (account.localAccountId) {
  //     getUserProfile(account.localAccountId, (data) => {
  //       setProfile(data ?? null);
  //       // setCanShopItems([...(data?.items ?? [])])
  //     });
  //   }
  // }, [account?.localAccountId]);

  const handleBuyStaff = () => {
    if (shopItems?.[activeIndex]?.uid && !canShopItems?.includes(shopItems?.[activeIndex]?.uid)) {
      const currentShoppedItems = canShopItems ? [...canShopItems, shopItems?.[activeIndex]?.uid] : [shopItems?.[activeIndex]?.uid];
      updateUserProfile(account.localAccountId, { items: currentShoppedItems }, () => window.location.reload())
    }
  }

  // console.log(canShopItems);
  // console.log(profile);

  const getButton = () => {
    if (currentProfile?.items?.includes(shopItems?.[activeIndex]?.uid)) {
      return <ButtonMotion variant='success' onClick={() => {}} className='buy-btn'>Select</ButtonMotion>;
    }
    return (<ButtonMotion variant='success' onClick={handleBuyStaff} className='buy-btn'>
      Buy
    </ButtonMotion>)
  }

  const getCoin = () => {
    if (currentProfile?.items?.includes(shopItems?.[activeIndex]?.uid)) {
      return <div />;
    }

    return <Coin50 />;
  }

  return (
    <div className='shop-item'>
      <div className='img-wrapper'>
        <Swiper navigation={true} modules={[Navigation]} className='mySwiper'
                onSlideChange={(e: { realIndex: SetStateAction<number>; }) => setActiveIndex(e.realIndex)}>
          {
            shopItems.map((item) => (
              <SwiperSlide key={`shop-${item.uid}`}>
                <img src={item.shop_svg} className='img-fluid' alt='item' />
                {getCoin()}
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
      {getButton()}
    </div>
  )
}
