import { EnumGameMechanicCategory } from "src/@constants";
export const HighlightWordGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.highlight_word_game,
  points: 100,
  content: {
    sentence: '',
    highlight: [],
  }
}
