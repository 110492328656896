import type { FC } from 'react';
import { motion } from 'framer-motion';
import React, { memo, useState } from 'react';

const transition = {
  type: 'spring',
  damping: 25,
  stiffness: 120
};

interface ImgWithZoom {
  filePath: string,
  type?: 'img' | 'video'
}

const ImgWithZoom: FC<ImgWithZoom> = ({ filePath, type = 'img' }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <motion.div className={`image-container${isZoomed ? ' open' : ''}`}>
      <motion.div
        animate={{ opacity: isZoomed ? 1 : 0 }}
        transition={transition}
        className='shade'
        onClick={() => setIsZoomed(false)}
      />
      <motion.div className='img-frame' onClick={() => setIsZoomed(!isZoomed)}>
        {type === 'img' ?
          <motion.img
            className='img-fluid'
            src={filePath}
            alt={'game_image'}
            transition={transition}
            key={Math.random()}
          /> :
          <motion.video className='img-fluid' controls transition={transition}>
            <source src={filePath} type='video/mp4' key={Math.random()} />
          </motion.video>}
      </motion.div>
    </motion.div>
  );
};

export default memo(ImgWithZoom);