import { RouteObject } from 'react-router-dom';
import GamesOverview from '../games/games-overview';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The route of the main functionality
 */
export const gamesOverviewRoutes: RouteObject = {
  path: 'games-overview',
  element: <GamesOverview />
};
