import { TGameMechanicCategory } from 'src/@types';
import { TGameMechanicContent } from './types';
import {
  quizDbToViewConverter
} from './island-games/game-mechanics/quiz/utils';
import {
  crosswordDbToViewConverter
} from './island-games/game-mechanics/crossword/utils';
import {
  calculationDbToViewConverter
} from './island-games/game-mechanics/calculation/utils';
import {
  fillInGameDbToViewConverter
} from './island-games/game-mechanics/fill-in-game/utils';
import {
  essayDbToViewConverter
} from './island-games/game-mechanics/essay/utils';
import {
  hotspotDbToViewConverter
} from "./island-games/game-mechanics/hotspot/utils";
import {
  simulationDbToViewConverter
} from "./island-games/game-mechanics/simulation-game/utils";
import {
  simulationDialogueDbToViewConverter
} from "./island-games/game-mechanics/simulation-dialogue/utils";
import {
  dragDropImageDbToViewConverter
} from "./island-games/game-mechanics/drag-drop-image/utils";
import {
  dragDropTextDbToViewConverter
} from "./island-games/game-mechanics/drag-drop-text/utils";
import {
  jumbleWordsGameDbToViewConverter
} from './island-games/game-mechanics/jamble-words/utils';
import {
  memoryDbToViewConverter
} from './island-games/game-mechanics/memory/utils';
import {
  highlightWordDbToViewConverter
} from './island-games/game-mechanics/highlight-word/utils';
import {
  informativeDbToViewConverter
} from './island-games/game-mechanics/informative-game/utils';
import {
  scrambleSentenceGameDbToViewConverter
} from "./island-games/game-mechanics/scramble-game/utils";
import {
  dragDropTextToImageDbToViewConverter
} from "./island-games/game-mechanics/drag-drop-text-to-image/utils";
import {
  markSpotDbToViewConverter
} from "./island-games/game-mechanics/mark-spot/utils";
import {
  wordSearchDbToViewConverter
} from "./island-games/game-mechanics/word-search/utils";

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The properties' interface that required to pass to the converter method to
 *    convert into the TGameMechanicContent type
 */
interface ConvertDbDataToGameMechanicContentProps {
  data: Record<string, any>;
  category: TGameMechanicCategory;
}

/**
 * @description
 *    The method is converting the DB data into the TGameMechanicContent object.
 *    It simply checks the provided content type to match with the game mechanic
 *    object
 */
export function convertDbDataToGameMechanicContent(
  { data, category }: ConvertDbDataToGameMechanicContentProps
): TGameMechanicContent {
  switch (category) {
    // =========== QUIZ GAMES ===========
    case "quiz_true_false":
      return quizDbToViewConverter(data);
    case "quiz_multi_choice":
      return quizDbToViewConverter(data);
    case "quiz_single_choice":
      return quizDbToViewConverter(data);
    case "crossword":
      return crosswordDbToViewConverter(data);
    case "calculation":
      return calculationDbToViewConverter(data);
    case "fill_in_game":
      return fillInGameDbToViewConverter(data);
    case "essay":
      return essayDbToViewConverter(data);
    case "hotspot":
      return hotspotDbToViewConverter(data);
    case "simulation_game":
      return simulationDbToViewConverter(data);
    case "simulation_dialogue":
      return simulationDialogueDbToViewConverter(data);
    case "drag_drop_image":
      return dragDropImageDbToViewConverter(data);
    case "drag_drop_text":
      return dragDropTextDbToViewConverter(data);
    case "drag_drop_text_to_image":
      return dragDropTextToImageDbToViewConverter(data);
    case "mark_spot":
      return markSpotDbToViewConverter(data);
    case "memory":
      return memoryDbToViewConverter(data);
    case "jumble_words":
      return jumbleWordsGameDbToViewConverter(data);
    case "highlight_word_game":
      return highlightWordDbToViewConverter(data);
    case "informative_game":
      return informativeDbToViewConverter(data);
    case "scramble_sentence":
      return scrambleSentenceGameDbToViewConverter(data);
    case "word_search":
      return wordSearchDbToViewConverter(data);
    // =========== QUIZ GAMES ===========
  }
  throw new Error("Can't find the game mechanic");
}

/**
 * @description
 *    The method is creating dummy data for the game mechanic content
 * @param props
 */
export function createGameMechanicDummyContent(
  props: ConvertDbDataToGameMechanicContentProps
) {
  return convertDbDataToGameMechanicContent(props);
}
