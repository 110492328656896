import React, { memo, useMemo, useRef } from 'react';
import { IGemProps } from 'src/@interfaces/skill-island-interfaces';
import { diamondColors } from 'src/helpers';
import { useGame } from 'src/hooks';

function Gems({ index, onGameSelect, color, level, title }: IGemProps) {
  const { showTheGameInfoTooltip } = useGame();
  const gemRef = useRef<SVGGElement | null>(null);

  const renderedCoreGem = useMemo(() => {
    switch (index) {
      case 0 :
        return <g id='GAME GEM 4_2'>
          <path id='Vector_363' fill={`#${diamondColors[color][0]}`}
                d='M635.165 18.7721L658.494 9.13449H693.997L717.708 19.6154L696.746 30.0962H657.374L635.165 18.7721Z' />
          <path id='Vector_364' fill={`#${diamondColors[color][1]}`}
                d='M635.165 18.7721L620 35.8788L650.769 56.5004L657.374 30.0962L635.165 18.7721Z' />
          <path id='Vector_365' fill={`#${diamondColors[color][1]}`}
                d='M696.746 30.0962L706.887 56.5004L734.468 35.2835L717.708 19.6154L696.746 30.0962Z' />
          <path id='Vector_366' fill={`#${diamondColors[color][2]}`}
                d='M657.374 30.0962L696.895 30.4222L706.887 56.5004H650.769L657.374 30.0962Z' />
          <path id='Vector_367' fill={`#${diamondColors[color][2]}`}
                d='M706.887 56.5004L706.264 57.7192L678.825 102.477L734.468 35.2835L706.887 56.5004Z' />
          <path id='Vector_368' fill={`#${diamondColors[color][2]}`}
                d='M650.769 56.5004L678.825 102.477L620 35.8788L650.769 56.5004Z' />
          <path id='Vector_369' fill={`#${diamondColors[color][1]}`}
                d='M706.887 56.5004L678.825 102.477L650.769 56.5004H706.887Z' />
          <path id='Vector_370' fill={`#${diamondColors[color][3]}`}
                d='M636.901 19.6366C638.156 21.4437 639.24 23.3854 640.289 25.1641C643.159 30.0395 644.937 35.4465 647.162 40.6055C648.629 44.014 649.444 47.3872 649.869 51.0296C650.075 52.7658 650.238 54.6083 650.614 56.3799L650.777 56.4862L657.381 30.0821L636.908 19.6366H636.901Z' />
          <path id='Vector_371' fill={`#${diamondColors[color][4]}`}
                d='M712.301 17.4327C711.982 17.5815 711.642 17.7091 711.288 17.8295C707.334 19.1689 702.926 19.5232 698.801 20.0547C693.855 20.6925 689.008 21.2098 684.012 21.2098C678.109 21.2098 672.256 21.5499 666.353 21.2098C660.492 20.8767 654.511 21.1531 648.736 19.9697C645.341 19.2752 641.259 18.6374 637.915 17.6382L635.115 18.7862L657.381 30.3088H696.753L717.715 19.8279L712.315 17.4398L712.301 17.4327Z' />
          <path id='Vector_372' fill='white'
                d='M696.746 27.5664H657.374L637.908 17.6382L635.165 18.7721L657.374 30.3017L696.725 30.4222L717.708 19.6154L715.015 18.4248L696.746 27.5664Z' />
          <path id='Vector_373' fill='white'
                d='M635.165 18.7721L634.201 19.8563L655.935 30.9466L649.721 55.7988L650.769 56.5004L657.374 30.0962L635.165 18.7721Z' />
          <path id='Vector_374' fill={`#${diamondColors[color][2]}`}
                d='M707.397 56.3303C707.397 47.9683 710.586 40.8464 714.626 33.6678C716.355 30.5994 718.388 27.7506 719.572 24.3775C720.692 21.1815 718.537 20.0831 715.809 21.4649C711.125 23.8318 706.554 26.2412 701.82 28.5656C698.695 30.0962 697.136 30.8049 698.433 34.5324C699.326 37.0977 700.531 39.6346 701.65 42.129C703.62 46.5368 705.923 51.4619 707.397 55.65V56.3232V56.3303Z'
                opacity='.2' />
          <path id='Vector_375' fill='white'
                d='M698.433 30.6277L718.651 20.5153L717.708 19.6366L696.746 30.1175L706.887 56.5216L708.042 55.6287L698.433 30.6277Z'
                opacity='.2' />
          <g id='Group_7' fill='white'>
            <path id='Vector_376'
                  d='M715.448 41.7757C723.139 34.085 723.139 21.6159 715.448 13.9252C707.757 6.23455 695.288 6.23453 687.597 13.9252C679.907 21.6159 679.907 34.085 687.597 41.7757C695.288 49.4664 707.757 49.4664 715.448 41.7757Z'
                  opacity='.1' />
            <path id='Vector_377'
                  d='M708.695 40.5175C716.117 37.4612 719.657 28.9665 716.601 21.544C713.544 14.1215 705.05 10.582 697.627 13.6383C690.205 16.6946 686.665 25.1893 689.722 32.6118C692.778 40.0343 701.273 43.5738 708.695 40.5175Z'
                  opacity='.2' />
            <path id='Vector_378'
                  d='M703.167 17.1138C702.543 21.5287 703.167 27.0845 695.287 27.7577C697.625 28.849 700.233 29.7986 701.856 31.9812C703.301 33.9229 704.478 37.2039 704.726 39.5779C705.172 37.0055 704.577 34.6103 706.164 31.9812C707.348 30.0254 709.36 28.6506 711.607 28.3955C709.084 26.9994 706.894 27.2191 705.364 24.2074C704.782 23.0594 704.237 21.8547 703.882 20.6571C703.507 19.4169 703.641 17.5036 702.834 16.6036L703.174 17.1138H703.167Z'
                  opacity='.7' />
          </g>
          <g id='Group_8' fill='white'>
            <path id='Vector_379'
                  d='M665.032 62.0491C666.751 53.4207 661.151 45.0321 652.522 43.3127C643.894 41.5932 635.505 47.1941 633.786 55.8225C632.066 64.451 637.667 72.8396 646.295 74.559C654.924 76.2784 663.312 70.6776 665.032 62.0491Z'
                  opacity='.1' />
            <path id='Vector_380'
                  d='M662.505 58.3287C662.505 64.8269 657.239 70.0922 650.741 70.0922C644.243 70.0922 638.978 64.8269 638.978 58.3287C638.978 51.8304 644.243 46.5651 650.741 46.5651C657.239 46.5651 662.505 51.8304 662.505 58.3287Z'
                  opacity='.2' />
            <path id='Vector_381'
                  d='M650.748 50.2572C650.245 53.8288 650.748 58.3287 644.37 58.8672C646.262 59.7459 648.367 60.5184 649.685 62.2829C650.854 63.8561 651.811 66.5064 652.01 68.4269C652.371 66.3434 651.889 64.4088 653.172 62.2829C654.128 60.6955 655.758 59.59 657.572 59.3775C655.532 58.2507 653.76 58.4279 652.52 55.9901C652.045 55.0618 651.613 54.0839 651.322 53.1201C651.017 52.1209 651.124 50.569 650.472 49.8391L650.748 50.2501V50.2572Z'
                  opacity='.7' />
          </g>
          <path id='Vector_382' fill='white'
                d='M652.222 56.3303C653.838 56.9326 655.454 57.7759 656.481 58.357C662.207 61.5884 667.593 66.8041 671.724 71.8709C675.756 76.8101 678.407 82.2809 679.505 88.5807C680.107 92.0389 680.455 95.5609 680.809 99.0545L706.887 56.3232H652.222V56.3303Z'
                opacity='.1' />
          <path id='Vector_383' fill='white'
                d='M686.691 56.3303C686.691 56.3303 667.153 81.9266 667.501 81.4022C667.848 80.8778 699.829 56.4933 699.829 56.4933L686.691 56.3232V56.3303Z'
                opacity='.2' />
          <path id='Vector_384' fill='white'
                d='M641.387 22.2799L674.41 9.13449H679.555L651.074 27.0916L641.387 22.2799Z' opacity='.2' />
          <path id='Vector_385' fill='white'
                d='M664.56 30.1175L694.344 9.02109L698.32 10.7785L684.005 30.1883L664.56 30.1175Z' opacity='.2' />
        </g>;
      case 1 :
        return <g id='GEMS 2'>
          <g id='GAME GEM 4'>
            <path id='Vector_94' fill={`#${diamondColors[color][0]}`}
                  d='M933.165 155.772L956.494 146.134H991.997L1015.71 156.615L994.746 167.096H955.374L933.165 155.772Z' />
            <path id='Vector_95' fill={`#${diamondColors[color][1]}`}
                  d='M933.165 155.772L918 172.879L948.769 193.5L955.374 167.096L933.165 155.772Z' />
            <path id='Vector_96' fill={`#${diamondColors[color][1]}`}
                  d='M994.746 167.096L1004.89 193.5L1032.47 172.284L1015.71 156.615L994.746 167.096Z' />
            <path id='Vector_97' fill={`#${diamondColors[color][2]}`}
                  d='M955.374 167.096L994.895 167.422L1004.89 193.5H948.769L955.374 167.096Z' />
            <path id='Vector_98' fill={`#${diamondColors[color][2]}`}
                  d='M1004.89 193.5L1004.26 194.719L976.825 239.477L1032.47 172.284L1004.89 193.5Z' />
            <path id='Vector_99' fill={`#${diamondColors[color][2]}`} d='M948.769 193.5L976.825 239.477L918 172.879L948.769 193.5Z' />
            <path id='Vector_100' fill={`#${diamondColors[color][1]}`} d='M1004.89 193.5L976.825 239.477L948.769 193.5H1004.89Z' />
            <path id='Vector_101' fill={`#${diamondColors[color][3]}`}
                  d='M934.901 156.637C936.156 158.444 937.24 160.385 938.289 162.164C941.159 167.04 942.937 172.447 945.162 177.605C946.629 181.014 947.444 184.387 947.869 188.03C948.075 189.766 948.238 191.608 948.614 193.38L948.776 193.486L955.381 167.082L934.908 156.637H934.901Z' />
            <path id='Vector_102' fill={`#${diamondColors[color][4]}`}
                  d='M1010.3 154.433C1009.98 154.582 1009.64 154.709 1009.29 154.83C1005.33 156.169 1000.93 156.523 996.801 157.055C991.855 157.692 987.008 158.21 982.012 158.21C976.109 158.21 970.256 158.55 964.353 158.21C958.492 157.877 952.511 158.153 946.736 156.97C943.341 156.275 939.259 155.637 935.915 154.638L933.115 155.786L955.381 167.309H994.753L1015.72 156.828L1010.32 154.44L1010.3 154.433Z' />
            <path id='Vector_103' fill='white'
                  d='M994.746 164.566H955.374L935.908 154.638L933.165 155.772L955.374 167.302L994.725 167.422L1015.71 156.615L1013.02 155.425L994.746 164.566Z' />
            <path id='Vector_104' fill='white'
                  d='M933.165 155.772L932.201 156.856L953.935 167.947L947.721 192.799L948.769 193.5L955.374 167.096L933.165 155.772Z' />
            <path id='Vector_105' fill={`#${diamondColors[color][2]}`}
                  d='M1005.4 193.33C1005.4 184.968 1008.59 177.846 1012.63 170.668C1014.35 167.599 1016.39 164.751 1017.57 161.377C1018.69 158.181 1016.54 157.083 1013.81 158.465C1009.12 160.832 1004.55 163.241 999.82 165.566C996.695 167.096 995.136 167.805 996.433 171.532C997.326 174.098 998.531 176.635 999.65 179.129C1001.62 183.537 1003.92 188.462 1005.4 192.65V193.323V193.33Z'
                  opacity='.2' />
            <path id='Vector_106' fill='white'
                  d='M996.433 167.628L1016.65 157.515L1015.71 156.637L994.746 167.117L1004.89 193.522L1006.04 192.629L996.433 167.628Z'
                  opacity='.2' />
            <g id='Group_5' fill='white'>
              <path id='Vector_107'
                    d='M1013.45 178.776C1021.14 171.085 1021.14 158.616 1013.45 150.925C1005.76 143.235 993.288 143.235 985.597 150.925C977.907 158.616 977.907 171.085 985.597 178.776C993.288 186.466 1005.76 186.466 1013.45 178.776Z'
                    opacity='.1' />
              <path id='Vector_108'
                    d='M1006.7 177.518C1014.12 174.461 1017.66 165.967 1014.6 158.544C1011.54 151.122 1003.05 147.582 995.627 150.638C988.205 153.695 984.665 162.189 987.722 169.612C990.778 177.034 999.273 180.574 1006.7 177.518Z'
                    opacity='.2' />
              <path id='Vector_109'
                    d='M1001.17 154.114C1000.54 158.529 1001.17 164.085 993.287 164.758C995.625 165.849 998.233 166.799 999.856 168.981C1001.3 170.923 1002.48 174.204 1002.73 176.578C1003.17 174.006 1002.58 171.61 1004.16 168.981C1005.35 167.025 1007.36 165.651 1009.61 165.395C1007.08 163.999 1004.89 164.219 1003.36 161.207C1002.78 160.059 1002.24 158.855 1001.88 157.657C1001.51 156.417 1001.64 154.504 1000.83 153.604L1001.17 154.114H1001.17Z'
                    opacity='.7' />
            </g>
            <g id='Group_6' fill='white'>
              <path id='Vector_110'
                    d='M963.032 199.049C964.751 190.421 959.151 182.032 950.522 180.313C941.894 178.593 933.505 184.194 931.786 192.823C930.066 201.451 935.667 209.84 944.295 211.559C952.924 213.278 961.312 207.678 963.032 199.049Z'
                    opacity='.1' />
              <path id='Vector_111'
                    d='M960.505 195.329C960.505 201.827 955.239 207.092 948.741 207.092C942.243 207.092 936.978 201.827 936.978 195.329C936.978 188.83 942.243 183.565 948.741 183.565C955.239 183.565 960.505 188.83 960.505 195.329Z'
                    opacity='.2' />
              <path id='Vector_112'
                    d='M948.748 187.257C948.245 190.829 948.748 195.329 942.37 195.867C944.262 196.746 946.367 197.518 947.685 199.283C948.854 200.856 949.811 203.506 950.01 205.427C950.371 203.343 949.889 201.409 951.172 199.283C952.128 197.696 953.758 196.59 955.572 196.377C953.532 195.251 951.76 195.428 950.52 192.99C950.045 192.062 949.613 191.084 949.322 190.12C949.017 189.121 949.124 187.569 948.472 186.839L948.748 187.25V187.257Z'
                    opacity='.7' />
            </g>
            <path id='Vector_113' fill='white'
                  d='M950.222 193.33C951.838 193.933 953.454 194.776 954.481 195.357C960.207 198.588 965.593 203.804 969.724 208.871C973.756 213.81 976.407 219.281 977.505 225.581C978.107 229.039 978.455 232.561 978.809 236.055L1004.89 193.323H950.222V193.33Z'
                  opacity='.1' />
            <path id='Vector_114' fill='white'
                  d='M984.691 193.33C984.691 193.33 965.153 218.927 965.501 218.402C965.848 217.878 997.829 193.493 997.829 193.493L984.691 193.323V193.33Z'
                  opacity='.2' />
            <path id='Vector_115' fill='white'
                  d='M939.387 159.28L972.41 146.134H977.555L949.074 164.092L939.387 159.28Z' opacity='.2' />
            <path id='Vector_116' fill='white'
                  d='M962.56 167.117L992.344 146.021L996.32 147.779L982.005 167.188L962.56 167.117Z' opacity='.2' />
          </g>
        </g>;
    }
  }, [index]);

  const renderedMiniGem = useMemo(() => {
    switch (index) {
      case 2 :
        return <g id='Mini gem_17'>
          <path id='Vector_829' fill={`#${diamondColors[color][0]}`}
                d='M215.757 402.651L243.53 428.559L222.873 447.904L215.756 403.576L189.268 433.638L218.326 476.033L222.873 447.904L215.757 402.651Z' />
          <path id='Vector_830' fill={`#${diamondColors[color][1]}`} d='M215.71 402.651L189.268 433.489L222.859 448.127L215.71 402.651Z' />
          <path id='Vector_831' fill='white'
                d='M215.711 402.651L214.973 403.511L221.718 446.422L190.023 432.61L189.268 433.489L222.859 448.127L215.711 402.651Z' />
          <path id='Vector_832' fill={`#${diamondColors[color][2]}`} d='M222.776 447.863L218.208 476.033L243.53 428.49L222.776 447.863Z' />
          <path id='Vector_833' fill={`#${diamondColors[color][2]}`} d='M222.776 447.863L218.208 476.033L243.53 428.49L222.776 447.863Z' />
          <path id='Vector_834' fill='white' d='M189.268 433.658L219.241 424.594L215.799 402.651L189.268 433.658Z'
                opacity='.2' />
          <path id='Vector_835' fill='white' d='M219.077 424.522L243.53 428.49L215.624 402.651L219.077 424.121V424.522Z'
                opacity='.2' />
          <path id='Vector_836' fill={`#${diamondColors[color][3]}`}
                d='M220.878 459.595C220.927 459.289 235.684 436.066 235.684 436.066L243.53 429.007L225.709 461.984L218.208 476.033L220.878 459.595Z'
                opacity='.5' />
          <path id='Vector_837' fill={`#${diamondColors[color][3]}`}
                d='M223.669 447.863L242.646 430.149L243.53 428.49L222.776 447.863L218.208 476.033L219.492 473.621L223.669 447.863Z' />
          <path id='Vector_838' fill={`#${diamondColors[color][3]}`}
                d='M199.042 447.929L222.342 448.127L189.268 433.658L199.042 447.929Z'
                opacity='.5' />
          <path id='Vector_839' fill='white'
                d='M190.819 433.12C191.838 434.165 194.345 434.312 195.764 434.585C198.721 435.224 201.77 435.332 204.765 434.905C208.395 434.32 211.246 432.724 214.063 430.479C215.904 429.013 216.631 427.572 217.402 425.487C217.63 424.955 217.81 424.403 217.939 423.839C218.866 424.331 218.743 427.475 219.009 428.522C219.532 430.581 220.102 432.581 220.317 434.686C220.726 438.171 221.314 441.632 222.081 445.056C222.226 445.757 222.682 446.663 221.883 447.016C221.251 447.296 220.126 446.745 219.581 446.545C217.962 445.953 216.71 444.933 215.151 444.198C213.637 443.483 212.036 443.101 210.508 442.386C208.891 441.628 207.31 440.943 205.755 440.065C203.374 438.72 200.731 437.67 198.24 436.619C196.988 436.09 195.673 435.592 194.483 435.048C193.541 434.617 191.442 434.12 190.917 433.216L190.819 433.12Z'
                opacity='.4' />
          <path id='Vector_840' fill='white'
                d='M227.374 456.264C232.631 452.822 234.103 445.769 230.661 440.512C227.218 435.254 220.166 433.783 214.908 437.225C209.651 440.667 208.179 447.72 211.621 452.977C215.063 458.235 222.116 459.706 227.374 456.264Z'
                opacity='.1' />
          <path id='Vector_841' fill='white'
                d='M230.545 447.37C230.337 449.044 229.638 450.618 228.535 451.894C227.432 453.17 225.976 454.09 224.35 454.539C222.724 454.987 221.002 454.943 219.401 454.412C217.8 453.882 216.393 452.889 215.357 451.558C214.32 450.228 213.702 448.62 213.579 446.938C213.457 445.256 213.836 443.575 214.669 442.109C215.501 440.642 216.75 439.455 218.257 438.699C219.764 437.942 221.462 437.649 223.136 437.857C225.38 438.136 227.421 439.295 228.811 441.079C230.2 442.863 230.824 445.126 230.545 447.37Z'
                opacity='.2' />
          <path id='Vector_842' fill='white'
                d='M222.963 440.693C222.292 443.206 222.253 446.467 217.691 446.288C218.951 447.086 220.377 447.824 221.154 449.207C221.875 450.633 222.252 452.211 222.256 453.812C222.694 452.356 222.523 450.924 223.62 449.52C224.019 448.999 224.53 448.577 225.114 448.285C225.699 447.993 226.341 447.839 226.993 447.835C225.649 446.846 224.377 446.814 223.716 444.962C223.456 444.26 223.256 443.537 223.119 442.8C222.995 442.056 223.205 440.956 222.806 440.376L222.963 440.693Z'
                opacity='.7' />
          <path id='Vector_843' fill='white'
                d='M223.801 446.863C223.478 445.822 223.13 445.943 222.83 446.388C221.322 445.635 221.539 450.763 223.296 449.466C223.892 449.028 224 447.508 223.801 446.863Z' />
          <path id='Vector_844' fill='white'
                d='M196.959 434.387C196.764 435.959 196.106 437.438 195.07 438.637C194.035 439.835 192.666 440.7 191.139 441.121C189.612 441.542 187.994 441.501 186.49 441.003C184.986 440.504 183.664 439.571 182.691 438.321C181.717 437.071 181.136 435.561 181.021 433.981C180.906 432.401 181.262 430.822 182.044 429.444C182.827 428.067 184 426.952 185.416 426.241C186.831 425.53 188.426 425.255 189.998 425.45C192.106 425.713 194.024 426.801 195.329 428.477C196.635 430.153 197.221 432.279 196.959 434.387Z'
                opacity='.1' />
          <path id='Vector_845' fill='white'
                d='M195.941 434.134C195.796 435.3 195.308 436.397 194.539 437.286C193.771 438.175 192.756 438.816 191.622 439.129C190.489 439.441 189.289 439.41 188.174 439.04C187.058 438.67 186.077 437.978 185.355 437.051C184.633 436.124 184.202 435.003 184.116 433.831C184.031 432.659 184.295 431.488 184.875 430.465C185.455 429.443 186.326 428.616 187.376 428.089C188.426 427.561 189.609 427.357 190.776 427.502C191.55 427.598 192.298 427.846 192.977 428.231C193.656 428.616 194.253 429.132 194.732 429.748C195.212 430.363 195.566 431.068 195.773 431.82C195.98 432.573 196.037 433.359 195.941 434.134Z'
                opacity='.2' />
          <path id='Vector_846' fill='white'
                d='M190.492 429.228C190.007 430.968 189.979 433.225 186.685 433.1C187.594 433.652 188.624 434.163 189.185 435.121C189.706 436.108 189.979 437.2 189.982 438.309C190.298 437.3 190.174 436.309 190.966 435.337C191.255 434.977 191.624 434.684 192.046 434.482C192.468 434.28 192.932 434.174 193.403 434.171C192.432 433.486 191.514 433.464 191.036 432.181C190.848 431.696 190.704 431.195 190.604 430.685C190.514 430.17 190.667 429.409 190.378 429.007L190.492 429.228Z'
                opacity='.7' />
          <path id='Vector_847' fill='white'
                d='M220.746 404.385C220.601 405.501 220.114 406.551 219.345 407.401C218.577 408.252 217.562 408.866 216.429 409.165C215.296 409.463 214.095 409.434 212.979 409.081C211.864 408.727 210.883 408.065 210.16 407.178C209.438 406.291 209.007 405.219 208.922 404.098C208.836 402.976 209.1 401.856 209.681 400.878C210.261 399.9 211.131 399.109 212.182 398.605C213.232 398.1 214.415 397.905 215.582 398.044C217.146 398.229 218.569 399.002 219.537 400.191C220.506 401.381 220.941 402.889 220.746 404.385Z'
                opacity='.1' />
          <path id='Vector_848' fill='white'
                d='M219.725 404.194C219.617 405.006 219.257 405.769 218.689 406.388C218.121 407.007 217.371 407.453 216.533 407.67C215.696 407.887 214.808 407.866 213.984 407.609C213.159 407.352 212.434 406.87 211.9 406.225C211.366 405.58 211.048 404.8 210.985 403.985C210.922 403.169 211.117 402.354 211.546 401.643C211.975 400.932 212.618 400.357 213.395 399.99C214.171 399.623 215.046 399.481 215.908 399.582C216.48 399.649 217.033 399.821 217.535 400.089C218.036 400.357 218.477 400.716 218.831 401.144C219.186 401.572 219.447 402.062 219.6 402.586C219.754 403.109 219.796 403.656 219.725 404.194Z'
                opacity='.2' />
          <path id='Vector_849' fill='white'
                d='M215.677 400.743C215.341 401.999 215.322 403.629 213.04 403.54C213.671 403.939 214.383 404.308 214.771 405C215.132 405.712 215.321 406.501 215.323 407.302C215.541 406.574 215.456 405.858 216.005 405.156C216.204 404.895 216.46 404.684 216.752 404.538C217.044 404.393 217.365 404.316 217.691 404.314C217.019 403.819 216.383 403.803 216.053 402.877C215.922 402.526 215.822 402.164 215.754 401.796C215.691 401.424 215.797 400.874 215.597 400.584L215.677 400.743Z'
                opacity='.7' />
          <path id='Vector_850' fill='white'
                d='M190.75 433.312C190.508 432.42 190.247 432.524 190.022 432.906C188.891 432.261 189.054 436.654 190.372 435.544C190.819 435.167 190.9 433.864 190.75 433.312Z' />
        </g>;
      case 3 :
        return <g id='Mini gem_18'>
          <path id='Vector_851' fill={`#${diamondColors[color][0]}`}
                d='M268.234 359.975L286 376.549L272.786 388.924L268.233 360.567L251.289 379.797L269.877 406.917L272.786 388.924L268.234 359.975Z' />
          <path id='Vector_852' fill={`#${diamondColors[color][1]}`} d='M268.204 359.975L251.289 379.702L272.777 389.066L268.204 359.975Z' />
          <path id='Vector_853' fill='white'
                d='M268.204 359.975L267.732 360.526L272.047 387.975L251.772 379.14L251.289 379.702L272.777 389.066L268.204 359.975Z' />
          <path id='Vector_854' fill={`#${diamondColors[color][2]}`} d='M272.724 388.897L269.802 406.917L286 376.504L272.724 388.897Z' />
          <path id='Vector_855' fill={`#${diamondColors[color][2]}`} d='M272.724 388.897L269.802 406.917L286 376.504L272.724 388.897Z' />
          <path id='Vector_856' fill='white' d='M251.289 379.81L270.463 374.012L268.261 359.975L251.289 379.81Z'
                opacity='.2' />
          <path id='Vector_857' fill='white' d='M270.358 373.966L286 376.504L268.149 359.975L270.358 373.71V373.966Z'
                opacity='.2' />
          <path id='Vector_858' fill={`#${diamondColors[color][3]}`}
                d='M271.51 396.402C271.541 396.206 280.981 381.351 280.981 381.351L286 376.835L274.6 397.93L269.802 406.917L271.51 396.402Z'
                opacity='.5' />
          <path id='Vector_859' fill={`#${diamondColors[color][3]}`}
                d='M273.295 388.897L285.435 377.566L286 376.504L272.724 388.897L269.802 406.917L270.623 405.374L273.295 388.897Z' />
          <path id='Vector_860' fill={`#${diamondColors[color][3]}`}
                d='M257.542 388.94L272.446 389.066L251.289 379.81L257.542 388.94Z'
                opacity='.5' />
          <path id='Vector_861' fill='white'
                d='M252.281 379.466C252.933 380.134 254.537 380.229 255.445 380.403C257.336 380.812 259.286 380.881 261.203 380.608C263.524 380.234 265.348 379.213 267.151 377.776C268.328 376.839 268.793 375.917 269.286 374.583C269.432 374.243 269.547 373.89 269.629 373.529C270.223 373.844 270.144 375.855 270.314 376.525C270.649 377.842 271.014 379.121 271.151 380.468C271.412 382.697 271.789 384.911 272.279 387.101C272.372 387.55 272.664 388.129 272.153 388.355C271.748 388.535 271.029 388.182 270.68 388.054C269.644 387.675 268.844 387.023 267.847 386.553C266.878 386.095 265.854 385.851 264.876 385.393C263.841 384.909 262.83 384.47 261.836 383.909C260.312 383.049 258.622 382.376 257.028 381.704C256.227 381.366 255.387 381.047 254.625 380.699C254.022 380.424 252.68 380.106 252.344 379.528L252.281 379.466Z'
                opacity='.4' />
          <path id='Vector_862' fill='white'
                d='M275.665 394.271C279.028 392.069 279.97 387.558 277.768 384.195C275.566 380.831 271.054 379.89 267.691 382.092C264.328 384.294 263.386 388.805 265.588 392.169C267.79 395.532 272.302 396.473 275.665 394.271Z'
                opacity='.1' />
          <path id='Vector_863' fill='white'
                d='M277.694 388.582C277.561 389.653 277.113 390.66 276.408 391.476C275.702 392.292 274.771 392.881 273.731 393.167C272.691 393.454 271.589 393.426 270.565 393.087C269.541 392.747 268.641 392.112 267.978 391.261C267.315 390.41 266.919 389.381 266.841 388.305C266.763 387.229 267.005 386.154 267.538 385.216C268.07 384.278 268.869 383.519 269.833 383.035C270.797 382.551 271.883 382.363 272.954 382.496C274.389 382.675 275.695 383.416 276.584 384.557C277.473 385.699 277.872 387.146 277.694 388.582Z'
                opacity='.2' />
          <path id='Vector_864' fill='white'
                d='M272.844 384.311C272.414 385.918 272.389 388.004 269.471 387.89C270.277 388.4 271.189 388.872 271.686 389.757C272.147 390.669 272.389 391.678 272.391 392.703C272.671 391.771 272.562 390.855 273.264 389.957C273.519 389.624 273.846 389.354 274.22 389.167C274.594 388.98 275.005 388.882 275.421 388.879C274.562 388.246 273.748 388.226 273.325 387.041C273.159 386.592 273.031 386.13 272.943 385.658C272.864 385.183 272.998 384.479 272.743 384.107L272.844 384.311Z'
                opacity='.7' />
          <path id='Vector_865' fill='white'
                d='M273.379 388.257C273.173 387.592 272.95 387.669 272.758 387.953C271.794 387.472 271.933 390.752 273.057 389.923C273.438 389.642 273.507 388.67 273.379 388.257Z' />
          <path id='Vector_866' fill='white'
                d='M256.209 380.277C256.084 381.282 255.663 382.228 255.001 382.995C254.338 383.762 253.463 384.315 252.486 384.584C251.509 384.854 250.474 384.827 249.512 384.509C248.55 384.19 247.704 383.593 247.082 382.793C246.459 381.994 246.087 381.028 246.014 380.017C245.94 379.006 246.168 377.996 246.668 377.115C247.168 376.234 247.919 375.52 248.825 375.066C249.73 374.611 250.75 374.435 251.756 374.56C253.105 374.728 254.331 375.424 255.166 376.496C256.001 377.568 256.376 378.928 256.209 380.277Z'
                opacity='.1' />
          <path id='Vector_867' fill='white'
                d='M255.558 380.115C255.465 380.861 255.153 381.562 254.661 382.131C254.169 382.7 253.52 383.11 252.795 383.31C252.07 383.509 251.303 383.49 250.589 383.253C249.875 383.017 249.248 382.574 248.786 381.981C248.324 381.388 248.048 380.671 247.994 379.921C247.939 379.171 248.108 378.422 248.479 377.768C248.85 377.114 249.407 376.585 250.079 376.248C250.75 375.91 251.507 375.779 252.253 375.872C252.749 375.933 253.228 376.092 253.662 376.339C254.096 376.585 254.478 376.915 254.784 377.309C255.091 377.703 255.317 378.153 255.45 378.635C255.583 379.116 255.619 379.619 255.558 380.115Z'
                opacity='.2' />
          <path id='Vector_868' fill='white'
                d='M252.072 376.976C251.762 378.089 251.744 379.533 249.636 379.453C250.218 379.807 250.877 380.134 251.236 380.746C251.569 381.377 251.744 382.076 251.746 382.785C251.948 382.14 251.869 381.506 252.375 380.885C252.56 380.654 252.796 380.467 253.066 380.338C253.336 380.208 253.633 380.14 253.934 380.138C253.313 379.7 252.726 379.686 252.42 378.866C252.3 378.555 252.207 378.235 252.144 377.909C252.086 377.579 252.184 377.092 251.999 376.835L252.072 376.976Z'
                opacity='.7' />
          <path id='Vector_869' fill='white'
                d='M271.426 361.085C271.333 361.798 271.021 362.47 270.529 363.014C270.038 363.558 269.388 363.951 268.664 364.142C267.939 364.333 267.171 364.314 266.457 364.088C265.743 363.862 265.116 363.438 264.654 362.871C264.192 362.304 263.916 361.618 263.861 360.901C263.807 360.183 263.976 359.467 264.347 358.841C264.718 358.216 265.275 357.71 265.947 357.387C266.619 357.064 267.376 356.939 268.122 357.028C269.122 357.147 270.032 357.641 270.652 358.402C271.272 359.163 271.55 360.128 271.426 361.085Z'
                opacity='.1' />
          <path id='Vector_870' fill='white'
                d='M270.772 360.962C270.703 361.482 270.473 361.97 270.109 362.366C269.746 362.761 269.266 363.047 268.73 363.186C268.195 363.325 267.627 363.311 267.099 363.147C266.572 362.982 266.108 362.674 265.767 362.261C265.425 361.849 265.221 361.35 265.181 360.828C265.141 360.307 265.266 359.785 265.54 359.33C265.814 358.876 266.226 358.508 266.723 358.273C267.219 358.038 267.779 357.947 268.33 358.012C268.697 358.055 269.05 358.165 269.371 358.337C269.692 358.508 269.974 358.737 270.2 359.011C270.427 359.285 270.594 359.599 270.692 359.933C270.79 360.268 270.817 360.618 270.772 360.962Z'
                opacity='.2' />
          <path id='Vector_871' fill='white'
                d='M268.183 358.754C267.968 359.558 267.956 360.601 266.496 360.544C266.899 360.799 267.355 361.035 267.603 361.478C267.834 361.934 267.955 362.438 267.956 362.95C268.096 362.485 268.041 362.027 268.392 361.578C268.52 361.411 268.683 361.276 268.87 361.183C269.057 361.089 269.263 361.04 269.471 361.039C269.041 360.722 268.634 360.712 268.423 360.12C268.34 359.895 268.276 359.664 268.232 359.428C268.192 359.19 268.259 358.839 268.132 358.653L268.183 358.754Z'
                opacity='.7' />
          <path id='Vector_872' fill='white'
                d='M252.237 379.589C252.082 379.018 251.915 379.085 251.771 379.329C251.048 378.917 251.152 381.727 251.995 381.017C252.281 380.775 252.333 379.942 252.237 379.589Z' />
        </g>;


      case 4:
        return <g id='Mini gem_19'>
          <path id='Vector_873' fill={`#${diamondColors[color][0]}`}
                d='M323.234 492.975L341 509.549L327.786 521.924L323.233 493.567L306.289 512.797L324.877 539.917L327.786 521.924L323.234 492.975Z' />
          <path id='Vector_874' fill={`#${diamondColors[color][1]}`} d='M323.204 492.975L306.289 512.702L327.777 522.066L323.204 492.975Z' />
          <path id='Vector_875' fill='white'
                d='M323.204 492.975L322.732 493.526L327.047 520.975L306.772 512.14L306.289 512.702L327.777 522.066L323.204 492.975Z' />
          <path id='Vector_876' fill={`#${diamondColors[color][2]}`} d='M327.724 521.897L324.802 539.917L341 509.504L327.724 521.897Z' />
          <path id='Vector_877' fill={`#${diamondColors[color][2]}`} d='M327.724 521.897L324.802 539.917L341 509.504L327.724 521.897Z' />
          <path id='Vector_878' fill='white' d='M306.289 512.81L325.463 507.012L323.261 492.975L306.289 512.81Z'
                opacity='.2' />
          <path id='Vector_879' fill='white' d='M325.358 506.966L341 509.504L323.149 492.975L325.358 506.71V506.966Z'
                opacity='.2' />
          <path id='Vector_880' fill={`#${diamondColors[color][3]}`}
                d='M326.51 529.402C326.541 529.206 335.981 514.351 335.981 514.351L341 509.835L329.6 530.93L324.802 539.917L326.51 529.402Z'
                opacity='.5' />
          <path id='Vector_881' fill={`#${diamondColors[color][3]}`}
                d='M328.295 521.897L340.435 510.566L341 509.504L327.724 521.897L324.802 539.917L325.623 538.374L328.295 521.897Z' />
          <path id='Vector_882' fill={`#${diamondColors[color][3]}`}
                d='M312.542 521.94L327.446 522.066L306.289 512.81L312.542 521.94Z'
                opacity='.5' />
          <path id='Vector_883' fill='white'
                d='M307.281 512.466C307.933 513.134 309.537 513.229 310.445 513.403C312.336 513.812 314.286 513.881 316.203 513.608C318.524 513.234 320.348 512.213 322.151 510.776C323.328 509.839 323.793 508.917 324.286 507.583C324.432 507.243 324.547 506.89 324.629 506.529C325.223 506.844 325.144 508.855 325.314 509.525C325.649 510.842 326.014 512.121 326.151 513.468C326.412 515.697 326.789 517.911 327.279 520.102C327.372 520.55 327.664 521.129 327.153 521.355C326.748 521.535 326.029 521.182 325.68 521.054C324.644 520.676 323.844 520.023 322.847 519.553C321.878 519.095 320.854 518.851 319.876 518.393C318.841 517.909 317.83 517.47 316.836 516.909C315.312 516.049 313.622 515.376 312.028 514.704C311.227 514.366 310.387 514.047 309.625 513.699C309.022 513.424 307.68 513.106 307.344 512.528L307.281 512.466Z'
                opacity='.4' />
          <path id='Vector_884' fill='white'
                d='M330.665 527.271C334.028 525.069 334.97 520.558 332.768 517.195C330.566 513.831 326.054 512.89 322.691 515.092C319.328 517.294 318.386 521.805 320.588 525.169C322.79 528.532 327.302 529.473 330.665 527.271Z'
                opacity='.1' />
          <path id='Vector_885' fill='white'
                d='M332.694 521.582C332.561 522.653 332.113 523.66 331.408 524.476C330.702 525.292 329.771 525.881 328.731 526.168C327.691 526.454 326.589 526.426 325.565 526.087C324.541 525.747 323.641 525.112 322.978 524.261C322.315 523.41 321.919 522.381 321.841 521.305C321.763 520.229 322.005 519.154 322.538 518.216C323.07 517.278 323.869 516.519 324.833 516.035C325.797 515.551 326.883 515.363 327.954 515.496C329.389 515.675 330.695 516.416 331.584 517.557C332.473 518.699 332.872 520.146 332.694 521.582Z'
                opacity='.2' />
          <path id='Vector_886' fill='white'
                d='M327.844 517.311C327.414 518.918 327.389 521.004 324.471 520.89C325.277 521.4 326.189 521.872 326.686 522.757C327.147 523.669 327.389 524.678 327.391 525.703C327.671 524.771 327.562 523.855 328.264 522.957C328.519 522.624 328.846 522.354 329.22 522.167C329.594 521.98 330.005 521.882 330.421 521.879C329.562 521.246 328.748 521.226 328.325 520.041C328.159 519.592 328.031 519.13 327.943 518.658C327.864 518.183 327.998 517.479 327.743 517.108L327.844 517.311Z'
                opacity='.7' />
          <path id='Vector_887' fill='white'
                d='M328.379 521.257C328.173 520.592 327.95 520.669 327.758 520.953C326.794 520.472 326.933 523.752 328.057 522.923C328.438 522.642 328.507 521.67 328.379 521.257Z' />
          <path id='Vector_888' fill='white'
                d='M311.209 513.277C311.084 514.282 310.663 515.228 310.001 515.995C309.338 516.762 308.463 517.315 307.486 517.584C306.509 517.854 305.474 517.827 304.512 517.509C303.55 517.19 302.704 516.593 302.082 515.793C301.459 514.994 301.087 514.028 301.014 513.017C300.94 512.006 301.168 510.996 301.668 510.115C302.168 509.234 302.919 508.52 303.825 508.066C304.73 507.611 305.75 507.435 306.756 507.56C308.105 507.728 309.331 508.424 310.166 509.496C311.001 510.568 311.376 511.928 311.209 513.277Z'
                opacity='.1' />
          <path id='Vector_889' fill='white'
                d='M310.558 513.115C310.465 513.861 310.153 514.562 309.661 515.131C309.169 515.7 308.52 516.11 307.795 516.31C307.07 516.509 306.303 516.49 305.589 516.253C304.875 516.017 304.248 515.574 303.786 514.981C303.324 514.388 303.048 513.671 302.994 512.921C302.939 512.171 303.108 511.422 303.479 510.768C303.85 510.114 304.407 509.585 305.079 509.248C305.75 508.91 306.507 508.779 307.253 508.872C307.749 508.934 308.228 509.092 308.662 509.339C309.096 509.585 309.478 509.915 309.784 510.309C310.091 510.703 310.317 511.153 310.45 511.635C310.583 512.116 310.619 512.619 310.558 513.115Z'
                opacity='.2' />
          <path id='Vector_890' fill='white'
                d='M307.072 509.976C306.762 511.089 306.744 512.533 304.636 512.453C305.218 512.807 305.877 513.134 306.236 513.746C306.569 514.378 306.744 515.076 306.746 515.785C306.948 515.14 306.869 514.506 307.375 513.885C307.56 513.654 307.796 513.467 308.066 513.338C308.336 513.208 308.633 513.14 308.934 513.138C308.313 512.7 307.726 512.686 307.42 511.866C307.3 511.555 307.207 511.235 307.144 510.909C307.086 510.579 307.184 510.092 306.999 509.835L307.072 509.976Z'
                opacity='.7' />
          <path id='Vector_891' fill='white'
                d='M326.426 494.085C326.333 494.798 326.021 495.47 325.529 496.014C325.038 496.558 324.388 496.951 323.664 497.142C322.939 497.333 322.171 497.314 321.457 497.088C320.743 496.862 320.116 496.438 319.654 495.871C319.192 495.304 318.916 494.618 318.861 493.901C318.807 493.183 318.976 492.467 319.347 491.841C319.718 491.216 320.275 490.71 320.947 490.387C321.619 490.064 322.376 489.939 323.122 490.028C324.122 490.147 325.032 490.641 325.652 491.402C326.272 492.163 326.55 493.128 326.426 494.085Z'
                opacity='.1' />
          <path id='Vector_892' fill='white'
                d='M325.772 493.963C325.703 494.482 325.473 494.97 325.109 495.366C324.746 495.761 324.266 496.047 323.73 496.186C323.195 496.325 322.627 496.311 322.099 496.147C321.572 495.982 321.108 495.674 320.767 495.261C320.425 494.849 320.221 494.35 320.181 493.828C320.141 493.307 320.266 492.785 320.54 492.33C320.814 491.876 321.226 491.508 321.723 491.273C322.219 491.038 322.779 490.947 323.33 491.012C323.697 491.055 324.05 491.165 324.371 491.337C324.692 491.508 324.974 491.737 325.2 492.011C325.427 492.285 325.594 492.599 325.692 492.933C325.79 493.268 325.817 493.618 325.772 493.963Z'
                opacity='.2' />
          <path id='Vector_893' fill='white'
                d='M323.183 491.754C322.968 492.558 322.956 493.601 321.496 493.544C321.899 493.799 322.355 494.035 322.603 494.478C322.834 494.934 322.955 495.438 322.956 495.951C323.096 495.485 323.041 495.027 323.392 494.578C323.52 494.411 323.683 494.276 323.87 494.183C324.057 494.089 324.263 494.04 324.471 494.039C324.041 493.722 323.634 493.712 323.423 493.12C323.34 492.895 323.276 492.664 323.232 492.428C323.192 492.19 323.259 491.839 323.132 491.653L323.183 491.754Z'
                opacity='.7' />
          <path id='Vector_894' fill='white'
                d='M307.237 512.589C307.082 512.018 306.915 512.085 306.771 512.329C306.048 511.917 306.152 514.727 306.995 514.017C307.281 513.775 307.333 512.942 307.237 512.589Z' />
        </g>;


      case 5 :
        return <g id='Mini gem_20'>
          <path id='Vector_895' fill={`#${diamondColors[color][0]}`}
                d='M425.234 473.975L443 490.549L429.786 502.924L425.233 474.567L408.289 493.797L426.877 520.917L429.786 502.924L425.234 473.975Z' />
          <path id='Vector_896' fill={`#${diamondColors[color][1]}`} d='M425.204 473.975L408.289 493.702L429.777 503.066L425.204 473.975Z' />
          <path id='Vector_897' fill='white'
                d='M425.204 473.975L424.732 474.526L429.047 501.975L408.772 493.14L408.289 493.702L429.777 503.066L425.204 473.975Z' />
          <path id='Vector_898' fill={`#${diamondColors[color][2]}`} d='M429.724 502.897L426.802 520.917L443 490.504L429.724 502.897Z' />
          <path id='Vector_899' fill={`#${diamondColors[color][2]}`} d='M429.724 502.897L426.802 520.917L443 490.504L429.724 502.897Z' />
          <path id='Vector_900' fill='white' d='M408.289 493.81L427.463 488.012L425.261 473.975L408.289 493.81Z'
                opacity='.2' />
          <path id='Vector_901' fill='white' d='M427.358 487.966L443 490.504L425.149 473.975L427.358 487.71V487.966Z'
                opacity='.2' />
          <path id='Vector_902' fill={`#${diamondColors[color][3]}`}
                d='M428.51 510.402C428.541 510.206 437.981 495.351 437.981 495.351L443 490.835L431.6 511.93L426.802 520.917L428.51 510.402Z'
                opacity='.5' />
          <path id='Vector_903' fill={`#${diamondColors[color][3]}`}
                d='M430.295 502.897L442.435 491.566L443 490.504L429.724 502.897L426.802 520.917L427.623 519.374L430.295 502.897Z' />
          <path id='Vector_904' fill={`#${diamondColors[color][3]}`}
                d='M414.542 502.94L429.446 503.066L408.289 493.81L414.542 502.94Z'
                opacity='.5' />
          <path id='Vector_905' fill='white'
                d='M409.281 493.466C409.933 494.134 411.537 494.229 412.445 494.403C414.336 494.812 416.286 494.881 418.203 494.608C420.524 494.234 422.348 493.213 424.151 491.776C425.328 490.839 425.793 489.917 426.286 488.583C426.432 488.243 426.547 487.89 426.629 487.529C427.223 487.844 427.144 489.855 427.314 490.525C427.649 491.842 428.014 493.121 428.151 494.468C428.412 496.697 428.789 498.911 429.279 501.102C429.372 501.55 429.664 502.129 429.153 502.355C428.748 502.535 428.029 502.182 427.68 502.054C426.644 501.676 425.844 501.023 424.847 500.553C423.878 500.095 422.854 499.851 421.876 499.393C420.841 498.909 419.83 498.47 418.836 497.909C417.312 497.049 415.622 496.376 414.028 495.704C413.227 495.366 412.387 495.047 411.625 494.699C411.022 494.424 409.68 494.106 409.344 493.528L409.281 493.466Z'
                opacity='.4' />
          <path id='Vector_906' fill='white'
                d='M432.665 508.271C436.028 506.069 436.97 501.558 434.768 498.195C432.566 494.831 428.054 493.89 424.691 496.092C421.328 498.294 420.386 502.805 422.588 506.169C424.79 509.532 429.302 510.473 432.665 508.271Z'
                opacity='.1' />
          <path id='Vector_907' fill='white'
                d='M434.694 502.582C434.561 503.653 434.113 504.66 433.408 505.476C432.702 506.292 431.771 506.881 430.731 507.168C429.691 507.454 428.589 507.426 427.565 507.087C426.541 506.747 425.641 506.112 424.978 505.261C424.315 504.41 423.919 503.381 423.841 502.305C423.763 501.229 424.005 500.154 424.538 499.216C425.07 498.278 425.869 497.519 426.833 497.035C427.797 496.551 428.883 496.363 429.954 496.496C431.389 496.675 432.695 497.416 433.584 498.557C434.473 499.699 434.872 501.146 434.694 502.582Z'
                opacity='.2' />
          <path id='Vector_908' fill='white'
                d='M429.844 498.311C429.414 499.918 429.389 502.004 426.471 501.89C427.277 502.4 428.189 502.872 428.686 503.757C429.147 504.669 429.389 505.678 429.391 506.703C429.671 505.771 429.562 504.855 430.264 503.957C430.519 503.624 430.846 503.354 431.22 503.167C431.594 502.98 432.005 502.882 432.421 502.879C431.562 502.246 430.748 502.226 430.325 501.041C430.159 500.592 430.031 500.13 429.943 499.658C429.864 499.183 429.998 498.479 429.743 498.108L429.844 498.311Z'
                opacity='.7' />
          <path id='Vector_909' fill='white'
                d='M430.379 502.257C430.173 501.592 429.95 501.669 429.758 501.953C428.794 501.472 428.933 504.752 430.057 503.923C430.438 503.642 430.507 502.67 430.379 502.257Z' />
          <path id='Vector_910' fill='white'
                d='M413.209 494.277C413.084 495.282 412.663 496.228 412.001 496.995C411.338 497.762 410.463 498.315 409.486 498.584C408.509 498.854 407.474 498.827 406.512 498.509C405.55 498.19 404.704 497.593 404.082 496.793C403.459 495.994 403.087 495.028 403.014 494.017C402.94 493.006 403.168 491.996 403.668 491.115C404.168 490.234 404.919 489.52 405.825 489.066C406.73 488.611 407.75 488.435 408.756 488.56C410.105 488.728 411.331 489.424 412.166 490.496C413.001 491.568 413.376 492.928 413.209 494.277Z'
                opacity='.1' />
          <path id='Vector_911' fill='white'
                d='M412.558 494.115C412.465 494.861 412.153 495.562 411.661 496.131C411.169 496.7 410.52 497.11 409.795 497.31C409.07 497.509 408.303 497.49 407.589 497.253C406.875 497.017 406.248 496.574 405.786 495.981C405.324 495.388 405.048 494.671 404.994 493.921C404.939 493.171 405.108 492.422 405.479 491.768C405.85 491.114 406.407 490.585 407.079 490.248C407.75 489.91 408.507 489.779 409.253 489.872C409.749 489.934 410.228 490.092 410.662 490.339C411.096 490.585 411.478 490.915 411.784 491.309C412.091 491.703 412.317 492.153 412.45 492.635C412.583 493.116 412.619 493.619 412.558 494.115Z'
                opacity='.2' />
          <path id='Vector_912' fill='white'
                d='M409.072 490.976C408.762 492.089 408.744 493.533 406.636 493.453C407.218 493.807 407.877 494.134 408.236 494.746C408.569 495.378 408.744 496.076 408.746 496.785C408.948 496.14 408.869 495.506 409.375 494.885C409.56 494.654 409.796 494.467 410.066 494.338C410.336 494.208 410.633 494.14 410.934 494.138C410.313 493.7 409.726 493.686 409.42 492.866C409.3 492.555 409.207 492.235 409.144 491.909C409.086 491.579 409.184 491.092 408.999 490.835L409.072 490.976Z'
                opacity='.7' />
          <path id='Vector_913' fill='white'
                d='M428.426 475.085C428.333 475.798 428.021 476.47 427.529 477.014C427.038 477.558 426.388 477.951 425.664 478.142C424.939 478.333 424.171 478.314 423.457 478.088C422.743 477.862 422.116 477.438 421.654 476.871C421.192 476.304 420.916 475.618 420.861 474.901C420.807 474.183 420.976 473.467 421.347 472.841C421.718 472.216 422.275 471.71 422.947 471.387C423.619 471.064 424.376 470.939 425.122 471.028C426.122 471.147 427.032 471.641 427.652 472.402C428.272 473.163 428.55 474.128 428.426 475.085Z'
                opacity='.1' />
          <path id='Vector_914' fill='white'
                d='M427.772 474.963C427.703 475.482 427.473 475.97 427.109 476.366C426.746 476.761 426.266 477.047 425.73 477.186C425.195 477.325 424.627 477.311 424.099 477.147C423.572 476.982 423.108 476.674 422.767 476.261C422.425 475.849 422.221 475.35 422.181 474.828C422.141 474.307 422.266 473.785 422.54 473.33C422.814 472.876 423.226 472.508 423.723 472.273C424.219 472.038 424.779 471.947 425.33 472.012C425.697 472.055 426.05 472.165 426.371 472.337C426.692 472.508 426.974 472.737 427.2 473.011C427.427 473.285 427.594 473.599 427.692 473.933C427.79 474.268 427.817 474.618 427.772 474.963Z'
                opacity='.2' />
          <path id='Vector_915' fill='white'
                d='M425.183 472.754C424.968 473.558 424.956 474.601 423.496 474.544C423.899 474.799 424.355 475.035 424.603 475.478C424.834 475.934 424.955 476.438 424.956 476.951C425.096 476.485 425.041 476.027 425.392 475.578C425.52 475.411 425.683 475.276 425.87 475.183C426.057 475.089 426.263 475.04 426.471 475.039C426.041 474.722 425.634 474.712 425.423 474.12C425.34 473.895 425.276 473.664 425.232 473.428C425.192 473.19 425.259 472.839 425.132 472.653L425.183 472.754Z'
                opacity='.7' />
          <path id='Vector_916' fill='white'
                d='M409.237 493.589C409.082 493.018 408.915 493.085 408.771 493.329C408.048 492.917 408.152 495.727 408.995 495.017C409.281 494.775 409.333 493.942 409.237 493.589Z' />
        </g>;


      case 6 :
        return <g id='Mini gem_21'>
          <path id='Vector_917' fill={`#${diamondColors[color][0]}`}
                d='M372.018 276.686L400 302.789L379.187 322.28L372.018 277.618L345.331 307.906L374.607 350.62L379.187 322.28L372.018 276.686Z' />
          <path id='Vector_918' fill={`#${diamondColors[color][1]}`} d='M371.971 276.686L345.331 307.756L379.174 322.504L371.971 276.686Z' />
          <path id='Vector_919' fill='white'
                d='M371.972 276.686L371.228 277.553L378.024 320.786L346.091 306.87L345.331 307.756L379.174 322.504L371.972 276.686Z' />
          <path id='Vector_920' fill={`#${diamondColors[color][2]}`} d='M379.09 322.238L374.488 350.62L400 302.719L379.09 322.238Z' />
          <path id='Vector_921' fill={`#${diamondColors[color][2]}`} d='M379.09 322.238L374.488 350.62L400 302.719L379.09 322.238Z' />
          <path id='Vector_922' fill='white' d='M345.331 307.926L375.529 298.794L372.061 276.686L345.331 307.926Z'
                opacity='.2' />
          <path id='Vector_923' fill='white' d='M375.363 298.721L400 302.719L371.884 276.686L375.363 298.317V298.721Z'
                opacity='.2' />
          <path id='Vector_924' fill={`#${diamondColors[color][3]}`}
                d='M377.177 334.058C377.228 333.749 392.095 310.352 392.095 310.352L400 303.24L382.045 336.465L374.488 350.62L377.177 334.058Z'
                opacity='.5' />
          <path id='Vector_925' fill={`#${diamondColors[color][3]}`}
                d='M379.99 322.238L399.11 304.391L400 302.719L379.09 322.238L374.488 350.62L375.782 348.189L379.99 322.238Z' />
          <path id='Vector_926' fill={`#${diamondColors[color][3]}`}
                d='M355.178 322.305L378.653 322.504L345.331 307.926L355.178 322.305Z'
                opacity='.5' />
          <path id='Vector_927' fill='white'
                d='M346.893 307.384C347.919 308.437 350.445 308.585 351.875 308.86C354.854 309.503 357.926 309.613 360.944 309.183C364.601 308.593 367.473 306.985 370.312 304.723C372.166 303.246 372.899 301.794 373.676 299.694C373.906 299.157 374.087 298.601 374.216 298.033C375.151 298.529 375.027 301.697 375.295 302.751C375.822 304.826 376.396 306.841 376.613 308.962C377.024 312.472 377.618 315.96 378.39 319.41C378.536 320.116 378.995 321.029 378.19 321.384C377.553 321.667 376.42 321.111 375.871 320.91C374.24 320.314 372.979 319.286 371.408 318.545C369.882 317.824 368.269 317.44 366.73 316.719C365.1 315.956 363.508 315.266 361.941 314.381C359.542 313.027 356.879 311.968 354.369 310.909C353.108 310.377 351.784 309.874 350.585 309.326C349.635 308.892 347.521 308.392 346.992 307.481L346.893 307.384Z'
                opacity='.4' />
          <path id='Vector_928' fill='white'
                d='M383.722 330.702C389.019 327.234 390.502 320.129 387.034 314.831C383.566 309.534 376.46 308.052 371.163 311.52C365.866 314.988 364.383 322.094 367.851 327.391C371.32 332.688 378.425 334.17 383.722 330.702Z'
                opacity='.1' />
          <path id='Vector_929' fill='white'
                d='M386.918 321.742C386.708 323.428 386.003 325.014 384.892 326.299C383.781 327.585 382.314 328.512 380.676 328.964C379.038 329.415 377.303 329.371 375.69 328.837C374.077 328.302 372.659 327.301 371.615 325.961C370.571 324.62 369.948 323 369.824 321.306C369.701 319.611 370.083 317.918 370.922 316.44C371.761 314.963 373.019 313.767 374.537 313.005C376.056 312.242 377.766 311.947 379.452 312.157C381.713 312.438 383.77 313.605 385.17 315.403C386.57 317.2 387.199 319.481 386.918 321.742Z'
                opacity='.2' />
          <path id='Vector_930' fill='white'
                d='M379.279 315.014C378.602 317.546 378.563 320.831 373.967 320.651C375.237 321.455 376.673 322.199 377.456 323.592C378.182 325.029 378.563 326.618 378.567 328.231C379.007 326.764 378.835 325.321 379.941 323.907C380.343 323.382 380.857 322.957 381.446 322.663C382.035 322.368 382.682 322.214 383.339 322.209C381.985 321.213 380.704 321.181 380.038 319.315C379.775 318.608 379.573 317.879 379.436 317.137C379.311 316.387 379.522 315.279 379.12 314.694L379.279 315.014Z'
                opacity='.7' />
          <path id='Vector_931' fill='white'
                d='M380.123 321.23C379.798 320.182 379.447 320.303 379.144 320.752C377.625 319.993 377.844 325.159 379.614 323.853C380.214 323.411 380.324 321.88 380.123 321.23Z' />
          <path id='Vector_932' fill='white'
                d='M353.079 308.66C352.882 310.244 352.22 311.735 351.176 312.942C350.133 314.15 348.754 315.021 347.215 315.445C345.677 315.869 344.047 315.828 342.532 315.326C341.016 314.824 339.684 313.884 338.703 312.624C337.723 311.365 337.137 309.843 337.021 308.251C336.905 306.659 337.264 305.069 338.052 303.681C338.84 302.293 340.022 301.17 341.449 300.453C342.875 299.737 344.482 299.46 346.066 299.657C348.19 299.921 350.122 301.018 351.437 302.706C352.752 304.395 353.343 306.537 353.079 308.66Z'
                opacity='.1' />
          <path id='Vector_933' fill='white'
                d='M352.053 308.405C351.907 309.58 351.415 310.686 350.641 311.581C349.867 312.477 348.844 313.123 347.702 313.438C346.561 313.752 345.352 313.721 344.228 313.349C343.104 312.976 342.115 312.279 341.388 311.344C340.66 310.41 340.226 309.281 340.14 308.1C340.054 306.919 340.32 305.739 340.904 304.709C341.489 303.68 342.366 302.846 343.424 302.315C344.482 301.783 345.674 301.577 346.849 301.723C347.63 301.82 348.383 302.07 349.067 302.458C349.751 302.846 350.352 303.366 350.835 303.986C351.319 304.607 351.675 305.316 351.884 306.075C352.093 306.833 352.15 307.625 352.053 308.405Z'
                opacity='.2' />
          <path id='Vector_934' fill='white'
                d='M346.563 303.463C346.075 305.215 346.047 307.49 342.727 307.364C343.644 307.92 344.682 308.435 345.247 309.4C345.771 310.394 346.046 311.495 346.049 312.612C346.368 311.596 346.243 310.597 347.041 309.618C347.332 309.255 347.704 308.96 348.129 308.756C348.554 308.553 349.022 308.446 349.496 308.443C348.518 307.753 347.593 307.731 347.112 306.438C346.922 305.949 346.776 305.445 346.677 304.931C346.585 304.412 346.739 303.645 346.449 303.24L346.563 303.463Z'
                opacity='.7' />
          <path id='Vector_935' fill='white'
                d='M377.045 278.433C376.899 279.557 376.408 280.615 375.634 281.472C374.859 282.329 373.837 282.947 372.695 283.248C371.554 283.55 370.344 283.52 369.22 283.164C368.096 282.808 367.107 282.14 366.38 281.247C365.652 280.353 365.218 279.273 365.132 278.143C365.046 277.014 365.312 275.885 365.896 274.9C366.481 273.915 367.358 273.117 368.416 272.609C369.475 272.101 370.667 271.904 371.842 272.044C373.418 272.231 374.851 273.01 375.827 274.208C376.803 275.406 377.241 276.926 377.045 278.433Z'
                opacity='.1' />
          <path id='Vector_936' fill='white'
                d='M376.016 278.241C375.908 279.058 375.545 279.828 374.972 280.451C374.4 281.074 373.644 281.524 372.8 281.743C371.956 281.962 371.062 281.94 370.232 281.681C369.401 281.422 368.67 280.937 368.132 280.287C367.594 279.637 367.274 278.851 367.21 278.03C367.146 277.208 367.343 276.387 367.776 275.67C368.208 274.954 368.856 274.374 369.638 274.005C370.421 273.635 371.302 273.492 372.17 273.594C372.747 273.661 373.304 273.835 373.809 274.105C374.315 274.375 374.759 274.736 375.116 275.168C375.473 275.599 375.736 276.093 375.891 276.62C376.045 277.147 376.087 277.698 376.016 278.241Z'
                opacity='.2' />
          <path id='Vector_937' fill='white'
                d='M371.938 274.763C371.599 276.029 371.58 277.672 369.281 277.582C369.916 277.984 370.634 278.356 371.025 279.052C371.389 279.77 371.579 280.565 371.581 281.372C371.801 280.638 371.715 279.917 372.268 279.21C372.469 278.947 372.726 278.734 373.021 278.587C373.315 278.441 373.639 278.363 373.967 278.361C373.29 277.862 372.649 277.847 372.316 276.913C372.185 276.56 372.084 276.195 372.015 275.824C371.952 275.45 372.059 274.896 371.858 274.603L371.938 274.763Z'
                opacity='.7' />
          <path id='Vector_938' fill='white'
                d='M346.823 307.577C346.58 306.678 346.316 306.783 346.089 307.168C344.95 306.519 345.114 310.945 346.442 309.826C346.893 309.446 346.974 308.133 346.823 307.577Z' />
        </g>;


      case 7 :
        return <g id='Mini gem_22'>
          <path id='Vector_939' fill={`#${diamondColors[color][0]}`}
                d='M305.018 237.686L333 263.789L312.187 283.28L305.018 238.618L278.331 268.906L307.607 311.62L312.187 283.28L305.018 237.686Z' />
          <path id='Vector_940' fill={`#${diamondColors[color][1]}`} d='M304.971 237.686L278.331 268.756L312.174 283.504L304.971 237.686Z' />
          <path id='Vector_941' fill='white'
                d='M304.972 237.686L304.228 238.553L311.024 281.786L279.091 267.87L278.331 268.756L312.174 283.504L304.972 237.686Z' />
          <path id='Vector_942' fill={`#${diamondColors[color][2]}`} d='M312.09 283.238L307.488 311.62L333 263.719L312.09 283.238Z' />
          <path id='Vector_943' fill={`#${diamondColors[color][2]}`} d='M312.09 283.238L307.488 311.62L333 263.719L312.09 283.238Z' />
          <path id='Vector_944' fill='white' d='M278.331 268.926L308.529 259.794L305.061 237.686L278.331 268.926Z'
                opacity='.2' />
          <path id='Vector_945' fill='white' d='M308.363 259.721L333 263.719L304.884 237.686L308.363 259.317V259.721Z'
                opacity='.2' />
          <path id='Vector_946' fill={`#${diamondColors[color][3]}`}
                d='M310.177 295.058C310.228 294.75 325.095 271.352 325.095 271.352L333 264.24L315.045 297.465L307.488 311.62L310.177 295.058Z'
                opacity='.5' />
          <path id='Vector_947' fill={`#${diamondColors[color][3]}`}
                d='M312.99 283.238L332.11 265.391L333 263.719L312.09 283.238L307.488 311.62L308.782 309.189L312.99 283.238Z' />
          <path id='Vector_948' fill={`#${diamondColors[color][3]}`}
                d='M288.178 283.305L311.653 283.504L278.331 268.926L288.178 283.305Z'
                opacity='.5' />
          <path id='Vector_949' fill='white'
                d='M279.893 268.384C280.919 269.437 283.445 269.586 284.875 269.86C287.854 270.503 290.926 270.613 293.944 270.183C297.601 269.593 300.473 267.985 303.312 265.723C305.166 264.246 305.899 262.794 306.676 260.694C306.906 260.157 307.087 259.601 307.216 259.033C308.151 259.529 308.027 262.697 308.295 263.751C308.822 265.826 309.396 267.841 309.613 269.962C310.024 273.473 310.618 276.96 311.39 280.41C311.536 281.116 311.995 282.029 311.19 282.384C310.553 282.667 309.42 282.111 308.871 281.91C307.24 281.314 305.979 280.286 304.408 279.545C302.882 278.825 301.269 278.44 299.73 277.719C298.1 276.956 296.508 276.266 294.941 275.381C292.542 274.027 289.879 272.968 287.369 271.909C286.108 271.377 284.784 270.874 283.585 270.326C282.635 269.892 280.521 269.392 279.992 268.481L279.893 268.384Z'
                opacity='.4' />
          <path id='Vector_950' fill='white'
                d='M316.722 291.702C322.019 288.234 323.502 281.129 320.034 275.831C316.566 270.534 309.46 269.052 304.163 272.52C298.866 275.988 297.383 283.094 300.851 288.391C304.32 293.688 311.425 295.17 316.722 291.702Z'
                opacity='.1' />
          <path id='Vector_951' fill='white'
                d='M319.918 282.742C319.708 284.428 319.003 286.014 317.892 287.299C316.781 288.585 315.314 289.512 313.676 289.964C312.038 290.415 310.303 290.371 308.69 289.837C307.077 289.302 305.659 288.301 304.615 286.961C303.571 285.62 302.948 284 302.824 282.306C302.701 280.611 303.083 278.918 303.922 277.44C304.761 275.963 306.019 274.767 307.537 274.005C309.056 273.242 310.766 272.947 312.452 273.157C314.713 273.438 316.77 274.605 318.17 276.403C319.57 278.2 320.199 280.481 319.918 282.742Z'
                opacity='.2' />
          <path id='Vector_952' fill='white'
                d='M312.279 276.014C311.602 278.546 311.563 281.831 306.967 281.651C308.237 282.455 309.673 283.199 310.456 284.592C311.182 286.029 311.563 287.618 311.567 289.231C312.007 287.764 311.835 286.322 312.941 284.907C313.343 284.382 313.857 283.957 314.446 283.663C315.035 283.369 315.682 283.214 316.339 283.209C314.985 282.213 313.704 282.181 313.038 280.315C312.775 279.608 312.573 278.879 312.436 278.137C312.311 277.388 312.522 276.279 312.12 275.694L312.279 276.014Z'
                opacity='.7' />
          <path id='Vector_953' fill='white'
                d='M313.123 282.23C312.798 281.182 312.447 281.303 312.144 281.752C310.625 280.993 310.844 286.159 312.614 284.853C313.214 284.411 313.324 282.88 313.123 282.23Z' />
          <path id='Vector_954' fill='white'
                d='M286.079 269.66C285.882 271.244 285.22 272.735 284.176 273.942C283.133 275.15 281.754 276.021 280.215 276.445C278.677 276.87 277.047 276.828 275.532 276.326C274.016 275.824 272.684 274.884 271.703 273.624C270.723 272.365 270.137 270.843 270.021 269.251C269.905 267.659 270.264 266.069 271.052 264.681C271.84 263.293 273.022 262.17 274.449 261.453C275.875 260.737 277.482 260.46 279.066 260.657C281.19 260.921 283.122 262.018 284.437 263.706C285.752 265.395 286.343 267.537 286.079 269.66Z'
                opacity='.1' />
          <path id='Vector_955' fill='white'
                d='M285.053 269.405C284.907 270.58 284.415 271.686 283.641 272.581C282.867 273.477 281.844 274.123 280.702 274.438C279.561 274.752 278.352 274.721 277.228 274.349C276.104 273.976 275.115 273.279 274.388 272.345C273.66 271.41 273.226 270.281 273.14 269.1C273.054 267.919 273.32 266.739 273.904 265.709C274.489 264.68 275.366 263.846 276.424 263.315C277.482 262.783 278.674 262.577 279.849 262.723C280.63 262.82 281.383 263.07 282.067 263.458C282.751 263.846 283.352 264.366 283.835 264.986C284.319 265.607 284.675 266.316 284.884 267.075C285.093 267.833 285.15 268.625 285.053 269.405Z'
                opacity='.2' />
          <path id='Vector_956' fill='white'
                d='M279.563 264.463C279.075 266.215 279.047 268.49 275.727 268.364C276.644 268.92 277.682 269.435 278.247 270.4C278.771 271.394 279.046 272.495 279.049 273.612C279.368 272.596 279.243 271.597 280.041 270.618C280.332 270.255 280.704 269.96 281.129 269.757C281.554 269.553 282.022 269.446 282.496 269.443C281.518 268.753 280.593 268.731 280.112 267.438C279.922 266.949 279.776 266.445 279.677 265.931C279.585 265.412 279.739 264.645 279.449 264.24L279.563 264.463Z'
                opacity='.7' />
          <path id='Vector_957' fill='white'
                d='M310.045 239.433C309.899 240.557 309.408 241.615 308.634 242.472C307.859 243.329 306.837 243.947 305.695 244.248C304.554 244.55 303.344 244.52 302.22 244.164C301.096 243.808 300.107 243.141 299.38 242.247C298.652 241.353 298.218 240.273 298.132 239.143C298.046 238.014 298.312 236.885 298.896 235.9C299.481 234.915 300.358 234.118 301.416 233.609C302.475 233.101 303.667 232.904 304.842 233.044C306.418 233.231 307.851 234.01 308.827 235.208C309.803 236.406 310.241 237.926 310.045 239.433Z'
                opacity='.1' />
          <path id='Vector_958' fill='white'
                d='M309.016 239.241C308.908 240.058 308.545 240.828 307.972 241.451C307.4 242.074 306.644 242.524 305.8 242.743C304.956 242.962 304.062 242.94 303.232 242.681C302.401 242.422 301.67 241.937 301.132 241.287C300.594 240.637 300.274 239.851 300.21 239.03C300.146 238.208 300.343 237.387 300.776 236.67C301.208 235.954 301.856 235.374 302.638 235.005C303.421 234.635 304.302 234.492 305.17 234.594C305.747 234.661 306.304 234.835 306.809 235.105C307.315 235.375 307.759 235.736 308.116 236.168C308.473 236.599 308.736 237.093 308.891 237.62C309.045 238.147 309.087 238.698 309.016 239.241Z'
                opacity='.2' />
          <path id='Vector_959' fill='white'
                d='M304.938 235.763C304.599 237.029 304.58 238.672 302.281 238.582C302.916 238.984 303.634 239.356 304.025 240.052C304.389 240.77 304.579 241.565 304.581 242.372C304.801 241.638 304.715 240.917 305.268 240.21C305.469 239.947 305.726 239.735 306.021 239.588C306.315 239.441 306.639 239.363 306.967 239.361C306.29 238.862 305.649 238.847 305.316 237.913C305.185 237.56 305.084 237.195 305.015 236.824C304.952 236.45 305.059 235.896 304.858 235.603L304.938 235.763Z'
                opacity='.7' />
          <path id='Vector_960' fill='white'
                d='M279.823 268.577C279.58 267.679 279.316 267.783 279.089 268.168C277.95 267.519 278.114 271.945 279.442 270.826C279.893 270.446 279.974 269.133 279.823 268.577Z' />
        </g>;


      case 8:
        return <g id='Mini gem_23'>
          <path id='Vector_961' fill={`#${diamondColors[color][0]}`}
                d='M533.018 254.686L561 280.789L540.187 300.28L533.018 255.618L506.331 285.906L535.607 328.62L540.187 300.28L533.018 254.686Z' />
          <path id='Vector_962' fill={`#${diamondColors[color][1]}`} d='M532.971 254.686L506.331 285.756L540.174 300.504L532.971 254.686Z' />
          <path id='Vector_963' fill='white'
                d='M532.972 254.686L532.228 255.553L539.024 298.786L507.091 284.87L506.331 285.756L540.174 300.504L532.972 254.686Z' />
          <path id='Vector_964' fill={`#${diamondColors[color][2]}`} d='M540.09 300.238L535.488 328.62L561 280.719L540.09 300.238Z' />
          <path id='Vector_965' fill={`#${diamondColors[color][2]}`} d='M540.09 300.238L535.488 328.62L561 280.719L540.09 300.238Z' />
          <path id='Vector_966' fill='white' d='M506.331 285.926L536.529 276.794L533.061 254.686L506.331 285.926Z'
                opacity='.2' />
          <path id='Vector_967' fill='white' d='M536.363 276.721L561 280.719L532.884 254.686L536.363 276.317V276.721Z'
                opacity='.2' />
          <path id='Vector_968' fill={`#${diamondColors[color][3]}`}
                d='M538.177 312.058C538.228 311.75 553.095 288.352 553.095 288.352L561 281.24L543.045 314.465L535.488 328.62L538.177 312.058Z'
                opacity='.5' />
          <path id='Vector_969' fill={`#${diamondColors[color][3]}`}
                d='M540.99 300.238L560.11 282.391L561 280.719L540.09 300.238L535.488 328.62L536.782 326.189L540.99 300.238Z' />
          <path id='Vector_970' fill={`#${diamondColors[color][3]}`}
                d='M516.178 300.305L539.653 300.504L506.331 285.926L516.178 300.305Z'
                opacity='.5' />
          <path id='Vector_971' fill='white'
                d='M507.893 285.384C508.919 286.437 511.445 286.585 512.875 286.86C515.854 287.503 518.926 287.613 521.944 287.183C525.601 286.593 528.473 284.985 531.312 282.723C533.166 281.246 533.899 279.794 534.676 277.694C534.906 277.157 535.087 276.601 535.216 276.033C536.151 276.529 536.027 279.697 536.295 280.751C536.822 282.826 537.396 284.841 537.613 286.962C538.024 290.473 538.618 293.96 539.39 297.41C539.536 298.116 539.995 299.029 539.19 299.384C538.553 299.667 537.42 299.111 536.871 298.91C535.24 298.314 533.979 297.286 532.408 296.545C530.882 295.824 529.269 295.44 527.73 294.719C526.1 293.956 524.508 293.266 522.941 292.381C520.542 291.027 517.879 289.968 515.369 288.909C514.108 288.377 512.784 287.874 511.585 287.326C510.635 286.892 508.521 286.392 507.992 285.481L507.893 285.384Z'
                opacity='.4' />
          <path id='Vector_972' fill='white'
                d='M544.722 308.702C550.019 305.234 551.502 298.129 548.034 292.831C544.566 287.534 537.46 286.052 532.163 289.52C526.866 292.988 525.383 300.094 528.851 305.391C532.32 310.688 539.425 312.17 544.722 308.702Z'
                opacity='.1' />
          <path id='Vector_973' fill='white'
                d='M547.918 299.742C547.708 301.428 547.003 303.014 545.892 304.299C544.781 305.585 543.314 306.512 541.676 306.964C540.038 307.415 538.303 307.371 536.69 306.837C535.077 306.302 533.659 305.301 532.615 303.961C531.571 302.62 530.948 301 530.824 299.306C530.701 297.611 531.083 295.918 531.922 294.44C532.761 292.963 534.019 291.767 535.537 291.005C537.056 290.242 538.766 289.947 540.452 290.157C542.713 290.438 544.77 291.605 546.17 293.403C547.57 295.2 548.199 297.481 547.918 299.742Z'
                opacity='.2' />
          <path id='Vector_974' fill='white'
                d='M540.279 293.014C539.602 295.546 539.563 298.831 534.967 298.651C536.237 299.455 537.673 300.199 538.456 301.592C539.182 303.029 539.563 304.618 539.567 306.231C540.007 304.764 539.835 303.321 540.941 301.907C541.343 301.382 541.857 300.957 542.446 300.663C543.035 300.368 543.682 300.214 544.339 300.209C542.985 299.213 541.704 299.181 541.038 297.315C540.775 296.608 540.573 295.879 540.436 295.137C540.311 294.388 540.522 293.279 540.12 292.694L540.279 293.014Z'
                opacity='.7' />
          <path id='Vector_975' fill='white'
                d='M541.123 299.23C540.798 298.182 540.447 298.303 540.144 298.752C538.625 297.993 538.844 303.159 540.614 301.853C541.214 301.411 541.324 299.88 541.123 299.23Z' />
          <path id='Vector_976' fill='white'
                d='M514.079 286.66C513.882 288.244 513.22 289.735 512.176 290.942C511.133 292.15 509.754 293.021 508.215 293.445C506.677 293.869 505.047 293.828 503.532 293.326C502.016 292.824 500.684 291.884 499.703 290.624C498.723 289.365 498.137 287.843 498.021 286.251C497.905 284.659 498.264 283.069 499.052 281.681C499.84 280.293 501.022 279.17 502.449 278.453C503.875 277.737 505.482 277.46 507.066 277.657C509.19 277.921 511.122 279.018 512.437 280.706C513.752 282.395 514.343 284.537 514.079 286.66Z'
                opacity='.1' />
          <path id='Vector_977' fill='white'
                d='M513.053 286.405C512.907 287.58 512.415 288.686 511.641 289.581C510.867 290.477 509.844 291.123 508.702 291.438C507.561 291.752 506.352 291.721 505.228 291.349C504.104 290.976 503.115 290.279 502.388 289.344C501.66 288.41 501.226 287.281 501.14 286.1C501.054 284.919 501.32 283.739 501.904 282.709C502.489 281.68 503.366 280.846 504.424 280.315C505.482 279.783 506.674 279.577 507.849 279.723C508.63 279.82 509.383 280.07 510.067 280.458C510.751 280.846 511.352 281.366 511.835 281.986C512.319 282.607 512.675 283.316 512.884 284.075C513.093 284.833 513.15 285.625 513.053 286.405Z'
                opacity='.2' />
          <path id='Vector_978' fill='white'
                d='M507.563 281.463C507.075 283.215 507.047 285.49 503.727 285.364C504.644 285.92 505.682 286.435 506.247 287.4C506.771 288.394 507.046 289.495 507.049 290.612C507.368 289.596 507.243 288.597 508.041 287.618C508.332 287.255 508.704 286.96 509.129 286.757C509.554 286.553 510.021 286.446 510.496 286.443C509.518 285.753 508.593 285.731 508.112 284.438C507.922 283.949 507.776 283.445 507.677 282.931C507.585 282.412 507.739 281.645 507.449 281.24L507.563 281.463Z'
                opacity='.7' />
          <path id='Vector_979' fill='white'
                d='M538.045 256.433C537.899 257.557 537.408 258.615 536.634 259.472C535.859 260.329 534.837 260.947 533.695 261.248C532.554 261.55 531.344 261.52 530.22 261.164C529.096 260.808 528.107 260.14 527.38 259.247C526.652 258.353 526.218 257.273 526.132 256.143C526.046 255.014 526.312 253.885 526.896 252.9C527.481 251.915 528.358 251.118 529.416 250.609C530.475 250.101 531.667 249.904 532.842 250.044C534.418 250.231 535.851 251.01 536.827 252.208C537.803 253.406 538.241 254.926 538.045 256.433Z'
                opacity='.1' />
          <path id='Vector_980' fill='white'
                d='M537.016 256.241C536.908 257.058 536.545 257.828 535.972 258.451C535.4 259.074 534.644 259.524 533.8 259.743C532.956 259.962 532.063 259.94 531.232 259.681C530.401 259.422 529.67 258.937 529.132 258.287C528.594 257.637 528.274 256.851 528.21 256.03C528.147 255.208 528.343 254.387 528.776 253.67C529.208 252.954 529.856 252.374 530.638 252.005C531.421 251.635 532.302 251.492 533.17 251.594C533.747 251.661 534.304 251.835 534.809 252.105C535.315 252.375 535.759 252.736 536.116 253.168C536.473 253.599 536.736 254.093 536.891 254.62C537.045 255.147 537.087 255.698 537.016 256.241Z'
                opacity='.2' />
          <path id='Vector_981' fill='white'
                d='M532.938 252.763C532.599 254.029 532.58 255.672 530.281 255.582C530.916 255.984 531.634 256.356 532.025 257.052C532.389 257.77 532.579 258.565 532.581 259.372C532.801 258.638 532.715 257.917 533.268 257.21C533.469 256.947 533.726 256.734 534.021 256.588C534.315 256.441 534.639 256.363 534.967 256.361C534.29 255.862 533.649 255.847 533.316 254.913C533.185 254.56 533.084 254.195 533.015 253.824C532.952 253.45 533.059 252.896 532.858 252.603L532.938 252.763Z'
                opacity='.7' />
          <path id='Vector_982' fill='white'
                d='M507.823 285.577C507.58 284.678 507.316 284.783 507.089 285.168C505.95 284.519 506.114 288.945 507.442 287.826C507.893 287.446 507.974 286.133 507.823 285.577Z' />
        </g>;


      case 9:
        return <g id='Mini gem_24'>
          <path id='Vector_983' fill={`#${diamondColors[color][0]}`}
                d='M480.764 173.236L518 207.971L490.305 233.907L480.764 174.476L445.251 214.78L484.209 271.62L490.305 233.907L480.764 173.236Z' />
          <path id='Vector_984' fill={`#${diamondColors[color][1]}`} d='M480.702 173.236L445.251 214.581L490.286 234.206L480.702 173.236Z' />
          <path id='Vector_985' fill='white'
                d='M480.703 173.236L479.713 174.389L488.756 231.92L446.262 213.402L445.251 214.581L490.286 234.206L480.703 173.236Z' />
          <path id='Vector_986' fill={`#${diamondColors[color][2]}`} d='M490.175 233.851L484.05 271.62L518 207.878L490.175 233.851Z' />
          <path id='Vector_987' fill={`#${diamondColors[color][2]}`} d='M490.175 233.851L484.05 271.62L518 207.878L490.175 233.851Z' />
          <path id='Vector_988' fill='white' d='M445.251 214.806L485.436 202.655L480.821 173.236L445.251 214.806Z'
                opacity='.2' />
          <path id='Vector_989' fill='white' d='M485.216 202.558L518 207.878L480.586 173.236L485.216 202.021V202.558Z'
                opacity='.2' />
          <path id='Vector_990' fill={`#${diamondColors[color][3]}`}
                d='M487.63 249.581C487.697 249.17 507.481 218.035 507.481 218.035L518 208.571L494.108 252.784L484.05 271.62L487.63 249.581Z'
                opacity='.5' />
          <path id='Vector_991' fill={`#${diamondColors[color][3]}`}
                d='M491.373 233.852L516.815 210.102L518 207.878L490.175 233.852L484.05 271.62L485.773 268.385L491.373 233.852Z' />
          <path id='Vector_992' fill={`#${diamondColors[color][3]}`}
                d='M458.355 233.941L489.593 234.206L445.251 214.806L458.355 233.941Z'
                opacity='.5' />
          <path id='Vector_993' fill='white'
                d='M447.33 214.086C448.696 215.486 452.057 215.684 453.96 216.05C457.924 216.906 462.012 217.051 466.028 216.479C470.894 215.695 474.717 213.555 478.494 210.544C480.961 208.579 481.936 206.647 482.97 203.852C483.276 203.138 483.517 202.399 483.69 201.642C484.934 202.302 484.769 206.518 485.125 207.921C485.826 210.682 486.59 213.363 486.879 216.186C487.426 220.857 488.216 225.497 489.243 230.088C489.438 231.028 490.049 232.243 488.978 232.716C488.13 233.092 486.623 232.353 485.891 232.085C483.721 231.291 482.042 229.924 479.953 228.938C477.922 227.979 475.776 227.468 473.727 226.508C471.559 225.493 469.439 224.574 467.355 223.397C464.162 221.594 460.619 220.185 457.279 218.776C455.601 218.068 453.838 217.399 452.243 216.67C450.979 216.093 448.166 215.427 447.461 214.215L447.33 214.086Z'
                opacity='.4' />
          <path id='Vector_994' fill='white'
                d='M496.339 245.115C503.388 240.5 505.361 231.045 500.746 223.996C496.131 216.947 486.676 214.974 479.627 219.589C472.578 224.204 470.605 233.66 475.22 240.709C479.835 247.758 489.29 249.731 496.339 245.115Z'
                opacity='.1' />
          <path id='Vector_995' fill='white'
                d='M500.591 233.191C500.313 235.435 499.375 237.546 497.896 239.256C496.418 240.967 494.465 242.201 492.285 242.802C490.106 243.403 487.797 243.344 485.651 242.633C483.504 241.921 481.617 240.59 480.228 238.806C478.839 237.022 478.009 234.866 477.845 232.611C477.681 230.356 478.189 228.103 479.306 226.137C480.422 224.171 482.096 222.58 484.117 221.565C486.137 220.55 488.413 220.158 490.657 220.437C493.666 220.811 496.403 222.365 498.266 224.756C500.129 227.148 500.965 230.183 500.591 233.191Z'
                opacity='.2' />
          <path id='Vector_996' fill='white'
                d='M490.426 224.24C489.526 227.608 489.474 231.98 483.358 231.74C485.047 232.81 486.959 233.8 488 235.654C488.967 237.566 489.473 239.681 489.478 241.827C490.065 239.875 489.836 237.955 491.307 236.073C491.842 235.375 492.527 234.808 493.31 234.417C494.094 234.026 494.955 233.819 495.829 233.814C494.027 232.488 492.322 232.446 491.436 229.962C491.086 229.021 490.818 228.051 490.635 227.064C490.469 226.067 490.75 224.592 490.215 223.813L490.426 224.24Z'
                opacity='.7' />
          <path id='Vector_997' fill='white'
                d='M491.549 232.511C491.116 231.116 490.65 231.278 490.247 231.874C488.226 230.865 488.517 237.74 490.873 236.002C491.671 235.413 491.817 233.375 491.549 232.511Z' />
          <path id='Vector_998' fill='white'
                d='M455.562 215.784C455.3 217.892 454.419 219.875 453.03 221.482C451.641 223.089 449.807 224.248 447.759 224.813C445.712 225.377 443.543 225.322 441.526 224.654C439.51 223.986 437.737 222.735 436.432 221.059C435.127 219.383 434.348 217.358 434.194 215.24C434.04 213.121 434.517 211.005 435.566 209.158C436.614 207.311 438.187 205.816 440.085 204.863C441.984 203.91 444.122 203.541 446.23 203.803C449.056 204.154 451.627 205.614 453.377 207.861C455.127 210.108 455.913 212.958 455.562 215.784Z'
                opacity='.1' />
          <path id='Vector_999' fill='white'
                d='M454.197 215.445C454.002 217.008 453.348 218.479 452.318 219.671C451.287 220.863 449.926 221.723 448.407 222.141C446.888 222.56 445.279 222.519 443.783 222.023C442.288 221.527 440.973 220.599 440.004 219.356C439.036 218.113 438.458 216.61 438.344 215.039C438.229 213.467 438.583 211.897 439.361 210.527C440.139 209.156 441.306 208.047 442.714 207.34C444.122 206.633 445.708 206.359 447.272 206.553C448.31 206.682 449.313 207.014 450.224 207.531C451.134 208.047 451.933 208.738 452.576 209.564C453.22 210.39 453.694 211.334 453.972 212.343C454.25 213.352 454.326 214.406 454.197 215.445Z'
                opacity='.2' />
          <path id='Vector_1000' fill='white'
                d='M446.892 208.868C446.241 211.2 446.204 214.226 441.787 214.059C443.007 214.799 444.387 215.485 445.139 216.768C445.838 218.092 446.204 219.556 446.207 221.042C446.631 219.69 446.466 218.361 447.527 217.058C447.914 216.575 448.409 216.183 448.975 215.912C449.541 215.641 450.163 215.498 450.794 215.494C449.493 214.576 448.261 214.547 447.621 212.827C447.369 212.176 447.175 211.505 447.042 210.821C446.92 210.131 447.126 209.11 446.739 208.571L446.892 208.868Z'
                opacity='.7' />
          <path id='Vector_1001' fill='white'
                d='M487.454 175.561C487.26 177.057 486.606 178.464 485.576 179.604C484.545 180.745 483.184 181.568 481.665 181.968C480.146 182.369 478.537 182.33 477.041 181.856C475.545 181.382 474.23 180.494 473.261 179.305C472.293 178.116 471.715 176.678 471.6 175.175C471.486 173.672 471.84 172.169 472.618 170.859C473.396 169.548 474.563 168.487 475.971 167.811C477.38 167.134 478.966 166.872 480.53 167.058C482.627 167.308 484.534 168.343 485.833 169.938C487.131 171.532 487.714 173.555 487.454 175.561Z'
                opacity='.1' />
          <path id='Vector_1002' fill='white'
                d='M486.084 175.305C485.94 176.393 485.457 177.416 484.696 178.246C483.934 179.075 482.928 179.673 481.805 179.965C480.682 180.256 479.493 180.228 478.387 179.883C477.281 179.538 476.309 178.892 475.593 178.027C474.878 177.162 474.451 176.117 474.366 175.023C474.282 173.93 474.544 172.838 475.119 171.884C475.694 170.931 476.557 170.16 477.598 169.668C478.639 169.176 479.811 168.986 480.967 169.121C481.734 169.211 482.476 169.442 483.148 169.801C483.821 170.16 484.411 170.641 484.886 171.215C485.362 171.789 485.712 172.446 485.917 173.148C486.123 173.85 486.179 174.582 486.084 175.305Z'
                opacity='.2' />
          <path id='Vector_1003' fill='white'
                d='M480.657 170.677C480.207 172.362 480.181 174.547 477.122 174.427C477.967 174.963 478.923 175.458 479.443 176.384C479.927 177.34 480.18 178.398 480.183 179.471C480.476 178.495 480.361 177.535 481.097 176.594C481.364 176.245 481.707 175.962 482.099 175.766C482.49 175.57 482.921 175.467 483.358 175.465C482.457 174.801 481.604 174.78 481.161 173.538C480.986 173.068 480.852 172.583 480.761 172.089C480.676 171.591 480.818 170.853 480.551 170.464L480.657 170.677Z'
                opacity='.7' />
          <path id='Vector_1004' fill='white'
                d='M447.237 214.343C446.913 213.147 446.563 213.286 446.261 213.798C444.745 212.934 444.963 218.824 446.73 217.335C447.33 216.83 447.438 215.083 447.237 214.343Z' />
        </g>;

      case 10:
        return <g id='Mini gem_25'>
          <path id='Vector_1005' fill={`#${diamondColors[color][0]}`}
                d='M614.797 322.058L645 350.233L622.536 371.27L614.797 323.064L585.992 355.755L617.592 401.859L622.536 371.27L614.797 322.058Z' />
          <path id='Vector_1006' fill={`#${diamondColors[color][1]}`} d='M614.747 322.058L585.992 355.594L622.521 371.512L614.747 322.058Z' />
          <path id='Vector_1007' fill='white'
                d='M614.747 322.058L613.945 322.993L621.28 369.658L586.812 354.637L585.992 355.594L622.521 371.512L614.747 322.058Z' />
          <path id='Vector_1008' fill={`#${diamondColors[color][2]}`} d='M622.43 371.225L617.463 401.859L645 350.157L622.43 371.225Z' />
          <path id='Vector_1009' fill={`#${diamondColors[color][2]}`} d='M622.43 371.225L617.463 401.859L645 350.157L622.43 371.225Z' />
          <path id='Vector_1010' fill='white' d='M585.992 355.777L618.587 345.921L614.844 322.058L585.992 355.777Z'
                opacity='.2' />
          <path id='Vector_1011' fill='white' d='M618.408 345.842L645 350.157L614.653 322.058L618.408 345.406V345.842Z'
                opacity='.2' />
          <path id='Vector_1012' fill={`#${diamondColors[color][3]}`}
                d='M620.366 383.983C620.42 383.65 636.468 358.396 636.468 358.396L645 350.719L625.62 386.581L617.463 401.859L620.366 383.983Z'
                opacity='.5' />
          <path id='Vector_1013' fill={`#${diamondColors[color][3]}`}
                d='M623.402 371.226L644.039 351.961L645 350.157L622.43 371.226L617.463 401.859L618.86 399.236L623.402 371.226Z' />
          <path id='Vector_1014' fill={`#${diamondColors[color][3]}`}
                d='M596.621 371.297L621.959 371.512L585.992 355.777L596.621 371.297Z'
                opacity='.5' />
          <path id='Vector_1015' fill='white'
                d='M587.678 355.192C588.786 356.328 591.512 356.489 593.056 356.785C596.271 357.48 599.587 357.598 602.844 357.134C606.791 356.497 609.892 354.762 612.956 352.32C614.957 350.725 615.748 349.158 616.586 346.892C616.835 346.312 617.03 345.713 617.17 345.099C618.179 345.634 618.045 349.054 618.334 350.192C618.903 352.431 619.523 354.606 619.757 356.896C620.201 360.685 620.841 364.448 621.675 368.172C621.832 368.934 622.328 369.92 621.459 370.303C620.772 370.609 619.549 370.009 618.956 369.792C617.195 369.148 615.834 368.039 614.139 367.239C612.492 366.461 610.751 366.047 609.089 365.268C607.33 364.445 605.611 363.7 603.92 362.745C601.331 361.283 598.457 360.14 595.748 358.997C594.387 358.423 592.957 357.88 591.663 357.289C590.638 356.82 588.356 356.28 587.785 355.297L587.678 355.192Z'
                opacity='.4' />
          <path id='Vector_1016' fill='white'
                d='M627.43 380.361C633.148 376.618 634.748 368.948 631.005 363.231C627.262 357.513 619.592 355.913 613.874 359.656C608.157 363.4 606.557 371.069 610.3 376.787C614.043 382.504 621.713 384.105 627.43 380.361Z'
                opacity='.1' />
          <path id='Vector_1017' fill='white'
                d='M630.88 370.689C630.653 372.509 629.893 374.221 628.693 375.609C627.494 376.996 625.91 377.997 624.142 378.485C622.374 378.972 620.501 378.924 618.761 378.347C617.02 377.77 615.489 376.69 614.362 375.243C613.235 373.796 612.563 372.048 612.429 370.219C612.296 368.39 612.709 366.562 613.614 364.967C614.52 363.372 615.878 362.082 617.517 361.259C619.155 360.436 621.002 360.117 622.822 360.344C625.262 360.647 627.482 361.907 628.993 363.848C630.504 365.788 631.183 368.249 630.88 370.689Z'
                opacity='.2' />
          <path id='Vector_1018' fill='white'
                d='M622.634 363.428C621.904 366.161 621.862 369.706 616.901 369.512C618.271 370.38 619.822 371.183 620.667 372.687C621.451 374.237 621.861 375.953 621.866 377.694C622.341 376.111 622.156 374.553 623.349 373.027C623.783 372.46 624.338 372.001 624.974 371.683C625.609 371.366 626.308 371.199 627.017 371.194C625.555 370.119 624.172 370.085 623.453 368.07C623.17 367.307 622.952 366.52 622.803 365.719C622.669 364.91 622.897 363.714 622.463 363.083L622.634 363.428Z'
                opacity='.7' />
          <path id='Vector_1019' fill='white'
                d='M623.545 370.138C623.194 369.006 622.815 369.137 622.489 369.621C620.849 368.802 621.086 374.378 622.996 372.969C623.644 372.492 623.762 370.838 623.545 370.138Z' />
          <path id='Vector_1020' fill='white'
                d='M594.355 356.57C594.143 358.28 593.428 359.888 592.301 361.192C591.175 362.495 589.687 363.435 588.026 363.893C586.365 364.351 584.606 364.306 582.971 363.764C581.335 363.222 579.897 362.208 578.839 360.849C577.78 359.489 577.148 357.847 577.023 356.128C576.898 354.41 577.285 352.693 578.136 351.195C578.986 349.697 580.262 348.485 581.802 347.711C583.341 346.938 585.076 346.639 586.785 346.852C589.078 347.137 591.163 348.321 592.583 350.143C594.002 351.966 594.64 354.278 594.355 356.57Z'
                opacity='.1' />
          <path id='Vector_1021' fill='white'
                d='M593.248 356.295C593.09 357.563 592.559 358.756 591.724 359.723C590.888 360.69 589.784 361.387 588.552 361.726C587.32 362.066 586.014 362.033 584.801 361.63C583.588 361.228 582.521 360.475 581.736 359.467C580.951 358.459 580.482 357.24 580.389 355.965C580.296 354.691 580.583 353.417 581.214 352.305C581.845 351.194 582.792 350.294 583.934 349.721C585.076 349.147 586.362 348.925 587.631 349.082C588.473 349.187 589.287 349.456 590.025 349.875C590.763 350.295 591.412 350.855 591.934 351.525C592.455 352.194 592.84 352.96 593.065 353.779C593.291 354.597 593.353 355.452 593.248 356.295Z'
                opacity='.2' />
          <path id='Vector_1022' fill='white'
                d='M587.322 350.96C586.795 352.852 586.765 355.306 583.182 355.171C584.171 355.771 585.291 356.327 585.901 357.368C586.467 358.442 586.764 359.629 586.767 360.835C587.111 359.738 586.977 358.66 587.838 357.604C588.152 357.211 588.553 356.893 589.012 356.674C589.471 356.454 589.976 356.338 590.488 356.335C589.432 355.59 588.433 355.566 587.914 354.171C587.709 353.643 587.552 353.099 587.445 352.544C587.346 351.985 587.512 351.156 587.199 350.719L587.322 350.96Z'
                opacity='.7' />
          <path id='Vector_1023' fill='white'
                d='M620.223 323.944C620.066 325.157 619.536 326.299 618.7 327.224C617.864 328.149 616.76 328.816 615.528 329.141C614.296 329.466 612.99 329.434 611.777 329.05C610.564 328.665 609.497 327.945 608.711 326.981C607.926 326.016 607.457 324.85 607.364 323.631C607.271 322.412 607.559 321.193 608.19 320.13C608.821 319.067 609.767 318.206 610.91 317.657C612.052 317.109 613.339 316.896 614.607 317.047C616.308 317.249 617.855 318.09 618.908 319.383C619.962 320.676 620.435 322.317 620.223 323.944Z'
                opacity='.1' />
          <path id='Vector_1024' fill='white'
                d='M619.112 323.736C618.996 324.619 618.604 325.449 617.986 326.122C617.368 326.794 616.552 327.28 615.642 327.516C614.731 327.752 613.766 327.729 612.869 327.449C611.972 327.17 611.184 326.646 610.603 325.944C610.023 325.243 609.676 324.395 609.608 323.508C609.539 322.621 609.751 321.735 610.218 320.962C610.685 320.188 611.384 319.563 612.229 319.164C613.073 318.765 614.024 318.611 614.962 318.72C615.584 318.793 616.185 318.981 616.731 319.272C617.276 319.564 617.755 319.953 618.141 320.419C618.526 320.885 618.81 321.418 618.977 321.987C619.144 322.556 619.19 323.15 619.112 323.736Z'
                opacity='.2' />
          <path id='Vector_1025' fill='white'
                d='M614.711 319.982C614.345 321.349 614.324 323.122 611.843 323.025C612.529 323.459 613.304 323.86 613.726 324.612C614.118 325.387 614.323 326.245 614.325 327.116C614.563 326.324 614.47 325.545 615.067 324.782C615.284 324.499 615.562 324.269 615.88 324.11C616.197 323.952 616.546 323.868 616.901 323.866C616.17 323.328 615.478 323.311 615.119 322.303C614.977 321.922 614.869 321.528 614.794 321.128C614.726 320.724 614.841 320.126 614.624 319.81L614.711 319.982Z'
                opacity='.7' />
          <path id='Vector_1026' fill='white'
                d='M587.603 355.401C587.34 354.431 587.056 354.544 586.811 354.959C585.581 354.258 585.758 359.036 587.191 357.828C587.678 357.418 587.766 356.001 587.603 355.401Z' />
        </g>;

      case 11:
        return <g id='Mini gem_9'>
          <path id='Vector_386' fill={`#${diamondColors[color][0]}`}
                d='M632.924 209.442L656.834 231.748L639.05 248.403L632.923 210.239L610.119 236.12L635.136 272.62L639.05 248.403L632.924 209.442Z' />
          <path id='Vector_387' fill={`#${diamondColors[color][1]}`}
                d='M632.884 209.442L610.119 235.992L639.038 248.595L632.884 209.442Z' />
          <path id='Vector_388' fill='white'
                d='M632.884 209.442L632.249 210.183L638.056 247.126L610.768 235.235L610.119 235.992L639.038 248.595L632.884 209.442Z' />
          <path id='Vector_389' fill={`#${diamondColors[color][2]}`}
                d='M638.966 248.367L635.034 272.62L656.834 231.688L638.966 248.367Z' />
          <path id='Vector_390' fill={`#${diamondColors[color][2]}`}
                d='M638.966 248.367L635.034 272.62L656.834 231.688L638.966 248.367Z' />
          <path id='Vector_391' fill='white' d='M610.119 236.137L635.924 228.334L632.96 209.442L610.119 236.137Z'
                opacity='.2' />
          <path id='Vector_392' fill='white'
                d='M635.782 228.271L656.834 231.688L632.809 209.442L635.782 227.927V228.271Z' opacity='.2' />
          <path id='Vector_393' fill={`#${diamondColors[color][3]}`}
                d='M637.332 258.468C637.375 258.204 650.08 238.21 650.08 238.21L656.834 232.133L641.492 260.524L635.034 272.62L637.332 258.468Z'
                opacity='.5' />
          <path id='Vector_394' fill={`#${diamondColors[color][3]}`}
                d='M639.736 248.367L656.074 233.116L656.834 231.688L638.966 248.367L635.034 272.62L636.14 270.543L639.736 248.367Z' />
          <path id='Vector_395' fill={`#${diamondColors[color][3]}`}
                d='M618.533 248.424L638.593 248.595L610.119 236.137L618.533 248.424Z' opacity='.5' />
          <path id='Vector_396' fill='white'
                d='M611.453 235.674C612.331 236.574 614.489 236.701 615.711 236.935C618.257 237.485 620.882 237.579 623.461 237.211C626.585 236.708 629.04 235.334 631.466 233.4C633.05 232.138 633.676 230.897 634.34 229.103C634.536 228.644 634.691 228.169 634.802 227.684C635.601 228.107 635.495 230.814 635.724 231.715C636.174 233.488 636.665 235.21 636.85 237.023C637.202 240.022 637.708 243.002 638.368 245.95C638.493 246.553 638.885 247.334 638.198 247.637C637.653 247.879 636.685 247.404 636.216 247.233C634.822 246.723 633.744 245.845 632.402 245.212C631.099 244.596 629.72 244.267 628.404 243.651C627.012 242.999 625.651 242.409 624.312 241.653C622.262 240.496 619.987 239.591 617.842 238.686C616.765 238.232 615.633 237.802 614.608 237.334C613.797 236.963 611.99 236.535 611.538 235.757L611.453 235.674Z'
                opacity='.4' />
          <path id='Vector_397' fill='white'
                d='M642.925 255.6C647.451 252.636 648.718 246.565 645.755 242.038C642.791 237.512 636.719 236.245 632.193 239.208C627.666 242.172 626.399 248.244 629.363 252.77C632.327 257.297 638.398 258.564 642.925 255.6Z'
                opacity='.1' />
          <path id='Vector_398' fill='white'
                d='M645.656 247.943C645.476 249.384 644.874 250.739 643.925 251.838C642.975 252.936 641.721 253.728 640.322 254.114C638.922 254.5 637.439 254.463 636.061 254.006C634.683 253.549 633.471 252.694 632.579 251.548C631.687 250.403 631.154 249.019 631.049 247.57C630.943 246.122 631.27 244.675 631.987 243.413C632.704 242.15 633.779 241.129 635.076 240.477C636.374 239.825 637.835 239.573 639.276 239.752C641.208 239.993 642.966 240.99 644.162 242.526C645.359 244.062 645.896 246.011 645.656 247.943Z'
                opacity='.2' />
          <path id='Vector_399' fill='white'
                d='M639.128 242.195C638.55 244.358 638.516 247.165 634.589 247.011C635.674 247.698 636.901 248.334 637.57 249.524C638.191 250.752 638.516 252.11 638.519 253.489C638.896 252.235 638.749 251.002 639.693 249.794C640.037 249.345 640.477 248.981 640.98 248.73C641.483 248.479 642.036 248.346 642.597 248.343C641.44 247.491 640.345 247.464 639.776 245.869C639.552 245.265 639.38 244.642 639.262 244.008C639.155 243.368 639.336 242.421 638.992 241.921L639.128 242.195Z'
                opacity='.7' />
          <path id='Vector_400' fill='white'
                d='M639.849 247.506C639.571 246.61 639.271 246.714 639.013 247.097C637.715 246.449 637.902 250.864 639.414 249.748C639.927 249.37 640.021 248.061 639.849 247.506Z' />
          <path id='Vector_401' fill='white'
                d='M616.74 236.765C616.571 238.118 616.006 239.392 615.114 240.424C614.222 241.456 613.044 242.2 611.729 242.563C610.414 242.925 609.021 242.89 607.727 242.461C606.432 242.032 605.294 241.228 604.456 240.152C603.617 239.076 603.117 237.776 603.018 236.415C602.919 235.055 603.226 233.696 603.899 232.51C604.572 231.324 605.583 230.364 606.801 229.752C608.02 229.14 609.393 228.903 610.747 229.071C612.562 229.297 614.213 230.234 615.337 231.677C616.46 233.12 616.965 234.95 616.74 236.765Z'
                opacity='.1' />
          <path id='Vector_402' fill='white'
                d='M615.863 236.547C615.738 237.551 615.318 238.495 614.656 239.261C613.995 240.026 613.121 240.578 612.145 240.847C611.17 241.116 610.137 241.09 609.176 240.771C608.216 240.453 607.371 239.857 606.749 239.058C606.128 238.26 605.756 237.295 605.683 236.286C605.609 235.277 605.837 234.269 606.336 233.389C606.836 232.509 607.585 231.797 608.489 231.342C609.393 230.888 610.412 230.712 611.416 230.837C612.083 230.92 612.727 231.133 613.312 231.465C613.896 231.797 614.41 232.24 614.823 232.771C615.236 233.301 615.54 233.907 615.719 234.555C615.897 235.203 615.946 235.88 615.863 236.547Z'
                opacity='.2' />
          <path id='Vector_403' fill='white'
                d='M611.172 232.323C610.754 233.821 610.731 235.764 607.894 235.657C608.677 236.133 609.564 236.573 610.047 237.397C610.495 238.247 610.73 239.187 610.733 240.141C611.005 239.273 610.899 238.42 611.58 237.583C611.829 237.273 612.146 237.021 612.51 236.847C612.873 236.673 613.273 236.581 613.678 236.579C612.843 235.989 612.052 235.97 611.64 234.866C611.478 234.448 611.354 234.017 611.269 233.578C611.191 233.135 611.322 232.479 611.074 232.133L611.172 232.323Z'
                opacity='.7' />
          <path id='Vector_404' fill='white'
                d='M637.219 210.935C637.095 211.896 636.675 212.8 636.013 213.532C635.351 214.264 634.478 214.793 633.502 215.05C632.526 215.307 631.493 215.282 630.532 214.978C629.572 214.673 628.727 214.103 628.105 213.34C627.484 212.576 627.112 211.653 627.039 210.688C626.965 209.722 627.193 208.758 627.692 207.916C628.192 207.074 628.941 206.393 629.846 205.959C630.75 205.524 631.769 205.356 632.773 205.475C634.119 205.635 635.344 206.301 636.178 207.325C637.012 208.349 637.387 209.647 637.219 210.935Z'
                opacity='.1' />
          <path id='Vector_405' fill='white'
                d='M636.34 210.771C636.247 211.47 635.937 212.127 635.448 212.659C634.959 213.192 634.313 213.576 633.592 213.763C632.871 213.95 632.107 213.932 631.397 213.711C630.687 213.489 630.063 213.075 629.603 212.519C629.143 211.964 628.869 211.292 628.815 210.59C628.761 209.888 628.929 209.187 629.298 208.574C629.668 207.962 630.221 207.467 630.89 207.151C631.558 206.835 632.311 206.713 633.054 206.8C633.546 206.858 634.022 207.006 634.454 207.237C634.886 207.468 635.265 207.776 635.571 208.145C635.876 208.514 636.101 208.935 636.233 209.386C636.365 209.837 636.401 210.307 636.34 210.771Z'
                opacity='.2' />
          <path id='Vector_406' fill='white'
                d='M632.855 207.799C632.566 208.881 632.549 210.284 630.585 210.208C631.127 210.551 631.741 210.869 632.075 211.464C632.386 212.078 632.548 212.757 632.55 213.446C632.738 212.82 632.665 212.203 633.137 211.599C633.309 211.375 633.529 211.193 633.78 211.067C634.032 210.942 634.308 210.875 634.589 210.874C634.01 210.448 633.463 210.434 633.178 209.636C633.066 209.334 632.98 209.023 632.921 208.706C632.867 208.386 632.958 207.912 632.786 207.663L632.855 207.799Z'
                opacity='.7' />
          <path id='Vector_407' fill='white'
                d='M611.394 235.839C611.186 235.071 610.961 235.161 610.767 235.49C609.794 234.935 609.934 238.717 611.068 237.761C611.453 237.436 611.523 236.315 611.394 235.839Z' />
        </g>;

      case 12:
        return <g id='Mini gem_10'>
          <path id='Vector_408' fill={`#${diamondColors[color][0]}`}
                d='M636.778 134.921L666.165 162.336L644.307 182.806L636.777 135.9L608.749 167.71L639.497 212.57L644.307 182.806L636.778 134.921Z' />
          <path id='Vector_409' fill={`#${diamondColors[color][1]}`}
                d='M636.729 134.921L608.749 167.553L644.293 183.042L636.729 134.921Z' />
          <path id='Vector_410' fill='white'
                d='M636.729 134.921L635.948 135.832L643.085 181.237L609.547 166.622L608.749 167.553L644.293 183.042L636.729 134.921Z' />
          <path id='Vector_411' fill={`#${diamondColors[color][2]}`}
                d='M644.205 182.762L639.371 212.57L666.165 162.263L644.205 182.762Z' />
          <path id='Vector_412' fill={`#${diamondColors[color][2]}`}
                d='M644.205 182.762L639.371 212.57L666.165 162.263L644.205 182.762Z' />
          <path id='Vector_413' fill='white' d='M608.749 167.731L640.465 158.141L636.823 134.921L608.749 167.731Z'
                opacity='.2' />
          <path id='Vector_414' fill='white'
                d='M640.291 158.064L666.166 162.263L636.637 134.921L640.291 157.64V158.064Z' opacity='.2' />
          <path id='Vector_415' fill={`#${diamondColors[color][3]}`}
                d='M642.196 195.176C642.249 194.852 657.864 170.279 657.864 170.279L666.165 162.809L647.309 197.704L639.371 212.57L642.196 195.176Z'
                opacity='.5' />
          <path id='Vector_416' fill={`#${diamondColors[color][3]}`}
                d='M645.15 182.763L665.231 164.018L666.165 162.263L644.205 182.763L639.371 212.57L640.73 210.017L645.15 182.763Z' />
          <path id='Vector_417' fill={`#${diamondColors[color][3]}`}
                d='M619.091 182.832L643.746 183.042L608.749 167.731L619.091 182.832Z' opacity='.5' />
          <path id='Vector_418' fill='white'
                d='M610.39 167.162C611.468 168.267 614.121 168.424 615.623 168.712C618.751 169.388 621.977 169.503 625.147 169.051C628.988 168.432 632.004 166.743 634.986 164.367C636.933 162.816 637.703 161.291 638.518 159.085C638.76 158.522 638.95 157.938 639.086 157.341C640.068 157.862 639.938 161.189 640.219 162.296C640.773 164.475 641.376 166.592 641.603 168.819C642.036 172.506 642.658 176.168 643.47 179.792C643.623 180.533 644.105 181.492 643.26 181.865C642.591 182.163 641.401 181.579 640.824 181.368C639.111 180.741 637.786 179.662 636.137 178.884C634.535 178.127 632.84 177.724 631.224 176.966C629.512 176.165 627.839 175.44 626.194 174.51C623.674 173.088 620.878 171.976 618.242 170.864C616.918 170.305 615.527 169.777 614.267 169.202C613.27 168.746 611.05 168.22 610.494 167.264L610.39 167.162Z'
                opacity='.4' />
          <path id='Vector_419' fill='white'
                d='M649.07 191.652C654.633 188.009 656.19 180.547 652.548 174.984C648.906 169.42 641.443 167.863 635.88 171.505C630.316 175.148 628.759 182.611 632.402 188.174C636.044 193.737 643.507 195.294 649.07 191.652Z'
                opacity='.1' />
          <path id='Vector_420' fill='white'
                d='M652.426 182.241C652.206 184.012 651.466 185.678 650.299 187.028C649.132 188.378 647.591 189.352 645.87 189.826C644.15 190.3 642.328 190.254 640.634 189.692C638.94 189.131 637.451 188.08 636.354 186.672C635.258 185.264 634.603 183.563 634.474 181.783C634.344 180.003 634.745 178.225 635.626 176.673C636.507 175.121 637.829 173.866 639.423 173.065C641.018 172.264 642.815 171.954 644.585 172.174C646.96 172.469 649.12 173.696 650.591 175.584C652.061 177.472 652.721 179.866 652.426 182.241Z'
                opacity='.2' />
          <path id='Vector_421' fill='white'
                d='M644.403 175.176C643.692 177.834 643.652 181.285 638.824 181.096C640.158 181.94 641.666 182.721 642.489 184.184C643.251 185.693 643.651 187.363 643.655 189.057C644.118 187.516 643.937 186.001 645.098 184.515C645.52 183.964 646.061 183.517 646.679 183.208C647.298 182.899 647.977 182.737 648.667 182.732C647.245 181.686 645.899 181.652 645.2 179.692C644.924 178.949 644.713 178.184 644.568 177.405C644.437 176.618 644.659 175.454 644.236 174.839L644.403 175.176Z'
                opacity='.7' />
          <path id='Vector_422' fill='white'
                d='M645.289 181.704C644.948 180.603 644.579 180.731 644.262 181.201C642.666 180.405 642.896 185.831 644.755 184.459C645.385 183.995 645.501 182.386 645.289 181.704Z' />
          <path id='Vector_423' fill='white'
                d='M616.887 168.503C616.68 170.166 615.985 171.731 614.888 172.999C613.792 174.268 612.345 175.183 610.729 175.628C609.113 176.074 607.401 176.03 605.809 175.503C604.218 174.975 602.819 173.988 601.789 172.666C600.759 171.343 600.144 169.745 600.022 168.073C599.901 166.401 600.277 164.73 601.105 163.273C601.933 161.815 603.174 160.635 604.672 159.883C606.17 159.131 607.858 158.84 609.521 159.046C611.752 159.324 613.781 160.476 615.162 162.249C616.544 164.023 617.164 166.272 616.887 168.503Z'
                opacity='.1' />
          <path id='Vector_424' fill='white'
                d='M615.81 168.235C615.656 169.469 615.14 170.629 614.326 171.57C613.513 172.511 612.439 173.189 611.24 173.52C610.041 173.85 608.771 173.818 607.591 173.426C606.41 173.035 605.372 172.303 604.608 171.321C603.844 170.34 603.388 169.154 603.298 167.914C603.207 166.674 603.487 165.435 604.101 164.353C604.714 163.271 605.635 162.396 606.747 161.838C607.858 161.28 609.11 161.064 610.344 161.217C611.164 161.319 611.955 161.581 612.674 161.989C613.392 162.396 614.023 162.942 614.531 163.593C615.038 164.245 615.412 164.99 615.632 165.787C615.851 166.583 615.912 167.415 615.81 168.235Z'
                opacity='.2' />
          <path id='Vector_425' fill='white'
                d='M610.044 163.044C609.531 164.884 609.501 167.273 606.015 167.141C606.978 167.725 608.068 168.266 608.661 169.279C609.212 170.324 609.501 171.479 609.504 172.652C609.838 171.585 609.708 170.536 610.546 169.508C610.851 169.127 611.241 168.817 611.688 168.603C612.135 168.39 612.626 168.277 613.124 168.274C612.097 167.549 611.125 167.526 610.62 166.169C610.42 165.655 610.268 165.125 610.163 164.585C610.067 164.041 610.229 163.235 609.924 162.809L610.044 163.044Z'
                opacity='.7' />
          <path id='Vector_426' fill='white'
                d='M642.057 136.756C641.904 137.937 641.388 139.048 640.575 139.948C639.762 140.848 638.688 141.497 637.489 141.814C636.29 142.13 635.02 142.099 633.839 141.725C632.658 141.351 631.62 140.65 630.856 139.711C630.092 138.773 629.635 137.639 629.545 136.452C629.455 135.266 629.734 134.08 630.348 133.045C630.962 132.011 631.883 131.174 632.995 130.64C634.106 130.106 635.358 129.899 636.593 130.046C638.247 130.243 639.753 131.06 640.778 132.319C641.803 133.577 642.263 135.173 642.057 136.756Z'
                opacity='.1' />
          <path id='Vector_427' fill='white'
                d='M640.976 136.554C640.863 137.413 640.481 138.221 639.88 138.875C639.279 139.53 638.485 140.002 637.599 140.232C636.713 140.462 635.774 140.44 634.901 140.167C634.029 139.895 633.261 139.386 632.697 138.703C632.132 138.02 631.795 137.195 631.728 136.332C631.661 135.469 631.868 134.607 632.322 133.855C632.776 133.102 633.457 132.494 634.278 132.105C635.1 131.717 636.025 131.567 636.938 131.674C637.543 131.745 638.128 131.927 638.659 132.211C639.19 132.494 639.656 132.874 640.031 133.327C640.406 133.78 640.682 134.298 640.845 134.852C641.007 135.406 641.051 135.984 640.976 136.554Z'
                opacity='.2' />
          <path id='Vector_428' fill='white'
                d='M636.693 132.902C636.338 134.232 636.318 135.957 633.903 135.862C634.57 136.285 635.324 136.675 635.735 137.406C636.117 138.161 636.317 138.996 636.318 139.843C636.55 139.072 636.46 138.315 637.04 137.572C637.251 137.296 637.522 137.073 637.831 136.918C638.14 136.764 638.48 136.683 638.824 136.681C638.114 136.157 637.44 136.141 637.091 135.16C636.953 134.789 636.847 134.406 636.775 134.017C636.708 133.623 636.82 133.041 636.609 132.734L636.693 132.902Z'
                opacity='.7' />
          <path id='Vector_429' fill='white'
                d='M610.317 167.365C610.061 166.421 609.784 166.531 609.546 166.935C608.35 166.253 608.522 170.902 609.917 169.727C610.39 169.328 610.475 167.949 610.317 167.365Z' />
        </g>;


      case 13:
        return <g id='Mini gem_11'>
          <path id='Vector_430' fill={`#${diamondColors[color][0]}`}
                d='M774.68 75.5703L796 95.4584L780.143 110.308L774.68 76.2803L754.347 99.3566L776.653 131.901L780.143 110.308L774.68 75.5703Z' />
          <path id='Vector_431' fill={`#${diamondColors[color][1]}`}
                d='M774.645 75.5703L754.347 99.2427L780.132 110.479L774.645 75.5703Z' />
          <path id='Vector_432' fill='white'
                d='M774.645 75.5703L774.079 76.2306L779.256 109.17L754.926 98.5676L754.347 99.2427L780.132 110.479L774.645 75.5703Z' />
          <path id='Vector_433' fill={`#${diamondColors[color][2]}`}
                d='M780.068 110.276L776.562 131.901L796 95.405L780.068 110.276Z' />
          <path id='Vector_434' fill={`#${diamondColors[color][2]}`}
                d='M780.068 110.276L776.562 131.901L796 95.405L780.068 110.276Z' />
          <path id='Vector_435' fill='white' d='M754.347 99.3719L777.355 92.4147L774.713 75.5703L754.347 99.3719Z'
                opacity='.2' />
          <path id='Vector_436' fill='white' d='M777.229 92.3588L796 95.405L774.579 75.5703L777.229 92.0513V92.3588Z'
                opacity='.2' />
          <path id='Vector_437' fill={`#${diamondColors[color][3]}`}
                d='M778.611 119.282C778.65 119.047 789.977 101.221 789.977 101.221L796 95.8016L782.32 121.116L776.562 131.901L778.611 119.282Z'
                opacity='.5' />
          <path id='Vector_438' fill={`#${diamondColors[color][3]}`}
                d='M780.754 110.277L795.322 96.6785L796 95.405L780.068 110.277L776.562 131.901L777.548 130.049L780.754 110.277Z' />
          <path id='Vector_439' fill={`#${diamondColors[color][3]}`}
                d='M761.85 110.327L779.736 110.479L754.347 99.3719L761.85 110.327Z' opacity='.5' />
          <path id='Vector_440' fill='white'
                d='M755.537 98.9593C756.32 99.7612 758.244 99.8746 759.333 100.084C761.603 100.574 763.944 100.657 766.243 100.33C769.029 99.8806 771.218 98.6555 773.381 96.9316C774.793 95.8062 775.351 94.7 775.943 93.0999C776.119 92.6912 776.256 92.2677 776.355 91.8347C777.068 92.2123 776.973 94.626 777.177 95.4295C777.579 97.0103 778.016 98.5456 778.181 100.162C778.495 102.836 778.947 105.493 779.535 108.122C779.646 108.659 779.996 109.355 779.383 109.626C778.898 109.842 778.035 109.418 777.616 109.265C776.373 108.81 775.412 108.028 774.216 107.463C773.053 106.914 771.824 106.621 770.651 106.072C769.41 105.49 768.196 104.964 767.003 104.29C765.175 103.258 763.146 102.452 761.234 101.645C760.273 101.239 759.264 100.856 758.35 100.439C757.627 100.108 756.016 99.727 755.613 99.0332L755.537 98.9593Z'
                opacity='.4' />
          <path id='Vector_441' fill='white'
                d='M783.598 116.726C787.634 114.083 788.764 108.669 786.121 104.633C783.479 100.598 778.065 99.4679 774.029 102.11C769.993 104.753 768.863 110.166 771.506 114.202C774.148 118.238 779.562 119.368 783.598 116.726Z'
                opacity='.1' />
          <path id='Vector_442' fill='white'
                d='M786.033 109.898C785.873 111.183 785.336 112.391 784.489 113.371C783.643 114.35 782.525 115.057 781.277 115.401C780.029 115.745 778.707 115.711 777.478 115.304C776.249 114.897 775.169 114.134 774.373 113.113C773.578 112.092 773.103 110.857 773.009 109.566C772.915 108.275 773.206 106.985 773.845 105.859C774.484 104.733 775.443 103.822 776.6 103.242C777.757 102.661 779.06 102.436 780.345 102.595C782.067 102.81 783.634 103.699 784.701 105.069C785.768 106.438 786.247 108.176 786.033 109.898Z'
                opacity='.2' />
          <path id='Vector_443' fill='white'
                d='M780.212 104.773C779.697 106.702 779.667 109.205 776.165 109.068C777.133 109.68 778.227 110.247 778.823 111.308C779.377 112.403 779.667 113.614 779.67 114.843C780.006 113.725 779.874 112.626 780.717 111.548C781.023 111.148 781.415 110.824 781.864 110.6C782.312 110.376 782.805 110.258 783.306 110.255C782.274 109.496 781.298 109.471 780.79 108.049C780.59 107.511 780.437 106.955 780.332 106.39C780.237 105.819 780.398 104.975 780.091 104.529L780.212 104.773Z'
                opacity='.7' />
          <path id='Vector_444' fill='white'
                d='M780.855 109.509C780.608 108.71 780.34 108.803 780.11 109.144C778.952 108.566 779.119 112.502 780.468 111.507C780.925 111.171 781.008 110.004 780.855 109.509Z' />
          <path id='Vector_445' fill='white'
                d='M760.251 99.9318C760.101 101.139 759.596 102.274 758.801 103.194C758.006 104.114 756.956 104.778 755.783 105.101C754.611 105.424 753.369 105.393 752.215 105.01C751.06 104.628 750.045 103.911 749.298 102.952C748.551 101.993 748.105 100.833 748.016 99.6201C747.928 98.4072 748.201 97.1953 748.802 96.1377C749.402 95.0802 750.303 94.2244 751.389 93.6787C752.476 93.1329 753.7 92.9217 754.907 93.0718C756.526 93.273 757.998 94.1088 759 95.3953C760.002 96.6817 760.452 98.3135 760.251 99.9318Z'
                opacity='.1' />
          <path id='Vector_446' fill='white'
                d='M759.469 99.7374C759.358 100.633 758.983 101.475 758.393 102.157C757.803 102.84 757.024 103.332 756.154 103.572C755.284 103.811 754.363 103.788 753.507 103.504C752.65 103.22 751.897 102.689 751.343 101.977C750.789 101.265 750.458 100.405 750.392 99.505C750.327 98.6051 750.529 97.7061 750.975 96.9215C751.42 96.1369 752.088 95.502 752.894 95.097C753.701 94.692 754.609 94.5352 755.504 94.6463C756.099 94.7201 756.673 94.9104 757.194 95.2062C757.715 95.502 758.173 95.8976 758.541 96.3704C758.91 96.8432 759.181 97.3838 759.34 97.9615C759.499 98.5393 759.543 99.1427 759.469 99.7374Z'
                opacity='.2' />
          <path id='Vector_447' fill='white'
                d='M755.286 95.9716C754.914 97.307 754.893 99.0397 752.364 98.9439C753.062 99.3679 753.853 99.7602 754.283 100.495C754.683 101.253 754.892 102.091 754.895 102.942C755.137 102.168 755.043 101.407 755.65 100.661C755.872 100.385 756.155 100.16 756.479 100.005C756.803 99.8498 757.159 99.768 757.521 99.7658C756.776 99.2402 756.071 99.2233 755.704 98.2387C755.56 97.8658 755.449 97.4815 755.373 97.0901C755.303 96.695 755.42 96.1104 755.199 95.8016L755.286 95.9716Z'
                opacity='.7' />
          <path id='Vector_448' fill='white'
                d='M778.511 76.9015C778.399 77.758 778.025 78.5637 777.435 79.2168C776.845 79.8698 776.066 80.3408 775.196 80.5702C774.326 80.7997 773.405 80.7773 772.549 80.5058C771.692 80.2344 770.939 79.7261 770.385 79.0452C769.83 78.3643 769.499 77.5415 769.434 76.6807C769.368 75.8199 769.571 74.9598 770.016 74.2093C770.462 73.4587 771.13 72.8514 771.936 72.4641C772.742 72.0768 773.651 71.9269 774.546 72.0333C775.747 72.1761 776.839 72.7692 777.582 73.6821C778.326 74.5951 778.66 75.7531 778.511 76.9015Z'
                opacity='.1' />
          <path id='Vector_449' fill='white'
                d='M777.726 76.7549C777.644 77.3778 777.367 77.9638 776.931 78.4388C776.495 78.9137 775.919 79.2562 775.276 79.423C774.633 79.5899 773.952 79.5735 773.319 79.376C772.686 79.1786 772.13 78.8089 771.72 78.3136C771.31 77.8184 771.066 77.22 771.017 76.5939C770.969 75.9679 771.119 75.3423 771.448 74.7965C771.777 74.2507 772.271 73.809 772.867 73.5274C773.463 73.2458 774.135 73.1369 774.797 73.2144C775.236 73.2658 775.66 73.3981 776.045 73.6038C776.43 73.8095 776.769 74.0847 777.041 74.4134C777.313 74.7422 777.513 75.1183 777.631 75.52C777.749 75.9218 777.781 76.3414 777.726 76.7549Z'
                opacity='.2' />
          <path id='Vector_450' fill='white'
                d='M774.619 74.1053C774.361 75.0699 774.347 76.3212 772.595 76.2526C773.079 76.5591 773.626 76.8424 773.924 77.373C774.201 77.9203 774.346 78.5259 774.347 79.1405C774.515 78.5816 774.45 78.0321 774.871 77.493C775.024 77.2931 775.22 77.131 775.444 77.019C775.669 76.9071 775.915 76.848 776.165 76.8465C775.65 76.4666 775.161 76.4547 774.907 75.7434C774.807 75.4741 774.731 75.1965 774.678 74.9138C774.63 74.6284 774.711 74.2063 774.558 73.9835L774.619 74.1053Z'
                opacity='.7' />
          <path id='Vector_451' fill='white'
                d='M755.484 99.1063C755.299 98.4217 755.098 98.5014 754.925 98.7947C754.057 98.2998 754.182 101.672 755.194 100.82C755.537 100.53 755.599 99.5302 755.484 99.1063Z' />
        </g>;


      case 14:
        return <g id='Mini gem_12'>
          <path id='Vector_452' fill={`#${diamondColors[color][0]}`}
                d='M826.807 133.524L853.821 158.724L833.728 177.541L826.807 134.424L801.042 163.664L829.306 204.901L833.728 177.541L826.807 133.524Z' />
          <path id='Vector_453' fill={`#${diamondColors[color][1]}`}
                d='M826.762 133.524L801.042 163.519L833.715 177.757L826.762 133.524Z' />
          <path id='Vector_454' fill='white'
                d='M826.762 133.524L826.045 134.361L832.605 176.099L801.776 162.664L801.042 163.519L833.715 177.757L826.762 133.524Z' />
          <path id='Vector_455' fill={`#${diamondColors[color][2]}`}
                d='M833.634 177.5L829.191 204.901L853.821 158.657L833.634 177.5Z' />
          <path id='Vector_456' fill={`#${diamondColors[color][2]}`}
                d='M833.634 177.5L829.191 204.901L853.821 158.657L833.634 177.5Z' />
          <path id='Vector_457' fill='white' d='M801.042 163.683L830.196 154.868L826.848 133.524L801.042 163.683Z'
                opacity='.2' />
          <path id='Vector_458' fill='white'
                d='M830.036 154.797L853.821 158.657L826.678 133.524L830.036 154.407V154.797Z' opacity='.2' />
          <path id='Vector_459' fill={`#${diamondColors[color][3]}`}
                d='M831.788 188.912C831.836 188.614 846.19 166.026 846.19 166.026L853.821 159.159L836.487 191.235L829.191 204.901L831.788 188.912Z'
                opacity='.5' />
          <path id='Vector_460' fill={`#${diamondColors[color][3]}`}
                d='M834.503 177.501L852.962 160.27L853.821 158.657L833.634 177.501L829.191 204.901L830.441 202.554L834.503 177.501Z' />
          <path id='Vector_461' fill={`#${diamondColors[color][3]}`}
                d='M810.549 177.565L833.212 177.757L801.042 163.683L810.549 177.565Z' opacity='.5' />
          <path id='Vector_462' fill='white'
                d='M802.55 163.16C803.542 164.176 805.98 164.32 807.361 164.585C810.237 165.206 813.202 165.312 816.116 164.897C819.646 164.328 822.419 162.775 825.16 160.591C826.95 159.165 827.657 157.763 828.407 155.736C828.629 155.218 828.804 154.681 828.929 154.133C829.832 154.611 829.712 157.67 829.971 158.688C830.479 160.691 831.034 162.636 831.243 164.684C831.64 168.073 832.213 171.439 832.959 174.77C833.099 175.452 833.543 176.333 832.766 176.676C832.151 176.949 831.057 176.413 830.526 176.219C828.952 175.643 827.734 174.651 826.218 173.935C824.745 173.24 823.188 172.869 821.702 172.173C820.128 171.436 818.591 170.769 817.078 169.915C814.762 168.608 812.191 167.585 809.768 166.563C808.551 166.05 807.272 165.564 806.115 165.035C805.198 164.616 803.157 164.133 802.646 163.254L802.55 163.16Z'
                opacity='.4' />
          <path id='Vector_463' fill='white'
                d='M838.106 185.672C843.22 182.324 844.652 175.464 841.304 170.35C837.955 165.236 831.096 163.805 825.982 167.153C820.868 170.501 819.436 177.361 822.784 182.475C826.133 187.589 832.993 189.02 838.106 185.672Z'
                opacity='.1' />
          <path id='Vector_464' fill='white'
                d='M841.191 177.021C840.989 178.649 840.309 180.18 839.236 181.421C838.163 182.662 836.747 183.558 835.165 183.994C833.584 184.43 831.909 184.387 830.352 183.871C828.795 183.355 827.426 182.389 826.418 181.095C825.41 179.8 824.808 178.236 824.689 176.6C824.57 174.964 824.939 173.33 825.749 171.903C826.559 170.477 827.773 169.323 829.239 168.586C830.705 167.85 832.356 167.565 833.984 167.768C836.167 168.039 838.153 169.166 839.504 170.902C840.856 172.637 841.463 174.838 841.191 177.021Z'
                opacity='.2' />
          <path id='Vector_465' fill='white'
                d='M833.817 170.527C833.163 172.971 833.126 176.142 828.688 175.969C829.914 176.745 831.301 177.463 832.057 178.808C832.758 180.195 833.125 181.729 833.129 183.287C833.554 181.87 833.388 180.477 834.456 179.112C834.844 178.605 835.341 178.194 835.909 177.91C836.477 177.627 837.102 177.477 837.736 177.473C836.429 176.511 835.192 176.48 834.549 174.678C834.295 173.996 834.101 173.292 833.968 172.576C833.848 171.852 834.052 170.782 833.663 170.218L833.817 170.527Z'
                opacity='.7' />
          <path id='Vector_466' fill='white'
                d='M834.631 176.528C834.317 175.515 833.979 175.633 833.687 176.065C832.22 175.334 832.431 180.321 834.14 179.06C834.72 178.633 834.825 177.155 834.631 176.528Z' />
          <path id='Vector_467' fill='white'
                d='M808.523 164.393C808.333 165.922 807.694 167.36 806.686 168.526C805.678 169.692 804.348 170.533 802.862 170.943C801.377 171.352 799.803 171.312 798.34 170.827C796.878 170.343 795.591 169.435 794.644 168.219C793.698 167.004 793.132 165.535 793.021 163.998C792.909 162.461 793.255 160.925 794.016 159.585C794.777 158.245 795.918 157.161 797.295 156.469C798.672 155.778 800.223 155.51 801.752 155.7C803.803 155.955 805.668 157.014 806.938 158.644C808.207 160.274 808.778 162.342 808.523 164.393Z'
                opacity='.1' />
          <path id='Vector_468' fill='white'
                d='M807.533 164.146C807.391 165.281 806.917 166.348 806.169 167.212C805.422 168.077 804.434 168.701 803.332 169.005C802.23 169.308 801.063 169.278 799.978 168.919C798.892 168.559 797.938 167.886 797.236 166.984C796.534 166.082 796.114 164.992 796.031 163.852C795.948 162.712 796.205 161.572 796.769 160.578C797.334 159.584 798.18 158.78 799.202 158.266C800.223 157.753 801.374 157.555 802.508 157.695C803.262 157.789 803.99 158.03 804.65 158.405C805.31 158.78 805.89 159.281 806.357 159.88C806.824 160.479 807.168 161.164 807.369 161.896C807.571 162.628 807.626 163.393 807.533 164.146Z'
                opacity='.2' />
          <path id='Vector_469' fill='white'
                d='M802.233 159.375C801.761 161.067 801.734 163.262 798.529 163.141C799.414 163.678 800.416 164.175 800.961 165.106C801.468 166.067 801.733 167.129 801.736 168.207C802.044 167.226 801.924 166.262 802.694 165.317C802.974 164.966 803.333 164.682 803.744 164.485C804.154 164.289 804.606 164.185 805.064 164.182C804.12 163.516 803.226 163.495 802.762 162.247C802.579 161.775 802.438 161.288 802.342 160.792C802.254 160.291 802.403 159.551 802.122 159.159L802.233 159.375Z'
                opacity='.7' />
          <path id='Vector_470' fill='white'
                d='M831.66 135.211C831.519 136.296 831.045 137.317 830.298 138.144C829.55 138.972 828.563 139.569 827.461 139.859C826.358 140.15 825.191 140.122 824.106 139.778C823.02 139.434 822.066 138.79 821.364 137.927C820.661 137.064 820.242 136.022 820.159 134.931C820.076 133.84 820.332 132.75 820.897 131.799C821.461 130.848 822.308 130.079 823.33 129.588C824.351 129.097 825.502 128.907 826.637 129.042C828.158 129.223 829.542 129.975 830.484 131.131C831.426 132.288 831.849 133.756 831.66 135.211Z'
                opacity='.1' />
          <path id='Vector_471' fill='white'
                d='M830.666 135.025C830.562 135.814 830.212 136.557 829.659 137.159C829.107 137.76 828.377 138.194 827.562 138.406C826.748 138.617 825.884 138.596 825.082 138.346C824.28 138.096 823.575 137.628 823.056 137C822.536 136.373 822.227 135.614 822.165 134.821C822.104 134.028 822.294 133.235 822.711 132.543C823.128 131.852 823.754 131.292 824.51 130.935C825.265 130.579 826.115 130.441 826.954 130.539C827.511 130.604 828.048 130.771 828.536 131.032C829.024 131.293 829.453 131.641 829.798 132.058C830.142 132.475 830.396 132.951 830.546 133.46C830.695 133.969 830.736 134.501 830.666 135.025Z'
                opacity='.2' />
          <path id='Vector_472' fill='white'
                d='M826.729 131.668C826.403 132.89 826.384 134.475 824.165 134.389C824.778 134.777 825.471 135.136 825.849 135.808C826.199 136.502 826.383 137.269 826.385 138.048C826.598 137.34 826.515 136.643 827.048 135.96C827.242 135.707 827.491 135.502 827.775 135.36C828.059 135.218 828.371 135.143 828.688 135.141C828.035 134.66 827.416 134.645 827.095 133.743C826.968 133.402 826.871 133.05 826.804 132.692C826.743 132.33 826.846 131.796 826.652 131.513L826.729 131.668Z'
                opacity='.7' />
          <path id='Vector_473' fill='white'
                d='M802.484 163.347C802.248 162.479 801.994 162.58 801.775 162.952C800.675 162.325 800.834 166.598 802.116 165.518C802.55 165.151 802.629 163.884 802.484 163.347Z' />
        </g>;


      case 15:
        return <g id='Mini gem_13'>
          <path id='Vector_474' fill={`#${diamondColors[color][0]}`}
                d='M912.37 237.001L942.232 264.857L920.021 285.657L912.37 237.995L883.89 270.317L915.133 315.901L920.021 285.657L912.37 237.001Z' />
          <path id='Vector_475' fill={`#${diamondColors[color][1]}`}
                d='M912.321 237.001L883.89 270.158L920.006 285.897L912.321 237.001Z' />
          <path id='Vector_476' fill='white'
                d='M912.321 237.001L911.528 237.926L918.78 284.063L884.701 269.212L883.89 270.158L920.006 285.897L912.321 237.001Z' />
          <path id='Vector_477' fill={`#${diamondColors[color][2]}`}
                d='M919.917 285.612L915.006 315.901L942.232 264.782L919.917 285.612Z' />
          <path id='Vector_478' fill={`#${diamondColors[color][2]}`}
                d='M919.917 285.612L915.006 315.901L942.232 264.782L919.917 285.612Z' />
          <path id='Vector_479' fill='white' d='M883.89 270.339L916.117 260.594L912.416 237.001L883.89 270.339Z'
                opacity='.2' />
          <path id='Vector_480' fill='white' d='M915.94 260.516L942.232 264.782L912.228 237.001L915.94 260.085V260.516Z'
                opacity='.2' />
          <path id='Vector_481' fill={`#${diamondColors[color][3]}`}
                d='M917.876 298.226C917.93 297.897 933.796 272.928 933.796 272.928L942.232 265.338L923.071 300.795L915.006 315.901L917.876 298.226Z'
                opacity='.5' />
          <path id='Vector_482' fill={`#${diamondColors[color][3]}`}
                d='M920.878 285.613L941.282 266.566L942.232 264.782L919.917 285.613L915.006 315.901L916.387 313.307L920.878 285.613Z' />
          <path id='Vector_483' fill={`#${diamondColors[color][3]}`}
                d='M894.399 285.684L919.451 285.897L883.89 270.339L894.399 285.684Z' opacity='.5' />
          <path id='Vector_484' fill='white'
                d='M885.557 269.761C886.653 270.884 889.348 271.043 890.874 271.336C894.054 272.023 897.332 272.139 900.552 271.68C904.455 271.051 907.52 269.335 910.55 266.921C912.528 265.344 913.31 263.795 914.139 261.554C914.385 260.981 914.578 260.388 914.716 259.782C915.714 260.311 915.582 263.691 915.867 264.817C916.43 267.031 917.043 269.181 917.274 271.445C917.713 275.191 918.346 278.912 919.17 282.594C919.326 283.348 919.816 284.322 918.957 284.701C918.277 285.003 917.068 284.41 916.482 284.196C914.741 283.559 913.395 282.463 911.72 281.672C910.091 280.903 908.37 280.493 906.727 279.723C904.988 278.909 903.288 278.172 901.616 277.228C899.056 275.782 896.214 274.652 893.536 273.523C892.19 272.955 890.777 272.418 889.497 271.834C888.484 271.37 886.228 270.836 885.663 269.864L885.557 269.761Z'
                opacity='.4' />
          <path id='Vector_485' fill='white'
                d='M924.861 294.645C930.514 290.944 932.096 283.361 928.395 277.708C924.694 272.056 917.111 270.473 911.458 274.174C905.805 277.875 904.223 285.458 907.924 291.111C911.625 296.764 919.208 298.346 924.861 294.645Z'
                opacity='.1' />
          <path id='Vector_486' fill='white'
                d='M928.271 285.083C928.047 286.882 927.295 288.575 926.109 289.947C924.924 291.319 923.358 292.308 921.61 292.79C919.862 293.272 918.01 293.225 916.289 292.654C914.568 292.084 913.054 291.016 911.94 289.585C910.826 288.155 910.161 286.426 910.029 284.618C909.898 282.809 910.305 281.002 911.2 279.425C912.096 277.848 913.438 276.573 915.059 275.759C916.679 274.945 918.505 274.63 920.304 274.854C922.717 275.154 924.912 276.4 926.406 278.318C927.9 280.237 928.571 282.67 928.271 285.083Z'
                opacity='.2' />
          <path id='Vector_487' fill='white'
                d='M920.119 277.904C919.396 280.605 919.355 284.111 914.45 283.919C915.805 284.777 917.338 285.571 918.173 287.058C918.948 288.591 919.354 290.287 919.359 292.009C919.829 290.443 919.645 288.903 920.825 287.394C921.254 286.834 921.803 286.38 922.432 286.066C923.06 285.752 923.751 285.586 924.451 285.582C923.006 284.519 921.639 284.485 920.928 282.493C920.648 281.738 920.433 280.961 920.286 280.168C920.153 279.369 920.379 278.186 919.949 277.562L920.119 277.904Z'
                opacity='.7' />
          <path id='Vector_488' fill='white'
                d='M921.019 284.537C920.672 283.418 920.298 283.548 919.975 284.026C918.354 283.217 918.588 288.73 920.477 287.336C921.117 286.865 921.234 285.23 921.019 284.537Z' />
          <path id='Vector_489' fill='white'
                d='M892.159 271.123C891.949 272.813 891.242 274.404 890.128 275.692C889.015 276.981 887.544 277.911 885.902 278.363C884.26 278.816 882.52 278.772 880.903 278.236C879.286 277.7 877.865 276.697 876.818 275.353C875.771 274.009 875.146 272.385 875.023 270.686C874.899 268.988 875.282 267.29 876.123 265.809C876.964 264.328 878.225 263.129 879.748 262.365C881.27 261.6 882.984 261.304 884.675 261.514C886.941 261.796 889.003 262.967 890.407 264.769C891.81 266.571 892.44 268.856 892.159 271.123Z'
                opacity='.1' />
          <path id='Vector_490' fill='white'
                d='M891.064 270.851C890.908 272.105 890.384 273.284 889.557 274.24C888.731 275.196 887.639 275.885 886.421 276.221C885.203 276.557 883.913 276.524 882.713 276.126C881.514 275.729 880.459 274.984 879.682 273.987C878.906 272.99 878.442 271.785 878.351 270.525C878.259 269.265 878.543 268.006 879.167 266.907C879.79 265.808 880.726 264.918 881.855 264.351C882.985 263.784 884.257 263.564 885.511 263.72C886.344 263.823 887.148 264.09 887.878 264.504C888.608 264.918 889.249 265.472 889.765 266.135C890.281 266.797 890.661 267.554 890.884 268.363C891.107 269.173 891.168 270.018 891.064 270.851Z'
                opacity='.2' />
          <path id='Vector_491' fill='white'
                d='M885.206 265.576C884.684 267.447 884.654 269.873 881.112 269.739C882.09 270.333 883.198 270.883 883.801 271.912C884.361 272.973 884.654 274.148 884.657 275.339C884.997 274.255 884.864 273.19 885.716 272.145C886.026 271.757 886.423 271.443 886.876 271.225C887.33 271.008 887.829 270.894 888.335 270.891C887.292 270.154 886.304 270.131 885.791 268.752C885.588 268.229 885.433 267.691 885.327 267.143C885.229 266.589 885.394 265.771 885.084 265.338L885.206 265.576Z'
                opacity='.7' />
          <path id='Vector_492' fill='white'
                d='M917.735 238.865C917.579 240.065 917.055 241.194 916.229 242.108C915.402 243.023 914.311 243.683 913.093 244.004C911.875 244.325 910.584 244.294 909.384 243.914C908.185 243.534 907.13 242.822 906.353 241.868C905.577 240.914 905.113 239.762 905.021 238.556C904.929 237.35 905.213 236.146 905.837 235.094C906.461 234.043 907.397 233.193 908.526 232.65C909.656 232.108 910.928 231.898 912.182 232.047C913.864 232.247 915.394 233.077 916.435 234.356C917.476 235.635 917.944 237.257 917.735 238.865Z'
                opacity='.1' />
          <path id='Vector_493' fill='white'
                d='M916.636 238.66C916.521 239.533 916.134 240.353 915.523 241.019C914.912 241.684 914.106 242.163 913.205 242.397C912.305 242.631 911.351 242.608 910.464 242.331C909.577 242.055 908.797 241.537 908.223 240.843C907.65 240.15 907.307 239.311 907.239 238.435C907.171 237.558 907.381 236.681 907.843 235.917C908.304 235.152 908.996 234.534 909.831 234.139C910.665 233.745 911.606 233.592 912.533 233.701C913.148 233.773 913.743 233.958 914.282 234.246C914.821 234.535 915.295 234.92 915.676 235.38C916.057 235.841 916.338 236.368 916.503 236.93C916.668 237.493 916.713 238.081 916.636 238.66Z'
                opacity='.2' />
          <path id='Vector_494' fill='white'
                d='M912.284 234.949C911.923 236.3 911.903 238.053 909.449 237.956C910.127 238.386 910.893 238.783 911.311 239.526C911.699 240.292 911.902 241.141 911.904 242.001C912.139 241.219 912.047 240.449 912.637 239.694C912.851 239.414 913.126 239.187 913.44 239.03C913.754 238.873 914.1 238.79 914.45 238.788C913.728 238.256 913.044 238.24 912.688 237.243C912.548 236.866 912.441 236.477 912.367 236.081C912.3 235.681 912.414 235.09 912.199 234.778L912.284 234.949Z'
                opacity='.7' />
          <path id='Vector_495' fill='white'
                d='M885.483 269.967C885.223 269.008 884.942 269.119 884.7 269.53C883.484 268.837 883.659 273.561 885.076 272.367C885.557 271.961 885.644 270.56 885.483 269.967Z' />
        </g>;


      case 16:
        return <g id='Mini gem_14'>
          <path id='Vector_496' fill={`#${diamondColors[color][0]}`}
                d='M812.37 266.001L842.232 293.857L820.021 314.657L812.37 266.995L783.89 299.317L815.133 344.901L820.021 314.657L812.37 266.001Z' />
          <path id='Vector_497' fill={`#${diamondColors[color][1]}`}
                d='M812.32 266.001L783.89 299.158L820.006 314.897L812.32 266.001Z' />
          <path id='Vector_498' fill='white'
                d='M812.321 266.001L811.528 266.926L818.78 313.063L784.701 298.212L783.89 299.158L820.006 314.897L812.321 266.001Z' />
          <path id='Vector_499' fill={`#${diamondColors[color][2]}`}
                d='M819.917 314.612L815.006 344.901L842.232 293.782L819.917 314.612Z' />
          <path id='Vector_500' fill={`#${diamondColors[color][2]}`}
                d='M819.917 314.612L815.006 344.901L842.232 293.782L819.917 314.612Z' />
          <path id='Vector_501' fill='white' d='M783.89 299.339L816.117 289.594L812.416 266.001L783.89 299.339Z'
                opacity='.2' />
          <path id='Vector_502' fill='white' d='M815.94 289.516L842.232 293.782L812.228 266.001L815.94 289.085V289.516Z'
                opacity='.2' />
          <path id='Vector_503' fill={`#${diamondColors[color][3]}`}
                d='M817.876 327.226C817.93 326.897 833.796 301.928 833.796 301.928L842.232 294.338L823.071 329.795L815.006 344.901L817.876 327.226Z'
                opacity='.5' />
          <path id='Vector_504' fill={`#${diamondColors[color][3]}`}
                d='M820.878 314.613L841.282 295.566L842.232 293.782L819.917 314.613L815.006 344.901L816.387 342.307L820.878 314.613Z' />
          <path id='Vector_505' fill={`#${diamondColors[color][3]}`}
                d='M794.399 314.684L819.451 314.897L783.89 299.339L794.399 314.684Z' opacity='.5' />
          <path id='Vector_506' fill='white'
                d='M785.557 298.761C786.653 299.884 789.348 300.043 790.874 300.336C794.054 301.023 797.332 301.139 800.552 300.68C804.455 300.051 807.52 298.335 810.55 295.921C812.528 294.344 813.31 292.795 814.139 290.554C814.385 289.981 814.578 289.388 814.716 288.782C815.714 289.311 815.582 292.691 815.867 293.817C816.43 296.031 817.043 298.181 817.274 300.445C817.713 304.191 818.346 307.912 819.17 311.594C819.326 312.348 819.816 313.322 818.957 313.701C818.277 314.003 817.068 313.41 816.482 313.196C814.741 312.559 813.395 311.463 811.72 310.672C810.091 309.903 808.37 309.493 806.727 308.723C804.988 307.909 803.288 307.172 801.616 306.228C799.056 304.782 796.214 303.652 793.536 302.523C792.19 301.955 790.777 301.418 789.497 300.834C788.484 300.37 786.228 299.836 785.663 298.864L785.557 298.761Z'
                opacity='.4' />
          <path id='Vector_507' fill='white'
                d='M824.861 323.645C830.514 319.944 832.096 312.361 828.395 306.708C824.694 301.056 817.111 299.473 811.458 303.174C805.805 306.875 804.223 314.458 807.924 320.111C811.625 325.764 819.208 327.346 824.861 323.645Z'
                opacity='.1' />
          <path id='Vector_508' fill='white'
                d='M828.271 314.083C828.047 315.882 827.295 317.575 826.109 318.947C824.924 320.319 823.358 321.308 821.61 321.79C819.862 322.272 818.01 322.225 816.289 321.654C814.568 321.084 813.054 320.016 811.94 318.585C810.826 317.155 810.161 315.426 810.029 313.618C809.898 311.809 810.305 310.002 811.2 308.425C812.096 306.848 813.438 305.573 815.059 304.759C816.679 303.945 818.505 303.63 820.304 303.854C822.717 304.154 824.912 305.4 826.406 307.318C827.9 309.237 828.571 311.67 828.271 314.083Z'
                opacity='.2' />
          <path id='Vector_509' fill='white'
                d='M820.119 306.904C819.396 309.605 819.355 313.111 814.45 312.919C815.805 313.777 817.338 314.571 818.173 316.058C818.948 317.591 819.354 319.287 819.359 321.009C819.829 319.443 819.645 317.903 820.825 316.394C821.254 315.834 821.803 315.38 822.432 315.066C823.06 314.752 823.751 314.586 824.451 314.582C823.006 313.519 821.639 313.485 820.928 311.493C820.648 310.738 820.433 309.961 820.286 309.168C820.153 308.369 820.379 307.186 819.949 306.562L820.119 306.904Z'
                opacity='.7' />
          <path id='Vector_510' fill='white'
                d='M821.019 313.537C820.672 312.418 820.298 312.548 819.975 313.026C818.354 312.217 818.588 317.73 820.477 316.336C821.117 315.865 821.234 314.23 821.019 313.537Z' />
          <path id='Vector_511' fill='white'
                d='M792.159 300.123C791.949 301.813 791.242 303.404 790.128 304.692C789.015 305.981 787.544 306.911 785.902 307.363C784.26 307.816 782.52 307.772 780.903 307.236C779.286 306.7 777.865 305.697 776.818 304.353C775.771 303.009 775.146 301.385 775.023 299.686C774.899 297.988 775.282 296.29 776.123 294.809C776.964 293.328 778.225 292.129 779.748 291.365C781.27 290.6 782.984 290.304 784.675 290.514C786.941 290.796 789.003 291.967 790.407 293.769C791.81 295.571 792.44 297.856 792.159 300.123Z'
                opacity='.1' />
          <path id='Vector_512' fill='white'
                d='M791.064 299.851C790.908 301.105 790.384 302.284 789.557 303.24C788.731 304.196 787.639 304.885 786.421 305.221C785.203 305.557 783.913 305.524 782.713 305.126C781.514 304.729 780.459 303.984 779.682 302.987C778.906 301.99 778.442 300.785 778.351 299.525C778.259 298.265 778.543 297.006 779.167 295.907C779.79 294.808 780.726 293.918 781.855 293.351C782.985 292.784 784.257 292.564 785.511 292.72C786.344 292.823 787.148 293.09 787.878 293.504C788.608 293.918 789.249 294.472 789.765 295.135C790.281 295.797 790.661 296.554 790.884 297.363C791.107 298.173 791.168 299.018 791.064 299.851Z'
                opacity='.2' />
          <path id='Vector_513' fill='white'
                d='M785.206 294.576C784.684 296.447 784.654 298.873 781.112 298.739C782.09 299.333 783.198 299.883 783.801 300.912C784.361 301.973 784.654 303.148 784.657 304.339C784.997 303.255 784.864 302.19 785.716 301.145C786.026 300.757 786.423 300.443 786.876 300.225C787.33 300.008 787.829 299.894 788.335 299.891C787.292 299.154 786.304 299.131 785.791 297.752C785.588 297.229 785.433 296.691 785.327 296.143C785.229 295.589 785.394 294.771 785.084 294.338L785.206 294.576Z'
                opacity='.7' />
          <path id='Vector_514' fill='white'
                d='M817.735 267.865C817.579 269.065 817.055 270.194 816.229 271.108C815.402 272.023 814.311 272.683 813.093 273.004C811.875 273.325 810.584 273.294 809.384 272.914C808.185 272.534 807.13 271.822 806.353 270.868C805.577 269.914 805.113 268.762 805.021 267.556C804.929 266.35 805.213 265.146 805.837 264.094C806.461 263.043 807.397 262.193 808.526 261.65C809.656 261.108 810.928 260.898 812.182 261.047C813.864 261.247 815.394 262.077 816.435 263.356C817.476 264.635 817.944 266.257 817.735 267.865Z'
                opacity='.1' />
          <path id='Vector_515' fill='white'
                d='M816.636 267.66C816.521 268.533 816.134 269.353 815.523 270.019C814.912 270.684 814.106 271.163 813.205 271.397C812.305 271.631 811.351 271.608 810.464 271.331C809.577 271.055 808.797 270.537 808.223 269.843C807.65 269.15 807.307 268.311 807.239 267.435C807.171 266.558 807.381 265.681 807.843 264.917C808.304 264.152 808.996 263.534 809.831 263.139C810.665 262.745 811.606 262.592 812.533 262.701C813.148 262.773 813.743 262.958 814.282 263.246C814.821 263.535 815.295 263.92 815.676 264.38C816.057 264.841 816.338 265.368 816.503 265.93C816.668 266.493 816.713 267.081 816.636 267.66Z'
                opacity='.2' />
          <path id='Vector_516' fill='white'
                d='M812.284 263.949C811.923 265.3 811.903 267.053 809.449 266.956C810.127 267.386 810.893 267.783 811.311 268.526C811.699 269.292 811.902 270.141 811.904 271.001C812.139 270.219 812.047 269.449 812.637 268.694C812.851 268.414 813.126 268.187 813.44 268.03C813.754 267.873 814.1 267.79 814.45 267.788C813.728 267.256 813.044 267.24 812.688 266.243C812.548 265.866 812.441 265.477 812.367 265.081C812.3 264.681 812.414 264.09 812.199 263.778L812.284 263.949Z'
                opacity='.7' />
          <path id='Vector_517' fill='white'
                d='M785.483 298.967C785.223 298.008 784.942 298.119 784.7 298.53C783.484 297.837 783.659 302.561 785.076 301.367C785.557 300.961 785.644 299.56 785.483 298.967Z' />
        </g>;


      case 17:
        return <g id='Mini gem_15'>
          <path id='Vector_518' fill={`#${diamondColors[color][0]}`}
                d='M706.016 268.017L730 290.391L712.161 307.097L706.015 268.815L683.14 294.776L708.235 331.388L712.161 307.097L706.016 268.017Z' />
          <path id='Vector_519' fill={`#${diamondColors[color][1]}`}
                d='M705.976 268.017L683.14 294.648L712.149 307.289L705.976 268.017Z' />
          <path id='Vector_520' fill='white'
                d='M705.976 268.017L705.339 268.759L711.163 305.816L683.792 293.889L683.14 294.648L712.149 307.289L705.976 268.017Z' />
          <path id='Vector_521' fill={`#${diamondColors[color][2]}`}
                d='M712.077 307.061L708.132 331.388L730 290.331L712.077 307.061Z' />
          <path id='Vector_522' fill={`#${diamondColors[color][2]}`}
                d='M712.077 307.061L708.132 331.388L730 290.331L712.077 307.061Z' />
          <path id='Vector_523' fill='white' d='M683.14 294.793L709.025 286.967L706.052 268.017L683.14 294.793Z'
                opacity='.2' />
          <path id='Vector_524' fill='white' d='M708.883 286.904L730 290.331L705.901 268.017L708.883 286.558V286.904Z'
                opacity='.2' />
          <path id='Vector_525' fill={`#${diamondColors[color][3]}`}
                d='M710.438 317.193C710.481 316.928 723.224 296.873 723.224 296.873L730 290.777L714.61 319.256L708.132 331.388L710.438 317.193Z'
                opacity='.5' />
          <path id='Vector_526' fill={`#${diamondColors[color][3]}`}
                d='M712.849 307.061L729.237 291.763L730 290.331L712.077 307.061L708.132 331.388L709.242 329.305L712.849 307.061Z' />
          <path id='Vector_527' fill={`#${diamondColors[color][3]}`}
                d='M691.581 307.118L711.702 307.289L683.14 294.793L691.581 307.118Z' opacity='.5' />
          <path id='Vector_528' fill='white'
                d='M684.479 294.329C685.359 295.231 687.525 295.359 688.75 295.594C691.304 296.146 693.937 296.239 696.524 295.871C699.658 295.366 702.12 293.987 704.553 292.048C706.142 290.782 706.77 289.538 707.436 287.737C707.633 287.278 707.788 286.801 707.9 286.314C708.701 286.739 708.595 289.454 708.824 290.358C709.276 292.137 709.768 293.864 709.954 295.682C710.307 298.691 710.815 301.68 711.477 304.637C711.602 305.242 711.996 306.025 711.306 306.329C710.76 306.572 709.789 306.095 709.318 305.923C707.92 305.412 706.839 304.531 705.493 303.896C704.185 303.278 702.802 302.949 701.483 302.331C700.086 301.677 698.721 301.085 697.378 300.327C695.322 299.166 693.039 298.258 690.888 297.351C689.807 296.894 688.672 296.464 687.644 295.994C686.83 295.622 685.018 295.193 684.564 294.412L684.479 294.329Z'
                opacity='.4' />
          <path id='Vector_529' fill='white'
                d='M716.048 314.316C720.588 311.344 721.859 305.253 718.886 300.713C715.914 296.172 709.823 294.901 705.283 297.874C700.742 300.847 699.471 306.937 702.444 311.478C705.417 316.018 711.507 317.289 716.048 314.316Z'
                opacity='.1' />
          <path id='Vector_530' fill='white'
                d='M718.787 306.636C718.607 308.081 718.003 309.44 717.051 310.542C716.098 311.644 714.84 312.439 713.436 312.826C712.032 313.213 710.545 313.175 709.163 312.717C707.78 312.259 706.565 311.401 705.67 310.252C704.775 309.103 704.241 307.714 704.135 306.262C704.029 304.809 704.357 303.358 705.076 302.092C705.795 300.825 706.873 299.8 708.175 299.147C709.476 298.493 710.942 298.24 712.388 298.42C714.326 298.661 716.089 299.662 717.289 301.202C718.489 302.743 719.028 304.698 718.787 306.636Z'
                opacity='.2' />
          <path id='Vector_531' fill='white'
                d='M712.239 300.87C711.659 303.039 711.626 305.855 707.686 305.701C708.774 306.39 710.005 307.028 710.676 308.222C711.299 309.453 711.625 310.816 711.629 312.198C712.006 310.941 711.859 309.704 712.806 308.492C713.151 308.042 713.592 307.677 714.097 307.425C714.601 307.173 715.156 307.04 715.719 307.037C714.558 306.182 713.46 306.155 712.889 304.555C712.664 303.949 712.491 303.325 712.373 302.689C712.266 302.046 712.448 301.096 712.103 300.595L712.239 300.87Z'
                opacity='.7' />
          <path id='Vector_532' fill='white'
                d='M712.962 306.197C712.684 305.299 712.383 305.403 712.124 305.787C710.821 305.137 711.009 309.565 712.526 308.446C713.041 308.067 713.135 306.754 712.962 306.197Z' />
          <path id='Vector_533' fill='white'
                d='M689.782 295.423C689.613 296.781 689.046 298.058 688.151 299.093C687.256 300.128 686.075 300.875 684.756 301.239C683.437 301.602 682.04 301.567 680.741 301.136C679.443 300.706 678.301 299.9 677.46 298.821C676.619 297.742 676.118 296.437 676.018 295.073C675.919 293.708 676.226 292.345 676.902 291.155C677.577 289.965 678.591 289.002 679.813 288.389C681.036 287.775 682.413 287.537 683.771 287.706C685.591 287.932 687.247 288.872 688.375 290.32C689.502 291.767 690.008 293.603 689.782 295.423Z'
                opacity='.1' />
          <path id='Vector_534' fill='white'
                d='M688.903 295.205C688.777 296.212 688.356 297.159 687.692 297.927C687.028 298.695 686.152 299.248 685.173 299.518C684.195 299.788 683.158 299.761 682.195 299.442C681.232 299.122 680.385 298.525 679.761 297.724C679.137 296.923 678.765 295.955 678.691 294.943C678.617 293.931 678.846 292.919 679.347 292.037C679.848 291.154 680.599 290.44 681.506 289.984C682.413 289.529 683.435 289.352 684.442 289.477C685.111 289.56 685.757 289.774 686.343 290.107C686.93 290.44 687.445 290.885 687.859 291.417C688.273 291.949 688.579 292.557 688.758 293.207C688.937 293.857 688.986 294.536 688.903 295.205Z'
                opacity='.2' />
          <path id='Vector_535' fill='white'
                d='M684.197 290.968C683.778 292.47 683.754 294.42 680.909 294.312C681.695 294.789 682.584 295.23 683.069 296.057C683.518 296.91 683.754 297.853 683.756 298.81C684.029 297.939 683.923 297.083 684.607 296.244C684.856 295.933 685.174 295.68 685.539 295.506C685.903 295.331 686.304 295.239 686.711 295.237C685.873 294.645 685.08 294.626 684.667 293.519C684.504 293.099 684.38 292.667 684.294 292.226C684.216 291.782 684.348 291.124 684.099 290.777L684.197 290.968Z'
                opacity='.7' />
          <path id='Vector_536' fill='white'
                d='M710.324 269.514C710.199 270.478 709.778 271.384 709.115 272.119C708.451 272.854 707.574 273.383 706.596 273.641C705.617 273.9 704.581 273.874 703.617 273.569C702.654 273.264 701.806 272.692 701.183 271.926C700.559 271.16 700.186 270.234 700.113 269.266C700.039 268.297 700.267 267.33 700.768 266.485C701.269 265.641 702.021 264.958 702.928 264.522C703.835 264.086 704.857 263.918 705.864 264.037C707.215 264.198 708.444 264.865 709.28 265.892C710.117 266.919 710.492 268.222 710.324 269.514Z'
                opacity='.1' />
          <path id='Vector_537' fill='white'
                d='M709.442 269.349C709.35 270.05 709.038 270.709 708.548 271.244C708.057 271.778 707.409 272.163 706.686 272.351C705.963 272.539 705.196 272.52 704.484 272.298C703.772 272.076 703.146 271.66 702.685 271.103C702.224 270.546 701.949 269.872 701.894 269.168C701.84 268.464 702.009 267.76 702.379 267.146C702.75 266.532 703.305 266.035 703.976 265.718C704.646 265.401 705.402 265.279 706.146 265.366C706.64 265.424 707.118 265.573 707.551 265.804C707.984 266.036 708.365 266.345 708.671 266.715C708.977 267.085 709.202 267.508 709.335 267.96C709.467 268.412 709.504 268.884 709.442 269.349Z'
                opacity='.2' />
          <path id='Vector_538' fill='white'
                d='M705.947 266.368C705.657 267.454 705.64 268.861 703.669 268.784C704.214 269.129 704.829 269.448 705.165 270.045C705.476 270.66 705.639 271.342 705.641 272.033C705.83 271.404 705.756 270.786 706.23 270.18C706.402 269.955 706.623 269.772 706.875 269.646C707.127 269.52 707.405 269.454 707.686 269.452C707.106 269.025 706.556 269.012 706.271 268.211C706.158 267.908 706.072 267.596 706.013 267.278C705.959 266.957 706.05 266.482 705.878 266.231L705.947 266.368Z'
                opacity='.7' />
          <path id='Vector_539' fill='white'
                d='M684.42 294.495C684.211 293.724 683.985 293.814 683.791 294.144C682.815 293.587 682.955 297.381 684.093 296.422C684.479 296.097 684.549 294.971 684.42 294.495Z' />
        </g>;


      case 18:
        return <g id='Mini gem_16'>
          <path id='Vector_540' fill={`#${diamondColors[color][0]}`}
                d='M757.492 359.104L782 381.967L763.771 399.038L757.491 359.92L734.118 386.448L759.759 423.859L763.771 399.038L757.492 359.104Z' />
          <path id='Vector_541' fill={`#${diamondColors[color][1]}`}
                d='M757.451 359.104L734.118 386.317L763.759 399.234L757.451 359.104Z' />
          <path id='Vector_542' fill='white'
                d='M757.451 359.104L756.8 359.863L762.752 397.729L734.783 385.541L734.118 386.317L763.759 399.234L757.451 359.104Z' />
          <path id='Vector_543' fill={`#${diamondColors[color][2]}`}
                d='M763.686 399.001L759.655 423.859L782 381.905L763.686 399.001Z' />
          <path id='Vector_544' fill={`#${diamondColors[color][2]}`}
                d='M763.686 399.001L759.655 423.859L782 381.905L763.686 399.001Z' />
          <path id='Vector_545' fill='white' d='M734.118 386.466L760.567 378.468L757.529 359.104L734.118 386.466Z'
                opacity='.2' />
          <path id='Vector_546' fill='white' d='M760.422 378.404L782 381.905L757.375 359.104L760.422 378.05V378.404Z'
                opacity='.2' />
          <path id='Vector_547' fill={`#${diamondColors[color][3]}`}
                d='M762.011 409.354C762.055 409.084 775.077 388.591 775.077 388.591L782 382.361L766.274 411.462L759.655 423.859L762.011 409.354Z'
                opacity='.5' />
          <path id='Vector_548' fill={`#${diamondColors[color][3]}`}
                d='M764.474 399.001L781.22 383.369L782 381.905L763.686 399.001L759.655 423.859L760.788 421.731L764.474 399.001Z' />
          <path id='Vector_549' fill={`#${diamondColors[color][3]}`}
                d='M742.742 399.06L763.303 399.234L734.118 386.466L742.742 399.06Z' opacity='.5' />
          <path id='Vector_550' fill='white'
                d='M735.486 385.991C736.385 386.913 738.597 387.044 739.85 387.284C742.459 387.847 745.149 387.943 747.793 387.567C750.996 387.05 753.511 385.642 755.998 383.66C757.621 382.367 758.263 381.095 758.944 379.256C759.145 378.786 759.304 378.299 759.417 377.801C760.236 378.235 760.128 381.01 760.362 381.934C760.824 383.751 761.327 385.516 761.516 387.374C761.877 390.448 762.396 393.502 763.073 396.524C763.201 397.142 763.603 397.942 762.898 398.253C762.34 398.501 761.348 398.014 760.866 397.838C759.438 397.316 758.333 396.416 756.958 395.767C755.621 395.136 754.208 394.799 752.86 394.168C751.433 393.499 750.038 392.894 748.666 392.12C746.564 390.933 744.232 390.006 742.034 389.079C740.93 388.612 739.77 388.172 738.719 387.692C737.888 387.312 736.036 386.874 735.572 386.076L735.486 385.991Z'
                opacity='.4' />
          <path id='Vector_551' fill='white'
                d='M767.743 406.415C772.383 403.377 773.681 397.154 770.644 392.514C767.606 387.875 761.383 386.576 756.743 389.614C752.104 392.651 750.805 398.875 753.843 403.514C756.88 408.154 763.104 409.452 767.743 406.415Z'
                opacity='.1' />
          <path id='Vector_552' fill='white'
                d='M770.542 398.566C770.358 400.043 769.741 401.432 768.768 402.558C767.795 403.684 766.51 404.496 765.075 404.892C763.64 405.287 762.121 405.249 760.708 404.781C759.295 404.312 758.053 403.436 757.139 402.262C756.224 401.088 755.679 399.669 755.57 398.185C755.462 396.7 755.797 395.217 756.532 393.923C757.267 392.629 758.368 391.582 759.698 390.914C761.028 390.246 762.526 389.988 764.003 390.171C765.984 390.417 767.785 391.44 769.011 393.015C770.237 394.589 770.788 396.586 770.542 398.566Z'
                opacity='.2' />
          <path id='Vector_553' fill='white'
                d='M763.851 392.674C763.258 394.891 763.225 397.769 759.199 397.611C760.311 398.316 761.569 398.967 762.255 400.187C762.891 401.445 763.224 402.838 763.227 404.25C763.613 402.965 763.463 401.702 764.431 400.463C764.783 400.003 765.234 399.631 765.75 399.373C766.265 399.115 766.832 398.98 767.407 398.976C766.221 398.103 765.099 398.076 764.516 396.441C764.286 395.821 764.109 395.183 763.988 394.533C763.879 393.877 764.065 392.906 763.712 392.394L763.851 392.674Z'
                opacity='.7' />
          <path id='Vector_554' fill='white'
                d='M764.59 398.119C764.306 397.2 763.998 397.307 763.733 397.699C762.403 397.035 762.595 401.56 764.145 400.416C764.67 400.029 764.766 398.687 764.59 398.119Z' />
          <path id='Vector_555' fill='white'
                d='M740.904 387.109C740.732 388.497 740.152 389.802 739.237 390.859C738.323 391.917 737.116 392.68 735.768 393.052C734.421 393.423 732.993 393.387 731.666 392.947C730.339 392.507 729.172 391.684 728.313 390.581C727.454 389.478 726.941 388.145 726.84 386.751C726.738 385.357 727.052 383.963 727.743 382.748C728.433 381.532 729.468 380.548 730.718 379.921C731.967 379.294 733.374 379.051 734.762 379.223C736.622 379.455 738.314 380.415 739.466 381.894C740.618 383.373 741.135 385.249 740.904 387.109Z'
                opacity='.1' />
          <path id='Vector_556' fill='white'
                d='M740.006 386.886C739.877 387.915 739.447 388.883 738.769 389.667C738.09 390.452 737.195 391.018 736.195 391.293C735.195 391.569 734.136 391.542 733.151 391.215C732.167 390.889 731.301 390.278 730.664 389.46C730.027 388.642 729.646 387.653 729.571 386.619C729.496 385.584 729.729 384.551 730.241 383.649C730.753 382.747 731.521 382.017 732.448 381.551C733.374 381.086 734.418 380.906 735.448 381.033C736.131 381.118 736.791 381.337 737.39 381.677C737.99 382.017 738.516 382.472 738.939 383.015C739.362 383.559 739.674 384.18 739.857 384.844C740.04 385.508 740.091 386.202 740.006 386.886Z'
                opacity='.2' />
          <path id='Vector_557' fill='white'
                d='M735.197 382.557C734.769 384.092 734.745 386.084 731.837 385.974C732.64 386.461 733.549 386.912 734.044 387.757C734.504 388.628 734.744 389.592 734.747 390.57C735.026 389.68 734.917 388.805 735.616 387.948C735.87 387.63 736.196 387.372 736.568 387.193C736.941 387.015 737.35 386.921 737.766 386.918C736.909 386.314 736.099 386.295 735.677 385.163C735.511 384.734 735.384 384.293 735.297 383.843C735.216 383.388 735.351 382.716 735.097 382.361L735.197 382.557Z'
                opacity='.7' />
          <path id='Vector_558' fill='white'
                d='M761.895 360.635C761.767 361.619 761.337 362.545 760.659 363.296C759.98 364.047 759.085 364.588 758.085 364.852C757.085 365.116 756.026 365.09 755.041 364.778C754.057 364.466 753.191 363.882 752.553 363.099C751.916 362.316 751.536 361.37 751.46 360.381C751.385 359.391 751.618 358.402 752.13 357.54C752.642 356.677 753.41 355.979 754.337 355.534C755.264 355.088 756.308 354.916 757.338 355.038C758.718 355.202 759.973 355.884 760.828 356.934C761.683 357.983 762.066 359.314 761.895 360.635Z'
                opacity='.1' />
          <path id='Vector_559' fill='white'
                d='M760.993 360.466C760.899 361.182 760.581 361.856 760.079 362.402C759.578 362.948 758.916 363.341 758.177 363.533C757.438 363.725 756.655 363.706 755.927 363.479C755.199 363.252 754.56 362.827 754.089 362.258C753.617 361.689 753.336 361.001 753.281 360.281C753.225 359.561 753.397 358.842 753.776 358.215C754.155 357.587 754.722 357.08 755.408 356.756C756.093 356.432 756.865 356.307 757.625 356.396C758.13 356.455 758.618 356.607 759.061 356.844C759.503 357.08 759.892 357.396 760.205 357.774C760.518 358.152 760.748 358.585 760.884 359.046C761.019 359.508 761.056 359.991 760.993 360.466Z'
                opacity='.2' />
          <path id='Vector_560' fill='white'
                d='M757.421 357.42C757.125 358.529 757.108 359.967 755.095 359.889C755.651 360.241 756.28 360.567 756.622 361.177C756.941 361.806 757.107 362.502 757.109 363.208C757.302 362.566 757.227 361.934 757.711 361.314C757.887 361.085 758.112 360.898 758.37 360.77C758.628 360.641 758.911 360.573 759.199 360.571C758.606 360.135 758.045 360.121 757.753 359.303C757.638 358.994 757.55 358.675 757.489 358.35C757.434 358.021 757.527 357.536 757.351 357.28L757.421 357.42Z'
                opacity='.7' />
          <path id='Vector_561' fill='white'
                d='M735.425 386.16C735.211 385.373 734.981 385.465 734.782 385.802C733.785 385.233 733.928 389.11 735.091 388.13C735.486 387.797 735.557 386.648 735.425 386.16Z' />
        </g>;



      case 19 :
        return <g id='Mini gem'>
          <path id='Vector_117' fill={`#${diamondColors[color][0]}`}
                d='M706.018 444.686L734 470.789L713.187 490.28L706.018 445.618L679.331 475.906L708.607 518.62L713.187 490.28L706.018 444.686Z' />
          <path id='Vector_118' fill={`#${diamondColors[color][1]}`} d='M705.971 444.686L679.331 475.756L713.174 490.504L705.971 444.686Z' />
          <path id='Vector_119' fill='white'
                d='M705.972 444.686L705.228 445.553L712.024 488.786L680.091 474.87L679.331 475.756L713.174 490.504L705.972 444.686Z' />
          <path id='Vector_120' fill={`#${diamondColors[color][2]}`} d='M713.09 490.238L708.488 518.62L734 470.719L713.09 490.238Z' />
          <path id='Vector_121' fill={`#${diamondColors[color][2]}`} d='M713.09 490.238L708.488 518.62L734 470.719L713.09 490.238Z' />
          <path id='Vector_122' fill='white' d='M679.331 475.926L709.529 466.794L706.061 444.686L679.331 475.926Z'
                opacity='.2' />
          <path id='Vector_123' fill='white' d='M709.363 466.721L734 470.719L705.884 444.686L709.363 466.317V466.721Z'
                opacity='.2' />
          <path id='Vector_124' fill={`#${diamondColors[color][3]}`}
                d='M711.177 502.058C711.228 501.75 726.095 478.352 726.095 478.352L734 471.24L716.045 504.465L708.488 518.62L711.177 502.058Z'
                opacity='.5' />
          <path id='Vector_125' fill={`#${diamondColors[color][3]}`}
                d='M713.99 490.238L733.11 472.391L734 470.719L713.09 490.238L708.488 518.62L709.782 516.189L713.99 490.238Z' />
          <path id='Vector_126' fill={`#${diamondColors[color][3]}`}
                d='M689.178 490.305L712.653 490.504L679.331 475.926L689.178 490.305Z'
                opacity='.5' />
          <path id='Vector_127' fill='white'
                d='M680.893 475.384C681.919 476.437 684.445 476.585 685.875 476.86C688.854 477.503 691.926 477.613 694.944 477.183C698.601 476.593 701.473 474.985 704.312 472.723C706.166 471.246 706.899 469.794 707.676 467.694C707.906 467.157 708.087 466.601 708.216 466.033C709.151 466.529 709.027 469.697 709.295 470.751C709.822 472.826 710.396 474.841 710.613 476.962C711.024 480.473 711.618 483.96 712.39 487.41C712.536 488.116 712.995 489.029 712.19 489.384C711.553 489.667 710.42 489.111 709.871 488.91C708.24 488.314 706.979 487.286 705.408 486.545C703.882 485.824 702.269 485.44 700.73 484.719C699.1 483.956 697.508 483.266 695.941 482.381C693.542 481.027 690.879 479.968 688.369 478.909C687.108 478.377 685.784 477.874 684.585 477.326C683.635 476.892 681.521 476.392 680.992 475.481L680.893 475.384Z'
                opacity='.4' />
          <path id='Vector_128' fill='white'
                d='M717.722 498.702C723.019 495.234 724.502 488.129 721.034 482.831C717.566 477.534 710.46 476.052 705.163 479.52C699.866 482.988 698.383 490.094 701.851 495.391C705.32 500.688 712.425 502.17 717.722 498.702Z'
                opacity='.1' />
          <path id='Vector_129' fill='white'
                d='M720.918 489.742C720.708 491.428 720.003 493.014 718.892 494.299C717.781 495.585 716.314 496.512 714.676 496.964C713.038 497.415 711.303 497.371 709.69 496.837C708.077 496.302 706.659 495.301 705.615 493.961C704.571 492.62 703.948 491 703.824 489.306C703.701 487.611 704.083 485.918 704.922 484.44C705.761 482.963 707.019 481.767 708.537 481.005C710.056 480.242 711.766 479.947 713.452 480.157C715.713 480.438 717.77 481.605 719.17 483.403C720.57 485.2 721.199 487.481 720.918 489.742Z'
                opacity='.2' />
          <path id='Vector_130' fill='white'
                d='M713.279 483.014C712.602 485.546 712.563 488.831 707.967 488.651C709.237 489.455 710.673 490.199 711.456 491.592C712.182 493.029 712.563 494.618 712.567 496.231C713.007 494.764 712.835 493.321 713.941 491.907C714.343 491.382 714.857 490.957 715.446 490.663C716.035 490.368 716.682 490.214 717.339 490.209C715.985 489.213 714.704 489.181 714.038 487.315C713.775 486.608 713.573 485.879 713.436 485.137C713.311 484.387 713.522 483.279 713.12 482.694L713.279 483.014Z'
                opacity='.7' />
          <path id='Vector_131' fill='white'
                d='M714.123 489.23C713.798 488.182 713.447 488.303 713.144 488.752C711.625 487.993 711.844 493.159 713.614 491.853C714.214 491.411 714.324 489.88 714.123 489.23Z' />
          <path id='Vector_132' fill='white'
                d='M687.079 476.66C686.882 478.244 686.22 479.735 685.176 480.942C684.133 482.15 682.754 483.021 681.215 483.445C679.677 483.869 678.047 483.828 676.532 483.326C675.016 482.824 673.684 481.884 672.703 480.624C671.723 479.365 671.137 477.843 671.021 476.251C670.905 474.659 671.264 473.069 672.052 471.681C672.84 470.293 674.022 469.17 675.449 468.453C676.875 467.737 678.482 467.46 680.066 467.657C682.19 467.921 684.122 469.018 685.437 470.706C686.752 472.395 687.343 474.537 687.079 476.66Z'
                opacity='.1' />
          <path id='Vector_133' fill='white'
                d='M686.053 476.405C685.907 477.58 685.415 478.686 684.641 479.581C683.867 480.477 682.844 481.123 681.702 481.438C680.561 481.752 679.352 481.721 678.228 481.349C677.104 480.976 676.115 480.279 675.388 479.344C674.66 478.41 674.226 477.281 674.14 476.1C674.054 474.919 674.32 473.739 674.904 472.709C675.489 471.68 676.366 470.846 677.424 470.315C678.482 469.783 679.674 469.577 680.849 469.723C681.63 469.82 682.383 470.07 683.067 470.458C683.751 470.846 684.352 471.366 684.835 471.986C685.319 472.607 685.675 473.316 685.884 474.075C686.093 474.833 686.15 475.625 686.053 476.405Z'
                opacity='.2' />
          <path id='Vector_134' fill='white'
                d='M680.563 471.463C680.075 473.215 680.047 475.49 676.727 475.364C677.644 475.92 678.682 476.435 679.247 477.4C679.771 478.394 680.046 479.495 680.049 480.612C680.368 479.596 680.243 478.597 681.041 477.618C681.332 477.255 681.704 476.96 682.129 476.756C682.554 476.553 683.022 476.446 683.496 476.443C682.518 475.753 681.593 475.731 681.112 474.438C680.922 473.949 680.776 473.445 680.677 472.931C680.585 472.412 680.739 471.645 680.449 471.24L680.563 471.463Z'
                opacity='.7' />
          <path id='Vector_135' fill='white'
                d='M711.045 446.433C710.899 447.557 710.408 448.615 709.634 449.472C708.859 450.329 707.837 450.947 706.695 451.248C705.554 451.55 704.344 451.52 703.22 451.164C702.096 450.808 701.107 450.14 700.38 449.247C699.652 448.353 699.218 447.273 699.132 446.143C699.046 445.014 699.312 443.885 699.896 442.9C700.481 441.915 701.358 441.117 702.416 440.609C703.475 440.101 704.667 439.904 705.842 440.044C707.418 440.231 708.851 441.01 709.827 442.208C710.803 443.406 711.241 444.926 711.045 446.433Z'
                opacity='.1' />
          <path id='Vector_136' fill='white'
                d='M710.016 446.241C709.908 447.058 709.545 447.828 708.972 448.451C708.4 449.074 707.644 449.524 706.8 449.743C705.956 449.962 705.063 449.94 704.232 449.681C703.401 449.422 702.67 448.937 702.132 448.287C701.594 447.637 701.274 446.851 701.21 446.03C701.147 445.208 701.343 444.387 701.776 443.67C702.208 442.954 702.856 442.374 703.638 442.005C704.421 441.635 705.302 441.492 706.17 441.594C706.747 441.661 707.304 441.835 707.809 442.105C708.315 442.375 708.759 442.736 709.116 443.168C709.473 443.599 709.736 444.093 709.891 444.62C710.045 445.147 710.087 445.698 710.016 446.241Z'
                opacity='.2' />
          <path id='Vector_137' fill='white'
                d='M705.938 442.763C705.599 444.029 705.58 445.672 703.281 445.582C703.916 445.984 704.634 446.356 705.025 447.052C705.389 447.77 705.579 448.565 705.581 449.372C705.801 448.638 705.715 447.917 706.268 447.21C706.469 446.947 706.726 446.734 707.021 446.587C707.315 446.441 707.639 446.363 707.967 446.361C707.29 445.862 706.649 445.847 706.316 444.913C706.185 444.56 706.084 444.195 706.015 443.824C705.952 443.45 706.059 442.896 705.858 442.603L705.938 442.763Z'
                opacity='.7' />
          <path id='Vector_138' fill='white'
                d='M680.823 475.577C680.58 474.678 680.316 474.783 680.089 475.168C678.95 474.519 679.114 478.945 680.442 477.826C680.893 477.446 680.974 476.133 680.823 475.577Z' />
        </g>;


      case 20 :
        return <g id='Mini gem_2'>
          <path id='Vector_139' fill={`#${diamondColors[color][0]}`}
                d='M872.136 456.494L893 475.957L877.482 490.489L872.136 457.189L852.238 479.772L874.067 511.62L877.482 490.489L872.136 456.494Z' />
          <path id='Vector_140' fill={`#${diamondColors[color][1]}`} d='M872.102 456.494L852.238 479.66L877.472 490.656L872.102 456.494Z' />
          <path id='Vector_141' fill='white'
                d='M872.102 456.494L871.548 457.14L876.614 489.375L852.804 478.999L852.238 479.66L877.472 490.656L872.102 456.494Z' />
          <path id='Vector_142' fill={`#${diamondColors[color][2]}`} d='M877.409 490.458L873.978 511.62L893 475.904L877.409 490.458Z' />
          <path id='Vector_143' fill={`#${diamondColors[color][2]}`} d='M877.409 490.458L873.978 511.62L893 475.904L877.409 490.458Z' />
          <path id='Vector_144' fill='white' d='M852.238 479.787L874.754 472.978L872.168 456.494L852.238 479.787Z'
                opacity='.2' />
          <path id='Vector_145' fill='white' d='M874.63 472.923L893 475.904L872.037 456.494L874.63 472.623V472.923Z'
                opacity='.2' />
          <path id='Vector_146' fill={`#${diamondColors[color][3]}`}
                d='M875.983 499.271C876.021 499.041 887.106 481.596 887.106 481.596L893 476.293L879.613 501.066L873.978 511.62L875.983 499.271Z'
                opacity='.5' />
          <path id='Vector_147' fill={`#${diamondColors[color][3]}`}
                d='M878.081 490.458L892.336 477.151L893 475.904L877.409 490.458L873.978 511.62L874.943 509.807L878.081 490.458Z' />
          <path id='Vector_148' fill={`#${diamondColors[color][3]}`}
                d='M859.58 490.508L877.083 490.656L852.238 479.787L859.58 490.508Z'
                opacity='.5' />
          <path id='Vector_149' fill='white'
                d='M853.403 479.383C854.168 480.168 856.052 480.279 857.118 480.483C859.339 480.963 861.629 481.044 863.88 480.724C866.606 480.284 868.748 479.086 870.864 477.398C872.247 476.297 872.793 475.215 873.372 473.649C873.544 473.249 873.679 472.834 873.775 472.411C874.472 472.78 874.38 475.142 874.58 475.929C874.973 477.475 875.401 478.978 875.562 480.559C875.869 483.177 876.311 485.777 876.887 488.349C876.996 488.876 877.339 489.556 876.739 489.821C876.263 490.032 875.419 489.618 875.009 489.468C873.793 489.023 872.853 488.257 871.682 487.705C870.544 487.167 869.341 486.881 868.193 486.343C866.978 485.774 865.791 485.259 864.623 484.6C862.834 483.59 860.848 482.8 858.977 482.011C858.037 481.614 857.049 481.239 856.155 480.831C855.448 480.507 853.871 480.134 853.476 479.455L853.403 479.383Z'
                opacity='.4' />
          <path id='Vector_150' fill='white'
                d='M880.863 496.769C884.813 494.183 885.918 488.885 883.332 484.936C880.747 480.986 875.448 479.881 871.499 482.466C867.549 485.052 866.444 490.35 869.03 494.3C871.616 498.249 876.914 499.355 880.863 496.769Z'
                opacity='.1' />
          <path id='Vector_151' fill='white'
                d='M883.246 490.088C883.09 491.345 882.564 492.528 881.736 493.486C880.907 494.445 879.813 495.136 878.592 495.473C877.37 495.809 876.077 495.777 874.874 495.378C873.672 494.979 872.614 494.233 871.836 493.234C871.057 492.234 870.593 491.026 870.501 489.763C870.409 488.499 870.693 487.237 871.319 486.135C871.945 485.033 872.883 484.142 874.015 483.573C875.147 483.005 876.422 482.785 877.679 482.941C879.365 483.151 880.899 484.021 881.943 485.362C882.987 486.702 883.455 488.402 883.246 490.088Z'
                opacity='.2' />
          <path id='Vector_152' fill='white'
                d='M877.55 485.072C877.045 486.959 877.017 489.409 873.589 489.275C874.536 489.874 875.607 490.429 876.191 491.468C876.732 492.539 877.016 493.724 877.019 494.927C877.348 493.833 877.219 492.757 878.044 491.703C878.343 491.311 878.727 490.994 879.166 490.775C879.605 490.555 880.088 490.44 880.577 490.437C879.568 489.694 878.612 489.67 878.116 488.278C877.92 487.751 877.77 487.208 877.667 486.654C877.574 486.096 877.732 485.269 877.432 484.833L877.55 485.072Z'
                opacity='.7' />
          <path id='Vector_153' fill='white'
                d='M878.179 489.707C877.937 488.925 877.675 489.016 877.45 489.35C876.317 488.784 876.48 492.636 877.8 491.662C878.247 491.333 878.329 490.191 878.179 489.707Z' />
          <path id='Vector_154' fill='white'
                d='M858.015 480.334C857.868 481.515 857.375 482.627 856.596 483.527C855.818 484.427 854.791 485.077 853.643 485.393C852.496 485.71 851.281 485.679 850.151 485.304C849.021 484.93 848.028 484.229 847.297 483.29C846.565 482.351 846.129 481.216 846.042 480.029C845.956 478.842 846.223 477.656 846.811 476.622C847.399 475.587 848.28 474.749 849.343 474.215C850.407 473.681 851.605 473.474 852.786 473.621C854.37 473.818 855.81 474.636 856.791 475.895C857.771 477.154 858.212 478.751 858.015 480.334Z'
                opacity='.1' />
          <path id='Vector_155' fill='white'
                d='M857.25 480.144C857.141 481.02 856.775 481.844 856.197 482.512C855.62 483.18 854.857 483.662 854.006 483.896C853.155 484.131 852.253 484.108 851.415 483.83C850.577 483.552 849.841 483.032 849.298 482.336C848.755 481.639 848.432 480.797 848.368 479.917C848.303 479.036 848.502 478.156 848.938 477.389C849.373 476.621 850.027 475.999 850.816 475.603C851.605 475.207 852.494 475.053 853.37 475.162C853.952 475.234 854.514 475.42 855.024 475.71C855.534 475.999 855.982 476.387 856.342 476.849C856.703 477.312 856.968 477.841 857.124 478.406C857.28 478.972 857.323 479.562 857.25 480.144Z'
                opacity='.2' />
          <path id='Vector_156' fill='white'
                d='M853.157 476.459C852.793 477.766 852.772 479.461 850.297 479.368C850.98 479.783 851.754 480.167 852.175 480.886C852.566 481.627 852.772 482.448 852.774 483.28C853.011 482.523 852.918 481.778 853.513 481.048C853.73 480.778 854.007 480.558 854.324 480.406C854.641 480.254 854.99 480.174 855.344 480.172C854.615 479.658 853.925 479.641 853.566 478.678C853.424 478.313 853.316 477.937 853.242 477.554C853.173 477.167 853.288 476.595 853.072 476.293L853.157 476.459Z'
                opacity='.7' />
          <path id='Vector_157' fill='white'
                d='M875.885 457.797C875.776 458.635 875.41 459.423 874.832 460.062C874.255 460.701 873.492 461.162 872.641 461.387C871.79 461.611 870.888 461.59 870.05 461.324C869.212 461.058 868.475 460.561 867.932 459.895C867.39 459.228 867.066 458.423 867.002 457.581C866.938 456.738 867.136 455.897 867.572 455.162C868.008 454.428 868.662 453.833 869.451 453.454C870.24 453.075 871.129 452.928 872.005 453.033C873.18 453.172 874.249 453.753 874.976 454.646C875.704 455.54 876.031 456.673 875.885 457.797Z'
                opacity='.1' />
          <path id='Vector_158' fill='white'
                d='M875.117 457.653C875.037 458.263 874.766 458.836 874.339 459.301C873.912 459.766 873.349 460.101 872.72 460.264C872.09 460.428 871.424 460.412 870.804 460.218C870.185 460.025 869.64 459.663 869.239 459.179C868.838 458.694 868.599 458.108 868.551 457.496C868.504 456.883 868.651 456.271 868.973 455.737C869.295 455.203 869.779 454.77 870.362 454.495C870.945 454.219 871.602 454.113 872.25 454.188C872.68 454.239 873.095 454.368 873.472 454.569C873.849 454.771 874.18 455.04 874.446 455.362C874.712 455.684 874.909 456.052 875.024 456.445C875.139 456.838 875.171 457.249 875.117 457.653Z'
                opacity='.2' />
          <path id='Vector_159' fill='white'
                d='M872.076 455.06C871.824 456.004 871.81 457.229 870.096 457.162C870.569 457.462 871.105 457.739 871.396 458.258C871.667 458.794 871.809 459.386 871.81 459.988C871.975 459.441 871.911 458.903 872.323 458.376C872.473 458.18 872.665 458.021 872.884 457.912C873.103 457.802 873.345 457.744 873.589 457.743C873.085 457.371 872.607 457.359 872.359 456.663C872.261 456.4 872.186 456.128 872.134 455.851C872.087 455.572 872.167 455.159 872.017 454.941L872.076 455.06Z'
                opacity='.7' />
          <path id='Vector_160' fill='white'
                d='M853.351 479.527C853.169 478.857 852.973 478.935 852.804 479.222C851.954 478.737 852.077 482.038 853.067 481.203C853.403 480.92 853.463 479.941 853.351 479.527Z' />
        </g>;


      case 21:
        return <g id='Mini gem_3'>
          <path id='Vector_161' fill={`#${diamondColors[color][0]}`}
                d='M958.11 419.494L978.973 438.957L963.455 453.489L958.11 420.189L938.211 442.772L960.04 474.62L963.455 453.489L958.11 419.494Z' />
          <path id='Vector_162' fill={`#${diamondColors[color][1]}`} d='M958.075 419.494L938.211 442.66L963.445 453.656L958.075 419.494Z' />
          <path id='Vector_163' fill='white'
                d='M958.075 419.494L957.521 420.14L962.588 452.375L938.778 441.999L938.211 442.66L963.445 453.656L958.075 419.494Z' />
          <path id='Vector_164' fill={`#${diamondColors[color][2]}`} d='M963.383 453.458L959.951 474.62L978.974 438.904L963.383 453.458Z' />
          <path id='Vector_165' fill={`#${diamondColors[color][2]}`} d='M963.383 453.458L959.951 474.62L978.974 438.904L963.383 453.458Z' />
          <path id='Vector_166' fill='white' d='M938.211 442.787L960.728 435.978L958.142 419.494L938.211 442.787Z'
                opacity='.2' />
          <path id='Vector_167' fill='white'
                d='M960.604 435.923L978.974 438.904L958.01 419.494L960.604 435.623V435.923Z' opacity='.2' />
          <path id='Vector_168' fill={`#${diamondColors[color][3]}`}
                d='M961.957 462.271C961.994 462.041 973.08 444.596 973.08 444.596L978.974 439.293L965.586 464.066L959.951 474.62L961.957 462.271Z'
                opacity='.5' />
          <path id='Vector_169' fill={`#${diamondColors[color][3]}`}
                d='M964.054 453.458L978.31 440.151L978.974 438.904L963.383 453.458L959.951 474.62L960.916 472.807L964.054 453.458Z' />
          <path id='Vector_170' fill={`#${diamondColors[color][3]}`}
                d='M945.554 453.508L963.057 453.656L938.211 442.787L945.554 453.508Z'
                opacity='.5' />
          <path id='Vector_171' fill='white'
                d='M939.376 442.383C940.142 443.168 942.025 443.279 943.091 443.483C945.312 443.963 947.603 444.044 949.853 443.724C952.58 443.284 954.721 442.086 956.838 440.398C958.22 439.297 958.767 438.215 959.346 436.649C959.517 436.249 959.652 435.834 959.749 435.411C960.446 435.78 960.354 438.142 960.553 438.929C960.946 440.475 961.374 441.978 961.536 443.559C961.843 446.177 962.285 448.777 962.861 451.349C962.97 451.876 963.312 452.556 962.712 452.821C962.237 453.032 961.392 452.618 960.982 452.468C959.766 452.023 958.826 451.257 957.655 450.705C956.518 450.167 955.315 449.881 954.167 449.343C952.952 448.774 951.764 448.259 950.596 447.6C948.807 446.59 946.822 445.8 944.951 445.011C944.01 444.614 943.023 444.239 942.129 443.831C941.421 443.507 939.845 443.134 939.45 442.455L939.376 442.383Z'
                opacity='.4' />
          <path id='Vector_172' fill='white'
                d='M966.837 459.769C970.786 457.183 971.892 451.885 969.306 447.936C966.72 443.986 961.422 442.881 957.472 445.466C953.523 448.052 952.417 453.35 955.003 457.3C957.589 461.249 962.887 462.355 966.837 459.769Z'
                opacity='.1' />
          <path id='Vector_173' fill='white'
                d='M969.219 453.088C969.063 454.345 968.537 455.528 967.709 456.486C966.881 457.445 965.787 458.136 964.565 458.473C963.344 458.809 962.05 458.777 960.848 458.378C959.645 457.979 958.588 457.233 957.809 456.234C957.031 455.234 956.566 454.026 956.474 452.763C956.382 451.499 956.667 450.237 957.293 449.135C957.918 448.033 958.856 447.142 959.988 446.573C961.12 446.005 962.396 445.785 963.653 445.941C965.339 446.151 966.872 447.021 967.916 448.362C968.96 449.702 969.429 451.402 969.219 453.088Z'
                opacity='.2' />
          <path id='Vector_174' fill='white'
                d='M963.524 448.072C963.019 449.959 962.99 452.409 959.563 452.275C960.51 452.874 961.581 453.429 962.164 454.468C962.706 455.539 962.99 456.724 962.993 457.927C963.321 456.833 963.193 455.757 964.017 454.703C964.317 454.311 964.701 453.994 965.14 453.775C965.579 453.555 966.061 453.44 966.551 453.437C965.541 452.694 964.586 452.67 964.089 451.278C963.893 450.751 963.743 450.208 963.64 449.654C963.547 449.096 963.705 448.269 963.405 447.833L963.524 448.072Z'
                opacity='.7' />
          <path id='Vector_175' fill='white'
                d='M964.153 452.707C963.91 451.925 963.649 452.016 963.423 452.35C962.29 451.784 962.454 455.636 963.774 454.662C964.221 454.333 964.303 453.191 964.153 452.707Z' />
          <path id='Vector_176' fill='white'
                d='M943.989 443.334C943.842 444.515 943.348 445.627 942.57 446.527C941.792 447.427 940.764 448.077 939.617 448.393C938.469 448.71 937.254 448.679 936.124 448.304C934.995 447.93 934.001 447.229 933.27 446.29C932.539 445.351 932.102 444.216 932.016 443.029C931.929 441.842 932.197 440.656 932.784 439.622C933.372 438.587 934.253 437.749 935.317 437.215C936.381 436.681 937.579 436.474 938.76 436.621C940.343 436.818 941.784 437.636 942.764 438.895C943.745 440.154 944.185 441.751 943.989 443.334Z'
                opacity='.1' />
          <path id='Vector_177' fill='white'
                d='M943.224 443.144C943.115 444.02 942.748 444.844 942.171 445.512C941.593 446.18 940.831 446.662 939.98 446.896C939.129 447.131 938.227 447.108 937.389 446.83C936.551 446.552 935.814 446.032 935.272 445.336C934.729 444.639 934.405 443.797 934.341 442.917C934.277 442.036 934.475 441.156 934.911 440.389C935.347 439.621 936.001 438.999 936.79 438.603C937.579 438.207 938.467 438.053 939.344 438.162C939.926 438.234 940.488 438.42 940.998 438.71C941.508 438.999 941.956 439.387 942.316 439.849C942.676 440.312 942.942 440.841 943.098 441.406C943.253 441.972 943.296 442.562 943.224 443.144Z'
                opacity='.2' />
          <path id='Vector_178' fill='white'
                d='M939.131 439.459C938.766 440.766 938.745 442.461 936.27 442.368C936.954 442.783 937.727 443.167 938.149 443.886C938.54 444.627 938.745 445.448 938.747 446.28C938.985 445.523 938.892 444.778 939.487 444.048C939.703 443.778 939.981 443.558 940.298 443.406C940.615 443.254 940.963 443.174 941.317 443.172C940.588 442.658 939.898 442.641 939.539 441.678C939.398 441.313 939.289 440.937 939.215 440.554C939.147 440.167 939.262 439.595 939.045 439.293L939.131 439.459Z'
                opacity='.7' />
          <path id='Vector_179' fill='white'
                d='M961.858 420.797C961.749 421.635 961.383 422.423 960.806 423.062C960.228 423.701 959.466 424.162 958.615 424.387C957.763 424.611 956.862 424.59 956.024 424.324C955.185 424.058 954.448 423.561 953.906 422.895C953.363 422.228 953.039 421.423 952.975 420.581C952.911 419.738 953.109 418.897 953.545 418.162C953.981 417.428 954.635 416.833 955.424 416.454C956.213 416.075 957.102 415.928 957.978 416.033C959.153 416.172 960.222 416.753 960.95 417.646C961.677 418.54 962.004 419.673 961.858 420.797Z'
                opacity='.1' />
          <path id='Vector_180' fill='white'
                d='M961.091 420.653C961.01 421.263 960.739 421.836 960.313 422.301C959.886 422.766 959.322 423.101 958.693 423.264C958.064 423.428 957.397 423.412 956.778 423.218C956.158 423.025 955.614 422.663 955.213 422.179C954.812 421.694 954.572 421.108 954.525 420.496C954.478 419.883 954.624 419.271 954.947 418.737C955.269 418.203 955.752 417.77 956.335 417.495C956.919 417.219 957.576 417.113 958.223 417.188C958.653 417.239 959.069 417.368 959.446 417.569C959.822 417.771 960.153 418.04 960.42 418.362C960.686 418.684 960.882 419.052 960.997 419.445C961.112 419.838 961.144 420.249 961.091 420.653Z'
                opacity='.2' />
          <path id='Vector_181' fill='white'
                d='M958.05 418.06C957.798 419.004 957.783 420.229 956.069 420.162C956.543 420.462 957.078 420.739 957.37 421.258C957.641 421.794 957.783 422.386 957.784 422.988C957.948 422.441 957.884 421.903 958.296 421.376C958.446 421.18 958.638 421.021 958.858 420.912C959.077 420.802 959.318 420.744 959.563 420.743C959.058 420.371 958.58 420.359 958.332 419.663C958.234 419.4 958.159 419.128 958.108 418.851C958.061 418.572 958.14 418.159 957.99 417.941L958.05 418.06Z'
                opacity='.7' />
          <path id='Vector_182' fill='white'
                d='M939.324 442.527C939.143 441.857 938.946 441.935 938.777 442.222C937.928 441.737 938.05 445.038 939.04 444.203C939.376 443.92 939.437 442.941 939.324 442.527Z' />
        </g>;


      case 22 :
        return <g id='Mini gem_4'>
          <path id='Vector_183' fill={`#${diamondColors[color][0]}`}
                d='M1020.69 354.044L1050.81 382.139L1028.41 403.117L1020.69 355.047L991.966 387.646L1023.48 433.62L1028.41 403.117L1020.69 354.044Z' />
          <path id='Vector_184' fill={`#${diamondColors[color][1]}`} d='M1020.64 354.044L991.966 387.485L1028.39 403.358L1020.64 354.044Z' />
          <path id='Vector_185' fill='white'
                d='M1020.64 354.044L1019.84 354.976L1027.15 401.509L992.784 386.531L991.966 387.485L1028.39 403.358L1020.64 354.044Z' />
          <path id='Vector_186' fill={`#${diamondColors[color][2]}`} d='M1028.3 403.072L1023.35 433.62L1050.81 382.063L1028.3 403.072Z' />
          <path id='Vector_187' fill={`#${diamondColors[color][2]}`} d='M1028.3 403.072L1023.35 433.62L1050.81 382.063L1028.3 403.072Z' />
          <path id='Vector_188' fill='white' d='M991.966 387.667L1024.47 377.839L1020.74 354.044L991.966 387.667Z'
                opacity='.2' />
          <path id='Vector_189' fill='white' d='M1024.29 377.76L1050.81 382.063L1020.55 354.044L1024.29 377.326V377.76Z'
                opacity='.2' />
          <path id='Vector_190' fill={`#${diamondColors[color][3]}`}
                d='M1026.24 415.794C1026.3 415.462 1042.3 390.279 1042.3 390.279L1050.81 382.624L1031.48 418.385L1023.35 433.62L1026.24 415.794Z'
                opacity='.5' />
          <path id='Vector_191' fill={`#${diamondColors[color][3]}`}
                d='M1029.27 403.072L1049.85 383.862L1050.81 382.063L1028.3 403.072L1023.35 433.62L1024.74 431.004L1029.27 403.072Z' />
          <path id='Vector_192' fill={`#${diamondColors[color][3]}`}
                d='M1002.57 403.144L1027.83 403.358L991.966 387.667L1002.57 403.144Z'
                opacity='.5' />
          <path id='Vector_193' fill='white'
                d='M993.648 387.084C994.753 388.217 997.471 388.378 999.01 388.673C1002.22 389.366 1005.52 389.483 1008.77 389.02C1012.71 388.386 1015.8 386.655 1018.85 384.22C1020.85 382.63 1021.64 381.068 1022.47 378.807C1022.72 378.23 1022.92 377.632 1023.06 377.02C1024.06 377.553 1023.93 380.963 1024.22 382.098C1024.78 384.331 1025.4 386.5 1025.64 388.783C1026.08 392.561 1026.72 396.314 1027.55 400.028C1027.71 400.788 1028.2 401.77 1027.33 402.153C1026.65 402.458 1025.43 401.859 1024.84 401.643C1023.08 401.001 1021.72 399.895 1020.03 399.097C1018.39 398.322 1016.66 397.908 1015 397.132C1013.24 396.311 1011.53 395.568 1009.84 394.615C1007.26 393.158 1004.4 392.018 1001.69 390.878C1000.34 390.306 998.912 389.765 997.621 389.175C996.6 388.708 994.324 388.169 993.754 387.189L993.648 387.084Z'
                opacity='.4' />
          <path id='Vector_194' fill='white'
                d='M1033.29 412.182C1038.99 408.449 1040.59 400.802 1036.85 395.1C1033.12 389.399 1025.47 387.803 1019.77 391.536C1014.07 395.269 1012.47 402.916 1016.21 408.618C1019.94 414.319 1027.59 415.915 1033.29 412.182Z'
                opacity='.1' />
          <path id='Vector_195' fill='white'
                d='M1036.73 402.538C1036.5 404.353 1035.74 406.06 1034.55 407.443C1033.35 408.827 1031.77 409.825 1030.01 410.311C1028.25 410.797 1026.38 410.749 1024.64 410.174C1022.91 409.599 1021.38 408.522 1020.26 407.079C1019.13 405.636 1018.46 403.892 1018.33 402.068C1018.2 400.244 1018.61 398.422 1019.51 396.832C1020.41 395.241 1021.77 393.955 1023.4 393.134C1025.04 392.313 1026.88 391.996 1028.69 392.221C1031.13 392.524 1033.34 393.781 1034.85 395.715C1036.35 397.65 1037.03 400.104 1036.73 402.538Z'
                opacity='.2' />
          <path id='Vector_196' fill='white'
                d='M1028.51 395.297C1027.78 398.022 1027.74 401.558 1022.79 401.364C1024.15 402.23 1025.7 403.03 1026.54 404.529C1027.33 406.076 1027.73 407.786 1027.74 409.523C1028.21 407.944 1028.03 406.391 1029.22 404.869C1029.65 404.304 1030.2 403.846 1030.84 403.529C1031.47 403.212 1032.17 403.046 1032.88 403.041C1031.42 401.969 1030.04 401.935 1029.32 399.925C1029.04 399.164 1028.82 398.38 1028.67 397.581C1028.54 396.775 1028.77 395.582 1028.33 394.952L1028.51 395.297Z'
                opacity='.7' />
          <path id='Vector_197' fill='white'
                d='M1029.41 401.988C1029.06 400.859 1028.69 400.99 1028.36 401.472C1026.73 400.656 1026.96 406.216 1028.87 404.811C1029.51 404.335 1029.63 402.686 1029.41 401.988Z' />
          <path id='Vector_198' fill='white'
                d='M1000.31 388.458C1000.09 390.163 999.381 391.767 998.258 393.067C997.135 394.367 995.651 395.304 993.995 395.761C992.339 396.217 990.584 396.173 988.954 395.632C987.323 395.092 985.889 394.08 984.833 392.725C983.778 391.369 983.148 389.731 983.023 388.018C982.898 386.304 983.284 384.593 984.132 383.099C984.981 381.605 986.253 380.396 987.788 379.625C989.324 378.854 991.053 378.555 992.758 378.767C995.044 379.052 997.123 380.232 998.539 382.05C999.954 383.867 1000.59 386.172 1000.31 388.458Z'
                opacity='.1' />
          <path id='Vector_199' fill='white'
                d='M999.202 388.184C999.045 389.448 998.516 390.638 997.682 391.602C996.848 392.566 995.748 393.261 994.519 393.6C993.29 393.939 991.989 393.905 990.779 393.504C989.569 393.103 988.506 392.353 987.723 391.347C986.939 390.342 986.472 389.126 986.379 387.855C986.287 386.584 986.573 385.314 987.202 384.206C987.832 383.097 988.775 382.2 989.914 381.628C991.053 381.056 992.336 380.835 993.601 380.992C994.441 381.096 995.252 381.365 995.988 381.783C996.725 382.201 997.371 382.759 997.891 383.427C998.412 384.095 998.795 384.859 999.02 385.675C999.245 386.491 999.307 387.344 999.202 388.184Z'
                opacity='.2' />
          <path id='Vector_200' fill='white'
                d='M993.293 382.864C992.767 384.75 992.737 387.198 989.164 387.063C990.151 387.662 991.268 388.216 991.876 389.254C992.441 390.325 992.737 391.509 992.74 392.711C993.083 391.617 992.949 390.543 993.808 389.489C994.12 389.098 994.52 388.781 994.978 388.562C995.436 388.342 995.939 388.227 996.449 388.224C995.397 387.481 994.401 387.457 993.883 386.066C993.679 385.54 993.523 384.997 993.415 384.444C993.317 383.886 993.483 383.06 993.17 382.624L993.293 382.864Z'
                opacity='.7' />
          <path id='Vector_201' fill='white'
                d='M1026.1 355.924C1025.94 357.134 1025.42 358.272 1024.58 359.195C1023.75 360.117 1022.65 360.783 1021.42 361.107C1020.19 361.431 1018.89 361.399 1017.68 361.016C1016.47 360.632 1015.41 359.914 1014.62 358.953C1013.84 357.991 1013.37 356.828 1013.28 355.612C1013.19 354.396 1013.47 353.181 1014.1 352.121C1014.73 351.061 1015.67 350.203 1016.81 349.656C1017.95 349.108 1019.24 348.897 1020.5 349.047C1022.2 349.249 1023.74 350.087 1024.79 351.376C1025.84 352.666 1026.31 354.302 1026.1 355.924Z'
                opacity='.1' />
          <path id='Vector_202' fill='white'
                d='M1024.99 355.717C1024.88 356.597 1024.49 357.425 1023.87 358.096C1023.25 358.767 1022.44 359.251 1021.53 359.486C1020.62 359.722 1019.66 359.699 1018.77 359.42C1017.87 359.141 1017.09 358.619 1016.51 357.919C1015.93 357.219 1015.58 356.374 1015.52 355.49C1015.45 354.605 1015.66 353.722 1016.12 352.95C1016.59 352.179 1017.29 351.556 1018.13 351.158C1018.97 350.76 1019.92 350.606 1020.85 350.716C1021.48 350.788 1022.07 350.975 1022.62 351.266C1023.16 351.556 1023.64 351.945 1024.02 352.409C1024.41 352.874 1024.69 353.405 1024.86 353.973C1025.02 354.54 1025.07 355.133 1024.99 355.717Z'
                opacity='.2' />
          <path id='Vector_203' fill='white'
                d='M1020.6 351.974C1020.24 353.337 1020.22 355.104 1017.74 355.007C1018.43 355.441 1019.2 355.841 1019.62 356.59C1020.01 357.363 1020.22 358.219 1020.22 359.087C1020.46 358.298 1020.36 357.521 1020.96 356.76C1021.18 356.477 1021.45 356.248 1021.77 356.09C1022.09 355.932 1022.43 355.849 1022.79 355.846C1022.06 355.31 1021.37 355.293 1021.01 354.288C1020.87 353.908 1020.76 353.516 1020.69 353.116C1020.62 352.713 1020.73 352.117 1020.52 351.802L1020.6 351.974Z'
                opacity='.7' />
          <path id='Vector_204' fill='white'
                d='M993.573 387.292C993.311 386.325 993.027 386.438 992.783 386.852C991.557 386.153 991.734 390.917 993.163 389.713C993.648 389.304 993.735 387.891 993.573 387.292Z' />
        </g>;


      case 23 :
        return <g id='Mini gem_5'>
          <path id='Vector_205' fill={`#${diamondColors[color][0]}`}
                d='M987.809 289.852L1010.81 311.307L993.702 327.326L987.808 290.617L965.874 315.512L989.937 350.62L993.702 327.326L987.809 289.852Z' />
          <path id='Vector_206' fill={`#${diamondColors[color][1]}`} d='M987.771 289.852L965.874 315.389L993.69 327.511L987.771 289.852Z' />
          <path id='Vector_207' fill='white'
                d='M987.771 289.852L987.16 290.564L992.745 326.098L966.498 314.661L965.874 315.389L993.69 327.511L987.771 289.852Z' />
          <path id='Vector_208' fill={`#${diamondColors[color][2]}`} d='M993.621 327.292L989.839 350.62L1010.81 311.249L993.621 327.292Z' />
          <path id='Vector_209' fill={`#${diamondColors[color][2]}`} d='M993.621 327.292L989.839 350.62L1010.81 311.249L993.621 327.292Z' />
          <path id='Vector_210' fill='white' d='M965.874 315.528L990.694 308.023L987.844 289.852L965.874 315.528Z'
                opacity='.2' />
          <path id='Vector_211' fill='white'
                d='M990.558 307.963L1010.81 311.249L987.699 289.852L990.558 307.631V307.963Z' opacity='.2' />
          <path id='Vector_212' fill={`#${diamondColors[color][3]}`}
                d='M992.05 337.007C992.091 336.754 1004.31 317.523 1004.31 317.523L1010.81 311.677L996.05 338.985L989.839 350.62L992.05 337.007Z'
                opacity='.5' />
          <path id='Vector_213' fill={`#${diamondColors[color][3]}`}
                d='M994.361 327.292L1010.08 312.623L1010.81 311.249L993.621 327.292L989.839 350.62L990.902 348.622L994.361 327.292Z' />
          <path id='Vector_214' fill={`#${diamondColors[color][3]}`}
                d='M973.967 327.347L993.262 327.511L965.874 315.528L973.967 327.347Z'
                opacity='.5' />
          <path id='Vector_215' fill='white'
                d='M967.157 315.083C968.001 315.948 970.078 316.071 971.253 316.296C973.701 316.825 976.226 316.915 978.707 316.561C981.712 316.077 984.073 314.755 986.407 312.896C987.93 311.682 988.533 310.488 989.171 308.762C989.36 308.321 989.509 307.864 989.616 307.397C990.384 307.805 990.282 310.409 990.502 311.275C990.935 312.981 991.407 314.637 991.586 316.38C991.924 319.265 992.411 322.132 993.046 324.967C993.166 325.548 993.543 326.298 992.882 326.59C992.358 326.823 991.427 326.366 990.975 326.201C989.635 325.71 988.598 324.866 987.308 324.257C986.053 323.664 984.728 323.349 983.462 322.756C982.123 322.129 980.814 321.561 979.526 320.834C977.554 319.721 975.366 318.851 973.303 317.98C972.266 317.543 971.178 317.13 970.192 316.68C969.412 316.323 967.674 315.911 967.239 315.163L967.157 315.083Z'
                opacity='.4' />
          <path id='Vector_216' fill='white'
                d='M997.429 334.249C1001.78 331.399 1003 325.558 1000.15 321.204C997.3 316.851 991.46 315.632 987.106 318.482C982.752 321.333 981.534 327.173 984.384 331.527C987.235 335.881 993.075 337.1 997.429 334.249Z'
                opacity='.1' />
          <path id='Vector_217' fill='white'
                d='M1000.06 326.884C999.883 328.27 999.304 329.574 998.391 330.63C997.477 331.687 996.271 332.449 994.925 332.82C993.579 333.191 992.153 333.155 990.827 332.716C989.501 332.276 988.336 331.454 987.478 330.352C986.619 329.25 986.107 327.919 986.006 326.526C985.904 325.133 986.218 323.741 986.908 322.527C987.597 321.312 988.631 320.33 989.88 319.703C991.128 319.076 992.533 318.833 993.919 319.006C995.778 319.237 997.468 320.197 998.619 321.674C999.77 323.152 1000.29 325.026 1000.06 326.884Z'
                opacity='.2' />
          <path id='Vector_218' fill='white'
                d='M993.777 321.355C993.22 323.435 993.189 326.136 989.411 325.988C990.454 326.649 991.635 327.26 992.278 328.405C992.875 329.586 993.188 330.892 993.191 332.218C993.553 331.012 993.412 329.826 994.321 328.664C994.651 328.233 995.074 327.883 995.558 327.641C996.042 327.399 996.574 327.272 997.114 327.269C996.001 326.449 994.948 326.423 994.4 324.889C994.184 324.308 994.019 323.709 993.905 323.099C993.803 322.483 993.977 321.572 993.646 321.092L993.777 321.355Z'
                opacity='.7' />
          <path id='Vector_219' fill='white'
                d='M994.47 326.464C994.203 325.602 993.915 325.702 993.666 326.07C992.417 325.447 992.597 329.693 994.052 328.62C994.545 328.256 994.635 326.998 994.47 326.464Z' />
          <path id='Vector_220' fill='white'
                d='M972.242 316.132C972.08 317.434 971.536 318.659 970.678 319.652C969.82 320.644 968.688 321.36 967.423 321.709C966.158 322.058 964.818 322.023 963.573 321.611C962.328 321.198 961.233 320.425 960.427 319.39C959.62 318.355 959.139 317.104 959.044 315.796C958.949 314.488 959.244 313.18 959.891 312.039C960.539 310.898 961.511 309.975 962.683 309.387C963.855 308.798 965.176 308.57 966.478 308.732C968.224 308.949 969.812 309.851 970.893 311.238C971.974 312.626 972.459 314.387 972.242 316.132Z'
                opacity='.1' />
          <path id='Vector_221' fill='white'
                d='M971.399 315.923C971.279 316.888 970.875 317.797 970.238 318.533C969.602 319.269 968.761 319.8 967.823 320.059C966.885 320.317 965.891 320.292 964.967 319.986C964.043 319.679 963.231 319.106 962.633 318.338C962.035 317.57 961.678 316.643 961.607 315.672C961.536 314.701 961.755 313.731 962.236 312.885C962.716 312.038 963.437 311.353 964.306 310.917C965.176 310.48 966.156 310.311 967.122 310.43C967.763 310.51 968.383 310.715 968.945 311.034C969.507 311.354 970.001 311.78 970.398 312.29C970.795 312.8 971.088 313.384 971.26 314.007C971.432 314.63 971.479 315.281 971.399 315.923Z'
                opacity='.2' />
          <path id='Vector_222' fill='white'
                d='M966.887 311.86C966.485 313.301 966.462 315.17 963.734 315.067C964.487 315.524 965.34 315.947 965.805 316.74C966.236 317.557 966.462 318.462 966.464 319.38C966.726 318.545 966.624 317.724 967.28 316.919C967.518 316.621 967.824 316.379 968.174 316.211C968.523 316.044 968.907 315.956 969.297 315.953C968.494 315.386 967.733 315.368 967.337 314.306C967.182 313.904 967.062 313.489 966.98 313.067C966.905 312.64 967.032 312.01 966.793 311.677L966.887 311.86Z'
                opacity='.7' />
          <path id='Vector_223' fill='white'
                d='M991.941 291.288C991.821 292.212 991.417 293.081 990.781 293.785C990.144 294.49 989.304 294.998 988.365 295.245C987.427 295.493 986.433 295.469 985.509 295.176C984.585 294.883 983.773 294.335 983.175 293.6C982.576 292.866 982.219 291.978 982.149 291.049C982.078 290.121 982.297 289.193 982.777 288.383C983.258 287.574 983.979 286.918 984.848 286.501C985.718 286.083 986.698 285.921 987.664 286.036C988.959 286.19 990.137 286.83 990.939 287.815C991.742 288.8 992.102 290.049 991.941 291.288Z'
                opacity='.1' />
          <path id='Vector_224' fill='white'
                d='M991.095 291.129C991.006 291.801 990.707 292.434 990.237 292.946C989.767 293.458 989.145 293.828 988.452 294.008C987.758 294.188 987.023 294.17 986.34 293.957C985.658 293.744 985.057 293.345 984.615 292.811C984.173 292.277 983.909 291.631 983.857 290.956C983.805 290.28 983.966 289.606 984.322 289.017C984.677 288.428 985.21 287.952 985.853 287.648C986.496 287.344 987.22 287.226 987.934 287.31C988.408 287.365 988.866 287.508 989.281 287.73C989.697 287.952 990.061 288.249 990.355 288.604C990.648 288.958 990.865 289.364 990.992 289.797C991.119 290.231 991.154 290.683 991.095 291.129Z'
                opacity='.2' />
          <path id='Vector_225' fill='white'
                d='M987.743 288.271C987.465 289.312 987.449 290.662 985.559 290.588C986.081 290.918 986.671 291.224 986.993 291.796C987.292 292.387 987.448 293.04 987.449 293.703C987.631 293.1 987.56 292.507 988.014 291.926C988.179 291.71 988.391 291.535 988.633 291.414C988.875 291.294 989.141 291.23 989.411 291.228C988.854 290.818 988.328 290.806 988.054 290.038C987.946 289.748 987.863 289.448 987.807 289.143C987.755 288.835 987.842 288.38 987.677 288.14L987.743 288.271Z'
                opacity='.7' />
          <path id='Vector_226' fill='white'
                d='M967.101 315.242C966.9 314.503 966.684 314.589 966.497 314.906C965.561 314.372 965.696 318.01 966.787 317.09C967.158 316.778 967.224 315.699 967.101 315.242Z' />
        </g>;


      case 24 :
        return <g id='Mini gem_6'>
          <path id='Vector_227' fill={`#${diamondColors[color][0]}`}
                d='M1105.78 342.852L1128.78 364.307L1111.68 380.326L1105.78 343.617L1083.85 368.512L1107.91 403.62L1111.68 380.326L1105.78 342.852Z' />
          <path id='Vector_228' fill={`#${diamondColors[color][1]}`} d='M1105.74 342.852L1083.85 368.389L1111.66 380.511L1105.74 342.852Z' />
          <path id='Vector_229' fill='white'
                d='M1105.74 342.852L1105.13 343.564L1110.72 379.098L1084.47 367.661L1083.85 368.389L1111.66 380.511L1105.74 342.852Z' />
          <path id='Vector_230' fill={`#${diamondColors[color][2]}`} d='M1111.59 380.292L1107.81 403.62L1128.78 364.249L1111.59 380.292Z' />
          <path id='Vector_231' fill={`#${diamondColors[color][2]}`} d='M1111.59 380.292L1107.81 403.62L1128.78 364.249L1111.59 380.292Z' />
          <path id='Vector_232' fill='white' d='M1083.85 368.528L1108.67 361.023L1105.82 342.852L1083.85 368.528Z'
                opacity='.2' />
          <path id='Vector_233' fill='white'
                d='M1108.53 360.963L1128.78 364.249L1105.67 342.852L1108.53 360.631V360.963Z' opacity='.2' />
          <path id='Vector_234' fill={`#${diamondColors[color][3]}`}
                d='M1110.02 390.007C1110.06 389.754 1122.28 370.523 1122.28 370.523L1128.78 364.677L1114.02 391.985L1107.81 403.62L1110.02 390.007Z'
                opacity='.5' />
          <path id='Vector_235' fill={`#${diamondColors[color][3]}`}
                d='M1112.33 380.292L1128.05 365.623L1128.78 364.249L1111.59 380.292L1107.81 403.62L1108.88 401.622L1112.33 380.292Z' />
          <path id='Vector_236' fill={`#${diamondColors[color][3]}`}
                d='M1091.94 380.347L1111.24 380.511L1083.85 368.528L1091.94 380.347Z'
                opacity='.5' />
          <path id='Vector_237' fill='white'
                d='M1085.13 368.083C1085.97 368.948 1088.05 369.071 1089.23 369.296C1091.67 369.825 1094.2 369.915 1096.68 369.561C1099.69 369.077 1102.05 367.755 1104.38 365.896C1105.9 364.682 1106.51 363.488 1107.14 361.762C1107.33 361.321 1107.48 360.864 1107.59 360.397C1108.36 360.805 1108.26 363.409 1108.48 364.275C1108.91 365.981 1109.38 367.637 1109.56 369.38C1109.9 372.265 1110.38 375.132 1111.02 377.967C1111.14 378.548 1111.52 379.298 1110.86 379.59C1110.33 379.823 1109.4 379.366 1108.95 379.201C1107.61 378.71 1106.57 377.866 1105.28 377.257C1104.03 376.664 1102.7 376.349 1101.44 375.756C1100.1 375.129 1098.79 374.561 1097.5 373.834C1095.53 372.721 1093.34 371.851 1091.28 370.98C1090.24 370.543 1089.15 370.13 1088.17 369.68C1087.39 369.323 1085.65 368.911 1085.21 368.163L1085.13 368.083Z'
                opacity='.4' />
          <path id='Vector_238' fill='white'
                d='M1115.4 387.249C1119.76 384.399 1120.97 378.558 1118.12 374.204C1115.27 369.851 1109.43 368.632 1105.08 371.482C1100.73 374.333 1099.51 380.173 1102.36 384.527C1105.21 388.881 1111.05 390.1 1115.4 387.249Z'
                opacity='.1' />
          <path id='Vector_239' fill='white'
                d='M1118.03 379.884C1117.86 381.27 1117.28 382.574 1116.36 383.63C1115.45 384.687 1114.24 385.449 1112.9 385.82C1111.55 386.191 1110.13 386.155 1108.8 385.716C1107.47 385.276 1106.31 384.454 1105.45 383.352C1104.59 382.25 1104.08 380.919 1103.98 379.526C1103.88 378.133 1104.19 376.741 1104.88 375.527C1105.57 374.312 1106.6 373.33 1107.85 372.703C1109.1 372.076 1110.51 371.833 1111.89 372.006C1113.75 372.237 1115.44 373.197 1116.59 374.674C1117.74 376.152 1118.26 378.026 1118.03 379.884Z'
                opacity='.2' />
          <path id='Vector_240' fill='white'
                d='M1111.75 374.355C1111.19 376.435 1111.16 379.136 1107.38 378.988C1108.43 379.649 1109.61 380.26 1110.25 381.405C1110.85 382.586 1111.16 383.892 1111.16 385.218C1111.53 384.012 1111.39 382.826 1112.29 381.664C1112.62 381.233 1113.05 380.883 1113.53 380.641C1114.02 380.399 1114.55 380.272 1115.09 380.269C1113.97 379.449 1112.92 379.423 1112.37 377.889C1112.16 377.308 1111.99 376.709 1111.88 376.099C1111.78 375.483 1111.95 374.572 1111.62 374.092L1111.75 374.355Z'
                opacity='.7' />
          <path id='Vector_241' fill='white'
                d='M1112.44 379.464C1112.18 378.602 1111.89 378.702 1111.64 379.07C1110.39 378.447 1110.57 382.693 1112.03 381.62C1112.52 381.256 1112.61 379.998 1112.44 379.464Z' />
          <path id='Vector_242' fill='white'
                d='M1090.22 369.132C1090.05 370.434 1089.51 371.659 1088.65 372.652C1087.79 373.644 1086.66 374.36 1085.4 374.709C1084.13 375.058 1082.79 375.023 1081.55 374.611C1080.3 374.198 1079.21 373.425 1078.4 372.39C1077.59 371.355 1077.11 370.104 1077.02 368.796C1076.92 367.488 1077.22 366.18 1077.86 365.039C1078.51 363.898 1079.48 362.975 1080.66 362.387C1081.83 361.798 1083.15 361.57 1084.45 361.732C1086.2 361.949 1087.79 362.851 1088.87 364.238C1089.95 365.626 1090.43 367.387 1090.22 369.132Z'
                opacity='.1' />
          <path id='Vector_243' fill='white'
                d='M1089.37 368.923C1089.25 369.888 1088.85 370.797 1088.21 371.533C1087.58 372.269 1086.73 372.8 1085.8 373.059C1084.86 373.317 1083.86 373.292 1082.94 372.986C1082.02 372.679 1081.2 372.106 1080.61 371.338C1080.01 370.57 1079.65 369.643 1079.58 368.672C1079.51 367.701 1079.73 366.731 1080.21 365.885C1080.69 365.038 1081.41 364.353 1082.28 363.917C1083.15 363.48 1084.13 363.311 1085.1 363.43C1085.74 363.51 1086.36 363.715 1086.92 364.034C1087.48 364.354 1087.97 364.78 1088.37 365.29C1088.77 365.8 1089.06 366.384 1089.23 367.007C1089.41 367.63 1089.45 368.281 1089.37 368.923Z'
                opacity='.2' />
          <path id='Vector_244' fill='white'
                d='M1084.86 364.86C1084.46 366.301 1084.44 368.17 1081.71 368.067C1082.46 368.524 1083.31 368.947 1083.78 369.74C1084.21 370.557 1084.44 371.462 1084.44 372.38C1084.7 371.545 1084.6 370.724 1085.25 369.919C1085.49 369.621 1085.8 369.379 1086.15 369.211C1086.5 369.044 1086.88 368.956 1087.27 368.953C1086.47 368.386 1085.71 368.368 1085.31 367.306C1085.16 366.904 1085.04 366.489 1084.95 366.067C1084.88 365.64 1085.01 365.01 1084.77 364.677L1084.86 364.86Z'
                opacity='.7' />
          <path id='Vector_245' fill='white'
                d='M1109.91 344.288C1109.79 345.212 1109.39 346.081 1108.75 346.785C1108.12 347.49 1107.28 347.998 1106.34 348.245C1105.4 348.493 1104.41 348.469 1103.48 348.176C1102.56 347.883 1101.75 347.335 1101.15 346.6C1100.55 345.866 1100.19 344.978 1100.12 344.049C1100.05 343.121 1100.27 342.193 1100.75 341.383C1101.23 340.574 1101.95 339.918 1102.82 339.501C1103.69 339.083 1104.67 338.921 1105.64 339.036C1106.93 339.19 1108.11 339.83 1108.91 340.815C1109.72 341.8 1110.08 343.049 1109.91 344.288Z'
                opacity='.1' />
          <path id='Vector_246' fill='white'
                d='M1109.07 344.129C1108.98 344.801 1108.68 345.434 1108.21 345.946C1107.74 346.458 1107.12 346.828 1106.43 347.008C1105.73 347.188 1105 347.17 1104.31 346.957C1103.63 346.744 1103.03 346.345 1102.59 345.811C1102.15 345.277 1101.88 344.631 1101.83 343.956C1101.78 343.28 1101.94 342.606 1102.3 342.017C1102.65 341.428 1103.18 340.952 1103.83 340.648C1104.47 340.344 1105.19 340.226 1105.91 340.31C1106.38 340.365 1106.84 340.508 1107.25 340.73C1107.67 340.952 1108.03 341.249 1108.33 341.604C1108.62 341.958 1108.84 342.364 1108.97 342.797C1109.09 343.231 1109.13 343.683 1109.07 344.129Z'
                opacity='.2' />
          <path id='Vector_247' fill='white'
                d='M1105.72 341.271C1105.44 342.312 1105.42 343.662 1103.53 343.588C1104.05 343.918 1104.64 344.224 1104.97 344.796C1105.27 345.387 1105.42 346.04 1105.42 346.703C1105.6 346.1 1105.53 345.507 1105.99 344.926C1106.15 344.71 1106.36 344.535 1106.61 344.414C1106.85 344.294 1107.11 344.23 1107.38 344.228C1106.83 343.818 1106.3 343.806 1106.03 343.038C1105.92 342.748 1105.84 342.448 1105.78 342.143C1105.73 341.835 1105.82 341.38 1105.65 341.14L1105.72 341.271Z'
                opacity='.7' />
          <path id='Vector_248' fill='white'
                d='M1085.07 368.242C1084.87 367.503 1084.66 367.589 1084.47 367.906C1083.53 367.372 1083.67 371.01 1084.76 370.09C1085.13 369.778 1085.2 368.699 1085.07 368.242Z' />
        </g>;


      case 25:
        return <g id='Mini gem_7'>
          <path id='Vector_249' fill={`#${diamondColors[color][0]}`}
                d='M1072.78 147.852L1095.78 169.307L1078.68 185.326L1072.78 148.617L1050.85 173.512L1074.91 208.62L1078.68 185.326L1072.78 147.852Z' />
          <path id='Vector_250' fill={`#${diamondColors[color][1]}`} d='M1072.74 147.852L1050.85 173.389L1078.66 185.511L1072.74 147.852Z' />
          <path id='Vector_251' fill='white'
                d='M1072.74 147.852L1072.13 148.564L1077.72 184.098L1051.47 172.661L1050.85 173.389L1078.66 185.511L1072.74 147.852Z' />
          <path id='Vector_252' fill={`#${diamondColors[color][2]}`} d='M1078.59 185.292L1074.81 208.62L1095.78 169.249L1078.59 185.292Z' />
          <path id='Vector_253' fill={`#${diamondColors[color][2]}`} d='M1078.59 185.292L1074.81 208.62L1095.78 169.249L1078.59 185.292Z' />
          <path id='Vector_254' fill='white' d='M1050.85 173.528L1075.67 166.023L1072.82 147.852L1050.85 173.528Z'
                opacity='.2' />
          <path id='Vector_255' fill='white'
                d='M1075.53 165.963L1095.78 169.249L1072.67 147.852L1075.53 165.631V165.963Z' opacity='.2' />
          <path id='Vector_256' fill={`#${diamondColors[color][3]}`}
                d='M1077.02 195.007C1077.06 194.754 1089.28 175.523 1089.28 175.523L1095.78 169.677L1081.02 196.985L1074.81 208.62L1077.02 195.007Z'
                opacity='.5' />
          <path id='Vector_257' fill={`#${diamondColors[color][3]}`}
                d='M1079.33 185.292L1095.05 170.623L1095.78 169.249L1078.59 185.292L1074.81 208.62L1075.88 206.622L1079.33 185.292Z' />
          <path id='Vector_258' fill={`#${diamondColors[color][3]}`}
                d='M1058.94 185.347L1078.24 185.511L1050.85 173.528L1058.94 185.347Z'
                opacity='.5' />
          <path id='Vector_259' fill='white'
                d='M1052.13 173.083C1052.97 173.948 1055.05 174.071 1056.23 174.296C1058.67 174.825 1061.2 174.915 1063.68 174.561C1066.69 174.077 1069.05 172.755 1071.38 170.896C1072.9 169.682 1073.51 168.488 1074.14 166.762C1074.33 166.321 1074.48 165.864 1074.59 165.397C1075.36 165.805 1075.26 168.409 1075.48 169.275C1075.91 170.981 1076.38 172.637 1076.56 174.38C1076.9 177.265 1077.38 180.132 1078.02 182.967C1078.14 183.548 1078.52 184.298 1077.86 184.59C1077.33 184.823 1076.4 184.366 1075.95 184.201C1074.61 183.71 1073.57 182.866 1072.28 182.257C1071.03 181.664 1069.7 181.349 1068.44 180.756C1067.1 180.129 1065.79 179.561 1064.5 178.834C1062.53 177.721 1060.34 176.851 1058.28 175.98C1057.24 175.543 1056.15 175.13 1055.17 174.68C1054.39 174.323 1052.65 173.911 1052.21 173.163L1052.13 173.083Z'
                opacity='.4' />
          <path id='Vector_260' fill='white'
                d='M1082.4 192.249C1086.76 189.399 1087.97 183.558 1085.12 179.204C1082.27 174.851 1076.43 173.632 1072.08 176.482C1067.73 179.333 1066.51 185.173 1069.36 189.527C1072.21 193.881 1078.05 195.1 1082.4 192.249Z'
                opacity='.1' />
          <path id='Vector_261' fill='white'
                d='M1085.03 184.884C1084.86 186.27 1084.28 187.574 1083.36 188.63C1082.45 189.687 1081.24 190.449 1079.9 190.82C1078.55 191.191 1077.13 191.155 1075.8 190.716C1074.47 190.276 1073.31 189.454 1072.45 188.352C1071.59 187.25 1071.08 185.919 1070.98 184.526C1070.88 183.133 1071.19 181.741 1071.88 180.527C1072.57 179.312 1073.6 178.33 1074.85 177.703C1076.1 177.076 1077.51 176.833 1078.89 177.006C1080.75 177.237 1082.44 178.197 1083.59 179.674C1084.74 181.152 1085.26 183.026 1085.03 184.884Z'
                opacity='.2' />
          <path id='Vector_262' fill='white'
                d='M1078.75 179.355C1078.19 181.435 1078.16 184.136 1074.38 183.988C1075.43 184.649 1076.61 185.26 1077.25 186.405C1077.85 187.586 1078.16 188.892 1078.16 190.218C1078.53 189.012 1078.39 187.826 1079.29 186.664C1079.62 186.233 1080.05 185.883 1080.53 185.641C1081.02 185.399 1081.55 185.272 1082.09 185.269C1080.97 184.449 1079.92 184.423 1079.37 182.889C1079.16 182.308 1078.99 181.709 1078.88 181.099C1078.78 180.483 1078.95 179.572 1078.62 179.092L1078.75 179.355Z'
                opacity='.7' />
          <path id='Vector_263' fill='white'
                d='M1079.44 184.464C1079.18 183.602 1078.89 183.702 1078.64 184.07C1077.39 183.447 1077.57 187.693 1079.03 186.62C1079.52 186.256 1079.61 184.998 1079.44 184.464Z' />
          <path id='Vector_264' fill='white'
                d='M1057.22 174.132C1057.05 175.434 1056.51 176.659 1055.65 177.652C1054.79 178.644 1053.66 179.36 1052.4 179.709C1051.13 180.058 1049.79 180.023 1048.55 179.611C1047.3 179.198 1046.21 178.425 1045.4 177.39C1044.59 176.355 1044.11 175.104 1044.02 173.796C1043.92 172.488 1044.22 171.18 1044.86 170.039C1045.51 168.898 1046.48 167.975 1047.66 167.387C1048.83 166.798 1050.15 166.57 1051.45 166.732C1053.2 166.949 1054.79 167.851 1055.87 169.238C1056.95 170.626 1057.43 172.387 1057.22 174.132Z'
                opacity='.1' />
          <path id='Vector_265' fill='white'
                d='M1056.37 173.923C1056.25 174.888 1055.85 175.797 1055.21 176.533C1054.58 177.269 1053.73 177.8 1052.8 178.059C1051.86 178.317 1050.86 178.292 1049.94 177.986C1049.02 177.679 1048.2 177.106 1047.61 176.338C1047.01 175.57 1046.65 174.643 1046.58 173.672C1046.51 172.701 1046.73 171.731 1047.21 170.885C1047.69 170.038 1048.41 169.353 1049.28 168.917C1050.15 168.48 1051.13 168.311 1052.1 168.43C1052.74 168.51 1053.36 168.715 1053.92 169.034C1054.48 169.354 1054.97 169.78 1055.37 170.29C1055.77 170.8 1056.06 171.384 1056.23 172.007C1056.41 172.63 1056.45 173.281 1056.37 173.923Z'
                opacity='.2' />
          <path id='Vector_266' fill='white'
                d='M1051.86 169.86C1051.46 171.301 1051.44 173.17 1048.71 173.067C1049.46 173.524 1050.31 173.947 1050.78 174.74C1051.21 175.557 1051.44 176.462 1051.44 177.38C1051.7 176.545 1051.6 175.724 1052.25 174.919C1052.49 174.621 1052.8 174.379 1053.15 174.211C1053.5 174.044 1053.88 173.956 1054.27 173.953C1053.47 173.386 1052.71 173.368 1052.31 172.306C1052.16 171.904 1052.04 171.489 1051.95 171.067C1051.88 170.64 1052.01 170.01 1051.77 169.677L1051.86 169.86Z'
                opacity='.7' />
          <path id='Vector_267' fill='white'
                d='M1076.91 149.288C1076.79 150.212 1076.39 151.081 1075.75 151.785C1075.12 152.49 1074.28 152.998 1073.34 153.245C1072.4 153.493 1071.41 153.469 1070.48 153.176C1069.56 152.883 1068.75 152.335 1068.15 151.6C1067.55 150.866 1067.19 149.978 1067.12 149.049C1067.05 148.121 1067.27 147.193 1067.75 146.383C1068.23 145.574 1068.95 144.918 1069.82 144.501C1070.69 144.083 1071.67 143.921 1072.64 144.036C1073.93 144.19 1075.11 144.83 1075.91 145.815C1076.72 146.8 1077.08 148.049 1076.91 149.288Z'
                opacity='.1' />
          <path id='Vector_268' fill='white'
                d='M1076.07 149.129C1075.98 149.801 1075.68 150.434 1075.21 150.946C1074.74 151.458 1074.12 151.828 1073.43 152.008C1072.73 152.188 1072 152.17 1071.31 151.957C1070.63 151.744 1070.03 151.345 1069.59 150.811C1069.15 150.277 1068.88 149.631 1068.83 148.956C1068.78 148.28 1068.94 147.606 1069.3 147.017C1069.65 146.428 1070.18 145.952 1070.83 145.648C1071.47 145.344 1072.19 145.226 1072.91 145.31C1073.38 145.365 1073.84 145.508 1074.25 145.73C1074.67 145.952 1075.03 146.249 1075.33 146.604C1075.62 146.958 1075.84 147.364 1075.97 147.797C1076.09 148.231 1076.13 148.683 1076.07 149.129Z'
                opacity='.2' />
          <path id='Vector_269' fill='white'
                d='M1072.72 146.271C1072.44 147.312 1072.42 148.662 1070.53 148.588C1071.05 148.918 1071.64 149.224 1071.97 149.796C1072.27 150.387 1072.42 151.04 1072.42 151.703C1072.6 151.1 1072.53 150.507 1072.99 149.926C1073.15 149.71 1073.36 149.535 1073.61 149.414C1073.85 149.294 1074.11 149.23 1074.38 149.228C1073.83 148.818 1073.3 148.806 1073.03 148.038C1072.92 147.748 1072.84 147.448 1072.78 147.143C1072.73 146.835 1072.82 146.38 1072.65 146.14L1072.72 146.271Z'
                opacity='.7' />
          <path id='Vector_270' fill='white'
                d='M1052.07 173.242C1051.87 172.503 1051.66 172.589 1051.47 172.906C1050.53 172.372 1050.67 176.01 1051.76 175.09C1052.13 174.778 1052.2 173.699 1052.07 173.242Z' />
        </g>;


      case 26:
        return <g id='Mini gem_8'>
          <path id='Vector_271' fill={`#${diamondColors[color][0]}`}
                d='M1036.02 224.686L1064 250.789L1043.19 270.28L1036.02 225.618L1009.33 255.906L1038.61 298.62L1043.19 270.28L1036.02 224.686Z' />
          <path id='Vector_272' fill={`#${diamondColors[color][1]}`} d='M1035.97 224.686L1009.33 255.756L1043.17 270.504L1035.97 224.686Z' />
          <path id='Vector_273' fill='white'
                d='M1035.97 224.686L1035.23 225.553L1042.02 268.786L1010.09 254.87L1009.33 255.756L1043.17 270.504L1035.97 224.686Z' />
          <path id='Vector_274' fill={`#${diamondColors[color][2]}`} d='M1043.09 270.238L1038.49 298.62L1064 250.719L1043.09 270.238Z' />
          <path id='Vector_275' fill={`#${diamondColors[color][2]}`} d='M1043.09 270.238L1038.49 298.62L1064 250.719L1043.09 270.238Z' />
          <path id='Vector_276' fill='white' d='M1009.33 255.926L1039.53 246.794L1036.06 224.686L1009.33 255.926Z'
                opacity='.2' />
          <path id='Vector_277' fill='white' d='M1039.36 246.721L1064 250.719L1035.88 224.686L1039.36 246.317V246.721Z'
                opacity='.2' />
          <path id='Vector_278' fill={`#${diamondColors[color][3]}`}
                d='M1041.18 282.058C1041.23 281.75 1056.1 258.352 1056.1 258.352L1064 251.24L1046.05 284.465L1038.49 298.62L1041.18 282.058Z'
                opacity='.5' />
          <path id='Vector_279' fill={`#${diamondColors[color][3]}`}
                d='M1043.99 270.238L1063.11 252.391L1064 250.719L1043.09 270.238L1038.49 298.62L1039.78 296.189L1043.99 270.238Z' />
          <path id='Vector_280' fill={`#${diamondColors[color][3]}`}
                d='M1019.18 270.305L1042.65 270.504L1009.33 255.926L1019.18 270.305Z'
                opacity='.5' />
          <path id='Vector_281' fill='white'
                d='M1010.89 255.384C1011.92 256.437 1014.45 256.585 1015.88 256.86C1018.85 257.503 1021.93 257.613 1024.94 257.183C1028.6 256.593 1031.47 254.985 1034.31 252.723C1036.17 251.246 1036.9 249.794 1037.68 247.694C1037.91 247.157 1038.09 246.601 1038.22 246.033C1039.15 246.529 1039.03 249.697 1039.3 250.751C1039.82 252.826 1040.4 254.841 1040.61 256.962C1041.02 260.473 1041.62 263.96 1042.39 267.41C1042.54 268.116 1043 269.029 1042.19 269.384C1041.55 269.667 1040.42 269.111 1039.87 268.91C1038.24 268.314 1036.98 267.286 1035.41 266.545C1033.88 265.824 1032.27 265.44 1030.73 264.719C1029.1 263.956 1027.51 263.266 1025.94 262.381C1023.54 261.027 1020.88 259.968 1018.37 258.909C1017.11 258.377 1015.78 257.874 1014.58 257.326C1013.64 256.892 1011.52 256.392 1010.99 255.481L1010.89 255.384Z'
                opacity='.4' />
          <path id='Vector_282' fill='white'
                d='M1047.72 278.702C1053.02 275.234 1054.5 268.129 1051.03 262.831C1047.57 257.534 1040.46 256.052 1035.16 259.52C1029.87 262.988 1028.38 270.094 1031.85 275.391C1035.32 280.688 1042.43 282.17 1047.72 278.702Z'
                opacity='.1' />
          <path id='Vector_283' fill='white'
                d='M1050.92 269.742C1050.71 271.428 1050 273.014 1048.89 274.299C1047.78 275.585 1046.31 276.512 1044.68 276.964C1043.04 277.415 1041.3 277.371 1039.69 276.837C1038.08 276.302 1036.66 275.301 1035.61 273.961C1034.57 272.62 1033.95 271 1033.82 269.306C1033.7 267.611 1034.08 265.918 1034.92 264.44C1035.76 262.963 1037.02 261.767 1038.54 261.005C1040.06 260.242 1041.77 259.947 1043.45 260.157C1045.71 260.438 1047.77 261.605 1049.17 263.403C1050.57 265.2 1051.2 267.481 1050.92 269.742Z'
                opacity='.2' />
          <path id='Vector_284' fill='white'
                d='M1043.28 263.014C1042.6 265.546 1042.56 268.831 1037.97 268.651C1039.24 269.455 1040.67 270.199 1041.46 271.592C1042.18 273.029 1042.56 274.618 1042.57 276.231C1043.01 274.764 1042.84 273.321 1043.94 271.907C1044.34 271.382 1044.86 270.957 1045.45 270.663C1046.03 270.368 1046.68 270.214 1047.34 270.209C1045.98 269.213 1044.7 269.181 1044.04 267.315C1043.77 266.608 1043.57 265.879 1043.44 265.137C1043.31 264.387 1043.52 263.279 1043.12 262.694L1043.28 263.014Z'
                opacity='.7' />
          <path id='Vector_285' fill='white'
                d='M1044.12 269.23C1043.8 268.182 1043.45 268.303 1043.14 268.752C1041.63 267.993 1041.84 273.159 1043.61 271.853C1044.21 271.411 1044.32 269.88 1044.12 269.23Z' />
          <path id='Vector_286' fill='white'
                d='M1017.08 256.66C1016.88 258.244 1016.22 259.735 1015.18 260.942C1014.13 262.15 1012.75 263.021 1011.22 263.445C1009.68 263.869 1008.05 263.828 1006.53 263.326C1005.02 262.824 1003.68 261.884 1002.7 260.624C1001.72 259.365 1001.14 257.843 1001.02 256.251C1000.91 254.659 1001.26 253.069 1002.05 251.681C1002.84 250.293 1004.02 249.17 1005.45 248.453C1006.88 247.737 1008.48 247.46 1010.07 247.657C1012.19 247.921 1014.12 249.018 1015.44 250.706C1016.75 252.395 1017.34 254.536 1017.08 256.66Z'
                opacity='.1' />
          <path id='Vector_287' fill='white'
                d='M1016.05 256.405C1015.91 257.58 1015.42 258.686 1014.64 259.581C1013.87 260.477 1012.84 261.123 1011.7 261.438C1010.56 261.752 1009.35 261.721 1008.23 261.349C1007.1 260.976 1006.12 260.279 1005.39 259.344C1004.66 258.41 1004.23 257.281 1004.14 256.1C1004.05 254.919 1004.32 253.739 1004.9 252.709C1005.49 251.68 1006.37 250.846 1007.42 250.315C1008.48 249.783 1009.67 249.577 1010.85 249.723C1011.63 249.82 1012.38 250.07 1013.07 250.458C1013.75 250.846 1014.35 251.366 1014.84 251.986C1015.32 252.607 1015.68 253.316 1015.88 254.075C1016.09 254.833 1016.15 255.625 1016.05 256.405Z'
                opacity='.2' />
          <path id='Vector_288' fill='white'
                d='M1010.56 251.463C1010.07 253.215 1010.05 255.49 1006.73 255.364C1007.64 255.92 1008.68 256.435 1009.25 257.4C1009.77 258.394 1010.05 259.495 1010.05 260.612C1010.37 259.596 1010.24 258.597 1011.04 257.618C1011.33 257.255 1011.7 256.96 1012.13 256.756C1012.55 256.553 1013.02 256.446 1013.5 256.443C1012.52 255.753 1011.59 255.731 1011.11 254.438C1010.92 253.949 1010.78 253.445 1010.68 252.931C1010.59 252.412 1010.74 251.645 1010.45 251.24L1010.56 251.463Z'
                opacity='.7' />
          <path id='Vector_289' fill='white'
                d='M1041.05 226.433C1040.9 227.557 1040.41 228.615 1039.63 229.472C1038.86 230.329 1037.84 230.947 1036.7 231.248C1035.55 231.55 1034.34 231.52 1033.22 231.164C1032.1 230.808 1031.11 230.14 1030.38 229.247C1029.65 228.353 1029.22 227.273 1029.13 226.143C1029.05 225.014 1029.31 223.885 1029.9 222.9C1030.48 221.915 1031.36 221.117 1032.42 220.609C1033.47 220.101 1034.67 219.904 1035.84 220.044C1037.42 220.231 1038.85 221.01 1039.83 222.208C1040.8 223.406 1041.24 224.926 1041.05 226.433Z'
                opacity='.1' />
          <path id='Vector_290' fill='white'
                d='M1040.02 226.241C1039.91 227.058 1039.54 227.828 1038.97 228.451C1038.4 229.074 1037.64 229.524 1036.8 229.743C1035.96 229.962 1035.06 229.94 1034.23 229.681C1033.4 229.422 1032.67 228.937 1032.13 228.287C1031.59 227.637 1031.27 226.851 1031.21 226.03C1031.15 225.208 1031.34 224.387 1031.78 223.67C1032.21 222.954 1032.86 222.374 1033.64 222.005C1034.42 221.635 1035.3 221.492 1036.17 221.594C1036.75 221.661 1037.3 221.835 1037.81 222.105C1038.31 222.375 1038.76 222.736 1039.12 223.168C1039.47 223.599 1039.74 224.093 1039.89 224.62C1040.04 225.147 1040.09 225.698 1040.02 226.241Z'
                opacity='.2' />
          <path id='Vector_291' fill='white'
                d='M1035.94 222.763C1035.6 224.029 1035.58 225.672 1033.28 225.582C1033.92 225.984 1034.63 226.356 1035.03 227.052C1035.39 227.77 1035.58 228.565 1035.58 229.372C1035.8 228.638 1035.72 227.917 1036.27 227.21C1036.47 226.947 1036.73 226.734 1037.02 226.587C1037.32 226.44 1037.64 226.363 1037.97 226.361C1037.29 225.862 1036.65 225.847 1036.32 224.913C1036.18 224.56 1036.08 224.195 1036.02 223.824C1035.95 223.45 1036.06 222.896 1035.86 222.603L1035.94 222.763Z'
                opacity='.7' />
          <path id='Vector_292' fill='white'
                d='M1010.82 255.577C1010.58 254.678 1010.32 254.783 1010.09 255.168C1008.95 254.519 1009.11 258.945 1010.44 257.826C1010.89 257.446 1010.97 256.133 1010.82 255.577Z' />
        </g>;


    }
  }, [index]);
  return (
    <g className='gem-wrapper'
       ref={gemRef}
       data-tooltip-id='double-click-tip'
       onClick={onGameSelect}
       onPointerOver={(e) => showTheGameInfoTooltip({ x: e.pageX, y: e.pageY, level, title })}
       onPointerOut={()=>showTheGameInfoTooltip(null)}
    >
      {renderedCoreGem}
      {renderedMiniGem}
    </g>
  );
}

export default memo(Gems);