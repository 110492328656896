import { RouteObject, Navigate } from 'react-router-dom';
import { PATH_GLMS_PLAYER } from 'src/routes/paths';
import { PlayerMainContainer } from 'src/containers';
import { WorldFactory } from '../worlds';
import { GameFactory } from '../games';
import { GamesList } from '../components';
import { IslandGameCreate } from '../games/island-games';
import { GameMechanicPickerView, IslandGameEditView, IslandGamePlayView } from '../games/island-games/sections';
import Profile from '../components/profile';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The route of the worlds, courses and classes picker
 */
export const glmsPlayerCoursesRoutes: RouteObject = {
  path: PATH_GLMS_PLAYER.classes.root,
  element: <PlayerMainContainer />,
  children: [
    {
      path: PATH_GLMS_PLAYER.classes.course.full,
      element: <WorldFactory />
    },
    {
      // ToDo add class picker layout view
      element: <Navigate
        to={PATH_GLMS_PLAYER.classes.course.redirect('demoClassId', 'demoCourseId')}
        replace
      />,
      index: true
    },
    {
      path: PATH_GLMS_PLAYER.classes.islands.full,
      element: <GamesList />
    }
  ]
};

/**
 * @description - The route of the games playing/editing part
 */
export const glmsPlayerGamesRoutes: RouteObject = {
  path: PATH_GLMS_PLAYER.games.root,
  element: <PlayerMainContainer />,
  children: [
    {
      path: PATH_GLMS_PLAYER.games.game.full,
      element: <GameFactory />
    },
    {
      path: `${PATH_GLMS_PLAYER.games.game.full}/:fileId`,
      element: <GameFactory />
    },
    {
      path: PATH_GLMS_PLAYER.games.game.create.full,
      element: <IslandGameCreate />
    },
    {
      path: PATH_GLMS_PLAYER.games.game.play.full,
      element: <IslandGamePlayView />
    },
    {
      path: PATH_GLMS_PLAYER.games.game.edit.full,
      element: <IslandGameEditView />
    },
    {
      path: PATH_GLMS_PLAYER.games.game.create.mechanics.full,
      element: <GameMechanicPickerView />
    },
    {
      element: <Navigate
        to={PATH_GLMS_PLAYER.games.not_found.root}
        replace
      />,
      index: true
    }
  ]
};

/**
 * @description - The route of the profile part
 */
export const glmsProfileRoutes: RouteObject = {
  path: PATH_GLMS_PLAYER.profile.root,
  element: <PlayerMainContainer />,
  children: [{
    path: PATH_GLMS_PLAYER.profile.full,
    element: <Profile />
  }
  ]
};