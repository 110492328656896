import { Col } from 'react-bootstrap';
import classnames from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';
import ImageBtn from 'src/assets/svg/buttons/image-btn';
import { CardData } from '../types';
import { uploadFile } from "../../../../../api/v0";

function EditableCard({ saveData, data, index, gameId }:
                        { saveData: (data: CardData, key: string) => void, data: CardData, index: string, gameId: string }) {
  const [mechanics, setMechanics] = useState<string>('');

  function addText(val: string) {
    saveData({ word: val }, index);
  }

  const handleFileUpload = (file: File) => {
    if (gameId) {
      uploadFile(
        `${gameId}/image/${file.name}`,
        file,
        (url: string) => {
          saveData({ image: url }, index);
        },
        (error: string) => console.log(error)
      );
    }
  };

  const onUpload = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files?.length) {
      let file = files[0];
      handleFileUpload(file);
    }
    setMechanics('img');
  }, []);

  useEffect(() => {
    if (!(data.word || data.image)) setMechanics('');
  }, [data]);

  return (
    <Col>
      <div className={classnames('card is-flipped')}>
        <div className='card-face card-back-face flex-row'>
          {!mechanics ? <>
            <span className='select-text' onClick={() => setMechanics('text')}>Aa𝙸</span>
            <label onClick={() => setMechanics('')} className='col-12'>
              <input type='file' id='file-upload' onChange={onUpload}
                     accept='image/png, image/gif, image/jpeg, image/svg' />
              <span><ImageBtn /></span>
            </label>
          </> : <>
            {mechanics === 'text' ? <textarea value={data.word}
                                              placeholder='type your text here...'
                                              onChange={(e) => addText(e.target.value)} /> :
              <img className='img-fluid' src={data.image} />}</>}
        </div>
      </div>
    </Col>
  );
}

export default memo(EditableCard);
