import { memo } from 'react';
import { diamondColors } from '../../../helpers';
import { TGameTypeCategory } from "../../../@types";

function SelectedIsland({ colorClass, gameType } : { colorClass : string, gameType?: TGameTypeCategory }){
  return (
    <svg viewBox="0 0 787 887" fill="none" xmlns="http://www.w3.org/2000/svg">

      <g style={{mixBlendMode:"multiply"}}>
        <path d="M87 808.211L113.389 813.315H123.236L242.61 867.612L295.388 861.923L362.569 886.734L448.276 877.938L573.304 834.436H684.513L703.367 785.28L778.76 762.512L786.295 692.318L701.475 668.37C701.475 668.37 654.351 610.815 646.817 613.175C639.282 615.535 576.447 612.229 568.281 613.175C560.116 614.121 333.645 668.37 333.645 668.37L87.0117 808.211H87Z" fill="#C3C8CA"/>
      </g>
      <path d="M166.569 651.118L258.69 598.341V458.863L299.646 414.157L319.668 319.385L370.646 296.769L396.591 172.36L474.437 140.948L524.703 182.021L546.372 375.935L600.096 436.247V515.413L644.077 553.11L660.408 651.118L644.077 756.673L553.603 771.754L498.314 839.612H413.494L331.607 793.12L302.695 729.035L179.138 756.673L166.569 651.118Z" fill="#A06E4D"/>
      <path d="M396.591 172.36L474.437 140.948L524.703 182.021L447.675 210.057L396.591 172.36Z" fill="url(#paint0_linear_1_23)"/>
      <path d="M370.646 296.769L396.112 336.977L447.675 210.057L396.591 172.36L370.646 296.769Z" fill="#AD7A57"/>
      <path d="M319.668 319.385L396.112 336.977L370.646 296.769L319.668 319.385Z" fill="url(#paint1_linear_1_23)"/>
      <path d="M299.646 414.157L377.106 431.762L396.112 336.977L319.668 319.385L299.646 414.157Z" fill="#AD7A57"/>
      <path d="M258.69 458.863L279.67 475.205L377.106 431.762L299.646 414.157L258.69 458.863Z" fill="url(#paint2_linear_1_23)"/>
      <path d="M258.69 598.341L274.635 612.593L279.67 475.205L258.69 458.863V598.341Z" fill="#AD7A57"/>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M377.106 431.762L388.566 643.584L274.635 612.593L279.67 475.205L377.106 431.762Z" fill="#A97C50"/>
      </g>
      <path d="M166.569 651.118L274.635 612.593L258.69 598.341L166.569 651.118Z" fill="#CBDB44"/>
      <path d="M302.695 729.035L274.635 612.593L166.569 651.118L179.138 756.673L302.695 729.035Z" fill="#A06E4D"/>
      <path d="M413.494 839.612L388.566 643.584L274.635 612.593L302.695 729.035L331.607 793.12L413.494 839.612Z" fill="#AD7A57"/>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M447.675 210.057L486.189 403.574L546.372 375.935L524.703 182.021L447.675 210.057Z" fill="#A97C50"/>
      </g>
      <path d="M600.096 436.247L581.242 450.067L486.189 403.574L546.372 375.935L600.096 436.247Z" fill="url(#paint3_linear_1_23)"/>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M600.096 515.413L578.73 527.983L581.242 450.067L600.096 436.247V515.413Z" fill="#A97C50"/>
      </g>
      <path d="M630.234 559.395L578.73 527.983L600.096 515.413L644.077 553.11L630.234 559.395Z" fill="#D8DC5D"/>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M630.234 559.395L644.077 553.11L660.408 651.118L630.234 559.395Z" fill="#A97C50"/>
      </g>
      <path d="M553.603 771.754L486.189 403.574L581.242 450.067L578.73 527.983L630.234 559.395L660.408 651.118L644.077 756.673L553.603 771.754Z" fill="#AD7A57"/>
      <path d="M377.106 455.72L470.559 428.584L532.308 462.636L474.437 491.536L432.968 496.559L377.106 455.72Z" fill="url(#paint4_linear_1_23)"/>
      <path d="M432.968 496.559L454.707 857.205L413.494 839.612L388.566 643.584L377.106 455.72L432.968 496.559Z" fill="#AD7A57"/>
      <path d="M474.437 491.536L524.703 839.612L454.707 857.205L432.968 496.559L474.437 491.536Z" fill="#A06E4D"/>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M532.308 462.636L600.096 764.009L524.703 839.612L474.437 491.536L532.308 462.636Z" fill="#A97C50"/>
      </g>
      <path d="M644.077 756.673L686.797 726.102L660.408 651.118L644.077 756.673Z" fill="#3C2415"/>
      <path d="M205.527 674.365L282.112 643.584L357.89 674.365L321.759 695.73L205.527 674.365Z" fill="#CBDB44"/>
      <path d="M205.527 750.774V674.365L321.759 695.73L331.607 831.447L205.527 750.774Z" fill="#AD7A57"/>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M357.89 674.365L370.646 815.28L331.607 831.447L321.759 695.73L357.89 674.365Z" fill="#A97C50"/>
      </g>
      <path d="M274.635 612.593V663.77L388.566 643.584L274.635 612.593Z" fill="#996C4D"/>
      <path d="M274.635 663.77L258.69 652.999L274.635 612.593V663.77Z" fill="#AD7A57"/>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M447.675 210.057L396.591 172.36L474.437 140.948C474.437 140.948 417.162 169.463 413.494 170.818C409.826 172.173 447.675 210.057 447.675 210.057Z" fill="#373538"/>
      </g>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M370.646 296.769L319.668 319.385L396.112 336.977C396.112 336.977 338.849 323.835 338.253 319.385C337.658 314.934 370.658 296.769 370.658 296.769H370.646Z" fill="#373538"/>
      </g>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M299.646 414.157L258.69 458.874L279.67 475.205L331.607 452.052C331.607 452.052 288.034 469.213 282.17 464.704C276.306 460.195 272.241 461.865 273.374 455.732C274.507 449.599 299.646 414.157 299.646 414.157Z" fill="#373538"/>
      </g>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M258.69 598.341L166.581 651.118L274.647 612.581C274.647 612.581 221.729 627.078 219.475 625.723C217.22 624.368 258.702 598.341 258.702 598.341H258.69Z" fill="#373538"/>
      </g>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M321.759 695.73L208.272 674.879L258.69 652.999C258.69 652.999 232.091 661.702 242.476 669.657C252.849 677.612 321.771 695.73 321.771 695.73H321.759Z" fill="#373538"/>
      </g>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M432.968 496.559L377.106 455.72L470.559 428.572C470.559 428.572 405.609 448.536 418.237 462.624C430.865 476.712 474.437 491.525 474.437 491.525L432.968 496.548V496.559Z" fill="#373538"/>
      </g>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M331.607 831.447L269.18 791.508C269.18 791.508 268.468 766.03 274.647 759.71C280.815 753.391 293.443 757 296.153 746.627C298.863 736.254 293.898 738.053 297.052 723.626C300.206 709.199 321.607 693.499 321.771 695.742C321.934 697.973 331.618 831.459 331.618 831.459L331.607 831.447Z" fill="#373538"/>
      </g>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M454.707 857.205L425.772 844.857C425.772 844.857 419.873 804.813 413.494 775.048C407.116 745.272 413.868 722.984 415.901 703.907C417.933 684.831 432.372 681.759 422.442 638.187C412.513 594.615 410.002 575.667 410.808 547.701C411.614 519.736 432.967 496.571 432.967 496.571L454.707 857.217V857.205Z" fill="#191919"/>
      </g>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M447.675 210.057C447.675 210.057 410.27 194.497 403.051 201.716C395.832 208.935 376.008 271.023 376.008 271.023L396.58 172.372L447.663 210.068L447.675 210.057Z" fill="#373538"/>
      </g>
      <path d="M179.138 756.673L205.527 750.774L331.607 831.447L370.646 815.291L413.494 839.612L454.707 857.205L524.703 839.612L600.096 764.009L644.077 756.685L686.797 726.103C686.797 726.103 668.737 732.644 651.6 737.153C634.463 741.662 645.736 746.931 629.65 750.681C616.882 753.659 602.888 753.648 596.124 756.673C589.361 759.699 575.062 771.591 566.488 785.573C557.914 799.556 529.866 829.648 523.523 831.435C517.18 833.223 478.841 835.886 467.113 840.897C455.385 845.909 447.78 742.165 447.78 742.165C447.78 742.165 460.303 870.58 439.311 839.402C436.215 834.8 423.272 835.477 410.691 829.169C398.11 822.849 390.435 806.612 383.227 807.511C376.008 808.411 370.144 810.666 370.144 810.666C370.144 810.666 343.755 819.006 337.214 822.394L330.672 825.782C330.672 825.782 323.908 809.988 316.012 807.511C308.115 805.035 300.977 808.715 287.146 797.29C273.315 785.866 224.895 751.802 217.454 750.003C210.013 748.204 204.149 747.97 204.149 747.97L179.162 756.673H179.138Z" fill="#4A4747"/>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M486.189 403.574L581.254 450.067L600.108 436.247L552.692 383.014C552.692 383.014 587.854 419.835 587.854 428.584C587.854 437.334 589.513 445.908 580.786 442.742C572.06 439.588 486.189 403.574 486.189 403.574Z" fill="#373538"/>
      </g>
      <g  style={{mixBlendMode:"screen"}}>
        <path d="M578.73 527.983C578.73 527.983 615.504 547.024 617.992 544.314C620.48 541.604 600.096 515.413 600.096 515.413L644.077 553.11L630.234 559.395L578.73 527.983Z" fill="#373538"/>
      </g>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M205.527 674.365L223.47 677.671L274.635 663.77L258.69 652.999L205.527 674.365Z" fill="#C3C8CA"/>
      </g>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M274.635 663.77L306.97 669.657L331.805 663.77L388.566 643.584L274.635 663.77Z" fill="#C3C8CA"/>
      </g>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M357.89 674.365L377.106 695.73L399.103 831.447L370.646 815.28L357.89 674.365Z" fill="#C3C8CA"/>
      </g>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M532.308 462.636L555.064 492.961L632.956 758.531L560.484 825.863L524.703 839.612L600.096 764.009L532.308 462.636Z" fill="#C3C8CA"/>
      </g>
      <g style={{mixBlendMode:"multiply"}}>
        <path d="M377.106 455.72L388.566 466.853L404.406 684.633L356.22 655.09L388.566 643.584L377.106 455.72Z" fill="#C3C8CA"/>
      </g>
      {gameType === "core" ?
        <g>
          <path d="M401.146 24.3138L431.375 11.8409H477.363L508.079 25.4178L480.925 38.9948H429.917L401.146 24.3138Z" fill={`#${diamondColors[colorClass][0]}`} />
          <path d="M401.146 24.3138L381.51 46.4864L421.36 73.2065L429.917 38.9948L401.146 24.3138Z" fill={`#${diamondColors[colorClass][1]}`} />
          <path d="M480.925 38.9948L494.068 73.2065L529.792 45.7241L508.079 25.4178L480.925 38.9948Z" fill={`#${diamondColors[colorClass][1]}`} />
          <path d="M429.917 38.9948L481.122 39.4153L494.068 73.2064H421.36L429.917 38.9948Z" fill={`#${diamondColors[colorClass][2]}`} />
          <path d="M494.068 73.2064L493.267 74.7705L457.714 132.771L529.792 45.7241L494.068 73.2064Z" fill={`#${diamondColors[colorClass][2]}`} />
          <path d="M421.36 73.2065L457.714 132.771L381.51 46.4864L421.36 73.2065Z" fill={`#${diamondColors[colorClass][2]}`} />
          <path d="M494.068 73.2065L457.714 132.771L421.36 73.2065H494.068Z" fill={`#${diamondColors[colorClass][1]}`} />
          <path d="M403.394 25.4442C405.01 27.7836 406.43 30.3071 407.783 32.6072C411.503 38.9291 413.803 45.9212 416.681 52.6111C418.587 57.0272 419.639 61.3908 420.191 66.1092C420.453 68.3566 420.677 70.7487 421.15 73.0356L421.36 73.1802L429.917 38.9816L403.394 25.4442Z" fill={`#${diamondColors[colorClass][3]}`} />
          <path d="M501.087 22.5921C500.666 22.7761 500.233 22.947 499.773 23.1047C494.647 24.8396 488.929 25.2996 483.593 25.983C477.193 26.8111 470.91 27.4814 464.431 27.4814C456.781 27.4814 449.198 27.9151 441.548 27.4814C433.965 27.0476 426.21 27.4025 418.732 25.8779C414.329 24.9842 409.045 24.1561 404.708 22.855L401.08 24.3401L429.93 39.2708H480.938L508.092 25.6939L501.087 22.6052V22.5921Z" fill={`#${diamondColors[colorClass][4]}`} />
          <path d="M480.925 35.7089H429.917L404.708 22.8549L401.146 24.3138L429.917 39.2576L480.899 39.4153L508.079 25.4178L504.596 23.8801L480.925 35.7089Z" fill="white"/>
          <path d="M401.146 24.3138L399.898 25.7333L428.063 40.0988L420.007 72.2864L421.36 73.2065L429.917 38.9948L401.146 24.3138Z" fill="white"/>
          <path opacity="0.2" d="M494.726 72.9831C494.726 62.1531 498.866 52.9266 504.084 43.6212C506.318 39.6388 508.96 35.9587 510.484 31.5821C511.93 27.442 509.144 26.0225 505.608 27.81C499.536 30.8723 493.622 34.0004 487.484 37.0102C483.436 38.9948 481.412 39.9149 483.094 44.7384C484.251 48.0636 485.815 51.3494 487.26 54.5826C489.81 60.2868 492.794 66.6744 494.713 72.1025V72.9831H494.726Z" fill="#AAE6F9"/>
          <path opacity="0.2" d="M483.12 39.6782L509.301 26.5876L508.079 25.4442L480.925 39.0211L494.068 73.2328L495.567 72.0762L483.12 39.6782Z" fill="white"/>
          <path opacity="0.1" d="M505.151 54.1165C515.114 44.1539 515.114 28.0012 505.151 18.0386C495.188 8.07599 479.036 8.07603 469.073 18.0387C459.11 28.0013 459.11 44.1539 469.073 54.1165C479.036 64.0791 495.188 64.0792 505.151 54.1165Z" fill="white"/>
          <path opacity="0.2" d="M496.423 52.4761C506.041 48.5156 510.628 37.5078 506.668 27.8894C502.707 18.2711 491.699 13.6845 482.081 17.6449C472.463 21.6054 467.876 32.6132 471.837 42.2316C475.797 51.8499 486.805 56.4365 496.423 52.4761Z" fill="white"/>
          <path opacity="0.7" d="M489.258 22.1715C488.456 27.8888 489.258 35.0913 479.046 35.9587C482.069 37.365 485.446 38.6005 487.563 41.4394C489.442 43.9629 490.967 48.2082 491.282 51.2837C491.86 47.9584 491.085 44.8435 493.148 41.4526C494.686 38.9159 497.288 37.1416 500.193 36.7999C496.934 34.9861 494.082 35.2753 492.11 31.3717C491.348 29.8865 490.651 28.3225 490.191 26.7716C489.705 25.1681 489.876 22.6841 488.824 21.5143L489.258 22.1715Z" fill="white"/>
          <path opacity="0.1" d="M439.835 80.3838C442.062 69.2072 434.808 58.3413 423.631 56.1141C412.454 53.8869 401.589 61.1418 399.361 72.3183C397.134 83.4949 404.389 94.3607 415.566 96.588C426.742 98.8152 437.608 91.5603 439.835 80.3838Z" fill="white"/>
          <path opacity="0.2" d="M436.58 75.5591C436.58 83.9707 429.759 90.792 421.347 90.792C412.936 90.792 406.114 83.9707 406.114 75.5591C406.114 67.1474 412.936 60.3261 421.347 60.3261C429.759 60.3261 436.58 67.1474 436.58 75.5591Z" fill="white"/>
          <path opacity="0.7" d="M421.334 65.1103C420.677 69.7367 421.334 75.5591 413.08 76.2688C415.525 77.4123 418.259 78.4112 419.967 80.6981C421.479 82.7353 422.714 86.1657 422.977 88.6629C423.437 85.9685 422.819 83.4581 424.489 80.6981C425.737 78.6477 427.84 77.2152 430.193 76.9391C427.551 75.4803 425.251 75.7037 423.647 72.5493C423.03 71.3401 422.464 70.0784 422.096 68.8298C421.702 67.5286 421.847 65.5177 420.992 64.5714L421.347 65.0971L421.334 65.1103Z" fill="white"/>
          <path opacity="0.1" d="M423.253 72.983C425.343 73.7716 427.446 74.8625 428.773 75.6117C436.186 79.8044 443.165 86.56 448.527 93.1184C453.745 99.5191 457.189 106.603 458.608 114.765C459.397 119.247 459.844 123.808 460.29 128.329L494.068 72.9699H423.253V72.983Z" fill="white"/>
          <path opacity="0.2" d="M467.9 72.983C467.9 72.983 442.587 106.143 443.033 105.473C443.48 104.803 484.921 73.2065 484.921 73.2065L467.9 72.983Z" fill="white"/>
          <path opacity="0.2" d="M409.216 28.8614L451.997 11.8409H458.661L421.755 35.0912L409.216 28.8614Z" fill="white"/>
          <path opacity="0.2" d="M439.235 39.021L477.81 11.6832L482.962 13.957L464.431 39.113L439.235 39.021Z" fill="white"/>
        </g> :
        <g><path d="M453.097 10.7181L517.099 70.4239L469.495 115.004L453.095 12.8497L392.054 82.1265L459.018 179.826L469.495 115.004L453.097 10.7181Z" fill={`#${diamondColors[colorClass][0]}`} />
          <path d="M452.99 10.7181L392.054 81.7846L469.463 115.518L452.99 10.7181Z" fill={`#${diamondColors[colorClass][1]}`} />
          <path d="M452.991 10.7181L451.29 12.7004L466.834 111.588L393.793 79.7577L392.054 81.7846L469.463 115.518L452.991 10.7181Z" fill="white"/>
          <path d="M469.271 114.908L458.745 179.826L517.099 70.2633L469.271 114.908Z" fill={`#${diamondColors[colorClass][2]}`} />
          <path d="M469.271 114.908L458.745 179.826L517.099 70.2633L469.271 114.908Z" fill={`#${diamondColors[colorClass][2]}`} />
          <path opacity="0.2" d="M392.054 82.1724L461.127 61.2863L453.195 10.7181L392.054 82.1724Z" fill="white"/>
          <path opacity="0.2" d="M460.748 61.1186L517.099 70.2633L452.791 10.7181L460.748 60.1955V61.1186Z" fill="white"/>
          <path opacity="0.5" d="M464.898 141.945C465.012 141.239 499.019 87.7222 499.019 87.7222L517.099 71.4542L476.032 147.45L458.745 179.826L464.898 141.945Z" fill={`#${diamondColors[colorClass][3]}`} />
          <path d="M471.331 114.91L515.063 74.0867L517.099 70.2633L469.271 114.91L458.745 179.826L461.705 174.267L471.331 114.91Z" fill={`#${diamondColors[colorClass][3]}`} />
          <path opacity="0.5" d="M414.578 115.062L468.272 115.518L392.054 82.1724L414.578 115.062Z" fill={`#${diamondColors[colorClass][3]}`} />
          <path opacity="0.4" d="M395.627 80.9337C397.976 83.3412 403.753 83.6817 407.024 84.3092C413.838 85.7812 420.864 86.0312 427.767 85.0476C436.131 83.6995 442.701 80.0218 449.194 74.8464C453.435 71.468 455.111 68.1471 456.888 63.3434C457.414 62.1164 457.828 60.8452 458.125 59.5452C460.263 60.6789 459.979 67.925 460.592 70.3372C461.797 75.0826 463.111 79.6917 463.606 84.5435C464.548 92.5724 465.904 100.548 467.671 108.44C468.005 110.054 469.055 112.143 467.214 112.956C465.757 113.603 463.166 112.332 461.909 111.872C458.178 110.508 455.294 108.158 451.702 106.462C448.212 104.814 444.522 103.935 441 102.286C437.274 100.541 433.631 98.9614 430.047 96.9377C424.56 93.8397 418.469 91.4179 412.728 88.9962C409.844 87.7788 406.815 86.6293 404.072 85.3761C401.901 84.3835 397.065 83.2385 395.854 81.1556L395.627 80.9337Z" fill="white"/>
          <path opacity="0.1" d="M479.868 134.269C491.984 126.337 495.375 110.084 487.442 97.968C479.51 85.8519 463.257 82.4606 451.141 90.3932C439.025 98.3258 435.634 114.578 443.566 126.695C451.499 138.811 467.751 142.202 479.868 134.269Z" fill="white"/>
          <path opacity="0.2" d="M487.177 113.773C486.697 117.63 485.085 121.258 482.544 124.198C480.002 127.139 476.646 129.259 472.9 130.292C469.153 131.325 465.184 131.224 461.495 130.002C457.806 128.779 454.563 126.49 452.174 123.424C449.786 120.358 448.361 116.652 448.079 112.776C447.797 108.9 448.67 105.027 450.589 101.648C452.508 98.268 455.386 95.5333 458.859 93.7893C462.332 92.0453 466.244 91.3702 470.101 91.8496C475.272 92.4926 479.977 95.1636 483.179 99.2751C486.382 103.387 487.819 108.602 487.177 113.773Z" fill="white"/>
          <path opacity="0.7" d="M469.704 98.3865C468.156 104.177 468.067 111.691 457.554 111.279C460.458 113.119 463.744 114.82 465.534 118.006C467.196 121.292 468.066 124.928 468.075 128.618C469.083 125.262 468.689 121.962 471.218 118.727C472.137 117.526 473.315 116.553 474.661 115.88C476.008 115.207 477.488 114.853 478.99 114.844C475.893 112.564 472.963 112.492 471.439 108.222C470.838 106.605 470.378 104.938 470.062 103.241C469.777 101.527 470.261 98.9918 469.341 97.6541L469.704 98.3865Z" fill="white"/>
          <path d="M471.634 112.604C470.89 110.205 470.088 110.484 469.396 111.509C465.921 109.775 466.422 121.591 470.471 118.604C471.843 117.593 472.094 114.09 471.634 112.604Z" fill="white"/>
          <path opacity="0.1" d="M409.777 83.8531C409.327 87.4762 407.812 90.8845 405.425 93.6468C403.038 96.4091 399.885 98.4014 396.366 99.3718C392.846 100.342 389.118 100.247 385.652 99.0988C382.187 97.9504 379.14 95.8002 376.896 92.9199C374.653 90.0397 373.314 86.5587 373.049 82.9175C372.784 79.2762 373.604 75.6381 375.407 72.4632C377.209 69.2883 379.913 66.7193 383.175 65.0809C386.438 63.4426 390.113 62.8085 393.736 63.259C398.594 63.8631 403.013 66.3721 406.021 70.2342C409.03 74.0963 410.381 78.9951 409.777 83.8531Z" fill="white"/>
          <path opacity="0.2" d="M407.431 83.2695C407.096 85.9572 405.972 88.4851 404.201 90.534C402.43 92.5828 400.09 94.0606 397.479 94.7801C394.868 95.4997 392.102 95.4289 389.532 94.5767C386.961 93.7245 384.7 92.1291 383.036 89.9923C381.372 87.8555 380.378 85.2731 380.182 82.5718C379.985 79.8705 380.593 77.1715 381.93 74.8161C383.268 72.4606 385.273 70.5546 387.693 69.3388C390.114 68.123 392.84 67.6522 395.528 67.9858C397.313 68.2074 399.037 68.7786 400.602 69.6667C402.166 70.5548 403.54 71.7424 404.646 73.1617C405.751 74.581 406.566 76.204 407.044 77.9384C407.522 79.6727 407.654 81.4843 407.431 83.2695Z" fill="white"/>
          <path opacity="0.7" d="M394.874 71.9645C393.756 75.9733 393.693 81.175 386.1 80.8876C388.196 82.1603 390.57 83.3382 391.863 85.5446C393.063 87.8193 393.692 90.336 393.698 92.8905C394.427 90.5667 394.142 88.2826 395.967 86.0433C396.632 85.2124 397.482 84.5385 398.455 84.0728C399.428 83.6071 400.497 83.3617 401.582 83.3551C399.346 81.7771 397.229 81.7262 396.128 78.7703C395.694 77.651 395.362 76.4974 395.133 75.3223C394.924 74.136 395.277 72.3813 394.613 71.4542L394.874 71.9645Z" fill="white"/>
          <path opacity="0.1" d="M464.595 14.7147C464.261 17.2859 463.138 19.7048 461.367 21.6652C459.595 23.6256 457.256 25.0396 454.645 25.7284C452.034 26.4172 449.268 26.35 446.696 25.5351C444.125 24.7201 441.864 23.1942 440.2 21.1502C438.535 19.1062 437.542 16.6359 437.345 14.0518C437.148 11.4676 437.757 8.88554 439.094 6.63235C440.431 4.37916 442.437 2.55603 444.858 1.39327C447.279 0.230508 450.005 -0.219549 452.693 0.100051C456.298 0.528591 459.577 2.30914 461.809 5.0499C464.04 7.79066 465.043 11.267 464.595 14.7147Z" fill="white"/>
          <path opacity="0.2" d="M462.24 14.2746C461.993 16.1446 461.163 17.9038 459.854 19.3296C458.545 20.7553 456.816 21.7835 454.886 22.2844C452.956 22.7853 450.911 22.7362 449.01 22.1434C447.11 21.5506 445.439 20.4407 444.209 18.954C442.978 17.4673 442.244 15.6707 442.099 13.7912C441.954 11.9118 442.404 10.0339 443.393 8.39524C444.381 6.75662 445.864 5.43076 447.653 4.5853C449.443 3.73984 451.458 3.41294 453.445 3.64564C454.764 3.79988 456.038 4.19704 457.194 4.81471C458.35 5.43237 459.365 6.25826 460.182 7.24529C460.999 8.23231 461.601 9.36131 461.954 10.5674C462.307 11.7735 462.404 13.0331 462.24 14.2746Z" fill="white"/>
          <path opacity="0.7" d="M452.913 6.32014C452.139 9.216 452.095 12.9726 446.836 12.7666C448.289 13.6869 449.931 14.5373 450.826 16.1302C451.657 17.7731 452.092 19.5911 452.096 21.4363C452.6 19.7583 452.404 18.1088 453.668 16.4903C454.128 15.8903 454.717 15.4037 455.39 15.0675C456.063 14.7313 456.803 14.5541 457.554 14.5495C456.006 13.4091 454.54 13.3733 453.778 11.238C453.478 10.4295 453.248 9.5962 453.09 8.74746C452.945 7.89051 453.189 6.62346 452.729 5.95453L452.913 6.32014Z" fill="white"/>
          <path d="M395.469 81.3751C394.911 79.3198 394.309 79.559 393.79 80.4395C391.185 78.9539 391.56 89.0786 394.597 86.5191C395.627 85.65 395.813 82.6476 395.469 81.3751Z" fill="white"/></g> }
      <defs>
        <linearGradient id="paint0_linear_1_23" x1="396.591" y1="175.503" x2="524.703" y2="175.503" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2C939"/>
          <stop offset="0.07" stopColor="#C3CE39"/>
          <stop offset="0.14" stopColor="#C6DB39"/>
          <stop offset="0.15" stopColor="#C6DB39"/>
          <stop offset="0.48" stopColor="#D4DC54"/>
          <stop offset="0.77" stopColor="#DDDC65"/>
          <stop offset="1" stopColor="#E0DD6B"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1_23" x1="319.668" y1="316.873" x2="396.112" y2="316.873" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2C939"/>
          <stop offset="0.07" stopColor="#C3CE39"/>
          <stop offset="0.14" stopColor="#C6DB39"/>
          <stop offset="0.15" stopColor="#C6DB39"/>
          <stop offset="0.48" stopColor="#D4DC54"/>
          <stop offset="0.77" stopColor="#DDDC65"/>
          <stop offset="1" stopColor="#E0DD6B"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1_23" x1="258.69" y1="444.681" x2="377.106" y2="444.681" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2C939"/>
          <stop offset="0.07" stopColor="#C3CE39"/>
          <stop offset="0.14" stopColor="#C6DB39"/>
          <stop offset="0.15" stopColor="#C6DB39"/>
          <stop offset="0.48" stopColor="#D4DC54"/>
          <stop offset="0.77" stopColor="#DDDC65"/>
          <stop offset="1" stopColor="#E0DD6B"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1_23" x1="486.189" y1="413.001" x2="600.096" y2="413.001" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2C939"/>
          <stop offset="0.07" stopColor="#C3CE39"/>
          <stop offset="0.14" stopColor="#C6DB39"/>
          <stop offset="0.15" stopColor="#C6DB39"/>
          <stop offset="0.48" stopColor="#D4DC54"/>
          <stop offset="0.77" stopColor="#DDDC65"/>
          <stop offset="1" stopColor="#E0DD6B"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1_23" x1="377.106" y1="462.578" x2="532.308" y2="462.578" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2C939"/>
          <stop offset="0.07" stopColor="#C3CE39"/>
          <stop offset="0.14" stopColor="#C6DB39"/>
          <stop offset="0.15" stopColor="#C6DB39"/>
          <stop offset="0.48" stopColor="#D4DC54"/>
          <stop offset="0.77" stopColor="#DDDC65"/>
          <stop offset="1" stopColor="#E0DD6B"/>
        </linearGradient>
      </defs>
    </svg>

  )
}

export default memo(SelectedIsland)