import { memo } from 'react';

function EditorCloseBtn({close}:{ close: () => void}) {
  return (
    <div className='close-btn' onClick={close}>
      <svg viewBox='0 0 78 78' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='39' cy='39' r='37' stroke='#FF0000' strokeWidth='4' />
        <line x1='24.8284' y1='25' x2='54' y2='54.1716' stroke='#FF0000' strokeWidth='4' strokeLinecap='round' />
        <line x1='24' y1='54.1716' x2='53.1716' y2='25' stroke='#FF0000' strokeWidth='4' strokeLinecap='round' />
      </svg>
    </div>
  );
}

export default memo(EditorCloseBtn);
