import React, { memo, useState, useCallback, useMemo } from 'react';
import { useGameMechanic } from 'src/hooks';
import { Col, Row } from 'react-bootstrap';
import { TDragDropTextGame, TDragDropTextGameOptions } from "./types";
import GameNotFound from '../../../not-found';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { checkIsMobile } from 'src/helpers';
import { DropItem, DragItem } from 'src/components';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The calculation game component
 * @constructor
 */
function DragDropTextGame() {
  // Internal states
  const splitSentence = useCallback((sentence: string, index: number) => sentence.split('{{_}}')[index], []);
  const [completed, setCompleted] = useState<Record<number, TDragDropTextGameOptions>>({});
  const [moves, setMoves] = useState(0);
  const isMobile = checkIsMobile();
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const {
    sentence,
    sentences,
    missing_words,
    filePath,
  } = gameMechanic.content as unknown as TDragDropTextGame;

  function handleDrop(item: TDragDropTextGameOptions, index: number) {
    setMoves(prev => prev++);
    setCompleted(prev => ({ ...prev, [index]: { word: item.word, index: item.index } }));
  }

  const isCompleted = useCallback((indexNumber: number, word:string) => {
    const isFinal = Object.values(completed).filter(el => el.index === indexNumber && el.word === word).length;
    if (isFinal) {
      changeCorrectAnswersPoints(100);
    }
    return isFinal;
  }, [completed]);
  const isGameFinished = useMemo(() => Object.keys(completed)?.length === missing_words?.length, [completed]);

  const parseSentence = (sentence: string) => {
    const parts = sentence?.split('{{_}}')
    const finalView: any[] = [];
    if (parts?.length > 2) {
      parts?.map((part: string, index: number) => {
        if (part?.length > 0) {
          finalView.push(part);
        }
        if (index < parts?.length - 1) {
          finalView.push(<DropItem key={`${completed[index]?.word}-${index}`}
            onDrop={(item: TDragDropTextGameOptions) => handleDrop(item, index)}>
            {completed[index]?.word}
          </DropItem>)
        }
        return;
      })
    } else {
      finalView.push(splitSentence(sentence, 0));
      finalView.push(
        (<DropItem onDrop={(item: TDragDropTextGameOptions) => handleDrop(item, 0)}>
          {completed[0]?.word}
        </DropItem>)
      );
      finalView.push(splitSentence(sentence, 1));
    }

    return finalView;
  }

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      {filePath ?  filePath.includes('video') ? (
          <video className='img-fluid' controls>
            <source src={filePath} type='video/mp4' key={Math.random()} />
          </video>)
        :
        ( <img
          src={filePath}
          alt={'drag_drop_text_image'}
          style={{ width: 500 }}
          key={Math.random()}
        />) : null}
      <div className='drag-text drag-drop' style={{ marginTop: 16 }}>

        <div className='sentences'>
          {sentence && !sentences && (
            <p>
              {parseSentence(sentence)}
            </p>
          )}
          {sentences && !sentence && sentences.map((selectedSentence, i) => (
            <p key={`sentence-${selectedSentence}-${i}`}>
              {splitSentence(selectedSentence, 0)}
              <DropItem onDrop={(item: TDragDropTextGameOptions) => handleDrop(item, i)}>
                {completed[i]?.word}
              </DropItem>
              {splitSentence(selectedSentence, 1)}
            </p>
          ))}
        </div>
        <Row>
          <Col md={12}>
            <div className='missing-words'>
              {
                missing_words
                  .filter((w) => !isCompleted(w.index, w.word)).map((w, i) => (
                    <DragItem
                      item={{ index: w.index, word: w.word, element:<span>{w.word}</span> }}
                      key={`${w.word}-${i}-missing`}
                    >
                      <span>{w.word}</span>
                    </DragItem>
                ))}
            </div>
          </Col>
        </Row>
      </div>
    </DndProvider>
  );
}

export default memo(DragDropTextGame);
