import { Spinner } from 'react-bootstrap';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The loader component to show the spinner
 * ToDo add timme out here and design
 * @constructor
 */
export default function Loading(){
  return (
    <div className="loader"><Spinner animation="border" /></div>
  )
}
