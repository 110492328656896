import { Col, Row } from 'react-bootstrap';
import React, { memo } from 'react';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { checkIsMobile } from 'src/helpers';
import { useGameEditMechanic } from 'src/hooks';
import { Uploader } from '../../../../components';
import { TGameMechanicContent } from '../../../../@types';
import GameNotFound from '../../../not-found';
import { TDragDropImageGame, TDragDropImageGameOptions } from './types';


function DragDropEditor() {
  const isMobile = checkIsMobile();

  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const { answers, options } = gameMechanic.content as TDragDropImageGame;

  const updateOptionFile = (optionIndex: number, filePath?: string | null) => {
    let optionsClone = structuredClone(options);
    optionsClone[optionIndex] = { ...optionsClone[optionIndex], filePath };
    updateGameMechanic({
      ...gameMechanic,
      content: {
        ...gameMechanic.content,
        options: optionsClone
      } as TGameMechanicContent
    });
  };

  const onTextChange = (optionIndex: number, newText: string) => {
    const currentAnswers = Array.from({ length: 3 }, (answer, index) =>
      index === optionIndex ? newText : (answers[index])
    );

    let changedOptions = structuredClone(options);
    if (!changedOptions[optionIndex]) {
      changedOptions[optionIndex] = {} as TDragDropImageGameOptions;
    }
    changedOptions[optionIndex].option = newText;
    updateGameMechanic({
      ...gameMechanic,
      content: {
        ...gameMechanic.content,
        options: changedOptions,
        answers: currentAnswers.filter((answerOption) => answerOption !== undefined)
      } as TGameMechanicContent
    });
  };

  return (
    <div className='drag-drop-image-editor'>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <Row>
          <Col md={4}>
            <input placeholder='enter the word' value={answers[0] || ''}
                   onChange={(e) => onTextChange(0, e.target.value)} />
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <Uploader
                gameId={gameId}
                currentUrl={options?.[0]?.filePath}
                onGameMechanicContentChange={(filePath) => updateOptionFile(0, filePath)}
                key={Math.random()}
              />
            </DndProvider>
          </Col>
          <Col md={4}>
            <input placeholder='enter the word' value={answers[1] || ''}
                   onChange={(e) => onTextChange(1, e.target.value)} />
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <Uploader
                gameId={gameId}
                currentUrl={options?.[1]?.filePath}
                onGameMechanicContentChange={(filePath) => updateOptionFile(1, filePath)}
                key={Math.random()}
              />
            </DndProvider>
          </Col>
          <Col md={4}>
            <input placeholder='enter the word' value={answers[2] || ''}
                   onChange={(e) => onTextChange(2, e.target.value)} />
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <Uploader
                gameId={gameId}
                currentUrl={options?.[2]?.filePath}
                onGameMechanicContentChange={(filePath) => updateOptionFile(2, filePath)}
                key={Math.random()}
              />
            </DndProvider>
          </Col>
        </Row>
      </DndProvider>
    </div>
  );
}

export default memo(DragDropEditor);
