import { Container } from 'react-bootstrap';
import 'src/assets/styles/game-overview.scss';
import DeleteBtn from '../../assets/svg/buttons/delete-btn';
import EditBtn from '../../assets/svg/buttons/edit-btn';
import FilterBtn from '../../assets/svg/buttons/filter-btn';
import PublishedIcon from '../../assets/svg/icons/published-icon';
import ReviewIcon from '../../assets/svg/icons/review-icon';
import ArrowIcon from '../../assets/svg/icons/arrow-icon';
import { ButtonMotion } from '../../components';
import { useEffect,  useState } from 'react';
import Filter from './filter';
import CloseBtn from '../../assets/svg/buttons/close-btn';
import { filterFunctionsArr } from './filter-functions';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllGames } from "../../api/v0";
import { PATH_GLMS_PLAYER } from "../../routes/paths";
import {useAuth} from "../../hooks";

export interface FilterData {
  id?: string,
  created_at?: string,
  updated_at?: string
  status?: string,
  title?: string,
  created_by?: string,
}

export default function GamesOverview() {
  const [filter, setFilter] = useState<string>('');
  const [filterData, setFilterData] = useState<FilterData | {}>({});
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [filteredData, setFilteredData] = useState<FilterData[]>(data);

  const navigateTo = useNavigate();
  const { user } = useAuth();

  if (user?.role === 'student') {
    return null;
  }

  useEffect(() => {
    setFilteredData(filterFunctionsArr.reduce((d, f) =>
      d.filter((row)=> f(row, filterData)), data))
  }, [filterData, data]);

  useEffect(() => {
    getAllGames((allGames) => setData(allGames.map((game) => ({
      title: game.title,
      status: 'published',
      created_at: game.created_at.toDate().toDateString(),
      updated_at: game.updated_at.toDate().toDateString(),
      id: game.id,
      created_by: game.created_by,
    }))));
  }, []);

  function deleteRow(index:number){
    setFilteredData(prev=> prev.filter((row,i)=> index !== i))
    //TODO delete from main data too
  }


  return (
    <Container>
      <div className='games-overview'>
        {filter &&
        <Filter filter={filter} save={(data) => setFilterData(data)} fData={filterData} close={() => setFilter('')} />}
        <div className='game-wrapper animate'>
          <div className='game-container editor game-picker'>
            <CloseBtn close={() => { navigateTo('/') }} />
            <h2>Games overview</h2>
            <table className='table'>
              <thead className='thead-light text-uppercase'>
              <tr>
                <th scope='col'>
                  <div>Title</div>
                </th>
                <th scope='col'>
                  <div>Created By</div>
                </th>
                <th scope='col'>
                  <div>Creation date</div>
                </th>
                <th scope='col'>
                  <div>Last update</div>
                </th>
                <th scope='col'>
                  <div>status</div>
                </th>
                <th scope='col'></th>
              </tr>
              <tr>
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('title')} />
                    <div><ArrowIcon direction='up' /> <span>A-Z</span></div>
                  </div>
                </th>
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('created_by')} />
                    <div><ArrowIcon direction='up' /> <span>A-Z</span></div>
                  </div>
                </th>
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('date')} />
                    <div><ArrowIcon direction='down' /> <span>ASC</span></div>
                  </div>
                </th>
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('updated')} />
                    <div><ArrowIcon direction='up' /> <span>DESC</span></div>
                  </div>
                </th>
                <th scope='col'>
                  <div><FilterBtn onClick={() => setFilter('status')} />
                    <div><PublishedIcon /> <span>Pub</span><ReviewIcon /> <span>Rev</span></div>
                  </div>
                </th>
                <th scope='col'></th>
              </tr>
              </thead>
              <tbody>
              {filteredData.map((row, i) => {
                return (
                  <tr key={`row-${i}`}>
                    <td>
                      <div>{row.title}</div>
                    </td>
                    <td>
                      <div>{row.created_by}</div>
                    </td>
                    <td>
                      <div>{row.created_at}</div>
                    </td>
                    <td>
                      <div>{row.updated_at}</div>
                    </td>
                    <td>
                      <div>{row.status === 'published' ? <PublishedIcon /> : <ReviewIcon />} {row.status}</div>
                    </td>
                    <td>
                      <EditBtn onClick={() => {navigateTo(`/${PATH_GLMS_PLAYER.games.game.get(row.id || "0")}`);}} />
                      <DeleteBtn onClick={() => deleteRow(i)} />
                    </td>
                  </tr>);
              })}
              </tbody>
            </table>
          </div>
          <div className='submit-btn'>
            <ButtonMotion
              className='btn-block'
              variant={'success'}
              onClick={() => {
              }}
            >
              Next
            </ButtonMotion>
          </div>
        </div>
      </div>
    </Container>
  );
}