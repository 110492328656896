import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import 'src/assets/styles/editors/highlight-word.scss';
import ButtonMotion from 'src/components/buttons/button-motion';
import { handleChange } from 'src/helpers';
import { useGameEditMechanic } from 'src/hooks';
import { TGameMechanicContent } from 'src/@types';
import GameNotFound from '../../../not-found';
import { TScrambleSentenceGame } from './types';
import 'src/assets/styles/editors/highlight-word.scss';

type currentSelectionType = Record<number, { word: string }>

function ScrambleSentenceEditor() {
  const {
    gameMechanic,
    updateGameMechanic
  } = useGameEditMechanic();

  if (gameMechanic) {
    const { sentenceArr = [], highlights = [] } = gameMechanic.content as TScrambleSentenceGame;
    const [description, setDescription] = useState<string>(sentenceArr?.length ? sentenceArr.join(' ') : '');
    const [descriptionSaved, setDescriptionSaved] = useState<Boolean>(false);
    const [currentSelection, setCurrentSelection] = useState<currentSelectionType>({});
    const onGameMechanicContentChange = (finalContent: TScrambleSentenceGame) => {
      updateGameMechanic({
        ...gameMechanic,
        content: finalContent as unknown as TGameMechanicContent
      });
    };

    const isHighlighted = useCallback((num: number) => (highlights.includes(num)), [highlights]);

    const shuffle = (array: string[]) => {
      const shuffledArray = [...array];

      for (let i = highlights.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));

        [shuffledArray[highlights[i]], shuffledArray[highlights[j]]] = [shuffledArray[highlights[j]], shuffledArray[highlights[i]]];
      }

      return shuffledArray;
    };


    const scrambleWords = () => {
      // const shuffledArr = shuffle(sentenceArr);
      onGameMechanicContentChange({
        ...gameMechanic.content as TScrambleSentenceGame,
        sentenceArr: sentenceArr
      });
    };

    const selectWords = () => {
      const highlightKeys = Object.keys(currentSelection).sort((a, b) => Number(a) - Number(b));
      let selectionClone = { ...currentSelection };
      let newArr: any[] = [];
      sentenceArr.map((sentenceName, i) => {
        if (currentSelection[i]) {
          newArr.push(Object.values(selectionClone).filter((items) => items.word.trim() !== '').map(item => item.word).join(' '));
          selectionClone = {};
        } else {
          newArr.push(sentenceArr[i]);
        }
      })
      onGameMechanicContentChange({
        ...gameMechanic.content as TScrambleSentenceGame,
        sentenceArr: newArr.filter((item) => item.trim('') !== ''),
        highlights: [...highlights, Number(highlightKeys[0])]
      });
      setCurrentSelection({});
    };

    const toggleSelection = (index: number, word: string) => {
      setCurrentSelection(prev => {
          if (prev[index]) {
            let newObj = Object.assign({}, prev);
            delete newObj[index];
            return newObj as currentSelectionType;
          } else {
            return { ...prev, [index]: { word } };
          }
        }
      );
    };

    const saveDescription = () => {
      onGameMechanicContentChange({
        ...gameMechanic.content as TScrambleSentenceGame,
        sentenceArr: description.split(' ').filter(el=> el.trim() !== '')
      });
      setDescriptionSaved(true);
    };

    const actionButtons = useMemo(() => {
      if (!descriptionSaved) return <ButtonMotion variant={'scramble'} onClick={saveDescription}
                                                  disabled={description.length === 0}>Save</ButtonMotion>;
      if (Object.keys(currentSelection).length > 0 || highlights.length === 0) return <ButtonMotion
        variant={'scramble'}
        onClick={selectWords}>Select
        Word</ButtonMotion>;
      // return <ButtonMotion variant={'scramble'} onClick={scrambleWords}>Scramble</ButtonMotion>;
    }, [descriptionSaved, currentSelection, description]);



    return (
      <div className='scramble-sentence-editor'>
        {descriptionSaved ? <div className='sentence-to-scramble text-area-style'><p className="d-flex flex-wrap">{sentenceArr.map((word, i) =>
            <span key={`word-${i}`} onClick={() => toggleSelection(i, word)}
                  className={classnames({
                    selected: currentSelection[i],
                    highlighted: isHighlighted(i)
                  })}>{word}</span>)}</p></div> :
          <textarea value={description} onChange={handleChange(setDescription)}
                    placeholder='Enter here the correct sentence here, then select
                  the words desired and click on “scramble” button to create a new drag&drop block.' />}

        <div className='btn-wrapper with-count'>
          {actionButtons}
        </div>
      </div>
    );
  }
  return <GameNotFound />;
}

export default memo(ScrambleSentenceEditor);
