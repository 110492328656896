import { TDragDropImageGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the drag and drop
 *    image game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function dragDropImageDbToViewConverter(
  dbData: Record<string, any>
): TDragDropImageGame {
  return {
    answers: dbData.answers,
    options: dbData.options?.map((option: Record<string, any>) => ({
      description: option.description || null,
      option: option.option || null,
      filePath: option.filePath || null
    })),
  }
}
