import { TScrambleSentenceGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the "highlight word game"
 *    game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function scrambleSentenceGameDbToViewConverter(
  dbData: Record<string, any>
): TScrambleSentenceGame {
  return {
    sentenceArr: dbData.sentenceArr,
    highlights: dbData.highlights,
  }
}
