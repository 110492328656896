import { TMemoryGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the hotspot game
 *    type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function memoryDbToViewConverter(
  dbData: Record<string, any>
): TMemoryGame {
  return {
    cards: dbData.cards,
  }
}
