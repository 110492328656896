import { memo } from 'react';

function UploadBtn() {
  return (
    <svg  viewBox='0 0 85 82' fill='none' xmlns='http://www.w3.org/2000/svg' className="upload-btn">
      <g clipPath='url(#clip0_1_4)'>
        <path fillRule='evenodd' clipRule='evenodd'
              d='M25.5559 29.0237C22.9418 29.0237 20.8228 31.1416 20.8228 33.7543C20.8228 36.367 22.9418 38.4848 25.5559 38.4848C28.1701 38.4848 30.2891 36.367 30.2891 33.7543C30.2891 31.1416 28.1701 29.0237 25.5559 29.0237ZM15.1437 33.7543C15.1437 28.0074 19.8059 23.3477 25.5559 23.3477C31.306 23.3477 35.9682 28.0074 35.9682 33.7543C35.9682 39.5012 31.306 44.1608 25.5559 44.1608C19.8059 44.1608 15.1437 39.5012 15.1437 33.7543Z'
              fill='black' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M37.466 58.7445C32.7347 64.0409 27.7992 71.2831 22.5815 80.5526C21.8131 81.9184 20.0809 82.4026 18.7144 81.6346C17.3479 80.8666 16.8634 79.1354 17.6318 77.7696C22.9577 68.3067 28.1239 60.6796 33.2298 54.9647C38.3232 49.2639 43.4965 45.3102 48.8739 43.4495C54.356 41.5516 59.8292 41.9082 65.2244 44.4215C70.5254 46.8906 75.6632 51.3977 80.7638 57.6536C81.7541 58.8687 81.5713 60.6566 80.3556 61.6463C79.1399 62.6361 77.351 62.4534 76.3607 61.2384C71.5424 55.33 67.0488 51.5342 62.825 49.5654C58.6952 47.6427 54.7482 47.4227 50.732 48.8115C46.6111 50.2376 42.2081 53.4339 37.4643 58.7445H37.466Z'
              fill='black' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M5.26736 13.4751C8.63931 10.105 13.2145 8.21065 17.9832 8.21065H45.5463C47.1152 8.21065 48.3858 9.48065 48.3858 11.0486C48.3858 12.6166 47.1152 13.8866 45.5463 13.8866H17.9832C14.7195 13.8866 11.5907 15.1814 9.28176 17.4891C6.97286 19.7967 5.67731 22.9238 5.67731 26.1857V64.0249C5.67731 67.2868 6.97286 70.4139 9.28176 72.7198C11.5889 75.0257 14.7195 76.3223 17.9832 76.3223H63.4159C66.6796 76.3223 69.8084 75.0274 72.1173 72.7198C74.4245 70.4139 75.7218 67.2851 75.7218 64.0249V45.1044C75.7218 43.5365 76.9925 42.2665 78.5613 42.2665C80.1302 42.2665 81.4009 43.5365 81.4009 45.1044V64.0249C81.4009 68.7927 79.5055 73.3637 76.1335 76.7338C72.7616 80.1039 68.1863 81.9982 63.4177 81.9982H17.9832C13.2128 81.9982 8.63931 80.1039 5.26736 76.7338C1.8954 73.3637 0 68.7927 0 64.0249V26.1857C0 21.4197 1.8954 16.847 5.26736 13.4769V13.4751Z'
              fill='black' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M70.0427 0C71.6097 0 72.8822 1.27 72.8822 2.83798V33.1104C72.8822 34.6784 71.6115 35.9484 70.0427 35.9484C68.4738 35.9484 67.2031 34.6784 67.2031 33.1104V2.83798C67.2031 1.27 68.4738 0 70.0427 0Z'
              fill='black' />
        <path fillRule='evenodd' clipRule='evenodd'
              d='M68.0373 0.831883C69.1465 -0.276704 70.9443 -0.276704 72.0535 0.831883L84.1695 12.9412C85.2787 14.0498 85.2787 15.8466 84.1695 16.9552C83.0603 18.0638 81.2625 18.0638 80.1533 16.9552L70.0463 6.85373L59.9393 16.9552C58.8301 18.0638 57.0323 18.0638 55.9231 16.9552C54.8139 15.8466 54.8139 14.0498 55.9231 12.9412L68.0373 0.831883Z'
              fill='black' />
      </g>
      <defs>
        <clipPath id='clip0_1_4'>
          <rect width='85' height='82' fill='white' />
        </clipPath>
      </defs>
    </svg>

  );
}

export default memo(UploadBtn)