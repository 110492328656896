import { TDragDropTextGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the drag and drop
 *    image game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function dragDropTextDbToViewConverter(
  dbData: Record<string, any>
): TDragDropTextGame {
  return {
    sentence: dbData.sentence ?? null,
    sentences: dbData.sentences ?? null,
    missing_words: dbData.missing_words?.map((option: Record<string, any>) => ({
      word: option.word,
      index: option.index,
    })),
    filePath: dbData.filePath || null,
  }
}
