import { TGameMechanicContent } from 'src/@types';
import { useGameEditMechanic } from 'src/hooks';
import GameNotFound from 'src/games/not-found';
import React from 'react';
import { TInformativeGame } from './types';
import { uploadFile } from 'src/api/v0';
import TinyMceEditor from "../../../../components/tiny-mce-editor";

function InformativeEditor() {
  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();

  if (gameMechanic) {
    const onGameMechanicContentChange = (finalContent: TInformativeGame) => {
      updateGameMechanic({
        ...gameMechanic,
        content: finalContent as unknown as TGameMechanicContent
      });
    };
    const { content } = gameMechanic;
    const { infoDescription, video, image, audio } = content as unknown as TInformativeGame;

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
      const { files } = e.target;
      if (files?.length) {
        let file = files[0];
        if (gameId) {
          uploadFile(
            `${gameId}/${type}/${file.name}`,
            file,
            (url: string) => {
              const finalContent = {
                ...content,
                [type]: url
              };
              onGameMechanicContentChange(finalContent as unknown as TInformativeGame);
            },
            (error: string) => console.log(error)
          );
        }
      }
    };

    return (
      <div className='informative-game editor'>
        <div className='inputs'>
          <TinyMceEditor handleChange={(value) => onGameMechanicContentChange({ ...content, infoDescription:value })} value={infoDescription ?? ''} />
          <label>add video block <img src='/assets/images/select-icon.svg' alt='select-icon' />
            <input type='file' placeholder='add text block'
                   accept='video/*'
                   onChange={(e) => handleFileUpload(e, 'video')} />
          </label>
          <label> add image block <img src='/assets/images/select-icon.svg' alt='select-icon' />
            <input type='file' placeholder='add image block'
                   accept='image/*'
                   onChange={(e) => handleFileUpload(e, 'image')} />
          </label>
          <label> add audio block <img src='/assets/images/select-icon.svg' alt='select-icon' />
            <input type='file' placeholder='add text block'
                   accept='audio/*'
                   onChange={(e) => handleFileUpload(e, 'audio')} /> </label>
        </div>
        <div className='d-md-flex d-block'>
          {video && <div className='video-wrapper'>
            <video className="img-fluid" width='100%' height='auto' controls>
              <source src={video} type='video/mp4' />
              <source src={video} type='video/ogg' />
            </video>
          </div>}
          {image &&
            <div className='video-wrapper'>
                <img className="img-fluid w-100"  src={image} alt={'image-interactive'}  />
            </div>}
          <div className='audio-info mt-2 mt-md-0 ps-md-4'>
            {infoDescription && <p dangerouslySetInnerHTML={{__html:infoDescription}}/>}
            {audio && <audio controls>
              <source src={audio} type='audio/ogg' />
              <source src={audio} type='audio/mpeg' />
            </audio>}
          </div>
        </div>
      </div>
    );
  }
  return <GameNotFound />;
}

export default InformativeEditor;