import React, { memo, useState } from 'react';
import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { ButtonMotion, Uploader } from 'src/components';
import { checkIsMobile } from 'src/helpers';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import GameNotFound from 'src/games/not-found';
import { TGameMechanicContent } from 'src/@types';
import { useGameEditMechanic } from 'src/hooks';
import { TGameFillInGame } from './types';
import TinyMceEditor from '../../../../components/tiny-mce-editor';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //
interface ITextSelectEvent extends React.MouseEvent<HTMLTextAreaElement> {
  target: HTMLTextAreaElement & EventTarget;
}

function FillInBlanksEditor() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();


  if (gameMechanic) {
    const isMobile = checkIsMobile();
    const { text, answers, filePath } = gameMechanic.content as TGameFillInGame;
    const [isTextSaved, setIsTextSaved] = useState(false);

    const validateCurrentContent = () => {
      return true;
    };

    const onGameMechanicContentChange = (finalContent: TGameMechanicContent) => {
      if (validateCurrentContent()) {
        updateGameMechanic({
          ...gameMechanic,
          content: finalContent as unknown as TGameMechanicContent
        });
      }
    };

    const updateImage = (filePath: string) => {
      onGameMechanicContentChange({
        text: text ?? null,
        answers: answers ?? null,
        filePath: filePath ?? null
      } as TGameMechanicContent);
    };

    const [selectedText, setSelectedText] = useState<{ start: number, end: number } | null>(null);

    const onTextWordsSelectHandler = (event: ITextSelectEvent) => {
      setSelectedText({ start: event.target.selectionStart, end: event.target.selectionEnd });
    };

    const handleOnSelectWordButtonClick = () => {
      if (selectedText) {
        let convertedText = text ?? '';
        const selection = convertedText.substring(selectedText.start, selectedText.end);
        const convertedAnswers = [...(answers ?? [])];
        convertedAnswers.push(selection);

        convertedText = convertedText.slice(0, selectedText.start) + '{{_}}' + convertedText.slice(selectedText.end);
        onGameMechanicContentChange({
          ...gameMechanic?.content as TGameFillInGame,
          text: convertedText,
          answers: convertedAnswers
        } as TGameFillInGame);

        setSelectedText(null);
      }
    };

    const handleTextChange = (newText: string) => {
      if (gameId && gameMechanic?.content) {
        onGameMechanicContentChange({
          ...gameMechanic.content,
          text: newText
        } as TGameFillInGame);
      }
    };

    const parseText = () => {
      if (text && answers && text?.includes('{{_}}')) {
        return text.split('{{_}}').map((wordPart: string, index: number) => (`${wordPart} ${answers[index] ?? ''}`)).join(' ');
      }
      return text;
    }

    return (
      <div className='fill-in-blanks-editor'>
        <Row>
          <Col md={4} className='mb-3 mb-md-0'>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <Uploader gameId={gameId} currentUrl={filePath} onGameMechanicContentChange={updateImage}
                        key={Math.random()} />
            </DndProvider>
          </Col>
          <Col md={8}>
            {!isTextSaved
              ? <textarea onChange={(e) => handleTextChange(e.target.value)} value={parseText()} />
              : <textarea value={text} onSelect={onTextWordsSelectHandler} readOnly={true} />
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <div className='btn-wrapper'>
              {!isTextSaved ?
                <ButtonMotion
                  variant='success'
                  className={classnames({ disabled: false })}
                  onClick={() => setIsTextSaved(true)}
                >
                  Save
                </ButtonMotion> :
                <>
                  <ButtonMotion
                    variant='success'
                    className={classnames({ disabled: false })}
                    onClick={() => setIsTextSaved(false)}
                  >
                    Edit
                  </ButtonMotion>
                  <ButtonMotion
                    variant='success'
                    className={classnames({ disabled: false })}
                    onClick={handleOnSelectWordButtonClick}
                  >
                    Hide selected
                  </ButtonMotion>
                </>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return <GameNotFound />;
}

export default memo(FillInBlanksEditor);