import React, { memo, useMemo, useRef } from 'react';
import { IGemProps } from 'src/@interfaces/skill-island-interfaces';
import { diamondColors } from 'src/helpers';
import { useGame } from 'src/hooks';

function Gems({ index, onGameSelect, color, level, title }: IGemProps) {
  const { showTheGameInfoTooltip } = useGame();
  const gemRef = useRef<SVGGElement | null>(null);
  const renderedCoreGem = useMemo(() => {
    switch (index) {
      case 0 :
        return <g id='GAME GEM 4_2'>
          <path id='Vector_1167' fill={`#${diamondColors[color][0]}`}
                d='M425.057 453.895L447.293 444.709H481.132L503.733 454.699L483.753 464.689H446.226L425.057 453.895Z' />
          <path id='Vector_1168' fill={`#${diamondColors[color][1]}`}
                d='M425.057 453.895L410.61 470.2L439.931 489.856L446.226 464.689L425.057 453.895Z' />
          <path id='Vector_1169' fill={`#${diamondColors[color][1]}`}
                d='M483.753 464.689L493.419 489.856L519.707 469.633L503.733 454.699L483.753 464.689Z' />
          <path id='Vector_1170' fill={`#${diamondColors[color][2]}`}
                d='M446.226 464.689L483.902 464.999L493.419 489.856H439.931L446.226 464.689Z' />
          <path id='Vector_1171' fill={`#${diamondColors[color][2]}`}
                d='M493.419 489.856L492.824 491.017L466.678 533.678L519.707 469.633L493.419 489.856Z' />
          <path id='Vector_1172' fill={`#${diamondColors[color][2]}`}
                d='M439.931 489.856L466.678 533.678L410.61 470.2L439.931 489.856Z' />
          <path id='Vector_1173' fill={`#${diamondColors[color][1]}`}
                d='M493.419 489.856L466.678 533.678L439.931 489.856H493.419Z' />
          <path id='Vector_1174' fill={`#${diamondColors[color][3]}`}
                d='M426.712 454.719C427.908 456.442 428.941 458.292 429.941 459.988C432.676 464.635 434.372 469.788 436.493 474.706C437.891 477.954 438.668 481.169 439.073 484.641C439.269 486.296 439.424 488.052 439.782 489.741L439.937 489.842L446.232 464.675L426.719 454.719H426.712Z' />
          <path id='Vector_1175' fill={`#${diamondColors[color][4]}`}
                d='M498.586 452.619C498.282 452.76 497.958 452.882 497.62 452.997C493.851 454.273 489.65 454.611 485.719 455.118C481.004 455.726 476.384 456.219 471.622 456.219C465.996 456.219 460.417 456.543 454.79 456.219C449.204 455.901 443.504 456.165 437.999 455.037C434.763 454.375 430.873 453.767 427.685 452.814L425.017 453.909L446.239 464.891H483.767L503.746 454.902L498.599 452.625L498.586 452.619Z' />
          <path id='Vector_1176' fill='white'
                d='M483.753 462.277H446.226L427.678 452.814L425.057 453.895L446.226 464.885L483.733 464.999L503.733 454.699L501.166 453.564L483.753 462.277Z' />
          <path id='Vector_1177' fill='white'
                d='M425.057 453.895L424.139 454.929L444.855 465.499L438.931 489.187L439.931 489.856L446.226 464.689L425.057 453.895Z' />
          <path id='Vector_1178' fill={`#${diamondColors[color][2]}`}
                d='M493.905 489.694C493.905 481.723 496.945 474.935 500.795 468.093C502.443 465.168 504.381 462.453 505.509 459.238C506.576 456.192 504.523 455.145 501.923 456.462C497.458 458.718 493.101 461.014 488.589 463.23C485.611 464.689 484.125 465.364 485.361 468.917C486.212 471.362 487.36 473.78 488.427 476.158C490.305 480.359 492.5 485.053 493.905 489.045V489.687V489.694Z'
                opacity='.2' />
          <path id='Vector_1179' fill='white'
                d='M485.368 465.195L504.631 455.557L503.733 454.719L483.753 464.709L493.419 489.876L494.52 489.025L485.368 465.195Z'
                opacity='.2' />
          <g id='Group_134' fill='white'>
            <path id='Vector_1180'
                  d='M501.581 475.818C508.911 468.488 508.911 456.603 501.581 449.273C494.251 441.942 482.366 441.942 475.036 449.273C467.705 456.603 467.705 468.488 475.036 475.818C482.366 483.149 494.251 483.149 501.581 475.818Z'
                  opacity='.1' />
            <path id='Vector_1181'
                  d='M495.155 474.622C502.229 471.709 505.603 463.612 502.69 456.538C499.777 449.463 491.68 446.089 484.606 449.002C477.531 451.916 474.157 460.012 477.07 467.087C479.983 474.162 488.08 477.535 495.155 474.622Z'
                  opacity='.2' />
            <path id='Vector_1182'
                  d='M489.879 452.315C489.285 456.523 489.879 461.818 482.369 462.46C484.597 463.5 487.083 464.405 488.63 466.485C490.008 468.336 491.129 471.463 491.365 473.726C491.791 471.274 491.224 468.991 492.737 466.485C493.865 464.621 495.783 463.311 497.924 463.068C495.519 461.737 493.432 461.946 491.973 459.076C491.419 457.982 490.899 456.833 490.562 455.692C490.204 454.51 490.332 452.686 489.562 451.828L489.886 452.315H489.879Z'
                  opacity='.7' />
          </g>
          <g id='Group_135' fill='white'>
            <path id='Vector_1183'
                  d='M453.525 495.148C455.164 486.924 449.825 478.928 441.601 477.289C433.377 475.65 425.381 480.989 423.742 489.213C422.104 497.437 427.442 505.433 435.666 507.071C443.89 508.71 451.886 503.372 453.525 495.148Z'
                  opacity='.1' />
            <path id='Vector_1184'
                  d='M451.123 491.598C451.123 497.792 446.104 502.811 439.91 502.811C433.717 502.811 428.698 497.792 428.698 491.598C428.698 485.404 433.717 480.386 439.91 480.386C446.104 480.386 451.123 485.404 451.123 491.598Z'
                  opacity='.2' />
            <path id='Vector_1185'
                  d='M439.91 483.905C439.431 487.309 439.91 491.598 433.831 492.112C435.635 492.949 437.641 493.685 438.897 495.367C440.012 496.867 440.923 499.393 441.113 501.223C441.457 499.237 440.998 497.394 442.22 495.367C443.132 493.854 444.686 492.801 446.415 492.598C444.47 491.524 442.781 491.693 441.599 489.369C441.146 488.484 440.734 487.552 440.457 486.634C440.167 485.681 440.268 484.202 439.647 483.506L439.91 483.898V483.905Z'
                  opacity='.7' />
          </g>
          <path id='Vector_1186' fill='white'
                d='M441.322 489.694C442.862 490.268 444.402 491.071 445.381 491.625C450.839 494.705 455.972 499.677 459.91 504.506C463.753 509.214 466.28 514.428 467.326 520.433C467.901 523.729 468.232 527.086 468.569 530.416L493.425 489.687H441.322V489.694Z'
                opacity='.1' />
          <path id='Vector_1187' fill='white'
                d='M474.169 489.694C474.169 489.694 455.547 514.09 455.878 513.591C456.209 513.091 486.691 489.849 486.691 489.849L474.169 489.687V489.694Z'
                opacity='.2' />
          <path id='Vector_1188' fill='white'
                d='M430.995 457.239L462.47 444.709H467.374L440.221 461.825L430.995 457.239Z' opacity='.2' />
          <path id='Vector_1189' fill='white'
                d='M453.075 464.709L481.463 444.601L485.253 446.276L471.615 464.777L453.075 464.709Z' opacity='.2' />
        </g>;
      case 1:
        return <g id='GAME GEM 4'>
          <path id='Vector_362' fill={`#${diamondColors[color][0]}`}
                d='M782.457 434.166L804.692 424.98H838.532L861.132 434.969L841.152 444.959H803.625L782.457 434.166Z' />
          <path id='Vector_363' fill={`#${diamondColors[color][1]}`}
                d='M782.457 434.166L768.009 450.471L797.33 470.126L803.625 444.959L782.457 434.166Z' />
          <path id='Vector_364' fill={`#${diamondColors[color][1]}`}
                d='M841.152 444.959L850.818 470.126L877.106 449.903L861.132 434.969L841.152 444.959Z' />
          <path id='Vector_365' fill={`#${diamondColors[color][2]}`}
                d='M803.625 444.959L841.301 445.27L850.818 470.126H797.33L803.625 444.959Z' />
          <path id='Vector_366' fill={`#${diamondColors[color][2]}`}
                d='M850.818 470.126L850.224 471.288L824.077 513.949L877.106 449.903L850.818 470.126Z' />
          <path id='Vector_367' fill={`#${diamondColors[color][2]}`}
                d='M797.33 470.126L824.077 513.949L768.009 450.471L797.33 470.126Z' />
          <path id='Vector_368' fill={`#${diamondColors[color][1]}`}
                d='M850.818 470.126L824.077 513.949L797.33 470.126H850.818Z' />
          <path id='Vector_369' fill={`#${diamondColors[color][3]}`}
                d='M784.111 434.99C785.307 436.712 786.34 438.563 787.34 440.258C790.076 444.905 791.771 450.059 793.892 454.976C795.29 458.225 796.067 461.44 796.472 464.912C796.668 466.567 796.823 468.323 797.181 470.011L797.337 470.113L803.632 444.946L784.118 434.99H784.111Z' />
          <path id='Vector_370' fill={`#${diamondColors[color][4]}`}
                d='M855.985 432.889C855.681 433.031 855.357 433.152 855.019 433.267C851.25 434.544 847.049 434.882 843.118 435.388C838.403 435.996 833.783 436.489 829.021 436.489C823.395 436.489 817.816 436.813 812.19 436.489C806.604 436.172 800.903 436.435 795.398 435.307C792.163 434.645 788.272 434.037 785.084 433.085L782.416 434.179L803.638 445.162H841.166L861.145 435.172L855.999 432.896L855.985 432.889Z' />
          <path id='Vector_371' fill='white'
                d='M841.152 442.548H803.625L785.077 433.085L782.457 434.166L803.625 445.155L841.132 445.27L861.132 434.969L858.565 433.835L841.152 442.548Z' />
          <path id='Vector_372' fill='white'
                d='M782.457 434.166L781.538 435.199L802.254 445.77L796.33 469.457L797.33 470.126L803.625 444.959L782.457 434.166Z' />
          <path id='Vector_373' fill={`#${diamondColors[color][2]}`}
                d='M851.304 469.964C851.304 461.994 854.344 455.206 858.194 448.363C859.842 445.439 861.78 442.723 862.908 439.508C863.976 436.462 861.922 435.415 859.322 436.732C854.857 438.988 850.501 441.285 845.989 443.5C843.01 444.959 841.524 445.635 842.76 449.187C843.611 451.633 844.759 454.051 845.826 456.428C847.704 460.629 849.899 465.324 851.304 469.316V469.957V469.964Z'
                opacity='.2' />
          <path id='Vector_374' fill='white'
                d='M842.767 445.466L862.03 435.827L861.132 434.99L841.152 444.979L850.818 470.146L851.919 469.295L842.767 445.466Z'
                opacity='.2' />
          <g id='Group_31' fill='white'>
            <path id='Vector_375'
                  d='M858.98 456.089C866.311 448.758 866.311 436.874 858.98 429.543C851.65 422.213 839.765 422.213 832.435 429.543C825.105 436.874 825.105 448.758 832.435 456.089C839.765 463.419 851.65 463.419 858.98 456.089Z'
                  opacity='.1' />
            <path id='Vector_376'
                  d='M852.554 454.893C859.629 451.98 863.002 443.883 860.089 436.808C857.176 429.734 849.079 426.36 842.005 429.273C834.93 432.186 831.556 440.283 834.469 447.357C837.383 454.432 845.479 457.806 852.554 454.893Z'
                  opacity='.2' />
            <path id='Vector_377'
                  d='M847.279 432.585C846.684 436.793 847.279 442.089 839.768 442.73C841.997 443.77 844.482 444.676 846.029 446.756C847.407 448.607 848.528 451.734 848.765 453.997C849.19 451.545 848.623 449.262 850.136 446.756C851.264 444.892 853.182 443.581 855.323 443.338C852.919 442.008 850.831 442.217 849.373 439.346C848.819 438.252 848.299 437.104 847.961 435.962C847.603 434.78 847.731 432.957 846.961 432.099L847.285 432.585H847.279Z'
                  opacity='.7' />
          </g>
          <g id='Group_32' fill='white'>
            <path id='Vector_378'
                  d='M810.924 475.418C812.563 467.194 807.224 459.198 799 457.56C790.776 455.921 782.781 461.259 781.142 469.483C779.503 477.707 784.841 485.703 793.065 487.342C801.29 488.981 809.285 483.642 810.924 475.418Z'
                  opacity='.1' />
            <path id='Vector_379'
                  d='M808.522 471.869C808.522 478.063 803.503 483.081 797.31 483.081C791.116 483.081 786.097 478.063 786.097 471.869C786.097 465.675 791.116 460.656 797.31 460.656C803.503 460.656 808.522 465.675 808.522 471.869Z'
                  opacity='.2' />
            <path id='Vector_380'
                  d='M797.31 464.175C796.83 467.58 797.31 471.869 791.231 472.382C793.034 473.22 795.04 473.956 796.296 475.638C797.411 477.137 798.323 479.663 798.512 481.494C798.856 479.508 798.397 477.664 799.62 475.638C800.531 474.125 802.085 473.071 803.814 472.868C801.869 471.794 800.18 471.963 798.998 469.64C798.546 468.755 798.134 467.823 797.857 466.904C797.566 465.952 797.668 464.473 797.046 463.777L797.31 464.169V464.175Z'
                  opacity='.7' />
          </g>
          <path id='Vector_381' fill='white'
                d='M798.721 469.964C800.261 470.538 801.801 471.342 802.781 471.896C808.238 474.976 813.372 479.947 817.309 484.776C821.153 489.484 823.679 494.699 824.726 500.703C825.3 503.999 825.631 507.356 825.968 510.686L850.825 469.957H798.721V469.964Z'
                opacity='.1' />
          <path id='Vector_382' fill='white'
                d='M831.568 469.964C831.568 469.964 812.946 494.361 813.277 493.861C813.608 493.361 844.091 470.119 844.091 470.119L831.568 469.957V469.964Z'
                opacity='.2' />
          <path id='Vector_383' fill='white' d='M788.394 437.509L819.869 424.98H824.773L797.62 442.095L788.394 437.509Z'
                opacity='.2' />
          <path id='Vector_384' fill='white'
                d='M810.474 444.979L838.863 424.872L842.652 426.547L829.015 445.047L810.474 444.979Z' opacity='.2' />
        </g>;
      case 2:
        return <g id='GAME GEM 1'>
          <path id='Vector_51' fill={`#${diamondColors[color][0]}`}
                d='M643.054 17.6454L664.652 8.72046H697.534L719.489 18.4345L700.079 28.1392H663.619L643.054 17.6454Z' />
          <path id='Vector_52' fill={`#${diamondColors[color][1]}`}
                d='M643.054 17.6454L629.009 33.4942L657.503 52.5936L663.619 28.1392L643.054 17.6454Z' />
          <path id='Vector_53' fill={`#${diamondColors[color][1]}`}
                d='M700.079 28.1392L709.465 52.5935L735.009 32.9399L719.489 18.4345L700.079 28.1392Z' />
          <path id='Vector_54' fill={`#${diamondColors[color][2]}`}
                d='M663.619 28.1392L700.22 28.4399L709.465 52.5936H657.503L663.619 28.1392Z' />
          <path id='Vector_55' fill={`#${diamondColors[color][2]}`}
                d='M709.465 52.5936L708.892 53.7116L683.479 95.1702L735.009 32.9399L709.465 52.5936Z' />
          <path id='Vector_56' fill={`#${diamondColors[color][2]}`}
                d='M657.503 52.5936L683.479 95.1702L629.009 33.4942L657.503 52.5936Z' />
          <path id='Vector_57' fill={`#${diamondColors[color][1]}`}
                d='M709.465 52.5936L683.479 95.1702L657.503 52.5936H709.465Z' />
          <path id='Vector_58' fill={`#${diamondColors[color][3]}`}
                d='M644.66 18.4534C645.816 20.1256 646.83 21.9294 647.798 23.5735C650.457 28.0923 652.101 33.0903 654.158 37.8722C655.521 41.0288 656.272 44.1478 656.667 47.5205C656.855 49.127 657.014 50.8368 657.352 52.4715L657.503 52.5748L663.619 28.1299L644.66 18.4534Z' />
          <path id='Vector_59' fill={`#${diamondColors[color][4]}`}
                d='M714.482 16.4053C714.181 16.5368 713.871 16.6589 713.542 16.7717C709.878 18.0118 705.791 18.3406 701.977 18.8291C697.402 19.421 692.911 19.9001 688.28 19.9001C682.812 19.9001 677.391 20.2101 671.924 19.9001C666.503 19.5901 660.96 19.8437 655.615 18.7539C652.467 18.1151 648.691 17.5233 645.59 16.5932L642.997 17.6548L663.619 28.3271H700.079L719.489 18.6224L714.482 16.4147V16.4053Z' />
          <path id='Vector_60' fill='white'
                d='M700.079 25.7905H663.619L645.59 16.5931L643.054 17.6453L663.619 28.3271L700.061 28.4398L719.489 18.4345L716.999 17.3259L700.079 25.7905Z' />
          <path id='Vector_61' fill='white'
                d='M643.054 17.6454L642.161 18.6506L662.285 28.919L656.526 51.9359L657.503 52.5936L663.619 28.1392L643.054 17.6454Z' />
          <path id='Vector_62' fill={`#${diamondColors[color][2]}`}
                d='M709.934 52.4338C709.934 44.6926 712.894 38.0976 716.624 31.4462C718.221 28.5996 720.109 25.9691 721.199 22.8406C722.232 19.8813 720.24 18.8667 717.713 20.1444C713.373 22.3333 709.145 24.5693 704.758 26.7206C701.864 28.1392 700.418 28.7968 701.62 32.2447C702.447 34.6215 703.565 36.9702 704.598 39.2813C706.421 43.3586 708.553 47.9244 709.925 51.8044V52.4338H709.934Z'
                opacity='.2' />
          <path id='Vector_63' fill='white'
                d='M701.639 28.6278L720.363 19.2613L719.489 18.4534L700.079 28.1581L709.465 52.603L710.536 51.7857L701.639 28.6278Z'
                opacity='.2' />
          <g id='Group' fill='white'>
            <path id='Vector_64'
                  d='M717.386 38.9529C724.507 31.8317 724.507 20.2859 717.386 13.1646C710.265 6.04341 698.719 6.04341 691.598 13.1646C684.476 20.2859 684.476 31.8317 691.598 38.9529C698.719 46.0741 710.265 46.0741 717.386 38.9529Z'
                  opacity='.1' />
            <path id='Vector_65'
                  d='M711.152 37.7897C718.027 34.9588 721.306 27.0904 718.475 20.2153C715.644 13.3402 707.776 10.0617 700.901 12.8926C694.025 15.7235 690.747 23.5918 693.578 30.467C696.409 37.3421 704.277 40.6206 711.152 37.7897Z'
                  opacity='.2' />
            <path id='Vector_66'
                  d='M706.026 16.114C705.453 20.2007 706.026 25.349 698.727 25.969C700.887 26.9743 703.302 27.8574 704.814 29.8866C706.158 31.6904 707.248 34.7249 707.473 36.9232C707.886 34.5464 707.332 32.3198 708.807 29.896C709.906 28.0828 711.766 26.8146 713.843 26.5703C711.513 25.2738 709.474 25.4805 708.065 22.6903C707.52 21.6287 707.022 20.5107 706.693 19.4022C706.346 18.256 706.468 16.4804 705.716 15.6443L706.026 16.114Z'
                  opacity='.7' />
          </g>
          <g id='Group_2' fill='white'>
            <path id='Vector_67'
                  d='M670.705 57.7273C672.297 49.7383 667.112 41.9715 659.123 40.3795C651.134 38.7875 643.367 43.9732 641.775 51.9621C640.183 59.9511 645.369 67.7179 653.358 69.3099C661.346 70.9019 669.113 65.7162 670.705 57.7273Z'
                  opacity='.1' />
            <path id='Vector_68'
                  d='M668.372 54.2752C668.372 60.2878 663.497 65.1636 657.484 65.1636C651.471 65.1636 646.596 60.2878 646.596 54.2752C646.596 48.2626 651.471 43.3868 657.484 43.3868C663.497 43.3868 668.372 48.2626 668.372 54.2752Z'
                  opacity='.2' />
            <path id='Vector_69'
                  d='M657.484 46.8064C657.014 50.1134 657.484 54.2752 651.584 54.7825C653.332 55.5999 655.286 56.3138 656.507 57.9485C657.587 59.4047 658.47 61.8567 658.658 63.6417C658.987 61.7158 658.546 59.9214 659.739 57.9485C660.631 56.4829 662.134 55.4589 663.816 55.2617C661.928 54.2188 660.284 54.3786 659.138 52.1238C658.696 51.2595 658.292 50.3576 658.029 49.4651C657.747 48.5351 657.85 47.0977 657.24 46.4213L657.493 46.797L657.484 46.8064Z'
                  opacity='.7' />
          </g>
          <path id='Vector_70' fill='white'
                d='M658.846 52.4338C660.34 52.9975 661.843 53.7773 662.792 54.3128C668.091 57.3097 673.079 62.1385 676.912 66.8265C680.642 71.4017 683.103 76.4654 684.118 82.2995C684.682 85.5031 685.001 88.7631 685.32 91.9948L709.465 52.4244H658.846V52.4338Z'
                opacity='.1' />
          <path id='Vector_71' fill='white'
                d='M690.76 52.4338C690.76 52.4338 672.666 76.1366 672.985 75.6575C673.305 75.1783 702.926 52.5936 702.926 52.5936L690.76 52.4338Z'
                opacity='.2' />
          <path id='Vector_72' fill='white' d='M648.813 20.896L679.392 8.72046H684.155L657.785 25.349L648.813 20.896Z'
                opacity='.2' />
          <path id='Vector_73' fill='white'
                d='M670.27 28.158L697.853 8.61707L701.536 10.2423L688.28 28.2237L670.27 28.158Z' opacity='.2' />
        </g>;
      case 3 :
        return <g id='GAME GEM 4_3'>
          <path id='Vector_1423' fill={`#${diamondColors[color][0]}`}
                d='M595.517 663.525L613.229 656.208H640.185L658.187 664.165L642.272 672.123H612.379L595.517 663.525Z' />
          <path id='Vector_1424' fill={`#${diamondColors[color][1]}`}
                d='M595.517 663.525L584.009 676.513L607.365 692.17L612.379 672.123L595.517 663.525Z' />
          <path id='Vector_1425' fill={`#${diamondColors[color][1]}`}
                d='M642.272 672.123L649.971 692.17L670.912 676.061L658.187 664.165L642.272 672.123Z' />
          <path id='Vector_1426' fill={`#${diamondColors[color][2]}`}
                d='M612.379 672.123L642.391 672.37L649.971 692.17H607.365L612.379 672.123Z' />
          <path id='Vector_1427' fill={`#${diamondColors[color][2]}`}
                d='M649.971 692.17L649.498 693.095L628.671 727.077L670.912 676.061L649.971 692.17Z' />
          <path id='Vector_1428' fill={`#${diamondColors[color][2]}`}
                d='M607.365 692.17L628.671 727.077L584.009 676.513L607.365 692.17Z' />
          <path id='Vector_1429' fill={`#${diamondColors[color][1]}`}
                d='M649.971 692.17L628.671 727.077L607.365 692.17H649.971Z' />
          <path id='Vector_1430' fill={`#${diamondColors[color][3]}`}
                d='M596.835 664.182C597.788 665.553 598.611 667.028 599.407 668.378C601.586 672.08 602.937 676.185 604.626 680.102C605.74 682.69 606.359 685.251 606.681 688.016C606.837 689.334 606.961 690.733 607.246 692.078L607.37 692.159L612.385 672.112L596.841 664.182H596.835Z' />
          <path id='Vector_1431' fill={`#${diamondColors[color][4]}`}
                d='M654.087 662.508C653.845 662.621 653.587 662.718 653.318 662.81C650.316 663.826 646.969 664.095 643.838 664.499C640.082 664.983 636.402 665.376 632.609 665.376C628.127 665.376 623.683 665.634 619.201 665.376C614.752 665.123 610.211 665.333 605.826 664.434C603.249 663.907 600.15 663.423 597.61 662.664L595.485 663.536L612.39 672.284H642.283L658.198 664.327L654.098 662.514L654.087 662.508Z' />
          <path id='Vector_1432' fill='white'
                d='M642.272 670.202H612.379L597.605 662.664L595.517 663.525L612.379 672.279L642.256 672.37L658.187 664.165L656.143 663.261L642.272 670.202Z' />
          <path id='Vector_1433' fill='white'
                d='M595.517 663.525L594.786 664.348L611.287 672.768L606.568 691.637L607.365 692.17L612.379 672.123L595.517 663.525Z' />
          <path id='Vector_1434' fill={`#${diamondColors[color][2]}`}
                d='M650.359 692.041C650.359 685.692 652.78 680.285 655.847 674.835C657.159 672.505 658.704 670.342 659.602 667.781C660.452 665.354 658.817 664.52 656.745 665.57C653.189 667.367 649.719 669.196 646.124 670.961C643.752 672.123 642.568 672.661 643.553 675.491C644.231 677.439 645.145 679.365 645.995 681.259C647.491 684.605 649.24 688.344 650.359 691.524V692.035V692.041Z'
                opacity='.2' />
          <path id='Vector_1435' fill='white'
                d='M643.558 672.526L658.903 664.849L658.187 664.182L642.272 672.139L649.971 692.186L650.848 691.508L643.558 672.526Z'
                opacity='.2' />
          <g id='Group_152' fill='white'>
            <path id='Vector_1436'
                  d='M656.473 680.988C662.312 675.149 662.312 665.682 656.473 659.843C650.634 654.004 641.167 654.004 635.328 659.843C629.489 665.682 629.489 675.149 635.328 680.988C641.167 686.827 650.634 686.827 656.473 680.988Z'
                  opacity='.1' />
            <path id='Vector_1437'
                  d='M651.354 680.035C656.99 677.715 659.677 671.266 657.356 665.63C655.036 659.995 648.587 657.307 642.951 659.628C637.316 661.948 634.628 668.398 636.949 674.033C639.269 679.669 645.719 682.356 651.354 680.035Z'
                  opacity='.2' />
            <path id='Vector_1438'
                  d='M647.152 662.266C646.679 665.618 647.152 669.836 641.169 670.347C642.945 671.176 644.925 671.897 646.157 673.554C647.254 675.028 648.147 677.519 648.336 679.322C648.675 677.369 648.223 675.55 649.428 673.554C650.326 672.069 651.854 671.025 653.56 670.832C651.645 669.772 649.982 669.938 648.82 667.652C648.379 666.78 647.965 665.866 647.695 664.956C647.41 664.015 647.513 662.562 646.899 661.879L647.157 662.266H647.152Z'
                  opacity='.7' />
          </g>
          <g id='Group_153' fill='white'>
            <path id='Vector_1439'
                  d='M618.193 696.385C619.499 689.834 615.246 683.465 608.695 682.16C602.144 680.854 595.775 685.107 594.47 691.658C593.164 698.209 597.417 704.578 603.968 705.883C610.519 707.189 616.888 702.936 618.193 696.385Z'
                  opacity='.1' />
            <path id='Vector_1440'
                  d='M616.28 693.558C616.28 698.492 612.282 702.489 607.349 702.489C602.415 702.489 598.417 698.492 598.417 693.558C598.417 688.624 602.415 684.627 607.349 684.627C612.282 684.627 616.28 688.624 616.28 693.558Z'
                  opacity='.2' />
            <path id='Vector_1441'
                  d='M607.349 687.43C606.967 690.142 607.349 693.558 602.506 693.967C603.943 694.634 605.541 695.221 606.541 696.56C607.429 697.755 608.156 699.767 608.306 701.225C608.581 699.643 608.215 698.174 609.189 696.56C609.915 695.355 611.152 694.516 612.53 694.354C610.98 693.499 609.635 693.633 608.694 691.783C608.333 691.078 608.005 690.335 607.784 689.603C607.553 688.845 607.634 687.667 607.139 687.112L607.349 687.424V687.43Z'
                  opacity='.7' />
          </g>
          <path id='Vector_1442' fill='white'
                d='M608.473 692.041C609.7 692.498 610.926 693.138 611.707 693.58C616.054 696.033 620.143 699.993 623.28 703.84C626.341 707.59 628.353 711.743 629.187 716.527C629.645 719.152 629.908 721.826 630.177 724.479L649.977 692.035H608.473V692.041Z'
                opacity='.1' />
          <path id='Vector_1443' fill='white'
                d='M634.637 692.041C634.637 692.041 619.804 711.474 620.068 711.076C620.331 710.678 644.613 692.165 644.613 692.165L634.637 692.035V692.041Z'
                opacity='.2' />
          <path id='Vector_1444' fill='white'
                d='M600.247 666.188L625.319 656.208H629.225L607.596 669.842L600.247 666.188Z' opacity='.2' />
          <path id='Vector_1445' fill='white'
                d='M617.835 672.139L640.448 656.122L643.467 657.456L632.604 672.193L617.835 672.139Z' opacity='.2' />
        </g>;
    }
  }, [index]);

  const renderedMiniGem = useMemo(() => {
    switch (index) {
      case 4 :
        return <g id='Mini gem_12'>
          <path id='Vector_1190' fill={`#${diamondColors[color][0]}`}
                d='M324.027 587.956L352.009 614.06L331.196 633.55L324.026 588.888L297.339 619.176L326.616 661.89L331.196 633.55L324.027 587.956Z' />
          <path id='Vector_1191' fill={`#${diamondColors[color][1]}`}
                d='M323.98 587.956L297.339 619.027L331.182 633.775L323.98 587.956Z' />
          <path id='Vector_1192' fill='white'
                d='M323.981 587.956L323.237 588.823L330.033 632.056L298.099 618.14L297.339 619.027L331.182 633.775L323.981 587.956Z' />
          <path id='Vector_1193' fill={`#${diamondColors[color][2]}`}
                d='M331.098 633.508L326.496 661.89L352.009 613.99L331.098 633.508Z' />
          <path id='Vector_1194' fill={`#${diamondColors[color][2]}`}
                d='M331.098 633.508L326.496 661.89L352.009 613.99L331.098 633.508Z' />
          <path id='Vector_1195' fill='white' d='M297.339 619.196L327.538 610.065L324.07 587.956L297.339 619.196Z'
                opacity='.2' />
          <path id='Vector_1196' fill='white'
                d='M327.372 609.991L352.009 613.99L323.893 587.956L327.372 609.588V609.991Z' opacity='.2' />
          <path id='Vector_1197' fill={`#${diamondColors[color][3]}`}
                d='M329.186 645.328C329.236 645.02 344.104 621.622 344.104 621.622L352.009 614.51L334.054 647.735L326.496 661.89L329.186 645.328Z'
                opacity='.5' />
          <path id='Vector_1198' fill={`#${diamondColors[color][3]}`}
                d='M331.999 633.509L351.119 615.661L352.009 613.99L331.098 633.509L326.496 661.89L327.791 659.46L331.999 633.509Z' />
          <path id='Vector_1199' fill={`#${diamondColors[color][3]}`}
                d='M307.187 633.575L330.662 633.775L297.339 619.196L307.187 633.575Z'
                opacity='.5' />
          <path id='Vector_1200' fill='white'
                d='M298.901 618.655C299.928 619.707 302.454 619.856 303.884 620.13C306.863 620.774 309.935 620.883 312.953 620.453C316.61 619.864 319.482 618.256 322.321 615.993C324.175 614.516 324.908 613.064 325.684 610.964C325.914 610.428 326.095 609.872 326.225 609.304C327.16 609.799 327.036 612.967 327.304 614.022C327.831 616.096 328.405 618.112 328.622 620.233C329.033 623.743 329.626 627.23 330.399 630.68C330.545 631.386 331.004 632.299 330.199 632.655C329.562 632.938 328.429 632.382 327.879 632.181C326.248 631.584 324.987 630.557 323.417 629.816C321.891 629.095 320.278 628.711 318.738 627.99C317.109 627.227 315.516 626.536 313.95 625.652C311.551 624.297 308.888 623.238 306.378 622.18C305.117 621.647 303.793 621.145 302.594 620.597C301.644 620.163 299.53 619.662 299 618.752L298.901 618.655Z'
                opacity='.4' />
          <path id='Vector_1201' fill='white'
                d='M335.731 641.973C341.028 638.505 342.511 631.399 339.043 626.102C335.575 620.805 328.469 619.322 323.172 622.79C317.875 626.258 316.392 633.364 319.86 638.661C323.328 643.958 330.434 645.441 335.731 641.973Z'
                opacity='.1' />
          <path id='Vector_1202' fill='white'
                d='M338.927 633.012C338.717 634.698 338.012 636.284 336.901 637.57C335.79 638.855 334.323 639.783 332.685 640.234C331.047 640.686 329.312 640.642 327.699 640.107C326.086 639.573 324.668 638.572 323.624 637.231C322.58 635.891 321.956 634.271 321.833 632.576C321.71 630.881 322.092 629.188 322.931 627.711C323.77 626.233 325.028 625.038 326.546 624.275C328.065 623.513 329.775 623.217 331.461 623.427C333.722 623.708 335.779 624.876 337.179 626.673C338.579 628.471 339.208 630.751 338.927 633.012Z'
                opacity='.2' />
          <path id='Vector_1203' fill='white'
                d='M331.288 626.285C330.611 628.816 330.572 632.101 325.976 631.922C327.245 632.726 328.682 633.469 329.465 634.863C330.191 636.299 330.571 637.889 330.575 639.502C331.016 638.035 330.844 636.592 331.949 635.178C332.351 634.653 332.866 634.227 333.455 633.933C334.044 633.639 334.691 633.484 335.348 633.48C333.994 632.483 332.712 632.452 332.046 630.585C331.784 629.878 331.582 629.149 331.444 628.407C331.32 627.658 331.531 626.55 331.129 625.965L331.288 626.285Z'
                opacity='.7' />
          <path id='Vector_1204' fill='white'
                d='M332.131 632.501C331.806 631.452 331.455 631.574 331.153 632.022C329.634 631.264 329.853 636.43 331.623 635.124C332.223 634.682 332.332 633.15 332.131 632.501Z' />
          <path id='Vector_1205' fill='white'
                d='M305.088 619.931C304.891 621.515 304.229 623.005 303.185 624.213C302.141 625.42 300.763 626.291 299.224 626.716C297.685 627.14 296.055 627.098 294.54 626.596C293.025 626.094 291.693 625.154 290.712 623.895C289.731 622.636 289.146 621.114 289.03 619.522C288.914 617.93 289.273 616.339 290.061 614.951C290.849 613.563 292.031 612.44 293.457 611.724C294.884 611.007 296.491 610.73 298.075 610.927C300.199 611.191 302.131 612.288 303.446 613.977C304.761 615.665 305.352 617.807 305.088 619.931Z'
                opacity='.1' />
          <path id='Vector_1206' fill='white'
                d='M304.062 619.676C303.916 620.851 303.424 621.956 302.65 622.852C301.875 623.748 300.853 624.394 299.711 624.708C298.57 625.023 297.36 624.992 296.236 624.619C295.112 624.247 294.124 623.549 293.396 622.615C292.669 621.681 292.235 620.552 292.149 619.371C292.063 618.19 292.329 617.01 292.913 615.98C293.498 614.95 294.375 614.117 295.433 613.585C296.491 613.054 297.683 612.848 298.858 612.994C299.638 613.091 300.392 613.34 301.076 613.729C301.76 614.117 302.361 614.636 302.844 615.257C303.328 615.877 303.684 616.587 303.893 617.345C304.102 618.103 304.159 618.895 304.062 619.676Z'
                opacity='.2' />
          <path id='Vector_1207' fill='white'
                d='M298.572 614.733C298.083 616.486 298.056 618.76 294.736 618.634C295.653 619.191 296.69 619.706 297.255 620.67C297.78 621.665 298.055 622.765 298.058 623.882C298.376 622.866 298.252 621.868 299.05 620.889C299.34 620.525 299.712 620.231 300.138 620.027C300.563 619.823 301.03 619.716 301.505 619.713C300.527 619.023 299.602 619.001 299.12 617.709C298.931 617.219 298.785 616.715 298.685 616.201C298.594 615.683 298.748 614.915 298.458 614.51L298.572 614.733Z'
                opacity='.7' />
          <path id='Vector_1208' fill='white'
                d='M329.054 589.704C328.908 590.828 328.417 591.885 327.642 592.743C326.868 593.6 325.846 594.218 324.704 594.519C323.562 594.82 322.353 594.791 321.229 594.434C320.105 594.078 319.116 593.411 318.388 592.517C317.661 591.624 317.226 590.544 317.14 589.414C317.054 588.284 317.32 587.155 317.905 586.17C318.49 585.185 319.367 584.388 320.425 583.88C321.483 583.371 322.675 583.175 323.851 583.314C325.426 583.502 326.86 584.28 327.836 585.478C328.812 586.677 329.25 588.196 329.054 589.704Z'
                opacity='.1' />
          <path id='Vector_1209' fill='white'
                d='M328.025 589.511C327.917 590.329 327.553 591.098 326.981 591.721C326.409 592.345 325.653 592.794 324.809 593.013C323.965 593.232 323.071 593.211 322.24 592.952C321.41 592.692 320.679 592.207 320.141 591.557C319.603 590.907 319.282 590.122 319.219 589.3C319.155 588.478 319.352 587.657 319.784 586.941C320.217 586.224 320.865 585.645 321.647 585.275C322.429 584.906 323.311 584.763 324.179 584.864C324.756 584.932 325.313 585.105 325.818 585.375C326.324 585.646 326.767 586.007 327.125 586.438C327.482 586.87 327.745 587.363 327.899 587.891C328.054 588.418 328.096 588.969 328.025 589.511Z'
                opacity='.2' />
          <path id='Vector_1210' fill='white'
                d='M323.946 586.034C323.608 587.3 323.589 588.942 321.29 588.852C321.925 589.254 322.643 589.626 323.034 590.323C323.397 591.041 323.588 591.836 323.59 592.642C323.81 591.909 323.724 591.188 324.277 590.48C324.478 590.218 324.735 590.005 325.03 589.858C325.324 589.711 325.647 589.634 325.976 589.632C325.299 589.133 324.658 589.117 324.325 588.184C324.194 587.83 324.093 587.466 324.024 587.095C323.961 586.72 324.067 586.166 323.866 585.874L323.946 586.034Z'
                opacity='.7' />
          <path id='Vector_1211' fill='white'
                d='M298.832 618.848C298.588 617.949 298.325 618.054 298.098 618.439C296.959 617.789 297.123 622.216 298.451 621.097C298.901 620.717 298.983 619.404 298.832 618.848Z' />
        </g>;
      case 5:

       return <g id='Mini gem_13'>
          <path id='Vector_1212' fill={`#${diamondColors[color][0]}`}
                d='M186.027 771.956L214.009 798.06L193.196 817.55L186.026 772.888L159.339 803.176L188.616 845.89L193.196 817.55L186.027 771.956Z' />
          <path id='Vector_1213' fill={`#${diamondColors[color][1]}`}
                d='M185.98 771.956L159.339 803.027L193.182 817.775L185.98 771.956Z' />
          <path id='Vector_1214' fill='white'
                d='M185.981 771.956L185.237 772.823L192.033 816.056L160.099 802.14L159.339 803.027L193.182 817.775L185.981 771.956Z' />
          <path id='Vector_1215' fill={`#${diamondColors[color][2]}`}
                d='M193.098 817.508L188.496 845.89L214.009 797.99L193.098 817.508Z' />
          <path id='Vector_1216' fill={`#${diamondColors[color][2]}`}
                d='M193.098 817.508L188.496 845.89L214.009 797.99L193.098 817.508Z' />
          <path id='Vector_1217' fill='white' d='M159.339 803.196L189.538 794.065L186.07 771.956L159.339 803.196Z'
                opacity='.2' />
          <path id='Vector_1218' fill='white'
                d='M189.372 793.991L214.009 797.99L185.893 771.956L189.372 793.588V793.991Z' opacity='.2' />
          <path id='Vector_1219' fill={`#${diamondColors[color][3]}`}
                d='M191.186 829.328C191.236 829.02 206.104 805.622 206.104 805.622L214.009 798.51L196.054 831.735L188.496 845.89L191.186 829.328Z'
                opacity='.5' />
          <path id='Vector_1220' fill={`#${diamondColors[color][3]}`}
                d='M193.999 817.509L213.119 799.661L214.009 797.99L193.098 817.509L188.496 845.89L189.791 843.46L193.999 817.509Z' />
          <path id='Vector_1221' fill={`#${diamondColors[color][3]}`}
                d='M169.187 817.575L192.662 817.775L159.339 803.196L169.187 817.575Z'
                opacity='.5' />
          <path id='Vector_1222' fill='white'
                d='M160.901 802.655C161.928 803.707 164.454 803.856 165.884 804.13C168.863 804.774 171.935 804.883 174.953 804.453C178.61 803.864 181.482 802.256 184.321 799.993C186.175 798.516 186.908 797.064 187.684 794.964C187.914 794.428 188.095 793.872 188.225 793.304C189.16 793.799 189.036 796.967 189.304 798.022C189.831 800.096 190.405 802.112 190.622 804.233C191.033 807.743 191.626 811.23 192.399 814.68C192.545 815.386 193.004 816.299 192.199 816.655C191.562 816.938 190.429 816.382 189.879 816.181C188.248 815.584 186.987 814.557 185.417 813.816C183.891 813.095 182.278 812.711 180.738 811.99C179.109 811.227 177.516 810.536 175.95 809.652C173.551 808.297 170.888 807.238 168.378 806.18C167.117 805.647 165.793 805.145 164.594 804.597C163.644 804.163 161.53 803.662 161 802.752L160.901 802.655Z'
                opacity='.4' />
          <path id='Vector_1223' fill='white'
                d='M197.731 825.973C203.028 822.505 204.511 815.399 201.043 810.102C197.575 804.805 190.469 803.322 185.172 806.79C179.875 810.258 178.392 817.364 181.86 822.661C185.328 827.958 192.434 829.441 197.731 825.973Z'
                opacity='.1' />
          <path id='Vector_1224' fill='white'
                d='M200.927 817.012C200.717 818.698 200.012 820.284 198.901 821.57C197.79 822.855 196.323 823.783 194.685 824.234C193.047 824.686 191.312 824.642 189.699 824.107C188.086 823.573 186.668 822.572 185.624 821.231C184.58 819.891 183.957 818.271 183.833 816.576C183.71 814.881 184.092 813.188 184.931 811.711C185.77 810.233 187.028 809.038 188.546 808.275C190.065 807.513 191.775 807.217 193.461 807.427C195.722 807.708 197.779 808.876 199.179 810.673C200.579 812.471 201.208 814.751 200.927 817.012Z'
                opacity='.2' />
          <path id='Vector_1225' fill='white'
                d='M193.288 810.285C192.611 812.816 192.572 816.101 187.976 815.922C189.245 816.726 190.682 817.469 191.465 818.863C192.191 820.299 192.571 821.889 192.575 823.502C193.016 822.035 192.844 820.592 193.949 819.178C194.351 818.653 194.866 818.227 195.455 817.933C196.044 817.639 196.691 817.484 197.348 817.48C195.994 816.483 194.712 816.452 194.046 814.585C193.784 813.878 193.582 813.149 193.444 812.407C193.32 811.658 193.531 810.55 193.129 809.965L193.288 810.285Z'
                opacity='.7' />
          <path id='Vector_1226' fill='white'
                d='M194.131 816.501C193.806 815.452 193.455 815.574 193.153 816.022C191.634 815.264 191.853 820.43 193.623 819.124C194.223 818.682 194.332 817.15 194.131 816.501Z' />
          <path id='Vector_1227' fill='white'
                d='M167.088 803.931C166.891 805.515 166.229 807.005 165.185 808.213C164.141 809.42 162.763 810.291 161.224 810.716C159.685 811.14 158.055 811.098 156.54 810.596C155.025 810.094 153.693 809.154 152.712 807.895C151.731 806.636 151.146 805.114 151.03 803.522C150.914 801.93 151.273 800.339 152.061 798.951C152.849 797.563 154.031 796.44 155.457 795.724C156.884 795.007 158.491 794.73 160.075 794.927C162.199 795.191 164.131 796.288 165.446 797.977C166.761 799.665 167.352 801.807 167.088 803.931Z'
                opacity='.1' />
          <path id='Vector_1228' fill='white'
                d='M166.062 803.676C165.916 804.851 165.424 805.956 164.65 806.852C163.875 807.748 162.853 808.394 161.711 808.708C160.57 809.023 159.36 808.992 158.236 808.619C157.112 808.247 156.124 807.549 155.396 806.615C154.669 805.681 154.235 804.552 154.149 803.371C154.063 802.19 154.329 801.01 154.913 799.98C155.498 798.95 156.375 798.117 157.433 797.585C158.491 797.054 159.683 796.848 160.858 796.994C161.638 797.091 162.392 797.34 163.076 797.729C163.76 798.117 164.361 798.636 164.844 799.257C165.328 799.877 165.684 800.587 165.893 801.345C166.102 802.103 166.159 802.895 166.062 803.676Z'
                opacity='.2' />
          <path id='Vector_1229' fill='white'
                d='M160.572 798.733C160.083 800.486 160.056 802.76 156.736 802.634C157.653 803.191 158.69 803.706 159.255 804.67C159.78 805.665 160.055 806.765 160.058 807.882C160.376 806.866 160.252 805.868 161.05 804.889C161.34 804.525 161.712 804.231 162.138 804.027C162.563 803.823 163.03 803.716 163.505 803.713C162.527 803.023 161.602 803.001 161.12 801.709C160.931 801.219 160.785 800.715 160.685 800.201C160.594 799.683 160.748 798.915 160.458 798.51L160.572 798.733Z'
                opacity='.7' />
          <path id='Vector_1230' fill='white'
                d='M191.054 773.704C190.908 774.828 190.417 775.885 189.642 776.743C188.868 777.6 187.846 778.218 186.704 778.519C185.562 778.82 184.353 778.791 183.229 778.434C182.105 778.078 181.116 777.411 180.388 776.517C179.661 775.624 179.226 774.544 179.14 773.414C179.054 772.284 179.32 771.155 179.905 770.17C180.49 769.185 181.367 768.388 182.425 767.88C183.483 767.371 184.675 767.175 185.851 767.314C187.426 767.502 188.86 768.28 189.836 769.478C190.812 770.677 191.25 772.196 191.054 773.704Z'
                opacity='.1' />
          <path id='Vector_1231' fill='white'
                d='M190.024 773.511C189.917 774.329 189.553 775.098 188.981 775.721C188.409 776.345 187.653 776.794 186.809 777.013C185.965 777.232 185.071 777.211 184.24 776.952C183.409 776.692 182.679 776.207 182.141 775.557C181.603 774.907 181.282 774.122 181.219 773.3C181.155 772.478 181.352 771.657 181.784 770.941C182.217 770.224 182.865 769.645 183.647 769.275C184.429 768.906 185.311 768.763 186.179 768.864C186.756 768.932 187.313 769.105 187.818 769.375C188.324 769.646 188.767 770.007 189.125 770.438C189.482 770.87 189.745 771.363 189.899 771.891C190.054 772.418 190.096 772.969 190.024 773.511Z'
                opacity='.2' />
          <path id='Vector_1232' fill='white'
                d='M185.946 770.034C185.608 771.3 185.589 772.942 183.29 772.852C183.925 773.254 184.643 773.626 185.034 774.323C185.397 775.041 185.588 775.836 185.59 776.642C185.81 775.909 185.724 775.188 186.277 774.48C186.478 774.218 186.735 774.005 187.03 773.858C187.324 773.711 187.647 773.634 187.976 773.631C187.299 773.133 186.658 773.117 186.325 772.184C186.194 771.83 186.093 771.466 186.024 771.095C185.961 770.72 186.067 770.166 185.866 769.874L185.946 770.034Z'
                opacity='.7' />
          <path id='Vector_1233' fill='white'
                d='M160.832 802.848C160.588 801.949 160.325 802.054 160.098 802.439C158.959 801.789 159.123 806.216 160.451 805.097C160.901 804.717 160.983 803.404 160.832 802.848Z' />
        </g>;

      case 6 :
        return <g id='Mini gem_14'>
          <path id='Vector_1234' fill={`#${diamondColors[color][0]}`}
                d='M364.027 774.956L392.009 801.06L371.196 820.55L364.026 775.888L337.339 806.176L366.616 848.89L371.196 820.55L364.027 774.956Z' />
          <path id='Vector_1235' fill={`#${diamondColors[color][1]}`}
                d='M363.98 774.956L337.339 806.027L371.182 820.775L363.98 774.956Z' />
          <path id='Vector_1236' fill='white'
                d='M363.981 774.956L363.237 775.823L370.033 819.056L338.099 805.14L337.339 806.027L371.182 820.775L363.981 774.956Z' />
          <path id='Vector_1237' fill={`#${diamondColors[color][2]}`}
                d='M371.098 820.508L366.496 848.89L392.009 800.99L371.098 820.508Z' />
          <path id='Vector_1238' fill={`#${diamondColors[color][2]}`}
                d='M371.098 820.508L366.496 848.89L392.009 800.99L371.098 820.508Z' />
          <path id='Vector_1239' fill='white' d='M337.339 806.196L367.538 797.065L364.07 774.956L337.339 806.196Z'
                opacity='.2' />
          <path id='Vector_1240' fill='white'
                d='M367.372 796.991L392.009 800.99L363.893 774.956L367.372 796.588V796.991Z' opacity='.2' />
          <path id='Vector_1241' fill={`#${diamondColors[color][2]}`}
                d='M369.186 832.328C369.236 832.02 384.104 808.622 384.104 808.622L392.009 801.51L374.054 834.735L366.496 848.89L369.186 832.328Z'
                opacity='.5' />
          <path id='Vector_1242' fill={`#${diamondColors[color][2]}`}
                d='M371.999 820.509L391.119 802.661L392.009 800.99L371.098 820.509L366.496 848.89L367.791 846.46L371.999 820.509Z' />
          <path id='Vector_1243' fill={`#${diamondColors[color][3]}`}
                d='M347.187 820.575L370.662 820.775L337.339 806.196L347.187 820.575Z'
                opacity='.5' />
          <path id='Vector_1244' fill='white'
                d='M338.901 805.655C339.928 806.707 342.454 806.856 343.884 807.13C346.863 807.774 349.935 807.883 352.953 807.453C356.61 806.864 359.482 805.256 362.321 802.993C364.175 801.516 364.908 800.064 365.684 797.964C365.914 797.428 366.095 796.872 366.225 796.304C367.16 796.799 367.036 799.967 367.304 801.022C367.831 803.096 368.405 805.112 368.622 807.233C369.033 810.743 369.626 814.23 370.399 817.68C370.545 818.386 371.004 819.299 370.199 819.655C369.562 819.938 368.429 819.382 367.879 819.181C366.248 818.584 364.987 817.557 363.417 816.816C361.891 816.095 360.278 815.711 358.738 814.99C357.109 814.227 355.516 813.536 353.95 812.652C351.551 811.297 348.888 810.238 346.378 809.18C345.117 808.647 343.793 808.145 342.594 807.597C341.644 807.163 339.53 806.662 339 805.752L338.901 805.655Z'
                opacity='.4' />
          <path id='Vector_1245' fill='white'
                d='M375.731 828.973C381.028 825.505 382.511 818.399 379.043 813.102C375.575 807.805 368.469 806.322 363.172 809.79C357.875 813.258 356.392 820.364 359.86 825.661C363.328 830.958 370.434 832.441 375.731 828.973Z'
                opacity='.1' />
          <path id='Vector_1246' fill='white'
                d='M378.927 820.012C378.717 821.698 378.012 823.284 376.901 824.57C375.79 825.855 374.323 826.783 372.685 827.234C371.047 827.686 369.312 827.642 367.699 827.107C366.086 826.573 364.668 825.572 363.624 824.231C362.58 822.891 361.956 821.271 361.833 819.576C361.71 817.881 362.092 816.188 362.931 814.711C363.77 813.233 365.028 812.038 366.546 811.275C368.065 810.513 369.775 810.217 371.461 810.427C373.722 810.708 375.779 811.876 377.179 813.673C378.579 815.471 379.208 817.751 378.927 820.012Z'
                opacity='.2' />
          <path id='Vector_1247' fill='white'
                d='M371.288 813.285C370.611 815.816 370.572 819.101 365.976 818.922C367.245 819.726 368.682 820.469 369.465 821.863C370.191 823.299 370.571 824.889 370.575 826.502C371.016 825.035 370.844 823.592 371.949 822.178C372.351 821.653 372.866 821.227 373.455 820.933C374.044 820.639 374.691 820.484 375.348 820.48C373.994 819.483 372.712 819.452 372.046 817.585C371.784 816.878 371.582 816.149 371.444 815.407C371.32 814.658 371.531 813.55 371.129 812.965L371.288 813.285Z'
                opacity='.7' />
          <path id='Vector_1248' fill='white'
                d='M372.131 819.501C371.806 818.452 371.455 818.574 371.153 819.022C369.634 818.264 369.853 823.43 371.623 822.124C372.223 821.682 372.332 820.15 372.131 819.501Z' />
          <path id='Vector_1249' fill='white'
                d='M345.088 806.931C344.891 808.515 344.229 810.005 343.185 811.213C342.141 812.42 340.763 813.291 339.224 813.716C337.685 814.14 336.055 814.098 334.54 813.596C333.025 813.094 331.693 812.154 330.712 810.895C329.731 809.636 329.146 808.114 329.03 806.522C328.914 804.93 329.273 803.339 330.061 801.951C330.849 800.563 332.031 799.44 333.457 798.724C334.884 798.007 336.491 797.73 338.075 797.927C340.199 798.191 342.131 799.288 343.446 800.977C344.761 802.665 345.352 804.807 345.088 806.931Z'
                opacity='.1' />
          <path id='Vector_1250' fill='white'
                d='M344.062 806.676C343.916 807.851 343.424 808.956 342.65 809.852C341.875 810.748 340.853 811.394 339.711 811.708C338.57 812.023 337.36 811.992 336.236 811.619C335.112 811.247 334.124 810.549 333.396 809.615C332.669 808.681 332.235 807.552 332.149 806.371C332.063 805.19 332.329 804.01 332.913 802.98C333.498 801.95 334.375 801.117 335.433 800.585C336.491 800.054 337.683 799.848 338.858 799.994C339.638 800.091 340.392 800.34 341.076 800.729C341.76 801.117 342.361 801.636 342.844 802.257C343.328 802.877 343.684 803.587 343.893 804.345C344.102 805.103 344.159 805.895 344.062 806.676Z'
                opacity='.2' />
          <path id='Vector_1251' fill='white'
                d='M338.572 801.733C338.083 803.486 338.056 805.76 334.736 805.634C335.653 806.191 336.69 806.706 337.255 807.67C337.78 808.665 338.055 809.765 338.058 810.882C338.376 809.866 338.252 808.868 339.05 807.889C339.34 807.525 339.712 807.231 340.138 807.027C340.563 806.823 341.03 806.716 341.505 806.713C340.527 806.023 339.602 806.001 339.12 804.709C338.931 804.219 338.785 803.715 338.685 803.201C338.594 802.683 338.748 801.915 338.458 801.51L338.572 801.733Z'
                opacity='.7' />
          <path id='Vector_1252' fill='white'
                d='M369.054 776.704C368.908 777.828 368.417 778.885 367.642 779.743C366.868 780.6 365.846 781.218 364.704 781.519C363.562 781.82 362.353 781.791 361.229 781.434C360.105 781.078 359.116 780.411 358.388 779.517C357.661 778.624 357.226 777.544 357.14 776.414C357.054 775.284 357.32 774.155 357.905 773.17C358.49 772.185 359.367 771.388 360.425 770.88C361.483 770.371 362.675 770.175 363.851 770.314C365.426 770.502 366.86 771.28 367.836 772.478C368.812 773.677 369.25 775.196 369.054 776.704Z'
                opacity='.1' />
          <path id='Vector_1253' fill='white'
                d='M368.025 776.511C367.917 777.329 367.553 778.098 366.981 778.721C366.409 779.345 365.653 779.794 364.809 780.013C363.965 780.232 363.071 780.211 362.24 779.952C361.41 779.692 360.679 779.207 360.141 778.557C359.603 777.907 359.282 777.122 359.219 776.3C359.155 775.478 359.352 774.657 359.784 773.941C360.217 773.224 360.865 772.645 361.647 772.275C362.429 771.906 363.311 771.763 364.179 771.864C364.756 771.932 365.313 772.105 365.818 772.375C366.324 772.646 366.767 773.007 367.125 773.438C367.482 773.87 367.745 774.363 367.899 774.891C368.054 775.418 368.096 775.969 368.025 776.511Z'
                opacity='.2' />
          <path id='Vector_1254' fill='white'
                d='M363.946 773.034C363.608 774.3 363.589 775.942 361.29 775.852C361.925 776.254 362.643 776.626 363.034 777.323C363.397 778.041 363.588 778.836 363.59 779.642C363.81 778.909 363.724 778.188 364.277 777.48C364.478 777.218 364.735 777.005 365.03 776.858C365.324 776.711 365.647 776.634 365.976 776.631C365.299 776.133 364.658 776.117 364.325 775.184C364.194 774.83 364.093 774.466 364.024 774.095C363.961 773.72 364.067 773.166 363.866 772.874L363.946 773.034Z'
                opacity='.7' />
          <path id='Vector_1255' fill='white'
                d='M338.832 805.848C338.588 804.949 338.325 805.054 338.098 805.439C336.959 804.789 337.123 809.216 338.451 808.097C338.901 807.717 338.983 806.404 338.832 805.848Z' />
        </g>;

      case 7 :
        return <g id='Mini gem_15'>
          <path id='Vector_1256' fill={`#${diamondColors[color][0]}`}
                d='M471.027 639.956L499.009 666.06L478.196 685.55L471.026 640.888L444.339 671.176L473.616 713.89L478.196 685.55L471.027 639.956Z' />
          <path id='Vector_1257' fill={`#${diamondColors[color][1]}`}
                d='M470.98 639.956L444.339 671.027L478.182 685.775L470.98 639.956Z' />
          <path id='Vector_1258' fill='white'
                d='M470.981 639.956L470.237 640.823L477.033 684.056L445.099 670.14L444.339 671.027L478.182 685.775L470.981 639.956Z' />
          <path id='Vector_1259' fill={`#${diamondColors[color][2]}`}
                d='M478.098 685.508L473.496 713.89L499.009 665.99L478.098 685.508Z' />
          <path id='Vector_1260' fill={`#${diamondColors[color][2]}`}
                d='M478.098 685.508L473.496 713.89L499.009 665.99L478.098 685.508Z' />
          <path id='Vector_1261' fill='white' d='M444.339 671.196L474.538 662.065L471.07 639.956L444.339 671.196Z'
                opacity='.2' />
          <path id='Vector_1262' fill='white'
                d='M474.372 661.991L499.009 665.99L470.893 639.956L474.372 661.588V661.991Z' opacity='.2' />
          <path id='Vector_1263' fill={`#${diamondColors[color][3]}`}
                d='M476.186 697.328C476.236 697.02 491.104 673.622 491.104 673.622L499.009 666.51L481.054 699.735L473.496 713.89L476.186 697.328Z'
                opacity='.5' />
          <path id='Vector_1264' fill={`#${diamondColors[color][3]}`}
                d='M478.999 685.509L498.119 667.661L499.009 665.99L478.098 685.509L473.496 713.89L474.791 711.46L478.999 685.509Z' />
          <path id='Vector_1265' fill={`#${diamondColors[color][3]}`}
                d='M454.187 685.575L477.662 685.775L444.339 671.196L454.187 685.575Z'
                opacity='.5' />
          <path id='Vector_1266' fill='white'
                d='M445.901 670.655C446.928 671.707 449.454 671.856 450.884 672.13C453.863 672.774 456.935 672.883 459.953 672.453C463.61 671.864 466.482 670.256 469.321 667.993C471.175 666.516 471.908 665.064 472.684 662.964C472.914 662.428 473.095 661.872 473.225 661.304C474.16 661.799 474.036 664.967 474.304 666.022C474.831 668.096 475.405 670.112 475.622 672.233C476.033 675.743 476.626 679.23 477.399 682.68C477.545 683.386 478.004 684.299 477.199 684.655C476.562 684.938 475.429 684.382 474.879 684.181C473.248 683.584 471.987 682.557 470.417 681.816C468.891 681.095 467.278 680.711 465.739 679.99C464.109 679.227 462.516 678.536 460.95 677.652C458.551 676.297 455.888 675.238 453.378 674.18C452.117 673.647 450.793 673.145 449.594 672.597C448.644 672.163 446.53 671.662 446 670.752L445.901 670.655Z'
                opacity='.4' />
          <path id='Vector_1267' fill='white'
                d='M482.731 693.973C488.028 690.505 489.511 683.399 486.043 678.102C482.575 672.805 475.469 671.322 470.172 674.79C464.875 678.258 463.392 685.364 466.86 690.661C470.328 695.958 477.434 697.441 482.731 693.973Z'
                opacity='.1' />
          <path id='Vector_1268' fill='white'
                d='M485.927 685.012C485.717 686.698 485.012 688.284 483.901 689.57C482.79 690.855 481.323 691.783 479.685 692.234C478.047 692.686 476.312 692.642 474.699 692.107C473.086 691.573 471.668 690.572 470.624 689.231C469.58 687.891 468.956 686.271 468.833 684.576C468.71 682.881 469.092 681.188 469.931 679.711C470.77 678.233 472.028 677.038 473.546 676.275C475.065 675.513 476.775 675.217 478.461 675.427C480.722 675.708 482.779 676.876 484.179 678.673C485.579 680.471 486.208 682.751 485.927 685.012Z'
                opacity='.2' />
          <path id='Vector_1269' fill='white'
                d='M478.288 678.285C477.611 680.816 477.572 684.101 472.976 683.922C474.245 684.726 475.682 685.469 476.465 686.863C477.191 688.299 477.571 689.889 477.575 691.502C478.016 690.035 477.844 688.592 478.949 687.178C479.351 686.653 479.866 686.227 480.455 685.933C481.044 685.639 481.691 685.484 482.348 685.48C480.994 684.483 479.712 684.452 479.046 682.585C478.784 681.878 478.582 681.149 478.444 680.407C478.32 679.658 478.531 678.55 478.129 677.965L478.288 678.285Z'
                opacity='.7' />
          <path id='Vector_1270' fill='white'
                d='M479.131 684.501C478.806 683.452 478.455 683.574 478.153 684.022C476.634 683.264 476.853 688.43 478.623 687.124C479.223 686.682 479.332 685.15 479.131 684.501Z' />
          <path id='Vector_1271' fill='white'
                d='M452.088 671.931C451.891 673.515 451.229 675.005 450.185 676.213C449.141 677.42 447.763 678.291 446.224 678.716C444.685 679.14 443.055 679.098 441.54 678.596C440.025 678.094 438.693 677.154 437.712 675.895C436.731 674.636 436.146 673.114 436.03 671.522C435.914 669.93 436.273 668.339 437.061 666.951C437.849 665.563 439.031 664.44 440.457 663.724C441.884 663.007 443.491 662.73 445.075 662.927C447.199 663.191 449.131 664.288 450.446 665.977C451.761 667.665 452.352 669.807 452.088 671.931Z'
                opacity='.1' />
          <path id='Vector_1272' fill='white'
                d='M451.062 671.676C450.916 672.851 450.424 673.956 449.65 674.852C448.875 675.748 447.853 676.394 446.711 676.708C445.57 677.023 444.36 676.992 443.236 676.619C442.112 676.247 441.124 675.549 440.396 674.615C439.669 673.681 439.235 672.552 439.149 671.371C439.063 670.19 439.329 669.01 439.913 667.98C440.498 666.95 441.375 666.117 442.433 665.585C443.491 665.054 444.683 664.848 445.858 664.994C446.638 665.091 447.392 665.34 448.076 665.729C448.76 666.117 449.361 666.636 449.844 667.257C450.328 667.877 450.684 668.587 450.893 669.345C451.102 670.103 451.159 670.895 451.062 671.676Z'
                opacity='.2' />
          <path id='Vector_1273' fill='white'
                d='M445.572 666.733C445.083 668.486 445.056 670.76 441.736 670.634C442.653 671.191 443.69 671.706 444.255 672.67C444.78 673.665 445.055 674.765 445.058 675.882C445.376 674.866 445.252 673.868 446.05 672.889C446.34 672.525 446.712 672.231 447.138 672.027C447.563 671.823 448.03 671.716 448.505 671.713C447.527 671.023 446.602 671.001 446.12 669.709C445.931 669.219 445.785 668.715 445.685 668.201C445.594 667.683 445.748 666.915 445.458 666.51L445.572 666.733Z'
                opacity='.7' />
          <path id='Vector_1274' fill='white'
                d='M476.054 641.704C475.908 642.828 475.417 643.885 474.643 644.743C473.868 645.6 472.846 646.218 471.704 646.519C470.562 646.82 469.353 646.791 468.229 646.434C467.105 646.078 466.116 645.411 465.388 644.517C464.661 643.624 464.226 642.544 464.14 641.414C464.054 640.284 464.32 639.155 464.905 638.17C465.49 637.185 466.367 636.388 467.425 635.88C468.483 635.371 469.675 635.175 470.851 635.314C472.426 635.502 473.86 636.28 474.836 637.478C475.812 638.677 476.25 640.196 476.054 641.704Z'
                opacity='.1' />
          <path id='Vector_1275' fill='white'
                d='M475.025 641.511C474.917 642.329 474.553 643.098 473.981 643.721C473.409 644.345 472.653 644.794 471.809 645.013C470.965 645.232 470.071 645.211 469.24 644.952C468.41 644.692 467.679 644.207 467.141 643.557C466.603 642.907 466.282 642.122 466.219 641.3C466.155 640.478 466.352 639.657 466.784 638.941C467.217 638.224 467.865 637.645 468.647 637.275C469.429 636.906 470.311 636.763 471.179 636.864C471.756 636.932 472.313 637.105 472.818 637.375C473.324 637.646 473.767 638.007 474.125 638.438C474.482 638.87 474.745 639.363 474.899 639.891C475.054 640.418 475.096 640.969 475.025 641.511Z'
                opacity='.2' />
          <path id='Vector_1276' fill='white'
                d='M470.946 638.034C470.608 639.3 470.589 640.942 468.29 640.852C468.925 641.254 469.643 641.626 470.034 642.323C470.397 643.041 470.588 643.836 470.59 644.642C470.81 643.909 470.724 643.188 471.277 642.48C471.478 642.218 471.735 642.005 472.03 641.858C472.324 641.711 472.647 641.634 472.976 641.632C472.299 641.133 471.658 641.117 471.325 640.184C471.194 639.83 471.093 639.466 471.024 639.095C470.961 638.72 471.067 638.166 470.866 637.874L470.946 638.034Z'
                opacity='.7' />
          <path id='Vector_1277' fill='white'
                d='M445.832 670.848C445.588 669.949 445.325 670.054 445.098 670.439C443.959 669.789 444.123 674.216 445.451 673.097C445.901 672.717 445.983 671.404 445.832 670.848Z' />
        </g>;

      case 8 :
        return <g id='Mini gem_16'>
          <path id='Vector_1278' fill={`#${diamondColors[color][0]}`}
                d='M618.027 488.956L646.009 515.06L625.196 534.55L618.026 489.888L591.339 520.176L620.616 562.89L625.196 534.55L618.027 488.956Z' />
          <path id='Vector_1279' fill={`#${diamondColors[color][1]}`}
                d='M617.98 488.956L591.339 520.027L625.182 534.775L617.98 488.956Z' />
          <path id='Vector_1280' fill='white'
                d='M617.981 488.956L617.237 489.823L624.033 533.056L592.099 519.14L591.339 520.027L625.182 534.775L617.981 488.956Z' />
          <path id='Vector_1281' fill={`#${diamondColors[color][2]}`}
                d='M625.098 534.508L620.496 562.89L646.009 514.99L625.098 534.508Z' />
          <path id='Vector_1282' fill={`#${diamondColors[color][2]}`}
                d='M625.098 534.508L620.496 562.89L646.009 514.99L625.098 534.508Z' />
          <path id='Vector_1283' fill='white' d='M591.339 520.196L621.538 511.065L618.07 488.956L591.339 520.196Z'
                opacity='.2' />
          <path id='Vector_1284' fill='white'
                d='M621.372 510.991L646.009 514.99L617.893 488.956L621.372 510.588V510.991Z' opacity='.2' />
          <path id='Vector_1285' fill={`#${diamondColors[color][3]}`}
                d='M623.186 546.328C623.236 546.02 638.104 522.622 638.104 522.622L646.009 515.51L628.054 548.735L620.496 562.89L623.186 546.328Z'
                opacity='.5' />
          <path id='Vector_1286' fill={`#${diamondColors[color][3]}`}
                d='M625.999 534.509L645.119 516.661L646.009 514.99L625.098 534.509L620.496 562.89L621.791 560.46L625.999 534.509Z' />
          <path id='Vector_1287' fill={`#${diamondColors[color][3]}`}
                d='M601.187 534.575L624.662 534.775L591.339 520.196L601.187 534.575Z'
                opacity='.5' />
          <path id='Vector_1288' fill='white'
                d='M592.901 519.655C593.928 520.707 596.454 520.856 597.884 521.13C600.863 521.774 603.935 521.883 606.953 521.453C610.61 520.864 613.482 519.256 616.321 516.993C618.175 515.516 618.908 514.064 619.684 511.964C619.914 511.428 620.095 510.872 620.225 510.304C621.16 510.799 621.036 513.967 621.304 515.022C621.831 517.096 622.405 519.112 622.622 521.233C623.033 524.743 623.626 528.23 624.399 531.68C624.545 532.386 625.004 533.299 624.199 533.655C623.562 533.938 622.429 533.382 621.879 533.181C620.248 532.584 618.987 531.557 617.417 530.816C615.891 530.095 614.278 529.711 612.739 528.99C611.109 528.227 609.516 527.536 607.95 526.652C605.551 525.297 602.888 524.238 600.378 523.18C599.117 522.647 597.793 522.145 596.594 521.597C595.644 521.163 593.53 520.662 593 519.752L592.901 519.655Z'
                opacity='.4' />
          <path id='Vector_1289' fill='white'
                d='M629.731 542.973C635.028 539.505 636.511 532.399 633.043 527.102C629.575 521.805 622.469 520.322 617.172 523.79C611.875 527.258 610.392 534.364 613.86 539.661C617.328 544.958 624.434 546.441 629.731 542.973Z'
                opacity='.1' />
          <path id='Vector_1290' fill='white'
                d='M632.927 534.012C632.717 535.698 632.012 537.284 630.901 538.57C629.79 539.855 628.323 540.783 626.685 541.234C625.047 541.686 623.312 541.642 621.699 541.107C620.086 540.573 618.668 539.572 617.624 538.231C616.58 536.891 615.956 535.271 615.833 533.576C615.71 531.881 616.092 530.188 616.931 528.711C617.77 527.233 619.028 526.038 620.546 525.275C622.065 524.513 623.775 524.217 625.461 524.427C627.722 524.708 629.779 525.876 631.179 527.673C632.579 529.471 633.208 531.751 632.927 534.012Z'
                opacity='.2' />
          <path id='Vector_1291' fill='white'
                d='M625.288 527.285C624.611 529.816 624.572 533.101 619.976 532.922C621.245 533.726 622.682 534.469 623.465 535.863C624.191 537.299 624.571 538.889 624.575 540.502C625.016 539.035 624.844 537.592 625.949 536.178C626.351 535.653 626.866 535.227 627.455 534.933C628.044 534.639 628.691 534.484 629.348 534.48C627.994 533.483 626.712 533.452 626.046 531.585C625.784 530.878 625.582 530.149 625.444 529.407C625.32 528.658 625.531 527.55 625.129 526.965L625.288 527.285Z'
                opacity='.7' />
          <path id='Vector_1292' fill='white'
                d='M626.131 533.501C625.806 532.452 625.455 532.574 625.153 533.022C623.634 532.264 623.853 537.43 625.623 536.124C626.223 535.682 626.332 534.15 626.131 533.501Z' />
          <path id='Vector_1293' fill='white'
                d='M599.088 520.931C598.891 522.515 598.229 524.005 597.185 525.213C596.141 526.42 594.763 527.291 593.224 527.716C591.685 528.14 590.055 528.098 588.54 527.596C587.025 527.094 585.693 526.154 584.712 524.895C583.731 523.636 583.146 522.114 583.03 520.522C582.914 518.93 583.273 517.339 584.061 515.951C584.849 514.563 586.031 513.44 587.457 512.724C588.884 512.007 590.491 511.73 592.075 511.927C594.199 512.191 596.131 513.288 597.446 514.977C598.761 516.665 599.352 518.807 599.088 520.931Z'
                opacity='.1' />
          <path id='Vector_1294' fill='white'
                d='M598.062 520.676C597.916 521.851 597.424 522.956 596.65 523.852C595.875 524.748 594.853 525.394 593.711 525.708C592.57 526.023 591.36 525.992 590.236 525.619C589.112 525.247 588.124 524.549 587.396 523.615C586.669 522.681 586.235 521.552 586.149 520.371C586.063 519.19 586.329 518.01 586.913 516.98C587.498 515.95 588.375 515.117 589.433 514.585C590.491 514.054 591.683 513.848 592.858 513.994C593.638 514.091 594.392 514.34 595.076 514.729C595.76 515.117 596.361 515.636 596.844 516.257C597.328 516.877 597.684 517.587 597.893 518.345C598.102 519.103 598.159 519.895 598.062 520.676Z'
                opacity='.2' />
          <path id='Vector_1295' fill='white'
                d='M592.572 515.733C592.083 517.486 592.056 519.76 588.736 519.634C589.653 520.191 590.69 520.706 591.255 521.67C591.78 522.665 592.055 523.765 592.058 524.882C592.376 523.866 592.252 522.868 593.05 521.889C593.34 521.525 593.712 521.231 594.138 521.027C594.563 520.823 595.03 520.716 595.505 520.713C594.527 520.023 593.602 520.001 593.12 518.709C592.931 518.219 592.785 517.715 592.685 517.201C592.594 516.683 592.748 515.915 592.458 515.51L592.572 515.733Z'
                opacity='.7' />
          <path id='Vector_1296' fill='white'
                d='M623.054 490.704C622.908 491.828 622.417 492.885 621.643 493.743C620.868 494.6 619.846 495.218 618.704 495.519C617.562 495.82 616.353 495.791 615.229 495.434C614.105 495.078 613.116 494.411 612.388 493.517C611.661 492.624 611.226 491.544 611.14 490.414C611.054 489.284 611.32 488.155 611.905 487.17C612.49 486.185 613.367 485.388 614.425 484.88C615.483 484.371 616.675 484.175 617.851 484.314C619.426 484.502 620.86 485.28 621.836 486.478C622.812 487.677 623.25 489.196 623.054 490.704Z'
                opacity='.1' />
          <path id='Vector_1297' fill='white'
                d='M622.025 490.511C621.917 491.329 621.553 492.098 620.981 492.721C620.409 493.345 619.653 493.794 618.809 494.013C617.965 494.232 617.071 494.211 616.24 493.952C615.41 493.692 614.679 493.207 614.141 492.557C613.603 491.907 613.282 491.122 613.219 490.3C613.155 489.478 613.352 488.657 613.784 487.941C614.217 487.224 614.865 486.645 615.647 486.275C616.429 485.906 617.311 485.763 618.179 485.864C618.756 485.932 619.313 486.105 619.818 486.375C620.324 486.646 620.767 487.007 621.125 487.438C621.482 487.87 621.745 488.363 621.899 488.891C622.054 489.418 622.096 489.969 622.025 490.511Z'
                opacity='.2' />
          <path id='Vector_1298' fill='white'
                d='M617.947 487.034C617.608 488.3 617.589 489.942 615.29 489.852C615.925 490.254 616.643 490.626 617.034 491.323C617.397 492.041 617.588 492.836 617.59 493.642C617.81 492.909 617.724 492.188 618.277 491.48C618.478 491.218 618.735 491.005 619.03 490.858C619.324 490.711 619.647 490.634 619.976 490.632C619.299 490.133 618.658 490.117 618.325 489.184C618.194 488.83 618.093 488.466 618.024 488.095C617.961 487.72 618.067 487.166 617.866 486.874L617.947 487.034Z'
                opacity='.7' />
          <path id='Vector_1299' fill='white'
                d='M592.832 519.848C592.588 518.949 592.325 519.054 592.098 519.439C590.959 518.789 591.123 523.216 592.451 522.097C592.901 521.717 592.983 520.404 592.832 519.848Z' />
        </g>;

      case 9:
        return <g id='Mini gem_7'>
          <path id='Vector_385' fill={`#${diamondColors[color][0]}`}
                d='M1004.03 588.956L1032.01 615.06L1011.2 634.55L1004.03 589.888L977.339 620.176L1006.62 662.89L1011.2 634.55L1004.03 588.956Z' />
          <path id='Vector_386' fill={`#${diamondColors[color][1]}`}
                d='M1003.98 588.956L977.339 620.027L1011.18 634.775L1003.98 588.956Z' />
          <path id='Vector_387' fill='white'
                d='M1003.98 588.956L1003.24 589.823L1010.03 633.056L978.099 619.14L977.339 620.027L1011.18 634.775L1003.98 588.956Z' />
          <path id='Vector_388' fill={`#${diamondColors[color][2]}`}
                d='M1011.1 634.508L1006.5 662.89L1032.01 614.99L1011.1 634.508Z' />
          <path id='Vector_389' fill={`#${diamondColors[color][2]}`}
                d='M1011.1 634.508L1006.5 662.89L1032.01 614.99L1011.1 634.508Z' />
          <path id='Vector_390' fill='white' d='M977.339 620.196L1007.54 611.065L1004.07 588.956L977.339 620.196Z'
                opacity='.2' />
          <path id='Vector_391' fill='white'
                d='M1007.37 610.991L1032.01 614.99L1003.89 588.956L1007.37 610.588V610.991Z' opacity='.2' />
          <path id='Vector_392' fill={`#${diamondColors[color][3]}`}
                d='M1009.19 646.328C1009.24 646.02 1024.1 622.622 1024.1 622.622L1032.01 615.51L1014.05 648.735L1006.5 662.89L1009.19 646.328Z'
                opacity='.5' />
          <path id='Vector_393' fill={`#${diamondColors[color][3]}`}
                d='M1012 634.509L1031.12 616.661L1032.01 614.99L1011.1 634.509L1006.5 662.89L1007.79 660.46L1012 634.509Z' />
          <path id='Vector_394' fill={`#${diamondColors[color][3]}`}
                d='M987.187 634.575L1010.66 634.775L977.339 620.196L987.187 634.575Z'
                opacity='.5' />
          <path id='Vector_395' fill='white'
                d='M978.901 619.655C979.928 620.707 982.454 620.856 983.884 621.13C986.863 621.774 989.935 621.883 992.953 621.453C996.61 620.864 999.482 619.256 1002.32 616.993C1004.17 615.516 1004.91 614.064 1005.68 611.964C1005.91 611.428 1006.1 610.872 1006.23 610.304C1007.16 610.799 1007.04 613.967 1007.3 615.022C1007.83 617.096 1008.41 619.112 1008.62 621.233C1009.03 624.743 1009.63 628.23 1010.4 631.68C1010.54 632.386 1011 633.299 1010.2 633.655C1009.56 633.938 1008.43 633.382 1007.88 633.181C1006.25 632.584 1004.99 631.557 1003.42 630.816C1001.89 630.095 1000.28 629.711 998.738 628.99C997.109 628.227 995.516 627.536 993.95 626.652C991.551 625.297 988.888 624.238 986.378 623.18C985.117 622.647 983.793 622.145 982.594 621.597C981.644 621.163 979.53 620.662 979 619.752L978.901 619.655Z'
                opacity='.4' />
          <path id='Vector_396' fill='white'
                d='M1015.73 642.973C1021.03 639.505 1022.51 632.399 1019.04 627.102C1015.57 621.805 1008.47 620.322 1003.17 623.79C997.875 627.258 996.392 634.364 999.86 639.661C1003.33 644.958 1010.43 646.441 1015.73 642.973Z'
                opacity='.1' />
          <path id='Vector_397' fill='white'
                d='M1018.93 634.012C1018.72 635.698 1018.01 637.284 1016.9 638.57C1015.79 639.855 1014.32 640.783 1012.68 641.234C1011.05 641.686 1009.31 641.642 1007.7 641.107C1006.09 640.573 1004.67 639.572 1003.62 638.231C1002.58 636.891 1001.96 635.271 1001.83 633.576C1001.71 631.881 1002.09 630.188 1002.93 628.711C1003.77 627.233 1005.03 626.038 1006.55 625.275C1008.06 624.513 1009.78 624.217 1011.46 624.427C1013.72 624.708 1015.78 625.876 1017.18 627.673C1018.58 629.471 1019.21 631.751 1018.93 634.012Z'
                opacity='.2' />
          <path id='Vector_398' fill='white'
                d='M1011.29 627.285C1010.61 629.816 1010.57 633.101 1005.98 632.922C1007.25 633.726 1008.68 634.469 1009.46 635.863C1010.19 637.299 1010.57 638.889 1010.58 640.502C1011.02 639.035 1010.84 637.592 1011.95 636.178C1012.35 635.653 1012.87 635.227 1013.45 634.933C1014.04 634.639 1014.69 634.484 1015.35 634.48C1013.99 633.483 1012.71 633.452 1012.05 631.585C1011.78 630.878 1011.58 630.149 1011.44 629.407C1011.32 628.658 1011.53 627.55 1011.13 626.965L1011.29 627.285Z'
                opacity='.7' />
          <path id='Vector_399' fill='white'
                d='M1012.13 633.501C1011.81 632.452 1011.46 632.574 1011.15 633.022C1009.63 632.264 1009.85 637.43 1011.62 636.124C1012.22 635.682 1012.33 634.15 1012.13 633.501Z' />
          <path id='Vector_400' fill='white'
                d='M985.088 620.931C984.891 622.515 984.229 624.005 983.185 625.213C982.141 626.42 980.763 627.291 979.224 627.716C977.685 628.14 976.055 628.098 974.54 627.596C973.025 627.094 971.693 626.154 970.712 624.895C969.731 623.636 969.146 622.114 969.03 620.522C968.914 618.93 969.273 617.339 970.061 615.951C970.849 614.563 972.031 613.44 973.457 612.724C974.884 612.007 976.491 611.73 978.075 611.927C980.199 612.191 982.131 613.288 983.446 614.977C984.761 616.665 985.352 618.807 985.088 620.931Z'
                opacity='.1' />
          <path id='Vector_401' fill='white'
                d='M984.062 620.676C983.916 621.851 983.424 622.956 982.65 623.852C981.875 624.748 980.853 625.394 979.711 625.708C978.57 626.023 977.36 625.992 976.236 625.619C975.112 625.247 974.124 624.549 973.396 623.615C972.669 622.681 972.235 621.552 972.149 620.371C972.063 619.19 972.329 618.01 972.913 616.98C973.498 615.95 974.375 615.117 975.433 614.585C976.491 614.054 977.683 613.848 978.858 613.994C979.638 614.091 980.392 614.34 981.076 614.729C981.76 615.117 982.361 615.636 982.844 616.257C983.328 616.877 983.684 617.587 983.893 618.345C984.102 619.103 984.159 619.895 984.062 620.676Z'
                opacity='.2' />
          <path id='Vector_402' fill='white'
                d='M978.572 615.733C978.083 617.486 978.056 619.76 974.736 619.634C975.653 620.191 976.69 620.706 977.255 621.67C977.78 622.665 978.055 623.765 978.058 624.882C978.376 623.866 978.252 622.868 979.05 621.889C979.34 621.525 979.712 621.231 980.138 621.027C980.563 620.823 981.03 620.716 981.505 620.713C980.527 620.023 979.602 620.001 979.12 618.709C978.931 618.219 978.785 617.715 978.685 617.201C978.594 616.683 978.748 615.915 978.458 615.51L978.572 615.733Z'
                opacity='.7' />
          <path id='Vector_403' fill='white'
                d='M1009.05 590.704C1008.91 591.828 1008.42 592.885 1007.64 593.743C1006.87 594.6 1005.85 595.218 1004.7 595.519C1003.56 595.82 1002.35 595.791 1001.23 595.434C1000.1 595.078 999.116 594.411 998.388 593.517C997.661 592.624 997.226 591.544 997.14 590.414C997.054 589.284 997.32 588.155 997.905 587.17C998.49 586.185 999.367 585.388 1000.43 584.88C1001.48 584.371 1002.68 584.175 1003.85 584.314C1005.43 584.502 1006.86 585.28 1007.84 586.478C1008.81 587.677 1009.25 589.196 1009.05 590.704Z'
                opacity='.1' />
          <path id='Vector_404' fill='white'
                d='M1008.02 590.511C1007.92 591.329 1007.55 592.098 1006.98 592.721C1006.41 593.345 1005.65 593.794 1004.81 594.013C1003.97 594.232 1003.07 594.211 1002.24 593.952C1001.41 593.692 1000.68 593.207 1000.14 592.557C999.603 591.907 999.282 591.122 999.219 590.3C999.155 589.478 999.352 588.657 999.784 587.941C1000.22 587.224 1000.86 586.645 1001.65 586.275C1002.43 585.906 1003.31 585.763 1004.18 585.864C1004.76 585.932 1005.31 586.105 1005.82 586.375C1006.32 586.646 1006.77 587.007 1007.12 587.438C1007.48 587.87 1007.74 588.363 1007.9 588.891C1008.05 589.418 1008.1 589.969 1008.02 590.511Z'
                opacity='.2' />
          <path id='Vector_405' fill='white'
                d='M1003.95 587.034C1003.61 588.3 1003.59 589.942 1001.29 589.852C1001.92 590.254 1002.64 590.626 1003.03 591.323C1003.4 592.041 1003.59 592.836 1003.59 593.642C1003.81 592.909 1003.72 592.188 1004.28 591.48C1004.48 591.218 1004.74 591.005 1005.03 590.858C1005.32 590.711 1005.65 590.634 1005.98 590.632C1005.3 590.133 1004.66 590.117 1004.32 589.184C1004.19 588.83 1004.09 588.466 1004.02 588.095C1003.96 587.72 1004.07 587.166 1003.87 586.874L1003.95 587.034Z'
                opacity='.7' />
          <path id='Vector_406' fill='white'
                d='M978.832 619.848C978.588 618.949 978.325 619.054 978.098 619.439C976.959 618.789 977.123 623.216 978.451 622.097C978.901 621.717 978.983 620.404 978.832 619.848Z' />
        </g>;

      case 10:
        return <g id='Mini gem_8'>
          <path id='Vector_407' fill={`#${diamondColors[color][0]}`}
                d='M904.027 476.956L932.009 503.06L911.196 522.55L904.026 477.888L877.339 508.176L906.616 550.89L911.196 522.55L904.027 476.956Z' />
          <path id='Vector_408' fill={`#${diamondColors[color][1]}`}
                d='M903.98 476.956L877.339 508.027L911.182 522.775L903.98 476.956Z' />
          <path id='Vector_409' fill='white'
                d='M903.981 476.956L903.237 477.823L910.033 521.056L878.099 507.14L877.339 508.027L911.182 522.775L903.981 476.956Z' />
          <path id='Vector_410' fill={`#${diamondColors[color][2]}`}
                d='M911.098 522.508L906.496 550.89L932.009 502.99L911.098 522.508Z' />
          <path id='Vector_411' fill={`#${diamondColors[color][2]}`}
                d='M911.098 522.508L906.496 550.89L932.009 502.99L911.098 522.508Z' />
          <path id='Vector_412' fill='white' d='M877.339 508.196L907.538 499.065L904.07 476.956L877.339 508.196Z'
                opacity='.2' />
          <path id='Vector_413' fill='white'
                d='M907.372 498.991L932.009 502.99L903.893 476.956L907.372 498.588V498.991Z' opacity='.2' />
          <path id='Vector_414' fill={`#${diamondColors[color][3]}`}
                d='M909.186 534.328C909.236 534.02 924.104 510.622 924.104 510.622L932.009 503.51L914.054 536.735L906.496 550.89L909.186 534.328Z'
                opacity='.5' />
          <path id='Vector_415' fill={`#${diamondColors[color][3]}`}
                d='M911.999 522.509L931.119 504.661L932.009 502.99L911.098 522.509L906.496 550.89L907.791 548.46L911.999 522.509Z' />
          <path id='Vector_416' fill={`#${diamondColors[color][3]}`}
                d='M887.187 522.575L910.662 522.775L877.339 508.196L887.187 522.575Z'
                opacity='.5' />
          <path id='Vector_417' fill='white'
                d='M878.901 507.655C879.928 508.707 882.454 508.856 883.884 509.13C886.863 509.774 889.935 509.883 892.953 509.453C896.61 508.864 899.482 507.256 902.321 504.993C904.175 503.516 904.908 502.064 905.684 499.964C905.914 499.428 906.095 498.872 906.225 498.304C907.16 498.799 907.036 501.967 907.304 503.022C907.831 505.096 908.405 507.112 908.622 509.233C909.033 512.743 909.626 516.23 910.399 519.68C910.545 520.386 911.004 521.299 910.199 521.655C909.562 521.938 908.429 521.382 907.879 521.181C906.248 520.584 904.987 519.557 903.417 518.816C901.891 518.095 900.278 517.711 898.738 516.99C897.109 516.227 895.516 515.536 893.95 514.652C891.551 513.297 888.888 512.238 886.378 511.18C885.117 510.647 883.793 510.145 882.594 509.597C881.644 509.163 879.53 508.662 879 507.752L878.901 507.655Z'
                opacity='.4' />
          <path id='Vector_418' fill='white'
                d='M915.731 530.973C921.028 527.505 922.511 520.399 919.043 515.102C915.575 509.805 908.469 508.322 903.172 511.79C897.875 515.258 896.392 522.364 899.86 527.661C903.328 532.958 910.434 534.441 915.731 530.973Z'
                opacity='.1' />
          <path id='Vector_419' fill='white'
                d='M918.927 522.012C918.717 523.698 918.012 525.284 916.901 526.57C915.79 527.855 914.323 528.783 912.685 529.234C911.047 529.686 909.312 529.642 907.699 529.107C906.086 528.573 904.668 527.572 903.624 526.231C902.58 524.891 901.957 523.271 901.833 521.576C901.71 519.881 902.092 518.188 902.931 516.711C903.77 515.233 905.028 514.038 906.546 513.275C908.065 512.513 909.775 512.217 911.461 512.427C913.722 512.708 915.779 513.876 917.179 515.673C918.579 517.471 919.208 519.751 918.927 522.012Z'
                opacity='.2' />
          <path id='Vector_420' fill='white'
                d='M911.288 515.285C910.611 517.816 910.572 521.101 905.976 520.922C907.245 521.726 908.682 522.469 909.465 523.863C910.191 525.299 910.571 526.889 910.575 528.502C911.016 527.035 910.844 525.592 911.949 524.178C912.351 523.653 912.866 523.227 913.455 522.933C914.044 522.639 914.691 522.484 915.348 522.48C913.994 521.483 912.712 521.452 912.046 519.585C911.784 518.878 911.582 518.149 911.444 517.407C911.32 516.658 911.531 515.55 911.129 514.965L911.288 515.285Z'
                opacity='.7' />
          <path id='Vector_421' fill='white'
                d='M912.131 521.501C911.806 520.452 911.455 520.574 911.153 521.022C909.634 520.264 909.853 525.43 911.623 524.124C912.223 523.682 912.332 522.15 912.131 521.501Z' />
          <path id='Vector_422' fill='white'
                d='M885.088 508.931C884.891 510.515 884.229 512.005 883.185 513.213C882.141 514.42 880.763 515.291 879.224 515.716C877.685 516.14 876.055 516.098 874.54 515.596C873.025 515.094 871.693 514.154 870.712 512.895C869.731 511.636 869.146 510.114 869.03 508.522C868.914 506.93 869.273 505.339 870.061 503.951C870.849 502.563 872.031 501.44 873.457 500.724C874.884 500.007 876.491 499.73 878.075 499.927C880.199 500.191 882.131 501.288 883.446 502.977C884.761 504.665 885.352 506.807 885.088 508.931Z'
                opacity='.1' />
          <path id='Vector_423' fill='white'
                d='M884.062 508.676C883.916 509.851 883.424 510.956 882.65 511.852C881.875 512.748 880.853 513.394 879.711 513.708C878.57 514.023 877.36 513.992 876.236 513.619C875.112 513.247 874.124 512.549 873.396 511.615C872.669 510.681 872.235 509.552 872.149 508.371C872.063 507.19 872.329 506.01 872.913 504.98C873.498 503.95 874.375 503.117 875.433 502.585C876.491 502.054 877.683 501.848 878.858 501.994C879.638 502.091 880.392 502.34 881.076 502.729C881.76 503.117 882.361 503.636 882.844 504.257C883.328 504.877 883.684 505.587 883.893 506.345C884.102 507.103 884.159 507.895 884.062 508.676Z'
                opacity='.2' />
          <path id='Vector_424' fill='white'
                d='M878.572 503.733C878.083 505.486 878.056 507.76 874.736 507.634C875.653 508.191 876.69 508.706 877.255 509.67C877.78 510.665 878.055 511.765 878.058 512.882C878.376 511.866 878.252 510.868 879.05 509.889C879.34 509.525 879.712 509.231 880.138 509.027C880.563 508.823 881.03 508.716 881.505 508.713C880.527 508.023 879.602 508.001 879.12 506.709C878.931 506.219 878.785 505.715 878.685 505.201C878.594 504.683 878.748 503.915 878.458 503.51L878.572 503.733Z'
                opacity='.7' />
          <path id='Vector_425' fill='white'
                d='M909.054 478.704C908.908 479.828 908.417 480.885 907.642 481.743C906.868 482.6 905.846 483.218 904.704 483.519C903.562 483.82 902.353 483.791 901.229 483.434C900.105 483.078 899.116 482.411 898.388 481.517C897.661 480.624 897.226 479.544 897.14 478.414C897.054 477.284 897.32 476.155 897.905 475.17C898.49 474.185 899.367 473.388 900.425 472.88C901.483 472.371 902.675 472.175 903.851 472.314C905.426 472.502 906.86 473.28 907.836 474.478C908.812 475.677 909.25 477.196 909.054 478.704Z'
                opacity='.1' />
          <path id='Vector_426' fill='white'
                d='M908.024 478.511C907.917 479.329 907.553 480.098 906.981 480.721C906.409 481.345 905.653 481.794 904.809 482.013C903.965 482.232 903.071 482.211 902.24 481.952C901.409 481.692 900.679 481.207 900.141 480.557C899.603 479.907 899.282 479.122 899.219 478.3C899.155 477.478 899.352 476.657 899.784 475.941C900.217 475.224 900.865 474.645 901.647 474.275C902.429 473.906 903.311 473.763 904.179 473.864C904.756 473.932 905.313 474.105 905.818 474.375C906.324 474.646 906.767 475.007 907.125 475.438C907.482 475.87 907.745 476.363 907.899 476.891C908.054 477.418 908.096 477.969 908.024 478.511Z'
                opacity='.2' />
          <path id='Vector_427' fill='white'
                d='M903.946 475.034C903.608 476.3 903.589 477.942 901.29 477.852C901.925 478.254 902.643 478.626 903.034 479.323C903.397 480.041 903.588 480.836 903.59 481.642C903.81 480.909 903.724 480.188 904.277 479.48C904.478 479.218 904.735 479.005 905.03 478.858C905.324 478.711 905.647 478.634 905.976 478.632C905.299 478.133 904.658 478.117 904.325 477.184C904.194 476.83 904.093 476.466 904.024 476.095C903.961 475.72 904.067 475.166 903.866 474.874L903.946 475.034Z'
                opacity='.7' />
          <path id='Vector_428' fill='white'
                d='M878.832 507.848C878.588 506.949 878.325 507.054 878.098 507.439C876.959 506.789 877.123 511.216 878.451 510.097C878.901 509.717 878.983 508.404 878.832 507.848Z' />
        </g>;

      case 11:
        return <g id='Mini gem_9'>
          <path id='Vector_429' fill={`#${diamondColors[color][0]}`}
                d='M819.027 533.956L847.009 560.06L826.196 579.55L819.026 534.888L792.339 565.176L821.616 607.89L826.196 579.55L819.027 533.956Z' />
          <path id='Vector_430' fill={`#${diamondColors[color][1]}`}
                d='M818.98 533.956L792.339 565.027L826.182 579.775L818.98 533.956Z' />
          <path id='Vector_431' fill='white'
                d='M818.981 533.956L818.237 534.823L825.033 578.056L793.099 564.14L792.339 565.027L826.182 579.775L818.981 533.956Z' />
          <path id='Vector_432' fill={`#${diamondColors[color][2]}`}
                d='M826.098 579.508L821.496 607.89L847.009 559.99L826.098 579.508Z' />
          <path id='Vector_433' fill={`#${diamondColors[color][2]}`}
                d='M826.098 579.508L821.496 607.89L847.009 559.99L826.098 579.508Z' />
          <path id='Vector_434' fill='white' d='M792.339 565.196L822.538 556.065L819.07 533.956L792.339 565.196Z'
                opacity='.2' />
          <path id='Vector_435' fill='white'
                d='M822.372 555.991L847.009 559.99L818.893 533.956L822.372 555.588V555.991Z' opacity='.2' />
          <path id='Vector_436' fill={`#${diamondColors[color][3]}`}
                d='M824.186 591.328C824.236 591.02 839.104 567.622 839.104 567.622L847.009 560.51L829.054 593.735L821.496 607.89L824.186 591.328Z'
                opacity='.5' />
          <path id='Vector_437' fill={`#${diamondColors[color][3]}`}
                d='M826.999 579.509L846.119 561.661L847.009 559.99L826.098 579.509L821.496 607.89L822.791 605.46L826.999 579.509Z' />
          <path id='Vector_438' fill={`#${diamondColors[color][3]}`}
                d='M802.187 579.575L825.662 579.775L792.339 565.196L802.187 579.575Z'
                opacity='.5' />
          <path id='Vector_439' fill='white'
                d='M793.901 564.655C794.928 565.707 797.454 565.856 798.884 566.13C801.863 566.774 804.935 566.883 807.953 566.453C811.61 565.864 814.482 564.256 817.321 561.993C819.175 560.516 819.908 559.064 820.684 556.964C820.914 556.428 821.095 555.872 821.225 555.304C822.16 555.799 822.036 558.967 822.304 560.022C822.831 562.096 823.405 564.112 823.622 566.233C824.033 569.743 824.626 573.23 825.399 576.68C825.545 577.386 826.004 578.299 825.199 578.655C824.562 578.938 823.429 578.382 822.879 578.181C821.248 577.584 819.987 576.557 818.417 575.816C816.891 575.095 815.278 574.711 813.738 573.99C812.109 573.227 810.516 572.536 808.95 571.652C806.551 570.297 803.888 569.238 801.378 568.18C800.117 567.647 798.793 567.145 797.594 566.597C796.644 566.163 794.53 565.662 794 564.752L793.901 564.655Z'
                opacity='.4' />
          <path id='Vector_440' fill='white'
                d='M830.731 587.973C836.028 584.505 837.511 577.399 834.043 572.102C830.575 566.805 823.469 565.322 818.172 568.79C812.875 572.258 811.392 579.364 814.86 584.661C818.328 589.958 825.434 591.441 830.731 587.973Z'
                opacity='.1' />
          <path id='Vector_441' fill='white'
                d='M833.927 579.012C833.717 580.698 833.012 582.284 831.901 583.57C830.79 584.855 829.323 585.783 827.685 586.234C826.047 586.686 824.312 586.642 822.699 586.107C821.086 585.573 819.668 584.572 818.624 583.231C817.58 581.891 816.957 580.271 816.833 578.576C816.71 576.881 817.092 575.188 817.931 573.711C818.77 572.233 820.028 571.038 821.546 570.275C823.065 569.513 824.775 569.217 826.461 569.427C828.722 569.708 830.779 570.876 832.179 572.673C833.579 574.471 834.208 576.751 833.927 579.012Z'
                opacity='.2' />
          <path id='Vector_442' fill='white'
                d='M826.288 572.285C825.611 574.816 825.572 578.101 820.976 577.922C822.245 578.726 823.682 579.469 824.465 580.863C825.191 582.299 825.571 583.889 825.575 585.502C826.016 584.035 825.844 582.592 826.949 581.178C827.351 580.653 827.866 580.227 828.455 579.933C829.044 579.639 829.691 579.484 830.348 579.48C828.994 578.483 827.712 578.452 827.046 576.585C826.784 575.878 826.582 575.149 826.444 574.407C826.32 573.658 826.531 572.55 826.129 571.965L826.288 572.285Z'
                opacity='.7' />
          <path id='Vector_443' fill='white'
                d='M827.131 578.501C826.806 577.452 826.455 577.574 826.153 578.022C824.634 577.264 824.853 582.43 826.623 581.124C827.223 580.682 827.332 579.15 827.131 578.501Z' />
          <path id='Vector_444' fill='white'
                d='M800.088 565.931C799.891 567.515 799.229 569.005 798.185 570.213C797.141 571.42 795.763 572.291 794.224 572.716C792.685 573.14 791.055 573.098 789.54 572.596C788.025 572.094 786.693 571.154 785.712 569.895C784.731 568.636 784.146 567.114 784.03 565.522C783.914 563.93 784.273 562.339 785.061 560.951C785.849 559.563 787.031 558.44 788.457 557.724C789.884 557.007 791.491 556.73 793.075 556.927C795.199 557.191 797.131 558.288 798.446 559.977C799.761 561.665 800.352 563.807 800.088 565.931Z'
                opacity='.1' />
          <path id='Vector_445' fill='white'
                d='M799.062 565.676C798.916 566.851 798.424 567.956 797.65 568.852C796.875 569.748 795.853 570.394 794.711 570.708C793.57 571.023 792.36 570.992 791.236 570.619C790.112 570.247 789.124 569.549 788.396 568.615C787.669 567.681 787.235 566.552 787.149 565.371C787.063 564.19 787.329 563.01 787.913 561.98C788.498 560.95 789.375 560.117 790.433 559.585C791.491 559.054 792.683 558.848 793.858 558.994C794.638 559.091 795.392 559.34 796.076 559.729C796.76 560.117 797.361 560.636 797.844 561.257C798.328 561.877 798.684 562.587 798.893 563.345C799.102 564.103 799.159 564.895 799.062 565.676Z'
                opacity='.2' />
          <path id='Vector_446' fill='white'
                d='M793.572 560.733C793.083 562.486 793.056 564.76 789.736 564.634C790.653 565.191 791.69 565.706 792.255 566.67C792.78 567.665 793.055 568.765 793.058 569.882C793.376 568.866 793.252 567.868 794.05 566.889C794.34 566.525 794.712 566.231 795.138 566.027C795.563 565.823 796.03 565.716 796.505 565.713C795.527 565.023 794.602 565.001 794.12 563.709C793.931 563.219 793.785 562.715 793.685 562.201C793.594 561.683 793.748 560.915 793.458 560.51L793.572 560.733Z'
                opacity='.7' />
          <path id='Vector_447' fill='white'
                d='M824.054 535.704C823.908 536.828 823.417 537.885 822.642 538.743C821.868 539.6 820.846 540.218 819.704 540.519C818.562 540.82 817.353 540.791 816.229 540.434C815.105 540.078 814.116 539.411 813.388 538.517C812.661 537.624 812.226 536.544 812.14 535.414C812.054 534.284 812.32 533.155 812.905 532.17C813.49 531.185 814.367 530.388 815.425 529.88C816.483 529.371 817.675 529.175 818.851 529.314C820.426 529.502 821.86 530.28 822.836 531.478C823.812 532.677 824.25 534.196 824.054 535.704Z'
                opacity='.1' />
          <path id='Vector_448' fill='white'
                d='M823.024 535.511C822.917 536.329 822.553 537.098 821.981 537.721C821.409 538.345 820.653 538.794 819.809 539.013C818.965 539.232 818.071 539.211 817.24 538.952C816.409 538.692 815.679 538.207 815.141 537.557C814.603 536.907 814.282 536.122 814.219 535.3C814.155 534.478 814.352 533.657 814.784 532.941C815.217 532.224 815.865 531.645 816.647 531.275C817.429 530.906 818.311 530.763 819.179 530.864C819.756 530.932 820.313 531.105 820.818 531.375C821.324 531.646 821.767 532.007 822.125 532.438C822.482 532.87 822.745 533.363 822.899 533.891C823.054 534.418 823.096 534.969 823.024 535.511Z'
                opacity='.2' />
          <path id='Vector_449' fill='white'
                d='M818.946 532.034C818.608 533.3 818.589 534.942 816.29 534.852C816.925 535.254 817.643 535.626 818.034 536.323C818.397 537.041 818.588 537.836 818.59 538.642C818.81 537.909 818.724 537.188 819.277 536.48C819.478 536.218 819.735 536.005 820.03 535.858C820.324 535.711 820.647 535.634 820.976 535.632C820.299 535.133 819.658 535.117 819.325 534.184C819.194 533.83 819.093 533.466 819.024 533.095C818.961 532.72 819.067 532.166 818.866 531.874L818.946 532.034Z'
                opacity='.7' />
          <path id='Vector_450' fill='white'
                d='M793.832 564.848C793.588 563.949 793.325 564.054 793.098 564.439C791.959 563.789 792.123 568.216 793.451 567.097C793.901 566.717 793.983 565.404 793.832 564.848Z' />
        </g>;

      case 12:
        return <g id='Mini gem_10'>
          <path id='Vector_451' fill={`#${diamondColors[color][0]}`}
                d='M699.027 504.956L727.009 531.06L706.196 550.55L699.026 505.888L672.339 536.176L701.616 578.89L706.196 550.55L699.027 504.956Z' />
          <path id='Vector_452' fill={`#${diamondColors[color][1]}`}
                d='M698.98 504.956L672.339 536.027L706.182 550.775L698.98 504.956Z' />
          <path id='Vector_453' fill='white'
                d='M698.981 504.956L698.237 505.823L705.033 549.056L673.099 535.14L672.339 536.027L706.182 550.775L698.981 504.956Z' />
          <path id='Vector_454' fill={`#${diamondColors[color][2]}`}
                d='M706.098 550.508L701.496 578.89L727.009 530.99L706.098 550.508Z' />
          <path id='Vector_455' fill={`#${diamondColors[color][2]}`}
                d='M706.098 550.508L701.496 578.89L727.009 530.99L706.098 550.508Z' />
          <path id='Vector_456' fill='white' d='M672.339 536.196L702.538 527.065L699.07 504.956L672.339 536.196Z'
                opacity='.2' />
          <path id='Vector_457' fill='white'
                d='M702.372 526.991L727.009 530.99L698.893 504.956L702.372 526.588V526.991Z' opacity='.2' />
          <path id='Vector_458' fill={`#${diamondColors[color][3]}`}
                d='M704.186 562.328C704.236 562.02 719.104 538.622 719.104 538.622L727.009 531.51L709.054 564.735L701.496 578.89L704.186 562.328Z'
                opacity='.5' />
          <path id='Vector_459' fill={`#${diamondColors[color][3]}`}
                d='M706.999 550.509L726.119 532.661L727.009 530.99L706.098 550.509L701.496 578.89L702.791 576.46L706.999 550.509Z' />
          <path id='Vector_460' fill={`#${diamondColors[color][3]}`}
                d='M682.187 550.575L705.662 550.775L672.339 536.196L682.187 550.575Z'
                opacity='.5' />
          <path id='Vector_461' fill='white'
                d='M673.901 535.655C674.928 536.707 677.454 536.856 678.884 537.13C681.863 537.774 684.935 537.883 687.953 537.453C691.61 536.864 694.482 535.256 697.321 532.993C699.175 531.516 699.908 530.064 700.684 527.964C700.914 527.428 701.095 526.872 701.225 526.304C702.16 526.799 702.036 529.967 702.304 531.022C702.831 533.096 703.405 535.112 703.622 537.233C704.033 540.743 704.626 544.23 705.399 547.68C705.545 548.386 706.004 549.299 705.199 549.655C704.562 549.938 703.429 549.382 702.879 549.181C701.248 548.584 699.987 547.557 698.417 546.816C696.891 546.095 695.278 545.711 693.739 544.99C692.109 544.227 690.516 543.536 688.95 542.652C686.551 541.297 683.888 540.238 681.378 539.18C680.117 538.647 678.793 538.145 677.594 537.597C676.644 537.163 674.53 536.662 674 535.752L673.901 535.655Z'
                opacity='.4' />
          <path id='Vector_462' fill='white'
                d='M710.731 558.973C716.028 555.505 717.511 548.399 714.043 543.102C710.575 537.805 703.469 536.322 698.172 539.79C692.875 543.258 691.392 550.364 694.86 555.661C698.328 560.958 705.434 562.441 710.731 558.973Z'
                opacity='.1' />
          <path id='Vector_463' fill='white'
                d='M713.927 550.012C713.717 551.698 713.012 553.284 711.901 554.57C710.79 555.855 709.323 556.783 707.685 557.234C706.047 557.686 704.312 557.642 702.699 557.107C701.086 556.573 699.668 555.572 698.624 554.231C697.58 552.891 696.956 551.271 696.833 549.576C696.71 547.881 697.092 546.188 697.931 544.711C698.77 543.233 700.028 542.038 701.546 541.275C703.065 540.513 704.775 540.217 706.461 540.427C708.722 540.708 710.779 541.876 712.179 543.673C713.579 545.471 714.208 547.751 713.927 550.012Z'
                opacity='.2' />
          <path id='Vector_464' fill='white'
                d='M706.288 543.285C705.611 545.816 705.572 549.101 700.976 548.922C702.245 549.726 703.682 550.469 704.465 551.863C705.191 553.299 705.571 554.889 705.575 556.502C706.016 555.035 705.844 553.592 706.949 552.178C707.351 551.653 707.866 551.227 708.455 550.933C709.044 550.639 709.691 550.484 710.348 550.48C708.994 549.483 707.712 549.452 707.046 547.585C706.784 546.878 706.582 546.149 706.444 545.407C706.32 544.658 706.531 543.55 706.129 542.965L706.288 543.285Z'
                opacity='.7' />
          <path id='Vector_465' fill='white'
                d='M707.131 549.501C706.806 548.452 706.455 548.574 706.153 549.022C704.634 548.264 704.853 553.43 706.623 552.124C707.223 551.682 707.332 550.15 707.131 549.501Z' />
          <path id='Vector_466' fill='white'
                d='M680.088 536.931C679.891 538.515 679.229 540.005 678.185 541.213C677.141 542.42 675.763 543.291 674.224 543.716C672.685 544.14 671.055 544.098 669.54 543.596C668.025 543.094 666.693 542.154 665.712 540.895C664.731 539.636 664.146 538.114 664.03 536.522C663.914 534.93 664.273 533.339 665.061 531.951C665.849 530.563 667.031 529.44 668.457 528.724C669.884 528.007 671.491 527.73 673.075 527.927C675.199 528.191 677.131 529.288 678.446 530.977C679.761 532.665 680.352 534.807 680.088 536.931Z'
                opacity='.1' />
          <path id='Vector_467' fill='white'
                d='M679.062 536.676C678.916 537.851 678.424 538.956 677.65 539.852C676.875 540.748 675.853 541.394 674.711 541.708C673.57 542.023 672.36 541.992 671.236 541.619C670.112 541.247 669.124 540.549 668.396 539.615C667.669 538.681 667.235 537.552 667.149 536.371C667.063 535.19 667.329 534.01 667.913 532.98C668.498 531.95 669.375 531.117 670.433 530.585C671.491 530.054 672.683 529.848 673.858 529.994C674.638 530.091 675.392 530.34 676.076 530.729C676.76 531.117 677.361 531.636 677.844 532.257C678.328 532.877 678.684 533.587 678.893 534.345C679.102 535.103 679.159 535.895 679.062 536.676Z'
                opacity='.2' />
          <path id='Vector_468' fill='white'
                d='M673.572 531.733C673.083 533.486 673.056 535.76 669.736 535.634C670.653 536.191 671.69 536.706 672.255 537.67C672.78 538.665 673.055 539.765 673.058 540.882C673.376 539.866 673.252 538.868 674.05 537.889C674.34 537.525 674.712 537.231 675.138 537.027C675.563 536.823 676.03 536.716 676.505 536.713C675.527 536.023 674.602 536.001 674.12 534.709C673.931 534.219 673.785 533.715 673.685 533.201C673.594 532.683 673.748 531.915 673.458 531.51L673.572 531.733Z'
                opacity='.7' />
          <path id='Vector_469' fill='white'
                d='M704.054 506.704C703.908 507.828 703.417 508.885 702.642 509.743C701.868 510.6 700.846 511.218 699.704 511.519C698.562 511.82 697.353 511.791 696.229 511.434C695.105 511.078 694.116 510.411 693.388 509.517C692.661 508.624 692.226 507.544 692.14 506.414C692.054 505.284 692.32 504.155 692.905 503.17C693.49 502.185 694.367 501.388 695.425 500.88C696.483 500.371 697.675 500.175 698.851 500.314C700.426 500.502 701.86 501.28 702.836 502.478C703.812 503.677 704.25 505.196 704.054 506.704Z'
                opacity='.1' />
          <path id='Vector_470' fill='white'
                d='M703.025 506.511C702.917 507.329 702.553 508.098 701.981 508.721C701.409 509.345 700.653 509.794 699.809 510.013C698.965 510.232 698.071 510.211 697.24 509.952C696.41 509.692 695.679 509.207 695.141 508.557C694.603 507.907 694.282 507.122 694.219 506.3C694.155 505.478 694.352 504.657 694.784 503.941C695.217 503.224 695.865 502.645 696.647 502.275C697.429 501.906 698.311 501.763 699.179 501.864C699.756 501.932 700.313 502.105 700.818 502.375C701.324 502.646 701.767 503.007 702.125 503.438C702.482 503.87 702.745 504.363 702.899 504.891C703.054 505.418 703.096 505.969 703.025 506.511Z'
                opacity='.2' />
          <path id='Vector_471' fill='white'
                d='M698.946 503.034C698.608 504.3 698.589 505.942 696.29 505.852C696.925 506.254 697.643 506.626 698.034 507.323C698.397 508.041 698.588 508.836 698.59 509.642C698.81 508.909 698.724 508.188 699.277 507.48C699.478 507.218 699.735 507.005 700.03 506.858C700.324 506.711 700.647 506.634 700.976 506.632C700.299 506.133 699.658 506.117 699.325 505.184C699.194 504.83 699.093 504.466 699.024 504.095C698.961 503.72 699.067 503.166 698.866 502.874L698.946 503.034Z'
                opacity='.7' />
          <path id='Vector_472' fill='white'
                d='M673.832 535.848C673.588 534.949 673.325 535.054 673.098 535.439C671.959 534.789 672.123 539.216 673.451 538.097C673.901 537.717 673.983 536.404 673.832 535.848Z' />
        </g>;

      case 13:
        return <g id='Mini gem_11'>
          <path id='Vector_473' fill={`#${diamondColors[color][0]}`}
                d='M769.027 625.956L797.009 652.06L776.196 671.55L769.026 626.888L742.339 657.176L771.616 699.89L776.196 671.55L769.027 625.956Z' />
          <path id='Vector_474' fill={`#${diamondColors[color][1]}`}
                d='M768.98 625.956L742.339 657.027L776.182 671.775L768.98 625.956Z' />
          <path id='Vector_475' fill='white'
                d='M768.981 625.956L768.237 626.823L775.033 670.056L743.099 656.14L742.339 657.027L776.182 671.775L768.981 625.956Z' />
          <path id='Vector_476' fill={`#${diamondColors[color][2]}`}
                d='M776.098 671.508L771.496 699.89L797.009 651.99L776.098 671.508Z' />
          <path id='Vector_477' fill={`#${diamondColors[color][2]}`}
                d='M776.098 671.508L771.496 699.89L797.009 651.99L776.098 671.508Z' />
          <path id='Vector_478' fill='white' d='M742.339 657.196L772.538 648.065L769.07 625.956L742.339 657.196Z'
                opacity='.2' />
          <path id='Vector_479' fill='white'
                d='M772.372 647.991L797.009 651.99L768.893 625.956L772.372 647.588V647.991Z' opacity='.2' />
          <path id='Vector_480' fill={`#${diamondColors[color][3]}`}
                d='M774.186 683.328C774.236 683.02 789.104 659.622 789.104 659.622L797.009 652.51L779.054 685.735L771.496 699.89L774.186 683.328Z'
                opacity='.5' />
          <path id='Vector_481' fill={`#${diamondColors[color][3]}`}
                d='M776.999 671.509L796.119 653.661L797.009 651.99L776.098 671.509L771.496 699.89L772.791 697.46L776.999 671.509Z' />
          <path id='Vector_482' fill={`#${diamondColors[color][3]}`}
                d='M752.187 671.575L775.662 671.775L742.339 657.196L752.187 671.575Z'
                opacity='.5' />
          <path id='Vector_483' fill='white'
                d='M743.901 656.655C744.928 657.707 747.454 657.856 748.884 658.13C751.863 658.774 754.935 658.883 757.953 658.453C761.61 657.864 764.482 656.256 767.321 653.993C769.175 652.516 769.908 651.064 770.684 648.964C770.914 648.428 771.095 647.872 771.225 647.304C772.16 647.799 772.036 650.967 772.304 652.022C772.831 654.096 773.405 656.112 773.622 658.233C774.033 661.743 774.626 665.23 775.399 668.68C775.545 669.386 776.004 670.299 775.199 670.655C774.562 670.938 773.429 670.382 772.879 670.181C771.248 669.584 769.987 668.557 768.417 667.816C766.891 667.095 765.278 666.711 763.738 665.99C762.109 665.227 760.516 664.536 758.95 663.652C756.551 662.297 753.888 661.238 751.378 660.18C750.117 659.647 748.793 659.145 747.594 658.597C746.644 658.163 744.53 657.662 744 656.752L743.901 656.655Z'
                opacity='.4' />
          <path id='Vector_484' fill='white'
                d='M780.731 679.973C786.028 676.505 787.511 669.399 784.043 664.102C780.575 658.805 773.469 657.322 768.172 660.79C762.875 664.258 761.392 671.364 764.86 676.661C768.328 681.958 775.434 683.441 780.731 679.973Z'
                opacity='.1' />
          <path id='Vector_485' fill='white'
                d='M783.927 671.012C783.717 672.698 783.012 674.284 781.901 675.57C780.79 676.855 779.323 677.783 777.685 678.234C776.047 678.686 774.312 678.642 772.699 678.107C771.086 677.573 769.668 676.572 768.624 675.231C767.58 673.891 766.956 672.271 766.833 670.576C766.71 668.881 767.092 667.188 767.931 665.711C768.77 664.233 770.028 663.038 771.546 662.275C773.065 661.513 774.775 661.217 776.461 661.427C778.722 661.708 780.779 662.876 782.179 664.673C783.579 666.471 784.208 668.751 783.927 671.012Z'
                opacity='.2' />
          <path id='Vector_486' fill='white'
                d='M776.288 664.285C775.611 666.816 775.572 670.101 770.976 669.922C772.245 670.726 773.682 671.469 774.465 672.863C775.191 674.299 775.571 675.889 775.575 677.502C776.016 676.035 775.844 674.592 776.949 673.178C777.351 672.653 777.866 672.227 778.455 671.933C779.044 671.639 779.691 671.484 780.348 671.48C778.994 670.483 777.712 670.452 777.046 668.585C776.784 667.878 776.582 667.149 776.444 666.407C776.32 665.658 776.531 664.55 776.129 663.965L776.288 664.285Z'
                opacity='.7' />
          <path id='Vector_487' fill='white'
                d='M777.131 670.501C776.806 669.452 776.455 669.574 776.153 670.022C774.634 669.264 774.853 674.43 776.623 673.124C777.223 672.682 777.332 671.15 777.131 670.501Z' />
          <path id='Vector_488' fill='white'
                d='M750.088 657.931C749.891 659.515 749.229 661.005 748.185 662.213C747.141 663.42 745.763 664.291 744.224 664.716C742.685 665.14 741.055 665.098 739.54 664.596C738.025 664.094 736.693 663.154 735.712 661.895C734.731 660.636 734.146 659.114 734.03 657.522C733.914 655.93 734.273 654.339 735.061 652.951C735.849 651.563 737.031 650.44 738.457 649.724C739.884 649.007 741.491 648.73 743.075 648.927C745.199 649.191 747.131 650.288 748.446 651.977C749.761 653.665 750.352 655.807 750.088 657.931Z'
                opacity='.1' />
          <path id='Vector_489' fill='white'
                d='M749.062 657.676C748.916 658.851 748.424 659.956 747.65 660.852C746.875 661.748 745.853 662.394 744.711 662.708C743.57 663.023 742.36 662.992 741.236 662.619C740.112 662.247 739.124 661.549 738.396 660.615C737.669 659.681 737.235 658.552 737.149 657.371C737.063 656.19 737.329 655.01 737.913 653.98C738.498 652.95 739.375 652.117 740.433 651.585C741.491 651.054 742.683 650.848 743.858 650.994C744.638 651.091 745.392 651.34 746.076 651.729C746.76 652.117 747.361 652.636 747.844 653.257C748.328 653.877 748.684 654.587 748.893 655.345C749.102 656.103 749.159 656.895 749.062 657.676Z'
                opacity='.2' />
          <path id='Vector_490' fill='white'
                d='M743.572 652.733C743.083 654.486 743.056 656.76 739.736 656.634C740.653 657.191 741.69 657.706 742.255 658.67C742.78 659.665 743.055 660.765 743.058 661.882C743.376 660.866 743.252 659.868 744.05 658.889C744.34 658.525 744.712 658.231 745.138 658.027C745.563 657.823 746.03 657.716 746.505 657.713C745.527 657.023 744.602 657.001 744.12 655.709C743.931 655.219 743.785 654.715 743.685 654.201C743.594 653.683 743.748 652.915 743.458 652.51L743.572 652.733Z'
                opacity='.7' />
          <path id='Vector_491' fill='white'
                d='M774.054 627.704C773.908 628.828 773.417 629.885 772.642 630.743C771.868 631.6 770.846 632.218 769.704 632.519C768.562 632.82 767.353 632.791 766.229 632.434C765.105 632.078 764.116 631.411 763.388 630.517C762.661 629.624 762.226 628.544 762.14 627.414C762.054 626.284 762.32 625.155 762.905 624.17C763.49 623.185 764.367 622.388 765.425 621.88C766.483 621.371 767.675 621.175 768.851 621.314C770.426 621.502 771.86 622.28 772.836 623.478C773.812 624.677 774.25 626.196 774.054 627.704Z'
                opacity='.1' />
          <path id='Vector_492' fill='white'
                d='M773.025 627.511C772.917 628.329 772.553 629.098 771.981 629.721C771.409 630.345 770.653 630.794 769.809 631.013C768.965 631.232 768.071 631.211 767.24 630.952C766.41 630.692 765.679 630.207 765.141 629.557C764.603 628.907 764.282 628.122 764.219 627.3C764.155 626.478 764.352 625.657 764.784 624.941C765.217 624.224 765.865 623.645 766.647 623.275C767.429 622.906 768.311 622.763 769.179 622.864C769.756 622.932 770.313 623.105 770.818 623.375C771.324 623.646 771.767 624.007 772.125 624.438C772.482 624.87 772.745 625.363 772.899 625.891C773.054 626.418 773.096 626.969 773.025 627.511Z'
                opacity='.2' />
          <path id='Vector_493' fill='white'
                d='M768.946 624.034C768.608 625.3 768.589 626.942 766.29 626.852C766.925 627.254 767.643 627.626 768.034 628.323C768.397 629.041 768.588 629.836 768.59 630.642C768.81 629.909 768.724 629.188 769.277 628.48C769.478 628.218 769.735 628.005 770.03 627.858C770.324 627.711 770.647 627.634 770.976 627.632C770.299 627.133 769.658 627.117 769.325 626.184C769.194 625.83 769.093 625.466 769.024 625.095C768.961 624.72 769.067 624.166 768.866 623.874L768.946 624.034Z'
                opacity='.7' />
          <path id='Vector_494' fill='white'
                d='M743.832 656.848C743.588 655.949 743.325 656.054 743.098 656.439C741.959 655.789 742.123 660.216 743.451 659.097C743.901 658.717 743.983 657.404 743.832 656.848Z' />
        </g>;



      case 14:
        return <g id='Mini gem'>
          <path id='Vector_74' fill={`#${diamondColors[color][0]}`}
                d='M675.027 240.956L703.009 267.06L682.196 286.55L675.026 241.888L648.339 272.176L677.616 314.89L682.196 286.55L675.027 240.956Z' />
          <path id='Vector_75' fill={`#${diamondColors[color][1]}`}
                d='M674.98 240.956L648.339 272.027L682.182 286.775L674.98 240.956Z' />
          <path id='Vector_76' fill='white'
                d='M674.981 240.956L674.237 241.823L681.033 285.056L649.099 271.14L648.339 272.027L682.182 286.775L674.981 240.956Z' />
          <path id='Vector_77' fill={`#${diamondColors[color][2]}`}
                d='M682.098 286.508L677.496 314.89L703.009 266.989L682.098 286.508Z' />
          <path id='Vector_78' fill={`#${diamondColors[color][2]}`}
                d='M682.098 286.508L677.496 314.89L703.009 266.989L682.098 286.508Z' />
          <path id='Vector_79' fill='white' d='M648.339 272.196L678.538 263.065L675.07 240.956L648.339 272.196Z'
                opacity='.2' />
          <path id='Vector_80' fill='white'
                d='M678.372 262.991L703.009 266.989L674.893 240.956L678.372 262.588V262.991Z' opacity='.2' />
          <path id='Vector_81' fill={`#${diamondColors[color][3]}`}
                d='M680.186 298.328C680.236 298.02 695.104 274.622 695.104 274.622L703.009 267.51L685.054 300.735L677.496 314.89L680.186 298.328Z'
                opacity='.5' />
          <path id='Vector_82' fill={`#${diamondColors[color][3]}`}
                d='M682.999 286.509L702.119 268.661L703.009 266.989L682.098 286.509L677.496 314.89L678.791 312.46L682.999 286.509Z' />
          <path id='Vector_83' fill={`#${diamondColors[color][3]}`}
                d='M658.187 286.575L681.662 286.775L648.339 272.196L658.187 286.575Z'
                opacity='.5' />
          <path id='Vector_84' fill='white'
                d='M649.901 271.655C650.928 272.707 653.454 272.856 654.884 273.13C657.863 273.774 660.935 273.883 663.953 273.453C667.61 272.864 670.482 271.256 673.321 268.993C675.175 267.516 675.908 266.064 676.684 263.964C676.914 263.428 677.095 262.872 677.225 262.304C678.16 262.799 678.036 265.967 678.304 267.022C678.831 269.096 679.405 271.112 679.622 273.233C680.033 276.743 680.626 280.23 681.399 283.68C681.545 284.386 682.004 285.299 681.199 285.655C680.562 285.938 679.429 285.382 678.879 285.181C677.248 284.584 675.987 283.557 674.417 282.816C672.891 282.095 671.278 281.711 669.739 280.99C668.109 280.227 666.516 279.536 664.95 278.651C662.551 277.297 659.888 276.238 657.378 275.179C656.117 274.647 654.793 274.145 653.594 273.597C652.644 273.163 650.53 272.662 650 271.752L649.901 271.655Z'
                opacity='.4' />
          <path id='Vector_85' fill='white'
                d='M686.731 294.973C692.028 291.505 693.511 284.399 690.043 279.102C686.575 273.805 679.469 272.322 674.172 275.79C668.875 279.258 667.392 286.364 670.86 291.661C674.328 296.958 681.434 298.441 686.731 294.973Z'
                opacity='.1' />
          <path id='Vector_86' fill='white'
                d='M689.927 286.012C689.717 287.698 689.012 289.284 687.901 290.57C686.79 291.855 685.323 292.783 683.685 293.234C682.047 293.686 680.312 293.642 678.699 293.107C677.086 292.573 675.668 291.572 674.624 290.231C673.58 288.891 672.956 287.271 672.833 285.576C672.71 283.881 673.092 282.188 673.931 280.711C674.77 279.233 676.028 278.038 677.546 277.275C679.065 276.513 680.775 276.217 682.461 276.427C684.722 276.708 686.779 277.876 688.179 279.673C689.579 281.471 690.208 283.751 689.927 286.012Z'
                opacity='.2' />
          <path id='Vector_87' fill='white'
                d='M682.288 279.285C681.611 281.816 681.572 285.101 676.976 284.922C678.245 285.726 679.682 286.469 680.465 287.863C681.191 289.299 681.571 290.889 681.575 292.502C682.016 291.035 681.844 289.592 682.949 288.178C683.351 287.653 683.866 287.227 684.455 286.933C685.044 286.639 685.691 286.484 686.348 286.48C684.994 285.483 683.712 285.452 683.046 283.585C682.784 282.878 682.582 282.149 682.444 281.407C682.32 280.658 682.531 279.55 682.129 278.965L682.288 279.285Z'
                opacity='.7' />
          <path id='Vector_88' fill='white'
                d='M683.131 285.501C682.806 284.452 682.455 284.574 682.153 285.022C680.634 284.264 680.853 289.43 682.623 288.124C683.223 287.682 683.332 286.15 683.131 285.501Z' />
          <path id='Vector_89' fill='white'
                d='M656.088 272.931C655.891 274.515 655.229 276.005 654.185 277.213C653.141 278.42 651.763 279.291 650.224 279.716C648.685 280.14 647.055 280.098 645.54 279.596C644.025 279.094 642.693 278.154 641.712 276.895C640.731 275.636 640.146 274.114 640.03 272.522C639.914 270.93 640.273 269.339 641.061 267.951C641.849 266.563 643.031 265.44 644.457 264.724C645.884 264.007 647.491 263.73 649.075 263.927C651.199 264.191 653.131 265.288 654.446 266.977C655.761 268.665 656.352 270.807 656.088 272.931Z'
                opacity='.1' />
          <path id='Vector_90' fill='white'
                d='M655.062 272.676C654.916 273.851 654.424 274.956 653.65 275.852C652.875 276.748 651.853 277.394 650.711 277.708C649.57 278.023 648.36 277.992 647.236 277.619C646.112 277.247 645.124 276.549 644.396 275.615C643.669 274.681 643.235 273.552 643.149 272.371C643.063 271.19 643.329 270.01 643.913 268.98C644.498 267.95 645.375 267.117 646.433 266.585C647.491 266.054 648.683 265.848 649.858 265.994C650.638 266.091 651.392 266.34 652.076 266.729C652.76 267.117 653.361 267.636 653.844 268.257C654.328 268.877 654.684 269.587 654.893 270.345C655.102 271.103 655.159 271.895 655.062 272.676Z'
                opacity='.2' />
          <path id='Vector_91' fill='white'
                d='M649.572 267.733C649.083 269.486 649.056 271.76 645.736 271.634C646.653 272.191 647.69 272.706 648.255 273.67C648.78 274.665 649.055 275.765 649.058 276.882C649.376 275.866 649.252 274.867 650.05 273.888C650.34 273.525 650.712 273.231 651.138 273.027C651.563 272.823 652.03 272.716 652.505 272.713C651.527 272.023 650.602 272.001 650.12 270.709C649.931 270.219 649.785 269.715 649.685 269.201C649.594 268.683 649.748 267.915 649.458 267.51L649.572 267.733Z'
                opacity='.7' />
          <path id='Vector_92' fill='white'
                d='M680.054 242.704C679.908 243.828 679.417 244.885 678.643 245.742C677.868 246.6 676.846 247.218 675.704 247.519C674.562 247.82 673.353 247.791 672.229 247.434C671.105 247.078 670.116 246.411 669.388 245.517C668.661 244.624 668.226 243.544 668.14 242.414C668.054 241.284 668.32 240.155 668.905 239.17C669.49 238.185 670.367 237.388 671.425 236.88C672.483 236.371 673.675 236.174 674.851 236.314C676.426 236.502 677.86 237.28 678.836 238.478C679.812 239.677 680.25 241.196 680.054 242.704Z'
                opacity='.1' />
          <path id='Vector_93' fill='white'
                d='M679.025 242.511C678.917 243.329 678.553 244.098 677.981 244.721C677.409 245.345 676.653 245.794 675.809 246.013C674.965 246.232 674.071 246.211 673.24 245.952C672.41 245.692 671.679 245.207 671.141 244.557C670.603 243.907 670.282 243.122 670.219 242.3C670.155 241.478 670.352 240.657 670.784 239.941C671.217 239.224 671.865 238.645 672.647 238.275C673.429 237.906 674.311 237.763 675.179 237.864C675.756 237.932 676.313 238.105 676.818 238.375C677.324 238.646 677.767 239.007 678.125 239.438C678.482 239.87 678.745 240.363 678.899 240.891C679.054 241.418 679.096 241.969 679.025 242.511Z'
                opacity='.2' />
          <path id='Vector_94' fill='white'
                d='M674.946 239.034C674.608 240.3 674.589 241.942 672.29 241.852C672.925 242.254 673.643 242.626 674.034 243.323C674.397 244.041 674.588 244.836 674.59 245.642C674.81 244.909 674.724 244.188 675.277 243.48C675.478 243.218 675.735 243.005 676.03 242.858C676.324 242.711 676.647 242.634 676.976 242.631C676.299 242.133 675.658 242.117 675.325 241.184C675.194 240.83 675.093 240.466 675.024 240.095C674.961 239.72 675.067 239.166 674.866 238.874L674.946 239.034Z'
                opacity='.7' />
          <path id='Vector_95' fill='white'
                d='M649.832 271.848C649.588 270.949 649.325 271.054 649.098 271.439C647.959 270.789 648.123 275.216 649.451 274.097C649.901 273.717 649.983 272.404 649.832 271.848Z' />
        </g>;

      case 15:
        return <g id='Mini gem_2'>
          <path id='Vector_96' fill={`#${diamondColors[color][0]}`}
                d='M566.027 217.956L594.009 244.06L573.196 263.55L566.026 218.888L539.339 249.176L568.616 291.89L573.196 263.55L566.027 217.956Z' />
          <path id='Vector_97' fill={`#${diamondColors[color][1]}`}
                d='M565.98 217.956L539.339 249.027L573.182 263.775L565.98 217.956Z' />
          <path id='Vector_98' fill='white'
                d='M565.981 217.956L565.237 218.823L572.033 262.056L540.099 248.14L539.339 249.027L573.182 263.775L565.981 217.956Z' />
          <path id='Vector_99' fill={`#${diamondColors[color][2]}`}
                d='M573.098 263.508L568.496 291.89L594.009 243.989L573.098 263.508Z' />
          <path id='Vector_100' fill={`#${diamondColors[color][2]}`}
                d='M573.098 263.508L568.496 291.89L594.009 243.989L573.098 263.508Z' />
          <path id='Vector_101' fill='white' d='M539.339 249.196L569.538 240.065L566.07 217.956L539.339 249.196Z'
                opacity='.2' />
          <path id='Vector_102' fill='white'
                d='M569.372 239.991L594.009 243.989L565.893 217.956L569.372 239.588V239.991Z' opacity='.2' />
          <path id='Vector_103' fill={`#${diamondColors[color][3]}`}
                d='M571.186 275.328C571.236 275.02 586.104 251.622 586.104 251.622L594.009 244.51L576.054 277.735L568.496 291.89L571.186 275.328Z'
                opacity='.5' />
          <path id='Vector_104' fill={`#${diamondColors[color][3]}`}
                d='M573.999 263.509L593.119 245.661L594.009 243.989L573.098 263.509L568.496 291.89L569.791 289.46L573.999 263.509Z' />
          <path id='Vector_105' fill={`#${diamondColors[color][3]}`}
                d='M549.187 263.575L572.662 263.775L539.339 249.196L549.187 263.575Z'
                opacity='.5' />
          <path id='Vector_106' fill='white'
                d='M540.901 248.655C541.928 249.707 544.454 249.856 545.884 250.13C548.863 250.774 551.935 250.883 554.953 250.453C558.61 249.864 561.482 248.256 564.321 245.993C566.175 244.516 566.908 243.064 567.684 240.964C567.914 240.428 568.095 239.872 568.225 239.304C569.16 239.799 569.036 242.967 569.304 244.022C569.831 246.096 570.405 248.112 570.622 250.233C571.033 253.743 571.626 257.23 572.399 260.68C572.545 261.386 573.004 262.299 572.199 262.655C571.562 262.938 570.429 262.382 569.879 262.181C568.248 261.584 566.987 260.557 565.417 259.816C563.891 259.095 562.278 258.711 560.739 257.99C559.109 257.227 557.516 256.536 555.95 255.651C553.551 254.297 550.888 253.238 548.378 252.179C547.117 251.647 545.793 251.145 544.594 250.597C543.644 250.163 541.53 249.662 541 248.752L540.901 248.655Z'
                opacity='.4' />
          <path id='Vector_107' fill='white'
                d='M577.731 271.973C583.028 268.505 584.511 261.399 581.043 256.102C577.575 250.805 570.469 249.322 565.172 252.79C559.875 256.258 558.392 263.364 561.86 268.661C565.328 273.958 572.434 275.441 577.731 271.973Z'
                opacity='.1' />
          <path id='Vector_108' fill='white'
                d='M580.927 263.012C580.717 264.698 580.012 266.284 578.901 267.57C577.79 268.855 576.323 269.783 574.685 270.234C573.047 270.686 571.312 270.642 569.699 270.107C568.086 269.573 566.668 268.572 565.624 267.231C564.58 265.891 563.956 264.271 563.833 262.576C563.71 260.881 564.092 259.188 564.931 257.711C565.77 256.233 567.028 255.038 568.546 254.275C570.065 253.513 571.775 253.217 573.461 253.427C575.722 253.708 577.779 254.876 579.179 256.673C580.579 258.471 581.208 260.751 580.927 263.012Z'
                opacity='.2' />
          <path id='Vector_109' fill='white'
                d='M573.288 256.285C572.611 258.816 572.572 262.101 567.976 261.922C569.245 262.726 570.682 263.469 571.465 264.863C572.191 266.299 572.571 267.889 572.575 269.502C573.016 268.035 572.844 266.592 573.949 265.178C574.351 264.653 574.866 264.227 575.455 263.933C576.044 263.639 576.691 263.484 577.348 263.48C575.994 262.483 574.712 262.452 574.046 260.585C573.784 259.878 573.582 259.149 573.444 258.407C573.32 257.658 573.531 256.55 573.129 255.965L573.288 256.285Z'
                opacity='.7' />
          <path id='Vector_110' fill='white'
                d='M574.131 262.501C573.806 261.452 573.455 261.574 573.153 262.022C571.634 261.264 571.853 266.43 573.623 265.124C574.223 264.682 574.332 263.15 574.131 262.501Z' />
          <path id='Vector_111' fill='white'
                d='M547.088 249.931C546.891 251.515 546.229 253.005 545.185 254.213C544.141 255.42 542.763 256.291 541.224 256.716C539.685 257.14 538.055 257.098 536.54 256.596C535.025 256.094 533.693 255.154 532.712 253.895C531.731 252.636 531.146 251.114 531.03 249.522C530.914 247.93 531.273 246.339 532.061 244.951C532.849 243.563 534.031 242.44 535.457 241.724C536.884 241.007 538.491 240.73 540.075 240.927C542.199 241.191 544.131 242.288 545.446 243.977C546.761 245.665 547.352 247.807 547.088 249.931Z'
                opacity='.1' />
          <path id='Vector_112' fill='white'
                d='M546.062 249.676C545.916 250.851 545.424 251.956 544.65 252.852C543.875 253.748 542.853 254.394 541.711 254.708C540.57 255.023 539.36 254.992 538.236 254.619C537.112 254.247 536.124 253.549 535.396 252.615C534.669 251.681 534.235 250.552 534.149 249.371C534.063 248.19 534.329 247.01 534.913 245.98C535.498 244.95 536.375 244.117 537.433 243.585C538.491 243.054 539.683 242.848 540.858 242.994C541.638 243.091 542.392 243.34 543.076 243.729C543.76 244.117 544.361 244.636 544.844 245.257C545.328 245.877 545.684 246.587 545.893 247.345C546.102 248.103 546.159 248.895 546.062 249.676Z'
                opacity='.2' />
          <path id='Vector_113' fill='white'
                d='M540.572 244.733C540.083 246.486 540.056 248.76 536.736 248.634C537.653 249.191 538.69 249.706 539.255 250.67C539.78 251.665 540.055 252.765 540.058 253.882C540.376 252.866 540.252 251.867 541.05 250.888C541.34 250.525 541.712 250.231 542.138 250.027C542.563 249.823 543.03 249.716 543.505 249.713C542.527 249.023 541.602 249.001 541.12 247.709C540.931 247.219 540.785 246.715 540.685 246.201C540.594 245.683 540.748 244.915 540.458 244.51L540.572 244.733Z'
                opacity='.7' />
          <path id='Vector_114' fill='white'
                d='M571.054 219.704C570.908 220.828 570.417 221.885 569.643 222.742C568.868 223.6 567.846 224.218 566.704 224.519C565.562 224.82 564.353 224.791 563.229 224.434C562.105 224.078 561.116 223.411 560.388 222.517C559.661 221.624 559.226 220.544 559.14 219.414C559.054 218.284 559.32 217.155 559.905 216.17C560.49 215.185 561.367 214.388 562.425 213.88C563.483 213.371 564.675 213.174 565.851 213.314C567.426 213.502 568.86 214.28 569.836 215.478C570.812 216.677 571.25 218.196 571.054 219.704Z'
                opacity='.1' />
          <path id='Vector_115' fill='white'
                d='M570.024 219.511C569.917 220.329 569.553 221.098 568.981 221.721C568.409 222.345 567.653 222.794 566.809 223.013C565.965 223.232 565.071 223.211 564.24 222.952C563.41 222.692 562.679 222.207 562.141 221.557C561.603 220.907 561.282 220.122 561.219 219.3C561.155 218.478 561.352 217.657 561.784 216.941C562.217 216.224 562.865 215.645 563.647 215.275C564.429 214.906 565.311 214.763 566.179 214.864C566.756 214.932 567.313 215.105 567.818 215.375C568.324 215.646 568.767 216.007 569.125 216.438C569.482 216.87 569.745 217.363 569.899 217.891C570.054 218.418 570.096 218.969 570.024 219.511Z'
                opacity='.2' />
          <path id='Vector_116' fill='white'
                d='M565.947 216.034C565.608 217.3 565.589 218.942 563.29 218.852C563.925 219.254 564.643 219.626 565.034 220.323C565.397 221.041 565.588 221.836 565.59 222.642C565.81 221.909 565.724 221.188 566.277 220.48C566.478 220.218 566.735 220.005 567.03 219.858C567.324 219.711 567.647 219.634 567.976 219.631C567.299 219.133 566.658 219.117 566.325 218.184C566.194 217.83 566.093 217.466 566.024 217.095C565.961 216.72 566.067 216.166 565.866 215.874L565.947 216.034Z'
                opacity='.7' />
          <path id='Vector_117' fill='white'
                d='M540.832 248.848C540.588 247.949 540.325 248.054 540.098 248.439C538.959 247.789 539.123 252.216 540.451 251.097C540.901 250.717 540.983 249.404 540.832 248.848Z' />
        </g>;

      case 16:
        return <g id='Mini gem_3'>
          <path id='Vector_118' fill={`#${diamondColors[color][0]}`}
                d='M611.027 130.956L639.009 157.06L618.196 176.55L611.026 131.888L584.339 162.176L613.616 204.89L618.196 176.55L611.027 130.956Z' />
          <path id='Vector_119' fill={`#${diamondColors[color][1]}`}
                d='M610.98 130.956L584.339 162.027L618.182 176.775L610.98 130.956Z' />
          <path id='Vector_120' fill='white'
                d='M610.981 130.956L610.237 131.823L617.033 175.056L585.099 161.14L584.339 162.027L618.182 176.775L610.981 130.956Z' />
          <path id='Vector_121' fill={`#${diamondColors[color][2]}`}
                d='M618.098 176.508L613.496 204.89L639.009 156.989L618.098 176.508Z' />
          <path id='Vector_122' fill={`#${diamondColors[color][2]}`}
                d='M618.098 176.508L613.496 204.89L639.009 156.989L618.098 176.508Z' />
          <path id='Vector_123' fill='white' d='M584.339 162.196L614.538 153.065L611.07 130.956L584.339 162.196Z'
                opacity='.2' />
          <path id='Vector_124' fill='white'
                d='M614.372 152.991L639.009 156.989L610.893 130.956L614.372 152.588V152.991Z' opacity='.2' />
          <path id='Vector_125' fill={`#${diamondColors[color][3]}`}
                d='M616.186 188.328C616.236 188.02 631.104 164.622 631.104 164.622L639.009 157.51L621.054 190.735L613.496 204.89L616.186 188.328Z'
                opacity='.5' />
          <path id='Vector_126' fill={`#${diamondColors[color][3]}`}
                d='M618.999 176.509L638.119 158.661L639.009 156.989L618.098 176.509L613.496 204.89L614.791 202.46L618.999 176.509Z' />
          <path id='Vector_127' fill={`#${diamondColors[color][3]}`}
                d='M594.187 176.575L617.662 176.775L584.339 162.196L594.187 176.575Z'
                opacity='.5' />
          <path id='Vector_128' fill='white'
                d='M585.901 161.654C586.928 162.707 589.454 162.856 590.884 163.13C593.863 163.774 596.935 163.883 599.953 163.453C603.61 162.864 606.482 161.256 609.321 158.993C611.175 157.516 611.908 156.064 612.684 153.964C612.914 153.428 613.095 152.872 613.225 152.303C614.16 152.799 614.036 155.967 614.304 157.022C614.831 159.096 615.405 161.112 615.622 163.233C616.033 166.743 616.626 170.23 617.399 173.68C617.545 174.386 618.004 175.299 617.199 175.655C616.562 175.938 615.429 175.382 614.879 175.181C613.248 174.584 611.987 173.557 610.417 172.816C608.891 172.095 607.278 171.711 605.739 170.99C604.109 170.227 602.516 169.536 600.95 168.651C598.551 167.297 595.888 166.238 593.378 165.179C592.117 164.647 590.793 164.145 589.594 163.597C588.644 163.163 586.53 162.662 586 161.751L585.901 161.654Z'
                opacity='.4' />
          <path id='Vector_129' fill='white'
                d='M622.731 184.973C628.028 181.505 629.511 174.399 626.043 169.102C622.575 163.805 615.469 162.322 610.172 165.79C604.875 169.258 603.392 176.364 606.86 181.661C610.328 186.958 617.434 188.441 622.731 184.973Z'
                opacity='.1' />
          <path id='Vector_130' fill='white'
                d='M625.927 176.012C625.717 177.698 625.012 179.284 623.901 180.57C622.79 181.855 621.323 182.783 619.685 183.234C618.047 183.686 616.312 183.642 614.699 183.107C613.086 182.573 611.668 181.572 610.624 180.231C609.58 178.891 608.956 177.271 608.833 175.576C608.71 173.881 609.092 172.188 609.931 170.711C610.77 169.233 612.028 168.037 613.546 167.275C615.065 166.513 616.775 166.217 618.461 166.427C620.722 166.708 622.779 167.876 624.179 169.673C625.579 171.471 626.208 173.751 625.927 176.012Z'
                opacity='.2' />
          <path id='Vector_131' fill='white'
                d='M618.288 169.285C617.611 171.816 617.572 175.101 612.976 174.922C614.245 175.726 615.682 176.469 616.465 177.863C617.191 179.299 617.571 180.889 617.575 182.502C618.016 181.035 617.844 179.592 618.949 178.178C619.351 177.653 619.866 177.227 620.455 176.933C621.044 176.639 621.691 176.484 622.348 176.48C620.994 175.483 619.712 175.452 619.046 173.585C618.784 172.878 618.582 172.149 618.444 171.407C618.32 170.658 618.531 169.55 618.129 168.965L618.288 169.285Z'
                opacity='.7' />
          <path id='Vector_132' fill='white'
                d='M619.131 175.501C618.806 174.452 618.455 174.574 618.153 175.022C616.634 174.264 616.853 179.43 618.623 178.124C619.223 177.682 619.332 176.15 619.131 175.501Z' />
          <path id='Vector_133' fill='white'
                d='M592.088 162.931C591.891 164.515 591.229 166.005 590.185 167.213C589.141 168.42 587.763 169.291 586.224 169.716C584.685 170.14 583.055 170.098 581.54 169.596C580.025 169.094 578.693 168.154 577.712 166.895C576.731 165.636 576.146 164.114 576.03 162.522C575.914 160.93 576.273 159.339 577.061 157.951C577.849 156.563 579.031 155.44 580.457 154.724C581.884 154.007 583.491 153.73 585.075 153.927C587.199 154.191 589.131 155.288 590.446 156.977C591.761 158.665 592.352 160.807 592.088 162.931Z'
                opacity='.1' />
          <path id='Vector_134' fill='white'
                d='M591.062 162.676C590.916 163.851 590.424 164.956 589.65 165.852C588.875 166.747 587.853 167.394 586.711 167.708C585.57 168.023 584.36 167.992 583.236 167.619C582.112 167.247 581.124 166.549 580.396 165.615C579.669 164.681 579.235 163.552 579.149 162.371C579.063 161.19 579.329 160.01 579.913 158.98C580.498 157.95 581.375 157.117 582.433 156.585C583.491 156.054 584.683 155.848 585.858 155.994C586.638 156.091 587.392 156.34 588.076 156.729C588.76 157.117 589.361 157.636 589.844 158.257C590.328 158.877 590.684 159.587 590.893 160.345C591.102 161.103 591.159 161.895 591.062 162.676Z'
                opacity='.2' />
          <path id='Vector_135' fill='white'
                d='M585.572 157.733C585.083 159.486 585.056 161.76 581.736 161.634C582.653 162.191 583.69 162.706 584.255 163.67C584.78 164.665 585.055 165.765 585.058 166.882C585.376 165.866 585.252 164.867 586.05 163.888C586.34 163.525 586.712 163.231 587.138 163.027C587.563 162.823 588.03 162.716 588.505 162.713C587.527 162.023 586.602 162.001 586.12 160.709C585.931 160.219 585.785 159.715 585.685 159.201C585.594 158.683 585.748 157.915 585.458 157.51L585.572 157.733Z'
                opacity='.7' />
          <path id='Vector_136' fill='white'
                d='M616.054 132.704C615.908 133.828 615.417 134.885 614.643 135.742C613.868 136.6 612.846 137.218 611.704 137.519C610.562 137.82 609.353 137.791 608.229 137.434C607.105 137.078 606.116 136.411 605.388 135.517C604.661 134.624 604.226 133.544 604.14 132.414C604.054 131.284 604.32 130.155 604.905 129.17C605.49 128.185 606.367 127.388 607.425 126.88C608.483 126.371 609.675 126.174 610.851 126.314C612.426 126.502 613.86 127.28 614.836 128.478C615.812 129.677 616.25 131.196 616.054 132.704Z'
                opacity='.1' />
          <path id='Vector_137' fill='white'
                d='M615.025 132.511C614.917 133.329 614.553 134.098 613.981 134.721C613.409 135.345 612.653 135.794 611.809 136.013C610.965 136.232 610.071 136.211 609.24 135.952C608.41 135.692 607.679 135.207 607.141 134.557C606.603 133.907 606.282 133.122 606.219 132.3C606.155 131.478 606.352 130.657 606.784 129.941C607.217 129.224 607.865 128.645 608.647 128.275C609.429 127.906 610.311 127.763 611.179 127.864C611.756 127.932 612.313 128.105 612.818 128.375C613.324 128.645 613.767 129.007 614.125 129.438C614.482 129.87 614.745 130.363 614.899 130.891C615.054 131.418 615.096 131.968 615.025 132.511Z'
                opacity='.2' />
          <path id='Vector_138' fill='white'
                d='M610.947 129.034C610.608 130.3 610.589 131.942 608.29 131.852C608.925 132.254 609.643 132.626 610.034 133.323C610.397 134.041 610.588 134.836 610.59 135.642C610.81 134.909 610.724 134.188 611.277 133.48C611.478 133.218 611.735 133.005 612.03 132.858C612.324 132.711 612.647 132.633 612.976 132.631C612.299 132.133 611.658 132.117 611.325 131.184C611.194 130.83 611.093 130.466 611.024 130.095C610.961 129.72 611.067 129.166 610.866 128.874L610.947 129.034Z'
                opacity='.7' />
          <path id='Vector_139' fill='white'
                d='M585.832 161.848C585.588 160.949 585.325 161.054 585.098 161.439C583.959 160.789 584.123 165.216 585.451 164.096C585.901 163.717 585.983 162.404 585.832 161.848Z' />
        </g>;

      case 17:
        return <g id='Mini gem_4'>
          <path id='Vector_140' fill={`#${diamondColors[color][0]}`}
                d='M785.027 281.956L813.009 308.06L792.196 327.55L785.026 282.888L758.339 313.176L787.616 355.89L792.196 327.55L785.027 281.956Z' />
          <path id='Vector_141' fill={`#${diamondColors[color][1]}`}
                d='M784.98 281.956L758.339 313.027L792.182 327.775L784.98 281.956Z' />
          <path id='Vector_142' fill='white'
                d='M784.981 281.956L784.237 282.823L791.033 326.056L759.099 312.14L758.339 313.027L792.182 327.775L784.981 281.956Z' />
          <path id='Vector_143' fill={`#${diamondColors[color][2]}`}
                d='M792.098 327.508L787.496 355.89L813.009 307.99L792.098 327.508Z' />
          <path id='Vector_144' fill={`#${diamondColors[color][2]}`}
                d='M792.098 327.508L787.496 355.89L813.009 307.99L792.098 327.508Z' />
          <path id='Vector_145' fill='white' d='M758.339 313.196L788.538 304.065L785.07 281.956L758.339 313.196Z'
                opacity='.2' />
          <path id='Vector_146' fill='white'
                d='M788.372 303.991L813.009 307.99L784.893 281.956L788.372 303.588V303.991Z' opacity='.2' />
          <path id='Vector_147' fill={`#${diamondColors[color][3]}`}
                d='M790.186 339.329C790.236 339.02 805.104 315.623 805.104 315.623L813.009 308.51L795.054 341.735L787.496 355.89L790.186 339.329Z'
                opacity='.5' />
          <path id='Vector_148' fill={`#${diamondColors[color][3]}`}
                d='M792.999 327.509L812.119 309.661L813.009 307.99L792.098 327.509L787.496 355.89L788.791 353.46L792.999 327.509Z' />
          <path id='Vector_149' fill={`#${diamondColors[color][3]}`}
                d='M768.187 327.575L791.662 327.775L758.339 313.196L768.187 327.575Z'
                opacity='.5' />
          <path id='Vector_150' fill='white'
                d='M759.901 312.655C760.928 313.707 763.454 313.856 764.884 314.13C767.863 314.774 770.935 314.883 773.953 314.453C777.61 313.864 780.482 312.256 783.321 309.993C785.175 308.516 785.908 307.064 786.684 304.964C786.914 304.428 787.095 303.872 787.225 303.304C788.16 303.799 788.036 306.967 788.304 308.022C788.831 310.097 789.405 312.112 789.622 314.233C790.033 317.743 790.626 321.23 791.399 324.68C791.545 325.386 792.004 326.299 791.199 326.655C790.562 326.938 789.429 326.382 788.879 326.181C787.248 325.584 785.987 324.557 784.417 323.816C782.891 323.095 781.278 322.711 779.738 321.99C778.109 321.227 776.516 320.536 774.95 319.652C772.551 318.297 769.888 317.238 767.378 316.18C766.117 315.647 764.793 315.145 763.594 314.597C762.644 314.163 760.53 313.662 760 312.752L759.901 312.655Z'
                opacity='.4' />
          <path id='Vector_151' fill='white'
                d='M796.731 335.973C802.028 332.505 803.511 325.399 800.043 320.102C796.575 314.805 789.469 313.322 784.172 316.79C778.875 320.258 777.392 327.364 780.86 332.661C784.328 337.958 791.434 339.441 796.731 335.973Z'
                opacity='.1' />
          <path id='Vector_152' fill='white'
                d='M799.927 327.012C799.717 328.698 799.012 330.284 797.901 331.57C796.79 332.855 795.323 333.783 793.685 334.234C792.047 334.686 790.312 334.642 788.699 334.107C787.086 333.573 785.668 332.572 784.624 331.231C783.58 329.891 782.956 328.271 782.833 326.576C782.71 324.882 783.092 323.188 783.931 321.711C784.77 320.233 786.028 319.038 787.546 318.275C789.065 317.513 790.775 317.217 792.461 317.427C794.722 317.708 796.779 318.876 798.179 320.673C799.579 322.471 800.208 324.751 799.927 327.012Z'
                opacity='.2' />
          <path id='Vector_153' fill='white'
                d='M792.288 320.285C791.611 322.816 791.572 326.101 786.976 325.922C788.245 326.726 789.682 327.469 790.465 328.863C791.191 330.299 791.571 331.889 791.575 333.502C792.016 332.035 791.844 330.592 792.949 329.178C793.351 328.653 793.866 328.227 794.455 327.933C795.044 327.639 795.691 327.484 796.348 327.48C794.994 326.483 793.712 326.452 793.046 324.585C792.784 323.878 792.582 323.149 792.444 322.407C792.32 321.658 792.531 320.55 792.129 319.965L792.288 320.285Z'
                opacity='.7' />
          <path id='Vector_154' fill='white'
                d='M793.131 326.501C792.806 325.452 792.455 325.574 792.153 326.022C790.634 325.264 790.853 330.43 792.623 329.124C793.223 328.682 793.332 327.15 793.131 326.501Z' />
          <path id='Vector_155' fill='white'
                d='M766.088 313.931C765.891 315.515 765.229 317.005 764.185 318.213C763.141 319.42 761.763 320.291 760.224 320.716C758.685 321.14 757.055 321.098 755.54 320.596C754.025 320.094 752.693 319.154 751.712 317.895C750.731 316.636 750.146 315.114 750.03 313.522C749.914 311.93 750.273 310.339 751.061 308.951C751.849 307.563 753.031 306.44 754.457 305.724C755.884 305.007 757.491 304.73 759.075 304.927C761.199 305.191 763.131 306.288 764.446 307.977C765.761 309.665 766.352 311.807 766.088 313.931Z'
                opacity='.1' />
          <path id='Vector_156' fill='white'
                d='M765.062 313.676C764.916 314.851 764.424 315.956 763.65 316.852C762.875 317.748 761.853 318.394 760.711 318.708C759.57 319.023 758.36 318.992 757.236 318.619C756.112 318.247 755.124 317.549 754.396 316.615C753.669 315.681 753.235 314.552 753.149 313.371C753.063 312.19 753.329 311.01 753.913 309.98C754.498 308.95 755.375 308.117 756.433 307.585C757.491 307.054 758.683 306.848 759.858 306.994C760.638 307.091 761.392 307.34 762.076 307.729C762.76 308.117 763.361 308.636 763.844 309.257C764.328 309.877 764.684 310.587 764.893 311.345C765.102 312.103 765.159 312.895 765.062 313.676Z'
                opacity='.2' />
          <path id='Vector_157' fill='white'
                d='M759.572 308.733C759.083 310.486 759.056 312.76 755.736 312.634C756.653 313.191 757.69 313.706 758.255 314.67C758.78 315.665 759.055 316.765 759.058 317.882C759.376 316.866 759.252 315.868 760.05 314.889C760.34 314.525 760.712 314.231 761.138 314.027C761.563 313.823 762.03 313.716 762.505 313.713C761.527 313.023 760.602 313.001 760.12 311.709C759.931 311.219 759.785 310.715 759.685 310.201C759.594 309.683 759.748 308.916 759.458 308.51L759.572 308.733Z'
                opacity='.7' />
          <path id='Vector_158' fill='white'
                d='M790.054 283.704C789.908 284.828 789.417 285.885 788.642 286.743C787.868 287.6 786.846 288.218 785.704 288.519C784.562 288.82 783.353 288.791 782.229 288.434C781.105 288.078 780.116 287.411 779.388 286.517C778.661 285.624 778.226 284.544 778.14 283.414C778.054 282.284 778.32 281.155 778.905 280.17C779.49 279.185 780.367 278.388 781.425 277.88C782.483 277.371 783.675 277.175 784.851 277.314C786.426 277.502 787.86 278.28 788.836 279.478C789.812 280.677 790.25 282.196 790.054 283.704Z'
                opacity='.1' />
          <path id='Vector_159' fill='white'
                d='M789.025 283.511C788.917 284.329 788.553 285.098 787.981 285.721C787.409 286.345 786.653 286.794 785.809 287.013C784.965 287.232 784.071 287.211 783.24 286.952C782.41 286.692 781.679 286.207 781.141 285.557C780.603 284.907 780.282 284.122 780.219 283.3C780.155 282.478 780.352 281.657 780.784 280.941C781.217 280.225 781.865 279.645 782.647 279.275C783.429 278.906 784.311 278.763 785.179 278.864C785.756 278.932 786.313 279.105 786.818 279.376C787.324 279.646 787.767 280.007 788.125 280.438C788.482 280.87 788.745 281.363 788.899 281.891C789.054 282.418 789.096 282.969 789.025 283.511Z'
                opacity='.2' />
          <path id='Vector_160' fill='white'
                d='M784.946 280.034C784.608 281.3 784.589 282.942 782.29 282.852C782.925 283.254 783.643 283.626 784.034 284.323C784.397 285.041 784.588 285.836 784.59 286.642C784.81 285.909 784.724 285.188 785.277 284.48C785.478 284.218 785.735 284.005 786.03 283.858C786.324 283.711 786.647 283.634 786.976 283.632C786.299 283.133 785.658 283.117 785.325 282.184C785.194 281.83 785.093 281.466 785.024 281.095C784.961 280.72 785.067 280.166 784.866 279.874L784.946 280.034Z'
                opacity='.7' />
          <path id='Vector_161' fill='white'
                d='M759.832 312.848C759.588 311.949 759.325 312.054 759.098 312.439C757.959 311.789 758.123 316.216 759.451 315.097C759.901 314.717 759.983 313.404 759.832 312.848Z' />
        </g>;

      case 18 :
        return <g id='Mini gem_5'>
          <path id='Vector_162' fill={`#${diamondColors[color][0]}`}
                d='M742.027 194.956L770.009 221.06L749.196 240.55L742.026 195.888L715.339 226.176L744.616 268.89L749.196 240.55L742.027 194.956Z' />
          <path id='Vector_163' fill={`#${diamondColors[color][1]}`}
                d='M741.98 194.956L715.339 226.027L749.182 240.775L741.98 194.956Z' />
          <path id='Vector_164' fill='white'
                d='M741.981 194.956L741.237 195.823L748.033 239.056L716.099 225.14L715.339 226.027L749.182 240.775L741.981 194.956Z' />
          <path id='Vector_165' fill={`#${diamondColors[color][2]}`}
                d='M749.098 240.508L744.496 268.89L770.009 220.99L749.098 240.508Z' />
          <path id='Vector_166' fill={`#${diamondColors[color][2]}`}
                d='M749.098 240.508L744.496 268.89L770.009 220.99L749.098 240.508Z' />
          <path id='Vector_167' fill='white' d='M715.339 226.196L745.538 217.065L742.07 194.956L715.339 226.196Z'
                opacity='.2' />
          <path id='Vector_168' fill='white'
                d='M745.372 216.991L770.009 220.99L741.893 194.956L745.372 216.588V216.991Z' opacity='.2' />
          <path id='Vector_169' fill={`#${diamondColors[color][3]}`}
                d='M747.186 252.328C747.236 252.02 762.104 228.622 762.104 228.622L770.009 221.51L752.054 254.735L744.496 268.89L747.186 252.328Z'
                opacity='.5' />
          <path id='Vector_170' fill={`#${diamondColors[color][3]}`}
                d='M749.999 240.509L769.119 222.661L770.009 220.99L749.098 240.509L744.496 268.89L745.791 266.46L749.999 240.509Z' />
          <path id='Vector_171' fill={`#${diamondColors[color][3]}`}
                d='M725.187 240.575L748.662 240.775L715.339 226.196L725.187 240.575Z'
                opacity='.5' />
          <path id='Vector_172' fill='white'
                d='M716.901 225.655C717.928 226.707 720.454 226.856 721.884 227.13C724.863 227.774 727.935 227.883 730.953 227.453C734.61 226.864 737.482 225.256 740.321 222.993C742.175 221.516 742.908 220.064 743.684 217.964C743.914 217.428 744.095 216.872 744.225 216.304C745.16 216.799 745.036 219.967 745.304 221.022C745.831 223.096 746.405 225.112 746.622 227.233C747.033 230.743 747.626 234.23 748.399 237.68C748.545 238.386 749.004 239.299 748.199 239.655C747.562 239.938 746.429 239.382 745.879 239.181C744.248 238.584 742.987 237.557 741.417 236.816C739.891 236.095 738.278 235.711 736.738 234.99C735.109 234.227 733.516 233.536 731.95 232.652C729.551 231.297 726.888 230.238 724.378 229.179C723.117 228.647 721.793 228.145 720.594 227.597C719.644 227.163 717.53 226.662 717 225.752L716.901 225.655Z'
                opacity='.4' />
          <path id='Vector_173' fill='white'
                d='M753.731 248.973C759.028 245.505 760.511 238.399 757.043 233.102C753.575 227.805 746.469 226.322 741.172 229.79C735.875 233.258 734.392 240.364 737.86 245.661C741.328 250.958 748.434 252.441 753.731 248.973Z'
                opacity='.1' />
          <path id='Vector_174' fill='white'
                d='M756.927 240.012C756.717 241.698 756.012 243.284 754.901 244.57C753.79 245.855 752.323 246.783 750.685 247.234C749.047 247.686 747.312 247.642 745.699 247.107C744.086 246.573 742.668 245.572 741.624 244.231C740.58 242.891 739.956 241.271 739.833 239.576C739.71 237.881 740.092 236.188 740.931 234.711C741.77 233.233 743.028 232.038 744.546 231.275C746.065 230.513 747.775 230.217 749.461 230.427C751.722 230.708 753.779 231.876 755.179 233.673C756.579 235.471 757.208 237.751 756.927 240.012Z'
                opacity='.2' />
          <path id='Vector_175' fill='white'
                d='M749.288 233.285C748.611 235.816 748.572 239.101 743.976 238.922C745.245 239.726 746.682 240.469 747.465 241.863C748.191 243.299 748.571 244.889 748.575 246.502C749.016 245.035 748.844 243.592 749.949 242.178C750.351 241.653 750.866 241.227 751.455 240.933C752.044 240.639 752.691 240.484 753.348 240.48C751.994 239.483 750.712 239.452 750.046 237.585C749.784 236.878 749.582 236.149 749.444 235.407C749.32 234.658 749.531 233.55 749.129 232.965L749.288 233.285Z'
                opacity='.7' />
          <path id='Vector_176' fill='white'
                d='M750.131 239.501C749.806 238.452 749.455 238.574 749.153 239.022C747.634 238.264 747.853 243.43 749.623 242.124C750.223 241.682 750.332 240.15 750.131 239.501Z' />
          <path id='Vector_177' fill='white'
                d='M723.088 226.931C722.891 228.515 722.229 230.005 721.185 231.213C720.141 232.42 718.763 233.291 717.224 233.716C715.685 234.14 714.055 234.098 712.54 233.596C711.025 233.094 709.693 232.154 708.712 230.895C707.731 229.636 707.146 228.114 707.03 226.522C706.914 224.93 707.273 223.339 708.061 221.951C708.849 220.563 710.031 219.44 711.457 218.724C712.884 218.007 714.491 217.73 716.075 217.927C718.199 218.191 720.131 219.288 721.446 220.977C722.761 222.665 723.352 224.807 723.088 226.931Z'
                opacity='.1' />
          <path id='Vector_178' fill='white'
                d='M722.062 226.676C721.916 227.851 721.424 228.956 720.65 229.852C719.875 230.748 718.853 231.394 717.711 231.708C716.57 232.023 715.36 231.992 714.236 231.619C713.112 231.247 712.124 230.549 711.396 229.615C710.669 228.681 710.235 227.552 710.149 226.371C710.063 225.19 710.329 224.01 710.913 222.98C711.498 221.95 712.375 221.117 713.433 220.585C714.491 220.054 715.683 219.848 716.858 219.994C717.638 220.091 718.392 220.34 719.076 220.729C719.76 221.117 720.361 221.636 720.844 222.257C721.328 222.877 721.684 223.587 721.893 224.345C722.102 225.103 722.159 225.895 722.062 226.676Z'
                opacity='.2' />
          <path id='Vector_179' fill='white'
                d='M716.572 221.733C716.083 223.486 716.056 225.76 712.736 225.634C713.653 226.191 714.69 226.706 715.255 227.67C715.78 228.665 716.055 229.765 716.058 230.882C716.376 229.866 716.252 228.867 717.05 227.888C717.34 227.525 717.712 227.231 718.138 227.027C718.563 226.823 719.03 226.716 719.505 226.713C718.527 226.023 717.602 226.001 717.12 224.709C716.931 224.219 716.785 223.715 716.685 223.201C716.594 222.683 716.748 221.915 716.458 221.51L716.572 221.733Z'
                opacity='.7' />
          <path id='Vector_180' fill='white'
                d='M747.054 196.704C746.908 197.828 746.417 198.885 745.642 199.743C744.868 200.6 743.846 201.218 742.704 201.519C741.562 201.82 740.353 201.791 739.229 201.434C738.105 201.078 737.116 200.411 736.388 199.517C735.661 198.624 735.226 197.544 735.14 196.414C735.054 195.284 735.32 194.155 735.905 193.17C736.49 192.185 737.367 191.388 738.425 190.88C739.483 190.371 740.675 190.175 741.851 190.314C743.426 190.502 744.86 191.28 745.836 192.478C746.812 193.677 747.25 195.196 747.054 196.704Z'
                opacity='.1' />
          <path id='Vector_181' fill='white'
                d='M746.025 196.511C745.917 197.329 745.553 198.098 744.981 198.721C744.409 199.345 743.653 199.794 742.809 200.013C741.965 200.232 741.071 200.211 740.24 199.952C739.41 199.692 738.679 199.207 738.141 198.557C737.603 197.907 737.282 197.122 737.219 196.3C737.155 195.478 737.352 194.657 737.784 193.941C738.217 193.224 738.865 192.645 739.647 192.275C740.429 191.906 741.311 191.763 742.179 191.864C742.756 191.932 743.313 192.105 743.818 192.375C744.324 192.646 744.767 193.007 745.125 193.438C745.482 193.87 745.745 194.363 745.899 194.891C746.054 195.418 746.096 195.969 746.025 196.511Z'
                opacity='.2' />
          <path id='Vector_182' fill='white'
                d='M741.946 193.034C741.608 194.3 741.589 195.942 739.29 195.852C739.925 196.254 740.643 196.626 741.034 197.323C741.397 198.041 741.588 198.836 741.59 199.642C741.81 198.909 741.724 198.188 742.277 197.48C742.478 197.218 742.735 197.005 743.03 196.858C743.324 196.711 743.647 196.634 743.976 196.631C743.299 196.133 742.658 196.117 742.325 195.184C742.194 194.83 742.093 194.466 742.024 194.095C741.961 193.72 742.067 193.166 741.866 192.874L741.946 193.034Z'
                opacity='.7' />
          <path id='Vector_183' fill='white'
                d='M716.832 225.848C716.588 224.949 716.325 225.054 716.098 225.439C714.959 224.789 715.123 229.216 716.451 228.097C716.901 227.717 716.983 226.404 716.832 225.848Z' />
        </g>;

      case 19 :
        return <g id='Mini gem_6'>
          <path id='Vector_184' fill={`#${diamondColors[color][0]}`}
                d='M563.809 370.527L598.009 402.431L572.571 426.252L563.808 371.666L531.191 408.684L566.973 460.89L572.571 426.252L563.809 370.527Z' />
          <path id='Vector_185' fill={`#${diamondColors[color][1]}`}
                d='M563.752 370.527L531.191 408.501L572.554 426.527L563.752 370.527Z' />
          <path id='Vector_186' fill='white'
                d='M563.752 370.527L562.843 371.586L571.149 424.427L532.119 407.418L531.191 408.501L572.554 426.527L563.752 370.527Z' />
          <path id='Vector_187' fill={`#${diamondColors[color][2]}`}
                d='M572.452 426.201L566.827 460.89L598.009 402.345L572.452 426.201Z' />
          <path id='Vector_188' fill={`#${diamondColors[color][2]}`}
                d='M572.452 426.201L566.827 460.89L598.009 402.345L572.452 426.201Z' />
          <path id='Vector_189' fill='white' d='M531.191 408.708L568.1 397.548L563.861 370.527L531.191 408.708Z'
                opacity='.2' />
          <path id='Vector_190' fill='white'
                d='M567.897 397.458L598.009 402.345L563.645 370.527L567.897 396.965V397.458Z' opacity='.2' />
          <path id='Vector_191' fill={`#${diamondColors[color][3]}`}
                d='M570.115 440.648C570.176 440.271 588.347 411.674 588.347 411.674L598.009 402.981L576.064 443.59L566.827 460.89L570.115 440.648Z'
                opacity='.5' />
          <path id='Vector_192' fill={`#${diamondColors[color][3]}`}
                d='M573.552 426.202L596.921 404.388L598.009 402.345L572.452 426.202L566.827 460.89L568.409 457.919L573.552 426.202Z' />
          <path id='Vector_193' fill={`#${diamondColors[color][3]}`}
                d='M543.226 426.283L571.918 426.527L531.191 408.708L543.226 426.283Z'
                opacity='.5' />
          <path id='Vector_194' fill='white'
                d='M533.1 408.047C534.355 409.333 537.442 409.515 539.19 409.85C542.831 410.637 546.585 410.77 550.274 410.245C554.743 409.525 558.254 407.559 561.724 404.794C563.989 402.989 564.885 401.214 565.835 398.647C566.116 397.992 566.337 397.312 566.495 396.618C567.638 397.223 567.487 401.095 567.814 402.384C568.458 404.92 569.16 407.383 569.425 409.976C569.928 414.266 570.653 418.528 571.597 422.745C571.775 423.607 572.336 424.723 571.353 425.158C570.574 425.504 569.189 424.824 568.517 424.579C566.524 423.85 564.983 422.594 563.063 421.688C561.198 420.807 559.227 420.338 557.345 419.456C555.354 418.524 553.407 417.68 551.492 416.598C548.56 414.943 545.305 413.649 542.238 412.355C540.697 411.704 539.078 411.09 537.612 410.42C536.452 409.89 533.868 409.278 533.221 408.165L533.1 408.047Z'
                opacity='.4' />
          <path id='Vector_195' fill='white'
                d='M578.114 436.547C584.588 432.308 586.4 423.623 582.161 417.149C577.923 410.675 569.238 408.862 562.764 413.101C556.289 417.34 554.477 426.025 558.716 432.499C562.955 438.973 571.64 440.785 578.114 436.547Z'
                opacity='.1' />
          <path id='Vector_196' fill='white'
                d='M582.019 425.595C581.763 427.656 580.902 429.594 579.544 431.165C578.186 432.736 576.392 433.87 574.39 434.422C572.388 434.974 570.268 434.92 568.297 434.266C566.325 433.613 564.592 432.39 563.316 430.751C562.04 429.113 561.278 427.133 561.127 425.062C560.977 422.991 561.443 420.921 562.469 419.115C563.494 417.309 565.032 415.848 566.888 414.916C568.744 413.984 570.834 413.623 572.895 413.88C575.658 414.223 578.172 415.65 579.883 417.847C581.595 420.044 582.363 422.831 582.019 425.595Z'
                opacity='.2' />
          <path id='Vector_197' fill='white'
                d='M572.683 417.373C571.856 420.467 571.808 424.482 566.191 424.262C567.742 425.245 569.498 426.154 570.455 427.856C571.343 429.612 571.808 431.555 571.812 433.527C572.351 431.734 572.141 429.97 573.492 428.242C573.983 427.6 574.612 427.08 575.332 426.72C576.051 426.361 576.842 426.171 577.645 426.167C575.99 424.948 574.424 424.91 573.61 422.628C573.289 421.764 573.043 420.874 572.874 419.966C572.722 419.051 572.981 417.696 572.489 416.981L572.683 417.373Z'
                opacity='.7' />
          <path id='Vector_198' fill='white'
                d='M573.714 424.97C573.317 423.688 572.888 423.837 572.518 424.385C570.662 423.458 570.929 429.772 573.093 428.176C573.826 427.636 573.96 425.764 573.714 424.97Z' />
          <path id='Vector_199' fill='white'
                d='M540.661 409.607C540.42 411.543 539.611 413.364 538.335 414.84C537.06 416.316 535.375 417.381 533.494 417.899C531.614 418.418 529.621 418.367 527.77 417.753C525.918 417.14 524.289 415.991 523.091 414.451C521.892 412.912 521.177 411.052 521.035 409.107C520.893 407.161 521.332 405.217 522.295 403.52C523.258 401.824 524.703 400.451 526.446 399.576C528.19 398.7 530.153 398.361 532.089 398.602C534.685 398.925 537.046 400.266 538.654 402.329C540.261 404.393 540.983 407.011 540.661 409.607Z'
                opacity='.1' />
          <path id='Vector_200' fill='white'
                d='M539.407 409.295C539.228 410.731 538.628 412.082 537.681 413.177C536.735 414.271 535.485 415.061 534.089 415.445C532.694 415.83 531.216 415.792 529.842 415.337C528.469 414.881 527.261 414.029 526.372 412.887C525.482 411.745 524.951 410.365 524.846 408.922C524.741 407.479 525.066 406.036 525.781 404.778C526.495 403.519 527.567 402.501 528.86 401.851C530.153 401.201 531.61 400.95 533.047 401.128C534.001 401.246 534.922 401.551 535.758 402.026C536.594 402.501 537.328 403.135 537.919 403.894C538.509 404.652 538.945 405.519 539.2 406.446C539.456 407.373 539.526 408.341 539.407 409.295Z'
                opacity='.2' />
          <path id='Vector_201' fill='white'
                d='M532.697 403.254C532.1 405.396 532.066 408.176 528.009 408.022C529.129 408.702 530.397 409.331 531.088 410.51C531.729 411.726 532.065 413.071 532.069 414.436C532.458 413.194 532.306 411.974 533.281 410.777C533.636 410.333 534.091 409.973 534.611 409.724C535.13 409.475 535.702 409.344 536.281 409.34C535.087 408.497 533.955 408.47 533.367 406.891C533.136 406.292 532.958 405.676 532.836 405.048C532.724 404.414 532.912 403.477 532.558 402.981L532.697 403.254Z'
                opacity='.7' />
          <path id='Vector_202' fill='white'
                d='M569.953 372.662C569.775 374.036 569.174 375.329 568.228 376.376C567.281 377.424 566.032 378.179 564.636 378.547C563.241 378.916 561.763 378.88 560.389 378.444C559.015 378.009 557.807 377.193 556.917 376.101C556.028 375.009 555.497 373.689 555.392 372.308C555.287 370.927 555.612 369.547 556.326 368.343C557.041 367.139 558.113 366.165 559.406 365.544C560.7 364.923 562.157 364.682 563.593 364.853C565.519 365.082 567.271 366.033 568.464 367.498C569.657 368.962 570.192 370.82 569.953 372.662Z'
                opacity='.1' />
          <path id='Vector_203' fill='white'
                d='M568.695 372.427C568.563 373.426 568.119 374.366 567.419 375.128C566.72 375.89 565.796 376.44 564.765 376.707C563.733 376.975 562.641 376.949 561.625 376.632C560.61 376.315 559.717 375.722 559.059 374.928C558.402 374.133 558.01 373.173 557.932 372.169C557.855 371.165 558.095 370.161 558.623 369.285C559.152 368.41 559.944 367.701 560.9 367.25C561.856 366.798 562.933 366.623 563.995 366.747C564.7 366.83 565.38 367.042 565.998 367.372C566.616 367.702 567.158 368.144 567.595 368.671C568.031 369.198 568.353 369.802 568.542 370.446C568.73 371.091 568.782 371.764 568.695 372.427Z'
                opacity='.2' />
          <path id='Vector_204' fill='white'
                d='M563.71 368.177C563.297 369.724 563.273 371.731 560.463 371.621C561.24 372.113 562.117 372.567 562.595 373.419C563.039 374.297 563.272 375.268 563.274 376.254C563.544 375.357 563.439 374.476 564.114 373.611C564.36 373.29 564.674 373.03 565.034 372.851C565.394 372.671 565.789 372.576 566.191 372.574C565.363 371.965 564.58 371.945 564.173 370.804C564.012 370.372 563.89 369.927 563.805 369.474C563.728 369.016 563.858 368.339 563.613 367.981L563.71 368.177Z'
                opacity='.7' />
          <path id='Vector_205' fill='white'
                d='M533.015 408.282C532.717 407.184 532.395 407.312 532.118 407.783C530.726 406.989 530.926 412.399 532.549 411.031C533.1 410.567 533.199 408.962 533.015 408.282Z' />
        </g>;

      case 20 :
        return <g id='Mini gem_17'>
          <path id='Vector_1446' fill={`#${diamondColors[color][0]}`}
                d='M511.027 747.956L539.009 774.06L518.196 793.55L511.026 748.888L484.339 779.176L513.616 821.89L518.196 793.55L511.027 747.956Z' />
          <path id='Vector_1447' fill={`#${diamondColors[color][1]}`}
                d='M510.98 747.956L484.339 779.027L518.182 793.775L510.98 747.956Z' />
          <path id='Vector_1448' fill='white'
                d='M510.981 747.956L510.237 748.823L517.033 792.056L485.099 778.14L484.339 779.027L518.182 793.775L510.981 747.956Z' />
          <path id='Vector_1449' fill={`#${diamondColors[color][2]}`}
                d='M518.098 793.508L513.496 821.89L539.009 773.99L518.098 793.508Z' />
          <path id='Vector_1450' fill={`#${diamondColors[color][2]}`}
                d='M518.098 793.508L513.496 821.89L539.009 773.99L518.098 793.508Z' />
          <path id='Vector_1451' fill='white' d='M484.339 779.196L514.538 770.065L511.07 747.956L484.339 779.196Z'
                opacity='.2' />
          <path id='Vector_1452' fill='white'
                d='M514.372 769.991L539.009 773.99L510.893 747.956L514.372 769.588V769.991Z' opacity='.2' />
          <path id='Vector_1453' fill={`#${diamondColors[color][3]}`}
                d='M516.186 805.328C516.236 805.02 531.104 781.622 531.104 781.622L539.009 774.51L521.054 807.735L513.496 821.89L516.186 805.328Z'
                opacity='.5' />
          <path id='Vector_1454' fill={`#${diamondColors[color][3]}`}
                d='M518.999 793.509L538.119 775.661L539.009 773.99L518.098 793.509L513.496 821.89L514.791 819.46L518.999 793.509Z' />
          <path id='Vector_1455' fill={`#${diamondColors[color][3]}`}
                d='M494.187 793.575L517.662 793.775L484.339 779.196L494.187 793.575Z'
                opacity='.5' />
          <path id='Vector_1456' fill='white'
                d='M485.901 778.655C486.928 779.707 489.454 779.856 490.884 780.13C493.863 780.774 496.935 780.883 499.953 780.453C503.61 779.864 506.482 778.256 509.321 775.993C511.175 774.516 511.908 773.064 512.684 770.964C512.914 770.428 513.095 769.872 513.225 769.304C514.16 769.799 514.036 772.967 514.304 774.022C514.831 776.096 515.405 778.112 515.622 780.233C516.033 783.743 516.626 787.23 517.399 790.68C517.545 791.386 518.004 792.299 517.199 792.655C516.562 792.938 515.429 792.382 514.879 792.181C513.248 791.584 511.987 790.557 510.417 789.816C508.891 789.095 507.278 788.711 505.739 787.99C504.109 787.227 502.516 786.536 500.95 785.652C498.551 784.297 495.888 783.238 493.378 782.18C492.117 781.647 490.793 781.145 489.594 780.597C488.644 780.163 486.53 779.662 486 778.752L485.901 778.655Z'
                opacity='.4' />
          <path id='Vector_1457' fill='white'
                d='M522.731 801.973C528.028 798.505 529.511 791.399 526.043 786.102C522.575 780.805 515.469 779.322 510.172 782.79C504.875 786.258 503.392 793.364 506.86 798.661C510.328 803.958 517.434 805.441 522.731 801.973Z'
                opacity='.1' />
          <path id='Vector_1458' fill='white'
                d='M525.927 793.012C525.717 794.698 525.012 796.284 523.901 797.57C522.79 798.855 521.323 799.783 519.685 800.234C518.047 800.686 516.312 800.642 514.699 800.107C513.086 799.573 511.668 798.572 510.624 797.231C509.58 795.891 508.956 794.271 508.833 792.576C508.71 790.881 509.092 789.188 509.931 787.711C510.77 786.233 512.028 785.038 513.546 784.275C515.065 783.513 516.775 783.217 518.461 783.427C520.722 783.708 522.779 784.876 524.179 786.673C525.579 788.471 526.208 790.751 525.927 793.012Z'
                opacity='.2' />
          <path id='Vector_1459' fill='white'
                d='M518.288 786.285C517.611 788.816 517.572 792.101 512.976 791.922C514.245 792.726 515.682 793.469 516.465 794.863C517.191 796.299 517.571 797.889 517.575 799.502C518.016 798.035 517.844 796.592 518.949 795.178C519.351 794.653 519.866 794.227 520.455 793.933C521.044 793.639 521.691 793.484 522.348 793.48C520.994 792.483 519.712 792.452 519.046 790.585C518.784 789.878 518.582 789.149 518.444 788.407C518.32 787.658 518.531 786.55 518.129 785.965L518.288 786.285Z'
                opacity='.7' />
          <path id='Vector_1460' fill='white'
                d='M519.131 792.501C518.806 791.452 518.455 791.574 518.153 792.022C516.634 791.264 516.853 796.43 518.623 795.124C519.223 794.682 519.332 793.15 519.131 792.501Z' />
          <path id='Vector_1461' fill='white'
                d='M492.088 779.931C491.891 781.515 491.229 783.005 490.185 784.213C489.141 785.42 487.763 786.291 486.224 786.716C484.685 787.14 483.055 787.098 481.54 786.596C480.025 786.094 478.693 785.154 477.712 783.895C476.731 782.636 476.146 781.114 476.03 779.522C475.914 777.93 476.273 776.339 477.061 774.951C477.849 773.563 479.031 772.44 480.457 771.724C481.884 771.007 483.491 770.73 485.075 770.927C487.199 771.191 489.131 772.288 490.446 773.977C491.761 775.665 492.352 777.807 492.088 779.931Z'
                opacity='.1' />
          <path id='Vector_1462' fill='white'
                d='M491.062 779.676C490.916 780.851 490.424 781.956 489.65 782.852C488.875 783.748 487.853 784.394 486.711 784.708C485.57 785.023 484.36 784.992 483.236 784.619C482.112 784.247 481.124 783.549 480.396 782.615C479.669 781.681 479.235 780.552 479.149 779.371C479.063 778.19 479.329 777.01 479.913 775.98C480.498 774.95 481.375 774.117 482.433 773.585C483.491 773.054 484.683 772.848 485.858 772.994C486.638 773.091 487.392 773.34 488.076 773.729C488.76 774.117 489.361 774.636 489.844 775.257C490.328 775.877 490.684 776.587 490.893 777.345C491.102 778.103 491.159 778.895 491.062 779.676Z'
                opacity='.2' />
          <path id='Vector_1463' fill='white'
                d='M485.572 774.733C485.083 776.486 485.056 778.76 481.736 778.634C482.653 779.191 483.69 779.706 484.255 780.67C484.78 781.665 485.055 782.765 485.058 783.882C485.376 782.866 485.252 781.868 486.05 780.889C486.34 780.525 486.712 780.231 487.138 780.027C487.563 779.823 488.03 779.716 488.505 779.713C487.527 779.023 486.602 779.001 486.12 777.709C485.931 777.219 485.785 776.715 485.685 776.201C485.594 775.683 485.748 774.915 485.458 774.51L485.572 774.733Z'
                opacity='.7' />
          <path id='Vector_1464' fill='white'
                d='M516.054 749.704C515.908 750.828 515.417 751.885 514.643 752.743C513.868 753.6 512.846 754.218 511.704 754.519C510.562 754.82 509.353 754.791 508.229 754.434C507.105 754.078 506.116 753.411 505.388 752.517C504.661 751.624 504.226 750.544 504.14 749.414C504.054 748.284 504.32 747.155 504.905 746.17C505.49 745.185 506.367 744.388 507.425 743.88C508.483 743.371 509.675 743.175 510.851 743.314C512.426 743.502 513.86 744.28 514.836 745.478C515.812 746.677 516.25 748.196 516.054 749.704Z'
                opacity='.1' />
          <path id='Vector_1465' fill='white'
                d='M515.025 749.511C514.917 750.329 514.553 751.098 513.981 751.721C513.409 752.345 512.653 752.794 511.809 753.013C510.965 753.232 510.071 753.211 509.24 752.952C508.41 752.692 507.679 752.207 507.141 751.557C506.603 750.907 506.282 750.122 506.219 749.3C506.155 748.478 506.352 747.657 506.784 746.941C507.217 746.224 507.865 745.645 508.647 745.275C509.429 744.906 510.311 744.763 511.179 744.864C511.756 744.932 512.313 745.105 512.818 745.375C513.324 745.646 513.767 746.007 514.125 746.438C514.482 746.87 514.745 747.363 514.899 747.891C515.054 748.418 515.096 748.969 515.025 749.511Z'
                opacity='.2' />
          <path id='Vector_1466' fill='white'
                d='M510.947 746.034C510.608 747.3 510.589 748.942 508.29 748.852C508.925 749.254 509.643 749.626 510.034 750.323C510.397 751.041 510.588 751.836 510.59 752.642C510.81 751.909 510.724 751.188 511.277 750.48C511.478 750.218 511.735 750.005 512.03 749.858C512.324 749.711 512.647 749.634 512.976 749.631C512.299 749.133 511.658 749.117 511.325 748.184C511.194 747.83 511.093 747.466 511.024 747.095C510.961 746.72 511.067 746.166 510.866 745.874L510.947 746.034Z'
                opacity='.7' />
          <path id='Vector_1467' fill='white'
                d='M485.832 778.848C485.588 777.949 485.325 778.054 485.098 778.439C483.959 777.789 484.123 782.216 485.451 781.097C485.901 780.717 485.983 779.404 485.832 778.848Z' />
        </g>;

      case 21 :
        return <g id='Mini gem_18'>
          <path id='Vector_1468' fill={`#${diamondColors[color][0]}`}
                d='M679.018 708.686L707 734.789L686.187 754.28L679.018 709.618L652.331 739.906L681.607 782.62L686.187 754.28L679.018 708.686Z' />
          <path id='Vector_1469' fill={`#${diamondColors[color][1]}`}
                d='M678.971 708.686L652.331 739.756L686.174 754.504L678.971 708.686Z' />
          <path id='Vector_1470' fill='white'
                d='M678.972 708.686L678.228 709.553L685.024 752.786L653.091 738.87L652.331 739.756L686.174 754.504L678.972 708.686Z' />
          <path id='Vector_1471' fill={`#${diamondColors[color][2]}`}
                d='M686.09 754.238L681.488 782.62L707 734.719L686.09 754.238Z' />
          <path id='Vector_1472' fill={`#${diamondColors[color][2]}`}
                d='M686.09 754.238L681.488 782.62L707 734.719L686.09 754.238Z' />
          <path id='Vector_1473' fill='white' d='M652.331 739.926L682.529 730.794L679.061 708.686L652.331 739.926Z'
                opacity='.2' />
          <path id='Vector_1474' fill='white' d='M682.363 730.721L707 734.719L678.884 708.686L682.363 730.317V730.721Z'
                opacity='.2' />
          <path id='Vector_1475' fill={`#${diamondColors[color][3]}`}
                d='M684.177 766.058C684.228 765.75 699.095 742.352 699.095 742.352L707 735.24L689.045 768.465L681.488 782.62L684.177 766.058Z'
                opacity='.5' />
          <path id='Vector_1476' fill={`#${diamondColors[color][3]}`}
                d='M686.99 754.238L706.11 736.391L707 734.719L686.09 754.238L681.488 782.62L682.782 780.189L686.99 754.238Z' />
          <path id='Vector_1477' fill={`#${diamondColors[color][3]}`}
                d='M662.178 754.305L685.653 754.504L652.331 739.926L662.178 754.305Z'
                opacity='.5' />
          <path id='Vector_1478' fill='white'
                d='M653.893 739.384C654.919 740.437 657.445 740.586 658.875 740.86C661.854 741.503 664.926 741.613 667.944 741.183C671.601 740.593 674.473 738.985 677.312 736.723C679.166 735.246 679.899 733.794 680.676 731.694C680.906 731.157 681.087 730.601 681.216 730.033C682.151 730.529 682.027 733.697 682.295 734.751C682.822 736.826 683.396 738.841 683.613 740.962C684.024 744.473 684.618 747.96 685.39 751.41C685.536 752.116 685.995 753.029 685.19 753.384C684.553 753.667 683.42 753.111 682.871 752.91C681.24 752.314 679.979 751.286 678.408 750.545C676.882 749.825 675.269 749.44 673.73 748.719C672.1 747.956 670.508 747.266 668.941 746.381C666.542 745.027 663.879 743.968 661.369 742.909C660.108 742.377 658.784 741.874 657.585 741.326C656.635 740.892 654.521 740.392 653.992 739.481L653.893 739.384Z'
                opacity='.4' />
          <path id='Vector_1479' fill='white'
                d='M690.722 762.702C696.019 759.234 697.502 752.129 694.034 746.831C690.566 741.534 683.46 740.052 678.163 743.52C672.866 746.988 671.383 754.094 674.851 759.391C678.32 764.688 685.425 766.17 690.722 762.702Z'
                opacity='.1' />
          <path id='Vector_1480' fill='white'
                d='M693.918 753.742C693.708 755.428 693.003 757.014 691.892 758.299C690.781 759.585 689.314 760.512 687.676 760.964C686.038 761.415 684.303 761.371 682.69 760.837C681.077 760.302 679.659 759.301 678.615 757.961C677.571 756.62 676.948 755 676.824 753.306C676.701 751.611 677.083 749.918 677.922 748.44C678.761 746.963 680.019 745.767 681.537 745.005C683.056 744.242 684.766 743.947 686.452 744.157C688.713 744.438 690.77 745.605 692.17 747.403C693.57 749.2 694.199 751.481 693.918 753.742Z'
                opacity='.2' />
          <path id='Vector_1481' fill='white'
                d='M686.279 747.014C685.602 749.546 685.563 752.831 680.967 752.651C682.237 753.455 683.673 754.199 684.456 755.592C685.182 757.029 685.563 758.618 685.567 760.231C686.007 758.764 685.835 757.322 686.941 755.907C687.343 755.382 687.857 754.957 688.446 754.663C689.035 754.369 689.682 754.214 690.339 754.209C688.985 753.213 687.704 753.181 687.038 751.315C686.775 750.608 686.573 749.879 686.436 749.137C686.311 748.388 686.522 747.279 686.12 746.694L686.279 747.014Z'
                opacity='.7' />
          <path id='Vector_1482' fill='white'
                d='M687.123 753.23C686.798 752.182 686.447 752.303 686.144 752.752C684.625 751.993 684.844 757.159 686.614 755.853C687.214 755.411 687.324 753.88 687.123 753.23Z' />
          <path id='Vector_1483' fill='white'
                d='M660.079 740.66C659.882 742.244 659.22 743.735 658.176 744.942C657.133 746.15 655.754 747.021 654.215 747.445C652.677 747.87 651.047 747.828 649.532 747.326C648.016 746.824 646.684 745.884 645.703 744.624C644.723 743.365 644.137 741.843 644.021 740.251C643.905 738.659 644.264 737.069 645.052 735.681C645.84 734.293 647.022 733.17 648.449 732.453C649.875 731.737 651.482 731.46 653.066 731.657C655.19 731.921 657.122 733.018 658.437 734.706C659.752 736.395 660.343 738.537 660.079 740.66Z'
                opacity='.1' />
          <path id='Vector_1484' fill='white'
                d='M659.053 740.405C658.907 741.58 658.415 742.686 657.641 743.581C656.867 744.477 655.844 745.123 654.702 745.438C653.561 745.752 652.352 745.721 651.228 745.349C650.104 744.976 649.115 744.279 648.388 743.344C647.66 742.41 647.226 741.281 647.14 740.1C647.054 738.919 647.32 737.739 647.904 736.709C648.489 735.68 649.366 734.846 650.424 734.315C651.482 733.783 652.674 733.577 653.849 733.723C654.63 733.82 655.383 734.07 656.067 734.458C656.751 734.846 657.352 735.366 657.835 735.986C658.319 736.607 658.675 737.316 658.884 738.075C659.093 738.833 659.15 739.625 659.053 740.405Z'
                opacity='.2' />
          <path id='Vector_1485' fill='white'
                d='M653.563 735.463C653.075 737.215 653.047 739.49 649.727 739.364C650.644 739.92 651.682 740.435 652.247 741.4C652.771 742.394 653.046 743.495 653.049 744.612C653.368 743.596 653.243 742.597 654.041 741.618C654.332 741.255 654.704 740.96 655.129 740.757C655.554 740.553 656.022 740.446 656.496 740.443C655.518 739.753 654.593 739.731 654.112 738.438C653.922 737.949 653.776 737.445 653.677 736.931C653.585 736.412 653.739 735.645 653.449 735.24L653.563 735.463Z'
                opacity='.7' />
          <path id='Vector_1486' fill='white'
                d='M684.045 710.433C683.899 711.557 683.408 712.615 682.634 713.472C681.859 714.329 680.837 714.947 679.695 715.248C678.554 715.55 677.344 715.52 676.22 715.164C675.096 714.808 674.107 714.141 673.38 713.247C672.652 712.353 672.218 711.273 672.132 710.143C672.046 709.014 672.312 707.885 672.896 706.9C673.481 705.915 674.358 705.118 675.416 704.609C676.475 704.101 677.667 703.904 678.842 704.044C680.418 704.231 681.851 705.01 682.827 706.208C683.803 707.406 684.241 708.926 684.045 710.433Z'
                opacity='.1' />
          <path id='Vector_1487' fill='white'
                d='M683.016 710.241C682.908 711.058 682.545 711.828 681.972 712.451C681.4 713.074 680.644 713.524 679.8 713.743C678.956 713.962 678.063 713.94 677.232 713.681C676.401 713.422 675.67 712.937 675.132 712.287C674.594 711.637 674.274 710.851 674.21 710.03C674.147 709.208 674.343 708.387 674.776 707.67C675.208 706.954 675.856 706.374 676.638 706.005C677.421 705.635 678.302 705.492 679.17 705.594C679.747 705.661 680.304 705.835 680.809 706.105C681.315 706.375 681.759 706.736 682.116 707.168C682.473 707.599 682.736 708.093 682.891 708.62C683.045 709.147 683.087 709.698 683.016 710.241Z'
                opacity='.2' />
          <path id='Vector_1488' fill='white'
                d='M678.938 706.763C678.599 708.029 678.58 709.672 676.281 709.582C676.916 709.984 677.634 710.356 678.025 711.052C678.389 711.77 678.579 712.565 678.581 713.372C678.801 712.638 678.715 711.917 679.268 711.21C679.469 710.947 679.726 710.735 680.021 710.588C680.315 710.441 680.639 710.363 680.967 710.361C680.29 709.862 679.649 709.847 679.316 708.913C679.185 708.56 679.084 708.195 679.015 707.824C678.952 707.45 679.059 706.896 678.858 706.603L678.938 706.763Z'
                opacity='.7' />
          <path id='Vector_1489' fill='white'
                d='M653.823 739.577C653.58 738.679 653.316 738.783 653.089 739.168C651.95 738.519 652.114 742.945 653.442 741.826C653.893 741.446 653.974 740.133 653.823 739.577Z' />
        </g>;

      case 22 :
        return <g id='Mini gem_19'>
          <path id='Vector_1490' fill={`#${diamondColors[color][0]}`}
                d='M605.571 740.091L630 762.879L611.83 779.895L605.571 740.905L582.273 767.346L607.831 804.636L611.83 779.895L605.571 740.091Z' />
          <path id='Vector_1491' fill={`#${diamondColors[color][1]}`}
                d='M605.531 740.091L582.273 767.216L611.818 780.091L605.531 740.091Z' />
          <path id='Vector_1492' fill='white'
                d='M605.531 740.091L604.882 740.848L610.815 778.591L582.936 766.442L582.273 767.216L611.818 780.091L605.531 740.091Z' />
          <path id='Vector_1493' fill={`#${diamondColors[color][2]}`}
                d='M611.745 779.858L607.727 804.636L630 762.818L611.745 779.858Z' />
          <path id='Vector_1494' fill={`#${diamondColors[color][2]}`}
                d='M611.745 779.858L607.727 804.636L630 762.818L611.745 779.858Z' />
          <path id='Vector_1495' fill='white' d='M582.273 767.364L608.636 759.392L605.609 740.091L582.273 767.364Z'
                opacity='.2' />
          <path id='Vector_1496' fill='white' d='M608.492 759.328L630 762.818L605.455 740.091L608.492 758.976V759.328Z'
                opacity='.2' />
          <path id='Vector_1497' fill={`#${diamondColors[color][3]}`}
                d='M610.076 790.178C610.119 789.908 623.099 769.482 623.099 769.482L630 763.273L614.325 792.279L607.727 804.636L610.076 790.178Z'
                opacity='.5' />
          <path id='Vector_1498' fill={`#${diamondColors[color][3]}`}
                d='M612.531 779.859L629.223 764.277L630 762.818L611.745 779.859L607.727 804.636L608.857 802.514L612.531 779.859Z' />
          <path id='Vector_1499' fill={`#${diamondColors[color][3]}`}
                d='M590.87 779.917L611.364 780.091L582.273 767.364L590.87 779.917Z'
                opacity='.5' />
          <path id='Vector_1500' fill='white'
                d='M583.636 766.891C584.533 767.81 586.738 767.94 587.986 768.179C590.587 768.741 593.269 768.836 595.904 768.461C599.096 767.947 601.604 766.543 604.082 764.567C605.7 763.278 606.34 762.01 607.018 760.177C607.219 759.709 607.377 759.223 607.49 758.727C608.307 759.16 608.198 761.926 608.432 762.846C608.892 764.658 609.394 766.417 609.583 768.269C609.942 771.333 610.46 774.377 611.134 777.389C611.262 778.006 611.662 778.803 610.96 779.113C610.404 779.36 609.415 778.875 608.935 778.699C607.511 778.179 606.41 777.282 605.039 776.635C603.707 776.006 602.299 775.67 600.955 775.041C599.532 774.375 598.142 773.772 596.774 772.999C594.679 771.817 592.355 770.892 590.164 769.968C589.063 769.504 587.906 769.065 586.86 768.586C586.031 768.208 584.185 767.771 583.723 766.976L583.636 766.891Z'
                opacity='.4' />
          <path id='Vector_1501' fill='white'
                d='M615.789 787.248C620.414 784.22 621.708 778.017 618.68 773.393C615.653 768.768 609.449 767.474 604.825 770.501C600.2 773.529 598.906 779.732 601.934 784.357C604.962 788.981 611.165 790.276 615.789 787.248Z'
                opacity='.1' />
          <path id='Vector_1502' fill='white'
                d='M618.579 779.425C618.396 780.897 617.781 782.282 616.811 783.404C615.841 784.526 614.56 785.336 613.13 785.73C611.7 786.124 610.185 786.086 608.777 785.619C607.369 785.153 606.131 784.279 605.219 783.109C604.308 781.938 603.764 780.524 603.656 779.045C603.548 777.565 603.882 776.087 604.614 774.797C605.347 773.507 606.445 772.463 607.771 771.798C609.096 771.132 610.59 770.874 612.062 771.057C614.035 771.303 615.831 772.322 617.053 773.891C618.276 775.461 618.824 777.451 618.579 779.425Z'
                opacity='.2' />
          <path id='Vector_1503' fill='white'
                d='M611.91 773.552C611.319 775.762 611.285 778.63 607.273 778.473C608.381 779.175 609.635 779.825 610.319 781.041C610.953 782.295 611.285 783.683 611.288 785.091C611.673 783.81 611.523 782.551 612.488 781.316C612.839 780.858 613.288 780.486 613.802 780.229C614.316 779.973 614.881 779.837 615.455 779.834C614.272 778.964 613.154 778.936 612.572 777.306C612.343 776.689 612.167 776.053 612.047 775.405C611.938 774.751 612.123 773.783 611.771 773.273L611.91 773.552Z'
                opacity='.7' />
          <path id='Vector_1504' fill='white'
                d='M612.647 778.979C612.363 778.063 612.057 778.17 611.793 778.561C610.466 777.899 610.658 782.409 612.203 781.269C612.727 780.883 612.822 779.546 612.647 778.979Z' />
          <path id='Vector_1505' fill='white'
                d='M589.037 768.005C588.865 769.388 588.287 770.689 587.376 771.743C586.465 772.798 585.262 773.558 583.918 773.928C582.575 774.299 581.152 774.263 579.829 773.824C578.506 773.386 577.343 772.565 576.487 771.466C575.631 770.366 575.12 769.038 575.019 767.648C574.917 766.258 575.231 764.87 575.919 763.658C576.607 762.446 577.639 761.465 578.884 760.84C580.129 760.215 581.532 759.973 582.915 760.145C584.769 760.375 586.456 761.333 587.604 762.807C588.752 764.281 589.267 766.151 589.037 768.005Z'
                opacity='.1' />
          <path id='Vector_1506' fill='white'
                d='M588.142 767.782C588.014 768.808 587.585 769.773 586.909 770.555C586.233 771.337 585.34 771.901 584.343 772.176C583.347 772.45 582.291 772.423 581.31 772.098C580.329 771.773 579.466 771.164 578.831 770.348C578.195 769.533 577.816 768.547 577.741 767.516C577.666 766.485 577.898 765.455 578.409 764.556C578.919 763.657 579.684 762.929 580.608 762.465C581.532 762.001 582.573 761.822 583.598 761.949C584.28 762.033 584.938 762.252 585.535 762.59C586.132 762.929 586.657 763.383 587.079 763.924C587.5 764.466 587.812 765.086 587.994 765.748C588.176 766.41 588.227 767.101 588.142 767.782Z'
                opacity='.2' />
          <path id='Vector_1507' fill='white'
                d='M583.349 763.467C582.922 764.998 582.898 766.983 580 766.873C580.8 767.359 581.706 767.809 582.199 768.651C582.658 769.519 582.898 770.48 582.9 771.455C583.178 770.568 583.07 769.696 583.766 768.841C584.02 768.524 584.344 768.267 584.716 768.089C585.087 767.911 585.495 767.818 585.909 767.815C585.056 767.213 584.248 767.193 583.828 766.065C583.662 765.638 583.535 765.198 583.448 764.749C583.368 764.296 583.503 763.627 583.249 763.273L583.349 763.467Z'
                opacity='.7' />
          <path id='Vector_1508' fill='white'
                d='M609.96 741.616C609.833 742.598 609.404 743.521 608.728 744.269C608.052 745.017 607.159 745.557 606.162 745.82C605.166 746.083 604.11 746.057 603.129 745.746C602.147 745.435 601.284 744.853 600.649 744.073C600.014 743.292 599.634 742.35 599.559 741.363C599.484 740.377 599.716 739.391 600.227 738.531C600.737 737.671 601.503 736.976 602.427 736.532C603.351 736.088 604.391 735.916 605.417 736.038C606.793 736.202 608.045 736.881 608.897 737.927C609.748 738.974 610.131 740.3 609.96 741.616Z'
                opacity='.1' />
          <path id='Vector_1509' fill='white'
                d='M609.061 741.448C608.967 742.162 608.65 742.834 608.15 743.378C607.651 743.922 606.991 744.314 606.254 744.506C605.518 744.697 604.737 744.678 604.012 744.452C603.286 744.225 602.649 743.802 602.179 743.234C601.709 742.667 601.429 741.981 601.374 741.264C601.318 740.547 601.49 739.83 601.868 739.204C602.245 738.579 602.811 738.073 603.494 737.75C604.177 737.427 604.946 737.303 605.704 737.392C606.208 737.45 606.694 737.602 607.135 737.838C607.576 738.073 607.964 738.389 608.276 738.765C608.587 739.142 608.817 739.573 608.952 740.033C609.087 740.494 609.124 740.975 609.061 741.448Z'
                opacity='.2' />
          <path id='Vector_1510' fill='white'
                d='M605.501 738.412C605.206 739.518 605.189 740.951 603.182 740.873C603.736 741.224 604.363 741.549 604.705 742.157C605.022 742.784 605.188 743.478 605.19 744.182C605.382 743.541 605.307 742.912 605.789 742.294C605.965 742.065 606.19 741.879 606.447 741.751C606.704 741.623 606.986 741.555 607.273 741.553C606.682 741.118 606.122 741.104 605.832 740.289C605.717 739.981 605.629 739.663 605.569 739.339C605.514 739.012 605.607 738.528 605.431 738.273L605.501 738.412Z'
                opacity='.7' />
          <path id='Vector_1511' fill='white'
                d='M583.576 767.059C583.363 766.275 583.133 766.366 582.935 766.702C581.941 766.135 582.084 770 583.243 769.023C583.636 768.691 583.707 767.545 583.576 767.059Z' />
        </g>;

      case 23 :
        return <g id='Mini gem_20'>
          <path id='Vector_1512' fill={`#${diamondColors[color][0]}`}
                d='M742.571 755.091L767 777.879L748.83 794.895L742.571 755.905L719.273 782.346L744.831 819.636L748.83 794.895L742.571 755.091Z' />
          <path id='Vector_1513' fill={`#${diamondColors[color][1]}`}
                d='M742.531 755.091L719.273 782.216L748.818 795.091L742.531 755.091Z' />
          <path id='Vector_1514' fill='white'
                d='M742.531 755.091L741.882 755.848L747.815 793.591L719.936 781.442L719.273 782.216L748.818 795.091L742.531 755.091Z' />
          <path id='Vector_1515' fill={`#${diamondColors[color][2]}`}
                d='M748.745 794.858L744.727 819.636L767 777.818L748.745 794.858Z' />
          <path id='Vector_1516' fill={`#${diamondColors[color][2]}`}
                d='M748.745 794.858L744.727 819.636L767 777.818L748.745 794.858Z' />
          <path id='Vector_1517' fill='white' d='M719.273 782.364L745.636 774.392L742.609 755.091L719.273 782.364Z'
                opacity='.2' />
          <path id='Vector_1518' fill='white' d='M745.492 774.328L767 777.818L742.455 755.091L745.492 773.976V774.328Z'
                opacity='.2' />
          <path id='Vector_1519' fill={`#${diamondColors[color][3]}`}
                d='M747.076 805.178C747.119 804.908 760.099 784.482 760.099 784.482L767 778.273L751.325 807.279L744.727 819.636L747.076 805.178Z'
                opacity='.5' />
          <path id='Vector_1520' fill={`#${diamondColors[color][3]}`}
                d='M749.531 794.859L766.223 779.277L767 777.818L748.745 794.859L744.727 819.636L745.857 817.514L749.531 794.859Z' />
          <path id='Vector_1521' fill={`#${diamondColors[color][3]}`}
                d='M727.87 794.917L748.364 795.091L719.273 782.364L727.87 794.917Z'
                opacity='.5' />
          <path id='Vector_1522' fill='white'
                d='M720.636 781.891C721.533 782.81 723.738 782.94 724.986 783.179C727.587 783.741 730.269 783.836 732.904 783.461C736.096 782.947 738.604 781.543 741.082 779.567C742.7 778.278 743.34 777.01 744.018 775.177C744.219 774.709 744.377 774.223 744.49 773.727C745.307 774.16 745.198 776.926 745.432 777.846C745.892 779.658 746.394 781.417 746.583 783.269C746.942 786.333 747.46 789.377 748.134 792.389C748.262 793.006 748.662 793.803 747.96 794.113C747.404 794.36 746.415 793.875 745.935 793.699C744.511 793.179 743.41 792.282 742.039 791.635C740.707 791.006 739.299 790.67 737.955 790.041C736.532 789.375 735.142 788.772 733.774 787.999C731.679 786.817 729.355 785.892 727.164 784.968C726.063 784.504 724.906 784.065 723.86 783.586C723.031 783.208 721.185 782.771 720.723 781.976L720.636 781.891Z'
                opacity='.4' />
          <path id='Vector_1523' fill='white'
                d='M752.789 802.248C757.414 799.22 758.708 793.017 755.68 788.393C752.653 783.768 746.449 782.474 741.825 785.501C737.2 788.529 735.906 794.732 738.934 799.357C741.962 803.981 748.165 805.276 752.789 802.248Z'
                opacity='.1' />
          <path id='Vector_1524' fill='white'
                d='M755.579 794.425C755.396 795.897 754.781 797.282 753.811 798.404C752.841 799.526 751.56 800.336 750.13 800.73C748.7 801.124 747.185 801.086 745.777 800.619C744.369 800.153 743.131 799.279 742.219 798.109C741.308 796.938 740.764 795.524 740.656 794.045C740.548 792.565 740.882 791.087 741.614 789.797C742.347 788.507 743.445 787.463 744.771 786.798C746.096 786.132 747.59 785.874 749.062 786.057C751.035 786.303 752.831 787.322 754.053 788.891C755.276 790.461 755.824 792.451 755.579 794.425Z'
                opacity='.2' />
          <path id='Vector_1525' fill='white'
                d='M748.91 788.552C748.319 790.762 748.285 793.63 744.273 793.473C745.381 794.175 746.635 794.825 747.319 796.041C747.953 797.295 748.285 798.683 748.288 800.091C748.673 798.81 748.523 797.551 749.488 796.316C749.839 795.858 750.288 795.486 750.802 795.229C751.316 794.973 751.881 794.837 752.455 794.834C751.272 793.964 750.154 793.936 749.572 792.306C749.343 791.689 749.167 791.053 749.047 790.405C748.938 789.751 749.123 788.783 748.771 788.273L748.91 788.552Z'
                opacity='.7' />
          <path id='Vector_1526' fill='white'
                d='M749.647 793.979C749.363 793.063 749.057 793.17 748.793 793.561C747.466 792.899 747.657 797.409 749.203 796.269C749.727 795.883 749.822 794.546 749.647 793.979Z' />
          <path id='Vector_1527' fill='white'
                d='M726.037 783.005C725.865 784.388 725.287 785.689 724.376 786.743C723.465 787.798 722.262 788.558 720.918 788.928C719.575 789.299 718.152 789.263 716.829 788.824C715.506 788.386 714.343 787.565 713.487 786.466C712.631 785.366 712.12 784.038 712.019 782.648C711.917 781.258 712.231 779.87 712.919 778.658C713.607 777.446 714.639 776.465 715.884 775.84C717.129 775.215 718.532 774.973 719.915 775.145C721.769 775.375 723.456 776.333 724.604 777.807C725.752 779.281 726.267 781.151 726.037 783.005Z'
                opacity='.1' />
          <path id='Vector_1528' fill='white'
                d='M725.142 782.782C725.014 783.808 724.585 784.773 723.909 785.555C723.233 786.337 722.34 786.901 721.343 787.176C720.347 787.45 719.291 787.423 718.31 787.098C717.329 786.773 716.466 786.164 715.831 785.348C715.195 784.533 714.816 783.547 714.741 782.516C714.666 781.485 714.898 780.455 715.409 779.556C715.919 778.657 716.684 777.929 717.608 777.465C718.532 777.001 719.573 776.822 720.598 776.949C721.28 777.033 721.938 777.252 722.535 777.59C723.132 777.929 723.657 778.383 724.079 778.924C724.5 779.466 724.812 780.086 724.994 780.748C725.176 781.41 725.227 782.101 725.142 782.782Z'
                opacity='.2' />
          <path id='Vector_1529' fill='white'
                d='M720.349 778.467C719.922 779.998 719.898 781.983 717 781.873C717.8 782.359 718.706 782.809 719.199 783.651C719.658 784.519 719.898 785.48 719.9 786.455C720.178 785.568 720.07 784.696 720.766 783.841C721.02 783.524 721.344 783.267 721.716 783.089C722.087 782.911 722.495 782.818 722.909 782.815C722.056 782.213 721.248 782.193 720.828 781.065C720.662 780.638 720.535 780.198 720.448 779.749C720.368 779.296 720.503 778.627 720.249 778.273L720.349 778.467Z'
                opacity='.7' />
          <path id='Vector_1530' fill='white'
                d='M746.96 756.616C746.833 757.598 746.404 758.521 745.728 759.269C745.052 760.017 744.159 760.557 743.162 760.82C742.166 761.083 741.11 761.057 740.129 760.746C739.147 760.435 738.284 759.853 737.649 759.073C737.014 758.292 736.634 757.35 736.559 756.363C736.484 755.377 736.716 754.391 737.227 753.531C737.737 752.671 738.503 751.976 739.427 751.532C740.351 751.088 741.391 750.916 742.417 751.038C743.793 751.202 745.045 751.881 745.897 752.927C746.748 753.974 747.131 755.3 746.96 756.616Z'
                opacity='.1' />
          <path id='Vector_1531' fill='white'
                d='M746.061 756.448C745.967 757.162 745.65 757.834 745.15 758.378C744.651 758.922 743.991 759.314 743.254 759.506C742.518 759.697 741.737 759.678 741.012 759.452C740.286 759.225 739.649 758.802 739.179 758.234C738.709 757.667 738.429 756.981 738.374 756.264C738.318 755.547 738.49 754.83 738.868 754.204C739.245 753.579 739.811 753.073 740.494 752.75C741.177 752.427 741.946 752.303 742.704 752.392C743.208 752.45 743.694 752.602 744.135 752.838C744.576 753.073 744.964 753.389 745.276 753.765C745.587 754.142 745.817 754.573 745.952 755.033C746.087 755.494 746.124 755.975 746.061 756.448Z'
                opacity='.2' />
          <path id='Vector_1532' fill='white'
                d='M742.501 753.412C742.206 754.518 742.189 755.951 740.182 755.873C740.736 756.224 741.363 756.549 741.705 757.157C742.022 757.784 742.188 758.478 742.19 759.182C742.382 758.541 742.307 757.912 742.789 757.294C742.965 757.065 743.19 756.879 743.447 756.751C743.704 756.623 743.986 756.555 744.273 756.553C743.682 756.118 743.122 756.104 742.832 755.289C742.717 754.981 742.629 754.663 742.569 754.339C742.514 754.012 742.607 753.528 742.431 753.273L742.501 753.412Z'
                opacity='.7' />
          <path id='Vector_1533' fill='white'
                d='M720.576 782.059C720.363 781.275 720.133 781.366 719.935 781.702C718.941 781.135 719.084 785 720.243 784.023C720.636 783.691 720.707 782.545 720.576 782.059Z' />
        </g>;

    }
  }, [index]);
  return (
    <g className='gem-wrapper'
       ref={gemRef}
       data-tooltip-id='double-click-tip'
       onClick={onGameSelect}
       onPointerOver={(e) => showTheGameInfoTooltip({ x: e.pageX, y: e.pageY, level, title })}
       onPointerOut={() => showTheGameInfoTooltip(null)}
    >
      {renderedCoreGem}
      {renderedMiniGem}
    </g>
  );
}

export default memo(Gems);