import React, { memo, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import 'src/assets/styles/editors/highlight-word.scss';
import { ButtonMotion } from '../../../../components';
import { useGameEditMechanic } from '../../../../hooks';
import { TGameMechanicContent } from '../../../../@types';
import { THighlightGame } from './types';
import { handleChange } from '../../../../helpers';
import GameNotFound from '../../../not-found';


function HighlightWordsEditor() {
  const {
    gameMechanic,
    updateGameMechanic,
  } = useGameEditMechanic();

  if (gameMechanic) {
    const onGameMechanicContentChange = (finalContent: THighlightGame) => {
      updateGameMechanic({
        ...gameMechanic,
        content: finalContent as unknown as TGameMechanicContent
      });
    };

    const { highlight, sentence } = gameMechanic.content as THighlightGame;

    const [description, setDescription] = useState<string>(sentence ?? '');
    const [descriptionSaved, setDescriptionSaved] = useState<Boolean>(false);
    const [descriptionArr, setDescriptionArr] = useState<string[]>([]);
    const [currentSelection, setCurrentSelection] = useState<number[]>([]);
    const [highlighted, setHighlighted] = useState<number[]>(highlight ?? []);

    const selectWords = ()=> {
      const currentHighlight = [...highlighted, ...currentSelection];
      setHighlighted(currentHighlight);
      setCurrentSelection([]);
      onGameMechanicContentChange({ ...gameMechanic.content as THighlightGame, highlight: currentHighlight });
    }

    const toggleSelection = (index: number) => {
      setCurrentSelection(prev => {
        if (prev.includes(index)) {
          return prev.filter(n => n !== index);
        } else {
          return [...prev, index];
        }
      });
    }

    const saveDescription = () => {
      onGameMechanicContentChange({ ...gameMechanic.content as THighlightGame, sentence: description });
      setDescriptionSaved(true);
      setDescriptionArr(description.split(' '));
    };

    const actionButtons = useMemo(() => {
      if (!descriptionSaved) return <ButtonMotion variant={'scramble'} disabled={description.length === 0}
                                                  onClick={saveDescription}>Save</ButtonMotion>;
      return <ButtonMotion variant={'scramble'}
                           onClick={selectWords}>Select
        Word</ButtonMotion>;
    }, [descriptionSaved, currentSelection, description]);


    return (
      <div className='highlight-word-editor'>
        {descriptionSaved ? <div className='sentence-to-scramble text-area-style'><p className="d-flex flex-wrap">{descriptionArr.map((word, i) =>
            <span key={`word-${i}`} onClick={() => toggleSelection(i)}
                  className={classnames({
                    selected: currentSelection.includes(i),
                    highlighted: highlighted.includes(i)
                  })}>{word}</span>)}</p></div> :
          <textarea value={description} onChange={handleChange(setDescription)}
                    placeholder='Enter here the correct sentence, then select the words desired and click on “select word” button to create a new selectable word.' />}

          {actionButtons}
      </div>
    );
  }
  return <GameNotFound />;
}

export default memo(HighlightWordsEditor);
