import { memo, ReactNode, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import DndPreview from '../dnd-preview';
import { checkIsMobile } from '../../helpers';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

interface DragItemProps {
  children: ReactNode;
  item: any,
}

/**
 * @description
 *    The drag component for the drag and drop action
 * @param { ReactNode } children
 *    The React Node child component that needs to be parsed
 * @param { any } item
 *    The item object that needs to be passed to the library
 * @constructor
 */
function DragItemComponent({ children, item }: DragItemProps){
  const isMobile=checkIsMobile();
  const [{isDragging}, dragRef, dragPreview] = useDrag(
    () => ({
      type: "div",
      item,
      collect: (monitor:any) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [item]
  )
  return (
    <div className="drag-item col-md-3 col-6 px-2">
      <span className="drag-item w-100" ref={dragRef} style={{opacity: isDragging ? 0 : 1} }>{children}</span>
      {isMobile && item.element &&  <DndPreview>{item.element} </DndPreview> }
    </div>
  )
}

export const DragItem = memo(DragItemComponent)