import { memo } from 'react';

function CloseBtn({close}:{ close: () => void}) {
  return (
    <div className='close-btn' onClick={close}>
      X
    </div>
  );
}

export default memo(CloseBtn);
