import { EnumGameMechanicCategory } from "src/@constants";
export const JumbleWordsGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.jumble_words,
  points: 100,
  content: {
    answer: '',
    filePath:'',
    letters: [],
    draggedItems: {}
  }
}
