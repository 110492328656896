import { IBox, ICell, Iword, IWordCoords } from './types';

const completeList = [    'Japan',  'Kenya', 'Kiribati', 'Kosovo', 'Kuwait', 'Kyrgyzstan',   'Lime', 'Lemon', 'Apricot',  'Mother', ];
const englishAlphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'R', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
export function getRandomWords() {
  let lst = [];
  for (let i = 0; i < 5; i++) {
    let currItem = completeList[Math.floor(Math.random() * completeList.length)];
    if (lst.indexOf(currItem) === -1) {
      lst.push(currItem);
    } else {
      i--;
    }
  }
  return lst.sort((a, b) => {
    return b.length - a.length;
  });
}


class WSGenerator {

  gridSize: number;
  gridArr: ICell[][] | string[][];
  directions: number[];
  wordList: Iword[];
  alreadyFound: string[];
  startBox: ICell | null;
  endBox: ICell | null;
  alphabets:string[]

  constructor(wordList: Iword[], alphabet = englishAlphabet) {
    this.gridSize = 0;
    this.gridArr = [];
    this.directions = [-4, -3, -2, -1, 1, 2, 3, 4];
    this.wordList = structuredClone(wordList)
    this.alreadyFound = [];
    this.startBox = null;
    this.endBox = null;
    this.alphabets = alphabet

  }


  getRandomRow() {
    return Math.floor(Math.random() * this.gridSize);
  };

  getRandomColumn() {
    return Math.floor(Math.random() * this.gridSize);
  };

  getRandomDirection() {
    return this.directions[Math.floor(Math.random() * this.directions.length)];
  };

  setGridSize() {
    let len = this.wordList.length;
    let list = this.wordList.slice();
    let currLen = len;
    for (let i = 0; i < len; i++) {
      if (list[i].text.length > currLen) {
        currLen = list[i].text.length;
      }
    }
    this.gridSize = currLen + 3;
  };


  initGrid() {
    let grid: string[][] = [];
    for (let i = 0; i < this.gridSize; i++) {
      grid[i] = [];
      for (let j = 0; j < this.gridSize; j++) {
        grid[i][j] = '$$';
      }
    }
    this.gridArr = grid.slice();
    for (let i = 0; i < this.wordList.length; i++) {
      this.populateWord(this.wordList[i].text);
    }
    this.populateUnusedBoxes();
  };

  isPlacable(word: string, start: IWordCoords, end: IWordCoords | null, direction: number) {
    let i = 0, wordLength = word.length;
    let currI = start.x, currJ = start.y;
    while (currI >= 0 && currI < this.gridSize && currJ >= 0 && currJ < this.gridSize && i < wordLength && (this.gridArr[currI][currJ] === word[i] || this.gridArr[currI][currJ] === '$$')) {
      i++;
      switch (direction) {
        case -1: {
          currJ = currJ - 1;
          break;
        }
        case 1: {
          currJ++;
          break;
        }
        case -2: {
          currI--;
          break;
        }
        case 2: {
          currI++;
          break;
        }
        case 3: {
          currI++;
          currJ++;
          break;
        }
        case -3: {
          currI--;
          currJ--;
          break;
        }
        case 4: {
          currI++;
          currJ--;
          break;
        }
        case -4: {
          currI--;
          currJ++;
          break;
        }
        default: {

        }
      }
    }
    return i === wordLength;
  };


  placeWord(word: string, start: IWordCoords, end: IWordCoords | null , direction: number) {
    let i = 0,
      wordLength = word.length;
    let currI = start.x, currJ = start.y;
    while (i < wordLength) {
      this.gridArr[currI][currJ] = {
        letter: word[i],
        id: (currI + 1) + '-cell-' + (currJ + 1),
        used: false,
        highlighted: false
      };
      i++;
      switch (direction) {
        case -1: {
          currJ = currJ - 1;
          break;
        }
        case 1: {
          currJ++;
          break;
        }
        case -2: {
          currI--;
          break;
        }
        case 2: {
          currI++;
          break;
        }
        case 3: {
          currI++;
          currJ++;
          break;
        }
        case -3: {
          currI--;
          currJ--;
          break;
        }
        case 4: {
          currI++;
          currJ--;
          break;
        }
        case -4: {
          currI--;
          currJ++;
          break;
        }
        default: {

        }
      }

    }
  };

  populateWord(word: string) {
    let start = { x: this.getRandomRow(), y: this.getRandomColumn() };
    let dir = this.getRandomDirection();

    if (this.isPlacable(word, start, null, dir)) {
      this.placeWord(word.toUpperCase(), start, null, dir);
    } else {
      this.populateWord(word);
    }
  };

  populateUnusedBoxes() {
    let indexi;
    let indexj;
    for (indexi = 0; indexi < this.gridSize; indexi++) {
      for (indexj = 0; indexj < this.gridSize; indexj++) {
        if (this.gridArr[indexi][indexj] === '$$') {
          this.gridArr[indexi][indexj] = {
            letter: this.alphabets[Math.floor(Math.random() * 25)],
            id: (indexi + 1) + '-cell-' + (indexj + 1),
            used: false,
            highlighted: false
          };
        }
      }
    }
  };



// Solver part
  getDirection(startObj: ICell, endObj: ICell) {
    let dir;
    let stRow = startObj.row!,
      stCol = startObj.col!,
      curRow = endObj.row!,
      curCol = endObj.col!;

    if (curRow === stRow && curCol !== stCol) {
      if (stCol < curCol) {
        dir = 1;
      } else {
        dir = -1;
      }
    } else if (curCol === stCol && curRow !== stRow) {
      if (stRow < curRow) {
        dir = 2;
      } else {
        dir = -2;
      }
    } else if (((curCol - stCol) === (curRow - stRow)) || ((stCol - curCol) === (stRow - curRow))) {
      if (stRow < curRow && stCol < curCol) {
        dir = 3;
      } else if (stRow > curRow && stCol > curCol) {
        dir = -3;
      }
    } else if (((stRow - curRow) === (curCol - stCol)) || ((curCol - stCol) === (curRow - curRow))) {
      if (stRow < curRow && stCol > curCol) {
        dir = 4;
      } else if (stRow > curRow && stCol < curCol) {
        dir = -4;
      }
    }
    return dir ? dir : 0;
  };

  getStringBetweenPoints(startBox: ICell, endBox: ICell) {
    let dir = this.getDirection(startBox, endBox);
    return this.getStringByRowCol(startBox, endBox, dir);
  };

  getStringByRowCol(startBox: ICell, endBox: ICell, dir: number) {
    let returnedString = '';
    let cellIds = [];
    let str = startBox.row!, stc = startBox.col!,
      enr = endBox.row!, enc = endBox.col!;

    switch (dir) {
      case -1: {
        for (let k = stc; k >= enc; k -= 1) {
          returnedString = returnedString + (this.gridArr[str][k] as ICell).letter;
          cellIds.push([str, k]);
        }
        break;
      }
      case 1: {
        for (let k = stc; k <= enc; k += 1) {
          returnedString = returnedString + (this.gridArr[str][k] as ICell).letter;
          cellIds.push([str, k]);
        }
        break;
      }
      case -2: {
        for (let k = str; k >= enr; k -= 1) {
          returnedString = returnedString + (this.gridArr[k][stc] as ICell).letter;
          cellIds.push([k, stc]);
        }
        break;
      }
      case 2: {
        for (let k = str; k <= enr; k += 1) {
          returnedString = returnedString + (this.gridArr[k][stc] as ICell).letter;
          cellIds.push([k, stc]);
        }
        break;
      }
      case -3: {
        // @ts-ignore
        for (let k = str, j = stc; k >= enr, j >= enc; k -= 1, j -= 1) {
          returnedString = returnedString + (this.gridArr[k][j] as ICell).letter;
          cellIds.push([k, j]);
        }
        break;
      }
      case 3: {
        // @ts-ignore
        for (let k = str, j = stc; k <= enr, j <= enc; k += 1, j += 1) {
          returnedString = returnedString + (this.gridArr[k][j] as ICell).letter;
          cellIds.push([k, j]);
        }
        break;
      }
      case -4: {
        // @ts-ignore
        for (let k = str, j = stc; k >= enr, j <= enc; k -= 1, j += 1) {
          returnedString = returnedString + (this.gridArr[k][j] as ICell).letter;
          cellIds.push([k, j]);
        }
        break;
      }
      case 4: {
        // @ts-ignore
        for (let k = str, j = stc; k <= enr, j >= enc; k += 1, j -= 1) {
          returnedString = returnedString + (this.gridArr[k][j] as ICell).letter;
          cellIds.push([k, j]);
        }
        break;
      }
      default: {

      }
    }
    return { str: returnedString, ids: cellIds };
  };

  TestString(testStr: string) { // match the found string with the elements of the words
    let str = testStr,
      reverseStr = '',
      matched = false,
      reverseMatched = false,
      matchFound = false,
      reverseMatchFound = false;

    for (let i = 0; i <= str.length; i += 1) {
      reverseStr = str.substring(i, i + 1) + reverseStr;
    }
    matched = this.matchString(str);
    reverseMatched = this.matchString(reverseStr);


    if (matched) {
      matchFound = this.isAlreadyFound(testStr);
    }
    if (reverseMatched) {
      reverseMatchFound = this.isAlreadyFound(reverseStr);
    }

    if (matched && !matchFound) {
      return { found: false, str: testStr, match: true };
    } else if (reverseMatched && !reverseMatchFound) {
      return { found: false, str: reverseStr, match: true };
    } else if (matchFound && reverseMatchFound) {
      return { found: true, match: false };
    } else {
      return { found: false, match: false };
    }
  };

  isAlreadyFound(str: string) {
    let count, found = false;
    for (count = 0; count < this.alreadyFound.length; count++) {
      if (str === this.alreadyFound[count]) {
        found = true;
        break;
      }
    }
    return found;
  };


  matchString(str: string) {
    let matched = false;
    for (let count = 0; count < this.wordList.length; count++) {
      if (str.toUpperCase() === this.wordList[count].text.toUpperCase()) {
        matched = true;
        break;
      }
    }
    return matched;
  };

  getBoxById(id: string) {
    let [row, col] = id.split('-cell-') as unknown as number[];
    row -= 1; // subtract for 0 based index
    col -= 1; // subtract for 0 based index
    return Object.assign({}, this.gridArr[row][col], { row: row, col: col });
  };


}

export default WSGenerator;

