import { EnumGameMechanicCategory } from "src/@constants";
export const EssayGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.essay,
  points: 100,
  content: {
    answer: '',
    question:'',
    maxLength: 2500,
    keyWords: [],
    text: null,
  }
}
