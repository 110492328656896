import { Form } from 'react-bootstrap';
import { memo,  useState } from 'react';
import { TQuizGame } from './types';
import Check from 'src/assets/images/gamification/input-checked.svg';


// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The single choice component's input properties' interface
 * @param { TQuizGame } gameData
 *    The quiz game's data
 */
interface MultiChoiceProps {
  gameData: TQuizGame,
  onAnswersSelect: (answersIndex: number[]) => void
}

/**
 * @description
 *    The Multi Choice game's body component
 */
function MultiChoice({ gameData, onAnswersSelect }: MultiChoiceProps) {
  const { options, question } = gameData;
  const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);

  /**
   * @description
   *    The method is handling the logic for
   * @param { number } selectedIndex
   *    The indexes of the user selected answer from the options.
   * @param { boolean } isSelected
   *    The indexes of the user selected answer from the options.
   */
  const handleAnswersClick = (selectedIndex: number, isSelected: boolean) => {
    let modifiedSelection = [...selectedAnswers];
    if (isSelected) {
      modifiedSelection = [
        ...selectedAnswers,
        selectedIndex
      ];
    } else {
      const index = modifiedSelection.indexOf(selectedIndex);
      if (index > -1) {
        modifiedSelection.splice(index, 1);
      }
    }

    setSelectedAnswers(modifiedSelection);
    onAnswersSelect(modifiedSelection);
  }

  return (
    <Form>
      {options.map((opt, index) => {
        return (
          <p key={`${opt.title}_${index}`} className='custom-checkbox'>
            <input
              type='checkbox'
              id={`${opt.title}_${index}`}
              name={question}
              checked={selectedAnswers.includes(index)}
              onChange={(e) => handleAnswersClick(index, e.target.checked)}
            />
            <label htmlFor={`${opt.title}_${index}`}>{opt.title} <span className='check-wrapper'><img src={Check}
                                                                                               alt='check' /></span></label>
          </p>
        );
      })}
      <p key={'final.option'}><label htmlFor={'final.option'} /></p>
    </Form>
  );
}

export default memo(MultiChoice);