import { EnumGameMechanicCategory } from "src/@constants";

export const ScrambleGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.scramble_sentence,
  points: 100,
  content: {
    sentenceArr: [],
    highlights: [],
  }
};
