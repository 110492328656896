import React, { memo, useState, useCallback, useMemo } from 'react';
import { useGameMechanic } from 'src/hooks';
import { TDragDropImageGame, TDragDropImageGameOptions } from './types';
import GameNotFound from '../../../not-found';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { checkIsMobile } from 'src/helpers';
import { DropItem, DragItem } from 'src/components';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The calculation game component
 * @constructor
 */
function DragDropImageGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints,
    points
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const {
    options,
    answers
  } = gameMechanic.content as unknown as TDragDropImageGame;

  const isMobile = checkIsMobile();
  const [completed, setCompleted] = useState<string[]>([]);

  function handleDrop(option: TDragDropImageGameOptions, c: string, index?: number) {
    // @ts-ignore
    if (gameMechanic && (option.option === c)) {
      changeCorrectAnswersPoints(points + 100);
    }

    setCompleted(prev => [...prev, option.filePath as string]);
  }

  function shuffleArray(array?: TDragDropImageGameOptions[]) {
    let newArray: TDragDropImageGameOptions[] = [];
    if (array) {
      newArray = [...array];
      for (let i = newArray.length - 1; i > 0; i--) {
        // Generate a random index from 0 to i
        const j = Math.floor(Math.random() * (i + 1));
        // Swap elements array[i] and array[j]
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
      }
    }

    return newArray;
  }

  const isCompleted = useCallback((title: string) => completed.includes(title), [completed]);
  const isGameFinished = useMemo(() => completed.length === options.length, [completed.length]);

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <div className='drag-image'>
        <div className='categories'>
          {answers.map((c, index) => (
            <DropItem
              key={c}
              onDrop={(item: TDragDropImageGameOptions) => handleDrop(item, c, index)}
            >
              <div className='category'>{c}</div>
            </DropItem>
          ))}
        </div>
        {!isGameFinished && (
          <div className='options position-relative d-flex justify-content-space-around flex-wrap px-3'>
            {shuffleArray(options).map((opt, i) => !isCompleted(opt.filePath as string) && (
              <DragItem
                item={{
                  ...opt, index: i, element: <div className='img-wrapper'>
                    <img
                      src={opt?.filePath ?? ''}
                      alt={opt.description}
                      className='img-fluid'
                    />
                  </div>
                }}
                key={`${opt.option}-${i}`}
              >
                <div className='img-wrapper'>
                  <img
                    src={opt?.filePath ?? ''}
                    alt={opt.description}
                    className='img-fluid'
                  />
                </div>
              </DragItem>
            ))}
          </div>)}
      </div>
    </DndProvider>
  );
}

export default memo(DragDropImageGame);
