import { memo } from 'react';
import { useDrag } from 'react-dnd';

function DragItem({children, obj}:any){
  const [{isDragging}, dragRef, preview] = useDrag(
    () => ({
      type: "div",
      item: obj,
      collect: (monitor:any) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [obj]
  )

  return (
     <span ref={dragRef} style={{opacity: isDragging ? 0 : 1} }>{children}</span>
  )
}

export default memo(DragItem)