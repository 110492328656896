import React, { ChangeEvent, memo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useGameEditMechanic } from 'src/hooks';
import { TEssayGame } from './types';
import GameNotFound from '../../../not-found';
import { TGameMechanicContent } from 'src/@types';
import { ButtonMotion } from 'src/components';
import 'src/assets/styles/editors/essay-game.scss'
import TinyMceEditor from '../../../../components/tiny-mce-editor';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The calculation game component
 * @constructor
 */
function EssayGameEditor() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    page,
    updateGameMechanic,
    saveUpdatedGameMechanic,
    changePage
  } = useGameEditMechanic();

  const [keyWord, setKeyWord] = useState('');

  const validateCurrentContent = () => {
    return true;
  };

  if (gameMechanic) {

    const { question, keyWords=[]} = gameMechanic.content as TEssayGame;
    const onGameMechanicContentChange = (finalContent: TEssayGame) => {
      updateGameMechanic({
        ...gameMechanic,
        content: finalContent as unknown as TGameMechanicContent
      });
    };

    /**
     * @description
     *    The method is changing the page to go to the next one
     */
    const nextPageClickHandler = () => {
      if (validateCurrentContent()) {
        saveUpdatedGameMechanic(gameMechanic.content);
        updateGameMechanic(null);
        changePage(page + 1);
      }
    };

    const handleChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      keyType: 'question' | 'answer'
    ) => {
      if (gameMechanic?.content) {
        onGameMechanicContentChange({
          ...gameMechanic?.content as TEssayGame,
          [keyType]: e.target.value,
        })
      }
    };
    const saveKeyWord = () => {
      onGameMechanicContentChange({
        ...gameMechanic.content as TEssayGame,
        keyWords: [...keyWords, keyWord]
      });
      setKeyWord('');
    };

    return (
      <div className='essay-editor'>
        <Row>
          <Col md={6}>
            <div className='keywords-input'>
              <input type='text' className='form-control' value={keyWord}
                     onChange={(e) => setKeyWord(e.target.value)} />
              <ButtonMotion variant='add-keyword' onClick={saveKeyWord}>Add keyword</ButtonMotion>
            </div>
            <div className='keywords-list'>
              <ul>
                {keyWords?.map(key=> <li key={key}>{key}</li>)}
              </ul>
            </div>
          </Col>
          <Col md={6}>
            <TinyMceEditor handleChange={(value) => onGameMechanicContentChange({
              ...gameMechanic.content as TEssayGame,
              question: value
            })} value={question} />
          </Col>
        </Row>
      </div>
    );
  }

  return <GameNotFound />;
}

export default memo(EssayGameEditor);
