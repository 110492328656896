import { TEssayGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the essay game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function essayDbToViewConverter(
  dbData: Record<string, any>
): TEssayGame {
  return {
    question: dbData.question,
    answer: dbData.answer,
    maxLength: dbData.maxLength,
    keyWords: dbData.keyWords,
    text: dbData.text ?? null,
  }
}
