import { EnumGameMechanicCategory } from "src/@constants";
export const FillInGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.fill_in_game,
  points: 100,
  content: {
    text: '',
    answers: [],
    filePath: null,
  }
}
