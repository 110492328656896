import { EnumGameMechanicCategory } from "src/@constants";
export const HotspotGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.hotspot,
  points: 100,
  content: {
    questions: [],
    answers: [],
    boats: {}
  }
}
