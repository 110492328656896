import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';
import { SetStateAction, useEffect, useState } from 'react';
import { ButtonMotion } from '../../buttons';
import { AvatarProfileType } from './index';

export type WardrobeItemType = {
  avatar_svg: string;
  shop_svg: string;
  uid: string;
  category: string;
  type: string;
  gender: string;
  price?: number;
}

interface WardrobePickerProps {
  currentStyle: AvatarProfileType,
  wardrobeItems: WardrobeItemType[],
  onSelectItem: (category: string, uid: string) => void,
}

export const wardrobeItemsCategoryList = ['tops', 'accessories', 'bottoms', 'shoes'];

export default function WardrobePicker({wardrobeItems, currentStyle, onSelectItem}: WardrobePickerProps) {
  const [currentItems, setCurrentItems] = useState<WardrobeItemType[]>(wardrobeItems ?? []);
  useEffect(() => {
    if (wardrobeItems && wardrobeItems !== currentItems) {
      setCurrentItems(wardrobeItems);
    }
  }, [wardrobeItems]);

  const parseItems = (category: string): WardrobeItemType[] => {
    if (currentStyle) {
      return currentItems?.filter((item) => item.category === category && item.gender === currentStyle.gender && currentStyle?.items?.includes(item?.uid));
    }

    return [];
  }

  const itemsByCategory: Record<string, WardrobeItemType[]> = {
    tops: parseItems('top') ?? [],
    bottoms: parseItems('bottom') ?? [],
    accessories: parseItems('accessory') ?? [],
    shoes: parseItems('shoe') ?? [],
  };

  return (
    <div>
      {wardrobeItemsCategoryList.map((category) => (
        <div className="list-item ps-4  position-relative my-2" key={`avatar_style_picker_${category}`}>
          <h4>{category}</h4>
          <WardrobeItem items={itemsByCategory[category]} onSelect={(uid: string) => onSelectItem(category, uid)} />
        </div>
      ))}
    </div>
  )
}

export function WardrobeItem({ items, onSelect }: { items: WardrobeItemType[], onSelect: (uid: string) => void }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSelectStaff = () => {
    onSelect(items[activeIndex].uid);
  }

  return (
    <div className='shop-item'>
      <div className='img-wrapper'>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className='mySwiper'
          onSlideChange={(e: { realIndex: SetStateAction<number>; }) => setActiveIndex(e.realIndex)}
        >
          {items.map((item, index) => (
            <SwiperSlide key={item.uid}>
              <img src={item.shop_svg} className='img-fluid' alt='item' />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <ButtonMotion
        variant='success'
        onClick={handleSelectStaff}
        className='buy-btn'
      >
        Select
      </ButtonMotion>
    </div>
  )
}
