import { useContext } from 'react';
import { ContextWorldsBase } from 'src/contexts';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The hook for the world component. This is containing all
 * necessary lifecycle methods and variables.
 */
const useWorld = () => {
  const context = useContext(ContextWorldsBase);

  if (!context) throw new Error(
    'World context must be use inside GameBaseProvider'
  );

  return context;
};

export default useWorld;
