import React, { useState, useEffect } from 'react';
import { showTheTimeUp } from '../../redux/slices';

interface TimerProps {
  timeInMinute?: number;
}

const Timer: React.FC<TimerProps> = ({ timeInMinute = 0 }) => {
  const [timeInSeconds, setTimeInSeconds] = useState<number>(timeInMinute * 60);

  const formatTime = (time: number): string => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${hours.toString().padStart(2, '0')}H:${minutes.toString().padStart(2, '0')}M:${seconds.toString().padStart(2, '0')}S`;
  };

  useEffect(() => {
    if (timeInMinute > 0) {
      // Only set the interval if the time is greater than 0
      if (timeInSeconds > 0) {
        const id = setInterval(() => {
          setTimeInSeconds(timeInSeconds - 1);
        }, 1000);

        // Clear the interval on unmount or when the timer reaches 0
        return () => clearInterval(id);
      } else if (timeInSeconds === 0) {
        // Alert on timer end
        showTheTimeUp(true);
      }
    }
  }, [timeInSeconds]);

  return (
    <div className='timer'>
      {formatTime(timeInSeconds)}
    </div>
  );
};

export default Timer;
