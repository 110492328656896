import React, { memo, useMemo, useRef } from 'react';
import { IGemProps } from 'src/@interfaces/skill-island-interfaces';
import { diamondColors } from 'src/helpers';
import { useGame } from 'src/hooks';

function Gems({ index, onGameSelect, color, level, title }: IGemProps) {
  const { showTheGameInfoTooltip } = useGame();
  const gemRef = useRef<SVGGElement | null>(null);
  const renderedCoreGem = useMemo(() => {
    switch (index) {
      case 0 :
        return <g id='GAME 4 GEM'>
          <path id='Vector_1102' fill={`#${diamondColors[color][0]}`}
                d='M437.527 313.49L461.133 303.39H497.045L521.03 314.384L499.826 325.377H459.994L437.527 313.49Z' />
          <path id='Vector_1103' fill={`#${diamondColors[color][1]}`}
                d='M437.527 313.49L422.193 331.444L453.312 353.08L459.994 325.377L437.527 313.49Z' />
          <path id='Vector_1104' fill={`#${diamondColors[color][1]}`}
                d='M499.826 325.377L510.09 353.08L537.986 330.826L521.03 314.384L499.826 325.377Z' />
          <path id='Vector_1105' fill={`#${diamondColors[color][2]}`}
                d='M459.994 325.377L499.98 325.718L510.09 353.08H453.312L459.994 325.377Z' />
          <path id='Vector_1106' fill={`#${diamondColors[color][2]}`}
                d='M510.09 353.08L509.464 354.346L481.701 401.312L537.986 330.826L510.09 353.08Z' />
          <path id='Vector_1107' fill={`#${diamondColors[color][2]}`}
                d='M453.312 353.08L481.701 401.312L422.193 331.444L453.312 353.08Z' />
          <path id='Vector_1108' fill={`#${diamondColors[color][1]}`}
                d='M510.09 353.08L481.701 401.312L453.312 353.08H510.09Z' />
          <path id='Vector_1109' fill={`#${diamondColors[color][3]}`}
                d='M439.282 314.405C440.544 316.299 441.653 318.343 442.71 320.205C445.615 325.324 447.411 330.986 449.658 336.403C451.147 339.979 451.968 343.512 452.399 347.333C452.604 349.153 452.778 351.09 453.148 352.942L453.312 353.059L459.994 325.367L439.282 314.405Z' />
          <path id='Vector_1110' fill={`#${diamondColors[color][4]}`}
                d='M515.57 312.095C515.242 312.244 514.903 312.383 514.544 312.511C510.541 313.915 506.077 314.288 501.91 314.841C496.911 315.512 492.005 316.055 486.945 316.055C480.972 316.055 475.05 316.406 469.077 316.055C463.155 315.703 457.099 315.991 451.259 314.756C447.821 314.032 443.695 313.362 440.308 312.308L437.476 313.511L460.004 325.601H499.836L521.041 314.607L515.57 312.106V312.095Z' />
          <path id='Vector_1111' fill='white'
                d='M499.826 322.717H459.994L440.308 312.308L437.527 313.49L459.994 325.59L499.806 325.718L521.03 314.384L518.311 313.138L499.826 322.717Z' />
          <path id='Vector_1112' fill='white'
                d='M437.527 313.49L436.552 314.639L458.546 326.271L452.255 352.335L453.312 353.08L459.994 325.377L437.527 313.49Z' />
          <path id='Vector_1113' fill={`#${diamondColors[color][2]}`}
                d='M510.603 352.899C510.603 344.129 513.836 336.658 517.91 329.124C519.655 325.899 521.718 322.919 522.909 319.375C524.038 316.023 521.862 314.873 519.101 316.321C514.359 318.8 509.741 321.333 504.948 323.77C501.786 325.377 500.206 326.122 501.52 330.028C502.423 332.721 503.644 335.381 504.773 337.999C506.764 342.618 509.094 347.791 510.593 352.186V352.899H510.603Z'
                opacity='.2' />
          <path id='Vector_1114' fill='white'
                d='M501.54 325.931L521.985 315.331L521.03 314.405L499.826 325.399L510.09 353.101L511.26 352.165L501.54 325.931Z'
                opacity='.2' />
          <g id='Group_70' fill='white'>
            <path id='Vector_1115'
                  d='M518.744 337.622C526.524 329.555 526.524 316.476 518.744 308.408C510.964 300.341 498.351 300.341 490.571 308.408C482.791 316.476 482.791 329.555 490.571 337.622C498.351 345.689 510.964 345.689 518.744 337.622Z'
                  opacity='.1' />
            <path id='Vector_1116'
                  d='M511.928 336.294C519.439 333.087 523.021 324.173 519.928 316.385C516.836 308.597 508.24 304.883 500.729 308.09C493.218 311.297 489.636 320.21 492.729 327.998C495.821 335.787 504.417 339.501 511.928 336.294Z'
                  opacity='.2' />
            <path id='Vector_1117'
                  d='M506.333 311.755C505.707 316.384 506.333 322.217 498.358 322.919C500.719 324.058 503.357 325.058 505.009 327.357C506.477 329.4 507.667 332.838 507.914 335.328C508.365 332.636 507.76 330.113 509.371 327.368C510.572 325.314 512.604 323.877 514.872 323.6C512.327 322.131 510.1 322.366 508.56 319.205C507.965 318.002 507.421 316.736 507.062 315.48C506.682 314.181 506.816 312.17 505.994 311.223L506.333 311.755Z'
                  opacity='.7' />
          </g>
          <g id='Group_71' fill='white'>
            <path id='Vector_1118'
                  d='M467.739 358.892C469.478 349.842 463.813 341.043 455.085 339.24C446.358 337.436 437.872 343.311 436.133 352.361C434.394 361.411 440.059 370.209 448.787 372.013C457.515 373.816 466 367.942 467.739 358.892Z'
                  opacity='.1' />
            <path id='Vector_1119'
                  d='M465.197 354.985C465.197 361.796 459.87 367.32 453.302 367.32C446.733 367.32 441.407 361.796 441.407 354.985C441.407 348.174 446.733 342.65 453.302 342.65C459.87 342.65 465.197 348.174 465.197 354.985Z'
                  opacity='.2' />
            <path id='Vector_1120'
                  d='M453.292 346.524C452.778 350.27 453.292 354.985 446.846 355.56C448.755 356.486 450.89 357.294 452.224 359.146C453.405 360.796 454.369 363.573 454.575 365.596C454.934 363.414 454.451 361.381 455.755 359.146C456.73 357.486 458.372 356.326 460.209 356.102C458.146 354.921 456.35 355.102 455.098 352.548C454.616 351.569 454.174 350.547 453.887 349.536C453.579 348.482 453.692 346.854 453.025 346.088L453.302 346.513L453.292 346.524Z'
                  opacity='.7' />
          </g>
          <path id='Vector_1121' fill='white'
                d='M454.79 352.899C456.422 353.538 458.064 354.421 459.101 355.027C464.889 358.422 470.339 363.893 474.527 369.203C478.601 374.386 481.29 380.123 482.399 386.732C483.015 390.361 483.364 394.054 483.713 397.715L510.09 352.888H454.79V352.899Z'
                opacity='.1' />
          <path id='Vector_1122' fill='white'
                d='M489.655 352.899C489.655 352.899 469.888 379.75 470.237 379.207C470.586 378.665 502.946 353.08 502.946 353.08L489.655 352.899Z'
                opacity='.2' />
          <path id='Vector_1123' fill='white' d='M443.829 317.172L477.236 303.39H482.44L453.62 322.217L443.829 317.172Z'
                opacity='.2' />
          <path id='Vector_1124' fill='white'
                d='M467.27 325.399L497.394 303.262L501.417 305.103L486.946 325.473L467.27 325.399Z' opacity='.2' />
        </g>;
      case 1:
        return <g id='GAME 3 GEM'>
          <path id='Vector_253' fill={`#${diamondColors[color][0]}`}
                d='M982.794 21.4699L1008.53 10.4574H1047.69L1073.84 22.4447L1050.72 34.4319H1007.29L982.794 21.4699Z' />
          <path id='Vector_254' fill={`#${diamondColors[color][1]}`}
                d='M982.794 21.4698L966.075 41.0463L1000.01 64.6378L1007.29 34.4318L982.794 21.4698Z' />
          <path id='Vector_255' fill={`#${diamondColors[color][1]}`}
                d='M1050.72 34.4318L1061.91 64.6378L1092.33 40.3732L1073.84 22.4446L1050.72 34.4318Z' />
          <path id='Vector_256' fill={`#${diamondColors[color][2]}`}
                d='M1007.29 34.4318L1050.89 34.8032L1061.91 64.6378H1000.01L1007.29 34.4318Z' />
          <path id='Vector_257' fill={`#${diamondColors[color][2]}`}
                d='M1061.91 64.6378L1061.22 66.0187L1030.96 117.228L1092.33 40.3732L1061.91 64.6378Z' />
          <path id='Vector_258' fill={`#${diamondColors[color][2]}`}
                d='M1000.01 64.6379L1030.96 117.229L966.075 41.0463L1000.01 64.6379Z' />
          <path id='Vector_259' fill={`#${diamondColors[color][1]}`}
                d='M1061.91 64.6379L1030.96 117.229L1000.01 64.6379H1061.91Z' />
          <path id='Vector_260' fill={`#${diamondColors[color][3]}`}
                d='M984.708 22.4678C986.084 24.5334 987.293 26.7614 988.445 28.7922C991.612 34.3739 993.571 40.5474 996.022 46.4539C997.644 50.353 998.54 54.2056 999.01 58.3716C999.234 60.3559 999.424 62.4679 999.827 64.487L1000.01 64.6147L1007.29 34.4203L984.708 22.4678Z' />
          <path id='Vector_261' fill={`#${diamondColors[color][4]}`}
                d='M1067.89 19.9497C1067.53 20.1122 1067.16 20.263 1066.77 20.4023C1062.41 21.934 1057.54 22.3402 1052.99 22.9436C1047.54 23.6747 1042.2 24.2665 1036.68 24.2665C1030.17 24.2665 1023.71 24.6495 1017.2 24.2665C1010.74 23.8836 1004.14 24.1969 997.768 22.8508C994.019 22.0617 989.52 21.3306 985.827 20.1818L982.738 21.4931L1007.3 34.6755H1050.73L1073.85 22.6883L1067.89 19.9613V19.9497Z' />
          <path id='Vector_262' fill='white'
                d='M1050.72 31.5308H1007.29L985.827 20.1818L982.794 21.4699L1007.29 34.6639L1050.7 34.8032L1073.84 22.4446L1070.88 21.0869L1050.72 31.5308Z' />
          <path id='Vector_263' fill='white'
                d='M982.794 21.4698L981.731 22.7231L1005.71 35.4066L998.853 63.8256L1000.01 64.6378L1007.29 34.4318L982.794 21.4698Z' />
          <path id='Vector_264' fill={`#${diamondColors[color][2]}`}
                d='M1062.47 64.4405C1062.47 54.8786 1066 46.7324 1070.44 38.5165C1072.34 35.0004 1074.59 31.7512 1075.89 27.887C1077.12 24.2316 1074.75 22.9784 1071.74 24.5565C1066.57 27.2603 1061.53 30.0222 1056.31 32.6796C1052.86 34.4318 1051.14 35.2441 1052.57 39.5029C1053.55 42.4387 1054.89 45.3398 1056.12 48.1945C1058.29 53.2308 1060.83 58.8704 1062.46 63.663V64.4405H1062.47Z'
                opacity='.2' />
          <path id='Vector_265' fill='white'
                d='M1052.59 35.0353L1074.88 23.4774L1073.84 22.4678L1050.72 34.4551L1061.91 64.6611L1063.19 63.6399L1052.59 35.0353Z'
                opacity='.2' />
          <g id='Group_21' fill='white'>
            <path id='Vector_266'
                  d='M1071.36 47.7806C1079.84 38.9844 1079.84 24.723 1071.36 15.9269C1062.87 7.13071 1049.12 7.13073 1040.64 15.9269C1032.15 24.723 1032.15 38.9844 1040.64 47.7805C1049.12 56.5767 1062.87 56.5767 1071.36 47.7806Z'
                  opacity='.1' />
            <path id='Vector_267'
                  d='M1063.92 46.342C1072.11 42.8453 1076.01 33.1263 1072.64 24.6341C1069.27 16.1419 1059.9 12.0924 1051.71 15.5891C1043.52 19.0859 1039.61 28.8048 1042.98 37.297C1046.36 45.7892 1055.73 49.8388 1063.92 46.342Z'
                  opacity='.2' />
            <path id='Vector_268'
                  d='M1057.81 19.5784C1057.12 24.6263 1057.81 30.9854 1049.11 31.7513C1051.69 32.9929 1054.56 34.0838 1056.36 36.5903C1057.96 38.8183 1059.26 42.5665 1059.53 45.2819C1060.02 42.346 1059.36 39.5958 1061.12 36.6019C1062.43 34.3622 1064.64 32.7957 1067.12 32.494C1064.34 30.8926 1061.91 31.1479 1060.24 27.7014C1059.59 26.3901 1058.99 25.0092 1058.6 23.6399C1058.19 22.2242 1058.33 20.031 1057.44 18.9982L1057.81 19.5784Z'
                  opacity='.7' />
          </g>
          <g id='Group_22' fill='white'>
            <path id='Vector_269'
                  d='M1015.74 70.9746C1017.64 61.1067 1011.46 51.513 1001.95 49.5466C992.429 47.5802 983.177 53.9856 981.28 63.8535C979.384 73.7214 985.561 83.3151 995.078 85.2815C1004.59 87.2479 1013.85 80.8425 1015.74 70.9746Z'
                  opacity='.1' />
            <path id='Vector_270'
                  d='M1012.96 66.7151C1012.96 74.1418 1007.16 80.1644 999.995 80.1644C992.832 80.1644 987.024 74.1418 987.024 66.7151C987.024 59.2883 992.832 53.2657 999.995 53.2657C1007.16 53.2657 1012.96 59.2883 1012.96 66.7151Z'
                  opacity='.2' />
            <path id='Vector_271'
                  d='M999.983 57.4896C999.424 61.5743 999.983 66.715 992.955 67.3417C995.037 68.3512 997.365 69.2331 998.819 71.2523C1000.11 73.051 1001.16 76.0797 1001.38 78.2845C1001.77 75.9056 1001.25 73.6892 1002.67 71.2523C1003.73 69.442 1005.52 68.1771 1007.53 67.9334C1005.28 66.6454 1003.32 66.8426 1001.95 64.0576C1001.43 62.99 1000.95 61.876 1000.63 60.7736C1000.3 59.6248 1000.42 57.8493 999.692 57.0138L999.995 57.478L999.983 57.4896Z'
                  opacity='.7' />
          </g>
          <path id='Vector_272' fill='white'
                d='M1001.62 64.4406C1003.4 65.1368 1005.19 66.1 1006.32 66.7614C1012.63 70.4632 1018.57 76.4278 1023.14 82.2183C1027.58 87.8696 1030.51 94.1243 1031.72 101.331C1032.39 105.288 1032.77 109.314 1033.15 113.306L1061.91 64.429H1001.62V64.4406Z'
                opacity='.1' />
          <path id='Vector_273' fill='white'
                d='M1039.63 64.4406C1039.63 64.4406 1018.08 93.7183 1018.46 93.1265C1018.84 92.5346 1054.13 64.6379 1054.13 64.6379L1039.63 64.4406Z'
                opacity='.2' />
          <path id='Vector_274' fill='white' d='M989.665 25.485L1026.09 10.4574H1031.77L1000.34 30.9854L989.665 25.485Z'
                opacity='.2' />
          <path id='Vector_275' fill='white'
                d='M1015.23 34.455L1048.07 10.3181L1052.46 12.3256L1036.68 34.5362L1015.23 34.455Z' opacity='.2' />
        </g>;
    }
  }, [index]);

  const renderedMiniGem = useMemo(() => {
    switch (index) {

      case 2 :
        return <g id='Mini gem_15'>
          <path id='Vector_1125' fill={`#${diamondColors[color][0]}`}
                d='M736.968 751.699L771 783.447L745.687 807.152L736.967 752.833L704.509 789.669L740.116 841.62L745.687 807.152L736.968 751.699Z' />
          <path id='Vector_1126' fill={`#${diamondColors[color][1]}`}
                d='M736.911 751.699L704.509 789.488L745.67 807.425L736.911 751.699Z' />
          <path id='Vector_1127' fill='white'
                d='M736.911 751.699L736.007 752.753L744.272 805.335L705.434 788.41L704.509 789.488L745.67 807.425L736.911 751.699Z' />
          <path id='Vector_1128' fill={`#${diamondColors[color][2]}`}
                d='M745.568 807.101L739.971 841.62L771 783.361L745.568 807.101Z' />
          <path id='Vector_1129' fill={`#${diamondColors[color][2]}`}
                d='M745.568 807.101L739.971 841.62L771 783.361L745.568 807.101Z' />
          <path id='Vector_1130' fill='white' d='M704.509 789.694L741.238 778.588L737.02 751.699L704.509 789.694Z'
                opacity='.2' />
          <path id='Vector_1131' fill='white' d='M741.036 778.499L771 783.361L736.805 751.699L741.036 778.008V778.499Z'
                opacity='.2' />
          <path id='Vector_1132' fill={`#${diamondColors[color][3]}`}
                d='M743.243 821.477C743.304 821.102 761.386 792.645 761.386 792.645L771 783.995L749.163 824.404L739.971 841.62L743.243 821.477Z'
                opacity='.5' />
          <path id='Vector_1133' fill={`#${diamondColors[color][3]}`}
                d='M746.664 807.101L769.917 785.394L771 783.361L745.568 807.101L739.971 841.62L741.545 838.663L746.664 807.101Z' />
          <path id='Vector_1134' fill={`#${diamondColors[color][3]}`}
                d='M716.486 807.182L745.037 807.425L704.509 789.694L716.486 807.182Z' opacity='.5' />
          <path id='Vector_1135' fill='white'
                d='M706.409 789.035C707.658 790.315 710.73 790.496 712.469 790.83C716.092 791.613 719.828 791.746 723.499 791.223C727.947 790.506 731.44 788.55 734.893 785.798C737.147 784.002 738.039 782.236 738.984 779.682C739.263 779.029 739.483 778.353 739.641 777.662C740.778 778.265 740.627 782.118 740.953 783.401C741.594 785.924 742.292 788.375 742.556 790.955C743.057 795.224 743.778 799.465 744.717 803.661C744.895 804.52 745.453 805.63 744.474 806.062C743.7 806.407 742.322 805.731 741.653 805.486C739.669 804.761 738.136 803.511 736.226 802.61C734.37 801.733 732.408 801.266 730.536 800.389C728.554 799.461 726.617 798.621 724.711 797.545C721.794 795.898 718.555 794.61 715.502 793.322C713.969 792.675 712.358 792.064 710.9 791.397C709.745 790.87 707.173 790.261 706.53 789.153L706.409 789.035Z'
                opacity='.4' />
          <path id='Vector_1136' fill='white'
                d='M751.203 817.396C757.645 813.178 759.448 804.535 755.23 798.093C751.012 791.65 742.37 789.847 735.928 794.065C729.485 798.283 727.682 806.925 731.9 813.368C736.118 819.81 744.76 821.614 751.203 817.396Z'
                opacity='.1' />
          <path id='Vector_1137' fill='white'
                d='M755.089 806.497C754.834 808.548 753.977 810.477 752.626 812.04C751.274 813.604 749.49 814.732 747.497 815.281C745.505 815.83 743.395 815.777 741.433 815.126C739.472 814.476 737.747 813.259 736.477 811.629C735.207 809.998 734.449 808.028 734.299 805.967C734.149 803.906 734.614 801.847 735.634 800.05C736.655 798.252 738.185 796.798 740.032 795.871C741.878 794.944 743.959 794.585 746.009 794.84C748.759 795.181 751.261 796.602 752.964 798.788C754.666 800.974 755.431 803.747 755.089 806.497Z'
                opacity='.2' />
          <path id='Vector_1138' fill='white'
                d='M745.798 798.315C744.975 801.394 744.928 805.39 739.338 805.171C740.882 806.149 742.629 807.053 743.581 808.748C744.464 810.495 744.927 812.428 744.932 814.39C745.468 812.606 745.259 810.851 746.603 809.131C747.092 808.493 747.718 807.975 748.434 807.617C749.15 807.26 749.937 807.071 750.736 807.066C749.089 805.854 747.531 805.816 746.721 803.545C746.402 802.686 746.157 801.799 745.989 800.897C745.837 799.985 746.095 798.637 745.605 797.926L745.798 798.315Z'
                opacity='.7' />
          <path id='Vector_1139' fill='white'
                d='M746.825 805.876C746.429 804.6 746.002 804.748 745.635 805.293C743.787 804.371 744.053 810.654 746.206 809.066C746.936 808.528 747.069 806.665 746.825 805.876Z' />
          <path id='Vector_1140' fill='white'
                d='M713.933 790.588C713.694 792.514 712.888 794.326 711.619 795.795C710.35 797.264 708.673 798.323 706.802 798.839C704.93 799.355 702.948 799.305 701.105 798.694C699.262 798.084 697.642 796.94 696.449 795.409C695.256 793.877 694.544 792.026 694.403 790.09C694.262 788.154 694.699 786.219 695.657 784.531C696.616 782.843 698.053 781.477 699.788 780.606C701.523 779.735 703.477 779.397 705.404 779.637C707.987 779.958 710.337 781.292 711.936 783.346C713.536 785.4 714.254 788.004 713.933 790.588Z'
                opacity='.1' />
          <path id='Vector_1141' fill='white'
                d='M712.686 790.277C712.508 791.706 711.91 793.051 710.968 794.14C710.026 795.229 708.782 796.015 707.394 796.398C706.006 796.78 704.535 796.743 703.168 796.29C701.801 795.836 700.599 794.988 699.714 793.852C698.829 792.716 698.301 791.343 698.196 789.906C698.092 788.47 698.415 787.035 699.126 785.782C699.837 784.53 700.903 783.516 702.19 782.87C703.477 782.223 704.927 781.973 706.356 782.15C707.306 782.268 708.222 782.572 709.054 783.044C709.886 783.516 710.617 784.148 711.205 784.903C711.792 785.657 712.226 786.52 712.48 787.442C712.734 788.365 712.804 789.328 712.686 790.277Z'
                opacity='.2' />
          <path id='Vector_1142' fill='white'
                d='M706.009 784.266C705.414 786.398 705.38 789.164 701.343 789.011C702.458 789.687 703.72 790.314 704.407 791.487C705.045 792.696 705.38 794.035 705.383 795.393C705.771 794.157 705.62 792.943 706.59 791.752C706.943 791.31 707.395 790.952 707.913 790.704C708.43 790.457 708.998 790.326 709.575 790.323C708.386 789.484 707.261 789.457 706.676 787.885C706.445 787.29 706.268 786.676 706.147 786.051C706.035 785.421 706.223 784.488 705.87 783.995L706.009 784.266Z'
                opacity='.7' />
          <path id='Vector_1143' fill='white'
                d='M743.082 753.824C742.904 755.192 742.307 756.478 741.365 757.52C740.423 758.563 739.18 759.314 737.791 759.681C736.403 760.047 734.932 760.011 733.564 759.578C732.197 759.145 730.995 758.333 730.11 757.246C729.225 756.159 728.697 754.846 728.592 753.472C728.487 752.098 728.811 750.725 729.522 749.527C730.233 748.329 731.3 747.359 732.587 746.741C733.874 746.123 735.324 745.883 736.753 746.053C738.67 746.281 740.413 747.228 741.6 748.685C742.787 750.143 743.32 751.991 743.082 753.824Z'
                opacity='.1' />
          <path id='Vector_1144' fill='white'
                d='M741.83 753.59C741.698 754.585 741.257 755.52 740.561 756.278C739.864 757.036 738.945 757.583 737.919 757.849C736.893 758.116 735.805 758.09 734.795 757.774C733.784 757.459 732.896 756.869 732.242 756.078C731.587 755.288 731.197 754.333 731.12 753.333C731.043 752.334 731.282 751.335 731.808 750.464C732.333 749.593 733.122 748.888 734.073 748.438C735.025 747.989 736.096 747.815 737.153 747.939C737.854 748.021 738.532 748.232 739.146 748.56C739.761 748.889 740.301 749.328 740.735 749.853C741.169 750.377 741.49 750.978 741.677 751.619C741.865 752.26 741.917 752.93 741.83 753.59Z'
                opacity='.2' />
          <path id='Vector_1145' fill='white'
                d='M736.87 749.361C736.458 750.9 736.435 752.898 733.639 752.788C734.411 753.278 735.284 753.73 735.76 754.577C736.202 755.451 736.434 756.417 736.436 757.398C736.704 756.506 736.599 755.629 737.271 754.768C737.516 754.449 737.829 754.191 738.187 754.012C738.545 753.833 738.939 753.739 739.338 753.736C738.515 753.13 737.735 753.111 737.33 751.976C737.17 751.546 737.048 751.103 736.964 750.651C736.887 750.196 737.017 749.522 736.772 749.166L736.87 749.361Z'
                opacity='.7' />
          <path id='Vector_1146' fill='white'
                d='M706.325 789.27C706.028 788.177 705.708 788.304 705.432 788.772C704.047 787.982 704.246 793.366 705.861 792.005C706.409 791.543 706.508 789.947 706.325 789.27Z' />
        </g>;
      case 3:
        return <g id='Mini gem_16'>
          <path id='Vector_1147' fill={`#${diamondColors[color][0]}`}
                d='M903.166 773.938L938.622 807.013L912.251 831.71L903.166 775.118L869.35 813.496L906.447 867.62L912.251 831.71L903.166 773.938Z' />
          <path id='Vector_1148' fill={`#${diamondColors[color][1]}`}
                d='M903.107 773.938L869.35 813.307L912.233 831.994L903.107 773.938Z' />
          <path id='Vector_1149' fill='white'
                d='M903.108 773.938L902.166 775.036L910.777 829.817L870.313 812.184L869.35 813.307L912.233 831.994L903.108 773.938Z' />
          <path id='Vector_1150' fill={`#${diamondColors[color][2]}`}
                d='M912.127 831.656L906.295 867.62L938.622 806.924L912.127 831.656Z' />
          <path id='Vector_1151' fill={`#${diamondColors[color][2]}`}
                d='M912.127 831.656L906.295 867.62L938.622 806.924L912.127 831.656Z' />
          <path id='Vector_1152' fill='white' d='M869.35 813.522L907.615 801.951L903.221 773.938L869.35 813.522Z'
                opacity='.2' />
          <path id='Vector_1153' fill='white'
                d='M907.405 801.858L938.623 806.924L902.997 773.938L907.405 801.347V801.858Z' opacity='.2' />
          <path id='Vector_1154' fill={`#${diamondColors[color][3]}`}
                d='M909.704 846.634C909.767 846.243 928.606 816.596 928.606 816.596L938.622 807.584L915.872 849.684L906.295 867.62L909.704 846.634Z'
                opacity='.5' />
          <path id='Vector_1155' fill={`#${diamondColors[color][3]}`}
                d='M913.268 831.657L937.495 809.042L938.622 806.924L912.127 831.657L906.295 867.62L907.935 864.54L913.268 831.657Z' />
          <path id='Vector_1156' fill={`#${diamondColors[color][3]}`}
                d='M881.828 831.742L911.573 831.994L869.35 813.522L881.828 831.742Z' opacity='.5' />
          <path id='Vector_1157' fill='white'
                d='M871.33 812.835C872.631 814.169 875.831 814.358 877.643 814.705C881.418 815.521 885.31 815.659 889.134 815.114C893.768 814.368 897.408 812.33 901.005 809.463C903.354 807.592 904.282 805.752 905.267 803.091C905.558 802.411 905.787 801.707 905.952 800.987C907.136 801.615 906.979 805.629 907.319 806.965C907.986 809.594 908.714 812.147 908.989 814.835C909.51 819.283 910.262 823.701 911.24 828.073C911.425 828.968 912.007 830.125 910.987 830.575C910.18 830.934 908.745 830.229 908.048 829.975C905.981 829.219 904.383 827.917 902.394 826.978C900.46 826.065 898.416 825.578 896.465 824.664C894.401 823.697 892.383 822.822 890.398 821.701C887.358 819.985 883.983 818.643 880.803 817.302C879.206 816.627 877.527 815.991 876.008 815.296C874.805 814.747 872.126 814.112 871.455 812.958L871.33 812.835Z'
                opacity='.4' />
          <path id='Vector_1158' fill='white'
                d='M917.997 842.382C924.709 837.988 926.588 828.984 922.193 822.272C917.799 815.56 908.795 813.681 902.083 818.076C895.371 822.47 893.492 831.474 897.887 838.186C902.281 844.898 911.285 846.777 917.997 842.382Z'
                opacity='.1' />
          <path id='Vector_1159' fill='white'
                d='M922.046 831.028C921.78 833.165 920.887 835.174 919.479 836.803C918.072 838.432 916.212 839.607 914.137 840.179C912.061 840.751 909.863 840.695 907.819 840.018C905.775 839.341 903.979 838.073 902.656 836.374C901.333 834.676 900.543 832.623 900.387 830.476C900.23 828.328 900.714 826.183 901.777 824.311C902.84 822.438 904.435 820.923 906.359 819.957C908.283 818.991 910.45 818.617 912.586 818.883C915.451 819.239 918.058 820.719 919.832 822.996C921.606 825.274 922.402 828.163 922.046 831.028Z'
                opacity='.2' />
          <path id='Vector_1160' fill='white'
                d='M912.367 822.504C911.509 825.711 911.46 829.874 905.636 829.646C907.245 830.665 909.065 831.607 910.057 833.373C910.977 835.193 911.459 837.207 911.464 839.251C912.022 837.392 911.804 835.564 913.205 833.772C913.715 833.107 914.367 832.568 915.113 832.195C915.859 831.822 916.679 831.626 917.511 831.621C915.795 830.358 914.172 830.318 913.328 827.953C912.995 827.057 912.74 826.134 912.565 825.193C912.407 824.244 912.675 822.839 912.165 822.098L912.367 822.504Z'
                opacity='.7' />
          <path id='Vector_1161' fill='white'
                d='M913.436 830.38C913.024 829.051 912.579 829.206 912.196 829.773C910.271 828.813 910.549 835.359 912.791 833.704C913.552 833.144 913.69 831.203 913.436 830.38Z' />
          <path id='Vector_1162' fill='white'
                d='M879.168 814.453C878.919 816.46 878.08 818.348 876.757 819.878C875.435 821.408 873.688 822.512 871.739 823.05C869.789 823.587 867.724 823.535 865.804 822.899C863.884 822.262 862.196 821.071 860.953 819.476C859.71 817.88 858.969 815.952 858.822 813.934C858.675 811.917 859.129 809.902 860.128 808.143C861.126 806.384 862.624 804.961 864.432 804.053C866.239 803.146 868.275 802.795 870.282 803.044C872.973 803.379 875.421 804.769 877.088 806.908C878.754 809.048 879.503 811.761 879.168 814.453Z'
                opacity='.1' />
          <path id='Vector_1163' fill='white'
                d='M877.869 814.129C877.683 815.618 877.061 817.019 876.079 818.154C875.098 819.289 873.802 820.107 872.356 820.506C870.909 820.905 869.377 820.865 867.953 820.393C866.529 819.921 865.276 819.037 864.354 817.854C863.432 816.67 862.882 815.239 862.773 813.743C862.664 812.246 863.001 810.751 863.742 809.446C864.483 808.142 865.594 807.086 866.934 806.412C868.275 805.739 869.786 805.478 871.275 805.663C872.264 805.785 873.219 806.102 874.085 806.594C874.952 807.086 875.713 807.744 876.326 808.53C876.938 809.316 877.39 810.215 877.654 811.176C877.919 812.137 877.992 813.141 877.869 814.129Z'
                opacity='.2' />
          <path id='Vector_1164' fill='white'
                d='M870.913 807.867C870.293 810.087 870.258 812.969 866.052 812.81C867.213 813.515 868.528 814.167 869.244 815.39C869.909 816.65 870.257 818.044 870.261 819.459C870.664 818.172 870.507 816.907 871.518 815.666C871.886 815.206 872.357 814.832 872.896 814.574C873.435 814.316 874.027 814.18 874.628 814.177C873.39 813.303 872.217 813.275 871.607 811.637C871.367 811.017 871.182 810.378 871.056 809.727C870.94 809.07 871.136 808.098 870.768 807.584L870.913 807.867Z'
                opacity='.7' />
          <path id='Vector_1165' fill='white'
                d='M909.536 776.152C909.351 777.576 908.729 778.916 907.748 780.002C906.767 781.088 905.471 781.871 904.024 782.253C902.578 782.635 901.045 782.597 899.621 782.146C898.196 781.694 896.944 780.849 896.022 779.717C895.1 778.584 894.549 777.216 894.44 775.784C894.331 774.353 894.669 772.922 895.409 771.674C896.15 770.426 897.261 769.416 898.602 768.772C899.943 768.128 901.454 767.878 902.943 768.055C904.94 768.293 906.756 769.279 907.993 770.798C909.229 772.316 909.784 774.242 909.536 776.152Z'
                opacity='.1' />
          <path id='Vector_1166' fill='white'
                d='M908.232 775.908C908.095 776.944 907.635 777.918 906.91 778.708C906.184 779.498 905.227 780.068 904.158 780.345C903.088 780.623 901.956 780.595 900.903 780.267C899.85 779.939 898.924 779.324 898.243 778.5C897.561 777.676 897.155 776.681 897.074 775.64C896.994 774.599 897.243 773.559 897.791 772.651C898.338 771.743 899.16 771.009 900.151 770.54C901.142 770.072 902.259 769.891 903.359 770.02C904.09 770.105 904.796 770.325 905.436 770.667C906.077 771.009 906.639 771.467 907.091 772.014C907.544 772.561 907.878 773.186 908.073 773.854C908.269 774.522 908.323 775.22 908.232 775.908Z'
                opacity='.2' />
          <path id='Vector_1167' fill='white'
                d='M903.065 771.501C902.636 773.105 902.611 775.187 899.698 775.072C900.503 775.582 901.413 776.053 901.908 776.936C902.369 777.846 902.61 778.853 902.612 779.875C902.892 778.946 902.783 778.032 903.483 777.135C903.738 776.803 904.064 776.533 904.437 776.347C904.81 776.161 905.22 776.063 905.636 776.06C904.778 775.428 903.966 775.408 903.544 774.226C903.378 773.778 903.25 773.316 903.163 772.846C903.083 772.371 903.218 771.669 902.963 771.299L903.065 771.501Z'
                opacity='.7' />
          <path id='Vector_1168' fill='white'
                d='M871.242 813.08C870.933 811.941 870.599 812.074 870.312 812.562C868.869 811.739 869.076 817.348 870.759 815.93C871.33 815.448 871.433 813.785 871.242 813.08Z' />
        </g>;

      case 4 :
        return <g id='Mini gem_17'>
          <path id='Vector_1169' fill={`#${diamondColors[color][0]}`}
                d='M813.372 857.938L848.828 891.013L822.456 915.71L813.371 859.118L779.556 897.496L816.652 951.62L822.456 915.71L813.372 857.938Z' />
          <path id='Vector_1170' fill={`#${diamondColors[color][1]}`}
                d='M813.313 857.938L779.556 897.307L822.438 915.994L813.313 857.938Z' />
          <path id='Vector_1171' fill='white'
                d='M813.313 857.938L812.371 859.036L820.982 913.817L780.519 896.184L779.556 897.307L822.438 915.994L813.313 857.938Z' />
          <path id='Vector_1172' fill={`#${diamondColors[color][2]}`}
                d='M822.332 915.657L816.501 951.62L848.828 890.924L822.332 915.657Z' />
          <path id='Vector_1173' fill={`#${diamondColors[color][2]}`}
                d='M822.332 915.657L816.501 951.62L848.828 890.924L822.332 915.657Z' />
          <path id='Vector_1174' fill='white' d='M779.556 897.522L817.82 885.951L813.426 857.938L779.556 897.522Z'
                opacity='.2' />
          <path id='Vector_1175' fill='white'
                d='M817.61 885.858L848.828 890.924L813.202 857.938L817.61 885.347V885.858Z' opacity='.2' />
          <path id='Vector_1176' fill={`#${diamondColors[color][3]}`}
                d='M819.909 930.634C819.973 930.243 838.812 900.596 838.812 900.596L848.828 891.584L826.077 933.684L816.501 951.62L819.909 930.634Z'
                opacity='.5' />
          <path id='Vector_1177' fill={`#${diamondColors[color][3]}`}
                d='M823.473 915.657L847.7 893.042L848.828 890.924L822.332 915.657L816.501 951.62L818.141 948.54L823.473 915.657Z' />
          <path id='Vector_1178' fill={`#${diamondColors[color][3]}`}
                d='M792.033 915.742L821.779 915.994L779.556 897.522L792.033 915.742Z' opacity='.5' />
          <path id='Vector_1179' fill='white'
                d='M781.535 896.835C782.836 898.169 786.037 898.358 787.848 898.705C791.623 899.521 795.515 899.659 799.34 899.114C803.973 898.368 807.613 896.33 811.21 893.463C813.559 891.592 814.488 889.752 815.472 887.091C815.763 886.411 815.993 885.707 816.157 884.987C817.342 885.615 817.185 889.629 817.524 890.965C818.192 893.594 818.919 896.147 819.194 898.835C819.715 903.283 820.467 907.702 821.446 912.073C821.631 912.968 822.212 914.125 821.193 914.575C820.385 914.934 818.95 914.229 818.253 913.975C816.187 913.219 814.589 911.917 812.599 910.978C810.666 910.065 808.622 909.578 806.671 908.664C804.606 907.697 802.588 906.822 800.603 905.701C797.563 903.985 794.189 902.643 791.009 901.302C789.411 900.628 787.733 899.991 786.213 899.296C785.01 898.747 782.331 898.112 781.66 896.958L781.535 896.835Z'
                opacity='.4' />
          <path id='Vector_1180' fill='white'
                d='M828.202 926.382C834.914 921.988 836.793 912.984 832.398 906.272C828.004 899.56 819 897.681 812.288 902.076C805.576 906.47 803.698 915.474 808.092 922.186C812.487 928.898 821.49 930.777 828.202 926.382Z'
                opacity='.1' />
          <path id='Vector_1181' fill='white'
                d='M832.251 915.028C831.986 917.165 831.093 919.174 829.685 920.803C828.277 922.432 826.418 923.607 824.342 924.179C822.267 924.751 820.068 924.695 818.024 924.018C815.981 923.341 814.184 922.073 812.861 920.374C811.538 918.676 810.748 916.623 810.592 914.476C810.436 912.328 810.92 910.183 811.983 908.311C813.046 906.438 814.64 904.923 816.564 903.957C818.488 902.991 820.655 902.617 822.792 902.883C825.657 903.239 828.263 904.719 830.037 906.996C831.811 909.274 832.607 912.163 832.251 915.028Z'
                opacity='.2' />
          <path id='Vector_1182' fill='white'
                d='M822.572 906.504C821.714 909.712 821.665 913.874 815.841 913.646C817.45 914.665 819.27 915.608 820.262 917.373C821.182 919.193 821.664 921.207 821.669 923.251C822.228 921.392 822.01 919.564 823.41 917.772C823.92 917.107 824.572 916.568 825.318 916.195C826.064 915.822 826.884 915.626 827.716 915.621C826.001 914.358 824.377 914.318 823.533 911.953C823.2 911.057 822.945 910.134 822.77 909.193C822.612 908.244 822.88 906.839 822.371 906.098L822.572 906.504Z'
                opacity='.7' />
          <path id='Vector_1183' fill='white'
                d='M823.641 914.38C823.229 913.051 822.785 913.206 822.401 913.773C820.476 912.813 820.754 919.359 822.997 917.704C823.757 917.144 823.896 915.203 823.641 914.38Z' />
          <path id='Vector_1184' fill='white'
                d='M789.374 898.453C789.124 900.46 788.285 902.348 786.963 903.878C785.64 905.408 783.894 906.512 781.944 907.05C779.994 907.587 777.929 907.535 776.009 906.899C774.089 906.262 772.401 905.071 771.158 903.476C769.916 901.88 769.174 899.952 769.027 897.934C768.88 895.917 769.335 893.902 770.333 892.143C771.332 890.384 772.83 888.961 774.637 888.053C776.444 887.146 778.48 886.795 780.487 887.044C783.179 887.379 785.627 888.769 787.293 890.908C788.96 893.048 789.708 895.762 789.374 898.453Z'
                opacity='.1' />
          <path id='Vector_1185' fill='white'
                d='M788.074 898.129C787.889 899.618 787.266 901.019 786.285 902.154C785.303 903.289 784.007 904.107 782.561 904.506C781.114 904.905 779.582 904.866 778.158 904.393C776.734 903.921 775.482 903.037 774.56 901.854C773.638 900.67 773.087 899.239 772.978 897.743C772.869 896.246 773.207 894.751 773.947 893.446C774.688 892.142 775.799 891.086 777.14 890.412C778.48 889.739 779.991 889.478 781.48 889.663C782.469 889.785 783.424 890.102 784.291 890.594C785.157 891.086 785.919 891.744 786.531 892.53C787.143 893.316 787.595 894.215 787.86 895.176C788.124 896.137 788.197 897.141 788.074 898.129Z'
                opacity='.2' />
          <path id='Vector_1186' fill='white'
                d='M781.118 891.867C780.499 894.088 780.463 896.969 776.257 896.81C777.418 897.515 778.733 898.168 779.449 899.39C780.114 900.65 780.463 902.044 780.466 903.459C780.87 902.172 780.712 900.907 781.723 899.666C782.091 899.206 782.563 898.832 783.101 898.574C783.64 898.317 784.233 898.181 784.834 898.177C783.595 897.303 782.422 897.275 781.812 895.637C781.572 895.017 781.388 894.378 781.261 893.727C781.145 893.07 781.341 892.098 780.973 891.584L781.118 891.867Z'
                opacity='.7' />
          <path id='Vector_1187' fill='white'
                d='M819.742 860.152C819.557 861.576 818.934 862.916 817.953 864.002C816.972 865.088 815.676 865.871 814.23 866.253C812.783 866.635 811.251 866.597 809.826 866.146C808.402 865.694 807.149 864.849 806.227 863.717C805.305 862.584 804.755 861.216 804.646 859.784C804.537 858.353 804.874 856.922 805.615 855.674C806.356 854.426 807.467 853.416 808.808 852.772C810.149 852.128 811.659 851.878 813.148 852.055C815.145 852.293 816.962 853.279 818.198 854.798C819.434 856.316 819.99 858.242 819.742 860.152Z'
                opacity='.1' />
          <path id='Vector_1188' fill='white'
                d='M818.437 859.908C818.3 860.944 817.84 861.918 817.115 862.708C816.39 863.498 815.432 864.068 814.363 864.345C813.294 864.623 812.161 864.595 811.108 864.267C810.055 863.939 809.13 863.324 808.448 862.5C807.766 861.677 807.36 860.681 807.279 859.64C807.199 858.599 807.448 857.559 807.996 856.651C808.544 855.743 809.365 855.009 810.356 854.54C811.348 854.072 812.464 853.891 813.565 854.02C814.296 854.105 815.001 854.325 815.642 854.667C816.282 855.009 816.844 855.467 817.297 856.014C817.749 856.561 818.083 857.186 818.279 857.854C818.474 858.522 818.528 859.22 818.437 859.908Z'
                opacity='.2' />
          <path id='Vector_1189' fill='white'
                d='M813.27 855.501C812.841 857.105 812.817 859.187 809.904 859.072C810.708 859.582 811.618 860.053 812.114 860.936C812.574 861.846 812.815 862.853 812.818 863.875C813.097 862.946 812.988 862.032 813.688 861.135C813.943 860.803 814.269 860.533 814.642 860.347C815.015 860.161 815.425 860.063 815.841 860.06C814.984 859.428 814.171 859.409 813.749 858.226C813.583 857.778 813.456 857.316 813.368 856.846C813.288 856.371 813.423 855.669 813.168 855.299L813.27 855.501Z'
                opacity='.7' />
          <path id='Vector_1190' fill='white'
                d='M781.447 897.08C781.138 895.941 780.805 896.074 780.517 896.562C779.074 895.739 779.282 901.348 780.964 899.93C781.535 899.448 781.638 897.785 781.447 897.08Z' />
        </g>;

      case 5 :
        return <g id='Mini gem_18'>
          <path id='Vector_1191' fill={`#${diamondColors[color][0]}`}
                d='M563.253 818.13L605.828 857.846L574.162 887.5L563.253 819.548L522.648 865.63L567.192 930.62L574.162 887.5L563.253 818.13Z' />
          <path id='Vector_1192' fill={`#${diamondColors[color][1]}`}
                d='M563.182 818.13L522.648 865.403L574.14 887.842L563.182 818.13Z' />
          <path id='Vector_1193' fill='white'
                d='M563.183 818.13L562.052 819.448L572.391 885.228L523.805 864.055L522.648 865.403L574.14 887.842L563.183 818.13Z' />
          <path id='Vector_1194' fill={`#${diamondColors[color][2]}`}
                d='M574.013 887.436L567.011 930.62L605.828 857.739L574.013 887.436Z' />
          <path id='Vector_1195' fill={`#${diamondColors[color][2]}`}
                d='M574.013 887.436L567.011 930.62L605.828 857.739L574.013 887.436Z' />
          <path id='Vector_1196' fill='white' d='M522.648 865.661L568.595 851.767L563.319 818.13L522.648 865.661Z'
                opacity='.2' />
          <path id='Vector_1197' fill='white'
                d='M568.343 851.656L605.828 857.739L563.05 818.13L568.343 851.042V851.656Z' opacity='.2' />
          <path id='Vector_1198' fill={`#${diamondColors[color][3]}`}
                d='M571.103 905.421C571.18 904.952 593.801 869.352 593.801 869.352L605.828 858.531L578.51 909.083L567.011 930.62L571.103 905.421Z'
                opacity='.5' />
          <path id='Vector_1199' fill={`#${diamondColors[color][3]}`}
                d='M575.383 887.438L604.473 860.282L605.828 857.739L574.013 887.438L567.011 930.62L568.98 926.922L575.383 887.438Z' />
          <path id='Vector_1200' fill={`#${diamondColors[color][3]}`}
                d='M537.631 887.539L573.348 887.842L522.648 865.661L537.631 887.539Z' opacity='.5' />
          <path id='Vector_1201' fill='white'
                d='M525.025 864.837C526.587 866.438 530.431 866.665 532.606 867.082C537.139 868.061 541.812 868.228 546.404 867.573C551.968 866.677 556.339 864.23 560.658 860.788C563.478 858.54 564.593 856.331 565.775 853.136C566.125 852.32 566.401 851.474 566.598 850.609C568.02 851.363 567.832 856.183 568.239 857.788C569.041 860.945 569.915 864.011 570.245 867.238C570.871 872.579 571.773 877.884 572.948 883.134C573.17 884.208 573.869 885.597 572.645 886.138C571.675 886.568 569.952 885.723 569.115 885.417C566.633 884.509 564.715 882.946 562.326 881.818C560.004 880.722 557.55 880.137 555.207 879.04C552.728 877.879 550.305 876.829 547.921 875.483C544.271 873.422 540.219 871.811 536.401 870.2C534.482 869.39 532.467 868.625 530.643 867.792C529.198 867.132 525.981 866.37 525.176 864.984L525.025 864.837Z'
                opacity='.4' />
          <path id='Vector_1202' fill='white'
                d='M581.061 900.315C589.121 895.039 591.377 884.227 586.1 876.168C580.823 868.108 570.012 865.852 561.953 871.129C553.893 876.406 551.637 887.217 556.914 895.277C562.191 903.336 573.002 905.592 581.061 900.315Z'
                opacity='.1' />
          <path id='Vector_1203' fill='white'
                d='M585.923 886.682C585.604 889.247 584.532 891.66 582.842 893.616C581.151 895.572 578.918 896.983 576.426 897.67C573.934 898.357 571.294 898.29 568.84 897.477C566.386 896.664 564.229 895.141 562.64 893.101C561.052 891.062 560.103 888.597 559.916 886.018C559.728 883.44 560.309 880.864 561.585 878.616C562.862 876.368 564.776 874.549 567.086 873.388C569.397 872.228 571.999 871.779 574.565 872.098C578.005 872.526 581.134 874.303 583.264 877.037C585.394 879.772 586.351 883.242 585.923 886.682Z'
                opacity='.2' />
          <path id='Vector_1204' fill='white'
                d='M574.301 876.446C573.271 880.298 573.212 885.296 566.219 885.023C568.15 886.246 570.336 887.378 571.527 889.497C572.632 891.683 573.211 894.101 573.217 896.556C573.887 894.324 573.626 892.128 575.308 889.977C575.919 889.178 576.703 888.531 577.598 888.083C578.494 887.636 579.479 887.4 580.478 887.394C578.418 885.877 576.468 885.829 575.455 882.989C575.055 881.913 574.749 880.805 574.539 879.675C574.349 878.535 574.671 876.849 574.059 875.959L574.301 876.446Z'
                opacity='.7' />
          <path id='Vector_1205' fill='white'
                d='M575.584 885.904C575.09 884.308 574.556 884.494 574.096 885.175C571.784 884.022 572.118 891.882 574.811 889.895C575.724 889.222 575.89 886.892 575.584 885.904Z' />
          <path id='Vector_1206' fill='white'
                d='M534.438 866.779C534.138 869.189 533.13 871.456 531.543 873.293C529.955 875.131 527.857 876.456 525.516 877.102C523.175 877.747 520.695 877.684 518.39 876.92C516.084 876.156 514.058 874.726 512.565 872.81C511.073 870.894 510.182 868.579 510.006 866.156C509.83 863.734 510.375 861.314 511.574 859.202C512.773 857.09 514.572 855.381 516.742 854.292C518.912 853.202 521.357 852.78 523.767 853.08C526.999 853.482 529.938 855.15 531.939 857.72C533.94 860.289 534.839 863.547 534.438 866.779Z'
                opacity='.1' />
          <path id='Vector_1207' fill='white'
                d='M532.877 866.391C532.654 868.178 531.907 869.86 530.728 871.223C529.55 872.586 527.994 873.569 526.257 874.047C524.52 874.526 522.68 874.479 520.97 873.912C519.26 873.345 517.756 872.284 516.649 870.863C515.542 869.441 514.882 867.723 514.751 865.926C514.62 864.13 515.025 862.334 515.914 860.767C516.803 859.201 518.137 857.933 519.747 857.124C521.357 856.315 523.171 856.002 524.959 856.224C526.146 856.371 527.293 856.751 528.334 857.342C529.375 857.933 530.289 858.723 531.024 859.667C531.759 860.611 532.302 861.691 532.619 862.844C532.937 863.998 533.025 865.203 532.877 866.391Z'
                opacity='.2' />
          <path id='Vector_1208' fill='white'
                d='M524.524 858.87C523.78 861.537 523.738 864.997 518.687 864.806C520.082 865.653 521.661 866.436 522.521 867.904C523.319 869.417 523.737 871.091 523.742 872.79C524.226 871.245 524.037 869.725 525.251 868.236C525.693 867.683 526.259 867.235 526.906 866.925C527.553 866.615 528.264 866.452 528.986 866.447C527.498 865.398 526.09 865.364 525.358 863.398C525.07 862.653 524.848 861.886 524.696 861.104C524.557 860.315 524.792 859.148 524.35 858.531L524.524 858.87Z'
                opacity='.7' />
          <path id='Vector_1209' fill='white'
                d='M570.902 820.788C570.68 822.499 569.933 824.108 568.755 825.412C567.576 826.716 566.021 827.656 564.284 828.114C562.547 828.573 560.706 828.528 558.996 827.986C557.286 827.444 555.782 826.429 554.675 825.069C553.567 823.709 552.906 822.066 552.776 820.347C552.645 818.628 553.05 816.911 553.939 815.412C554.829 813.913 556.163 812.7 557.773 811.927C559.383 811.153 561.197 810.854 562.985 811.067C565.383 811.352 567.564 812.536 569.049 814.359C570.533 816.182 571.2 818.495 570.902 820.788Z'
                opacity='.1' />
          <path id='Vector_1210' fill='white'
                d='M569.336 820.495C569.172 821.739 568.619 822.91 567.748 823.858C566.877 824.806 565.727 825.49 564.444 825.824C563.16 826.157 561.799 826.124 560.535 825.73C559.271 825.335 558.16 824.597 557.341 823.608C556.523 822.619 556.035 821.424 555.938 820.174C555.841 818.924 556.141 817.675 556.798 816.585C557.456 815.495 558.442 814.613 559.633 814.05C560.823 813.488 562.164 813.27 563.485 813.425C564.363 813.528 565.21 813.792 565.979 814.203C566.748 814.614 567.423 815.163 567.967 815.82C568.51 816.476 568.91 817.227 569.145 818.029C569.38 818.832 569.445 819.67 569.336 820.495Z'
                opacity='.2' />
          <path id='Vector_1211' fill='white'
                d='M563.131 815.204C562.616 817.13 562.587 819.629 559.089 819.492C560.055 820.104 561.148 820.67 561.743 821.73C562.296 822.823 562.585 824.032 562.588 825.259C562.923 824.143 562.793 823.046 563.634 821.969C563.939 821.57 564.331 821.247 564.779 821.023C565.227 820.799 565.719 820.681 566.219 820.678C565.189 819.92 564.214 819.896 563.707 818.475C563.507 817.938 563.354 817.383 563.249 816.819C563.153 816.249 563.315 815.406 563.009 814.961L563.131 815.204Z'
                opacity='.7' />
          <path id='Vector_1212' fill='white'
                d='M524.92 865.13C524.549 863.763 524.148 863.922 523.803 864.508C522.07 863.52 522.319 870.255 524.34 868.552C525.025 867.974 525.149 865.977 524.92 865.13Z' />
        </g>;

      case 6 :
        return <g id='Mini gem_19'>
          <path id='Vector_1213' fill={`#${diamondColors[color][0]}`}
                d='M350.28 809.13L392.854 848.846L361.188 878.5L350.279 810.548L309.675 856.63L354.219 921.62L361.188 878.5L350.28 809.13Z' />
          <path id='Vector_1214' fill={`#${diamondColors[color][1]}`}
                d='M350.209 809.13L309.675 856.403L361.167 878.842L350.209 809.13Z' />
          <path id='Vector_1215' fill='white'
                d='M350.21 809.13L349.078 810.448L359.418 876.228L310.831 855.055L309.675 856.403L361.167 878.842L350.21 809.13Z' />
          <path id='Vector_1216' fill={`#${diamondColors[color][2]}`}
                d='M361.039 878.436L354.037 921.62L392.854 848.739L361.039 878.436Z' />
          <path id='Vector_1217' fill={`#${diamondColors[color][2]}`}
                d='M361.039 878.436L354.037 921.62L392.854 848.739L361.039 878.436Z' />
          <path id='Vector_1218' fill='white' d='M309.675 856.661L355.622 842.767L350.345 809.13L309.675 856.661Z'
                opacity='.2' />
          <path id='Vector_1219' fill='white'
                d='M355.369 842.656L392.854 848.739L350.076 809.13L355.369 842.042V842.656Z' opacity='.2' />
          <path id='Vector_1220' fill={`#${diamondColors[color][3]}`}
                d='M358.13 896.421C358.206 895.952 380.827 860.352 380.827 860.352L392.854 849.531L365.536 900.083L354.037 921.62L358.13 896.421Z'
                opacity='.5' />
          <path id='Vector_1221' fill={`#${diamondColors[color][3]}`}
                d='M362.41 878.437L391.5 851.282L392.854 848.739L361.039 878.437L354.037 921.62L356.006 917.921L362.41 878.437Z' />
          <path id='Vector_1222' fill={`#${diamondColors[color][3]}`}
                d='M324.658 878.539L360.375 878.842L309.675 856.661L324.658 878.539Z' opacity='.5' />
          <path id='Vector_1223' fill='white'
                d='M312.052 855.837C313.614 857.438 317.457 857.665 319.632 858.082C324.165 859.061 328.839 859.228 333.431 858.573C338.995 857.677 343.365 855.23 347.684 851.788C350.505 849.54 351.62 847.331 352.802 844.136C353.152 843.32 353.427 842.474 353.625 841.609C355.047 842.363 354.858 847.183 355.266 848.788C356.068 851.945 356.941 855.011 357.271 858.238C357.897 863.579 358.8 868.884 359.975 874.134C360.197 875.208 360.895 876.597 359.671 877.138C358.702 877.568 356.978 876.723 356.142 876.417C353.66 875.509 351.741 873.946 349.352 872.818C347.031 871.722 344.576 871.137 342.234 870.04C339.755 868.879 337.331 867.829 334.948 866.483C331.297 864.422 327.246 862.811 323.427 861.2C321.509 860.39 319.493 859.625 317.669 858.792C316.225 858.132 313.008 857.37 312.202 855.984L312.052 855.837Z'
                opacity='.4' />
          <path id='Vector_1224' fill='white'
                d='M368.088 891.315C376.147 886.039 378.403 875.227 373.127 867.168C367.85 859.108 357.039 856.852 348.979 862.129C340.92 867.406 338.664 878.217 343.94 886.277C349.217 894.336 360.028 896.592 368.088 891.315Z'
                opacity='.1' />
          <path id='Vector_1225' fill='white'
                d='M372.95 877.682C372.631 880.247 371.558 882.66 369.868 884.616C368.178 886.572 365.945 887.983 363.453 888.67C360.961 889.357 358.321 889.29 355.867 888.477C353.413 887.663 351.255 886.141 349.667 884.101C348.078 882.062 347.13 879.597 346.942 877.018C346.754 874.44 347.336 871.864 348.612 869.616C349.888 867.368 351.803 865.548 354.113 864.388C356.423 863.228 359.026 862.779 361.591 863.098C365.031 863.526 368.161 865.303 370.291 868.037C372.421 870.772 373.377 874.242 372.95 877.682Z'
                opacity='.2' />
          <path id='Vector_1226' fill='white'
                d='M361.327 867.446C360.297 871.298 360.238 876.296 353.245 876.023C355.177 877.246 357.362 878.378 358.553 880.497C359.658 882.683 360.237 885.101 360.243 887.556C360.914 885.324 360.652 883.128 362.334 880.977C362.946 880.178 363.729 879.531 364.625 879.083C365.521 878.635 366.505 878.4 367.504 878.394C365.444 876.877 363.495 876.829 362.481 873.989C362.082 872.913 361.775 871.805 361.566 870.675C361.376 869.535 361.698 867.849 361.085 866.959L361.327 867.446Z'
                opacity='.7' />
          <path id='Vector_1227' fill='white'
                d='M362.611 876.904C362.116 875.308 361.582 875.494 361.122 876.175C358.811 875.022 359.144 882.882 361.837 880.895C362.75 880.222 362.917 877.892 362.611 876.904Z' />
          <path id='Vector_1228' fill='white'
                d='M321.464 857.779C321.165 860.189 320.157 862.456 318.569 864.293C316.981 866.131 314.884 867.456 312.543 868.102C310.202 868.747 307.722 868.684 305.416 867.92C303.111 867.156 301.084 865.726 299.592 863.81C298.099 861.894 297.209 859.578 297.032 857.156C296.856 854.734 297.402 852.314 298.601 850.202C299.8 848.09 301.598 846.381 303.769 845.292C305.939 844.202 308.384 843.78 310.794 844.08C314.025 844.481 316.965 846.15 318.966 848.72C320.967 851.289 321.865 854.547 321.464 857.779Z'
                opacity='.1' />
          <path id='Vector_1229' fill='white'
                d='M319.903 857.391C319.681 859.178 318.933 860.86 317.755 862.223C316.576 863.586 315.02 864.569 313.284 865.047C311.547 865.526 309.707 865.479 307.997 864.912C306.287 864.345 304.783 863.284 303.676 861.862C302.569 860.441 301.908 858.723 301.777 856.926C301.646 855.13 302.051 853.334 302.94 851.767C303.83 850.201 305.164 848.933 306.774 848.124C308.384 847.315 310.197 847.002 311.985 847.224C313.173 847.371 314.32 847.751 315.36 848.342C316.401 848.933 317.315 849.723 318.051 850.667C318.786 851.611 319.328 852.691 319.646 853.844C319.964 854.998 320.051 856.203 319.903 857.391Z'
                opacity='.2' />
          <path id='Vector_1230' fill='white'
                d='M311.551 849.87C310.807 852.537 310.765 855.997 305.714 855.806C307.109 856.653 308.687 857.436 309.547 858.904C310.346 860.417 310.764 862.091 310.768 863.79C311.253 862.245 311.064 860.725 312.278 859.236C312.72 858.683 313.285 858.235 313.932 857.925C314.579 857.615 315.291 857.452 316.012 857.447C314.525 856.398 313.117 856.364 312.385 854.398C312.096 853.653 311.875 852.886 311.723 852.104C311.584 851.315 311.818 850.148 311.377 849.531L311.551 849.87Z'
                opacity='.7' />
          <path id='Vector_1231' fill='white'
                d='M357.929 811.788C357.707 813.499 356.959 815.108 355.781 816.412C354.603 817.716 353.047 818.656 351.31 819.114C349.573 819.573 347.733 819.528 346.023 818.986C344.312 818.444 342.808 817.429 341.701 816.069C340.594 814.709 339.933 813.066 339.802 811.347C339.671 809.628 340.076 807.911 340.966 806.412C341.855 804.913 343.189 803.7 344.8 802.927C346.41 802.153 348.224 801.854 350.012 802.067C352.409 802.352 354.59 803.536 356.075 805.359C357.56 807.182 358.227 809.495 357.929 811.788Z'
                opacity='.1' />
          <path id='Vector_1232' fill='white'
                d='M356.362 811.495C356.198 812.739 355.646 813.91 354.775 814.858C353.904 815.806 352.754 816.49 351.47 816.824C350.186 817.157 348.826 817.124 347.562 816.73C346.298 816.335 345.186 815.597 344.368 814.608C343.549 813.619 343.061 812.424 342.964 811.174C342.868 809.924 343.167 808.675 343.825 807.584C344.483 806.494 345.469 805.613 346.659 805.05C347.849 804.488 349.19 804.27 350.512 804.425C351.389 804.528 352.237 804.792 353.005 805.203C353.774 805.614 354.45 806.163 354.993 806.82C355.536 807.476 355.937 808.227 356.172 809.029C356.407 809.832 356.471 810.67 356.362 811.495Z'
                opacity='.2' />
          <path id='Vector_1233' fill='white'
                d='M350.158 806.204C349.643 808.13 349.614 810.629 346.115 810.492C347.082 811.104 348.174 811.67 348.77 812.73C349.322 813.823 349.612 815.032 349.615 816.259C349.95 815.143 349.819 814.046 350.66 812.969C350.966 812.57 351.358 812.246 351.806 812.023C352.253 811.799 352.746 811.681 353.245 811.678C352.215 810.92 351.24 810.896 350.733 809.475C350.534 808.938 350.381 808.383 350.276 807.819C350.179 807.249 350.342 806.406 350.036 805.961L350.158 806.204Z'
                opacity='.7' />
          <path id='Vector_1234' fill='white'
                d='M311.946 856.13C311.575 854.763 311.175 854.922 310.83 855.508C309.096 854.52 309.346 861.255 311.366 859.552C312.052 858.974 312.175 856.977 311.946 856.13Z' />
        </g>;

      case 7:
        return <g id='Mini gem_20'>
          <path id='Vector_1235' fill={`#${diamondColors[color][0]}`}
                d='M477.28 698.13L519.854 737.846L488.188 767.5L477.279 699.548L436.675 745.63L481.219 810.62L488.188 767.5L477.28 698.13Z' />
          <path id='Vector_1236' fill={`#${diamondColors[color][1]}`}
                d='M477.209 698.13L436.675 745.403L488.167 767.842L477.209 698.13Z' />
          <path id='Vector_1237' fill='white'
                d='M477.21 698.13L476.078 699.448L486.418 765.228L437.831 744.055L436.675 745.403L488.167 767.842L477.21 698.13Z' />
          <path id='Vector_1238' fill={`#${diamondColors[color][2]}`}
                d='M488.039 767.436L481.037 810.62L519.854 737.739L488.039 767.436Z' />
          <path id='Vector_1239' fill={`#${diamondColors[color][2]}`}
                d='M488.039 767.436L481.037 810.62L519.854 737.739L488.039 767.436Z' />
          <path id='Vector_1240' fill='white' d='M436.675 745.661L482.622 731.767L477.345 698.13L436.675 745.661Z'
                opacity='.2' />
          <path id='Vector_1241' fill='white'
                d='M482.369 731.656L519.854 737.739L477.076 698.13L482.369 731.042V731.656Z' opacity='.2' />
          <path id='Vector_1242' fill={`#${diamondColors[color][3]}`}
                d='M485.13 785.421C485.206 784.952 507.827 749.352 507.827 749.352L519.854 738.531L492.536 789.083L481.037 810.62L485.13 785.421Z'
                opacity='.5' />
          <path id='Vector_1243' fill={`#${diamondColors[color][3]}`}
                d='M489.41 767.437L518.5 740.282L519.854 737.739L488.039 767.437L481.037 810.62L483.006 806.921L489.41 767.437Z' />
          <path id='Vector_1244' fill={`#${diamondColors[color][3]}`}
                d='M451.658 767.539L487.375 767.842L436.675 745.661L451.658 767.539Z' opacity='.5' />
          <path id='Vector_1245' fill='white'
                d='M439.052 744.837C440.614 746.438 444.457 746.665 446.632 747.082C451.165 748.061 455.839 748.228 460.431 747.573C465.995 746.677 470.365 744.23 474.684 740.788C477.505 738.54 478.62 736.331 479.802 733.136C480.152 732.32 480.427 731.474 480.625 730.609C482.047 731.363 481.858 736.183 482.266 737.788C483.068 740.945 483.941 744.011 484.271 747.238C484.897 752.579 485.8 757.884 486.975 763.134C487.197 764.208 487.895 765.597 486.671 766.138C485.702 766.568 483.978 765.723 483.142 765.417C480.66 764.509 478.741 762.946 476.352 761.818C474.031 760.722 471.576 760.137 469.234 759.04C466.755 757.879 464.331 756.829 461.948 755.483C458.297 753.422 454.246 751.811 450.427 750.2C448.509 749.39 446.493 748.625 444.669 747.792C443.225 747.132 440.008 746.37 439.202 744.984L439.052 744.837Z'
                opacity='.4' />
          <path id='Vector_1246' fill='white'
                d='M495.088 780.315C503.147 775.039 505.403 764.227 500.127 756.168C494.85 748.108 484.039 745.852 475.979 751.129C467.92 756.406 465.664 767.217 470.94 775.277C476.217 783.336 487.028 785.592 495.088 780.315Z'
                opacity='.1' />
          <path id='Vector_1247' fill='white'
                d='M499.95 766.682C499.631 769.247 498.559 771.66 496.868 773.616C495.178 775.572 492.945 776.983 490.453 777.67C487.961 778.357 485.321 778.29 482.867 777.477C480.413 776.663 478.255 775.141 476.667 773.101C475.078 771.062 474.13 768.597 473.942 766.018C473.754 763.44 474.336 760.864 475.612 758.616C476.888 756.368 478.803 754.548 481.113 753.388C483.423 752.228 486.026 751.779 488.591 752.098C492.031 752.526 495.161 754.303 497.291 757.037C499.421 759.772 500.377 763.242 499.95 766.682Z'
                opacity='.2' />
          <path id='Vector_1248' fill='white'
                d='M488.327 756.446C487.297 760.298 487.238 765.296 480.245 765.023C482.177 766.246 484.362 767.378 485.553 769.497C486.659 771.683 487.237 774.101 487.243 776.556C487.914 774.324 487.652 772.128 489.334 769.977C489.946 769.178 490.729 768.531 491.625 768.083C492.521 767.635 493.505 767.4 494.504 767.394C492.444 765.877 490.495 765.829 489.481 762.989C489.082 761.913 488.775 760.805 488.566 759.675C488.376 758.535 488.698 756.849 488.085 755.959L488.327 756.446Z'
                opacity='.7' />
          <path id='Vector_1249' fill='white'
                d='M489.611 765.904C489.116 764.308 488.583 764.494 488.122 765.175C485.811 764.022 486.144 771.882 488.837 769.895C489.75 769.222 489.917 766.892 489.611 765.904Z' />
          <path id='Vector_1250' fill='white'
                d='M448.464 746.779C448.165 749.189 447.157 751.456 445.569 753.293C443.981 755.131 441.884 756.456 439.543 757.102C437.202 757.747 434.722 757.684 432.416 756.92C430.111 756.156 428.084 754.726 426.592 752.81C425.099 750.894 424.209 748.578 424.032 746.156C423.856 743.734 424.402 741.314 425.601 739.202C426.8 737.09 428.598 735.381 430.769 734.292C432.939 733.202 435.384 732.78 437.794 733.08C441.025 733.481 443.965 735.15 445.966 737.72C447.967 740.289 448.865 743.547 448.464 746.779Z'
                opacity='.1' />
          <path id='Vector_1251' fill='white'
                d='M446.903 746.391C446.681 748.178 445.933 749.86 444.755 751.223C443.576 752.586 442.02 753.569 440.284 754.047C438.547 754.526 436.707 754.479 434.997 753.912C433.287 753.345 431.783 752.284 430.676 750.862C429.569 749.441 428.908 747.723 428.777 745.926C428.646 744.13 429.051 742.334 429.94 740.767C430.83 739.201 432.164 737.933 433.774 737.124C435.384 736.315 437.197 736.002 438.985 736.224C440.173 736.371 441.32 736.751 442.36 737.342C443.401 737.933 444.315 738.723 445.051 739.667C445.786 740.611 446.328 741.691 446.646 742.844C446.964 743.998 447.051 745.203 446.903 746.391Z'
                opacity='.2' />
          <path id='Vector_1252' fill='white'
                d='M438.551 738.87C437.807 741.537 437.765 744.997 432.714 744.806C434.109 745.653 435.687 746.436 436.547 747.904C437.346 749.417 437.764 751.091 437.768 752.79C438.253 751.245 438.064 749.725 439.278 748.236C439.72 747.683 440.285 747.235 440.932 746.925C441.579 746.615 442.291 746.452 443.012 746.447C441.525 745.398 440.117 745.364 439.385 743.398C439.096 742.653 438.875 741.886 438.723 741.104C438.584 740.315 438.818 739.148 438.377 738.531L438.551 738.87Z'
                opacity='.7' />
          <path id='Vector_1253' fill='white'
                d='M484.929 700.788C484.707 702.499 483.959 704.108 482.781 705.412C481.603 706.716 480.047 707.656 478.31 708.114C476.573 708.573 474.733 708.528 473.023 707.986C471.312 707.444 469.808 706.429 468.701 705.069C467.594 703.709 466.933 702.066 466.802 700.347C466.671 698.628 467.076 696.911 467.966 695.412C468.855 693.913 470.189 692.7 471.8 691.927C473.41 691.153 475.224 690.854 477.012 691.067C479.409 691.352 481.59 692.536 483.075 694.359C484.56 696.182 485.227 698.495 484.929 700.788Z'
                opacity='.1' />
          <path id='Vector_1254' fill='white'
                d='M483.362 700.495C483.198 701.739 482.646 702.91 481.775 703.858C480.904 704.806 479.754 705.49 478.47 705.824C477.186 706.157 475.826 706.124 474.562 705.73C473.298 705.335 472.186 704.597 471.368 703.608C470.549 702.619 470.061 701.424 469.964 700.174C469.868 698.924 470.167 697.675 470.825 696.584C471.483 695.494 472.469 694.613 473.659 694.05C474.849 693.488 476.19 693.27 477.512 693.425C478.389 693.528 479.237 693.792 480.005 694.203C480.774 694.614 481.45 695.163 481.993 695.82C482.536 696.476 482.937 697.227 483.172 698.029C483.407 698.832 483.471 699.67 483.362 700.495Z'
                opacity='.2' />
          <path id='Vector_1255' fill='white'
                d='M477.158 695.204C476.643 697.13 476.614 699.629 473.115 699.492C474.082 700.104 475.174 700.67 475.77 701.73C476.322 702.823 476.612 704.032 476.615 705.259C476.95 704.143 476.819 703.046 477.66 701.969C477.966 701.57 478.358 701.246 478.806 701.023C479.253 700.799 479.746 700.681 480.245 700.678C479.215 699.92 478.24 699.896 477.733 698.475C477.534 697.938 477.381 697.383 477.276 696.819C477.179 696.249 477.342 695.406 477.036 694.961L477.158 695.204Z'
                opacity='.7' />
          <path id='Vector_1256' fill='white'
                d='M438.946 745.13C438.575 743.763 438.175 743.922 437.83 744.508C436.096 743.52 436.346 750.255 438.366 748.552C439.052 747.974 439.175 745.977 438.946 745.13Z' />
        </g>;

      case 8:
        return <g id='Mini gem_21'>
          <path id='Vector_1257' fill={`#${diamondColors[color][0]}`}
                d='M215.28 656.13L257.854 695.846L226.188 725.5L215.279 657.548L174.675 703.63L219.219 768.62L226.188 725.5L215.28 656.13Z' />
          <path id='Vector_1258' fill={`#${diamondColors[color][1]}`}
                d='M215.209 656.13L174.675 703.403L226.167 725.842L215.209 656.13Z' />
          <path id='Vector_1259' fill='white'
                d='M215.21 656.13L214.078 657.448L224.418 723.228L175.831 702.055L174.675 703.403L226.167 725.842L215.21 656.13Z' />
          <path id='Vector_1260' fill={`#${diamondColors[color][2]}`}
                d='M226.039 725.436L219.037 768.62L257.854 695.739L226.039 725.436Z' />
          <path id='Vector_1261' fill={`#${diamondColors[color][2]}`}
                d='M226.039 725.436L219.037 768.62L257.854 695.739L226.039 725.436Z' />
          <path id='Vector_1262' fill='white' d='M174.675 703.661L220.622 689.767L215.345 656.13L174.675 703.661Z'
                opacity='.2' />
          <path id='Vector_1263' fill='white'
                d='M220.369 689.656L257.854 695.739L215.076 656.13L220.369 689.042V689.656Z' opacity='.2' />
          <path id='Vector_1264' fill={`#${diamondColors[color][3]}`}
                d='M223.13 743.421C223.206 742.952 245.827 707.352 245.827 707.352L257.854 696.531L230.536 747.083L219.037 768.62L223.13 743.421Z'
                opacity='.5' />
          <path id='Vector_1265' fill={`#${diamondColors[color][3]}`}
                d='M227.41 725.437L256.5 698.282L257.854 695.739L226.039 725.437L219.037 768.62L221.006 764.921L227.41 725.437Z' />
          <path id='Vector_1266' fill={`#${diamondColors[color][3]}`}
                d='M189.658 725.539L225.375 725.842L174.675 703.661L189.658 725.539Z' opacity='.5' />
          <path id='Vector_1267' fill='white'
                d='M177.052 702.837C178.614 704.438 182.457 704.665 184.632 705.082C189.165 706.061 193.839 706.228 198.431 705.573C203.995 704.677 208.365 702.23 212.684 698.788C215.505 696.54 216.62 694.331 217.802 691.136C218.152 690.32 218.427 689.474 218.625 688.609C220.047 689.363 219.858 694.183 220.266 695.788C221.068 698.945 221.941 702.011 222.271 705.238C222.897 710.579 223.8 715.884 224.975 721.134C225.197 722.208 225.895 723.597 224.671 724.138C223.702 724.568 221.978 723.723 221.142 723.417C218.66 722.509 216.741 720.946 214.352 719.818C212.031 718.722 209.576 718.137 207.234 717.04C204.755 715.879 202.331 714.829 199.948 713.483C196.297 711.422 192.246 709.811 188.427 708.2C186.509 707.39 184.493 706.625 182.669 705.792C181.225 705.132 178.008 704.37 177.202 702.984L177.052 702.837Z'
                opacity='.4' />
          <path id='Vector_1268' fill='white'
                d='M233.088 738.315C241.147 733.039 243.403 722.227 238.127 714.168C232.85 706.108 222.039 703.852 213.979 709.129C205.92 714.406 203.664 725.217 208.94 733.277C214.217 741.336 225.028 743.592 233.088 738.315Z'
                opacity='.1' />
          <path id='Vector_1269' fill='white'
                d='M237.95 724.682C237.631 727.247 236.558 729.66 234.868 731.616C233.178 733.572 230.945 734.983 228.453 735.67C225.961 736.357 223.321 736.29 220.867 735.477C218.413 734.663 216.255 733.141 214.667 731.101C213.078 729.062 212.13 726.597 211.942 724.018C211.754 721.44 212.336 718.864 213.612 716.616C214.888 714.368 216.803 712.548 219.113 711.388C221.423 710.228 224.026 709.779 226.591 710.098C230.031 710.526 233.161 712.303 235.291 715.037C237.421 717.772 238.377 721.242 237.95 724.682Z'
                opacity='.2' />
          <path id='Vector_1270' fill='white'
                d='M226.327 714.446C225.297 718.298 225.238 723.296 218.245 723.023C220.177 724.246 222.362 725.378 223.553 727.497C224.658 729.683 225.237 732.101 225.243 734.556C225.914 732.324 225.652 730.128 227.334 727.977C227.946 727.178 228.729 726.531 229.625 726.083C230.521 725.635 231.505 725.4 232.504 725.394C230.444 723.877 228.495 723.829 227.481 720.989C227.082 719.913 226.775 718.805 226.566 717.675C226.376 716.535 226.698 714.849 226.085 713.959L226.327 714.446Z'
                opacity='.7' />
          <path id='Vector_1271' fill='white'
                d='M227.611 723.904C227.116 722.308 226.582 722.494 226.122 723.175C223.811 722.022 224.144 729.882 226.837 727.895C227.75 727.222 227.917 724.892 227.611 723.904Z' />
          <path id='Vector_1272' fill='white'
                d='M186.464 704.779C186.165 707.189 185.157 709.456 183.569 711.293C181.981 713.131 179.884 714.456 177.543 715.102C175.202 715.747 172.722 715.684 170.416 714.92C168.111 714.156 166.084 712.726 164.592 710.81C163.099 708.894 162.209 706.578 162.032 704.156C161.856 701.734 162.402 699.314 163.601 697.202C164.8 695.09 166.598 693.381 168.769 692.292C170.939 691.202 173.384 690.78 175.794 691.08C179.025 691.481 181.965 693.15 183.966 695.72C185.967 698.289 186.865 701.547 186.464 704.779Z'
                opacity='.1' />
          <path id='Vector_1273' fill='white'
                d='M184.903 704.391C184.681 706.178 183.933 707.86 182.755 709.223C181.576 710.586 180.02 711.569 178.284 712.047C176.547 712.526 174.707 712.479 172.997 711.912C171.287 711.345 169.783 710.284 168.676 708.862C167.569 707.441 166.908 705.723 166.777 703.926C166.646 702.13 167.051 700.334 167.94 698.767C168.83 697.201 170.164 695.933 171.774 695.124C173.384 694.315 175.197 694.002 176.985 694.224C178.173 694.371 179.32 694.751 180.36 695.342C181.401 695.933 182.315 696.723 183.051 697.667C183.786 698.611 184.328 699.691 184.646 700.844C184.964 701.998 185.051 703.203 184.903 704.391Z'
                opacity='.2' />
          <path id='Vector_1274' fill='white'
                d='M176.551 696.87C175.807 699.537 175.765 702.997 170.714 702.806C172.109 703.653 173.687 704.436 174.547 705.904C175.346 707.417 175.764 709.091 175.768 710.79C176.253 709.245 176.064 707.725 177.278 706.236C177.72 705.683 178.285 705.235 178.932 704.925C179.579 704.615 180.291 704.452 181.012 704.447C179.525 703.398 178.117 703.364 177.385 701.398C177.096 700.653 176.875 699.886 176.723 699.104C176.584 698.315 176.818 697.148 176.377 696.531L176.551 696.87Z'
                opacity='.7' />
          <path id='Vector_1275' fill='white'
                d='M222.929 658.788C222.707 660.499 221.959 662.108 220.781 663.412C219.603 664.716 218.047 665.656 216.31 666.114C214.573 666.573 212.733 666.528 211.023 665.986C209.312 665.444 207.808 664.429 206.701 663.069C205.594 661.709 204.933 660.066 204.802 658.347C204.671 656.628 205.076 654.911 205.966 653.412C206.855 651.913 208.189 650.7 209.8 649.927C211.41 649.153 213.224 648.854 215.012 649.067C217.409 649.352 219.59 650.536 221.075 652.359C222.56 654.182 223.227 656.495 222.929 658.788Z'
                opacity='.1' />
          <path id='Vector_1276' fill='white'
                d='M221.362 658.495C221.198 659.739 220.646 660.91 219.775 661.858C218.904 662.806 217.754 663.49 216.47 663.824C215.186 664.157 213.826 664.124 212.562 663.73C211.298 663.335 210.186 662.597 209.368 661.608C208.549 660.619 208.061 659.424 207.964 658.174C207.868 656.924 208.167 655.675 208.825 654.584C209.483 653.494 210.469 652.613 211.659 652.05C212.849 651.488 214.19 651.27 215.512 651.425C216.389 651.528 217.237 651.792 218.005 652.203C218.774 652.614 219.45 653.163 219.993 653.82C220.536 654.476 220.937 655.227 221.172 656.029C221.407 656.832 221.471 657.67 221.362 658.495Z'
                opacity='.2' />
          <path id='Vector_1277' fill='white'
                d='M215.158 653.204C214.643 655.13 214.614 657.629 211.115 657.492C212.082 658.104 213.174 658.67 213.77 659.73C214.322 660.823 214.612 662.032 214.615 663.259C214.95 662.143 214.819 661.046 215.66 659.969C215.966 659.57 216.358 659.246 216.806 659.023C217.253 658.799 217.746 658.681 218.245 658.678C217.215 657.92 216.24 657.896 215.733 656.475C215.534 655.938 215.381 655.383 215.276 654.819C215.179 654.249 215.342 653.406 215.036 652.961L215.158 653.204Z'
                opacity='.7' />
          <path id='Vector_1278' fill='white'
                d='M176.946 703.13C176.575 701.763 176.175 701.922 175.83 702.508C174.096 701.52 174.346 708.255 176.366 706.552C177.052 705.974 177.175 703.977 176.946 703.13Z' />
        </g>;

      case 9:
        return <g id='Mini gem_22'>
          <path id='Vector_1279' fill={`#${diamondColors[color][0]}`}
                d='M384.135 492.215L421 526.605L393.58 552.283L384.134 493.443L348.975 533.346L387.546 589.62L393.58 552.283L384.135 492.215Z' />
          <path id='Vector_1280' fill={`#${diamondColors[color][1]}`}
                d='M384.073 492.215L348.975 533.149L393.562 552.579L384.073 492.215Z' />
          <path id='Vector_1281' fill='white'
                d='M384.074 492.215L383.095 493.357L392.048 550.315L349.976 531.981L348.975 533.149L393.562 552.579L384.074 492.215Z' />
          <path id='Vector_1282' fill={`#${diamondColors[color][2]}`}
                d='M393.451 552.227L387.388 589.62L421 526.512L393.451 552.227Z' />
          <path id='Vector_1283' fill={`#${diamondColors[color][2]}`}
                d='M393.451 552.227L387.388 589.62L421 526.512L393.451 552.227Z' />
          <path id='Vector_1284' fill='white' d='M348.975 533.372L388.76 521.342L384.191 492.215L348.975 533.372Z'
                opacity='.2' />
          <path id='Vector_1285' fill='white' d='M388.542 521.245L421 526.512L383.959 492.215L388.542 520.713V521.245Z'
                opacity='.2' />
          <path id='Vector_1286' fill={`#${diamondColors[color][3]}`}
                d='M390.932 567.8C390.998 567.394 410.586 536.569 410.586 536.569L421 527.198L397.345 570.971L387.388 589.62L390.932 567.8Z'
                opacity='.5' />
          <path id='Vector_1287' fill={`#${diamondColors[color][3]}`}
                d='M394.638 552.228L419.827 528.715L421 526.512L393.451 552.228L387.388 589.62L389.093 586.417L394.638 552.228Z' />
          <path id='Vector_1288' fill={`#${diamondColors[color][3]}`}
                d='M361.949 552.316L392.876 552.579L348.975 533.372L361.949 552.316Z' opacity='.5' />
          <path id='Vector_1289' fill='white'
                d='M351.033 532.658C352.386 534.045 355.714 534.241 357.597 534.603C361.522 535.451 365.569 535.595 369.545 535.028C374.363 534.252 378.147 532.133 381.887 529.152C384.33 527.206 385.295 525.294 386.319 522.527C386.622 521.82 386.86 521.088 387.031 520.339C388.263 520.992 388.099 525.166 388.452 526.555C389.146 529.288 389.903 531.943 390.189 534.738C390.731 539.362 391.512 543.956 392.53 548.502C392.722 549.432 393.327 550.635 392.267 551.103C391.427 551.476 389.935 550.744 389.211 550.479C387.062 549.693 385.4 548.339 383.332 547.363C381.321 546.413 379.196 545.907 377.168 544.957C375.021 543.952 372.923 543.042 370.859 541.877C367.698 540.092 364.19 538.697 360.883 537.302C359.222 536.601 357.477 535.939 355.897 535.217C354.647 534.646 351.861 533.986 351.164 532.786L351.033 532.658Z'
                opacity='.4' />
          <path id='Vector_1290' fill='white'
                d='M399.555 563.379C406.534 558.81 408.487 549.449 403.918 542.47C399.349 535.491 389.987 533.538 383.009 538.107C376.03 542.676 374.076 552.038 378.646 559.016C383.215 565.995 392.576 567.948 399.555 563.379Z'
                opacity='.1' />
          <path id='Vector_1291' fill='white'
                d='M403.765 551.574C403.489 553.795 402.56 555.885 401.096 557.579C399.632 559.272 397.699 560.494 395.541 561.089C393.383 561.684 391.097 561.626 388.972 560.921C386.848 560.217 384.979 558.899 383.604 557.132C382.228 555.366 381.407 553.232 381.245 551C381.082 548.767 381.585 546.536 382.691 544.59C383.796 542.643 385.453 541.068 387.454 540.063C389.454 539.059 391.708 538.67 393.929 538.946C396.908 539.316 399.618 540.855 401.462 543.223C403.307 545.591 404.135 548.595 403.765 551.574Z'
                opacity='.2' />
          <path id='Vector_1292' fill='white'
                d='M393.701 542.711C392.809 546.046 392.758 550.374 386.702 550.137C388.375 551.197 390.268 552.176 391.299 554.012C392.256 555.905 392.757 557.999 392.762 560.124C393.343 558.191 393.116 556.29 394.573 554.427C395.102 553.736 395.78 553.175 396.556 552.787C397.332 552.4 398.184 552.196 399.05 552.19C397.266 550.877 395.578 550.836 394.7 548.376C394.354 547.445 394.089 546.485 393.907 545.507C393.743 544.52 394.022 543.06 393.491 542.289L393.701 542.711Z'
                opacity='.7' />
          <path id='Vector_1293' fill='white'
                d='M394.812 550.9C394.384 549.519 393.922 549.679 393.523 550.27C391.522 549.271 391.81 556.077 394.142 554.356C394.933 553.774 395.077 551.756 394.812 550.9Z' />
          <path id='Vector_1294' fill='white'
                d='M359.183 534.34C358.924 536.427 358.052 538.39 356.677 539.981C355.302 541.572 353.486 542.72 351.458 543.279C349.431 543.838 347.284 543.783 345.288 543.121C343.291 542.46 341.536 541.221 340.244 539.562C338.952 537.903 338.181 535.898 338.028 533.801C337.875 531.704 338.348 529.608 339.386 527.78C340.424 525.951 341.982 524.471 343.861 523.527C345.74 522.584 347.857 522.219 349.944 522.478C352.742 522.826 355.287 524.271 357.02 526.496C358.753 528.72 359.531 531.542 359.183 534.34Z'
                opacity='.1' />
          <path id='Vector_1295' fill='white'
                d='M357.832 534.004C357.639 535.552 356.992 537.008 355.971 538.188C354.951 539.368 353.604 540.219 352.1 540.634C350.596 541.048 349.003 541.008 347.522 540.517C346.041 540.026 344.739 539.107 343.781 537.876C342.822 536.645 342.25 535.158 342.137 533.602C342.023 532.046 342.374 530.491 343.144 529.135C343.914 527.778 345.069 526.68 346.463 525.98C347.857 525.28 349.428 525.008 350.976 525.201C352.004 525.328 352.997 525.657 353.898 526.169C354.799 526.68 355.591 527.364 356.228 528.182C356.864 528.999 357.334 529.934 357.609 530.933C357.884 531.932 357.96 532.976 357.832 534.004Z'
                opacity='.2' />
          <path id='Vector_1296' fill='white'
                d='M350.599 527.492C349.955 529.801 349.919 532.798 345.545 532.632C346.753 533.365 348.12 534.043 348.865 535.314C349.556 536.625 349.918 538.074 349.922 539.546C350.341 538.207 350.178 536.891 351.229 535.602C351.612 535.123 352.101 534.735 352.662 534.467C353.222 534.198 353.838 534.057 354.463 534.053C353.175 533.144 351.956 533.115 351.322 531.412C351.072 530.768 350.88 530.103 350.749 529.426C350.628 528.743 350.831 527.732 350.449 527.198L350.599 527.492Z'
                opacity='.7' />
          <path id='Vector_1297' fill='white'
                d='M390.758 494.517C390.566 495.998 389.919 497.391 388.898 498.52C387.878 499.65 386.531 500.464 385.027 500.861C383.523 501.257 381.93 501.219 380.449 500.749C378.968 500.28 377.665 499.401 376.707 498.224C375.748 497.046 375.176 495.624 375.062 494.135C374.949 492.647 375.299 491.159 376.07 489.862C376.84 488.564 377.995 487.514 379.39 486.844C380.784 486.174 382.354 485.915 383.903 486.099C385.979 486.346 387.867 487.371 389.153 488.95C390.439 490.529 391.016 492.531 390.758 494.517Z'
                opacity='.1' />
          <path id='Vector_1298' fill='white'
                d='M389.402 494.263C389.259 495.341 388.781 496.354 388.027 497.175C387.273 497.996 386.277 498.589 385.166 498.877C384.054 499.166 382.876 499.137 381.781 498.796C380.687 498.454 379.724 497.815 379.016 496.959C378.307 496.102 377.884 495.068 377.801 493.985C377.717 492.902 377.976 491.821 378.546 490.877C379.115 489.933 379.969 489.169 381 488.682C382.03 488.196 383.191 488.007 384.336 488.141C385.095 488.23 385.829 488.459 386.495 488.815C387.161 489.17 387.746 489.646 388.216 490.215C388.686 490.783 389.033 491.433 389.237 492.128C389.44 492.823 389.496 493.548 389.402 494.263Z'
                opacity='.2' />
          <path id='Vector_1299' fill='white'
                d='M384.029 489.682C383.583 491.35 383.558 493.513 380.529 493.395C381.366 493.925 382.312 494.415 382.827 495.332C383.306 496.279 383.557 497.326 383.559 498.388C383.849 497.422 383.736 496.472 384.464 495.54C384.729 495.194 385.068 494.914 385.456 494.72C385.844 494.526 386.27 494.424 386.702 494.422C385.811 493.765 384.966 493.744 384.528 492.514C384.355 492.049 384.222 491.569 384.131 491.08C384.048 490.586 384.188 489.856 383.923 489.471L384.029 489.682Z'
                opacity='.7' />
          <path id='Vector_1300' fill='white'
                d='M350.942 532.913C350.621 531.729 350.274 531.867 349.975 532.374C348.474 531.518 348.69 537.35 350.44 535.876C351.033 535.375 351.14 533.646 350.942 532.913Z' />
        </g>;

      case 10:
        return <g id='Mini gem_23'>
          <path id='Vector_1301' fill={`#${diamondColors[color][0]}`}
                d='M486.135 521.174L523 555.564L495.58 581.241L486.134 522.401L450.975 562.304L489.546 618.578L495.58 581.241L486.135 521.174Z' />
          <path id='Vector_1302' fill={`#${diamondColors[color][1]}`}
                d='M486.073 521.174L450.975 562.107L495.562 581.537L486.073 521.174Z' />
          <path id='Vector_1303' fill='white'
                d='M486.074 521.174L485.095 522.315L494.048 579.273L451.976 560.94L450.975 562.107L495.562 581.537L486.074 521.174Z' />
          <path id='Vector_1304' fill={`#${diamondColors[color][2]}`}
                d='M495.451 581.186L489.388 618.578L523 555.471L495.451 581.186Z' />
          <path id='Vector_1305' fill={`#${diamondColors[color][2]}`}
                d='M495.451 581.186L489.388 618.578L523 555.471L495.451 581.186Z' />
          <path id='Vector_1306' fill='white' d='M450.975 562.331L490.76 550.3L486.191 521.174L450.975 562.331Z'
                opacity='.2' />
          <path id='Vector_1307' fill='white' d='M490.542 550.204L523 555.471L485.959 521.174L490.542 549.672V550.204Z'
                opacity='.2' />
          <path id='Vector_1308' fill={`#${diamondColors[color][3]}`}
                d='M492.932 596.759C492.998 596.353 512.586 565.527 512.586 565.527L523 556.157L499.345 599.93L489.388 618.578L492.932 596.759Z'
                opacity='.5' />
          <path id='Vector_1309' fill={`#${diamondColors[color][3]}`}
                d='M496.638 581.187L521.827 557.673L523 555.471L495.451 581.187L489.388 618.578L491.093 615.376L496.638 581.187Z' />
          <path id='Vector_1310' fill={`#${diamondColors[color][3]}`}
                d='M463.949 581.275L494.876 581.537L450.975 562.331L463.949 581.275Z' opacity='.5' />
          <path id='Vector_1311' fill='white'
                d='M453.033 561.617C454.386 563.004 457.714 563.2 459.597 563.561C463.522 564.409 467.569 564.553 471.545 563.987C476.363 563.21 480.147 561.092 483.887 558.111C486.33 556.165 487.295 554.252 488.319 551.485C488.622 550.778 488.86 550.046 489.031 549.298C490.263 549.95 490.099 554.124 490.452 555.514C491.146 558.247 491.903 560.902 492.188 563.696C492.731 568.321 493.512 572.915 494.53 577.46C494.722 578.39 495.327 579.593 494.267 580.062C493.427 580.434 491.935 579.702 491.211 579.437C489.062 578.651 487.4 577.298 485.332 576.321C483.321 575.372 481.196 574.866 479.168 573.916C477.021 572.911 474.923 572.001 472.859 570.835C469.698 569.051 466.19 567.656 462.883 566.261C461.222 565.56 459.477 564.898 457.897 564.176C456.647 563.604 453.861 562.945 453.163 561.745L453.033 561.617Z'
                opacity='.4' />
          <path id='Vector_1312' fill='white'
                d='M501.555 592.338C508.534 587.769 510.487 578.407 505.918 571.429C501.349 564.45 491.987 562.497 485.009 567.066C478.03 571.635 476.076 580.996 480.646 587.975C485.215 594.954 494.576 596.907 501.555 592.338Z'
                opacity='.1' />
          <path id='Vector_1313' fill='white'
                d='M505.765 580.533C505.489 582.754 504.56 584.844 503.096 586.537C501.632 588.231 499.699 589.452 497.541 590.047C495.383 590.642 493.097 590.584 490.972 589.88C488.848 589.176 486.979 587.857 485.604 586.091C484.228 584.325 483.407 582.191 483.245 579.958C483.082 577.726 483.585 575.495 484.691 573.548C485.796 571.602 487.453 570.026 489.454 569.022C491.454 568.017 493.708 567.628 495.929 567.905C498.908 568.275 501.618 569.813 503.462 572.182C505.307 574.55 506.135 577.554 505.765 580.533Z'
                opacity='.2' />
          <path id='Vector_1314' fill='white'
                d='M495.701 571.67C494.809 575.005 494.758 579.333 488.702 579.096C490.375 580.155 492.268 581.135 493.299 582.971C494.256 584.863 494.757 586.957 494.762 589.083C495.343 587.15 495.116 585.249 496.573 583.386C497.102 582.694 497.78 582.133 498.556 581.746C499.332 581.358 500.184 581.154 501.05 581.149C499.266 579.836 497.578 579.794 496.7 577.335C496.354 576.404 496.089 575.444 495.907 574.466C495.743 573.479 496.022 572.018 495.491 571.248L495.701 571.67Z'
                opacity='.7' />
          <path id='Vector_1315' fill='white'
                d='M496.812 579.859C496.384 578.477 495.922 578.638 495.523 579.228C493.522 578.229 493.81 585.035 496.142 583.315C496.933 582.732 497.077 580.715 496.812 579.859Z' />
          <path id='Vector_1316' fill='white'
                d='M461.183 563.299C460.924 565.385 460.052 567.349 458.677 568.94C457.302 570.531 455.486 571.678 453.458 572.237C451.431 572.796 449.284 572.742 447.288 572.08C445.291 571.419 443.536 570.18 442.244 568.521C440.952 566.862 440.181 564.857 440.028 562.76C439.875 560.662 440.348 558.567 441.386 556.738C442.424 554.909 443.982 553.43 445.861 552.486C447.74 551.542 449.857 551.177 451.944 551.437C454.742 551.785 457.287 553.23 459.02 555.454C460.753 557.679 461.531 560.5 461.183 563.299Z'
                opacity='.1' />
          <path id='Vector_1317' fill='white'
                d='M459.832 562.963C459.639 564.511 458.992 565.967 457.971 567.147C456.951 568.327 455.604 569.178 454.1 569.593C452.596 570.007 451.003 569.966 449.522 569.475C448.041 568.984 446.739 568.066 445.781 566.835C444.822 565.604 444.25 564.117 444.137 562.561C444.023 561.005 444.374 559.45 445.144 558.093C445.914 556.737 447.069 555.639 448.463 554.939C449.857 554.238 451.428 553.967 452.976 554.159C454.004 554.287 454.997 554.616 455.898 555.127C456.799 555.639 457.591 556.323 458.228 557.14C458.864 557.958 459.334 558.893 459.609 559.892C459.884 560.891 459.96 561.934 459.832 562.963Z'
                opacity='.2' />
          <path id='Vector_1318' fill='white'
                d='M452.599 556.451C451.955 558.76 451.919 561.756 447.545 561.591C448.753 562.324 450.12 563.002 450.865 564.273C451.556 565.583 451.918 567.033 451.922 568.504C452.341 567.166 452.178 565.85 453.229 564.56C453.612 564.082 454.101 563.693 454.662 563.425C455.222 563.157 455.838 563.016 456.463 563.012C455.175 562.103 453.956 562.074 453.322 560.371C453.072 559.726 452.88 559.062 452.749 558.385C452.628 557.702 452.831 556.691 452.449 556.157L452.599 556.451Z'
                opacity='.7' />
          <path id='Vector_1319' fill='white'
                d='M492.758 523.476C492.566 524.957 491.919 526.35 490.898 527.479C489.878 528.608 488.531 529.423 487.027 529.819C485.523 530.216 483.93 530.177 482.449 529.708C480.967 529.239 479.665 528.36 478.707 527.182C477.748 526.005 477.176 524.582 477.062 523.094C476.949 521.605 477.299 520.118 478.07 518.82C478.84 517.522 479.995 516.472 481.39 515.803C482.784 515.133 484.354 514.874 485.903 515.058C487.979 515.304 489.867 516.33 491.153 517.909C492.439 519.487 493.016 521.49 492.758 523.476Z'
                opacity='.1' />
          <path id='Vector_1320' fill='white'
                d='M491.402 523.222C491.259 524.299 490.781 525.312 490.027 526.134C489.273 526.955 488.277 527.547 487.165 527.836C486.054 528.124 484.876 528.096 483.781 527.754C482.687 527.413 481.724 526.774 481.016 525.917C480.307 525.061 479.884 524.026 479.8 522.944C479.717 521.861 479.976 520.779 480.546 519.836C481.115 518.892 481.969 518.128 483 517.641C484.03 517.154 485.191 516.966 486.336 517.1C487.095 517.189 487.829 517.417 488.495 517.773C489.161 518.129 489.746 518.605 490.216 519.173C490.686 519.742 491.033 520.392 491.237 521.087C491.44 521.781 491.496 522.507 491.402 523.222Z'
                opacity='.2' />
          <path id='Vector_1321' fill='white'
                d='M486.029 518.64C485.583 520.308 485.558 522.472 482.529 522.353C483.366 522.884 484.312 523.373 484.827 524.291C485.306 525.237 485.557 526.284 485.559 527.347C485.849 526.381 485.736 525.43 486.464 524.498C486.729 524.153 487.068 523.872 487.456 523.679C487.844 523.485 488.27 523.383 488.702 523.38C487.811 522.723 486.966 522.703 486.528 521.473C486.355 521.007 486.222 520.527 486.131 520.038C486.048 519.545 486.188 518.815 485.923 518.43L486.029 518.64Z'
                opacity='.7' />
          <path id='Vector_1322' fill='white'
                d='M452.942 561.871C452.621 560.688 452.274 560.825 451.975 561.332C450.474 560.477 450.69 566.309 452.44 564.834C453.033 564.334 453.14 562.604 452.942 561.871Z' />
        </g>;


      case 11:
        return <g id='Mini gem_9'>
          <path id='Vector_683' fill={`#${diamondColors[color][0]}`}
                d='M831.341 659.201L868.371 693.745L840.829 719.537L831.34 660.434L796.024 700.516L834.767 757.041L840.829 719.537L831.341 659.201Z' />
          <path id='Vector_684' fill={`#${diamondColors[color][1]}`}
                d='M831.279 659.201L796.024 700.318L840.81 719.834L831.279 659.201Z' />
          <path id='Vector_685' fill='white'
                d='M831.28 659.201L830.296 660.348L839.289 717.561L797.03 699.145L796.024 700.318L840.81 719.834L831.28 659.201Z' />
          <path id='Vector_686' fill={`#${diamondColors[color][2]}`}
                d='M840.699 719.482L834.609 757.041L868.371 693.652L840.699 719.482Z' />
          <path id='Vector_687' fill={`#${diamondColors[color][2]}`}
                d='M840.699 719.482L834.609 757.041L868.371 693.652L840.699 719.482Z' />
          <path id='Vector_688' fill='white' d='M796.024 700.542L835.987 688.458L831.398 659.201L796.024 700.542Z'
                opacity='.2' />
          <path id='Vector_689' fill='white'
                d='M835.768 688.361L868.371 693.652L831.164 659.201L835.768 687.827V688.361Z' opacity='.2' />
          <path id='Vector_690' fill={`#${diamondColors[color][3]}`}
                d='M838.169 735.124C838.235 734.716 857.91 703.753 857.91 703.753L868.371 694.341L844.611 738.309L834.609 757.041L838.169 735.124Z'
                opacity='.5' />
          <path id='Vector_691' fill={`#${diamondColors[color][3]}`}
                d='M841.891 719.483L867.193 695.864L868.371 693.652L840.699 719.483L834.609 757.041L836.322 753.825L841.891 719.483Z' />
          <path id='Vector_692' fill={`#${diamondColors[color][3]}`}
                d='M809.056 719.571L840.121 719.834L796.024 700.542L809.056 719.571Z' opacity='.5' />
          <path id='Vector_693' fill='white'
                d='M798.091 699.825C799.45 701.218 802.793 701.415 804.685 701.778C808.627 702.63 812.692 702.775 816.686 702.206C821.526 701.426 825.327 699.298 829.083 696.304C831.537 694.349 832.507 692.428 833.535 689.648C833.839 688.938 834.078 688.203 834.25 687.451C835.487 688.107 835.323 692.299 835.678 693.695C836.375 696.44 837.135 699.107 837.422 701.914C837.966 706.559 838.751 711.174 839.773 715.74C839.966 716.674 840.574 717.882 839.509 718.352C838.666 718.727 837.167 717.991 836.439 717.725C834.281 716.936 832.612 715.576 830.534 714.595C828.515 713.642 826.38 713.133 824.343 712.179C822.186 711.169 820.079 710.256 818.006 709.085C814.831 707.292 811.307 705.891 807.985 704.49C806.317 703.786 804.564 703.121 802.977 702.396C801.721 701.821 798.923 701.159 798.222 699.954L798.091 699.825Z'
                opacity='.4' />
          <path id='Vector_694' fill='white'
                d='M846.83 730.683C853.84 726.094 855.802 716.691 851.212 709.681C846.623 702.671 837.22 700.709 830.21 705.298C823.2 709.888 821.238 719.291 825.827 726.301C830.417 733.311 839.82 735.273 846.83 730.683Z'
                opacity='.1' />
          <path id='Vector_695' fill='white'
                d='M851.059 718.825C850.781 721.057 849.848 723.156 848.378 724.857C846.908 726.558 844.966 727.785 842.798 728.383C840.631 728.98 838.335 728.922 836.2 728.215C834.066 727.507 832.189 726.183 830.808 724.409C829.426 722.635 828.601 720.491 828.438 718.248C828.275 716.006 828.78 713.765 829.89 711.81C831.001 709.854 832.666 708.272 834.675 707.263C836.684 706.254 838.948 705.864 841.179 706.141C844.171 706.513 846.893 708.058 848.746 710.437C850.599 712.816 851.431 715.833 851.059 718.825Z'
                opacity='.2' />
          <path id='Vector_696' fill='white'
                d='M840.95 709.923C840.054 713.273 840.003 717.62 833.92 717.382C835.6 718.446 837.501 719.431 838.537 721.274C839.498 723.175 840.002 725.279 840.007 727.414C840.59 725.472 840.363 723.563 841.825 721.691C842.357 720.997 843.039 720.433 843.818 720.044C844.597 719.655 845.453 719.45 846.322 719.444C844.53 718.126 842.835 718.084 841.954 715.614C841.606 714.678 841.339 713.714 841.157 712.731C840.992 711.74 841.272 710.273 840.739 709.499L840.95 709.923Z'
                opacity='.7' />
          <path id='Vector_697' fill='white'
                d='M842.066 718.149C841.636 716.761 841.172 716.922 840.771 717.515C838.761 716.512 839.051 723.348 841.393 721.62C842.187 721.035 842.332 719.008 842.066 718.149Z' />
          <path id='Vector_698' fill='white'
                d='M806.278 701.514C806.017 703.611 805.141 705.583 803.76 707.181C802.379 708.779 800.555 709.932 798.519 710.493C796.482 711.055 794.325 711 792.32 710.335C790.315 709.671 788.552 708.427 787.254 706.76C785.956 705.094 785.182 703.08 785.028 700.973C784.875 698.866 785.349 696.762 786.392 694.925C787.435 693.088 789 691.601 790.887 690.654C792.775 689.706 794.901 689.339 796.997 689.599C799.808 689.949 802.365 691.401 804.105 693.635C805.845 695.87 806.627 698.704 806.278 701.514Z'
                opacity='.1' />
          <path id='Vector_699' fill='white'
                d='M804.921 701.177C804.727 702.732 804.077 704.194 803.052 705.38C802.027 706.565 800.674 707.42 799.163 707.836C797.652 708.253 796.052 708.212 794.565 707.719C793.077 707.226 791.769 706.303 790.806 705.066C789.844 703.83 789.269 702.336 789.155 700.773C789.041 699.21 789.393 697.649 790.167 696.286C790.94 694.923 792.101 693.82 793.501 693.117C794.901 692.414 796.479 692.141 798.034 692.334C799.067 692.462 800.064 692.793 800.969 693.307C801.874 693.82 802.67 694.508 803.309 695.329C803.949 696.15 804.42 697.089 804.697 698.092C804.973 699.096 805.049 700.144 804.921 701.177Z'
                opacity='.2' />
          <path id='Vector_700' fill='white'
                d='M797.656 694.636C797.009 696.955 796.972 699.965 792.579 699.799C793.792 700.535 795.165 701.217 795.913 702.493C796.608 703.809 796.971 705.265 796.975 706.743C797.397 705.399 797.232 704.077 798.288 702.782C798.672 702.301 799.165 701.911 799.727 701.642C800.29 701.372 800.909 701.23 801.536 701.226C800.243 700.313 799.018 700.284 798.381 698.574C798.13 697.926 797.938 697.259 797.806 696.579C797.684 695.892 797.889 694.877 797.504 694.341L797.656 694.636Z'
                opacity='.7' />
          <path id='Vector_701' fill='white'
                d='M837.994 661.513C837.801 663.001 837.15 664.4 836.126 665.535C835.101 666.669 833.748 667.487 832.237 667.886C830.726 668.284 829.126 668.245 827.638 667.774C826.151 667.302 824.843 666.419 823.88 665.237C822.916 664.054 822.342 662.625 822.228 661.13C822.114 659.635 822.466 658.141 823.24 656.837C824.014 655.534 825.174 654.479 826.575 653.806C827.975 653.133 829.553 652.873 831.108 653.058C833.193 653.306 835.09 654.336 836.382 655.922C837.673 657.507 838.253 659.519 837.994 661.513Z'
                opacity='.1' />
          <path id='Vector_702' fill='white'
                d='M836.631 661.259C836.488 662.341 836.008 663.359 835.251 664.183C834.493 665.008 833.493 665.603 832.376 665.893C831.26 666.183 830.076 666.154 828.977 665.811C827.877 665.468 826.911 664.826 826.199 663.966C825.487 663.106 825.062 662.066 824.978 660.979C824.894 659.892 825.155 658.805 825.727 657.857C826.299 656.909 827.157 656.142 828.192 655.653C829.227 655.164 830.393 654.975 831.543 655.109C832.306 655.198 833.043 655.428 833.712 655.786C834.38 656.143 834.968 656.621 835.44 657.192C835.913 657.763 836.261 658.416 836.466 659.114C836.67 659.812 836.726 660.54 836.631 661.259Z'
                opacity='.2' />
          <path id='Vector_703' fill='white'
                d='M831.235 656.657C830.787 658.332 830.761 660.505 827.719 660.386C828.559 660.919 829.51 661.411 830.027 662.332C830.508 663.283 830.76 664.335 830.762 665.402C831.054 664.431 830.94 663.477 831.672 662.541C831.938 662.194 832.278 661.912 832.668 661.718C833.058 661.523 833.486 661.421 833.92 661.418C833.024 660.758 832.176 660.737 831.735 659.502C831.562 659.034 831.429 658.552 831.337 658.061C831.254 657.565 831.395 656.832 831.129 656.445L831.235 656.657Z'
                opacity='.7' />
          <path id='Vector_704' fill='white'
                d='M798 700.081C797.677 698.892 797.329 699.03 797.029 699.54C795.521 698.68 795.738 704.538 797.495 703.057C798.091 702.554 798.199 700.817 798 700.081Z' />
        </g>;
      case 12:
        return <g id='Mini gem_10'>
          <path id='Vector_705' fill={`#${diamondColors[color][0]}`}
                d='M1091.14 699.512L1136 741.36L1102.63 772.607L1091.14 701.006L1048.36 749.563L1095.29 818.041L1102.63 772.607L1091.14 699.512Z' />
          <path id='Vector_706' fill={`#${diamondColors[color][1]}`}
                d='M1091.07 699.512L1048.36 749.323L1102.61 772.967L1091.07 699.512Z' />
          <path id='Vector_707' fill='white'
                d='M1091.07 699.512L1089.87 700.902L1100.77 770.212L1049.57 747.903L1048.36 749.323L1102.61 772.967L1091.07 699.512Z' />
          <path id='Vector_708' fill={`#${diamondColors[color][2]}`}
                d='M1102.48 772.54L1095.1 818.041L1136 741.248L1102.48 772.54Z' />
          <path id='Vector_709' fill={`#${diamondColors[color][2]}`}
                d='M1102.48 772.54L1095.1 818.041L1136 741.248L1102.48 772.54Z' />
          <path id='Vector_710' fill='white' d='M1048.36 749.595L1096.77 734.956L1091.21 699.512L1048.36 749.595Z'
                opacity='.2' />
          <path id='Vector_711' fill='white' d='M1096.5 734.838L1136 741.248L1090.93 699.512L1096.5 734.191V734.838Z'
                opacity='.2' />
          <path id='Vector_712' fill={`#${diamondColors[color][3]}`}
                d='M1099.41 791.49C1099.49 790.995 1123.33 753.485 1123.33 753.485L1136 742.083L1107.22 795.348L1095.1 818.041L1099.41 791.49Z'
                opacity='.5' />
          <path id='Vector_713' fill={`#${diamondColors[color][3]}`}
                d='M1103.92 772.541L1134.57 743.928L1136 741.248L1102.48 772.541L1095.1 818.041L1097.17 814.144L1103.92 772.541Z' />
          <path id='Vector_714' fill={`#${diamondColors[color][3]}`}
                d='M1064.14 772.647L1101.78 772.967L1048.36 749.595L1064.14 772.647Z' opacity='.5' />
          <path id='Vector_715' fill='white'
                d='M1050.86 748.727C1052.51 750.414 1056.56 750.653 1058.85 751.093C1063.62 752.124 1068.55 752.3 1073.39 751.61C1079.25 750.665 1083.85 748.088 1088.41 744.46C1091.38 742.092 1092.55 739.765 1093.8 736.398C1094.17 735.538 1094.46 734.647 1094.66 733.736C1096.16 734.53 1095.96 739.609 1096.39 741.3C1097.24 744.626 1098.16 747.856 1098.51 751.257C1099.17 756.884 1100.12 762.475 1101.36 768.006C1101.59 769.138 1102.33 770.602 1101.04 771.171C1100.01 771.625 1098.2 770.734 1097.32 770.412C1094.7 769.455 1092.68 767.808 1090.16 766.62C1087.72 765.465 1085.13 764.849 1082.66 763.693C1080.05 762.47 1077.5 761.363 1074.98 759.944C1071.14 757.773 1066.87 756.075 1062.85 754.378C1060.82 753.525 1058.7 752.719 1056.78 751.841C1055.26 751.145 1051.87 750.342 1051.02 748.882L1050.86 748.727Z'
                opacity='.4' />
          <path id='Vector_716' fill='white'
                d='M1109.9 786.11C1118.4 780.55 1120.77 769.158 1115.21 760.666C1109.65 752.174 1098.26 749.797 1089.77 755.357C1081.28 760.917 1078.9 772.309 1084.46 780.801C1090.02 789.293 1101.41 791.67 1109.9 786.11Z'
                opacity='.1' />
          <path id='Vector_717' fill='white'
                d='M1115.03 771.744C1114.69 774.448 1113.56 776.99 1111.78 779.051C1110 781.112 1107.65 782.599 1105.02 783.323C1102.39 784.047 1099.61 783.976 1097.03 783.119C1094.44 782.262 1092.17 780.657 1090.49 778.508C1088.82 776.359 1087.82 773.762 1087.62 771.045C1087.43 768.329 1088.04 765.614 1089.38 763.245C1090.73 760.877 1092.74 758.96 1095.18 757.737C1097.61 756.515 1100.36 756.042 1103.06 756.378C1106.68 756.829 1109.98 758.701 1112.23 761.582C1114.47 764.464 1115.48 768.12 1115.03 771.744Z'
                opacity='.2' />
          <path id='Vector_718' fill='white'
                d='M1102.78 760.96C1101.7 765.018 1101.63 770.285 1094.26 769.996C1096.3 771.285 1098.6 772.478 1099.86 774.711C1101.02 777.014 1101.63 779.562 1101.64 782.149C1102.35 779.797 1102.07 777.484 1103.84 775.216C1104.49 774.375 1105.31 773.693 1106.26 773.221C1107.2 772.749 1108.24 772.501 1109.29 772.494C1107.12 770.897 1105.06 770.846 1104 767.854C1103.58 766.72 1103.25 765.552 1103.03 764.362C1102.83 763.161 1103.17 761.384 1102.53 760.446L1102.78 760.96Z'
                opacity='.7' />
          <path id='Vector_719' fill='white'
                d='M1104.13 770.925C1103.61 769.244 1103.05 769.439 1102.56 770.157C1100.13 768.942 1100.48 777.224 1103.32 775.13C1104.28 774.421 1104.46 771.966 1104.13 770.925Z' />
          <path id='Vector_720' fill='white'
                d='M1060.78 750.773C1060.46 753.312 1059.4 755.701 1057.73 757.638C1056.05 759.574 1053.84 760.97 1051.38 761.65C1048.91 762.33 1046.3 762.264 1043.87 761.459C1041.44 760.654 1039.3 759.147 1037.73 757.128C1036.16 755.109 1035.22 752.669 1035.03 750.117C1034.85 747.565 1035.42 745.015 1036.69 742.79C1037.95 740.565 1039.85 738.764 1042.13 737.616C1044.42 736.467 1046.99 736.023 1049.53 736.339C1052.94 736.762 1056.04 738.521 1058.14 741.228C1060.25 743.934 1061.2 747.368 1060.78 750.773Z'
                opacity='.1' />
          <path id='Vector_721' fill='white'
                d='M1059.13 750.364C1058.9 752.248 1058.11 754.02 1056.87 755.456C1055.63 756.892 1053.99 757.928 1052.16 758.432C1050.33 758.936 1048.39 758.887 1046.59 758.289C1044.79 757.692 1043.2 756.574 1042.03 755.076C1040.87 753.578 1040.17 751.768 1040.03 749.875C1039.9 747.982 1040.32 746.09 1041.26 744.439C1042.2 742.788 1043.6 741.452 1045.3 740.6C1046.99 739.748 1048.91 739.418 1050.79 739.652C1052.04 739.807 1053.25 740.207 1054.35 740.83C1055.44 741.452 1056.41 742.285 1057.18 743.279C1057.96 744.274 1058.53 745.412 1058.86 746.627C1059.2 747.843 1059.29 749.113 1059.13 750.364Z'
                opacity='.2' />
          <path id='Vector_722' fill='white'
                d='M1050.33 742.44C1049.55 745.25 1049.5 748.896 1044.18 748.695C1045.65 749.587 1047.31 750.412 1048.22 751.959C1049.06 753.553 1049.5 755.317 1049.51 757.107C1050.02 755.479 1049.82 753.878 1051.1 752.308C1051.56 751.726 1052.16 751.253 1052.84 750.927C1053.52 750.601 1054.27 750.429 1055.03 750.424C1053.47 749.318 1051.98 749.282 1051.21 747.211C1050.91 746.426 1050.67 745.617 1050.51 744.794C1050.37 743.962 1050.61 742.732 1050.15 742.083L1050.33 742.44Z'
                opacity='.7' />
          <path id='Vector_723' fill='white'
                d='M1099.2 702.314C1098.97 704.116 1098.18 705.811 1096.94 707.185C1095.7 708.559 1094.06 709.55 1092.23 710.033C1090.4 710.516 1088.46 710.469 1086.65 709.898C1084.85 709.326 1083.27 708.257 1082.1 706.824C1080.93 705.392 1080.24 703.66 1080.1 701.849C1079.96 700.038 1080.39 698.228 1081.33 696.649C1082.26 695.069 1083.67 693.792 1085.37 692.977C1087.06 692.162 1088.97 691.846 1090.86 692.07C1093.38 692.37 1095.68 693.618 1097.25 695.54C1098.81 697.461 1099.51 699.897 1099.2 702.314Z'
                opacity='.1' />
          <path id='Vector_724' fill='white'
                d='M1097.55 702.005C1097.38 703.316 1096.79 704.549 1095.88 705.548C1094.96 706.548 1093.75 707.268 1092.39 707.619C1091.04 707.97 1089.61 707.936 1088.28 707.52C1086.94 707.105 1085.77 706.327 1084.91 705.285C1084.05 704.243 1083.53 702.984 1083.43 701.666C1083.33 700.349 1083.65 699.033 1084.34 697.884C1085.03 696.736 1086.07 695.806 1087.32 695.214C1088.58 694.621 1089.99 694.392 1091.38 694.555C1092.31 694.663 1093.2 694.942 1094.01 695.375C1094.82 695.808 1095.53 696.386 1096.11 697.078C1096.68 697.77 1097.1 698.561 1097.35 699.407C1097.6 700.252 1097.66 701.135 1097.55 702.005Z'
                opacity='.2' />
          <path id='Vector_725' fill='white'
                d='M1091.01 696.43C1090.47 698.46 1090.44 701.093 1086.75 700.948C1087.77 701.593 1088.92 702.189 1089.55 703.306C1090.13 704.457 1090.44 705.732 1090.44 707.025C1090.79 705.849 1090.65 704.693 1091.54 703.558C1091.86 703.138 1092.28 702.797 1092.75 702.561C1093.22 702.325 1093.74 702.201 1094.26 702.198C1093.18 701.398 1092.15 701.373 1091.62 699.877C1091.41 699.31 1091.25 698.726 1091.14 698.131C1091.03 697.53 1091.21 696.642 1090.88 696.174L1091.01 696.43Z'
                opacity='.7' />
          <path id='Vector_726' fill='white'
                d='M1050.75 749.036C1050.36 747.596 1049.94 747.763 1049.57 748.381C1047.75 747.339 1048.01 754.436 1050.14 752.642C1050.86 752.033 1050.99 749.928 1050.75 749.036Z' />
        </g>;

      case 13:
        return <g id='Mini gem_11'>
          <path id='Vector_727' fill={`#${diamondColors[color][0]}`}
                d='M907.797 644.058L938 672.233L915.536 693.27L907.797 645.064L878.992 677.755L910.592 723.859L915.536 693.27L907.797 644.058Z' />
          <path id='Vector_728' fill={`#${diamondColors[color][1]}`}
                d='M907.747 644.058L878.992 677.594L915.521 693.512L907.747 644.058Z' />
          <path id='Vector_729' fill='white'
                d='M907.747 644.058L906.945 644.993L914.28 691.658L879.812 676.637L878.992 677.594L915.521 693.512L907.747 644.058Z' />
          <path id='Vector_730' fill={`#${diamondColors[color][2]}`}
                d='M915.43 693.225L910.463 723.859L938 672.157L915.43 693.225Z' />
          <path id='Vector_731' fill={`#${diamondColors[color][2]}`}
                d='M915.43 693.225L910.463 723.859L938 672.157L915.43 693.225Z' />
          <path id='Vector_732' fill='white' d='M878.992 677.777L911.587 667.921L907.844 644.058L878.992 677.777Z'
                opacity='.2' />
          <path id='Vector_733' fill='white' d='M911.408 667.842L938 672.157L907.653 644.058L911.408 667.406V667.842Z'
                opacity='.2' />
          <path id='Vector_734' fill={`#${diamondColors[color][3]}`}
                d='M913.366 705.983C913.42 705.65 929.468 680.396 929.468 680.396L938 672.719L918.62 708.581L910.463 723.859L913.366 705.983Z'
                opacity='.5' />
          <path id='Vector_735' fill={`#${diamondColors[color][3]}`}
                d='M916.402 693.226L937.039 673.961L938 672.157L915.43 693.226L910.463 723.859L911.86 721.236L916.402 693.226Z' />
          <path id='Vector_736' fill={`#${diamondColors[color][3]}`}
                d='M889.621 693.297L914.959 693.512L878.992 677.777L889.621 693.297Z' opacity='.5' />
          <path id='Vector_737' fill='white'
                d='M880.678 677.192C881.786 678.328 884.512 678.489 886.056 678.785C889.271 679.48 892.587 679.598 895.844 679.134C899.791 678.497 902.892 676.762 905.956 674.32C907.957 672.725 908.748 671.158 909.586 668.891C909.835 668.312 910.03 667.713 910.17 667.099C911.179 667.634 911.045 671.054 911.334 672.192C911.903 674.431 912.523 676.606 912.757 678.896C913.201 682.685 913.841 686.448 914.675 690.172C914.832 690.934 915.328 691.92 914.459 692.303C913.772 692.609 912.549 692.009 911.956 691.792C910.195 691.148 908.834 690.039 907.139 689.239C905.492 688.461 903.751 688.047 902.089 687.268C900.33 686.445 898.611 685.7 896.92 684.745C894.331 683.283 891.457 682.14 888.748 680.997C887.387 680.422 885.957 679.88 884.663 679.289C883.638 678.82 881.356 678.28 880.785 677.297L880.678 677.192Z'
                opacity='.4' />
          <path id='Vector_738' fill='white'
                d='M920.43 702.361C926.148 698.618 927.748 690.948 924.005 685.231C920.262 679.513 912.592 677.913 906.874 681.656C901.157 685.4 899.557 693.069 903.3 698.787C907.043 704.504 914.713 706.105 920.43 702.361Z'
                opacity='.1' />
          <path id='Vector_739' fill='white'
                d='M923.88 692.689C923.653 694.509 922.893 696.221 921.693 697.609C920.494 698.996 918.91 699.997 917.142 700.485C915.374 700.972 913.501 700.924 911.761 700.347C910.02 699.77 908.489 698.69 907.362 697.243C906.235 695.796 905.563 694.048 905.429 692.219C905.296 690.39 905.709 688.562 906.614 686.967C907.52 685.372 908.878 684.082 910.517 683.259C912.155 682.436 914.002 682.117 915.822 682.344C918.262 682.647 920.482 683.907 921.993 685.848C923.504 687.788 924.183 690.249 923.88 692.689Z'
                opacity='.2' />
          <path id='Vector_740' fill='white'
                d='M915.634 685.428C914.904 688.161 914.862 691.706 909.901 691.512C911.271 692.38 912.822 693.183 913.667 694.687C914.451 696.237 914.861 697.953 914.866 699.694C915.341 698.111 915.155 696.553 916.349 695.027C916.783 694.46 917.338 694.001 917.974 693.683C918.609 693.366 919.308 693.199 920.017 693.194C918.555 692.119 917.172 692.085 916.453 690.07C916.17 689.307 915.952 688.52 915.803 687.719C915.669 686.91 915.897 685.714 915.463 685.083L915.634 685.428Z'
                opacity='.7' />
          <path id='Vector_741' fill='white'
                d='M916.545 692.138C916.194 691.006 915.815 691.137 915.489 691.621C913.849 690.802 914.086 696.378 915.996 694.969C916.644 694.492 916.762 692.838 916.545 692.138Z' />
          <path id='Vector_742' fill='white'
                d='M887.355 678.57C887.143 680.28 886.428 681.888 885.301 683.192C884.175 684.495 882.687 685.435 881.026 685.893C879.365 686.351 877.606 686.306 875.971 685.764C874.335 685.222 872.897 684.208 871.839 682.849C870.78 681.489 870.148 679.847 870.023 678.128C869.898 676.41 870.285 674.693 871.136 673.195C871.986 671.697 873.262 670.485 874.802 669.711C876.341 668.938 878.076 668.639 879.785 668.852C882.078 669.137 884.163 670.321 885.583 672.143C887.002 673.966 887.64 676.277 887.355 678.57Z'
                opacity='.1' />
          <path id='Vector_743' fill='white'
                d='M886.248 678.295C886.09 679.563 885.559 680.756 884.724 681.723C883.888 682.69 882.784 683.387 881.552 683.726C880.32 684.066 879.014 684.033 877.801 683.63C876.588 683.228 875.521 682.475 874.736 681.467C873.951 680.459 873.482 679.24 873.389 677.965C873.296 676.691 873.583 675.417 874.214 674.305C874.845 673.194 875.792 672.294 876.934 671.721C878.076 671.147 879.362 670.925 880.631 671.082C881.473 671.187 882.287 671.456 883.025 671.875C883.763 672.295 884.412 672.855 884.934 673.525C885.455 674.194 885.84 674.96 886.065 675.779C886.291 676.597 886.353 677.452 886.248 678.295Z'
                opacity='.2' />
          <path id='Vector_744' fill='white'
                d='M880.322 672.96C879.795 674.852 879.765 677.306 876.182 677.171C877.171 677.771 878.291 678.327 878.901 679.368C879.467 680.442 879.764 681.629 879.767 682.835C880.111 681.738 879.977 680.66 880.838 679.604C881.152 679.211 881.553 678.893 882.012 678.674C882.471 678.454 882.976 678.338 883.488 678.335C882.432 677.59 881.433 677.566 880.914 676.171C880.709 675.643 880.552 675.099 880.445 674.544C880.346 673.985 880.512 673.156 880.199 672.719L880.322 672.96Z'
                opacity='.7' />
          <path id='Vector_745' fill='white'
                d='M913.223 645.944C913.066 647.157 912.536 648.299 911.7 649.224C910.864 650.149 909.76 650.816 908.528 651.141C907.296 651.466 905.99 651.434 904.777 651.05C903.564 650.665 902.497 649.945 901.711 648.981C900.926 648.016 900.457 646.85 900.364 645.631C900.271 644.412 900.559 643.193 901.19 642.13C901.821 641.067 902.767 640.206 903.91 639.657C905.052 639.109 906.339 638.896 907.607 639.047C909.308 639.249 910.855 640.09 911.908 641.383C912.962 642.676 913.435 644.317 913.223 645.944Z'
                opacity='.1' />
          <path id='Vector_746' fill='white'
                d='M912.112 645.736C911.996 646.619 911.604 647.449 910.986 648.122C910.368 648.794 909.552 649.28 908.642 649.516C907.731 649.752 906.766 649.729 905.869 649.449C904.972 649.17 904.184 648.646 903.603 647.944C903.023 647.243 902.676 646.395 902.608 645.508C902.539 644.621 902.751 643.735 903.218 642.962C903.685 642.188 904.384 641.563 905.229 641.164C906.073 640.765 907.024 640.611 907.962 640.72C908.584 640.793 909.185 640.981 909.731 641.272C910.276 641.564 910.755 641.953 911.141 642.419C911.526 642.885 911.81 643.418 911.977 643.987C912.144 644.556 912.19 645.15 912.112 645.736Z'
                opacity='.2' />
          <path id='Vector_747' fill='white'
                d='M907.711 641.982C907.345 643.349 907.325 645.122 904.843 645.025C905.529 645.459 906.304 645.86 906.726 646.612C907.118 647.387 907.323 648.245 907.325 649.116C907.563 648.324 907.47 647.545 908.067 646.782C908.284 646.499 908.562 646.269 908.88 646.11C909.197 645.952 909.546 645.868 909.901 645.866C909.17 645.328 908.478 645.311 908.119 644.303C907.977 643.922 907.869 643.528 907.794 643.128C907.726 642.724 907.841 642.126 907.624 641.81L907.711 641.982Z'
                opacity='.7' />
          <path id='Vector_748' fill='white'
                d='M880.603 677.401C880.34 676.431 880.056 676.544 879.811 676.959C878.581 676.258 878.758 681.036 880.191 679.828C880.678 679.418 880.766 678.001 880.603 677.401Z' />
        </g>;

      case 14:
        return <g id='Mini gem_12'>
          <path id='Vector_749' fill={`#${diamondColors[color][0]}`}
                d='M1191.14 570.512L1236 612.36L1202.63 643.607L1191.14 572.006L1148.36 620.563L1195.29 689.041L1202.63 643.607L1191.14 570.512Z' />
          <path id='Vector_750' fill={`#${diamondColors[color][1]}`}
                d='M1191.07 570.512L1148.36 620.323L1202.61 643.967L1191.07 570.512Z' />
          <path id='Vector_751' fill='white'
                d='M1191.07 570.512L1189.87 571.902L1200.77 641.212L1149.57 618.903L1148.36 620.323L1202.61 643.967L1191.07 570.512Z' />
          <path id='Vector_752' fill={`#${diamondColors[color][2]}`}
                d='M1202.48 643.54L1195.1 689.041L1236 612.248L1202.48 643.54Z' />
          <path id='Vector_753' fill={`#${diamondColors[color][2]}`}
                d='M1202.48 643.54L1195.1 689.041L1236 612.248L1202.48 643.54Z' />
          <path id='Vector_754' fill='white' d='M1148.36 620.595L1196.77 605.956L1191.21 570.512L1148.36 620.595Z'
                opacity='.2' />
          <path id='Vector_755' fill='white' d='M1196.5 605.838L1236 612.248L1190.93 570.512L1196.5 605.191V605.838Z'
                opacity='.2' />
          <path id='Vector_756' fill={`#${diamondColors[color][3]}`}
                d='M1199.41 662.49C1199.49 661.995 1223.33 624.485 1223.33 624.485L1236 613.083L1207.22 666.348L1195.1 689.041L1199.41 662.49Z'
                opacity='.5' />
          <path id='Vector_757' fill={`#${diamondColors[color][3]}`}
                d='M1203.92 643.541L1234.57 614.928L1236 612.248L1202.48 643.541L1195.1 689.041L1197.17 685.144L1203.92 643.541Z' />
          <path id='Vector_758' fill={`#${diamondColors[color][3]}`}
                d='M1164.14 643.647L1201.78 643.967L1148.36 620.595L1164.14 643.647Z' opacity='.5' />
          <path id='Vector_759' fill='white'
                d='M1150.86 619.727C1152.51 621.414 1156.56 621.653 1158.85 622.093C1163.62 623.124 1168.55 623.3 1173.39 622.61C1179.25 621.665 1183.85 619.088 1188.41 615.46C1191.38 613.092 1192.55 610.765 1193.8 607.398C1194.17 606.538 1194.46 605.647 1194.66 604.736C1196.16 605.53 1195.96 610.609 1196.39 612.3C1197.24 615.626 1198.16 618.856 1198.51 622.257C1199.17 627.884 1200.12 633.475 1201.36 639.006C1201.59 640.138 1202.33 641.602 1201.04 642.171C1200.01 642.625 1198.2 641.734 1197.32 641.412C1194.7 640.455 1192.68 638.808 1190.16 637.62C1187.72 636.465 1185.13 635.849 1182.66 634.693C1180.05 633.47 1177.5 632.363 1174.98 630.944C1171.14 628.773 1166.87 627.075 1162.85 625.378C1160.82 624.525 1158.7 623.719 1156.78 622.841C1155.26 622.145 1151.87 621.342 1151.02 619.882L1150.86 619.727Z'
                opacity='.4' />
          <path id='Vector_760' fill='white'
                d='M1209.9 657.11C1218.4 651.55 1220.77 640.158 1215.21 631.666C1209.65 623.174 1198.26 620.797 1189.77 626.357C1181.28 631.917 1178.9 643.309 1184.46 651.801C1190.02 660.293 1201.41 662.67 1209.9 657.11Z'
                opacity='.1' />
          <path id='Vector_761' fill='white'
                d='M1215.03 642.744C1214.69 645.448 1213.56 647.99 1211.78 650.051C1210 652.112 1207.65 653.599 1205.02 654.323C1202.39 655.047 1199.61 654.976 1197.03 654.119C1194.44 653.262 1192.17 651.657 1190.49 649.508C1188.82 647.359 1187.82 644.762 1187.62 642.045C1187.43 639.329 1188.04 636.614 1189.38 634.245C1190.73 631.877 1192.74 629.96 1195.18 628.737C1197.61 627.515 1200.36 627.042 1203.06 627.378C1206.68 627.829 1209.98 629.701 1212.23 632.582C1214.47 635.464 1215.48 639.12 1215.03 642.744Z'
                opacity='.2' />
          <path id='Vector_762' fill='white'
                d='M1202.78 631.96C1201.7 636.018 1201.63 641.285 1194.26 640.996C1196.3 642.285 1198.6 643.478 1199.86 645.711C1201.02 648.014 1201.63 650.562 1201.64 653.149C1202.35 650.797 1202.07 648.484 1203.84 646.216C1204.49 645.375 1205.31 644.693 1206.26 644.221C1207.2 643.749 1208.24 643.501 1209.29 643.494C1207.12 641.897 1205.06 641.846 1204 638.854C1203.58 637.72 1203.25 636.552 1203.03 635.362C1202.83 634.161 1203.17 632.384 1202.53 631.446L1202.78 631.96Z'
                opacity='.7' />
          <path id='Vector_763' fill='white'
                d='M1204.13 641.925C1203.61 640.244 1203.05 640.439 1202.56 641.157C1200.13 639.942 1200.48 648.224 1203.32 646.13C1204.28 645.421 1204.46 642.966 1204.13 641.925Z' />
          <path id='Vector_764' fill='white'
                d='M1160.78 621.773C1160.46 624.312 1159.4 626.701 1157.73 628.638C1156.05 630.574 1153.84 631.97 1151.38 632.65C1148.91 633.33 1146.3 633.264 1143.87 632.459C1141.44 631.654 1139.3 630.147 1137.73 628.128C1136.16 626.109 1135.22 623.669 1135.03 621.117C1134.85 618.565 1135.42 616.015 1136.69 613.79C1137.95 611.565 1139.85 609.764 1142.13 608.616C1144.42 607.467 1146.99 607.023 1149.53 607.339C1152.94 607.762 1156.04 609.521 1158.14 612.228C1160.25 614.934 1161.2 618.368 1160.78 621.773Z'
                opacity='.1' />
          <path id='Vector_765' fill='white'
                d='M1159.13 621.364C1158.9 623.248 1158.11 625.02 1156.87 626.456C1155.63 627.892 1153.99 628.928 1152.16 629.432C1150.33 629.936 1148.39 629.887 1146.59 629.289C1144.79 628.692 1143.2 627.574 1142.03 626.076C1140.87 624.578 1140.17 622.768 1140.03 620.875C1139.9 618.982 1140.32 617.09 1141.26 615.439C1142.2 613.788 1143.6 612.452 1145.3 611.6C1146.99 610.748 1148.91 610.418 1150.79 610.652C1152.04 610.807 1153.25 611.207 1154.35 611.83C1155.44 612.452 1156.41 613.285 1157.18 614.279C1157.96 615.274 1158.53 616.412 1158.86 617.627C1159.2 618.843 1159.29 620.113 1159.13 621.364Z'
                opacity='.2' />
          <path id='Vector_766' fill='white'
                d='M1150.33 613.44C1149.55 616.25 1149.5 619.896 1144.18 619.695C1145.65 620.587 1147.31 621.412 1148.22 622.959C1149.06 624.553 1149.5 626.317 1149.51 628.107C1150.02 626.479 1149.82 624.878 1151.1 623.308C1151.56 622.726 1152.16 622.253 1152.84 621.927C1153.52 621.601 1154.27 621.429 1155.03 621.424C1153.47 620.318 1151.98 620.282 1151.21 618.211C1150.91 617.426 1150.67 616.617 1150.51 615.794C1150.37 614.962 1150.61 613.732 1150.15 613.083L1150.33 613.44Z'
                opacity='.7' />
          <path id='Vector_767' fill='white'
                d='M1199.2 573.314C1198.97 575.116 1198.18 576.811 1196.94 578.185C1195.7 579.559 1194.06 580.55 1192.23 581.033C1190.4 581.516 1188.46 581.469 1186.65 580.898C1184.85 580.326 1183.27 579.257 1182.1 577.824C1180.93 576.392 1180.24 574.66 1180.1 572.849C1179.96 571.038 1180.39 569.228 1181.33 567.649C1182.26 566.069 1183.67 564.792 1185.37 563.977C1187.06 563.162 1188.97 562.846 1190.86 563.07C1193.38 563.37 1195.68 564.618 1197.25 566.54C1198.81 568.461 1199.51 570.897 1199.2 573.314Z'
                opacity='.1' />
          <path id='Vector_768' fill='white'
                d='M1197.55 573.005C1197.38 574.316 1196.79 575.549 1195.88 576.548C1194.96 577.548 1193.75 578.268 1192.39 578.619C1191.04 578.97 1189.61 578.936 1188.28 578.52C1186.94 578.105 1185.77 577.327 1184.91 576.285C1184.05 575.243 1183.53 573.984 1183.43 572.666C1183.33 571.349 1183.65 570.033 1184.34 568.884C1185.03 567.736 1186.07 566.806 1187.32 566.214C1188.58 565.621 1189.99 565.392 1191.38 565.555C1192.31 565.663 1193.2 565.942 1194.01 566.375C1194.82 566.808 1195.53 567.386 1196.11 568.078C1196.68 568.77 1197.1 569.561 1197.35 570.407C1197.6 571.252 1197.66 572.135 1197.55 573.005Z'
                opacity='.2' />
          <path id='Vector_769' fill='white'
                d='M1191.01 567.43C1190.47 569.46 1190.44 572.093 1186.75 571.948C1187.77 572.593 1188.92 573.189 1189.55 574.306C1190.13 575.457 1190.44 576.732 1190.44 578.025C1190.79 576.849 1190.65 575.693 1191.54 574.558C1191.86 574.138 1192.28 573.797 1192.75 573.561C1193.22 573.325 1193.74 573.201 1194.26 573.198C1193.18 572.398 1192.15 572.373 1191.62 570.877C1191.41 570.31 1191.25 569.726 1191.14 569.131C1191.03 568.53 1191.21 567.642 1190.88 567.174L1191.01 567.43Z'
                opacity='.7' />
          <path id='Vector_770' fill='white'
                d='M1150.75 620.036C1150.36 618.596 1149.94 618.763 1149.57 619.38C1147.75 618.339 1148.01 625.436 1150.14 623.642C1150.86 623.033 1150.99 620.928 1150.75 620.036Z' />
        </g>;

      case 15:
        return <g id='Mini gem_13'>
          <path id='Vector_771' fill={`#${diamondColors[color][0]}`}
                d='M1293.14 675.512L1338 717.36L1304.63 748.607L1293.14 677.006L1250.36 725.563L1297.29 794.041L1304.63 748.607L1293.14 675.512Z' />
          <path id='Vector_772' fill={`#${diamondColors[color][1]}`}
                d='M1293.07 675.512L1250.36 725.323L1304.61 748.967L1293.07 675.512Z' />
          <path id='Vector_773' fill='white'
                d='M1293.07 675.512L1291.87 676.902L1302.77 746.212L1251.57 723.903L1250.36 725.323L1304.61 748.967L1293.07 675.512Z' />
          <path id='Vector_774' fill={`#${diamondColors[color][2]}`}
                d='M1304.48 748.54L1297.1 794.041L1338 717.248L1304.48 748.54Z' />
          <path id='Vector_775' fill={`#${diamondColors[color][2]}`}
                d='M1304.48 748.54L1297.1 794.041L1338 717.248L1304.48 748.54Z' />
          <path id='Vector_776' fill='white' d='M1250.36 725.595L1298.77 710.956L1293.21 675.512L1250.36 725.595Z'
                opacity='.2' />
          <path id='Vector_777' fill='white' d='M1298.5 710.838L1338 717.248L1292.93 675.512L1298.5 710.191V710.838Z'
                opacity='.2' />
          <path id='Vector_778' fill={`#${diamondColors[color][3]}`}
                d='M1301.41 767.49C1301.49 766.995 1325.33 729.485 1325.33 729.485L1338 718.083L1309.22 771.348L1297.1 794.041L1301.41 767.49Z'
                opacity='.5' />
          <path id='Vector_779' fill={`#${diamondColors[color][3]}`}
                d='M1305.92 748.541L1336.57 719.928L1338 717.248L1304.48 748.541L1297.1 794.041L1299.17 790.144L1305.92 748.541Z' />
          <path id='Vector_780' fill={`#${diamondColors[color][3]}`}
                d='M1266.14 748.647L1303.78 748.967L1250.36 725.595L1266.14 748.647Z' opacity='.5' />
          <path id='Vector_781' fill='white'
                d='M1252.86 724.727C1254.51 726.414 1258.56 726.653 1260.85 727.093C1265.62 728.124 1270.55 728.3 1275.39 727.61C1281.25 726.665 1285.85 724.088 1290.41 720.46C1293.38 718.092 1294.55 715.765 1295.8 712.398C1296.17 711.538 1296.46 710.647 1296.66 709.736C1298.16 710.53 1297.96 715.609 1298.39 717.3C1299.24 720.626 1300.16 723.856 1300.51 727.257C1301.17 732.884 1302.12 738.475 1303.36 744.006C1303.59 745.138 1304.33 746.602 1303.04 747.171C1302.01 747.625 1300.2 746.734 1299.32 746.412C1296.7 745.455 1294.68 743.808 1292.16 742.62C1289.72 741.465 1287.13 740.849 1284.66 739.693C1282.05 738.47 1279.5 737.363 1276.98 735.944C1273.14 733.773 1268.87 732.075 1264.85 730.378C1262.82 729.525 1260.7 728.719 1258.78 727.841C1257.26 727.145 1253.87 726.342 1253.02 724.882L1252.86 724.727Z'
                opacity='.4' />
          <path id='Vector_782' fill='white'
                d='M1311.9 762.11C1320.4 756.55 1322.77 745.158 1317.21 736.666C1311.65 728.174 1300.26 725.797 1291.77 731.357C1283.28 736.917 1280.9 748.309 1286.46 756.801C1292.02 765.293 1303.41 767.67 1311.9 762.11Z'
                opacity='.1' />
          <path id='Vector_783' fill='white'
                d='M1317.03 747.744C1316.69 750.448 1315.56 752.99 1313.78 755.051C1312 757.112 1309.65 758.599 1307.02 759.323C1304.39 760.047 1301.61 759.976 1299.03 759.119C1296.44 758.262 1294.17 756.657 1292.49 754.508C1290.82 752.359 1289.82 749.762 1289.62 747.045C1289.43 744.329 1290.04 741.614 1291.38 739.245C1292.73 736.877 1294.74 734.96 1297.18 733.737C1299.61 732.515 1302.36 732.042 1305.06 732.378C1308.68 732.829 1311.98 734.701 1314.23 737.582C1316.47 740.464 1317.48 744.12 1317.03 747.744Z'
                opacity='.2' />
          <path id='Vector_784' fill='white'
                d='M1304.78 736.96C1303.7 741.018 1303.63 746.285 1296.26 745.996C1298.3 747.285 1300.6 748.478 1301.86 750.711C1303.02 753.014 1303.63 755.562 1303.64 758.149C1304.35 755.797 1304.07 753.484 1305.84 751.216C1306.49 750.375 1307.31 749.693 1308.26 749.221C1309.2 748.749 1310.24 748.501 1311.29 748.494C1309.12 746.897 1307.06 746.846 1306 743.854C1305.58 742.72 1305.25 741.552 1305.03 740.362C1304.83 739.161 1305.17 737.384 1304.53 736.446L1304.78 736.96Z'
                opacity='.7' />
          <path id='Vector_785' fill='white'
                d='M1306.13 746.925C1305.61 745.244 1305.05 745.439 1304.56 746.157C1302.13 744.942 1302.48 753.224 1305.32 751.13C1306.28 750.421 1306.46 747.966 1306.13 746.925Z' />
          <path id='Vector_786' fill='white'
                d='M1262.78 726.773C1262.46 729.312 1261.4 731.701 1259.73 733.638C1258.05 735.574 1255.84 736.97 1253.38 737.65C1250.91 738.33 1248.3 738.264 1245.87 737.459C1243.44 736.654 1241.3 735.147 1239.73 733.128C1238.16 731.109 1237.22 728.669 1237.03 726.117C1236.85 723.565 1237.42 721.015 1238.69 718.79C1239.95 716.565 1241.85 714.764 1244.13 713.616C1246.42 712.467 1248.99 712.023 1251.53 712.339C1254.94 712.762 1258.04 714.521 1260.14 717.228C1262.25 719.934 1263.2 723.368 1262.78 726.773Z'
                opacity='.1' />
          <path id='Vector_787' fill='white'
                d='M1261.13 726.364C1260.9 728.248 1260.11 730.02 1258.87 731.456C1257.63 732.892 1255.99 733.928 1254.16 734.432C1252.33 734.936 1250.39 734.887 1248.59 734.289C1246.79 733.692 1245.2 732.574 1244.03 731.076C1242.87 729.578 1242.17 727.768 1242.03 725.875C1241.9 723.982 1242.32 722.09 1243.26 720.439C1244.2 718.788 1245.6 717.452 1247.3 716.6C1248.99 715.748 1250.91 715.418 1252.79 715.652C1254.04 715.807 1255.25 716.207 1256.35 716.83C1257.44 717.452 1258.41 718.285 1259.18 719.279C1259.96 720.274 1260.53 721.412 1260.86 722.627C1261.2 723.843 1261.29 725.113 1261.13 726.364Z'
                opacity='.2' />
          <path id='Vector_788' fill='white'
                d='M1252.33 718.44C1251.55 721.25 1251.5 724.896 1246.18 724.695C1247.65 725.587 1249.31 726.412 1250.22 727.959C1251.06 729.553 1251.5 731.317 1251.51 733.107C1252.02 731.479 1251.82 729.878 1253.1 728.308C1253.56 727.726 1254.16 727.253 1254.84 726.927C1255.52 726.601 1256.27 726.429 1257.03 726.424C1255.47 725.318 1253.98 725.282 1253.21 723.211C1252.91 722.426 1252.67 721.617 1252.51 720.794C1252.37 719.962 1252.61 718.732 1252.15 718.083L1252.33 718.44Z'
                opacity='.7' />
          <path id='Vector_789' fill='white'
                d='M1301.2 678.314C1300.97 680.116 1300.18 681.811 1298.94 683.185C1297.7 684.559 1296.06 685.55 1294.23 686.033C1292.4 686.516 1290.46 686.469 1288.65 685.898C1286.85 685.326 1285.27 684.257 1284.1 682.824C1282.93 681.392 1282.24 679.66 1282.1 677.849C1281.96 676.038 1282.39 674.228 1283.33 672.649C1284.26 671.069 1285.67 669.792 1287.37 668.977C1289.06 668.162 1290.97 667.846 1292.86 668.07C1295.38 668.37 1297.68 669.618 1299.25 671.54C1300.81 673.461 1301.51 675.897 1301.2 678.314Z'
                opacity='.1' />
          <path id='Vector_790' fill='white'
                d='M1299.55 678.005C1299.38 679.316 1298.79 680.549 1297.88 681.548C1296.96 682.548 1295.75 683.268 1294.39 683.619C1293.04 683.97 1291.61 683.936 1290.28 683.52C1288.94 683.105 1287.77 682.327 1286.91 681.285C1286.05 680.243 1285.53 678.984 1285.43 677.666C1285.33 676.349 1285.65 675.033 1286.34 673.884C1287.03 672.736 1288.07 671.806 1289.32 671.214C1290.58 670.621 1291.99 670.392 1293.38 670.555C1294.31 670.663 1295.2 670.942 1296.01 671.375C1296.82 671.808 1297.53 672.386 1298.11 673.078C1298.68 673.77 1299.1 674.561 1299.35 675.407C1299.6 676.252 1299.66 677.135 1299.55 678.005Z'
                opacity='.2' />
          <path id='Vector_791' fill='white'
                d='M1293.01 672.43C1292.47 674.46 1292.44 677.093 1288.75 676.948C1289.77 677.593 1290.92 678.189 1291.55 679.306C1292.13 680.457 1292.44 681.732 1292.44 683.025C1292.79 681.849 1292.65 680.693 1293.54 679.558C1293.86 679.138 1294.28 678.797 1294.75 678.561C1295.22 678.325 1295.74 678.201 1296.26 678.198C1295.18 677.398 1294.15 677.373 1293.62 675.877C1293.41 675.31 1293.25 674.726 1293.14 674.131C1293.03 673.53 1293.21 672.642 1292.88 672.174L1293.01 672.43Z'
                opacity='.7' />
          <path id='Vector_792' fill='white'
                d='M1252.75 725.036C1252.36 723.596 1251.94 723.763 1251.57 724.381C1249.75 723.339 1250.01 730.436 1252.14 728.642C1252.86 728.033 1252.99 725.928 1252.75 725.036Z' />
        </g>;

      case 16:
        return <g id='Mini gem_14'>
          <path id='Vector_793' fill={`#${diamondColors[color][0]}`}
                d='M1415.14 503.512L1460 545.36L1426.63 576.607L1415.14 505.006L1372.36 553.563L1419.29 622.041L1426.63 576.607L1415.14 503.512Z' />
          <path id='Vector_794' fill={`#${diamondColors[color][1]}`}
                d='M1415.07 503.512L1372.36 553.323L1426.61 576.967L1415.07 503.512Z' />
          <path id='Vector_795' fill='white'
                d='M1415.07 503.512L1413.87 504.902L1424.77 574.212L1373.57 551.903L1372.36 553.323L1426.61 576.967L1415.07 503.512Z' />
          <path id='Vector_796' fill={`#${diamondColors[color][2]}`}
                d='M1426.48 576.54L1419.1 622.041L1460 545.248L1426.48 576.54Z' />
          <path id='Vector_797' fill={`#${diamondColors[color][2]}`}
                d='M1426.48 576.54L1419.1 622.041L1460 545.248L1426.48 576.54Z' />
          <path id='Vector_798' fill='white' d='M1372.36 553.595L1420.77 538.956L1415.21 503.512L1372.36 553.595Z'
                opacity='.2' />
          <path id='Vector_799' fill='white' d='M1420.5 538.838L1460 545.248L1414.93 503.512L1420.5 538.191V538.838Z'
                opacity='.2' />
          <path id='Vector_800' fill={`#${diamondColors[color][3]}`}
                d='M1423.41 595.49C1423.49 594.995 1447.33 557.485 1447.33 557.485L1460 546.083L1431.22 599.348L1419.1 622.041L1423.41 595.49Z'
                opacity='.5' />
          <path id='Vector_801' fill={`#${diamondColors[color][3]}`}
                d='M1427.92 576.541L1458.57 547.928L1460 545.248L1426.48 576.541L1419.1 622.041L1421.17 618.144L1427.92 576.541Z' />
          <path id='Vector_802' fill={`#${diamondColors[color][3]}`}
                d='M1388.14 576.647L1425.78 576.967L1372.36 553.595L1388.14 576.647Z' opacity='.5' />
          <path id='Vector_803' fill='white'
                d='M1374.86 552.727C1376.51 554.414 1380.56 554.653 1382.85 555.093C1387.62 556.124 1392.55 556.3 1397.39 555.61C1403.25 554.665 1407.85 552.088 1412.41 548.46C1415.38 546.092 1416.55 543.765 1417.8 540.398C1418.17 539.538 1418.46 538.647 1418.66 537.736C1420.16 538.53 1419.96 543.609 1420.39 545.3C1421.24 548.626 1422.16 551.856 1422.51 555.257C1423.17 560.884 1424.12 566.475 1425.36 572.006C1425.59 573.138 1426.33 574.602 1425.04 575.171C1424.01 575.625 1422.2 574.734 1421.32 574.412C1418.7 573.455 1416.68 571.808 1414.16 570.62C1411.72 569.465 1409.13 568.849 1406.66 567.693C1404.05 566.47 1401.5 565.363 1398.98 563.944C1395.14 561.773 1390.87 560.075 1386.85 558.378C1384.82 557.525 1382.7 556.719 1380.78 555.841C1379.26 555.145 1375.87 554.342 1375.02 552.882L1374.86 552.727Z'
                opacity='.4' />
          <path id='Vector_804' fill='white'
                d='M1433.9 590.11C1442.4 584.55 1444.77 573.158 1439.21 564.666C1433.65 556.174 1422.26 553.797 1413.77 559.357C1405.28 564.917 1402.9 576.309 1408.46 584.801C1414.02 593.293 1425.41 595.67 1433.9 590.11Z'
                opacity='.1' />
          <path id='Vector_805' fill='white'
                d='M1439.03 575.744C1438.69 578.448 1437.56 580.99 1435.78 583.051C1434 585.112 1431.65 586.599 1429.02 587.323C1426.39 588.047 1423.61 587.976 1421.03 587.119C1418.44 586.262 1416.17 584.657 1414.49 582.508C1412.82 580.359 1411.82 577.762 1411.62 575.045C1411.43 572.329 1412.04 569.614 1413.38 567.245C1414.73 564.877 1416.74 562.96 1419.18 561.737C1421.61 560.515 1424.36 560.042 1427.06 560.378C1430.68 560.829 1433.98 562.701 1436.23 565.582C1438.47 568.464 1439.48 572.12 1439.03 575.744Z'
                opacity='.2' />
          <path id='Vector_806' fill='white'
                d='M1426.78 564.96C1425.7 569.018 1425.63 574.285 1418.26 573.996C1420.3 575.285 1422.6 576.478 1423.86 578.711C1425.02 581.014 1425.63 583.562 1425.64 586.149C1426.35 583.797 1426.07 581.484 1427.84 579.216C1428.49 578.375 1429.31 577.693 1430.26 577.221C1431.2 576.749 1432.24 576.501 1433.29 576.494C1431.12 574.897 1429.06 574.846 1428 571.854C1427.58 570.72 1427.25 569.552 1427.03 568.362C1426.83 567.161 1427.17 565.384 1426.53 564.446L1426.78 564.96Z'
                opacity='.7' />
          <path id='Vector_807' fill='white'
                d='M1428.13 574.925C1427.61 573.244 1427.05 573.439 1426.56 574.157C1424.13 572.942 1424.48 581.224 1427.32 579.13C1428.28 578.421 1428.46 575.966 1428.13 574.925Z' />
          <path id='Vector_808' fill='white'
                d='M1384.78 554.773C1384.46 557.312 1383.4 559.701 1381.73 561.638C1380.05 563.574 1377.84 564.97 1375.38 565.65C1372.91 566.33 1370.3 566.264 1367.87 565.459C1365.44 564.654 1363.3 563.147 1361.73 561.128C1360.16 559.109 1359.22 556.669 1359.03 554.117C1358.85 551.565 1359.42 549.015 1360.69 546.79C1361.95 544.564 1363.85 542.764 1366.13 541.616C1368.42 540.467 1370.99 540.023 1373.53 540.338C1376.94 540.762 1380.04 542.521 1382.14 545.228C1384.25 547.934 1385.2 551.368 1384.78 554.773Z'
                opacity='.1' />
          <path id='Vector_809' fill='white'
                d='M1383.13 554.364C1382.9 556.248 1382.11 558.02 1380.87 559.456C1379.63 560.892 1377.99 561.927 1376.16 562.432C1374.33 562.936 1372.39 562.887 1370.59 562.289C1368.79 561.692 1367.2 560.574 1366.03 559.076C1364.87 557.578 1364.17 555.768 1364.03 553.875C1363.9 551.982 1364.32 550.09 1365.26 548.439C1366.2 546.788 1367.6 545.452 1369.3 544.6C1370.99 543.748 1372.91 543.418 1374.79 543.652C1376.04 543.807 1377.25 544.207 1378.35 544.83C1379.44 545.452 1380.41 546.285 1381.18 547.279C1381.96 548.274 1382.53 549.412 1382.86 550.627C1383.2 551.843 1383.29 553.113 1383.13 554.364Z'
                opacity='.2' />
          <path id='Vector_810' fill='white'
                d='M1374.33 546.44C1373.55 549.25 1373.5 552.896 1368.18 552.695C1369.65 553.587 1371.31 554.412 1372.22 555.959C1373.06 557.553 1373.5 559.317 1373.51 561.107C1374.02 559.479 1373.82 557.878 1375.1 556.308C1375.56 555.726 1376.16 555.253 1376.84 554.927C1377.52 554.601 1378.27 554.429 1379.03 554.424C1377.47 553.318 1375.98 553.282 1375.21 551.211C1374.91 550.426 1374.67 549.617 1374.51 548.794C1374.37 547.962 1374.61 546.732 1374.15 546.083L1374.33 546.44Z'
                opacity='.7' />
          <path id='Vector_811' fill='white'
                d='M1423.2 506.314C1422.97 508.116 1422.18 509.811 1420.94 511.185C1419.7 512.559 1418.06 513.55 1416.23 514.033C1414.4 514.516 1412.46 514.469 1410.65 513.898C1408.85 513.326 1407.27 512.257 1406.1 510.824C1404.93 509.392 1404.24 507.66 1404.1 505.849C1403.96 504.038 1404.39 502.228 1405.33 500.649C1406.26 499.069 1407.67 497.792 1409.37 496.977C1411.06 496.162 1412.97 495.846 1414.86 496.07C1417.38 496.37 1419.68 497.618 1421.25 499.539C1422.81 501.46 1423.51 503.897 1423.2 506.314Z'
                opacity='.1' />
          <path id='Vector_812' fill='white'
                d='M1421.55 506.005C1421.38 507.316 1420.79 508.549 1419.88 509.548C1418.96 510.548 1417.75 511.268 1416.39 511.619C1415.04 511.97 1413.61 511.936 1412.28 511.52C1410.94 511.105 1409.77 510.327 1408.91 509.285C1408.05 508.243 1407.53 506.984 1407.43 505.666C1407.33 504.349 1407.65 503.033 1408.34 501.884C1409.03 500.736 1410.07 499.806 1411.32 499.214C1412.58 498.621 1413.99 498.392 1415.38 498.555C1416.31 498.663 1417.2 498.942 1418.01 499.375C1418.82 499.808 1419.53 500.386 1420.11 501.078C1420.68 501.77 1421.1 502.561 1421.35 503.407C1421.6 504.252 1421.66 505.135 1421.55 506.005Z'
                opacity='.2' />
          <path id='Vector_813' fill='white'
                d='M1415.01 500.43C1414.47 502.46 1414.44 505.093 1410.75 504.948C1411.77 505.593 1412.92 506.189 1413.55 507.306C1414.13 508.457 1414.44 509.732 1414.44 511.025C1414.79 509.849 1414.65 508.693 1415.54 507.558C1415.86 507.138 1416.28 506.797 1416.75 506.561C1417.22 506.325 1417.74 506.201 1418.26 506.198C1417.18 505.398 1416.15 505.373 1415.62 503.877C1415.41 503.31 1415.25 502.726 1415.14 502.131C1415.03 501.53 1415.21 500.642 1414.88 500.174L1415.01 500.43Z'
                opacity='.7' />
          <path id='Vector_814' fill='white'
                d='M1374.75 553.036C1374.36 551.596 1373.94 551.763 1373.57 552.38C1371.75 551.339 1372.01 558.436 1374.14 556.642C1374.86 556.033 1374.99 553.928 1374.75 553.036Z' />
        </g>;



      case 17 :
        return <g id='Mini gem'>
          <path id='Vector_276' fill={`#${diamondColors[color][0]}`}
                d='M860.135 289.174L897 323.564L869.58 349.241L860.134 290.401L824.975 330.304L863.546 386.578L869.58 349.241L860.135 289.174Z' />
          <path id='Vector_277' fill={`#${diamondColors[color][1]}`}
                d='M860.073 289.174L824.975 330.107L869.562 349.537L860.073 289.174Z' />
          <path id='Vector_278' fill='white'
                d='M860.074 289.174L859.095 290.315L868.048 347.273L825.976 328.94L824.975 330.107L869.562 349.537L860.074 289.174Z' />
          <path id='Vector_279' fill={`#${diamondColors[color][2]}`}
                d='M869.451 349.186L863.388 386.578L897 323.471L869.451 349.186Z' />
          <path id='Vector_280' fill={`#${diamondColors[color][2]}`}
                d='M869.451 349.186L863.388 386.578L897 323.471L869.451 349.186Z' />
          <path id='Vector_281' fill='white' d='M824.975 330.331L864.76 318.3L860.191 289.174L824.975 330.331Z'
                opacity='.2' />
          <path id='Vector_282' fill='white' d='M864.542 318.204L897 323.471L859.959 289.174L864.542 317.672V318.204Z'
                opacity='.2' />
          <path id='Vector_283' fill={`#${diamondColors[color][3]}`}
                d='M866.932 364.759C866.998 364.352 886.586 333.527 886.586 333.527L897 324.157L873.345 367.93L863.388 386.578L866.932 364.759Z'
                opacity='.5' />
          <path id='Vector_284' fill={`#${diamondColors[color][3]}`}
                d='M870.638 349.187L895.827 325.673L897 323.471L869.451 349.187L863.388 386.578L865.093 383.376L870.638 349.187Z' />
          <path id='Vector_285' fill={`#${diamondColors[color][3]}`}
                d='M837.949 349.275L868.876 349.537L824.975 330.331L837.949 349.275Z' opacity='.5' />
          <path id='Vector_286' fill='white'
                d='M827.033 329.617C828.386 331.004 831.714 331.2 833.597 331.561C837.522 332.409 841.569 332.553 845.545 331.987C850.363 331.21 854.147 329.092 857.887 326.111C860.33 324.165 861.295 322.252 862.319 319.485C862.622 318.778 862.86 318.046 863.031 317.298C864.263 317.951 864.099 322.124 864.452 323.514C865.146 326.247 865.903 328.902 866.189 331.696C866.731 336.321 867.512 340.915 868.53 345.46C868.722 346.39 869.327 347.593 868.267 348.062C867.427 348.434 865.935 347.702 865.211 347.437C863.062 346.651 861.4 345.298 859.332 344.321C857.321 343.372 855.196 342.866 853.168 341.916C851.021 340.911 848.923 340.001 846.859 338.835C843.698 337.051 840.19 335.656 836.883 334.261C835.222 333.56 833.477 332.898 831.897 332.176C830.647 331.604 827.861 330.945 827.164 329.745L827.033 329.617Z'
                opacity='.4' />
          <path id='Vector_287' fill='white'
                d='M875.555 360.338C882.534 355.769 884.487 346.407 879.918 339.429C875.349 332.45 865.987 330.497 859.009 335.066C852.03 339.635 850.076 348.996 854.646 355.975C859.215 362.954 868.576 364.907 875.555 360.338Z'
                opacity='.1' />
          <path id='Vector_288' fill='white'
                d='M879.765 348.533C879.489 350.754 878.56 352.844 877.096 354.537C875.632 356.231 873.699 357.452 871.541 358.047C869.383 358.642 867.097 358.584 864.972 357.88C862.848 357.176 860.979 355.857 859.604 354.091C858.228 352.325 857.407 350.191 857.245 347.958C857.082 345.726 857.585 343.495 858.691 341.548C859.796 339.602 861.454 338.026 863.454 337.022C865.454 336.017 867.708 335.628 869.929 335.905C872.908 336.275 875.618 337.813 877.462 340.182C879.307 342.55 880.135 345.554 879.765 348.533Z'
                opacity='.2' />
          <path id='Vector_289' fill='white'
                d='M869.701 339.67C868.809 343.005 868.758 347.333 862.702 347.096C864.375 348.155 866.268 349.135 867.299 350.971C868.256 352.863 868.757 354.957 868.762 357.083C869.343 355.15 869.116 353.249 870.573 351.386C871.102 350.694 871.78 350.133 872.556 349.746C873.332 349.358 874.184 349.154 875.05 349.149C873.266 347.836 871.578 347.794 870.7 345.335C870.354 344.404 870.089 343.444 869.907 342.466C869.743 341.479 870.022 340.018 869.491 339.248L869.701 339.67Z'
                opacity='.7' />
          <path id='Vector_290' fill='white'
                d='M870.812 347.859C870.384 346.477 869.922 346.638 869.523 347.228C867.522 346.229 867.81 353.035 870.143 351.315C870.933 350.732 871.077 348.715 870.812 347.859Z' />
          <path id='Vector_291' fill='white'
                d='M835.183 331.299C834.924 333.385 834.052 335.349 832.677 336.94C831.302 338.531 829.486 339.678 827.458 340.237C825.431 340.796 823.284 340.742 821.288 340.08C819.291 339.419 817.536 338.18 816.244 336.521C814.952 334.862 814.181 332.857 814.028 330.76C813.875 328.662 814.348 326.567 815.386 324.738C816.424 322.909 817.982 321.43 819.861 320.486C821.74 319.542 823.857 319.177 825.944 319.437C828.742 319.785 831.287 321.23 833.02 323.454C834.753 325.679 835.531 328.5 835.183 331.299Z'
                opacity='.1' />
          <path id='Vector_292' fill='white'
                d='M833.832 330.963C833.639 332.511 832.992 333.967 831.971 335.147C830.951 336.327 829.604 337.178 828.1 337.593C826.596 338.007 825.003 337.966 823.522 337.475C822.041 336.984 820.739 336.066 819.781 334.835C818.822 333.604 818.25 332.117 818.137 330.561C818.023 329.005 818.374 327.45 819.144 326.093C819.914 324.737 821.069 323.639 822.463 322.939C823.857 322.238 825.428 321.967 826.976 322.159C828.004 322.287 828.997 322.616 829.898 323.127C830.799 323.639 831.591 324.323 832.228 325.14C832.864 325.958 833.334 326.893 833.609 327.892C833.884 328.891 833.96 329.934 833.832 330.963Z'
                opacity='.2' />
          <path id='Vector_293' fill='white'
                d='M826.599 324.451C825.955 326.76 825.919 329.756 821.545 329.591C822.753 330.324 824.12 331.002 824.865 332.273C825.556 333.583 825.918 335.033 825.922 336.504C826.341 335.166 826.178 333.85 827.229 332.56C827.612 332.082 828.102 331.693 828.662 331.425C829.222 331.157 829.838 331.016 830.463 331.012C829.175 330.103 827.956 330.074 827.322 328.371C827.072 327.726 826.88 327.062 826.749 326.385C826.628 325.702 826.831 324.691 826.449 324.157L826.599 324.451Z'
                opacity='.7' />
          <path id='Vector_294' fill='white'
                d='M866.758 291.476C866.566 292.957 865.919 294.35 864.898 295.479C863.878 296.608 862.531 297.423 861.027 297.819C859.523 298.216 857.93 298.177 856.449 297.708C854.967 297.239 853.665 296.36 852.707 295.182C851.748 294.005 851.176 292.582 851.062 291.094C850.949 289.605 851.299 288.118 852.07 286.82C852.84 285.522 853.995 284.472 855.39 283.803C856.784 283.133 858.354 282.874 859.903 283.058C861.979 283.304 863.867 284.33 865.153 285.909C866.439 287.487 867.016 289.49 866.758 291.476Z'
                opacity='.1' />
          <path id='Vector_295' fill='white'
                d='M865.402 291.222C865.259 292.299 864.781 293.312 864.027 294.134C863.273 294.955 862.277 295.547 861.165 295.836C860.054 296.124 858.876 296.096 857.781 295.754C856.687 295.413 855.724 294.774 855.016 293.917C854.307 293.061 853.884 292.026 853.8 290.944C853.717 289.861 853.976 288.779 854.546 287.836C855.115 286.892 855.969 286.128 857 285.641C858.03 285.154 859.191 284.966 860.336 285.1C861.095 285.189 861.829 285.417 862.495 285.773C863.161 286.129 863.746 286.605 864.216 287.173C864.686 287.742 865.033 288.392 865.237 289.087C865.44 289.781 865.496 290.507 865.402 291.222Z'
                opacity='.2' />
          <path id='Vector_296' fill='white'
                d='M860.029 286.64C859.583 288.308 859.558 290.472 856.529 290.353C857.366 290.884 858.312 291.373 858.827 292.291C859.306 293.237 859.557 294.284 859.559 295.347C859.849 294.381 859.736 293.43 860.464 292.498C860.729 292.153 861.068 291.872 861.456 291.679C861.844 291.485 862.27 291.383 862.702 291.38C861.811 290.723 860.966 290.703 860.528 289.473C860.355 289.007 860.222 288.527 860.131 288.038C860.048 287.545 860.188 286.815 859.923 286.43L860.029 286.64Z'
                opacity='.7' />
          <path id='Vector_297' fill='white'
                d='M826.942 329.871C826.621 328.688 826.274 328.825 825.975 329.332C824.474 328.477 824.69 334.309 826.44 332.834C827.033 332.334 827.14 330.604 826.942 329.871Z' />
        </g>;

      case 18 :
        return <g id='Mini gem_2'>
          <path id='Vector_298' fill={`#${diamondColors[color][0]}`}
                d='M674.135 374.174L711 408.564L683.58 434.241L674.134 375.401L638.975 415.304L677.546 471.578L683.58 434.241L674.135 374.174Z' />
          <path id='Vector_299' fill={`#${diamondColors[color][1]}`}
                d='M674.073 374.174L638.975 415.107L683.562 434.537L674.073 374.174Z' />
          <path id='Vector_300' fill='white'
                d='M674.074 374.174L673.095 375.315L682.048 432.273L639.976 413.94L638.975 415.107L683.562 434.537L674.074 374.174Z' />
          <path id='Vector_301' fill={`#${diamondColors[color][2]}`}
                d='M683.451 434.186L677.388 471.578L711 408.471L683.451 434.186Z' />
          <path id='Vector_302' fill={`#${diamondColors[color][2]}`}
                d='M683.451 434.186L677.388 471.578L711 408.471L683.451 434.186Z' />
          <path id='Vector_303' fill='white' d='M638.975 415.331L678.76 403.3L674.191 374.174L638.975 415.331Z'
                opacity='.2' />
          <path id='Vector_304' fill='white' d='M678.542 403.204L711 408.471L673.959 374.174L678.542 402.672V403.204Z'
                opacity='.2' />
          <path id='Vector_305' fill={`#${diamondColors[color][3]}`}
                d='M680.932 449.759C680.998 449.352 700.586 418.527 700.586 418.527L711 409.157L687.345 452.93L677.388 471.578L680.932 449.759Z'
                opacity='.5' />
          <path id='Vector_306' fill={`#${diamondColors[color][3]}`}
                d='M684.638 434.187L709.827 410.673L711 408.471L683.451 434.187L677.388 471.578L679.093 468.376L684.638 434.187Z' />
          <path id='Vector_307' fill={`#${diamondColors[color][3]}`}
                d='M651.949 434.275L682.876 434.537L638.975 415.331L651.949 434.275Z' opacity='.5' />
          <path id='Vector_308' fill='white'
                d='M641.033 414.617C642.386 416.004 645.714 416.2 647.597 416.561C651.522 417.409 655.569 417.553 659.545 416.987C664.363 416.21 668.147 414.092 671.887 411.111C674.33 409.165 675.295 407.252 676.319 404.485C676.622 403.778 676.86 403.046 677.031 402.298C678.263 402.951 678.099 407.124 678.452 408.514C679.146 411.247 679.903 413.902 680.189 416.696C680.731 421.321 681.512 425.915 682.53 430.46C682.722 431.39 683.327 432.593 682.267 433.062C681.427 433.434 679.935 432.702 679.211 432.437C677.062 431.651 675.4 430.298 673.332 429.321C671.321 428.372 669.196 427.866 667.168 426.916C665.021 425.911 662.923 425.001 660.859 423.835C657.698 422.051 654.19 420.656 650.883 419.261C649.222 418.56 647.477 417.898 645.897 417.176C644.647 416.604 641.861 415.945 641.164 414.745L641.033 414.617Z'
                opacity='.4' />
          <path id='Vector_309' fill='white'
                d='M689.555 445.338C696.534 440.769 698.487 431.407 693.918 424.429C689.349 417.45 679.987 415.497 673.009 420.066C666.03 424.635 664.076 433.996 668.646 440.975C673.215 447.954 682.576 449.907 689.555 445.338Z'
                opacity='.1' />
          <path id='Vector_310' fill='white'
                d='M693.765 433.533C693.489 435.754 692.56 437.844 691.096 439.537C689.632 441.231 687.699 442.452 685.541 443.047C683.383 443.642 681.097 443.584 678.972 442.88C676.848 442.176 674.979 440.857 673.604 439.091C672.228 437.325 671.407 435.191 671.245 432.958C671.082 430.726 671.585 428.495 672.691 426.548C673.796 424.602 675.453 423.026 677.454 422.022C679.454 421.017 681.708 420.628 683.929 420.905C686.908 421.275 689.618 422.813 691.462 425.182C693.307 427.55 694.135 430.554 693.765 433.533Z'
                opacity='.2' />
          <path id='Vector_311' fill='white'
                d='M683.701 424.67C682.809 428.005 682.758 432.333 676.702 432.096C678.375 433.155 680.268 434.135 681.299 435.971C682.256 437.863 682.757 439.957 682.762 442.083C683.343 440.15 683.116 438.249 684.573 436.386C685.102 435.694 685.78 435.133 686.556 434.746C687.332 434.358 688.184 434.154 689.05 434.149C687.266 432.836 685.578 432.794 684.7 430.335C684.354 429.404 684.089 428.444 683.907 427.466C683.743 426.479 684.022 425.018 683.491 424.248L683.701 424.67Z'
                opacity='.7' />
          <path id='Vector_312' fill='white'
                d='M684.812 432.859C684.384 431.477 683.922 431.638 683.523 432.228C681.522 431.229 681.81 438.035 684.143 436.315C684.933 435.732 685.077 433.715 684.812 432.859Z' />
          <path id='Vector_313' fill='white'
                d='M649.183 416.299C648.924 418.385 648.052 420.349 646.677 421.94C645.302 423.531 643.486 424.678 641.458 425.237C639.431 425.796 637.284 425.742 635.288 425.08C633.291 424.419 631.536 423.18 630.244 421.521C628.952 419.862 628.181 417.857 628.028 415.76C627.875 413.662 628.348 411.567 629.386 409.738C630.424 407.909 631.982 406.43 633.861 405.486C635.74 404.542 637.857 404.177 639.944 404.437C642.742 404.785 645.287 406.23 647.02 408.454C648.753 410.679 649.531 413.5 649.183 416.299Z'
                opacity='.1' />
          <path id='Vector_314' fill='white'
                d='M647.832 415.963C647.639 417.511 646.992 418.967 645.971 420.147C644.951 421.327 643.604 422.178 642.1 422.593C640.596 423.007 639.003 422.966 637.522 422.475C636.041 421.984 634.739 421.066 633.781 419.835C632.822 418.604 632.25 417.117 632.137 415.561C632.023 414.005 632.374 412.45 633.144 411.093C633.914 409.737 635.069 408.639 636.463 407.939C637.857 407.238 639.428 406.967 640.976 407.159C642.004 407.287 642.997 407.616 643.898 408.127C644.799 408.639 645.591 409.323 646.228 410.14C646.864 410.958 647.334 411.893 647.609 412.892C647.884 413.891 647.96 414.934 647.832 415.963Z'
                opacity='.2' />
          <path id='Vector_315' fill='white'
                d='M640.599 409.451C639.955 411.76 639.919 414.756 635.545 414.591C636.753 415.324 638.12 416.002 638.865 417.273C639.556 418.583 639.918 420.033 639.922 421.504C640.341 420.166 640.178 418.85 641.229 417.56C641.612 417.082 642.102 416.693 642.662 416.425C643.222 416.157 643.838 416.016 644.463 416.012C643.175 415.103 641.956 415.074 641.322 413.371C641.072 412.726 640.88 412.062 640.749 411.385C640.628 410.702 640.831 409.691 640.449 409.157L640.599 409.451Z'
                opacity='.7' />
          <path id='Vector_316' fill='white'
                d='M680.758 376.476C680.566 377.957 679.919 379.35 678.898 380.479C677.878 381.608 676.531 382.423 675.027 382.819C673.523 383.216 671.93 383.177 670.449 382.708C668.967 382.239 667.665 381.36 666.707 380.182C665.748 379.005 665.176 377.582 665.062 376.094C664.949 374.605 665.299 373.118 666.07 371.82C666.84 370.522 667.995 369.472 669.39 368.803C670.784 368.133 672.354 367.874 673.903 368.058C675.979 368.304 677.867 369.33 679.153 370.909C680.439 372.487 681.016 374.49 680.758 376.476Z'
                opacity='.1' />
          <path id='Vector_317' fill='white'
                d='M679.402 376.222C679.259 377.299 678.781 378.312 678.027 379.134C677.273 379.955 676.277 380.547 675.165 380.836C674.054 381.124 672.876 381.096 671.781 380.754C670.687 380.413 669.724 379.774 669.016 378.917C668.307 378.061 667.884 377.026 667.8 375.944C667.717 374.861 667.976 373.779 668.546 372.836C669.115 371.892 669.969 371.128 671 370.641C672.03 370.154 673.191 369.966 674.336 370.1C675.095 370.189 675.829 370.417 676.495 370.773C677.161 371.129 677.746 371.605 678.216 372.173C678.686 372.742 679.033 373.392 679.237 374.087C679.44 374.781 679.496 375.507 679.402 376.222Z'
                opacity='.2' />
          <path id='Vector_318' fill='white'
                d='M674.029 371.64C673.583 373.308 673.558 375.472 670.529 375.353C671.366 375.884 672.312 376.373 672.827 377.291C673.306 378.237 673.557 379.284 673.559 380.347C673.849 379.381 673.736 378.43 674.464 377.498C674.729 377.153 675.068 376.872 675.456 376.679C675.844 376.485 676.27 376.383 676.702 376.38C675.811 375.723 674.966 375.703 674.528 374.473C674.355 374.007 674.222 373.527 674.131 373.038C674.048 372.545 674.188 371.815 673.923 371.43L674.029 371.64Z'
                opacity='.7' />
          <path id='Vector_319' fill='white'
                d='M640.942 414.871C640.621 413.688 640.274 413.825 639.975 414.332C638.474 413.477 638.69 419.309 640.44 417.834C641.033 417.334 641.14 415.604 640.942 414.871Z' />
        </g>;

      case 19 :
        return <g id='Mini gem_3'>
          <path id='Vector_320' fill={`#${diamondColors[color][0]}`}
                d='M631.135 499.174L668 533.564L640.58 559.241L631.134 500.401L595.975 540.304L634.546 596.578L640.58 559.241L631.135 499.174Z' />
          <path id='Vector_321' fill={`#${diamondColors[color][1]}`}
                d='M631.073 499.174L595.975 540.107L640.562 559.537L631.073 499.174Z' />
          <path id='Vector_322' fill='white'
                d='M631.074 499.174L630.095 500.315L639.048 557.273L596.976 538.94L595.975 540.107L640.562 559.537L631.074 499.174Z' />
          <path id='Vector_323' fill={`#${diamondColors[color][2]}`}
                d='M640.451 559.186L634.388 596.578L668 533.471L640.451 559.186Z' />
          <path id='Vector_324' fill={`#${diamondColors[color][2]}`}
                d='M640.451 559.186L634.388 596.578L668 533.471L640.451 559.186Z' />
          <path id='Vector_325' fill='white' d='M595.975 540.331L635.76 528.3L631.191 499.174L595.975 540.331Z'
                opacity='.2' />
          <path id='Vector_326' fill='white' d='M635.542 528.204L668 533.471L630.959 499.174L635.542 527.672V528.204Z'
                opacity='.2' />
          <path id='Vector_327' fill={`#${diamondColors[color][3]}`}
                d='M637.932 574.759C637.998 574.352 657.586 543.527 657.586 543.527L668 534.157L644.345 577.93L634.388 596.578L637.932 574.759Z'
                opacity='.5' />
          <path id='Vector_328' fill={`#${diamondColors[color][3]}`}
                d='M641.638 559.187L666.827 535.673L668 533.471L640.451 559.187L634.388 596.578L636.093 593.376L641.638 559.187Z' />
          <path id='Vector_329' fill={`#${diamondColors[color][3]}`}
                d='M608.949 559.275L639.876 559.537L595.975 540.331L608.949 559.275Z' opacity='.5' />
          <path id='Vector_330' fill='white'
                d='M598.033 539.617C599.386 541.004 602.714 541.2 604.597 541.561C608.522 542.409 612.569 542.553 616.545 541.987C621.363 541.21 625.147 539.092 628.887 536.111C631.33 534.165 632.295 532.252 633.319 529.485C633.622 528.778 633.86 528.046 634.031 527.298C635.263 527.951 635.099 532.124 635.452 533.514C636.146 536.247 636.903 538.902 637.189 541.696C637.731 546.321 638.512 550.915 639.53 555.46C639.722 556.39 640.327 557.593 639.267 558.062C638.427 558.434 636.935 557.702 636.211 557.437C634.062 556.651 632.4 555.298 630.332 554.321C628.321 553.372 626.196 552.866 624.168 551.916C622.021 550.911 619.923 550.001 617.859 548.835C614.698 547.051 611.19 545.656 607.883 544.261C606.222 543.56 604.477 542.898 602.897 542.176C601.647 541.604 598.861 540.945 598.164 539.745L598.033 539.617Z'
                opacity='.4' />
          <path id='Vector_331' fill='white'
                d='M646.555 570.338C653.534 565.769 655.487 556.407 650.918 549.429C646.349 542.45 636.987 540.497 630.009 545.066C623.03 549.635 621.076 558.996 625.646 565.975C630.215 572.954 639.576 574.907 646.555 570.338Z'
                opacity='.1' />
          <path id='Vector_332' fill='white'
                d='M650.765 558.533C650.489 560.754 649.56 562.844 648.096 564.537C646.632 566.231 644.699 567.452 642.541 568.047C640.383 568.642 638.097 568.584 635.972 567.88C633.848 567.176 631.979 565.857 630.604 564.091C629.228 562.325 628.407 560.191 628.245 557.958C628.082 555.726 628.585 553.495 629.691 551.548C630.796 549.602 632.453 548.026 634.454 547.022C636.454 546.017 638.708 545.628 640.929 545.905C643.908 546.275 646.618 547.813 648.462 550.182C650.307 552.55 651.135 555.554 650.765 558.533Z'
                opacity='.2' />
          <path id='Vector_333' fill='white'
                d='M640.701 549.67C639.809 553.005 639.758 557.333 633.702 557.096C635.375 558.155 637.268 559.135 638.299 560.971C639.256 562.863 639.757 564.957 639.762 567.083C640.343 565.15 640.116 563.249 641.573 561.386C642.102 560.694 642.78 560.133 643.556 559.746C644.332 559.358 645.184 559.154 646.05 559.149C644.266 557.836 642.578 557.794 641.7 555.335C641.354 554.404 641.089 553.444 640.907 552.466C640.743 551.479 641.022 550.018 640.491 549.248L640.701 549.67Z'
                opacity='.7' />
          <path id='Vector_334' fill='white'
                d='M641.812 557.859C641.384 556.477 640.922 556.638 640.523 557.228C638.522 556.229 638.81 563.035 641.143 561.315C641.933 560.732 642.077 558.715 641.812 557.859Z' />
          <path id='Vector_335' fill='white'
                d='M606.183 541.299C605.924 543.385 605.052 545.349 603.677 546.94C602.302 548.531 600.486 549.678 598.458 550.237C596.431 550.796 594.284 550.742 592.288 550.08C590.291 549.419 588.536 548.18 587.244 546.521C585.952 544.862 585.181 542.857 585.028 540.76C584.875 538.662 585.348 536.567 586.386 534.738C587.424 532.909 588.982 531.43 590.861 530.486C592.74 529.542 594.857 529.177 596.944 529.437C599.742 529.785 602.287 531.23 604.02 533.454C605.753 535.679 606.531 538.5 606.183 541.299Z'
                opacity='.1' />
          <path id='Vector_336' fill='white'
                d='M604.832 540.963C604.639 542.511 603.992 543.967 602.971 545.147C601.951 546.327 600.604 547.178 599.1 547.593C597.596 548.007 596.003 547.966 594.522 547.475C593.041 546.984 591.739 546.066 590.781 544.835C589.822 543.604 589.25 542.117 589.137 540.561C589.023 539.005 589.374 537.45 590.144 536.093C590.914 534.737 592.069 533.639 593.463 532.939C594.857 532.238 596.428 531.967 597.976 532.159C599.004 532.287 599.997 532.616 600.898 533.127C601.799 533.639 602.591 534.323 603.228 535.14C603.864 535.958 604.334 536.893 604.609 537.892C604.884 538.891 604.96 539.934 604.832 540.963Z'
                opacity='.2' />
          <path id='Vector_337' fill='white'
                d='M597.599 534.451C596.955 536.76 596.919 539.756 592.545 539.591C593.753 540.324 595.12 541.002 595.865 542.273C596.556 543.583 596.918 545.033 596.922 546.504C597.341 545.166 597.178 543.85 598.229 542.56C598.612 542.082 599.102 541.693 599.662 541.425C600.222 541.157 600.838 541.016 601.463 541.012C600.175 540.103 598.956 540.074 598.322 538.371C598.072 537.726 597.88 537.062 597.749 536.385C597.628 535.702 597.831 534.691 597.449 534.157L597.599 534.451Z'
                opacity='.7' />
          <path id='Vector_338' fill='white'
                d='M637.758 501.476C637.566 502.957 636.919 504.35 635.898 505.479C634.878 506.608 633.531 507.423 632.027 507.819C630.523 508.216 628.93 508.177 627.449 507.708C625.967 507.239 624.665 506.36 623.707 505.182C622.748 504.005 622.176 502.582 622.062 501.094C621.949 499.605 622.299 498.118 623.07 496.82C623.84 495.522 624.995 494.472 626.39 493.803C627.784 493.133 629.354 492.874 630.903 493.058C632.979 493.304 634.867 494.33 636.153 495.909C637.439 497.487 638.016 499.49 637.758 501.476Z'
                opacity='.1' />
          <path id='Vector_339' fill='white'
                d='M636.402 501.222C636.259 502.299 635.781 503.312 635.027 504.134C634.273 504.955 633.277 505.547 632.165 505.836C631.054 506.124 629.876 506.096 628.781 505.754C627.687 505.413 626.724 504.774 626.016 503.917C625.307 503.061 624.884 502.026 624.8 500.944C624.717 499.861 624.976 498.779 625.546 497.836C626.115 496.892 626.969 496.128 628 495.641C629.03 495.154 630.191 494.966 631.336 495.1C632.095 495.189 632.829 495.417 633.495 495.773C634.161 496.129 634.746 496.605 635.216 497.173C635.686 497.742 636.033 498.392 636.237 499.087C636.44 499.781 636.496 500.507 636.402 501.222Z'
                opacity='.2' />
          <path id='Vector_340' fill='white'
                d='M631.029 496.64C630.583 498.308 630.558 500.472 627.529 500.353C628.366 500.884 629.312 501.373 629.827 502.291C630.306 503.237 630.557 504.284 630.559 505.347C630.849 504.381 630.736 503.43 631.464 502.498C631.729 502.153 632.068 501.872 632.456 501.679C632.844 501.485 633.27 501.383 633.702 501.38C632.811 500.723 631.966 500.703 631.528 499.473C631.355 499.007 631.222 498.527 631.131 498.038C631.048 497.545 631.188 496.815 630.923 496.43L631.029 496.64Z'
                opacity='.7' />
          <path id='Vector_341' fill='white'
                d='M597.942 539.871C597.621 538.688 597.274 538.825 596.975 539.332C595.474 538.477 595.69 544.309 597.44 542.834C598.033 542.334 598.14 540.604 597.942 539.871Z' />
        </g>;


      case 20 :
        return <g id='Mini gem_4'>
          <path id='Vector_342' fill={`#${diamondColors[color][0]}`}
                d='M1026.13 212.174L1063 246.564L1035.58 272.241L1026.13 213.401L990.975 253.304L1029.55 309.578L1035.58 272.241L1026.13 212.174Z' />
          <path id='Vector_343' fill={`#${diamondColors[color][1]}`}
                d='M1026.07 212.174L990.975 253.107L1035.56 272.537L1026.07 212.174Z' />
          <path id='Vector_344' fill='white'
                d='M1026.07 212.174L1025.09 213.315L1034.05 270.273L991.976 251.94L990.975 253.107L1035.56 272.537L1026.07 212.174Z' />
          <path id='Vector_345' fill={`#${diamondColors[color][2]}`}
                d='M1035.45 272.186L1029.39 309.578L1063 246.471L1035.45 272.186Z' />
          <path id='Vector_346' fill={`#${diamondColors[color][2]}`}
                d='M1035.45 272.186L1029.39 309.578L1063 246.471L1035.45 272.186Z' />
          <path id='Vector_347' fill='white' d='M990.975 253.331L1030.76 241.3L1026.19 212.174L990.975 253.331Z'
                opacity='.2' />
          <path id='Vector_348' fill='white' d='M1030.54 241.204L1063 246.471L1025.96 212.174L1030.54 240.672V241.204Z'
                opacity='.2' />
          <path id='Vector_349' fill={`#${diamondColors[color][3]}`}
                d='M1032.93 287.759C1033 287.352 1052.59 256.527 1052.59 256.527L1063 247.157L1039.35 290.93L1029.39 309.578L1032.93 287.759Z'
                opacity='.5' />
          <path id='Vector_350' fill={`#${diamondColors[color][3]}`}
                d='M1036.64 272.187L1061.83 248.673L1063 246.471L1035.45 272.187L1029.39 309.578L1031.09 306.376L1036.64 272.187Z' />
          <path id='Vector_351' fill={`#${diamondColors[color][3]}`}
                d='M1003.95 272.275L1034.88 272.537L990.975 253.331L1003.95 272.275Z' opacity='.5' />
          <path id='Vector_352' fill='white'
                d='M993.033 252.617C994.386 254.004 997.714 254.2 999.597 254.561C1003.52 255.409 1007.57 255.553 1011.55 254.987C1016.36 254.21 1020.15 252.092 1023.89 249.111C1026.33 247.165 1027.3 245.252 1028.32 242.485C1028.62 241.778 1028.86 241.046 1029.03 240.298C1030.26 240.951 1030.1 245.124 1030.45 246.514C1031.15 249.247 1031.9 251.902 1032.19 254.696C1032.73 259.321 1033.51 263.915 1034.53 268.46C1034.72 269.39 1035.33 270.593 1034.27 271.062C1033.43 271.434 1031.93 270.702 1031.21 270.437C1029.06 269.651 1027.4 268.298 1025.33 267.321C1023.32 266.372 1021.2 265.866 1019.17 264.916C1017.02 263.911 1014.92 263.001 1012.86 261.835C1009.7 260.051 1006.19 258.656 1002.88 257.261C1001.22 256.56 999.477 255.898 997.897 255.176C996.647 254.604 993.861 253.945 993.164 252.745L993.033 252.617Z'
                opacity='.4' />
          <path id='Vector_353' fill='white'
                d='M1041.55 283.338C1048.53 278.769 1050.49 269.407 1045.92 262.429C1041.35 255.45 1031.99 253.497 1025.01 258.066C1018.03 262.635 1016.08 271.996 1020.65 278.975C1025.21 285.954 1034.58 287.907 1041.55 283.338Z'
                opacity='.1' />
          <path id='Vector_354' fill='white'
                d='M1045.76 271.533C1045.49 273.754 1044.56 275.844 1043.1 277.537C1041.63 279.231 1039.7 280.452 1037.54 281.047C1035.38 281.642 1033.1 281.584 1030.97 280.88C1028.85 280.176 1026.98 278.857 1025.6 277.091C1024.23 275.325 1023.41 273.191 1023.24 270.958C1023.08 268.726 1023.59 266.495 1024.69 264.548C1025.8 262.602 1027.45 261.026 1029.45 260.022C1031.45 259.017 1033.71 258.628 1035.93 258.905C1038.91 259.275 1041.62 260.813 1043.46 263.182C1045.31 265.55 1046.14 268.554 1045.76 271.533Z'
                opacity='.2' />
          <path id='Vector_355' fill='white'
                d='M1035.7 262.67C1034.81 266.005 1034.76 270.333 1028.7 270.096C1030.38 271.155 1032.27 272.135 1033.3 273.971C1034.26 275.863 1034.76 277.957 1034.76 280.083C1035.34 278.15 1035.12 276.249 1036.57 274.386C1037.1 273.694 1037.78 273.134 1038.56 272.746C1039.33 272.358 1040.18 272.154 1041.05 272.149C1039.27 270.836 1037.58 270.794 1036.7 268.335C1036.35 267.404 1036.09 266.444 1035.91 265.466C1035.74 264.479 1036.02 263.018 1035.49 262.248L1035.7 262.67Z'
                opacity='.7' />
          <path id='Vector_356' fill='white'
                d='M1036.81 270.859C1036.38 269.477 1035.92 269.638 1035.52 270.228C1033.52 269.229 1033.81 276.035 1036.14 274.315C1036.93 273.732 1037.08 271.715 1036.81 270.859Z' />
          <path id='Vector_357' fill='white'
                d='M1001.18 254.299C1000.92 256.385 1000.05 258.349 998.677 259.94C997.302 261.531 995.486 262.678 993.458 263.237C991.431 263.796 989.284 263.742 987.288 263.08C985.291 262.419 983.536 261.18 982.244 259.521C980.952 257.862 980.181 255.857 980.028 253.76C979.875 251.662 980.348 249.567 981.386 247.738C982.424 245.909 983.982 244.43 985.861 243.486C987.74 242.542 989.857 242.177 991.944 242.437C994.742 242.785 997.287 244.23 999.02 246.454C1000.75 248.679 1001.53 251.5 1001.18 254.299Z'
                opacity='.1' />
          <path id='Vector_358' fill='white'
                d='M999.832 253.963C999.639 255.511 998.992 256.967 997.971 258.147C996.951 259.327 995.604 260.178 994.1 260.593C992.596 261.007 991.003 260.966 989.522 260.475C988.041 259.984 986.739 259.066 985.781 257.835C984.822 256.604 984.25 255.117 984.137 253.561C984.023 252.005 984.374 250.45 985.144 249.093C985.914 247.737 987.069 246.639 988.463 245.939C989.857 245.238 991.428 244.967 992.976 245.159C994.004 245.287 994.997 245.616 995.898 246.127C996.799 246.639 997.591 247.323 998.228 248.14C998.864 248.958 999.334 249.893 999.609 250.892C999.884 251.891 999.96 252.934 999.832 253.963Z'
                opacity='.2' />
          <path id='Vector_359' fill='white'
                d='M992.599 247.451C991.955 249.76 991.919 252.756 987.545 252.591C988.753 253.324 990.12 254.002 990.865 255.273C991.556 256.583 991.918 258.033 991.922 259.504C992.341 258.166 992.178 256.85 993.229 255.56C993.612 255.082 994.102 254.693 994.662 254.425C995.222 254.157 995.838 254.016 996.463 254.012C995.175 253.103 993.956 253.074 993.322 251.371C993.072 250.726 992.88 250.062 992.749 249.385C992.628 248.702 992.831 247.691 992.449 247.157L992.599 247.451Z'
                opacity='.7' />
          <path id='Vector_360' fill='white'
                d='M1032.76 214.476C1032.57 215.957 1031.92 217.35 1030.9 218.479C1029.88 219.608 1028.53 220.423 1027.03 220.819C1025.52 221.216 1023.93 221.177 1022.45 220.708C1020.97 220.239 1019.67 219.36 1018.71 218.182C1017.75 217.005 1017.18 215.582 1017.06 214.094C1016.95 212.605 1017.3 211.118 1018.07 209.82C1018.84 208.522 1020 207.472 1021.39 206.803C1022.78 206.133 1024.35 205.874 1025.9 206.058C1027.98 206.304 1029.87 207.33 1031.15 208.909C1032.44 210.487 1033.02 212.49 1032.76 214.476Z'
                opacity='.1' />
          <path id='Vector_361' fill='white'
                d='M1031.4 214.222C1031.26 215.299 1030.78 216.312 1030.03 217.134C1029.27 217.955 1028.28 218.547 1027.17 218.836C1026.05 219.124 1024.88 219.096 1023.78 218.754C1022.69 218.413 1021.72 217.774 1021.02 216.917C1020.31 216.061 1019.88 215.026 1019.8 213.944C1019.72 212.861 1019.98 211.779 1020.55 210.836C1021.11 209.892 1021.97 209.128 1023 208.641C1024.03 208.154 1025.19 207.966 1026.34 208.1C1027.1 208.189 1027.83 208.417 1028.49 208.773C1029.16 209.129 1029.75 209.605 1030.22 210.173C1030.69 210.742 1031.03 211.392 1031.24 212.087C1031.44 212.781 1031.5 213.507 1031.4 214.222Z'
                opacity='.2' />
          <path id='Vector_362' fill='white'
                d='M1026.03 209.64C1025.58 211.308 1025.56 213.472 1022.53 213.353C1023.37 213.884 1024.31 214.373 1024.83 215.291C1025.31 216.237 1025.56 217.284 1025.56 218.347C1025.85 217.381 1025.74 216.43 1026.46 215.498C1026.73 215.153 1027.07 214.872 1027.46 214.679C1027.84 214.485 1028.27 214.383 1028.7 214.38C1027.81 213.723 1026.97 213.703 1026.53 212.473C1026.35 212.007 1026.22 211.527 1026.13 211.038C1026.05 210.545 1026.19 209.815 1025.92 209.43L1026.03 209.64Z'
                opacity='.7' />
          <path id='Vector_363' fill='white'
                d='M992.942 252.871C992.621 251.688 992.274 251.825 991.975 252.332C990.474 251.477 990.69 257.309 992.44 255.834C993.033 255.334 993.14 253.604 992.942 252.871Z' />
        </g>;


      case 21 :
        return <g id='Mini gem_5'>
          <path id='Vector_364' fill={`#${diamondColors[color][0]}`}
                d='M975.135 424.174L1012 458.564L984.58 484.241L975.134 425.401L939.975 465.304L978.546 521.578L984.58 484.241L975.135 424.174Z' />
          <path id='Vector_365' fill={`#${diamondColors[color][1]}`}
                d='M975.073 424.174L939.975 465.107L984.562 484.537L975.073 424.174Z' />
          <path id='Vector_366' fill='white'
                d='M975.074 424.174L974.095 425.315L983.048 482.273L940.976 463.94L939.975 465.107L984.562 484.537L975.074 424.174Z' />
          <path id='Vector_367' fill={`#${diamondColors[color][2]}`}
                d='M984.451 484.186L978.388 521.578L1012 458.471L984.451 484.186Z' />
          <path id='Vector_368' fill={`#${diamondColors[color][2]}`}
                d='M984.451 484.186L978.388 521.578L1012 458.471L984.451 484.186Z' />
          <path id='Vector_369' fill='white' d='M939.975 465.331L979.76 453.3L975.191 424.174L939.975 465.331Z'
                opacity='.2' />
          <path id='Vector_370' fill='white' d='M979.542 453.204L1012 458.471L974.959 424.174L979.542 452.672V453.204Z'
                opacity='.2' />
          <path id='Vector_371' fill={`#${diamondColors[color][3]}`}
                d='M981.932 499.759C981.998 499.352 1001.59 468.527 1001.59 468.527L1012 459.157L988.345 502.93L978.388 521.578L981.932 499.759Z'
                opacity='.5' />
          <path id='Vector_372' fill={`#${diamondColors[color][3]}`}
                d='M985.638 484.187L1010.83 460.673L1012 458.471L984.451 484.187L978.388 521.578L980.093 518.376L985.638 484.187Z' />
          <path id='Vector_373' fill={`#${diamondColors[color][3]}`}
                d='M952.949 484.275L983.876 484.537L939.975 465.331L952.949 484.275Z' opacity='.5' />
          <path id='Vector_374' fill='white'
                d='M942.033 464.617C943.386 466.004 946.714 466.2 948.597 466.561C952.522 467.409 956.569 467.553 960.545 466.987C965.363 466.21 969.147 464.092 972.887 461.111C975.33 459.165 976.295 457.252 977.319 454.485C977.622 453.778 977.86 453.046 978.031 452.298C979.263 452.951 979.099 457.124 979.452 458.514C980.146 461.247 980.903 463.902 981.189 466.696C981.731 471.321 982.512 475.915 983.53 480.46C983.722 481.39 984.327 482.593 983.267 483.062C982.427 483.434 980.935 482.702 980.211 482.437C978.062 481.651 976.4 480.298 974.332 479.321C972.321 478.372 970.196 477.866 968.168 476.916C966.021 475.911 963.923 475.001 961.859 473.835C958.698 472.051 955.19 470.656 951.883 469.261C950.222 468.56 948.477 467.898 946.897 467.176C945.647 466.604 942.861 465.945 942.164 464.745L942.033 464.617Z'
                opacity='.4' />
          <path id='Vector_375' fill='white'
                d='M990.555 495.338C997.534 490.769 999.487 481.407 994.918 474.429C990.349 467.45 980.987 465.497 974.009 470.066C967.03 474.635 965.076 483.996 969.646 490.975C974.215 497.954 983.576 499.907 990.555 495.338Z'
                opacity='.1' />
          <path id='Vector_376' fill='white'
                d='M994.765 483.533C994.489 485.754 993.56 487.844 992.096 489.537C990.632 491.231 988.699 492.452 986.541 493.047C984.383 493.642 982.097 493.584 979.972 492.88C977.848 492.176 975.979 490.857 974.604 489.091C973.228 487.325 972.407 485.191 972.245 482.958C972.082 480.726 972.585 478.495 973.691 476.548C974.796 474.602 976.454 473.026 978.454 472.022C980.454 471.017 982.708 470.628 984.929 470.905C987.908 471.275 990.618 472.813 992.462 475.182C994.307 477.55 995.135 480.554 994.765 483.533Z'
                opacity='.2' />
          <path id='Vector_377' fill='white'
                d='M984.701 474.67C983.809 478.005 983.758 482.333 977.702 482.096C979.375 483.155 981.268 484.135 982.299 485.971C983.256 487.863 983.757 489.957 983.762 492.083C984.343 490.15 984.116 488.249 985.573 486.386C986.102 485.694 986.78 485.133 987.556 484.746C988.332 484.358 989.184 484.154 990.05 484.149C988.266 482.836 986.578 482.794 985.7 480.335C985.354 479.404 985.089 478.444 984.907 477.466C984.743 476.479 985.022 475.018 984.491 474.248L984.701 474.67Z'
                opacity='.7' />
          <path id='Vector_378' fill='white'
                d='M985.812 482.859C985.384 481.477 984.922 481.638 984.523 482.228C982.522 481.229 982.81 488.035 985.143 486.315C985.933 485.732 986.077 483.715 985.812 482.859Z' />
          <path id='Vector_379' fill='white'
                d='M950.183 466.299C949.924 468.385 949.052 470.349 947.677 471.94C946.302 473.531 944.486 474.678 942.458 475.237C940.431 475.796 938.284 475.742 936.288 475.08C934.291 474.419 932.536 473.18 931.244 471.521C929.952 469.862 929.181 467.857 929.028 465.76C928.875 463.662 929.348 461.567 930.386 459.738C931.424 457.909 932.982 456.43 934.861 455.486C936.74 454.542 938.857 454.177 940.944 454.437C943.742 454.785 946.287 456.23 948.02 458.454C949.753 460.679 950.531 463.5 950.183 466.299Z'
                opacity='.1' />
          <path id='Vector_380' fill='white'
                d='M948.832 465.963C948.639 467.511 947.992 468.967 946.971 470.147C945.951 471.327 944.604 472.178 943.1 472.593C941.596 473.007 940.003 472.966 938.522 472.475C937.041 471.984 935.739 471.066 934.781 469.835C933.822 468.604 933.25 467.117 933.137 465.561C933.023 464.005 933.374 462.45 934.144 461.093C934.914 459.737 936.069 458.639 937.463 457.939C938.857 457.238 940.428 456.967 941.976 457.159C943.004 457.287 943.997 457.616 944.898 458.127C945.799 458.639 946.591 459.323 947.228 460.14C947.864 460.958 948.334 461.893 948.609 462.892C948.884 463.891 948.96 464.934 948.832 465.963Z'
                opacity='.2' />
          <path id='Vector_381' fill='white'
                d='M941.599 459.451C940.955 461.76 940.919 464.756 936.545 464.591C937.753 465.324 939.12 466.002 939.865 467.273C940.556 468.583 940.918 470.033 940.922 471.504C941.341 470.166 941.178 468.85 942.229 467.56C942.612 467.082 943.102 466.693 943.662 466.425C944.222 466.157 944.838 466.016 945.463 466.012C944.175 465.103 942.956 465.074 942.322 463.371C942.072 462.726 941.88 462.062 941.749 461.385C941.628 460.702 941.831 459.691 941.449 459.157L941.599 459.451Z'
                opacity='.7' />
          <path id='Vector_382' fill='white'
                d='M981.758 426.476C981.566 427.957 980.918 429.35 979.898 430.479C978.878 431.608 977.531 432.423 976.027 432.819C974.523 433.216 972.93 433.177 971.449 432.708C969.967 432.239 968.665 431.36 967.707 430.182C966.748 429.005 966.176 427.582 966.062 426.094C965.949 424.605 966.299 423.118 967.07 421.82C967.84 420.522 968.995 419.472 970.39 418.803C971.784 418.133 973.354 417.874 974.903 418.058C976.979 418.304 978.867 419.33 980.153 420.909C981.439 422.487 982.016 424.49 981.758 426.476Z'
                opacity='.1' />
          <path id='Vector_383' fill='white'
                d='M980.402 426.222C980.259 427.299 979.781 428.312 979.027 429.134C978.273 429.955 977.277 430.547 976.165 430.836C975.054 431.124 973.876 431.096 972.781 430.754C971.687 430.413 970.724 429.774 970.016 428.917C969.307 428.061 968.884 427.026 968.8 425.944C968.717 424.861 968.976 423.779 969.546 422.836C970.115 421.892 970.969 421.128 972 420.641C973.03 420.154 974.191 419.966 975.336 420.1C976.095 420.189 976.829 420.417 977.495 420.773C978.161 421.129 978.746 421.605 979.216 422.173C979.686 422.742 980.033 423.392 980.237 424.087C980.44 424.781 980.496 425.507 980.402 426.222Z'
                opacity='.2' />
          <path id='Vector_384' fill='white'
                d='M975.029 421.64C974.583 423.308 974.558 425.472 971.529 425.353C972.366 425.884 973.312 426.373 973.827 427.291C974.306 428.237 974.557 429.284 974.559 430.347C974.849 429.381 974.736 428.43 975.464 427.498C975.729 427.153 976.068 426.872 976.456 426.679C976.844 426.485 977.27 426.383 977.702 426.38C976.811 425.723 975.966 425.703 975.528 424.473C975.355 424.007 975.222 423.527 975.131 423.038C975.048 422.545 975.188 421.815 974.923 421.43L975.029 421.64Z'
                opacity='.7' />
          <path id='Vector_385' fill='white'
                d='M941.942 464.871C941.621 463.688 941.274 463.825 940.975 464.332C939.474 463.477 939.69 469.309 941.44 467.834C942.033 467.334 942.14 465.604 941.942 464.871Z' />
        </g>;


      case 22 :
        return <g id='Mini gem_6'>
          <path id='Vector_386' fill={`#${diamondColors[color][0]}`}
                d='M1141.13 348.174L1178 382.564L1150.58 408.241L1141.13 349.401L1105.98 389.304L1144.55 445.578L1150.58 408.241L1141.13 348.174Z' />
          <path id='Vector_387' fill={`#${diamondColors[color][1]}`}
                d='M1141.07 348.174L1105.98 389.107L1150.56 408.537L1141.07 348.174Z' />
          <path id='Vector_388' fill='white'
                d='M1141.07 348.174L1140.09 349.315L1149.05 406.273L1106.98 387.94L1105.98 389.107L1150.56 408.537L1141.07 348.174Z' />
          <path id='Vector_389' fill={`#${diamondColors[color][2]}`}
                d='M1150.45 408.186L1144.39 445.578L1178 382.471L1150.45 408.186Z' />
          <path id='Vector_390' fill={`#${diamondColors[color][2]}`}
                d='M1150.45 408.186L1144.39 445.578L1178 382.471L1150.45 408.186Z' />
          <path id='Vector_391' fill='white' d='M1105.98 389.331L1145.76 377.3L1141.19 348.174L1105.98 389.331Z'
                opacity='.2' />
          <path id='Vector_392' fill='white' d='M1145.54 377.204L1178 382.471L1140.96 348.174L1145.54 376.672V377.204Z'
                opacity='.2' />
          <path id='Vector_393' fill={`#${diamondColors[color][3]}`}
                d='M1147.93 423.759C1148 423.352 1167.59 392.527 1167.59 392.527L1178 383.157L1154.35 426.93L1144.39 445.578L1147.93 423.759Z'
                opacity='.5' />
          <path id='Vector_394' fill={`#${diamondColors[color][3]}`}
                d='M1151.64 408.187L1176.83 384.673L1178 382.471L1150.45 408.187L1144.39 445.578L1146.09 442.376L1151.64 408.187Z' />
          <path id='Vector_395' fill={`#${diamondColors[color][3]}`}
                d='M1118.95 408.275L1149.88 408.537L1105.98 389.331L1118.95 408.275Z' opacity='.5' />
          <path id='Vector_396' fill='white'
                d='M1108.03 388.617C1109.39 390.004 1112.71 390.2 1114.6 390.561C1118.52 391.409 1122.57 391.553 1126.55 390.987C1131.36 390.21 1135.15 388.092 1138.89 385.111C1141.33 383.165 1142.3 381.252 1143.32 378.485C1143.62 377.778 1143.86 377.046 1144.03 376.298C1145.26 376.951 1145.1 381.124 1145.45 382.514C1146.15 385.247 1146.9 387.902 1147.19 390.696C1147.73 395.321 1148.51 399.915 1149.53 404.46C1149.72 405.39 1150.33 406.593 1149.27 407.062C1148.43 407.434 1146.93 406.702 1146.21 406.437C1144.06 405.651 1142.4 404.298 1140.33 403.321C1138.32 402.372 1136.2 401.866 1134.17 400.916C1132.02 399.911 1129.92 399.001 1127.86 397.835C1124.7 396.051 1121.19 394.656 1117.88 393.261C1116.22 392.56 1114.48 391.898 1112.9 391.176C1111.65 390.604 1108.86 389.945 1108.16 388.745L1108.03 388.617Z'
                opacity='.4' />
          <path id='Vector_397' fill='white'
                d='M1156.55 419.338C1163.53 414.769 1165.49 405.407 1160.92 398.429C1156.35 391.45 1146.99 389.497 1140.01 394.066C1133.03 398.635 1131.08 407.996 1135.65 414.975C1140.21 421.954 1149.58 423.907 1156.55 419.338Z'
                opacity='.1' />
          <path id='Vector_398' fill='white'
                d='M1160.76 407.533C1160.49 409.754 1159.56 411.844 1158.1 413.537C1156.63 415.231 1154.7 416.452 1152.54 417.047C1150.38 417.642 1148.1 417.584 1145.97 416.88C1143.85 416.176 1141.98 414.857 1140.6 413.091C1139.23 411.325 1138.41 409.191 1138.24 406.958C1138.08 404.726 1138.59 402.495 1139.69 400.548C1140.8 398.602 1142.45 397.026 1144.45 396.022C1146.45 395.017 1148.71 394.628 1150.93 394.905C1153.91 395.275 1156.62 396.813 1158.46 399.182C1160.31 401.55 1161.14 404.554 1160.76 407.533Z'
                opacity='.2' />
          <path id='Vector_399' fill='white'
                d='M1150.7 398.67C1149.81 402.005 1149.76 406.333 1143.7 406.096C1145.38 407.155 1147.27 408.135 1148.3 409.971C1149.26 411.863 1149.76 413.957 1149.76 416.083C1150.34 414.15 1150.12 412.249 1151.57 410.386C1152.1 409.694 1152.78 409.133 1153.56 408.746C1154.33 408.358 1155.18 408.154 1156.05 408.149C1154.27 406.836 1152.58 406.794 1151.7 404.335C1151.35 403.404 1151.09 402.444 1150.91 401.466C1150.74 400.479 1151.02 399.018 1150.49 398.248L1150.7 398.67Z'
                opacity='.7' />
          <path id='Vector_400' fill='white'
                d='M1151.81 406.859C1151.38 405.477 1150.92 405.638 1150.52 406.228C1148.52 405.229 1148.81 412.035 1151.14 410.315C1151.93 409.732 1152.08 407.715 1151.81 406.859Z' />
          <path id='Vector_401' fill='white'
                d='M1116.18 390.299C1115.92 392.385 1115.05 394.349 1113.68 395.94C1112.3 397.531 1110.49 398.678 1108.46 399.237C1106.43 399.796 1104.28 399.742 1102.29 399.08C1100.29 398.419 1098.54 397.18 1097.24 395.521C1095.95 393.862 1095.18 391.857 1095.03 389.76C1094.88 387.662 1095.35 385.567 1096.39 383.738C1097.42 381.909 1098.98 380.43 1100.86 379.486C1102.74 378.542 1104.86 378.177 1106.94 378.437C1109.74 378.785 1112.29 380.23 1114.02 382.454C1115.75 384.679 1116.53 387.5 1116.18 390.299Z'
                opacity='.1' />
          <path id='Vector_402' fill='white'
                d='M1114.83 389.963C1114.64 391.511 1113.99 392.967 1112.97 394.147C1111.95 395.327 1110.6 396.178 1109.1 396.593C1107.6 397.007 1106 396.966 1104.52 396.475C1103.04 395.984 1101.74 395.066 1100.78 393.835C1099.82 392.604 1099.25 391.117 1099.14 389.561C1099.02 388.005 1099.37 386.45 1100.14 385.093C1100.91 383.737 1102.07 382.639 1103.46 381.939C1104.86 381.238 1106.43 380.967 1107.98 381.159C1109 381.287 1110 381.616 1110.9 382.127C1111.8 382.639 1112.59 383.323 1113.23 384.14C1113.86 384.958 1114.33 385.893 1114.61 386.892C1114.88 387.891 1114.96 388.934 1114.83 389.963Z'
                opacity='.2' />
          <path id='Vector_403' fill='white'
                d='M1107.6 383.451C1106.96 385.76 1106.92 388.756 1102.55 388.591C1103.75 389.324 1105.12 390.002 1105.86 391.273C1106.56 392.583 1106.92 394.033 1106.92 395.504C1107.34 394.166 1107.18 392.85 1108.23 391.56C1108.61 391.082 1109.1 390.693 1109.66 390.425C1110.22 390.157 1110.84 390.016 1111.46 390.012C1110.17 389.103 1108.96 389.074 1108.32 387.371C1108.07 386.726 1107.88 386.062 1107.75 385.385C1107.63 384.702 1107.83 383.691 1107.45 383.157L1107.6 383.451Z'
                opacity='.7' />
          <path id='Vector_404' fill='white'
                d='M1147.76 350.476C1147.57 351.957 1146.92 353.35 1145.9 354.479C1144.88 355.608 1143.53 356.423 1142.03 356.819C1140.52 357.216 1138.93 357.177 1137.45 356.708C1135.97 356.239 1134.67 355.36 1133.71 354.182C1132.75 353.005 1132.18 351.582 1132.06 350.094C1131.95 348.605 1132.3 347.118 1133.07 345.82C1133.84 344.522 1135 343.472 1136.39 342.803C1137.78 342.133 1139.35 341.874 1140.9 342.058C1142.98 342.304 1144.87 343.33 1146.15 344.909C1147.44 346.487 1148.02 348.49 1147.76 350.476Z'
                opacity='.1' />
          <path id='Vector_405' fill='white'
                d='M1146.4 350.222C1146.26 351.299 1145.78 352.312 1145.03 353.134C1144.27 353.955 1143.28 354.547 1142.17 354.836C1141.05 355.124 1139.88 355.096 1138.78 354.754C1137.69 354.413 1136.72 353.774 1136.02 352.917C1135.31 352.061 1134.88 351.026 1134.8 349.944C1134.72 348.861 1134.98 347.779 1135.55 346.836C1136.11 345.892 1136.97 345.128 1138 344.641C1139.03 344.154 1140.19 343.966 1141.34 344.1C1142.1 344.189 1142.83 344.417 1143.49 344.773C1144.16 345.129 1144.75 345.605 1145.22 346.173C1145.69 346.742 1146.03 347.392 1146.24 348.087C1146.44 348.781 1146.5 349.507 1146.4 350.222Z'
                opacity='.2' />
          <path id='Vector_406' fill='white'
                d='M1141.03 345.64C1140.58 347.308 1140.56 349.472 1137.53 349.353C1138.37 349.884 1139.31 350.373 1139.83 351.291C1140.31 352.237 1140.56 353.284 1140.56 354.347C1140.85 353.381 1140.74 352.43 1141.46 351.498C1141.73 351.153 1142.07 350.872 1142.46 350.679C1142.84 350.485 1143.27 350.383 1143.7 350.38C1142.81 349.723 1141.97 349.703 1141.53 348.473C1141.35 348.007 1141.22 347.527 1141.13 347.038C1141.05 346.545 1141.19 345.815 1140.92 345.43L1141.03 345.64Z'
                opacity='.7' />
          <path id='Vector_407' fill='white'
                d='M1107.94 388.871C1107.62 387.688 1107.27 387.825 1106.98 388.332C1105.47 387.477 1105.69 393.309 1107.44 391.834C1108.03 391.334 1108.14 389.604 1107.94 388.871Z' />
        </g>;


      case 23 :
        return <g id='Mini gem_7'>
          <path id='Vector_408' fill={`#${diamondColors[color][0]}`}
                d='M858.135 522.174L895 556.564L867.58 582.241L858.134 523.401L822.975 563.304L861.546 619.578L867.58 582.241L858.135 522.174Z' />
          <path id='Vector_409' fill={`#${diamondColors[color][1]}`}
                d='M858.073 522.174L822.975 563.107L867.562 582.537L858.073 522.174Z' />
          <path id='Vector_410' fill='white'
                d='M858.074 522.174L857.095 523.315L866.048 580.273L823.976 561.94L822.975 563.107L867.562 582.537L858.074 522.174Z' />
          <path id='Vector_411' fill={`#${diamondColors[color][2]}`}
                d='M867.451 582.186L861.388 619.578L895 556.471L867.451 582.186Z' />
          <path id='Vector_412' fill={`#${diamondColors[color][2]}`}
                d='M867.451 582.186L861.388 619.578L895 556.471L867.451 582.186Z' />
          <path id='Vector_413' fill='white' d='M822.975 563.331L862.76 551.3L858.191 522.174L822.975 563.331Z'
                opacity='.2' />
          <path id='Vector_414' fill='white' d='M862.542 551.204L895 556.471L857.959 522.174L862.542 550.672V551.204Z'
                opacity='.2' />
          <path id='Vector_415' fill={`#${diamondColors[color][3]}`}
                d='M864.932 597.759C864.998 597.352 884.586 566.527 884.586 566.527L895 557.157L871.345 600.93L861.388 619.578L864.932 597.759Z'
                opacity='.5' />
          <path id='Vector_416' fill={`#${diamondColors[color][3]}`}
                d='M868.638 582.187L893.827 558.673L895 556.471L867.451 582.187L861.388 619.578L863.093 616.376L868.638 582.187Z' />
          <path id='Vector_417' fill={`#${diamondColors[color][3]}`}
                d='M835.949 582.275L866.876 582.537L822.975 563.331L835.949 582.275Z' opacity='.5' />
          <path id='Vector_418' fill='white'
                d='M825.033 562.617C826.386 564.004 829.714 564.2 831.597 564.561C835.522 565.409 839.569 565.553 843.545 564.987C848.363 564.21 852.147 562.092 855.887 559.111C858.33 557.165 859.295 555.252 860.319 552.485C860.622 551.778 860.86 551.046 861.031 550.298C862.263 550.951 862.099 555.124 862.452 556.514C863.146 559.247 863.903 561.902 864.189 564.696C864.731 569.321 865.512 573.915 866.53 578.46C866.722 579.39 867.327 580.593 866.267 581.062C865.427 581.434 863.935 580.702 863.211 580.437C861.062 579.651 859.4 578.298 857.332 577.321C855.321 576.372 853.196 575.866 851.168 574.916C849.021 573.911 846.923 573.001 844.859 571.835C841.698 570.051 838.19 568.656 834.883 567.261C833.222 566.56 831.477 565.898 829.897 565.176C828.647 564.604 825.861 563.945 825.164 562.745L825.033 562.617Z'
                opacity='.4' />
          <path id='Vector_419' fill='white'
                d='M873.555 593.338C880.534 588.769 882.487 579.407 877.918 572.429C873.349 565.45 863.987 563.497 857.009 568.066C850.03 572.635 848.076 581.996 852.646 588.975C857.215 595.954 866.576 597.907 873.555 593.338Z'
                opacity='.1' />
          <path id='Vector_420' fill='white'
                d='M877.765 581.533C877.489 583.754 876.56 585.844 875.096 587.537C873.632 589.231 871.699 590.452 869.541 591.047C867.383 591.642 865.097 591.584 862.972 590.88C860.848 590.176 858.979 588.857 857.604 587.091C856.228 585.325 855.407 583.191 855.245 580.958C855.082 578.726 855.585 576.495 856.691 574.548C857.796 572.602 859.454 571.026 861.454 570.022C863.454 569.017 865.708 568.628 867.929 568.905C870.908 569.275 873.618 570.813 875.462 573.182C877.307 575.55 878.135 578.554 877.765 581.533Z'
                opacity='.2' />
          <path id='Vector_421' fill='white'
                d='M867.701 572.67C866.809 576.005 866.758 580.333 860.702 580.096C862.375 581.155 864.268 582.135 865.299 583.971C866.256 585.863 866.757 587.957 866.762 590.083C867.343 588.15 867.116 586.249 868.573 584.386C869.102 583.694 869.78 583.133 870.556 582.746C871.332 582.358 872.184 582.154 873.05 582.149C871.266 580.836 869.578 580.794 868.7 578.335C868.354 577.404 868.089 576.444 867.907 575.466C867.743 574.479 868.022 573.018 867.491 572.248L867.701 572.67Z'
                opacity='.7' />
          <path id='Vector_422' fill='white'
                d='M868.812 580.859C868.384 579.477 867.922 579.638 867.523 580.228C865.522 579.229 865.81 586.035 868.143 584.315C868.933 583.732 869.077 581.715 868.812 580.859Z' />
          <path id='Vector_423' fill='white'
                d='M833.183 564.299C832.924 566.385 832.052 568.349 830.677 569.94C829.302 571.531 827.486 572.678 825.458 573.237C823.431 573.796 821.284 573.742 819.288 573.08C817.291 572.419 815.536 571.18 814.244 569.521C812.952 567.862 812.181 565.857 812.028 563.76C811.875 561.662 812.348 559.567 813.386 557.738C814.424 555.909 815.982 554.43 817.861 553.486C819.74 552.542 821.857 552.177 823.944 552.437C826.742 552.785 829.287 554.23 831.02 556.454C832.753 558.679 833.531 561.5 833.183 564.299Z'
                opacity='.1' />
          <path id='Vector_424' fill='white'
                d='M831.832 563.963C831.639 565.511 830.992 566.967 829.971 568.147C828.951 569.327 827.604 570.178 826.1 570.593C824.596 571.007 823.003 570.966 821.522 570.475C820.041 569.984 818.739 569.066 817.781 567.835C816.822 566.604 816.25 565.117 816.137 563.561C816.023 562.005 816.374 560.45 817.144 559.093C817.914 557.737 819.069 556.639 820.463 555.939C821.857 555.238 823.428 554.967 824.976 555.159C826.004 555.287 826.997 555.616 827.898 556.127C828.799 556.639 829.591 557.323 830.228 558.14C830.864 558.958 831.334 559.893 831.609 560.892C831.884 561.891 831.96 562.934 831.832 563.963Z'
                opacity='.2' />
          <path id='Vector_425' fill='white'
                d='M824.599 557.451C823.955 559.76 823.919 562.756 819.545 562.591C820.753 563.324 822.12 564.002 822.865 565.273C823.556 566.583 823.918 568.033 823.922 569.504C824.341 568.166 824.178 566.85 825.229 565.56C825.612 565.082 826.102 564.693 826.662 564.425C827.222 564.157 827.838 564.016 828.463 564.012C827.175 563.103 825.956 563.074 825.322 561.371C825.072 560.726 824.88 560.062 824.749 559.385C824.628 558.702 824.831 557.691 824.449 557.157L824.599 557.451Z'
                opacity='.7' />
          <path id='Vector_426' fill='white'
                d='M864.758 524.476C864.566 525.957 863.919 527.35 862.898 528.479C861.878 529.608 860.531 530.423 859.027 530.819C857.523 531.216 855.93 531.177 854.449 530.708C852.967 530.239 851.665 529.36 850.707 528.182C849.748 527.005 849.176 525.582 849.062 524.094C848.949 522.605 849.299 521.118 850.07 519.82C850.84 518.522 851.995 517.472 853.39 516.803C854.784 516.133 856.354 515.874 857.903 516.058C859.979 516.304 861.867 517.33 863.153 518.909C864.439 520.487 865.016 522.49 864.758 524.476Z'
                opacity='.1' />
          <path id='Vector_427' fill='white'
                d='M863.402 524.222C863.259 525.299 862.781 526.312 862.027 527.134C861.273 527.955 860.277 528.547 859.165 528.836C858.054 529.124 856.876 529.096 855.781 528.754C854.687 528.413 853.724 527.774 853.016 526.917C852.307 526.061 851.884 525.026 851.8 523.944C851.717 522.861 851.976 521.779 852.546 520.836C853.115 519.892 853.969 519.128 855 518.641C856.03 518.154 857.191 517.966 858.336 518.1C859.095 518.189 859.829 518.417 860.495 518.773C861.161 519.129 861.746 519.605 862.216 520.173C862.686 520.742 863.033 521.392 863.237 522.087C863.44 522.781 863.496 523.507 863.402 524.222Z'
                opacity='.2' />
          <path id='Vector_428' fill='white'
                d='M858.029 519.64C857.583 521.308 857.558 523.472 854.529 523.353C855.366 523.884 856.312 524.373 856.827 525.291C857.306 526.237 857.557 527.284 857.559 528.347C857.849 527.381 857.736 526.43 858.464 525.498C858.729 525.153 859.068 524.872 859.456 524.679C859.844 524.485 860.27 524.383 860.702 524.38C859.811 523.723 858.966 523.703 858.528 522.473C858.355 522.007 858.222 521.527 858.131 521.038C858.048 520.545 858.188 519.815 857.923 519.43L858.029 519.64Z'
                opacity='.7' />
          <path id='Vector_429' fill='white'
                d='M824.942 562.871C824.621 561.688 824.274 561.825 823.975 562.332C822.474 561.477 822.69 567.309 824.44 565.834C825.033 565.334 825.14 563.604 824.942 562.871Z' />
        </g>;


      case 24 :
        return <g id='Mini gem_8'>
          <path id='Vector_430' fill={`#${diamondColors[color][0]}`}
                d='M1072.13 530.174L1109 564.564L1081.58 590.241L1072.13 531.401L1036.98 571.304L1075.55 627.578L1081.58 590.241L1072.13 530.174Z' />
          <path id='Vector_431' fill={`#${diamondColors[color][1]}`}
                d='M1072.07 530.174L1036.98 571.107L1081.56 590.537L1072.07 530.174Z' />
          <path id='Vector_432' fill='white'
                d='M1072.07 530.174L1071.09 531.315L1080.05 588.273L1037.98 569.94L1036.98 571.107L1081.56 590.537L1072.07 530.174Z' />
          <path id='Vector_433' fill={`#${diamondColors[color][2]}`}
                d='M1081.45 590.186L1075.39 627.578L1109 564.471L1081.45 590.186Z' />
          <path id='Vector_434' fill={`#${diamondColors[color][2]}`}
                d='M1081.45 590.186L1075.39 627.578L1109 564.471L1081.45 590.186Z' />
          <path id='Vector_435' fill='white' d='M1036.98 571.331L1076.76 559.3L1072.19 530.174L1036.98 571.331Z'
                opacity='.2' />
          <path id='Vector_436' fill='white' d='M1076.54 559.204L1109 564.471L1071.96 530.174L1076.54 558.672V559.204Z'
                opacity='.2' />
          <path id='Vector_437' fill={`#${diamondColors[color][3]}`}
                d='M1078.93 605.759C1079 605.352 1098.59 574.527 1098.59 574.527L1109 565.157L1085.35 608.93L1075.39 627.578L1078.93 605.759Z'
                opacity='.5' />
          <path id='Vector_438' fill={`#${diamondColors[color][3]}`}
                d='M1082.64 590.187L1107.83 566.673L1109 564.471L1081.45 590.187L1075.39 627.578L1077.09 624.376L1082.64 590.187Z' />
          <path id='Vector_439' fill={`#${diamondColors[color][3]}`}
                d='M1049.95 590.275L1080.88 590.537L1036.98 571.331L1049.95 590.275Z' opacity='.5' />
          <path id='Vector_440' fill='white'
                d='M1039.03 570.617C1040.39 572.004 1043.71 572.2 1045.6 572.561C1049.52 573.409 1053.57 573.553 1057.55 572.987C1062.36 572.21 1066.15 570.092 1069.89 567.111C1072.33 565.165 1073.3 563.252 1074.32 560.485C1074.62 559.778 1074.86 559.046 1075.03 558.298C1076.26 558.951 1076.1 563.124 1076.45 564.514C1077.15 567.247 1077.9 569.902 1078.19 572.696C1078.73 577.321 1079.51 581.915 1080.53 586.46C1080.72 587.39 1081.33 588.593 1080.27 589.062C1079.43 589.434 1077.93 588.702 1077.21 588.437C1075.06 587.651 1073.4 586.298 1071.33 585.321C1069.32 584.372 1067.2 583.866 1065.17 582.916C1063.02 581.911 1060.92 581.001 1058.86 579.835C1055.7 578.051 1052.19 576.656 1048.88 575.261C1047.22 574.56 1045.48 573.898 1043.9 573.176C1042.65 572.604 1039.86 571.945 1039.16 570.745L1039.03 570.617Z'
                opacity='.4' />
          <path id='Vector_441' fill='white'
                d='M1087.55 601.338C1094.53 596.769 1096.49 587.407 1091.92 580.429C1087.35 573.45 1077.99 571.497 1071.01 576.066C1064.03 580.635 1062.08 589.996 1066.65 596.975C1071.21 603.954 1080.58 605.907 1087.55 601.338Z'
                opacity='.1' />
          <path id='Vector_442' fill='white'
                d='M1091.76 589.533C1091.49 591.754 1090.56 593.844 1089.1 595.537C1087.63 597.231 1085.7 598.452 1083.54 599.047C1081.38 599.642 1079.1 599.584 1076.97 598.88C1074.85 598.176 1072.98 596.857 1071.6 595.091C1070.23 593.325 1069.41 591.191 1069.24 588.958C1069.08 586.726 1069.59 584.495 1070.69 582.548C1071.8 580.602 1073.45 579.026 1075.45 578.022C1077.45 577.017 1079.71 576.628 1081.93 576.905C1084.91 577.275 1087.62 578.813 1089.46 581.182C1091.31 583.55 1092.14 586.554 1091.76 589.533Z'
                opacity='.2' />
          <path id='Vector_443' fill='white'
                d='M1081.7 580.67C1080.81 584.005 1080.76 588.333 1074.7 588.096C1076.38 589.155 1078.27 590.135 1079.3 591.971C1080.26 593.863 1080.76 595.957 1080.76 598.083C1081.34 596.15 1081.12 594.249 1082.57 592.386C1083.1 591.694 1083.78 591.133 1084.56 590.746C1085.33 590.358 1086.18 590.154 1087.05 590.149C1085.27 588.836 1083.58 588.794 1082.7 586.335C1082.35 585.404 1082.09 584.444 1081.91 583.466C1081.74 582.479 1082.02 581.018 1081.49 580.248L1081.7 580.67Z'
                opacity='.7' />
          <path id='Vector_444' fill='white'
                d='M1082.81 588.859C1082.38 587.477 1081.92 587.638 1081.52 588.228C1079.52 587.229 1079.81 594.035 1082.14 592.315C1082.93 591.732 1083.08 589.715 1082.81 588.859Z' />
          <path id='Vector_445' fill='white'
                d='M1047.18 572.299C1046.92 574.385 1046.05 576.349 1044.68 577.94C1043.3 579.531 1041.49 580.678 1039.46 581.237C1037.43 581.796 1035.28 581.742 1033.29 581.08C1031.29 580.419 1029.54 579.18 1028.24 577.521C1026.95 575.862 1026.18 573.857 1026.03 571.76C1025.88 569.662 1026.35 567.567 1027.39 565.738C1028.42 563.909 1029.98 562.43 1031.86 561.486C1033.74 560.542 1035.86 560.177 1037.94 560.437C1040.74 560.785 1043.29 562.23 1045.02 564.454C1046.75 566.679 1047.53 569.5 1047.18 572.299Z'
                opacity='.1' />
          <path id='Vector_446' fill='white'
                d='M1045.83 571.963C1045.64 573.511 1044.99 574.967 1043.97 576.147C1042.95 577.327 1041.6 578.178 1040.1 578.593C1038.6 579.007 1037 578.966 1035.52 578.475C1034.04 577.984 1032.74 577.066 1031.78 575.835C1030.82 574.604 1030.25 573.117 1030.14 571.561C1030.02 570.005 1030.37 568.45 1031.14 567.093C1031.91 565.737 1033.07 564.639 1034.46 563.939C1035.86 563.238 1037.43 562.967 1038.98 563.159C1040 563.287 1041 563.616 1041.9 564.127C1042.8 564.639 1043.59 565.323 1044.23 566.14C1044.86 566.958 1045.33 567.893 1045.61 568.892C1045.88 569.891 1045.96 570.934 1045.83 571.963Z'
                opacity='.2' />
          <path id='Vector_447' fill='white'
                d='M1038.6 565.451C1037.96 567.76 1037.92 570.756 1033.55 570.591C1034.75 571.324 1036.12 572.002 1036.86 573.273C1037.56 574.583 1037.92 576.033 1037.92 577.504C1038.34 576.166 1038.18 574.85 1039.23 573.56C1039.61 573.082 1040.1 572.693 1040.66 572.425C1041.22 572.157 1041.84 572.016 1042.46 572.012C1041.17 571.103 1039.96 571.074 1039.32 569.371C1039.07 568.726 1038.88 568.062 1038.75 567.385C1038.63 566.702 1038.83 565.691 1038.45 565.157L1038.6 565.451Z'
                opacity='.7' />
          <path id='Vector_448' fill='white'
                d='M1078.76 532.476C1078.57 533.957 1077.92 535.35 1076.9 536.479C1075.88 537.608 1074.53 538.423 1073.03 538.819C1071.52 539.216 1069.93 539.177 1068.45 538.708C1066.97 538.239 1065.67 537.36 1064.71 536.182C1063.75 535.005 1063.18 533.582 1063.06 532.094C1062.95 530.605 1063.3 529.118 1064.07 527.82C1064.84 526.522 1066 525.472 1067.39 524.803C1068.78 524.133 1070.35 523.874 1071.9 524.058C1073.98 524.304 1075.87 525.33 1077.15 526.909C1078.44 528.487 1079.02 530.49 1078.76 532.476Z'
                opacity='.1' />
          <path id='Vector_449' fill='white'
                d='M1077.4 532.222C1077.26 533.299 1076.78 534.312 1076.03 535.134C1075.27 535.955 1074.28 536.547 1073.17 536.836C1072.05 537.124 1070.88 537.096 1069.78 536.754C1068.69 536.413 1067.72 535.774 1067.02 534.917C1066.31 534.061 1065.88 533.026 1065.8 531.944C1065.72 530.861 1065.98 529.779 1066.55 528.836C1067.11 527.892 1067.97 527.128 1069 526.641C1070.03 526.154 1071.19 525.966 1072.34 526.1C1073.1 526.189 1073.83 526.417 1074.49 526.773C1075.16 527.129 1075.75 527.605 1076.22 528.173C1076.69 528.742 1077.03 529.392 1077.24 530.087C1077.44 530.781 1077.5 531.507 1077.4 532.222Z'
                opacity='.2' />
          <path id='Vector_450' fill='white'
                d='M1072.03 527.64C1071.58 529.308 1071.56 531.472 1068.53 531.353C1069.37 531.884 1070.31 532.373 1070.83 533.291C1071.31 534.237 1071.56 535.284 1071.56 536.347C1071.85 535.381 1071.74 534.43 1072.46 533.498C1072.73 533.153 1073.07 532.872 1073.46 532.679C1073.84 532.485 1074.27 532.383 1074.7 532.38C1073.81 531.723 1072.97 531.703 1072.53 530.473C1072.35 530.007 1072.22 529.527 1072.13 529.038C1072.05 528.545 1072.19 527.815 1071.92 527.43L1072.03 527.64Z'
                opacity='.7' />
          <path id='Vector_451' fill='white'
                d='M1038.94 570.871C1038.62 569.688 1038.27 569.825 1037.98 570.332C1036.47 569.477 1036.69 575.309 1038.44 573.834C1039.03 573.334 1039.14 571.604 1038.94 570.871Z' />
        </g>;

    }
  }, [index]);

  return (
    <g className='gem-wrapper'
       ref={gemRef}
       data-tooltip-id='double-click-tip'
       onClick={onGameSelect}
       onPointerOver={(e) => showTheGameInfoTooltip({ x: e.pageX, y: e.pageY, level, title })}
       onPointerOut={()=>showTheGameInfoTooltip(null)}
    >
      {renderedCoreGem}
      {renderedMiniGem}
    </g>
  );
}

export default memo(Gems);