export const CLASSTER_TENANTS = {
  "Admissions & Records Department": "7CF39292-FAE5-4318-B84E-C3B8908D86A5",
  "Institute of Applied Sciences": "9995E42B-EB52-4E4B-B73F-FF101B342376",
  "Institute for the Creative Arts": "DA35ABD6-EB63-4CB7-95C9-F19C996ADB7D",
  "Institute of Engineering and Transport": "89E06A4F-898C-4269-8895-2E9E0BFEED27",
  "Institute of Business Management and Commerce": "85264396-66A1-4D12-A6D8-C662A2FF4B55",
  "Institute of Community Services": "3C66EE77-39DE-48A0-AF7C-48E570954406",
  "Institute of Information And Communication Technology": "9E2EF51A-9BFD-4E7C-BD39-55E07D4716F5",
  "Gozo Campus": "AD610AC5-3B21-4DD1-94EC-E6A2BBF7F97D",
  "Cross Curriculum": "AFBB38FD-D7A2-4E3B-8E47-D37936E0D56B",
  "Master Programme": "094D4E9A-5D88-4460-AFD4-2273F25E348C",
  "Partner Schools": "49192FA0-3974-420E-B41C-043DD137CE79"
}

export const CLASSTER_TENANTS_BY_UUID: Record<string, string> = {
  "7CF39292-FAE5-4318-B84E-C3B8908D86A5": "Admissions & Records Department",
  "9995E42B-EB52-4E4B-B73F-FF101B342376": "Institute of Applied Sciences",
  "DA35ABD6-EB63-4CB7-95C9-F19C996ADB7D": "Institute for the Creative Arts",
  "89E06A4F-898C-4269-8895-2E9E0BFEED27": "Institute of Engineering and Transport",
  "85264396-66A1-4D12-A6D8-C662A2FF4B55": "Institute of Business Management and Commerce",
  "3C66EE77-39DE-48A0-AF7C-48E570954406": "Institute of Community Services",
  "9E2EF51A-9BFD-4E7C-BD39-55E07D4716F5": "Institute of Information And Communication Technology",
  "AD610AC5-3B21-4DD1-94EC-E6A2BBF7F97D": "Gozo Campus",
  "AFBB38FD-D7A2-4E3B-8E47-D37936E0D56B": "Cross Curriculum",
  "094D4E9A-5D88-4460-AFD4-2273F25E348C": "Master Programme",
  "49192FA0-3974-420E-B41C-043DD137CE79": "Partner Schools"
}

export const PROGRAM_NAMES_BY_UUID: Record<string, string> = {
  "7CF39292-FAE5-4318-B84E-C3B8908D86A5": "Admissions & Records Department",
  "9995E42B-EB52-4E4B-B73F-FF101B342376": "Institute of Applied Sciences",
  "DA35ABD6-EB63-4CB7-95C9-F19C996ADB7D": "Institute for the Creative Arts",
  "89E06A4F-898C-4269-8895-2E9E0BFEED27": "Institute of Engineering and Transport",
  "85264396-66A1-4D12-A6D8-C662A2FF4B55": "Institute of Business Management and Commerce",
  "3C66EE77-39DE-48A0-AF7C-48E570954406": "Institute of Community Services",
  "9E2EF51A-9BFD-4E7C-BD39-55E07D4716F5": "Institute of Information And Communication Technology",
  "AD610AC5-3B21-4DD1-94EC-E6A2BBF7F97D": "Gozo Campus",
  "AFBB38FD-D7A2-4E3B-8E47-D37936E0D56B": "Cross Curriculum",
  "094D4E9A-5D88-4460-AFD4-2273F25E348C": "Master Programme",
  "49192FA0-3974-420E-B41C-043DD137CE79": "Partner Schools"
}

// export const CLASSTER_TENANTS_INGESTABLE = [
//   CLASSTER_TENANTS["Institute of Applied Sciences"],
//   CLASSTER_TENANTS["Institute for the Creative Arts"],
//   CLASSTER_TENANTS["Institute of Engineering and Transport"],
//   CLASSTER_TENANTS["Institute of Business Management and Commerce"],
//   CLASSTER_TENANTS["Institute of Community Services"],
//   CLASSTER_TENANTS["Institute of Information And Communication Technology"],
//   CLASSTER_TENANTS["Gozo Campus"]
// ]
