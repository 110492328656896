import { TSimulationDialogueGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the simulation
 *    dialogue game type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function simulationDialogueDbToViewConverter(
  dbData: Record<string, any>
): TSimulationDialogueGame {
  return { players: dbData.players };

}

