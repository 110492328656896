// The redux modules in order to provide the reducers
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// All Slices
import { gameReducer, worldReducer, userReducer } from './slices';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

// Initial configuration for the redux persists.
// This is creating a local storage.
const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-glms-',
  whitelist: [], // ToDo add sort and filter config here
};

// Defining the root reducers
const rootReducer = combineReducers({
  game: gameReducer,
  world: worldReducer,
  user:userReducer
  // product: persistReducer(productPersistConfig, productReducer), persistor
});

export { rootPersistConfig, rootReducer };
