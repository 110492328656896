import { memo } from 'react';

function AvatarShe(){
    return (
        <svg viewBox="0 0 247 408" fill="none" preserveAspectRatio="xMinYMin meet">
            <path d="M145.978 310.572L208.362 274.33C226.275 299.533 247.069 330.256 247 361.743L184.616 398.02C184.72 366.534 163.891 335.811 146.013 310.607L145.978 310.572Z" fill="#FCBF29"/>
            <path d="M80.3313 220.208C84.0806 218.021 88.1423 216.424 92.2387 215.105C71.4442 227.186 50.6497 239.267 29.8552 251.383C25.7588 252.702 21.6971 254.299 17.9478 256.486C36.6594 245.62 61.6198 231.109 80.3313 220.208Z" fill="#FCBF29"/>
            <path d="M75.5405 224.964C60.5088 233.713 45.4771 242.426 30.4801 251.174C30.2718 251.244 30.0635 251.313 29.8205 251.383C50.615 239.302 71.4095 227.221 92.204 215.105C92.4123 215.036 92.6205 214.966 92.8288 214.897C89.9474 216.563 87.0313 218.264 84.1152 219.965C81.2338 221.632 78.3872 223.298 75.5058 224.964H75.5405Z" fill="#FCBF29"/>
            <path d="M92.2734 215.244C94.0439 217.188 95.8838 219.098 97.7238 221.007C76.9293 233.088 56.1348 245.169 35.3403 257.25C33.4656 255.34 31.6604 253.431 29.8899 251.487C48.6015 240.621 73.5618 226.11 92.2734 215.209V215.244Z" fill="#FCBF29"/>
            <path d="M29.8899 251.522L92.2734 215.244C92.5859 215.14 92.8983 215.071 93.2107 214.932L30.8272 251.175C30.5148 251.279 30.2024 251.383 29.8899 251.487V251.522Z" fill="#FFCD5F"/>
            <path d="M91.2321 232.879C74.3257 242.704 57.3845 252.528 40.4781 262.387C38.7424 260.686 37.0414 258.985 35.3403 257.284C56.1348 245.203 76.9293 233.123 97.7238 221.042C99.3902 222.743 101.126 224.478 102.862 226.145C98.9735 228.401 95.1202 230.658 91.2321 232.914V232.879Z" fill="#FCBF29"/>
            <path d="M102.862 226.145C105.917 229.13 109.076 232.046 112.339 234.893C91.5444 246.974 70.7499 259.055 49.9554 271.136C46.7269 268.289 43.5678 265.338 40.5128 262.387C61.3073 250.307 82.1018 238.226 102.896 226.11L102.862 226.145Z" fill="#FCBF29"/>
            <path d="M109.492 260.721C95.4325 268.914 81.338 277.072 67.2783 285.265C61.3073 280.787 55.5098 276.065 49.9554 271.136C70.7499 259.055 91.5444 246.974 112.339 234.893C117.893 239.823 123.691 244.544 129.662 249.022C122.927 252.945 116.227 256.833 109.492 260.756V260.721Z" fill="#FCBF29"/>
            <path d="M157.538 267.491C179.687 280.266 201.21 288.251 218.88 290.299L156.497 326.576C138.827 324.493 117.338 316.544 95.1548 303.734C85.6081 298.214 76.235 292 67.2437 285.23C72.3122 282.28 77.3805 279.329 82.4489 276.413C90.5029 271.726 98.5569 267.074 106.576 262.388C114.248 257.909 121.955 253.466 129.627 248.988C138.618 255.757 147.992 261.971 157.504 267.491H157.538Z" fill="#FCBF29"/>
            <path d="M30.8273 251.209L93.2108 214.967C107.583 230.45 130.148 248.085 155.56 262.77C180.208 277.003 202.148 285.3 216.589 286.862L154.206 323.105C139.729 321.543 117.789 313.246 93.1762 299.012C67.7645 284.328 45.1995 266.692 30.8273 251.209Z" fill="#FFCD5F"/>
            <path d="M116.783 205.697L54.399 241.94C49.9902 244.509 44.3663 246.418 38.4647 248.467C36.5553 249.126 34.6113 249.821 32.6325 250.515C31.7299 250.827 30.8273 251.174 29.9247 251.487L92.3082 215.209C93.2108 214.862 94.1134 214.55 95.016 214.237C96.9948 213.543 98.9388 212.849 100.848 212.189C106.75 210.141 112.374 208.232 116.783 205.663V205.697Z" fill="#F2BC81"/>
            <path d="M92.8635 214.897C93.5578 214.689 94.2869 214.48 94.9812 214.272L32.5977 250.515C31.9034 250.723 31.1744 250.931 30.4801 251.14C41.0336 244.995 51.5871 238.885 62.1405 232.741C67.9727 229.339 73.8048 225.971 79.6717 222.569C80.3313 222.187 81.234 221.666 81.8936 221.285C82.5879 220.868 83.4904 220.347 84.15 219.965C84.8443 219.583 85.747 219.028 86.4413 218.646C86.7884 218.438 87.2396 218.195 87.5521 217.987C88.2117 217.605 89.0796 217.084 89.7739 216.702C90.0863 216.529 90.5029 216.251 90.8501 216.077C91.475 215.73 92.2387 215.175 92.8983 214.897H92.8635Z" fill="#FCBF29"/>
            <path d="M130.044 183.792C137.612 194.45 146.013 202.712 154.761 207.78C133.967 219.861 113.172 231.942 92.3775 244.058C83.6292 238.989 75.2281 230.727 67.6602 220.07C86.3718 209.204 111.332 194.693 130.044 183.827V183.792Z" fill="#F2BC81"/>
            <path d="M154.761 207.815C154.761 207.815 154.796 207.815 154.83 207.815C134.036 219.896 113.241 231.977 92.447 244.093C92.447 244.093 92.4123 244.093 92.3776 244.093C113.172 232.012 133.967 219.931 154.761 207.815Z" fill="#F2BC81"/>
            <path d="M184.582 397.986L247 361.708L246.965 371.602L184.582 407.88V397.986Z" fill="#FCBF29"/>
            <path d="M39.9227 150.639L102.306 114.396L107.479 120.159L45.0953 156.402L39.9227 150.639Z" fill="#FFD098"/>
            <path d="M27.0432 108.39L89.4267 72.1475C89.4267 72.1475 89.5309 72.0781 89.5656 72.0434L27.1821 108.286C27.1821 108.286 27.0779 108.356 27.0432 108.39Z" fill="#FFD098"/>
            <path d="M89.5657 72.078C92.447 84.6103 96.6822 98.7047 102.306 114.396L39.9226 150.639C34.2987 134.947 30.0635 120.853 27.1822 108.321L89.4615 72.1474C89.4615 72.1474 89.5309 72.1127 89.5657 72.078Z" fill="#FFD098"/>
            <path d="M144.312 171.225C144.312 173.69 149.033 178.446 154.865 181.813L92.4818 218.091C86.6496 214.723 81.9283 209.967 81.9283 207.503C100.64 196.637 125.6 182.126 144.312 171.26V171.225Z" fill="#FFD098"/>
            <path d="M208.292 274.191C211.069 278.357 213.812 282.488 216.589 286.723C195.795 298.804 175 310.885 154.206 322.966C151.463 318.731 148.686 314.565 145.909 310.434C145.249 309.427 144.555 308.386 143.895 307.413C164.69 295.333 185.484 283.252 206.279 271.136C206.938 272.143 207.633 273.149 208.292 274.191Z" fill="#F2BC81"/>
            <path d="M216.589 286.723C217.353 287.903 218.117 289.118 218.915 290.299L156.532 326.576C155.768 325.361 155.004 324.181 154.206 322.966C175 310.885 195.795 298.804 216.589 286.723Z" fill="#F2BC81"/>
            <path d="M154.206 323.07L216.589 286.827C217.353 287.973 218.117 289.153 218.88 290.333L156.497 326.611C155.733 325.431 154.969 324.25 154.206 323.105V323.07Z" fill="#FFCD5F"/>
            <path d="M30.8272 251.209C45.1994 266.692 67.7644 284.328 93.1761 299.012C117.824 313.246 139.764 321.543 154.206 323.105C154.969 324.25 155.733 325.431 156.497 326.611C138.827 324.528 117.338 316.578 95.1547 303.768C71.1317 289.917 48.1849 271.518 29.8899 251.522C30.2024 251.418 30.5148 251.313 30.8272 251.209Z" fill="#FFCD5F"/>
            <path d="M81.9283 207.503L144.312 171.225C147.332 172.961 149.033 170.913 151.949 172.579C153.372 173.377 154.345 174.697 154.9 175.738L92.5165 211.981C91.9958 210.939 90.989 209.655 89.5657 208.822C86.6496 207.155 84.9485 209.204 81.9283 207.468V207.503Z" fill="#C7320A"/>
            <path d="M92.69 211.877L155.074 175.634C155.074 175.634 154.935 175.738 154.9 175.773L92.5165 212.016C92.5165 212.016 92.6206 211.911 92.69 211.877Z" fill="#FFD098"/>
            <path d="M145.978 310.572C163.891 335.776 184.686 366.499 184.582 397.986V407.88L0 301.338V291.444C0.104146 263.637 16.4204 255.236 32.5978 250.55C31.6952 250.862 30.7926 251.209 29.89 251.522C48.185 271.518 71.1318 289.917 95.1548 303.768C117.303 316.543 138.827 324.528 156.497 326.611C152.991 321.091 149.45 315.78 145.978 310.572Z" fill="#FCBF29"/>
            <path d="M92.6899 211.877L155.073 175.634C155.629 175.287 156.566 175.252 157.816 175.981L95.4325 212.224C94.1827 211.495 93.2454 211.53 92.6899 211.877Z" fill="#C7320A"/>
            <path d="M154.796 207.815C163.475 212.814 171.841 214.307 179.409 212.467L117.026 248.71C117.026 248.71 117.026 248.71 116.991 248.71C137.716 236.663 158.441 224.617 179.131 212.606C158.406 224.652 137.681 236.663 116.991 248.71C109.423 250.515 101.057 249.057 92.4124 244.058C113.207 231.977 134.001 219.896 154.796 207.78V207.815Z" fill="#F2BC81"/>
            <path d="M67.6255 220.069C75.2282 230.762 83.6293 239.024 92.4123 244.093C101.091 249.092 109.458 250.55 117.025 248.744C120.011 261.797 124.35 275.302 128.482 282.8C133.029 291.167 139.278 300.54 145.909 310.468C149.45 315.78 152.991 321.091 156.497 326.611C155.733 325.431 154.969 324.25 154.206 323.105C139.729 321.542 117.789 313.245 93.1761 299.012C67.7644 284.328 45.1994 266.692 30.8272 251.209C30.5148 251.313 30.2024 251.418 29.8899 251.522C30.7925 251.174 31.6951 250.862 32.5977 250.55C34.5765 249.855 36.5205 249.161 38.4299 248.501C45.0952 246.21 51.3788 244.058 55.9612 240.968C60.127 238.226 64.5705 229.72 67.5907 220.069H67.6255Z" fill="#F2BC81"/>
            <path d="M190.865 246.523C194.961 254.021 200.412 262.353 206.244 271.136C185.449 283.217 164.655 295.298 143.86 307.413C138.028 298.631 132.543 290.299 128.482 282.8C124.385 275.302 120.011 261.797 117.026 248.744L179.409 212.502C182.395 225.555 186.734 239.059 190.83 246.557L190.865 246.523Z" fill="#F2BC81"/>
            <path d="M154.865 181.813C159.413 184.452 163.301 185.458 164.794 184.59L102.41 220.833C100.917 221.701 97.0294 220.66 92.4817 218.056C111.193 207.19 136.154 192.679 154.865 181.778V181.813Z" fill="#FFD098"/>
            <path d="M95.4672 212.224C98.3833 213.89 100.015 217.917 103.07 219.688C103.07 222.152 98.3139 221.423 92.4818 218.056C86.6149 214.689 81.9283 209.933 81.9283 207.468C84.9485 209.204 86.6496 207.155 89.5657 208.822C90.989 209.655 91.9611 210.939 92.5165 211.981C93.0372 211.53 94.0439 211.391 95.4672 212.224Z" fill="#C7320A"/>
            <path d="M95.4672 212.224L157.851 175.981C160.767 177.647 162.433 181.674 165.488 183.445L103.105 219.722C100.05 217.952 98.3833 213.925 95.5019 212.259L95.4672 212.224Z" fill="#C7320A"/>
            <path d="M164.828 184.591L102.445 220.833C102.862 220.59 103.07 220.208 103.07 219.688L165.453 183.41C165.453 183.931 165.21 184.348 164.794 184.591H164.828Z" fill="#C7320A"/>
            <path d="M126.954 110.334C126.954 112.903 128.759 115.993 130.946 117.277L68.5628 153.555C66.3758 152.271 64.5706 149.181 64.5706 146.612C85.3651 134.531 106.16 122.45 126.954 110.369V110.334Z" fill="#FFD098"/>
            <path d="M130.946 117.243C132.057 117.902 133.099 117.972 133.828 117.52L71.4442 153.763C70.7152 154.18 69.7084 154.11 68.5627 153.485C87.2743 142.62 112.235 128.109 130.946 117.208V117.243Z" fill="#FFD098"/>
            <path d="M55.1975 94.5041L117.581 58.2612C97.1684 76.5215 107.375 119.777 107.479 120.159L45.0953 156.401C44.9912 156.02 34.7849 112.73 55.1975 94.4693V94.5041Z" fill="#F56B2C"/>
            <path d="M128.134 107.765C127.405 108.182 126.954 109.085 126.954 110.334C106.16 122.415 85.3651 134.496 64.5706 146.577C85.2957 134.531 106.021 122.485 126.711 110.439C105.986 122.485 85.2609 134.531 64.5706 146.542C64.5706 145.293 65.0219 144.425 65.7509 144.008C84.4625 133.142 109.423 118.631 128.134 107.765Z" fill="#FFD098"/>
            <path d="M65.7162 144.043L128.1 107.8C128.829 107.384 129.835 107.418 130.946 108.078L68.5628 144.321C67.4519 143.696 66.4452 143.626 65.7162 144.043Z" fill="#494949"/>
            <path d="M68.5976 144.286C70.8194 145.57 72.5898 148.695 72.5898 151.229C72.5898 153.798 70.7846 154.805 68.5628 153.52C66.3758 152.236 64.5706 149.146 64.5706 146.577C64.5706 144.043 66.4105 143.001 68.5976 144.286Z" fill="#494949"/>
            <path d="M133.793 117.555L71.4095 153.833C72.1385 153.416 72.5551 152.513 72.5898 151.264C72.5898 148.73 70.8193 145.64 68.5975 144.321L130.981 108.078C133.203 109.362 134.973 112.487 134.973 115.021C134.973 116.305 134.522 117.173 133.793 117.59V117.555Z" fill="#494949"/>
            <path d="M122.302 69.2661C122.302 69.2661 122.406 69.4744 122.441 69.5785C101.647 81.6595 80.852 93.7404 60.0575 105.856C59.884 105.509 59.7103 105.162 59.5715 104.849C70.4721 98.5311 91.3823 86.6816 122.302 69.3007V69.2661Z" fill="#FFD098"/>
            <path d="M117.581 58.2612C118.657 61.1426 120.185 64.9265 122.302 69.3007C91.3825 86.6815 70.4722 98.5311 59.5716 104.849C57.6275 100.788 56.2043 97.2813 55.1975 94.5388C73.9091 83.6729 98.8695 69.1619 117.581 58.296V58.2612Z" fill="#FFD098"/>
            <path d="M99.9109 152.132L162.294 115.854L176.285 125.262L113.901 161.505L99.9109 152.132Z" fill="#FFD098"/>
            <path d="M101.369 146.994L163.787 110.751L176.285 125.262L113.901 161.505L101.369 146.994Z" fill="#F56B2C"/>
            <path d="M122.441 69.5785C129.072 83.0828 141.257 101.829 162.294 115.854L99.9108 152.097C78.908 138.037 66.6882 119.291 60.0576 105.821C80.8521 93.7404 101.647 81.6595 122.441 69.5438V69.5785Z" fill="#FFD098"/>
            <path d="M179.062 145.05C180.173 145.709 181.18 145.779 181.909 145.362L119.525 181.605C118.796 182.022 117.789 181.952 116.678 181.293C135.39 170.427 160.35 155.916 179.062 145.015V145.05Z" fill="#FFD098"/>
            <path d="M175.07 138.107C175.07 140.676 176.875 143.765 179.062 145.015L116.678 181.292C114.491 180.008 112.686 176.918 112.686 174.384C133.411 162.303 154.136 150.257 174.827 138.245C154.101 150.292 133.376 162.338 112.686 174.349C112.686 173.1 113.137 172.232 113.866 171.815L176.25 135.572C175.521 135.989 175.07 136.892 175.07 138.141V138.107Z" fill="#FFD098"/>
            <path d="M97.0988 141.231L159.482 104.988C161.843 108.494 163.718 110.681 163.787 110.751L101.404 146.994C101.404 146.994 99.4942 144.737 97.0988 141.231Z" fill="#F56B2C"/>
            <path d="M113.866 171.815L176.25 135.572C176.979 135.156 177.986 135.191 179.096 135.85L116.713 172.128C115.602 171.503 114.595 171.433 113.866 171.85V171.815Z" fill="#494949"/>
            <path d="M186.977 209.412L124.593 245.655C132.89 240.829 138.202 215.14 143.097 201.705L207.112 181.813C202.252 195.248 195.274 204.587 186.977 209.412Z" fill="#FFD098"/>
            <path d="M116.713 172.093C118.935 173.377 120.705 176.502 120.705 179.036C120.705 181.605 118.9 182.612 116.678 181.327C114.491 180.043 112.651 176.953 112.686 174.419C112.686 171.885 114.491 170.843 116.713 172.128V172.093Z" fill="#494949"/>
            <path d="M181.943 145.327L119.56 181.57C120.289 181.154 120.705 180.251 120.74 178.967C120.74 176.432 118.969 173.343 116.748 172.024L179.131 135.746C181.353 137.031 183.158 140.155 183.123 142.689C183.123 143.974 182.672 144.876 181.943 145.293V145.327Z" fill="#494949"/>
            <path d="M146.325 198.234L208.709 161.991L211.625 165.462L149.241 201.705L146.325 198.234Z" fill="#FFD098"/>
            <path d="M97.0988 141.231L159.482 104.988C167.536 113.702 179.756 124.88 196.663 135.676L134.279 171.919C117.338 161.158 105.153 149.979 97.0988 141.231Z" fill="#FFD098"/>
            <path d="M196.663 135.642C198.433 141.335 203.154 155.291 208.709 161.991L146.325 198.234C140.771 191.568 136.05 177.613 134.279 171.885C152.991 161.019 177.951 146.508 196.663 135.642Z" fill="#FFD098"/>
            <path d="M150.248 198.72L212.631 162.442L207.112 181.813L149.241 201.705L150.248 198.72Z" fill="#F56B2C"/>
            <path d="M91.6138 49.0271L153.997 12.7842C179.131 27.4688 196.94 46.4582 207.528 69.5786C220.338 97.5939 219.679 125.262 217.04 143.036C215.374 154.388 212.875 161.713 212.632 162.442L150.248 198.685C150.491 197.956 152.991 190.631 154.657 179.279C157.295 161.505 157.955 133.837 145.145 105.821C134.557 82.701 116.748 63.6769 91.6138 49.0271Z" fill="#F56B2C"/>
            <path d="M154.657 179.279C160.628 185.354 163.961 196.567 162.329 205.628C160.906 213.543 151.428 218.993 144.694 218.091C138.41 235.448 128.62 245.967 117.025 248.744C109.458 250.584 101.091 249.126 92.4123 244.093C83.664 239.024 75.2281 230.762 67.6255 220.069C56.1694 203.927 46.5533 182.299 40.4087 157.86C33.6739 151.021 24.266 134.635 22.9121 125.123C21.6971 116.618 23.4676 110.3 27.1821 108.321C30.0635 120.853 34.2987 134.947 39.9226 150.639L45.0953 156.402C44.9911 156.02 34.7501 112.764 55.1974 94.5041C59.5369 106.377 71.9303 133.385 99.9108 152.132L113.936 161.539L101.404 147.028C101.404 147.028 99.4941 144.772 97.0988 141.266C105.187 149.979 117.373 161.158 134.279 171.954C136.049 177.647 140.771 191.603 146.325 198.303L149.241 201.775L150.283 198.754C150.526 198.025 153.025 190.7 154.692 179.348L154.657 179.279ZM92.4817 218.091C98.3139 221.458 103.07 222.187 103.07 219.722C100.015 217.952 98.3485 213.96 95.4672 212.259C94.0438 211.46 93.0719 211.599 92.5164 212.016C91.9957 210.974 90.9889 209.655 89.5656 208.856C86.6495 207.19 84.9484 209.238 81.9282 207.503C81.9282 209.967 86.6495 214.723 92.4817 218.091ZM68.5628 153.52C70.7845 154.805 72.5898 153.798 72.5898 151.229C72.5898 148.695 70.8193 145.605 68.5975 144.286C66.4104 143.001 64.5705 144.043 64.5705 146.577C64.5705 149.146 66.3757 152.236 68.5628 153.52ZM116.678 181.292C118.9 182.577 120.705 181.57 120.705 179.001C120.705 176.467 118.935 173.377 116.713 172.093C114.526 170.808 112.686 171.85 112.686 174.384C112.686 176.953 114.491 180.043 116.678 181.292Z" fill="#FFD098"/>
            <path d="M36.4511 39.7928L98.8346 3.54994C117.754 -7.45483 149.97 10.4236 153.997 12.7842L91.6139 49.0271C87.5522 46.7011 55.371 28.8227 36.4511 39.7928Z" fill="#F56B2C"/>
            <path d="M91.6137 49.027C116.748 63.7116 134.557 82.7009 145.145 105.821C157.955 133.837 157.295 161.505 154.657 179.279C152.991 190.631 150.491 197.956 150.248 198.685L149.207 201.705L146.29 198.234C140.736 191.534 136.015 177.613 134.244 171.885C117.338 161.123 105.118 149.945 97.0641 141.196C99.4247 144.703 101.299 146.89 101.369 146.959L113.901 161.47L99.876 152.097C71.8954 133.385 59.5368 106.377 55.1626 94.4694C34.75 112.73 44.9563 155.985 45.0604 156.402L39.8879 150.639C34.264 134.947 30.0287 120.853 27.1473 108.321C20.1695 78.0491 21.1068 57.0463 29.751 45.7291C46.4143 23.8584 86.9966 46.3886 91.579 49.027H91.6137Z" fill="#F56B2C"/>
        </svg>

    )
}

export default memo(AvatarShe)
