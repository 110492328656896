import { memo } from 'react';

function TrueOrFalseIcon() {
    return (
        <svg  viewBox="0 0 86 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_105_27)">
                <path d="M29.1599 37.1088H7.8115C3.49909 37.1018 0.0068925 33.5946 0 29.2687V7.84004C0.0068925 3.51188 3.49909 0.00691768 7.8115 0H29.1599C33.4723 0.00691768 36.9645 3.51188 36.9714 7.84004V29.2664C36.9668 33.5946 33.4723 37.1041 29.1599 37.1088ZM7.8115 3.22595C5.27506 3.23056 3.22109 5.29203 3.2165 7.83774V29.2664C3.22109 31.8121 5.27506 33.8736 7.8115 33.8782H29.1599C31.6963 33.8736 33.7503 31.8121 33.7549 29.2664V7.84004C33.7503 5.29433 31.6963 3.23286 29.1599 3.22825H7.8115V3.22595Z" fill="black"/>
                <path d="M17.0428 23.9421C16.6155 23.9421 16.2065 23.7715 15.9056 23.4694L10.9774 18.5233C10.3387 17.903 10.3204 16.8815 10.9384 16.2404C11.5564 15.5994 12.5742 15.5809 13.2129 16.2012C13.2267 16.2151 13.2405 16.2266 13.252 16.2404L17.0428 20.0451L24.8107 12.2466C25.4494 11.6263 26.4672 11.6448 27.0852 12.2858C27.6872 12.9107 27.6872 13.9022 27.0852 14.5294L18.1801 23.4671C17.8791 23.7715 17.4702 23.9398 17.0428 23.9421Z" fill="black"/>
                <path d="M78.1825 37H56.8175C52.5018 36.9931 49.0069 33.4984 49 29.1829V7.81706C49.0069 3.50158 52.5018 0.00689741 56.8175 0H78.1825C82.4982 0.00689741 85.9931 3.50158 86 7.81706V29.1806C85.9954 33.4961 82.4982 36.9954 78.1825 37ZM56.8198 3.21649C54.2814 3.22109 52.2259 5.27652 52.2213 7.81476V29.1806C52.2259 31.7189 54.2814 33.7743 56.8198 33.7789H78.1847C80.7232 33.7743 82.7787 31.7189 82.7833 29.1806V7.81706C82.7787 5.27882 80.7232 3.22339 78.1847 3.21879H56.8198V3.21649Z" fill="black"/>
                <path d="M33.7425 73C33.3239 73 32.9233 72.8268 32.6284 72.5201L26.5536 66.1995L22.0251 70.9095C21.4106 71.5486 20.4113 71.5486 19.7968 70.9095C19.5448 70.6473 19.3872 70.3055 19.3467 69.938L17.0104 48.8248C16.9114 47.9259 17.5303 47.1135 18.3946 47.0105C18.5139 46.9965 18.6355 46.9965 18.757 47.0105L39.0566 49.4381C39.9209 49.5411 40.5421 50.3534 40.4408 51.2547C40.4003 51.6223 40.2405 51.964 39.9906 52.2262L35.4644 56.9339L41.5392 63.2522C42.1536 63.8913 42.1536 64.9307 41.5392 65.5698L34.8589 72.5201C34.5641 72.8268 34.1612 73 33.7448 73H33.7425ZM26.5536 62.2456C26.9723 62.2456 27.3729 62.4188 27.6677 62.7255L33.7425 69.0461L38.1968 64.4133L32.122 58.095C31.5075 57.456 31.5053 56.4189 32.122 55.7798L35.4531 52.3152L20.3685 50.5056L22.1039 66.1971L25.435 62.7325C25.7298 62.4235 26.1327 62.2479 26.5536 62.2479V62.2456Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_105_27">
                    <rect width="86" height="73" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default memo(TrueOrFalseIcon);
