import { TCrosswordGame } from './types';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The method is converting the server side object into the crossword game
 *    type.
 * @param { Record<string, any> } dbData
 *    The server side data in json format.
 */
export function crosswordDbToViewConverter(
  dbData: Record<string, any>
): TCrosswordGame {
  return {
    across: dbData.across ?? null,
    down: dbData.down ?? null,
    grid: dbData.grid ?? { row: 8, column: 8 }
  }
}

