import { memo } from 'react';

function Coin(){
  return (
    <svg  viewBox="0 0 43 46" fill="none" xmlns="http://www.w3.org/2000/svg" className="coin-svg">
      <path d="M18.0296 0.97403L4.36894 9.03673L0.600006 15.4836V30.514L4.36894 36.9609L18.0296 45.026H25.5714L39.2311 37.3023L43 30.8544V15.3284L39.2311 8.88058L25.5714 0.97403H18.0296Z" fill="#E5A535"/>
      <path d="M35.2909 31.1228L21.7995 38.9688L8.30911 31.1228V15.4274L21.7995 7.58182L35.2909 15.4274V31.1228Z" fill="#FFDE52"/>
      <path d="M22.0733 7.58182L18.2208 0.97403H25.9299L22.0733 7.58182Z" fill="#FFDE52"/>
      <path d="M8.3091 15.2909H0.600006L4.45254 9.23376L8.3091 15.2909Z" fill="#FFDE52"/>
      <path d="M8.3091 30.7091L4.45455 37.3169L0.600006 30.7091H8.3091Z" fill="#FFDE52"/>
      <path d="M22.0773 38.9688L25.9299 45.026H18.2208L22.0773 38.9688Z" fill="#FFBC45"/>
      <path d="M35.2909 31.2597H43L39.1475 37.3169L35.2909 31.2597Z" fill="#FFBC45"/>
      <path opacity="0.5" d="M35.2909 31.2597H43L39.1475 37.3169L35.2909 31.2597Z" fill="#C97231"/>
      <path d="M35.2909 15.2909L39.1456 8.68314L43 15.2909H35.2909Z" fill="#FFBC45"/>
      <path d="M4.45456 9.23376L8.1959 15.2909L8.85975 14.6758L4.45456 9.23376Z" fill="white"/>
      <path d="M0.600006 30.5481L8.3091 30.7091V30.1584L0.600006 30.5481Z" fill="white"/>
      <path opacity="0.5" d="M8.30911 31.0092L22.626 23.5507L22.0289 38.9688L8.30911 31.0092Z" fill="#C97231"/>
      <path d="M9.51933 31.129V15.4314L22.626 7.92769L22.0205 7.58182L8.30911 15.4284V31.1222L22.0205 38.9688L22.626 38.623L9.51933 31.129Z" fill="white"/>
      <path opacity="0.5" d="M29.6972 34.013L22.626 23.5507L35.2909 30.7892L29.6972 34.013Z" fill="#C97231"/>
      <path opacity="0.5" d="M35.2909 15.344L24.3154 17.4935L22.626 7.58182L35.2909 15.344Z" fill="#C97231"/>
      <path opacity="0.4" d="M8.30911 23.0764L29.4802 11.987L35.2909 15.4459L11.7324 32.9117L8.30911 30.8223V23.0764Z" fill="white"/>
      <path opacity="0.4" d="M17.6701 36.4692L35.2909 18.0442V31.04L21.9081 38.9688L17.6701 36.4692Z" fill="white"/>
      <path d="M0.600006 25.7532V15.2909H8.3091C8.3091 15.2909 6.51146 15.3116 3.88214 18.8721C2.33594 20.9176 1.21913 23.2586 0.600006 25.7532Z" fill="#FFDE52"/>
      <path opacity="0.5" d="M40.7974 31.2597C40.7974 31.2597 38.8859 30.2386 38.2204 26.1996C37.5548 22.1606 40.132 15.2909 40.132 15.2909H35.2909V31.2597H40.7974Z" fill="#C97231"/>
      <path opacity="0.5" d="M25.8347 45.026L22.0753 38.764L35.7012 31.2597L38.0442 35.1635C38.0442 35.1635 36.5207 33.2339 32.628 35.0872C26.5006 38.0056 25.8347 45.026 25.8347 45.026Z" fill="#C97231"/>
      <path opacity="0.5" d="M24.9596 2.62598C24.9596 2.62598 24.6246 3.61868 26.2085 6.52691C27.7925 9.43514 33.0883 14.1896 33.0883 14.1896L22.0753 7.68381L24.9596 2.62598Z" fill="#C97231"/>
      <path d="M18.2208 0.97403L21.4912 8.68312L22.626 8.01773L18.2208 0.97403Z" fill="white"/>
      <path opacity="0.5" d="M35.4767 31.2597H36.9429L35.4767 15.2909C35.4767 15.2909 35.0588 31.2597 35.4767 31.2597Z" fill="#C97231"/>
      <path opacity="0.5" d="M22.0753 38.9688L36.3922 32.3498L35.7529 31.2597L22.0753 38.9688Z" fill="#C97231"/>
      <path opacity="0.1" d="M38.5948 13.0883C41.0277 13.0883 43 11.116 43 8.68312C43 6.2502 41.0277 4.27792 38.5948 4.27792C36.1619 4.27792 34.1896 6.2502 34.1896 8.68312C34.1896 11.116 36.1619 13.0883 38.5948 13.0883Z" fill="white"/>
      <path opacity="0.2" d="M42.4241 9.0904C42.3435 9.73886 42.0725 10.3489 41.6452 10.8433C41.2179 11.3377 40.6535 11.6945 40.0235 11.8682C39.3936 12.0418 38.7262 12.0249 38.1059 11.8193C37.4857 11.6137 36.9404 11.2286 36.5389 10.713C36.1374 10.1975 35.8977 9.57447 35.8503 8.92274C35.8029 8.27102 35.95 7.61982 36.2727 7.05161C36.5954 6.4834 37.0793 6.0235 37.6633 5.73034C38.2473 5.43718 38.905 5.3238 39.5535 5.40451C40.4229 5.51272 41.2139 5.9617 41.7523 6.65293C42.2906 7.34417 42.5322 8.22097 42.4241 9.0904Z" fill="white"/>
      <path opacity="0.7" d="M39.3653 6.60136C39.1275 7.52739 39.1137 8.72775 37.4935 8.66299C37.9826 8.90827 38.4052 9.27795 38.7229 9.73864C38.9789 10.2643 39.1131 10.8461 39.1147 11.4364C39.1655 10.8784 39.3309 10.3384 39.5995 9.85381C39.7414 9.66194 39.9228 9.50632 40.1303 9.39881C40.3379 9.29131 40.566 9.23465 40.7974 9.23315C40.321 8.86873 39.8685 8.85704 39.6344 8.16976C39.5411 7.91148 39.4702 7.64516 39.4225 7.37353C39.3773 7.09997 39.453 6.69705 39.3111 6.48053L39.3653 6.60136Z" fill="white"/>
      <path d="M39.6392 8.92761C39.4683 8.6106 39.2835 8.64763 39.1292 8.78261C38.327 8.55121 38.4429 10.1145 39.3764 9.72039C39.522 9.61061 39.6222 9.48138 39.6679 9.34339C39.7136 9.20541 39.7036 9.06284 39.6392 8.92761Z" fill="white"/>
      <path d="M4.45456 8.98418L8.7589 14.7403L20.974 7.72127L18.0276 0.97403L4.45456 8.98418Z" fill="#FFDE52"/>
      <path opacity="0.4" d="M8.23055 14.1896C8.45769 12.6226 9.18232 11.1687 10.2988 10.0399C12.2664 7.86338 18.7714 2.42754 18.7714 2.42754L18.1408 0.97403L4.45456 9.10527L8.23055 14.1896Z" fill="white"/>
      <path opacity="0.5" d="M8.30911 24.394L32.2203 13.6389L34.1896 14.8134L9.03585 29.6078L8.30911 24.394Z" fill="white"/>
      <path opacity="0.1" d="M13.2205 30.9055C13.0794 32.0944 12.6051 33.213 11.8573 34.1194C11.1095 35.0257 10.1218 35.6796 9.01941 35.9979C7.91697 36.3163 6.74911 36.2848 5.66364 35.9078C4.57818 35.5308 3.62384 34.8251 2.92123 33.8799C2.21861 32.9346 1.79945 31.7925 1.71652 30.5977C1.6336 29.4028 1.89058 28.2089 2.4553 27.1671C3.02002 26.1254 3.8671 25.2822 4.88911 24.7447C5.91111 24.2072 7.06222 23.9998 8.19702 24.1477C8.95053 24.2457 9.67834 24.4979 10.3387 24.8906C10.999 25.2833 11.5788 25.8089 12.0453 26.4365C12.5118 27.0641 12.8557 27.7816 13.0574 28.5485C13.259 29.3154 13.3145 30.1162 13.2205 30.9055Z" fill="white"/>
      <path opacity="0.2" d="M12.1321 30.7021C12.0313 31.5667 11.6923 32.38 11.1582 33.0392C10.6241 33.6984 9.91884 34.1735 9.13141 34.405C8.34399 34.6366 7.50981 34.6143 6.73448 34.3402C5.95915 34.0661 5.27763 33.5525 4.77573 32.8651C4.27384 32.1777 3.97408 31.3471 3.91479 30.4781C3.8555 29.6091 4.03924 28.7411 4.44253 27.9834C4.84583 27.2257 5.4506 26.6124 6.18055 26.2215C6.9105 25.8305 7.73279 25.6795 8.54335 25.787C9.63025 25.9311 10.6189 26.5299 11.292 27.4516C11.965 28.3734 12.2672 29.5427 12.1321 30.7021Z" fill="white"/>
      <path opacity="0.7" d="M8.36606 27.0254C8.00726 28.3632 7.9876 30.1004 5.55585 30.0051C6.29027 30.3595 6.92496 30.8943 7.40173 31.5607C7.78594 32.32 7.98697 33.1602 7.98858 34.013C8.06408 33.207 8.31169 32.4274 8.71442 31.7274C8.927 31.45 9.19931 31.2246 9.51065 31.0691C9.82199 30.9136 10.1644 30.8314 10.5117 30.8292C9.79617 30.3054 9.11786 30.2855 8.76632 29.2987C8.62702 28.9247 8.52061 28.539 8.44809 28.1462C8.38068 27.7502 8.49358 27.1636 8.28099 26.8546L8.36606 27.0254Z" fill="white"/>
      <path d="M8.805 30.0975C8.63441 29.4632 8.44949 29.5361 8.29034 29.807C7.49171 29.344 7.60703 32.4689 8.53813 31.6829C8.68373 31.4637 8.78393 31.2054 8.83036 30.9295C8.87679 30.6536 8.86812 30.3685 8.805 30.0975Z" fill="white"/>
    </svg>

  )
}

export function Coin50() {
    return (
      <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="coin-svg">
          <path
            d="M9.85765 0.347672L2.26558 4.88765L0.167267 8.512V16.972L2.26558 20.5964L9.85765 25.1268H14.0447L21.6273 20.7871L23.7256 17.1628V8.42616L21.6273 4.80181L14.0447 0.347672H9.85765Z"
            fill="#E5A636" />
          <path d="M19.5385 17.1246L11.9464 21.5025L4.35435 17.1246V8.3594L11.9464 3.98156L19.5385 8.3594V17.1246Z"
                fill="#FFDE53" />
          <path d="M11.9464 3.98156L9.85765 0.347672H14.0447L11.9464 3.98156Z" fill="#FFDE53" />
          <path d="M4.35435 8.512H0.167267L2.26558 4.88765L4.35435 8.512Z" fill="#FFDE53" />
          <path d="M4.35435 16.972L2.26558 20.5964L0.167267 16.972H4.35435Z" fill="#FFDE53" />
          <path d="M11.9464 21.5025L14.0447 25.1268H9.85765L11.9464 21.5025Z" fill="#FBBB48" />
          <path d="M19.5385 17.1628H23.7256L21.6273 20.7871L19.5385 17.1628Z" fill="#FBBB48" />
          <path opacity="0.5" d="M19.5385 17.1628H23.7256L21.6273 20.7871L19.5385 17.1628Z" fill="#C97230" />
          <path d="M19.5385 8.42616L21.6273 4.80181L23.7256 8.42616H19.5385Z" fill="#FBBB48" />
          <path d="M2.26558 4.88765L4.35435 8.512L4.72632 8.14957L2.26558 4.88765Z" fill="white" />
          <path d="M0.167267 16.972L4.35435 17.1246V16.5905L0.167267 16.972Z" fill="white" />
          <path opacity="0.5" d="M4.24944 17.1628L12.2802 13.0901L11.9464 21.5025L4.24944 17.1628Z" fill="#C97230" />
          <path
            d="M5.03154 17.1246V8.3594L12.2802 4.17232L11.9464 3.98156L4.35435 8.3594V17.1246L11.9464 21.5025L12.2802 21.3117L5.03154 17.1246Z"
            fill="white" />
          <path opacity="0.5" d="M16.3338 18.9749L12.2802 13.0901L19.5385 17.1628L16.3338 18.9749Z" fill="#C97230" />
          <path opacity="0.5" d="M19.5385 8.42616L13.2531 9.60885L12.2802 4.17232L19.5385 8.42616Z" fill="#C97230" />
          <path opacity="0.4"
                d="M4.35435 12.7372L16.267 6.47092L19.5385 8.42616L6.28098 18.3073L4.35435 17.1246V12.7372Z"
                fill="white" />
          <path opacity="0.4" d="M9.54291 20.1386L19.5385 10.0381V17.1628L11.9464 21.5025L9.54291 20.1386Z"
                fill="white" />
          <path
            d="M0.167267 14.1584V8.512H4.35435C4.35435 8.512 3.3815 8.52154 1.95083 10.4386C0.520164 12.3557 0.167267 14.1488 0.167267 14.1488V14.1584Z"
            fill="#FFDE53" />
          <path opacity="0.5"
                d="M22.4952 17.1628C22.4952 17.1628 21.4651 16.6 21.1122 14.3968C20.7593 12.1936 22.1423 8.42616 22.1423 8.42616H19.5385V17.1628H22.4952Z"
                fill="#C97230" />
          <path opacity="0.5"
                d="M14.0447 25.1268L11.9464 21.5025L19.5385 17.1532L20.8452 19.4137C20.8452 19.4137 19.9963 18.2978 17.8312 19.366C14.4167 21.0542 14.0447 25.1173 14.0447 25.1173V25.1268Z"
                fill="#C97230" />
          <path opacity="0.5"
                d="M13.5774 1.15838C13.5774 1.15838 13.3866 1.71157 14.2832 3.33299C15.1797 4.95442 18.1651 7.59638 18.1651 7.59638L11.956 3.97203L13.5774 1.15838Z"
                fill="#C97230" />
          <path d="M9.85765 0.347672L11.6603 4.53475L12.2802 4.17232L9.85765 0.347672Z" fill="white" />
          <path opacity="0.5"
                d="M19.5385 17.1628H20.3492L19.5385 8.42616C19.5385 8.42616 19.3096 17.1628 19.5385 17.1628Z"
                fill="#C97230" />
          <path opacity="0.5" d="M11.9464 21.5025L19.8914 17.7732L19.5385 17.1628L11.9464 21.5025Z" fill="#C97230" />
          <path opacity="0.1"
                d="M21.2744 7.19579C22.6281 7.19579 23.7256 6.09835 23.7256 4.74458C23.7256 3.39082 22.6281 2.29338 21.2744 2.29338C19.9206 2.29338 18.8232 3.39082 18.8232 4.74458C18.8232 6.09835 19.9206 7.19579 21.2744 7.19579Z"
                fill="white" />
          <path opacity="0.2"
                d="M23.2868 4.90673C23.1628 5.89866 22.2663 6.59491 21.2743 6.48046C20.2824 6.35647 19.5862 5.45992 19.7006 4.46799C19.8246 3.47606 20.7212 2.7798 21.7131 2.89426C22.705 3.01825 23.4013 3.92434 23.2868 4.90673Z"
                fill="white" />
          <path opacity="0.7"
                d="M21.6463 3.44745C21.5033 3.98156 21.4937 4.67782 20.5114 4.63967C20.7784 4.81135 21.0932 4.96395 21.2553 5.25962C21.4079 5.51714 21.5033 5.94635 21.4937 6.24202C21.5891 5.92727 21.551 5.6316 21.7894 5.32639C21.9611 5.10702 22.2377 4.96395 22.5143 4.96395C22.2282 4.75412 21.9516 4.74459 21.8085 4.35354C21.7513 4.20093 21.7036 4.04833 21.6845 3.89572C21.6559 3.73358 21.7036 3.50467 21.6177 3.38068L21.6559 3.44745H21.6463Z"
                fill="white" />
          <path
            d="M21.8657 4.75412C21.7894 4.52522 21.7131 4.55383 21.6368 4.64921C21.2839 4.48707 21.3316 5.61252 21.7417 5.32639C21.8848 5.23101 21.9038 4.89719 21.8562 4.75412H21.8657Z"
            fill="white" />
          <path d="M2.26558 4.88765L4.6691 8.14957L11.4981 4.17232L9.85765 0.347672L2.26558 4.88765Z" fill="#FFDE53" />
          <path opacity="0.4"
                d="M4.35435 7.72037C4.35435 7.72037 4.41158 6.62353 5.49889 5.40269C6.58619 4.19139 10.201 1.15838 10.201 1.15838L9.84811 0.347672L2.25604 4.88765L4.35435 7.72037Z"
                fill="white" />
          <path opacity="0.5" d="M4.25897 13.4716L17.8312 7.40562L18.9471 8.07327L4.6691 16.4093L4.25897 13.4716Z"
                fill="white" />
          <path opacity="0.1"
                d="M7.10123 17.1151C6.88186 18.9082 5.2509 20.1767 3.4578 19.9478C1.6647 19.7284 0.396178 18.0975 0.625084 16.3044C0.844453 14.5113 2.47541 13.2427 4.26851 13.4716C6.06161 13.691 7.33014 15.322 7.10123 17.1151Z"
                fill="white" />
          <path opacity="0.2"
                d="M6.5385 16.9148C6.37636 18.231 5.1746 19.1752 3.84885 19.0036C2.53264 18.8414 1.5884 17.6397 1.76008 16.3139C1.92222 14.9977 3.12398 14.0534 4.44973 14.2251C5.76594 14.3873 6.71018 15.589 6.5385 16.9148Z"
                fill="white" />
          <path opacity="0.7"
                d="M4.34481 14.9786C4.15406 15.6939 4.14452 16.6191 2.82831 16.5714C3.19074 16.8003 3.60087 17.0102 3.82024 17.4012C4.02053 17.7541 4.14452 18.3168 4.13498 18.7079C4.25897 18.2978 4.21128 17.8876 4.52603 17.4871C4.76448 17.1914 5.11737 17.0006 5.49888 17.0102C5.11737 16.7336 4.7454 16.724 4.55465 16.1899C4.47834 15.9896 4.42112 15.7798 4.38297 15.5795C4.34481 15.3697 4.41158 15.0549 4.29713 14.8928L4.34481 14.9786Z"
                fill="white" />
          <path
            d="M4.64048 16.7145C4.53557 16.4093 4.43065 16.4474 4.33527 16.5714C3.86792 16.3521 3.93469 17.859 4.47834 17.4775C4.65956 17.344 4.69771 16.9052 4.63094 16.7145H4.64048Z"
            fill="white" />
          <path
            d="M11.9083 14.0248C11.9083 15.2457 11.0117 16.3139 9.03739 16.3139C8.08362 16.3139 7.07261 16.0468 6.39543 15.57L7.02492 14.3491C7.5495 14.7402 8.27437 14.9691 8.98971 14.9691C9.79088 14.9691 10.2868 14.6353 10.2868 14.0725C10.2868 13.5098 9.93394 13.176 8.67496 13.176H6.91047L7.26337 9.28456H11.4791V10.5722H8.59866L8.49374 11.8884H9.05647C11.0976 11.8884 11.9083 12.804 11.9083 14.0344V14.0248Z"
            fill="white" />
          <path
            d="M12.0609 12.7372C12.0609 10.4577 13.3294 9.16057 15.0271 9.16057C16.7249 9.16057 17.9934 10.4577 17.9934 12.7372C17.9934 15.0168 16.7344 16.3139 15.0271 16.3139C13.3199 16.3139 12.0609 15.0168 12.0609 12.7372ZM16.3815 12.7372C16.3815 11.1635 15.8283 10.5149 15.0271 10.5149C14.226 10.5149 13.6823 11.1635 13.6823 12.7372C13.6823 14.311 14.2355 14.9595 15.0271 14.9595C15.8188 14.9595 16.3815 14.311 16.3815 12.7372Z"
            fill="white" />
          <path
            d="M11.6984 13.7292C11.6984 14.95 10.8019 16.0182 8.82757 16.0182C7.87379 16.0182 6.86279 15.7512 6.18561 15.2743L6.8151 14.0535C7.33968 14.4445 8.06455 14.6734 8.77988 14.6734C9.58106 14.6734 10.077 14.3396 10.077 13.7769C10.077 13.2141 9.72412 12.8803 8.46514 12.8803H6.70065L7.05355 8.98889H11.2692V10.2765H8.38884L8.28392 11.5927H8.84665C10.8877 11.5927 11.6984 12.5083 11.6984 13.7387V13.7292Z"
            fill="#E5A636" />
          <path
            d="M11.851 12.4511C11.851 10.1716 13.1196 8.87444 14.8173 8.87444C16.515 8.87444 17.7835 10.1716 17.7835 12.4511C17.7835 14.7306 16.5246 16.0278 14.8173 16.0278C13.11 16.0278 11.851 14.7306 11.851 12.4511ZM16.1717 12.4511C16.1717 10.8774 15.6185 10.2288 14.8173 10.2288C14.0161 10.2288 13.4725 10.8774 13.4725 12.4511C13.4725 14.0248 14.0257 14.6734 14.8173 14.6734C15.6089 14.6734 16.1717 14.0248 16.1717 12.4511Z"
            fill="#E5A636" />
      </svg>

    )
}

export default memo(Coin)

