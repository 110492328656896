import { Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ButtonMotion } from 'src/components';
import { useGameEditMechanic } from 'src/hooks';
import { TGameMechanicContent } from 'src/@types';
import { THotspotGame } from './types';
import 'src/assets/styles/editors/battleships.scss';
import GameNotFound from '../../../not-found';
import TinyMceEditor from '../../../../components/tiny-mce-editor';
import usePrevious from 'src/hooks/usePrevious';

export default function BattleshipsEditor() {
  const {
    gameMechanic,
    updateGameMechanic
  } = useGameEditMechanic();

  if (gameMechanic) {
    const { boats, questions } = gameMechanic.content as THotspotGame;
    const [
      selectedCells,
      setSelectedCells
    ] = useState<number[]>(Object.values(boats).flat() ?? []);
    const [currentCell, setCurrentCell] = useState<number | null>(null);
    const [currentQuestion, setCurrentQuestion] = useState<string | null>(null);
    const previousCell = usePrevious(currentCell);

    const onGameMechanicContentChange = (finalContent: THotspotGame) => {
      updateGameMechanic({
        ...gameMechanic,
        content: finalContent as unknown as TGameMechanicContent
      });
    };


    const createBoats = (numbers: number[]): Record<string, number[]> => {
      const grid: boolean[][] = Array(10).fill(null).map(() => Array(10).fill(false));
      const visited: boolean[][] = Array(10).fill(null).map(() => Array(10).fill(false));
      let groups: Record<string, number[]> = {};
      let groupIndex: number = 0;

      // Mark the numbers in the grid
      numbers.forEach(num => {
        const row: number = Math.floor((num - 1) / 10);
        const col: number = (num - 1) % 10;
        grid[row][col] = true;
      });

      function dfs(row: number, col: number, group: number[]): void {
        // Directions: up, down, left, right
        const directions: [number, number][] = [[-1, 0], [1, 0], [0, -1], [0, 1]];

        for (let [dr, dc] of directions) {
          const newRow: number = row + dr, newCol: number = col + dc;

          if (newRow >= 0 && newRow < 10 && newCol >= 0 && newCol < 10 &&
            grid[newRow][newCol] && !visited[newRow][newCol]) {
            visited[newRow][newCol] = true;
            group.push(newRow * 10 + newCol + 1);
            dfs(newRow, newCol, group);
          }
        }
      }

      // Run DFS for each number
      numbers.forEach(num => {
        const row: number = Math.floor((num - 1) / 10);
        const col: number = (num - 1) % 10;

        if (!visited[row][col]) {
          visited[row][col] = true;
          let group: number[] = [num];
          dfs(row, col, group);
          groups[`group${groupIndex++}`] = group;
        }
      });

      return groups;
    };

    const onSubmitCell = () => {
      if (currentCell && currentQuestion?.length) {
        let finalSelectedCells = [...selectedCells];
          finalSelectedCells.push(currentCell);

        onGameMechanicContentChange({
          ...(gameMechanic.content as THotspotGame),
          boats: createBoats(finalSelectedCells),
          questions: { ...(gameMechanic.content as THotspotGame).questions, [currentCell]: currentQuestion }
        });
        setSelectedCells(finalSelectedCells);
      }

      setCurrentCell(null);
      setCurrentQuestion(null);
    };

    const buildGridCells = () => {
      return new Array(100).fill('')
        .map((el, i) => {
          const index = i + 1;
          return (
            <span
              className={
                classnames({
                  active:  currentCell === index,
                  selected: selectedCells.includes(index)
                })
              }
              key={`battleship_cell_${i}`}
              onClick={() => setCurrentCell(currentCell === index ? null : index)}
            >
              {index}
            </span>
          );
        });
    };

    useEffect(() => {
      if (currentCell) {
        if (questions[currentCell]) {
          setCurrentQuestion(questions[currentCell]);
        } else if (questions[previousCell as number]) {
          setCurrentQuestion('');
        }
      }
    }, [currentCell]);

    return (
      <div className='battleships-editor'>
        <div className='hotspot'>
          <Row>
            <Col md={5}>
              <div className='numbers-container'>
                {buildGridCells()}
              </div>
            </Col>
            <Col md={7}>
              <TinyMceEditor handleChange={(value) => setCurrentQuestion(value)} value={currentQuestion ?? ''} />
              <div className='d-flex justify-content-between mt-2'>
                <ButtonMotion
                  variant={'success'}
                  disabled={!Boolean(currentCell && currentQuestion?.length)}
                  onClick={onSubmitCell}
                >
                  Next cell
                </ButtonMotion>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  return <GameNotFound />;
};
