import { useEffect, useMemo, useState } from 'react';
import AzureAuth from '../../../azure-component/AzureAuth';
import { getUserProfile, getAllAvatars, saveProfiles, getShopAllItems } from '../../../api/v0';
import AvatarStylePicker from './avatarStylePicker';
import WardrobePicker, { WardrobeItemType } from './wardrobePicker';

export type AvatarProfileType = {
  gender: string;
  style: {
    body: number,
    head: number,
    color: string,
  },
  wardrobe: {
    top: string | null,
    bottom: string | null,
    shoes: string | null,
    accessories: string | null,
  },
  items?: string[],
  coins?: number,
}

export function Avatar({ onProfileChange }: { onProfileChange: (data: Record<string, any>) => void }) {
  const account = AzureAuth.getAccount();
  const [currentList, setCurrentList] = useState('style');
  const [shopItems, setShopItems] = useState<WardrobeItemType[]>([]);
  const [allStyles, setAllStyles] = useState<Record<string, Record<string, any>>>({});

  useEffect(() => {
    getAllAvatars(setAllStyles);
    getShopAllItems((data: Record<string, any>[]) => setShopItems(data as unknown as WardrobeItemType[]));
  }, []);


  const [profile, setProfile] = useState<AvatarProfileType>({
    gender: 'male',
    style: {
      body: 1,
      head: 1,
      color: 'FACEC3',
    },
    wardrobe: {
      top: null,
      bottom: null,
      shoes: null,
      accessories: null,
    },
  })

  const updateProfile = (currentProfile: AvatarProfileType) => {
    setProfile(currentProfile);
    onProfileChange(currentProfile);
  }

  const submitProfile = (currentProfile: AvatarProfileType) => {
    if (account?.localAccountId) {
      saveProfiles(account.localAccountId, currentProfile, () => {}, () => {});
    }
  }

  const onSelectWardrobeItem = (category: string, uid: string) => {
    let currentUpdatedProfile = {
      ...profile,
    }
    switch (category) {
      case 'tops':
        currentUpdatedProfile = {
          ...profile,
          wardrobe: {
            ...profile.wardrobe,
            top: uid
          }
        }
        break;
      case 'bottoms':
        currentUpdatedProfile = {
          ...profile,
          wardrobe: {
            ...profile.wardrobe,
            bottom: uid
          }
        }
        break;
      default:
        currentUpdatedProfile = {
          ...profile,
          wardrobe: {
            ...profile.wardrobe,
            [category]: uid
          }
        }
        break;
    }

    if (currentUpdatedProfile !== profile) {
      setProfile(currentUpdatedProfile);
      onProfileChange(currentUpdatedProfile);
      submitProfile(currentUpdatedProfile);
    }

  }

  const parseProfile = (data: Record<string, any>) => {
    if (data && data !== profile) {
      setProfile(data as unknown as AvatarProfileType);
    }
  }

  useEffect(() => {
    if (account?.localAccountId) {
      getUserProfile(account.localAccountId, parseProfile);
    }
  }, [account?.localAccountId]);

  const avatarVector = useMemo(() => {
    const selectedStyles = Object.values(allStyles)?.filter((checkedStyle) => checkedStyle?.gender === profile?.gender && checkedStyle?.skin_color === profile?.style?.color);
    const selectedBodyStyle = selectedStyles?.filter((checkedStyle) => checkedStyle.type === 'body' && checkedStyle.option === profile?.style?.body);
    const selectedHeadStyle = selectedStyles?.filter((checkedStyle) => checkedStyle.type === 'head' && checkedStyle.option === profile?.style?.head);
    const selectedWardrobeTopItem: Record<string, any>[] | null = shopItems?.filter((item) => item.uid === profile?.wardrobe?.top) ?? null;
    const selectedWardrobeBottomItem: Record<string, any>[] | null = shopItems?.filter((item) => item.uid === profile?.wardrobe?.bottom) ?? null;
    const selectedWardrobeAccessoriesItem: Record<string, any>[] | null = shopItems?.filter((item) => item.uid === profile?.wardrobe?.accessories) ?? null;
    const selectedWardrobeShoesItem: Record<string, any>[] | null = shopItems?.filter((item) => item.uid === profile?.wardrobe?.shoes) ?? null;
    return (
      <div style={{
        position: 'relative',
        width: '200px',
        height: '500px',
      }}>
        <img src={selectedBodyStyle?.[0]?.svg ?? ''} alt={'body'} style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }} />
        <img src={selectedHeadStyle?.[0]?.svg ?? ''} alt={'head'} style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }} />
        {selectedWardrobeAccessoriesItem?.[0]?.avatar_svg && (
          <img src={selectedWardrobeAccessoriesItem[0].avatar_svg ?? ''} alt={'body'} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }} />
        )}
        {selectedWardrobeBottomItem?.[0]?.avatar_svg && (
          <img src={selectedWardrobeBottomItem[0].avatar_svg ?? ''} alt={'body'} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }} />
        )}
        {selectedWardrobeTopItem?.[0]?.avatar_svg && (
          <img src={selectedWardrobeTopItem[0].avatar_svg ?? ''} alt={'body'} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }} />
        )}
        {selectedWardrobeShoesItem?.[0]?.avatar_svg && (
          <img src={selectedWardrobeShoesItem[0].avatar_svg ?? ''} alt={'body'} style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }} />
        )}
      </div>
    )
  }, [profile, allStyles, shopItems]);

  return (
    <div className='avatar-container d-flex position-relative flex-shrink-0 flex-grow-1'>
      <div className='avatar-wrapper position-relative'>
        <div className='avatar-img-wrapper bg-white d-flex align-items-center justify-content-center'>
          {avatarVector}
        </div>
      </div>
      <div className='avatar-items'>
        {
          currentList === "wardrobe"
            ? (
              <WardrobePicker
                currentStyle={profile}
                wardrobeItems={shopItems}
                onSelectItem={onSelectWardrobeItem}
              />
            ) : (
              <AvatarStylePicker
                currentStyle={profile}
                updateCurrentStyle={updateProfile}
                submitCurrentProfile={() => submitProfile(profile)}
              />
            )
        }
        <div className='item-tabs'>
          <div
            className={`item-tab ${currentList === "style" ? "active" : ""}`}
            onClick={() => setCurrentList('style')}
          >
            Style
          </div>
          <div
            className={`item-tab ${currentList === "wardrobe" ? "active" : ""}`}
            onClick={() => setCurrentList('wardrobe')}
          >
            Wardrobe
          </div>
        </div>
      </div>
    </div>
  );
}