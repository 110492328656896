import React, { memo, useMemo, useRef } from 'react';
import { IGemProps } from 'src/@interfaces/skill-island-interfaces';
import { diamondColors } from 'src/helpers';
import {  useGame } from 'src/hooks';


function Gems({ index, onGameSelect, color, level, title }: IGemProps) {
  const { showTheGameInfoTooltip } = useGame();
  const gemRef = useRef<SVGGElement | null>(null);

  const renderCoreGem = useMemo(()=> {
    switch (index) {
      case 0:        return <g id='Big Gem'>
          <path id='Vector_1279' fill={`#${diamondColors[color][0]}`}
                d='M1271.34 256.646L1305.71 242.449H1358L1392.93 257.886L1362.05 273.323H1304.05L1271.34 256.646Z' />
          <path id='Vector_1280' fill={`#${diamondColors[color][1]}`}
                d='M1271.34 256.646L1249 281.841L1294.33 312.223L1304.05 273.323L1271.34 256.646Z' />
          <path id='Vector_1281' fill={`#${diamondColors[color][1]}`}
                d='M1362.05 273.323L1377 312.223L1417.61 280.975L1392.93 257.886L1362.05 273.323Z' />
          <path id='Vector_1282' fill={`#${diamondColors[color][2]}`}
                d='M1304.05 273.323L1362.28 273.802L1377 312.223H1294.33L1304.05 273.323Z' />
          <path id='Vector_1283' fill={`#${diamondColors[color][2]}`}
                d='M1377 312.223L1376.07 314.016L1335.66 379.949L1417.61 280.975L1377 312.223Z' />
          <path id='Vector_1284' fill={`#${diamondColors[color][2]}`}
                d='M1294.33 312.223L1335.66 379.949L1249 281.842L1294.33 312.223Z' />
          <path id='Vector_1285' fill={`#${diamondColors[color][1]}`}
                d='M1377 312.223L1335.66 379.949L1294.33 312.223H1377Z' />
          <path id='Vector_1286' fill={`#${diamondColors[color][3]}`}
                d='M1273.9 257.916C1275.73 260.576 1277.35 263.445 1278.89 266.061C1283.12 273.249 1285.73 281.199 1289 288.805C1291.17 293.827 1292.37 298.788 1293 304.153C1293.29 306.708 1293.55 309.428 1294.09 312.028L1294.32 312.193L1304.05 273.309L1273.9 257.916Z' />
          <path id='Vector_1287' fill={`#${diamondColors[color][4]}`}
                d='M1384.98 254.673C1384.5 254.883 1384 255.077 1383.48 255.256C1377.65 257.229 1371.15 257.752 1365.08 258.529C1357.81 259.47 1350.66 260.233 1343.3 260.233C1334.6 260.233 1325.98 260.726 1317.28 260.233C1308.66 259.739 1299.84 260.143 1291.34 258.409C1286.33 257.393 1280.32 256.452 1275.39 254.972L1271.27 256.661L1304.07 273.637H1362.07L1392.94 258.2L1384.98 254.688V254.673Z' />
          <path id='Vector_1288' fill='white'
                d='M1362.05 269.602H1304.05L1275.39 254.972L1271.34 256.646L1304.05 273.637L1362.02 273.802L1392.93 257.886L1388.97 256.138L1362.05 269.602Z' />
          <path id='Vector_1289' fill='white'
                d='M1271.34 256.646L1269.92 258.245L1301.93 274.579L1292.77 311.191L1294.33 312.223L1304.05 273.323L1271.34 256.646Z' />
          <path id='Vector_1290' fill={`#${diamondColors[color][2]}`}
                d='M1377.74 311.969C1377.74 299.655 1382.45 289.164 1388.38 278.584C1390.92 274.056 1393.93 269.871 1395.66 264.895C1397.3 260.188 1394.14 258.574 1390.12 260.606C1383.21 264.088 1376.49 267.645 1369.51 271.067C1364.91 273.323 1362.6 274.37 1364.52 279.854C1365.83 283.635 1367.61 287.371 1369.25 291.047C1372.15 297.533 1375.55 304.795 1377.73 310.967V311.969H1377.74Z'
                opacity='.2' />
          <path id='Vector_1291' fill='white'
                d='M1364.55 274.101L1394.32 259.216L1392.93 257.916L1362.05 273.353L1377 312.253L1378.7 310.937L1364.55 274.101Z'
                opacity='.2' />
          <g id='Group_116' fill='white'>
            <path id='Vector_1292'
                  d='M1389.6 290.532C1400.93 279.204 1400.93 260.838 1389.6 249.511C1378.28 238.183 1359.91 238.183 1348.58 249.511C1337.26 260.838 1337.26 279.204 1348.58 290.532C1359.91 301.859 1378.28 301.859 1389.6 290.532Z'
                  opacity='.1' />
            <path id='Vector_1293'
                  d='M1379.67 288.687C1390.61 284.184 1395.82 271.668 1391.32 260.731C1386.81 249.795 1374.3 244.58 1363.36 249.083C1352.43 253.586 1347.21 266.102 1351.71 277.039C1356.22 287.975 1368.73 293.19 1379.67 288.687Z'
                  opacity='.2' />
            <path id='Vector_1294'
                  d='M1371.51 254.21C1370.6 260.711 1371.51 268.9 1359.9 269.886C1363.34 271.485 1367.18 272.89 1369.58 276.118C1371.72 278.987 1373.45 283.814 1373.81 287.311C1374.47 283.53 1373.59 279.988 1375.93 276.133C1377.68 273.249 1380.64 271.231 1383.94 270.843C1380.24 268.78 1377 269.109 1374.75 264.671C1373.89 262.982 1373.09 261.204 1372.57 259.441C1372.02 257.617 1372.21 254.793 1371.02 253.463L1371.51 254.21Z'
                  opacity='.7' />
          </g>
          <g id='Group_117' fill='white'>
            <path id='Vector_1295'
                  d='M1315.33 320.387C1317.86 307.68 1309.62 295.325 1296.91 292.792C1284.2 290.26 1271.84 298.509 1269.31 311.217C1266.78 323.925 1275.03 336.279 1287.74 338.812C1300.44 341.344 1312.8 333.095 1315.33 320.387Z'
                  opacity='.1' />
            <path id='Vector_1296'
                  d='M1311.62 314.913C1311.62 324.477 1303.86 332.233 1294.3 332.233C1284.73 332.233 1276.98 324.477 1276.98 314.913C1276.98 305.348 1284.73 297.592 1294.3 297.592C1303.86 297.592 1311.62 305.348 1311.62 314.913Z'
                  opacity='.2' />
            <path id='Vector_1297'
                  d='M1294.3 303.017C1293.55 308.277 1294.3 314.898 1284.91 315.705C1287.69 317.005 1290.8 318.14 1292.74 320.741C1294.46 323.057 1295.86 326.957 1296.16 329.797C1296.69 326.733 1295.98 323.879 1297.88 320.741C1299.3 318.409 1301.69 316.781 1304.37 316.467C1301.36 314.808 1298.75 315.062 1296.93 311.475C1296.22 310.101 1295.58 308.666 1295.16 307.246C1294.71 305.767 1294.88 303.48 1293.91 302.404L1294.31 303.002L1294.3 303.017Z'
                  opacity='.7' />
          </g>
          <path id='Vector_1298' fill='white'
                d='M1296.48 311.969C1298.85 312.865 1301.24 314.106 1302.75 314.957C1311.18 319.724 1319.12 327.406 1325.21 334.863C1331.15 342.14 1335.06 350.195 1336.68 359.475C1337.57 364.571 1338.08 369.757 1338.59 374.898L1377 311.954H1296.48V311.969Z'
                opacity='.1' />
          <path id='Vector_1299' fill='white'
                d='M1347.24 311.969C1347.24 311.969 1318.46 349.672 1318.97 348.91C1319.48 348.148 1366.59 312.223 1366.59 312.223L1347.24 311.969Z'
                opacity='.2' />
          <path id='Vector_1300' fill='white'
                d='M1280.52 261.802L1329.16 242.449H1336.74L1294.77 268.9L1280.52 261.802Z' opacity='.2' />
          <path id='Vector_1301' fill='white'
                d='M1314.63 273.353L1358.51 242.27L1364.37 244.87L1343.28 273.458L1314.63 273.353Z' opacity='.2' />
        </g>;
      case 1:        return <g id='GAME GEM 12'>
        <path id='Vector_649' fill={`#${diamondColors[color][0]}`}
              d='M787.471 159.692L755.529 146.499H706.936L674.48 160.845L703.172 175.191H757.07L787.471 159.692Z' />
        <path id='Vector_650' fill={`#${diamondColors[color][1]}`}
              d='M787.471 159.692L808.233 183.107L766.111 211.341L757.07 175.191L787.471 159.692Z' />
        <path id='Vector_651' fill={`#${diamondColors[color][1]}`}
              d='M703.172 175.191L689.284 211.341L651.537 182.301L674.48 160.845L703.172 175.191Z' />
        <path id='Vector_652' fill={`#${diamondColors[color][2]}`}
              d='M757.07 175.191L702.964 175.635L689.284 211.341H766.111L757.07 175.191Z' />
        <path id='Vector_653' fill={`#${diamondColors[color][2]}`}
              d='M689.284 211.341L690.145 213.007L727.698 274.28L651.537 182.301L689.284 211.341Z' />
        <path id='Vector_654' fill={`#${diamondColors[color][2]}`}
              d='M766.111 211.341L727.698 274.28L808.233 183.107L766.111 211.341Z' />
        <path id='Vector_655' fill={`#${diamondColors[color][1]}`}
              d='M689.284 211.341L727.698 274.28L766.111 211.341H689.284Z' />
        <path id='Vector_656' fill={`#${diamondColors[color][3]}`}
              d='M785.096 160.872C783.388 163.344 781.888 166.011 780.457 168.441C776.527 175.121 774.097 182.51 771.055 189.578C769.042 194.245 767.931 198.855 767.347 203.841C767.069 206.216 766.833 208.744 766.333 211.16L766.111 211.313L757.07 175.177L785.096 160.872Z' />
        <path id='Vector_657' fill={`#${diamondColors[color][4]}`}
              d='M681.868 157.859C682.313 158.053 682.771 158.234 683.257 158.4C688.673 160.234 694.714 160.72 700.353 161.442C707.116 162.317 713.754 163.025 720.601 163.025C728.684 163.025 736.697 163.483 744.78 163.025C752.793 162.567 760.987 162.942 768.889 161.331C773.541 160.386 779.124 159.511 783.707 158.137L787.54 159.706L757.056 175.482H703.158L674.466 161.136L681.868 157.873V157.859Z' />
        <path id='Vector_658' fill='white'
              d='M703.172 171.733H757.07L783.707 158.137L787.471 159.692L757.07 175.482L703.2 175.635L674.48 160.845L678.16 159.22L703.172 171.733Z' />
        <path id='Vector_659' fill='white'
              d='M787.471 159.692L788.79 161.178L759.042 176.357L767.556 210.382L766.111 211.341L757.07 175.191L787.471 159.692Z' />
        <path id='Vector_660' fill={`#${diamondColors[color][2]}`}
              d='M688.59 211.104C688.59 199.661 684.215 189.912 678.702 180.079C676.341 175.871 673.549 171.983 671.938 167.358C670.411 162.983 673.355 161.484 677.091 163.372C683.507 166.608 689.756 169.913 696.242 173.094C700.519 175.191 702.658 176.163 700.88 181.26C699.658 184.773 698.006 188.245 696.478 191.662C693.784 197.689 690.631 204.438 688.604 210.174V211.104H688.59Z'
              opacity='.2' />
        <path id='Vector_661' fill='white'
              d='M700.853 175.913L673.188 162.081L674.48 160.872L703.172 175.219L689.284 211.368L687.701 210.146L700.853 175.913Z'
              opacity='.2' />
        <g id='Group_70' fill='white'>
          <path id='Vector_662'
                d='M677.566 191.183C667.039 180.656 667.039 163.588 677.566 153.061C688.093 142.534 705.161 142.534 715.688 153.061C726.215 163.588 726.215 180.656 715.688 191.183C705.161 201.71 688.093 201.71 677.566 191.183Z'
                opacity='.1' />
          <path id='Vector_663'
                d='M686.799 189.468C676.636 185.283 671.79 173.652 675.975 163.489C680.159 153.326 691.791 148.479 701.954 152.664C712.117 156.849 716.964 168.48 712.779 178.643C708.594 188.807 696.963 193.653 686.799 189.468Z'
                opacity='.2' />
          <path id='Vector_664'
                d='M694.381 157.428C695.228 163.47 694.381 171.08 705.172 171.997C701.978 173.483 698.408 174.788 696.173 177.788C694.187 180.454 692.576 184.94 692.242 188.19C691.631 184.676 692.451 181.385 690.27 177.802C688.645 175.121 685.896 173.246 682.826 172.885C686.271 170.969 689.284 171.274 691.367 167.15C692.173 165.58 692.909 163.928 693.395 162.289C693.909 160.595 693.728 157.97 694.839 156.734L694.381 157.428Z'
                opacity='.7' />
        </g>
        <g id='Group_71' fill='white'>
          <path id='Vector_665'
                d='M746.589 218.928C744.236 207.119 751.902 195.637 763.712 193.284C775.521 190.93 787.003 198.596 789.356 210.406C791.709 222.216 784.044 233.697 772.234 236.05C760.424 238.404 748.943 230.738 746.589 218.928Z'
                opacity='.1' />
          <path id='Vector_666'
                d='M750.043 213.84C750.043 222.729 757.251 229.936 766.139 229.936C775.027 229.936 782.235 222.729 782.235 213.84C782.235 204.952 775.027 197.744 766.139 197.744C757.251 197.744 750.043 204.952 750.043 213.84Z'
                opacity='.2' />
          <path id='Vector_667'
                d='M766.139 202.786C766.833 207.674 766.139 213.826 774.861 214.576C772.277 215.785 769.389 216.84 767.583 219.257C765.986 221.409 764.681 225.034 764.403 227.673C763.917 224.826 764.57 222.173 762.806 219.257C761.487 217.09 759.265 215.576 756.779 215.285C759.57 213.743 762 213.979 763.695 210.646C764.347 209.368 764.945 208.035 765.334 206.716C765.75 205.341 765.597 203.216 766.5 202.216L766.125 202.772L766.139 202.786Z'
                opacity='.7' />
        </g>
        <path id='Vector_668' fill='white'
              d='M764.111 211.105C761.903 211.938 759.681 213.09 758.279 213.882C750.446 218.312 743.071 225.451 737.405 232.381C731.892 239.144 728.253 246.629 726.753 255.254C725.92 259.989 725.448 264.808 724.976 269.586L689.284 211.091H764.111V211.105Z'
              opacity='.1' />
        <path id='Vector_669' fill='white'
              d='M716.935 211.104C716.935 211.104 743.683 246.143 743.21 245.435C742.738 244.727 698.95 211.341 698.95 211.341L716.935 211.104Z'
              opacity='.2' />
        <path id='Vector_670' fill='white'
              d='M778.944 164.483L733.739 146.499H726.698L765.695 171.08L778.944 164.483Z' opacity='.2' />
        <path id='Vector_671' fill='white'
              d='M747.238 175.219L706.463 146.332L701.019 148.748L720.615 175.316L747.238 175.219Z' opacity='.2' />
      </g>;
      case 2:  return <g id='GAME GEM 3'>
        <path id='Vector_250' fill={`#${diamondColors[color][0]}`}
              d='M921.497 38.3641L953.031 25.3391H1001L1033.05 39.502L1004.72 53.665H951.509L921.497 38.3641Z' />
        <path id='Vector_251' fill={`#${diamondColors[color][1]}`}
              d='M921.497 38.3641L901 61.48L942.584 89.3534L951.509 53.665L921.497 38.3641Z' />
        <path id='Vector_252' fill={`#${diamondColors[color][1]}`}
              d='M1004.72 53.665L1018.43 89.3534L1055.7 60.6848L1033.05 39.5021L1004.72 53.665Z' />
        <path id='Vector_253' fill={`#${diamondColors[color][2]}`}
              d='M951.509 53.665L1004.93 54.1037L1018.43 89.3534H942.584L951.509 53.665Z' />
        <path id='Vector_254' fill={`#${diamondColors[color][2]}`}
              d='M1018.43 89.3534L1017.58 90.9987L980.507 151.489L1055.7 60.6848L1018.43 89.3534Z' />
        <path id='Vector_255' fill={`#${diamondColors[color][2]}`}
              d='M942.584 89.3534L980.507 151.489L901 61.48L942.584 89.3534Z' />
        <path id='Vector_256' fill={`#${diamondColors[color][1]}`}
              d='M1018.43 89.3534L980.507 151.489L942.584 89.3534H1018.43Z' />
        <path id='Vector_257' fill={`#${diamondColors[color][3]}`}
              d='M923.842 39.5294C925.528 41.9699 927.009 44.6024 928.421 47.0017C932.301 53.5964 934.7 60.8904 937.703 67.8691C939.691 72.4758 940.788 77.0277 941.364 81.9497C941.638 84.2942 941.871 86.7896 942.365 89.1752L942.584 89.326L951.509 53.6513L923.842 39.5294Z' />
        <path id='Vector_258' fill={`#${diamondColors[color][4]}`}
              d='M1025.75 36.5543C1025.31 36.7462 1024.86 36.9245 1024.38 37.089C1019.03 38.8988 1013.07 39.3786 1007.5 40.0916C1000.83 40.9553 994.273 41.6546 987.513 41.6546C979.534 41.6546 971.623 42.107 963.643 41.6546C955.732 41.2022 947.643 41.5723 939.842 39.9819C935.249 39.0496 929.737 38.1858 925.213 36.8285L921.429 38.3778L951.523 53.9529H1004.73L1033.06 39.7899L1025.75 36.568V36.5543Z' />
        <path id='Vector_259' fill='white'
              d='M1004.72 50.2511H951.509L925.213 36.8285L921.497 38.3641L951.509 53.9529L1004.69 54.1037L1033.05 39.5021L1029.41 37.8979L1004.72 50.2511Z' />
        <path id='Vector_260' fill='white'
              d='M921.497 38.3641L920.195 39.8311L949.563 54.8167L941.158 88.4074L942.584 89.3534L951.509 53.665L921.497 38.3641Z' />
        <path id='Vector_261' fill={`#${diamondColors[color][2]}`}
              d='M1019.12 89.1203C1019.12 77.8229 1023.43 68.1981 1028.88 58.4911C1031.21 54.3368 1033.96 50.4979 1035.55 45.9323C1037.06 41.6135 1034.16 40.1327 1030.47 41.9974C1024.13 45.1919 1017.96 48.455 1011.56 51.5947C1007.34 53.665 1005.23 54.6247 1006.98 59.6565C1008.19 63.1253 1009.82 66.5529 1011.33 69.9256C1013.99 75.876 1017.1 82.5393 1019.1 88.2017V89.1203H1019.12Z'
              opacity='.2' />
        <path id='Vector_262' fill='white'
              d='M1007.01 54.3779L1034.32 40.7223L1033.05 39.5294L1004.72 53.6924L1018.43 89.3809L1019.99 88.1743L1007.01 54.3779Z'
              opacity='.2' />
        <g id='Group_39' fill='white'>
          <path id='Vector_263'
                d='M1030 69.4528C1040.39 59.0601 1040.39 42.2102 1030 31.8176C1019.61 21.4249 1002.76 21.4249 992.364 31.8176C981.971 42.2103 981.971 59.0601 992.364 69.4528C1002.76 79.8454 1019.61 79.8454 1030 69.4528Z'
                opacity='.1' />
          <path id='Vector_264'
                d='M1020.88 67.7603C1030.92 63.6289 1035.7 52.1459 1031.57 42.1124C1027.44 32.0789 1015.96 27.2943 1005.92 31.4257C995.889 35.5571 991.104 47.0401 995.236 57.0736C999.367 67.1071 1010.85 71.8917 1020.88 67.7603Z'
                opacity='.2' />
          <path id='Vector_265'
                d='M1013.4 36.1293C1012.56 42.0933 1013.4 49.6067 1002.75 50.5116C1005.9 51.9786 1009.42 53.2674 1011.63 56.2289C1013.59 58.8613 1015.18 63.2898 1015.51 66.498C1016.11 63.0293 1015.3 59.7799 1017.46 56.2426C1019.06 53.5964 1021.78 51.7455 1024.81 51.3891C1021.41 49.497 1018.43 49.7987 1016.37 45.7266C1015.58 44.1773 1014.85 42.5458 1014.37 40.928C1013.86 39.2553 1014.04 36.664 1012.95 35.4438L1013.4 36.1293Z'
                opacity='.7' />
        </g>
        <g id='Group_40' fill='white'>
          <path id='Vector_266'
                d='M961.857 96.8443C964.18 85.1853 956.612 73.8504 944.953 71.527C933.294 69.2037 921.959 76.7717 919.636 88.4307C917.312 100.09 924.88 111.425 936.539 113.748C948.198 116.071 959.533 108.503 961.857 96.8443Z'
                opacity='.1' />
          <path id='Vector_267'
                d='M958.447 91.8213C958.447 100.596 951.331 107.712 942.557 107.712C933.782 107.712 926.666 100.596 926.666 91.8213C926.666 83.0466 933.782 75.9308 942.557 75.9308C951.331 75.9308 958.447 83.0466 958.447 91.8213Z'
                opacity='.2' />
          <path id='Vector_268'
                d='M942.557 80.9077C941.871 85.7338 942.557 91.8076 933.946 92.548C936.496 93.7408 939.348 94.7828 941.131 97.1684C942.707 99.2935 943.996 102.872 944.27 105.477C944.75 102.666 944.106 100.048 945.847 97.1684C947.15 95.0295 949.343 93.5351 951.797 93.2472C949.042 91.7253 946.642 91.9584 944.97 88.6679C944.325 87.4065 943.736 86.0903 943.352 84.7878C942.94 83.4305 943.091 81.3328 942.2 80.3456L942.57 80.894L942.557 80.9077Z'
                opacity='.7' />
        </g>
        <path id='Vector_269' fill='white'
              d='M944.558 89.1204C946.738 89.943 948.932 91.081 950.317 91.8625C958.049 96.2361 965.33 103.283 970.924 110.125C976.367 116.802 979.959 124.192 981.439 132.706C982.262 137.381 982.728 142.139 983.194 146.855L1018.43 89.1066H944.558V89.1204Z'
              opacity='.1' />
        <path id='Vector_270' fill='white'
              d='M991.133 89.1204C991.133 89.1204 964.726 123.712 965.193 123.013C965.659 122.313 1008.89 89.3534 1008.89 89.3534L991.133 89.1204Z'
              opacity='.2' />
        <path id='Vector_271' fill='white'
              d='M929.915 43.0942L974.543 25.3391H981.494L942.995 49.6067L929.915 43.0942Z' opacity='.2' />
        <path id='Vector_272' fill='white'
              d='M961.217 53.6924L1001.47 25.1746L1006.85 27.5602L987.5 53.7884L961.217 53.6924Z' opacity='.2' />
      </g>;
    }
  },[index])

  const renderedMiniGem = useMemo(() => {
    switch (index) {
      case 3 :
        return <g id='Mini gem_16'>
          <path id='Vector_1103' fill={`#${diamondColors[color][0]}`}
                d='M1390.25 400.281L1428 435.5L1399.92 461.796L1390.25 401.538L1354.24 442.403L1393.74 500.033L1399.92 461.796L1390.25 400.281Z' />
          <path id='Vector_1104' fill={`#${diamondColors[color][1]}`}
                d='M1390.18 400.281L1354.24 442.201L1399.9 462.099L1390.18 400.281Z' />
          <path id='Vector_1105' fill='white'
                d='M1390.18 400.281L1389.18 401.45L1398.35 459.781L1355.27 441.005L1354.24 442.201L1399.9 462.099L1390.18 400.281Z' />
          <path id='Vector_1106' fill={`#${diamondColors[color][2]}`}
                d='M1399.79 461.74L1393.58 500.033L1428 435.405L1399.79 461.74Z' />
          <path id='Vector_1107' fill={`#${diamondColors[color][2]}`}
                d='M1399.79 461.74L1393.58 500.033L1428 435.405L1399.79 461.74Z' />
          <path id='Vector_1108' fill='white' d='M1354.24 442.43L1394.98 430.11L1390.3 400.281L1354.24 442.43Z'
                opacity='.2' />
          <path id='Vector_1109' fill='white' d='M1394.76 430.011L1428 435.405L1390.07 400.281L1394.76 429.466V430.011Z'
                opacity='.2' />
          <path id='Vector_1110' fill={`#${diamondColors[color][3]}`}
                d='M1397.21 477.688C1397.28 477.271 1417.33 445.703 1417.33 445.703L1428 436.107L1403.78 480.935L1393.58 500.033L1397.21 477.688Z'
                opacity='.5' />
          <path id='Vector_1111' fill={`#${diamondColors[color][3]}`}
                d='M1401 461.741L1426.8 437.66L1428 435.405L1399.79 461.741L1393.58 500.033L1395.32 496.753L1401 461.741Z' />
          <path id='Vector_1112' fill={`#${diamondColors[color][3]}`}
                d='M1367.53 461.83L1399.2 462.099L1354.24 442.43L1367.53 461.83Z' opacity='.5' />
          <path id='Vector_1113' fill='white'
                d='M1356.35 441.699C1357.73 443.119 1361.14 443.32 1363.07 443.69C1367.09 444.558 1371.23 444.706 1375.31 444.126C1380.24 443.33 1384.11 441.161 1387.94 438.108C1390.45 436.116 1391.43 434.157 1392.48 431.323C1392.79 430.599 1393.04 429.849 1393.21 429.083C1394.47 429.751 1394.31 434.026 1394.67 435.448C1395.38 438.248 1396.15 440.966 1396.45 443.828C1397 448.564 1397.8 453.269 1398.84 457.924C1399.04 458.876 1399.66 460.108 1398.57 460.588C1397.71 460.97 1396.19 460.22 1395.44 459.949C1393.24 459.144 1391.54 457.758 1389.42 456.758C1387.37 455.785 1385.19 455.267 1383.11 454.294C1380.91 453.265 1378.76 452.333 1376.65 451.139C1373.41 449.312 1369.82 447.883 1366.43 446.455C1364.73 445.737 1362.95 445.059 1361.33 444.319C1360.05 443.734 1357.19 443.059 1356.48 441.83L1356.35 441.699Z'
                opacity='.4' />
          <path id='Vector_1114' fill='white'
                d='M1406.04 473.16C1413.19 468.481 1415.19 458.894 1410.51 451.747C1405.83 444.6 1396.24 442.6 1389.09 447.279C1381.95 451.958 1379.95 461.545 1384.63 468.692C1389.3 475.839 1398.89 477.839 1406.04 473.16Z'
                opacity='.1' />
          <path id='Vector_1115' fill='white'
                d='M1410.35 461.07C1410.07 463.345 1409.12 465.485 1407.62 467.22C1406.12 468.954 1404.14 470.205 1401.93 470.814C1399.72 471.424 1397.38 471.364 1395.2 470.643C1393.02 469.922 1391.11 468.571 1389.7 466.763C1388.29 464.954 1387.45 462.769 1387.29 460.482C1387.12 458.196 1387.64 455.911 1388.77 453.918C1389.9 451.924 1391.6 450.311 1393.65 449.282C1395.69 448.254 1398 447.855 1400.28 448.138C1403.33 448.517 1406.1 450.093 1407.99 452.518C1409.88 454.943 1410.73 458.02 1410.35 461.07Z'
                opacity='.2' />
          <path id='Vector_1116' fill='white'
                d='M1400.04 451.994C1399.13 455.409 1399.08 459.842 1392.88 459.599C1394.59 460.684 1396.53 461.687 1397.58 463.567C1398.56 465.505 1399.08 467.65 1399.08 469.826C1399.68 467.847 1399.44 465.9 1400.94 463.992C1401.48 463.284 1402.17 462.71 1402.97 462.313C1403.76 461.916 1404.63 461.707 1405.52 461.702C1403.69 460.357 1401.97 460.314 1401.07 457.796C1400.71 456.842 1400.44 455.859 1400.25 454.857C1400.09 453.847 1400.37 452.351 1399.83 451.562L1400.04 451.994Z'
                opacity='.7' />
          <path id='Vector_1117' fill='white'
                d='M1401.18 460.381C1400.74 458.966 1400.27 459.13 1399.86 459.735C1397.81 458.712 1398.11 465.682 1400.5 463.92C1401.3 463.323 1401.45 461.257 1401.18 460.381Z' />
          <path id='Vector_1118' fill='white'
                d='M1364.69 443.421C1364.43 445.558 1363.53 447.569 1362.13 449.198C1360.72 450.828 1358.86 452.003 1356.78 452.575C1354.71 453.148 1352.51 453.092 1350.46 452.414C1348.42 451.737 1346.62 450.468 1345.3 448.769C1343.97 447.07 1343.19 445.017 1343.03 442.869C1342.87 440.721 1343.36 438.575 1344.42 436.703C1345.48 434.83 1347.08 433.314 1349 432.348C1350.93 431.382 1353.09 431.008 1355.23 431.273C1358.1 431.63 1360.7 433.11 1362.48 435.388C1364.25 437.666 1365.05 440.556 1364.69 443.421Z'
                opacity='.1' />
          <path id='Vector_1119' fill='white'
                d='M1363.31 443.077C1363.11 444.662 1362.45 446.153 1361.4 447.362C1360.36 448.571 1358.98 449.442 1357.44 449.867C1355.9 450.291 1354.27 450.249 1352.75 449.747C1351.24 449.244 1349.9 448.303 1348.92 447.042C1347.94 445.782 1347.35 444.259 1347.24 442.665C1347.12 441.072 1347.48 439.48 1348.27 438.09C1349.06 436.701 1350.24 435.577 1351.67 434.86C1353.09 434.142 1354.7 433.865 1356.29 434.061C1357.34 434.192 1358.36 434.529 1359.28 435.053C1360.2 435.577 1361.01 436.277 1361.67 437.115C1362.32 437.952 1362.8 438.909 1363.08 439.932C1363.36 440.955 1363.44 442.024 1363.31 443.077Z'
                opacity='.2' />
          <path id='Vector_1120' fill='white'
                d='M1355.9 436.408C1355.24 438.773 1355.21 441.841 1350.73 441.672C1351.96 442.423 1353.36 443.117 1354.13 444.419C1354.83 445.761 1355.21 447.245 1355.21 448.752C1355.64 447.381 1355.47 446.034 1356.55 444.713C1356.94 444.223 1357.44 443.825 1358.02 443.551C1358.59 443.276 1359.22 443.131 1359.86 443.127C1358.54 442.197 1357.29 442.167 1356.64 440.423C1356.39 439.763 1356.19 439.082 1356.06 438.389C1355.93 437.689 1356.14 436.654 1355.75 436.107L1355.9 436.408Z'
                opacity='.7' />
          <path id='Vector_1121' fill='white'
                d='M1397.03 402.638C1396.83 404.155 1396.17 405.582 1395.12 406.738C1394.08 407.895 1392.7 408.729 1391.16 409.135C1389.62 409.541 1387.99 409.502 1386.47 409.021C1384.95 408.54 1383.62 407.64 1382.64 406.435C1381.66 405.229 1381.07 403.772 1380.96 402.247C1380.84 400.723 1381.2 399.2 1381.99 397.871C1382.78 396.542 1383.96 395.466 1385.39 394.78C1386.81 394.095 1388.42 393.829 1390.01 394.018C1392.13 394.27 1394.07 395.321 1395.39 396.937C1396.7 398.554 1397.29 400.605 1397.03 402.638Z'
                opacity='.1' />
          <path id='Vector_1122' fill='white'
                d='M1395.64 402.379C1395.49 403.482 1395 404.52 1394.23 405.361C1393.46 406.202 1392.44 406.808 1391.3 407.104C1390.16 407.399 1388.96 407.37 1387.84 407.02C1386.72 406.671 1385.73 406.016 1385 405.139C1384.28 404.262 1383.85 403.202 1383.76 402.094C1383.67 400.985 1383.94 399.877 1384.52 398.911C1385.11 397.944 1385.98 397.162 1387.04 396.663C1388.09 396.165 1389.28 395.972 1390.45 396.109C1391.23 396.2 1391.98 396.434 1392.66 396.799C1393.35 397.163 1393.94 397.65 1394.43 398.232C1394.91 398.815 1395.26 399.481 1395.47 400.192C1395.68 400.903 1395.74 401.646 1395.64 402.379Z'
                opacity='.2' />
          <path id='Vector_1123' fill='white'
                d='M1390.14 397.687C1389.68 399.395 1389.66 401.611 1386.55 401.489C1387.41 402.032 1388.38 402.534 1388.91 403.473C1389.4 404.442 1389.65 405.515 1389.66 406.603C1389.95 405.613 1389.84 404.64 1390.58 403.686C1390.85 403.332 1391.2 403.045 1391.6 402.847C1392 402.648 1392.43 402.544 1392.88 402.541C1391.96 401.868 1391.1 401.847 1390.65 400.588C1390.47 400.111 1390.34 399.619 1390.24 399.119C1390.16 398.613 1390.3 397.866 1390.03 397.471L1390.14 397.687Z'
                opacity='.7' />
          <path id='Vector_1124' fill='white'
                d='M1356.25 441.959C1355.92 440.747 1355.57 440.888 1355.26 441.408C1353.73 440.531 1353.95 446.504 1355.74 444.994C1356.35 444.481 1356.46 442.71 1356.25 441.959Z' />
        </g>;

      case 4 :
        return <g id='Mini gem_17'>
          <path id='Vector_1125' fill={`#${diamondColors[color][0]}`}
                d='M1554.25 570.322L1592 605.541L1563.92 631.837L1554.25 571.58L1518.24 612.444L1557.74 670.074L1563.92 631.837L1554.25 570.322Z' />
          <path id='Vector_1126' fill={`#${diamondColors[color][1]}`}
                d='M1554.18 570.322L1518.24 612.242L1563.9 632.14L1554.18 570.322Z' />
          <path id='Vector_1127' fill='white'
                d='M1554.18 570.322L1553.18 571.492L1562.35 629.822L1519.27 611.047L1518.24 612.242L1563.9 632.14L1554.18 570.322Z' />
          <path id='Vector_1128' fill={`#${diamondColors[color][2]}`}
                d='M1563.79 631.781L1557.58 670.074L1592 605.446L1563.79 631.781Z' />
          <path id='Vector_1129' fill={`#${diamondColors[color][2]}`}
                d='M1563.79 631.781L1557.58 670.074L1592 605.446L1563.79 631.781Z' />
          <path id='Vector_1130' fill='white' d='M1518.24 612.471L1558.98 600.151L1554.3 570.322L1518.24 612.471Z'
                opacity='.2' />
          <path id='Vector_1131' fill='white' d='M1558.76 600.052L1592 605.446L1554.07 570.322L1558.76 599.508V600.052Z'
                opacity='.2' />
          <path id='Vector_1132' fill={`#${diamondColors[color][3]}`}
                d='M1561.21 647.729C1561.28 647.313 1581.33 615.745 1581.33 615.745L1592 606.149L1567.78 650.976L1557.58 670.074L1561.21 647.729Z'
                opacity='.5' />
          <path id='Vector_1133' fill={`#${diamondColors[color][3]}`}
                d='M1565 631.782L1590.8 607.702L1592 605.446L1563.79 631.782L1557.58 670.074L1559.32 666.795L1565 631.782Z' />
          <path id='Vector_1134' fill={`#${diamondColors[color][3]}`}
                d='M1531.53 631.872L1563.2 632.14L1518.24 612.471L1531.53 631.872Z' opacity='.5' />
          <path id='Vector_1135' fill='white'
                d='M1520.35 611.74C1521.73 613.16 1525.14 613.361 1527.07 613.731C1531.09 614.6 1535.23 614.747 1539.31 614.167C1544.24 613.372 1548.11 611.202 1551.94 608.15C1554.45 606.157 1555.43 604.198 1556.48 601.364C1556.79 600.641 1557.04 599.891 1557.21 599.124C1558.47 599.793 1558.31 604.067 1558.67 605.49C1559.38 608.289 1560.15 611.008 1560.45 613.87C1561 618.606 1561.8 623.31 1562.84 627.965C1563.04 628.918 1563.66 630.15 1562.57 630.629C1561.71 631.011 1560.19 630.261 1559.44 629.99C1557.24 629.185 1555.54 627.799 1553.42 626.799C1551.37 625.827 1549.19 625.308 1547.11 624.336C1544.91 623.306 1542.76 622.374 1540.65 621.181C1537.41 619.353 1533.82 617.925 1530.43 616.496C1528.73 615.778 1526.95 615.1 1525.33 614.361C1524.05 613.775 1521.19 613.1 1520.48 611.871L1520.35 611.74Z'
                opacity='.4' />
          <path id='Vector_1136' fill='white'
                d='M1570.04 643.202C1577.18 638.522 1579.19 628.935 1574.51 621.788C1569.83 614.642 1560.24 612.641 1553.09 617.32C1545.95 621.999 1543.95 631.586 1548.62 638.733C1553.3 645.88 1562.89 647.881 1570.04 643.202Z'
                opacity='.1' />
          <path id='Vector_1137' fill='white'
                d='M1574.35 631.112C1574.07 633.387 1573.12 635.526 1571.62 637.261C1570.12 638.995 1568.14 640.246 1565.93 640.856C1563.72 641.465 1561.38 641.405 1559.2 640.684C1557.02 639.963 1555.11 638.613 1553.7 636.804C1552.29 634.995 1551.45 632.81 1551.29 630.523C1551.12 628.237 1551.64 625.953 1552.77 623.959C1553.9 621.965 1555.6 620.352 1557.65 619.324C1559.69 618.295 1562 617.897 1564.28 618.179C1567.33 618.559 1570.1 620.134 1571.99 622.559C1573.88 624.985 1574.73 628.061 1574.35 631.112Z'
                opacity='.2' />
          <path id='Vector_1138' fill='white'
                d='M1564.04 622.035C1563.13 625.451 1563.08 629.883 1556.88 629.64C1558.59 630.725 1560.53 631.729 1561.58 633.608C1562.56 635.547 1563.08 637.691 1563.08 639.868C1563.68 637.888 1563.44 635.942 1564.94 634.034C1565.48 633.325 1566.17 632.751 1566.97 632.354C1567.76 631.957 1568.63 631.748 1569.52 631.743C1567.69 630.398 1565.97 630.356 1565.07 627.837C1564.71 626.883 1564.44 625.9 1564.25 624.899C1564.09 623.888 1564.37 622.392 1563.83 621.603L1564.04 622.035Z'
                opacity='.7' />
          <path id='Vector_1139' fill='white'
                d='M1565.18 630.422C1564.74 629.007 1564.27 629.171 1563.86 629.776C1561.81 628.753 1562.11 635.723 1564.5 633.961C1565.3 633.365 1565.45 631.298 1565.18 630.422Z' />
          <path id='Vector_1140' fill='white'
                d='M1528.69 613.462C1528.43 615.6 1527.53 617.61 1526.13 619.24C1524.72 620.869 1522.86 622.044 1520.78 622.617C1518.71 623.189 1516.51 623.133 1514.46 622.455C1512.42 621.778 1510.62 620.51 1509.3 618.811C1507.97 617.112 1507.19 615.058 1507.03 612.911C1506.87 610.763 1507.36 608.617 1508.42 606.744C1509.48 604.871 1511.08 603.356 1513 602.389C1514.93 601.423 1517.09 601.049 1519.23 601.315C1522.1 601.671 1524.7 603.151 1526.48 605.429C1528.25 607.707 1529.05 610.597 1528.69 613.462Z'
                opacity='.1' />
          <path id='Vector_1141' fill='white'
                d='M1527.31 613.118C1527.11 614.704 1526.45 616.195 1525.4 617.403C1524.36 618.612 1522.98 619.484 1521.44 619.908C1519.9 620.332 1518.27 620.291 1516.75 619.788C1515.23 619.285 1513.9 618.344 1512.92 617.084C1511.94 615.823 1511.35 614.3 1511.24 612.707C1511.12 611.113 1511.48 609.521 1512.27 608.132C1513.06 606.742 1514.24 605.618 1515.67 604.901C1517.09 604.184 1518.7 603.906 1520.29 604.103C1521.34 604.234 1522.36 604.57 1523.28 605.094C1524.2 605.618 1525.01 606.319 1525.67 607.156C1526.32 607.993 1526.8 608.95 1527.08 609.974C1527.36 610.997 1527.44 612.065 1527.31 613.118Z'
                opacity='.2' />
          <path id='Vector_1142' fill='white'
                d='M1519.9 606.45C1519.24 608.814 1519.21 611.883 1514.73 611.713C1515.96 612.464 1517.36 613.159 1518.13 614.46C1518.83 615.802 1519.21 617.287 1519.21 618.793C1519.64 617.423 1519.47 616.075 1520.55 614.754C1520.94 614.264 1521.44 613.867 1522.02 613.592C1522.59 613.317 1523.22 613.173 1523.86 613.169C1522.54 612.238 1521.29 612.208 1520.64 610.464C1520.39 609.804 1520.19 609.124 1520.06 608.43C1519.93 607.731 1520.14 606.696 1519.75 606.149L1519.9 606.45Z'
                opacity='.7' />
          <path id='Vector_1143' fill='white'
                d='M1561.03 572.68C1560.83 574.196 1560.17 575.623 1559.12 576.78C1558.08 577.936 1556.7 578.77 1555.16 579.176C1553.62 579.583 1551.99 579.543 1550.47 579.062C1548.95 578.582 1547.62 577.682 1546.64 576.476C1545.66 575.27 1545.07 573.813 1544.96 572.289C1544.84 570.764 1545.2 569.241 1545.99 567.912C1546.78 566.583 1547.96 565.508 1549.39 564.822C1550.81 564.136 1552.42 563.87 1554.01 564.059C1556.13 564.312 1558.07 565.362 1559.39 566.979C1560.7 568.595 1561.29 570.646 1561.03 572.68Z'
                opacity='.1' />
          <path id='Vector_1144' fill='white'
                d='M1559.64 572.42C1559.49 573.523 1559 574.561 1558.23 575.402C1557.46 576.243 1556.44 576.849 1555.3 577.145C1554.16 577.44 1552.96 577.411 1551.84 577.062C1550.72 576.712 1549.73 576.057 1549 575.18C1548.28 574.303 1547.85 573.244 1547.76 572.135C1547.67 571.026 1547.94 569.919 1548.52 568.952C1549.11 567.986 1549.98 567.203 1551.04 566.705C1552.09 566.206 1553.28 566.013 1554.45 566.15C1555.23 566.241 1555.98 566.476 1556.66 566.84C1557.35 567.204 1557.94 567.692 1558.43 568.274C1558.91 568.856 1559.26 569.522 1559.47 570.233C1559.68 570.945 1559.74 571.688 1559.64 572.42Z'
                opacity='.2' />
          <path id='Vector_1145' fill='white'
                d='M1554.14 567.728C1553.68 569.436 1553.66 571.652 1550.55 571.531C1551.41 572.073 1552.38 572.575 1552.91 573.515C1553.4 574.484 1553.65 575.556 1553.66 576.645C1553.95 575.655 1553.84 574.682 1554.58 573.727C1554.85 573.373 1555.2 573.086 1555.6 572.888C1556 572.69 1556.43 572.585 1556.88 572.582C1555.96 571.91 1555.1 571.888 1554.65 570.629C1554.47 570.152 1554.34 569.66 1554.24 569.16C1554.16 568.654 1554.3 567.907 1554.03 567.512L1554.14 567.728Z'
                opacity='.7' />
          <path id='Vector_1146' fill='white'
                d='M1520.25 612.001C1519.92 610.788 1519.57 610.93 1519.26 611.449C1517.73 610.573 1517.95 616.545 1519.74 615.035C1520.35 614.522 1520.46 612.751 1520.25 612.001Z' />
        </g>;


      case 5:
        return <g id='Mini gem_19'>
          <path id='Vector_1147' fill={`#${diamondColors[color][0]}`}
                d='M1424.25 525.322L1462 560.541L1433.92 586.837L1424.25 526.58L1388.24 567.444L1427.74 625.074L1433.92 586.837L1424.25 525.322Z' />
          <path id='Vector_1148' fill={`#${diamondColors[color][1]}`}
                d='M1424.18 525.322L1388.24 567.242L1433.9 587.14L1424.18 525.322Z' />
          <path id='Vector_1149' fill='white'
                d='M1424.18 525.322L1423.18 526.492L1432.35 584.822L1389.27 566.047L1388.24 567.242L1433.9 587.14L1424.18 525.322Z' />
          <path id='Vector_1150' fill={`#${diamondColors[color][2]}`}
                d='M1433.79 586.781L1427.58 625.074L1462 560.446L1433.79 586.781Z' />
          <path id='Vector_1151' fill={`#${diamondColors[color][2]}`}
                d='M1433.79 586.781L1427.58 625.074L1462 560.446L1433.79 586.781Z' />
          <path id='Vector_1152' fill='white' d='M1388.24 567.471L1428.98 555.151L1424.3 525.322L1388.24 567.471Z'
                opacity='.2' />
          <path id='Vector_1153' fill='white' d='M1428.76 555.052L1462 560.446L1424.07 525.322L1428.76 554.508V555.052Z'
                opacity='.2' />
          <path id='Vector_1154' fill={`#${diamondColors[color][3]}`}
                d='M1431.21 602.729C1431.28 602.313 1451.33 570.745 1451.33 570.745L1462 561.149L1437.78 605.976L1427.58 625.074L1431.21 602.729Z'
                opacity='.5' />
          <path id='Vector_1155' fill={`#${diamondColors[color][3]}`}
                d='M1435 586.782L1460.8 562.702L1462 560.446L1433.79 586.782L1427.58 625.074L1429.32 621.795L1435 586.782Z' />
          <path id='Vector_1156' fill={`#${diamondColors[color][3]}`}
                d='M1401.53 586.872L1433.2 587.14L1388.24 567.471L1401.53 586.872Z' opacity='.5' />
          <path id='Vector_1157' fill='white'
                d='M1390.35 566.74C1391.73 568.16 1395.14 568.361 1397.07 568.731C1401.09 569.6 1405.23 569.747 1409.31 569.167C1414.24 568.372 1418.11 566.202 1421.94 563.15C1424.45 561.157 1425.43 559.198 1426.48 556.364C1426.79 555.641 1427.04 554.891 1427.21 554.124C1428.47 554.793 1428.31 559.067 1428.67 560.49C1429.38 563.289 1430.15 566.008 1430.45 568.87C1431 573.606 1431.8 578.31 1432.84 582.965C1433.04 583.918 1433.66 585.15 1432.57 585.629C1431.71 586.011 1430.19 585.261 1429.44 584.99C1427.24 584.185 1425.54 582.799 1423.42 581.799C1421.37 580.827 1419.19 580.308 1417.11 579.336C1414.91 578.306 1412.76 577.374 1410.65 576.181C1407.41 574.353 1403.82 572.925 1400.43 571.496C1398.73 570.778 1396.95 570.1 1395.33 569.361C1394.05 568.775 1391.19 568.1 1390.48 566.871L1390.35 566.74Z'
                opacity='.4' />
          <path id='Vector_1158' fill='white'
                d='M1440.04 598.202C1447.19 593.522 1449.19 583.935 1444.51 576.788C1439.83 569.642 1430.24 567.641 1423.09 572.32C1415.95 576.999 1413.95 586.586 1418.63 593.733C1423.3 600.88 1432.89 602.881 1440.04 598.202Z'
                opacity='.1' />
          <path id='Vector_1159' fill='white'
                d='M1444.35 586.112C1444.07 588.387 1443.12 590.526 1441.62 592.261C1440.12 593.995 1438.14 595.246 1435.93 595.856C1433.72 596.465 1431.38 596.405 1429.2 595.684C1427.02 594.963 1425.11 593.613 1423.7 591.804C1422.29 589.995 1421.45 587.81 1421.29 585.523C1421.12 583.237 1421.64 580.953 1422.77 578.959C1423.9 576.965 1425.6 575.352 1427.65 574.324C1429.69 573.295 1432 572.897 1434.28 573.179C1437.33 573.559 1440.1 575.134 1441.99 577.559C1443.88 579.985 1444.73 583.061 1444.35 586.112Z'
                opacity='.2' />
          <path id='Vector_1160' fill='white'
                d='M1434.04 577.035C1433.13 580.451 1433.08 584.883 1426.88 584.64C1428.59 585.725 1430.53 586.729 1431.58 588.608C1432.56 590.547 1433.08 592.691 1433.08 594.868C1433.68 592.888 1433.44 590.942 1434.94 589.034C1435.48 588.325 1436.17 587.751 1436.97 587.354C1437.76 586.957 1438.63 586.748 1439.52 586.743C1437.69 585.398 1435.97 585.356 1435.07 582.837C1434.71 581.883 1434.44 580.9 1434.25 579.899C1434.09 578.888 1434.37 577.392 1433.83 576.603L1434.04 577.035Z'
                opacity='.7' />
          <path id='Vector_1161' fill='white'
                d='M1435.18 585.422C1434.74 584.007 1434.27 584.171 1433.86 584.776C1431.81 583.753 1432.11 590.723 1434.5 588.961C1435.3 588.365 1435.45 586.298 1435.18 585.422Z' />
          <path id='Vector_1162' fill='white'
                d='M1398.69 568.462C1398.43 570.6 1397.53 572.61 1396.13 574.24C1394.72 575.869 1392.86 577.044 1390.78 577.617C1388.71 578.189 1386.51 578.133 1384.46 577.455C1382.42 576.778 1380.62 575.51 1379.3 573.811C1377.97 572.112 1377.19 570.058 1377.03 567.911C1376.87 565.763 1377.36 563.617 1378.42 561.744C1379.48 559.871 1381.08 558.356 1383 557.389C1384.93 556.423 1387.09 556.049 1389.23 556.315C1392.1 556.671 1394.7 558.151 1396.48 560.429C1398.25 562.707 1399.05 565.597 1398.69 568.462Z'
                opacity='.1' />
          <path id='Vector_1163' fill='white'
                d='M1397.31 568.118C1397.11 569.704 1396.45 571.195 1395.4 572.403C1394.36 573.612 1392.98 574.484 1391.44 574.908C1389.9 575.332 1388.27 575.291 1386.75 574.788C1385.24 574.285 1383.9 573.344 1382.92 572.084C1381.94 570.823 1381.35 569.3 1381.24 567.707C1381.12 566.113 1381.48 564.521 1382.27 563.132C1383.06 561.742 1384.24 560.618 1385.67 559.901C1387.09 559.184 1388.7 558.906 1390.29 559.103C1391.34 559.234 1392.36 559.57 1393.28 560.094C1394.2 560.618 1395.01 561.319 1395.67 562.156C1396.32 562.993 1396.8 563.95 1397.08 564.974C1397.36 565.997 1397.44 567.065 1397.31 568.118Z'
                opacity='.2' />
          <path id='Vector_1164' fill='white'
                d='M1389.9 561.45C1389.24 563.814 1389.21 566.883 1384.73 566.713C1385.96 567.464 1387.36 568.159 1388.13 569.46C1388.83 570.802 1389.21 572.287 1389.21 573.793C1389.64 572.423 1389.47 571.075 1390.55 569.754C1390.94 569.264 1391.44 568.867 1392.02 568.592C1392.59 568.317 1393.22 568.173 1393.86 568.169C1392.54 567.238 1391.29 567.208 1390.64 565.464C1390.39 564.804 1390.19 564.124 1390.06 563.43C1389.93 562.731 1390.14 561.696 1389.75 561.149L1389.9 561.45Z'
                opacity='.7' />
          <path id='Vector_1165' fill='white'
                d='M1431.03 527.68C1430.83 529.196 1430.17 530.623 1429.12 531.78C1428.08 532.936 1426.7 533.77 1425.16 534.176C1423.62 534.583 1421.99 534.543 1420.47 534.062C1418.95 533.582 1417.62 532.682 1416.64 531.476C1415.66 530.27 1415.07 528.813 1414.96 527.289C1414.84 525.764 1415.2 524.241 1415.99 522.912C1416.78 521.583 1417.96 520.508 1419.39 519.822C1420.81 519.136 1422.42 518.87 1424.01 519.059C1426.13 519.312 1428.07 520.362 1429.39 521.979C1430.7 523.595 1431.29 525.646 1431.03 527.68Z'
                opacity='.1' />
          <path id='Vector_1166' fill='white'
                d='M1429.64 527.42C1429.49 528.523 1429 529.561 1428.23 530.402C1427.46 531.243 1426.44 531.849 1425.3 532.145C1424.16 532.44 1422.96 532.411 1421.84 532.062C1420.72 531.712 1419.73 531.057 1419 530.18C1418.28 529.303 1417.85 528.244 1417.76 527.135C1417.67 526.026 1417.94 524.919 1418.52 523.952C1419.11 522.986 1419.98 522.203 1421.04 521.705C1422.09 521.206 1423.28 521.013 1424.45 521.15C1425.23 521.241 1425.98 521.476 1426.66 521.84C1427.35 522.204 1427.94 522.692 1428.43 523.274C1428.91 523.856 1429.26 524.522 1429.47 525.233C1429.68 525.945 1429.74 526.688 1429.64 527.42Z'
                opacity='.2' />
          <path id='Vector_1167' fill='white'
                d='M1424.14 522.728C1423.68 524.436 1423.66 526.652 1420.55 526.531C1421.41 527.073 1422.38 527.575 1422.91 528.515C1423.4 529.484 1423.65 530.556 1423.66 531.645C1423.95 530.655 1423.84 529.682 1424.58 528.727C1424.85 528.373 1425.2 528.086 1425.6 527.888C1426 527.69 1426.43 527.585 1426.88 527.582C1425.96 526.91 1425.1 526.888 1424.65 525.629C1424.47 525.152 1424.34 524.66 1424.24 524.16C1424.16 523.654 1424.3 522.907 1424.03 522.512L1424.14 522.728Z'
                opacity='.7' />
          <path id='Vector_1168' fill='white'
                d='M1390.25 567.001C1389.92 565.788 1389.57 565.93 1389.26 566.449C1387.73 565.573 1387.95 571.545 1389.74 570.035C1390.35 569.522 1390.46 567.751 1390.25 567.001Z' />
        </g>;


      case 6 :
        return <g id='Mini gem_20'>
          <path id='Vector_1169' fill={`#${diamondColors[color][0]}`}
                d='M1314.25 639.322L1352 674.541L1323.92 700.837L1314.25 640.58L1278.24 681.444L1317.74 739.074L1323.92 700.837L1314.25 639.322Z' />
          <path id='Vector_1170' fill={`#${diamondColors[color][1]}`}
                d='M1314.18 639.322L1278.24 681.242L1323.9 701.14L1314.18 639.322Z' />
          <path id='Vector_1171' fill='white'
                d='M1314.18 639.322L1313.18 640.492L1322.35 698.822L1279.27 680.047L1278.24 681.242L1323.9 701.14L1314.18 639.322Z' />
          <path id='Vector_1172' fill={`#${diamondColors[color][2]}`}
                d='M1323.79 700.781L1317.58 739.074L1352 674.446L1323.79 700.781Z' />
          <path id='Vector_1173' fill={`#${diamondColors[color][2]}`}
                d='M1323.79 700.781L1317.58 739.074L1352 674.446L1323.79 700.781Z' />
          <path id='Vector_1174' fill='white' d='M1278.24 681.471L1318.98 669.151L1314.3 639.322L1278.24 681.471Z'
                opacity='.2' />
          <path id='Vector_1175' fill='white' d='M1318.76 669.052L1352 674.446L1314.07 639.322L1318.76 668.508V669.052Z'
                opacity='.2' />
          <path id='Vector_1176' fill={`#${diamondColors[color][3]}`}
                d='M1321.21 716.729C1321.28 716.313 1341.33 684.745 1341.33 684.745L1352 675.149L1327.78 719.976L1317.58 739.074L1321.21 716.729Z'
                opacity='.5' />
          <path id='Vector_1177' fill={`#${diamondColors[color][3]}`}
                d='M1325 700.782L1350.8 676.702L1352 674.446L1323.79 700.782L1317.58 739.074L1319.32 735.795L1325 700.782Z' />
          <path id='Vector_1178' fill={`#${diamondColors[color][3]}`}
                d='M1291.53 700.872L1323.2 701.14L1278.24 681.471L1291.53 700.872Z' opacity='.5' />
          <path id='Vector_1179' fill='white'
                d='M1280.35 680.74C1281.73 682.16 1285.14 682.361 1287.07 682.731C1291.09 683.6 1295.23 683.747 1299.31 683.167C1304.24 682.372 1308.11 680.202 1311.94 677.15C1314.45 675.157 1315.43 673.198 1316.48 670.364C1316.79 669.641 1317.04 668.891 1317.21 668.124C1318.47 668.793 1318.31 673.067 1318.67 674.49C1319.38 677.289 1320.15 680.008 1320.45 682.87C1321 687.606 1321.8 692.31 1322.84 696.965C1323.04 697.918 1323.66 699.15 1322.57 699.629C1321.71 700.011 1320.19 699.261 1319.44 698.99C1317.24 698.185 1315.54 696.799 1313.42 695.799C1311.37 694.827 1309.19 694.308 1307.11 693.336C1304.91 692.306 1302.76 691.374 1300.65 690.181C1297.41 688.353 1293.82 686.925 1290.43 685.496C1288.73 684.778 1286.95 684.1 1285.33 683.361C1284.05 682.775 1281.19 682.1 1280.48 680.871L1280.35 680.74Z'
                opacity='.4' />
          <path id='Vector_1180' fill='white'
                d='M1330.04 712.202C1337.19 707.522 1339.19 697.935 1334.51 690.788C1329.83 683.642 1320.24 681.641 1313.09 686.32C1305.95 690.999 1303.95 700.586 1308.63 707.733C1313.3 714.88 1322.89 716.881 1330.04 712.202Z'
                opacity='.1' />
          <path id='Vector_1181' fill='white'
                d='M1334.35 700.112C1334.07 702.387 1333.12 704.526 1331.62 706.261C1330.12 707.995 1328.14 709.246 1325.93 709.856C1323.72 710.465 1321.38 710.405 1319.2 709.684C1317.02 708.963 1315.11 707.613 1313.7 705.804C1312.29 703.995 1311.45 701.81 1311.29 699.523C1311.12 697.237 1311.64 694.953 1312.77 692.959C1313.9 690.965 1315.6 689.352 1317.65 688.324C1319.69 687.295 1322 686.897 1324.28 687.179C1327.33 687.559 1330.1 689.134 1331.99 691.559C1333.88 693.985 1334.73 697.061 1334.35 700.112Z'
                opacity='.2' />
          <path id='Vector_1182' fill='white'
                d='M1324.04 691.035C1323.13 694.451 1323.08 698.883 1316.88 698.64C1318.59 699.725 1320.53 700.729 1321.58 702.608C1322.56 704.547 1323.08 706.691 1323.08 708.868C1323.68 706.888 1323.44 704.942 1324.94 703.034C1325.48 702.325 1326.17 701.751 1326.97 701.354C1327.76 700.957 1328.63 700.748 1329.52 700.743C1327.69 699.398 1325.97 699.356 1325.07 696.837C1324.71 695.883 1324.44 694.9 1324.25 693.899C1324.09 692.888 1324.37 691.392 1323.83 690.603L1324.04 691.035Z'
                opacity='.7' />
          <path id='Vector_1183' fill='white'
                d='M1325.18 699.422C1324.74 698.007 1324.27 698.171 1323.86 698.776C1321.81 697.753 1322.11 704.723 1324.5 702.961C1325.3 702.365 1325.45 700.298 1325.18 699.422Z' />
          <path id='Vector_1184' fill='white'
                d='M1288.69 682.462C1288.43 684.6 1287.53 686.61 1286.13 688.24C1284.72 689.869 1282.86 691.044 1280.78 691.617C1278.71 692.189 1276.51 692.133 1274.46 691.455C1272.42 690.778 1270.62 689.51 1269.3 687.811C1267.97 686.112 1267.19 684.058 1267.03 681.911C1266.87 679.763 1267.36 677.617 1268.42 675.744C1269.48 673.871 1271.08 672.356 1273 671.389C1274.93 670.423 1277.09 670.049 1279.23 670.315C1282.1 670.671 1284.7 672.151 1286.48 674.429C1288.25 676.707 1289.05 679.597 1288.69 682.462Z'
                opacity='.1' />
          <path id='Vector_1185' fill='white'
                d='M1287.31 682.118C1287.11 683.704 1286.45 685.195 1285.4 686.403C1284.36 687.612 1282.98 688.484 1281.44 688.908C1279.9 689.332 1278.27 689.291 1276.75 688.788C1275.24 688.285 1273.9 687.344 1272.92 686.084C1271.94 684.823 1271.35 683.3 1271.24 681.707C1271.12 680.113 1271.48 678.521 1272.27 677.132C1273.06 675.742 1274.24 674.618 1275.67 673.901C1277.09 673.184 1278.7 672.906 1280.29 673.103C1281.34 673.234 1282.36 673.57 1283.28 674.094C1284.2 674.618 1285.01 675.319 1285.67 676.156C1286.32 676.993 1286.8 677.95 1287.08 678.974C1287.36 679.997 1287.44 681.065 1287.31 682.118Z'
                opacity='.2' />
          <path id='Vector_1186' fill='white'
                d='M1279.9 675.45C1279.24 677.814 1279.21 680.883 1274.73 680.713C1275.96 681.464 1277.36 682.159 1278.13 683.46C1278.83 684.802 1279.21 686.287 1279.21 687.793C1279.64 686.423 1279.47 685.075 1280.55 683.754C1280.94 683.264 1281.44 682.867 1282.02 682.592C1282.59 682.317 1283.22 682.173 1283.86 682.169C1282.54 681.238 1281.29 681.208 1280.64 679.464C1280.39 678.804 1280.19 678.124 1280.06 677.43C1279.93 676.731 1280.14 675.696 1279.75 675.149L1279.9 675.45Z'
                opacity='.7' />
          <path id='Vector_1187' fill='white'
                d='M1321.03 641.68C1320.83 643.196 1320.17 644.623 1319.12 645.78C1318.08 646.936 1316.7 647.77 1315.16 648.176C1313.62 648.583 1311.99 648.543 1310.47 648.062C1308.95 647.582 1307.62 646.682 1306.64 645.476C1305.66 644.27 1305.07 642.813 1304.96 641.289C1304.84 639.764 1305.2 638.241 1305.99 636.912C1306.78 635.583 1307.96 634.508 1309.39 633.822C1310.81 633.136 1312.42 632.87 1314.01 633.059C1316.13 633.312 1318.07 634.362 1319.39 635.979C1320.7 637.595 1321.29 639.646 1321.03 641.68Z'
                opacity='.1' />
          <path id='Vector_1188' fill='white'
                d='M1319.64 641.42C1319.49 642.523 1319 643.561 1318.23 644.402C1317.46 645.243 1316.44 645.849 1315.3 646.145C1314.16 646.44 1312.96 646.411 1311.84 646.062C1310.72 645.712 1309.73 645.057 1309 644.18C1308.28 643.303 1307.85 642.244 1307.76 641.135C1307.67 640.026 1307.94 638.919 1308.52 637.952C1309.11 636.986 1309.98 636.203 1311.04 635.705C1312.09 635.206 1313.28 635.013 1314.45 635.15C1315.23 635.241 1315.98 635.476 1316.66 635.84C1317.35 636.204 1317.94 636.692 1318.43 637.274C1318.91 637.856 1319.26 638.522 1319.47 639.233C1319.68 639.945 1319.74 640.688 1319.64 641.42Z'
                opacity='.2' />
          <path id='Vector_1189' fill='white'
                d='M1314.14 636.728C1313.68 638.436 1313.66 640.652 1310.55 640.531C1311.41 641.073 1312.38 641.575 1312.91 642.515C1313.4 643.484 1313.65 644.556 1313.66 645.645C1313.95 644.655 1313.84 643.682 1314.58 642.727C1314.85 642.373 1315.2 642.086 1315.6 641.888C1316 641.69 1316.43 641.585 1316.88 641.582C1315.96 640.91 1315.1 640.888 1314.65 639.629C1314.47 639.152 1314.34 638.66 1314.24 638.16C1314.16 637.654 1314.3 636.907 1314.03 636.512L1314.14 636.728Z'
                opacity='.7' />
          <path id='Vector_1190' fill='white'
                d='M1280.25 681.001C1279.92 679.788 1279.57 679.93 1279.26 680.449C1277.73 679.573 1277.95 685.545 1279.74 684.035C1280.35 683.522 1280.46 681.751 1280.25 681.001Z' />
        </g>;

      case 7 :
        return <g id='Mini gem_21'>
          <path id='Vector_1191' fill={`#${diamondColors[color][0]}`}
                d='M1218.36 416.81L1265 460.315L1230.31 492.799L1218.36 418.363L1173.88 468.843L1222.68 540.033L1230.31 492.799L1218.36 416.81Z' />
          <path id='Vector_1192' fill={`#${diamondColors[color][1]}`}
                d='M1218.29 416.81L1173.88 468.593L1230.29 493.174L1218.29 416.81Z' />
          <path id='Vector_1193' fill='white'
                d='M1218.29 416.81L1217.05 418.254L1228.37 490.31L1175.15 467.117L1173.88 468.593L1230.29 493.174L1218.29 416.81Z' />
          <path id='Vector_1194' fill={`#${diamondColors[color][2]}`}
                d='M1230.15 492.729L1222.48 540.033L1265 460.198L1230.15 492.729Z' />
          <path id='Vector_1195' fill={`#${diamondColors[color][2]}`}
                d='M1230.15 492.729L1222.48 540.033L1265 460.198L1230.15 492.729Z' />
          <path id='Vector_1196' fill='white' d='M1173.88 468.876L1224.21 453.657L1218.43 416.81L1173.88 468.876Z'
                opacity='.2' />
          <path id='Vector_1197' fill='white' d='M1223.94 453.535L1265 460.198L1218.14 416.81L1223.94 452.862V453.535Z'
                opacity='.2' />
          <path id='Vector_1198' fill={`#${diamondColors[color][3]}`}
                d='M1226.96 512.43C1227.05 511.916 1251.83 472.92 1251.83 472.92L1265 461.066L1235.08 516.441L1222.48 540.033L1226.96 512.43Z'
                opacity='.5' />
          <path id='Vector_1199' fill={`#${diamondColors[color][3]}`}
                d='M1231.65 492.731L1263.52 462.984L1265 460.198L1230.15 492.731L1222.48 540.033L1224.64 535.982L1231.65 492.731Z' />
          <path id='Vector_1200' fill={`#${diamondColors[color][3]}`}
                d='M1190.3 492.841L1229.42 493.174L1173.88 468.876L1190.3 492.841Z' opacity='.5' />
          <path id='Vector_1201' fill='white'
                d='M1176.49 467.973C1178.2 469.728 1182.41 469.976 1184.79 470.433C1189.76 471.506 1194.88 471.688 1199.91 470.971C1206 469.989 1210.79 467.309 1215.52 463.538C1218.61 461.076 1219.83 458.656 1221.13 455.156C1221.51 454.262 1221.81 453.336 1222.03 452.388C1223.59 453.214 1223.38 458.494 1223.83 460.252C1224.7 463.71 1225.66 467.068 1226.02 470.604C1226.71 476.454 1227.7 482.266 1228.98 488.016C1229.23 489.193 1229.99 490.714 1228.65 491.307C1227.59 491.778 1225.7 490.852 1224.78 490.517C1222.07 489.523 1219.96 487.81 1217.35 486.575C1214.8 485.374 1212.12 484.734 1209.55 483.532C1206.83 482.26 1204.18 481.11 1201.57 479.635C1197.57 477.378 1193.13 475.613 1188.95 473.848C1186.85 472.961 1184.64 472.124 1182.64 471.21C1181.06 470.487 1177.53 469.653 1176.65 468.135L1176.49 467.973Z'
                opacity='.4' />
          <path id='Vector_1202' fill='white'
                d='M1237.87 506.837C1246.7 501.057 1249.17 489.214 1243.39 480.386C1237.61 471.557 1225.77 469.086 1216.94 474.866C1208.11 480.646 1205.64 492.489 1211.42 501.318C1217.2 510.146 1229.04 512.617 1237.87 506.837Z'
                opacity='.1' />
          <path id='Vector_1203' fill='white'
                d='M1243.2 491.903C1242.85 494.713 1241.67 497.356 1239.82 499.499C1237.97 501.641 1235.52 503.187 1232.79 503.939C1230.06 504.692 1227.17 504.619 1224.48 503.728C1221.8 502.837 1219.43 501.169 1217.69 498.934C1215.95 496.7 1214.91 494 1214.71 491.176C1214.5 488.352 1215.14 485.53 1216.54 483.067C1217.93 480.604 1220.03 478.612 1222.56 477.341C1225.09 476.07 1227.94 475.578 1230.75 475.928C1234.52 476.396 1237.95 478.342 1240.28 481.338C1242.62 484.334 1243.66 488.134 1243.2 491.903Z'
                opacity='.2' />
          <path id='Vector_1204' fill='white'
                d='M1230.46 480.691C1229.34 484.91 1229.27 490.385 1221.61 490.085C1223.73 491.425 1226.12 492.665 1227.43 494.987C1228.64 497.381 1229.27 500.03 1229.28 502.719C1230.01 500.274 1229.73 497.869 1231.57 495.512C1232.24 494.637 1233.1 493.928 1234.08 493.438C1235.06 492.947 1236.14 492.689 1237.23 492.682C1234.97 491.021 1232.84 490.969 1231.73 487.858C1231.29 486.679 1230.96 485.465 1230.73 484.228C1230.52 482.979 1230.87 481.132 1230.2 480.157L1230.46 480.691Z'
                opacity='.7' />
          <path id='Vector_1205' fill='white'
                d='M1231.87 491.051C1231.33 489.303 1230.74 489.506 1230.24 490.253C1227.71 488.989 1228.07 497.599 1231.02 495.422C1232.02 494.686 1232.21 492.133 1231.87 491.051Z' />
          <path id='Vector_1206' fill='white'
                d='M1186.8 470.101C1186.47 472.741 1185.37 475.224 1183.63 477.237C1181.89 479.25 1179.59 480.701 1177.03 481.409C1174.46 482.116 1171.74 482.047 1169.22 481.21C1166.69 480.373 1164.47 478.806 1162.84 476.707C1161.2 474.609 1160.23 472.072 1160.04 469.419C1159.84 466.766 1160.44 464.115 1161.75 461.801C1163.07 459.488 1165.04 457.616 1167.41 456.422C1169.79 455.228 1172.47 454.766 1175.11 455.094C1178.65 455.535 1181.87 457.363 1184.06 460.177C1186.25 462.991 1187.24 466.561 1186.8 470.101Z'
                opacity='.1' />
          <path id='Vector_1207' fill='white'
                d='M1185.09 469.675C1184.84 471.634 1184.03 473.476 1182.74 474.969C1181.44 476.462 1179.74 477.538 1177.84 478.063C1175.93 478.587 1173.92 478.536 1172.05 477.915C1170.17 477.294 1168.53 476.131 1167.31 474.574C1166.1 473.017 1165.38 471.135 1165.23 469.167C1165.09 467.199 1165.53 465.232 1166.51 463.516C1167.48 461.799 1168.94 460.411 1170.71 459.525C1172.47 458.639 1174.46 458.296 1176.42 458.539C1177.72 458.7 1178.97 459.116 1180.11 459.764C1181.25 460.411 1182.25 461.276 1183.06 462.31C1183.86 463.344 1184.46 464.527 1184.81 465.791C1185.16 467.055 1185.25 468.375 1185.09 469.675Z'
                opacity='.2' />
          <path id='Vector_1208' fill='white'
                d='M1175.94 461.438C1175.12 464.359 1175.08 468.149 1169.55 467.94C1171.07 468.867 1172.8 469.726 1173.74 471.333C1174.62 472.991 1175.08 474.825 1175.08 476.686C1175.61 474.993 1175.41 473.328 1176.74 471.697C1177.22 471.091 1177.84 470.6 1178.55 470.261C1179.26 469.921 1180.04 469.743 1180.83 469.738C1179.2 468.588 1177.65 468.551 1176.85 466.397C1176.54 465.581 1176.29 464.741 1176.13 463.885C1175.98 463.02 1176.23 461.742 1175.75 461.066L1175.94 461.438Z'
                opacity='.7' />
          <path id='Vector_1209' fill='white'
                d='M1226.74 419.722C1226.5 421.596 1225.68 423.358 1224.39 424.787C1223.1 426.215 1221.39 427.245 1219.49 427.747C1217.59 428.249 1215.57 428.2 1213.7 427.606C1211.83 427.013 1210.18 425.901 1208.97 424.411C1207.75 422.922 1207.03 421.122 1206.89 419.239C1206.74 417.356 1207.19 415.475 1208.16 413.833C1209.13 412.191 1210.6 410.862 1212.36 410.015C1214.12 409.168 1216.11 408.84 1218.07 409.073C1220.7 409.385 1223.09 410.683 1224.71 412.68C1226.34 414.677 1227.07 417.21 1226.74 419.722Z'
                opacity='.1' />
          <path id='Vector_1210' fill='white'
                d='M1225.03 419.401C1224.85 420.764 1224.24 422.046 1223.29 423.085C1222.33 424.124 1221.07 424.873 1219.67 425.238C1218.26 425.603 1216.77 425.567 1215.39 425.135C1214 424.703 1212.78 423.894 1211.89 422.811C1210.99 421.728 1210.46 420.419 1210.35 419.049C1210.24 417.68 1210.57 416.311 1211.29 415.117C1212.01 413.923 1213.09 412.957 1214.4 412.341C1215.7 411.725 1217.17 411.487 1218.62 411.656C1219.58 411.769 1220.51 412.058 1221.35 412.508C1222.19 412.958 1222.93 413.56 1223.53 414.279C1224.12 414.999 1224.56 415.821 1224.82 416.7C1225.07 417.579 1225.15 418.497 1225.03 419.401Z'
                opacity='.2' />
          <path id='Vector_1211' fill='white'
                d='M1218.23 413.605C1217.67 415.715 1217.63 418.453 1213.8 418.303C1214.86 418.973 1216.06 419.593 1216.71 420.753C1217.31 421.951 1217.63 423.275 1217.63 424.62C1218 423.397 1217.86 422.195 1218.78 421.016C1219.11 420.579 1219.54 420.224 1220.03 419.979C1220.53 419.734 1221.06 419.605 1221.61 419.602C1220.48 418.771 1219.42 418.745 1218.86 417.189C1218.64 416.6 1218.47 415.992 1218.36 415.374C1218.25 414.749 1218.43 413.826 1218.1 413.339L1218.23 413.605Z'
                opacity='.7' />
          <path id='Vector_1212' fill='white'
                d='M1176.37 468.295C1175.97 466.797 1175.53 466.972 1175.15 467.613C1173.25 466.531 1173.52 473.908 1175.74 472.043C1176.49 471.41 1176.62 469.222 1176.37 468.295Z' />
        </g>;


      case 8 :
        return <g id='Mini gem_22'>
          <path id='Vector_1213' fill={`#${diamondColors[color][0]}`}
                d='M968.364 474.81L1015 518.315L980.313 550.799L968.363 476.363L923.884 526.843L972.678 598.033L980.313 550.799L968.364 474.81Z' />
          <path id='Vector_1214' fill={`#${diamondColors[color][1]}`}
                d='M968.286 474.81L923.884 526.593L980.289 551.174L968.286 474.81Z' />
          <path id='Vector_1215' fill='white'
                d='M968.286 474.81L967.047 476.254L978.373 548.31L925.151 525.117L923.884 526.593L980.289 551.174L968.286 474.81Z' />
          <path id='Vector_1216' fill={`#${diamondColors[color][2]}`}
                d='M980.15 550.729L972.479 598.033L1015 518.198L980.15 550.729Z' />
          <path id='Vector_1217' fill={`#${diamondColors[color][2]}`}
                d='M980.15 550.729L972.479 598.033L1015 518.198L980.15 550.729Z' />
          <path id='Vector_1218' fill='white' d='M923.884 526.876L974.215 511.657L968.435 474.81L923.884 526.876Z'
                opacity='.2' />
          <path id='Vector_1219' fill='white' d='M973.939 511.535L1015 518.198L968.141 474.81L973.939 510.862V511.535Z'
                opacity='.2' />
          <path id='Vector_1220' fill={`#${diamondColors[color][3]}`}
                d='M976.963 570.43C977.046 569.916 1001.83 530.92 1001.83 530.92L1015 519.066L985.076 574.441L972.479 598.033L976.963 570.43Z'
                opacity='.5' />
          <path id='Vector_1221' fill={`#${diamondColors[color][3]}`}
                d='M981.65 550.731L1013.52 520.984L1015 518.198L980.15 550.731L972.479 598.033L974.636 593.982L981.65 550.731Z' />
          <path id='Vector_1222' fill={`#${diamondColors[color][3]}`}
                d='M940.297 550.841L979.422 551.174L923.884 526.876L940.297 550.841Z' opacity='.5' />
          <path id='Vector_1223' fill='white'
                d='M926.488 525.973C928.199 527.728 932.409 527.976 934.792 528.433C939.757 529.506 944.877 529.688 949.907 528.971C956.002 527.989 960.789 525.309 965.52 521.538C968.61 519.076 969.831 516.656 971.126 513.156C971.509 512.262 971.811 511.336 972.027 510.388C973.585 511.214 973.379 516.494 973.825 518.252C974.703 521.71 975.661 525.068 976.022 528.604C976.707 534.454 977.696 540.266 978.983 546.016C979.227 547.193 979.992 548.714 978.651 549.307C977.589 549.778 975.701 548.852 974.784 548.517C972.066 547.523 969.964 545.81 967.347 544.575C964.804 543.374 962.115 542.734 959.55 541.532C956.834 540.26 954.179 539.11 951.568 537.635C947.57 535.378 943.132 533.613 938.949 531.848C936.847 530.961 934.64 530.124 932.641 529.21C931.059 528.487 927.535 527.653 926.653 526.135L926.488 525.973Z'
                opacity='.4' />
          <path id='Vector_1224' fill='white'
                d='M987.871 564.837C996.699 559.057 999.17 547.214 993.39 538.386C987.61 529.557 975.767 527.086 966.939 532.866C958.11 538.646 955.639 550.489 961.419 559.318C967.199 568.146 979.042 570.617 987.871 564.837Z'
                opacity='.1' />
          <path id='Vector_1225' fill='white'
                d='M993.196 549.903C992.847 552.713 991.672 555.356 989.821 557.499C987.969 559.641 985.523 561.187 982.793 561.939C980.063 562.692 977.171 562.619 974.483 561.728C971.795 560.837 969.432 559.169 967.692 556.934C965.952 554.7 964.913 552 964.707 549.176C964.502 546.352 965.138 543.53 966.536 541.067C967.935 538.604 970.032 536.612 972.562 535.341C975.093 534.07 977.944 533.578 980.754 533.928C984.522 534.396 987.95 536.342 990.284 539.338C992.617 542.334 993.665 546.134 993.196 549.903Z'
                opacity='.2' />
          <path id='Vector_1226' fill='white'
                d='M980.465 538.691C979.337 542.91 979.272 548.385 971.612 548.085C973.728 549.425 976.122 550.665 977.426 552.987C978.637 555.381 979.271 558.03 979.278 560.719C980.012 558.274 979.725 555.869 981.568 553.512C982.238 552.637 983.096 551.928 984.077 551.438C985.058 550.947 986.137 550.689 987.231 550.682C984.975 549.021 982.839 548.969 981.729 545.858C981.291 544.679 980.956 543.465 980.726 542.228C980.518 540.979 980.871 539.132 980.2 538.157L980.465 538.691Z'
                opacity='.7' />
          <path id='Vector_1227' fill='white'
                d='M981.871 549.051C981.329 547.303 980.745 547.506 980.24 548.253C977.708 546.989 978.073 555.599 981.024 553.422C982.024 552.686 982.206 550.133 981.871 549.051Z' />
          <path id='Vector_1228' fill='white'
                d='M936.798 528.101C936.47 530.741 935.366 533.224 933.627 535.237C931.888 537.25 929.59 538.701 927.026 539.409C924.461 540.116 921.745 540.047 919.219 539.21C916.694 538.373 914.474 536.806 912.839 534.707C911.204 532.609 910.229 530.072 910.036 527.419C909.842 524.766 910.44 522.115 911.754 519.801C913.067 517.488 915.037 515.616 917.415 514.422C919.792 513.228 922.47 512.766 925.11 513.094C928.65 513.535 931.87 515.363 934.062 518.177C936.254 520.991 937.238 524.561 936.798 528.101Z'
                opacity='.1' />
          <path id='Vector_1229' fill='white'
                d='M935.089 527.675C934.845 529.634 934.026 531.476 932.735 532.969C931.444 534.462 929.74 535.538 927.837 536.063C925.935 536.587 923.919 536.536 922.046 535.915C920.173 535.294 918.526 534.131 917.313 532.574C916.1 531.017 915.376 529.135 915.233 527.167C915.09 525.199 915.533 523.232 916.507 521.516C917.482 519.799 918.943 518.411 920.707 517.525C922.47 516.639 924.457 516.296 926.415 516.539C927.716 516.7 928.972 517.116 930.112 517.764C931.252 518.411 932.254 519.276 933.059 520.31C933.865 521.344 934.458 522.527 934.807 523.791C935.155 525.055 935.251 526.375 935.089 527.675Z'
                opacity='.2' />
          <path id='Vector_1230' fill='white'
                d='M925.939 519.438C925.124 522.359 925.078 526.149 919.545 525.94C921.073 526.867 922.803 527.726 923.745 529.333C924.619 530.991 925.077 532.825 925.082 534.686C925.613 532.993 925.406 531.328 926.735 529.697C927.219 529.091 927.839 528.6 928.548 528.261C929.257 527.921 930.036 527.743 930.826 527.738C929.197 526.588 927.655 526.551 926.853 524.397C926.537 523.581 926.294 522.741 926.128 521.885C925.975 521.02 926.232 519.742 925.748 519.066L925.939 519.438Z'
                opacity='.7' />
          <path id='Vector_1231' fill='white'
                d='M976.742 477.722C976.499 479.596 975.68 481.358 974.39 482.787C973.099 484.215 971.395 485.245 969.492 485.747C967.589 486.249 965.574 486.2 963.7 485.606C961.826 485.013 960.179 483.901 958.966 482.411C957.753 480.922 957.029 479.122 956.886 477.239C956.743 475.356 957.186 473.475 958.16 471.833C959.135 470.191 960.597 468.862 962.36 468.015C964.124 467.168 966.111 466.84 968.07 467.073C970.696 467.385 973.085 468.683 974.712 470.68C976.338 472.677 977.068 475.21 976.742 477.722Z'
                opacity='.1' />
          <path id='Vector_1232' fill='white'
                d='M975.026 477.401C974.846 478.764 974.241 480.046 973.287 481.085C972.333 482.124 971.074 482.873 969.667 483.238C968.261 483.603 966.771 483.567 965.386 483.135C964.001 482.703 962.784 481.894 961.887 480.811C960.991 479.728 960.456 478.419 960.35 477.049C960.244 475.68 960.572 474.311 961.293 473.117C962.013 471.923 963.093 470.957 964.397 470.341C965.701 469.725 967.17 469.487 968.617 469.656C969.579 469.769 970.507 470.058 971.349 470.508C972.191 470.958 972.931 471.56 973.526 472.279C974.121 472.999 974.56 473.821 974.818 474.7C975.075 475.579 975.146 476.497 975.026 477.401Z'
                opacity='.2' />
          <path id='Vector_1233' fill='white'
                d='M968.23 471.605C967.665 473.715 967.633 476.453 963.802 476.303C964.86 476.973 966.057 477.593 966.709 478.753C967.314 479.951 967.632 481.275 967.635 482.62C968.002 481.397 967.859 480.195 968.78 479.016C969.115 478.579 969.544 478.224 970.035 477.979C970.525 477.734 971.064 477.605 971.612 477.602C970.484 476.771 969.415 476.745 968.86 475.189C968.641 474.6 968.474 473.992 968.359 473.374C968.253 472.749 968.431 471.826 968.096 471.339L968.23 471.605Z'
                opacity='.7' />
          <path id='Vector_1234' fill='white'
                d='M926.372 526.295C925.966 524.797 925.527 524.972 925.149 525.613C923.251 524.531 923.524 531.908 925.737 530.043C926.488 529.41 926.623 527.222 926.372 526.295Z' />
        </g>;


      case 9:
        return <g id='Mini gem_23'>
          <path id='Vector_1235' fill={`#${diamondColors[color][0]}`}
                d='M975.364 740.81L1022 784.315L987.312 816.799L975.363 742.363L930.884 792.843L979.678 864.033L987.312 816.799L975.364 740.81Z' />
          <path id='Vector_1236' fill={`#${diamondColors[color][1]}`}
                d='M975.286 740.81L930.884 792.593L987.289 817.174L975.286 740.81Z' />
          <path id='Vector_1237' fill='white'
                d='M975.286 740.81L974.047 742.254L985.373 814.31L932.151 791.117L930.884 792.593L987.289 817.174L975.286 740.81Z' />
          <path id='Vector_1238' fill={`#${diamondColors[color][2]}`}
                d='M987.15 816.729L979.479 864.033L1022 784.198L987.15 816.729Z' />
          <path id='Vector_1239' fill={`#${diamondColors[color][2]}`}
                d='M987.15 816.729L979.479 864.033L1022 784.198L987.15 816.729Z' />
          <path id='Vector_1240' fill='white' d='M930.884 792.876L981.215 777.657L975.435 740.81L930.884 792.876Z'
                opacity='.2' />
          <path id='Vector_1241' fill='white' d='M980.938 777.535L1022 784.198L975.14 740.81L980.938 776.862V777.535Z'
                opacity='.2' />
          <path id='Vector_1242' fill={`#${diamondColors[color][3]}`}
                d='M983.963 836.43C984.046 835.916 1008.83 796.92 1008.83 796.92L1022 785.066L992.076 840.441L979.479 864.033L983.963 836.43Z'
                opacity='.5' />
          <path id='Vector_1243' fill={`#${diamondColors[color][3]}`}
                d='M988.65 816.731L1020.52 786.984L1022 784.198L987.15 816.731L979.479 864.033L981.636 859.982L988.65 816.731Z' />
          <path id='Vector_1244' fill={`#${diamondColors[color][3]}`}
                d='M947.297 816.841L986.421 817.174L930.884 792.876L947.297 816.841Z' opacity='.5' />
          <path id='Vector_1245' fill='white'
                d='M933.488 791.973C935.199 793.728 939.409 793.976 941.792 794.433C946.757 795.506 951.877 795.688 956.907 794.971C963.001 793.989 967.789 791.309 972.52 787.538C975.61 785.076 976.831 782.656 978.126 779.156C978.509 778.262 978.811 777.336 979.027 776.388C980.585 777.214 980.379 782.494 980.825 784.252C981.703 787.71 982.661 791.068 983.022 794.604C983.707 800.454 984.696 806.266 985.983 812.016C986.227 813.193 986.992 814.715 985.651 815.307C984.589 815.778 982.701 814.852 981.784 814.517C979.066 813.523 976.964 811.811 974.347 810.575C971.804 809.374 969.115 808.734 966.549 807.532C963.834 806.26 961.179 805.11 958.568 803.635C954.57 801.378 950.132 799.613 945.949 797.848C943.847 796.961 941.64 796.124 939.641 795.21C938.059 794.487 934.535 793.653 933.653 792.135L933.488 791.973Z'
                opacity='.4' />
          <path id='Vector_1246' fill='white'
                d='M994.871 830.837C1003.7 825.057 1006.17 813.214 1000.39 804.386C994.61 795.557 982.767 793.086 973.939 798.866C965.11 804.646 962.639 816.489 968.419 825.318C974.199 834.146 986.042 836.617 994.871 830.837Z'
                opacity='.1' />
          <path id='Vector_1247' fill='white'
                d='M1000.2 815.903C999.847 818.713 998.672 821.356 996.821 823.499C994.969 825.641 992.523 827.187 989.793 827.939C987.063 828.692 984.171 828.619 981.483 827.728C978.795 826.837 976.432 825.169 974.692 822.934C972.951 820.7 971.913 818 971.707 815.176C971.502 812.352 972.138 809.53 973.536 807.067C974.935 804.604 977.032 802.612 979.562 801.341C982.093 800.07 984.944 799.578 987.754 799.928C991.522 800.396 994.95 802.342 997.284 805.338C999.617 808.334 1000.66 812.134 1000.2 815.903Z'
                opacity='.2' />
          <path id='Vector_1248' fill='white'
                d='M987.465 804.691C986.337 808.91 986.272 814.385 978.612 814.085C980.728 815.425 983.122 816.665 984.426 818.987C985.637 821.381 986.271 824.03 986.278 826.719C987.012 824.274 986.725 821.869 988.568 819.512C989.238 818.637 990.096 817.928 991.077 817.438C992.058 816.947 993.137 816.689 994.231 816.682C991.975 815.021 989.839 814.969 988.729 811.858C988.291 810.679 987.956 809.465 987.726 808.228C987.518 806.979 987.871 805.132 987.2 804.157L987.465 804.691Z'
                opacity='.7' />
          <path id='Vector_1249' fill='white'
                d='M988.871 815.051C988.329 813.303 987.745 813.506 987.24 814.253C984.708 812.989 985.073 821.599 988.024 819.422C989.024 818.686 989.206 816.133 988.871 815.051Z' />
          <path id='Vector_1250' fill='white'
                d='M943.798 794.101C943.47 796.741 942.366 799.224 940.627 801.237C938.888 803.25 936.59 804.702 934.026 805.409C931.461 806.116 928.744 806.047 926.219 805.21C923.694 804.373 921.474 802.806 919.839 800.707C918.204 798.609 917.229 796.072 917.036 793.419C916.842 790.766 917.44 788.115 918.754 785.801C920.067 783.488 922.037 781.616 924.415 780.422C926.792 779.228 929.47 778.766 932.11 779.094C935.65 779.535 938.87 781.363 941.062 784.177C943.253 786.991 944.238 790.561 943.798 794.101Z'
                opacity='.1' />
          <path id='Vector_1251' fill='white'
                d='M942.089 793.675C941.845 795.634 941.026 797.476 939.735 798.969C938.444 800.462 936.74 801.539 934.837 802.063C932.935 802.587 930.919 802.536 929.046 801.915C927.173 801.294 925.526 800.131 924.313 798.574C923.1 797.017 922.376 795.135 922.233 793.167C922.09 791.199 922.533 789.232 923.507 787.516C924.482 785.799 925.943 784.411 927.706 783.525C929.47 782.639 931.457 782.296 933.415 782.539C934.716 782.7 935.972 783.116 937.112 783.764C938.252 784.411 939.254 785.276 940.059 786.31C940.865 787.344 941.458 788.527 941.807 789.791C942.155 791.055 942.251 792.375 942.089 793.675Z'
                opacity='.2' />
          <path id='Vector_1252' fill='white'
                d='M932.939 785.438C932.124 788.359 932.078 792.149 926.545 791.94C928.073 792.867 929.803 793.726 930.745 795.333C931.619 796.991 932.077 798.825 932.082 800.686C932.613 798.993 932.406 797.328 933.735 795.697C934.219 795.091 934.839 794.6 935.548 794.261C936.257 793.921 937.036 793.743 937.826 793.738C936.197 792.588 934.655 792.551 933.853 790.397C933.537 789.581 933.294 788.741 933.128 787.885C932.975 787.02 933.232 785.742 932.748 785.066L932.939 785.438Z'
                opacity='.7' />
          <path id='Vector_1253' fill='white'
                d='M983.742 743.722C983.499 745.596 982.68 747.358 981.389 748.787C980.099 750.215 978.395 751.245 976.492 751.747C974.589 752.249 972.573 752.2 970.7 751.606C968.826 751.013 967.179 749.901 965.966 748.411C964.753 746.922 964.029 745.122 963.886 743.239C963.742 741.356 964.186 739.475 965.16 737.833C966.135 736.191 967.596 734.862 969.36 734.015C971.124 733.168 973.111 732.84 975.07 733.073C977.696 733.385 980.085 734.683 981.712 736.68C983.338 738.677 984.068 741.21 983.742 743.722Z'
                opacity='.1' />
          <path id='Vector_1254' fill='white'
                d='M982.026 743.401C981.846 744.764 981.241 746.046 980.287 747.085C979.333 748.124 978.074 748.873 976.667 749.238C975.261 749.603 973.771 749.567 972.386 749.135C971.001 748.703 969.784 747.894 968.887 746.811C967.991 745.728 967.456 744.419 967.35 743.049C967.244 741.68 967.572 740.311 968.293 739.117C969.013 737.923 970.093 736.957 971.397 736.341C972.701 735.725 974.17 735.487 975.617 735.656C976.579 735.769 977.507 736.058 978.349 736.508C979.191 736.958 979.931 737.56 980.526 738.279C981.121 738.999 981.56 739.821 981.818 740.7C982.075 741.579 982.146 742.497 982.026 743.401Z'
                opacity='.2' />
          <path id='Vector_1255' fill='white'
                d='M975.229 737.605C974.665 739.715 974.633 742.453 970.802 742.303C971.86 742.973 973.057 743.593 973.709 744.753C974.314 745.951 974.632 747.275 974.635 748.62C975.002 747.397 974.859 746.195 975.78 745.016C976.115 744.579 976.544 744.224 977.035 743.979C977.525 743.734 978.064 743.605 978.612 743.602C977.483 742.771 976.415 742.745 975.86 741.189C975.641 740.6 975.474 739.992 975.359 739.374C975.253 738.75 975.431 737.826 975.096 737.339L975.229 737.605Z'
                opacity='.7' />
          <path id='Vector_1256' fill='white'
                d='M933.372 792.295C932.966 790.797 932.527 790.972 932.149 791.613C930.251 790.531 930.524 797.908 932.737 796.043C933.488 795.41 933.623 793.222 933.372 792.295Z' />
        </g>;


      case 10:
        return <g id='Mini gem_24'>
          <path id='Vector_1257' fill={`#${diamondColors[color][0]}`}
                d='M797.364 652.81L844 696.315L809.312 728.799L797.363 654.363L752.884 704.843L801.678 776.033L809.312 728.799L797.364 652.81Z' />
          <path id='Vector_1258' fill={`#${diamondColors[color][1]}`}
                d='M797.286 652.81L752.884 704.593L809.289 729.174L797.286 652.81Z' />
          <path id='Vector_1259' fill='white'
                d='M797.286 652.81L796.047 654.254L807.373 726.31L754.151 703.117L752.884 704.593L809.289 729.174L797.286 652.81Z' />
          <path id='Vector_1260' fill={`#${diamondColors[color][2]}`}
                d='M809.15 728.729L801.479 776.033L844 696.198L809.15 728.729Z' />
          <path id='Vector_1261' fill={`#${diamondColors[color][2]}`}
                d='M809.15 728.729L801.479 776.033L844 696.198L809.15 728.729Z' />
          <path id='Vector_1262' fill='white' d='M752.884 704.876L803.215 689.657L797.435 652.81L752.884 704.876Z'
                opacity='.2' />
          <path id='Vector_1263' fill='white' d='M802.938 689.535L844 696.198L797.141 652.81L802.938 688.862V689.535Z'
                opacity='.2' />
          <path id='Vector_1264' fill={`#${diamondColors[color][3]}`}
                d='M805.963 748.43C806.046 747.916 830.825 708.92 830.825 708.92L844 697.066L814.076 752.441L801.479 776.033L805.963 748.43Z'
                opacity='.5' />
          <path id='Vector_1265' fill={`#${diamondColors[color][3]}`}
                d='M810.65 728.731L842.516 698.984L844 696.198L809.15 728.731L801.479 776.033L803.636 771.982L810.65 728.731Z' />
          <path id='Vector_1266' fill={`#${diamondColors[color][3]}`}
                d='M769.297 728.841L808.421 729.174L752.884 704.876L769.297 728.841Z' opacity='.5' />
          <path id='Vector_1267' fill='white'
                d='M755.488 703.973C757.199 705.728 761.409 705.976 763.792 706.433C768.757 707.506 773.877 707.688 778.907 706.971C785.001 705.989 789.789 703.309 794.52 699.538C797.61 697.076 798.831 694.656 800.126 691.156C800.509 690.262 800.811 689.336 801.027 688.388C802.585 689.214 802.379 694.494 802.825 696.252C803.703 699.71 804.661 703.068 805.022 706.604C805.707 712.454 806.696 718.266 807.983 724.016C808.227 725.193 808.992 726.714 807.651 727.307C806.589 727.778 804.701 726.852 803.784 726.517C801.066 725.523 798.964 723.81 796.347 722.575C793.804 721.374 791.115 720.734 788.549 719.532C785.834 718.26 783.179 717.11 780.568 715.635C776.57 713.378 772.132 711.613 767.949 709.848C765.847 708.961 763.64 708.124 761.641 707.21C760.059 706.487 756.535 705.653 755.653 704.135L755.488 703.973Z'
                opacity='.4' />
          <path id='Vector_1268' fill='white'
                d='M816.871 742.837C825.699 737.057 828.17 725.214 822.39 716.386C816.61 707.557 804.767 705.086 795.939 710.866C787.11 716.646 784.639 728.489 790.419 737.318C796.199 746.146 808.042 748.617 816.871 742.837Z'
                opacity='.1' />
          <path id='Vector_1269' fill='white'
                d='M822.196 727.903C821.847 730.713 820.672 733.356 818.821 735.499C816.969 737.641 814.523 739.187 811.793 739.939C809.063 740.692 806.171 740.619 803.483 739.728C800.795 738.837 798.432 737.169 796.692 734.934C794.951 732.7 793.913 730 793.707 727.176C793.502 724.352 794.138 721.53 795.536 719.067C796.935 716.604 799.032 714.612 801.562 713.341C804.093 712.07 806.944 711.578 809.754 711.928C813.522 712.396 816.95 714.342 819.284 717.338C821.617 720.334 822.665 724.134 822.196 727.903Z'
                opacity='.2' />
          <path id='Vector_1270' fill='white'
                d='M809.465 716.691C808.337 720.91 808.272 726.385 800.612 726.085C802.728 727.425 805.122 728.665 806.426 730.987C807.637 733.381 808.271 736.03 808.278 738.719C809.012 736.274 808.725 733.869 810.568 731.512C811.238 730.637 812.096 729.928 813.077 729.438C814.058 728.947 815.137 728.689 816.231 728.682C813.975 727.021 811.839 726.969 810.729 723.858C810.291 722.679 809.956 721.465 809.726 720.228C809.518 718.979 809.871 717.132 809.2 716.157L809.465 716.691Z'
                opacity='.7' />
          <path id='Vector_1271' fill='white'
                d='M810.871 727.051C810.329 725.303 809.745 725.506 809.24 726.253C806.708 724.989 807.073 733.599 810.024 731.422C811.024 730.686 811.206 728.133 810.871 727.051Z' />
          <path id='Vector_1272' fill='white'
                d='M765.798 706.101C765.47 708.741 764.366 711.224 762.627 713.237C760.888 715.25 758.59 716.701 756.026 717.409C753.461 718.116 750.744 718.047 748.219 717.21C745.694 716.373 743.474 714.806 741.839 712.707C740.204 710.609 739.229 708.072 739.036 705.419C738.842 702.766 739.44 700.115 740.754 697.801C742.067 695.488 744.037 693.616 746.415 692.422C748.792 691.228 751.47 690.766 754.11 691.094C757.65 691.535 760.87 693.363 763.062 696.177C765.253 698.991 766.238 702.561 765.798 706.101Z'
                opacity='.1' />
          <path id='Vector_1273' fill='white'
                d='M764.089 705.675C763.845 707.634 763.026 709.476 761.735 710.969C760.444 712.462 758.74 713.538 756.837 714.063C754.935 714.587 752.919 714.536 751.046 713.915C749.173 713.294 747.526 712.131 746.313 710.574C745.1 709.017 744.376 707.135 744.233 705.167C744.09 703.199 744.533 701.232 745.507 699.516C746.482 697.799 747.943 696.411 749.706 695.525C751.47 694.639 753.457 694.296 755.415 694.539C756.716 694.7 757.972 695.116 759.112 695.764C760.252 696.411 761.254 697.276 762.059 698.31C762.865 699.344 763.458 700.527 763.807 701.791C764.155 703.055 764.251 704.375 764.089 705.675Z'
                opacity='.2' />
          <path id='Vector_1274' fill='white'
                d='M754.939 697.438C754.124 700.359 754.078 704.149 748.545 703.94C750.073 704.867 751.803 705.726 752.745 707.333C753.619 708.991 754.077 710.825 754.082 712.686C754.613 710.993 754.406 709.328 755.735 707.697C756.219 707.091 756.839 706.6 757.548 706.261C758.257 705.921 759.036 705.743 759.826 705.738C758.197 704.588 756.655 704.551 755.853 702.397C755.537 701.581 755.294 700.741 755.128 699.885C754.975 699.02 755.232 697.742 754.748 697.066L754.939 697.438Z'
                opacity='.7' />
          <path id='Vector_1275' fill='white'
                d='M805.742 655.722C805.499 657.596 804.68 659.358 803.389 660.787C802.099 662.215 800.395 663.245 798.492 663.747C796.589 664.249 794.573 664.2 792.7 663.606C790.826 663.013 789.179 661.901 787.966 660.411C786.753 658.922 786.029 657.122 785.886 655.239C785.742 653.356 786.186 651.475 787.16 649.833C788.135 648.191 789.596 646.862 791.36 646.015C793.124 645.168 795.111 644.84 797.07 645.073C799.696 645.385 802.085 646.683 803.712 648.68C805.338 650.677 806.068 653.21 805.742 655.722Z'
                opacity='.1' />
          <path id='Vector_1276' fill='white'
                d='M804.026 655.401C803.846 656.764 803.241 658.046 802.287 659.085C801.333 660.124 800.074 660.873 798.667 661.238C797.261 661.603 795.771 661.567 794.386 661.135C793.001 660.703 791.784 659.894 790.887 658.811C789.991 657.728 789.456 656.419 789.35 655.049C789.244 653.68 789.572 652.311 790.293 651.117C791.013 649.923 792.093 648.957 793.397 648.341C794.701 647.725 796.17 647.487 797.617 647.656C798.579 647.769 799.507 648.058 800.349 648.508C801.191 648.958 801.931 649.56 802.526 650.279C803.121 650.999 803.56 651.821 803.818 652.7C804.075 653.579 804.146 654.497 804.026 655.401Z'
                opacity='.2' />
          <path id='Vector_1277' fill='white'
                d='M797.229 649.605C796.665 651.715 796.633 654.453 792.802 654.303C793.86 654.973 795.057 655.593 795.709 656.753C796.314 657.951 796.632 659.275 796.635 660.62C797.002 659.397 796.859 658.195 797.78 657.016C798.115 656.579 798.544 656.224 799.035 655.979C799.525 655.734 800.064 655.605 800.612 655.602C799.483 654.771 798.415 654.745 797.86 653.189C797.641 652.6 797.474 651.992 797.359 651.374C797.253 650.749 797.431 649.826 797.096 649.339L797.229 649.605Z'
                opacity='.7' />
          <path id='Vector_1278' fill='white'
                d='M755.372 704.295C754.966 702.797 754.527 702.972 754.149 703.613C752.251 702.531 752.524 709.908 754.737 708.043C755.488 707.41 755.623 705.222 755.372 704.295Z' />
        </g>;


      case 11:
        return <g id='Mini gem_25'>
          <path id='Vector_1302' fill={`#${diamondColors[color][0]}`}
                d='M1066.36 348.81L1113 392.315L1078.31 424.799L1066.36 350.363L1021.88 400.843L1070.68 472.033L1078.31 424.799L1066.36 348.81Z' />
          <path id='Vector_1303' fill={`#${diamondColors[color][1]}`}
                d='M1066.29 348.81L1021.88 400.593L1078.29 425.174L1066.29 348.81Z' />
          <path id='Vector_1304' fill='white'
                d='M1066.29 348.81L1065.05 350.254L1076.37 422.31L1023.15 399.117L1021.88 400.593L1078.29 425.174L1066.29 348.81Z' />
          <path id='Vector_1305' fill={`#${diamondColors[color][2]}`}
                d='M1078.15 424.729L1070.48 472.033L1113 392.198L1078.15 424.729Z' />
          <path id='Vector_1306' fill={`#${diamondColors[color][2]}`}
                d='M1078.15 424.729L1070.48 472.033L1113 392.198L1078.15 424.729Z' />
          <path id='Vector_1307' fill='white' d='M1021.88 400.876L1072.21 385.657L1066.43 348.81L1021.88 400.876Z'
                opacity='.2' />
          <path id='Vector_1308' fill='white' d='M1071.94 385.535L1113 392.198L1066.14 348.81L1071.94 384.862V385.535Z'
                opacity='.2' />
          <path id='Vector_1309' fill={`#${diamondColors[color][3]}`}
                d='M1074.96 444.43C1075.05 443.916 1099.83 404.92 1099.83 404.92L1113 393.066L1083.08 448.441L1070.48 472.033L1074.96 444.43Z'
                opacity='.5' />
          <path id='Vector_1310' fill={`#${diamondColors[color][3]}`}
                d='M1079.65 424.731L1111.52 394.984L1113 392.198L1078.15 424.731L1070.48 472.033L1072.64 467.982L1079.65 424.731Z' />
          <path id='Vector_1311' fill={`#${diamondColors[color][3]}`}
                d='M1038.3 424.841L1077.42 425.174L1021.88 400.876L1038.3 424.841Z' opacity='.5' />
          <path id='Vector_1312' fill='white'
                d='M1024.49 399.973C1026.2 401.728 1030.41 401.976 1032.79 402.433C1037.76 403.506 1042.88 403.688 1047.91 402.971C1054 401.989 1058.79 399.309 1063.52 395.538C1066.61 393.076 1067.83 390.656 1069.13 387.156C1069.51 386.262 1069.81 385.336 1070.03 384.388C1071.59 385.214 1071.38 390.494 1071.83 392.252C1072.7 395.71 1073.66 399.068 1074.02 402.604C1074.71 408.454 1075.7 414.266 1076.98 420.016C1077.23 421.193 1077.99 422.715 1076.65 423.307C1075.59 423.778 1073.7 422.852 1072.78 422.517C1070.07 421.523 1067.96 419.811 1065.35 418.575C1062.8 417.374 1060.12 416.734 1057.55 415.532C1054.83 414.26 1052.18 413.11 1049.57 411.635C1045.57 409.378 1041.13 407.613 1036.95 405.848C1034.85 404.961 1032.64 404.124 1030.64 403.21C1029.06 402.487 1025.53 401.653 1024.65 400.135L1024.49 399.973Z'
                opacity='.4' />
          <path id='Vector_1313' fill='white'
                d='M1085.87 438.837C1094.7 433.057 1097.17 421.214 1091.39 412.386C1085.61 403.557 1073.77 401.086 1064.94 406.866C1056.11 412.646 1053.64 424.489 1059.42 433.318C1065.2 442.146 1077.04 444.617 1085.87 438.837Z'
                opacity='.1' />
          <path id='Vector_1314' fill='white'
                d='M1091.2 423.903C1090.85 426.713 1089.67 429.356 1087.82 431.499C1085.97 433.641 1083.52 435.187 1080.79 435.939C1078.06 436.692 1075.17 436.619 1072.48 435.728C1069.8 434.837 1067.43 433.169 1065.69 430.934C1063.95 428.7 1062.91 426 1062.71 423.176C1062.5 420.352 1063.14 417.53 1064.54 415.067C1065.93 412.604 1068.03 410.612 1070.56 409.341C1073.09 408.07 1075.94 407.578 1078.75 407.928C1082.52 408.396 1085.95 410.342 1088.28 413.338C1090.62 416.334 1091.66 420.134 1091.2 423.903Z'
                opacity='.2' />
          <path id='Vector_1315' fill='white'
                d='M1078.46 412.691C1077.34 416.91 1077.27 422.385 1069.61 422.085C1071.73 423.425 1074.12 424.665 1075.43 426.987C1076.64 429.381 1077.27 432.03 1077.28 434.719C1078.01 432.274 1077.73 429.869 1079.57 427.512C1080.24 426.637 1081.1 425.928 1082.08 425.438C1083.06 424.947 1084.14 424.689 1085.23 424.682C1082.97 423.021 1080.84 422.969 1079.73 419.858C1079.29 418.679 1078.96 417.465 1078.73 416.228C1078.52 414.979 1078.87 413.132 1078.2 412.157L1078.46 412.691Z'
                opacity='.7' />
          <path id='Vector_1316' fill='white'
                d='M1079.87 423.051C1079.33 421.303 1078.74 421.506 1078.24 422.253C1075.71 420.989 1076.07 429.599 1079.02 427.422C1080.02 426.686 1080.21 424.133 1079.87 423.051Z' />
          <path id='Vector_1317' fill='white'
                d='M1034.8 402.101C1034.47 404.741 1033.37 407.224 1031.63 409.237C1029.89 411.25 1027.59 412.702 1025.03 413.409C1022.46 414.116 1019.74 414.047 1017.22 413.21C1014.69 412.373 1012.47 410.806 1010.84 408.707C1009.2 406.609 1008.23 404.072 1008.04 401.419C1007.84 398.766 1008.44 396.115 1009.75 393.801C1011.07 391.488 1013.04 389.616 1015.41 388.422C1017.79 387.228 1020.47 386.766 1023.11 387.095C1026.65 387.535 1029.87 389.363 1032.06 392.177C1034.25 394.991 1035.24 398.561 1034.8 402.101Z'
                opacity='.1' />
          <path id='Vector_1318' fill='white'
                d='M1033.09 401.676C1032.84 403.634 1032.03 405.476 1030.73 406.969C1029.44 408.462 1027.74 409.539 1025.84 410.063C1023.93 410.587 1021.92 410.536 1020.05 409.915C1018.17 409.294 1016.53 408.131 1015.31 406.574C1014.1 405.017 1013.38 403.135 1013.23 401.167C1013.09 399.199 1013.53 397.232 1014.51 395.516C1015.48 393.799 1016.94 392.411 1018.71 391.525C1020.47 390.639 1022.46 390.296 1024.42 390.539C1025.72 390.7 1026.97 391.116 1028.11 391.764C1029.25 392.411 1030.25 393.276 1031.06 394.31C1031.86 395.344 1032.46 396.527 1032.81 397.791C1033.15 399.055 1033.25 400.375 1033.09 401.676Z'
                opacity='.2' />
          <path id='Vector_1319' fill='white'
                d='M1023.94 393.438C1023.12 396.359 1023.08 400.149 1017.55 399.94C1019.07 400.867 1020.8 401.726 1021.74 403.333C1022.62 404.991 1023.08 406.825 1023.08 408.686C1023.61 406.993 1023.41 405.328 1024.74 403.697C1025.22 403.091 1025.84 402.6 1026.55 402.261C1027.26 401.921 1028.04 401.743 1028.83 401.738C1027.2 400.588 1025.65 400.551 1024.85 398.397C1024.54 397.581 1024.29 396.741 1024.13 395.885C1023.98 395.02 1024.23 393.742 1023.75 393.066L1023.94 393.438Z'
                opacity='.7' />
          <path id='Vector_1320' fill='white'
                d='M1074.74 351.722C1074.5 353.596 1073.68 355.358 1072.39 356.787C1071.1 358.215 1069.39 359.245 1067.49 359.747C1065.59 360.249 1063.57 360.2 1061.7 359.606C1059.83 359.013 1058.18 357.901 1056.97 356.411C1055.75 354.922 1055.03 353.122 1054.89 351.239C1054.74 349.356 1055.19 347.475 1056.16 345.833C1057.13 344.191 1058.6 342.862 1060.36 342.015C1062.12 341.168 1064.11 340.84 1066.07 341.073C1068.7 341.385 1071.09 342.683 1072.71 344.68C1074.34 346.677 1075.07 349.21 1074.74 351.722Z'
                opacity='.1' />
          <path id='Vector_1321' fill='white'
                d='M1073.03 351.401C1072.85 352.764 1072.24 354.046 1071.29 355.085C1070.33 356.124 1069.07 356.873 1067.67 357.238C1066.26 357.603 1064.77 357.567 1063.39 357.135C1062 356.703 1060.78 355.894 1059.89 354.811C1058.99 353.728 1058.46 352.419 1058.35 351.049C1058.24 349.68 1058.57 348.311 1059.29 347.117C1060.01 345.923 1061.09 344.957 1062.4 344.341C1063.7 343.725 1065.17 343.487 1066.62 343.656C1067.58 343.769 1068.51 344.058 1069.35 344.508C1070.19 344.958 1070.93 345.56 1071.53 346.279C1072.12 346.999 1072.56 347.821 1072.82 348.7C1073.07 349.579 1073.15 350.497 1073.03 351.401Z'
                opacity='.2' />
          <path id='Vector_1322' fill='white'
                d='M1066.23 345.605C1065.67 347.715 1065.63 350.453 1061.8 350.303C1062.86 350.973 1064.06 351.593 1064.71 352.754C1065.31 353.951 1065.63 355.275 1065.63 356.62C1066 355.397 1065.86 354.195 1066.78 353.016C1067.11 352.579 1067.54 352.224 1068.03 351.979C1068.53 351.734 1069.06 351.605 1069.61 351.602C1068.48 350.771 1067.42 350.745 1066.86 349.189C1066.64 348.6 1066.47 347.992 1066.36 347.374C1066.25 346.75 1066.43 345.826 1066.1 345.339L1066.23 345.605Z'
                opacity='.7' />
          <path id='Vector_1323' fill='white'
                d='M1024.37 400.295C1023.97 398.797 1023.53 398.972 1023.15 399.613C1021.25 398.531 1021.52 405.908 1023.74 404.043C1024.49 403.41 1024.62 401.222 1024.37 400.295Z' />
        </g>;





      case 12:
        return <g id='Group_43'>
          <g id='Group_44'>
            <path id='Vector_457' fill='#DD9D6E'
                  d='M268 268.736V332.314L307.239 347.901L306.839 284.323L268 268.736Z' />
            <path id='Vector_458' fill='#A57053'
                  d='M306.839 284.323L307.239 347.901L357.293 345.852L356.893 282.273L306.839 284.323Z' />
            <path id='Vector_459' fill='#DD9D6E'
                  d='M356.893 282.273L357.293 345.852L358.904 360.619L358.504 297.041L356.893 282.273Z' />
            <path id='Vector_460' fill='#DD9D6E'
                  d='M326.058 309.758V373.337L351.29 386.464L350.89 323.296L326.058 309.758Z' />
            <path id='Vector_461' fill='#C98D65'
                  d='M350.89 323.296L351.29 386.464L421.374 388.104L420.974 324.526L350.89 323.296Z' />
            <path id='Vector_462' fill='#A57053'
                  d='M420.973 324.526L421.374 388.104L476.63 371.287L476.23 307.709L420.973 324.526Z' />
            <path id='Vector_463' fill='#EFAA7B'
                  d='M297.234 248.23L268 268.736L306.839 284.323L356.893 282.273L358.504 297.041L326.058 309.758L350.89 323.296L420.974 324.526L476.23 307.709L430.578 281.454L404.556 270.785L376.122 255.619L336.073 247L322.456 247.82L297.234 248.23Z' />
          </g>
          <path id='Vector_464' fill='#F9BB91'
                d='M297.234 248.23L322.866 247.82L336.483 247L376.932 255.619L405.366 271.206L430.998 281.874L476.65 308.129L421.384 324.946L351.31 323.716L326.078 310.589L358.914 297.871L357.313 283.104L307.259 284.743L268.01 269.566L297.244 248.24L297.234 248.23ZM308.049 281.864L360.104 279.814L362.105 298.27L332.071 310.168L352.09 320.836L420.163 322.066L469.417 307.299L428.167 283.513L402.935 272.845L374.501 257.258L335.663 249.05L322.846 249.869L298.815 250.279L273.583 268.326L308.019 281.864H308.049Z' />
          <g id='Mini gem_8'>
            <path id='Vector_465' fill={`#${diamondColors[color][0]}`}
                  d='M377.383 187.248L414.692 222.052L386.942 248.039L377.383 188.49L341.8 228.874L380.835 285.826L386.942 248.039L377.383 187.248Z' />
            <path id='Vector_466' fill={`#${diamondColors[color][1]}`}
                  d='M377.321 187.248L341.8 228.675L386.924 248.339L377.321 187.248Z' />
            <path id='Vector_467' fill='white'
                  d='M377.322 187.248L376.33 188.403L385.391 246.048L342.813 227.493L341.8 228.675L386.924 248.339L377.322 187.248Z' />
            <path id='Vector_468' fill={`#${diamondColors[color][2]}`}
                  d='M386.812 247.983L380.676 285.826L414.693 221.959L386.812 247.983Z' />
            <path id='Vector_469' fill={`#${diamondColors[color][2]}`}
                  d='M386.812 247.983L380.676 285.826L414.693 221.959L386.812 247.983Z' />
            <path id='Vector_470' fill='white' d='M341.8 228.901L382.064 216.726L377.44 187.248L341.8 228.901Z'
                  opacity='.2' />
            <path id='Vector_471' fill='white'
                  d='M381.843 216.628L414.692 221.959L377.205 187.248L381.843 216.09V216.628Z' opacity='.2' />
            <path id='Vector_472' fill={`#${diamondColors[color][3]}`}
                  d='M384.263 263.744C384.329 263.333 404.153 232.136 404.153 232.136L414.693 222.653L390.753 266.953L380.676 285.826L384.263 263.744Z'
                  opacity='.5' />
            <path id='Vector_473' fill={`#${diamondColors[color][3]}`}
                  d='M388.013 247.984L413.506 224.187L414.693 221.959L386.812 247.984L380.676 285.826L382.402 282.585L388.013 247.984Z' />
            <path id='Vector_474' fill={`#${diamondColors[color][3]}`}
                  d='M354.93 248.073L386.23 248.339L341.8 228.901L354.93 248.073Z' opacity='.5' />
            <path id='Vector_475' fill='white'
                  d='M343.883 228.179C345.252 229.582 348.62 229.781 350.526 230.146C354.498 231.004 358.594 231.15 362.618 230.577C367.494 229.791 371.324 227.647 375.109 224.63C377.58 222.661 378.558 220.725 379.593 217.925C379.9 217.21 380.141 216.469 380.314 215.711C381.561 216.372 381.396 220.596 381.753 222.002C382.455 224.768 383.221 227.455 383.51 230.283C384.058 234.963 384.849 239.613 385.879 244.213C386.074 245.154 386.686 246.372 385.613 246.845C384.763 247.223 383.253 246.482 382.52 246.214C380.345 245.418 378.664 244.048 376.57 243.06C374.536 242.099 372.385 241.587 370.332 240.626C368.16 239.608 366.036 238.688 363.947 237.508C360.748 235.702 357.198 234.29 353.851 232.879C352.17 232.169 350.404 231.499 348.805 230.768C347.54 230.19 344.72 229.522 344.015 228.308L343.883 228.179Z'
                  opacity='.4' />
            <path id='Vector_476' fill='white'
                  d='M392.989 259.27C400.052 254.646 402.029 245.171 397.405 238.109C392.78 231.046 383.306 229.069 376.243 233.693C369.181 238.317 367.204 247.791 371.828 254.854C376.452 261.917 385.926 263.894 392.989 259.27Z'
                  opacity='.1' />
            <path id='Vector_477' fill='white'
                  d='M397.25 247.322C396.97 249.57 396.03 251.685 394.549 253.399C393.068 255.113 391.111 256.349 388.927 256.952C386.743 257.554 384.43 257.495 382.279 256.782C380.129 256.069 378.238 254.735 376.846 252.948C375.454 251.16 374.623 249 374.458 246.741C374.294 244.481 374.803 242.224 375.922 240.254C377.04 238.284 378.718 236.689 380.742 235.673C382.767 234.656 385.047 234.263 387.296 234.542C390.31 234.917 393.053 236.474 394.919 238.871C396.786 241.267 397.624 244.307 397.25 247.322Z'
                  opacity='.2' />
            <path id='Vector_478' fill='white'
                  d='M387.064 238.353C386.162 241.728 386.11 246.108 379.982 245.868C381.675 246.94 383.59 247.932 384.634 249.79C385.602 251.705 386.109 253.824 386.115 255.975C386.702 254.019 386.473 252.095 387.947 250.21C388.483 249.51 389.169 248.942 389.954 248.55C390.739 248.158 391.602 247.951 392.478 247.946C390.672 246.617 388.964 246.575 388.076 244.086C387.726 243.143 387.457 242.172 387.273 241.182C387.107 240.183 387.389 238.705 386.852 237.926L387.064 238.353Z'
                  opacity='.7' />
            <path id='Vector_479' fill='white'
                  d='M388.189 246.641C387.756 245.242 387.288 245.405 386.885 246.002C384.859 244.991 385.151 251.879 387.511 250.138C388.311 249.549 388.457 247.506 388.189 246.641Z' />
            <path id='Vector_480' fill='white'
                  d='M352.131 229.881C351.869 231.993 350.986 233.979 349.594 235.59C348.203 237.2 346.365 238.361 344.313 238.927C342.261 239.493 340.088 239.437 338.068 238.768C336.048 238.098 334.271 236.845 332.964 235.166C331.656 233.487 330.875 231.458 330.721 229.335C330.566 227.213 331.045 225.092 332.095 223.241C333.146 221.39 334.722 219.893 336.624 218.938C338.526 217.983 340.668 217.613 342.78 217.876C345.612 218.228 348.188 219.69 349.942 221.942C351.695 224.193 352.483 227.049 352.131 229.881Z'
                  opacity='.1' />
            <path id='Vector_481' fill='white'
                  d='M350.764 229.54C350.568 231.107 349.913 232.581 348.88 233.775C347.848 234.969 346.484 235.831 344.962 236.25C343.44 236.67 341.828 236.628 340.329 236.132C338.831 235.635 337.513 234.705 336.543 233.459C335.573 232.214 334.994 230.708 334.879 229.134C334.764 227.559 335.119 225.986 335.898 224.613C336.678 223.24 337.847 222.128 339.258 221.42C340.669 220.711 342.258 220.437 343.825 220.631C344.865 220.76 345.87 221.093 346.782 221.611C347.694 222.129 348.495 222.821 349.14 223.648C349.784 224.476 350.259 225.422 350.538 226.433C350.816 227.444 350.893 228.5 350.764 229.54Z'
                  opacity='.2' />
            <path id='Vector_482' fill='white'
                  d='M343.444 222.95C342.792 225.287 342.755 228.319 338.329 228.152C339.551 228.894 340.935 229.58 341.688 230.867C342.388 232.193 342.754 233.66 342.758 235.149C343.183 233.794 343.017 232.463 344.081 231.157C344.468 230.673 344.964 230.28 345.531 230.009C346.098 229.737 346.721 229.594 347.354 229.59C346.05 228.67 344.816 228.641 344.175 226.918C343.922 226.265 343.728 225.593 343.595 224.908C343.473 224.216 343.678 223.193 343.291 222.653L343.444 222.95Z'
                  opacity='.7' />
            <path id='Vector_483' fill='white'
                  d='M384.086 189.578C383.891 191.076 383.237 192.487 382.204 193.629C381.172 194.772 379.808 195.596 378.286 195.998C376.764 196.399 375.151 196.36 373.652 195.885C372.154 195.41 370.836 194.521 369.865 193.329C368.895 192.138 368.316 190.698 368.201 189.191C368.087 187.685 368.441 186.18 369.221 184.866C370 183.553 371.17 182.49 372.581 181.812C373.992 181.134 375.581 180.872 377.148 181.058C379.249 181.308 381.161 182.346 382.462 183.944C383.763 185.541 384.347 187.568 384.086 189.578Z'
                  opacity='.1' />
            <path id='Vector_484' fill='white'
                  d='M382.713 189.321C382.57 190.411 382.085 191.437 381.322 192.268C380.559 193.099 379.551 193.698 378.426 193.99C377.301 194.282 376.109 194.254 375.001 193.908C373.893 193.563 372.919 192.916 372.202 192.049C371.485 191.182 371.057 190.135 370.973 189.039C370.888 187.944 371.15 186.849 371.727 185.894C372.303 184.939 373.167 184.166 374.21 183.673C375.253 183.18 376.428 182.99 377.586 183.125C378.355 183.215 379.098 183.447 379.772 183.807C380.446 184.167 381.037 184.648 381.514 185.224C381.99 185.799 382.341 186.457 382.547 187.16C382.752 187.863 382.809 188.597 382.713 189.321Z'
                  opacity='.2' />
            <path id='Vector_485' fill='white'
                  d='M377.276 184.684C376.825 186.372 376.799 188.562 373.734 188.442C374.581 188.978 375.538 189.474 376.06 190.403C376.544 191.36 376.798 192.42 376.8 193.496C377.094 192.518 376.98 191.556 377.716 190.613C377.984 190.263 378.328 189.979 378.72 189.783C379.113 189.587 379.544 189.484 379.982 189.481C379.079 188.817 378.225 188.796 377.781 187.551C377.606 187.08 377.471 186.594 377.38 186.099C377.295 185.6 377.437 184.861 377.169 184.471L377.276 184.684Z'
                  opacity='.7' />
            <path id='Vector_486' fill='white'
                  d='M343.79 228.436C343.465 227.238 343.114 227.377 342.812 227.891C341.293 227.025 341.512 232.927 343.282 231.435C343.883 230.928 343.991 229.178 343.79 228.436Z' />
          </g>
        </g>;


      case 13:
        return <g id='Mini gem_9'>
          <path id='Vector_672' fill={`#${diamondColors[color][0]}`}
                d='M516.228 493.248L553.537 528.052L525.787 554.039L516.227 494.491L480.645 534.874L519.68 591.826L525.787 554.039L516.228 493.248Z' />
          <path id='Vector_673' fill={`#${diamondColors[color][1]}`}
                d='M516.166 493.248L480.645 534.675L525.769 554.339L516.166 493.248Z' />
          <path id='Vector_674' fill='white'
                d='M516.166 493.248L515.175 494.403L524.236 552.048L481.658 533.493L480.645 534.675L525.769 554.339L516.166 493.248Z' />
          <path id='Vector_675' fill={`#${diamondColors[color][2]}`}
                d='M525.657 553.984L519.521 591.826L553.537 527.959L525.657 553.984Z' />
          <path id='Vector_676' fill={`#${diamondColors[color][2]}`}
                d='M525.657 553.984L519.521 591.826L553.537 527.959L525.657 553.984Z' />
          <path id='Vector_677' fill='white' d='M480.645 534.901L520.909 522.726L516.285 493.248L480.645 534.901Z'
                opacity='.2' />
          <path id='Vector_678' fill='white' d='M520.688 522.628L553.537 527.959L516.05 493.248L520.688 522.09V522.628Z'
                opacity='.2' />
          <path id='Vector_679' fill={`#${diamondColors[color][3]}`}
                d='M523.107 569.744C523.174 569.333 542.998 538.136 542.998 538.136L553.537 528.653L529.598 572.953L519.521 591.826L523.107 569.744Z'
                opacity='.5' />
          <path id='Vector_680' fill={`#${diamondColors[color][3]}`}
                d='M526.858 553.984L552.35 530.187L553.537 527.959L525.657 553.984L519.521 591.826L521.246 588.586L526.858 553.984Z' />
          <path id='Vector_681' fill={`#${diamondColors[color][3]}`}
                d='M493.775 554.073L525.074 554.339L480.645 534.901L493.775 554.073Z' opacity='.5' />
          <path id='Vector_682' fill='white'
                d='M482.727 534.179C484.096 535.582 487.464 535.781 489.371 536.146C493.343 537.005 497.439 537.15 501.463 536.577C506.338 535.791 510.168 533.647 513.953 530.63C516.425 528.661 517.402 526.725 518.438 523.925C518.745 523.21 518.986 522.469 519.159 521.711C520.406 522.372 520.24 526.596 520.597 528.002C521.3 530.768 522.066 533.455 522.355 536.283C522.903 540.963 523.694 545.613 524.724 550.213C524.919 551.154 525.531 552.372 524.458 552.845C523.608 553.223 522.098 552.482 521.365 552.214C519.19 551.418 517.509 550.048 515.415 549.06C513.381 548.099 511.23 547.587 509.177 546.626C507.004 545.608 504.881 544.688 502.792 543.508C499.593 541.702 496.043 540.29 492.696 538.879C491.015 538.169 489.249 537.499 487.65 536.768C486.384 536.19 483.565 535.522 482.859 534.308L482.727 534.179Z'
                opacity='.4' />
          <path id='Vector_683' fill='white'
                d='M531.834 565.27C538.897 560.646 540.873 551.171 536.249 544.109C531.625 537.046 522.151 535.069 515.088 539.693C508.025 544.317 506.048 553.791 510.673 560.854C515.297 567.917 524.771 569.894 531.834 565.27Z'
                opacity='.1' />
          <path id='Vector_684' fill='white'
                d='M536.094 553.322C535.815 555.57 534.875 557.685 533.394 559.399C531.912 561.113 529.956 562.349 527.772 562.952C525.588 563.554 523.274 563.495 521.124 562.782C518.973 562.069 517.083 560.735 515.691 558.948C514.298 557.16 513.468 555 513.303 552.741C513.139 550.481 513.648 548.224 514.766 546.254C515.885 544.284 517.563 542.689 519.587 541.673C521.612 540.656 523.892 540.263 526.14 540.542C529.155 540.917 531.897 542.474 533.764 544.871C535.631 547.267 536.469 550.307 536.094 553.322Z'
                opacity='.2' />
          <path id='Vector_685' fill='white'
                d='M525.909 544.353C525.007 547.728 524.955 552.108 518.827 551.868C520.519 552.94 522.435 553.932 523.478 555.79C524.447 557.705 524.954 559.824 524.959 561.975C525.547 560.019 525.318 558.095 526.792 556.21C527.327 555.51 528.014 554.942 528.799 554.55C529.584 554.158 530.447 553.951 531.322 553.946C529.517 552.617 527.809 552.575 526.921 550.086C526.57 549.143 526.302 548.172 526.118 547.182C525.952 546.183 526.234 544.705 525.697 543.926L525.909 544.353Z'
                opacity='.7' />
          <path id='Vector_686' fill='white'
                d='M527.034 552.641C526.601 551.242 526.133 551.405 525.73 552.002C523.704 550.991 523.996 557.879 526.356 556.138C527.156 555.549 527.302 553.506 527.034 552.641Z' />
          <path id='Vector_687' fill='white'
                d='M490.976 535.881C490.713 537.993 489.83 539.979 488.439 541.59C487.047 543.2 485.21 544.361 483.158 544.927C481.106 545.493 478.933 545.437 476.913 544.768C474.892 544.098 473.116 542.845 471.808 541.166C470.501 539.487 469.72 537.458 469.566 535.335C469.411 533.213 469.889 531.092 470.94 529.241C471.991 527.39 473.567 525.893 475.469 524.938C477.371 523.983 479.513 523.613 481.625 523.876C484.457 524.228 487.033 525.69 488.787 527.942C490.54 530.193 491.328 533.049 490.976 535.881Z'
                opacity='.1' />
          <path id='Vector_688' fill='white'
                d='M489.608 535.54C489.413 537.107 488.758 538.581 487.725 539.775C486.693 540.969 485.329 541.831 483.807 542.25C482.285 542.67 480.673 542.629 479.174 542.132C477.675 541.635 476.358 540.705 475.388 539.459C474.417 538.214 473.838 536.708 473.724 535.134C473.609 533.559 473.964 531.986 474.743 530.613C475.523 529.24 476.692 528.128 478.102 527.42C479.513 526.711 481.103 526.437 482.669 526.631C483.71 526.76 484.715 527.093 485.627 527.611C486.539 528.129 487.34 528.821 487.985 529.648C488.629 530.476 489.104 531.422 489.383 532.433C489.661 533.444 489.738 534.5 489.608 535.54Z'
                opacity='.2' />
          <path id='Vector_689' fill='white'
                d='M482.289 528.95C481.637 531.287 481.6 534.319 477.174 534.152C478.396 534.894 479.779 535.58 480.533 536.867C481.232 538.193 481.599 539.66 481.603 541.149C482.027 539.794 481.862 538.463 482.926 537.157C483.313 536.673 483.809 536.28 484.376 536.009C484.943 535.737 485.566 535.594 486.198 535.59C484.895 534.67 483.661 534.641 483.019 532.918C482.767 532.265 482.573 531.593 482.44 530.908C482.317 530.216 482.523 529.193 482.136 528.653L482.289 528.95Z'
                opacity='.7' />
          <path id='Vector_690' fill='white'
                d='M522.931 495.578C522.736 497.077 522.081 498.487 521.049 499.629C520.016 500.772 518.653 501.596 517.131 501.998C515.609 502.399 513.996 502.36 512.497 501.885C510.998 501.41 509.68 500.521 508.71 499.329C507.74 498.138 507.161 496.698 507.046 495.191C506.931 493.685 507.286 492.18 508.066 490.866C508.845 489.553 510.014 488.49 511.426 487.812C512.837 487.134 514.426 486.872 515.993 487.058C518.094 487.308 520.005 488.346 521.307 489.944C522.608 491.541 523.192 493.568 522.931 495.578Z'
                opacity='.1' />
          <path id='Vector_691' fill='white'
                d='M521.558 495.321C521.414 496.411 520.93 497.437 520.167 498.268C519.404 499.099 518.396 499.698 517.271 499.99C516.146 500.282 514.954 500.254 513.846 499.908C512.738 499.563 511.764 498.916 511.047 498.049C510.33 497.182 509.902 496.135 509.817 495.039C509.733 493.944 509.995 492.849 510.571 491.894C511.148 490.939 512.012 490.166 513.055 489.673C514.098 489.18 515.273 488.99 516.431 489.125C517.2 489.215 517.943 489.447 518.617 489.807C519.29 490.167 519.882 490.648 520.358 491.224C520.834 491.799 521.185 492.457 521.391 493.16C521.597 493.863 521.654 494.597 521.558 495.321Z'
                opacity='.2' />
          <path id='Vector_692' fill='white'
                d='M516.121 490.684C515.67 492.372 515.644 494.562 512.579 494.442C513.425 494.979 514.383 495.474 514.904 496.403C515.389 497.361 515.643 498.42 515.645 499.496C515.939 498.518 515.824 497.556 516.561 496.613C516.829 496.263 517.172 495.979 517.565 495.783C517.957 495.587 518.389 495.484 518.827 495.481C517.924 494.817 517.07 494.796 516.625 493.551C516.45 493.08 516.316 492.594 516.224 492.099C516.14 491.6 516.282 490.861 516.014 490.471L516.121 490.684Z'
                opacity='.7' />
          <path id='Vector_693' fill='white'
                d='M482.635 534.436C482.31 533.238 481.959 533.377 481.657 533.891C480.138 533.025 480.356 538.927 482.127 537.435C482.727 536.928 482.836 535.178 482.635 534.436Z' />
        </g>;


      case 14:
        return <g id='Mini gem_10'>
          <path id='Vector_694' fill={`#${diamondColors[color][0]}`}
                d='M1104.23 392.248L1141.54 427.052L1113.79 453.039L1104.23 393.491L1068.64 433.874L1107.68 490.826L1113.79 453.039L1104.23 392.248Z' />
          <path id='Vector_695' fill={`#${diamondColors[color][1]}`}
                d='M1104.17 392.248L1068.64 433.675L1113.77 453.339L1104.17 392.248Z' />
          <path id='Vector_696' fill='white'
                d='M1104.17 392.248L1103.18 393.403L1112.24 451.048L1069.66 432.493L1068.64 433.675L1113.77 453.339L1104.17 392.248Z' />
          <path id='Vector_697' fill={`#${diamondColors[color][2]}`}
                d='M1113.66 452.984L1107.52 490.826L1141.54 426.959L1113.66 452.984Z' />
          <path id='Vector_698' fill={`#${diamondColors[color][2]}`}
                d='M1113.66 452.984L1107.52 490.826L1141.54 426.959L1113.66 452.984Z' />
          <path id='Vector_699' fill='white' d='M1068.64 433.901L1108.91 421.726L1104.29 392.248L1068.64 433.901Z'
                opacity='.2' />
          <path id='Vector_700' fill='white'
                d='M1108.69 421.628L1141.54 426.959L1104.05 392.248L1108.69 421.09V421.628Z' opacity='.2' />
          <path id='Vector_701' fill={`#${diamondColors[color][3]}`}
                d='M1111.11 468.744C1111.17 468.333 1131 437.136 1131 437.136L1141.54 427.653L1117.6 471.953L1107.52 490.826L1111.11 468.744Z'
                opacity='.5' />
          <path id='Vector_702' fill={`#${diamondColors[color][3]}`}
                d='M1114.86 452.984L1140.35 429.187L1141.54 426.959L1113.66 452.984L1107.52 490.826L1109.25 487.586L1114.86 452.984Z' />
          <path id='Vector_703' fill={`#${diamondColors[color][3]}`}
                d='M1081.77 453.073L1113.07 453.339L1068.64 433.901L1081.77 453.073Z' opacity='.5' />
          <path id='Vector_704' fill='white'
                d='M1070.73 433.179C1072.1 434.582 1075.46 434.781 1077.37 435.146C1081.34 436.005 1085.44 436.15 1089.46 435.577C1094.34 434.791 1098.17 432.647 1101.95 429.63C1104.43 427.661 1105.4 425.725 1106.44 422.925C1106.74 422.21 1106.99 421.469 1107.16 420.711C1108.41 421.372 1108.24 425.596 1108.6 427.002C1109.3 429.768 1110.07 432.455 1110.35 435.283C1110.9 439.963 1111.69 444.613 1112.72 449.213C1112.92 450.154 1113.53 451.372 1112.46 451.845C1111.61 452.223 1110.1 451.482 1109.36 451.214C1107.19 450.418 1105.51 449.048 1103.41 448.06C1101.38 447.099 1099.23 446.587 1097.18 445.626C1095 444.608 1092.88 443.688 1090.79 442.508C1087.59 440.702 1084.04 439.29 1080.7 437.879C1079.01 437.169 1077.25 436.499 1075.65 435.768C1074.38 435.19 1071.57 434.522 1070.86 433.308L1070.73 433.179Z'
                opacity='.4' />
          <path id='Vector_705' fill='white'
                d='M1119.83 464.27C1126.9 459.646 1128.87 450.171 1124.25 443.109C1119.63 436.046 1110.15 434.069 1103.09 438.693C1096.03 443.317 1094.05 452.791 1098.67 459.854C1103.3 466.917 1112.77 468.894 1119.83 464.27Z'
                opacity='.1' />
          <path id='Vector_706' fill='white'
                d='M1124.09 452.322C1123.81 454.57 1122.88 456.685 1121.39 458.399C1119.91 460.113 1117.96 461.349 1115.77 461.952C1113.59 462.554 1111.27 462.495 1109.12 461.782C1106.97 461.069 1105.08 459.735 1103.69 457.948C1102.3 456.16 1101.47 454 1101.3 451.741C1101.14 449.481 1101.65 447.224 1102.77 445.254C1103.88 443.284 1105.56 441.689 1107.59 440.673C1109.61 439.656 1111.89 439.263 1114.14 439.542C1117.16 439.917 1119.9 441.474 1121.76 443.871C1123.63 446.267 1124.47 449.307 1124.09 452.322Z'
                opacity='.2' />
          <path id='Vector_707' fill='white'
                d='M1113.91 443.353C1113.01 446.728 1112.95 451.108 1106.83 450.868C1108.52 451.94 1110.43 452.932 1111.48 454.79C1112.45 456.705 1112.95 458.824 1112.96 460.975C1113.55 459.019 1113.32 457.095 1114.79 455.21C1115.33 454.51 1116.01 453.942 1116.8 453.55C1117.58 453.158 1118.45 452.951 1119.32 452.946C1117.52 451.617 1115.81 451.575 1114.92 449.086C1114.57 448.143 1114.3 447.172 1114.12 446.182C1113.95 445.183 1114.23 443.705 1113.7 442.926L1113.91 443.353Z'
                opacity='.7' />
          <path id='Vector_708' fill='white'
                d='M1115.03 451.641C1114.6 450.242 1114.13 450.405 1113.73 451.002C1111.7 449.991 1112 456.879 1114.36 455.138C1115.16 454.549 1115.3 452.506 1115.03 451.641Z' />
          <path id='Vector_709' fill='white'
                d='M1078.98 434.881C1078.71 436.993 1077.83 438.979 1076.44 440.59C1075.05 442.2 1073.21 443.361 1071.16 443.927C1069.11 444.493 1066.93 444.437 1064.91 443.768C1062.89 443.098 1061.12 441.845 1059.81 440.166C1058.5 438.487 1057.72 436.458 1057.57 434.335C1057.41 432.213 1057.89 430.092 1058.94 428.241C1059.99 426.39 1061.57 424.893 1063.47 423.938C1065.37 422.983 1067.51 422.613 1069.63 422.876C1072.46 423.228 1075.03 424.69 1076.79 426.942C1078.54 429.193 1079.33 432.049 1078.98 434.881Z'
                opacity='.1' />
          <path id='Vector_710' fill='white'
                d='M1077.61 434.54C1077.41 436.107 1076.76 437.581 1075.73 438.775C1074.69 439.969 1073.33 440.831 1071.81 441.25C1070.28 441.67 1068.67 441.629 1067.17 441.132C1065.68 440.635 1064.36 439.705 1063.39 438.459C1062.42 437.214 1061.84 435.708 1061.72 434.134C1061.61 432.559 1061.96 430.986 1062.74 429.613C1063.52 428.24 1064.69 427.128 1066.1 426.42C1067.51 425.711 1069.1 425.437 1070.67 425.631C1071.71 425.76 1072.72 426.093 1073.63 426.611C1074.54 427.129 1075.34 427.821 1075.98 428.648C1076.63 429.476 1077.1 430.422 1077.38 431.433C1077.66 432.444 1077.74 433.5 1077.61 434.54Z'
                opacity='.2' />
          <path id='Vector_711' fill='white'
                d='M1070.29 427.95C1069.64 430.287 1069.6 433.319 1065.17 433.152C1066.4 433.894 1067.78 434.58 1068.53 435.867C1069.23 437.193 1069.6 438.66 1069.6 440.149C1070.03 438.794 1069.86 437.463 1070.93 436.157C1071.31 435.673 1071.81 435.28 1072.38 435.009C1072.94 434.737 1073.57 434.594 1074.2 434.59C1072.89 433.67 1071.66 433.641 1071.02 431.918C1070.77 431.265 1070.57 430.593 1070.44 429.908C1070.32 429.216 1070.52 428.193 1070.14 427.653L1070.29 427.95Z'
                opacity='.7' />
          <path id='Vector_712' fill='white'
                d='M1110.93 394.578C1110.74 396.077 1110.08 397.487 1109.05 398.629C1108.02 399.772 1106.65 400.596 1105.13 400.998C1103.61 401.399 1102 401.36 1100.5 400.885C1099 400.41 1097.68 399.521 1096.71 398.329C1095.74 397.138 1095.16 395.698 1095.05 394.191C1094.93 392.685 1095.29 391.18 1096.07 389.866C1096.85 388.553 1098.01 387.49 1099.43 386.812C1100.84 386.134 1102.43 385.872 1103.99 386.058C1106.09 386.308 1108.01 387.346 1109.31 388.944C1110.61 390.541 1111.19 392.568 1110.93 394.578Z'
                opacity='.1' />
          <path id='Vector_713' fill='white'
                d='M1109.56 394.321C1109.41 395.411 1108.93 396.437 1108.17 397.268C1107.4 398.099 1106.4 398.698 1105.27 398.99C1104.15 399.282 1102.95 399.254 1101.85 398.908C1100.74 398.563 1099.76 397.916 1099.05 397.049C1098.33 396.182 1097.9 395.135 1097.82 394.039C1097.73 392.944 1097.99 391.849 1098.57 390.894C1099.15 389.939 1100.01 389.166 1101.05 388.673C1102.1 388.18 1103.27 387.99 1104.43 388.125C1105.2 388.215 1105.94 388.447 1106.62 388.807C1107.29 389.167 1107.88 389.648 1108.36 390.224C1108.83 390.799 1109.19 391.457 1109.39 392.16C1109.6 392.863 1109.65 393.597 1109.56 394.321Z'
                opacity='.2' />
          <path id='Vector_714' fill='white'
                d='M1104.12 389.684C1103.67 391.372 1103.64 393.562 1100.58 393.442C1101.43 393.979 1102.38 394.474 1102.9 395.403C1103.39 396.361 1103.64 397.42 1103.65 398.496C1103.94 397.518 1103.82 396.556 1104.56 395.613C1104.83 395.263 1105.17 394.979 1105.56 394.783C1105.96 394.587 1106.39 394.484 1106.83 394.481C1105.92 393.817 1105.07 393.796 1104.63 392.551C1104.45 392.08 1104.32 391.594 1104.22 391.099C1104.14 390.6 1104.28 389.861 1104.01 389.471L1104.12 389.684Z'
                opacity='.7' />
          <path id='Vector_715' fill='white'
                d='M1070.63 433.436C1070.31 432.238 1069.96 432.377 1069.66 432.891C1068.14 432.025 1068.36 437.927 1070.13 436.435C1070.73 435.928 1070.84 434.178 1070.63 433.436Z' />
        </g>;


      case 15:
        return <g id='Mini Island'>
          <g id='Group_72'>
            <path id='Vector_716' fill='#D1D3D4'
                  d='M168.68 540.103L196.647 542.079H213.038L227.175 543.391H237.146H264.14L285.345 537.462L291.782 524.625L288.248 507.836L278.277 494.003L264.14 478.857L224.921 475.569L165.777 488.074L168.68 540.103Z'
                  style={{ mixBlendMode: 'multiply' }} />
            <g id='Group_73'>
              <path id='Vector_717' fill='#8E5E46'
                    d='M247.749 486.098L248.073 517.7L254.169 515.724L253.861 483.789L247.749 486.098Z' />
              <path id='Vector_718' fill='#A57053'
                    d='M224.921 487.742V519.361L248.073 517.7L247.749 486.098L224.921 487.742Z' />
              <path id='Vector_719' fill='#C98D65'
                    d='M213.994 484.786V516.72L224.921 519.361V487.742L213.994 484.786Z' />
              <path id='Vector_720' fill='#A57053'
                    d='M184.098 489.386V521.338L213.994 516.72V484.786L184.098 489.386Z' />
              <path id='Vector_721' fill='#DD9D6E' d='M115 490.382V522.317L137.179 535.818V504.216L115 490.382Z' />
              <path id='Vector_722' fill='#A57053'
                    d='M168.68 508.483V540.103L186.027 533.842L185.72 501.908L168.68 508.483Z' />
              <path id='Vector_723' fill='#C98D65'
                    d='M137.179 504.216V535.818L168.68 540.103V508.483L137.179 504.216Z' />
            </g>
            <g id='Group_74'>
              <path id='Vector_724' fill='#8E5E46'
                    d='M247.1 495.647L247.424 527.581L251.607 523.296L251.283 491.694L247.1 495.647Z' />
              <g id='Clip path group_3'>
                <mask id='mask2_0_4686' width='19' height='35' x='229' y='495' maskUnits='userSpaceOnUse'
                      style={{ maskType: 'luminance' }}>
                  <g id='clippath_2'>
                    <path id='Vector_725' fill='white'
                          d='M229.753 498.287V529.889C230.709 529.889 246.144 527.249 247.117 527.249V495.647C246.468 495.979 231.051 498.287 229.753 498.287Z' />
                  </g>
                </mask>
                <g mask='url(#mask2_0_4686)'>
                  <g id='Group_75'>
                    <g id='Group_76'>
                      <path id='Vector_726' fill='#8E5E46' d='M229.753 498.287V529.89H230.077V498.287H229.753Z' />
                      <path id='Vector_727' fill='#A57053'
                            d='M230.06 498.287V529.889C232.638 529.557 246.127 527.249 247.1 527.249V495.647C246.451 495.979 232.638 497.955 230.06 498.287Z' />
                    </g>
                  </g>
                </g>
              </g>
              <g id='Clip path group_4'>
                <mask id='mask3_0_4686' width='1' height='37' x='229' y='498' maskUnits='userSpaceOnUse'
                      style={{ maskType: 'luminance' }}>
                  <g id='clippath-1_2'>
                    <path id='Vector_728' fill='white'
                          d='M229.104 502.555V534.157C229.104 532.18 229.104 530.537 229.753 530.204V498.602C229.429 498.602 229.104 500.578 229.104 502.555Z' />
                  </g>
                </mask>
                <g mask='url(#mask3_0_4686)'>
                  <g id='Group_77'>
                    <path id='Vector_729' fill='#8E5E46'
                          d='M229.104 502.555V534.157C229.104 532.18 229.104 530.537 229.753 530.204V498.602C229.429 498.602 229.104 500.578 229.104 502.555Z' />
                  </g>
                </g>
              </g>
              <path id='Vector_730' fill='#DD9D6E'
                    d='M189.886 491.694L190.21 523.296L193.42 535.154V503.219L189.886 491.694Z' />
              <path id='Vector_731' fill='#A57053'
                    d='M213.038 510.127V542.079L229.429 537.462V505.86L213.038 510.127Z' />
              <path id='Vector_732' fill='#C98D65'
                    d='M184.746 507.504V539.438L213.038 542.079V510.127L184.746 507.504Z' />
            </g>
            <path id='Vector_733' fill='#8E5E46'
                  d='M264.14 519.361L264.464 534.174L265.42 525.605V511.124L264.14 519.361Z' />
            <path id='Vector_734' fill='#DD9D6E'
                  d='M236.497 515.724L236.822 530.554L239.075 536.483V521.985L236.497 515.724Z' />
            <path id='Vector_735' fill='#A57053'
                  d='M236.822 528.91L237.146 543.391L264.464 534.174L264.14 519.361L236.822 528.91Z' />
            <path id='Vector_736' fill='#DD9D6E'
                  d='M230.385 525.605L230.709 540.103L237.146 543.391L236.822 528.91L230.385 525.605Z' />
            <path id='Vector_737' fill='#DCD100'
                  d='M254.169 508.483L248.073 512.768L236.497 515.724L239.075 521.985L230.385 525.605L236.821 528.91L264.14 519.361L265.42 511.124L254.169 508.483Z' />
            <path id='Vector_738' fill='#EFAA7B'
                  d='M118.534 480.169L115 490.382L137.179 504.216L168.68 508.483L185.72 501.907L184.098 489.386L213.994 484.786L224.921 487.742L247.749 486.098L253.861 483.789L249.678 476.549L237.453 470.305L218.501 467L194.393 468.976L180.239 475.902L169.004 478.193L142.318 474.905L118.534 480.169Z' />
            <path id='Vector_739' fill='#EFAA7B'
                  d='M189.886 491.694L193.096 503.219L184.422 507.504L212.713 510.145L229.104 505.86C229.104 505.528 228.78 498.952 229.429 498.62C230.385 498.62 245.819 495.979 246.793 495.979L250.976 492.026L247.117 489.071L227.192 490.715L215.291 487.427L189.903 491.712L189.886 491.694Z' />
            <path id='Vector_740' fill='#DCD100'
                  d='M254.169 508.483L248.073 512.768L236.497 515.724L239.075 521.985L230.385 525.605L236.821 528.91L264.14 519.361L265.42 511.124L254.169 508.483Z' />
            <path id='Vector_741' fill='#F9BB91'
                  d='M118.534 480.169L142.318 474.905L168.68 478.193L180.256 476.217L194.393 469.308L218.501 467L237.47 470.288L249.678 476.549L253.861 483.789L247.749 485.765L224.614 487.409L213.687 484.769L183.79 489.386L185.395 501.89L168.356 508.483L136.854 504.199L115 490.697L118.534 480.169ZM137.828 503.219L168.356 507.172L184.098 501.243L182.493 488.406L214.318 483.474L225.246 486.43L247.749 484.786L252.256 483.474L248.722 477.546L237.146 471.617L218.826 468.329L195.691 470.305L181.554 477.213L169.346 479.522L142.984 476.566L120.481 481.498L117.271 490.715L137.845 503.219H137.828Z' />
            <path id='Vector_742' fill='#F9BB91'
                  d='M247.424 488.738L251.283 491.694L247.1 495.647C246.451 495.647 230.709 498.287 229.736 498.287C228.78 498.62 229.087 505.195 229.412 505.528L213.021 509.812L184.729 507.172L193.403 502.887L190.193 491.362L215.906 486.745L227.807 490.033L247.407 488.721L247.424 488.738ZM215.599 488.406L191.815 492.691L195.025 504.216L188.912 507.172L212.696 509.48L227.482 505.528C227.158 498.62 228.131 498.287 228.763 497.955H229.412C230.368 497.955 240.663 496.311 246.127 495.314L249.012 492.359L246.758 490.715L227.465 492.359L215.565 488.406H215.599Z' />
            <path id='Vector_743' fill='#EFAA7B'
                  d='M236.497 515.724L248.073 512.768L254.169 508.483L265.42 511.124L264.14 519.361L236.821 528.91L230.385 525.605L239.075 521.985L236.497 515.724Z' />
            <path id='Vector_744' fill='#D1D3D4'
                  d='M230.385 525.605L241.961 524.625L255.142 522.649L264.14 519.361L265.42 511.124L254.169 508.483L248.073 512.768L236.497 515.724L239.075 521.985L230.385 525.605Z'
                  style={{ mixBlendMode: 'multiply' }} />
          </g>
          <g id='Mini gem_11'>
            <path id='Vector_745' fill={`#${diamondColors[color][0]}`}
                  d='M187.309 385.248L224.619 420.052L196.868 446.039L187.309 386.49L151.726 426.874L190.761 483.826L196.868 446.039L187.309 385.248Z' />
            <path id='Vector_746' fill={`#${diamondColors[color][1]}`}
                  d='M187.247 385.248L151.726 426.675L196.85 446.339L187.247 385.248Z' />
            <path id='Vector_747' fill='white'
                  d='M187.248 385.248L186.256 386.403L195.317 444.048L152.739 425.493L151.726 426.675L196.85 446.339L187.248 385.248Z' />
            <path id='Vector_748' fill={`#${diamondColors[color][2]}`}
                  d='M196.738 445.983L190.602 483.826L224.619 419.959L196.738 445.983Z' />
            <path id='Vector_749' fill={`#${diamondColors[color][2]}`}
                  d='M196.738 445.983L190.602 483.826L224.619 419.959L196.738 445.983Z' />
            <path id='Vector_750' fill='white' d='M151.726 426.901L191.99 414.726L187.366 385.248L151.726 426.901Z'
                  opacity='.2' />
            <path id='Vector_751' fill='white'
                  d='M191.769 414.628L224.619 419.959L187.131 385.248L191.769 414.09V414.628Z' opacity='.2' />
            <path id='Vector_752' fill={`#${diamondColors[color][3]}`}
                  d='M194.189 461.744C194.255 461.333 214.079 430.136 214.079 430.136L224.619 420.653L200.679 464.953L190.602 483.826L194.189 461.744Z'
                  opacity='.5' />
            <path id='Vector_753' fill={`#${diamondColors[color][3]}`}
                  d='M197.939 445.984L223.432 422.187L224.619 419.959L196.738 445.984L190.602 483.826L192.328 480.585L197.939 445.984Z' />
            <path id='Vector_754' fill={`#${diamondColors[color][3]}`}
                  d='M164.856 446.073L196.156 446.339L151.726 426.901L164.856 446.073Z' opacity='.5' />
            <path id='Vector_755' fill='white'
                  d='M153.809 426.179C155.178 427.582 158.546 427.781 160.452 428.146C164.424 429.004 168.52 429.15 172.544 428.577C177.42 427.791 181.25 425.647 185.035 422.63C187.507 420.661 188.484 418.725 189.519 415.925C189.826 415.21 190.067 414.469 190.24 413.711C191.487 414.372 191.322 418.596 191.679 420.002C192.381 422.768 193.147 425.455 193.436 428.283C193.984 432.963 194.775 437.613 195.805 442.213C196 443.154 196.612 444.372 195.539 444.845C194.69 445.223 193.179 444.482 192.446 444.214C190.271 443.418 188.59 442.048 186.496 441.06C184.462 440.099 182.311 439.587 180.258 438.626C178.086 437.608 175.962 436.688 173.873 435.508C170.674 433.702 167.124 432.29 163.777 430.879C162.096 430.169 160.33 429.499 158.732 428.768C157.466 428.19 154.646 427.522 153.941 426.308L153.809 426.179Z'
                  opacity='.4' />
            <path id='Vector_756' fill='white'
                  d='M202.915 457.27C209.978 452.646 211.955 443.171 207.331 436.109C202.706 429.046 193.232 427.069 186.169 431.693C179.107 436.317 177.13 445.791 181.754 452.854C186.378 459.917 195.852 461.894 202.915 457.27Z'
                  opacity='.1' />
            <path id='Vector_757' fill='white'
                  d='M207.176 445.322C206.896 447.57 205.956 449.685 204.475 451.399C202.994 453.113 201.037 454.349 198.853 454.952C196.669 455.554 194.356 455.495 192.205 454.782C190.055 454.069 188.164 452.735 186.772 450.948C185.38 449.16 184.549 447 184.384 444.741C184.22 442.481 184.729 440.224 185.848 438.254C186.966 436.284 188.644 434.689 190.668 433.673C192.693 432.656 194.973 432.263 197.222 432.542C200.236 432.917 202.979 434.474 204.845 436.871C206.712 439.267 207.55 442.307 207.176 445.322Z'
                  opacity='.2' />
            <path id='Vector_758' fill='white'
                  d='M196.99 436.353C196.088 439.728 196.036 444.108 189.908 443.868C191.601 444.94 193.516 445.932 194.56 447.79C195.528 449.705 196.035 451.824 196.041 453.975C196.628 452.019 196.399 450.095 197.873 448.21C198.409 447.51 199.095 446.942 199.88 446.55C200.665 446.158 201.528 445.951 202.404 445.946C200.598 444.617 198.89 444.575 198.002 442.086C197.652 441.143 197.383 440.172 197.199 439.182C197.033 438.183 197.315 436.705 196.779 435.926L196.99 436.353Z'
                  opacity='.7' />
            <path id='Vector_759' fill='white'
                  d='M198.115 444.641C197.682 443.242 197.214 443.405 196.811 444.002C194.785 442.991 195.077 449.879 197.437 448.138C198.237 447.549 198.383 445.506 198.115 444.641Z' />
            <path id='Vector_760' fill='white'
                  d='M162.057 427.881C161.795 429.993 160.912 431.979 159.52 433.59C158.129 435.2 156.291 436.361 154.239 436.927C152.187 437.493 150.014 437.437 147.994 436.768C145.974 436.098 144.197 434.845 142.89 433.166C141.582 431.487 140.802 429.458 140.647 427.335C140.492 425.213 140.971 423.092 142.021 421.241C143.072 419.39 144.648 417.893 146.55 416.938C148.452 415.983 150.594 415.613 152.706 415.876C155.538 416.228 158.114 417.69 159.868 419.942C161.621 422.193 162.409 425.049 162.057 427.881Z'
                  opacity='.1' />
            <path id='Vector_761' fill='white'
                  d='M160.69 427.54C160.494 429.107 159.839 430.581 158.807 431.775C157.774 432.969 156.41 433.831 154.888 434.25C153.366 434.67 151.754 434.628 150.255 434.132C148.757 433.635 147.439 432.705 146.469 431.459C145.499 430.214 144.92 428.708 144.805 427.134C144.69 425.559 145.045 423.986 145.824 422.613C146.604 421.24 147.773 420.128 149.184 419.42C150.595 418.711 152.184 418.437 153.751 418.631C154.791 418.76 155.796 419.093 156.708 419.611C157.62 420.129 158.421 420.821 159.066 421.648C159.71 422.476 160.185 423.422 160.464 424.433C160.743 425.444 160.819 426.5 160.69 427.54Z'
                  opacity='.2' />
            <path id='Vector_762' fill='white'
                  d='M153.37 420.95C152.718 423.287 152.681 426.319 148.255 426.152C149.477 426.894 150.861 427.58 151.614 428.867C152.314 430.193 152.68 431.66 152.684 433.149C153.109 431.794 152.943 430.463 154.007 429.157C154.394 428.673 154.89 428.28 155.457 428.009C156.024 427.737 156.647 427.594 157.28 427.59C155.976 426.67 154.742 426.641 154.101 424.918C153.848 424.265 153.654 423.593 153.521 422.908C153.399 422.216 153.604 421.193 153.217 420.653L153.37 420.95Z'
                  opacity='.7' />
            <path id='Vector_763' fill='white'
                  d='M194.012 387.578C193.818 389.076 193.163 390.487 192.13 391.629C191.098 392.772 189.734 393.596 188.212 393.998C186.69 394.399 185.077 394.36 183.578 393.885C182.08 393.41 180.762 392.521 179.791 391.329C178.821 390.138 178.242 388.698 178.127 387.191C178.013 385.685 178.367 384.18 179.147 382.866C179.926 381.553 181.096 380.49 182.507 379.812C183.918 379.134 185.507 378.872 187.074 379.058C189.175 379.308 191.087 380.346 192.388 381.944C193.689 383.541 194.273 385.568 194.012 387.578Z'
                  opacity='.1' />
            <path id='Vector_764' fill='white'
                  d='M192.639 387.321C192.496 388.411 192.011 389.437 191.248 390.268C190.485 391.099 189.477 391.698 188.352 391.99C187.227 392.282 186.035 392.254 184.927 391.908C183.819 391.563 182.845 390.916 182.128 390.049C181.411 389.182 180.983 388.135 180.899 387.039C180.814 385.944 181.076 384.849 181.653 383.894C182.229 382.939 183.093 382.166 184.136 381.673C185.179 381.18 186.354 380.99 187.512 381.125C188.281 381.215 189.024 381.447 189.698 381.807C190.372 382.167 190.963 382.648 191.44 383.224C191.916 383.799 192.267 384.457 192.473 385.16C192.678 385.863 192.735 386.597 192.639 387.321Z'
                  opacity='.2' />
            <path id='Vector_765' fill='white'
                  d='M187.202 382.684C186.751 384.372 186.725 386.562 183.66 386.442C184.507 386.978 185.464 387.474 185.986 388.403C186.47 389.361 186.724 390.42 186.726 391.496C187.02 390.518 186.906 389.556 187.642 388.613C187.91 388.263 188.254 387.979 188.646 387.783C189.039 387.587 189.47 387.484 189.908 387.481C189.005 386.817 188.151 386.796 187.707 385.551C187.532 385.08 187.397 384.594 187.306 384.099C187.221 383.6 187.363 382.861 187.095 382.471L187.202 382.684Z'
                  opacity='.7' />
            <path id='Vector_766' fill='white'
                  d='M153.716 426.436C153.391 425.238 153.04 425.377 152.738 425.891C151.219 425.025 151.438 430.927 153.208 429.435C153.809 428.928 153.917 427.178 153.716 426.436Z' />
          </g>
        </g>;

      case 16:
        return <g id='Mini gem_12'>
          <path id='Vector_767' fill={`#${diamondColors[color][0]}`}
                d='M845.228 334.248L882.537 369.052L854.787 395.039L845.227 335.491L809.645 375.874L848.68 432.826L854.787 395.039L845.228 334.248Z' />
          <path id='Vector_768' fill={`#${diamondColors[color][1]}`}
                d='M845.166 334.248L809.645 375.675L854.769 395.339L845.166 334.248Z' />
          <path id='Vector_769' fill='white'
                d='M845.166 334.248L844.175 335.403L853.236 393.048L810.658 374.493L809.645 375.675L854.769 395.339L845.166 334.248Z' />
          <path id='Vector_770' fill={`#${diamondColors[color][2]}`}
                d='M854.657 394.984L848.521 432.826L882.537 368.959L854.657 394.984Z' />
          <path id='Vector_771' fill={`#${diamondColors[color][2]}`}
                d='M854.657 394.984L848.521 432.826L882.537 368.959L854.657 394.984Z' />
          <path id='Vector_772' fill='white' d='M809.645 375.901L849.909 363.726L845.285 334.248L809.645 375.901Z'
                opacity='.2' />
          <path id='Vector_773' fill='white' d='M849.688 363.628L882.537 368.959L845.05 334.248L849.688 363.09V363.628Z'
                opacity='.2' />
          <path id='Vector_774' fill={`#${diamondColors[color][3]}`}
                d='M852.107 410.744C852.174 410.333 871.998 379.136 871.998 379.136L882.537 369.653L858.598 413.953L848.521 432.826L852.107 410.744Z'
                opacity='.5' />
          <path id='Vector_775' fill={`#${diamondColors[color][3]}`}
                d='M855.858 394.984L881.35 371.187L882.537 368.959L854.657 394.984L848.521 432.826L850.246 429.586L855.858 394.984Z' />
          <path id='Vector_776' fill={`#${diamondColors[color][3]}`}
                d='M822.775 395.073L854.074 395.339L809.645 375.901L822.775 395.073Z' opacity='.5' />
          <path id='Vector_777' fill='white'
                d='M811.727 375.179C813.096 376.582 816.464 376.781 818.371 377.146C822.343 378.005 826.439 378.15 830.463 377.577C835.338 376.791 839.168 374.647 842.953 371.63C845.425 369.661 846.402 367.725 847.438 364.925C847.745 364.21 847.986 363.469 848.159 362.711C849.406 363.372 849.24 367.596 849.597 369.002C850.3 371.768 851.066 374.455 851.355 377.283C851.903 381.963 852.694 386.613 853.724 391.213C853.919 392.154 854.531 393.372 853.458 393.845C852.608 394.223 851.098 393.482 850.365 393.214C848.19 392.418 846.509 391.048 844.415 390.06C842.38 389.099 840.23 388.587 838.177 387.626C836.004 386.608 833.881 385.688 831.792 384.508C828.593 382.702 825.042 381.29 821.696 379.879C820.015 379.169 818.249 378.499 816.65 377.768C815.384 377.19 812.565 376.522 811.859 375.308L811.727 375.179Z'
                opacity='.4' />
          <path id='Vector_778' fill='white'
                d='M860.834 406.27C867.896 401.646 869.873 392.171 865.249 385.109C860.625 378.046 851.151 376.069 844.088 380.693C837.025 385.317 835.048 394.791 839.672 401.854C844.297 408.917 853.771 410.894 860.834 406.27Z'
                opacity='.1' />
          <path id='Vector_779' fill='white'
                d='M865.094 394.322C864.815 396.57 863.875 398.685 862.394 400.399C860.912 402.113 858.956 403.349 856.772 403.952C854.588 404.554 852.274 404.495 850.124 403.782C847.973 403.069 846.083 401.735 844.691 399.948C843.298 398.16 842.468 396 842.303 393.741C842.139 391.481 842.648 389.224 843.766 387.254C844.885 385.284 846.563 383.689 848.587 382.673C850.612 381.656 852.892 381.263 855.14 381.542C858.155 381.917 860.897 383.474 862.764 385.871C864.631 388.267 865.469 391.307 865.094 394.322Z'
                opacity='.2' />
          <path id='Vector_780' fill='white'
                d='M854.909 385.353C854.007 388.728 853.955 393.108 847.826 392.868C849.519 393.94 851.435 394.932 852.478 396.79C853.447 398.705 853.954 400.824 853.959 402.975C854.547 401.019 854.318 399.095 855.791 397.21C856.327 396.51 857.014 395.942 857.799 395.55C858.584 395.158 859.447 394.951 860.322 394.946C858.517 393.617 856.809 393.575 855.921 391.086C855.57 390.143 855.302 389.172 855.118 388.182C854.952 387.183 855.234 385.705 854.697 384.926L854.909 385.353Z'
                opacity='.7' />
          <path id='Vector_781' fill='white'
                d='M856.034 393.641C855.601 392.242 855.133 392.405 854.73 393.002C852.704 391.991 852.996 398.879 855.356 397.138C856.156 396.549 856.302 394.506 856.034 393.641Z' />
          <path id='Vector_782' fill='white'
                d='M819.976 376.881C819.713 378.993 818.83 380.979 817.439 382.59C816.047 384.2 814.209 385.361 812.158 385.927C810.106 386.493 807.933 386.437 805.913 385.768C803.892 385.098 802.116 383.845 800.808 382.166C799.501 380.487 798.72 378.458 798.566 376.335C798.411 374.213 798.889 372.092 799.94 370.241C800.991 368.39 802.567 366.893 804.469 365.938C806.371 364.983 808.513 364.613 810.625 364.876C813.457 365.228 816.033 366.69 817.786 368.942C819.54 371.193 820.328 374.049 819.976 376.881Z'
                opacity='.1' />
          <path id='Vector_783' fill='white'
                d='M818.608 376.54C818.413 378.107 817.758 379.581 816.725 380.775C815.693 381.969 814.329 382.831 812.807 383.25C811.285 383.67 809.673 383.629 808.174 383.132C806.675 382.635 805.358 381.705 804.388 380.459C803.417 379.214 802.838 377.708 802.724 376.134C802.609 374.559 802.964 372.986 803.743 371.613C804.522 370.24 805.692 369.128 807.102 368.42C808.513 367.711 810.103 367.437 811.669 367.631C812.71 367.76 813.715 368.093 814.627 368.611C815.539 369.129 816.34 369.821 816.985 370.648C817.629 371.476 818.104 372.422 818.383 373.433C818.661 374.444 818.738 375.5 818.608 376.54Z'
                opacity='.2' />
          <path id='Vector_784' fill='white'
                d='M811.289 369.95C810.637 372.287 810.6 375.319 806.174 375.152C807.396 375.894 808.779 376.58 809.533 377.867C810.232 379.193 810.599 380.66 810.603 382.149C811.027 380.794 810.862 379.463 811.926 378.157C812.313 377.673 812.809 377.28 813.376 377.009C813.943 376.737 814.566 376.594 815.198 376.59C813.895 375.67 812.661 375.641 812.019 373.918C811.766 373.265 811.573 372.593 811.439 371.908C811.317 371.216 811.523 370.193 811.136 369.653L811.289 369.95Z'
                opacity='.7' />
          <path id='Vector_785' fill='white'
                d='M851.931 336.578C851.736 338.077 851.081 339.487 850.049 340.629C849.016 341.772 847.653 342.596 846.131 342.998C844.609 343.399 842.996 343.36 841.497 342.885C839.998 342.41 838.68 341.521 837.71 340.329C836.74 339.138 836.161 337.698 836.046 336.191C835.931 334.685 836.286 333.18 837.066 331.866C837.845 330.553 839.014 329.49 840.425 328.812C841.837 328.134 843.426 327.872 844.993 328.058C847.094 328.308 849.005 329.346 850.306 330.944C851.608 332.541 852.192 334.568 851.931 336.578Z'
                opacity='.1' />
          <path id='Vector_786' fill='white'
                d='M850.558 336.321C850.414 337.411 849.93 338.437 849.167 339.268C848.404 340.099 847.396 340.698 846.271 340.99C845.146 341.282 843.954 341.254 842.846 340.908C841.738 340.563 840.764 339.916 840.047 339.049C839.33 338.182 838.902 337.135 838.817 336.039C838.733 334.944 838.995 333.849 839.571 332.894C840.148 331.939 841.012 331.166 842.055 330.673C843.098 330.18 844.273 329.99 845.431 330.125C846.2 330.215 846.943 330.447 847.616 330.807C848.29 331.167 848.882 331.648 849.358 332.224C849.834 332.799 850.185 333.457 850.391 334.16C850.597 334.863 850.654 335.597 850.558 336.321Z'
                opacity='.2' />
          <path id='Vector_787' fill='white'
                d='M845.121 331.684C844.67 333.372 844.644 335.562 841.579 335.442C842.425 335.979 843.383 336.474 843.904 337.403C844.389 338.361 844.643 339.42 844.645 340.496C844.939 339.518 844.824 338.556 845.561 337.613C845.829 337.263 846.172 336.979 846.565 336.783C846.957 336.587 847.389 336.484 847.826 336.481C846.924 335.817 846.069 335.796 845.625 334.551C845.45 334.08 845.316 333.594 845.224 333.099C845.14 332.6 845.282 331.861 845.014 331.471L845.121 331.684Z'
                opacity='.7' />
          <path id='Vector_788' fill='white'
                d='M811.635 375.436C811.31 374.238 810.959 374.377 810.657 374.891C809.138 374.025 809.356 379.927 811.127 378.435C811.727 377.928 811.836 376.178 811.635 375.436Z' />
        </g>;


      case 17:
        return <g id='Mini gem_13'>
          <path id='Vector_789' fill={`#${diamondColors[color][0]}`}
                d='M633.228 425.248L670.537 460.052L642.787 486.039L633.227 426.491L597.645 466.874L636.68 523.826L642.787 486.039L633.228 425.248Z' />
          <path id='Vector_790' fill={`#${diamondColors[color][1]}`}
                d='M633.166 425.248L597.645 466.675L642.769 486.339L633.166 425.248Z' />
          <path id='Vector_791' fill='white'
                d='M633.166 425.248L632.175 426.403L641.236 484.048L598.658 465.493L597.645 466.675L642.769 486.339L633.166 425.248Z' />
          <path id='Vector_792' fill={`#${diamondColors[color][2]}`}
                d='M642.657 485.984L636.521 523.826L670.537 459.959L642.657 485.984Z' />
          <path id='Vector_793' fill={`#${diamondColors[color][2]}`}
                d='M642.657 485.984L636.521 523.826L670.537 459.959L642.657 485.984Z' />
          <path id='Vector_794' fill='white' d='M597.645 466.901L637.909 454.726L633.285 425.248L597.645 466.901Z'
                opacity='.2' />
          <path id='Vector_795' fill='white' d='M637.688 454.628L670.537 459.959L633.05 425.248L637.688 454.09V454.628Z'
                opacity='.2' />
          <path id='Vector_796' fill={`#${diamondColors[color][3]}`}
                d='M640.107 501.744C640.174 501.333 659.998 470.136 659.998 470.136L670.537 460.653L646.598 504.953L636.521 523.826L640.107 501.744Z'
                opacity='.5' />
          <path id='Vector_797' fill={`#${diamondColors[color][3]}`}
                d='M643.858 485.984L669.35 462.187L670.537 459.959L642.657 485.984L636.521 523.826L638.246 520.586L643.858 485.984Z' />
          <path id='Vector_798' fill={`#${diamondColors[color][3]}`}
                d='M610.775 486.073L642.074 486.339L597.645 466.901L610.775 486.073Z' opacity='.5' />
          <path id='Vector_799' fill='white'
                d='M599.727 466.179C601.096 467.582 604.464 467.781 606.371 468.146C610.343 469.005 614.439 469.15 618.463 468.577C623.338 467.791 627.168 465.647 630.953 462.63C633.425 460.661 634.402 458.725 635.438 455.925C635.745 455.21 635.986 454.469 636.159 453.711C637.406 454.372 637.24 458.596 637.597 460.002C638.3 462.768 639.066 465.455 639.355 468.283C639.903 472.963 640.694 477.613 641.724 482.213C641.919 483.154 642.531 484.372 641.458 484.845C640.608 485.223 639.098 484.482 638.365 484.214C636.19 483.418 634.509 482.048 632.415 481.06C630.381 480.099 628.23 479.587 626.177 478.626C624.004 477.608 621.881 476.688 619.792 475.508C616.593 473.702 613.043 472.29 609.696 470.879C608.015 470.169 606.249 469.499 604.65 468.768C603.384 468.19 600.565 467.522 599.859 466.308L599.727 466.179Z'
                opacity='.4' />
          <path id='Vector_800' fill='white'
                d='M648.834 497.27C655.897 492.646 657.873 483.171 653.249 476.109C648.625 469.046 639.151 467.069 632.088 471.693C625.025 476.317 623.048 485.791 627.673 492.854C632.297 499.917 641.771 501.894 648.834 497.27Z'
                opacity='.1' />
          <path id='Vector_801' fill='white'
                d='M653.094 485.322C652.815 487.57 651.875 489.685 650.394 491.399C648.912 493.113 646.956 494.349 644.772 494.952C642.588 495.554 640.274 495.495 638.124 494.782C635.973 494.069 634.083 492.735 632.691 490.948C631.298 489.16 630.468 487 630.303 484.741C630.139 482.481 630.648 480.224 631.766 478.254C632.885 476.284 634.563 474.689 636.587 473.673C638.612 472.656 640.892 472.263 643.14 472.542C646.155 472.917 648.897 474.474 650.764 476.871C652.631 479.267 653.469 482.307 653.094 485.322Z'
                opacity='.2' />
          <path id='Vector_802' fill='white'
                d='M642.909 476.353C642.007 479.728 641.955 484.108 635.827 483.868C637.519 484.94 639.435 485.932 640.478 487.79C641.447 489.705 641.954 491.824 641.959 493.975C642.547 492.019 642.318 490.095 643.792 488.21C644.327 487.51 645.014 486.942 645.799 486.55C646.584 486.158 647.447 485.951 648.322 485.946C646.517 484.617 644.809 484.575 643.921 482.086C643.57 481.143 643.302 480.172 643.118 479.182C642.952 478.183 643.234 476.705 642.697 475.926L642.909 476.353Z'
                opacity='.7' />
          <path id='Vector_803' fill='white'
                d='M644.034 484.641C643.601 483.242 643.133 483.405 642.73 484.002C640.704 482.991 640.996 489.879 643.356 488.138C644.156 487.549 644.302 485.506 644.034 484.641Z' />
          <path id='Vector_804' fill='white'
                d='M607.976 467.881C607.713 469.993 606.83 471.979 605.439 473.59C604.047 475.2 602.21 476.361 600.158 476.927C598.106 477.493 595.933 477.437 593.913 476.768C591.892 476.098 590.116 474.845 588.808 473.166C587.501 471.487 586.72 469.458 586.566 467.335C586.411 465.213 586.889 463.092 587.94 461.241C588.991 459.39 590.567 457.893 592.469 456.938C594.371 455.983 596.513 455.613 598.625 455.876C601.457 456.228 604.033 457.69 605.787 459.942C607.54 462.193 608.328 465.049 607.976 467.881Z'
                opacity='.1' />
          <path id='Vector_805' fill='white'
                d='M606.608 467.54C606.413 469.107 605.758 470.581 604.725 471.775C603.693 472.969 602.329 473.831 600.807 474.25C599.285 474.67 597.673 474.629 596.174 474.132C594.675 473.635 593.358 472.705 592.388 471.459C591.417 470.214 590.838 468.708 590.724 467.134C590.609 465.559 590.964 463.986 591.743 462.613C592.523 461.24 593.692 460.128 595.102 459.42C596.513 458.711 598.103 458.437 599.669 458.631C600.71 458.76 601.715 459.093 602.627 459.611C603.539 460.129 604.34 460.821 604.985 461.648C605.629 462.476 606.104 463.422 606.383 464.433C606.661 465.444 606.738 466.5 606.608 467.54Z'
                opacity='.2' />
          <path id='Vector_806' fill='white'
                d='M599.289 460.95C598.637 463.287 598.6 466.319 594.174 466.152C595.396 466.894 596.779 467.58 597.533 468.867C598.232 470.193 598.599 471.66 598.603 473.149C599.027 471.794 598.862 470.463 599.926 469.157C600.313 468.673 600.809 468.28 601.376 468.009C601.943 467.737 602.566 467.594 603.198 467.59C601.895 466.67 600.661 466.641 600.019 464.918C599.767 464.265 599.573 463.593 599.44 462.908C599.317 462.216 599.523 461.193 599.136 460.653L599.289 460.95Z'
                opacity='.7' />
          <path id='Vector_807' fill='white'
                d='M639.931 427.578C639.736 429.077 639.081 430.487 638.049 431.629C637.016 432.772 635.653 433.596 634.131 433.998C632.609 434.399 630.996 434.36 629.497 433.885C627.998 433.41 626.68 432.521 625.71 431.329C624.74 430.138 624.161 428.698 624.046 427.191C623.931 425.685 624.286 424.18 625.066 422.866C625.845 421.553 627.014 420.49 628.426 419.812C629.837 419.134 631.426 418.872 632.993 419.058C635.094 419.308 637.005 420.346 638.307 421.944C639.608 423.541 640.192 425.568 639.931 427.578Z'
                opacity='.1' />
          <path id='Vector_808' fill='white'
                d='M638.558 427.321C638.414 428.411 637.93 429.437 637.167 430.268C636.404 431.099 635.396 431.698 634.271 431.99C633.146 432.282 631.954 432.254 630.846 431.908C629.738 431.563 628.764 430.916 628.047 430.049C627.33 429.182 626.902 428.135 626.817 427.039C626.733 425.944 626.995 424.849 627.571 423.894C628.148 422.939 629.012 422.166 630.055 421.673C631.098 421.18 632.273 420.99 633.431 421.125C634.2 421.215 634.943 421.447 635.617 421.807C636.29 422.167 636.882 422.648 637.358 423.224C637.834 423.799 638.185 424.457 638.391 425.16C638.597 425.863 638.654 426.597 638.558 427.321Z'
                opacity='.2' />
          <path id='Vector_809' fill='white'
                d='M633.121 422.684C632.67 424.372 632.644 426.562 629.579 426.442C630.425 426.979 631.383 427.474 631.904 428.403C632.389 429.361 632.643 430.42 632.645 431.496C632.939 430.518 632.824 429.556 633.561 428.613C633.829 428.263 634.172 427.979 634.565 427.783C634.957 427.587 635.389 427.484 635.827 427.481C634.924 426.817 634.07 426.796 633.625 425.551C633.45 425.08 633.316 424.594 633.224 424.099C633.14 423.6 633.282 422.861 633.014 422.471L633.121 422.684Z'
                opacity='.7' />
          <path id='Vector_810' fill='white'
                d='M599.635 466.436C599.31 465.238 598.959 465.377 598.657 465.891C597.138 465.025 597.356 470.927 599.127 469.435C599.727 468.928 599.836 467.178 599.635 466.436Z' />
        </g>;


      case 18 :
        return <g id='Mini gem_14'>
          <path id='Vector_811' fill={`#${diamondColors[color][0]}`}
                d='M745.228 549.248L782.537 584.052L754.787 610.039L745.227 550.49L709.645 590.874L748.68 647.826L754.787 610.039L745.228 549.248Z' />
          <path id='Vector_812' fill={`#${diamondColors[color][1]}`}
                d='M745.166 549.248L709.645 590.675L754.769 610.339L745.166 549.248Z' />
          <path id='Vector_813' fill='white'
                d='M745.166 549.248L744.175 550.403L753.236 608.048L710.658 589.493L709.645 590.675L754.769 610.339L745.166 549.248Z' />
          <path id='Vector_814' fill={`#${diamondColors[color][2]}`}
                d='M754.657 609.983L748.521 647.826L782.537 583.959L754.657 609.983Z' />
          <path id='Vector_815' fill={`#${diamondColors[color][2]}`}
                d='M754.657 609.983L748.521 647.826L782.537 583.959L754.657 609.983Z' />
          <path id='Vector_816' fill='white' d='M709.645 590.901L749.909 578.726L745.285 549.248L709.645 590.901Z'
                opacity='.2' />
          <path id='Vector_817' fill='white' d='M749.688 578.628L782.537 583.959L745.05 549.248L749.688 578.09V578.628Z'
                opacity='.2' />
          <path id='Vector_818' fill={`#${diamondColors[color][3]}`}
                d='M752.107 625.744C752.174 625.333 771.998 594.136 771.998 594.136L782.537 584.653L758.598 628.953L748.521 647.826L752.107 625.744Z'
                opacity='.5' />
          <path id='Vector_819' fill={`#${diamondColors[color][3]}`}
                d='M755.858 609.984L781.35 586.187L782.537 583.959L754.657 609.984L748.521 647.826L750.246 644.585L755.858 609.984Z' />
          <path id='Vector_820' fill={`#${diamondColors[color][3]}`}
                d='M722.775 610.073L754.074 610.339L709.645 590.901L722.775 610.073Z' opacity='.5' />
          <path id='Vector_821' fill='white'
                d='M711.727 590.179C713.096 591.582 716.464 591.78 718.371 592.146C722.343 593.004 726.439 593.15 730.463 592.577C735.338 591.791 739.168 589.647 742.953 586.63C745.425 584.661 746.402 582.725 747.438 579.925C747.745 579.209 747.986 578.468 748.159 577.711C749.406 578.371 749.24 582.595 749.597 584.002C750.3 586.768 751.066 589.455 751.355 592.283C751.903 596.963 752.694 601.613 753.724 606.213C753.919 607.154 754.531 608.371 753.458 608.845C752.608 609.223 751.098 608.482 750.365 608.213C748.19 607.418 746.509 606.048 744.415 605.06C742.381 604.099 740.23 603.587 738.177 602.626C736.004 601.608 733.881 600.688 731.792 599.508C728.593 597.702 725.043 596.29 721.696 594.879C720.015 594.169 718.249 593.499 716.65 592.768C715.384 592.19 712.565 591.522 711.859 590.308L711.727 590.179Z'
                opacity='.4' />
          <path id='Vector_822' fill='white'
                d='M760.834 621.27C767.897 616.645 769.873 607.171 765.249 600.108C760.625 593.046 751.151 591.069 744.088 595.693C737.025 600.317 735.048 609.791 739.673 616.854C744.297 623.917 753.771 625.894 760.834 621.27Z'
                opacity='.1' />
          <path id='Vector_823' fill='white'
                d='M765.094 609.322C764.815 611.57 763.875 613.685 762.394 615.399C760.912 617.113 758.956 618.349 756.772 618.951C754.588 619.554 752.274 619.495 750.124 618.782C747.973 618.069 746.083 616.735 744.691 614.947C743.298 613.16 742.468 611 742.303 608.741C742.139 606.481 742.648 604.224 743.766 602.253C744.885 600.283 746.563 598.689 748.587 597.673C750.612 596.656 752.892 596.262 755.14 596.542C758.155 596.917 760.898 598.474 762.764 600.87C764.631 603.267 765.469 606.307 765.094 609.322Z'
                opacity='.2' />
          <path id='Vector_824' fill='white'
                d='M754.909 600.352C754.007 603.728 753.955 608.108 747.827 607.868C749.519 608.94 751.435 609.932 752.478 611.789C753.447 613.705 753.954 615.824 753.959 617.975C754.547 616.019 754.318 614.095 755.792 612.21C756.327 611.51 757.014 610.942 757.799 610.55C758.584 610.158 759.447 609.951 760.322 609.946C758.517 608.617 756.809 608.575 755.921 606.086C755.57 605.143 755.302 604.172 755.118 603.182C754.952 602.183 755.234 600.705 754.697 599.925L754.909 600.352Z'
                opacity='.7' />
          <path id='Vector_825' fill='white'
                d='M756.034 608.64C755.601 607.242 755.133 607.404 754.73 608.002C752.704 606.991 752.996 613.879 755.356 612.138C756.156 611.548 756.302 609.506 756.034 608.64Z' />
          <path id='Vector_826' fill='white'
                d='M719.976 591.88C719.713 593.992 718.83 595.979 717.439 597.589C716.047 599.2 714.21 600.361 712.158 600.927C710.106 601.493 707.933 601.437 705.913 600.768C703.892 600.098 702.116 598.845 700.808 597.166C699.501 595.487 698.72 593.458 698.566 591.335C698.411 589.212 698.889 587.092 699.94 585.241C700.991 583.39 702.567 581.893 704.469 580.938C706.371 579.982 708.513 579.613 710.625 579.875C713.457 580.228 716.033 581.69 717.787 583.942C719.54 586.193 720.328 589.049 719.976 591.88Z'
                opacity='.1' />
          <path id='Vector_827' fill='white'
                d='M718.608 591.54C718.413 593.107 717.758 594.581 716.725 595.775C715.693 596.969 714.329 597.831 712.807 598.25C711.285 598.67 709.673 598.628 708.174 598.132C706.675 597.635 705.358 596.705 704.388 595.459C703.417 594.214 702.838 592.708 702.724 591.134C702.609 589.559 702.964 587.985 703.743 586.612C704.523 585.239 705.692 584.128 707.102 583.42C708.513 582.711 710.103 582.436 711.669 582.631C712.71 582.76 713.715 583.093 714.627 583.611C715.539 584.128 716.34 584.821 716.985 585.648C717.629 586.475 718.104 587.422 718.383 588.433C718.661 589.444 718.738 590.5 718.608 591.54Z'
                opacity='.2' />
          <path id='Vector_828' fill='white'
                d='M711.289 584.95C710.637 587.287 710.6 590.319 706.174 590.152C707.396 590.894 708.779 591.58 709.533 592.866C710.232 594.192 710.599 595.66 710.603 597.149C711.027 595.794 710.862 594.463 711.926 593.157C712.313 592.673 712.809 592.28 713.376 592.009C713.943 591.737 714.566 591.594 715.198 591.59C713.895 590.67 712.661 590.641 712.019 588.918C711.767 588.265 711.573 587.593 711.44 586.908C711.317 586.216 711.523 585.193 711.136 584.653L711.289 584.95Z'
                opacity='.7' />
          <path id='Vector_829' fill='white'
                d='M751.931 551.578C751.736 553.076 751.081 554.486 750.049 555.629C749.016 556.772 747.653 557.596 746.131 557.998C744.609 558.399 742.996 558.36 741.497 557.885C739.998 557.41 738.68 556.52 737.71 555.329C736.74 554.137 736.161 552.697 736.046 551.191C735.931 549.685 736.286 548.18 737.066 546.866C737.845 545.553 739.014 544.49 740.426 543.812C741.837 543.134 743.426 542.872 744.993 543.058C747.094 543.308 749.005 544.346 750.306 545.944C751.608 547.541 752.192 549.568 751.931 551.578Z'
                opacity='.1' />
          <path id='Vector_830' fill='white'
                d='M750.558 551.321C750.414 552.411 749.93 553.437 749.167 554.268C748.404 555.099 747.396 555.698 746.271 555.99C745.146 556.282 743.954 556.254 742.846 555.908C741.738 555.562 740.764 554.915 740.047 554.049C739.33 553.182 738.902 552.135 738.817 551.039C738.733 549.944 738.995 548.849 739.571 547.894C740.148 546.938 741.012 546.166 742.055 545.673C743.098 545.18 744.273 544.989 745.431 545.125C746.2 545.215 746.943 545.446 747.616 545.806C748.29 546.167 748.882 546.648 749.358 547.223C749.834 547.799 750.185 548.457 750.391 549.16C750.597 549.863 750.654 550.597 750.558 551.321Z'
                opacity='.2' />
          <path id='Vector_831' fill='white'
                d='M745.121 546.684C744.67 548.372 744.644 550.562 741.579 550.442C742.425 550.978 743.383 551.474 743.904 552.403C744.389 553.36 744.643 554.42 744.645 555.496C744.939 554.518 744.824 553.556 745.561 552.613C745.829 552.263 746.172 551.979 746.565 551.783C746.957 551.587 747.389 551.484 747.827 551.481C746.924 550.816 746.07 550.796 745.625 549.551C745.45 549.08 745.316 548.594 745.224 548.099C745.14 547.599 745.282 546.861 745.014 546.471L745.121 546.684Z'
                opacity='.7' />
          <path id='Vector_832' fill='white'
                d='M711.635 590.436C711.31 589.238 710.959 589.377 710.657 589.891C709.138 589.025 709.356 594.927 711.127 593.435C711.727 592.928 711.836 591.178 711.635 590.436Z' />
        </g>;


      case 19 :
        return <g id='Mini gem_15'>
          <path id='Vector_833' fill={`#${diamondColors[color][0]}`}
                d='M652.228 301.248L689.537 336.052L661.787 362.039L652.227 302.491L616.645 342.874L655.68 399.826L661.787 362.039L652.228 301.248Z' />
          <path id='Vector_834' fill={`#${diamondColors[color][1]}`}
                d='M652.166 301.248L616.645 342.675L661.769 362.339L652.166 301.248Z' />
          <path id='Vector_835' fill='white'
                d='M652.166 301.248L651.175 302.403L660.236 360.048L617.658 341.493L616.645 342.675L661.769 362.339L652.166 301.248Z' />
          <path id='Vector_836' fill={`#${diamondColors[color][2]}`}
                d='M661.657 361.984L655.521 399.826L689.537 335.959L661.657 361.984Z' />
          <path id='Vector_837' fill={`#${diamondColors[color][2]}`}
                d='M661.657 361.984L655.521 399.826L689.537 335.959L661.657 361.984Z' />
          <path id='Vector_838' fill='white' d='M616.645 342.901L656.909 330.726L652.285 301.248L616.645 342.901Z'
                opacity='.2' />
          <path id='Vector_839' fill='white' d='M656.688 330.628L689.537 335.959L652.05 301.248L656.688 330.09V330.628Z'
                opacity='.2' />
          <path id='Vector_840' fill={`#${diamondColors[color][3]}`}
                d='M659.107 377.744C659.174 377.333 678.998 346.136 678.998 346.136L689.537 336.653L665.598 380.953L655.521 399.826L659.107 377.744Z'
                opacity='.5' />
          <path id='Vector_841' fill={`#${diamondColors[color][3]}`}
                d='M662.858 361.984L688.35 338.187L689.537 335.959L661.657 361.984L655.521 399.826L657.246 396.586L662.858 361.984Z' />
          <path id='Vector_842' fill={`#${diamondColors[color][3]}`}
                d='M629.775 362.073L661.074 362.339L616.645 342.901L629.775 362.073Z' opacity='.5' />
          <path id='Vector_843' fill='white'
                d='M618.727 342.179C620.096 343.582 623.464 343.781 625.371 344.146C629.343 345.005 633.439 345.15 637.463 344.577C642.338 343.791 646.168 341.647 649.953 338.63C652.425 336.661 653.402 334.725 654.438 331.925C654.745 331.21 654.986 330.469 655.159 329.711C656.406 330.372 656.24 334.596 656.597 336.002C657.3 338.768 658.066 341.455 658.355 344.283C658.903 348.963 659.694 353.613 660.724 358.213C660.919 359.154 661.531 360.372 660.458 360.845C659.608 361.223 658.098 360.482 657.365 360.214C655.19 359.418 653.509 358.048 651.415 357.06C649.381 356.099 647.23 355.587 645.177 354.626C643.004 353.608 640.881 352.688 638.792 351.508C635.593 349.702 632.043 348.29 628.696 346.879C627.015 346.169 625.249 345.499 623.65 344.768C622.384 344.19 619.565 343.522 618.859 342.308L618.727 342.179Z'
                opacity='.4' />
          <path id='Vector_844' fill='white'
                d='M667.834 373.27C674.897 368.646 676.873 359.171 672.249 352.109C667.625 345.046 658.151 343.069 651.088 347.693C644.025 352.317 642.048 361.791 646.673 368.854C651.297 375.917 660.771 377.894 667.834 373.27Z'
                opacity='.1' />
          <path id='Vector_845' fill='white'
                d='M672.094 361.322C671.815 363.57 670.875 365.685 669.394 367.399C667.912 369.113 665.956 370.349 663.772 370.952C661.588 371.554 659.274 371.495 657.124 370.782C654.973 370.069 653.083 368.735 651.691 366.948C650.298 365.16 649.468 363 649.303 360.741C649.139 358.481 649.648 356.224 650.766 354.254C651.885 352.284 653.563 350.689 655.587 349.673C657.612 348.656 659.892 348.263 662.14 348.542C665.155 348.917 667.897 350.474 669.764 352.871C671.631 355.267 672.469 358.307 672.094 361.322Z'
                opacity='.2' />
          <path id='Vector_846' fill='white'
                d='M661.909 352.353C661.007 355.728 660.955 360.108 654.827 359.868C656.519 360.94 658.435 361.932 659.478 363.79C660.447 365.705 660.954 367.824 660.959 369.975C661.547 368.019 661.318 366.095 662.792 364.21C663.327 363.51 664.014 362.942 664.799 362.55C665.584 362.158 666.447 361.951 667.322 361.946C665.517 360.617 663.809 360.575 662.921 358.086C662.57 357.143 662.302 356.172 662.118 355.182C661.952 354.183 662.234 352.705 661.697 351.926L661.909 352.353Z'
                opacity='.7' />
          <path id='Vector_847' fill='white'
                d='M663.034 360.641C662.601 359.242 662.133 359.405 661.73 360.002C659.704 358.991 659.996 365.879 662.356 364.138C663.156 363.549 663.302 361.506 663.034 360.641Z' />
          <path id='Vector_848' fill='white'
                d='M626.976 343.881C626.713 345.993 625.83 347.979 624.439 349.59C623.047 351.2 621.21 352.361 619.158 352.927C617.106 353.493 614.933 353.437 612.913 352.768C610.892 352.098 609.116 350.845 607.808 349.166C606.501 347.487 605.72 345.458 605.566 343.335C605.411 341.213 605.889 339.092 606.94 337.241C607.991 335.39 609.567 333.893 611.469 332.938C613.371 331.983 615.513 331.613 617.625 331.876C620.457 332.228 623.033 333.69 624.787 335.942C626.54 338.193 627.328 341.049 626.976 343.881Z'
                opacity='.1' />
          <path id='Vector_849' fill='white'
                d='M625.608 343.54C625.413 345.107 624.758 346.581 623.725 347.775C622.693 348.969 621.329 349.831 619.807 350.25C618.285 350.67 616.673 350.629 615.174 350.132C613.675 349.635 612.358 348.705 611.388 347.459C610.417 346.214 609.838 344.708 609.724 343.134C609.609 341.559 609.964 339.986 610.743 338.613C611.523 337.24 612.692 336.128 614.102 335.42C615.513 334.711 617.103 334.437 618.669 334.631C619.71 334.76 620.715 335.093 621.627 335.611C622.539 336.129 623.34 336.821 623.985 337.648C624.629 338.476 625.104 339.422 625.383 340.433C625.661 341.444 625.738 342.5 625.608 343.54Z'
                opacity='.2' />
          <path id='Vector_850' fill='white'
                d='M618.289 336.95C617.637 339.287 617.6 342.319 613.174 342.152C614.396 342.894 615.779 343.58 616.533 344.867C617.232 346.193 617.599 347.66 617.603 349.149C618.027 347.794 617.862 346.463 618.926 345.157C619.313 344.673 619.809 344.28 620.376 344.009C620.943 343.737 621.566 343.594 622.198 343.59C620.895 342.67 619.661 342.641 619.019 340.918C618.767 340.265 618.573 339.593 618.44 338.908C618.317 338.216 618.523 337.193 618.136 336.653L618.289 336.95Z'
                opacity='.7' />
          <path id='Vector_851' fill='white'
                d='M658.931 303.578C658.736 305.077 658.081 306.487 657.049 307.629C656.016 308.772 654.653 309.596 653.131 309.998C651.609 310.399 649.996 310.36 648.497 309.885C646.998 309.41 645.68 308.521 644.71 307.329C643.74 306.138 643.161 304.698 643.046 303.191C642.931 301.685 643.286 300.18 644.066 298.866C644.845 297.553 646.014 296.49 647.426 295.812C648.837 295.134 650.426 294.872 651.993 295.058C654.094 295.308 656.005 296.346 657.307 297.944C658.608 299.541 659.192 301.568 658.931 303.578Z'
                opacity='.1' />
          <path id='Vector_852' fill='white'
                d='M657.558 303.321C657.414 304.411 656.93 305.437 656.167 306.268C655.404 307.099 654.396 307.698 653.271 307.99C652.146 308.282 650.954 308.254 649.846 307.908C648.738 307.563 647.764 306.916 647.047 306.049C646.33 305.182 645.902 304.135 645.817 303.039C645.733 301.944 645.995 300.849 646.571 299.894C647.148 298.939 648.012 298.166 649.055 297.673C650.098 297.18 651.273 296.99 652.431 297.125C653.2 297.215 653.943 297.447 654.617 297.807C655.29 298.167 655.882 298.648 656.358 299.224C656.834 299.799 657.185 300.457 657.391 301.16C657.597 301.863 657.654 302.597 657.558 303.321Z'
                opacity='.2' />
          <path id='Vector_853' fill='white'
                d='M652.121 298.684C651.67 300.372 651.644 302.562 648.579 302.442C649.425 302.979 650.383 303.474 650.904 304.403C651.389 305.361 651.643 306.42 651.645 307.496C651.939 306.518 651.824 305.556 652.561 304.613C652.829 304.263 653.172 303.979 653.565 303.783C653.957 303.587 654.389 303.484 654.827 303.481C653.924 302.817 653.07 302.796 652.625 301.551C652.45 301.08 652.316 300.594 652.224 300.099C652.14 299.6 652.282 298.861 652.014 298.471L652.121 298.684Z'
                opacity='.7' />
          <path id='Vector_854' fill='white'
                d='M618.635 342.436C618.31 341.238 617.959 341.377 617.657 341.891C616.138 341.025 616.356 346.927 618.127 345.435C618.727 344.928 618.836 343.178 618.635 342.436Z' />
        </g>;



      case 20 :
        return <g id='Mini gem'>
          <path id='Vector_273' fill={`#${diamondColors[color][0]}`}
                d='M1156.69 111.248L1194 146.052L1166.25 172.039L1156.69 112.49L1121.11 152.874L1160.14 209.826L1166.25 172.039L1156.69 111.248Z' />
          <path id='Vector_274' fill={`#${diamondColors[color][1]}`}
                d='M1156.63 111.248L1121.11 152.675L1166.23 172.339L1156.63 111.248Z' />
          <path id='Vector_275' fill='white'
                d='M1156.63 111.248L1155.64 112.403L1164.7 170.048L1122.12 151.493L1121.11 152.675L1166.23 172.339L1156.63 111.248Z' />
          <path id='Vector_276' fill={`#${diamondColors[color][2]}`}
                d='M1166.12 171.983L1159.98 209.826L1194 145.959L1166.12 171.983Z' />
          <path id='Vector_277' fill={`#${diamondColors[color][2]}`}
                d='M1166.12 171.983L1159.98 209.826L1194 145.959L1166.12 171.983Z' />
          <path id='Vector_278' fill='white' d='M1121.11 152.901L1161.37 140.726L1156.75 111.248L1121.11 152.901Z'
                opacity='.2' />
          <path id='Vector_279' fill='white' d='M1161.15 140.628L1194 145.959L1156.51 111.248L1161.15 140.09V140.628Z'
                opacity='.2' />
          <path id='Vector_280' fill={`#${diamondColors[color][3]}`}
                d='M1163.57 187.744C1163.64 187.333 1183.46 156.136 1183.46 156.136L1194 146.653L1170.06 190.953L1159.98 209.826L1163.57 187.744Z'
                opacity='.5' />
          <path id='Vector_281' fill={`#${diamondColors[color][3]}`}
                d='M1167.32 171.984L1192.81 148.187L1194 145.959L1166.12 171.984L1159.98 209.826L1161.71 206.585L1167.32 171.984Z' />
          <path id='Vector_282' fill={`#${diamondColors[color][3]}`}
                d='M1134.24 172.073L1165.54 172.339L1121.11 152.901L1134.24 172.073Z' opacity='.5' />
          <path id='Vector_283' fill='white'
                d='M1123.19 152.179C1124.56 153.582 1127.93 153.781 1129.83 154.146C1133.81 155.004 1137.9 155.15 1141.93 154.577C1146.8 153.791 1150.63 151.647 1154.42 148.63C1156.89 146.661 1157.87 144.725 1158.9 141.925C1159.21 141.21 1159.45 140.469 1159.62 139.711C1160.87 140.372 1160.7 144.596 1161.06 146.002C1161.76 148.768 1162.53 151.455 1162.82 154.283C1163.37 158.963 1164.16 163.613 1165.19 168.213C1165.38 169.154 1165.99 170.372 1164.92 170.845C1164.07 171.223 1162.56 170.482 1161.83 170.214C1159.65 169.418 1157.97 168.048 1155.88 167.06C1153.84 166.099 1151.69 165.587 1149.64 164.626C1147.47 163.608 1145.34 162.688 1143.25 161.508C1140.06 159.702 1136.51 158.29 1133.16 156.879C1131.48 156.169 1129.71 155.499 1128.11 154.768C1126.85 154.19 1124.03 153.522 1123.32 152.308L1123.19 152.179Z'
                opacity='.4' />
          <path id='Vector_284' fill='white'
                d='M1172.3 183.27C1179.36 178.646 1181.34 169.171 1176.71 162.109C1172.09 155.046 1162.61 153.069 1155.55 157.693C1148.49 162.317 1146.51 171.791 1151.14 178.854C1155.76 185.917 1165.23 187.894 1172.3 183.27Z'
                opacity='.1' />
          <path id='Vector_285' fill='white'
                d='M1176.56 171.322C1176.28 173.57 1175.34 175.685 1173.86 177.399C1172.38 179.113 1170.42 180.349 1168.23 180.952C1166.05 181.554 1163.74 181.495 1161.59 180.782C1159.44 180.069 1157.55 178.735 1156.15 176.948C1154.76 175.16 1153.93 173 1153.77 170.741C1153.6 168.481 1154.11 166.224 1155.23 164.254C1156.35 162.284 1158.03 160.689 1160.05 159.673C1162.07 158.656 1164.35 158.263 1166.6 158.542C1169.62 158.917 1172.36 160.474 1174.23 162.871C1176.09 165.267 1176.93 168.307 1176.56 171.322Z'
                opacity='.2' />
          <path id='Vector_286' fill='white'
                d='M1166.37 162.353C1165.47 165.728 1165.42 170.108 1159.29 169.868C1160.98 170.94 1162.9 171.932 1163.94 173.79C1164.91 175.705 1165.42 177.824 1165.42 179.975C1166.01 178.019 1165.78 176.095 1167.25 174.21C1167.79 173.51 1168.48 172.942 1169.26 172.55C1170.05 172.158 1170.91 171.951 1171.79 171.946C1169.98 170.617 1168.27 170.575 1167.38 168.086C1167.03 167.143 1166.76 166.172 1166.58 165.182C1166.41 164.183 1166.7 162.705 1166.16 161.926L1166.37 162.353Z'
                opacity='.7' />
          <path id='Vector_287' fill='white'
                d='M1167.5 170.641C1167.06 169.242 1166.6 169.405 1166.19 170.002C1164.17 168.991 1164.46 175.879 1166.82 174.138C1167.62 173.549 1167.76 171.506 1167.5 170.641Z' />
          <path id='Vector_288' fill='white'
                d='M1131.44 153.881C1131.18 155.993 1130.29 157.979 1128.9 159.59C1127.51 161.2 1125.67 162.361 1123.62 162.927C1121.57 163.493 1119.4 163.437 1117.38 162.768C1115.36 162.098 1113.58 160.845 1112.27 159.166C1110.96 157.487 1110.18 155.458 1110.03 153.335C1109.87 151.213 1110.35 149.092 1111.4 147.241C1112.45 145.39 1114.03 143.893 1115.93 142.938C1117.83 141.983 1119.98 141.613 1122.09 141.876C1124.92 142.228 1127.5 143.69 1129.25 145.942C1131 148.193 1131.79 151.049 1131.44 153.881Z'
                opacity='.1' />
          <path id='Vector_289' fill='white'
                d='M1130.07 153.54C1129.88 155.107 1129.22 156.581 1128.19 157.775C1127.16 158.969 1125.79 159.831 1124.27 160.25C1122.75 160.67 1121.14 160.628 1119.64 160.132C1118.14 159.635 1116.82 158.705 1115.85 157.459C1114.88 156.214 1114.3 154.708 1114.19 153.134C1114.07 151.559 1114.43 149.986 1115.21 148.613C1115.99 147.24 1117.15 146.128 1118.57 145.42C1119.98 144.711 1121.57 144.437 1123.13 144.631C1124.17 144.76 1125.18 145.093 1126.09 145.611C1127 146.129 1127.8 146.821 1128.45 147.648C1129.09 148.476 1129.57 149.422 1129.85 150.433C1130.12 151.444 1130.2 152.5 1130.07 153.54Z'
                opacity='.2' />
          <path id='Vector_290' fill='white'
                d='M1122.75 146.95C1122.1 149.287 1122.06 152.319 1117.64 152.152C1118.86 152.894 1120.24 153.58 1121 154.867C1121.7 156.193 1122.06 157.66 1122.07 159.149C1122.49 157.794 1122.32 156.463 1123.39 155.157C1123.78 154.673 1124.27 154.28 1124.84 154.009C1125.41 153.737 1126.03 153.594 1126.66 153.59C1125.36 152.67 1124.12 152.641 1123.48 150.918C1123.23 150.265 1123.04 149.593 1122.9 148.908C1122.78 148.216 1122.99 147.193 1122.6 146.653L1122.75 146.95Z'
                opacity='.7' />
          <path id='Vector_291' fill='white'
                d='M1163.39 113.578C1163.2 115.076 1162.54 116.487 1161.51 117.629C1160.48 118.772 1159.12 119.596 1157.59 119.998C1156.07 120.399 1154.46 120.36 1152.96 119.885C1151.46 119.41 1150.14 118.521 1149.17 117.329C1148.2 116.138 1147.62 114.698 1147.51 113.191C1147.39 111.685 1147.75 110.18 1148.53 108.866C1149.31 107.553 1150.48 106.49 1151.89 105.812C1153.3 105.134 1154.89 104.872 1156.46 105.058C1158.56 105.308 1160.47 106.346 1161.77 107.944C1163.07 109.541 1163.65 111.568 1163.39 113.578Z'
                opacity='.1' />
          <path id='Vector_292' fill='white'
                d='M1162.02 113.321C1161.88 114.411 1161.39 115.437 1160.63 116.268C1159.87 117.099 1158.86 117.698 1157.73 117.99C1156.61 118.282 1155.42 118.254 1154.31 117.908C1153.2 117.563 1152.23 116.916 1151.51 116.049C1150.79 115.182 1150.36 114.135 1150.28 113.039C1150.2 111.944 1150.46 110.849 1151.03 109.894C1151.61 108.939 1152.47 108.166 1153.52 107.673C1154.56 107.18 1155.74 106.99 1156.89 107.125C1157.66 107.215 1158.41 107.447 1159.08 107.807C1159.75 108.167 1160.34 108.648 1160.82 109.224C1161.3 109.799 1161.65 110.457 1161.85 111.16C1162.06 111.863 1162.12 112.597 1162.02 113.321Z'
                opacity='.2' />
          <path id='Vector_293' fill='white'
                d='M1156.58 108.684C1156.13 110.372 1156.11 112.562 1153.04 112.442C1153.89 112.978 1154.85 113.474 1155.37 114.403C1155.85 115.36 1156.11 116.42 1156.11 117.496C1156.4 116.518 1156.29 115.556 1157.02 114.613C1157.29 114.263 1157.64 113.979 1158.03 113.783C1158.42 113.587 1158.85 113.484 1159.29 113.481C1158.39 112.817 1157.53 112.796 1157.09 111.551C1156.91 111.08 1156.78 110.594 1156.69 110.099C1156.6 109.6 1156.74 108.861 1156.48 108.471L1156.58 108.684Z'
                opacity='.7' />
          <path id='Vector_294' fill='white'
                d='M1123.1 152.436C1122.77 151.238 1122.42 151.377 1122.12 151.891C1120.6 151.025 1120.82 156.927 1122.59 155.435C1123.19 154.928 1123.3 153.178 1123.1 152.436Z' />
        </g>;


      case 21 :
        return <g id='Mini gem_2'>
          <path id='Vector_295' fill={`#${diamondColors[color][0]}`}
                d='M896.691 146.248L934 181.052L906.25 207.039L896.69 147.49L861.107 187.874L900.143 244.826L906.25 207.039L896.691 146.248Z' />
          <path id='Vector_296' fill={`#${diamondColors[color][1]}`}
                d='M896.629 146.248L861.107 187.675L906.231 207.339L896.629 146.248Z' />
          <path id='Vector_297' fill='white'
                d='M896.629 146.248L895.638 147.403L904.699 205.048L862.121 186.493L861.107 187.675L906.231 207.339L896.629 146.248Z' />
          <path id='Vector_298' fill={`#${diamondColors[color][2]}`}
                d='M906.12 206.983L899.983 244.826L934 180.959L906.12 206.983Z' />
          <path id='Vector_299' fill={`#${diamondColors[color][2]}`}
                d='M906.12 206.983L899.983 244.826L934 180.959L906.12 206.983Z' />
          <path id='Vector_300' fill='white' d='M861.107 187.901L901.372 175.726L896.748 146.248L861.107 187.901Z'
                opacity='.2' />
          <path id='Vector_301' fill='white' d='M901.151 175.628L934 180.959L896.512 146.248L901.151 175.09V175.628Z'
                opacity='.2' />
          <path id='Vector_302' fill={`#${diamondColors[color][3]}`}
                d='M903.57 222.744C903.637 222.333 923.46 191.136 923.46 191.136L934 181.653L910.06 225.953L899.983 244.826L903.57 222.744Z'
                opacity='.5' />
          <path id='Vector_303' fill={`#${diamondColors[color][3]}`}
                d='M907.32 206.984L932.813 183.187L934 180.959L906.12 206.984L899.983 244.826L901.709 241.585L907.32 206.984Z' />
          <path id='Vector_304' fill={`#${diamondColors[color][3]}`}
                d='M874.237 207.073L905.537 207.339L861.107 187.901L874.237 207.073Z' opacity='.5' />
          <path id='Vector_305' fill='white'
                d='M863.19 187.179C864.559 188.582 867.927 188.781 869.833 189.146C873.806 190.004 877.901 190.15 881.925 189.577C886.801 188.791 890.631 186.647 894.416 183.63C896.888 181.661 897.865 179.725 898.901 176.925C899.207 176.21 899.449 175.469 899.622 174.711C900.868 175.372 900.703 179.596 901.06 181.002C901.763 183.768 902.528 186.455 902.817 189.283C903.366 193.963 904.157 198.613 905.187 203.213C905.381 204.154 905.993 205.372 904.92 205.845C904.071 206.223 902.561 205.482 901.828 205.214C899.653 204.418 897.971 203.048 895.878 202.06C893.843 201.099 891.692 200.587 889.64 199.626C887.467 198.608 885.343 197.688 883.255 196.508C880.056 194.702 876.505 193.29 873.159 191.879C871.478 191.169 869.712 190.499 868.113 189.768C866.847 189.19 864.028 188.522 863.322 187.308L863.19 187.179Z'
                opacity='.4' />
          <path id='Vector_306' fill='white'
                d='M912.296 218.27C919.359 213.646 921.336 204.171 916.712 197.109C912.088 190.046 902.614 188.069 895.551 192.693C888.488 197.317 886.511 206.791 891.135 213.854C895.759 220.917 905.234 222.894 912.296 218.27Z'
                opacity='.1' />
          <path id='Vector_307' fill='white'
                d='M916.557 206.322C916.278 208.57 915.338 210.685 913.856 212.399C912.375 214.113 910.419 215.349 908.235 215.952C906.051 216.554 903.737 216.495 901.587 215.782C899.436 215.069 897.545 213.735 896.153 211.948C894.761 210.16 893.93 208 893.766 205.741C893.601 203.481 894.111 201.224 895.229 199.254C896.348 197.284 898.025 195.689 900.05 194.673C902.074 193.656 904.355 193.263 906.603 193.542C909.618 193.917 912.36 195.474 914.227 197.871C916.094 200.267 916.932 203.307 916.557 206.322Z'
                opacity='.2' />
          <path id='Vector_308' fill='white'
                d='M906.372 197.353C905.469 200.728 905.418 205.108 899.289 204.868C900.982 205.94 902.897 206.932 903.941 208.79C904.91 210.705 905.417 212.824 905.422 214.975C906.01 213.019 905.78 211.095 907.254 209.21C907.79 208.51 908.477 207.942 909.262 207.55C910.047 207.158 910.909 206.951 911.785 206.946C909.98 205.617 908.271 205.575 907.383 203.086C907.033 202.143 906.765 201.172 906.581 200.182C906.414 199.183 906.697 197.705 906.16 196.926L906.372 197.353Z'
                opacity='.7' />
          <path id='Vector_309' fill='white'
                d='M907.497 205.641C907.063 204.242 906.596 204.405 906.192 205.002C904.167 203.991 904.459 210.879 906.819 209.138C907.619 208.549 907.765 206.506 907.497 205.641Z' />
          <path id='Vector_310' fill='white'
                d='M871.439 188.881C871.176 190.993 870.293 192.979 868.902 194.59C867.51 196.2 865.672 197.361 863.621 197.927C861.569 198.493 859.396 198.437 857.375 197.768C855.355 197.098 853.579 195.845 852.271 194.166C850.963 192.487 850.183 190.458 850.028 188.335C849.874 186.213 850.352 184.092 851.403 182.241C852.454 180.39 854.03 178.893 855.932 177.938C857.834 176.983 859.976 176.613 862.088 176.876C864.92 177.228 867.496 178.69 869.249 180.942C871.003 183.193 871.79 186.049 871.439 188.881Z'
                opacity='.1' />
          <path id='Vector_311' fill='white'
                d='M870.071 188.54C869.876 190.107 869.221 191.581 868.188 192.775C867.155 193.969 865.792 194.831 864.27 195.25C862.748 195.67 861.135 195.628 859.637 195.132C858.138 194.635 856.82 193.705 855.85 192.459C854.88 191.214 854.301 189.708 854.186 188.134C854.072 186.559 854.426 184.986 855.206 183.613C855.985 182.24 857.154 181.128 858.565 180.42C859.976 179.711 861.565 179.437 863.132 179.631C864.173 179.76 865.178 180.093 866.09 180.611C867.002 181.129 867.803 181.821 868.447 182.648C869.092 183.476 869.567 184.422 869.845 185.433C870.124 186.444 870.201 187.5 870.071 188.54Z'
                opacity='.2' />
          <path id='Vector_312' fill='white'
                d='M862.751 181.95C862.1 184.287 862.062 187.319 857.636 187.152C858.858 187.894 860.242 188.58 860.996 189.867C861.695 191.193 862.062 192.66 862.066 194.149C862.49 192.794 862.324 191.463 863.388 190.157C863.776 189.673 864.271 189.28 864.838 189.009C865.405 188.737 866.029 188.594 866.661 188.59C865.358 187.67 864.124 187.641 863.482 185.918C863.229 185.265 863.035 184.593 862.902 183.908C862.78 183.216 862.986 182.193 862.599 181.653L862.751 181.95Z'
                opacity='.7' />
          <path id='Vector_313' fill='white'
                d='M903.394 148.578C903.199 150.076 902.544 151.487 901.512 152.629C900.479 153.772 899.116 154.596 897.593 154.998C896.071 155.399 894.459 155.36 892.96 154.885C891.461 154.41 890.143 153.521 889.173 152.329C888.203 151.138 887.623 149.698 887.509 148.191C887.394 146.685 887.749 145.18 888.528 143.866C889.308 142.553 890.477 141.49 891.888 140.812C893.299 140.134 894.889 139.872 896.456 140.058C898.557 140.308 900.468 141.346 901.769 142.944C903.07 144.541 903.655 146.568 903.394 148.578Z'
                opacity='.1' />
          <path id='Vector_314' fill='white'
                d='M902.021 148.321C901.877 149.411 901.393 150.437 900.63 151.268C899.867 152.099 898.859 152.698 897.734 152.99C896.609 153.282 895.417 153.254 894.309 152.908C893.201 152.563 892.227 151.916 891.51 151.049C890.793 150.182 890.365 149.135 890.28 148.039C890.195 146.944 890.458 145.849 891.034 144.894C891.61 143.939 892.475 143.166 893.518 142.673C894.561 142.18 895.736 141.99 896.894 142.125C897.663 142.215 898.405 142.447 899.079 142.807C899.753 143.167 900.345 143.648 900.821 144.224C901.297 144.799 901.648 145.457 901.854 146.16C902.06 146.863 902.117 147.597 902.021 148.321Z'
                opacity='.2' />
          <path id='Vector_315' fill='white'
                d='M896.584 143.684C896.132 145.372 896.107 147.562 893.041 147.442C893.888 147.978 894.846 148.474 895.367 149.403C895.852 150.36 896.105 151.42 896.108 152.496C896.402 151.518 896.287 150.556 897.024 149.613C897.292 149.263 897.635 148.979 898.028 148.783C898.42 148.587 898.851 148.484 899.289 148.481C898.387 147.817 897.532 147.796 897.088 146.551C896.913 146.08 896.779 145.594 896.687 145.099C896.603 144.6 896.745 143.861 896.477 143.471L896.584 143.684Z'
                opacity='.7' />
          <path id='Vector_316' fill='white'
                d='M863.098 187.436C862.773 186.238 862.422 186.377 862.119 186.891C860.601 186.025 860.819 191.927 862.589 190.435C863.19 189.928 863.299 188.178 863.098 187.436Z' />
        </g>;


      case 22 :
        return <g id='Mini gem_3'>
          <path id='Vector_317' fill={`#${diamondColors[color][0]}`}
                d='M1052.69 201.248L1090 236.052L1062.25 262.039L1052.69 202.49L1017.11 242.874L1056.14 299.826L1062.25 262.039L1052.69 201.248Z' />
          <path id='Vector_318' fill={`#${diamondColors[color][1]}`}
                d='M1052.63 201.248L1017.11 242.675L1062.23 262.339L1052.63 201.248Z' />
          <path id='Vector_319' fill='white'
                d='M1052.63 201.248L1051.64 202.403L1060.7 260.048L1018.12 241.493L1017.11 242.675L1062.23 262.339L1052.63 201.248Z' />
          <path id='Vector_320' fill={`#${diamondColors[color][2]}`}
                d='M1062.12 261.983L1055.98 299.826L1090 235.959L1062.12 261.983Z' />
          <path id='Vector_321' fill={`#${diamondColors[color][2]}`}
                d='M1062.12 261.983L1055.98 299.826L1090 235.959L1062.12 261.983Z' />
          <path id='Vector_322' fill='white' d='M1017.11 242.901L1057.37 230.726L1052.75 201.248L1017.11 242.901Z'
                opacity='.2' />
          <path id='Vector_323' fill='white' d='M1057.15 230.628L1090 235.959L1052.51 201.248L1057.15 230.09V230.628Z'
                opacity='.2' />
          <path id='Vector_324' fill={`#${diamondColors[color][3]}`}
                d='M1059.57 277.744C1059.64 277.333 1079.46 246.136 1079.46 246.136L1090 236.653L1066.06 280.953L1055.98 299.826L1059.57 277.744Z'
                opacity='.5' />
          <path id='Vector_325' fill={`#${diamondColors[color][3]}`}
                d='M1063.32 261.984L1088.81 238.187L1090 235.959L1062.12 261.984L1055.98 299.826L1057.71 296.585L1063.32 261.984Z' />
          <path id='Vector_326' fill={`#${diamondColors[color][3]}`}
                d='M1030.24 262.073L1061.54 262.339L1017.11 242.901L1030.24 262.073Z' opacity='.5' />
          <path id='Vector_327' fill='white'
                d='M1019.19 242.179C1020.56 243.582 1023.93 243.781 1025.83 244.146C1029.81 245.004 1033.9 245.15 1037.93 244.577C1042.8 243.791 1046.63 241.647 1050.42 238.63C1052.89 236.661 1053.87 234.725 1054.9 231.925C1055.21 231.21 1055.45 230.469 1055.62 229.711C1056.87 230.372 1056.7 234.596 1057.06 236.002C1057.76 238.768 1058.53 241.455 1058.82 244.283C1059.37 248.963 1060.16 253.613 1061.19 258.213C1061.38 259.154 1061.99 260.372 1060.92 260.845C1060.07 261.223 1058.56 260.482 1057.83 260.214C1055.65 259.418 1053.97 258.048 1051.88 257.06C1049.84 256.099 1047.69 255.587 1045.64 254.626C1043.47 253.608 1041.34 252.688 1039.25 251.508C1036.06 249.702 1032.51 248.29 1029.16 246.879C1027.48 246.169 1025.71 245.499 1024.11 244.768C1022.85 244.19 1020.03 243.522 1019.32 242.308L1019.19 242.179Z'
                opacity='.4' />
          <path id='Vector_328' fill='white'
                d='M1068.3 273.27C1075.36 268.646 1077.34 259.171 1072.71 252.109C1068.09 245.046 1058.61 243.069 1051.55 247.693C1044.49 252.317 1042.51 261.791 1047.14 268.854C1051.76 275.917 1061.23 277.894 1068.3 273.27Z'
                opacity='.1' />
          <path id='Vector_329' fill='white'
                d='M1072.56 261.322C1072.28 263.57 1071.34 265.685 1069.86 267.399C1068.38 269.113 1066.42 270.349 1064.23 270.952C1062.05 271.554 1059.74 271.495 1057.59 270.782C1055.44 270.069 1053.55 268.735 1052.15 266.948C1050.76 265.16 1049.93 263 1049.77 260.741C1049.6 258.481 1050.11 256.224 1051.23 254.254C1052.35 252.284 1054.03 250.689 1056.05 249.673C1058.07 248.656 1060.35 248.263 1062.6 248.542C1065.62 248.917 1068.36 250.474 1070.23 252.871C1072.09 255.267 1072.93 258.307 1072.56 261.322Z'
                opacity='.2' />
          <path id='Vector_330' fill='white'
                d='M1062.37 252.353C1061.47 255.728 1061.42 260.108 1055.29 259.868C1056.98 260.94 1058.9 261.932 1059.94 263.79C1060.91 265.705 1061.42 267.824 1061.42 269.975C1062.01 268.019 1061.78 266.095 1063.25 264.21C1063.79 263.51 1064.48 262.942 1065.26 262.55C1066.05 262.158 1066.91 261.951 1067.79 261.946C1065.98 260.617 1064.27 260.575 1063.38 258.086C1063.03 257.143 1062.76 256.172 1062.58 255.182C1062.41 254.183 1062.7 252.705 1062.16 251.926L1062.37 252.353Z'
                opacity='.7' />
          <path id='Vector_331' fill='white'
                d='M1063.5 260.641C1063.06 259.242 1062.6 259.405 1062.19 260.002C1060.17 258.991 1060.46 265.879 1062.82 264.138C1063.62 263.549 1063.76 261.506 1063.5 260.641Z' />
          <path id='Vector_332' fill='white'
                d='M1027.44 243.881C1027.18 245.993 1026.29 247.979 1024.9 249.59C1023.51 251.2 1021.67 252.361 1019.62 252.927C1017.57 253.493 1015.4 253.437 1013.38 252.768C1011.36 252.098 1009.58 250.845 1008.27 249.166C1006.96 247.487 1006.18 245.458 1006.03 243.335C1005.87 241.213 1006.35 239.092 1007.4 237.241C1008.45 235.39 1010.03 233.893 1011.93 232.938C1013.83 231.983 1015.98 231.613 1018.09 231.876C1020.92 232.228 1023.5 233.69 1025.25 235.942C1027 238.193 1027.79 241.049 1027.44 243.881Z'
                opacity='.1' />
          <path id='Vector_333' fill='white'
                d='M1026.07 243.54C1025.88 245.107 1025.22 246.581 1024.19 247.775C1023.16 248.969 1021.79 249.831 1020.27 250.25C1018.75 250.67 1017.14 250.628 1015.64 250.132C1014.14 249.635 1012.82 248.705 1011.85 247.459C1010.88 246.214 1010.3 244.708 1010.19 243.134C1010.07 241.559 1010.43 239.986 1011.21 238.613C1011.99 237.24 1013.15 236.128 1014.57 235.42C1015.98 234.711 1017.57 234.437 1019.13 234.631C1020.17 234.76 1021.18 235.093 1022.09 235.611C1023 236.129 1023.8 236.821 1024.45 237.648C1025.09 238.476 1025.57 239.422 1025.85 240.433C1026.12 241.444 1026.2 242.5 1026.07 243.54Z'
                opacity='.2' />
          <path id='Vector_334' fill='white'
                d='M1018.75 236.95C1018.1 239.287 1018.06 242.319 1013.64 242.152C1014.86 242.894 1016.24 243.58 1017 244.867C1017.7 246.193 1018.06 247.66 1018.07 249.149C1018.49 247.794 1018.32 246.463 1019.39 245.157C1019.78 244.673 1020.27 244.28 1020.84 244.009C1021.41 243.737 1022.03 243.594 1022.66 243.59C1021.36 242.67 1020.12 242.641 1019.48 240.918C1019.23 240.265 1019.04 239.593 1018.9 238.908C1018.78 238.216 1018.99 237.193 1018.6 236.653L1018.75 236.95Z'
                opacity='.7' />
          <path id='Vector_335' fill='white'
                d='M1059.39 203.578C1059.2 205.076 1058.54 206.487 1057.51 207.629C1056.48 208.772 1055.12 209.596 1053.59 209.998C1052.07 210.399 1050.46 210.36 1048.96 209.885C1047.46 209.41 1046.14 208.521 1045.17 207.329C1044.2 206.138 1043.62 204.698 1043.51 203.191C1043.39 201.685 1043.75 200.18 1044.53 198.866C1045.31 197.553 1046.48 196.49 1047.89 195.812C1049.3 195.134 1050.89 194.872 1052.46 195.058C1054.56 195.308 1056.47 196.346 1057.77 197.944C1059.07 199.541 1059.65 201.568 1059.39 203.578Z'
                opacity='.1' />
          <path id='Vector_336' fill='white'
                d='M1058.02 203.321C1057.88 204.411 1057.39 205.437 1056.63 206.268C1055.87 207.099 1054.86 207.698 1053.73 207.99C1052.61 208.282 1051.42 208.254 1050.31 207.908C1049.2 207.563 1048.23 206.916 1047.51 206.049C1046.79 205.182 1046.36 204.135 1046.28 203.039C1046.2 201.944 1046.46 200.849 1047.03 199.894C1047.61 198.939 1048.47 198.166 1049.52 197.673C1050.56 197.18 1051.74 196.99 1052.89 197.125C1053.66 197.215 1054.41 197.447 1055.08 197.807C1055.75 198.167 1056.34 198.648 1056.82 199.224C1057.3 199.799 1057.65 200.457 1057.85 201.16C1058.06 201.863 1058.12 202.597 1058.02 203.321Z'
                opacity='.2' />
          <path id='Vector_337' fill='white'
                d='M1052.58 198.684C1052.13 200.372 1052.11 202.562 1049.04 202.442C1049.89 202.978 1050.85 203.474 1051.37 204.403C1051.85 205.36 1052.11 206.42 1052.11 207.496C1052.4 206.518 1052.29 205.556 1053.02 204.613C1053.29 204.263 1053.64 203.979 1054.03 203.783C1054.42 203.587 1054.85 203.484 1055.29 203.481C1054.39 202.817 1053.53 202.796 1053.09 201.551C1052.91 201.08 1052.78 200.594 1052.69 200.099C1052.6 199.6 1052.74 198.861 1052.48 198.471L1052.58 198.684Z'
                opacity='.7' />
          <path id='Vector_338' fill='white'
                d='M1019.1 242.436C1018.77 241.238 1018.42 241.377 1018.12 241.891C1016.6 241.025 1016.82 246.927 1018.59 245.435C1019.19 244.928 1019.3 243.178 1019.1 242.436Z' />
        </g>;


      case 23 :
        return <g id='Mini gem_4'>
          <path id='Vector_339' fill={`#${diamondColors[color][0]}`}
                d='M1253.69 166.248L1291 201.052L1263.25 227.039L1253.69 167.49L1218.11 207.874L1257.14 264.826L1263.25 227.039L1253.69 166.248Z' />
          <path id='Vector_340' fill={`#${diamondColors[color][1]}`}
                d='M1253.63 166.248L1218.11 207.675L1263.23 227.339L1253.63 166.248Z' />
          <path id='Vector_341' fill='white'
                d='M1253.63 166.248L1252.64 167.403L1261.7 225.048L1219.12 206.493L1218.11 207.675L1263.23 227.339L1253.63 166.248Z' />
          <path id='Vector_342' fill={`#${diamondColors[color][2]}`}
                d='M1263.12 226.983L1256.98 264.826L1291 200.959L1263.12 226.983Z' />
          <path id='Vector_343' fill={`#${diamondColors[color][2]}`}
                d='M1263.12 226.983L1256.98 264.826L1291 200.959L1263.12 226.983Z' />
          <path id='Vector_344' fill='white' d='M1218.11 207.901L1258.37 195.726L1253.75 166.248L1218.11 207.901Z'
                opacity='.2' />
          <path id='Vector_345' fill='white' d='M1258.15 195.628L1291 200.959L1253.51 166.248L1258.15 195.09V195.628Z'
                opacity='.2' />
          <path id='Vector_346' fill={`#${diamondColors[color][3]}`}
                d='M1260.57 242.744C1260.64 242.333 1280.46 211.136 1280.46 211.136L1291 201.653L1267.06 245.953L1256.98 264.826L1260.57 242.744Z'
                opacity='.5' />
          <path id='Vector_347' fill={`#${diamondColors[color][3]}`}
                d='M1264.32 226.984L1289.81 203.187L1291 200.959L1263.12 226.984L1256.98 264.826L1258.71 261.585L1264.32 226.984Z' />
          <path id='Vector_348' fill={`#${diamondColors[color][3]}`}
                d='M1231.24 227.073L1262.54 227.339L1218.11 207.901L1231.24 227.073Z' opacity='.5' />
          <path id='Vector_349' fill='white'
                d='M1220.19 207.179C1221.56 208.582 1224.93 208.781 1226.83 209.146C1230.81 210.004 1234.9 210.15 1238.93 209.577C1243.8 208.791 1247.63 206.647 1251.42 203.63C1253.89 201.661 1254.87 199.725 1255.9 196.925C1256.21 196.21 1256.45 195.469 1256.62 194.711C1257.87 195.372 1257.7 199.596 1258.06 201.002C1258.76 203.768 1259.53 206.455 1259.82 209.283C1260.37 213.963 1261.16 218.613 1262.19 223.213C1262.38 224.154 1262.99 225.372 1261.92 225.845C1261.07 226.223 1259.56 225.482 1258.83 225.214C1256.65 224.418 1254.97 223.048 1252.88 222.06C1250.84 221.099 1248.69 220.587 1246.64 219.626C1244.47 218.608 1242.34 217.688 1240.25 216.508C1237.06 214.702 1233.51 213.29 1230.16 211.879C1228.48 211.169 1226.71 210.499 1225.11 209.768C1223.85 209.19 1221.03 208.522 1220.32 207.308L1220.19 207.179Z'
                opacity='.4' />
          <path id='Vector_350' fill='white'
                d='M1269.3 238.27C1276.36 233.646 1278.34 224.171 1273.71 217.109C1269.09 210.046 1259.61 208.069 1252.55 212.693C1245.49 217.317 1243.51 226.791 1248.14 233.854C1252.76 240.917 1262.23 242.894 1269.3 238.27Z'
                opacity='.1' />
          <path id='Vector_351' fill='white'
                d='M1273.56 226.322C1273.28 228.57 1272.34 230.685 1270.86 232.399C1269.38 234.113 1267.42 235.349 1265.23 235.952C1263.05 236.554 1260.74 236.495 1258.59 235.782C1256.44 235.069 1254.55 233.735 1253.15 231.948C1251.76 230.16 1250.93 228 1250.77 225.741C1250.6 223.481 1251.11 221.224 1252.23 219.254C1253.35 217.284 1255.03 215.689 1257.05 214.673C1259.07 213.656 1261.35 213.263 1263.6 213.542C1266.62 213.917 1269.36 215.474 1271.23 217.871C1273.09 220.267 1273.93 223.307 1273.56 226.322Z'
                opacity='.2' />
          <path id='Vector_352' fill='white'
                d='M1263.37 217.353C1262.47 220.728 1262.42 225.108 1256.29 224.868C1257.98 225.94 1259.9 226.932 1260.94 228.79C1261.91 230.705 1262.42 232.824 1262.42 234.975C1263.01 233.019 1262.78 231.095 1264.25 229.21C1264.79 228.51 1265.48 227.942 1266.26 227.55C1267.05 227.158 1267.91 226.951 1268.79 226.946C1266.98 225.617 1265.27 225.575 1264.38 223.086C1264.03 222.143 1263.76 221.172 1263.58 220.182C1263.41 219.183 1263.7 217.705 1263.16 216.926L1263.37 217.353Z'
                opacity='.7' />
          <path id='Vector_353' fill='white'
                d='M1264.5 225.641C1264.06 224.242 1263.6 224.405 1263.19 225.002C1261.17 223.991 1261.46 230.879 1263.82 229.138C1264.62 228.549 1264.76 226.506 1264.5 225.641Z' />
          <path id='Vector_354' fill='white'
                d='M1228.44 208.881C1228.18 210.993 1227.29 212.979 1225.9 214.59C1224.51 216.2 1222.67 217.361 1220.62 217.927C1218.57 218.493 1216.4 218.437 1214.38 217.768C1212.36 217.098 1210.58 215.845 1209.27 214.166C1207.96 212.487 1207.18 210.458 1207.03 208.335C1206.87 206.213 1207.35 204.092 1208.4 202.241C1209.45 200.39 1211.03 198.893 1212.93 197.938C1214.83 196.983 1216.98 196.613 1219.09 196.876C1221.92 197.228 1224.5 198.69 1226.25 200.942C1228 203.193 1228.79 206.049 1228.44 208.881Z'
                opacity='.1' />
          <path id='Vector_355' fill='white'
                d='M1227.07 208.54C1226.88 210.107 1226.22 211.581 1225.19 212.775C1224.16 213.969 1222.79 214.831 1221.27 215.25C1219.75 215.67 1218.14 215.628 1216.64 215.132C1215.14 214.635 1213.82 213.705 1212.85 212.459C1211.88 211.214 1211.3 209.708 1211.19 208.134C1211.07 206.559 1211.43 204.986 1212.21 203.613C1212.99 202.24 1214.15 201.128 1215.57 200.42C1216.98 199.711 1218.57 199.437 1220.13 199.631C1221.17 199.76 1222.18 200.093 1223.09 200.611C1224 201.129 1224.8 201.821 1225.45 202.648C1226.09 203.476 1226.57 204.422 1226.85 205.433C1227.12 206.444 1227.2 207.5 1227.07 208.54Z'
                opacity='.2' />
          <path id='Vector_356' fill='white'
                d='M1219.75 201.95C1219.1 204.287 1219.06 207.319 1214.64 207.152C1215.86 207.894 1217.24 208.58 1218 209.867C1218.7 211.193 1219.06 212.66 1219.07 214.149C1219.49 212.794 1219.32 211.463 1220.39 210.157C1220.78 209.673 1221.27 209.28 1221.84 209.009C1222.41 208.737 1223.03 208.594 1223.66 208.59C1222.36 207.67 1221.12 207.641 1220.48 205.918C1220.23 205.265 1220.04 204.593 1219.9 203.908C1219.78 203.216 1219.99 202.193 1219.6 201.653L1219.75 201.95Z'
                opacity='.7' />
          <path id='Vector_357' fill='white'
                d='M1260.39 168.578C1260.2 170.076 1259.54 171.487 1258.51 172.629C1257.48 173.772 1256.12 174.596 1254.59 174.998C1253.07 175.399 1251.46 175.36 1249.96 174.885C1248.46 174.41 1247.14 173.521 1246.17 172.329C1245.2 171.138 1244.62 169.698 1244.51 168.191C1244.39 166.685 1244.75 165.18 1245.53 163.866C1246.31 162.553 1247.48 161.49 1248.89 160.812C1250.3 160.134 1251.89 159.872 1253.46 160.058C1255.56 160.308 1257.47 161.346 1258.77 162.944C1260.07 164.541 1260.65 166.568 1260.39 168.578Z'
                opacity='.1' />
          <path id='Vector_358' fill='white'
                d='M1259.02 168.321C1258.88 169.411 1258.39 170.437 1257.63 171.268C1256.87 172.099 1255.86 172.698 1254.73 172.99C1253.61 173.282 1252.42 173.254 1251.31 172.908C1250.2 172.563 1249.23 171.916 1248.51 171.049C1247.79 170.182 1247.36 169.135 1247.28 168.039C1247.2 166.944 1247.46 165.849 1248.03 164.894C1248.61 163.939 1249.47 163.166 1250.52 162.673C1251.56 162.18 1252.74 161.99 1253.89 162.125C1254.66 162.215 1255.41 162.447 1256.08 162.807C1256.75 163.167 1257.34 163.648 1257.82 164.224C1258.3 164.799 1258.65 165.457 1258.85 166.16C1259.06 166.863 1259.12 167.597 1259.02 168.321Z'
                opacity='.2' />
          <path id='Vector_359' fill='white'
                d='M1253.58 163.684C1253.13 165.372 1253.11 167.562 1250.04 167.442C1250.89 167.978 1251.85 168.474 1252.37 169.403C1252.85 170.36 1253.11 171.42 1253.11 172.496C1253.4 171.518 1253.29 170.556 1254.02 169.613C1254.29 169.263 1254.64 168.979 1255.03 168.783C1255.42 168.587 1255.85 168.484 1256.29 168.481C1255.39 167.817 1254.53 167.796 1254.09 166.551C1253.91 166.08 1253.78 165.594 1253.69 165.099C1253.6 164.6 1253.74 163.861 1253.48 163.471L1253.58 163.684Z'
                opacity='.7' />
          <path id='Vector_360' fill='white'
                d='M1220.1 207.436C1219.77 206.238 1219.42 206.377 1219.12 206.891C1217.6 206.025 1217.82 211.927 1219.59 210.435C1220.19 209.928 1220.3 208.178 1220.1 207.436Z' />
        </g>;


      case 24 :
        return <g id='Mini gem_5'>
          <path id='Vector_361' fill={`#${diamondColors[color][0]}`}
                d='M1384.69 347.248L1422 382.052L1394.25 408.039L1384.69 348.49L1349.11 388.874L1388.14 445.826L1394.25 408.039L1384.69 347.248Z' />
          <path id='Vector_362' fill={`#${diamondColors[color][1]}`}
                d='M1384.63 347.248L1349.11 388.675L1394.23 408.339L1384.63 347.248Z' />
          <path id='Vector_363' fill='white'
                d='M1384.63 347.248L1383.64 348.403L1392.7 406.048L1350.12 387.493L1349.11 388.675L1394.23 408.339L1384.63 347.248Z' />
          <path id='Vector_364' fill={`#${diamondColors[color][2]}`}
                d='M1394.12 407.983L1387.98 445.826L1422 381.959L1394.12 407.983Z' />
          <path id='Vector_365' fill={`#${diamondColors[color][2]}`}
                d='M1394.12 407.983L1387.98 445.826L1422 381.959L1394.12 407.983Z' />
          <path id='Vector_366' fill='white' d='M1349.11 388.901L1389.37 376.726L1384.75 347.248L1349.11 388.901Z'
                opacity='.2' />
          <path id='Vector_367' fill='white' d='M1389.15 376.628L1422 381.959L1384.51 347.248L1389.15 376.09V376.628Z'
                opacity='.2' />
          <path id='Vector_368' fill={`#${diamondColors[color][3]}`}
                d='M1391.57 423.744C1391.64 423.333 1411.46 392.136 1411.46 392.136L1422 382.653L1398.06 426.953L1387.98 445.826L1391.57 423.744Z'
                opacity='.5' />
          <path id='Vector_369' fill={`#${diamondColors[color][3]}`}
                d='M1395.32 407.984L1420.81 384.187L1422 381.959L1394.12 407.984L1387.98 445.826L1389.71 442.585L1395.32 407.984Z' />
          <path id='Vector_370' fill={`#${diamondColors[color][3]}`}
                d='M1362.24 408.073L1393.54 408.339L1349.11 388.901L1362.24 408.073Z' opacity='.5' />
          <path id='Vector_371' fill='white'
                d='M1351.19 388.179C1352.56 389.582 1355.93 389.781 1357.83 390.146C1361.81 391.004 1365.9 391.15 1369.93 390.577C1374.8 389.791 1378.63 387.647 1382.42 384.63C1384.89 382.661 1385.87 380.725 1386.9 377.925C1387.21 377.21 1387.45 376.469 1387.62 375.711C1388.87 376.372 1388.7 380.596 1389.06 382.002C1389.76 384.768 1390.53 387.455 1390.82 390.283C1391.37 394.963 1392.16 399.613 1393.19 404.213C1393.38 405.154 1393.99 406.372 1392.92 406.845C1392.07 407.223 1390.56 406.482 1389.83 406.214C1387.65 405.418 1385.97 404.048 1383.88 403.06C1381.84 402.099 1379.69 401.587 1377.64 400.626C1375.47 399.608 1373.34 398.688 1371.25 397.508C1368.06 395.702 1364.51 394.29 1361.16 392.879C1359.48 392.169 1357.71 391.499 1356.11 390.768C1354.85 390.19 1352.03 389.522 1351.32 388.308L1351.19 388.179Z'
                opacity='.4' />
          <path id='Vector_372' fill='white'
                d='M1400.3 419.27C1407.36 414.646 1409.34 405.171 1404.71 398.109C1400.09 391.046 1390.61 389.069 1383.55 393.693C1376.49 398.317 1374.51 407.791 1379.14 414.854C1383.76 421.917 1393.23 423.894 1400.3 419.27Z'
                opacity='.1' />
          <path id='Vector_373' fill='white'
                d='M1404.56 407.322C1404.28 409.57 1403.34 411.685 1401.86 413.399C1400.38 415.113 1398.42 416.349 1396.23 416.952C1394.05 417.554 1391.74 417.495 1389.59 416.782C1387.44 416.069 1385.55 414.735 1384.15 412.948C1382.76 411.16 1381.93 409 1381.77 406.741C1381.6 404.481 1382.11 402.224 1383.23 400.254C1384.35 398.284 1386.03 396.689 1388.05 395.673C1390.07 394.656 1392.35 394.263 1394.6 394.542C1397.62 394.917 1400.36 396.474 1402.23 398.871C1404.09 401.267 1404.93 404.307 1404.56 407.322Z'
                opacity='.2' />
          <path id='Vector_374' fill='white'
                d='M1394.37 398.353C1393.47 401.728 1393.42 406.108 1387.29 405.868C1388.98 406.94 1390.9 407.932 1391.94 409.79C1392.91 411.705 1393.42 413.824 1393.42 415.975C1394.01 414.019 1393.78 412.095 1395.25 410.21C1395.79 409.51 1396.48 408.942 1397.26 408.55C1398.05 408.158 1398.91 407.951 1399.79 407.946C1397.98 406.617 1396.27 406.575 1395.38 404.086C1395.03 403.143 1394.76 402.172 1394.58 401.182C1394.41 400.183 1394.7 398.705 1394.16 397.926L1394.37 398.353Z'
                opacity='.7' />
          <path id='Vector_375' fill='white'
                d='M1395.5 406.641C1395.06 405.242 1394.6 405.405 1394.19 406.002C1392.17 404.991 1392.46 411.879 1394.82 410.138C1395.62 409.549 1395.76 407.506 1395.5 406.641Z' />
          <path id='Vector_376' fill='white'
                d='M1359.44 389.881C1359.18 391.993 1358.29 393.979 1356.9 395.59C1355.51 397.2 1353.67 398.361 1351.62 398.927C1349.57 399.493 1347.4 399.437 1345.38 398.768C1343.36 398.098 1341.58 396.845 1340.27 395.166C1338.96 393.487 1338.18 391.458 1338.03 389.335C1337.87 387.213 1338.35 385.092 1339.4 383.241C1340.45 381.39 1342.03 379.893 1343.93 378.938C1345.83 377.983 1347.98 377.613 1350.09 377.876C1352.92 378.228 1355.5 379.69 1357.25 381.942C1359 384.193 1359.79 387.049 1359.44 389.881Z'
                opacity='.1' />
          <path id='Vector_377' fill='white'
                d='M1358.07 389.54C1357.88 391.107 1357.22 392.581 1356.19 393.775C1355.16 394.969 1353.79 395.831 1352.27 396.25C1350.75 396.67 1349.14 396.628 1347.64 396.132C1346.14 395.635 1344.82 394.705 1343.85 393.459C1342.88 392.214 1342.3 390.708 1342.19 389.134C1342.07 387.559 1342.43 385.986 1343.21 384.613C1343.99 383.24 1345.15 382.128 1346.57 381.42C1347.98 380.711 1349.57 380.437 1351.13 380.631C1352.17 380.76 1353.18 381.093 1354.09 381.611C1355 382.129 1355.8 382.821 1356.45 383.648C1357.09 384.476 1357.57 385.422 1357.85 386.433C1358.12 387.444 1358.2 388.5 1358.07 389.54Z'
                opacity='.2' />
          <path id='Vector_378' fill='white'
                d='M1350.75 382.95C1350.1 385.287 1350.06 388.319 1345.64 388.152C1346.86 388.894 1348.24 389.58 1349 390.867C1349.7 392.193 1350.06 393.66 1350.07 395.149C1350.49 393.794 1350.32 392.463 1351.39 391.157C1351.78 390.673 1352.27 390.28 1352.84 390.009C1353.41 389.737 1354.03 389.594 1354.66 389.59C1353.36 388.67 1352.12 388.641 1351.48 386.918C1351.23 386.265 1351.04 385.593 1350.9 384.908C1350.78 384.216 1350.99 383.193 1350.6 382.653L1350.75 382.95Z'
                opacity='.7' />
          <path id='Vector_379' fill='white'
                d='M1391.39 349.578C1391.2 351.076 1390.54 352.487 1389.51 353.629C1388.48 354.772 1387.12 355.596 1385.59 355.998C1384.07 356.399 1382.46 356.36 1380.96 355.885C1379.46 355.41 1378.14 354.521 1377.17 353.329C1376.2 352.138 1375.62 350.698 1375.51 349.191C1375.39 347.685 1375.75 346.18 1376.53 344.866C1377.31 343.553 1378.48 342.49 1379.89 341.812C1381.3 341.134 1382.89 340.872 1384.46 341.058C1386.56 341.308 1388.47 342.346 1389.77 343.944C1391.07 345.541 1391.65 347.568 1391.39 349.578Z'
                opacity='.1' />
          <path id='Vector_380' fill='white'
                d='M1390.02 349.321C1389.88 350.411 1389.39 351.437 1388.63 352.268C1387.87 353.099 1386.86 353.698 1385.73 353.99C1384.61 354.282 1383.42 354.254 1382.31 353.908C1381.2 353.563 1380.23 352.916 1379.51 352.049C1378.79 351.182 1378.36 350.135 1378.28 349.039C1378.2 347.944 1378.46 346.849 1379.03 345.894C1379.61 344.939 1380.47 344.166 1381.52 343.673C1382.56 343.18 1383.74 342.99 1384.89 343.125C1385.66 343.215 1386.41 343.447 1387.08 343.807C1387.75 344.167 1388.34 344.648 1388.82 345.224C1389.3 345.799 1389.65 346.457 1389.85 347.16C1390.06 347.863 1390.12 348.597 1390.02 349.321Z'
                opacity='.2' />
          <path id='Vector_381' fill='white'
                d='M1384.58 344.684C1384.13 346.372 1384.11 348.562 1381.04 348.442C1381.89 348.978 1382.85 349.474 1383.37 350.403C1383.85 351.361 1384.11 352.42 1384.11 353.496C1384.4 352.518 1384.29 351.556 1385.02 350.613C1385.29 350.263 1385.64 349.979 1386.03 349.783C1386.42 349.587 1386.85 349.484 1387.29 349.481C1386.39 348.817 1385.53 348.796 1385.09 347.551C1384.91 347.08 1384.78 346.594 1384.69 346.099C1384.6 345.6 1384.74 344.861 1384.48 344.471L1384.58 344.684Z'
                opacity='.7' />
          <path id='Vector_382' fill='white'
                d='M1351.1 388.436C1350.77 387.238 1350.42 387.377 1350.12 387.891C1348.6 387.025 1348.82 392.927 1350.59 391.435C1351.19 390.928 1351.3 389.178 1351.1 388.436Z' />
        </g>;


      case 25 :
        return <g id='Mini gem_6'>
          <path id='Vector_383' fill={`#${diamondColors[color][0]}`}
                d='M1601.69 118.248L1639 153.052L1611.25 179.039L1601.69 119.49L1566.11 159.874L1605.14 216.826L1611.25 179.039L1601.69 118.248Z' />
          <path id='Vector_384' fill={`#${diamondColors[color][1]}`}
                d='M1601.63 118.248L1566.11 159.675L1611.23 179.339L1601.63 118.248Z' />
          <path id='Vector_385' fill='white'
                d='M1601.63 118.248L1600.64 119.403L1609.7 177.048L1567.12 158.493L1566.11 159.675L1611.23 179.339L1601.63 118.248Z' />
          <path id='Vector_386' fill={`#${diamondColors[color][2]}`}
                d='M1611.12 178.983L1604.98 216.826L1639 152.959L1611.12 178.983Z' />
          <path id='Vector_387' fill={`#${diamondColors[color][2]}`}
                d='M1611.12 178.983L1604.98 216.826L1639 152.959L1611.12 178.983Z' />
          <path id='Vector_388' fill='white' d='M1566.11 159.901L1606.37 147.726L1601.75 118.248L1566.11 159.901Z'
                opacity='.2' />
          <path id='Vector_389' fill='white' d='M1606.15 147.628L1639 152.959L1601.51 118.248L1606.15 147.09V147.628Z'
                opacity='.2' />
          <path id='Vector_390' fill={`#${diamondColors[color][3]}`}
                d='M1608.57 194.744C1608.64 194.333 1628.46 163.136 1628.46 163.136L1639 153.653L1615.06 197.953L1604.98 216.826L1608.57 194.744Z'
                opacity='.5' />
          <path id='Vector_391' fill={`#${diamondColors[color][3]}`}
                d='M1612.32 178.984L1637.81 155.187L1639 152.959L1611.12 178.984L1604.98 216.826L1606.71 213.585L1612.32 178.984Z' />
          <path id='Vector_392' fill={`#${diamondColors[color][3]}`}
                d='M1579.24 179.073L1610.54 179.339L1566.11 159.901L1579.24 179.073Z' opacity='.5' />
          <path id='Vector_393' fill='white'
                d='M1568.19 159.179C1569.56 160.582 1572.93 160.781 1574.83 161.146C1578.81 162.004 1582.9 162.15 1586.93 161.577C1591.8 160.791 1595.63 158.647 1599.42 155.63C1601.89 153.661 1602.87 151.725 1603.9 148.925C1604.21 148.21 1604.45 147.469 1604.62 146.711C1605.87 147.372 1605.7 151.596 1606.06 153.002C1606.76 155.768 1607.53 158.455 1607.82 161.283C1608.37 165.963 1609.16 170.613 1610.19 175.213C1610.38 176.154 1610.99 177.372 1609.92 177.845C1609.07 178.223 1607.56 177.482 1606.83 177.214C1604.65 176.418 1602.97 175.048 1600.88 174.06C1598.84 173.099 1596.69 172.587 1594.64 171.626C1592.47 170.608 1590.34 169.688 1588.25 168.508C1585.06 166.702 1581.51 165.29 1578.16 163.879C1576.48 163.169 1574.71 162.499 1573.11 161.768C1571.85 161.19 1569.03 160.522 1568.32 159.308L1568.19 159.179Z'
                opacity='.4' />
          <path id='Vector_394' fill='white'
                d='M1617.3 190.27C1624.36 185.646 1626.34 176.171 1621.71 169.109C1617.09 162.046 1607.61 160.069 1600.55 164.693C1593.49 169.317 1591.51 178.791 1596.14 185.854C1600.76 192.917 1610.23 194.894 1617.3 190.27Z'
                opacity='.1' />
          <path id='Vector_395' fill='white'
                d='M1621.56 178.322C1621.28 180.57 1620.34 182.685 1618.86 184.399C1617.38 186.113 1615.42 187.349 1613.23 187.952C1611.05 188.554 1608.74 188.495 1606.59 187.782C1604.44 187.069 1602.55 185.735 1601.15 183.948C1599.76 182.16 1598.93 180 1598.77 177.741C1598.6 175.481 1599.11 173.224 1600.23 171.254C1601.35 169.284 1603.03 167.689 1605.05 166.673C1607.07 165.656 1609.35 165.263 1611.6 165.542C1614.62 165.917 1617.36 167.474 1619.23 169.871C1621.09 172.267 1621.93 175.307 1621.56 178.322Z'
                opacity='.2' />
          <path id='Vector_396' fill='white'
                d='M1611.37 169.353C1610.47 172.728 1610.42 177.108 1604.29 176.868C1605.98 177.94 1607.9 178.932 1608.94 180.79C1609.91 182.705 1610.42 184.824 1610.42 186.975C1611.01 185.019 1610.78 183.095 1612.25 181.21C1612.79 180.51 1613.48 179.942 1614.26 179.55C1615.05 179.158 1615.91 178.951 1616.79 178.946C1614.98 177.617 1613.27 177.575 1612.38 175.086C1612.03 174.143 1611.76 173.172 1611.58 172.182C1611.41 171.183 1611.7 169.705 1611.16 168.926L1611.37 169.353Z'
                opacity='.7' />
          <path id='Vector_397' fill='white'
                d='M1612.5 177.641C1612.06 176.242 1611.6 176.405 1611.19 177.002C1609.17 175.991 1609.46 182.879 1611.82 181.138C1612.62 180.549 1612.76 178.506 1612.5 177.641Z' />
          <path id='Vector_398' fill='white'
                d='M1576.44 160.881C1576.18 162.993 1575.29 164.979 1573.9 166.59C1572.51 168.2 1570.67 169.361 1568.62 169.927C1566.57 170.493 1564.4 170.437 1562.38 169.768C1560.36 169.098 1558.58 167.845 1557.27 166.166C1555.96 164.487 1555.18 162.458 1555.03 160.335C1554.87 158.213 1555.35 156.092 1556.4 154.241C1557.45 152.39 1559.03 150.893 1560.93 149.938C1562.83 148.983 1564.98 148.613 1567.09 148.876C1569.92 149.228 1572.5 150.69 1574.25 152.942C1576 155.193 1576.79 158.049 1576.44 160.881Z'
                opacity='.1' />
          <path id='Vector_399' fill='white'
                d='M1575.07 160.54C1574.88 162.107 1574.22 163.581 1573.19 164.775C1572.16 165.969 1570.79 166.831 1569.27 167.25C1567.75 167.67 1566.14 167.628 1564.64 167.132C1563.14 166.635 1561.82 165.705 1560.85 164.459C1559.88 163.214 1559.3 161.708 1559.19 160.134C1559.07 158.559 1559.43 156.986 1560.21 155.613C1560.99 154.24 1562.15 153.128 1563.57 152.42C1564.98 151.711 1566.57 151.437 1568.13 151.631C1569.17 151.76 1570.18 152.093 1571.09 152.611C1572 153.129 1572.8 153.821 1573.45 154.648C1574.09 155.476 1574.57 156.422 1574.85 157.433C1575.12 158.444 1575.2 159.5 1575.07 160.54Z'
                opacity='.2' />
          <path id='Vector_400' fill='white'
                d='M1567.75 153.95C1567.1 156.287 1567.06 159.319 1562.64 159.152C1563.86 159.894 1565.24 160.58 1566 161.867C1566.7 163.193 1567.06 164.66 1567.07 166.149C1567.49 164.794 1567.32 163.463 1568.39 162.157C1568.78 161.673 1569.27 161.28 1569.84 161.009C1570.41 160.737 1571.03 160.594 1571.66 160.59C1570.36 159.67 1569.12 159.641 1568.48 157.918C1568.23 157.265 1568.04 156.593 1567.9 155.908C1567.78 155.216 1567.99 154.193 1567.6 153.653L1567.75 153.95Z'
                opacity='.7' />
          <path id='Vector_401' fill='white'
                d='M1608.39 120.578C1608.2 122.076 1607.54 123.487 1606.51 124.629C1605.48 125.772 1604.12 126.596 1602.59 126.998C1601.07 127.399 1599.46 127.36 1597.96 126.885C1596.46 126.41 1595.14 125.521 1594.17 124.329C1593.2 123.138 1592.62 121.698 1592.51 120.191C1592.39 118.685 1592.75 117.18 1593.53 115.866C1594.31 114.553 1595.48 113.49 1596.89 112.812C1598.3 112.134 1599.89 111.872 1601.46 112.058C1603.56 112.308 1605.47 113.346 1606.77 114.944C1608.07 116.541 1608.65 118.568 1608.39 120.578Z'
                opacity='.1' />
          <path id='Vector_402' fill='white'
                d='M1607.02 120.321C1606.88 121.411 1606.39 122.437 1605.63 123.268C1604.87 124.099 1603.86 124.698 1602.73 124.99C1601.61 125.282 1600.42 125.254 1599.31 124.908C1598.2 124.563 1597.23 123.916 1596.51 123.049C1595.79 122.182 1595.36 121.135 1595.28 120.039C1595.2 118.944 1595.46 117.849 1596.03 116.894C1596.61 115.939 1597.47 115.166 1598.52 114.673C1599.56 114.18 1600.74 113.99 1601.89 114.125C1602.66 114.215 1603.41 114.447 1604.08 114.807C1604.75 115.167 1605.34 115.648 1605.82 116.224C1606.3 116.799 1606.65 117.457 1606.85 118.16C1607.06 118.863 1607.12 119.597 1607.02 120.321Z'
                opacity='.2' />
          <path id='Vector_403' fill='white'
                d='M1601.58 115.684C1601.13 117.372 1601.11 119.562 1598.04 119.442C1598.89 119.978 1599.85 120.474 1600.37 121.403C1600.85 122.36 1601.11 123.42 1601.11 124.496C1601.4 123.518 1601.29 122.556 1602.02 121.613C1602.29 121.263 1602.64 120.979 1603.03 120.783C1603.42 120.587 1603.85 120.484 1604.29 120.481C1603.39 119.817 1602.53 119.796 1602.09 118.551C1601.91 118.08 1601.78 117.594 1601.69 117.099C1601.6 116.6 1601.74 115.861 1601.48 115.471L1601.58 115.684Z'
                opacity='.7' />
          <path id='Vector_404' fill='white'
                d='M1568.1 159.436C1567.77 158.238 1567.42 158.377 1567.12 158.891C1565.6 158.025 1565.82 163.927 1567.59 162.435C1568.19 161.928 1568.3 160.178 1568.1 159.436Z' />
        </g>;


      case 26:
        return <g id='Mini gem_7'>
          <path id='Vector_405' fill={`#${diamondColors[color][0]}`}
                d='M1416.69 6.24792L1454 41.0523L1426.25 67.0394L1416.69 7.49048L1381.11 47.8741L1420.14 104.826L1426.25 67.0394L1416.69 6.24792Z' />
          <path id='Vector_406' fill={`#${diamondColors[color][1]}`}
                d='M1416.63 6.24792L1381.11 47.6748L1426.23 67.3388L1416.63 6.24792Z' />
          <path id='Vector_407' fill='white'
                d='M1416.63 6.24792L1415.64 7.40345L1424.7 65.0478L1382.12 46.4932L1381.11 47.6748L1426.23 67.3388L1416.63 6.24792Z' />
          <path id='Vector_408' fill={`#${diamondColors[color][2]}`}
                d='M1426.12 66.9835L1419.98 104.826L1454 40.9587L1426.12 66.9835Z' />
          <path id='Vector_409' fill={`#${diamondColors[color][2]}`}
                d='M1426.12 66.9835L1419.98 104.826L1454 40.9587L1426.12 66.9835Z' />
          <path id='Vector_410' fill='white' d='M1381.11 47.9008L1421.37 35.7257L1416.75 6.24792L1381.11 47.9008Z'
                opacity='.2' />
          <path id='Vector_411' fill='white' d='M1421.15 35.6279L1454 40.9587L1416.51 6.24792L1421.15 35.0898V35.6279Z'
                opacity='.2' />
          <path id='Vector_412' fill={`#${diamondColors[color][3]}`}
                d='M1423.57 82.744C1423.64 82.3327 1443.46 51.136 1443.46 51.136L1454 41.6529L1430.06 85.9531L1419.98 104.826L1423.57 82.744Z'
                opacity='.5' />
          <path id='Vector_413' fill={`#${diamondColors[color][3]}`}
                d='M1427.32 66.9845L1452.81 43.1874L1454 40.9587L1426.12 66.9845L1419.98 104.826L1421.71 101.585L1427.32 66.9845Z' />
          <path id='Vector_414' fill={`#${diamondColors[color][3]}`}
                d='M1394.24 67.0731L1425.54 67.3388L1381.11 47.9008L1394.24 67.0731Z' opacity='.5' />
          <path id='Vector_415' fill='white'
                d='M1383.19 47.1788C1384.56 48.5822 1387.93 48.7806 1389.83 49.1464C1393.81 50.0045 1397.9 50.1503 1401.93 49.5769C1406.8 48.791 1410.63 46.6472 1414.42 43.6303C1416.89 41.6609 1417.87 39.7251 1418.9 36.9248C1419.21 36.2096 1419.45 35.4685 1419.62 34.7108C1420.87 35.3716 1420.7 39.5956 1421.06 41.0017C1421.76 43.768 1422.53 46.4548 1422.82 49.283C1423.37 53.9633 1424.16 58.6127 1425.19 63.213C1425.38 64.1541 1425.99 65.3716 1424.92 65.8455C1424.07 66.2228 1422.56 65.4818 1421.83 65.2136C1419.65 64.4184 1417.97 63.0484 1415.88 62.0602C1413.84 61.0993 1411.69 60.5871 1409.64 59.6257C1407.47 58.6084 1405.34 57.6877 1403.25 56.508C1400.06 54.7021 1396.51 53.2903 1393.16 51.8787C1391.48 51.169 1389.71 50.4989 1388.11 49.7684C1386.85 49.1898 1384.03 48.5223 1383.32 47.3081L1383.19 47.1788Z'
                opacity='.4' />
          <path id='Vector_416' fill='white'
                d='M1432.3 78.2698C1439.36 73.6456 1441.34 64.1714 1436.71 57.1086C1432.09 50.0458 1422.61 48.0688 1415.55 52.693C1408.49 57.3172 1406.51 66.7913 1411.14 73.8542C1415.76 80.917 1425.23 82.8939 1432.3 78.2698Z'
                opacity='.1' />
          <path id='Vector_417' fill='white'
                d='M1436.56 66.3221C1436.28 68.5703 1435.34 70.685 1433.86 72.3991C1432.38 74.1131 1430.42 75.3494 1428.23 75.9515C1426.05 76.5537 1423.74 76.4948 1421.59 75.7821C1419.44 75.0694 1417.55 73.7349 1416.15 71.9476C1414.76 70.1603 1413.93 68.0003 1413.77 65.7408C1413.6 63.4813 1414.11 61.2237 1415.23 59.2536C1416.35 57.2835 1418.03 55.6894 1420.05 54.6727C1422.07 53.6561 1424.35 53.2625 1426.6 53.542C1429.62 53.9168 1432.36 55.4738 1434.23 57.8705C1436.09 60.2672 1436.93 63.3074 1436.56 66.3221Z'
                opacity='.2' />
          <path id='Vector_418' fill='white'
                d='M1426.37 57.3526C1425.47 60.7278 1425.42 65.1079 1419.29 64.8682C1420.98 65.9404 1422.9 66.9319 1423.94 68.7895C1424.91 70.7049 1425.42 72.8242 1425.42 74.9752C1426.01 73.019 1425.78 71.0953 1427.25 69.2097C1427.79 68.5098 1428.48 67.9423 1429.26 67.5501C1430.05 67.158 1430.91 66.9513 1431.79 66.9459C1429.98 65.6171 1428.27 65.575 1427.38 63.0861C1427.03 62.1434 1426.76 61.1718 1426.58 60.1821C1426.41 59.1833 1426.7 57.7054 1426.16 56.9256L1426.37 57.3526Z'
                opacity='.7' />
          <path id='Vector_419' fill='white'
                d='M1427.5 65.6405C1427.06 64.2422 1426.6 64.4046 1426.19 65.002C1424.17 63.9912 1424.46 70.8793 1426.82 69.1379C1427.62 68.5486 1427.76 66.5064 1427.5 65.6405Z' />
          <path id='Vector_420' fill='white'
                d='M1391.44 48.8806C1391.18 50.9926 1390.29 52.9794 1388.9 54.5897C1387.51 56.1999 1385.67 57.3612 1383.62 57.9269C1381.57 58.4927 1379.4 58.4373 1377.38 57.7678C1375.36 57.0983 1373.58 55.8449 1372.27 54.1659C1370.96 52.4869 1370.18 50.4578 1370.03 48.3352C1369.87 46.2126 1370.35 44.0918 1371.4 42.241C1372.45 40.3903 1374.03 38.8927 1375.93 37.9377C1377.83 36.9826 1379.98 36.613 1382.09 36.8756C1384.92 37.2278 1387.5 38.6904 1389.25 40.9417C1391 43.1931 1391.79 46.0487 1391.44 48.8806Z'
                opacity='.1' />
          <path id='Vector_421' fill='white'
                d='M1390.07 48.5404C1389.88 50.1071 1389.22 51.5807 1388.19 52.7751C1387.16 53.9694 1385.79 54.8308 1384.27 55.2503C1382.75 55.6697 1381.14 55.6285 1379.64 55.1317C1378.14 54.6349 1376.82 53.7049 1375.85 52.4593C1374.88 51.2137 1374.3 49.7083 1374.19 48.1337C1374.07 46.559 1374.43 44.9856 1375.21 43.6126C1375.99 42.2396 1377.15 41.1285 1378.57 40.4198C1379.98 39.711 1381.57 39.4366 1383.13 39.631C1384.17 39.7602 1385.18 40.0932 1386.09 40.6109C1387 41.1286 1387.8 41.8209 1388.45 42.6482C1389.09 43.4756 1389.57 44.4217 1389.85 45.4327C1390.12 46.4437 1390.2 47.4998 1390.07 48.5404Z'
                opacity='.2' />
          <path id='Vector_422' fill='white'
                d='M1382.75 41.9503C1382.1 44.2872 1382.06 47.3195 1377.64 47.1519C1378.86 47.8938 1380.24 48.5804 1381 49.8666C1381.7 51.1926 1382.06 52.6597 1382.07 54.1488C1382.49 52.7942 1382.32 51.4627 1383.39 50.1573C1383.78 49.673 1384.27 49.2801 1384.84 49.0087C1385.41 48.7372 1386.03 48.5941 1386.66 48.5903C1385.36 47.6704 1384.12 47.6408 1383.48 45.9177C1383.23 45.2652 1383.04 44.5927 1382.9 43.9077C1382.78 43.2162 1382.99 42.1933 1382.6 41.6529L1382.75 41.9503Z'
                opacity='.7' />
          <path id='Vector_423' fill='white'
                d='M1423.39 8.57765C1423.2 10.0765 1422.54 11.4865 1421.51 12.6293C1420.48 13.7721 1419.12 14.5963 1417.59 14.9979C1416.07 15.3994 1414.46 15.3602 1412.96 14.8852C1411.46 14.4101 1410.14 13.5206 1409.17 12.3291C1408.2 11.1376 1407.62 9.69758 1407.51 8.19121C1407.39 6.68484 1407.75 5.17966 1408.53 3.86621C1409.31 2.55275 1410.48 1.48999 1411.89 0.812181C1413.3 0.13437 1414.89 -0.127982 1416.46 0.058323C1418.56 0.308132 1420.47 1.34607 1421.77 2.94374C1423.07 4.54142 1423.65 6.56791 1423.39 8.57765Z'
                opacity='.1' />
          <path id='Vector_424' fill='white'
                d='M1422.02 8.32109C1421.88 9.41118 1421.39 10.4367 1420.63 11.2678C1419.87 12.0989 1418.86 12.6983 1417.73 12.9903C1416.61 13.2823 1415.42 13.2537 1414.31 12.9081C1413.2 12.5625 1412.23 11.9155 1411.51 11.0489C1410.79 10.1822 1410.36 9.13492 1410.28 8.03934C1410.2 6.94377 1410.46 5.84906 1411.03 4.89385C1411.61 3.93865 1412.47 3.16577 1413.52 2.67292C1414.56 2.18008 1415.74 1.98952 1416.89 2.12516C1417.66 2.21508 1418.41 2.44659 1419.08 2.80665C1419.75 3.16671 1420.34 3.64814 1420.82 4.22351C1421.3 4.79888 1421.65 5.45701 1421.85 6.16007C1422.06 6.86314 1422.12 7.59739 1422.02 8.32109Z'
                opacity='.2' />
          <path id='Vector_425' fill='white'
                d='M1416.58 3.68419C1416.13 5.37228 1416.11 7.56211 1413.04 7.44205C1413.89 7.97849 1414.85 8.47421 1415.37 9.4028C1415.85 10.3605 1416.11 11.4203 1416.11 12.4959C1416.4 11.5177 1416.29 10.5562 1417.02 9.61269C1417.29 9.26296 1417.64 8.9793 1418.03 8.78332C1418.42 8.58734 1418.85 8.48405 1419.29 8.48132C1418.39 7.81655 1417.53 7.79568 1417.09 6.55096C1416.91 6.07967 1416.78 5.59392 1416.69 5.09915C1416.6 4.59961 1416.74 3.86101 1416.48 3.47107L1416.58 3.68419Z'
                opacity='.7' />
          <path id='Vector_426' fill='white'
                d='M1383.1 47.4361C1382.77 46.238 1382.42 46.3774 1382.12 46.8907C1380.6 46.0247 1380.82 51.9267 1382.59 50.4347C1383.19 49.9281 1383.3 48.1779 1383.1 47.4361Z' />
        </g>;


    }
  }, [index]);

  return (
    <g className='gem-wrapper'
       ref={gemRef}
       data-tooltip-id='double-click-tip'
       onClick={onGameSelect}
       onPointerOver={(e) => showTheGameInfoTooltip({ x: e.pageX, y: e.pageY, level, title })}
       onPointerOut={()=>showTheGameInfoTooltip(null)}
    > {renderCoreGem}
      {renderedMiniGem}
    </g>
  );
}

export default memo(Gems);