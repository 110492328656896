import 'src/assets/styles/editors/informative.scss';
import { useGameMechanic } from 'src/hooks';
import GameNotFound from 'src/games/not-found';
import React from 'react';
import { TInformativeGame } from './types';

function InformativeGame() {
  const {
    gameMechanic
  } = useGameMechanic();

  if (gameMechanic) {
    const { content } = gameMechanic;
    const { video, audio, image, infoDescription } = content as unknown as TInformativeGame;

    return (
      <div className='informative-game'>
        <div className='d-md-flex d-block p-5'>
          {video &&
              <div className='video-wrapper'>
                  <video className="img-fluid" controls>
                      <source src={video} type='video/mp4' />
                      <source src={video} type='video/ogg' />
                  </video>
              </div>
          }
          {video && <br />}
          {image &&
            <div className='video-wrapper'>
              <img className="img-fluid" src={image} alt={'image-interactive'} />
            </div>}
          <div className='audio-info mt-2 mt-md-0 ps-md-4'>
            {infoDescription && (<p dangerouslySetInnerHTML={{__html: infoDescription}}/>)}
            {audio && <audio controls>
              <source src={audio} type='audio/ogg' />
              <source src={audio} type='audio/mpeg' />
            </audio>
            }
          </div>
        </div>
      </div>
    );
  }
  return <GameNotFound />;
};

export default InformativeGame;