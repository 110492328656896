import { memo } from 'react';
import { useDrop } from 'react-dnd';
import classnames from 'classnames';

function DropTarget(props: any) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'div',
    drop(_item, monitor) {
      props.onDrop(_item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  return <span ref={drop} className={classnames(`drop-area`, { 'is-over': isOver })}>{props.children}</span>;
}

export default memo(DropTarget);