import { memo } from 'react';

function AvatarIcon(){
  return (
    <svg  viewBox="0 0 121 183" fill="none" xmlns="http://www.w3.org/2000/svg" className="avatar" >
      <path d="M98.0679 141.028L71.5399 125.612C71.3482 125.907 71.0947 126.157 70.7969 126.345L97.3269 141.762C97.6241 141.574 97.8768 141.323 98.0679 141.028Z" fill="#F2BC81"/>
      <path d="M85.2759 93.365C82.6783 104.854 78.0244 115.779 71.5389 125.612L98.0679 141.028C104.553 131.195 109.208 120.271 111.809 108.783C103.846 104.157 93.2399 97.991 85.2759 93.365Z" fill="#F2BC81"/>
      <path d="M85.022 92.2419C85.1651 92.3866 85.2648 92.5686 85.3097 92.7671C85.3546 92.9656 85.3429 93.1728 85.276 93.365L111.809 108.783C111.875 108.59 111.887 108.383 111.841 108.184C111.795 107.985 111.695 107.803 111.551 107.659C102.707 102.521 93.864 97.3819 85.022 92.2419Z" fill="#F2BC81"/>
      <path d="M82.6509 90.707C83.4509 91.167 84.2389 91.6779 85.0219 92.2419C93.8619 97.3806 102.705 102.52 111.551 107.659C110.787 107.108 109.996 106.595 109.18 106.124C101.222 101.499 90.6099 95.334 82.6509 90.707Z" fill="#F2BC81"/>
      <path d="M104.924 109.631L78.395 94.2139L76.538 97.3708L103.067 112.789L104.924 109.631Z" fill="#0F303F"/>
      <path d="M103.067 112.79L76.5399 97.3708L76.9959 100.071L103.525 115.488L103.067 112.79Z" fill="#0F303F"/>
      <path d="M103.523 115.488L76.994 100.071L74.678 100.011L101.207 115.428L103.523 115.488Z" fill="#0F303F"/>
      <path d="M80.4819 89.5229C89.3233 94.6663 98.1666 99.8059 107.012 104.942C107.765 105.301 108.541 105.704 109.312 106.157L82.7819 90.74C82.0079 90.288 81.2399 89.8839 80.4819 89.5229Z" fill="#F2BC81"/>
      <path d="M91.34 131.237L64.811 115.82L65.251 118.526L91.781 133.943L91.34 131.237Z" fill="#0F303F"/>
      <path d="M93.1929 128.076L66.6639 112.659L64.8079 115.82L91.3369 131.237L93.1929 128.076Z" fill="#0F303F"/>
      <path d="M109.198 106.135L82.6699 90.7158C79.4616 88.873 75.9757 87.5628 72.3479 86.8359C72.2159 86.8149 72.1559 86.804 72.0969 86.792L98.6269 102.209C98.6849 102.22 98.7439 102.231 98.8759 102.253C102.504 102.98 105.99 104.291 109.198 106.135Z" fill="#0F303F"/>
      <path d="M80.4819 89.5229L80.1589 89.3718L100.314 101.088C102.438 102.323 104.563 103.557 106.688 104.788C106.795 104.84 106.901 104.888 107.012 104.941C98.1639 99.8029 89.3206 94.6636 80.4819 89.5229Z" fill="#F2BC81"/>
      <path d="M101.207 115.428L74.6779 100.011L72.3779 102.736L98.9079 118.153L101.207 115.428Z" fill="#0F303F"/>
      <path d="M91.7789 133.943L65.2489 118.526L62.937 118.46L89.4669 133.877L91.7789 133.943Z" fill="#0F303F"/>
      <path d="M104.924 109.635L78.3949 94.218L75.8209 95.2729L102.35 110.69L104.924 109.635Z" fill="#E5DAD1"/>
      <path d="M89.4669 133.877L62.9369 118.46L60.6439 121.183L87.1729 136.6L89.4669 133.877Z" fill="#0F303F"/>
      <path d="M99.3399 114.945L72.8069 99.5259L72.3729 102.739L98.9029 118.156L99.3399 114.945Z" fill="#E5DAD1"/>
      <path d="M93.1929 128.078L66.6639 112.661L64.0759 113.725L90.6049 129.142L93.1929 128.078Z" fill="#E5DAD1"/>
      <path d="M87.5979 133.399L61.0699 117.979L60.6439 121.184L87.1729 136.601L87.5979 133.399Z" fill="#E5DAD1"/>
      <path d="M101.188 108.66L74.6589 93.2429L75.8239 95.269L102.353 110.686L101.188 108.66Z" fill="#0F303F"/>
      <path d="M101.188 108.664L74.659 93.2468L73.512 96.603L100.044 112.02L101.188 108.664Z" fill="#E5DAD1"/>
      <path d="M99.34 114.941L72.81 99.5239L70.939 98.5149L97.469 113.934L99.34 114.941Z" fill="#0F303F"/>
      <path d="M100.045 112.02L73.5129 96.5989L70.9429 98.5149L97.4729 113.931L100.045 112.02Z" fill="#E5DAD1"/>
      <path d="M89.448 127.128L62.9189 111.711L64.0809 113.722L90.61 129.14L89.448 127.128Z" fill="#0F303F"/>
      <path d="M89.448 127.128L62.919 111.711L61.783 115.05L88.313 130.467L89.448 127.128Z" fill="#E5DAD1"/>
      <path d="M89.4479 127.128L88.3129 130.467L85.7319 132.386L87.5989 133.396L87.1729 136.596L89.4669 133.875L91.7749 133.938L91.3339 131.232L93.1939 128.073L90.6059 129.137L89.4479 127.128Z" fill="#E5DAD1"/>
      <path d="M87.5979 133.399L61.0699 117.979L59.2029 116.968L85.7319 132.386L87.5979 133.399Z" fill="#0F303F"/>
      <path d="M88.312 130.467L61.782 115.05L59.202 116.97L85.731 132.386L88.312 130.467Z" fill="#E5DAD1"/>
      <path d="M75.4029 144.916L48.8729 129.499L49.3289 132.185L75.8579 147.604L75.4029 144.916Z" fill="#0F303F"/>
      <path d="M77.2589 141.758L50.7299 126.341L48.8729 129.498L75.4029 144.915L77.2589 141.758Z" fill="#0F303F"/>
      <path d="M75.858 147.599L49.329 132.18L47.017 132.119L73.546 147.536L75.858 147.599Z" fill="#0F303F"/>
      <path d="M73.5459 147.54L47.0169 132.123L44.7169 134.846L71.2459 150.263L73.5459 147.54Z" fill="#0F303F"/>
      <path d="M77.2589 141.758L50.7299 126.341L48.1559 127.383L74.6859 142.801L77.2589 141.758Z" fill="#E5DAD1"/>
      <path d="M71.679 147.07L45.15 131.653L44.713 134.853L71.242 150.272L71.679 147.07Z" fill="#E5DAD1"/>
      <path d="M73.5279 140.788L46.9979 125.371L48.1599 127.382L74.6889 142.799L73.5279 140.788Z" fill="#0F303F"/>
      <path d="M73.524 140.788L46.994 125.371L45.851 128.717L72.38 144.134L73.524 140.788Z" fill="#E5DAD1"/>
      <path d="M69.6929 85.7988L96.2219 101.217C96.9777 101.646 97.7839 101.979 98.6219 102.208L72.0919 86.791C71.2536 86.563 70.4475 86.2295 69.6929 85.7988Z" fill="#0F303F"/>
      <path d="M71.6789 147.068L45.1499 131.65L43.2819 130.643L69.8119 146.06L71.6789 147.068Z" fill="#0F303F"/>
      <path d="M72.38 144.133L45.851 128.716L43.278 130.642L69.807 146.059L72.38 144.133Z" fill="#E5DAD1"/>
      <path d="M49.811 169.193L23.282 153.776C23.173 153.847 23.0503 153.896 22.9218 153.918C22.7932 153.94 22.6615 153.934 22.535 153.903L49.068 169.32C49.1938 169.352 49.325 169.357 49.4529 169.335C49.5809 169.313 49.7029 169.265 49.811 169.193Z" fill="#F2BC81"/>
      <path d="M59.445 149.65L32.916 134.231L33.368 136.931L59.898 152.348L59.445 149.65Z" fill="#0F303F"/>
      <path d="M61.312 146.484L34.783 131.067L32.916 134.232L59.445 149.651L61.312 146.484Z" fill="#0F303F"/>
      <path d="M59.8979 152.348L33.3679 136.931L31.0599 136.865L57.5889 152.282L59.8979 152.348Z" fill="#0F303F"/>
      <path d="M57.5879 152.282L31.0599 136.865L28.7599 139.597L55.2889 155.014L57.5879 152.282Z" fill="#0F303F"/>
      <path d="M61.312 146.484L34.783 131.067L32.199 132.131L58.728 147.548L61.312 146.484Z" fill="#E5DAD1"/>
      <path d="M55.7179 151.799L29.1889 136.382L28.7549 139.595L55.284 155.012L55.7179 151.799Z" fill="#E5DAD1"/>
      <path d="M57.5709 145.534L31.0399 130.117L32.1979 132.129L58.7269 147.548L57.5709 145.534Z" fill="#0F303F"/>
      <path d="M57.567 145.536L31.04 130.117L29.892 133.461L56.422 148.88L57.567 145.536Z" fill="#E5DAD1"/>
      <path d="M55.7209 151.799L29.1919 136.382L27.325 135.371L53.854 150.788L55.7209 151.799Z" fill="#0F303F"/>
      <path d="M56.4199 148.88L29.8899 133.461L27.3209 135.374L53.8499 150.791L56.4199 148.88Z" fill="#E5DAD1"/>
      <path d="M57.567 145.536L56.42 148.88L53.85 150.791L55.717 151.8L55.283 155.013L57.583 152.285L59.895 152.35L59.443 149.65L61.31 146.483L58.726 147.547L57.567 145.536Z" fill="#E5DAD1"/>
      <path d="M33.7169 115.415C42.5589 120.556 51.4019 125.695 60.2459 130.834C64.1289 133.087 68.8919 132.991 74.0459 130.014C82.3239 125.235 89.5869 114.181 93.7459 101.426L67.2169 86.009C63.0629 98.764 55.7999 109.818 47.5169 114.597C42.3639 117.574 37.5989 117.67 33.7169 115.415Z" fill="#0F303F"/>
      <path d="M49.5049 141.626L22.9759 126.209L21.1199 129.368L47.6489 144.787L49.5049 141.626Z" fill="#0F303F"/>
      <path d="M47.6489 144.787L21.1199 129.368L21.5599 132.074L48.0899 147.493L47.6489 144.787Z" fill="#0F303F"/>
      <path d="M48.091 147.493L21.561 132.074L19.249 132.013L45.779 147.43L48.091 147.493Z" fill="#0F303F"/>
      <path d="M45.7779 147.43L19.2479 132.013L16.9579 134.732L43.4869 150.15L45.7779 147.43Z" fill="#0F303F"/>
      <path d="M49.5049 141.63L22.9729 126.213L20.3889 127.276L46.9219 142.693L49.5049 141.63Z" fill="#E5DAD1"/>
      <path d="M43.91 146.947L17.381 131.53L16.955 134.73L43.487 150.147L43.91 146.947Z" fill="#E5DAD1"/>
      <path d="M45.76 140.665L19.231 125.246L20.393 127.273L46.922 142.69L45.76 140.665Z" fill="#0F303F"/>
      <path d="M45.76 140.667L19.228 125.25L18.095 128.602L44.625 144.019L45.76 140.667Z" fill="#E5DAD1"/>
      <path d="M43.9099 146.945L17.3809 131.526L15.5139 130.517L42.0399 145.936L43.9099 146.945Z" fill="#0F303F"/>
      <path d="M45.7599 140.667L44.6249 144.019L42.0399 145.938L43.9069 146.947L43.4849 150.147L45.7759 147.43L48.0879 147.491L47.6469 144.791L49.5029 141.63L46.9189 142.692L45.7599 140.667Z" fill="#E5DAD1"/>
      <path d="M44.6239 144.019L18.0949 128.599L15.5109 130.517L42.0399 145.938L44.6239 144.019Z" fill="#E5DAD1"/>
      <path d="M27.2809 107.93C28.6302 111.011 30.8707 113.617 33.714 115.413L60.242 130.83C57.399 129.034 55.1588 126.427 53.8099 123.347C45.8519 118.721 35.2399 112.555 27.2809 107.93Z" fill="#F2BC81"/>
      <path d="M35.191 153.017C37.644 161.24 42.441 166.883 49.068 169.317C49.1941 169.35 49.3258 169.356 49.4545 169.335C49.5832 169.314 49.706 169.266 49.815 169.194L97.326 141.763C97.6247 141.574 97.8792 141.323 98.072 141.027C104.557 131.194 109.21 120.27 111.809 108.782C111.877 108.59 111.888 108.382 111.843 108.184C111.797 107.985 111.696 107.803 111.551 107.66C107.745 105.001 103.429 103.16 98.876 102.252C98.744 102.228 98.689 102.217 98.631 102.208C96.9055 101.781 95.348 100.845 94.16 99.5229C94.1489 99.5749 94.095 99.8349 93.745 101.423C89.595 114.178 82.332 125.232 74.054 130.011C65.463 134.969 57.954 131.933 53.81 123.342L53.141 122.482C51.7744 125.649 50.0908 128.669 48.116 131.497C48.076 131.555 47.896 131.792 47.602 132.197C43.1115 138.382 39.0481 144.867 35.44 151.605C35.1833 152.028 35.0944 152.532 35.191 153.017ZM99.344 114.945L97.473 113.935L100.045 112.02L101.188 108.664L102.354 110.688L104.924 109.631L103.067 112.792L103.523 115.492L101.211 115.432L98.906 118.157L99.344 114.947V114.945ZM87.176 136.599L87.598 133.399L85.7309 132.39L88.312 130.471L89.448 127.132L90.61 129.143L93.197 128.08L92.429 129.38L91.338 131.238L91.779 133.938L89.4699 133.873L87.176 136.596V136.599ZM71.245 150.266L71.682 147.066L69.811 146.055L72.38 144.129L73.528 140.784L74.689 142.796L77.2589 141.754L75.406 144.915L75.8579 147.599L73.55 147.54L71.245 150.264V150.266ZM55.284 155.012L55.721 151.8L53.854 150.791L56.424 148.88L57.571 145.535L58.729 147.548L61.316 146.485L59.449 149.648L59.901 152.348L57.588 152.286L55.288 155.014L55.284 155.012ZM43.492 150.149L43.914 146.943L42.04 145.938L44.625 144.019L45.757 140.667L46.919 142.692L49.506 141.63L47.65 144.787L48.091 147.493L45.779 147.432L43.489 150.151L43.492 150.149Z" fill="#0F303F"/>
      <path d="M99.34 114.945L97.531 113.891L100.042 112.024L101.185 108.664L102.351 110.69L104.921 109.635L103.064 112.796L103.517 115.496L101.209 115.432L98.909 118.161L99.346 114.948L97.475 113.936L99.34 114.945Z" fill="#E5DAD1"/>
      <path d="M80.159 89.3718C77.6668 88.2152 75.0442 87.3639 72.348 86.8359C81.192 91.9726 90.0373 97.1122 98.884 102.255C101.576 102.784 104.195 103.634 106.684 104.789L93.436 97.0889C91.142 95.7555 88.847 94.4222 86.551 93.0889C85.566 92.5129 84.251 91.749 83.261 91.176C82.332 90.637 81.115 89.8708 80.159 89.3718Z" fill="#F2BC81"/>
      <path d="M111.809 108.783C109.208 120.271 104.553 131.195 98.0679 141.028C97.8766 141.323 97.6234 141.574 97.3259 141.762L120.263 128.518L120.252 124.292C120.201 106.371 100.472 102.538 98.8759 102.253C103.429 103.161 107.744 105.002 111.551 107.659C111.695 107.803 111.795 107.985 111.841 108.184C111.887 108.383 111.875 108.59 111.809 108.783Z" fill="#F2BC81"/>
      <path d="M98.884 102.255C90.04 97.1149 81.1946 91.9753 72.348 86.8359L98.876 102.253L98.884 102.255Z" fill="#F2BC81"/>
      <path d="M73.5239 140.788L72.3799 144.133L69.8069 146.059L71.6779 147.07L71.2399 150.27L73.5399 147.543L75.8479 147.606L75.3969 144.922L77.2529 141.761L74.6799 142.804L73.5239 140.788Z" fill="#E5DAD1"/>
      <path d="M94.1599 99.5259L67.6309 84.1089C67.6199 84.1609 67.5609 84.417 67.2159 86.009L93.7449 101.426C94.0909 99.834 94.1489 99.5779 94.1599 99.5259Z" fill="#0F303F"/>
      <path d="M19.382 152.422C28.226 157.561 37.069 162.7 45.911 167.839C46.9176 168.425 47.974 168.92 49.068 169.32L22.54 153.899C21.4464 153.499 20.3897 153.005 19.382 152.422Z" fill="#F2BC81"/>
      <path d="M64.867 73.0989C59.856 82.1419 53.5789 89.2589 46.9059 93.1089C55.7513 98.2476 64.5943 103.387 73.4349 108.528C80.1069 104.676 86.3859 97.5578 91.396 88.5178L64.867 73.0989Z" fill="#F2BC81"/>
      <path d="M105.982 48.342L79.4539 32.9248C79.0602 32.7145 78.6257 32.5921 78.1801 32.5659C77.7345 32.5396 77.2886 32.6103 76.8729 32.7729L103.401 48.1909C103.816 48.0278 104.262 47.9568 104.708 47.9829C105.154 48.0089 105.588 48.1316 105.982 48.342Z" fill="#FFD098"/>
      <path d="M103.401 48.1909L76.8729 32.7729C76.5235 32.8975 76.1853 33.0513 75.8619 33.2329L102.39 48.6489C102.713 48.4679 103.052 48.3147 103.401 48.1909Z" fill="#FFD098"/>
      <path d="M26.862 178.212L0.328979 162.799L0.343979 167.024L26.873 182.441L26.862 178.212Z" fill="#F2BC81"/>
      <path d="M75.5459 92.251L49.0159 76.834L44.7009 79.3259L71.2299 94.7429L75.5459 92.251Z" fill="#FFD098"/>
      <path d="M75.5399 90.199L49.0079 74.782L44.6959 77.2729L71.2249 92.6899L75.5399 90.199Z" fill="#494949"/>
      <path d="M49.9099 75.2988C49.8937 75.6082 49.8042 75.9093 49.6488 76.1772C49.4933 76.4452 49.2764 76.6722 49.0159 76.8398L75.5459 92.2559C75.8064 92.0884 76.0237 91.862 76.1801 91.5947C76.3365 91.3275 76.4276 91.0271 76.4459 90.718C67.5999 85.576 58.7546 80.4362 49.9099 75.2988Z" fill="#FFD098"/>
      <path d="M49.6489 74.7139C49.74 74.7787 49.8121 74.8666 49.858 74.9685C49.9039 75.0704 49.9218 75.1828 49.9099 75.2939C58.7539 80.4346 67.5983 85.5742 76.4429 90.7129C76.4536 90.6013 76.4347 90.4889 76.3882 90.387C76.3417 90.285 76.2692 90.1969 76.1779 90.1318L49.6489 74.7139Z" fill="#FFD098"/>
      <path d="M76.178 90.136L49.649 74.717C49.5458 74.6695 49.4318 74.6503 49.3188 74.6616C49.2057 74.6728 49.0978 74.714 49.006 74.781L75.54 90.1989C75.631 90.1325 75.7382 90.0916 75.8504 90.0805C75.9626 90.0695 76.0757 90.0886 76.178 90.136Z" fill="#494949"/>
      <path d="M44.7009 79.3269C44.61 79.3928 44.5031 79.4334 44.3913 79.4443C44.2796 79.4552 44.1669 79.436 44.0649 79.3889L70.5949 94.8059C70.6967 94.8535 70.8093 94.8729 70.9211 94.8618C71.0329 94.8507 71.1396 94.8096 71.2299 94.7429C63.2719 90.1169 52.6599 83.9509 44.7009 79.3269Z" fill="#FFD098"/>
      <path d="M44.065 79.3928L70.595 94.8098C70.5051 94.7459 70.4339 94.6593 70.3885 94.5588C70.3432 94.4583 70.3254 94.3477 70.337 94.238C70.3505 93.928 70.4379 93.6256 70.5918 93.3562C70.7458 93.0868 70.9618 92.8581 71.222 92.689L44.694 77.272C44.4334 77.4408 44.2171 77.6695 44.0629 77.939C43.9088 78.2084 43.8214 78.5109 43.808 78.821C43.7966 78.9306 43.8144 79.0412 43.8595 79.1416C43.9047 79.242 43.9755 79.3286 44.065 79.3928Z" fill="#494949"/>
      <path d="M58.725 46.4709C58.6965 47.0623 58.5275 47.6382 58.2318 48.1511C57.9362 48.664 57.5224 49.099 57.025 49.4199L83.553 64.8359C84.0508 64.5156 84.4652 64.0814 84.7619 63.5691C85.0585 63.0568 85.2289 62.4811 85.259 61.8899C76.4303 56.7599 67.6023 51.63 58.775 46.5C67.605 51.6287 76.433 56.7586 85.259 61.8899V61.877L78.923 58.198C72.239 54.312 65.5546 50.4273 58.87 46.5439C60.9906 47.7759 63.1116 49.0092 65.233 50.2439L85.259 61.877C85.2778 61.6709 85.2423 61.4645 85.156 61.2764C85.0696 61.0883 84.9355 60.926 84.767 60.8059L58.24 45.3879C58.4092 45.5095 58.5433 45.6738 58.6285 45.864C58.7137 46.0542 58.747 46.2637 58.725 46.4709Z" fill="#FFD098"/>
      <path d="M57.023 49.4199C56.8492 49.5453 56.6454 49.6225 56.4321 49.6436C56.2189 49.6646 56.0039 49.6289 55.809 49.54L82.343 64.957C82.5369 65.0461 82.7511 65.0818 82.9634 65.0605C83.1757 65.0393 83.3786 64.9617 83.551 64.8359L57.023 49.4199Z" fill="#FFD098"/>
      <path d="M46.9059 93.113C40.4849 96.82 34.4129 97.0978 29.4639 94.2358L55.9939 109.654C60.9409 112.516 67.0179 112.237 73.4349 108.532C64.5909 103.391 55.7479 98.251 46.9059 93.113Z" fill="#F2BC81"/>
      <path d="M84.7609 60.807L58.2319 45.39C58.0358 45.2999 57.8192 45.2639 57.6045 45.2857C57.3899 45.3075 57.1849 45.3863 57.0109 45.514L83.5409 60.933C83.7146 60.8051 83.9193 60.7261 84.1338 60.7039C84.3483 60.6818 84.5648 60.7174 84.7609 60.807Z" fill="#494949"/>
      <path d="M83.54 60.9329C83.0461 61.2533 82.6347 61.6858 82.3393 62.1951C82.0439 62.7044 81.8729 63.2762 81.84 63.864C81.84 64.953 82.616 65.3799 83.552 64.8379C84.0487 64.517 84.462 64.0823 84.7576 63.5701C85.0532 63.0578 85.2227 62.4826 85.252 61.8919C85.25 60.8149 84.489 60.3829 83.54 60.9329Z" fill="#494949"/>
      <path d="M55.806 49.54L82.335 64.957C82.1624 64.8353 82.0252 64.67 81.9374 64.478C81.8496 64.286 81.8142 64.0741 81.835 63.864C81.8678 63.2762 82.0389 62.7043 82.3343 62.1951C82.6297 61.6858 83.0411 61.2533 83.535 60.9329L57.005 45.5139C56.511 45.8343 56.0996 46.2666 55.8042 46.7759C55.5088 47.2852 55.3378 47.857 55.305 48.4448C55.2846 48.6553 55.3202 48.8675 55.4082 49.0598C55.4962 49.2521 55.6334 49.4178 55.806 49.54Z" fill="#494949"/>
      <path d="M68.3519 72.8789L41.8229 57.4619L43.0939 65.0669L69.6239 80.4839L68.3519 72.8789Z" fill="#FFD098"/>
      <path d="M73.6089 63.9409L47.0799 48.522L41.8229 57.4619L68.3519 72.8789L73.6089 63.9409Z" fill="#FFD098"/>
      <path d="M69.6239 80.4839L43.0939 65.0669L36.5699 64.8918L63.0989 80.3088L69.6239 80.4839Z" fill="#FFD098"/>
      <path d="M63.0989 80.3098L36.5699 64.8928L30.0809 72.5808L56.6109 87.998L63.0989 80.3098Z" fill="#FFD098"/>
      <path d="M38.261 58.29C38.2323 58.8806 38.0632 59.4557 37.7675 59.9678C37.4718 60.4798 37.0582 60.9139 36.561 61.2339L63.094 76.6499C63.5912 76.3299 64.0048 75.8958 64.3005 75.3838C64.5962 74.8717 64.7653 74.2964 64.794 73.7058C55.9473 68.5678 47.103 63.4294 38.261 58.29Z" fill="white"/>
      <path d="M37.7679 57.199C37.9396 57.3201 38.0759 57.4848 38.1626 57.6763C38.2492 57.8677 38.2832 58.0789 38.2609 58.2878C47.1049 63.4258 55.9479 68.5645 64.7899 73.7039V73.697L38.3569 58.335L64.7899 73.699C64.8098 73.4914 64.7747 73.2823 64.6881 73.0925C64.6016 72.9028 64.4667 72.739 64.297 72.6179C56.34 67.9939 45.7269 61.825 37.7679 57.199Z" fill="white"/>
      <path d="M36.5589 61.2339C36.3855 61.3594 36.1818 61.4368 35.9688 61.458C35.7557 61.4793 35.5408 61.4438 35.3459 61.355L61.8779 76.772C62.0729 76.8612 62.2881 76.8969 62.5015 76.8755C62.7148 76.854 62.9186 76.7762 63.0919 76.6499C55.129 72.0259 44.5209 65.8589 36.5589 61.2339Z" fill="white"/>
      <path d="M29.4399 94.2219L55.9689 109.638C55.7849 109.532 55.6009 109.421 55.4209 109.304C52.2999 107.304 49.6569 104.028 47.7019 99.532L21.1719 84.113C23.1279 88.613 25.7719 91.891 28.8919 93.887C29.0709 93.999 29.2549 94.1149 29.4399 94.2219Z" fill="#FFD098"/>
      <path d="M64.297 72.6218L37.768 57.2048C37.5719 57.1149 37.3555 57.0793 37.1409 57.1013C36.9264 57.1233 36.7217 57.2021 36.548 57.3298L63.078 72.7468C63.2515 72.6191 63.4561 72.5403 63.6704 72.5183C63.8848 72.4963 64.1011 72.532 64.297 72.6218Z" fill="#494949"/>
      <path d="M73.6089 63.9409L47.0799 48.522L39.7939 51.511L66.3229 66.9299L73.6089 63.9409Z" fill="white"/>
      <path d="M63.0779 72.7468C62.5834 73.0663 62.1715 73.4986 61.876 74.0078C61.5806 74.5171 61.4099 75.0891 61.3779 75.677C61.3779 76.768 62.1499 77.1938 63.0869 76.6538C63.5839 76.3331 63.9974 75.8988 64.293 75.3865C64.5887 74.8742 64.758 74.2988 64.7869 73.708C64.7859 72.629 64.0259 72.1988 63.0779 72.7468Z" fill="#494949"/>
      <path d="M35.346 61.355L61.875 76.772C61.7018 76.6504 61.5642 76.4847 61.4763 76.2922C61.3884 76.0998 61.3534 75.8875 61.375 75.677C61.4069 75.0891 61.5776 74.5171 61.8731 74.0078C62.1686 73.4985 62.5804 73.0663 63.075 72.7468L36.545 57.3298C36.0506 57.6495 35.6388 58.0816 35.3434 58.5908C35.0479 59.1 34.8771 59.6722 34.845 60.26C34.8236 60.4706 34.8587 60.683 34.9468 60.8755C35.0348 61.068 35.1726 61.2335 35.346 61.355Z" fill="#494949"/>
      <path d="M57.827 78.957L31.299 63.5388L30.082 72.5808L56.612 87.998L57.827 78.957Z" fill="white"/>
      <path d="M63.047 61.2268L36.5179 45.8098L39.7929 51.51L66.3219 66.929L63.047 61.2268Z" fill="#FFD098"/>
      <path d="M63.047 61.2268L36.518 45.8098L33.283 55.271L59.812 70.688L63.047 61.2268Z" fill="white"/>
      <path d="M46.7399 33.1609C46.1683 33.576 45.4964 33.8313 44.7933 33.9004C44.0902 33.9695 43.3814 33.8499 42.7399 33.554L69.2679 48.9709C69.9095 49.2668 70.6183 49.3861 71.3214 49.3169C72.0245 49.2476 72.6964 48.9922 73.2679 48.5769C74.9021 47.5107 76.2622 46.0745 77.2379 44.3848C78.2136 42.695 78.7775 40.7992 78.8839 38.8508L52.3549 23.4338C52.248 25.3821 51.6839 27.278 50.7085 28.9678C49.733 30.6576 48.3735 32.0939 46.7399 33.1609Z" fill="#FFD098"/>
      <path d="M57.827 78.957L31.299 63.5388L26.017 60.6838L52.546 76.103L57.827 78.957Z" fill="#FFD098"/>
      <path d="M59.813 70.688L33.284 55.271L26.017 60.6838L52.546 76.103L59.813 70.688Z" fill="white"/>
      <path d="M59.8129 70.688L63.0469 61.2268L66.3229 66.927L73.6089 63.939L68.3519 72.877L69.6239 80.4829L63.0989 80.3079L56.6109 87.9958L57.8269 78.9548L52.5399 76.0989L59.8129 70.688ZM63.0919 76.6499C63.5891 76.3299 64.0027 75.8958 64.2984 75.3838C64.5941 74.8717 64.7633 74.2964 64.7919 73.7058C64.7919 72.6268 64.0309 72.1949 63.0819 72.7429C62.587 73.063 62.1749 73.4956 61.8794 74.0056C61.584 74.5156 61.4135 75.0885 61.3819 75.677C61.3819 76.766 62.1539 77.1909 63.0949 76.6499H63.0919Z" fill="white"/>
      <path d="M42.7269 33.554C51.5709 38.6906 60.4139 43.8296 69.2559 48.9709C68.6877 48.5672 68.2362 48.0205 67.947 47.3862C67.6578 46.752 67.5412 46.0526 67.6089 45.3589L41.0799 29.9409C41.0105 30.635 41.1264 31.335 41.4157 31.9697C41.7051 32.6044 42.1575 33.1511 42.7269 33.554Z" fill="#F56B2C"/>
      <path d="M67.5719 32.717L41.0399 17.2988L41.0759 29.938L67.6049 45.356L67.5719 32.717Z" fill="#F56B2C"/>
      <path d="M78.846 26.21L52.317 10.791L52.353 23.4338L78.882 38.8508L78.846 26.21Z" fill="#FFD098"/>
      <path d="M94.7929 25.4119L68.2639 9.99901C63.6639 7.33101 57.6909 8.52481 52.3179 10.7988L78.8459 26.218C84.2209 23.938 90.1979 22.7439 94.7929 25.4119Z" fill="#FFD098"/>
      <path d="M73.1709 16.1991C71.5353 17.2641 70.1741 18.7003 69.1982 20.3907C68.2224 22.0811 67.6593 23.978 67.5549 25.9271L67.5719 32.714L67.6089 45.3561C67.6199 48.9181 70.1529 50.376 73.2629 48.578C74.8982 47.5116 76.2592 46.0751 77.2356 44.3846C78.212 42.6941 78.7763 40.7973 78.8829 38.848L78.8289 19.421C78.8169 15.862 76.2739 14.4111 73.1709 16.1991Z" fill="#F56B2C"/>
      <path d="M43.224 82.9361C43.5431 82.6754 43.8811 82.4382 44.235 82.2269C44.194 81.5719 44.183 80.9011 44.179 80.1981C44.109 55.0601 57.005 40.4579 67.572 32.7159L67.609 45.357C67.62 48.919 70.153 50.375 73.267 48.577C74.9011 47.5108 76.2612 46.0746 77.2369 44.3849C78.2126 42.6951 78.7765 40.7993 78.883 38.8509L78.846 26.2101C89.423 21.7411 102.346 21.4371 102.424 46.5721C102.424 47.2601 102.413 47.96 102.39 48.649C102.713 48.468 103.052 48.3148 103.401 48.191C104.081 47.9034 104.845 47.8846 105.538 48.1385C106.231 48.3925 106.803 48.9002 107.136 49.559C107.957 51.335 108.162 53.334 107.721 55.2401C107.045 59.2971 102.299 66.822 99.021 69.902C97.1907 76.3765 94.633 82.6231 91.3969 88.5221C86.3869 97.5621 80.108 104.68 73.436 108.532C66.764 112.384 60.468 112.532 55.421 109.304C52.3 107.304 49.657 104.028 47.702 99.5321C44.43 100.232 39.644 98.1952 38.953 94.9462C38.5761 92.8236 38.7637 90.6391 39.497 88.6119C40.2006 86.4165 41.4891 84.4544 43.224 82.9361ZM57.824 78.9571L56.608 87.9982L63.096 80.3099L69.621 80.485L68.349 72.879L73.606 63.941L66.32 66.9291L63.044 61.2291L59.81 70.6901L52.54 76.099L57.822 78.953L57.824 78.9571ZM83.548 64.8361C84.0458 64.5157 84.4602 64.0815 84.7569 63.5692C85.0535 63.0569 85.2239 62.4812 85.254 61.89C85.254 60.811 84.49 60.3811 83.538 60.9291C83.044 61.2499 82.6326 61.6826 82.3372 62.1923C82.0418 62.7019 81.8708 63.2738 81.838 63.8619C81.838 64.9529 82.615 65.3771 83.548 64.8361ZM71.227 94.743L75.543 92.2511C75.8034 92.0837 76.0207 91.857 76.1771 91.5897C76.3335 91.3225 76.4246 91.0221 76.443 90.713C76.443 90.132 76.031 89.9132 75.543 90.1962L71.227 92.6849C70.9675 92.8553 70.7521 93.0848 70.5983 93.3544C70.4445 93.6239 70.3567 93.9259 70.342 94.236C70.342 94.802 70.742 95.026 71.235 94.743H71.227Z" fill="#FFD098"/>
      <path d="M71.2219 92.6889C70.9617 92.858 70.7457 93.0868 70.5918 93.3562C70.4379 93.6256 70.3505 93.928 70.3369 94.238C70.3369 94.804 70.7369 95.0298 71.2259 94.7468L75.5419 92.2549C75.8031 92.0877 76.0208 91.861 76.1772 91.5932C76.3337 91.3255 76.4244 91.0244 76.4419 90.7148C76.4419 90.1338 76.0339 89.915 75.5419 90.198L71.2219 92.6889Z" fill="#494949"/>
      <path d="M53.1399 122.487L26.6109 107.071C25.2423 110.238 23.5589 113.258 21.5859 116.088L48.1149 131.504C50.0883 128.674 51.7718 125.654 53.1399 122.487Z" fill="#0F303F"/>
      <path d="M8.21395 137.461C3.94295 145.746 0.307948 155.073 0.328948 162.795L26.8619 178.212C26.8399 170.489 30.471 161.163 34.743 152.878L8.21395 137.461Z" fill="#F2BC81"/>
      <path d="M8.26093 137.363C8.24693 137.396 8.22893 137.428 8.21393 137.463L34.7399 152.878C34.7539 152.845 34.7729 152.813 34.7879 152.778C25.9493 147.638 17.1069 142.5 8.26093 137.363Z" fill="#F2BC81"/>
      <path d="M8.26093 137.363C17.1063 142.502 25.9496 147.641 34.7909 152.782C35.1289 152.132 35.4683 151.489 35.8089 150.854C26.9649 145.716 18.1219 140.577 9.27993 135.437C8.93993 136.072 8.59993 136.715 8.26093 137.363Z" fill="#F2BC81"/>
      <path d="M48.1159 131.499L21.5869 116.083C21.5429 116.138 21.3619 116.375 21.0689 116.775C16.5809 122.961 12.5182 129.444 8.90894 136.181L35.5629 152.781C40.5139 143.441 45.8989 134.515 47.6019 132.198C47.8919 131.799 48.0719 131.56 48.1159 131.499Z" fill="#0F303F"/>
      <path d="M55.4309 113.367L28.9019 97.95C28.6903 101.103 27.9147 104.192 26.6119 107.071L53.1409 122.487C54.4439 119.608 55.2195 116.519 55.4309 113.367Z" fill="#F2BC81"/>
      <path d="M47.6019 132.199C44.2669 136.733 26.8099 161.314 26.8619 178.212L26.8729 182.437L49.8109 169.193C49.7029 169.265 49.5809 169.313 49.4529 169.335C49.3249 169.357 49.1938 169.352 49.0679 169.32C42.4409 166.881 37.6439 161.24 35.1909 153.02C35.0921 152.534 35.1803 152.029 35.4379 151.606C39.0449 144.867 43.109 138.383 47.6019 132.199Z" fill="#F2BC81"/>
      <path d="M55.4199 109.299L28.8909 93.8818L28.9019 97.9448L55.4309 113.362L55.4199 109.299Z" fill="#F2BC81"/>
      <path d="M74.0499 130.014C82.3279 125.235 89.5909 114.181 93.7499 101.426C94.0959 99.834 94.1499 99.5779 94.1609 99.5339C92.4339 97.631 91.457 95.1654 91.4119 92.5959L91.4009 88.522C86.3909 97.562 80.1119 104.68 73.4399 108.532C66.7679 112.384 60.4719 112.532 55.4249 109.304L55.4359 113.367C55.2245 116.519 54.4489 119.608 53.1459 122.487L53.8149 123.347C57.9489 131.938 65.4589 134.976 74.0499 130.014Z" fill="#F2BC81"/>
      <path d="M44.235 82.2268L17.705 66.8098C17.3519 67.022 17.0141 67.2585 16.694 67.5178L43.224 82.936C43.5432 82.6753 43.8811 82.4381 44.235 82.2268Z" fill="#FFD098"/>
      <path d="M15.2759 83.0068L41.8039 98.4229C41.1137 98.0724 40.5038 97.5824 40.0129 96.9839C39.5219 96.3854 39.1607 95.6915 38.9519 94.946C38.5751 92.8235 38.7627 90.639 39.4959 88.6118L12.9669 73.1929C12.234 75.2201 12.045 77.4039 12.4189 79.5269C12.6291 80.2727 12.9914 80.967 13.4831 81.5659C13.9748 82.1648 14.5853 82.6555 15.2759 83.0068Z" fill="#FFD098"/>
      <path d="M43.2239 82.936L16.6939 67.5178C14.9596 69.0362 13.6712 70.998 12.9669 73.1929L39.4959 88.6118C40.1998 86.4163 41.4887 84.4542 43.2239 82.936Z" fill="#FFD098"/>
      <path d="M67.555 25.9299L41.025 10.5129L41.04 17.2988L67.569 32.7148L67.555 25.9299Z" fill="#F56B2C"/>
      <path d="M77.178 15.8179C68.3366 10.6793 59.4936 5.54028 50.649 0.400942C50.0074 0.102175 49.2977 -0.0195966 48.5932 0.047915C47.8887 0.115427 47.2151 0.369803 46.642 0.784976L73.171 16.202C73.744 15.7866 74.4177 15.5319 75.1222 15.4644C75.8267 15.3969 76.5365 15.519 77.178 15.8179Z" fill="#F56B2C"/>
      <path d="M73.1709 16.199L46.6399 0.784912C45.0038 1.84951 43.6422 3.28559 42.6661 4.97607C41.69 6.66656 41.127 8.56367 41.0229 10.5129L67.5529 25.9299C67.6571 23.9802 68.2202 22.0826 69.1965 20.3916C70.1727 18.7006 71.5345 17.2641 73.1709 16.199Z" fill="#F56B2C"/>
      <path d="M67.572 32.7158L41.04 17.2988C30.471 25.0398 17.576 39.643 17.647 64.78C17.647 65.48 17.661 66.1548 17.701 66.8088L44.231 82.2258C44.19 81.5708 44.179 80.9 44.175 80.197C44.109 55.06 57.005 40.4578 67.572 32.7158Z" fill="#FFD098"/>
    </svg>
  )
}

export default memo(AvatarIcon)