import { RouteObject, Navigate } from 'react-router-dom';
import { PATH_GLMS_PLAYER, PATH_MAIN } from 'src/routes/paths';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The route of the main functionality
 */
export const mainRoutes: RouteObject = {
  path: PATH_MAIN.root,
  element: <Navigate to={PATH_GLMS_PLAYER.classes.root} replace />,
};
