import { default as WorldIslands } from './islands';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The world component factory that parse the URL and load the
 * proper world component.
 * @constructor
 */
export function WorldFactory(): JSX.Element {
  return (
    <WorldIslands />
  );
}
