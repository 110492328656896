import React, { ChangeEvent, memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useGameEditMechanic } from 'src/hooks';
import { TCalculationGame } from './types';
import GameNotFound from '../../../not-found';
import { TGameMechanicContent } from 'src/@types';
import { Uploader } from 'src/components';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { checkIsMobile } from '../../../../helpers';
import TinyMceEditor from '../../../../components/tiny-mce-editor';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description
 *    The calculation game component
 * @constructor
 */
function CalculationGameEditor() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    updateGameMechanic,
    gameId
  } = useGameEditMechanic();

  const isMobile = checkIsMobile();

  const validateCurrentContent = () => {
    return true;
  };

  if (gameMechanic) {
    const {
      question,
      answer,
      filePath
    } = gameMechanic.content as unknown as TCalculationGame;

    const onGameMechanicContentChange = (finalContent: TGameMechanicContent) => {
      if (validateCurrentContent()) {
        updateGameMechanic({
          ...gameMechanic,
          content: finalContent as unknown as TGameMechanicContent
        });
      }
    };

    const handleChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      keyType: 'question' | 'answer'
    ) => {
      onGameMechanicContentChange({
        ...(gameMechanic.content
            ? gameMechanic.content as unknown as TCalculationGame
            : { question: '', answer: '', filePath: '' }
        ),
        [keyType]: e.target.value
      });
    };

    return (
      <div className='calculation-game-editor'>
        <Row>
          <Col md={4}>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <Uploader gameId={gameId} currentUrl={filePath}
                        onGameMechanicContentChange={(filePath) => onGameMechanicContentChange({
                          ...gameMechanic.content,
                          filePath
                        } as TGameMechanicContent)} key={Math.random()} />
            </
              DndProvider>
          </Col>
          <Col md={8}>
            <TinyMceEditor handleChange={(value) => onGameMechanicContentChange({
              ...gameMechanic.content as TCalculationGame,
              question: value
            })} value={question} />
            <div className='mt-3'>
              <p>Answer</p>
              <TinyMceEditor handleChange={(value) => onGameMechanicContentChange({
                ...gameMechanic.content as TCalculationGame,
                answer: value
              })} value={String(answer)} maxHeight={130}/>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return <GameNotFound />;
}

export default memo(CalculationGameEditor);
