import 'src/assets/styles/mixins.scss';
import 'src/assets/styles/shop.scss';
import { Avatar } from './avatar';
import {useEffect, useState} from "react";
import AzureAuth from "../../azure-component/AzureAuth";
import { getExpenses, getProfile, getScores, getShopAllItems, getUserProfile } from '../../api/v0';
import { ShopSection } from './shop';
import { WardrobeItemType } from './avatar/wardrobePicker';

const Shop = () => {
  const [profile , setProfile] = useState<any | null>(null);
  const [shopItems, setShopItems] = useState<WardrobeItemType[]>([]);
  const account = AzureAuth.getAccount();
  const { localAccountId: accountId, name: userName } = account;
  useEffect(() => {
    getShopAllItems((data: Record<string, any>[]) => setShopItems(data as unknown as WardrobeItemType[]));
  }, [accountId]);

  useEffect(() => {
    if (account.localAccountId) {
      getUserProfile(account.localAccountId, (data) => setProfile(data ?? null));
    }
  }, [account?.localAccountId]);

  const parseAvatarItems = (avatarItem: any, key: string) => {
    return avatarItem.map((itemData: any) => ({
      ...itemData,
      isShop: !profile?.shop?.[key]?.includes(itemData.url),
    }));
  }

  return (
    <div className='shop d-flex w-100 flex-column flex-md-row overflow-hidden'>
      <Avatar onProfileChange={setProfile} />
      <ShopSection wardrobeItems={shopItems} gender={profile?.gender} />
    </div>
  );
};

export default Shop;