// @ts-ignore

import { Image } from 'react-bootstrap';
import English from './English.svg';
import Science from './Science.svg';
import Maths from './Maths.svg';
import Maltese from './Maltese.svg';
import { ReactNode } from 'react';
import EnglishIsland from './English';
import ScienceIsland from './Science';
import MathsIsland from './Maths';
import MalteseIsland from './Maltese';

function getIslandIcon(iconName: string): string {
  switch (iconName) {
    case 'English':
      return English as unknown as string;
    case 'Science':
      return Science as unknown as string;
    case 'Maths':
      return Maths as unknown as string;
    case 'Maltese':
      return Maltese as unknown as string;
    default:
      return '';
  }
}

export function getIslandIcons(iconName: string): ReactNode {
  return (
    <Image fluid src={getIslandIcon(iconName)} />
  )
}

export function getIslandComponent(islandName:string){
  switch (islandName) {
    case 'English':
      return <EnglishIsland />
    case 'Science':
      return <ScienceIsland />
    case 'Maths':
      return <MathsIsland />
    case 'Maltese':
      return <MalteseIsland />
    default:
      return null;
  }
}
