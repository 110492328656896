import { useState, memo, useMemo, useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useGameMechanic } from 'src/hooks';
import { TPlayers, TSimulationDialogueGame, TSimulationOption } from './types';
import GameNotFound from '../../../not-found';
import AvatarHe from 'src/assets/svg/dialogue/avatar-he';
import AvatarShe from 'src/assets/svg/dialogue/avatar-she';
import { ButtonMotion } from '../../../../components';
import { checkIsMobile } from '../../../../helpers';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //


/**
 * @description
 *    The simulation dialogue game component
 * @constructor
 */
function SimulationDialogueGame() {
  /**
   * @description
   *    The game mechanic hooks that are necessary to load data and manage
   *    processes.
   */
  const {
    gameMechanic,
    changeCorrectAnswersPoints
  } = useGameMechanic();

  if (!gameMechanic) {
    return <GameNotFound />;
  }

  const {
    players
  } = gameMechanic.content as unknown as TSimulationDialogueGame;
  const { player1, player2 } = players;
  const [success, setSuccess] = useState<boolean>(false);
  const [player, setPlayer] = useState<string>('player1');
  const [dialogIndex, setDialogIndex] = useState<number>(0);
  const [gameComplete, setGameComplete] = useState<boolean>(false);
  const previousText = useRef('');
  const optionsLength = players['player1'].options.length - 1;
  const [answer, setAnswer] = useState('');
  const isMobile= checkIsMobile();

  const current = players[player as keyof TPlayers].options[dialogIndex];

  function toggleTurn() {
    setPlayer(prevState => (prevState === 'player1' ? 'player2' : 'player1'));
  }

  function selectAnswer() {
    if (answer === 'correct') {
      // complete game if all options are finished
      if (dialogIndex === optionsLength && player === 'player2') {
        setTimeout(() => {
          setGameComplete(true);
          setSuccess(true);
        }, 200);
        return;
      }

      //continue game
      if (player === 'player2') {
        setDialogIndex(prev => ++prev);
      }

    } else {
      // fail game
      setGameComplete(true);
      setSuccess(false);
    }
    previousText.current = current.correct;
    toggleTurn();
  }


  return (
    <div className='simulation-dialogue'>
      <Container>
        {success ? <Row>
            <Col>
              <h2>Congratulations!</h2>
            </Col>
          </Row>
          :
          <>
            <Row>
              {(!isMobile || (isMobile && player !== 'player2')) &&
              <Col md={6} sm={12}>
                <div className='img-frame h-100 d-flex align-items-center justify-content-center'>{player1.filePath ?
                  <img className='img-fluid' src={player1.filePath} alt='avatar1' /> :
                  <AvatarHe />}</div>
              </Col>}
              {(!isMobile || (isMobile && player !== 'player1')) &&
              <Col md={6} sm={12}>
                <div className='img-frame h-100 d-flex align-items-center justify-content-center'> {player2.filePath ?
                  <img className='img-fluid' src={player2.filePath} alt='avatar2' /> : <AvatarShe />}</div>
              </Col>}
            </Row>
            <Row>
              <Col md={6}>
                {!gameComplete &&
                <ButtonMotion className='w-100' variant={'simulation-select'} onClick={selectAnswer}>Ok</ButtonMotion>}
                <Options side='left' turn={player === 'player1'} gameLost={gameComplete && !success}
                         options={current} selectAnswer={setAnswer}
                         answer={answer}
                         previousAnswer={previousText.current}
                /></Col>
              <Col md={6}>
                {player === 'player2' &&
                <>
                  {!gameComplete &&
                  <ButtonMotion className='w-100' variant={'simulation-select'}
                                onClick={selectAnswer}>Ok</ButtonMotion>}
                  <Options side='right' turn={player === 'player2'} options={current} selectAnswer={setAnswer}
                           previousAnswer={previousText.current}
                           answer={answer}
                           gameLost={gameComplete && !success} />
                </>
                }
              </Col>
            </Row>
          </>
        }
      </Container>
    </div>
  );
}

export default memo(SimulationDialogueGame);

interface OptionsProps {
  side: string,
  previousAnswer: string,
  options: TSimulationOption,
  selectAnswer: Function,
  turn: boolean,
  gameLost: boolean,
  answer: string
}

function OptionsComponent({
                            side,
                            options,
                            selectAnswer,
                            answer,
                            turn,
                            previousAnswer,
                            gameLost
                          }: OptionsProps
) {
  const isTurnComponent = useMemo(() => {
    switch (gameLost) {
      case true :
        return (<div className={`bubble-wrap ${side}`}>
          <div className='text'>
            What?
          </div>
        </div>);
      case false:
        return <div className={`bubble-wrap ${side}`}>
          <ul>{Object.keys(options).map((key: string, i) => {
            return <li key={options[key as keyof TSimulationOption]}
                       className={answer === key ? 'selected' : ''}
                       onClick={() => selectAnswer(key)}>{options[key as keyof TSimulationOption]}</li>;
          })}</ul>
        </div>;
    }

  }, [gameLost, answer]);
  return (
    <>
      {turn ? isTurnComponent : previousAnswer ? <div className={`bubble-wrap previous ${side}`}>
        {previousAnswer}
      </div> : null
      }
    </>
  );
}

const Options = memo(OptionsComponent);
