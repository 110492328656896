import { EnumGameMechanicCategory } from "src/@constants";
export const InformativeGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.informative_game,
  points: 100,
  content: {
    audio: null,
    video: null,
    infoDescription: null
  }
}
