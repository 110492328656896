import { createContext, ReactNode } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { TGameMechanic, TGameMechanicContent } from 'src/@types';
import {
  updateGameMechanic as updateGameMechanicInternally,
} from 'src/redux/slices';
import { useEditGame } from 'src/hooks';

// ========================================================================== //
// ================================= LOGIC ================================== //
// ========================================================================== //

/**
 * @description - The game mechanic base interface that contains all necessary
 *  variables and methods for the game mechanic playable and editable components
 * @param { boolean } isLoading - The boolean variable that shows if the game is
 *  in loading process or not
 * @param { boolean} isPaused - The boolean variable that shows if the game is
 *  paused or not
 * @param { TGameMechanic } gameMechanicData
 *    the mechanic object of the game data that is necessary in order to parse
 *    the component view.
 * @param { function } changeCorrectAnswersPoints
 *    The method is changing the total points for the current page, based on the
 *    provided answers
 * @param { function } saveGameMechanicContent
 *    The method is changing the content data for the current page, based on the
 *    provided input
 * @param { function } addNewPage
 *    The method is adding a new page to the games data
 * @param { number } page
 *    The current page of the game
 */
export interface GameMechanicEditInterface {
  error: string | Error | null;
  page: number;
  isLoading: boolean;
  gameId: string | null;
  gameMechanic: TGameMechanic | null;
  updateGameMechanic: (gameMechanicData: TGameMechanic | null) => void;
  saveUpdatedGameMechanic: (mechanicContent?: TGameMechanicContent) => void;
  changePage: (pageNumber: number) => void;
}

/**
 * @description - Game mechanic base context to create context of world
 *  lifecycle
 */
export const ContextGameMechanicEdit = createContext<GameMechanicEditInterface | null>(null);

/**
 * @description - Games' base lifecycle Provider hook. Contains lifecycle logic
 * @param children - Children components
 * @constructor - Adding listener for game lifecycle
 */
export function GameMechanicEditProvider(
  { children }: { children: ReactNode }
) {

  // ========================== INTERNAL VARIABLES ========================== //
  /**
   * @description - The global state-based variables and methods
   */
  const dispatch = useDispatch();
  const {
    gameMechanic,
    game,
    status,
    isEditMode,
    isLoading,
    error,
    page,
  } = useSelector((state) => state.game);

  const { changePage, updateGame } = useEditGame();

  // ========================== LIFECYCLE METHODS =========================== //

  /**
   * @description
   *    The method is saving the modified game mechanic content by triggering
   *    the API to store the data into the server
   * @param { TGameMechanic } finalGameMechanic
   *    The final game mechanic data that need to be modified or added into the
   *    internal storage and then if needed update server's DB
   */
  const updateGameMechanic = (
    finalGameMechanic: TGameMechanic | null
  ) => {
    if (gameMechanic) {
      dispatch(updateGameMechanicInternally(finalGameMechanic));
    }
  }

  /**
   * @description
   *    The method is saving the game mechanic into the game object for the
   *    internal state
   */
  const saveUpdatedGameMechanic = (
    currentMechanicContentData?: TGameMechanicContent
  ) => {
    if (game && gameMechanic) {
      let modifiedContent = [...game?.content];
      modifiedContent[page] = {
        ...gameMechanic,
        ...(
          currentMechanicContentData
            ? { content: currentMechanicContentData }
            : {}
        )
      };
      updateGame({
        ...game,
        content: modifiedContent,
      });
    }
  }

  // ========================= VIEW METHODS/ACTIONS ========================= //
  /**
   * @description - The provider view
   */
  return (
    <ContextGameMechanicEdit.Provider
      value={{
        // Data
        error,
        isLoading,
        page,
        gameId: game?.id ?? null,
        gameMechanic,
        updateGameMechanic,
        saveUpdatedGameMechanic,
        changePage,
      }}
    >
      {children}
    </ContextGameMechanicEdit.Provider>
  );
}
