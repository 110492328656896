import { memo } from 'react';

function ImageHolderPortrait() {
  return (
    <svg viewBox='0 0 465 593' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M429.599 0H35.4007C15.8494 0 0 15.8574 0 35.4184V557.582C0 577.143 15.8494 593 35.4007 593H429.599C449.151 593 465 577.143 465 557.582V35.4184C465 15.8574 449.151 0 429.599 0Z'
        fill='#F2F2F2' />
      <path d='M24 44L437 559' stroke='#B3B3B3' strokeMiterlimit='10' />
      <path d='M436 43L25 560' stroke='#B3B3B3' strokeMiterlimit='10' />
    </svg>
  );
}

export default memo(ImageHolderPortrait);