import { EnumGameMechanicCategory } from "src/@constants";

export const SimulationDialogGameData = {
  title: '',
  description: '',
  category: EnumGameMechanicCategory.simulation_dialogue,
  points: 100,
  content: {
    players: {
      player1: {
        filePath: '',
        options: [
          {
            correct: '',
            incorrect: ''
          }
        ]
      },
      player2: {
        filePath: '',
        options: [
          {
            correct: '',
            incorrect: ''
          }
        ]
      }
    }
  }
};
